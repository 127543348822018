<template>
  <div class="popup-form add-country">
    <b-modal id="showLicenseModel"
            hideFooter
             ref="modal"
             title="View License"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @hide="handleCancel"
             @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true" >
    
            <div  v-if="!loading">
                 <table class="table table-bordered table-striped not-responsive-grid">
                <tbody>
                <tr>
                    <th>License Name</th>
                    <td><span>{{ showData.license_name }}</span></td>
                </tr>
                <!-- <tr>
                    <th>License Details</th>
                    <td><span class="parent_span" v-for="(list) in showData.license_details" :key="list.id">
                            <span v-if="list.type == 'project'" style="padding-left:56px;"><i class="project_icon">P</i>{{list.project_name}}</br></span>
                            <span v-if="list.type == 'module'">
                                <span style="margin-bottom:8px;display:block;padding-left:28px;"><i class="module_icon">M</i>{{list.module_name}}<br></span>
                            
                                <span style="padding-left:56px;" v-for="(project) in list.module_project" :key="project.id"><i class="project_icon">P</i>{{project.project_name}}</br>
                                </span>
                            </span>
                            
                            <span v-if="list.type == 'course'">
                                <span style="margin-bottom:8px;display:block;"><i class="course_icon">C</i>{{list.course_name}}<br></span>
                                <span v-for="(course) in list.course_details" :key="course.id">
                                    <span v-if="course.type == 'project'" style="margin-bottom:8px;padding-left:56px;display:block;"><i class="project_icon">P</i>{{course.project_name}}</br></span>
                                    <span v-if="course.type == 'module'">
                                        <span style="margin-bottom:8px;display:block;padding-left:28px;"><i class="module_icon">M</i>{{course.module_name}}<br></span>
                                    
                                        <span style="padding-left:56px;" v-for="(project) in course.module_project" :key="project.id"><i class="project_icon">P</i>{{project.project_name}}</br>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span></td>
                </tr>   -->
                  <tr>
                    <th>License Start Date</th>
                    <td><span>{{ showData.start_date }}</span></td>
                </tr> 
                <tr>
                    <th>License End Date</th>
                    <td><span>{{ showData.end_date }}</span></td>
                </tr>          
                </tbody>
            </table>
                <div class="card card-primary card-outline card-outline-tabs">
                    <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="project_tab" data-toggle="pill" href="#projects" role="tab" aria-controls="projects" aria-selected="true" @click="setTabSelection('project')">Projects</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="module_tab" data-toggle="pill" href="#modulesTabLink" role="tab" aria-controls="modulesTabLink" aria-selected="false" @click="setTabSelection('module')">Modules</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="course_tab" data-toggle="pill" href="#courses" role="tab" aria-controls="courses" aria-selected="false" @click="setTabSelection('course')">Courses</a>
                    </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="projects" role="tabpanel" aria-labelledby="project_tab" v-if="selectedLicenseTab == 'project'">
                        <Project :showId="showId"></Project>
                    </div>
                    <div class="tab-pane fade" id="modulesTabLink" role="tabpanel" aria-labelledby="module_tab" v-if="selectedLicenseTab == 'module'">
                        <!-- <ProjectTopics :project_id="project_id"></ProjectTopics> -->
                        <Module :showId="showId"></Module>
                    </div>
                    <div class="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="course_tab" v-if="selectedLicenseTab == 'course'">
                        <!-- <ProjectTopics :project_id="project_id"></ProjectTopics> -->
                        <Course :showId="showId"></Course>
                    </div>
                    </div>
                </div> 
            </div>
            </div>
            <div class="card-body" v-else>
                <CustomLoader></CustomLoader>
            </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
import Project from '@/components/cruds/Branchs/ViewLicense/Project'
import Module from '@/components/cruds/Branchs/ViewLicense/Module'
import Course from '@/components/cruds/Branchs/ViewLicense/Course'
export default {
    components:{
        CustomLoader,
        Project,
        Module,
        Course
    },
    props: ['showId'],
    created() {        
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('LicenseIndex', ['showData','loading','selectedLicenseTab']),
    },
    methods: {
        ...mapActions('LicenseIndex', ['fetchShowData','addShowDataAll','setLicenseTabSelection']),
        setTabSelection(tab){
            this.setLicenseTabSelection(tab);
        },
        handleCancel(evt){
            //console.log("cancel");
            this.addShowDataAll();
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.setTabSelection('project')
            //console.log("sdsd",this.showId);
            
             this.fetchShowData(this.showId);
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    }
}
</script>
<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    /* border-color: black; */
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>