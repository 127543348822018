<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branches</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branchs'">Branches</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Branch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Branch</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                    <tr>
                                        <th>Branch Name</th>
                                        <td><span>{{ item.branch_name }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Franchise Name</th>
                                        <td>
                                            <span v-if="item.franchise !== null">
                                                {{ item.franchise.display_franchise_name }}    
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch Mobile Number</th>
                                        <td><span>{{ item.mobile_no }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch Email</th>
                                        <td><span>{{ item.branch_email }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch Address</th>
                                        <td><span>{{ item.branch_address }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch Area</th>
                                        <td><span>{{ item.area }}</span></td>
                                    </tr>
                                    <!-- <tr>
                                        <th>Branch City</th>
                                        <td><span>{{ item.city }}</span></td>
                                    </tr> -->
                                    <tr>
                                        <th>Branch City</th>
                                        <!-- <td>{{ item.state }}</td> -->
                                        <td>
                                            <span v-if="item.city_list !== null">
                                                {{ item.city_list.city_name }}    
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch State</th>
                                        <!-- <td>{{ item.state }}</td> -->
                                        <td>
                                            <span v-if="item.state_list !== null">
                                                {{ item.state_list.state_name }}    
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>                                                                        
                                    <tr>
                                        <th>Branch Country</th>
                                        <!-- <td>{{ item.country }}</td> -->
                                        <td>
                                            <span v-if="item.country_list !== null">
                                                {{ item.country_list.country_name }}    
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch PIN/ZIP Code</th>
                                        <td><span>{{ item.pincode }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch Phone number</th>
                                        <td><span>{{ item.phone_no }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch GST number</th>
                                        <td><span>{{ item.branch_gst_no }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Branch PAN number</th>
                                        <td><span>{{ item.branch_pan_no }}</span></td>
                                    </tr>
                                    <!-- <tr>
                                        <th>Licence Start Date</th>
                                        <td><span>{{ item.licence_start_date }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Licence End Date</th>
                                        <td><span>{{ item.licence_end_date }}</span></td>
                                    </tr> -->
                                    <tr>
                                        <th>Branch Working Days</th>
                                        <td><span>{{ item.workingDaysName }}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
    ...mapGetters('BranchsSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BranchsSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
