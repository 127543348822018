<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Headers</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/skill-master'">Headers</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Header</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline card-outline-tabs">
                     <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="changeComp('header')" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Edit Header</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="changeComp('skill')" id="custom-tabs-four-skill-tab" data-toggle="pill" href="#custom-tabs-four-skill" role="tab" aria-controls="custom-tabs-four-skill" aria-selected="false">Skill</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div v-if="tabChange == 'header'" class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                <SkillHeaderEdit :header_id="header_id"></SkillHeaderEdit>
                            </div>
                            <div v-if="tabChange == 'skill'" class="tab-pane fade" id="custom-tabs-four-skill" role="tabpanel" aria-labelledby="custom-tabs-four-skill-tab">
                                <SkillIndex :header_id="header_id"></SkillIndex>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SkillHeaderEdit from '../../dtmodules/SkillHeaderEdit'
import SkillIndex from './SkillIndex'

export default {
    components: {
        SkillHeaderEdit,
        SkillIndex,
    },
    data() {
        return {
            form_data:{
               header_name:'',
            },
            header_id: '',
            tabChange:'header',
        }
    },
    computed: {
        ...mapGetters('SkillHeaderMasterSingle', ['headerDetail', 'loading']),
    },
    created() {
        this.header_id = this.$route.params.id;
    },
    watch: {
        "$route.params.id": function() {
            this.header_id = this.$route.params.id;
        },
        tabChange(){
            return this.tabChange;
        }
    },
    methods: {
        ...mapActions('SkillHeaderMasterSingle', ['fetchData', 'updateData']),
        changeComp(tabActive){
            this.tabChange = tabActive
        }
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.Header-card{
    margin: 10px;
}
</style>
