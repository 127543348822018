<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Issue Stock</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Issue Stock</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="category_id" class="form-group">
                                                <label for="type_">Category<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="category_id"
                                                        label="name"
                                                        id="category_id"
                                                        v-validate="'required'"
                                                        @input="updateCategory"
                                                        :value="item.item_category_id"
                                                        :options="categoriesAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('category_id')">
                                                    {{ $root.updateErrorMessage('category_id',this.errors, 'Category')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="item_id" class="form-group">
                                                <label for="type_">Item<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="item_id"
                                                        label="name"
                                                        id="item_id"
                                                        v-validate="'required'"
                                                        @input="updateItem"
                                                        :value="item.item_id"
                                                        :options="itemsAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('item_id')">
                                                    {{ $root.updateErrorMessage('item_id',this.errors, 'Item')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="sell_date" class="form-group">
                                                <label for="sell_date">Issue Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    v-model= "item.sell_date"
                                                    :config="$root.dpconfigDate"
                                                    name="sell_date"
                                                    placeholder="Select Issue Date"
                                                    v-validate="'required'"
                                                    autocomplete="off"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('sell_date')">
                                                    {{ $root.updateErrorMessage('sell_date',this.errors,'Issue date')}}
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="quantity">Quantity<p class="astrisk">*</p></label>
                                                <!--<label for="balance" class="align-right">Balance : {{item.item_id ? item.item_id.balance : 0}}</label>-->
                                                <label for="balance" class="align-right">Balance : {{balance}}</label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="quantity"
                                                        name="quantity"
                                                        id="quantity"
                                                        maxlength="17"
                                                        v-validate="'required|min_value:1|max_value:'+item.balance"
                                                        placeholder="Enter Quantity"
                                                        v-model="item.quantity"
                                                        oninput="this.value=this.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/\./g, '');"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('quantity')">
                                                          {{ $root.updateErrorMessage('quantity',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="sell_to" class="form-group">
                                                <label for="type_">Issue To<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="sell_to"
                                                        label="name"
                                                        id="sell_to"
                                                        v-validate="'required'"
                                                        @input="updateSellTo"
                                                        :value="item.sell_to"
                                                        :options="sellToAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('sell_to')">
                                                    {{ $root.updateErrorMessage('sell_to',this.errors, 'Issue To')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="sell_to_id" v-if="item.sell_to && item.sell_to.value_id != 4" class="form-group">
                                                <label for="type_">Issue To Name<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="sell_to_id"
                                                        label="name"
                                                        id="item_id"
                                                        v-validate="'required'"
                                                        @input="updateSellToId"
                                                        :value="item.sell_to_id"
                                                        :options="sellToIdAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('sell_to_id')">
                                                    {{ $root.updateErrorMessage('sell_to_id',this.errors, 'Issue to name')}}
                                                </span>
                                            </div>
                                            <div v-if="item.sell_to && item.sell_to.value_id == 4" class="form-group">
                                                <label for="other">Other Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="other"
                                                        name="other"
                                                        id="other"
                                                        v-validate="'required|max:255'"
                                                        placeholder="Enter Other Name"
                                                        v-model="item.other"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('other')">
                                                          {{ $root.updateErrorMessage('other',this.errors,'Other name')}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="remarks">Remarks</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="remarks"
                                                        name="remarks"
                                                        id="remarks"
                                                        v-validate="'max:255'"
                                                        placeholder="Enter Remarks"
                                                        v-model="item.remarks"
                                                        autocomplete="off"
                                                    ></textarea>
                                                        <span class="error" v-show="errors.has('remarks')">
                                                          {{ $root.updateErrorMessage('remarks',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="file">File (Allowed files : jpg,png,jpeg,pdf,csv,ods,xls, xlsx,odt,txt,docx,doc) </label>
                                                <input
                                                        type="file"
                                                        name="file"
                                                        ref="file"
                                                        id="file"
                                                        v-validate="'size:2048|ext:jpg,png,jpeg,pdf,csv,ods,xls,xlsx,odt,txt,docx,doc'"
                                                        class="form-control"
                                                        @change="updateFile"
                                                    >
                                            <span class="error" v-show="errors.has('file')">
                                              {{ $root.updateErrorMessage('file',this.errors,'file')}}
                                            </span>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

  export default {
    data() {
      return {
        balance:0,
        itemChanged: 0,
      }
    },
    computed: {
        ...mapGetters('InventoryItemSellSingle', ['item', 'loading', 'categoriesAll', 'itemsAll', 'sellToAll', 'sellToIdAll'])
    },
    created() {
        this.fetchCategoriesAll()
        this.fetchSellToAll()
    },
    destroyed() {
        this.resetState()
    },
    watch:{
      
    },
    methods: {
        ...mapActions('InventoryItemSellSingle', ['storeData', 'setItemCategoryId', 'setItemId', 'setItemsAll','setFile', 'setSellTo', 'setSellToIdAll'
          ,'fetchSellToBranch', 'fetchSellToEmployee', 'fetchSellToStudent', 'setSellToId', 'setBalance', 'setQuantity'
          , 'setSellDetails' , 'fetchCategoriesAll', 'fetchSellToAll', 'fetchItemsAll','resetState']),
        updateCategory(e) {
          this.setItemCategoryId(e)            
          this.setItemId(null)
          this.setItemsAll([])
//          this.setQuantity('');
          this.balance = 0;
          if(e){
            this.fetchItemsAll({id:e.value_id,all:1});
          }
        },
        updateSellTo(e) {
          this.setSellTo(e)            
          this.setSellToIdAll([]);
          var self = this
          setTimeout(function(){
            self.setSellToId(null);
          },100)
          if(!e){
            return ;
          }
          if(e.value_id == 1){
            this.fetchSellToBranch()
          }else if(e.value_id == 2){
            this.fetchSellToEmployee()
          }else if(e.value_id == 3){
            this.fetchSellToStudent()
          }
        },
        updateItem(e) {
          this.setBalance(0);
//          this.setQuantity('');
          this.itemChanged = 1;
          this.balance = 0;
          this.setItemId(e)
          if(e){
            this.balance = e.balance;
            this.setBalance(e.balance);
          }
        },
        updateSellToId(e) {
            this.setSellToId(e)
        },
        updateFile(e) {
          
          if(e && e.target && e.target.files){
              this.setFile(e.target.files[0]);
          }else{
            this.setFile(null);
          } 
            
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var itemDetails = {
                     item_category_id: this.item.item_category_id.value_id,
                     item_id: this.item.item_id.id,
                     sell_date: this.item.sell_date,
                     quantity: this.item.quantity,
                     sell_to: this.item.sell_to.value_id,
                     sell_to_id: this.item.sell_to_id ? this.item.sell_to_id.id : null,
                     other: this.item.other,
                     remarks: this.item.remarks,
                     file: this.item.file,
                 };
                this.setSellDetails(itemDetails);
                this.storeData()
                     .then((res) => {
                        if(res.data.error_custome)
                            {
                                this.$store.dispatch(
                                'Alert/setAlert',
                                { message: res.data.error_custome, errors: false, color: 'danger' },
                                { root: true })
                                return '';    
                            }        
                        this.$router.push({ name: 'inventory_item_sell.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        
    }
}
</script>


<style scoped>
  .align-right{
      float: right;
  }
</style>
