<template>
    <form @submit.prevent="submitForm" novalidate>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="mother_name">Mother Name<p class="astrisk">*</p></label>
                    <input
                            type="text"
                            class="form-control"
                            name="mother_name"
                            ref="mother_name"
                            maxlength="90"
                            v-validate="'required'"
                            placeholder="Enter Mother Name"
                            :value="item.mother_name"
                            @input="updateMother_name"
                            >
                            <span class="error" v-show="errors.has('mother_name')">
                                {{ $root.updateErrorMessage('mother_name',this.errors,'Mother Name')}}
                                </span>
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="other_mobile_no">Mother Mobile Number<p class="astrisk">*</p></label>
                    <input
                            type="text"
                            min="0"
                            class="form-control"
                            name="other_mobile_no"
                            ref="other_mobile_no"
                            v-validate="'required|digits:10'"
                            maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Enter Mother Mobile Number"
                            :value="item.other_mobile_no"
                            @input="updateOther_mobile_no"
                            >
                            <span class="error" v-show="errors.has('other_mobile_no')">
                                {{ $root.updateErrorMessage('other_mobile_no',this.errors,'Mother Mobile Number')}}
                                </span>                                                
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="email">Email<p class="astrisk">*</p></label>
                    <input
                            type="email"
                            class="form-control"
                            name="email"
                            ref="email"
                            maxlength="200"
                            v-validate="'required|email'"
                            placeholder="Enter Email"
                            :value="item.email"
                            @input="updateEmail"
                            >
                            <span class="error" v-show="errors.has('email')">
                                {{ $root.updateErrorMessage('email',this.errors)}}
                                </span>
                </div>
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <label for="whatsapp_no">Whatsapp Number</label>
                <label for="checkboxWp" style="font-weight: 400; cursor:pointer;">(Same as Mother Mobile Number?</label>
                        <input
                        id="checkboxWp"
                        type="checkbox"
                        @change="updateWPCheckbox">
                <label style="font-weight: 400; cursor:pointer;">)</label>                                    
                <input
                    type="text"
                    min="0"
                    class="form-control"
                    name="whatsapp_no"
                    ref="whatsapp_no"
                    v-validate="'digits:10'"
                    maxlength="10"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    placeholder="Enter Whatsapp Number"
                    :value="item.whatsapp_no"
                    @input="updateWhatsappNo"
                    >
                    <span class="error" v-show="errors.has('whatsapp_no')">
                        {{ $root.updateErrorMessage('whatsapp_no',this.errors,'Whatsapp number')}}
                    </span>
            </div>        
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="father_name">Father Name</label>
                    <input
                            type="text"
                            class="form-control"
                            name="father_name"
                            ref="father_name"
                            maxlength="90"
                            placeholder="Enter Father Name"
                            :value="item.father_name"
                            @input="updateFather_name"
                            >
                            <span class="error" v-show="errors.has('father_name')">
                                {{ $root.updateErrorMessage('father_name',this.errors,'Father Name')}}
                                </span>
                </div>
            </div>
            
                                       
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="mobile_no">Father Mobile Number</label>
                    <input
                            type="text"
                            class="form-control"
                            name="mobile_no"
                            ref="mobile_no"
                            v-validate="'digits:10'"
                            maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Enter Father Mobile Number"
                            :value="item.mobile_no"
                            @input="updateMobile_no"
                            >
                            <span class="error" v-show="errors.has('mobile_no')">
                                {{ $root.updateErrorMessage('mobile_no',this.errors,'Father Mobile Number')}}
                                </span>
                </div>
            </div>
            
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="other_email">Other Email</label>
                    <input
                            type="email"
                            class="form-control"
                            name="other_email"
                            ref="other_email"
                            maxlength="200"
                            v-validate="'email'"
                            placeholder="Enter Other Email"
                            :value="item.other_email"
                            @input="updateOther_email"
                            >
                            <span class="error" v-show="errors.has('other_email')">
                                {{ $root.updateErrorMessage('other_email',this.errors)}}
                                </span>                                                
                </div>
            </div> 
            <div class="col-md-6 col-xs-12">
                <div class="form-group">
                    <label for="user_status">Login Status</label>
                    <br>
                    <label class="switch" for="role">

                        <input
                            type="checkbox"
                            name="user_status"
                            :checked="item.user_status"
                            @change="updateUserStatus"
                            > 
                        <span class="slider round"></span>
                    </label>   

                    </div>
                </div>             
        </div>  
         <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>      
    </form>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props:['editId'],
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('ParentsSingle', ['item', 'loading', 'rolesAll']),
    },
    created() {
        //console.log("edit id",this.editId);
        this.fetchData(this.editId)
            .then(()=>{
                if(this.item.other_mobile_no == this.item.whatsapp_no) {
                    document.getElementById("checkboxWp").checked = true;
                }                
            })
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('ParentsSingle', ['fetchData', 'updateData', 'resetState', 'setFather_name', 'setMother_name', 'setMobile_no', 'setWhatsappNo', 'setOther_mobile_no', 'setEmail', 'setOther_email','setUserStatus', 'setUsername', 'setRole']),
        closeModel(){
            this.$emit('closeModel');
        },
        updateWhatsappNo(e) {
            this.setWhatsappNo(e.target.value)
            if(e.target.value == this.item.other_mobile_no) {
              document.getElementById('checkboxWp').checked = true
            } else {
              document.getElementById('checkboxWp').checked = false
            }
        },
        updateWPCheckbox(e){
            if(e.target.checked == true) {
                this.setWhatsappNo(this.item.other_mobile_no)
            } else {
                this.setWhatsappNo(null)
            }
        },
        updateUsername(e) {
            this.setUsername(e.target.value)
          },
        updateFather_name(e) {
            this.setFather_name(e.target.value)
        },
        updateMother_name(e) {
            this.setMother_name(e.target.value)
        },
        updateMobile_no(e) {
            this.setMobile_no(e.target.value)
        },
        updateOther_mobile_no(e) {
            this.setOther_mobile_no(e.target.value)
        },
        updateEmail(e) {
            this.setEmail(e.target.value)
        },
        updateOther_email(e) {
            this.setOther_email(e.target.value)
        },
        updateRole(value) {
            this.setRole(value)
        },
        updateUserStatus(e) {
            this.setUserStatus(e.target.checked)            
        },
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
            this.updateData()
                .then((res) => {
                    //console.log("dfdf",res.data.result);
                        if(!res.data.result)
                        {
                           for (const [key, value] of Object.entries(res.data.data)) {
                                 // console.log("ke",key,value);
                              this.$validator.errors.add({
                                 id: key,
                                 field: key,
                                 msg: value[0],
                                 scope: this.$options.scope,
                              });
                           }

                              return false;    
                           }  
                           this.$emit('closeModel');
                           this.$emit('loadItems');
                           this.$eventHub.$emit('create-success')      
                           
                        }) 
                .catch((error) => {
                    console.error(error)
                })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
              });
        }
    }
}
</script>


<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
