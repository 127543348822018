<template>
    <div class="popup-form add-country">
    <b-modal id="addAssessmentModel"
             ref="addAssessmentModel"
             title="Make Assessment"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <addAssessment :id="id" :assessmentTopicId="assessmentTopicId" @closeModel="closeModel" @loadItems="loadItems"></addAssessment>
    </b-modal>
  </div>
</template>
<script>
import addAssessment from '@/components/cruds/StudentAttendance/addAssessment'
export default {
    props: ['id','assessmentTopicId'],
    components:{
        addAssessment
    },
    methods: {
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){
            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(){
            this.$refs.addAssessmentModel.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

