import Vue from 'vue'
import VueRouter from 'vue-router'
import ChangePassword from '../components/ChangePassword.vue'
import PermissionsIndex from '../components/cruds/Permissions/Index.vue'
import PermissionsCreate from '../components/cruds/Permissions/Create.vue'
import PermissionsShow from '../components/cruds/Permissions/Show.vue'
import PermissionsEdit from '../components/cruds/Permissions/Edit.vue'
import RolesIndex from '../components/cruds/Roles/Index.vue'
import RolesCreate from '../components/cruds/Roles/Create.vue'
import RolesShow from '../components/cruds/Roles/Show.vue'
import RolesEdit from '../components/cruds/Roles/Edit.vue'
import LeaveReportIndex from '../components/cruds/LeaveReport/Index.vue'
import UsersIndex from '../components/cruds/Users/Index.vue'
// import UsersCreate from '../components/cruds/Users/Create.vue'
import UsersShow from '../components/cruds/Users/Show.vue'
// import UsersEdit from '../components/cruds/Users/Edit.vue'
import SmsOnAttendanceIndex from '../components/cruds/SmsOnAttendance/Index.vue'
import SmsOnDynamicTemplateCreate from '../components/cruds/SmsOnDynamicTemplate/Create.vue'
import SmsOnDynamicTemplateEdit from '../components/cruds/SmsOnDynamicTemplate/Edit.vue'
import SmsOnDynamicTemplateShow from '../components/cruds/SmsOnDynamicTemplate/Show.vue'
import SmsOnDynamicTemplateIndex from '../components/cruds/SmsOnDynamicTemplate/Index.vue'
import SmsOnAdmissionConfirmationIndex from '../components/cruds/SmsOnAdmissionConfirmation/Index.vue'
import SmsOnFeeReminderIfPendingIndex from '../components/cruds/SmsOnFeeReminderIfPending/Index.vue'
import CancelAdmissionIndex from '../components/cruds/CancelAdmission/Index.vue'
import CancelAdmissionCreate from '../components/cruds/CancelAdmission/Create.vue'
import CancelAdmissionShow from '../components/cruds/CancelAdmission/Show.vue'
import CancelAdmissionEdit from '../components/cruds/CancelAdmission/Edit.vue'
import FranchisesIndex from '../components/cruds/Franchises/Index.vue'
import FranchisesCreate from '../components/cruds/Franchises/Create.vue'
import FranchisesShow from '../components/cruds/Franchises/Show.vue'
import FranchisesEdit from '../components/cruds/Franchises/Edit.vue'
import CoursesIndex from '../components/cruds/Course/Index.vue'
import CoursesCreate from '../components/cruds/Course/Create.vue'
import CoursesShow from '../components/cruds/Course/Show.vue'
import CoursesEdit from '../components/cruds/Course/Edit.vue'
import ProjectIndex from '../components/cruds/Project/Index.vue'
import ProjectCreate from '../components/cruds/Project/Create.vue'
import ProjectShow from '../components/cruds/Project/Show.vue'
import ProjectEdit from '../components/cruds/Project/Edit.vue'
import ModuleIndex from '../components/cruds/Module/Index.vue'
import ModuleCreate from '../components/cruds/Module/Create.vue'
import ModuleShow from '../components/cruds/Module/Show.vue'
import ModuleEdit from '../components/cruds/Module/Edit.vue'
import ProjectFeesIndex from '../components/cruds/ProjectFees/Index.vue'
import ProjectFeesCreate from '../components/cruds/ProjectFees/Create.vue'
import ProjectFeesShow from '../components/cruds/ProjectFees/Show.vue'
import ProjectFeesEdit from '../components/cruds/ProjectFees/Edit.vue'
import BranchsIndex from '../components/cruds/Branchs/Index.vue'
import BranchsCreate from '../components/cruds/Branchs/Create.vue'
import BranchsShow from '../components/cruds/Branchs/Show.vue'
import BranchTabViewVue from '../components/cruds/Branchs/BranchTabView.vue'
// import BranchsEdit from '../components/cruds/Branchs/Edit.vue'
import FeesMastersIndex from '../components/cruds/FeesMasters/Index.vue'
import FeesMastersCreate from '../components/cruds/FeesMasters/Create.vue'
import FeesMastersShow from '../components/cruds/FeesMasters/Show.vue'
import FeesMastersEdit from '../components/cruds/FeesMasters/Edit.vue'
import FeeAdjustmentIndex from '../components/cruds/FeeAdjustment/Index.vue'
import FeeAdjustmentCreate from '../components/cruds/FeeAdjustment/Create.vue'
import FeeAdjustmentShow from '../components/cruds/FeeAdjustment/Show.vue'
import FeeAdjustmentEdit from '../components/cruds/FeeAdjustment/Edit.vue'
import Dashboard from '../components/cruds/Dashboard/Show.vue'
import TrainerDashboard from '../components/cruds/TrainerDashboard/Show.vue'
import ParentDashboard from '../components/cruds/ParentDashboard/Show.vue'
import AdminDashboard from '../components/cruds/AdminDashboard/Show.vue'
import StatesIndex from '../components/cruds/States/Index.vue'
import StatesCreate from '../components/cruds/States/Create.vue'
import StatesShow from '../components/cruds/States/Show.vue'
import StatesEdit from '../components/cruds/States/Edit.vue'
import MultipleBranchAccessIndex from '../components/cruds/MultipleBranchAccess/Index.vue'
import MultipleBranchAccessCreate from '../components/cruds/MultipleBranchAccess/Create.vue'
import MultipleBranchAccessShow from '../components/cruds/MultipleBranchAccess/Show.vue'
import MultipleBranchAccessEdit from '../components/cruds/MultipleBranchAccess/Edit.vue'
import LeaveAllocationsCreate from '../components/cruds/LeaveAllocations/Create.vue'

import ByPassAssessmentIndex from '../components/cruds/ByPassAssessment/Index.vue'
import ByPassAssessmentCreate from '../components/cruds/ByPassAssessment/Create.vue'
import ByPassAssessmentShow from '../components/cruds/ByPassAssessment/Show.vue'
import ByPassAssessmentEdit from '../components/cruds/ByPassAssessment/Edit.vue'

import HolidaysIndex from '../components/cruds/Holidays/Index.vue'
import HolidaysCreate from '../components/cruds/Holidays/Create.vue'
import HolidaysShow from '../components/cruds/Holidays/Show.vue'
import HolidaysEdit from '../components/cruds/Holidays/Edit.vue'
import SchoolMastersIndex from '../components/cruds/SchoolMasters/Index.vue'
import SchoolMastersCreate from '../components/cruds/SchoolMasters/Create.vue'
import SchoolMastersShow from '../components/cruds/SchoolMasters/Show.vue'
import SchoolMastersEdit from '../components/cruds/SchoolMasters/Edit.vue'
import BatchMastersIndex from '../components/cruds/BatchMasters/Index.vue'
import BatchMastersCreate from '../components/cruds/BatchMasters/Create.vue'
import BatchMastersShow from '../components/cruds/BatchMasters/Show.vue'
import BatchMastersEdit from '../components/cruds/BatchMasters/Edit.vue'
import EmployeeMastersIndex from '../components/cruds/EmployeeMasters/Index.vue'
import EmployeeMastersCreate from '../components/cruds/EmployeeMasters/Create.vue'
import EmployeeMastersShow from '../components/cruds/EmployeeMasters/Show.vue'
import EmployeeMastersEdit from '../components/cruds/EmployeeMasters/Edit.vue'
import CourseTopicMastersIndex from '../components/cruds/CourseTopicMasters/Index.vue'
import CourseTopicMastersCreate from '../components/cruds/CourseTopicMasters/Create.vue'
import CourseTopicMastersShow from '../components/cruds/CourseTopicMasters/Show.vue'
import CourseTopicMastersEdit from '../components/cruds/CourseTopicMasters/Edit.vue'
import CommonMastersIndex from '../components/cruds/CommonMasters/Index.vue'
import CommonMastersCreate from '../components/cruds/CommonMasters/Create.vue'
import CommonMastersShow from '../components/cruds/CommonMasters/Show.vue'
import CommonMastersEdit from '../components/cruds/CommonMasters/Edit.vue'
import ParentsIndex from '../components/cruds/Parents/Index.vue'
import ParentsCreate from '../components/cruds/Parents/Create.vue'
import ParentsShow from '../components/cruds/Parents/Show.vue'
import ParentsEdit from '../components/cruds/Parents/Edit.vue'
import LocationMastersIndex from '../components/cruds/LocationMasters/Index.vue'
import LocationMastersCreate from '../components/cruds/LocationMasters/Create.vue'
import LocationMastersShow from '../components/cruds/LocationMasters/Show.vue'
import LocationMastersEdit from '../components/cruds/LocationMasters/Edit.vue'
import InquiriesIndex from '../components/cruds/Inquiries/Index.vue'
import CommonInquiriesIndex from '../components/cruds/CommonInquiries/Index.vue'
import CommonInquiriesCreate from '../components/cruds/CommonInquiries/Create.vue'
import CommonInquiriesShow from '../components/cruds/CommonInquiries/Show.vue'
import CommonInquiriesEdit from '../components/cruds/CommonInquiries/Edit.vue'
import InquiriesCreate from '../components/cruds/Inquiries/Create.vue'
import InquiriesShow from '../components/cruds/Inquiries/Show.vue'
import InquiriesEdit from '../components/cruds/Inquiries/Edit.vue'
import InquiriesStatus from '../components/cruds/Inquiries/Status.vue'
import StudentProspectStatus from '../components/cruds/StudentProspect/Status.vue'

//student List
import StudentIndex from '../components/cruds/Student/Index.vue'
import StudentCreate from '../components/cruds/Student/Create.vue'
import StudentShow from '../components/cruds/Student/Show.vue'
import StudentEdit from '../components/cruds/Student/Edit.vue'
import StudentStatus from '../components/cruds/Student/Status.vue'

import CourseAssesmentsIndex from '../components/cruds/CourseAssesments/Index.vue'
import CourseAssesmentsCreate from '../components/cruds/CourseAssesments/Create.vue'
import CourseAssesmentsShow from '../components/cruds/CourseAssesments/Show.vue'
import CourseAssesmentsEdit from '../components/cruds/CourseAssesments/Edit.vue'
import BatchAllocationsIndex from '../components/cruds/BatchAllocations/Index.vue'
import BatchAllocationsCreate from '../components/cruds/BatchAllocations/Create.vue'
import BatchAllocationsShow from '../components/cruds/BatchAllocations/Show.vue'
import BatchAllocationsEdit from '../components/cruds/BatchAllocations/Edit.vue'
import LeaveRequestsIndex from '../components/cruds/LeaveRequests/Index.vue'
import LeaveRequestsCreate from '../components/cruds/LeaveRequests/Create.vue'
import LeaveRequestsShow from '../components/cruds/LeaveRequests/Show.vue'
import LeaveRequestsEdit from '../components/cruds/LeaveRequests/Edit.vue'

import LeaveApprovalShow from '../components/cruds/LeaveApproval/Show.vue'
import LeaveApprovalIndex from '../components/cruds/LeaveApproval/Index.vue'
import LeaveApprovalEdit from '../components/cruds/LeaveApproval/Edit.vue'

import RefundIndex from '../components/cruds/Refund/Index.vue'
import RefundCreate from '../components/cruds/Refund/Create.vue'
import RefundShow from '../components/cruds/Refund/Show.vue'
import RefundEdit from '../components/cruds/Refund/Edit.vue'

import ReceiptsIndex from '../components/cruds/Receipts/Index.vue'
import ReceiptsCreate from '../components/cruds/Receipts/Create.vue'
import ReceiptsShow from '../components/cruds/Receipts/Show.vue'
import ReceiptsEdit from '../components/cruds/Receipts/Edit.vue'

import StudentCompletedCourseReportsIndex from '../components/cruds/StudentCompletedCourseReports/Index.vue'
import TrainerStatisticsIndex from '../components/cruds/TrainerStatistics/Index.vue'
import ProgressReportIndex from '../components/cruds/ProgressReport/Index.vue'
import ReportsIndex from '../components/cruds/Reports/Index.vue'
import FeesPaidandBalanceReportIndex from '../components/cruds/FeesPaidandBalanceReport/Index.vue'
import FeeCollectionReportIndex from '../components/cruds/FeeCollectionReport/Index.vue'
import InquiryAdmissionReportIndex from '../components/cruds/InquiryAdmissionReport/Index.vue'
import TrainingManagementReportIndex from '../components/cruds/TrainingManagementReport/Index.vue'
import SmsSettingIndex from '../components/cruds/SmsSetting/Index.vue'

import StudentBatchIndex from '../components/cruds/StudentBatch/Index.vue'
import StudentBatchCreate from '../components/cruds/StudentBatch/Create.vue'
import StudentBatchShow from '../components/cruds/StudentBatch/Show.vue'
import StudentBatchEdit from '../components/cruds/StudentBatch/Edit.vue'

import StudentBatchListingIndex from '../components/cruds/StudentBatchListing/Index.vue'
import TrainerScheduleListingIndex from '../components/cruds/TrainerScheduleListing/Index.vue'
import BatchWiseStudentListingIndex from '../components/cruds/BatchWiseStudentListing/Index.vue'
import DailyDigestTrainingReportIndex from '../components/cruds/DailyDigestTrainingReport/Index.vue'
import EvaluationCriteriaIndex from '../components/cruds/EvaluationCriteria/Index.vue'


import Evaluation from '../components/cruds/Evaluation/Index.vue'
import StudentProspectIndex from '../components/cruds/StudentProspect/Index.vue'
//student assessment
import StudentAssessmentIndex from '../components/cruds/StudentAssessment/Index.vue'
import StudentAssessmentShow from '../components/cruds/StudentAssessment/Show.vue'
import StudentAttendanceIndex from '../components/cruds/StudentAttendance/Index.vue'

import BranchAdmissionFeeIndex from '../components/cruds/BranchAdmissionFee/Index.vue'
import BranchAdmissionFeeCreate from '../components/cruds/BranchAdmissionFee/Create.vue'
import BranchAdmissionFeeShow from '../components/cruds/BranchAdmissionFee/Show.vue'
import BranchAdmissionFeeEdit from '../components/cruds/BranchAdmissionFee/Edit.vue'
import BranchLabIndex from '../components/cruds/BranchLab/Index.vue'
import BranchLabCreate from '../components/cruds/BranchLab/Create.vue'
import BranchLabShow from '../components/cruds/BranchLab/Show.vue'
import BranchLabEdit from '../components/cruds/BranchLab/Edit.vue'

//Coupons Management
import CouponsIndex from '../components/cruds/Coupons/Index.vue'
import CouponsCreate from '../components/cruds/Coupons/Create.vue'
import CouponsShow from '../components/cruds/Coupons/Show.vue'
import CouponsEdit from '../components/cruds/Coupons/Edit.vue'

//GST Management
import GSTIndex from '../components/cruds/GSTManage/Index.vue'
import GSTCreate from '../components/cruds/GSTManage/Create.vue'
import GSTShow from '../components/cruds/GSTManage/Show.vue'
import GSTEdit from '../components/cruds/GSTManage/Edit.vue'

//Incomes & Expenses
import IncomeIndex from '../components/cruds/Income/Index.vue'
import IncomeShow from '../components/cruds/Income/Show.vue'
import IncomeCreate from '../components/cruds/Income/Create.vue'
import IncomeEdit from '../components/cruds/Income/Edit.vue'

import ExpenseIndex from '../components/cruds/Expense/Index.vue'
import ExpenseShow from '../components/cruds/Expense/Show.vue'
import ExpenseCreate from '../components/cruds/Expense/Create.vue'
import ExpenseEdit from '../components/cruds/Expense/Edit.vue'
import IncomeReportIndex from '../components/cruds/IncomeReport/Index.vue'
import ExpenseReportIndex from '../components/cruds/ExpenseReport/Index.vue'

//Email Template Setup
import EmailTemplateIndex from '../components/cruds/EmailTemplate/Index.vue'
import EmailTemplateShow from '../components/cruds/EmailTemplate/Show.vue'
import EmailTemplateCreate from '../components/cruds/EmailTemplate/Create.vue'
import EmailTemplateEdit from '../components/cruds/EmailTemplate/Edit.vue'

//Inventory Item
import InventoryItemIndex from '../components/cruds/InventoryItem/Index.vue'
import InventoryItemShow from '../components/cruds/InventoryItem/Show.vue'
import InventoryItemCreate from '../components/cruds/InventoryItem/Create.vue'
import InventoryItemEdit from '../components/cruds/InventoryItem/Edit.vue'
import InventoryItemPurchaseIndex from '../components/cruds/InventoryItemPurchase/Index.vue'
import InventoryItemPurchaseShow from '../components/cruds/InventoryItemPurchase/Show.vue'
import InventoryItemPurchaseCreate from '../components/cruds/InventoryItemPurchase/Create.vue'
import InventoryItemPurchaseEdit from '../components/cruds/InventoryItemPurchase/Edit.vue'
import InventoryItemSellIndex from '../components/cruds/InventoryItemSell/Index.vue'
import InventoryItemSellShow from '../components/cruds/InventoryItemSell/Show.vue'
import InventoryItemSellCreate from '../components/cruds/InventoryItemSell/Create.vue'
import InventoryItemSellEdit from '../components/cruds/InventoryItemSell/Edit.vue'
import InventoryStockReportIndex from '../components/cruds/InventoryStockReport/Index.vue'
import AdmissionDigestReportIndex from '../components/cruds/AdmissionDigestReport/Index.vue'

//Workshop
import WorkshopIndex from '../components/cruds/Workshop/Index.vue'
import WorkshopShow from '../components/cruds/Workshop/Show.vue'
import WorkshopCreate from '../components/cruds/Workshop/Create.vue'
import WorkshopEdit from '../components/cruds/Workshop/Edit.vue'

//Reminders
import RemindersIndex from '../components/cruds/Reminders/Index.vue'
import RemindersShow from '../components/cruds/Reminders/Show.vue'
import RemindersCreate from '../components/cruds/Reminders/Create.vue'
import RemindersEdit from '../components/cruds/Reminders/Edit.vue'

//course fee adjustments
import CourseFeeAdjustmentIndex from '../components/cruds/CourseFeeAdjustment/Index.vue'
import CourseFeeAdjustmentCreate from '../components/cruds/CourseFeeAdjustment/Create.vue'
import CourseFeeAdjustmentShow from '../components/cruds/CourseFeeAdjustment/Show.vue'
import CourseFeeAdjustmentEdit from '../components/cruds/CourseFeeAdjustment/Edit.vue'

import NotFound from '../components/cruds/Notfound/Index.vue'
import NotPermission from '../components/cruds/Notfound/NotPermission.vue'

import Login from '@/views/auth/Login.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import confirmAdmissionForm from '@/views/auth/confirmAdmissionForm.vue'
//import manualAdmissionForm from '../components/cruds/Student/manualAdmissionForm.vue'
import manualAdmissionForm from '@/views/auth/manualAdmissionForm.vue'
import successApplication from '@/views/auth/successApplication.vue'
import AdmissionFormPDF from '@/views/layout/AdmissionFormPDF.vue'
import reportCardForm from '@/views/auth/reportCardForm.vue'
import ReportCardPDF from '@/views/layout/ReportCardPDF.vue'
import certificate from '@/views/auth/certificate.vue'
import CertificatePDF from '@/views/layout/CertificatePDF.vue'
import store from '../store'
import ResetPassword from '@/views/auth/ResetPassword'

//skill master
import SkillMasterIndex from '../components/cruds/SkillMaster/Index.vue'
import SkillMasterCreate from '../components/cruds/SkillMaster/Create.vue'
import SkillMasterShow from '../components/cruds/SkillMaster/Show.vue'
import SkillMasterEdit from '../components/cruds/SkillMaster/Edit.vue'

//admin-slider
import AdminSliderIndex from '../components/cruds/AdminSlider/Index.vue'
import AdminSliderCreate from '../components/cruds/AdminSlider/Create.vue'
import AdminSliderShow from '../components/cruds/AdminSlider/Show.vue'
import AdminSliderEdit from '../components/cruds/AdminSlider/Edit.vue'

//certificate
import CertificateIndex from '../components/cruds/Certificate/Index.vue'
import CertificateCreate from '../components/cruds/Certificate/Create.vue'
import CertificateShow from '../components/cruds/Certificate/Show.vue'
import CertificateEdit from '../components/cruds/Certificate/Edit.vue'

//School List
// import SchoolsIndex from '../components/cruds/School/Index.vue'
// import SchoolsCreate from '../components/cruds/School/Create.vue'
// import SchoolsShow from '../components/cruds/School/Show.vue'
// import SchoolsEdit from '../components/cruds/School/Edit.vue'

Vue.use(VueRouter)
var HeadTitle = 'Robofun Lab | '
const routes = [
    { path: '/', component: Login, name: 'login', 
        meta: { title: HeadTitle + 'Login'}
    },
    { path: '/forgot-password', component: ForgotPassword, name: 'forgot-password',
        meta: { title: HeadTitle + 'Forgot Password'}
    },
    { path: '/confirm-admission-form/:id', component: confirmAdmissionForm, name: 'confirm-admission-form',
        meta: { title: HeadTitle + 'confirm Admission Form'}
    },
    { path: '/manual-admission-form/:id', component: manualAdmissionForm, name: 'manual-admission-form',
        meta: { title: HeadTitle + 'manual Admission Form' , requiresAuth: true, parentName:'manual-admission-form' }
    },
    { path: '/application-done', component: successApplication, name: 'application-done',
        meta: { title: HeadTitle + 'application done'}
    },
    { path: '/admissionformpdf/:id', component: AdmissionFormPDF, name: 'admissionformpdf',
        meta: { title: HeadTitle + 'admission form pdf'}
    },
    { path: '/report-card-form/:id', component: reportCardForm, name: 'report-card-form',
        meta: { title: HeadTitle + 'report card form' }
    },
    { path: '/certificate-form/:id', component: certificate, name: 'certificate-form',
        meta: { title: HeadTitle + 'Certificate' }
    },
    { path: '/reportcardpdf/:id', component: ReportCardPDF, name: 'reportcardpdf',
        meta: { title: HeadTitle + 'report card pdf' }
    },
    { path: '/certificatepdf/:id', component: CertificatePDF, name: 'certificatepdf',
        meta: { title: HeadTitle + 'Certificate PDF' }
    },
    { path: '/inner-confirm-admission-form/:id', component: confirmAdmissionForm, name: 'inner-confirm-admission-form',
        meta: { title: HeadTitle + 'confirm Admission Form'}
    },
    
    { path: '/reset-password/:token', component: ResetPassword, name: 'reset-password',
    meta: { title: HeadTitle + 'Reset Password'}
    },
    { path: '/change-password', component: ChangePassword, name: 'auth.change_password',
        meta: { title: HeadTitle + 'Change Password', requiresAuth: true}
    },
    { path: '/home', component: Dashboard, name: 'dashboard.show' , alias:'home',
        meta: { title: HeadTitle + 'Dashboard', requiresAuth: true, hasPermission: ["home"], parentName:'home' }
    },
    { path: '/permissions', component: PermissionsIndex, name: 'permissions.index' ,
        meta: { title: HeadTitle + 'Permissions'}
    },
    { path: '/permissions/create', component: PermissionsCreate, name: 'permissions.create' ,
        meta: { title: HeadTitle + 'Permission Create'}
    },
    { path: '/permissions/:id/show', component: PermissionsShow, name: 'permissions.show' ,
        meta: { title: HeadTitle + 'Permission View'}
    },
    { path: '/permissions/:id/edit', component: PermissionsEdit, name: 'permissions.edit' ,
        meta: { title: HeadTitle + 'Permission Edit'}
    },
    { path: '/roles', component: RolesIndex, name: 'roles.index' ,
        meta: { title: HeadTitle + 'Roles', hasPermission: [ "role_access" ], requiresAuth: true, parentName: 'roles'},        
    },
    { path: '/roles/create', component: RolesCreate, name: 'roles.create' ,
        meta: { title: HeadTitle + 'Role Create', requiresAuth: true, parentName: 'roles', hasPermission: ["role_access", "role_create"]}
    },
    { path: '/roles/:id/show', component: RolesShow, name: 'roles.show' ,
        meta: { title: HeadTitle + 'Role View', requiresAuth: true, parentName: 'roles', hasPermission: ["role_access", "role_view"]}
    },
    { path: '/roles/:id/edit', component: RolesEdit, name: 'roles.edit' ,
        meta: { title: HeadTitle + 'Role Edit', requiresAuth: true, parentName: 'roles', hasPermission: ["role_access", "role_edit"]}
    },
    { path: '/users', component: UsersIndex, name: 'users.index',
        meta: { title: HeadTitle + 'Users', requiresAuth: true, parentName: 'users', hasPermission: ["user_access"]},
    },
    // { path: '/users/create', component: UsersCreate, name: 'users.create',
    //     meta: { title: HeadTitle + 'User Create', requiresAuth: true}
    // },
    { path: '/users/:id/show', component: UsersShow, name: 'users.show',
        meta: { title: HeadTitle + 'User View', requiresAuth: true, parentName: 'users', hasPermission: ["user_access"]}
    },
    // { path: '/users/:id/edit', component: UsersEdit, name: 'users.edit' , 
    //     meta: { title: HeadTitle + 'User Edit', requiresAuth: true, hasPermission: ["user_access"]}
    // },
    { path: '/franchises', component: FranchisesIndex, name: 'franchises.index' ,
        meta: { title: HeadTitle + 'Franchises' , requiresAuth: true, parentName: 'franchises',hasPermission: ["franchise_access"]}
    },
    { path: '/franchises/create', component: FranchisesCreate, name: 'franchises.create' ,
        meta: { title: HeadTitle + 'Franchise Create' , requiresAuth: true, parentName: 'franchises', hasPermission: ["franchise_access", "franchise_create"]}
    },
    { path: '/franchises/:id/show', component: FranchisesShow, name: 'franchises.show' ,
        meta: { title: HeadTitle + 'Franchise View' , requiresAuth: true, parentName: 'franchises', hasPermission: ["franchise_access"]}
    },
    { path: '/franchises/:id/edit', component: FranchisesEdit, name: 'franchises.edit' ,
        meta: { title: HeadTitle + 'Franchise Edit' , requiresAuth: true, parentName: 'franchises', hasPermission: ["franchise_access", "franchise_edit"]}
    },
    { path: '/multiple-branch-access', component: MultipleBranchAccessIndex, name: 'multiple_branch_access.index', 
        meta: { title: HeadTitle + 'Multiple Branch Access Management', requiresAuth: true, parentName: 'multiple-branch-access', hasPermission: ["user_branchwise_role_access"]}
    },
    { path: '/multiple-branch-access/create', component: MultipleBranchAccessCreate, name: 'multiple_branch_access.create' , 
        meta: { title: HeadTitle + 'Multiple Branch Access Create', requiresAuth: true, parentName: 'multiple-branch-access',hasPermission: ["user_branchwise_role_access", "user_branchwise_role_create"]}
    },
    { path: '/multiple-branch-access/:id/show', component: MultipleBranchAccessShow, name: 'multiple_branch_access.show' , 
        meta: { title: HeadTitle + 'Multiple Branch Access View', requiresAuth: true, parentName: 'multiple-branch-access',hasPermission: ["user_branchwise_role_access", "user_branchwise_role_view"]}
    },
    { path: '/multiple-branch-access/:id/edit', component: MultipleBranchAccessEdit, name: 'multiple_branch_access.edit' , 
        meta: { title: HeadTitle + 'Multiple Branch Access Edit', requiresAuth: true, parentName: 'multiple-branch-access',hasPermission: ["user_branchwise_role_access", "user_branchwise_role_edit"]}
    },
    { path: '/branchs', component: BranchsIndex, name: 'branchs.index',
        meta: { title: HeadTitle + 'Branches', requiresAuth: true, parentName: 'branchs', hasPermission: ["branch_access"]}
    },
    { path: '/branchs/create', component: BranchsCreate, name: 'branchs.create',
        meta: { title: HeadTitle + 'Branch Create', requiresAuth: true, parentName: 'branchs', hasPermission: ["branch_access", "branch_create"]}
    },
    { path: '/branchs/:id/show', component: BranchsShow, name: 'branchs.show',
        meta: { title: HeadTitle + 'Branch View', requiresAuth: true, parentName: 'branchs', hasPermission: ["branch_access", "branch_view"]}
    },
    { path: '/branchs/:id/edit', component: BranchTabViewVue, name: 'branchs.edit',
        meta: { title: HeadTitle + 'Branch Edit', requiresAuth: true, parentName: 'branchs', hasPermission: ["branch_access", "branch_edit"]}
    },
    { path: '/branch-admission-fee', component: BranchAdmissionFeeIndex, name: 'branch_admission_fee.index' ,
        meta: { title: HeadTitle + 'Branch Admission Fee', requiresAuth: true, parentName: 'branch_admission_fee',hasPermission: ["branch_admission_fee_access"]}
    },
    { path: '/branch-admission-fee/create', component: BranchAdmissionFeeCreate, name: 'branch_admission_fee.create' ,
        meta: { title: HeadTitle + 'Create Branch Admission Fee', requiresAuth: true, parentName: 'branch-admission-fee',hasPermission: ["branch_admission_fee_access","branch_admission_fee_create"]}
    },
    { path: '/branch-admission-fee/:id/show', component: BranchAdmissionFeeShow, name: 'branch_admission_fee.show' ,
        meta: { title: HeadTitle + 'Branch Admission Fee View', requiresAuth: true, parentName: 'branch-admission-fee',hasPermission: ["branch_admission_fee_access","branch_admission_fee_view"]}
    },
    { path: '/branch-admission-fee/:id/edit', component: BranchAdmissionFeeEdit, name: 'branch_admission_fee.edit' ,
        meta: { title: HeadTitle + 'Branch Admission Fee Edit', requiresAuth: true, parentName: 'branch-admission-fee',hasPermission: ["branch_admission_fee_access","branch_admission_fee_edit"]}
    }, 
    { path: '/branch-lab', component: BranchLabIndex, name: 'branch_lab.index' ,
        meta: { title: HeadTitle + 'Branch Lab', requiresAuth: true, parentName:'branch-lab', hasPermission: ["branch_lab_access"]}
    },
    { path: '/branch-lab/create', component: BranchLabCreate, name: 'branch_lab.create' ,
        meta: { title: HeadTitle + 'Create Branch Lab', requiresAuth: true, parentName:'branch-lab' ,hasPermission: ["branch_lab_access", "branch_lab_create"]}
    },
    { path: '/branch-lab/:id/show', component: BranchLabShow, name: 'branch_lab.show' ,
        meta: { title: HeadTitle + 'Branch Lab View', requiresAuth: true, parentName:'branch-lab',hasPermission: ["branch_lab_access", "branch_lab_view"]}
    },
    { path: '/branch-lab/:id/edit', component: BranchLabEdit, name: 'branch_lab.edit' ,
        meta: { title: HeadTitle + 'Branch Lab Edit', requiresAuth: true, parentName:'branch-lab',hasPermission: ["branch_lab_access","branch_lab_edit"]}
    },
    { path: '/employee-masters', component: EmployeeMastersIndex, name: 'employee_masters.index', 
        meta: { title: HeadTitle + 'Employees', requiresAuth: true, parentName:'employee-masters',hasPermission: ["employee_master_access"]}
    },
    { path: '/employee-masters/create', component: EmployeeMastersCreate, name: 'employee_masters.create' ,
        meta: { title: HeadTitle + 'Employee Create', requiresAuth: true, parentName:'employee-masters',hasPermission: ["employee_master_access", "employee_master_create"]}
    },
    { path: '/employee-masters/:id/show', component: EmployeeMastersShow, name: 'employee_masters.show' ,
        meta: { title: HeadTitle + 'Employee View', requiresAuth: true, parentName:'employee-masters',hasPermission: ["employee_master_access", "employee_master_view"]}
    },
    { path: '/employee-masters/:id/edit', component: EmployeeMastersEdit, name: 'employee_masters.edit' ,
        meta: { title: HeadTitle + 'Employee Edit', requiresAuth: true, parentName:'employee-masters',hasPermission: ["employee_master_access", "employee_master_edit"]}
    },
    { path: '/student-prospect/:student_id/:course_id/:contact_name/:contact_no/:student_name/status', component: StudentProspectStatus, name: 'student_prospect.status' ,
        meta: { title: HeadTitle + 'Student Prospect Status', requiresAuth: true, parentName:'student-prospect',hasPermission: ["student_prospect_access", "student_prospect_edit"]}
    },   
    { path: '/student-prospect', component: StudentProspectIndex, name: 'student_prospect.index',
        meta: { title: HeadTitle + 'Student Prospect', requiresAuth: true, parentName:'student-prospect',hasPermission: ["student_prospect_access","student_prospect_view"]},
        props: true
    }, 
    { path: '/project-fees', component: ProjectFeesIndex, name: 'project_fees.index' ,
        meta: { title: HeadTitle + 'Project Fees', requiresAuth: true, parentName:'project-fees', hasPermission: ["project_fees_access"]},
    },
    { path: '/project-fees/create', component: ProjectFeesCreate, name: 'project_fees.create' ,
        meta: { title: HeadTitle + 'Project Fees Create', requiresAuth: true, parentName:'project-fees', hasPermission: ["project_fees_access", "project_fees_create"]}
    },
    { path: '/project-fees/show/:project_fee_id', component: ProjectFeesShow, name: 'project_fees.show' ,
        meta: { title: HeadTitle + 'Project Fees View', requiresAuth: true, parentName:'project-fees', hasPermission: ["project_fees_access", "project_fees_view"]}
    },
    { path: '/project-fees/edit/:project_fee_id', component: ProjectFeesEdit, name: 'project_fees.edit' ,
        meta: { title: HeadTitle + 'Project Fees Edit', requiresAuth: true, parentName:'project-fees', hasPermission: ["project_fees_access", "project_fees_edit"]}
    },

    { path: '/course-fee-adjustments', component: CourseFeeAdjustmentIndex, name: 'course_fee_adjustment.index' ,
    meta: { title: HeadTitle + 'Course Fee Adjustments', requiresAuth: true, parentName:'course-fee-adjustments', hasPermission: ["course_fee_adjustment_access"]},
    },  
    { path: '/course-fee-adjustments/create', component: CourseFeeAdjustmentCreate, name: 'course_fee_adjustment.create' ,
        meta: { title: HeadTitle + 'Course Fee Adjustments Create', requiresAuth: true, parentName:'course-fee-adjustments', hasPermission: ["course_fee_adjustment_access", "course_fee_adjustment_create"]}
    },
    { path: '/course-fee-adjustments/show/:branch_id/:course_id', component: CourseFeeAdjustmentShow, name: 'course_fee_adjustment.show' ,
        meta: { title: HeadTitle + 'Course Fee Adjustments View', requiresAuth: true, parentName:'course-fee-adjustments', hasPermission: ["course_fee_adjustment_access", "course_fee_adjustment_view"]}
    },
    { path: '/course-fee-adjustments/edit/:branch_id/:course_id', component: CourseFeeAdjustmentEdit, name: 'course_fee_adjustment.edit' ,
        meta: { title: HeadTitle + 'Course Fee Adjustments Edit', requiresAuth: true, parentName:'course-fee-adjustments', hasPermission: ["course_fee_adjustment_access", "course_fee_adjustment_edit"]}
    },



    { path: '/batch-masters', component: BatchMastersIndex, name: 'batch_masters.index' ,
        meta: { title: HeadTitle + 'Batches', requiresAuth: true,parentName:'batch-masters', hasPermission: ["batch_master_access"]}
    },
    { path: '/batch-masters/create', component: BatchMastersCreate, name: 'batch_masters.create' ,
        meta: { title: HeadTitle + 'Batch create', requiresAuth: true,parentName:'batch-masters', hasPermission: ["batch_master_access","batch_master_create"]}
    },
    { path: '/batch-masters/:id/show', component: BatchMastersShow, name: 'batch_masters.show' ,
        meta: { title: HeadTitle + 'Batch View', requiresAuth: true,parentName:'batch-masters', hasPermission: ["batch_master_access","batch_master_view"]}
    },
    { path: '/batch-masters/:id/edit', component: BatchMastersEdit, name: 'batch_masters.edit' ,
        meta: { title: HeadTitle + 'Batch Edit', requiresAuth: true,parentName:'batch-masters', hasPermission: ["batch_master_access","batch_master_edit"]}
    },
   
    

    { path: '/leave-report', component: LeaveReportIndex, name: 'leave_report.index' ,
        meta: { title: HeadTitle + 'Leave Report'}
    },    
    { path: '/sms-on-admission-confirmation', component: SmsOnAdmissionConfirmationIndex, name: 'sms_on_admission_confirmation.index' ,
        meta: { title: HeadTitle + 'SMS On Admission Confirmation'}
    },
    { path: '/sms-to-groups-staff-student', component: SmsOnAttendanceIndex, name: 'sms_on_attendance.index' ,
        meta: { title: HeadTitle + 'SMS To Groups/Staff/Student'}
    },
    { path: '/group/create', component: SmsOnDynamicTemplateCreate, name: 'sms_on_dynamic_template.create' ,
        meta: { title: HeadTitle + 'Create Group'}
    },
    { path: '/group/:id/show', component: SmsOnDynamicTemplateShow, name: 'sms_on_dynamic_template.show' ,
        meta: { title: HeadTitle + 'Show Group'}
    },
    { path: '/group/:id/edit', component: SmsOnDynamicTemplateEdit, name: 'sms_on_dynamic_template.edit' ,
        meta: { title: HeadTitle + 'Edit Group'}
    },
    { path: '/group', component: SmsOnDynamicTemplateIndex, name: 'sms_on_dynamic_template.index' ,
        meta: { title: HeadTitle + 'Group'}
    },         
    { path: '/sms-on-fee-reminder-if-pending', component: SmsOnFeeReminderIfPendingIndex, name: 'sms_on_fee_reminder_if_pending.index' ,
        meta: { title: HeadTitle + 'SMS On Fee Reminder If Pending'}
    },    
    { path: '/cancel-admission', component: CancelAdmissionIndex, name: 'cancel_admission.index' ,
        meta: { title: HeadTitle + 'Cancelled Admission'}
    },
    { path: '/cancel-admission/create', component: CancelAdmissionCreate, name: 'cancel_admission.create' ,
        meta: { title: HeadTitle + 'Cancel Admission'}
    },
    { path: '/cancel-admission/:id/show', component: CancelAdmissionShow, name: 'cancel_admission.show' ,
        meta: { title: HeadTitle + 'View Cancelled Admission'}
    },
    { path: '/cancel-admission/:id/edit', component: CancelAdmissionEdit, name: 'cancel_admission.edit' ,
        meta: { title: HeadTitle + 'Edit Cancelled Admission'}
    },
    
    { path: '/courses', component: CoursesIndex, name: 'courses.index' ,
        meta: { title: HeadTitle + 'Courses',requiresAuth: true, parentName:'courses',hasPermission: ["course_access"]}
    },
    { path: '/courses/create', component: CoursesCreate, name: 'courses.create' ,
        meta: { title: HeadTitle + 'Course Create',requiresAuth: true, parentName:'courses',hasPermission: ["course_access","course_create"]}
    },
    { path: '/courses/:id/show', component: CoursesShow, name: 'courses.show' ,
        meta: { title: HeadTitle + 'Course View',requiresAuth: true, parentName:'courses',hasPermission: ["course_access","course_view"]}
    },
    { path: '/courses/:id/edit', component: CoursesEdit, name: 'courses.edit' ,
        meta: { title: HeadTitle + 'Course Edit',requiresAuth: true, parentName:'courses',hasPermission: ["course_access","course_edit"]}
    },

    { path: '/projects', component: ProjectIndex, name: 'projects.index' ,
        meta: { title: HeadTitle + 'Projects',requiresAuth: true, parentName:'projects',hasPermission: ["project_access"]}
    },
    { path: '/projects/create', component: ProjectCreate, name: 'projects.create' ,
        meta: { title: HeadTitle + 'Project Create',requiresAuth: true, parentName:'projects',hasPermission: ["project_access","project_create"]}
    },
    { path: '/projects/:id/show', component: ProjectShow, name: 'projects.show' ,
        meta: { title: HeadTitle + 'Project View',requiresAuth: true, parentName:'projects',hasPermission: ["project_access","project_view"]}
    },
    { path: '/projects/:id/edit', component: ProjectEdit, name: 'projects.edit' ,
        meta: { title: HeadTitle + 'Project Edit',requiresAuth: true, parentName:'projects',hasPermission: ["project_access","project_edit"]}
    },
    
    { path: '/modules', component: ModuleIndex, name: 'modules.index' ,
        meta: { title: HeadTitle + 'Modules',requiresAuth: true, parentName:'modules',hasPermission: ["module_access"]}
    },
    { path: '/modules/create', component: ModuleCreate, name: 'modules.create' ,
        meta: { title: HeadTitle + 'Module Create',requiresAuth: true, parentName:'modules',hasPermission: ["module_access","module_create"]}
    },
    { path: '/modules/:id/show', component: ModuleShow, name: 'modules.show' ,
        meta: { title: HeadTitle + 'Module View',requiresAuth: true, parentName:'modules',hasPermission: ["module_access","module_view"]}
    },
    { path: '/modules/:id/edit', component: ModuleEdit, name: 'modules.edit' ,
        meta: { title: HeadTitle + 'Module Edit',requiresAuth: true, parentName:'modules',hasPermission: ["module_access","module_edit"]}
    },
    
    { path: '/fees-masters', component: FeesMastersIndex, name: 'fees_masters.index' ,
        meta: { title: HeadTitle + 'Course Fee'}
    },
    { path: '/fees-masters/create', component: FeesMastersCreate, name: 'fees_masters.create' ,
        meta: { title: HeadTitle + 'Course Fee Create'}
    },
    // { path: '/fees-masters/:id/show', component: FeesMastersShow, name: 'fees_masters.show' ,
    //     meta: { title: HeadTitle + 'Course Fee View'}
    // },
    { path: '/fees-masters-show/:id/:from_date/:fees_branch_id/show', component: FeesMastersShow, name: 'fees_masters.show' ,
        meta: { title: HeadTitle + 'Course Fee View'}
    },
    { path: '/fees-masters-edit/:id/:from_date/:fees_branch_id/edit', component: FeesMastersEdit, name: 'fees_masters.edit' ,
        meta: { title: HeadTitle + 'Course Fee Edit'}
    },
    { path: '/fees-adjustment', component: FeeAdjustmentIndex, name: 'fees_adjustment.index' ,
        meta: { title: HeadTitle + 'Fee Adjustment'}
    },
    { path: '/fees-adjustment/create', component: FeeAdjustmentCreate, name: 'fees_adjustment.create' ,
        meta: { title: HeadTitle + ' Fee Adjustment Create'}
    },
    { path: '/fees-adjustment/:id/show', component: FeeAdjustmentShow, name: 'fees_adjustment.show' ,
        meta: { title: HeadTitle + 'Fee Adjustment View'}
    },
    { path: '/fees-adjustment/:id/edit', component: FeeAdjustmentEdit, name: 'fees_adjustment.edit' ,
        meta: { title: HeadTitle + 'Fee Adjustment Edit'}
    },
    { path: '/trainer-home', component: TrainerDashboard, name: 'trainer_dashboard.show' ,
        meta: { title: HeadTitle + 'Dashboard',requiresAuth: true, hasPermission: ["home"], parentName: 'trainer-home'}
    }, 
    { path: '/parent-home', component: ParentDashboard, name: 'parent_dashboard.show' ,
        meta: { title: HeadTitle + 'Dashboard', parentName: 'parent-home'}
    },        
    { path: '/super-admin-home', component: AdminDashboard, name: 'admin_dashboard.show' ,
        meta: { title: HeadTitle + 'Dashboard',requiresAuth: true, hasPermission: ["home"], parentName:'super-admin-home'}
    },        
    { path: '/states', component: StatesIndex, name: 'states.index', 
        meta: { title: HeadTitle + 'States', requiresAuth: true, parentName:'states', hasPermission: ["state_access"]}
    },
    { path: '/states/create', component: StatesCreate, name: 'states.create' ,
        meta: { title: HeadTitle + 'State Create', requiresAuth: true, parentName:'states', hasPermission: ["state_access","state_create"]}
    },
    { path: '/states/:id/show', component: StatesShow, name: 'states.show' ,
        meta: { title: HeadTitle + 'State View', requiresAuth: true, parentName:'states', hasPermission: ["state_access","state_view"]}
    },
    { path: '/states/:id/edit', component: StatesEdit, name: 'states.edit' ,
        meta: { title: HeadTitle + 'State Edit', requiresAuth: true, parentName:'states', hasPermission: ["state_access","state_edit"]}
    },
    
    { path: '/leave-allocations/create', component: LeaveAllocationsCreate, name: 'leave_allocations.create' ,
        meta: { title: HeadTitle + 'Leave Allocation'}
    },
    { path: '/bypass-assessment', component: ByPassAssessmentIndex, name: 'bypass_assessment.index' ,
        meta: { title: HeadTitle + 'Bypass Assessment'}
    },
    { path: '/bypass-assessment/create', component: ByPassAssessmentCreate, name: 'bypass_assessment.create' ,
        meta: { title: HeadTitle + 'Bypass Assessment Create'}
    },
    { path: '/bypass-assessment/:id/show', component: ByPassAssessmentShow, name: 'bypass_assessment.show' ,
        meta: { title: HeadTitle + 'Bypass Assessment View'}
    },
    { path: '/bypass-assessment/:id/edit', component: ByPassAssessmentEdit, name: 'bypass_assessment.edit',
        meta: { title: HeadTitle + 'Bypass Assessment Edit'}
    },
    { path: '/holidays', component: HolidaysIndex, name: 'holidays.index' ,
        meta: { title: HeadTitle + 'Holidays',requiresAuth: true, parentName:'holidays', hasPermission: ["holiday_access"]}
    },
    { path: '/holidays/create', component: HolidaysCreate, name: 'holidays.create' ,
        meta: { title: HeadTitle + 'Holiday Create',requiresAuth: true, parentName:'holidays', hasPermission: ["holiday_access","holiday_create"]}
    },
    { path: '/holidays/:id/show', component: HolidaysShow, name: 'holidays.show' ,
        meta: { title: HeadTitle + 'Holiday View',requiresAuth: true, parentName:'holidays',hasPermission: ["holiday_access","holiday_view"]}
    },
    // { path: '/holidays/:id/edit', component: HolidaysEdit, name: 'holidays.edit',
    //     meta: { title: HeadTitle + 'Holiday Edit',requiresAuth: true, parentName:'holidays',hasPermission: ["holiday_access","holiday_edit"]}
    // },

    { path: '/school-masters', component: SchoolMastersIndex, name: 'school_masters.index' ,
        meta: { title: HeadTitle + 'Schools', requiresAuth: true, parentName:'school-master', hasPermission: ["school_master_access"]}
    },
    { path: '/school-masters/create', component: SchoolMastersCreate, name: 'school_masters.create' ,
        meta: { title: HeadTitle + 'School Create', requiresAuth: true, parentName:'school-master', hasPermission: ["school_master_access","school_master_create"]}
    },
    { path: '/school-masters/:id/show', component: SchoolMastersShow, name: 'school_masters.show' ,
        meta: { title: HeadTitle + 'School View', requiresAuth: true, parentName:'school-master', hasPermission: ["school_master_access","school_master_view"]}
    },
    { path: '/school-masters/:id/edit', component: SchoolMastersEdit, name: 'school_masters.edit' ,
        meta: { title: HeadTitle + 'School Edit', requiresAuth: true, parentName:'school-master', hasPermission: ["school_master_access","school_master_edit"]}
    },
    { path: '/skill-master', component: SkillMasterIndex, name: 'skill-master.index' ,
        meta: { title: HeadTitle + 'skill-master',requiresAuth: true, parentName:'skill-master', hasPermission: ["skill_master_access"]}
    },
    { path: '/skill-master/create', component: SkillMasterCreate, name: 'skill-master.create' ,
        meta: { title: HeadTitle + 'Skill Create', requiresAuth: true, parentName:'skill-master', hasPermission: ["skill_master_access", "skill_master_create"]}
    },
    { path: '/skill-master/:id/show', component: SkillMasterShow, name: 'skill-master.show' ,
        meta: { title: HeadTitle + 'Skill View', requiresAuth: true, parentName:'skill-master', hasPermission: ["skill_master_access", "skill_master_view"]}
    },
    { path: '/skill-master/:id/edit', component: SkillMasterEdit, name: 'skill-master.edit' ,
        meta: { title: HeadTitle + 'School Edit', requiresAuth: true, parentName:'skill-master', hasPermission: ["skill_master_access", "skill_master_edit"]}
    },

    // { path: '/schools', component: SchoolsIndex, name: 'schools.index' ,
    //     meta: { title: HeadTitle + 'Schools'}
    // },
    // { path: '/schools/create', component: SchoolsCreate, name: 'schools.create' ,
    //     meta: { title: HeadTitle + 'School Create'}
    // },
    // { path: '/schools/:id/show', component: SchoolsShow, name: 'schools.show' ,
    //     meta: { title: HeadTitle + 'School View'}
    // },
    // { path: '/schools/:id/edit', component: SchoolsEdit, name: 'schools.edit' ,
    //     meta: { title: HeadTitle + 'School Edit'}
    // },

    { path: '/course-topic-masters', component: CourseTopicMastersIndex, name: 'course_topic_masters.index' ,
        meta: { title: HeadTitle + 'Course Topics'}
    },
    { path: '/course-topic-masters/create', component: CourseTopicMastersCreate, name: 'course_topic_masters.create' ,
        meta: { title: HeadTitle + 'Course Topic Create'}
    },
    { path: '/course-topic-masters/:id/show', component: CourseTopicMastersShow, name: 'course_topic_masters.show' ,
        meta: { title: HeadTitle + 'Course Topic View'}
    },
    { path: '/course-topic-masters/:id/edit', component: CourseTopicMastersEdit, name: 'course_topic_masters.edit',
        meta: { title: HeadTitle + 'Course Topic Edit'}
    }, 
    { path: '/parents', component: ParentsIndex, name: 'parents.index' ,
        meta: { title: HeadTitle + 'Parents', requiresAuth: true, parentName:'parents', hasPermission: ["parent_access"]}
    },
    { path: '/parents/create', component: ParentsCreate, name: 'parents.create' ,
        meta: { title: HeadTitle + 'Parent Create',requiresAuth: true, parentName:'parents', hasPermission: ["parent_access","parent_create"]}
    },
    { path: '/parents/:id/show', component: ParentsShow, name: 'parents.show' ,
        meta: { title: HeadTitle + 'Parent View',requiresAuth: true, parentName:'parents', hasPermission: ["parent_access","parent_view"]}
    },
    { path: '/parents/:id/edit', component: ParentsEdit, name: 'parents.edit',
        meta: { title: HeadTitle + 'Parent Edit',requiresAuth: true, parentName:'parents', hasPermission: ["parent_access","parent_edit"]}
    },  
    { path: '/location-masters', component: LocationMastersIndex, name: 'location_masters.index' ,
        meta: { title: HeadTitle + 'Locations'}
    },
    { path: '/location-masters/create', component: LocationMastersCreate, name: 'location_masters.create' ,
        meta: { title: HeadTitle + 'Location Create'}
    },
    { path: '/location-masters/:id/show', component: LocationMastersShow, name: 'location_masters.show' ,
        meta: { title: HeadTitle + 'Location View'}
    },
    { path: '/location-masters/:id/edit', component: LocationMastersEdit, name: 'location_masters.edit' ,
        meta: { title: HeadTitle + 'Location Edit'}
    },
    { path: '/common-masters', component: CommonMastersIndex, name: 'common_masters.index',
        meta: { title: HeadTitle + 'Common Master', requiresAuth: true,parentName:'common-masters', hasPermission: ["common_master_access"]}
    },
    { path: '/common-masters/create', component: CommonMastersCreate, name: 'common_masters.create' ,
        meta: { title: HeadTitle + 'Common Master Create', requiresAuth: true,parentName:'common-masters', hasPermission: ["common_master_access", "common_master_create"]}
    },
    { path: '/common-masters/:id/show', component: CommonMastersShow, name: 'common_masters.show', 
        meta: { title: HeadTitle + 'Common Master View', requiresAuth: true,parentName:'common-masters', hasPermission: ["common_master_access","common_master_view"]}
    },
    { path: '/common-masters/:id/edit', component: CommonMastersEdit, name: 'common_masters.edit',
        meta: { title: HeadTitle + 'Common Master Edit', requiresAuth: true,parentName:'common-masters', hasPermission: ["common_master_access", "common_master_edit"]}
    },    
    { path: '/course-assesments', component: CourseAssesmentsIndex, name: 'course_assesments.index', 
        meta: { title: HeadTitle + 'Course Assesments'}
    },
    { path: '/course-assesments/create', component: CourseAssesmentsCreate, name: 'course_assesments.create' ,
        meta: { title: HeadTitle + 'Course Assesment Create'}
    },
    { path: '/course-assesments/:id/show', component: CourseAssesmentsShow, name: 'course_assesments.show' ,
        meta: { title: HeadTitle + 'Course Assesment View'}
    },
    { path: '/course-assesments/:id/edit', component: CourseAssesmentsEdit, name: 'course_assesments.edit',
        meta: { title: HeadTitle + 'Course Assesment Edit'}
    },        
    { path: '/inquiries', component: InquiriesIndex, name: 'inquiries.index' ,
        meta: { title: HeadTitle + 'Inquiries',requiresAuth: true,parentName:'inquiries', hasPermission: ["inquiry_access"]},
        props: true 
    },
    { path: '/inquiries/create', component: InquiriesCreate, name: 'inquiries.create' ,
        meta: { title: HeadTitle + 'Inquiry Create',requiresAuth: true,parentName:'inquiries', hasPermission: ["inquiry_access","inquiry_create"]}
    },
    { path: '/inquiries/:id/show', component: InquiriesShow, name: 'inquiries.show' ,
        meta: { title: HeadTitle + 'Inquiry View',requiresAuth: true,parentName:'inquiries', hasPermission: ["inquiry_access","inquiry_view"]}
    },
    { path: '/inquiries/:id/edit', component: InquiriesEdit, name: 'inquiries.edit' ,
        meta: { title: HeadTitle + 'Inquiry Edit',requiresAuth: true,parentName:'inquiries', hasPermission: ["inquiry_access","inquiry_edit"]}
    },
    { path: '/inquiries/:id/status', component: InquiriesStatus, name: 'inquiries.status' ,
        meta: { title: HeadTitle + 'Inquiry Status',requiresAuth: true,parentName:'inquiries', hasPermission: ["inquiry_access","inquiry_edit"]}
    },
    { path: '/student', component: StudentIndex, name: 'student.index' ,
        meta: { title: HeadTitle + 'Students', requiresAuth: true, parentName:'student', hasPermission: ["student_access"]}
    },
    { path: '/student/create', component: StudentCreate, name: 'student.create' ,
        meta: { title: HeadTitle + 'Student Create', requiresAuth: true,parentName:'student', hasPermission: ["student_access","student_create"]}
    },
    { path: '/student/:id/show', component: StudentShow, name: 'student.show' ,
        meta: { title: HeadTitle + 'Student View', requiresAuth: true,parentName:'student', hasPermission: ["student_access","student_view"]}
    },
    { path: '/student/:id/edit', component: StudentEdit, name: 'student.edit' ,
        meta: { title: HeadTitle + 'Student Edit', requiresAuth: true,parentName:'student', hasPermission: ["student_access","student_edit"]}
    },
    { path: '/coupons', component: CouponsIndex, name: 'coupons.index' ,
        meta: { title: HeadTitle + 'Coupons', requiresAuth: true,parentName:'coupons', hasPermission: ["coupons_access"]}, 
    },
    { path: '/coupons/create', component: CouponsCreate, name: 'coupons.create' ,
        meta: { title: HeadTitle + 'Coupons Create', requiresAuth: true,parentName:'coupons', hasPermission: ["coupons_access","coupons_create"]},
        props: true
    },
    { path: '/coupons/:id/show', component: CouponsShow, name: 'coupons.show' ,
        meta: { title: HeadTitle + 'Coupons View', requiresAuth: true,parentName:'coupons', hasPermission: ["coupons_access","coupons_view"]}
    },
    { path: '/coupons/:id/edit', component: CouponsEdit, name: 'coupons.edit' ,
        meta: { title: HeadTitle + 'Coupons Edit', requiresAuth: true,parentName:'coupons',hasPermission: ["coupons_access","coupons_edit"]}
    },
    { path: '/gst', component: GSTIndex, name: 'gst.index' ,
        meta: { title: HeadTitle + 'GST', requiresAuth: true,parentName:'gstmanage', hasPermission: ["gst_access"]}, 
    },
    { path: '/gst/create', component: GSTCreate, name: 'gst.create' ,
        meta: { title: HeadTitle + 'GST Create', requiresAuth: true,parentName:'gstmanage', hasPermission: ["gst_access","gst_create"]},
        props: true
    },
    { path: '/gst/:id/show', component: GSTShow, name: 'gst.show' ,
        meta: { title: HeadTitle + 'GST View', requiresAuth: true,parentName:'gstmanage', hasPermission: ["gst_access","gst_view"]}
    },
    { path: '/gst/:id/edit', component: GSTEdit, name: 'gst.edit' ,
        meta: { title: HeadTitle + 'GST Edit', requiresAuth: true,parentName:'gstmanage',hasPermission: ["gst_access","gst_edit"]}
    },
    { path: '/student/:id/status', component: StudentStatus, name: 'student.status' ,
        meta: { title: HeadTitle + 'Student Status', requiresAuth: true,parentName:'student'}
    },
    { path: '/batch-allocations', component: BatchAllocationsIndex, name: 'batch_allocations.index' ,
        meta: { title: HeadTitle + 'Batch Allocations'}
    },
    { path: '/batch-allocations/create', component: BatchAllocationsCreate, name: 'batch_allocations.create' ,
        meta: { title: HeadTitle + 'Batch Allocation Create'}
    },
    { path: '/batch-allocations/:id/show', component: BatchAllocationsShow, name: 'batch_allocations.show' ,
        meta: { title: HeadTitle + 'Batch Allocation View'}
    },
    { path: '/batch-allocations/:id/edit', component: BatchAllocationsEdit, name: 'batch_allocations.edit' ,
        meta: { title: HeadTitle + 'Batch Allocation Edit'}
    },
    { path: '/leave-requests', component: LeaveRequestsIndex, name: 'leave_requests.index' ,
        meta: { title: HeadTitle + 'Leave Requests'}
    },
    { path: '/leave-requests/create', component: LeaveRequestsCreate, name: 'leave_requests.create' ,
        meta: { title: HeadTitle + 'Leave Request Create'}
    },
    { path: '/leave-requests/:id/show', component: LeaveRequestsShow, name: 'leave_requests.show' ,
        meta: { title: HeadTitle + 'Leave Request View'}
    },
    { path: '/leave-requests/:id/edit', component: LeaveRequestsEdit, name: 'leave_requests.edit',
        meta: { title: HeadTitle + 'Leave Request Edit'}
    }, 
    { path: '/leave-approval', component: LeaveApprovalIndex, name: 'leave_approval.index' ,
        meta: { title: HeadTitle + 'Leave Approvals'}
    },
    { path: '/leave-approval/:id/show', component: LeaveApprovalShow, name: 'leave_approval.show' ,
        meta: { title: HeadTitle + 'Leave Approval View'}
    },
    { path: '/leave-approval/:id/edit', component: LeaveApprovalEdit, name: 'leave_approval.edit' ,
        meta: { title: HeadTitle + 'Leave Approval Edit'}
    },
    { path: '/refund', component: RefundIndex, name: 'refund.index' ,
        meta: { title: HeadTitle + 'Refund'}
    },
    { path: '/refund/create', component: RefundCreate, name: 'refund.create' ,
        meta: { title: HeadTitle + 'Refund Create'},
        props: true
    },
    { path: '/refund/:id/show', component: RefundShow, name: 'refund.show' ,
        meta: { title: HeadTitle + 'Refund View'}
    },
    { path: '/refund/:id/edit', component: RefundEdit, name: 'refund.edit' ,
        meta: { title: HeadTitle + 'Refund Edit'}
    },    
    { path: '/receipts', component: ReceiptsIndex, name: 'receipts.index' ,
        meta: { title: HeadTitle + 'Receipts'}
    },
    { path: '/receipts/create', component: ReceiptsCreate, name: 'receipts.create' ,
        meta: { title: HeadTitle + 'Receipt Create'},
        props: true
    },
    { path: '/receipts/:id/show', component: ReceiptsShow, name: 'receipts.show' ,
        meta: { title: HeadTitle + 'Receipt View'}
    },
    { path: '/receipts/:id/edit', component: ReceiptsEdit, name: 'receipts.edit' ,
        meta: { title: HeadTitle + 'Receipt Edit'}
    },
    { path: '/student-batch', component: StudentBatchIndex, name: 'student_batch.index' ,
        meta: { title: HeadTitle + 'Student Batches'},
        props: true
    },
    { path: '/student-batch/create', component: StudentBatchCreate, name: 'student_batch.create' ,
        meta: { title: HeadTitle + 'Student Batch Create'},
        props: true
    },
    { path: '/student-batch/:id/show', component: StudentBatchShow, name: 'student_batch.show' ,
        meta: { title: HeadTitle + 'Student Batch View'}
    },
    { path: '/student-batch/:id/edit', component: StudentBatchEdit, name: 'student_batch.edit' ,
        meta: { title: HeadTitle + 'Student Batch Edit'}
    },
    { path: '/student-assessment', component: StudentAssessmentIndex, name: 'student_assessment.index' ,
        meta: { title: HeadTitle + 'Student Assessment'}
    },
    { path: '/student-attendance', component: StudentAttendanceIndex, name: 'student_attendance.index' ,
        meta: { title: HeadTitle + 'Student Attendance',requiresAuth: true,parentName:'student-attendance', hasPermission: ["student_attendance_access"]}
    },
    { path: '/student-assessment/:id/show', component: StudentAssessmentShow, name: 'student_assessment.show' ,
        meta: { title: HeadTitle + 'Student Assessment View'}
    },
    { path: '/student-batch-listing', component: StudentBatchListingIndex, name: 'student_batch_listing.index',
        meta: { title: HeadTitle + 'Student Schedule Listing'} 
    },
    { path: '/trainer-schedule-listing', component: TrainerScheduleListingIndex, name: 'trainer_schedule_listing.index',
        meta: { title: HeadTitle + 'Trainer Schedule Listing'} 
    },
    { path: '/evaluation-criteria', component: EvaluationCriteriaIndex, name: 'evaluation_criteria.index',
        meta: { title: HeadTitle + 'Evaluation Criteria'} 
    },        
    { path: '/batch-wise-student-listing', component: BatchWiseStudentListingIndex, name: 'batch_wise_student_listing.index',
        meta: { title: HeadTitle + 'Batch Wise Student Listing'},
        props: true 
    },
    { path: '/daily-digest-training-report', component: DailyDigestTrainingReportIndex, name: 'daily_digest_training_report.index',
        meta: { title: HeadTitle + 'Daily Digest Training Report'},
        props: true 
    },   
    { path: '/evaluation', component: Evaluation, name: 'evaluation.index',
        meta: { title: HeadTitle + 'Evaluation'} 
    },
    { path: '/404', component: NotFound, name: 'notfound.index' , alias: '404',
        meta: { title: HeadTitle + 'Not Found'}
    },
    { path: '/401', component: NotPermission, name: 'notfound.NotPermission' , alias: '401',
        meta: { title: HeadTitle + 'Not Permissable', requiresAuth: true}
    },    
    {
     path: '/progress-report', component: ProgressReportIndex, name: 'progress_report.index' ,
        meta: { title: HeadTitle + 'Progress Report'},
        props: true 
    },
    {
     path: '/student-completed-course-report', component: StudentCompletedCourseReportsIndex, name: 'student_completed_course_report.index' ,
        meta: { title: HeadTitle + 'Student Completed Course Report'}
    }, 
    {
     path: '/trainer-statistics-report', component: TrainerStatisticsIndex, name: 'trainer_statistics_report.index' ,
        meta: { title: HeadTitle + 'Trainer Statistics Report'}
    }, 
    {
     path: '/student-admission-report', component: ReportsIndex, name: 'reports.index' ,
        meta: { title: HeadTitle + 'Student Admission Report'}
    },
    {
    path: '/fee-paid-and-balance-report', component: FeesPaidandBalanceReportIndex, name: 'fees_paid_and_balance_report.index' ,
        meta: { title: HeadTitle + 'Fee paid and balance report'}
    },
    {    
    path: '/fee-collection-report', component: FeeCollectionReportIndex, name: 'fee_collection_report.index' ,
        meta: { title: HeadTitle + 'Fee collection report'}
    }, 
    {    
    path: '/inquiry-admission-report', component: InquiryAdmissionReportIndex, name: 'inquiry_admission_report.index' ,
        meta: { title: HeadTitle + 'Inquiry report'}
    }, 
    {    
    path: '/training-management-report', component: TrainingManagementReportIndex, name: 'training_management_report.index' ,
        meta: { title: HeadTitle + 'Training Management report'}
    },     
    {
     path: '/sms-setting', component: SmsSettingIndex, name: 'sms_setting.index' ,
        meta: { title: HeadTitle + 'SMS Setting'}
    },            
    {
     path: '/incomes', component: IncomeIndex, name: 'income.index' ,
        meta: { title: HeadTitle + 'Incomes'}
    },            
    {
     path: '/incomes/:id/show', component: IncomeShow, name: 'income.show' ,
        meta: { title: HeadTitle + 'Incomes View'}
    },            
    {
     path: '/incomes/create', component: IncomeCreate, name: 'income.create' ,
        meta: { title: HeadTitle + 'Add Income'}
    },            
    {
     path: '/incomes/:id/edit', component: IncomeEdit, name: 'income.edit' ,
        meta: { title: HeadTitle + 'Edit Income'}
    },            
    {
     path: '/expenses', component: ExpenseIndex, name: 'expense.index' ,
        meta: { title: HeadTitle + 'Expenses'}
    },            
    {
     path: '/expenses/:id/show', component: ExpenseShow, name: 'expense.show' ,
        meta: { title: HeadTitle + 'Expenses View'}
    },            
    {
     path: '/expenses/create', component: ExpenseCreate, name: 'expense.create' ,
        meta: { title: HeadTitle + 'Add Expense'}
    },            
    {
     path: '/expenses/:id/edit', component: ExpenseEdit, name: 'expense.edit' ,
        meta: { title: HeadTitle + 'Edit Expense'}
    },
    {
    path: '/income-report', component: IncomeReportIndex, name: 'income_report.index' ,
        meta: { title: HeadTitle + 'Income report'}
    },
    {
    path: '/expense-report', component: ExpenseReportIndex, name: 'expense_report.index' ,
        meta: { title: HeadTitle + 'Expense report'}
    },
    { 
        path: '/email-template', component: EmailTemplateIndex, name: 'email-template.index',
        meta: { title: HeadTitle + '/email-template', requiresAuth: true, parentName:'email-template'},
        hasPermission: ["email_template_access"]
    },
    {
        path: '/email-template/:id/show', component: EmailTemplateShow, name: 'email-template.show',
        meta: { title: HeadTitle + '/email-template', requiresAuth: true, parentName:'email-template'},
        hasPermission: ["email_template_access", "email_template_view"]
    },
    {
        path: '/email-template/create', component: EmailTemplateCreate, name: 'email-template.create', 
        meta: { title: HeadTitle + '/email-template', requiresAuth: true, parentName:'email-template'},
        hasPermission: ["email_template_access", "email_template_create"]
    },        
    {
        path: '/email-template/:id/edit', component: EmailTemplateEdit, name: 'email-template.edit', 
        meta: { title: HeadTitle + '/email-template', requiresAuth: true, parentName:'email-template'},
        hasPermission: ["email_template_access", "email_template_edit"]
    },
    {
     path: '/inventory-item', component: InventoryItemIndex, name: 'inventory_item.index' ,
        meta: { title: HeadTitle + 'Inventory Items'}
    },            
    {
     path: '/inventory-item/:id/show', component: InventoryItemShow, name: 'inventory_item.show' ,
        meta: { title: HeadTitle + 'Item View'}
    },            
    {
     path: '/inventory-item/create', component: InventoryItemCreate, name: 'inventory_item.create' ,
        meta: { title: HeadTitle + 'Add Item'}
    },            
    {
     path: '/inventory-item/:id/edit', component: InventoryItemEdit, name: 'inventory_item.edit' ,
        meta: { title: HeadTitle + 'Edit Item'}
    },
    {
     path: '/inventory-item-purchase', component: InventoryItemPurchaseIndex, name: 'inventory_item_purchase.index' ,
        meta: { title: HeadTitle + 'Inventory Items Purchases'}
    },            
    {
     path: '/inventory-item-purchase/:id/show', component: InventoryItemPurchaseShow, name: 'inventory_item_purchase.show' ,
        meta: { title: HeadTitle + 'View Purchase'}
    },            
    {
     path: '/inventory-item-purchase/create', component: InventoryItemPurchaseCreate, name: 'inventory_item_purchase.create' ,
        meta: { title: HeadTitle + 'Purchase Item'}
    },            
    {
     path: '/inventory-item-purchase/:id/edit', component: InventoryItemPurchaseEdit, name: 'inventory_item_purchase.edit' ,
        meta: { title: HeadTitle + 'Edit Purchase'}
    },
    {
     path: '/inventory-item-sell', component: InventoryItemSellIndex, name: 'inventory_item_sell.index' ,
        meta: { title: HeadTitle + 'Inventory Items Sells'}
    },            
    {
     path: '/inventory-item-sell/:id/show', component: InventoryItemSellShow, name: 'inventory_item_sell.show' ,
        meta: { title: HeadTitle + 'View Sell'}
    },            
    {
     path: '/inventory-item-sell/create', component: InventoryItemSellCreate, name: 'inventory_item_sell.create' ,
        meta: { title: HeadTitle + 'Purchase Sell'}
    },            
    {
     path: '/inventory-item-sell/:id/edit', component: InventoryItemSellEdit, name: 'inventory_item_sell.edit' ,
        meta: { title: HeadTitle + 'Edit Sell'}
    },
    {
    path: '/inventory-stock-report', component: InventoryStockReportIndex, name: 'inventory_stock_report.index' ,
        meta: { title: HeadTitle + 'Inventory Stock Report'}
    },
    {
    path: '/admission-digest-report', component: AdmissionDigestReportIndex, name: 'admission_digest_report.index' ,
        meta: { title: HeadTitle + 'Admission Digest Report'}
    },
    {
     path: '/workshop', component: WorkshopIndex, name: 'workshop.index' ,
        meta: { title: HeadTitle + 'Workshops'}
    },            
    {
     path: '/workshop/:id/show', component: WorkshopShow, name: 'workshop.show' ,
        meta: { title: HeadTitle + 'View Workshop'}
    },            
    {
     path: '/workshop/create', component: WorkshopCreate, name: 'workshop.create' ,
        meta: { title: HeadTitle + 'Add Workshop'}
    },            
    {
     path: '/workshop/:id/edit', component: WorkshopEdit, name: 'workshop.edit' ,
        meta: { title: HeadTitle + 'Edit Workshop'}
    },
    {
     path: '/reminders', component: RemindersIndex, name: 'reminders.index' ,
        meta: { title: HeadTitle + 'Reminders'}
    },            
    {
     path: '/reminders/:id/show', component: RemindersShow, name: 'reminders.show' ,
        meta: { title: HeadTitle + 'View Reminder'}
    },            
    {
     path: '/reminders/create', component: RemindersCreate, name: 'reminders.create' ,
        meta: { title: HeadTitle + 'Add Reminder'}
    },            
    {
     path: '/reminders/:id/edit', component: RemindersEdit, name: 'reminders.edit' ,
        meta: { title: HeadTitle + 'Edit Reminder'}
    },
    { path: '/admin-slider', component: AdminSliderIndex, name: 'admin-slider.index', 
        meta: { title: HeadTitle + 'admin-slider', requiresAuth: true, parentName:'admin-slider', hasPermission: ["admin_slider_access"]}
    },
    { path: '/admin-slider/create', component: AdminSliderCreate, name: 'admin-slider.create', 
        meta: { title: HeadTitle + 'admin-slider', requiresAuth: true, parentName:'admin-slider',hasPermission: ["admin_slider_access","admin_slider_create"]}
    },
    { path: '/admin-slider/:id/show', component: AdminSliderShow, name: 'admin-slider.show', 
        meta: { title: HeadTitle + 'admin-slider', requiresAuth: true, parentName:'admin-slider',hasPermission: ["admin_slider_access","admin_slider_view"]}
    },
    { path: '/admin-slider/:id/edit', component: AdminSliderEdit, name: 'admin-slider.edit', 
        meta: { title: HeadTitle + 'admin-slider', requiresAuth: true, parentName:'admin-slider',hasPermission: ["admin_slider_access","admin_slider_edit"]}
    },
    { path: '/common-inquiries', component: CommonInquiriesIndex, name: 'common-inquiries.index' ,
        meta: { title: HeadTitle + 'Common Inquiries', requiresAuth: true, parentName:'common-inquiries', hasPermission: ["common_inquiry_access"]}
    },
    { path: '/common-inquiries/create', component: CommonInquiriesCreate, name: 'common-inquiries.create', 
        meta: { title: HeadTitle + 'Common Inquiries', requiresAuth: true, parentName:'common-inquiries.create',hasPermission: ["common_inquiry_access","common_inquiry_create"]}
    },
    { path: '/common-inquiries/:id/show', component: CommonInquiriesShow, name: 'common-inquiries.show', 
        meta: { title: HeadTitle + 'Common Inquiries', requiresAuth: true, parentName:'common-inquiries',hasPermission: ["common_inquiry_access","common_inquiry_view"]}
    },
    { path: '/common-inquiries/:id/edit', component: CommonInquiriesEdit, name: 'common-inquiries.edit', 
        meta: { title: HeadTitle + 'Common Inquiries', requiresAuth: true, parentName:'common-inquiries',hasPermission: ["common_inquiry_access","common_inquiry_edit"]}
    },
    { path: '/certificate', component: CertificateIndex, name: 'certificate.index', 
        meta: { title: HeadTitle + 'certificate', requiresAuth: true, parentName:'certificate',hasPermission: ["certificate_access"]}
    },
    { path: '/certificate/create', component: CertificateCreate, name: 'certificate.create', 
        meta: { title: HeadTitle + 'certificate', requiresAuth: true, parentName:'certificate',hasPermission: ["certificate_access","certificate_create"]}
    },
    { path: '/certificate/:id/show', component: CertificateShow, name: 'certificate.show', 
        meta: { title: HeadTitle + 'certificate', requiresAuth: true, parentName:'certificate',hasPermission: ["certificate_access","certificate_view"]}
    },
    { path: '/certificate/:id/edit', component: CertificateEdit, name: 'certificate.edit', 
        meta: { title: HeadTitle + 'certificate', requiresAuth: true, parentName:'certificate',hasPermission: ["certificate_access","certificate_edit"]}
    },
]

var router =  new VueRouter({
    mode: 'history',
    // base: '/admin',
    routes,
    scrollBehavior (to, from) {
      return { x: 0, y: 0 }
    }
})

//authentication and redirection of pagescheck
router.beforeEach((to, from, next) => {
    const publicPages = ['login', 'forgot-password', 'reset-password', 'confirm-admission-form', 'admissionformpdf', 'application-done', 'report-card-form', 'reportcardpdf','certificate-form','certificatepdf'];
    const authPathRequired = !publicPages.includes(to.name); //this is give true/false as per path not in above public pages...
    const loggedIn = store.state.AuthIndex.user;
    const userLength = store.state.AuthIndex.user.length;
    const role_permission = store.state.AuthIndex.rules;
    const trainer = (store.state.AuthIndex.fetchIfTrainer) ? store.state.AuthIndex.fetchIfTrainer : '';  
    // console.log(store.state.AuthIndex.loggedIn);
    if (authPathRequired && store.state.AuthIndex.loggedIn == false) {
        //if user is not logged in ...
            axios.defaults.headers.common['Authorization'] = null;
            axiosLms.defaults.headers.common['Authorization'] = null;
            window.localStorage.removeItem('vuex')
            next({'name' : 'login'});           
    }else {
        if (to.matched.some(record => record.meta.requiresAuth) && store.state.AuthIndex.loggedIn == true) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            // with define header auth token...
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + loggedIn.access_token;
            axiosLms.defaults.headers.common['Authorization'] = 'Bearer ' + loggedIn.access_token;
            let anArray = to.meta.hasPermission; // hasPermission is defone in route's meta....
            if(anArray){
                anArray.forEach((item) => {  
                    //console.log(item)                  
                    if(!role_permission.includes(item) && to.name != 401 && item != 'home'){  
                        next('/401');
                    }
                });
            }                
            if (to.matched.length == 1) {
                document.title = to.meta.title //Define with meta title...
                next();
            //    console.log(to.name);
                /* if((to.name == "home" || to.name == "super-admin-home") && trainer && (trainer.employee_type == 1 || trainer.employee_type == 9)){
                    next('/trainer-home')
                }else {
                    if(to.name == "home" && store.state.AuthIndex.user.api_session_branch_id == 1){
                        next('/super-admin-home') 
                    }else if(to.name == "super-admin-home" && store.state.AuthIndex.user.api_session_branch_id != 1) {
                        next('/home')
                    }else{
                        next();
                    }
                } */
            } else {
                document.title = HeadTitle + 'Not Found';
                next('/404'); 
            }
        }else {
           // console.log(":dfdfdf")
            if(publicPages.includes(to.name) && store.state.AuthIndex.loggedIn == true){
                if(to.name != 'confirm-admission-form' && to.name != 'admissionformpdf' && to.name != 'application-done' && to.name != 'report-card-form' && to.name != 'reportcardpdf' && to.name != 'certificate-form' && to.name != 'certificatepdf'){
                    next('/home'); // if router define but call without auth...
                }else{
                    next();
                }
                
            }else if(to.name == null){
                next('/404');  // redirect on 404 if page not exits...
            }else{
                next();
            }
        }
    }   
})

export default router;