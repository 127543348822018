<template>
   <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Student Attendance</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Student Attendance List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>


        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Student Attendance List</h3>
                    </div>
                    <div class="card-body">
                    
                        <div class="btn-group inquiry-btn">
                            <!-- <router-link
                                    to="/"
                                    v-if="$can(xprops.permission_prefix + 'edit')"
                                    class="btn btn-success btn-sm"
                                    >
                                <i class="fa fa-plus"></i> Add Student
                            </router-link> -->
                               <b-btn v-b-modal.addAttendanceModel v-if="$can(xprops.permission_prefix + 'create')" type="button" class="btn btn-success btn-sm">
                                <i class="fa fa-plus"></i> Add Student
                            </b-btn> 
                            <!-- <b-btn  v-if="$can(xprops.permission_prefix + 'create')"
                                 v-b-modal.addStudent title="Add Student" id="add_student" type="button" variant="success" class="btn btn-success btn-sm">
                                <i class="fa fa-plus"></i> Add New
                            </b-btn>           -->
                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>            
                        </div>
                        <div class="clearfix"></div>

                         <div class="row mb-3 align-items-end">
                            <div class="col-md-4 col-sm-4">
                                 <label for="from_date">Date</label>
                                <!-- @dp-change="updateSelectedDate" -->
                                 <date-picker                                            
                                        :config="$root.dpconfigDatePast"
                                        name="schedule_date"
                                        v-model="selected_date"
                                        v-validate="'required'"
                                        placeholder="Select Date"
                                        autocomplete="off"
                                        ></date-picker>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                 <label for="batches">Batch</label>
                                    <select v-validate="'required'" name="batch" label="batch" class="form-control select2 batches" placeholder="Select Batch" v-model="selectedBatch" @change="updateBatch">
                                        <!-- <option value="-1" selected>Select Branches</option> -->
                                        <optgroup v-for="(batches, index) in dOptions" :key="index" :label="index">
                                            <option v-for="(batch, i) in batches" :key="i" :value="batch.id">
                                                {{batch.full_batch_name }}
                                            </option>
                                        </optgroup>
                                    </select>
                            </div>
                            <div class="col-md-2 col-sm-2">
                                <button type="button" class="btn btn-success mt-30 float-left" @click="searchData()">Search</button>
                            </div>
                            
                        </div>

                        <bootstrap-alert />
                     
                        <div class="table-responsive student_table setting-group">
                            <vue-good-table
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                ref="table1"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                              
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                            >  
                             <template
                                    slot="table-row"
                                    slot-scope="props"
                                >          
                                    <span v-if="props.column.field == 'actions'">
                                        <!-- <StudentAssessmentActions  @loadItems="loadItems" :xprops="xprops" :row="props.row"></StudentAssessmentActions> -->
                                        <StudentAttendanceActions @addIdModel="addIdModel" 
                                        @setManualCompleteCourseData="setManualCompleteCourseData"
                                        @setCompleteCourseData="setCompleteCourseData" @setStartProject="setStartProject" @setCompleteProjectData="setCompleteProjectData" 
                                        @updateProjectStatus="updateProjectStatus"
                                        @setAssessmentData="setAssessmentData" 
                                        @setManualCompleteProjectData="setManualCompleteProjectData" @loadItems="loadItems" :xprops="xprops" :row="props.row"></StudentAttendanceActions>
                                    </span>
                                    <span v-else-if="props.column.field == 'assessmentProjectTopics'">
                                        <span v-for="(topic,index) in props.row.assessmentProjectTopics" :key="topic.id">{{index+1}}) {{topic.topic_name}}<br></span>
                                    </span>
                                    <span v-else-if="props.column.field == 'attendance'">
                                         <span v-if="props.row.status == 2" class="badge badge-danger">
                                            {{props.row.attendance}}
                                        </span>
                                        <span v-else-if="props.row.status == 1" class="badge badge-success">
                                            {{props.row.attendance}}
                                        </span>
                                        <span v-else class="badge badge-info">
                                            {{props.row.attendance}}
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <addAttendancePopup :id="id" @loadItems="loadItems"></addAttendancePopup>
        <StudentAssessmentPopup :id="id" :assessmentTopicId="assessmentTopicId" @loadItems="loadItems"></StudentAssessmentPopup>
        <StudentCompleteProjectPopup :id="id"  @resetId="resetId" :scheduleProjectId="scheduleProjectId" @loadItems="loadItems"></StudentCompleteProjectPopup>
        <StudentManualCompleteProjectPopup :id="id"  @resetId="resetId" :scheduleManualProjectId="scheduleManualProjectId" @loadItems="loadItems"></StudentManualCompleteProjectPopup>
        <StudentCompleteProjectRequestPopup :id="id" @resetId="resetId" :scheduleProjectId="scheduleProjectRequestId" @loadItems="loadItems"></StudentCompleteProjectRequestPopup>
         <StudentCompleteCoursePopup :id="id" :studentEnrollId="studentEnrollId" @loadItems="loadItems"></StudentCompleteCoursePopup>
         <StudentManualCompleteCoursePopup :id="id" :studentEnrollId="studentEnrollId" @loadItems="loadItems"></StudentManualCompleteCoursePopup>
         <StudentAddProjectPopup :id="id" :studentEnrollId="studentEnrollId" :course_name="course_name" :course_id="course_id" @loadItems="loadItems" @resetStartProjectData="resetStartProjectData"></StudentAddProjectPopup>
        <StudentCourseSchedulePopup :id="addId"  @loadItems="loadItems"></StudentCourseSchedulePopup>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import addAttendancePopup from '@/components/cruds/StudentAttendance/addAttendancePopup'
    import StudentAssessmentPopup from '@/components/cruds/StudentAttendance/StudentAssessmentPopup'
    import components from '../../comps/'
    import CustomLoader from '@/components/CustomLoader'
    import StudentAssessmentActions from '../../dtmodules/StudentAssessmentActions'
    import StudentCompletedTopicsPopup from '../../dtmodules/PopupActions/StudentCompletedTopics'
    import StudentAttendanceActions from '@/components/dtmodules/DatatableActions/StudentAttendanceActions'
    import StudentCompleteProjectPopup from '@/components/cruds/StudentAttendance/StudentCompleteProjectPopup'
    import StudentCompleteProjectRequestPopup from '@/components/cruds/StudentAttendance/StudentCompleteProjectRequestPopup'
    import StudentCompleteCoursePopup from '@/components/cruds/StudentAttendance/StudentCompleteCoursePopup'
    import StudentAddProjectPopup from '@/components/cruds/StudentAttendance/StudentAddProjectPopup.vue'
    import StudentCourseSchedulePopup from '@/components/cruds/Student/StudentCourseSchedulePopup'
    import StudentManualCompleteProjectPopup from '@/components/cruds/StudentAttendance/StudentManualCompleteProjectPopup.vue'
    import StudentManualCompleteCoursePopup from '@/components/cruds/StudentAttendance/StudentManualCompleteCoursePopup'
    StudentAttendanceActions.attachStore('StudentAttendanceIndex');
    export default {
      components: { 
        StudentCompletedTopicsPopup,
        CustomLoader,
        StudentAssessmentActions,
        StudentAttendanceActions,
        addAttendancePopup,
        StudentAssessmentPopup,
        StudentCompleteProjectPopup,
        StudentCompleteProjectRequestPopup,
        StudentCourseSchedulePopup,
        StudentCompleteCoursePopup,
        StudentAddProjectPopup,
        StudentManualCompleteProjectPopup,
        StudentManualCompleteCoursePopup
        },
    data() {
            return {
            id:'',
            addId:null,
            course_id:'',
            course_name:'',
            assessmentTopicId:'',
            scheduleProjectId:'',
            scheduleProjectRequestId:'',
            scheduleManualProjectId:'',
            studentEnrollId:'',
            dOptions:[],
            isLoading: true,
            refershLoad:false,
            selectedBatch:'',
            selected_date:moment().format("DD/MM/YYYY"),
            columns: [
                {
                label: 'Student Name',
                field: 'student_name',
                width: '15%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Student Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Course',
                field: 'course_name',
                width: '15%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Course', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Batch Detail',
                field: 'batch_detail',
                width: '20%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Batch Detail', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Attendance',
                field: 'attendance',
                width: '10%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Attendance', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Attendance By',
                field: 'employee_name',
                width: '10%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Attendance By', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Project Topic',
                field: 'assessmentProjectTopics',
                sortable:false,
                width: '20%',
                filterOptions: {
                        styleClass: 'student_attendance_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Project Topic', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'StudentAttendanceIndex',
                route: 'student-attendance',
                permission_prefix: 'student_attendance_'
            }, 
            }
        },
        computed: {
            ...mapGetters('StudentAttendanceIndex', ['batchDetails','relationships','item', 'loading','loadingAssessmentDue','loadingLab','batchSheduleAll','batchSheduleDetailAll','loadingSheduleDetail'/*,'employeeAll'*/,'assessmentDue','topicDueAll','changedTopicData','labsAll']),
        },
        created() {
             this.fetchBatchDetails(this.selected_date);
        },
       
        mounted() {
           this.resetselect();
        },
        destroyed() {
            this.resetState()
        },
        watch: {
            batchDetails(value){
                this.dOptions = value;
                // $(".batches").select2('destroy');
                this.resetselect(true);
                //$(".batches").select2('data', value);
            },
            
           selected_date(value){
                this.selectedBatch = '';
                $(".batches").val('').trigger('change');
                //$(".batches").empty().trigger('change');
                this.fetchBatchDetails(this.selected_date);
           }
        },
    
        methods: {
            ...mapActions('StudentAttendanceIndex', ['fetchBatchDetails','storeData','setLoading', 'resetState','setQuery', 'setSchedule_date','fetchBatchSheduleAll','fetchBatchSheduleDetailAll','setBatchSheduleDetailAll','setStudentAttendance','setStudentAttendanceDataAll','setBatchSheduleAll','setLoadingSheduleDetail','setChangedTopicData','fetchLabsAll','setLab','deleteAttendanceIfAvailable','deleteManuallyAddedStudentStore']),
            updateBatch(){

            },
            resetId(){
                this.scheduleProjectId = '';
                this.id = '';
            },  
            addIdModel(id){
                this.addId = id;
            },
            setStartProject(data){
                this.studentEnrollId = data.enroll_id;
                this.id = data.id;
                this.course_name = data.course_name;
                this.course_id = data.course_id;
            },
            resetStartProjectData(){
                console.log("reset");
                this.studentEnrollId = '';
                this.id = '';
                this.course_name = '';
                this.course_id = '';
            },
            setCompleteCourseData(data){
                this.studentEnrollId = data.student_enroll_id
            },
            setManualCompleteCourseData(data){
                this.studentEnrollId = data.student_enroll_id
            },

            // project completion request 6-6-23 
            updateProjectStatus(data){
                // console.log("upsData",data);
                this.studentId = data.student_id;
                this.id = data.id;
                this.scheduleProjectRequestId = data.schedule_project_id;
            },

            setCompleteProjectData(data){
               // console.log("data",data);
                this.id = data.id;
                this.scheduleProjectId = data.schedule_project_id;
            },
            setManualCompleteProjectData(data){
                console.log(data);
                this.id = data.id;
                this.scheduleManualProjectId = data.schedule_manual_project_id;
            },
            setAssessmentData(data){
                this.id = data.id;
                this.assessmentTopicId = data.schedule_topic_id;
            },
            resetselect(destroy=false){
                let _this = this;
                destroy && $(".batches").select2('destroy');
                $(".batches").select2({placeholder: "Select Batch"}).on('change', function () {
                    //_this.updateBranch($(this).select2("val"))
                    // console.log("fdfdf",$(this).select2("val"));
                    _this.selectedBatch = $(this).select2("val");
                });
            },
            searchData(){
                this.loadItems();
            },
            updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
            },
            onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.loadItems();
            },
            onSortChange(params) {
            this.updateParams({
                sort: [
                {
                    type: params[0].type,
                    field: params[0].field
                }
                ],
                page: 1
            });
            this.loadItems();
            },
            onColumnFilter(params) {
            //console.log("column",params);
            this.updateParams(params);
            this.updateParams({page:1});
            this.loadItems();
            },

            onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.loadItems();
            },
            // load items is what brings back the rows from server
            loadItems(flag = 0) {
           // console.log("here");
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.refershLoad = true;
                }
            axiosLms.get("/api/v1/studentAttendance", {
                params: {
                    max: this.serverParams.perPage,
                    page: this.serverParams.page,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:this.serverParams.columnFilters,
                    selectedDate:this.selected_date,
                    selectedBatch:this.selectedBatch
                }
                })
                .then(response => {
                //commit('setAll',  response.data.data)
                //console.log("rs",response.data);
                let res = response.data;
                if(res.result){
                    // console.log("dsd",res.data.data)
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
            
                });
            }, 

            resetFilters(){
                this.serverParams.columnFilters = {};
                let columnFilters = {"original_date":"","holiday_name":""};
                this.selectedBatch = '';
                this.selected_date = moment().format("DD/MM/YYYY");
                $(".batches").val('').trigger('change');
                this.updateParams(columnFilters);
                this.updateParams({page:1});
                this.$refs.table1.reset();
                this.loadItems(1);
            },
        }
    }
</script>
<style>
    #select-attendence .dropdown-menu {
        width: 70px !important;
    }
    #select-attendence .selected-tag{
        position: absolute !important;
        z-index: 1;
    }
    #select-attendence .dropdown-toggle .clear {
    display: none;
    }
    #loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
    }
    .ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
    }
    img {
    vertical-align: middle;
    }
    img {
    border: 0;
    }
    .bounce-enter-active {
    animation: bounce-in .5s;
    }
    .bounce-leave-active {
    animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
    0% {
    transform: scale(0);
    }
    50% {
    transform: scale(1.5);
    }
    100% {
    transform: scale(1);
    }
    }
    .dropd {
    width: 30% !important;
    }
    .myicon {
    padding-left: 10%;
    vertical-align: middle;  
    }
    #mySelect .dropdown-toggle .clear {
    display: none;
    }
    #mySelect .v-select .dropdown-toggle {
    border: none;
    }
    #mySelectLab .dropdown-toggle .clear {
    display: none;
    }
    #mySelectLab .v-select .dropdown-toggle {
    border: none;
    }
    .light-red{
    background-color: #dcdcdc !important;
    /* // color: #fff; */
    }
    .light-red .student-name-link{
    /*color: #0be666;*/
    /*color: #22a659;*/
    font-weight: 600;
    }
</style>