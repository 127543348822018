<template>
    <div>
        <div class="btn-group mb-3">
                            
            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
            </button>     
            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
            </button>                      
        </div>
        <div class="table-responsive setting-group">
            <vue-good-table
                ref="table1"
                styleClass="vgt-table table table-bordered striped thead-dark"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :pagination-options="{
                    enabled: true,
                    perPage:10,
                    perPageDropdown: [10, 20, 40, 80, 100],
                    dropdownAllowAll: false,
                }"
                :isLoading.sync="isLoading"
                :select-options="{ 
                    enabled: false,
                }"
                :search-options="{
                    enabled: false,
                    placeholder: 'search',
                }"
                :rows="rows"
                :columns="columns"
                >
                    <div slot="emptystate" class="text-center">
                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    
                    {{props.formattedRow[props.column.field]}}
                   
                </template>
                    <template slot="loadingContent">
                <CustomLoader></CustomLoader>
            </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableSingle from '../../../dtmodules/DatatableSingle'
import DatatableList from '../../../dtmodules/DatatableList'
import DatatableCheckbox from '../../../dtmodules/DatatableCheckbox'
import components from '../../../comps/'
import CustomLoader from '@/components/CustomLoader'

export default {
    props: ['showId'],
    components,
     components: {
        CustomLoader
    },
    data() {
        return {
            isLoading: true,
            refershLoad:false,
            columns: [
                 {
                    label: "Project Name",
                    field: "project_name",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Project Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Project Type",
                    field: "project_type",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Project Type', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Brand",
                    field: "project_brand",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Brand', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Project Kit",
                    field: "project_kit",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Project Kit', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Hours",
                    field: "project_hours",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Hours', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "License Start Date",
                    field: "start_date",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'License Start Date', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "License End Date",
                    field: "expiry_date",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'License End Date', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            date_filter : {search_course_name : null},
            xprops: {
                module: 'ProjectsIndex',
                route: 'projects',
                permission_prefix: 'project_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        // this.loadItems(0);
        // this.fetchData()
        //this.fetchCourseListWithDistinctAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('ProjectsIndex', ['item','data', 'total', 'loading', 'relationships','courseListWithDistinctAll']),
        ...mapGetters('AuthIndex', ['user']),
    },
    // watch: {
    //     query: {
    //         handler(query) {
    //             this.setQuery(query)
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        ...mapActions('ProjectsIndex', ['fetchData','fetchCourseListWithDistinctAll', 'setQuery', 'resetState']),
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axiosLms.get("/api/v1/projects", {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters,
                license_id:this.showId
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"project_name":"","project_type":"","project_brand":"","project_kit":"","project_hours":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        },  
        searchCourseData() {
            this.query = {};            
            if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
                this.date_filter.search_course_name = this.date_filter.search_course_name.course_name;    
            }
            this.fetchData(this.date_filter);
        }
    }
}
</script>
<style scoped>

</style>
