<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header"></section>
        <section class="content">
<!--             <div class="row img">
                <div class="col-md-12" style="height: 200px;">
                    <span class="info-box-icon bg-aqua"><img src="/assets/images/robot-home (1).png"></span>
                    
                </div>
            </div> -->
            <!-- <span><img src="/assets/images/robot-home (1).png"  class="center"></span>             -->
            <p id="cont" class="text-center">404</p>
        	<p id="msg" class="text-center">Sorry &#9785;, the page you are looking for could not be found!</p>
        </section>
    </section>
</template>


<script>

export default {
    data() {
        return {
            
        }
    },
};
</script>
<style scoped>
#cont {
	font-size: 200px;
}
#msg {
	font-size: 20px;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*width: 50%;*/
    height: 200px;
}
</style>


