<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Trainer Statistics</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Trainer Statistics</h3>
                        </div>
                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>
                        <div class="col-md-6 col-xs-12">
                             <div ref="course" class="form-group">
                                <label for="course">Course</label>
                                <v-select
                                    name="course"
                                    label="display_course_name"
                                    @input="updateCourseFilter"
                                    :value="courseFilter"
                                    :options="coursesAll"                           
                                    />
                            </div>
                        </div>                         
                       <div class="col-md-6 col-xs-12">
                            <date-r-picker 
                                @updateRange="updateRange" 
                                title="Schedule Date Range">
                            </date-r-picker>
                            <!-- <date-range-picker 
                                :autoApply="true" 
                                @updateRange="updateRange" 
                                v-model="dateRange"> -->
                            </date-range-picker>
                        </div> 
                    </div>
                    <div class="col-md-12">
                        <div class="text-center">
                            <button class="btn btn-sm btn-success" @click="applyFilter">Submit</button>
                        </div>                        
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-8 col-xs-12">
                    <div class="box">
                        <div class="col-md-12">
                            <div class="box box-solid">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Trainer Statistics Chart</h3>
                                </div>
                                <div class="box-body chart-responsive">
                                    <canvas id="trainer_statistics_bar_canvas"></canvas>
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Trainer Statistics Hours Wise Report</h3>
                        </div>
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="hoursResetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="hoursColumns"
                                        :data="hoursData"
                                        :total="hoursTotal"
                                        :query="hoursQuery"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>                
                </div>
                <div v-if="trainerStatisticsShown" class="col-md-12 col-xs-12" id="trainerStudentData">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Trainer Statistics Students List</h3>
                        </div>
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>                
                </div>                
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
import DateRangePicker from 'vue2-daterange-picker'
components['date-range-picker'] = DateRangePicker;
import totalAssessmentDone from '../../dtmodules/totalAssessmentDone'
export default {
    components,
    data() {
        return {
            trainerStatisticsChart: null,
            items:[],
            columns: [
                { title: 'Student', field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date' },
                { title: 'Course', field: 'course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Batch', field: 'batch_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Ass. Date', field: 'session_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Trainer', field: 'trainer_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
            ],
            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'TrainerStatisticsIndex',
                route: 'trainer_statistics_report',
                permission_prefix: 'trainer_statistics_report_'
            },

            hoursColumns: [
                { title: 'Trainer', field: 'trainer_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Hours', field: 'trainer_hours', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Total Ass.', tdComp:totalAssessmentDone, field: 'total_assessment_done', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
            ],
            hoursQuery: { /*sort: 'id', order: 'desc'*/ },

            dateRange: { // used for v-model prop
                // startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                // endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                startDate: new Date(),
                endDate: new Date(),
            },
        }
    },
    created() {
        var from_date = this.formatDateForDataBase(new Date()),
        to_date = this.formatDateForDataBase(new Date()),
        params = {
            'from_date': from_date,
            'to_date': to_date,
            'course_id': null
        }
        // this.updateRange(this.dateRange)
        this.fetchAllCourses()
        this.fetchData(params)
            .then(()=>{
                this.launchTheChart()
            })
    },
    destroyed() {},
    computed: {
        ...mapGetters('TrainerStatisticsIndex', ['hoursData', 'hoursTotal','data','total','coursesAll','all','dateFilter','courseFilter','loading','trainerStatisticsShown']),
        computedChartData() {
            var monthArray = [],
            data = [],
            finalObj = {};
            for (var i = 0; i < this.all.employeeStatisticsData.length; i++) {
                monthArray.push(this.all.employeeStatisticsData[i].trainer_name)
                data.push(this.all.employeeStatisticsData[i].trainer_hours)
            }
            finalObj.monthArray = monthArray
            finalObj.data = data
            return finalObj
        },        
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        hoursQuery: {
            handler(hoursQuery) {
                this.setHoursQuery(hoursQuery)
            },
            deep: true
        },        
    },    
    methods: {
        ...mapActions('TrainerStatisticsIndex', ['fetchAllCourses','fetchData', 'setHoursQuery','setQuery','setDateFilter','setCourseFilter','setFromDate','setExit_date','setTrainerStatisticsShown']),
        formatDateForDataBase(today) {
            var dd = today.getDate();
            var mm = today.getMonth()+1; //January is 0!
            var yyyy = today.getFullYear();
            if(dd<10) {
                dd = '0'+dd
            }
            if(mm<10) {
                mm = '0'+mm
            } 
            return yyyy + '-' + mm + '-' + dd;            
        },
        applyFilter() {
            this.setTrainerStatisticsShown(false)
            this.trainerStatisticsChart.destroy()
            var params = {
                'from_date': this.formatDateForDataBase(this.dateRange.startDate),
                'to_date': this.formatDateForDataBase(this.dateRange.endDate),
                'course_id': this.courseFilter ? this.courseFilter.id : null
            }
            this.fetchData(params)
                .then(()=>{
                    this.launchTheChart()
                })
        },         
        updateCourseFilter(value) {
            this.setCourseFilter(value)
        },        
        updateRange(e) {
            this.dateRange = e;
            // this.dataSeen = false;
            this.updateFromDate(e.startDate)
            this.updateExit_date(e.endDate)
        },  
        updateFromDate(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setFromDate(date)
            // this.dataSeen = false;
        },
        updateExit_date(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setExit_date(date)
            // this.dataSeen = false;
        },          
        launchTheChart() {
            var graphColors = [];
            for (var i = 0; i < this.computedChartData.data.length; i++) {
                var randomR = Math.floor((Math.random() * 170) + 100);
                var randomG = Math.floor((Math.random() * 170) + 100);
                var randomB = Math.floor((Math.random() * 170) + 100);
                var graphBackground = "rgb(" 
                        + randomR + ", " 
                        + randomG + ", " 
                        + randomB + ")";
                graphColors.push(graphBackground);                    
            }
            var data = {
              datasets: [{
                data: this.computedChartData.data,
                backgroundColor: graphColors
              }],
              labels: this.computedChartData.monthArray
            };
            var canvas = document.getElementById("trainer_statistics_bar_canvas");
            var ctx = canvas.getContext("2d");
            this.trainerStatisticsChart = new Chart(ctx, {
              type: 'bar',
              data: data,
              options: {          
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }, 
                    legend: {
                        display: false
                    },
              }
            });
        },   
        resetFilters(){
            var filtersField= [];
            filtersField = ['student_name','course_name','topic_name','batch_name','session_date','trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            // this.fetchData()
        },
        hoursResetFilters(){
            var filtersField= [];
            filtersField = ['trainer_name','trainer_hours','total_assessment_done'];
            this.$root.resetSearchFilters(Vue.delete,this.hoursQuery,filtersField);
            // this.fetchData()
        }             
    }
}
</script>


<style scoped>
.vue-daterange-picker .daterangepicker .calendars {
    margin-left: 0px !important;
}
</style>
 
