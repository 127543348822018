<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventReceiptView"
                ref="modal"
                title="View Receipt"
                 @shown="modalOpened" 
                size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-6 col-xs-12 table-responsive">
                                        <table class="table table-bordered table-striped not-responsive-grid">
                                            <tbody>
                                            <tr>
                                                <th>Receipt Date</th>
                                                <td>{{ item.receipt_date }}</td>
                                            </tr>
                                            <tr>
                                                <th>Receipt Amount</th>
                                                <td><i class="fa fa-rupee"></i> {{ item.receipt_amount }}</td>
                                            </tr>
                                            <tr>
                                                <th>Student Name</th>
                                                <td>{{ item.student_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Enrollment #</th>
                                                <td>{{ item.enroll_no }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Fee Details</h3>
                            </div>
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12 table-responsive">
                                        <table class="table table-bordered table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Course Name</th>
                                                    <th>Fee Type</th>
                                                    <th>Fee Paid Amount</th>
                                                    <th>GST Rate(%)</th>
                                                    <th>GST Amount</th>
                                                    <th>Fee Amount</th>
                                                    <th>Total Amount</th>
                                                    
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(fee, index) in item.receipt_detail">
                                                    <td>{{fee.course_name}}</td>
                                                    <td>{{fee.fees_type_name}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amount}}</td>
                                                    <td>{{fee.gst_rate}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.gst_amount}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amt}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.total_fees}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Payment Details</h3>
                            </div>
                            <div class="card-body" >
                            <div class="row">
                                <div class="col-md-12 col-xs-12 table-responsive">
                                    <table class="table table-bordered table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Payment Mode</th>
                                                <th>Amount</th>
                                                <th>Bank Name</th>
                                                <th>Cheque/Transaction Number</th>
                                                <th>Date</th>                            
                                            </tr>                               
                                        </thead>
                                        <tbody>
                                            <tr  v-for="(fee, index) in item.receipt_payment_mode">
                                                <td>{{fee.name}}</td>
                                                <td><i class="fa fa-rupee"></i> {{fee.amount}}</td>
                                                <td>{{(fee.bank_name) ? fee.bank_name: '-'}}</td>
                                                <td>{{(fee.instrument_no) ? fee.instrument_no : '-'}}</td>
                                                <td>{{(fee.instrument_date) ? fee.instrument_date_view : item.receipt_date1 }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="text-right" >
                            <button @click="sendPdf" class="btn btn-primary mr-2" :disabled="loading && mailSendBtnFlag" >
                                <span class="fa fa-spinner fa-spin" v-if="loading && mailSendBtnFlag" ></span>
                                Send Mail
                            </button>
                            <button @click="downloadPdf" class="btn btn-primary" :disabled="loading && downloadBtnFlag" >
                                <span class="fa fa-spinner fa-spin" v-if="loading && downloadBtnFlag" ></span>
                                Download
                            </button>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['receiptId', 'student_id'],
    data() {
        return {
            // Code...
            paymentDate:[],
            downloadBtnFlag:false,
            mailSendBtnFlag:false,
        }
    },
    created() {
        this.fetchData(this.student_id)
       // console.log(this.item);
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('ReceiptsSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.student_id)
        },
        item:function(){
           // console.log(this.item)
        }
    },
    methods: {
        ...mapActions('ReceiptsSingle', ['fetchData', 'resetReceipt', 'resetState','downloadInvoicePdf','sendMailInvoicePdf']),
       
        modalOpened() {
            this.fetchData(this.receiptId)  
            if(this.item.receipt_payment_mode){
                this.item.receipt_payment_mode.forEach(function(payment, i){
                    paymentDate[i] = '';
                    if(payment.instrument_date){
                        var dateVal = (payment.instrument_date).split('-');
                        finaldate = dateVal[2]+'/'+dateVal[1]+'/'+dateVal[0];
                        paymentDate[i] = finaldate;
                    }
                });
            }          
        },
        sendPdf(){
            this.mailSendBtnFlag = true;
            this.sendMailInvoicePdf({receiptId:this.receiptId, student_id:this.student_id})
            .then(res => {
                this.mailSendBtnFlag = false;
                if (res.data.result) {
                    this.$awn.success(res.data.message)
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        downloadPdf(){
            this.downloadBtnFlag = true;
            this.downloadInvoicePdf({receiptId:this.receiptId, student_id:this.student_id})
            .then(res => {
                this.downloadBtnFlag = false;
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.receiptId+'.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
}
</script>


<style scoped>

</style>
 