function initialState() {
    return {
        item: {
            course_id: null,
            course_name: null,
            course_months: null,
        },
        batchData:[],
        holdHistoryData:[],
        coursesAll: [],
        batchAll:[],
        courseWorkingDaysAll : [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    coursesAll: state => state.coursesAll,
    batchAll: state => state.batchAll,
    courseWorkingDaysAll: state => state.courseWorkingDaysAll,
    batchData: state => state.batchData,
    holdHistoryData: state => state.holdHistoryData,
}

const actions = {
    async fetchCoursesAll({ commit }, student_id) {
        await axiosLms.get('/api/v1/getStudentEnrolleCourses/' + student_id)
            .then(response => {
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
    },
    async fetchBatchAll({ commit }, course_id) {
        await axiosLms.get('/api/v1/getBatches/' + course_id)
            .then(response => {
                commit('setBatchesAll', response.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
    },
    storeData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                }
            }

            if (_.isEmpty(form_data.batchSchedule)) {
                params.set('batchSchedule', '')
            } else {
                for (let index in form_data.batchSchedule) {
                    if(form_data.batchSchedule[index]['daySelect']){
                        if(_.isEmpty(form_data.batchSchedule)){
                            params.set('batchSchedule['+form_data.batchSchedule[index]['day_id']+']', [])
                        }
                        else{
                            for (let index1 in form_data.batchSchedule[index]['selected_batch']) {
                                params.set('batchSchedule['+form_data.batchSchedule[index]['day_id']+']['+index1+']', form_data.batchSchedule[index]['selected_batch'][index1].batch_id)
                            }
                        }
                    }          
                }
            }

            axiosLms.post('/api/v1/student-batch/store', params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    updateData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                }
            }

            if (_.isEmpty(form_data.batchSchedule)) {
                params.set('batchSchedule', '')
            } else {
                for (let index in form_data.batchSchedule) {
                    if(form_data.batchSchedule[index]['daySelect']){
                        if(_.isEmpty(form_data.batchSchedule)){
                            params.set('batchSchedule['+form_data.batchSchedule[index]['day_id']+']', [])
                        }
                        else{
                            for (let index1 in form_data.batchSchedule[index]['selected_batch']) {
                                params.set('batchSchedule['+form_data.batchSchedule[index]['day_id']+']['+index1+']', form_data.batchSchedule[index]['selected_batch'][index1].batch_id)
                            }
                        }
                    }          
                }
            }

            axiosLms.post('/api/v1/student-batch/update/'+form_data.id, params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    extendEndData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                }
            }

            axiosLms.post('/api/v1/student-batch/extendEndDate/'+form_data.id, params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async getBatchData({ commit }, batch_id) {
        await axiosLms.get('/api/v1/student-batch/edit/' + batch_id)
            .then(response => {
                commit('setBatchData', response.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
    },
    async getHoldHistoryData({ commit }, history_id) {
        await axiosLms.get('/api/v1/history/show/' + history_id)
            .then(response => {
                commit('setHoldHistoryData', response.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
    },
    setHoldHistoryData({commit},value){
        commit('setHoldHistoryData', value)
    },
    setBatchData({commit},value){
        commit('setBatchData', value)
    },
    setCourseId({ commit }, value) {
        commit('setCourseId', value)
    }, 
    setCourseName({ commit }, value) {
        commit('setCourseName', value)
    },
    setCourseMonth({ commit }, value) {
        commit('setCourseMonth', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    holdBatch1({ commit, dispatch }, batchId) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/student-batch/hold/'+batchId)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error);
            })     
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    unHoldBatch1({ commit, dispatch }, batchId) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/student-batch/unhold/'+batchId)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error);
            })     
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/student-batch/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            })  
            .finally(() => {
            })
        })
    },
    fetchSingleData({ commit, state }, id) {
        axiosLms.get('/api/v1/student-batch/' + id)
        .then(response => {
            commit('setBatchData', response.data.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
}

const mutations = {
    setCoursesAll(state, value) {
        state.coursesAll = value
    }, 
    setBatchesAll(state,value){
        state.batchAll =  value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setCourseId(state, value) {
        state.item.course_id = value
    },
    setCourseName(state, value) {
        state.item.course_name = value
    },
    setCourseMonth(state, value) {
        state.item.course_months = value
    },
    setBatchData(state, item) {
        state.batchData = item
    },
    setHoldHistoryData(state, item) {
        state.holdHistoryData = item
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
