<template>
  <div class="popup-form" style="position: static;">
    <i
      v-b-modal.fetchStudentModal
      class="fa fa-cloud-download"
      style="color: #dd4b43; font-size: 2em;"
      title="Fetch Student data from other branch"
    ></i>
    <b-modal
      id="fetchStudentModal"
      ref="fetchStudentPopup"
      size="lg"
      title="Fetch student data from other branch"
      okTitle="Fetch"
      okVariant="success"
      cancelTitle="Close"
      cancelVariant="danger"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-header-close 
      @cancel="handleCancel"
      @ok="handleOk"
      @shown="clear"
    >
      <form @submit.stop.prevent="handleSubmit">
        <div class="container-fluid">
          <div class="row text-left">
            <div class="form-group col-md-6 col-xs-12">
                <label for="firstname">First Name</label>
                <input
                  class="form-control"
                  type="text"
                  name="firstname"
                  maxlength="90"
                  v-model="firstName"
                  placeholder="Enter First Name"
                >
                <span class="error" v-show="errors.has('first_name')">
                    {{ $root.updateErrorMessage('first_name',this.errors,'First Name')}}
                </span>
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <label for="lastname">Last Name</label>
                <input
                  class="form-control"
                  type="text"
                  name="lastname"
                  maxlength="90"
                  v-model="lastName"
                  placeholder="Enter Last Name"
                >
            </div>
          </div>
          <div class="row text-left">
            <div class="form-group col-md-6 col-xs-12">
                <label for="mothername">Mother Name</label>
                <input
                  class="form-control"
                  type="text"
                  name="mothername"
                  maxlength="90"
                  v-model="motherName"
                  placeholder="Enter Mother Name"
                >
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <label for="othermobileno">Mother Mobile No</label>
                <input
                  class="form-control"
                  type="text"
                  name="othermobileno"
                  maxlength="90"
                  v-model="motherMobileNo"
                  placeholder="Enter Mother Mobile Number"
                >
            </div>
          </div>
          <div class="row text-left">
            <div class="form-group col-md-6 col-xs-12">
                <label for="birth_date">Birth Date</label>
                <date-picker
                    :value="birthDate"
                    :config="$root.dpconfigDateFutureTemp"
                    name="birth_date"
                    placeholder="Select Birth Date"
                    @dp-change="updateBirth_date"
                    autocomplete="off"
                    >
                </date-picker>                
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <label for="branchname">Branch Name</label>
                <v-select
                    name="branchname"
                    label="branch_name"
                    v-validate="'required'"
                    @input="updateBranch"
                    :value="selectedBranch"
                    :options="branchMastersAll"
                    />
                <span class="error" v-show="valid">
                    Please enter at least one parameter
                </span>
                <span class="error" v-show="noData">
                    No data found
                </span>                              
            </div>                        
          </div>
        </div>
      </form>
      <div class="row dataTable">
        <div class="form-group col-md-12 ">
            <div v-if="studentDataExists" class="tableFixHead">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>#</th>
                            <th>Branch Name</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Birth Date</th>
                            <th>Mother Name</th>
                            <th>Mother Mobile No</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(stud, index) in allStudents" :key="index">
                            <td class="Branch Name">
                                <button type="button" :id="'select_'+index" class="btn btn-xs btn-success" @click="setAllStudentData(index)">Set</button>
                            </td>
                            <td class="Branch Name">{{index+1}}</td>
                            <td class="Branch Name">{{stud.branch_name}}</td>
                            <td class="Mobile Number">{{stud.first_name}}</td>
                            <td class="Mobile Number">{{stud.last_name}}</td>
                            <td class="Mobile Number">{{stud.for_birth_date}}</td>
                            <td class="Mobile Number">{{stud.mother_name}}</td>
                            <td class="Mobile Number">{{stud.mother_mobile_no}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
     </div>      
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      motherName: "",
      motherMobileNo: "",
      branchId: "",
      birthDate: null,
      selectedBranch: null,
      valid: false,
      noData: false,
      studentDataExists: false,
      allStudents: [],
      dobDateOptionFormate:{format:window.date_format_moment, maxDate: this.getDOBDate()}
    };
  },
  computed: {
    ...mapGetters("StudentSingle", ["item", "loading", "branchMastersAll","countrymastersAll","statemastersAll","standardAll","referenceAll","tshirtSizesAll","parentAll","schoolAll"])
  },
  watch: {
    "$route.params.id": function() {
      this.resetState();
    }
  },
  methods: {
    ...mapActions("StudentSingle", ['storeData', 'fetchBranchMasterAll','resetState', 'setOnlySummerCampGlobal','setBranch_id', 'setEnrollDate','setFirst_name', 'setMiddle_name', 'setLast_name', 'setStudent_address', 'setArea', 'setCountry_list', 'setState_list', 'setCity', 'setGender', 'setPin_code', 'setBirth_date', 'setParent', 'setTshirt', 'setSchool', 'setStudentSta', 'setStandard_id','setStudent_status','setStudent_reason', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchFeeAdditionalAdjustmentCharge','fetchCountrymastersAll','fetchStatemastersAll','fetchParentAll','fetchTshirtSizesAll','fetchSchoolAll','fetchStandardAll','fetchCourseAll','setCourseListAll','setCourse','fetchBatchAll','fetchFeeCourseList','setFeeCourseList','setBatch','fetchStudentStatusAll','fetchStudentReasonAll','fetchCountryStates','setStart_date','fetchFeesTypeAvailableListAll','setFeesTypeAvailableListAll','setCourseAll','setStatemastersAll','fetchComboFromCourseDetails','setProfile','fetchIfBranchAdmissionFeesApplicable','setReference','fetchReferenceAll']),
    getDOBDate(){
        var d = new Date();
        var pastYear = d.getFullYear() - 5;
        d.setFullYear(pastYear);
        return d;
    },
    updateBirth_date(e) {
        this.birthDate = e.target.value
    },     
    clear() {
      this.firstName = "";
      this.lastName = "";
      this.motherName = "";
      this.motherMobileNo = "";
      this.branchId = "";
      this.birthDate = "";
      this.selectedBranch = null;
      this.valid = false;
      this.noData = false;
      this.studentDataExists = false;
      this.allStudents = [];
    },
    handleCancel(evt) {
      this.clear();
    },
    handleOk(evt) {
        this.noData = false;
        evt.preventDefault();
        if(this.firstName.trim() === '' && this.lastName.trim() === '' && this.motherMobileNo.trim() === '' && this.birthDate.trim() === '' && this.motherName.trim() === '' && !(this.branchId > 0)) {
            this.valid = true;
            return
        }
        this.valid = false;
        this.handleSubmit();
    },
    handleSubmit() {
      let params = new FormData();
      params.set("branch_id", this.branchId);
      params.set("first_name", this.firstName);
      params.set("last_name", this.lastName);
      params.set("mother_name", this.motherName);
      params.set("other_mobile_no", this.motherMobileNo);
      params.set("birth_date", this.birthDate);
      axios.post("/api/v1/getStudentData", params)
        .then(response => {
            this.allStudents = response.data.data
            if(response.data.data.length == 0) {
                this.noData = true
                this.studentDataExists = false
                return
            }
            this.studentDataExists = true
        })
        .catch(function(error) {
            console.log("Error", error.message);
            this.$eventHub.$emit('sms-not-sent')
            reject(error);
        });
    },
    updateBranch(value) {
        this.selectedBranch = value
        if(value && value.id) {
            this.branchId = value.id
        } else {
            this.branchId = 0
        }
    },
    setAllStudentData(index) {
        const selectedData = this.allStudents[index];
        this.setEnrollDate(selectedData.for_enroll_date);
        this.setFirst_name(selectedData.first_name);
        this.setMiddle_name(selectedData.middle_name);
        this.setLast_name(selectedData.last_name);
        this.setGender(selectedData.gender);
        this.setBirth_date(selectedData.for_birth_date);
        this.setStudent_address(selectedData.student_address);
        this.setArea(selectedData.area);
        this.setPin_code(selectedData.pin_code);
        this.setCity(selectedData.city);
        this.setCountry_list((this.countrymastersAll.filter(option => option.id == selectedData.country))[0]);
        if(this.item.country_list !== null){
            this.fetchCountryStates(selectedData.country)
                .then(() => {
                    this.setState_list((this.statemastersAll.filter(option => option.id == selectedData.state))[0]);
                })          
        }
        else{
            this.setStatemastersAll([]);
            this.setState_list(null);
        }
        this.setStandard_id((this.standardAll.filter(value => value.value_id == selectedData.standard))[0])
        this.setReference((this.referenceAll.filter(value => value.value_id == selectedData.reference_id))[0])
        this.setTshirt((this.tshirtSizesAll.filter(value => value.value_id == selectedData.tshirt_size))[0])
        if(selectedData.parentExist != false) {
          this.setParent((this.parentAll.filter(value => value.id == selectedData.parentExist))[0])
        } else {
            //create and save parent
            let params = new FormData();
            params.set("email", selectedData.parent.email);
            params.set("father_name", selectedData.parent.father_name);
            params.set("mobile_no", selectedData.parent.mobile_no);
            params.set("mother_name", selectedData.parent.mother_name);
            params.set("other_mobile_no", selectedData.parent.other_mobile_no);
            params.set("other_email", selectedData.parent.other_email);
            params.set("whatsapp_no", selectedData.parent.whatsapp_no);
            axios.post("/api/v1/createParent", params)
                .then(response => {
                    if(response.data.data && response.data.data[0]) {
                        this.fetchParentAll()
                            .then(()=>{
                                this.setParent((this.parentAll.filter(value => value.id == response.data.data[0]))[0])
                            })
                    }
                })
                .catch(function(error) {
                    console.log("Error", error.message);
                    this.$eventHub.$emit('sms-not-sent')
                    reject(error);
                });
        }
        if(selectedData.schoolExist != false) {
          this.setSchool((this.schoolAll.filter(value => value.id == selectedData.schoolExist))[0])
        } else {
            //create and save schoool
            //create and save parent
            let params = new FormData();
            params.set("school_name", selectedData.school.school_name);
            params.set("school_address", selectedData.school.school_address);
            params.set("contact_no", selectedData.school.contact_no);
            params.set("area", selectedData.school.area);
            params.set("city", selectedData.school.city);
            params.set("pincode", selectedData.school.pincode);
            params.set("state", selectedData.school.state);
            params.set("country", selectedData.school.country);
            axios.post("/api/v1/createSchool", params)
                .then(response => {
                    if(response.data.data && response.data.data[0]) {
                        this.fetchSchoolAll()
                            .then(()=>{
                                this.setSchool((this.schoolAll.filter(value => value.id == response.data.data[0]))[0])
                            })
                    }
                })
                .catch(function(error) {
                    console.log("Error", error.message);
                    this.$eventHub.$emit('sms-not-sent')
                    reject(error);
                });            
        }        
        this.clear()
        this.$refs['fetchStudentPopup'].hide()
    }
  }
};
</script>
<style scoped>
    .tableFixHead thead th {
        position: sticky;
        top: 0;
    }
    
    table {
        border-collapse: collapse;
        width: 100%;
    }
    
    th,
    td {
        padding: 8px 16px;
    }
    
    th {
        background: #eee;
    }
    .dataTable {
        max-height: 250px;
        overflow: auto;
    }
</style>