<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">

        <ul class="navbar-nav">
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                >
                    <i class="fas fa-bars"></i>
                    </a>
            </li>
            <!-- <li class="nav-item d-none d-sm-inline-block">
                <a
                    href="#"
                    class="nav-link"
                >Contact</a>
            </li> -->
        </ul>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
                <div v-if="branchesDetails">
                    <select class="form-control" id="headerBranch" @change="changeBranch($event)">
                         <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                            <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id" :selected="(branch.branch_id == branch_select) ? 'selected': ''">
                                {{branch.branch_name}}
                            </option>
                        </optgroup>
                    </select>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a
                    class="nav-link user-name"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                >
                    <i class="far fa-user"></i>
                    {{stateUserName}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                        >
                            <i class="fas fa-user mr-2"></i> My Profile
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                                class="dropdown-item cursor-pointer"
                                @click="logout"
                            >
                                <i class="fas fa-sign-out mr-2"></i> Logout
                            </a>
                    </div>
            </li>

        </ul>
    </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import store from '../../store'
import authHeader from '../../store/auth-header'

export default {
    data(){
        return {
            stateUserName:'',
            branchesDetails:[],
            exeRun:1,
            franchise_header_id:'',
            branch_header_id:'',
            branch_select:'',
            idPage:false,
        }
    },
    computed: { ...mapGetters(["branches"]) },
    created: async function () {
        /* Used for get state required value for all loggedin pages...*/
        this.stateUserName = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.name : 'admin';
        this.franchise_header_id =  (store.state.AuthIndex.user) ? store.state.AuthIndex.user.franchise_id : null;
        this.branch_header_id =  (store.state.AuthIndex.user) ? store.state.AuthIndex.user.branch_id : null;
        this.branchesDetails = store.state.AuthIndex.branches;
        this.branch_select = store.state.AuthIndex.user.api_session_branch_id;
    },
    watch: {
        '$store.state.AuthIndex.branches': function() {
            this.branchesDetails = store.state.AuthIndex.branches;                       
        },
    },
    methods:{
        ...mapActions('AuthIndex', ['loggedOutUser', 'getBranchesData', 'updateUserStateOnChange', 'setRules']),
        logout(){
            /* Used for logout */
            axios.post('/api/v1/logout', '',{ headers: authHeader() }).then(response => {    
                this.loggedOutUser();
                window.localStorage.removeItem('vuex')
                this.$router.push({ path : '/' });
            }).catch(function (error) {
                console.log(error);
            });             
        },
        changeBranch(event){
            /* Used for update branch and franchise state as per request change from header change */
            let branch_id = event.target.value;
            axios.post('/api/v1/setSessionRoleId', { session_branch_id : branch_id} ).then(response => { 
                if(response.status == 200 && response.data.result == true){
                    let setbranch_id = response.data.data;
                    store.dispatch('Rules/fetchData')
                    this.updateUserStateOnChange(setbranch_id);
                    var currentLocation = window.location.href.split('/') ;
                    console.log(currentLocation);
                    if(this.$route.params.id || this.$route.params.student_id){
                        this.$router.push('/'+this.$route.meta.parentName)
                    }else{
                        if(currentLocation && currentLocation[3] == "home" && branch_id == 1){
                           window.location.href = '/super-admin-home'; 
                        }else if(currentLocation && currentLocation[3] == "super-admin-home" && branch_id != 1) {
                            //this.$router.push('/home')
                            window.location.href = '/home';   
                        }else{
                            window.location.reload();  
                        }
                    } 
                }
            }).catch(function (error) {
                console.log(error);
            }); 
        },        
    }  
};
</script>
