<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Income Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Income Report</h3>                               
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="income_type" class="form-group">
                                        <label for="income_type">Income Type</label>
                                        <v-select
                                                name="income_type"
                                                label="name"
                                                @input="updateIncomeType"
                                                :value="item.income_type"
                                                :options="incomeTypeAllComputed"
                                                />
                                    </div>
                                </div>
                               <div class="col-md-6 col-xs-12">
                                     <div ref="payment_mode" class="form-group">
                                        <label for="payment_mode">Payment Mode</label>
                                        <v-select
                                            id="payment_mode"
                                            name="payment_mode"
                                            label="name"
                                            @input="updatePaymentMode"
                                            :value="item.payment_mode"
                                            :options="paymentModeAll"
                                            />
                                        <span class="error" v-show="errors.has('course')">
                                            {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                        </span>
                                    </div>
                                </div> 
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="search_word">Search by Word</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="search_word"
                                        id="transaction_number"
                                        placeholder="Search by Transaction / Cheque Number / Bank name"
                                        @input="udateSearchWord"
                                        autocomplete="off"
                                        >
                                    
                                </div>       
                                <!--<div class="clearfix"></div>-->
                                <div  class="col-md-6 col-xs-12">
                                  <date-r-picker @updateRange="updateRange" title="Income Date Range"></date-r-picker>
                                </div>
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="report_type">Report Option</label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="report_type"
                                                    ref="report_type"
                                                    v-validate="'required|included:1,2'"
                                                    :value="1"
                                                    :checked="item.report_type === 1"
                                                    @change="updateReportType(1)"
                                                    >
                                            <span class="checkmark"></span> Details
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="report_type"
                                                    ref="report_type"
                                                    :value="2"
                                                    :checked="item.report_type === 2"
                                                    @change="updateReportType(2)"
                                                    >
                                            <span class="checkmark"></span> Summary
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('report_type')">
                                      {{ $root.updateErrorMessage('report_type',this.errors,'Report Option')}}
                                    </span>
                                </div>                                                   
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner 
                            class="std_information_save btn btn-block btn-success new_branch_save"
                            :isLoading="loading"
                            :disabled="loading">                            
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
            <div class="row"  v-if="dataSeen && item.report_type == 2">
                <div class="col-xs-12">  
                    <div v-if="loading" class="panel panel-default">
                      <div class="panel-body">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                </div>
                            </div>
                      </div>
                    </div>  
                    <div v-else-if="summaryDataAll.length == 0">
                        <div class="panel panel-info mypanel">
                            <div class="panel-body">
                                <div class="text-center">
                                      <h3>&#9785;</h3>
                                      <h4>Looks like no data available!</h4>
                                </div>   
                            </div>
                        </div>
                    </div>                                                                   
                    <div v-else class="panel panel-info mypanel">
                      <div class="panel-heading">Summary</div>
                      <div class="panel-body">
                            
                            <div class="col-md-6 col-xs-12"><br>
                                <div class="col-xs-12">
                                    <div class="box mybox">
                                        <div class="box-header with-border">
                                            <h3 class="box-title">Income Report Summary</h3>
                                            <div class="box-tools pull-right">
                                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                                    <i class="fa fa-minus"></i>
                                                </button> 
                                            </div>
                                        </div>                                           
                                        <div class="box-body">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                                </button>     
                                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersSummary">
                                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                                </button>                            
                                            </div>
                                        </div>                                
                                        <div class="box-body">
                                            <div class="row" v-if="loading">
                                                <div class="col-xs-4 col-xs-offset-4">
                                                    <div class="alert text-center">
                                                        <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive setting-group">
                                                <datatable
                                                        v-if="!loading"
                                                        :columns="columnsSummary"
                                                        :data="dataSummary"
                                                        :total="totalSummary"
                                                        :query="querySummary"
                                                        :xprops="xprops"
                                                        />                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                        <table class="table table-hover">
                                            <thead>
                                              <tr>
                                                <th>Payment Mode</th>
                                                <th>Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="value in paymentModeDataFinal">
                                                <td>{{value.payment_mode}}</td>
                                                <td> <i class="fa fa-rupee"></i> {{value.amount}}</td>
                                              </tr>
                                            </tbody>
                                        </table>                        	
                                    <div style="float: right; 
                                        font-weight: bold;
                                        font-size: 1.3em;
                                        border: 1px solid #d2d6de;
                                        padding: 10px;
                                        box-shadow: 1px 1px 1px 1px #afd0b3;">
                                       <span>Total  Amount:</span> 
                                        <span 
                                         style="
                                            padding-top: 0.4em;
                                            padding-right: 0.6em;
                                            padding-bottom: 0.3em;
                                            padding-left: 0.6em;">
                                            <i class="fa fa-rupee"></i>{{totalAmountComputed}}
                                        </span>
                                    </div> 		                    
                            </div>  
                      </div>
                    </div>                           
                </div>
            </div>      
            <div class="row"  v-if="dataSeen && item.report_type == 1">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Detail</h3>
                            <div class="box-tools pull-right">
                                <transition name="bounce">
                                    <div v-if="total != 0" class="btn-group">
                                        <button @click="exportIncomeReportList" type="button" title="Export to Excel" class="btn btn-box-tool dropdown-toggle upper-background" data-toggle="dropdown" aria-expanded="false">
                                            <i class="fa fa-file-excel-o" style="color: #207245; font-size: 2em;"></i>
                                        </button>
                                    </div>
                                </transition>
                            </div>  
                        </div>                     	
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                          <div class="text-right" style="float: right;
                                        width: 250px;
                                        font-weight: bold;
                                        font-size: 1.1em;
                                        border: 1px solid #d2d6de;
                                        padding: 10px;
                                        box-shadow: 1px 1px 1px 1px #afd0b3;">
                              <span>Total  Amount:</span> 
                              <span 
                               style="
                                  padding-top: 0.4em;
                                  padding-right: 0.6em;
                                  padding-bottom: 0.3em;
                                  padding-left: 0.6em;">
                                  <i class="fa fa-rupee"></i> {{totalAmountComputed}}
                              </span>
                          </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>                               
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IncomeReportDBActions from '../../dtmodules/DatatableActions/IncomeReportDBActions'
import components from '../../comps/'
import Amount from '../../dtmodules/Amount'
import DatatableColumnIcon from '../../../store/modules/datatable_column_icon'

IncomeReportDBActions.attachStore('IncomeIndex');

export default {
    components,
    IncomeReportDBActions,
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},        	
            exitDateOptionFormateFirst:{format:window.date_format_moment},  
            dataSeen: false,
            columns: [
                { title: 'Income Type', field:'income_type_text',  sortable: true, thComp: 'FilterTh', tdClass: 'Income Type' },
                { title: 'Payment Mode', field: 'payment_mode_text', sortable: true, thComp: 'FilterTh', tdClass: 'Payment Mode' },
                { title: 'Bank Name', field: 'bank_name', sortable: true, thComp: 'FilterTh', tdClass: 'Bank Name' },
                { title: 'Cheque/Transaction Number', field: 'instrument_no', sortable: true, thComp: 'FilterTh', tdClass: 'Instrument Number' },

                { title: 'Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                                
                // { title: 'Date', field: 'instrument_date', sortable: true, thComp: 'FilterTh', tdClass: 'Transaction Date' },
                { title: 'Total Amount', tdComp: Amount, field: 'amount', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' }, //tdStyle: 'text-align: right;', thStyle: 'text-align: center;'
                { title: 'Actions', tdComp: IncomeReportDBActions, visible: true, thClass: 'text-right', tdClass: '', tdStyle:'text-align: right'}
            ],
            columnsSummary: [
                { title: 'Date', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Holiday Date'},                   
                // { title: 'Date', field: 'instrument_date', sortable: true, thComp: 'FilterTh', tdClass: '' },
                { title: 'Amount', tdComp: Amount, field: 'amount', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name', tdStyle: 'margin-left: 5px;', thStyle: 'margin-left: 5px;' },
            ],             
            querySummary: { },
            query: {  },
            xprops: {
                module: 'IncomeReportIndex',
                route: 'income_report',
                permission_prefix: 'income_report_'
            }            
        }
    },
    created() {
        this.fetchIncomeTypeAll().then(()=>{});
        this.fetchPaymentModeAll().then(()=>{});
        let dateRange = { 
                startDate: moment(),
                endDate: moment(),
            }
        this.updateRange(dateRange)
    },   
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){},
        querySummary: {
            handler(query) {
                this.setQuerySummary(query)
            },
            deep: true
        },
        dataSummary: function(val){}
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('IncomeReportIndex', ['item','data', 'total','dataSummary','totalSummary', 'loading','incomeTypeAll','reportsDataAll','paymentModeAll','summaryDataAll']),
        
        incomeTypeAllComputed() {
            return this.incomeTypeAll;
        },
        summaryDataComputed() {
          var localvar = [];
          this.reportsDataAll.forEach(function(value,index){
                var index = -1;
                var filteredObj = localvar.find(function(item, i){
                  if(item.original_date_a == value.original_date_a){
                    index = i;
                    return i;
                  }
                });
                if(index == -1) {
                  localvar.push({
                    'original_format_date_a': value.original_format_date_a,                    
                    'original_date_a': value.original_date_a,
                    'amount': value.amount
                  });
                } else {
                   localvar[index].amount = parseFloat(localvar[index].amount) + parseFloat(value.amount)  
                }
          }.bind(this))     
          return localvar;
        },   
        paymentModeData() {
          var localvar = [];
          this.reportsDataAll.forEach(function(value,index){
            var index = -1;
            var filteredObj = localvar.find(function(item, i){
              if(item.payment_mode == value.payment_mode){
                index = i;
                return i;
              }
            });
            if(index == -1) {
              localvar.push({
                'payment_mode': value.payment_mode,
                'amount': value.amount
              });
            } else {
               localvar[index].amount = parseFloat(localvar[index].amount) + parseFloat(value.amount)  
            }
          }.bind(this))
          return localvar;
        },
        paymentModeDataFinal() {
            var localPaymentModeAll = this.paymentModeAll;
            this.paymentModeData.forEach(function(value,index){
                    var filteredObj = localPaymentModeAll.find(function(item, i){
                      if(item.value_id == value.payment_mode){
                        value.payment_mode = item.name
                      }
                    });
            })
            return this.paymentModeData;
        },    
        totalAmountComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.amount);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);             
        }, 
    },
    methods: {
        ...mapActions('IncomeReportIndex', ['resetState','fetchIncomeTypeAll','getReportsData','setIncomeType','setQuery','setQuerySummary',
        	'setPaymentMode','setFromDate','setExit_date','setSearchWord','setReportType','fetchPaymentModeAll','setSummaryDataAll','fetchIncomeData','exportIncomeReportListStore']),
        exportIncomeReportList() {
            var dataObj = {
                'income_type': this.item.income_type && this.item.income_type.value_id ? this.item.income_type.value_id : null,
                'payment_mode': this.item.payment_mode ? this.item.payment_mode.value_id : null,
                'search_word': this.item.search_word ? this.item.search_word : null,
                'from_date': this.item.from_date ? this.item.from_date : null,
                'to_date': this.item.to_date ? this.item.to_date : null
            }
            this.exportIncomeReportListStore(dataObj)
        },  
        updateIncomeType(value) {
            this.dataSeen = false;
            this.setIncomeType(value)
            
        },  
        updatePaymentMode(value) {
            this.dataSeen = false;
            this.setPaymentMode(value)
            
        },  
        udateSearchWord(value) {
            this.setSearchWord(value.target.value)
            this.dataSeen = false;
        },  
        updateRange(e){
          let st = moment(e.startDate).format('YYYY-MM-DD')
          let ed = moment(e.endDate).format('YYYY-MM-DD')
          this.setFromDate(st)
          this.setExit_date(ed)
          this.dataSeen = false;
        },
        updateReportType(value) {
            this.setReportType(value)
            this.dataSeen = false;
        },         
        resetFilters(){
            var filtersField= [];
            filtersField = ['income_type_text','payment_mode_text','bank_name','instrument_no','original_date','original_format_date','amount','remarks'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
        },        
        resetFiltersSummary(){
            var filtersField= [];
            filtersField = ['original_date_a','original_format_date_a','amount'];
            this.$root.resetSearchFilters(Vue.delete,this.querySummary,filtersField);
        },           
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.fetchIncomeData()
                    .then(() => {
                        this.setSummaryDataAll(this.summaryDataComputed)
                        this.resetFilters()
                        this.querySummary = {}
                        this.query = {}          
                        DatatableColumnIcon.datatable.setCogIcon();
                    })                     
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}

// console.log($(document).height());

</script>


<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
.mybox {
    border: 1px solid #eee;    
}
</style>
 