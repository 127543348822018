function initialState() {
  return {
      all: [],
      relationships: {
          // "country_name_id": 'country_name',
      },
      query: {},
      loading: false,
      error: false
  }
} 

const getters = {
  data: state => {
      let rows = state.all
      if (state.query.sort) {
          rows = _.orderBy(state.all, state.query.sort, state.query.order)
      }
      var queryFilter = JSON.stringify(state.query);
      var queryFilter = JSON.parse(queryFilter);
         ['school_name', 'school_board', 'country'].forEach(field => {
          switch (typeof queryFilter[field]) {
            case 'array':
              rows = _.filter(rows, function(item){
                if(queryFilter[field].includes(item[field]))
                {
                  return queryFilter[field] ;
                }
              });
              break
              case 'string':
              rows = _.filter(rows, function(item){
                if(item[field] && typeof item[field] === 'number')
                {
                  if(item[field] == queryFilter[field])
                  {
                      return item[field] ;
                  }
                  else if(queryFilter[field] == '')
                  {
                      return item[field] ;   
                  }

                }
                else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                {
                  return item[field] ;
                }
                 else if(queryFilter[field] == '' || queryFilter[field] == null){
                  if(item[field] == '' || item[field] == null){
                      return '  ' ;
                  }
                  else{
                      return item[field]
                  }
                  
                }                  
              });
              break 
            default:
              // nothing to do
              break
          }
        }) 
      return rows.slice(state.query.offset, state.query.offset + state.query.limit)
  },
  total: state => {
      let rows = state.all

      if (state.query.sort) {
          rows = _.orderBy(state.all, state.query.sort, state.query.order)
      }
      var queryFilter = JSON.stringify(state.query);
      var queryFilter = JSON.parse(queryFilter);
         ['school_name', 'school_board', 'country'].forEach(field => {
          switch (typeof queryFilter[field]) {
            case 'array':
              rows = _.filter(rows, function(item){
                if(queryFilter[field].includes(item[field]))
                {
                  return queryFilter[field] ;
                }
              });
              break
              case 'string':
              rows = _.filter(rows, function(item){
                if(item[field] && typeof item[field] === 'number')
                {
                  if(item[field] == queryFilter[field])
                  {
                      return item[field] ;
                  }
                  else if(queryFilter[field] == '')
                  {
                      return item[field] ;   
                  }

                }
                else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                {
                  return item[field] ;
                }
                 else if(queryFilter[field] == '' || queryFilter[field] == null){
                  if(item[field] == '' || item[field] == null){
                      return '  ' ;
                  }
                  else{
                      return item[field]
                  }
                  
                }                  
              });
              break 
            default:
              // nothing to do
              break
          }
        }) 
      return rows.length;
  },
  loading:       state => state.loading,
  relationships: state => state.relationships,
  error: state => state.error
}

const actions = {
  fetchData({ commit, state, dispatch }) {
      commit('setLoading', true)
      dispatch('Alert/resetState', null, { root: true })

      axios.get('/api/v1/branch-lab')
          .then(response => {
              commit('setAll',  response.data.data)
          })
          .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                  }) 
          .finally(() => {
              commit('setLoading', false)
          })
  },
  destroyData({ commit, state }, id) {
    return new Promise((resolve, reject) => {
        axios.delete('/api/v1/school/delete/' + id)
        .then(response => {
            resolve(response);
        })
        .catch(function (error) {
            reject(error)
        })
    })
  },
  setQuery({ commit }, value) {
      commit('setQuery', purify(value))
  },
  resetState({ commit }) {
      commit('resetState')
  },
  
}

const mutations = {
  setAll(state, items) {
      state.all = items
  },
  setLoading(state, loading) {
      state.loading = loading
  },
  setQuery(state, query) {
      state.query = query
  },
  setError(state, error) {
      state.error = error
  },    
  resetState(state) {
      state = Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
