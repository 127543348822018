
<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Holidays</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Holiday</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                    <div class="col-md-7">
                                    <div ref="holiday_date" class="form-group">
	                                    <label for="holiday_date">Holiday Date<p class="astrisk">*</p></label>
                                        <date-picker
                                            :value="item.holiday_date"
                                            :config="$root.dpconfigDate"
                                            name="holiday_date"
                                            v-validate="'required'"
                                            @input="updateHoliday_date"
                                            placeholder="Select Holiday Date"
                                            autocomplete="off"
                                            >
                                        </date-picker>	                                    
	                                     <span class="error" v-show="errors.has('holiday_date')">
	                                              {{ $root.updateErrorMessage('holiday_date',this.errors,'Holiday Date')}}
	                                     </span>
                                    </div>
                                </div>
                              <div class="col-md-7">
                                <div class="form-group">
                                    <label for="holiday_name">Holiday Name<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="holiday_name"
                                            ref="holiday_name"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter Holiday Name"
                                            :value="item.holiday_name"
                                            @input="updateHoliday_name"
                                            >
                                             <span class="error" v-show="errors.has('holiday_name')">
                                              {{ $root.updateErrorMessage('holiday_name',this.errors,'Holiday Name')}}
                                            </span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('HolidaysSingle', ['item', 'loading']),
      
    },
    created() {
        this.fetchData(this.$route.params.id)

    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('HolidaysSingle', ['fetchData', 'updateData', 'resetState', 'setHolidayName', 'setHoliday_date']),
        updateHoliday_name(e) {
            this.setHolidayName(e.target.value)
        },
        updateHoliday_date(e) {
            this.setHoliday_date(e);
        },
        submitForm() {
        this.$validator.validate().then(result => {
        if (result) {
            this.updateData()
                .then((res) => {
                    if(res.data.error_message)
                    {
                        this.$validator.errors.add({
                            id: 'holiday_date',
                            field: 'holiday_date',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'holidays.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }    

    }
}
</script>


<style scoped>

</style>
 