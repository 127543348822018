function initialState() {
    return {
        relationships: {},
        item: {
            student: null,
            gender: 2,
            branch_id: null,
            from_date: null,
            to_date: null,
            another_from_date: null,
            another_to_date: null,            
            course: null,
            type: null,
            status: null,
            reason: null,
        },
        query: {},
        querySummary: {},
        branchMastersAll: [],
        admissionDataAll: [],        
        summaryDataAll: [],        
        courseAll: [],      
        statusAll: [],        
        reasonAll: [],        
        loading: false,
        loadingMAC: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,    
    data: state => {
        let rows = state.admissionDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.admissionDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['row_number','original_date','original_format_date','student_name','gender','father_name','mobile_no','display_course_name','next_followup_date','inqStatus','reason'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.admissionDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.admissionDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['row_number','original_date','original_format_date','student_name','gender','father_name','mobile_no','display_course_name','next_followup_date','inqStatus','reason'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    dataSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','total'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.querySummary.offset, state.querySummary.offset + state.querySummary.limit)
    },
    totalSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','total'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    loading: state => state.loading,
    branchMastersAll: state => state.branchMastersAll,
    admissionDataAll: state => state.admissionDataAll,
    courseAll: state => state.courseAll,
    summaryDataAll: state => state.summaryDataAll,
    statusAll: state => state.statusAll,
    reasonAll: state => state.reasonAll,
    loadingMAC: state => state.loadingMAC,
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async exportInquiryAdmissionReportListStore({ commit }, dataObj) {
      window.location.href = location.protocol + "//" + location.host + '/exportInquiryAdmissionReportList/'
                           + dataObj.branch_id+ '/' 
                           + dataObj.course_id + '/' 
                           + dataObj.another_from_date+ '/' 
                           + dataObj.another_to_date+ '/' 
                           + dataObj.reason+ '/' 
                           + dataObj.status+ '/' 
                           + dataObj.from_date+ '/' 
                           + dataObj.to_date;
    },   
    getReportsData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.item.branch_id)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch_id.id)
            }
            if (_.isEmpty(state.item.course)) {
                params.set('course', '')
            } else {
                params.set('course', state.item.course.id)
            }
            if (_.isEmpty(state.item.status)) {
                params.set('status', '')
            } else {
                params.set('status', state.item.status.value_id)
            }
            if (_.isEmpty(state.item.reason)) {
                params.set('reason', '')
            } else {
                params.set('reason', state.item.reason.value_id)
            }
            if (_.isEmpty(state.item.from_date)) {
                params.set('from_date', '')
            } else {
                params.set('from_date', state.item.from_date)
            }
            if (_.isEmpty(state.item.to_date)) {
                params.set('to_date', '')
            } else {
                params.set('to_date', state.item.to_date)
            }
            if (_.isEmpty(state.item.another_from_date)) {
                params.set('another_from_date', '')
            } else {
                params.set('another_from_date', state.item.another_from_date)
            }
            if (_.isEmpty(state.item.another_to_date)) {
                params.set('another_to_date', '')
            } else {
                params.set('another_to_date', state.item.another_to_date)
            }           
            axios.post('/api/v1/inquiryReportData', params)
                .then(response => {
                    commit('setAdmissionDataAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })      
    },    
    async fetchcourseAll({ commit }) {
        await axios.get('/api/v1/getCourseListForReportsModule')
            .then(response => {                
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },    
    async fetchStatusAll({ commit }) {
        await axios.get('/api/v1/inqueryStatusList')
            .then(response => {
                commit('setStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },    
    async fetchReasonAll({ commit }) {
        await axios.get('/api/v1/reasonTypeList')
            .then(response => {
                commit('setReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },        
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setQuerySummary({ commit }, value) {
        commit('setQuerySummary', purify(value))
    },
    setGender({ commit }, value) {
        commit('setGender', value)
    }, 
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    }, 
    setAnotherJoining_date({ commit }, value) {
        commit('setAnotherJoining_date', value)
    },
    setAnotherExit_date({ commit }, value) {
        commit('setAnotherExit_date', value)
    },     
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },        
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },    
    setSummaryDataAll({ commit }, value) {
        commit('setSummaryDataAll', value)
    },   
    setAdmissionDataAll({ commit }, value) {
        commit('setAdmissionDataAll', value)
    },     
    setReason({ commit }, value) {
        commit('setReason', value)
    },     
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {    
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    setReasonAll(state, value) {
        state.reasonAll = value
    },   
    setReason(state, value) {
        state.item.reason = value
    },     
    setQuerySummary(state, querySummary) {
        state.querySummary = querySummary
    },    
    setError(state, error) {
        state.error = error
    },
    setGender(state, value) {
        state.item.gender = value
    },    
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },    
    setStatusAll(state, value) {
        state.statusAll = value
    },    
    setStatus(state, value) {
        state.item.status = value
    },    
    setJoining_date(state, value) {
        state.item.from_date = value
    },
    setExit_date(state, value) {
        state.item.to_date = value
    },
    setAnotherJoining_date(state, value) {
        state.item.another_from_date = value
    },
    setAnotherExit_date(state, value) {
        state.item.another_to_date = value
    },        
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setCourse(state, value) {
        state.item.course = value
    },    
    setAdmissionDataAll(state, value) {
        state.admissionDataAll = value
    },
    setSummaryDataAll(state, value) {
        state.summaryDataAll = value
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
