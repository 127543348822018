<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Stock Issue</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Stock Issue List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can('inventory_stock_sell_create')"
                                        :to="{ name: 'inventory_item_sell.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                                      
                            </div>
                        </div>
                        <div class="box-body" >
                          <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <label for="category_id">Category</label>
                                  <v-select
                                    name="category_id"
                                    label="name"                                           
                                    :value="item.item_category_id"
                                    :options="categoriesAll"        
                                    @input="updateCategory"
                                    />                                
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label for="item_id">Item</label>
                                  <v-select
                                    name="item_id"
                                    label="name"                                           
                                    :value="item.item_id"
                                    :options="itemsAll"        
                                    @input="updateItem"
                                    />                                
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label for="from_date">From Issue Date</label>
                                  <date-picker
                                      v-model= "filterData.from_date"
                                      :config="$root.dpconfigDate"
                                      name="from_date"
                                      placeholder="Select From Issue Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label for="to_date">To Issue Date</label>
                                  <date-picker
                                      v-model= "filterData.to_date"
                                      :config="$root.dpconfigDate"
                                      name="to_date"
                                      placeholder="Select To Issue Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <label for="sell_to">Sell To</label>
                                  <v-select
                                    name="sell_to"
                                    label="name"
                                    id="sell_to"
                                    @input="updateSellTo"
                                    :value="item.sell_to"
                                    :options="sellToAll"
                                    autocomplete="off"
                                    />                              
                            </div>
                            <div class="col-md-3 col-sm-12">
                              <div v-if="item.sell_to && item.sell_to.value_id != 4" >
                                <label for="sell_to_id">Sell To Name</label>
                                  <v-select
                                    name="sell_to_id"
                                    label="name"
                                    id="item_id"
                                    @input="updateSellToId"
                                    :value="item.sell_to_id"
                                    :options="sellToIdAll"
                                    autocomplete="off"
                                    />                             
                              </div>
                              <div v-if="item.sell_to && item.sell_to.value_id == 4" >
                                <label for="sell_to_id">Sell To Name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="other"
                                    id="other"
                                    placeholder="Enter Sell To Name"
                                    v-model="filterData.other"
                                    autocomplete="off"
                                    >
                              </div>
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <button type="button" class="btn btn-success m-t-25" @click="searchData()">Search</button>
                            
                            </div>
                          </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                              <datatable
                                      v-if="!loading"
                                      :columns="columns"
                                      :data="data"
                                      :total="total"
                                      :query="query"
                                      :xprops="xprops"
                                      />
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import ItemActions from '../../dtmodules/DatatableActions/InventoryItemSellActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'

ItemActions.attachStore('InventoryItemSellIndex');
export default {
    components,
    ItemActions, 
    data() {
        return {
            columns: [
                { title: 'Item Name', field: 'item_name', sortable: true, thComp: 'FilterTh', tdClass: 'Item Name' },
                { title: 'Category Name', field:'item_category_name',  sortable: true, thComp: 'FilterTh', tdClass: 'Category Name' },

                { title: 'Issue Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},
                // { title: 'Issue Date', field: 'sell_date', sortable: true, thComp: 'FilterTh', tdClass: 'Issue Date' },
                
                { title: 'Quantity', field: 'quantity', sortable: true, thComp: 'FilterTh', tdClass: 'Quantity' },
                { title: 'Issue To', field: 'sell_to_name', sortable: true, thComp: 'FilterTh', tdClass: 'Issue To' },
                { title: 'Issue To Name', field: 'sell_to_id_name', sortable: true, thComp: 'FilterTh', tdClass: 'Issue To Name' },
//                { title: 'File', field: 'file', sortable: true, thComp: 'FilterTh', tdClass: 'File' },
//                { title: 'Remarks', field: 'remarks', sortable: true, thComp: 'FilterTh', tdClass: 'Remarks' },
//                { title: 'Created On', field: 'created_at', sortable: true, thComp: 'FilterTh', tdClass: 'Created On' },
                { title: 'Actions', tdComp: ItemActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'InventoryItemSellIndex',
                route: 'inventory_item_sell',
                permission_prefix: 'inventory_stock_sell_'
            },
            filterData:{
              category_id:null,
              item_id:null,
              from_date:null,
              to_date:null,
              sell_to:null,
              sell_to_id:null,
              other:''
            },
        }
    },

    created() {
        this.fetchData()
        this.fetchCategoriesAll()
        this.fetchSellToAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('InventoryItemSellIndex', ['data', 'total', 'loading']),
        ...mapGetters('InventoryItemSellSingle',['item', 'categoriesAll','itemsAll', 'sellToAll', 'sellToIdAll']),

    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        
    },
    methods: {
        ...mapActions('InventoryItemSellIndex', ['fetchData', 'setQuery', 'resetState']),
        ...mapActions('InventoryItemSellSingle',['fetchCategoriesAll','setItemCategoryId','setItemId','setItemsAll','fetchItemsAll','fetchSellToAll', 'setSellTo', 'setSellToIdAll'
          ,'fetchSellToBranch', 'fetchSellToEmployee', 'fetchSellToStudent', 'setSellToId']),
        updateCategory(e) {
            this.setItemCategoryId(e)            
            this.setItemId(null)
            this.setItemsAll([])
            this.filterData.category_id = null
            this.filterData.item_id = null
            if(e){
              this.fetchItemsAll({id:e.value_id,all:0});
              this.filterData.category_id = e.value_id;
            }
        },
        updateItem(e) {
            this.filterData.item_id = null
            this.setItemId(e)
            if(e){
              this.filterData.item_id = e.id
            }
        },
        updateSellTo(e) {
          this.setSellTo(e)            
          this.setSellToIdAll([]);
          this.setSellToId(null);
          this.filterData.sell_to = null
          this.filterData.sell_to_id = null
          this.filterData.other = null
          if(!e){
            return ;
          }
          this.filterData.sell_to = e.value_id
          if(e.value_id == 1){
            this.fetchSellToBranch()
          }else if(e.value_id == 2){
            this.fetchSellToEmployee()
          }else if(e.value_id == 3){
            this.fetchSellToStudent()
          }
        },
        updateSellToId(e) {
            this.setSellToId(e)
            this.filterData.sell_to_id = null
            this.filterData.other = null
            if(e){
              this.filterData.sell_to_id = e.id
            }
        },
        resetFilters(){
            var filtersField= [];
            filtersField = ['item_category_name','item_name','original_date','original_format_date','quantity','file','remarks','created_at','sell_to_name','sell_to_id_name'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.updateCategory(null)
            this.updateItem(null)
            this.updateSellTo(null)
            this.updateSellToId(null)
            this.filterData.from_date = null;
            this.filterData.to_date = null;
            this.filterData.other = null;
            this.fetchData()
            
        },
        searchData(){
          if (this.filterData.from_date) {
            this.filterData.from_date = this.filterData.from_date.replace(/\//g, '-');    
          }
          if (this.filterData.to_date) {
            this.filterData.to_date = this.filterData.to_date.replace(/\//g, '-');
          }
          this.fetchData(this.filterData)
        },
    }
}
</script>


<style scoped>

</style>
