function initialState() {
    return {
        item: {
            quizSkill:[],
            topicAll:[],
            subtopicAll:[],
            id:0,
            project_id:"",
            topic_subtopic_id:"",
            quiz_id:"",
        },
        skillsDetails:[],
        SkillsListing:[],
        projectList:[],
        loading: false,
        quizList:[],
        topicAll:[],
        subTopicAll:[],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    projectList: state => state.projectList,
    topicAll: state => state.topicAll,
    subTopicAll: state => state.subTopicAll,
    quizList: state => state.quizList,
    SkillsListing: state => state.SkillsListing,
    skillsDetails: state => state.skillsDetails,
}

const actions = {
    storeData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if(form_data.project_id) {
                params.set('project_id', form_data.project_id)
            }

            //console.log("dfdf",form_data);
            if(form_data.subtopic_id) {
                params.set('topic_subtopic_id', form_data.subtopic_id.id)
            }
            else{
                params.set('topic_subtopic_id', form_data.topic_id.id)
            }

            if(form_data.quiz_id) {
                params.set('quiz_id', form_data.quiz_id)
            }

            // if (form_data.skillsInit) {
            //     params.set('skills',JSON.stringify(form_data.skillsInit))
            // }

            if((form_data.skillsInit.length) != 0 )
            {
               // console.log("fdfdf",data.fee_adjustments);
               // return false
               form_data.skillsInit.forEach(function(fee, feeIndex){
                   // console.log("loop",fee.fee_type);
                    // return false;
                    if(fee)
                    {
                        params.set('skills['+feeIndex+'][id]',fee.id);    
                        params.set('skills['+feeIndex+'][skill_id]',fee.skills.id);                                
                        params.set('skills['+feeIndex+'][point]',fee.points); 
                        params.set('skills['+feeIndex+'][weightage]',fee.weightage); 
                    }
                    
                })
            }
            else{
                params.set('skills',null);
            }
            
            axiosLms.post('/api/v1/quiz-skill-point', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchQuizSkillData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/quiz-skill-point/' + id)
        .then(response => {
            if(response.data.result) {
                if(response.data.data.quiz_points_details){
                    commit('setSkillsDetails', response.data.data.quiz_points_details)
                }
                else{
                    commit('setSkillsDetails', [])
                }
                
                // commit('setProjectAll', response.data.data.project_id)
                // commit('setTopicsSubtopics', response.data.data.topic_subtopic_id)
                // commit('setQuizAll', response.data.data.quiz_id)
                // commit('setItemId', response.data.data.id)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    updateData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if(state.item.project_id) {
                params.set('project_id', state.item.project_id)
            }

            if(state.item.topic_subtopic_id) {
                params.set('topic_subtopic_id', state.item.topic_subtopic_id)
            }

            if(state.item.quiz_id) {
                params.set('quiz_id', state.item.quiz_id)
            }

            if (state.skillsDetails) {
                params.set('skills',JSON.stringify(state.skillsDetails))
            }
            
            axiosLms.post('/api/v1/quiz-skill-point/'+id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchSkillData({ commit }) {
        await axiosLms.get('/api/v1/skillList')
        .then(response => {
            if(response.data.result){
                commit('setSkillsListing', response.data.data)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    async fetchQuizData({ commit, dispatch, state }, id) {
        await axiosLms.get('/api/v1/quiz/' + id)
        .then(response => {
            if(response.data.result){
                commit('setItem', response.data.data)
                commit('setItemTopicAll', response.data.data.topicAll)
                commit('setItemSubTopicAll', response.data.data.subtopicAll)
                var quizData = response.data.data.quiz;
                if(quizData && response.data.data.question_options){
                    var alldetails = response.data.data.question_options;
                    alldetails.forEach(function(v,i){
                        v.row_number = i+1; 
                        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                        state.IsImageA[i] = 0
                        if(regexp.test(v.options)){
                            state.IsImageA[i] = 1
                        }
                        state.IsImageNext[i] = 0;
                        if(regexp.test(v.option_second)){
                            state.IsImageNext[i] = 1;
                        }
                        if(quizData.value_id != 3){
                            v.option_second = v.correct_answer;
                        }
                    })
                    commit('setOriginalOption', alldetails)
                }
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    setSkillsDetails({ commit }, value) {
        commit('setSkillsDetails', value)
    },
    setSubTopicAll({ commit }, value) {
        commit('setSubTopicAll', value)
    },
    setItemTopicAll({ commit }, value) {
        commit('setItemTopicAll', value)
    },
    setItemSubTopicAll({ commit }, value) {
        commit('setItemSubTopicAll', value)
    },
    setItemQuizSubTopicAll({ commit }, value) {
        commit('setItemQuizSubTopicAll', value)
    },
    setProjectAll({ commit }, value) {
        commit('setProjectAll', value)
    },
    setTopicsSubtopics({ commit }, value) {
        commit('setTopicsSubtopics', value)
    },
    setQuizAll({ commit }, value) {
        commit('setQuizAll', value)
    },
    setQuizSkillId({ commit }, value) {
        commit('setQuizSkillId', value)
    },
    setSkillsListing({ commit }, value) {
        commit('setSkillsListing', value)
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setItemId({ commit }, value) {
        commit('setItemId', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    async updateElements({ commit }, payload) {
        await axiosLms.post('/api/v1/quesUpdateSortOrder', payload)
        .then(response => {
            resolve(response);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
}

const mutations = {
    setItem(state,value){
        state.item = value;
    },
    setItemId(state,value){
        state.item.id = value
    },
    setSkillsDetails(state,value){
        state.skillsDetails = value;
    },
    setSkillsListing(state,value){
        state.SkillsListing = value;
    },
    setProjectAll(state,value){
        state.item.project_id = value;
    },
    setTopicsSubtopics(state,value){
        state.item.topic_subtopic_id = value;
    },
    setQuizAll(state,value){
        state.item.quiz_id = value;
    },
    setQuizSkillId(state, value) {
        state.item.quizSkill = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
