function initialState() {
    return {
        item: {
            id: 0,
            gst_rate: null,
            total_fees: null,
            fees_type: null,
            start_date: null,
            old_start_date:null,
            old_active_id:0,
            gst_start_date:null,
        },
        GSTFeesData: [],
        nextFeeStartDate:null,
        feesTypeAll: [],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    loading: state => state.loading,
    GSTFeesData: state => state.GSTFeesData,
    nextFeeStartDate: state => state.nextFeeStartDate,
    feesTypeAll: state => state.feesTypeAll,
}

const actions = {
    updateBunchData({ commit, state }, finalData) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){
                params.set('fee_type', JSON.stringify(finalData));
            }
            params.set('old_start_date','');
            if(state.item.old_start_date){
                params.set('old_start_date', state.item.old_start_date);
            }
            params.set('old_active_id',0);
            if(state.item.old_active_id){
                params.set('old_active_id', state.item.old_active_id);
            }
            params.set('start_date', '');
            if(state.item.start_date){
                params.set('start_date', state.item.start_date);
            }
            axiosLms.post('/api/v1/gst/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
    EditBunchData({ commit, state }, finalData) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){
                params.set('fee_type', JSON.stringify(finalData));
            }
            params.set('old_start_date','');
            if(state.item.old_start_date){
                params.set('old_start_date', state.item.old_start_date);
            }
            params.set('old_active_id',0);
            if(state.item.old_active_id){
                params.set('old_active_id', state.item.old_active_id);
            }
            params.set('start_date', '');
            if(state.item.start_date){
                params.set('start_date', state.item.start_date);
            }
            axiosLms.post('/api/v1/gst/'+state.item.id, params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    async fetchActiveData({ commit }) {
        await axiosLms.get('/api/v1/getOldActiveRecord')
        .then(response => {
            commit('setItemOldDetails', response.data.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
            reject(error)
        }) 
    },
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList?isDropdown=1')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
    },
    fetchFeesTypeAll({ commit }) {
        axios.get('/api/v1/feesTypeList')
            .then(response => {
                commit('setFeesTypeAll', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
    },
    async fetchData({ commit, dispatch}, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/gst/'+id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setGSTFeesData', response.data.data.gst_management_details)
                commit('setLoading', false)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
    dispatch('fetchFeesTypeAll')
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setNextFeeStartDate({ commit }, value) {
        commit('setNextFeeStartDate', value)
    },
    setStartDate({ commit }, value) {
        commit('setStartDate', value)
    },
    setItemOldDetails({ commit }, value) {
        commit('setItemOldDetails', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item;
    },
    setStartDate(state, value) {
        state.item.start_date = value
    },    
    setItemOldDetails(state, value) {
        state.item.old_start_date = (value.old_start_date) ? value.old_start_date : '';
        state.item.start_date = (value.start_date) ? value.start_date : '';
        state.item.old_active_id = (value.id) ? value.id : 0;
    },
    setGSTFeesData(state, value) {
        state.GSTFeesData = value
    },
    setNextFeeStartDate(state, value) {
        state.nextFeeStartDate = value
    },
    setFeesTypeAll(state, value) {
        state.feesTypeAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
