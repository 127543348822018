function initialState() {
    return {
        item: [],
        loading: false
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading
}

const actions = {
    getReportCard({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/studentProgressReport/'+id)
            .then(response => {
                resolve(response)
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                reject(error)
            })     
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, value) {
        state.item = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
