<template>
    <div class="btn-group btn-group-xs">
        
        <router-link
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>


        <router-link
                v-if="row.status == 1"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(() => {
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })

                }
            })

        },
        convertToInquiry(id) {
            this.$swal({
                title: 'Are you sure you want to convert this record?',
                text: 'You won\'t be able to modify this record after this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Convert To Inquiry',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/convertToInquiry',
                        id
                    ).then(() => {
                        this.$eventHub.$emit('common',1,'Workshop converted to inquiry successfully')
                    })
                    .catch(error => {
                        this.$eventHub.$emit('common',2,error.response.data.message)
                    })
                }
            })

        },
        

    }
}
</script>


<style scoped>

</style>
