<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Student Prospect</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/student-prospect'">Student Prospect</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Student Prospect Status</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style="width: 100%;">Edit Student Prospect Status <span class="pull-right">{{ $route.params.student_name }}</span></h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">                        
                                <form @submit.prevent="submitForm" novalidate>
                                    
                                        <bootstrap-alert />
                                    
                                    <div class="form-group">
                                        <label for="inquiry_status">Next Course Offer<p class="astrisk">*</p></label>
                                        <v-select
                                                name="nextCourse"
                                                label="course_name"
                                                v-validate="'required'"
                                                @input="updateNextCourse"
                                                :disabled="enrolledDisabled"
                                                :value="myData.next_course_id"
                                                :options="nextCourseAll"
                                                />
                                        <span class="error" v-show="errors.has('nextCourse')">
                                        {{ $root.updateErrorMessage('nextCourse',this.errors,'Next Course')}}
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label for="inquiry_status">Student Prospect Status<p class="astrisk">*</p></label>
                                        <v-select
                                                name="status"
                                                label="name"
                                                v-validate="'required'"
                                                @input="updateStatus"
                                                :disabled="enrolledDisabled"
                                                :value="myData.prospect_status"
                                                :options="statusAll"
                                                />
                                        <span class="error" v-show="errors.has('status')">
                                        {{ $root.updateErrorMessage('status',this.errors,'Student Prospect Status')}}
                                        </span>
                                    </div>
                                    <div class="form-group" v-if="followupSeen || myData.prospect_status && myData.prospect_status && myData.prospect_status.value_id == 2">
                                        <label for="next_followup_date">Next Followup Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="myData.next_followup_date"
                                                :config="$root.dpconfigDatetime"
                                                name="next_followup_date"
                                                placeholder="Select Next Followup Date"
                                                @dp-change="updateNext_followup_date"
                                                v-validate="'required'"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('next_followup_date')">
                                        {{ $root.updateErrorMessage('next_followup_date',this.errors,'Next Followup Date')}}
                                        </span>
                                    </div>
                                    <div class="form-group" v-if="reasonSeen || myData.prospect_status && myData.prospect_status && myData.prospect_status.value_id == 3">
                                        <label for="reason">Reason<p class="astrisk">*</p></label>
                                        <v-select
                                                name="reason"
                                                label="name"
                                                @input="updateReason"
                                                :value="myData.reason"
                                                :options="reasonAll"
                                                v-validate="'required'"
                                                />
                                        <span class="error" v-show="errors.has('reason')">
                                        {{ $root.updateErrorMessage('reason',this.errors)}}
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label for="status_remarks">Status Remarks</label>
                                        <textarea
                                                rows="3"
                                                class="form-control"
                                                name="status_remarks"
                                                placeholder="Enter Status Remarks"
                                                :value="myData.status_remarks"
                                                @input="updateStatus_remarks"
                                                >
                                        </textarea>
                                    </div>
                                    <div class="col-md-12 col-md-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div> 
                                </form>
                            </div>
                        </div>
                
                
                        <div class="row" v-if="totalRecords.length > 0">
                            <div class="col-xs-12">
                                <div class="box">
                                    <div class="box-header with-border">
                                        <h3 class="box-title">Prospect Status History</h3>
                                        <div class="box-tools pull-right">
                                            <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                                <i class="fa fa-minus"></i>
                                            </button> 
                                        </div>                            
                                    </div>
                                    <div class="box-body">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                                            </button>                          
                                        </div>
                                    </div>                     
                                    <div class="box-body">
                                        <div class="row" v-if="loading">
                                            <div class="col-xs-4 col-xs-offset-4">
                                                <div class="alert text-center">
                                                    <i class="fa fa-spin fa-refresh"></i> Loading
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive setting-group">
                                            <datatable
                                                    v-if="!loading"
                                                    :columns="columns"
                                                    :data="dataProspect"
                                                    :total="totalProspect"
                                                    :query="query"
                                                    :xprops="xprops"
                                                    />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Prospect Status History</h3>
                        <div class="card-tools">
                            <button type="button" class="btn btn-sm" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="btn-group">
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>                          
                        </div>
                        <div class="table-responsive setting-group">
                            <vue-good-table
                                    ref="table1"
                                    styleClass="vgt-table table table-bordered striped thead-dark"
                                    mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    @on-search="onSearch"
                                    :totalRows="totalRecords"
                                    :pagination-options="{
                                        enabled: true,
                                        perPage:10,
                                        perPageDropdown: [10, 20, 40, 80, 100],
                                        dropdownAllowAll: false,
                                    }"
                                    :isLoading.sync="isLoading"
                                    :select-options="{ 
                                        enabled: false,
                                }"
                                    :search-options="{
                                        enabled: false,
                                        placeholder: 'search',
                                    }"
                                    :rows="rows"
                                    :columns="columns"
                                >
                                   <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                  <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                                    <!-- <template
                                        slot="table-row"
                                        slot-scope="props"
                                    >
                                        <span v-if="props.column.field == 'prospect_status'">
                                            <StudentProspectActions  :xprops="xprops" :row="props.row" :value="props.row.prospect_status"></StudentProspectActions>
                                        </span>
                                        <span v-else-if="props.column.field == 'student_concat_name'">
                                            <StudentName :row="props.row"></StudentName>
                                        </span>
                                        <span v-else>
                                            {{props.formattedRow[props.column.field]}}
                                        </span>
                            </template> -->
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'

export default {
    components,
     components: {
            CustomLoader
        },
    data() {
        return {
            followupSeen: false,
            reasonSeen: false,
            enrolledDisabled: false,
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Date & Time",
                    field: "dis_created_at",
                    filterOptions: {
                        styleClass: 'student_prospect_history_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Date & Time', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Inquiry Status",
                    field: "inquiry",
                    filterOptions: {
                        styleClass: 'student_prospect_history_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Inquiry Status', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Followup Date & Time",
                    field: "next_followup_date1",
                    filterOptions: {
                        styleClass: 'student_prospect_history_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Followup Date & Time', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Reason",
                    field: "reason",
                    filterOptions: {
                        styleClass: 'student_prospect_history_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Reason', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Status Remark",
                    field: "status_remarks",
                    filterOptions: {
                        styleClass: 'student_prospect_history_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Status Remark', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'StudentProspectIndex',
                route: 'student_propsect/:id/status',
                permission_prefix: 'student_propsect_'            
            }                        
        }
    },
    computed: {
        ...mapGetters('StudentProspectIndex', ['dataProspect', 'totalProspect', 'loading', 'relationships', 'item', 'myData','countrymastersAll', 'statemastersAll', 'referenceAll', 'schoolAll', 'standardAll', 'reasonAll','statusAll','inqHistoryAll','all','nextCourseAll']),
    },
    
    created() {
        this.fetchProspectData({student_id: this.$route.params.student_id, 
                                course_id: this.$route.params.course_id,
                                contact_name: this.$route.params.contact_name,
                                contact_no: this.$route.params.contact_no,
                                })
            .then(res => {
                this.jsonDecodeFunction()
            });
        this.fetchNextCourses({student_id: this.$route.params.student_id})
            .then(res => {})
        this.fetchStatusAll();
        this.fetchReasonAll()
        this.fetchProspectStatusHistory({student_id: this.$route.params.student_id, course_id: this.$route.params.course_id});
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },
    // destroyed() {
    //     this.resetState()
    // },
    watch: {
        // query: {
        //     handler(query) {
        //         this.setQuery(query)
        //     },
        //     deep: true
        // },    
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function(){
        	if(this.item && this.item.status && this.item.status.value_id == 3 && !this.enrolledDisabled){
        		this.enrolledDisabled = true;
        	}
        }
    },
    methods: {
        ...mapActions('StudentProspectIndex', ['fetchData', 'updateStatusData', 'setNextCourse', 'resetState', 'setQuery', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchStatusAll','fetchProspectStatusHistory','setinqHistoryAll','fetchNextCourses', 'fetchProspectData','fetchReasonAll']),
          updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems(0);
        },

        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems(0);
        },

        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems(0);
        },

        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems(0);
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems(0);
        },

        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axios
            .get("/api/v1/prospectHistoryData/"+this.$route.params.student_id+"/"+this.$route.params.course_id, {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        },
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            //this.$refs['table1'].reset();
            this.$refs.table1.reset();
            this.loadItems(1);
        },      
        updateGender(value) {
            this.prospect_status_filter = value
            //this.fetchData(this.prospect_status_filter);
            this.loadItems(0);
            this.setGender(value)
        },            
        jsonDecodeFunction() {
            if(typeof this.myData.prospect_status == 'string') {
              this.myData.prospect_status = jQuery.parseJSON(this.myData.prospect_status);
            }
        },
        disabledDates() {
          from: new Date(Date.now())
        },
        updateStatus(value) {
            if(value && (value != '' || value != null))
            {
                if(value.value_id == 2)
                {
                    this.followupSeen = true;    
                    this.reasonSeen = false;
                    this.setReason(null);
                }
                else if(value.value_id == 4)
                {
                    this.followupSeen = false;    
                    this.setNext_followup_date('')
                    this.reasonSeen = false;   
                    this.setReason(null);
                }
                else if(value.value_id == 1)
                {
                    this.followupSeen = false;    
                    this.setNext_followup_date('')
                    this.reasonSeen = false;    
                    this.setReason(null);
                }
                else if(value.value_id == 3)
                {
                    this.followupSeen = false;   
                    this.setNext_followup_date('') ;
                    this.reasonSeen = true;   
                }
            }
            else{
                this.followupSeen = false;
                this.reasonSeen = false;
            }
            this.setStatus(value)
        },
        updateNextCourse(value) {
            this.setNextCourse(value)
        },        
        updateNext_followup_date(e) {
            this.setNext_followup_date(e.target.value)
        },
        updateReason(value) {
            this.setReason(value)
        },
        updateStatus_remarks(e) {
            this.setStatus_remarks(e.target.value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    if(this.myData.prospect_status.value_id == 4){
                        this.$swal({
                            title: 'Are you sure you want to activate this course?',
                            text: 'You won\'t be able to revert this!',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            confirmButtonColor: '#dd4b39',
                            focusCancel: true,
                            reverseButtons: true
                        }).then(result => {
                            if (result.value) {
                                this.updateStatusData()
                                .then((res) => {
                                    if(res.data.error_message)
                                    {                                    
                                        this.$validator.errors.add({
                                            id: 'status',
                                            field: 'status',
                                            msg: res.data.message,
                                            scope: this.$options.scope,
                                        });
                                        return '';
                                    }                                          
                                    if(this.myData.prospect_status.value_id == 4){
                                        this.$router.push({ name: 'student.edit', params: {id: this.$route.params.student_id }})                        
                                        this.$eventHub.$emit('update-success')                            
                                    }
                                    else {
                                        this.$router.push({ name: 'student_prospect.index' })                        
                                        this.$eventHub.$emit('update-success')
                                    }
                                })
                                .catch((error) => {
                                    console.error(error)
                                })
                            }
                        })
                }
                else{
                    this.updateStatusData()
                    .then((res) => {
                        if(res.data.error_message)
                        {
                            this.$validator.errors.add({
                                id: 'status',
                                field: 'status',
                                msg: res.data.message,
                                scope: this.$options.scope,
                            });
                            return '';
                        }
                        if(this.myData.prospect_status.value_id == 4){
                            this.$router.push({ name: 'student.edit', params: {id: this.$route.params.student_id }})                        
                            this.$eventHub.$emit('update-success')                            
                        }
                        else {
                            this.$router.push({ name: 'student_prospect.index' })                        
                            this.$eventHub.$emit('update-success')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })                    
            }
                }
            });
        }
        
    }
}
</script>


<style scoped>

</style>
