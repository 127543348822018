function initialState() {
    return {
        indexAll: [],
        query: {},
        loading: false
    }
} 

const getters = {
    data: state => {
        let rows = state.indexAll
        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','original_date','original_format_date'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.indexAll

        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','original_date','original_format_date'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    indexAll: state => state.indexAll,
    query: state => state.query,
    loading: state => state.loading,
}

const actions = {
    async fetchIndexData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchIndexDataForCancelledAdmission')
                .then(response => {
                    commit('setIndexDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axios.delete('/api/v1/delete-cancel-admission/' + id)
                .then(response => {
                    var rows = state.indexAll
                    rows = rows.filter((item) => {
                        return item.id != id
                    })
                    commit('setIndexDataAll', rows);
                    
                    var queryLocal = purify(state.query);
                    if(rows.length == queryLocal.offset && rows.length != 0)
                    {
                        queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                        commit('setQuery', queryLocal)
                    }
                    resolve(response);
                })
                .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
                    .finally(() => {
                        // commit('setError', false)
                    })
                })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }    
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    setIndexDataAll(state, indexAll) {
        state.indexAll = indexAll
    },     
    resetState(state) {
        state = Object.assign(state, initialState())
    }    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
