<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Training Management Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Training Management Report</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="joining_date" class="form-group">
                                        <label for="joining_date">Date</label>
                                        <date-picker
                                                :value="item.from_date"
                                                :config="$root.dpconfigDate"
                                                name="joining_date"
                                                placeholder="Select From Date"
                                                @dp-change="updateJoining_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('joining_date')">
                                            {{ $root.updateErrorMessage('joining_date',this.errors,'Joining Date')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>                                
                               <div class="col-md-6 col-xs-12">
                                     <div ref="course" class="form-group">
                                        <label for="course">Lab Name</label>
                                        <v-select
                                              name="lab"
                                              label="lab_name"
                                              @input="updateLab"
                                              v-validate="'required'"
                                              :value="item.lab_id"
                                              :options="labsAll" 
                                            />
                                        <span class="error" v-show="errors.has('course')">
                                            {{ $root.updateErrorMessage('course',this.errors,'Lab Name')}}
                                        </span>
                                    </div>
                                </div>
                               <div class="col-md-6 col-xs-12">
                                     <div ref="course" class="form-group">
                                        <label for="course">Batch Name</label>
                                        <v-select
                                            id="batch"
                                            name="batch"
                                            label="batch_name"
                                            :value="item.batch_id"
                                            @input="updateBatchId"
                                            :options="batchesAll"
                                            />
                                        <span class="error" v-show="errors.has('course')">
                                            {{ $root.updateErrorMessage('course',this.errors,'Batch Name')}}
                                        </span>
                                    </div>
                                </div>                                
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="gender">Report Option<p class="astrisk">*</p></label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    v-validate="'required|included:1,2'"
                                                    :value="1"
                                                    :checked="item.gender === 1"
                                                    @change="updateGender(1)"
                                                    >
                                            <span class="checkmark"></span> Details
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="2"
                                                    :checked="item.gender === 2"
                                                    @change="updateGender(2)"
                                                    >
                                            <span class="checkmark"></span> Summary
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('gender')">
                                      {{ $root.updateErrorMessage('gender',this.errors,'Report Option')}}
                                    </span>
                                </div>                                                                        
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner class="std_information_save btn btn-block btn-success new_branch_save">
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
            <div class="row"  v-if="dataSeen && item.gender == 2">
                <div class="col-xs-12">  
                    <div v-if="loading" class="panel panel-default">
                      <div class="panel-body">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                </div>
                            </div>
                      </div>
                    </div>  
                    <div v-else-if="admissionDataAll.length == 0">
                        <div class="panel panel-info mypanel">
                            <div class="panel-body">
                                <div class="text-center">
                                      <h3>&#9785;</h3>
                                      <h4>Looks like no data available!</h4>
                                </div>   
                            </div>
                        </div>
                    </div>                                                                   
                    <div v-else class="panel panel-info mypanel">
                      <div class="panel-heading">
                            Summary
                      </div>
                      <div class="panel-body">
                            <div style="float: right; 
                                font-weight: bold;
                                font-size: 1.3em;
                                border: 1px solid #d2d6de;
                                padding: 10px;
                                box-shadow: 1px 1px 1px 1px #afd0b3;">
                               <span>Total Students:</span> 
                                <span 
                                 style="
                                    padding-top: 0.4em;
                                    padding-right: 0.6em;
                                    padding-bottom: 0.3em;
                                    padding-left: 0.6em;">
                                    {{admissionDataAll.length}}
                                </span>                                                                                                          
                            </div>
                            <div class="col-md-10 col-md-offset-1"><br>
                                <div class="col-xs-12">
                                    <div class="box mybox">
                                        <div class="box-header with-border">
                                            <h3 class="box-title">Student Admission Report Summary</h3>
                                            <div class="box-tools pull-right">
                                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                                    <i class="fa fa-minus"></i>
                                                </button> 
                                            </div>
                                        </div>                                           
                                        <div class="box-body">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                                </button>     
                                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersSummary">
                                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                                </button>                            
                                            </div>
                                        </div>                                
                                        <div class="box-body">
                                            <div class="row" v-if="loading">
                                                <div class="col-xs-4 col-xs-offset-4">
                                                    <div class="alert text-center">
                                                        <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive setting-group">
                                                <datatable
                                                        v-if="!loading"
                                                        :columns="columnsSummary"
                                                        :data="dataSummary"
                                                        :total="totalSummary"
                                                        :query="querySummary"
                                                        :xprops="xprops"
                                                        />                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                         
                      </div>
                    </div>                           
                </div>
            </div>            
            <div class="row" v-if="dataSeen && item.gender == 1">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Student Admission Report Details</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data"
                                    :total="total"
                                    :query="query"
                                    :xprops="xprops"
                                    />                                    
                        </div>
                    </div>
                </div>                
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReportsActions from '../../dtmodules/DatatableActions/ReportsActions'
import components from '../../comps/'

ReportsActions.attachStore('StudentIndex');
export default {
    ReportsActions,      
    components,     
    data() {
        return {
            dataSeen: false,
            count: 0,
            exitDateOptionFormate:{format:window.date_format_moment},      
            columns: [
                { title: '#', field: 'row_number', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Enroll Date', field: 'my_enroll_date', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Active Course', field: 'active_course', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Student Name', field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Date' },
                { title: 'Gender', field: 'gender', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Total Fees', field: 'TotalFees', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Total Paid', field: 'TotalPaid', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Total Balance', field: 'TotalBalance', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Actions', tdComp: ReportsActions, visible: true, thClass: '', tdStyle:'text-align: right' }
            ],
            columnsSummary: [
                { title: 'Enroll Date', field: 'my_enroll_date', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Total Students Enrolled', field: 'total', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' },
            ],            
            query: { sort: 'row_number' },
            querySummary: { /*sort: 'student_name'*/ },
            xprops: {
                module: 'ReportsIndex',
                route: 'student-admission-report',
                permission_prefix: 'reports_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchBranchMasterAll().then(()=>{});
        this.fetchcourseAll().then(()=>{});        
        this.fetchLabsAll().then(()=>{});        
        this.fetchBatches().then(()=>{});        
    },   
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){}, 
        querySummary: {
            handler(query) {
                this.setQuerySummary(query)
            },
            deep: true
        }
    },      
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('TrainingManagementReportIndex', ['item', 'data','total','dataSummary','totalSummary','loading','branchMastersAll','relationships','admissionDataAll','summaryDataAll','courseAll','labsAll','batchesAll']),
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                this.branchMastersAll.unshift({
                    'branch_name': 'All',
                    'id': '0'
                });
            }
            return this.branchMastersAll;
        },
        summaryDataComputed() {
          var localvar = [];
          this.admissionDataAll.forEach(function(value,index){
                var index = -1;
                var filteredObj = localvar.find(function(item, i){
                  if(item.my_enroll_date == value.my_enroll_date){
                    index = i;
                    return i;
                  }
                });
                if(index == -1) {
                  localvar.push({
                    'my_enroll_date': value.my_enroll_date,
                    'total': 1
                  });
                } else {
                   localvar[index].total = parseFloat(localvar[index].total) + parseFloat(1)  
                }
          }.bind(this))
          return localvar;      
        }
    },
    methods: {
        ...mapActions('TrainingManagementReportIndex', ['fetchBranchMasterAll','fetchcourseAll','fetchLabsAll','fetchBatches','resetState','getReportsData','setBranch','setJoining_date','setExit_date','setLab','setGender','setQuery','setQuerySummary','setCourse','setSummaryDataAll','setBatch_id']),

        updateBranch(value) {
            this.setBranch(value)
            this.dataSeen = false;
        },
        updateBatchId(value) {
            this.setBatch_id(value);
        },        
        updateCourse(value){
            this.setCourse(value);
            this.dataSeen = false;
        },
        updateLab(value){
            this.setLab(value)
        },
        updateJoining_date(e) {
            // if(e && e.target.value != ''){            
            //     this.exitDateOptionFormate = {
            //             format:window.date_format_moment,
            //             minDate: e.target.value,
            //             maxDate : false                    
            //        };
            //     this.setExit_date(null)
            // }
            this.setJoining_date(e.target.value)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            this.setExit_date(e.target.value)
            this.dataSeen = false;
        },
        updateGender(value) {
            this.setGender(value)
            this.dataSeen = false;
        },        
        resetFilters(){
            var filtersField= [];
            filtersField = ['row_number','my_enroll_date','active_course','student_name','gender','TotalFees','TotalPaid','TotalBalance'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
        }, 
        resetFiltersSummary(){
            var filtersField= [];
            filtersField = ['my_enroll_date','total'];
            this.$root.resetSearchFilters(Vue.delete,this.querySummary,filtersField);
        },                 
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true;
                    this.getReportsData()
                    .then(() => {
                        this.setSummaryDataAll(this.summaryDataComputed)
                        this.resetFilters()
                        this.querySummary = {}
                        this.query = {}
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.myLabel {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.mybox {
    border: 1px solid #eee;    
}

body {
  counter-reset: section;
}

td::before {
  counter-increment: section;
  content: "Section " counter(section) ": ";
}
</style>
 