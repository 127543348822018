<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Course Fee Adjustments</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/course-fee-adjustments'">Course Fee Adjustments</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Course Fee Adjustment</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Course Fee Adjustment</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th>Branch Name</th>
                                        <td>
                                            <span v-if="setEditCourseFeeData.branch_name !== null">
                                                {{ setEditCourseFeeData.branch_name }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th>Course Name</th>
                                        <td>
                                            <span v-if="setEditCourseFeeData.course_name !== null">
                                                {{ setEditCourseFeeData.course_name }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 
                        <div class=" col-md-12 col-md-offset-1" v-if="(setEditCourseFeeData.course_fee).length > 0">
                        <div class="form-group">
                             <div class="table-responsive">
                                <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="width: 150px;">Fee type</th>
                                                <th style="width: 150px;">Course Fee</th>
                                                <th style="width: 150px;">Discount Amount</th>
                                    
                                                
                                            </tr>
                                        </thead>
                                        <tbody v-for="(feesType, i) in setEditCourseFeeData.course_fee" :key="i">
                                            <tr>
                                                <td>{{feesType.fee_name}}</td>
                                                <td><i data-v-4e2af7c2="" class="fa fa-rupee"></i> {{feesType.fee_amount}}</td>
                                                <td><i data-v-4e2af7c2="" class="fa fa-rupee"></i> {{feesType.discount_amount}}</td>   
                                            </tr>
                                        </tbody>
                                </table>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        // this.fetchShowData(this.$route.params.project_fee_id)
        this.getShowData({'branch_id':this.$route.params.branch_id,'course_id':this.$route.params.course_id});
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CourseFeeAdjustmentSingle', ['item','courseDetailedData','setEditCourseFeeData','loading']),
        
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
        }
    },
    methods: {
        ...mapActions('CourseFeeAdjustmentSingle', ['fetchData','fetchShowData', 'resetState','getShowData']),
        formatedFromDate(dbdate) {
            var local = dbdate.split('-');
            return local[2] + '/' +  local[1] + '/' + local[0]
        }
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
</style>
