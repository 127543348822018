<template>
<div class="popup-form add-country">
<b-modal id="addCourseFaqModel"
             ref="modal"
             title="Add Course FAQ"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <AddFaq @loadItems="loadItems" @closeModel="closeModel"></AddFaq>
</b-modal>   
</div>
</template>
<script>
import AddFaq from '@/components/cruds/Course/Faqs/AddFaq'
export default {
    components:{
        AddFaq
    },
    data() {
        return {
        };
    },
    methods:{
        handleCancel(evt){
           
        },    
        handleOk (evt) {
            
        },
        modalOpened() {
            
        },
        closeModel(){
            this.$refs.modal.hide();
        },
        loadItems(){
            this.$emit('loadItems');
        }
    }
}
</script>