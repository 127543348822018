function initialState() {
    return {
        item: {
            topicAll:[],
            subtopicAll:[],
            project:[],
            question:'',
        },
        topicAll:[],
        subTopicAll:[],
        projectList:[],
        QuestionListing:[],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    topicAll: state => state.topicAll,
    subTopicAll: state => state.subTopicAll,
    projectList: state => state.projectList,
    QuestionListing:state => state.QuestionListing,
}

const actions = {
    fetchTopicAll({ commit }, project_id) {
        axiosLms.get('/api/v1/getTopic/' + project_id)
        .then(response => {
            commit('setTopicAll', response.data.data)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })
    },
    fetchSubTopicAll({ commit }, ids) {
        axiosLms.get('/api/v1/getSubTopic/' + ids.project_id + '/' + ids.topic_id)
        .then(response => {
            commit('setSubTopicAll', response.data.data)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })
    },
    async fetchQuizTopicDetails({ commit, dispatch }, data) {
        await axiosLms.get('/api/v1/getQuizTopicDetail/' + data)
        .then(response => {
            if(response.data.result){
                //console.log(response.data.data);
                if(response.data.data.topic_detail.parent_topic_id != 0){
                    commit('setItemTopicAll', response.data.data.parent_topic_detail)
                    commit('setItemSubTopicAll', response.data.data.topic_detail)
                }
                else{
                    commit('setItemTopicAll', response.data.data.topic_detail)
                    commit('setItemSubTopicAll', [])
                }
            }
        })
        .catch(function (error) {
                console.log('Error', error.message);
        })             
    },
    fetchProjectAll({ commit }) {
        axiosLms.get('/api/v1/getAllProjects')
        .then(response => {
            commit('setProjectAll', response.data.data)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projects/' + id)
        .then(response => {
            if(response.data.result){
                commit('setProjectId', response.data.data)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.project)) {
            params.set('project_id', '')
        } else {
            params.set('project_id', state.item.project.id)
        }

        if (state.item.subtopicAll && state.item.subtopicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.subtopicAll.id)
        } else if (state.item.topicAll && state.item.topicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.topicAll.id)
        } else{
            params.set('topic_subtopic_id', '')
        }
        
        if (_.isEmpty(state.item.question)) {
            params.set('question', '')
        } else {
            params.set('question', state.item.question)
        }
       
        axiosLms.post('/api/v1/tutor-task', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchQuestionData({ commit, dispatch }, data) {
        if(data){
            await axiosLms.get('/api/v1/getTutoreBytopicSubtopic/' + data.id)
            .then(response => {
                if(response.data.result) {
                    commit('setQuestionListing', response.data.data)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })
        }
    },
    async fetchTutorData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/tutor-task/' + id)
        .then(response => {
            if(response.data.result) {
                commit('setItem', response.data.data)
                // commit('setItemSubTopicAll', response.data.data.subTopicAll)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            axiosLms.delete('/api/v1/tutor-task/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            })
        })
    },
    updateData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.project)) {
            params.set('project_id', '')
        } else {
            params.set('project_id', state.item.project.id)
        }

        if (state.item.subtopicAll && state.item.subtopicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.subtopicAll.id)
        } else if (state.item.topicAll && state.item.topicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.topicAll.id)
        } else{
            params.set('topic_subtopic_id', '')
        }
        
        if (_.isEmpty(state.item.question)) {
            params.set('question', '')
        } else {
            params.set('question', state.item.question)
        }
        
        axiosLms.post('/api/v1/tutor-task/' + id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async updateElements({ commit }, payload) {
        await axiosLms.post('/api/v1/tutorTaskUpdateSortOrder', payload)
        .then(response => {
            resolve(response);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    async checkQuestion({ commit }, data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {   
            axiosLms.post('/api/v1/checkQuestion/'+data.id, {'question':data.question})
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        });
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    },
    setTopic({ commit }, value) {
        commit('setTopic', value)
    },
    setSubTopic({ commit }, value) {
        commit('setSubTopic', value)
    },
    setTopicAll({ commit }, value) {
        commit('setTopicAll', value)
    },
    setSubTopicAll({ commit }, value) {
        commit('setSubTopicAll', value)
    },
    setItemTopicAll({ commit }, value) {
        commit('setItemTopicAll', value)
    },
    setItemSubTopicAll({ commit }, value) {
        commit('setItemSubTopicAll', value)
    },
    setProjectId({ commit }, value) {
        commit('setProjectId', value)
    },
    setQuestion({ commit }, value) {
        commit('setQuestion', value)
    },
    setQuestionListing({ commit }, value) {
        commit('setQuestionListing', value)
    },
    setRemoveItem({ commit }, value) {
        commit('setRemoveItem', value)
    },
}

const mutations = {
    setItem(state,value) {
        state.item = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setTopic(state, value) {
        state.item.topicAll = value
    },
    setSubTopic(state, value) {
        state.item.subtopicAll = value
    },
    setTopicAll(state,value) {
        state.topicAll = value
    },
    setSubTopicAll(state,value) {
        state.subTopicAll = value
    },
    setItemTopicAll(state, value) {
        state.item.topicAll = value
    },
    setItemSubTopicAll(state, value) {
        state.item.subtopicAll = value
    },
    setProjectAll(state,value) {
        state.projectList = value;
    },
    setProjectId(state, value) {
        state.item.project = value;
    },
    setQuestion(state, value) {
        state.item.question = value
    },
    setRemoveItem(state,value) {
        state.item.project = [];
        state.item.question = '';
    },
    setQuestionListing(state,value) {
        state.QuestionListing = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
