<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Daily Digest Training Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Daily Digest Training Report</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>
                               <div class="col-md-6 col-xs-12">
                                     <date-r-picker @updateRange="updateRange"></date-r-picker>
                                </div> 
                                     
                                <div class="clearfix"></div>
                                                                                   
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner 
                            class="std_information_save btn btn-block btn-success new_branch_save"
                            :isLoading="loading"
                            :disabled="loading">                            
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
                 
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Present Student</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersPresentData">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="queryPresentData"
                                        :xprops="xprops"
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Absent Student</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">                                 
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersAbsentData">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="absentDataTableData"
                                        :total="absentTotal"
                                        :query="queryAbsentData"
                                        :xprops="xprops"
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Assessment Done Student</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">                                 
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersAssessmentDoneData">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                            <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="assessmentDoneDataTableData"
                                    :total="assessmentDoneTotal"
                                    :query="queryAssessmentDoneData"
                                    :xprops="xprops"
                                    />                                   
                        </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Attendance Not Done Student</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">                                 
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersAttendanceNotDone">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="attendanceNotDoneDatatableData"
                                        :total="attendanceNotDoneTotal"
                                        :query="queryAttendanceData"
                                        :xprops="xprops"
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Assessment Not Done Student</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">                                 
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersAssessmentDue">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="assessementDueDatatableData"
                                        :total="assessementDueTotal"
                                        :query="queryAssessmentData"
                                        :xprops="xprops"                                    
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Previous days attendance and assessment pending students</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <!-- <div class="btn-group">                                 
                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersPreviousDue">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="previousDueDatatableData"
                                        :total="previousDueTotal"
                                        :query="queryPreviousDue"
                                        :xprops="xprops"
                                        />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                                                           
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import components from '../../comps/'
import DateRangePicker from 'vue2-daterange-picker'
import '../../comps/vue-daterange-picker.min.css'
import TopicStatusTags from '../../dtmodules/TopicStatusTags'

components['date-range-picker'] = DateRangePicker;
import StudentName from '../../dtmodules/StudentName'
import DatatableColumnIcon from '../../../store/modules/datatable_column_icon'
export default {
    components,
    TopicStatusTags,    
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},
            exitDateOptionFormateFirst:{format:window.date_format_moment},  
            dataSeen: false,
            columns: [
                // { title: '#', field: 'srno', sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Type' },
                { title: 'Student Name', tdComp:StudentName, field: 'student_name', sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Type' },
                { title: 'Course Name', field: 'course_name', sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Date' }
,                { title: 'Batch Name', field: 'batch_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Lab Name', field: 'lab_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },

                { title: 'Schedule Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},
                // { title: 'Schedule Date', field: 'schedule_date', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Attendance', field: 'attendance', sortable: true, thComp: 'FilterTh', tdClass: 'Father Name' },
                // { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Father Mobile' },
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'inqStats'},
                { title: '', tdComp: TopicStatusTags, thClass: 'inqStats', tdClass: 'Inquiry Status'},

                // { title: 'Assessment', field: 'display_topic_status', sortable: true, thComp: 'FilterTh', tdClass: 'Father Mobile' },
                { title: 'Trainer Name', field: 'def_trainer_name', sortable: true, thComp: 'FilterTh', tdClass: 'Father Mobile' },
            ],
            queryPresentData: { sort: 'srno', order: 'desc' },
            queryAbsentData: { sort: 'srno', order: 'desc' },
            queryAssessmentDoneData: { sort: 'srno', order: 'desc' },
            queryAttendanceData: { sort: 'srno', order: 'desc' },
            queryAssessmentData: { sort: 'srno', order: 'desc' },
            queryPreviousDue: { sort: 'srno', order: 'desc' },
            xprops: {
                module: 'DailyDigestTrainingReportIndex',
                route: 'daily_digest_training_report',
                permission_prefix: 'daily_digest_training_report_'
            },
            dateRange: { // used for v-model prop
                startDate: moment(),
                endDate: moment(),
            },
            
        }
    },
    created() {
        this.fetchBranchMasterAll().then(()=>{});
        this.updateRange(this.dateRange)
    },   
    watch: {
        queryPresentData: {
            handler(query) {
                this.setQueryPresent(query)
            },
            deep: true
        },
        queryAbsentData: {
            handler(query) {
                this.setQueryAbsent(query)
            },
            deep: true
        },
        queryAssessmentDoneData: {
            handler(query) {
                this.setQueryAssessmentDone(query)
            },
            deep: true
        },        
        queryAttendanceData: {
            handler(query) {
                this.setQueryAttendance(query)
            },
            deep: true
        },
        queryAssessmentData: {
            handler(query) {
                this.setQueryAssessment(query)
            },
            deep: true
        },
        queryPreviousDue: {
            handler(query) {
                this.setQueryPrevious(query)
            },
            deep: true
        },                                
        // data: function(val){}        
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('DailyDigestTrainingReportIndex', ['presentData','item','data', 'total','absentDataTableData','absentTotal','assessmentDoneDataTableData','assessmentDoneTotal','attendanceNotDoneTotal','assessmentDueData','attendanceNotDoneDatatableData','assessementDueTotal','assessementDueDatatableData','previousDueDatatableData','previousDueTotal','totalSummary', 'loading','branchMastersAll','itemsAll']),
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                // this.branchMastersAll.unshift({
                //     'branch_name': 'All',
                //     'id': '0'
                // });
            }
            return this.branchMastersAll;
        },
         
    },
    methods: {
        ...mapActions('DailyDigestTrainingReportIndex', ['resetState','fetchBranchMasterAll','getReportsData','setBranch','setQueryPresent','setQueryAbsent','setQueryAssessmentDone','setQueryAttendance','setQueryAssessment','setQueryPrevious',
            'setFromDate','setExit_date','setReportType','fetchReportData',,'fetchAdmissionData','fetchDailyDigestTrainingReportData','fetchCourseCompleteData','fetchFeeCollectionData','attendanceNotDoneData']),
        updateBranch(value) {
            this.setBranch(value)
            this.dataSeen = false;
        },
        updateRange(e) {
            this.dataSeen = false;
            this.updateFromDate(e.startDate)
            this.updateExit_date(e.endDate)
        },  
        updateFromDate(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setFromDate(date)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setExit_date(date)
            this.dataSeen = false;
        },             
        resetFiltersPresentData(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryPresentData,filtersField);
            // this.fetchData()
        },
        resetFiltersAbsentData(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryAbsentData,filtersField);
            // this.fetchData()
        },
        resetFiltersAssessmentDoneData(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryAssessmentDoneData,filtersField);
            // this.fetchData()
        },        
        resetFiltersAssessmentDue(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryAssessmentData,filtersField);
            // this.fetchData()
        },
        resetFiltersAttendanceNotDone(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryAttendanceData,filtersField);
            // this.fetchData()
        },
        resetFiltersPreviousDue(){
            var filtersField= [];
            filtersField = ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'];
            this.$root.resetSearchFilters(Vue.delete,this.queryPreviousDue,filtersField);
            // this.fetchData()
        },                                
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.resetFiltersPresentData()
                    this.resetFiltersAbsentData()
                    this.resetFiltersAssessmentDoneData()
                    this.resetFiltersAssessmentDue()
                    this.resetFiltersAttendanceNotDone()
                    this.resetFiltersPreviousDue()
                    this.queryPresentData = {}                    
                    this.queryAbsentData = {}                    
                    this.queryAssessmentDoneData = {}                    
                    this.queryAttendanceData = {}                    
                    this.queryAssessmentData = {}                    
                    this.queryPreviousDue = {}                    
                    this.fetchDailyDigestTrainingReportData()
                        .then(() => { 
                            DatatableColumnIcon.datatable.setCogIcon()
                        })                     
                        .catch((error) => {
                            console.error(error)
                        })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
.mybox {
    border: 1px solid #eee;    
}

</style>
<style>
  .date-range-css .row{
    margin-left: 0px;
  }
  .date-range-css .reportrange-text{
    border-radius: 4px;
  }
</style>
 