<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventSkills"
            ref="modal"
            title="Add/Edit Quiz Skills"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"  
            @cancel="handleCancel"
            @shown="openModel" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true"
        >
            <section class="content">
                <form @submit.stop.prevent="handleSubmit" >
                    <div class="container-fluid">
                        <div class="row" style="justify-content: end;">
                            <button type="button" class="btn btn-block btn-success col-2" @click="AddNewLine()">+ Add Skill</button>
                        </div>
                        <hr/>
                        <div class="row" v-if="skillsInit.length > 0">
                            <div class="col-md-12 p-2" v-for="(row, index) in skillsInit" :key="index">
                                <div class="row">
                                    <div class="col-md-3">
                                        <v-select
                                            :id="'skill_name_'+index"
                                            :name="'skill_name_'+index"
                                            label="skill_name"
                                            v-model="row.skills"
                                            v-validate="'required'"
                                            :options="SkillsListing"
                                            @input="changeSkill"
                                            placeholder="Select Skill"
                                        />
                                        <span class="error" v-show="errors.has('skill_name_'+index)">
                                            {{ $root.updateErrorMessage('skill_name_'+index,errors,'Skill Name')}}
                                        </span>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-input 
                                            maxlength="6"
                                            id="points"
                                            type="text"
                                            :name="'points_'+index"
                                            class="points"
                                            v-validate="'required|min_value:1'"
                                            oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');"
                                            v-model="row.points"
                                            placeholder="Enter Points"
                                            >
                                        </b-form-input>
                                        <span class="error" v-show="errors.has('points_'+index)">
                                            {{ $root.updateErrorMessage('points_'+index,errors,'Points')}}
                                        </span>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-input 
                                            maxlength="6"
                                            type="text"
                                            :name="'weightage_'+index"
                                            class="weightage"
                                            v-validate="'required|min_value:0.1'"
                                            oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');"
                                            v-model="row.weightage"
                                            placeholder="Enter weightage"
                                            >
                                        </b-form-input>
                                         <span class="error" v-show="errors.has('weightage_'+index)">
                                            {{ $root.updateErrorMessage('weightage_'+index,errors,'Weightage')}}
                                        </span>
                                    </div> 
                                    <div class="col-md-1">
                                        <button type="button" title="Remove" class="btn btn-block btn-danger w-40 add_course_btn" @click="removeRow(index)"><i class="fa fa-close"></i></button>
                                    </div>
                                </div>
                            </div>
                             <span class="error  col-md-12" style="display:block;" v-if="this.skill_c_error != ''">
                                {{ 
                                skill_c_error
                                }}
                                </span>
                        </div>
                        <div class="text-align-center" v-else>
                            <span>Please add Quiz Skills Details.</span>
                        </div>
                    </div>
                </form>
            </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : ['project_id', 'topic_id', 'subtopic_id', 'quiz_id'],
    data() {
        return {
            skillsInit:[{'id':0,'skills':null,'points':'', 'weightage':'','row_number':1}],
            isError:[],
            isErrorSkill:[],
            isErrorPoints:[],
            isErrorWeightage:[],
            skill_c_error:'',
        }
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('QuizSkillSingle', ['item', 'loading', 'SkillsListing', 'skillsDetails'])
    },
    watch:{
        skillsDetails(value){
            // console.log("fdfd",value);

            if(value.length != 0){
                this.skillsInit = [];
                let _this = this;
                // console.log("sdds",value.adjusment_details)
                value.map((fee, feeIndex)=>{
                //console.log("sdsd",fee)
                _this.skillsInit.push({'id':0,'skills':fee.skills,'points':Number(fee.points), 'weightage':Number(fee.weightage),'row_number':1});
                    ///console.log("dff",fee,feeIndex)
                    
                });
            }
        }
    },
    methods: {
        ...mapActions('QuizSkillSingle', ['storeData', 'updateData', 'resetState', 'fetchSkillData', 'setSkillsDetails', 'setProjectAll', 'setQuizAll', 'setTopicsSubtopics', 'fetchQuizSkillData', 'setItemId']),
        AddNewLine(){
            this.skill_c_error = '';
            this.skillsInit.push({'id':0,'skills':'','points':'', 'weightage':'','row_number':1});
            // var num = (this.skillsDetails).length+1;
            // var newRow = {'id':0,'skills':0,'points':'', 'weightage':'','row_number':num}
            // var DetailsLocal = purify(this.skillsDetails);
            // DetailsLocal.push(newRow);
            // this.setSkillsDetails(DetailsLocal);
            // this.isError=[];
        },
        updateSkills(value, row_number){
            this.isError=[];
            this.isErrorSkill=[];
            this.isErrorPoints=[]
            this.isErrorWeightage=[];
            var ths = this
            var checkSkill = this.skillsDetails.findIndex((item) => item.skills.id == value.id && item.row_number != row_number);
            console.log(checkSkill);
            if(checkSkill > 0){
                this.isError[row_number] = true;
            }
        },
        removeRow(index){
            this.skillsInit.splice(index, 1);
            // var DetailsLocal = purify(this.skillsDetails);
            // this.skillsDetails.forEach(function(selectedList,addedIndex){ 
            //     if (selectedList && selectedList.row_number == row_number){
            //         DetailsLocal.splice(addedIndex, 1);
            //         return false;
            //     }
            // }.bind(this))
            // this.setSkillsDetails(DetailsLocal);
        },
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.handleSubmit()
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                } 
            });
        },
        openModel () {
            this.isError=[]
            this.isErrorSkill=[]
            this.isErrorPoints=[]
            this.isErrorWeightage=[]
            this.skill_c_error = '';
            this.skillsInit = [{'id':0,'skills':null,'points':'', 'weightage':'','row_number':1}]
            this.setItemId(0)
            // this.setSkillsDetails([{'id':0,'skills':null,'points':'', 'weightage':'','row_number':1}])
            this.fetchQuizSkillData(this.quiz_id)
            this.setProjectAll(this.project_id)
            var ts_id=0;
            if(this.subtopic_id) {
                ts_id = this.subtopic_id
            }else{
                ts_id= this.topic_id
            }
            this.setTopicsSubtopics(ts_id)
            this.setQuizAll(this.quiz_id)
            this.fetchSkillData()
        },
        changeSkill(){
            this.skill_c_error = ''
        },
        handleCancel(){

            this.setSkillsDetails([])
            this.$emit('closeModels')
        },
        handleSubmit () {
            this.skill_c_error = '';
           //console.
            // if(this.skillsInit.length == 0){
            //    // console.log("dfdf");
            //     this.skill_c_error = "Please add atleast one skill.";
            //     return false;
            // }
            this.$validator.validate().then(result => {
                if (result) {
                    
                    if((this.skillsInit).length != 0){
                        let fee_type_ids = [];
                        let _this = this;
                        this.skillsInit.forEach(function(fee, feeIndex){
                           // console.log(fee);
                        if(fee_type_ids.includes(fee.skills.id)){
                            _this.skill_c_error = "Selected Skill should be unique.";
                            return false;
                        }
                        fee_type_ids.push(fee.skills.id);
                        });
                    }

                    if(this.skill_c_error != ''){
                        return false; 
                    }
                   
                    let params = {
                        'project_id':this.project_id,
                        'subtopic_id':this.subtopic_id,
                        'quiz_id':this.quiz_id,
                        'skillsInit':this.skillsInit,
                        'topic_id':this.topic_id,
                    }
                    this.storeData(params)
                        .then(res => {
                            if(res.data.result == false){
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                return '';
                            }else{
                                this.$refs.modal.hide()
                                this.$eventHub.$emit('create-success')
                                this.$emit('loadItems')
                            }
                        }).catch(function (error) {
                            let message = error.response.data.message || error.message
                            let errors  = error.response.data.errors
                            dispatch(
                                'Alert/setAlert',
                                { message: message, errors: errors, color: 'danger' },
                                { root: true })
                            reject(error)
                        });
                    return false;
                    var ths = this
                    this.isErrorSkill=[];
                    this.isErrorPoints=[]
                    this.isErrorWeightage=[];
                    var finalError = false;
                    this.skillsDetails
                    .forEach(function(v, index) {
                        var r = index + 1;
                        ths.isErrorSkill[index]=false;
                        ths.isErrorPoints[index]=false;
                        ths.isErrorWeightage[index]=false;
                        if(v.skills == 0) {
                            ths.isErrorSkill[index]=true;
                            finalError = true;
                        }
                        if(v.points == '') {
                            ths.isErrorPoints[index]=true;
                            finalError = true;                    
                        }
                        if(v.weightage == '') {
                            ths.isErrorWeightage[index]=true;
                            finalError = true;                    
                        }
                        if(finalError){
                            return false;
                        }
                    })
                    if(this.isError.length == 0 && !finalError){
                        if(this.item.id > 0) {
                            this.updateData(this.item.id)
                            .then(() => {
                                this.$refs.modal.hide()
                                this.$eventHub.$emit('update-success')
                                this.$emit('loadItems')
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                        } else {
                            this.storeData()
                            .then(res => {
                                if(res.data.result == false){
                                    for (const [key, value] of Object.entries(res.data.data)) {
                                        this.$validator.errors.add({
                                            id: key,
                                            field: key,
                                            msg: value[0],
                                            scope: this.$options.scope,
                                        });
                                    }
                                    return '';
                                }else{
                                    this.$refs.modal.hide()
                                    this.$eventHub.$emit('create-success')
                                    this.$emit('loadItems')
                                }
                            }).catch(function (error) {
                                let message = error.response.data.message || error.message
                                let errors  = error.response.data.errors
                                dispatch(
                                    'Alert/setAlert',
                                    { message: message, errors: errors, color: 'danger' },
                                    { root: true })
                                reject(error)
                            })
                        }
                    }
                } else {
                    return this.$root.handleValidationFocus(this.errors, this.$refs);
                }
            });
        }
    }
}
</script>


<style scoped>
.card {
    min-height: 185px !important;
}
.w-40{
    width: 40px;
}
</style>
 