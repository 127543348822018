<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Inquiries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Inquiries List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>


        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Inquiries List</h3>
                    </div>
                    <div class="card-body">
                    <div class="btn-group inquiry-btn">
                            <router-link
                                    v-if="$can(xprops.permission_prefix + 'create')"
                                    :to="{ name: xprops.route + '.create' }"
                                    class="btn btn-success btn-sm"
                                    >
                                <i class="fa fa-plus"></i> Add New
                            </router-link>
                            <!-- <b-btn  v-if="$can(xprops.permission_prefix + 'create')"
                                 v-b-modal.addStudent title="Add Student" id="add_student" type="button" variant="success" class="btn btn-success btn-sm">
                                <i class="fa fa-plus"></i> Add New
                            </b-btn>           -->
                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" v-b-modal.modalInquiryImport  v-if="$can(xprops.permission_prefix + 'create')">
                                <i class="fa-solid fa-file-arrow-down"></i> Import
                            </button>            
                        </div>
                        
                        <div class="row mb-4 align-items-end">
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">From Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="from_date" placeholder="Select from date" v-model="date_filter.from_date" autocomplete="off"></date-picker>
                                <span class="error mb-10" style="position: absolute;" v-if="dateSearchError != ''">
                                    {{dateSearchError}}
                                </span>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <label for="to_date">To Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="to_date" placeholder="Select to date" v-model="date_filter.to_date" autocomplete="off"></date-picker>
                            </div>
                              <div class="col-md-1 col-sm-1">
                                            <div class="form-group">
                                                <label for="status">Assign Me</label><br>
                                                <label class="switch" for="role" style="position: relative;top: 15px;">
                                                <input
                                                        type="checkbox"
                                                        name="user_status"
                                                        value="1"
                                                        v-model="date_filter.assign_me"
                                                        > 
                                                <span class="slider round"></span>
                                                </label>   
                                                </div>
                                            </div>
                            
                            <div class="col-md-1 col-sm-1">
                                <button type="button" class="btn btn-success mt-30 float-right" @click="searchDataDateWise()">Search</button>
                            </div>
                            
                        </div>
                        <div class="clearfix"></div>

                       
                        <bootstrap-alert />
                     
                        <div class="table-responsive inquiry_table setting-group">
                            <vue-good-table
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                ref="table1"
                                mode="remote"
                                :row-style-class="rowStyleClassFn"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                              
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                            >  
                             <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                
                                    <span v-if="props.column.field == 'inquiry_type1'">
                                        <InquiryTypeAction  @loadItems="loadItems" :xprops="xprops" :row="props.row"></InquiryTypeAction>
                                    </span>
                                    <!-- <span v-else-if="props.column.field == 'id'">
                                       
                                    </span> -->
                                    <span v-else-if="props.column.field == 'student_name'">
                                        {{props.row.student_name}}
                                        <!-- <br>Std : {{props.row.standard}}<br>Age : {{props.row.age}} -->
                                    </span>
                                    <span v-else-if="props.column.field == 'contact_name'">
                                        {{props.row.contact_name}}
                                    </span>
                                    <span v-else-if="props.column.field == 'actions'">
                                        <InquiriesActions @setInquiryId="setInquiryId" @loadItems="loadItems" :xprops="xprops" :row="props.row"></InquiriesActions>
                                    </span>
                                    <span v-else-if="props.column.field == 'inqStatus'">
                                        <InquiryAction  @loadItems="loadItems" :xprops="xprops" :row="props.row" :value="props.row.inquiry_status" :field="props.column.field"></InquiryAction>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <TransferInquiries @loadItems="loadItems" @setInquiryId="setInquiryId" :inquiryId="inquiryId"></TransferInquiries>
        <AssignInquiries @loadItems="loadItems" @setInquiryId="setInquiryId" :inquiryId="inquiryId"></AssignInquiries>
        <InquiryHistoryPopup @loadItems="loadItems" :inquiryId="inquiryId"></InquiryHistoryPopup>
        <InquiryImportPopup @loadItems="loadItems"></InquiryImportPopup>
        <!-- <AddCourse></AddCourse> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InquiriesActions from '../../dtmodules/DatatableActions/InquiriesActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import Gender from '../../dtmodules/Gender'
import InquiryAction from '../../dtmodules/InquiryActions'
import InquiryTypeAction from '../../dtmodules/InquiryTypeAction'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
import TransferInquiries from '../../cruds/Inquiries/Transfer'
import AssignInquiries from '../../cruds/Inquiries/Assign'
import InquiryHistoryPopup from '@/components/cruds/Inquiries/InquiryHistoryPopup'
import InquiryImportPopup from '@/components/cruds/Inquiries/InquiryImportPopup'

InquiriesActions.attachStore('InquiriesIndex');
export default {
    components,
    components:{
        InquiriesActions,
        InquiryAction,
        InquiryTypeAction,
        CustomLoader,
        TransferInquiries,
        AssignInquiries,
        InquiryHistoryPopup,
        InquiryImportPopup
    },
    props: ['statusFromProps', 'pickerDates'],
    data() {
        return {
            inquiryId:'',
            isLoading: true,
            refershLoad:false,
            columns: [
                // {
                //     label: "",
                //     field: "id",
                //     sortable: false
                // },
                {
                label: 'Inquiry Type',
                field: 'inquiry_type1',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Inquiry Type', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: ['Cold','Hot'], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Source',
                field: 'source_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Source', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Type',
                field: 'front_inquiry_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Type', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Inquiry Date',
                field: 'original_date',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Inquiry Date', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Student Name',
                field: 'student_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Student Details', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Grade',
                field: 'standard_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Grade', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'School Name',
                field: 'school_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'School Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Contact Name',
                field: 'contact_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Contact Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Contact Number',
                field: 'contact_number',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Contact Number', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'City',
                field: 'city_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'City', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Course',
                field: 'course_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Course', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Assigned To',
                field: 'employee_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Assigned To', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                label: 'Inquiry Status',
                field: 'inqStatus',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Inquiry Status', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: ['Pending','Followup','Enrolled','Closed'], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: 'Organization Name',
                field: 'organization_name',
                filterOptions: {
                        styleClass: 'inquiry_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Organization Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'InquiriesIndex',
                route: 'inquiries',
                permission_prefix: 'inquiry_'
            }, 
            date_filter : {from_date : '', to_date: '',assign_me:false},
            dateSearchError:''
        }
    },
    created() {
        this.$root.relationships = this.relationships;
        // this.loadItems();

        // if(this.statusFromProps) {        
        //     // this.fetchData()
        //     this.fetchTopicAll()
        //         .then(res=>{
        //             for (var i = 0; i < this.topicAll.length; i++) {
        //                 if(this.topicAll[i].value_id == this.statusFromProps) {
        //                     this.date_filter.search_topic_name = this.topicAll[i];
        //                 }
        //             }
        //             this.searchInquiriesData()
        //         })
        // } else {
        //     this.fetchData()
        //     this.fetchTopicAll()
        // }


        // console.log(this.pickerDates);
        // console.log(this.statusFromProps);
        if (this.pickerDates) {
            this.date_filter.from_date = this.pickerDates.from_date
            this.date_filter.to_date = this.pickerDates.to_date
            this.searchDataDateWise()
        }

        if (this.statusFromProps == 1) {
            this.serverParams.columnFilters.inqStatus = 'Pending'
        } else if (this.statusFromProps == 2) {
            this.serverParams.columnFilters.inqStatus = 'Followup'
        } else if (this.statusFromProps == 3) {
            this.serverParams.columnFilters.inqStatus = 'Enrolled'
        } else if (this.statusFromProps == 4){
            this.serverParams.columnFilters.inqStatus = 'Closed'
        } else {
            this.serverParams.columnFilters.inqStatus = ''
        }

        if(this.serverParams.columnFilters.inqStatus != ''){
            this.loadItems();
        }

    },
    destroyed() {
       // this.resetState()
    },
    mounted(){
        if (this.statusFromProps == 1) {
            this.serverParams.columnFilters.inqStatus = 'Pending'
        } else if (this.statusFromProps == 2) {
            this.serverParams.columnFilters.inqStatus = 'Followup'
        } else if (this.statusFromProps == 3) {
            this.serverParams.columnFilters.inqStatus = 'Enrolled'
        } else if (this.statusFromProps == 4){
            this.serverParams.columnFilters.inqStatus = 'Closed'
        } else {
            this.serverParams.columnFilters.inqStatus = ''
        }
    },
    computed: {
        ...mapGetters('InquiriesIndex', ['data', 'total', 'loading', 'relationships','topicAll','all','excelLoader','universalColdFlag','universalHotFlag','hotExcelUpdateDetails','coldExcelUpdateDetails']),
        // ...mapGetters('DashboardIndex', ['item', 'pickerDates']),
    },
    watch: {
        // query: {
        //     handler(query) {
        //         this.setQuery(query)
        //     },
        //     deep: true
        // }
    },
    methods: {
        ...mapActions('InquiriesIndex', ['fetchData', 'setQuery', 'resetState','fetchTopicAll','setColdInquiryExcelFile','setHotInquiryExcelFile','importingColdInquiryExcelStore','setUniversalColdFlag','setUniversalHotFlag','importingHotInquiryExcelStore','deleteTempColdInquiryExcelData','deleteTempHotInquiryExcelData','setExcelLoader','exportInquiryListStore','fetchHotExcelUpdateDetails','fetchColdExcelUpdateDetails','setHotExcelUpdateDetails','setColdExcelUpdateDetails','exportReferenceDataListStore']),
        rowStyleClassFn(row) {
            if(row.children){
                if(row.children.length == 0){
                    return 'child_not_exist';
                }
            }
            return '';
        },
        setInquiryId(id){
            this.inquiryId = id;
        },
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {

            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.refershLoad = true;
                }
            axios.get("/api/v1/inquiries", {
                params: {
                    max: this.serverParams.perPage,
                    page: this.serverParams.page,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:this.serverParams.columnFilters,
                    from_date: this.date_filter.from_date,
                    to_date:this.date_filter.to_date,
                    assign_me:this.date_filter.assign_me
                }
                })
                .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
            });
        }, 

        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"original_date":"","holiday_name":""};
            this.date_filter.from_date='';
            this.date_filter.to_date = '';
            this.dateSearchError = '';
            this.date_filter.assign_me =false;
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        },

       searchDataDateWise(){
        this.dateSearchError = '';
            var start_date = moment(this.date_filter.from_date, "DD/MM/YYYY").toDate();
            var end_date = moment(this.date_filter.to_date, "DD/MM/YYYY").toDate();
            

            // if((this.date_filter.from_date == '' || this.date_filter.to_date == '') && this.date_filter.assign_me == ''){
            //     this.dateSearchError = 'Please select From date and Todate.';
            //     return false;
            // }
            if(end_date < start_date){

                this.dateSearchError = 'To Date greater than or equal to From Date.';
                return false;
            }

            if(start_date > end_date){
                this.dateSearchError = 'From Date less than or equal to To Date.';
                return false;
            }
            this.loadItems();
        }
    }
}
</script>

<style scoped>

ul li {
    margin-left: 10px;
    margin-right: 10px;    
}
.dropdown-buttons {
    width: 150px;
}
.m-t-25 {
    margin-top: 25px
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.upper-background {
    border: 1px solid #008b8b;
    background: #fffafa;
    z-index: 10021 !important;
}
.checkmarkck {
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
}
.containck{
    height: 33px;
    margin-bottom: 0px;
    font-size: unset;
}
.checkmarkck {
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
}
.containck{
    height: 33px;
    margin-bottom: 0px;
    font-size: unset;
}
</style>
