<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
             <div class="col-md-12 col-xs-12 row">
                <label for="project_name" class="col-md-2">Project Name :</label> <span class="col-md-8">{{assessmentTopicDetails.project.project_name}}</span>
            </div>
            <div class="col-md-12 col-xs-12 row">
                <label for="project_topic" class="col-md-2">Topic Name :</label> <span class="col-md-8">{{assessmentTopicDetails.project_topic.topic_name}}</span>
            </div>
            <div class="col-md-12 col-xs-12 row">
                <label for="topic_questions" class="col-md-2">Questions :</label> <span class="col-md-8"><span v-for="(assessment_task,index) in assessmentTopicDetails.assessment_task " :key="assessment_task.id">{{index+1}}) {{assessment_task.question}}<br></span></span></span>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="attendance_date" class="form-group">
                    <label for="attendance_date">Date<p class="astrisk">*</p></label>
                   <date-picker                                            
                        :config="$root.dpconfigDatePast"
                        name="attendance_date"
                        v-model="selected_date"
                        v-validate="'required'"
                        placeholder="Select Date"
                        disabled
                        autocomplete="off"
                        ></date-picker>
                    <span class="error" v-show="errors.has('attendance_date')">
                        {{ $root.updateErrorMessage('attendance_date',this.errors,'Attendance Date')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="remarks" class="form-group">
                    <label for="remarks">Tutor Remarks<p class="astrisk">*</p></label>
                    <textarea
                        type="text"
                        class="form-control"
                        ref="remarks"
                        name="remarks"
                        id="remarks"
                        maxlength="500"
                        v-validate="'required|max:500'"
                        placeholder="Enter remarks"
                        v-model="tutor_remarks"
                        autocomplete="off"
                    />
                    <span class="error" v-show="errors.has('remarks')">
                        {{ $root.updateErrorMessage('remarks',this.errors,'Tutor Remarks')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="attendance_date" class="form-group">
                    <label for="tutor_document">Document / Video</label>
                    <input
                            type="file"
                            name="tutor_document"
                            id="tutor_document"
                            v-validate="'size:2048|ext:mp4,doc,pdf,txt,docx,jpg,png,jpeg'"
                            class="form-control"
                            @change="updateTopcFiles"
                        >
                    <span style="font-size: 13px;"><b>Note :</b> Mp4, doc, pdf, txt, docx, jpg, png, jpeg  files are allowed. Video File size should be less than or equals to 2 MB.</span>
                    <br>
                    <span class="error" v-if="fileError != ''">
                        {{fileError}}
                    </span>
                    <span class="error" v-show="errors.has('tutor_document')">
                        {{ $root.updateErrorMessage('tutor_document',this.errors,'Tutor Document')}}
                    </span>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    props:['id','assessmentTopicId'],
    components: { CommonMasterPopup },
    data() {
        return {
            tutor_remarks:'',
            tutor_document:'',
            fileError:'',
            selected_date:moment().format("DD/MM/YYYY")
        }
    },
    computed: {
        ...mapGetters('StudentAttendanceIndex', ['assessmentTopicDetails','item', 'loading']),
       
    },
    created() {
        console.log(this.id);
        this.fetchAssessmentTopicDetails({'date':this.selected_date,'id':this.id,'assessmentTopicId':this.assessmentTopicId});
    },
    watch:{
        
    },
    mounted() {
       
    },
    methods: {
        ...mapActions('StudentAttendanceIndex', ['storeAssessmentData','fetchAssessmentTopicDetails','storeAssignEmployeeData','setEmployeeDetails','fetchBranchDetails','setBranches','fetchEmployeeDetails']),
        closeModel(){
            this.$emit('closeModel');
        },
        updateTopcFiles(e) {
            this.fileError = '';
            console.log(e.target.files[0]);
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if((e.target.files[0].type == 'video/mp4' || e.target.files[0].size <= 2000000 && e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                    console.log(e.target.files[0]);
                    this.tutor_document = e.target.files[0];
                }else{
                    var message = "";
                    if((e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg' || e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                       // this.fileError = 'File size should be less then 2MB.'
                        this.tutor_document = '';
                    }
                    else {
                        this.fileError = 'Only mp4, Pdf, Text, doc, jpg, png, jpeg  files are allowed.'
                        this.tutor_document = '';
                    }
                }
            }     
            else{
                this.tutor_document = '';
            }      
        },
        submitForm () {
            this.$validator.validate().then(result => {
                
                if (result) {
                    let data = {
                        'attendance_id':this.id,
                        'id': this.assessmentTopicId,
                        'remark' : this.tutor_remarks,
                        'tutor_document' :this.tutor_document,
                        'selectDate':this.selected_date
                    };
                    
                    this.storeAssessmentData(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        if(res.data.data.length > 0){
                             for (const [key, value] of Object.entries(res.data.data)) {
                                // console.log("ke",key,value);
                                if( value !== null ) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                            }
                            return '';    
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                            return false;
                        }
                       
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>