<template> 
    <div>
        <section class="content admission">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 total-width col-sm-6 col-12" title="TOTAL AMOUNT">
                  <div class="info-box">
                    <span class="info-box-icon bg-info"><i class="fa fa-inr"></i></span>

                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL AMOUNT</span>
                      <span class="info-box-number"><i class="fa fa-inr"></i>&nbsp;{{TotalAmount}}</span>
                      <p v-if="CreditPoints>0">
                      <span class="info-box-text" style="color:red;">- PAID USING CREDIT</span>
                      <span class="info-box-number" style="color:red;"><i class="fa fa-inr"></i>&nbsp;{{CreditPoints}}</span>
                      </p>
                      
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-md-3 total-width col-sm-6 col-12" title="TOTAL PAID">
                  <div class="info-box">
                    <span class="info-box-icon bg-success"><i class="fa fa-check"></i></span>

                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL PAID</span>
                      <span class="info-box-number"><i class="fa fa-inr"></i>&nbsp;{{TotalPaid}}</span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-md-3 total-width col-sm-6 col-12" title="TOTAL DUE">
                  <div class="info-box">
                    <span class="info-box-icon bg-warning"><i class="fa fa-clock-o" style="color:#ffff;"></i></span>

                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL DUE</span>
                      <span class="info-box-number"><i class="fa fa-inr"></i>&nbsp;{{TotalDue}}</span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <div class="col-md-3 total-width col-sm-6 col-12" title="TOTAL OVERDUE">
                  <div class="info-box">
                    <span class="info-box-icon bg-primary"><i class="fa fa-stopwatch"></i></span>

                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL OVERDUE</span>
                      <span class="info-box-number"><i class="fa fa-inr"></i>&nbsp;{{TotalOverDue}}</span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-md-3 total-width col-sm-6 col-12" title="TOTAL REFUND">
                  <div class="info-box">
                    <span class="info-box-icon bg-danger"><i class="fa fa-exclamation"></i></span>

                    <div class="info-box-content">
                      <span class="info-box-text">TOTAL REFUND</span>
                      <span class="info-box-number"><i class="fa fa-inr"></i>&nbsp;{{TotalRefund}}</span>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
              </div>
              <div class="row">
                <div class="col-md-12">
                                    <button v-b-modal.modalPreventBatch @click="openAddBatch" class="btn btn-primary float-right edit_delete_btn mr-1">Add Batch</button>
                  <button v-b-modal.modalPreventRefund v-if="$can('refund_create')" @click="openAddRefund" class="btn btn-primary float-right edit_delete_btn mr-1">Add Refund</button>  
                  <button v-b-modal.modalPreventReceipt v-if="$can('receipt_create')" @click="openAddReceipt" class="btn btn-primary float-right edit_delete_btn mr-1">Add Payment</button>                
                </div>                  
              </div>
            </div>
            <div class="container-fluid">
              <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                  <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="custom-tabs-four-adminssion-tab" data-toggle="pill" href="#custom-tabs-four-adminssion" role="tab" aria-controls="custom-tabs-four-adminssion" aria-selected="true" @click="loadadmission('custom-tabs-four-adminssion')">Admission Courses</a>
                    </li>
                    <li class="nav-item" v-if="$can('receipt_create')">
                      <a class="nav-link" id="custom-tabs-four-receipt-tab" data-toggle="pill" href="#custom-tabs-four-receipt" role="tab" aria-controls="custom-tabs-four-receipt" aria-selected="false" @click="loadpayment('custom-tabs-four-receipt')">Payment</a>
                    </li>
                    <li class="nav-item" v-if="$can('refund_create')">
                      <a class="nav-link" id="custom-tabs-four-refund-tab" data-toggle="pill" href="#custom-tabs-four-refund" role="tab" aria-controls="custom-tabs-four-refund" aria-selected="false" @click="loadrefund('custom-tabs-four-refund')">Refund</a>
                    </li>
                    <li class="nav-item" v-if="$can('student_batch_create')">
                      <a class="nav-link" id="custom-tabs-four-refund-tab" data-toggle="pill" href="#custom-tabs-four-batch" role="tab" aria-controls="custom-tabs-four-refund" aria-selected="false" @click="loadbatch('custom-tabs-four-batch')">Batch</a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content appendChild" id="custom-tabs-four-tabContent">
                    <div class="childElement tab-pane fade show active childRow" id="custom-tabs-four-adminssion" role="tabpanel" aria-labelledby="custom-tabs-four-adminssion-tab">
                      <courseDetails :tabShow="tabShow"  v-if="tabShow"></courseDetails>
                    </div>
                    <div class="childElement tab-pane fade childRow" v-if="$can('receipt_access')" id="custom-tabs-four-receipt" role="tabpanel" aria-labelledby="custom-tabs-four-receipt-tab">
                      <Receipt :paymentShow="paymentShow" v-if="paymentShow" ></Receipt>
                    </div>
                    <div class="childElement tab-pane fade childRow" v-if="$can('refund_access')"  id="custom-tabs-four-refund" role="tabpanel" aria-labelledby="custom-tabs-four-refund-tab">
                      <Refund :refundShow="refundShow"  v-if="refundShow" ></Refund>
                    </div>
                        <div class="childElement tab-pane fade childRow" v-if="$can('student_batch_access')"  id="custom-tabs-four-batch" role="tabpanel" aria-labelledby="custom-tabs-four-batch-tab">
                      <Batch :batchShow="batchShow"  v-if="batchShow" ></Batch>
                    </div>
                  </div>
                </div>
                <!-- /.card -->
              </div>
            </div>
        </section>
        <AddReceipt :isAddReceipt="isAddReceipt" :student_id="student_id" :paymentShow="paymentShow" @viewloadpayment="viewloadpayment" @paymentShowTab="paymentShowTab"></AddReceipt>
        <AddRefund :isAddRefund="isAddRefund" :student_id="student_id" :refundShow="refundShow" @viewloadrefund="viewloadrefund" @closeAddTab="closeAddTab" ></AddRefund>
        <AddBatch :isAddBatch="isAddBatch" v-if="isAddBatch" :student_id="student_id" :batchShow="batchShow" @viewloadbatch="viewloadbatch" @closeAddBatchTab="closeAddBatchTab" ></AddBatch>
    </div>                
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import courseDetails from './courseDetails'
import Receipt from './Receipt'
import AddReceipt from '../Receipts/AddReceipt'
import AddRefund from '../Refund/Create'
import Refund from '../Refund/Index'
import Batch from '../Batch/Index'
import AddBatch from '../Batch/Create'

export default {
    components: {
        courseDetails,
        Receipt,
        AddReceipt,
        AddRefund,
        Refund,
         Batch,
        AddBatch
    },   
    props:['adtabshow'],
    watch: {
        "$route.params.id": function() {
            this.student_id = this.$route.params.id;
        },  
        isAddRefund:function(){
          this.isAddRefund = false;
          console.log(this.isAddRefund);
        }  
    },
    created() {
      this.student_id = this.$route.params.id;
      this.fetchStudentCount(this.student_id);      
    },
    computed: {
        ...mapGetters('StudentShowSingle', ['item', 'loading', 'TotalAmount', 'TotalPaid', 'TotalDue','TotalOverDue', 'TotalRefund', 'CreditPoints']),
    },
    data() {
        return {
            tabShow:true,            
            paymentShow:false,
            refundShow:false,
             batchShow:false,
            isAddReceipt:false,
            isAddRefund:false,
             batchShow:false,
             isAddBatch:false,
            student_id:0,
        }
    },
    methods: {
      ...mapActions('StudentShowSingle', ['fetchData', 'fetchStudentCount']),
      openAddReceipt(){
        this.isAddReceipt = true 
       // this.paymentShow = false;
      // this.loadpayment('custom-tabs-four-receipt-tab')
      },
      openAddRefund(){
        this.isAddRefund = true
        this.refundShow = false;
      },
       openAddBatch(){
        this.isAddBatch = true
      },
      paymentShowTab(){
        this.isAddReceipt = false
          this.tabShow = false;
          this.paymentShow = true;   
          this.refundShow = false;
          this.batchShow = false;
          setTimeout(function(){
            $('#custom-tabs-four-receipt-tab').click();
          }, 300);
                
        this.fetchStudentCount(this.student_id);
      },
      closeAddTab(){
        this.isAddRefund = false
        this.tabShow = false;
        this.paymentShow = false;   
        this.refundShow = true;
        this.batchShow = false;
          setTimeout(function(){
            $('#custom-tabs-four-refund-tab').click();
          }, 300);
           
        this.fetchStudentCount(this.student_id);
      },
      closeAddBatchTab(){
        this.isAddBatch = false;
        this.tabShow = false;
        this.paymentShow = false;
        this.refundShow = false;
        this.batchShow = true;
          setTimeout(function(){
            $('#custom-tabs-four-refund-tab').click();
          }, 300);
      },
      loadadmission(tabId){
        this.tabShow = true;
        this.paymentShow = false;   
        this.refundShow = false;
        this.batchShow = false;
        this.fetchStudentCount(this.student_id);
      },
      viewloadpayment(){
        this.tabShow = true;
        this.paymentShow = false;   
        this.paymentShow = false;
        this.refundShow = false;
        this.batchShow = false;
        $('#custom-tabs-four-adminssion-tab').click();
        //this.paymentShowTab();
      },
      loadpayment(tabId){
        this.tabShow = false;
        this.paymentShow = true;   
        this.refundShow = false;
        this.fetchStudentCount(this.student_id);
      },
      loadbatch(tabId){
        this.tabShow = false;
        this.batchShow = true;
        this.refundShow = false;
        this.paymentShow = false;
      },
      viewloadrefund(){
        this.tabShow = true;
        this.paymentShow = false;   
         this.paymentShow = false;
        this.refundShow = false;
        this.batchShow = false;
        $('#custom-tabs-four-adminssion-tab').click();
        //this.closeAddTab();
      },
      viewloadbatch(){
        this.tabShow = true;
        this.paymentShow = false;
        this.refundShow = false;
        this.batchShow = false;
        $('#custom-tabs-four-adminssion-tab').click();
      },
      loadrefund(tabId){
        this.tabShow = false;
        this.paymentShow = false;   
        this.paymentShow = false;
        this.refundShow = true;
        this.batchShow = false;
        this.fetchStudentCount(this.student_id);
      },
      courseloaditems(){
        //..
      },
      refundloaditems(){
        //...
      }

    }
}
</script>
<style scoped>
.admission .container-fluid{
  padding-left:0px;
  padding-right: 0px;
}
.admission .container-fluid .card{
  margin-bottom:0px;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.admission .container-fluid[data-v-caaa758a] {
    padding-left: 0px;
}
.container-fluid{ padding-right: 7.5px; }
.info-box .info-box-icon {
  width: 45px;
}
.total-width{
    flex: 0 0 20% !important;
    max-width: 20% !important;
}
</style>