<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Expenses</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Expense</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="expense_type_id" class="form-group">
                                                <label for="type_">Expense Type<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="expense_type_id"
                                                        label="name"
                                                        id="expense_type_id"
                                                        v-validate="'required'"
                                                        @input="updateExpenseTypeId"
                                                        :value="item.expense_type"
                                                        :options="expenseTypeAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('expense_type_id')">
                                                    {{ $root.updateErrorMessage('expense_type_id',this.errors, 'Expense Type')}}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="payment_mode_id" class="form-group">
                                                <label for="type_">Payment Mode<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="payment_mode_id"
                                                        label="name"
                                                        id="payment_mode_id"
                                                        v-validate="'required'"
                                                        @input="updatePaymentModeId"
                                                        :value="item.payment_mode"
                                                        :options="paymentModeAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('payment_mode_id')">
                                                    {{ $root.updateErrorMessage('payment_mode_id',this.errors, 'Payment Mode')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="item.payment_mode && item.payment_mode.value_id != 1">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="transaction_number">Cheque/Transaction Number<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        name="transaction_number"
                                                        ref="transaction_number"
                                                        id="transaction_number"
                                                        maxlength="100"
                                                        v-validate="'required'"
                                                        placeholder="Enter Cheque/Transaction Number"
                                                        autocomplete="off"
                                                        :value="item.instrument_no"
                                                        @input="updateTransactionNumeber"
                                                        >
                                                        <span class="error" v-show="errors.has('transaction_number')">
                                                          {{ $root.updateErrorMessage('transaction_number',this.errors,'Cheque/Transaction number')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="bank_name">Bank Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="bank_name"
                                                        name="bank_name"
                                                        id="bank_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter Bank Name"
                                                        :value="item.bank_name"
                                                        autocomplete="off"
                                                        @input="updateBankName"
                                                        >
                                                        <span class="error" v-show="errors.has('bank_name')">
                                                          {{ $root.updateErrorMessage('bank_name',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="transaction_date" class="form-group">
                                                <label for="transaction_date">Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    :value= "item.instrument_date"
                                                    
                                                    :config="$root.dpconfigDate"
                                                    name="transaction_date"
                                                    placeholder="Select Date"
                                                    v-validate="'required'"
                                                    autocomplete="off"
                                                    @input="updateInstrumentDate"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('transaction_date')">
                                                    {{ $root.updateErrorMessage('transaction_date',this.errors,'Date')}}
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="amount">Amount<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="amount"
                                                        name="amount"
                                                        id="amount"
                                                        maxlength="17"
                                                        v-validate="'required|min_value:0.01'"
                                                        placeholder="Enter Amount"
                                                        :value="item.amount"
                                                        oninput="this.value=this.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/(\..*)\./g, '$1')
                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                        autocomplete="off"
                                                        @input="updateAmount"
                                                        >
                                                        <span class="error" v-show="errors.has('amount')">
                                                          {{ $root.updateErrorMessage('amount',this.errors,'Amount','a valid value')}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="remarks">Remarks</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="remarks"
                                                        name="remarks"
                                                        id="remarks"
                                                        maxlength="255"
                                                        v-validate="'max:255'"
                                                        placeholder="Enter remarks"
                                                        :value="item.remarks"
                                                        autocomplete="off"
                                                        @input="updateRemarks"
                                                        ></textarea>
                                                        <span class="error" v-show="errors.has('remarks')">
                                                          {{ $root.updateErrorMessage('remarks',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="file">File (Allowed files : jpg,png,jpeg,pdf,csv,ods,xls, xlsx,odt,txt,docx,doc) </label>
                                                <input
                                                        type="file"
                                                        name="file"
                                                        ref="file"
                                                        id="file"
                                                        v-validate="'size:2048|ext:jpg,png,jpeg,pdf,csv,ods,xls,xlsx,odt,txt,docx,doc'"
                                                        class="form-control"
                                                        @change="updateFile"
                                                        v-if="showFileUpload"
                                                    >
                                            <span class="error" v-show="errors.has('file')">
                                              {{ $root.updateErrorMessage('file',this.errors,'file')}}
                                            </span>
                                                <ul v-if="showRemoveFile" class="list-unstyled">
                                                  <li>
                                                 
                                                   <a :href="item.file" class="btn btn-xs btn-primary" target="_blank">View File</a>
                                                  
                                                    <button class="btn btn-xs btn-danger"
                                                            type="button"
                                                            @click="removeFile"
                                                    >
                                                        Remove file
                                                    </button>
                                                  </li>
                                              </ul>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
   
export default {
    data() {

        return {
            transaction_date:'',
            deleted : 0,
            showFileUpload: 1,
            showRemoveFile: 0,
        }
    },
    watch:{
        'item.payment_mode.value_id'(value){
            if(value == 1){
                this.updateTransactionNumeber({target:{value:''}});
                this.updateBankName({target:{value:''}});
            }
        },
        'item.file':function(val){
          if(val && typeof val ==='string'){
            this.showFileUpload = 0
            this.showRemoveFile = 1
          }
        },
    },
    computed: {
        ...mapGetters('ExpenseSingle', ['item', 'loading' , 'expenseTypeAll','paymentModeAll'])
    },
    created() {
        this.fetchData(this.$route.params.id)
        this.fetchExpenseTypesAll()
        this.fetchPaymentModeAll()
        
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('ExpenseSingle', ['updateData', 'setExpenseType', 'fetchData', 'setPaymentMode'
            , 'setExpenseDetails' , 'fetchExpenseTypesAll', 'fetchPaymentModeAll','resetState'
            , 'setInstrumentNo', 'setInstrumentDate', 'setBankName', 'setAmount', 'setRemarks','setFile']),
        updateExpenseTypeId(e) {
//            if(!e){
//                this.setExpenseType({value_id:null,name:''})            
//                return;
//            }
//            console.log(e);
            this.setExpenseType(e)            
        },
        updatePaymentModeId(e) {
//            if(!e){
//                this.setPaymentMode({value_id:null,name:''})            
//                return;
//            }
//            console.log(e);
            this.setPaymentMode(e)            
        },
        updateTransactionNumeber(e) {
            this.setInstrumentNo(e.target.value);
        },
        updateInstrumentDate(e) {
//            console.log(e);
            this.setInstrumentDate(e);
        },
        updateBankName(e) {
            this.setBankName(e.target.value);
        },
        updateAmount(e) {
            this.setAmount(e.target.value);
        },
        updateRemarks(e) {
            this.setRemarks(e.target.value);
            
        },
        updateFile(e) {
          if(e && e.target && e.target.files && e.target.files[0]){
              this.setFile(e.target.files[0]);
              this.showFileUpload = 1
              this.showRemoveFile = 0
          }else{
            this.setFile(null);
          } 
            
        },
        removeFile(){
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setFile('');
                     $("#file").val('');
                     this.deleted = 1;
                     this.showFileUpload = 1
                     this.showRemoveFile = 0
                }
            })
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var expenseDetails = {
                     expense_type: this.item.expense_type.value_id,
                     payment_mode: this.item.payment_mode.value_id,
                     bank_name: this.item.bank_name,
                     instrument_no: this.item.instrument_no,
                     instrument_date: this.item.instrument_date,
                     amount: this.item.amount,
                     remarks: this.item.remarks,
                     file: this.item.file,
                     file_deleted: this.deleted,
                 };
                this.setExpenseDetails(expenseDetails);
                this.updateData()
                     .then((res) => {
                          if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                  id: 'name',
                                  field: 'name',
                                  msg: res.data.message,
                                  scope: this.$options.scope,
                                });

                                return '';    
                            }        
                        this.$router.push({ name: 'expense.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        
    }
}
</script>


<style scoped>

</style>
