<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Schools</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/school-masters'">Schools</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add School</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add School</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="box col-md-12">
                                        <bootstrap-alert />
                                        <div class="box-body row create-flex">
                                            <div class="col-md-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="school_name">School Name<p class="astrisk">*</p></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        name="school_name"
                                                        ref="school_name"
                                                        maxlength="150"
                                                        placeholder="Enter School Name"
                                                        v-validate="'required'"
                                                        @input="updateSchoolName"
                                                        :value="item.schoolName"
                                                    >
                                                    <span class="error" v-show="errors.has('school_name')">
                                                        {{ $root.updateErrorMessage('school_name',this.errors,'School Name')}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="school_board">School Board<p class="astrisk">*</p></label>
                                                    <v-select
                                                        name="school_board"
                                                        label="name"
                                                        placeholder="Select School Board"
                                                        v-validate="'required'"
                                                        @input="updateSchoolBoard"
                                                        :value="item.schoolBoard"
                                                        :options="schoolBoardAll"
                                                    />
                                                    <span class="error" v-show="errors.has('school_board')">
                                                        {{ $root.updateErrorMessage('school_board',this.errors,'School Board')}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="country">Country<p class="astrisk">*</p></label>
                                                    <v-select
                                                        name="country"
                                                        label="country_name"
                                                        placeholder="Select Country"
                                                        v-validate="'required'"
                                                        @input="updateCountry"
                                                        :value="item.country"
                                                        :options="countrymastersAll"
                                                    />
                                                    <span class="error" v-show="errors.has('country')">
                                                        {{ $root.updateErrorMessage('country',this.errors,'Country')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                            type="submit"
                                            :isLoading="loading"
                                            :disabled="loading"
                                        >
                                            Save
                                        </vue-button-spinner>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
   import { mapGetters, mapActions } from 'vuex'
   
   export default {
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters('SchoolMastersSingle', ['item', 'loading', 'countrymastersAll', 'schoolBoardAll'])
        },
        created() {
            this.resetState();
            this.fetchCountrymastersAll()
            this.fetchTypes()
        },
        methods: {
            ...mapActions('SchoolMastersSingle', ['resetState','fetchCountrymastersAll', 'setSchoolName', 'setCountry', 'fetchTypes', 'setSchoolBoard', 'storeData']),

            updateSchoolName(e) {
                this.setSchoolName(e.target.value)
            },
            updateSchoolBoard(value) {
                this.setSchoolBoard(value)
            },
            updateCountry(value) {
                this.setCountry(value)
            },
            closeModel(){
                this.$emit('closeModel');
            },
            submitForm() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.storeData().then((res) => {
                            if(!res.data.result)
                            {
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.buttonLoading = false
                                return '';
                            }
                            this.$router.push({ name: 'school_masters.index' })
                            this.$eventHub.$emit('create-success')
                            //setTimeout(function () { this.$router.push({ name: 'school_masters.index' }) }.bind(this), 1000)                    
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                    } else {
                        return this.$root.handleValidationFocus(this.errors,this.$refs);
                    }
                })
            }
        }
    }
</script>

<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>