<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
            <div class="col-md-12 col-xs-12">
                <div ref="attendance_date" class="form-group">
                    <label for="attendance_date">Date<p class="astrisk">*</p></label>
                   <date-picker                                            
                        :config="$root.dpconfigDatePast"
                        name="attendance_date"
                        v-model="selected_date"
                        v-validate="'required'"
                        placeholder="Select Date"
                        disabled
                        autocomplete="off"
                        ></date-picker>
                    <span class="error" v-show="errors.has('attendance_date')">
                        {{ $root.updateErrorMessage('attendance_date',this.errors,'Attendance Date')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="student" class="form-group" id="student_div">
                    <label for="student">Student<p class="astrisk">*</p></label>
                    <select v-validate="'required'" class="form-control select2 student" name="student" placeholder="Select Student" v-model="student_id" @change="updateStudent">
                            <option v-for="(student, i) in studentDetails" :key="i" :value="student.id">
                                {{student.first_name}} {{student.last_name}} - {{student.enroll_no}}
                            </option>
                    </select>
                    <span class="error" v-show="errors.has('student')">
                        {{ $root.updateErrorMessage('student',this.errors,'Student')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="course" class="form-group" id="course_div">
                    <label for="course">Course<p class="astrisk">*</p></label>
                    <select v-validate="'required'" class="form-control select2 course" placeholder="Select Course" name="course" v-model="course_id" >
                        
                        <option v-for="(course, i) in studentCourseDetails" :key="i" :value="course.id">
                            {{course.course_name}}
                        </option>
                        
                    </select>
                    <span class="error" v-show="errors.has('course')">
                        {{ $root.updateErrorMessage('course',this.errors,'Course')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="batch" class="form-group" id="batch_div">
                    <label for="batches">Batch</label>
                        <select v-validate="'required'" name="batch" label="batch" class="form-control select2 batches" placeholder="Select Batch" v-model="selectedBatch">
                            <!-- <option value="-1" selected>Select Branches</option> -->
                            <optgroup v-for="(batches, index) in dOptions" :key="index" :label="index">
                                <option v-for="(batch, i) in batches" :key="i" :value="batch.id">
                                    {{batch.full_batch_name }}
                                </option>
                            </optgroup>
                        </select>
                    <span class="error" v-show="errors.has('course')">
                        {{ $root.updateErrorMessage('course',this.errors,'Course')}}
                    </span>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    props:['inquiryId'],
    components: { CommonMasterPopup },
    data() {
        return {
            branch_id:'',
            employee_id:'',
            student_id:'',
            course_id:'',
            selectedBatch:'',
            dOptions:[],
            selected_date:moment().format("DD/MM/YYYY")
        }
    },
    computed: {
        ...mapGetters('StudentAttendanceIndex', ['studentBatchDetails','studentCourseDetails','studentDetails','item', 'loading']),
       
    },
    created() {
        // this.setEmployeeDetails();
        this.fetchStudentDetails()
    },
    watch:{
        studentCourseDetails(val){
            let _this = this;
            // $(".course").select2('destroy');
            // $(".course").select2({dropdownParent: $('#course_div'),placeholder: "Select Course",}).on('change', function () {
            //     if($(this).select2("val") != '' || $(this).select2("val") != null){
            //         _this.updateCourse($(this).select2("val"))
            //     }
            // });
        },
        studentBatchDetails(val){
            this.dOptions = val;
            // $(".batches").select2('destroy');
            this.resetselect(true);
        }
    },
    mounted() {
        let _this = this;
        $(".student").select2({dropdownParent: $('#student_div'),placeholder: "Select Student",}).on('change', function () {
            _this.updateStudent($(this).select2("val"))
        });

        $(".course").select2({dropdownParent: $('#course_div'),placeholder: "Select Course",}).on('change', function () {
            if($(this).select2("val") != '' || $(this).select2("val") != null){
                _this.updateCourse($(this).select2("val"))
            }
        });
        this.resetselect();
    },
    methods: {
        ...mapActions('StudentAttendanceIndex', ['storeAttendanceData','fetchStudentBatches','fetchStudentCourseDetails','fetchStudentDetails','storeAssignEmployeeData','setEmployeeDetails','fetchBranchDetails','setBranches','fetchEmployeeDetails']),
        closeModel(){
            this.$emit('closeModel');
        },
        resetselect(destroy=false){
            let _this = this;
            destroy && $(".batches").select2('destroy');
            $(".batches").select2({dropdownParent: $('#batch_div'),placeholder: "Select Batch"}).on('change', function () {
                //_this.updateBranch($(this).select2("val"))
                //console.log("fdfdf",$(this).select2("val"));
                _this.selectedBatch = $(this).select2("val");
            });
        },
        updateStudent(val) {
            this.course_id = '';
            $(".course").val('').trigger('change');
             this.selectedBatch = '';
            $(".batches").val('').trigger('change');
            if(typeof val != 'undefined' && val!='' && val!=null){
                this.student_id = val;
                this.fetchStudentCourseDetails(val);
            }
        },
        updateCourse(val) {
            this.selectedBatch = '';
            $(".batches").val('').trigger('change');
             if(typeof val != 'undefined' && val!='' && val!=null){
                this.course_id = val;
                let params = {
                    'course_id':val,
                    'selectedDate':this.selected_date
                }
                this.fetchStudentBatches(params);
            }
            
        },
        submitForm () {
            this.$validator.validate().then(result => {

                if (result) {
                    let data = {
                        'student_id': this.student_id,
                        'course_id' : this.course_id,
                        'batch_id' :this.selectedBatch,
                        'selectedDate':this.selected_date
                    };
                    this.storeAttendanceData(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        if(res.data.data.length > 0){
                             for (const [key, value] of Object.entries(res.data.data)) {
                                // console.log("ke",key,value);
                                if( value !== null ) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                            }
                            return '';    
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                            return false;
                        }
                       
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>