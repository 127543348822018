function initialState() {
    return {
        item: {
            id: null,
            topic_name: null,
            course_name_id: null,
            sort_order: null,
            is_assessment_required : null,
        },
        coursesAll: [], 
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    coursesAll: state => state.coursesAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
             
         /*    if (_.isEmpty(state.item.country_id)) {
                params.delete('country_id')
            } else {
                for (let index in state.item.country_id) {
                    params.set('country_id['+index+']', state.item.country_id[index].id)
                }
            }*/
/*             params.set('country_id',state.item.country_id['id']);

    */          
    params.set('course_id',state.item.course_name_id['id']);
    params.delete('course_name_id');
                
            axios.post('/api/v1/course-topic-masters', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            //  if (_.isEmpty(state.item.course_id)) {
            //     params.delete('course_id')
            // } else {
            //     for (let index in state.item.course_id) {
            //         params.set('course_id['+index+']', state.item.course_id[index].id)
            //     }
            // }
            params.set('course_id',state.item.course_name_id['id']);
            params.delete('course_name_id');

            axios.post('/api/v1/course-topic-masters/' + state.item.id, params)
                .then(response => {
                    /*commit('setItem', response.data.data)*/
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/course-topic-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })         
            dispatch('fetchCoursesAll');
    },    
    fetchCoursesAll({ commit }) {
        // axios.get('/api/v1/courses')
        axios.get('/api/v1/getCourseListWithOutFullCourse')
            .then(response => {                
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })    
    },
    setIsAssessmentRequired({ commit }, value){
        commit('setIsAssessmentRequired', value)
    },
    setCourseTopicName({ commit }, value) {
        commit('setCourseTopicName', value)
    },
    setCourseId({ commit }, value) {
        commit('setCourseId', value)
    },
    setQuestionNumber({ commit }, value){
        commit('setQuestionNumber', value)
    },    
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setIsAssessmentRequired(state, value) {
        state.item.is_assessment_required = value
    },
    setCourseTopicName(state, value) {
        state.item.topic_name = value
    },
    setCourseId(state, value) {
        state.item.course_name_id = value
    },
    setQuestionNumber(state, value) {
        state.item.sort_order = value
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
