function initialState() {
    return {
        item: {
            user_type: null,
            city: null,
            min_age: null,
            max_age: null,
            image: null,
            image_url: null,
            url: null
        },
        editData:[],
        userAll: [],
        cityAll : [],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    userAll: state => state.userAll,
    cityAll: state => state.cityAll,
    loading: state => state.loading,
    getEditData: state => state.editData
}

const actions = {
    fetchUserAll({ commit, dispatch }) {
        axiosLms.get('/api/v1/userTypeList').then(response => {
            commit('setUserAll', response.data.data) 
        }).catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })    
        dispatch('fetchCityAll');       
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.user_type)) {
                params.set('user_type', '')
            } else {
                params.set('user_type', state.item.user_type.value_id)
            }

            if (_.isEmpty(state.item.city)) {
                params.set('city', '')
            } else {
                params.set('city', state.item.city.id)
                console.log( state.item.city.value);
            }

            if (state.item.min_age) {
                params.set('min_age', state.item.min_age)
            }

            if (state.item.max_age) {
                params.set('max_age', state.item.max_age)
            }

            if (_.isEmpty(state.item.url)) {
                params.set('url', '')
            } else {
                params.set('url', state.item.url)
            }

            axiosLms.post('/api/v1/admin-slider', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    fetchCityAll({ commit }) {
        axios.get('/api/v1/cities/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setCityAll', response.data.data)
                }
                else{
                    commit('setCityAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/admin-slider/' + id)
        .then(response => {
            commit('setItem', response.data.data)
            commit('setEditData', response.data.data)
            commit('setLoading', false)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
            commit('setLoading', false)
        })
        dispatch('fetchCityAll');
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.name)) {
                params.set('user_type', '')
            } else {
                params.set('user_type', state.item.name.value_id)
            }

            if (_.isEmpty(state.item.city)) {
                params.set('city', '')
            } else {
                params.set('city', state.item.city.id)
            }

            if (state.item.min_age) {
                params.set('min_age', state.item.min_age)
            }

            if (state.item.max_age) {
                params.set('max_age', state.item.max_age)
            }

            if (_.isEmpty(state.item.url)) {
                params.set('url', '')
            } else {
                params.set('url', state.item.url)
            }

            axiosLms.post('/api/v1/admin-slider/' + state.item.id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/admin-slider/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },

    setUserAll({ commit }, value) {
        commit('setUserAll', value)
    },
    setUser({ commit }, value) {
        commit('setUser', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setMinAge({ commit }, value) {
        commit('setMinAge', value)
    },
    setMaxAge({ commit }, value) {
        commit('setMaxAge', value)
    },
    setImage({ commit }, value) {
        commit('setImage', value)
    },
    setImageURL({ commit }, value) {
        commit('setImageURL', value)
    },
    setUrl({ commit }, value) {
        commit('setUrl', value)
    },
    setEditData({ commit }, value) {
        commit('setEditData', value)
    },
    setCityAll({ commit }, value) {
        commit('setCityAll', value)
      }, 
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setUserAll(state, value) {
        state.userAll = value
    },
    setUser(state, value) {
        state.item.user_type = value
    },
    setCity(state, value) {
        state.item.city = value
    },
    setCityAll(state, value) {
        state.cityAll = value
    },  
    setMinAge(state, value) {
        state.item.min_age = value
    },
    setMaxAge(state, value) {
        state.item.max_age = value
    },
    setImage(state, value) {
        state.item.image = value
    },
    setImageURL(state, value) {
        state.item.image_url = value
    },
    setUrl(state, value) {
        state.item.url = value
    },
    setEditData(state, item) {
        state.editData = item
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}