function initialState() {
    return {
        all: [],
        query: {},
        hoursQuery: {},
        from_date: null,
        to_date: null,
        dateFilter: null,
        coursesAll: [],
        courseFilter: null,
        loading: false,
        trainerStatisticsShown: false,
        trainerStatisticsFilterInStudentsData: null,
    }
} 

const getters = {
    trainerStatisticsShown: state => state.trainerStatisticsShown,
    coursesAll: state => state.coursesAll,
    all: state => state.all,
    data: state => {
        let rows = state.all.employeeStatisticsStudentsData

        if (state.query.sort) {
            rows = _.orderBy(state.all.employeeStatisticsStudentsData, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['student_name','course_name','topic_name','batch_name','session_date','trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total:         state => {
        let rows = state.all.employeeStatisticsStudentsData

        if (state.query.sort) {
            rows = _.orderBy(state.all.employeeStatisticsStudentsData, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['student_name','course_name','topic_name','batch_name','session_date','trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.length;
    },  
    hoursData: state => {
        let rows = state.all.employeeStatisticsHoursData

        if (state.hoursQuery.sort) {
            rows = _.orderBy(state.all.employeeStatisticsHoursData, state.hoursQuery.sort, state.hoursQuery.order)
        }
        var queryFilter = JSON.stringify(state.hoursQuery);
        var queryFilter = JSON.parse(queryFilter);
          ['trainer_name','trainer_hours','total_assessment_done'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.slice(state.hoursQuery.offset, state.hoursQuery.offset + state.hoursQuery.limit)
    },
    hoursTotal: state => {
        let rows = state.all.employeeStatisticsHoursData

        if (state.hoursQuery.sort) {
            rows = _.orderBy(state.all.employeeStatisticsHoursData, state.hoursQuery.sort, state.hoursQuery.order)
        }
        var queryFilter = JSON.stringify(state.hoursQuery);
        var queryFilter = JSON.parse(queryFilter);
          ['trainer_name','trainer_hours','total_assessment_done'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.length;
    },      
    dateFilter: state => state.dateFilter,
    courseFilter: state => state.courseFilter,
    loading: state => state.loading,
}

const actions = {
    fetchData({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/fetchTrainerStatistics', params)
                .then(response => {
                    commit('setAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error in fetchData', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchAllCourses({commit}) {
        axios.get('/api/v1/fetchAllCourses')
            .then(response => {
                commit('setAllCourses', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error);
            })
    },     
    setDateFilter({ commit }, value) {
        commit('setDateFilter', value)
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },  
    setHoursQuery({ commit }, value) {
        commit('setHoursQuery', purify(value))
    },
    setTrainerStatisticsShown({ commit }, value) {
        commit('setTrainerStatisticsShown', value)
    },        
    setTrainerFilterInStudentsData({ commit }, value) {
        commit('setTrainerFilterInStudentsData', value)
    },    
    setFromDate({ commit }, value) {
        commit('setFromDate', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },       
    setCourseFilter({ commit }, value) {
        commit('setCourseFilter', value)
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, all) {
        state.all = all
    },
    setAllCourses(state, value) {
        state.coursesAll = value
    },    
    setFromDate(state, value) {
        state.from_date = value
    },
    setExit_date(state, value) {
        state.to_date = value
    },
    setTrainerStatisticsShown(state, value) {
        state.trainerStatisticsShown = value
    },
    setTrainerFilterInStudentsData(state, value) {
        state.trainerStatisticsFilterInStudentsData = value;
    },                
    setQuery(state, query) {
        query.trainer_name = state.trainerStatisticsFilterInStudentsData
        state.query = query
    },
    setHoursQuery(state, hoursQuery) {
        state.hoursQuery = hoursQuery
    },        
    setDateFilter(state, dateFilter) {
        state.dateFilter = dateFilter
    },
    setCourseFilter(state, courseFilter) {
        state.courseFilter = courseFilter
    },        
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
