<template>
    <form @submit.prevent="submitForm" novalidate>
        <bootstrap-alert />
        
        <div class="form-group col-md-12 col-xs-12">
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <label for="title">Case Study Name<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="case_study_name"
                        maxlength="90"
                        v-validate="'required'"
                        placeholder="Enter Case Study Name"
                        v-model="case_study_name"
                        @input="updateNameError"
                    >
                    <span class="error" v-show="errors.has('case_study_name')">
                        {{ $root.updateErrorMessage('case_study_name',errors,'Case Study Name')}}
                    </span>
                    <span class="error" v-if="nameError != null">
                        {{ nameError}}
                    </span>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="logo">Background Image<p class="astrisk">*</p></label>
                        <input
                            type="file"
                            name="background_image"
                            id="background_image"
                            v-validate="'required|size:2048|ext:jpg,png,jpeg,svg'"
                            class="form-control"
                            @change="updateBackgroundImage"
                        >
                        <span style="font-size: 12px;"><b>Note :</b> jpg, jpeg, png, svg files are allowed. File size should be less than or equals to 2 MB.</span>
                        <span class="error" v-show="errors.has('background_image')">
                            {{ $root.updateErrorMessage('background_image',this.errors)}}
                        </span>
                    </div>
                </div>
                
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="logo">Slider Image<p class="astrisk">*</p></label>
                        <input
                            type="file"
                            name="case_study_profile"
                            id="case_study_profile"
                            v-validate="'required|size:2048|ext:jpg,png,jpeg,svg'"
                            class="form-control"
                            @change="updateProfileImage"
                        >
                        <span style="font-size: 12px;"><b>Note :</b> jpg, jpeg, png, svg files are allowed. File size should be less than or equals to 2 MB.</span>
                        <span class="error" v-show="errors.has('case_study_profile')">
                            {{ $root.updateErrorMessage('case_study_profile',this.errors,'Slider Image')}}
                        </span>
                        <ul v-if="profile" class="list-unstyled">
                            <li>
                            <div class="" style="">
                                <div v-if="profile != null">
                                    <img :src="profile" id="blahProfile" class="mt-3 student_image profile-user-img my-profile img-responsive" :alt="profile" > 
                                </div>
                            </div>
                                <button class="btn btn-xs btn-danger"
                                    type="button"
                                    @click="removeProfileImage"
                                >
                                    Remove file
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-md-6 col-xs-12" id="">
                    <label for="is_publish">&nbsp;</label>
                    <div class="form-group ds-flex">
                        <div class="custom-control custom-checkbox mr-2">
                            <input class="custom-control-input" type="checkbox" id="is_publish" name="is_publish" @change="updatedCourseCasestudyPublish">
                            <label for="is_publish" class="custom-control-label">Is Course Case Study Publish? </label>
                        </div>
                    </div>
                    
                    <ul v-if="backgroundImage" class="list-unstyled">
                        <li>
                        <div class="" style="">
                            <div v-if="backgroundImage != null">
                                <img :src="backgroundImage" id="blahBackground" class="mt-3 bg-img student_image profile-user-img my-profile img-responsive" :alt="backgroundImage"> 
                            </div>
                        </div>
                            <button class="btn btn-xs btn-danger"
                                type="button"
                                @click="removeBackgroundImage"
                            >
                                Remove file
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="form-group col-md-12 col-xs-12">
            <div class="row" v-if="case_study_details.length > 0">
                <span class="col-md-4 col-xs-12"><b>Title<p class="astrisk">*</p></b></span>
                <span class="col-md-4 col-xs-12"><b>Description<p class="astrisk">*</p></b></span>
                <span class="col-md-3 col-xs-12"><b>Image</b></span>
                <span class="col-md-1 col-xs-12"></span>
            </div>
            <div class="row" v-for="(details) in case_study_details" :key="details.rand_key">
                <div class="form-group col-md-4 col-xs-12">
                    <input
                        type="text"
                        class="form-control"
                        :name="'title_'+details.rand_key"
                        maxlength="90"
                        v-validate="'required'"
                        placeholder="Enter Title"
                        v-model="details.title"
                    >
                    <span class="error" v-show="errors.has('title_'+details.rand_key)">
                        {{ $root.updateErrorMessage('title_'+details.rand_key,errors,'Title')}}
                    </span>
                </div>
                
                <div class="form-group col-md-4 col-xs-12">
                    <ckeditor
                        :name="'description_'+details.rand_key"
                        v-validate="'required'"
                        placeholder="Enter Description"
                        :editor="editor"
                        :config="editorConfig"
                        v-model="details.description"
                    />
                    <span class="error" v-show="errors.has('description_'+details.rand_key)">
                        {{ $root.updateErrorMessage('description_'+details.rand_key,errors,'Description')}}
                    </span>
                </div>
                
                <div class="form-group col-md-3 col-xs-12" :key="'file_upload_'+details.rand_key">
                    <input
                        type="file"
                        v-validate="'size:2048|ext:jpg,png,jpeg,svg'"
                        class="form-control"
                        @change="updateImage($event,details.rand_key)"
                        :name="'image_'+details.rand_key"
                        :id="'image_'+details.rand_key"
                        placeholder="Select Image"
                    >
                    <span style="font-size: 11px;"><b>Note :</b> jpg, jpeg, png, svg files are allowed. File size should be less than or equals to 2 MB.</span>
                    <span class="error" v-show="errors.has('image_'+details.rand_key)"> 
                        {{ $root.updateErrorMessage('image_'+details.rand_key,errors,'Image')}}
                    </span>
                    <ul v-if="details.image_url" class="list-unstyled">
                        <li>
                            <div class="" style="">
                                <img :src="details.image_url" :id="'blah_'+details.rand_key" class="mt-3 student_image profile-user-img my-profile img-responsive" style="width:100%"> 
                            </div>
                            <button class="btn btn-xs btn-danger"
                                type="button"
                                @click="removeImage(details.rand_key)"
                            >
                                Remove file
                            </button>
                        </li>
                    </ul>
                </div>
                
                <div class="form-group col-md-1 col-xs-12">
                    <button type="button" title="Remove Detail" @click="removeField(details.rand_key)" class="btn btn-danger"><i class="fa fa-times"></i></button>
                </div>
            </div>
            <span class="error" style="display:block" v-if="this.case_study_details_error != ''">
                {{ case_study_details_error }}
            </span>
            <button type="button" @click="AddField" class="btn btn-info">
                + Add Case Study
            </button>
        </div>
        
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2'
export default {
    data() {
        return {
            nameError:'',
            case_study_name: '',
            case_study_details: [],
            case_study_details_error: [],
            isPublish: 0,
            editorData: '',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'undo', 'redo',
                        '|', 'heading',
                        '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                        '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                        '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
                        '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent', 'insertTable'
                    ],
                    shouldNotGroupWhenFull: false
                }
            }
        }
    },
    computed: {
        ...mapGetters('CourseCaseStudyIndex', ['caseStudyImage', 'loading', 'backgroundImage', 'profile']),
    },
    components: {
        ckeditor: CKEditor.component
    },  
    methods:{
        ...mapActions('CourseCaseStudyIndex', ['storeData','resetState','setCaseStudyImage','setBackgroundImage','setProfile','setIsPublish']),
        
        updateNameError() {
            this.nameError = ''
        },
        AddField() {
            let random = Math.floor((Math.random() * 100) + 1)
            this.case_study_details.push({ title: '', description: '', image: '', rand_key: random})
            setTimeout(() => {
                this.$validator.reset(`description_${random}`);
            }, 50)
        },
        removeField(index) {
            this.case_study_details_error = ''
        
            index = this.case_study_details.findIndex(p => p.rand_key == index)
            this.case_study_details.splice(index, 1)
        },
        updatedCourseCasestudyPublish(e) {
            if(e.target.checked == true) {
                this.isPublish = 1
            } else {
                this.isPublish = 0
            }
        },
        closeModel(){
            this.$emit('closeModel')
        },
        updateProfile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml') {
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(150)
                    }
                    reader.readAsDataURL(e.target.files[0])
                    this.setCaseStudyImage(e.target.files[0])
                    this.$forceUpdate()
                } else {
                    var message = ""
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml')) {
                        message = 'File size should be less then 2MB.'
                    } else {
                        message = 'Only JPG, JPEG, PNG, SVG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#case_study_profile").val('')
                            this.$validator.errors.remove('case_study_profile')
                            this.setCaseStudyImage('')
                        }
                    })
                }
            }
        },
        removeProfile(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setCaseStudyImage('')
                    $("#case_study_profile").val('')
                }
            })
        },
        updateImage(e,index){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                let index1 = this.case_study_details.findIndex(p => p.rand_key == index)
                if (e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml') {
                    reader.onload = function (e) {
                        $('#blah_'+index)
                            .attr('src', e.target.result)
                            .width(100)
                            .height(100)
                    }
                    reader.readAsDataURL(e.target.files[0])
                    this.case_study_details[index1].image = e.target.files[0]
                    this.case_study_details[index1].image_url = reader
                    this.$forceUpdate()
                } else {
                    var message = ""
                    if (e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml')) {
                        message = 'File size should be less then 2MB.'
                    } else {
                        message = 'Only JPG, JPEG, PNG files are allowed'
                    }
                    this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#image").val('')
                            this.$validator.errors.remove('image')
                            this.case_study_details[index1].image =  ''
                            this.case_study_details[index1].image_url = ''
                        }
                    })
                }
            }
        },
        removeImage(index) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                let index1 = this.case_study_details.findIndex(p => p.rand_key == index)
                if (typeof result.dismiss === "undefined") {
                    $("#image_"+index).val('')
                    this.$validator.errors.remove('image')
                    this.case_study_details[index1].image =  ''
                    this.case_study_details[index1].image_url = ''
                    this.$set(this.case_study_details)
                }
            })
        },
        updateBackgroundImage(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                if (e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml') {
                    reader.onload = function (e) {
                        $('#blahBackground')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(150)
                    }
                    reader.readAsDataURL(e.target.files[0])
                    this.setBackgroundImage(e.target.files[0])
                    this.$forceUpdate()
                } else {
                    var message = ""
                    if (e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml')) {
                        message = 'File size should be less then 2MB.'
                    } else {
                        message = 'Only JPG, JPEG, PNG, SVG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#background_image").val('')
                            this.$validator.errors.remove('background_image')
                            this.setBackgroundImage('')
                        }
                    })
                }
            }
        },
        removeBackgroundImage(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setBackgroundImage('')
                    $("#background_image").val('')
                }
            })
        },
        updateProfileImage(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                if (e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml') {
                    reader.onload = function (e) {
                        $('#blahProfile')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(150)
                    }
                    reader.readAsDataURL(e.target.files[0])
                    this.setProfile(e.target.files[0])
                    this.$forceUpdate()
                } else {
                    var message = ""
                    if (e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml')) {
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, SVG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#case_study_profile").val('')
                            this.$validator.errors.remove('case_study_profile')
                            this.setProfile('')
                        }
                    })
                }
            }
        },
        removeProfileImage(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setProfile('')
                    $("#case_study_profile").val('')
                }
            })
        },
        submitForm() {
            this.nameError = ''
            var params = {
                'course_id': this.$route.params.id,
                'case_study_details': this.case_study_details,
                'case_study_name': this.case_study_name,
                'case_study_profile': this.profile,
                'case_study_bg_img': this.backgroundImage,
                'is_published': this.isPublish,
            }
            this.$validator.validate().then(result => {
                if (result) {
                    this.buttonLoading = true
                    this.storeData(params)
                    .then((res) => {
                        if (res.data.referrals) {
                            this.$eventHub.$emit('common',1,res.data.referrals.message)
                        }
                        if (res.data.course) {
                            this.$eventHub.$emit('common',1,res.data.course.message)
                        }
                        if (!res.data.result) {
                            if ((res.data.data).length > 0) {
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    if(key == "allSelectedLists") {
                                        this.nameError = value[0]
                                    } else {
                                        this.$validator.errors.add({
                                            id: key,
                                            field: key,
                                            msg: value[0],
                                            scope: this.$options.scope,
                                        })
                                    }
                                }
                            } else {
                                this.nameError = res.data.message
                            }
                            this.buttonLoading = false
                            return ''
                        }
                        this.$emit('closeModel')
                        this.$emit('loadItems')
                        this.$eventHub.$emit('create-success')
                    })
                    .catch(() => {
                        this.$eventHub.$emit('common',3,'Something went wrong.')
                    })
                    .finally(() => {
                        this.buttonLoading = false
                    })
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs)
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-footer1 {
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>

<style>
.ck.ck-balloon-panel.ck-balloon-panel_visible {
    z-index: 1041;
}
</style>