<template>
  <div class="popup-form add-country">
    <b-modal id="showCaseStudyModel"
        hideFooter
        ref="modal"
        title="View Course Case Study"
        @ok="handleOk"
        okTitle="Save"
        okVariant="success"
        @hide="handleCancel"
        @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true"
    >
        <div v-if="!loading">
            <table class="table table-bordered table-striped not-responsive-grid">
                <tbody>
                    <th>Case Study Name</th>
                    <th>Case Study Background Image</th>
                    <th>Case Study Slider Image</th>
                    <th>Is Publish</th>
                    <tr>
                        <td>{{showData.case_study_name}}</td>
                        <td><img :src="showData.case_study_bg_img" width="300" height="150px" v-if="showData.case_study_bg_img != ''" class="student_image img-responsive" :alt="showData.case_study_profile"></td>
                        <td><img :src="showData.case_study_profile" width="100px" v-if="showData.case_study_profile != ''" class="student_image img-responsive" :alt="showData.case_study_profile"></td>
                        <td>
                            <span v-if="showData.is_published == 1">Yes</span>
                            <span v-else>No</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="mt-4 table table-bordered table-striped not-responsive-grid" v-if="showData.case_study_details != ''">
                <tbody>
                    <th width="30px">Sr no.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Image</th>
                    <tr v-for="(value, index) in showData.case_study_details" :key="index">
                        <td width="30px">{{index+1}}</td>
                        <td>{{value.title}}</td>
                        <td v-html="value.description"></td>
                        <td>
                            <img :src="value.image" width="100px" v-if="value.image" class="student_image img-responsive" :alt="value.image">
                            <img src="/assets/images/Logo.svg" width="100px" v-else class="student_image img-responsive" :alt="value.image">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-body" v-else>
            <CustomLoader></CustomLoader>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader,
    },
    props: ['showId'],
    created() {
    },
    destroyed() {
    },
    computed: {
        ...mapGetters('CourseCaseStudyIndex', ['showData','loading']),
    },
    methods: {
        ...mapActions('CourseCaseStudyIndex', ['fetchShowData']),
        
        handleCancel(evt){
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                }else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                } 
            });
        },
        modalOpened() {
            this.fetchShowData(this.showId);  
        },
    }
}
</script>

<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>