function initialState() {
    return {
        studentAll: [],
        courseAll: [],
        listingAll: [],
        relationships: {},
        item: {
            course: null,
            student: null,
        },        
        query: {},
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,    
    data: state => {
        let rows = state.listingAll
        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        rows = _.filter(rows, function(item,key){
            item.s_no =  key + 1
            return item
        })
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['s_no','original_date','original_format_date','attendance_update_by','attendance','schedule_days','batch_name','lab_name','topic_name','display_topic_status'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.listingAll

        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        rows = _.filter(rows, function(item,key){
            item.s_no =  key + 1
            return item
        })
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['s_no','original_date','original_format_date','attendance_update_by','schedule_days','batch_name','topic_session_no','lab_name','attendance','topic_name','display_topic_status'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    loading: state => state.loading,
    courseAll: state => state.courseAll,
    listingAll: state => state.listingAll,
    studentAll: state => state.studentAll,
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/students_list')
            .then(response => {
                commit('setAll',  response.data.data)
            })         
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
    getCountForCalendar({ commit, state, dispatch }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
            }
            if (_.isEmpty(state.item.student)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student.id)
            }
            if (_.isEmpty(state.item.student)) {
                params.set('start_date', '')
            } else {
                params.set('start_date', state.item.course.start_date)
            }
            params.set('prefer_batch_id', '0')
            params.set('employee_id', '0')
           
            axios.post('/api/v1/theBatchListingData', params)
                .then(response => {
                    commit('setListingAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchStudentCourseAll({ commit },id) {
        await axios.get('/api/v1/studentsCoursesList/'+id)
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
        axios.delete('/api/v1/deleteAttendance/' + id)
                .then(response => {
                    // var rows = state.all
                    // rows = rows.filter((item) => {
                    //     return item.id != id
                    // })
                    // commit('setAll', rows);
                    
                    // var queryLocal = purify(state.query);
                    // if(rows.length == queryLocal.offset && rows.length != 0)
                    // {
                    //     queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                    //     commit('setQuery', queryLocal)
                    // }
                    resolve();
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {
    setAll(state, items) {
        state.studentAll = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },    
    setError(state, error) {
        state.error = error
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setListingAll(state, value) {
        state.listingAll = value
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setStudent(state, value) {
        state.item.student = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
