<template>
  <div class='topicModal'>
  <div class="popup-form ">
    <span class="student-name" @click="getData"  title="View Topics & status">{{row.is_course ? row.course_name : row.student_name}}</span>
    
    <b-modal :id="row.student_name"
             :ref="row.student_name"
             :title="`${row.student_name}`"
             @ok="handleOk"
             okTitle="Ok"
             okVariant="success"             
             @cancel="handleCancel"
             modal-class=""
             :hide-footer="true"
             >
      <!--@shown="clear"-->
      <div class="container-fluid topic-modal-class">  
        <b> Course : {{row.course_name}} </b>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Scheduled Date</th>
                  <th>Batch</th>
                  <th>Topic</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if='computedTopics.length <= 0'>
                  <td colspan="3">
                    <div class="row" v-if="loadingTopics">
                        <div class="col-xs-4 col-xs-offset-4">
                            <div class="alert text-center">
                                <i class="fa fa-spin fa-refresh"></i> Loading topics...
                            </div>
                        </div>
                    </div>
                    <div v-else>
                      No topics added yet.
                    </div>
                  </td>
                </tr>
                <tr v-else v-for='(value,index) in computedTopics'>
                  <td>
                    <span >  {{value.session_date}} </span>
                  </td>
                  <td>
                    {{value.batch_name}}
                  </td>
                  <td>
                    {{value.topic_name}}
                  </td>
                  <td>
                    {{value.status_remark}}
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopicStatusTags from '../TopicStatusTags'
function dd(...args){
  for (let i in args){
    console.log(args[i]);
  }
}
export default {
  props:['row'],
  components:{
    TopicStatusTags,
  },
  data () {
    return {
    }
  },
   computed: {
       ...mapGetters('StudentAssessmentSingle', ['item', 'loadingTopics','courseTopics']),
       computedTopics(){
         let topics = this.courseTopics;
         let selected = [];
         topics.forEach(function(value){
           if(value['topic_status'] !== '' && value['topic_status'] !== null && value['topic_status'] != 3){
            selected.push(value);
          }
         });
         
         return selected;
       },
   },
   created() {
      
   },   
  methods: {
    ...mapActions('StudentAssessmentSingle', ['fetchCourseTopics','setCourseTopics']),
    
    handleCancel(evt){
       this.$refs.modal.hide()
    },    
    handleOk (evt) {
       this.$refs.modal.hide()
    },
    handleSubmit () {
      this.$refs.modal.hide()
    },
    getData(){
      this.setCourseTopics([]);
      let data = {
        course_id:this.row.course_id,
        student_id:this.row.student_id,
        start_date:this.row.schedule_date
      };
      this.fetchCourseTopics(data)
      this.$refs[this.row.student_name].show()
     
    },
    
  }
}
</script>
<style >
  .topicModal .popup-form{
      position: relative;
      top: unset;
      right: unset;
  }
  .topic-modal-class{
      height: 400px;
      overflow: auto;
  }
  .student-name {
      cursor: pointer;
      color: #3c8dbc;
  }
</style>