<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Receipts</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Receipt</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                     <div class="form-group" ref="student">
                                        <label for="student">Student Name / Enrollment Number<p class="astrisk">*</p></label>
                                        <v-select
                                            id="student"
                                            name="student"
                                            disabled="disabled"
                                            label="student_name"
                                            v-validate="'required'"
                                            @input="updateStudent"
                                            :value="item.student"
                                            :options="studentAll"
                                            />
                                        
                                        <span class="error" v-show="errors.has('student')">
                                            {{ $root.updateErrorMessage('student',this.errors,'Student Name / Enrollment Number')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="" v-if="studentSeen">
                            <div class="box-body">
                                <div class="p-top-15">
                                    <div class="col-md-3 col-sm-6 col-xs-12">
                                      <div class="info-box">
                                        <span class="info-box-icon bg-aqua"><i class="fa fa-inr"></i></span>

                                        <div class="info-box-content">
                                          <span class="info-box-text">Total Amount</span>
                                          <span class="info-box-number"><i class="fa fa-rupee"></i> {{sumTotalAmount}}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                      </div>
                                      <!-- /.info-box -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-md-3 col-sm-6 col-xs-12">
                                      <div class="info-box">
                                        <span class="info-box-icon bg-green"><i class="fa fa-check"></i></span>

                                        <div class="info-box-content">
                                          <span class="info-box-text">Total Paid</span>
                                          <span class="info-box-number"><i class="fa fa-rupee"></i> {{sumTotalPaid}}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                      </div>
                                      <!-- /.info-box -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-md-3 col-sm-6 col-xs-12">
                                      <div class="info-box">
                                        <span class="info-box-icon bg-yellow"><i class="fa fa-clock-o"></i></span>

                                        <div class="info-box-content">
                                          <span class="info-box-text">Total Due</span>
                                          <span class="info-box-number"><i class="fa fa-rupee"></i> {{sumTotalBalance}}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                      </div>
                                      <!-- /.info-box -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-md-3 col-sm-6 col-xs-12">
                                      <div class="info-box">
                                        <span class="info-box-icon bg-red"><i class="fa fa-exclamation"></i></span>

                                        <div class="info-box-content">
                                          <span class="info-box-text">Over Due</span>
                                          <span class="info-box-number"><i class="fa fa-rupee"></i> {{sumTotalDue}}</span>
                                        </div>
                                        <!-- /.info-box-content -->
                                      </div>
                                      <!-- /.info-box -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box" v-if="studentSeen">
                            <div class="box-body">
                                <div class="box-header">
                                    <h3 class="box-title">Fee Details</h3>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                     <div ref="receipt_date" class="form-group">
                                        <label for="receipt_date">Receipt Date<p class="astrisk">*</p></label>
                                        <date-picker
                                            :value="item.receipt_date"
                                            :config="$root.dpconfigDate"
                                            name="receipt_date"
                                            v-validate="'required'"
                                            @input="updateReceiptDate"
                                            placeholder="Select Receipt Date"
                                            autocomplete="off"
                                            >
                                        </date-picker>
                                       
                                        <span class="error" v-show="errors.has('receipt_date')">
                                            {{ $root.updateErrorMessage('receipt_date',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                                 <div class="col-md-6 col-xs-12">
                                     <div class="form-group">
                                        <label for="manual_receipt_no">Manual Receipt #</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="manual_receipt_no"
                                                ref="manual_receipt_no"
                                                placeholder="Enter Manual Receipt #"
                                                :value="item.manual_receipt_no"
                                                @input="updateManualReceiptNo"
                                                >
                                        
                                    </div>
                                </div>
                               
                                <!-- ######### Fee List - Start ############ --> 
                                <div class="col-xs-12 course_detail_section" > 
                                    <div class="box box-warning box_body_bg table-responsive">
                                        <table class="table table-bordered table-hover not-responsive-grid">
                                            <thead>
                                                <tr>
                                                    <th>Fee Type</th>
                                                    <th>Total Fee</th>
                                                    <th>Total Paid</th>
                                                    <th>Total Due</th>
                                                    <th>Amount to Paid</th>
                                                    <th>GST Amount</th>
                                                    <th>Fee Amount</th>
                                                    
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(fee, index) in pendingFeeAll">
                                                    <td>{{fee.fee_name}}</td>
                                                    <td> <span class="fa fa-rupee">{{fee.TotalFees}}</span> </td>
                                                    <td> <span class="fa fa-rupee">{{fee.TotalPaid}}</span> </td>
                                                    <td> <span class="fa fa-rupee">{{totalDueAmount(fee.TotalFees,fee.TotalPaid)}}</span></td>
                                                    <td class="relative">{{calculateAmount(index, fee.gst_rate)}}
                                                    <i  class="fa fa-rupee"></i>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="fee_paid"
                                                            ref="fee_paid"
                                                            oninput="this.value=this.value
                                                                  .replace(/[^\d.]/g, '')
                                                                  .replace(/(\..*)\./g, '$1')
                                                                  .replace(/(\.[\d]{2})./g, '$1');"                   
                                                            placeholder="Enter Amount to Paid"
                                                            v-model="inputParams[index]"
                                                            @input="calculateAmount(index, fee.gst_rate)"
                                                            >
                                                            
                                                    </td>
                                                    <td> <span class="fa fa-rupee">{{gstParams[index]}}</span></td>
                                                    <td> <span class="fa fa-rupee">{{amountParams[index]}}</span></td>
                                                   
                                                </tr>
                                                <tr class="total">
                                                    <td colspan="4" align="right"><b>Total</b></td>
                                                    <td> <span class="fa fa-rupee">{{totalPayment}}</span></td>
                                                    <td> <span class="fa fa-rupee">{{totalGST}}</span></td>
                                                    <td> <span class="fa fa-rupee">{{totalAmount}}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                                <!-- ######### Fee List - End ############ -->
                            </div>
                        </div>
                        <div class="box" v-if="studentSeen">
                            <div class="box-body">
                                <div class="box-header">
                                    <h3 class="box-title">Payment Details</h3>
                                </div>
                                <div class="row">
                                    <div class="col-md-7 col-xs-12">
                                        <span class="col-xs-12 error" v-show="errors.has('payment_amount_check')">
                                            {{ 
                                            $root.updateErrorMessage('payment_amount_check',this.errors)
                                            }}
                                        </span> 
                                    </div>

                             
                                    <div class="col-md-8 col-lg-6 col-xs-12 p-bottom-15 z-index pull-right">
                                        <div class="col-md-8">
                                            <div class="payment-detail-container">
                                            <v-select class="form-group"
                                                name="payment_mode"
                                                label="name"
                                                :options="paymentModeAll"
                                                v-model= "addPaymentModeParams"
                                            />
                                                <span class="error" v-show="errors.has('select_payment_mode')">
                                                {{ 
                                                $root.updateErrorMessage('select_payment_mode',this.errors)
                                                }}                                    
                                                </span>
                                        </div>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="btn btn-block btn-primary add_course_btn" @click="addPaymentRow()">Add Payment</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                
                                <!-- ######### Payment Mode - Start ############ --> 
                                <div class="col-xs-12 course_detail_section" > 
                                    <div class="box box-warning box_body_bg table-responsive">
                                        <table class="table table-bordered table-hover not-responsive-grid">
                                            <thead>
                                                <tr>
                                                    <th>Payment Mode</th>
                                                    <th>Amount</th>
                                                    <th>Bank Name</th>
                                                    <th>Cheque/Transaction Number</th>
                                                    <th>Date</th>
                                                    <th style="text-align: right;">Actions</th>
                                                </tr> 
                                            </thead>
                                            <tbody>    
                                                <tr v-for="(row, index) in paymentDetails">
                                                    <td ref="payment_mode">
                                                    {{row.payment_mode_detail.name}}

                                              
                                                    </td>
                                                    <td class="relative">
                                                    <i  class="fa fa-rupee"></i>
                                                    <!-- {{paymentInputParams}} -->
                                                        <input
                                                            type="text"
                                                            oninput="this.value=this.value
                                                                  .replace(/[^\d.]/g, '')
                                                                  .replace(/(\..*)\./g, '$1')
                                                                  .replace(/(\.[\d]{2})./g, '$1');"
                                                            class="form-control"
                                                            name="mode_amount_paid"
                                                            ref="mode_amount_paid"
                                                            placeholder="Enter Amount"
                                                            v-model= "paymentInputParams[row.row_number]"
                                                            v-validate="'required'"
                                                            >
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="bank_name"
                                                            ref="bank_name"
                                                            placeholder="Enter Bank Name"
                                                            :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 6)"
                                                            v-model= "bankNameParams[row.row_number]"
                                                            v-validate="'required'"
                                                            >
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="instrument_no"
                                                            ref="instrument_no"
                                                            placeholder="Enter Cheque/Transaction Number"
                                                            :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 6)"
                                                            v-model= "instrumentNumberParams[row.row_number]"
                                                            v-validate="'required'"
                                                            >
                                                    </td>
                                                    <td class="relative" ref="instrument_date">
                                                        <date-picker
                                                            v-model= "instrumentDateParams[row.row_number]"
                                                            :config="$root.dpconfigDate"
                                                            name="instrument_date"
                                                            :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 6)"
                                                            placeholder="Select Date"
                                                            v-validate="'required'"
                                                            autocomplete="off"
                                                            >
                                                        </date-picker>
                                                    </td>
                                                    <td><button v-if="index > 0" type="button" class="btn btn-block btn-primary add_course_btn" @click="removePaymentRow(row.row_number)">Remove</button></td>
                                                </tr>       

                                                 <tr class="total">
                                                    <td align="right"><b>Total</b></td>
                                                    <td class="fa fa-rupee"> {{doTotalPaymentDetail()}}{{totalPaymentDetail}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>                        
                                        <tr class="error_tr">
                                            <td>
                                                <span class="error" v-show="errors.has('payment_mode')">
                                                {{ $root.updateErrorMessage('payment_mode',this.errors,'Payment Mode')}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="error" v-show="errors.has('mode_amount_paid')">
                                                {{ $root.updateErrorMessage('mode_amount_paid',this.errors,'Amount')}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="error" v-show="errors.has('bank_name')">
                                                {{ $root.updateErrorMessage('bank_name',this.errors)}}
                                            </span>                    
                                            </td>
                                            <td>
                                                <span class="error" v-show="errors.has('instrument_no')">
                                                {{ $root.updateErrorMessage('instrument_no',this.errors,'Cheque/Transaction #')}}
                                            </span>                   
                                            </td>
                                            <td>
                                            <span class="error" v-show="errors.has('instrument_date')">
                                                {{ $root.updateErrorMessage('instrument_date',this.errors, 'Date')}}
                                            </span>
                                            </td>
                                            <td>
                                                                    
                                            </td>
                                        </tr>
                                        
                                            </tbody>
                                        </table>
                                  <!-- <span class="col-xs-12 error" v-show="errors.has('payment_amount_check')">
                                    {{ 
                                    $root.updateErrorMessage('payment_amount_check',this.errors)
                                    }}
                                </span> -->
                                    </div>
                                </div> 
                               
                                <!-- ####### Payment Mode - End ############ --> 

                                
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn" v-if="studentSeen">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            recieptId: null,
            disable: [],
            inputParams:[],
            paymentInputParams:[],
            instrumentDateParams:[new Date()],
            instrumentNumberParams:[],
            bankNameParams:[],
            addPaymentModeParams:null,
            // paymentModeParams:[{value_id:1,name:'Cash'}],
            paymentModeParams:[{value_id:1,row_number:1,payment_mode_detail:{value_id:1,name:'Cash'}}],
            gstParams:[],
            amountParams:[],
            studentSeen:false,
            totalGST:0,
            totalAmount:0,
            totalPayment:0,
            totalPaymentDetail:0,
            sumTotalAmount:0,
            sumTotalPaid:0,
            sumTotalBalance:0,
            sumTotalDue:0,
            paymentModeAmount:0,
            // Code...
        }
    },
    computed: {
        ...mapGetters('ReceiptsSingle', ['item', 'loading','studentAll','pendingFeeAll','paymentModeAll','paymentDetails']),
      
    },
    created() {
        this.recieptId = this.$route.params.id
        this.fetchData(this.$route.params.id)
            .then((res)=>{
                this.updateStudent(this.item.student)
            })
        //this.fetchStudentAll();

    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.theCalendarCount(this.$route.params.id)
            this.fetchPaymentMode(this.$route.params.id)
        }

    },
    methods: {
        ...mapActions('ReceiptsSingle', ['fetchData', 'updateData', 'resetState', 'setStudent', 'setReceiptDate', 'setManualReceiptNo', 'setReceiptAmount','fetchStudentAll','fetchPaymentFeeAll','setPaymentMode','setPaymentDetails','setPendingFeeAll','fetchPaymentMode']),
        updateStudent(value) {
            console.log('called',value)
            if(value && value!=null && value != '')
            {
                this.studentSeen = true;
                this.fetchPaymentFeeAll({'id':value.id,'rid':this.$route.params.id})
                .then((res) => {
                    this.fetchPaymentMode(this.$route.params.id)
                    .then((res) => {
                        this.getSumTotalValue();
                      })
                })
                
                //setTimeout(function () { this.getSumTotalValue() }.bind(this), 400)
            }else{
                this.studentSeen = false;
            }
            this.setStudent(value)
        },
        doTotalPaymentDetail(){            
            var tot = 0;
            this.paymentInputParams.forEach(function(fee){
                if(!fee)
                {
                    fee = 0;
                }
                tot = parseFloat(tot) + parseFloat(fee);
            }.bind(this))
            this.totalPaymentDetail = parseFloat(tot).toFixed(2);
        },
        getSumTotalValue(){

            var paymentModeParams = [];
            var row_number = [];
            var paymentInputParams = [];
            var instrumentDateParams = [];
            var instrumentNumberParams = [];
            var bankNameParams = [];
            this.paymentDetails.forEach(function(payment){
                    // if(payment.payment_mode==2){
                        // paymentModeParams.push({value_id:2,name:'Cheque',paymentModeValueId:payment.payment_mode})
                    //     paymentModeParams.push({value_id:payment.payment_mode,name:'Cheque',paymentModeValueId:payment.payment_mode})
                    // }
                    // else{
                        // paymentModeParams.push({value_id:1,name:'Cash',paymentModeValueId:payment.payment_mode})                    }
                        // paymentModeParams.push({value_id:payment.payment_mode,name:payment.payment_mode_detail.name,paymentModeValueId:payment.payment_mode})                    
                        paymentModeParams[payment.row_number]=({value_id:1,row_number:payment.row_number,payment_mode_detail:payment.payment_mode_detail});
                    // }
                        row_number.push(payment.row_number);
                        // paymentInputParams.push(payment.amount);
                        // instrumentDateParams.push(payment.instrument_date);
                        // instrumentNumberParams.push(payment.instrument_no);
                        // bankNameParams.push(payment.bank_name);                   
                        paymentInputParams[payment.row_number] = payment.amount;
                        instrumentDateParams[payment.row_number] = payment.instrument_date;
                        instrumentNumberParams[payment.row_number] = payment.instrument_no;
                        bankNameParams[payment.row_number] = payment.bank_name;                   

            })
            if(paymentModeParams !== '')
            {
                this.paymentModeParams = [];
                this.paymentInputParams = [];
                this.instrumentDateParams = [];
                this.instrumentNumberParams = [];
                this.bankNameParams = [];
                // this.paymentModeParams[row_number] = paymentModeParams;
                // this.paymentInputParams[row_number] = paymentInputParams;
                // this.instrumentDateParams[row_number] = instrumentDateParams;
                // this.instrumentNumberParams[row_number] = instrumentNumberParams;
                // this.bankNameParams[row_number] = bankNameParams;
                this.paymentModeParams = paymentModeParams;
                this.paymentInputParams = paymentInputParams;
                this.instrumentDateParams = instrumentDateParams;
                this.instrumentNumberParams = instrumentNumberParams;
                this.bankNameParams = bankNameParams;
            }
            var sumTotalAmount = 0;
            var sumTotalPaid = 0;
            var sumTotalBalance = 0;
            var sumTotalDue = 0;
            var inputFees = [];
            this.pendingFeeAll.forEach(function(fee){
                sumTotalAmount = sumTotalAmount + parseFloat(fee.TotalFees);
                sumTotalPaid = sumTotalPaid + parseFloat(fee.TotalPaid);
                
                if(fee.InputFees != null)
                {
                    inputFees.push(parseFloat(fee.InputFees).toFixed(2))
                }
            }.bind(this))
            if(inputFees !='')
            {                    
                this.inputParams = [];
                this.inputParams = inputFees;
            }
            this.sumTotalAmount = parseFloat(sumTotalAmount).toFixed(2);
            this.sumTotalPaid = parseFloat(sumTotalPaid).toFixed(2);
            this.sumTotalBalance = parseFloat(sumTotalAmount - sumTotalPaid).toFixed(2);
            //ENBALING AND DISABLING FIELD ON LOAD ACCORDING TO PAYMENT MODE (CASH OR BANK)
            if(typeof index != 'undefined'){
                if(this.paymentModeParams[index].value_id == 1){
                    this.disable[index] = true;
                } else if(this.paymentModeParams[index].value_id == 2) {
                    this.disable[index] = false;
                }
            }
        },

        updateReceiptDate(value) {
            this.setReceiptDate(value)
        },
        updateManualReceiptNo(e) {
            this.setManualReceiptNo(e.target.value)
        },
        updateReceiptAmount(e) {
            this.setReceiptAmount(e.target.value)
        },
        updatePaymentMode(e){

            // this.paymentModeParams[index] = this.addPaymentModeParams;
            // if(this.paymentModeParams[index].value_id == 1){
            //     this.disable[index] = true;
            // } else if(this.paymentModeParams[index].value_id == 2) {
            //     this.disable[index] = false;
            // }
        },
        addPaymentRow(){
            if(this.addPaymentModeParams == null)
            {
                this.$validator.errors.add({
                  id: 'select_payment_mode',
                  field: 'select_payment_mode',
                  msg: 'Please Select payment mode.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('select_payment_mode');
            }
            var newRow = {'payment_mode_detail':{'value_id':this.addPaymentModeParams.value_id,'name':this.addPaymentModeParams.name,'paymentModeValueId':this.addPaymentModeParams.value_id},'row_number':this.paymentDetails[this.paymentDetails.length - 1].row_number + 1}
            var paymentDetailsLocal = purify(this.paymentDetails);
            paymentDetailsLocal.push(newRow);
            if(this.addPaymentModeParams != null){
                    if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 1){
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = true;
                    } else// if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 2) 
                    {
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = false;
                    }
                this.paymentModeParams[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = newRow;
            }
            this.setPaymentDetails(paymentDetailsLocal);
            this.addPaymentModeParams = null;
        },
        removePaymentRow(index){
            var paymentDetailsLocal = purify(this.paymentDetails);
            // paymentDetailsLocal.splice(index,1);
             this.paymentDetails.forEach(function(selectedPaymentList,addedIndex){               
                if (selectedPaymentList && selectedPaymentList.row_number == index){
                    delete this.paymentModeParams[index];
                    delete this.paymentInputParams[index];
                    delete this.instrumentDateParams[index];
                    delete this.instrumentNumberParams[index];
                    delete this.bankNameParams[index];
                    paymentDetailsLocal.splice(addedIndex, 1);                       
                    return false;   
                }               
            }.bind(this))   
            this.setPaymentDetails(paymentDetailsLocal);          
        },
        // updatePaymentMode(value){
        //     console.log(value);
        //     console.log(this.addPaymentModeParams)
            
            // this.paymentModeParams[index] = this.addPaymentModeParams;
            // if(this.paymentModeParams[index].value_id == 1){
            //     this.disable[index] = true;
            // } else if(this.paymentModeParams[index].value_id == 2) {
            //     this.disable[index] = false;
            // }
        // },
        totalDueAmount(totalFees,totalPaid){
            return parseFloat(parseFloat(totalFees)-parseFloat(totalPaid)).toFixed(2);
        }
        ,
        calculateAmount(index, gst_rate)
        {
            console.log('this.inputParams[index]',this.inputParams[index])
            var inputFees = [];
            var localInputParams = this.inputParams[index];
            
            inputFees = this.inputParams;
            if(!(inputFees[index] > 0))
            {
                inputFees[index] = 0;   
            }

            this.amountParams[index] = 0;
            this.gstParams[index] = 0;
            // this.inputParams[index] = parseInt(this.inputParams[index]);
            
            var balance = parseFloat(parseFloat(this.pendingFeeAll[index].TotalFees) - parseFloat(this.pendingFeeAll[index].TotalPaid)).toFixed(2);
            if(parseFloat(inputFees[index]) > balance)
            {
                inputFees[index] = balance;
                localInputParams = balance;
            }
            if(gst_rate > 0)
            {
                gst_rate = parseFloat(gst_rate);
                this.amountParams[index] = (parseFloat(inputFees[index])*100/(100+gst_rate)).toFixed(2);

                this.gstParams[index] = (parseFloat(inputFees[index])-parseFloat(this.amountParams[index])).toFixed(2);
            }
            else{
                this.amountParams[index] = parseFloat(inputFees[index]);
                this.gstParams[index] = 0;
                
            }
            var totalPayment = 0;
            inputFees.forEach(function(amt){
                if(amt == '')
                {
                    amt=0;
                }
                 totalPayment = (parseFloat(totalPayment) + parseFloat(amt)).toFixed(2);
                
            })
            this.totalPayment = totalPayment;

            var totalGST = 0;
            this.gstParams.forEach(function(gst){
                if(gst == '')
                {
                    gst=0;
                }
                totalGST = (parseFloat(totalGST) + parseFloat(gst)).toFixed(2);
            })
            this.totalGST = totalGST;

            var totalAmount = 0;
            this.amountParams.forEach(function(amt){
                if(amt == '')
                {
                    amt=0;
                }
                totalAmount = (parseFloat(totalAmount) + parseFloat(amt)).toFixed(2);
            })
            this.totalAmount = totalAmount;
            this.inputParams = inputFees; 

            this.inputParams[index] = localInputParams;
       
        },
        preparePayment(){
            // Calculate all payment value for submit
            var pendingFeeAllLocal =  purify(this.pendingFeeAll);
            var submitFee = [];
            pendingFeeAllLocal['receipt_amount'] = this.totalPayment;
            this.inputParams.forEach(function(amt, index){
                submitFee[index] = {};
                submitFee[index].total_fees = amt;
                submitFee[index].fees_amount = this.amountParams[index];
                submitFee[index].gst_amount = this.gstParams[index];
                submitFee[index].fees_type = pendingFeeAllLocal[index]['fees_type'];
                
            }.bind(this))
            pendingFeeAllLocal['submitFee'] = submitFee;
            this.setPendingFeeAll(pendingFeeAllLocal);
        },
        preparePaymentMode(){
            // Calculate all payment mode for submit
            var paymentDetailsLocal =  purify(this.paymentDetails);
            var paymentMode = [];
            this.paymentModeAmount = 0;
            this.paymentInputParams.forEach(function(amt, index){
                paymentMode[index] = {};
                paymentMode[index].amount = parseFloat(amt);
                this.paymentModeAmount += parseFloat(amt);
                paymentMode[index].value_id = this.paymentModeParams[index].value_id;    
                if(this.paymentModeParams != null && typeof this.paymentModeParams[index] == 'object')
                {
                    paymentMode[index].payment_mode = this.paymentModeParams[index].payment_mode_detail.value_id;    
                }
                //  if(this.paymentModeParams[index] && this.paymentModeParams[index].value_id && this.paymentModeParams != null && typeof this.paymentModeParams[index] == 'object')
                // {
                //     paymentMode[index].payment_mode = this.paymentModeParams[index].value_id;    
                // }
                else{
                    paymentMode[index].payment_mode = null;       
                }
                if(this.instrumentNumberParams != null && typeof this.instrumentNumberParams[index] === 'string')
                {
                    paymentMode[index].instrument_no = this.instrumentNumberParams[index];
                }
                else{
                    paymentMode[index].instrument_no = null;       
                }
                if(this.instrumentDateParams != null && typeof this.instrumentDateParams[index] === 'string')
                {
                    paymentMode[index].instrument_date = this.instrumentDateParams[index];
                }
                else{
                    paymentMode[index].instrument_date = null;       
                }
                if(this.bankNameParams != null && typeof this.bankNameParams[index] === 'string')
                {
                    paymentMode[index].bank_name = this.bankNameParams[index];
                }
                else{
                    paymentMode[index].bank_name = null;       
                }

                
            }.bind(this))
            
            paymentDetailsLocal['paymentMode'] = paymentMode;
            this.setPaymentDetails(paymentDetailsLocal);
        },
        submitForm() {
        this.preparePayment();
        this.preparePaymentMode();

        if(this.paymentModeAmount != this.totalPayment || this.paymentModeAmount == 0 || this.paymentModeAmount == '')
        {
            this.$validator.errors.add({
              id: 'payment_amount_check',
              field: 'payment_amount_check',
              msg: 'Note: Amount to Paid and Payment Amount should be same.',
              scope: this.$options.scope,
            });
            return false;
        }
        else{
            this.$validator.errors.remove('payment_amount_check');;
        }
        this.$validator.validate().then(result => {
        if (result) {
            this.updateData()
                .then(() => {
                    this.$router.push({ name: 'receipts.index' })
                    this.$eventHub.$emit('create-success')
                    window.open(window.location.origin + '/generateReceiptInvoice/' + this.recieptId, '_blank')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }    

    }
}
</script>

<style scoped>
</style>
 