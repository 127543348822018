<template>
    <form @submit.stop.prevent="submitForm" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div ref="course_name" class="form-group">
                                <label for="course">Course Name<p class="astrisk">*</p></label>
                                <v-select
                                    name="course_name" 
                                    label="course_name"
                                    id="course"
                                    v-validate="'required'"
                                    @input="updateCourseName"
                                    :value="item.course_name"
                                    :options="coursesAll"
                                    disabled="true"
                                />
                                <span class="error" v-show="errors.has('course_name')">
                                    {{ $root.updateErrorMessage('course_name',this.errors, 'Course Name')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="start_date" class="form-group">
                                <label for="start_date">Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="start_date"
                                    :config="start_date_config"
                                    name="start_date"
                                    v-validate="'required'"
                                    :disabled="(batchData.isBatchHold || batchData.isStartDateDisabled)"
                                    @input="update_date"
                                    placeholder="Start Date"
                                    autocomplete="off"
                                    >
                                </date-picker>
                                <span class="error" v-show="errors.has('start_date')">
                                    {{ $root.updateErrorMessage('start_date',this.errors,'Start Date')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="end_date" class="form-group">
                                <label for="end_date">End Date<p class="astrisk">*</p></label>
                                <input :value="end_date" class="form-control" placeholder="End Date" readonly />
                            </div>
                        </div>
                        <div class="form-group col-md-12 col-xs-12" v-if="batchData.isBatchHold">
                            <div ref="resume_date" class="form-group">
                                <label for="resume_date">Resume Date<p class="astrisk">*</p></label>
                                <date-picker
                                    v-model="resume_date"
                                    :config="resume_date_config"
                                    name="resume_date"
                                    v-validate="'required'"
                                    placeholder="Resume Date"
                                    autocomplete="off"
                                    >
                                </date-picker>
                                <span class="error" v-show="errors.has('resume_date')">
                                    {{ $root.updateErrorMessage('resume_date',this.errors,'Resume Date')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12" v-if="batchSchedules.length > 0">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="course_days">Course Days</label>
                                </div>
                                <!-- <div class="col-md-3">
                                    <label for="course_days">Branch Lab</label>
                                </div> -->
                                <div class="col-md-10">
                                    <label for="course_days">Batch Time</label>
                                </div>
                            </div>
                            <div class="row" v-for="(batch, index) in batchSchedules" :key="index">
                                <div class="col-md-2">
                                    <div class="form-group weekdays">
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    name="workingDay"
                                                    type="checkbox"
                                                    v-model="batch.daySelect">
                                                    {{batch.dayname}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="form-group">
                                            <v-select
                                                :name="'lab_'+batch.dayname"
                                                label="lab_name"
                                                placeholder="Select Lab"
                                                v-validate="{required:batch.daySelect}"
                                                v-model="batch.selected_lab"
                                                :options="batch.labs"
                                                @input="setBatchTimeDropdown(index)"
                                                />
                                                
                                            <span class="error" v-show="errors.has('lab_'+batch.dayname)">
                                                {{ $root.updateErrorMessage('batch_time_'+batch.dayname,errors,'Batch time')}}
                                            </span>
                                        </div>
                                </div> -->
                                <div class="col-md-10">
                                    <div class="form-group">
                                            <v-select
                                                :name="'batch_time_'+batch.dayname"
                                                label="batch_name"
                                                placeholder="Select Batch Time"
                                                v-validate="{required:batch.daySelect}"
                                                v-model="batch.selected_batch"
                                                :options="batch.batches"
                                                multiple
                                                />
                                                
                                            <span class="error" v-show="errors.has('batch_time_'+batch.dayname)">
                                                {{ $root.updateErrorMessage('batch_time_'+batch.dayname,errors,'Batch time')}}
                                            </span>
                                        </div>
                                </div>
                            </div>
                            <span class="error" v-if="custError != ''">
                                {{ custError }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1">
            <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
            <button type="submit" :disabled="loading" class="btn btn-success">Save</button>
        </footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default({
    props: ['student_id', 'batchId' ,'isAddBatch', 'closeAddBatchTab'],
    data() {
        return {
            addCourseParams:null,
            checkedDays: [],
            workingCheckedDays: [],
            dayName: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            start_date: null,
            end_date: null,
            batchSchedules: [],
            override: 0,
            resume_date: null,
            start_date_config : this.$root.dpconfigDate,
            resume_date_config : this.$root.dpconfigDate,
            custError:'',
            dataLoad:false
        }
    },
    computed: {
        ...mapGetters('BatchSingle', ['item', 'loading', 'coursesAll','batchAll','batchData']),
    }, 
    created() {
        this.resume_date_config.minDate = false;
        this.start_date_config.minDate = false;
        this.reset();
        this.fetchCoursesAll(this.student_id);
        this.getBatchData(this.batchId);
    },
    watch: {
        batchAll(value){
            console.log(value);
            let _this = this;
            _this.batchSchedules = [];
            let batch_details = this.batchData.batch_details;
          
            Object.keys(value).forEach((key, batch) => {
                 _this.batchSchedules.push({daySelect:false,dayname:_this.dayName[key-1],day_id:key,batches:value[key]['batch'],batch_time:[],selected_batch:null});
            });
            

            console.log("ba",_this.batchSchedules)
           

             Object.keys(batch_details).forEach((key, batch) => {
                _this.batchSchedules[key-1]['daySelect'] = true;
               // console.log("selecred batch",batch_details[key].batch[29],_this.batchSchedules[key-1]['selected_lab'][0].id);
                _this.batchSchedules[key-1]['batch_time'] = _this.batchSchedules[key-1]['batches'];
                _this.batchSchedules[key-1]['selected_batch'] = batch_details[key].batch;
             
            });
            this.dataLoad = true;
            console.log("eidt",_this.batchSchedules);
        },
        batchData(value){
            this.resume_date_config.minDate = false;
             this.start_date_config.minDate = false;
            
            //console.log(value);
            this.setCourseId(value.course.id)
            this.setCourseName(value.course)
            this.fetchBatchAll(value.course.id)
            this.start_date = value.start_date;
           // console.log("dfdf",value.isBatchHold);
            this.end_date = value.end_date;
            // this.resume_date = value.resume_date;
            this.resume_date_config.minDate = (value.start_date).split("/").reverse().join("-");
             this.resume_date_config.maxDate = (value.end_date).split("/").reverse().join("-");
            if(value.last_attendance_date != null){
                // console.log(":hrtt");
                this.start_date_config.minDate = value.last_attendance_date;
                this.resume_date_config.minDate = (value.start_date).split("/").reverse().join("-");
            }
            // var newdate = date.split("/").reverse().join("-"); 
            // console.log("sdsd",(value.start_date).split("/").reverse().join("-"));
            // this.resume_date_config.minDate = (value.start_date).split("/").reverse().join("-");
        }
    },
    methods: {
        ...mapActions('BatchSingle', ['setBatchData','updateData','getBatchData','storeData','fetchBatchAll','fetchCoursesAll', 'fetchCourseWorkingDaysAll', 'setCoursesAll', 'setCourseId', 'setCourseName', 'setCourseMonth']),
        toogleModelSubTable(id ,child){
            var className = $('#modelshow_'+id).attr('class');
            if($('#modelshow_'+id).length > 0 && className == "allhide"){
                $('#modelshow_'+id).removeClass('allhide').addClass('allshow');
                $('#modelangle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');
            }else{
                $('#modelshow_'+id).removeClass('allshow').addClass('allhide');
                $('#modelangle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
            }    
        },
         setBatchTimeDropdown(id){
            // console.log("th",this.dataLoad);
            if(this.dataLoad){
                // console.log("changer",id);
                this.batchSchedules[id]['selected_batch'] = null;
                this.batchSchedules[id]['batch_time'] = this.batchSchedules[id]['batches'][this.batchSchedules[id].selected_lab.id];
            } 
            else{
                // console.log("else changer",id);
            }
        },
        closeModel(){
            this.reset()
            this.$emit('closeModel')
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    let flag = 0;
                    for (let index in this.batchSchedules) {
                        if(this.batchSchedules[index]['daySelect']){
                            flag = 1;
                        }          
                    }
                    if(flag == 0){
                        this.custError = 'Please select atleast one batch.';
                        this.isDisabled = false;
                        return false;
                    }
                    let data = {
                        'id':this.batchData.id,
                        'student_id':this.student_id,
                        'course_id':this.item.course_id,
                        'start_date':this.start_date,
                        'resume_date':this.resume_date,
                        'end_date':this.end_date,
                        'batchSchedule':this.batchSchedules,
                        'override':this.override,
                    }
                    this.updateData(data)
                    .then(response => {
                        if (response.data.result) {
                            this.override = 0;
                            // this.$router.push({ name: 'batch.show' })
                            this.closeModel();
                             this.$emit('loadItems');
                             this.isDisabled = false;
                            // this.fetchCoursesAll()
                            this.$eventHub.$emit('update-success')
                        } else {
                            if(response.data.data.override) {
                                this.$swal({
                                    title: 'Are you sure want to override batch schedule?',
                                    html: response.data.data.override[0],
                                    type: 'warning',
                                    cancelButtonText: "No",   
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    confirmButtonColor: '#dd4b39',
                                    focusCancel: true,
                                    reverseButtons: true
                                }).then(result => {
                                    if (result.value) {
                                        this.override = 1;
                                        this.submitForm();
                                    }
                                    else{
                                        this.isDisabled = false;
                                    }
                                })
                            } else {
                                console.log(response.data.data);
                                for (const [key, value] of Object.entries(response.data.data)) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.isDisabled = false;
                                
                                return this.$root.handleValidationFocus(this.errors, this.$refs);
                                return false;
                            }  
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                    return this.$root.handleValidationFocus(this.errors, this.$refs);
                }
            });
        },
        updateCourseName(value) {
            // console.log("vaye",value);
            // if(value != null) {
            //     this.setCourseId(value.course_id)
            //     this.setCourseName(value.course_name)
            //     // this.setCourseMonth(value.course_months)
            //     this.fetchBatchAll(value.course_id)
            // } else {
            //     this.reset()
            // }
        },
        reset() {
            this.setCourseId(null)
            this.setCourseName(null)
            this.setCourseMonth(null)
            //  this.setBatchData([]);
            this.start_date = null
            this.end_date = null
            this.resume_date = null
        },
        update_date(e) {
           
            this.start_date = e
            var index = e.split('/')
            const dateFormat = index[1] + '/' + index[0] + '/' + index[2]
            if(!(this.batchData.isBatchHold || this.batchData.isStartDateDisabled)){
                this.end_date = moment(dateFormat).add(this.batchData.course.course_months, 'M').format('DD/MM/YYYY')
            }
           
        },
        handleCancel(evt){
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.addCourseParams = null;
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            const current = new Date();
            var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            this.addCourseParams = null;
        },
    }
})
</script>

<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
div#swal2-content{
    white-space: pre-line;
}
</style>
