function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            user_id: null,
            leave_type: null,
            leave_start_date: null,
            leave_end_date: null,
            leave_option: null,
            start_time: null,
            end_time: null,
            leave_status: null,
            reason: null,
            days: null,
        },
        leaveCountAll: null,
        leaveTypeAll: [],
        leaveOptionAll: [],
        usersAll: [],
        allocateLeave: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    leaveTypeAll: state => state.leaveTypeAll,
    leaveOptionAll: state => state.leaveOptionAll,
    leaveCountAll: state => state.leaveCountAll,
    usersAll: state => state.usersAll,
    allocateLeave: state => state.allocateLeave,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.leave_type)) {
                params.set('leave_type_id', '')
            } else {
                params.set('leave_type_id', state.item.leave_type.value_id)
            }
            if (_.isEmpty(state.item.leave_option)) {
                params.set('leave_option_id', '')
            } else {
                params.set('leave_option_id', state.item.leave_option.value_id)
            }
            if (_.isEmpty(state.item.leave_status)) {
                params.set('leave_status_id', '')
            } else {
                params.set('leave_status_id', 1)
            }

            axios.post('/api/v1/leave-requests', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.leave_type)) {
                params.set('leave_type_id', '')
            } else {
                params.set('leave_type_id', state.item.leave_type.value_id)
            }
            if (_.isEmpty(state.item.leave_option)) {
                params.set('leave_option_id', '')
            } else {
                params.set('leave_option_id', state.item.leave_option.value_id)
            }
            if (_.isEmpty(state.item.leave_status)) {
                params.set('leave_status_id', '')
            } else {
                params.set('leave_status_id', state.item.leave_status.id)
            }

            axios.post('/api/v1/leave-requests/' + state.item.id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/leave-requests/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        dispatch('fetchLeaveTypeAll')
        dispatch('fetchLeaveOptionAll')
        dispatch('fetchUsersAll')
    },
    async fetchLeaveCountAll({ commit }, leave_dates) {
            if(leave_dates[0] != null && leave_dates[1] != null) {
                await axios.get('/api/v1/leaveCountList/' + leave_dates)
                    .then(response => {
                        commit('setLeaveCountAll', response.data)
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
             }   
    },    
    fetchLeaveTypeAll({ commit }) {
        axios.get('/api/v1/leaveTypeList')
            .then(response => {
                commit('setLeaveTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchLeaveOptionAll({ commit }) {
        axios.get('/api/v1/leaveOptionList')
            .then(response => {
                commit('setLeaveOptionAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchUsersAll({ commit }) {
        // axios.get('/api/v1/users')
        //     .then(response => {
        //         commit('setUsersAll', response.data.data)
        //     })
        // .catch(function (error) {
        //         if (error.response) {
        //           // The request was made and the server responded with a status code
        //           // that falls out of the range of 2xx
        //           console.log(error.response.data.message);
        //           console.log(error.response.status);
        //           if(error.response.status == 401) {
        //             window.location.href = location.protocol + "//" + location.host + "/sessionExpired"
        //           }
        //         } else if (error.request) {
        //           // The request was made but no response was received
        //           // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //           // http.ClientRequest in node.js
        //           console.log(error.request);
        //         } else {
        //           // Something happened in setting up the request that triggered an Error
        //           console.log('Error', error.message);
        //           let message = error.response.data.message || error.message
        //           let errors  = error.response.data.errors
        //           dispatch(
        //               'Alert/setAlert',
        //               { message: message, errors: errors, color: 'danger' },
        //               { root: true })
        //           reject(error)                  
        //           }
        //           console.log(error.config);
        // })            
    },
    fetchAllocateLeaveAll({ commit, dispatch }, id) {
        axios.get('/api/v1/fetchAllocateLeaveList/'+id)
            .then(response => {
                commit('setAllocateLeave', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setUser_id({ commit }, value) {
        commit('setUser_id', value)
    },
    setLeave_type({ commit }, value) {
        commit('setLeave_type', value)
    },
    setLeave_start_date({ commit }, value) {
        commit('setLeave_start_date', value)
    },
    setLeave_end_date({ commit }, value) {
        commit('setLeave_end_date', value)
    },
    setLeave_option({ commit }, value) {
        commit('setLeave_option', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setLeave_status({ commit }, value) {
        commit('setLeave_status', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setDays({ commit }, value) {
        commit('setDays', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setLeaveCountAll({ commit }, value) {
        commit('setLeaveCountAll', value)
    }
}

const mutations = {
    setLeaveCountAll(state, value) {
        state.leaveCountAll = value
    },
    setAllocateLeave(state, value) {
       state.allocateLeave = value[0]
    },
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setUser_id(state, value) {
        state.item.user_id = value
    },
    setLeave_type(state, value) {
        state.item.leave_type = value
    },
    setLeave_start_date(state, value) {
        state.item.leave_start_date = value
    },
    setLeave_end_date(state, value) {
        state.item.leave_end_date = value
    },
    setLeave_option(state, value) {
        state.item.leave_option = value
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setLeave_status(state, value) {
        state.item.leave_status = value
    },
    setReason(state, value) {
        state.item.reason = value
    },
    setDays(state, value) {
        state.item.days = value
    },
    setLeaveTypeAll(state, value) {
        state.leaveTypeAll = value
    },
    setLeaveOptionAll(state, value) {
        state.leaveOptionAll = value
    },
    setUsersAll(state, value) {
        state.usersAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
