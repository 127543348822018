<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Cancel Admission</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Cancel Admission</h3>
                            </div>
                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>                        
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                     <div ref="student" class="form-group">
                                        <label for="student">Student Name<p class="astrisk">*</p></label>
                                        <v-select
                                            id="student"
                                            name="student"
                                            label="student_name"
                                            v-validate="'required'"
                                            @input="updateStudent"
                                            :value="student"
                                            :options="studentAll"
                                            />
                                        
                                        <span class="error" v-show="errors.has('student')">
                                            {{ $root.updateErrorMessage('student',this.errors,'Student Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-10 col-md-offset-1" v-if="coursesAll && coursesAll.length > 0">
                                    <div class="tableFixHead">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr class="">
                                                    <th class="">Course Name</th>
                                                    <th class="">Status</th>
                                                    <th class="text-center" style="z-index: 1;">
                                                        <label class="containck">   
                                                            <input 
                                                                type="checkbox"
                                                                id="firstBox"
                                                                @change="checkAllCheckBoxes($event)">
                                                            <span class="checkmarkck"></span>
                                                        </label>                                                   
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center" v-for="(value, index) in coursesAll">
                                                    <td class="text-left">{{value.display_course_name}}</td>
                                                    <td class="text-left">
                                                        {{computedCourseStatus(value.course_status)}}
                                                    </td>
                                                    <td>
                                                        <label class="containck"> 
                                                            <input 
                                                                type="checkbox"
                                                                v-validate="'required'"
                                                                :name="{'checkAll': value.course_status == 0 ||  value.course_status == 1}"
                                                                :class="{'checkAll': value.course_status == 0 ||  value.course_status == 1}"
                                                                :disabled="value.course_status != 0 && value.course_status != 1"
                                                                @input="checkBoxChanged($event)"
                                                                v-model="selectedCourse[index]">
                                                            <span class="checkmarkck"></span>
                                                        </label>                                      
                                                    </td>                                                    
                                                </tr>  
                                            </tbody>                            
                                        </table>
                                    </div>
                                </div>
                             <div id="loader_placeholder" v-show="loading">
                                <div id="loadingDiv">
                                   <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                                </div>
                             </div>                                                                                       
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Cancel Admission
                            </vue-button-spinner>
                        </div>                            
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            selectedCourse:[],
        }
    },
    created() {
        this.fetchStudentData()
    },
    destroyed() {
        this.setStudent(null)
        this.setCoursesAll([])
    },
    computed: {
        ...mapGetters('CancelAdmissionSingle', ['loading','student','studentAll','coursesAll']),
    },
    methods: {
        ...mapActions('CancelAdmissionSingle', ['fetchStudentData','fetchCourseData','setStudent','setCoursesAll','cancelAdmission']),
        checkBoxChanged(e) {
            if($('.checkAll:checked').length == $('.checkAll').length) {
                document.getElementById("firstBox").checked = true
            }
            if(e.target.checked == false) {
                document.getElementById("firstBox").checked = false   
            }
        },        
        checkAllCheckBoxes(e) {
           this.smsToBeSent = []
           var arrMarkMail = document.getElementsByClassName("checkAll");
           if(e.target.checked) {
               for (var i = 0; i < arrMarkMail.length; i++) {
                  arrMarkMail[i].checked = true;
               }
                for (var i = 0; i < this.coursesAll.length; i++) {
                    if(this.coursesAll[i].course_status == 0 || this.coursesAll[i].course_status == 1) {
                        this.$set(this.selectedCourse, i, true)
                    }
                }               
           } else {
               for (var i = 0; i < arrMarkMail.length; i++) {
                  arrMarkMail[i].checked = false;
               }
                for (var i = 0; i < this.coursesAll.length; i++) {
                    if(this.coursesAll[i].course_status == 0 || this.coursesAll[i].course_status == 1) {
                        this.$set(this.selectedCourse, i, false)
                    }
                }               
           }
        },        
        computedCourseStatus(value) {
            var status = null;
            value == 0 ? status = 'Pending' : 
            value == 1 ? status = 'Active' :
            value == 3 ? status = 'Completed' :
            value == 4 ? status = 'Cancelled' : status = ' ';
            return status            
        },        
        updateStudent(value) {
            this.setStudent(value)
            if(value && value.id) {
                this.setCoursesAll([])
                this.fetchCourseData(value.id)
                    .then((res)=>{
                        this.$validator.errors.remove({
                            id: 'student',
                            field: 'student'});                    
                    })
                    .catch((error)=>{
                        this.$validator.errors.add({
                            id: 'student',
                            field: 'student',
                            msg: error.message,
                            scope: this.$options.scope,
                        });
                    })
            } else {
                this.setCoursesAll([])
            }
        },
        submitForm() {
            this.$validator.validate().then(result => {
                 if (result) {
                    var localCoursesIds = []
                    for (var i = 0; i < this.coursesAll.length; i++) {
                        if(this.selectedCourse[i] == true) {
                            localCoursesIds.push(this.coursesAll[i].id)
                        }
                    }                    
                    var params = {
                        'student_id': this.student.id,
                        'courses': localCoursesIds
                    }
                    this.cancelAdmission(params)
                        .then((res) => {
                            if(res.data.error_message == 1)
                            {
                                this.$eventHub.$emit('create-success')
                                this.$swal({
                                    title: 'Do you want to refund money?',
                                    type: 'question',
                                    showCancelButton: true,
                                    cancelButtonText: '<i class="fa fa-thumbs-down"></i>&nbsp;No',
                                    cancelButtonColor: '#f94b4b',
                                    confirmButtonText:'<i class="fa fa-thumbs-up"></i>&nbsp;Yes',
                                    confirmButtonColor: '#49ab4c',
                                    focusConfirm: true,
                                    reverseButtons: true,
                                    showCloseButton: true,      
                                    customClass: 'widthPopup',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    footer: 'You may refund money later on.'
                                }).then(result => {
                                    if (result.value) {
                                        var pathToRefund = '/refund/create?student_id='+ this.student.id
                                        this.$router.push({path: pathToRefund});                                                               
                                    } else {
                                        this.$router.push({ name: 'cancel_admission.index' })
                                    }
                                })                     
                            }
                            else if(res.data.error_message == 2)
                            {
                                this.$eventHub.$emit('action-error')
                            }  
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                  }
                  else{
                    if(this.student != null && this.selectedCourse.length == 0) {
                        this.$eventHub.$emit('select-at-least-one-course')
                        return
                    }                    
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
              });
            }        
        }
}
</script>


<style scoped>
   #loadingDiv {
       background-color: #000;
       height: 100%;
       left: 0;
       position: fixed;
       top: 0;
       width: 100%;
       z-index: 10020;
       filter: alpha(opacity=70);
       -moz-opacity: .7;
       -khtml-opacity: .7;
       opacity: .7;
   }
   .ajax-loader {
       position: fixed;
       left: 50%;
       top: 50%;
       margin-left: -32px;
       margin-top: -32px;
       display: block;
       z-index: 10020!important;
   }
   img {
    vertical-align: middle;
   }
   img {
    border: 0;
   }
.tableFixHead          { overflow-y: auto; height: 250px; }
.tableFixHead thead th { position: sticky; top: 0; }

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }   
</style>
 