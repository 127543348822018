<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Course Fee Adjustments</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/course-fee-adjustments'">Course Fee Adjustments</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Course Fee Adjustment</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Course Fee Adjustment</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                    <div class="col-md-12">
                                        <bootstrap-alert />
                                    </div>
                                    <div class="col-md-6 col-xs-12">    
                                            <div ref="branches" class="form-group">
                                                <label for="branches">Branch<p class="astrisk">*</p></label>
                                                <select v-validate="'required'" disabled name="branch" label="branch" class="form-control select2 branches" placeholder="Select Branches" @change="updateBranch">
                                                    <option value="" selected>Select Branches</option>
                                                    <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                                                        <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id">
                                                            {{branch.branch_name}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                                <span class="error" v-show="errors.has('branch')">
                                                    {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                                </span>
                                            </div>   
                                    </div> 
                    
                                    <div class="col-md-6 col-xs-12">     
                                        <div ref="course" class="form-group">
                                            <label for="course">Course<p class="astrisk">*</p></label>
                                            <select v-validate="'required'" name="course" disabled label="course" class="form-control select2 course" placeholder="Select Courses" @change="updateCourse">
                                                <option value="" selected>Select Course</option>
                                                <!-- <optgroup v-for="(course, index) in branchCourseAll" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'"> -->
                                                    <option v-for="(course, i) in branchCourseAll" :key="i" :value="course.id">
                                                        {{course.course_name}}
                                                    </option>
                                                <!-- </optgroup> -->
                                            </select>
                                            <span class="error" v-show="errors.has('course')">
                                                {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                            </span>
                                        </div>
                                        
                                    </div> 
                                    <div class="col-md-12 col-xs-12">
                                        <section v-if="course_fees.length != 0">
                                            <div class="table-responsive">
                                            <table class="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 150px;">Fee type</th>
                                                            <th style="width: 150px;">Course Fee</th>
                                                            <th style="width: 150px;">Old Discount Amount</th>
                                                            <th style="width: 280px;">Discount Amount</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody v-for="(feesType, i) in course_fees" :key="i">
                                                        <tr>
                                                            <td>{{feesType.fee_name}}</td>
                                                            <td><i data-v-4e2af7c2="" class="fa fa-rupee"></i> {{feesType.total_fees}}</td>
                                                            <td><i data-v-4e2af7c2="" class="fa fa-rupee"></i> {{feesType.old_discount_amount}}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="percentage_from"
                                                                    id="percentage_from"
                                                                    maxlength="10"
                                                                    v-model="feesType.discount"
                                                                    oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                    placeholder="Enter Discount Amount"
                                                                    v-validate="'required'"
                                                                    @input="updateNewFeeAmount(feesType.discount,i)"
                                                                    >                                                            
                                                            </td>         
                                                        </tr>
                                                    </tbody>
                                            </table>
                                            <span v-if="this.discount_error != ''" class="error">{{this.discount_error}}</span></div>                          
                                        </section>
                                    </div>

                                     
                                    
                                    <div class="col-md-2 col-xs-2 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                               
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {        
        return {
            branchesDetails:[],
            dpconfigDateFuture:this.$root.dpconfigDateFuture,
            branch_id:'',
            course_id:'',
           course_fees: [],
           discount_error:''
        }
    },
    computed: {
        ...mapGetters('CourseFeeAdjustmentSingle', ['setEditCourseFeeData','branchCourseFee','branchCourseAll','item', 'project','loading','loadingFeesAvailable', 'coursesAll', 'branchMastersAll', 'feesTypeAll','courseDetailedData','allCourseLevels','feesAvailable','nextFeeStartDate','mainProjectAll', 'branch']),
        computedOldDate() {
            if(this.courseDetailedData && this.courseDetailedData[0]) {
                var formatedFromDate = this.courseDetailedData[0].from_date.split('-');
                var finalOldDate = formatedFromDate[2]+'/'+formatedFromDate[1]+'/'+formatedFromDate[0]
                return finalOldDate;
            }
        }                
    },
    created() {
        this.branchesDetails = this.$store.state.AuthIndex.branches;
        this.getEditData({'branch_id':this.$route.params.branch_id,'course_id':this.$route.params.course_id});
        // this.branchesDetails = this.$store.state.AuthIndex.branches;
        // this.fetchFeesTypeAll()       
    },
    mounted() {
        let _this = this;
        $(".branches").select2({placeholder: "Select Branches"}).on('change', function () {
            _this.updateBranch($(this).select2("val"))
        });
        $(".course").select2({placeholder: "Select Course"}).on('change', function () {
            _this.updateCourse($(this).select2("val"))
        });
    },
    destroyed() {
        this.resetState()
        this.setFees_type(null);
        this.setFees_amount('');
    },
    watch:{
        setEditCourseFeeData(value){
            // console.log("sd",value);
            $(".branches").val(value.branch_id).trigger('change');
            //console.log("fdgdg",value.course_id)
            setTimeout(() => {
                $(".course").val(value.course_id).trigger('change');
            }, 100);            
        },
        branchCourseFee(value){
            this.course_fees = [];
            let _this = this;
            // console.log("sdds",value.adjusment_details)
            value.map((course_fee, feeIndex)=>{
                //console.log("sdsd",course_fee)
                _this.course_fees.push({ fee_name:course_fee.fee_type_details.name,fee_type: course_fee.fees_type,total_fees:Number(course_fee.fees_amount),old_discount_amount:0,discount: 0 });
                ///console.log("dff",fee,feeIndex)
            });

           // console.log("course fee",typeof _this.course_fees[1].fee_type);

            (this.setEditCourseFeeData.course_fee).map((fee, feeIndex)=>{
                var foundIndex = _this.course_fees.findIndex(x => x.fee_type == fee.fees_type);
                if(foundIndex !== -1){
                    _this.course_fees[foundIndex].old_discount_amount = Number(fee.discount_amount);
                }
                // _this.course_fees.push({ fee_name:fee.fee_name,fee_type: fee.fees_type,total_fees:Number(fee.fee_amount),old_discount_amount:Number(fee.discount_amount),discount: 0 });
                ///console.log("dff",fee,feeIndex)
            });
            //console.log("vcal",this.course_fees);
            
        },
        mainProjectAll(value){
            $('#lstBox1').empty();
            for (var i = 0; i < value.length; i++) {
                //console.log("loop",value[i]);
                $('#lstBox1')
                    .append($("<option data-hours='"+value[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",value[i].id)
                        .text(value[i].project_name));
            }
        }
    },
    methods: {
        ...mapActions('CourseFeeAdjustmentSingle', ['storeData', 'resetState', 'setProject', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type', 'fetchFeesTypeAll', 'fetchBranchMasterAll','fetchCourseDetailedData','fetchCoursesLevel','setStartDate','updateBunchData','fetchIfFeesAvailable','setMainProject','fetchProjects','fetchBranchCourses','fetchCourseFees','getEditData','updateData']),
        
        countTotalFees(){
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            }
            this.setTotal_fees(myVal.toFixed(2));            
            return myVal.toFixed(2);
        },
        computedOldFee(feesType) {
            var oldFee = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                 if(feesType == this.courseDetailedData[i].fees_type) {
                    oldFee = this.courseDetailedData[i].fees_amount
                 }
             }
            return oldFee;
        },        
        updateProject() {
            var localFlag = true;
            if(!this.branch){                    
                this.$validator.errors.add({
                    id: 'branch',
                    field: 'branch',
                    msg: 'please selecte branch',
                    scope: this.$options.scope,
                });
                localFlag = false
                this.myFlag = false;
                return false;
            }
            this.$validator.errors.clear()
            this.myFlag = true                        
        },
        updateNewFeeAmount(e, index) {
            //console.log(e,this.course_fees[index].total_fees)
            if(e == '' || e == 0){
                this.course_fees[index].discount = 0;
            }else if(e > this.course_fees[index].total_fees){
                this.course_fees[index].discount = this.course_fees[index].total_fees;
            }
            else{
                this.course_fees[index].discount = e.replace(/^0+/, '');
            }
            /*this is use for amount total*/
          
        },        
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },
        updateStartDate(e) {
            this.setStartDate(e.target.value)
        },           
        updateIs_gst_applicable(e) {
            // this.setIs_gst_applicable(e.target.value)
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                this.totalCountGST = 0;
                this.setGst_rate(0)
            }
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },
        updateBranch(value) {
            // console.log('value',value);
            this.branch_id = value;
            //fetch courses
            this.fetchBranchCourses(value);
            
        },
        updateCourse(value) {
            this.course_id = value;
            //fetch course fees
            this.fetchCourseFees({'branch_id':this.branch_id,'course_id':value});
            
        },
        updateFees_type(value) {
            if(value && value.other_value != null){
                this.totalCountGST = (value.other_value)?value.other_value:0;
                this.setGst_rate(this.totalCountGST);
                this.setFees_type(value)
            }
        },       
        submitForm() {
            this.discount_error='';
            this.$validator.validate().then(result => {           
                var form_details = []
                if (result) {
                    let count = 0;
                    this.course_fees.forEach(function(fee, feeIndex){
                        if(fee['discount'] == 0){
                           count++;
                        }
                    });
                    
                    if(count == this.course_fees.length){
                        this.discount_error = 'Please add atlease one discount amount.';
                       return false; 
                    }
                    var obj = {
                        'branch_id':this.branch_id,
                        'course_id':this.course_id,
                        'course_fees':this.course_fees
                    }
                    this.updateData(obj)
                        .then((res) => {                  
                            if(!res.data.result){
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    if(key == 'project_id'){ 
                                        this.$eventHub.$emit('common',3,value[0])
                                        return false;
                                    }
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.buttonLoading = false
                                return '';
                            }
                            this.$router.push({ name: 'course_fee_adjustment.index' })
                            this.$eventHub.$emit('update-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                    }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
            });            
        }        
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>