<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Common Master</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/common-masters'">Common Master</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Common</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>


        <section class="content">

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Common</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                   <div class="col-md-12">
                        <table class="table table-bordered table-striped not-responsive-grid">
                            <tbody>
                            <tr>
                                <th>Type</th>
                                <td>
                                    <span v-if="item.type_name_id !== null">
                                        {{ item.type_name_id.type_name }}    
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{{ item.name }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                 <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>

        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CommonMastersSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('CommonMastersSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
 