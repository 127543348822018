<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Fee Collection Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Fee Collection Report</h3>                               
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                    
                                </div>

                                <div class="myrange-date">
                                    <div  class="col-md-6 col-md-12">
                                        <date-r-picker @updateRange="updateRange" ></date-r-picker>
                                    </div>
                                </div>
                        		<div class="clearfix"></div>
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="gender">Report Option<p class="astrisk">*</p></label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    v-validate="'required|included:1,2'"
                                                    :value="1"
                                                    :checked="item.gender === 1"
                                                    @change="updateGender(1)"
                                                    >
                                            <span class="checkmark"></span> Detail
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="2"
                                                    :checked="item.gender === 2"
                                                    @change="updateGender(2)"
                                                    >
                                            <span class="checkmark"></span> Summary
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('gender')">
                                      {{ $root.updateErrorMessage('gender',this.errors,'Report Option')}}
                                    </span>
                                </div>           
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner 
                            class="std_information_save btn btn-block btn-success new_branch_save"
                            :isLoading="loading"
                            :disabled="loading">
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
            <div class="row"  v-if="dataSeen && item.gender == 2">
                <div class="col-xs-12">  
                    <div v-if="loading" class="panel panel-default">
                      <div class="panel-body">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                </div>
                            </div>
                      </div>
                    </div>  
					<div v-else-if="reportsDataAll.length == 0">
						<div class="panel panel-info mypanel">
							<div class="panel-body">
					            <div class="text-center">
						              <h3>&#9785;</h3>
						              <h4>Looks like no data available!</h4>
					            </div>   
							</div>
						</div>
					</div>                                                                   
                    <div v-else class="panel panel-info mypanel">
                      <div class="panel-heading">Summary</div>
                      <div class="panel-body"> 
                        <div class="col-md-6">
                                <div class="col-xs-12">
                                    <div class="box mybox">
                                        <div class="box-header with-border">
                                            <h3 class="box-title">Fee Collection Report Summary</h3>
                                            <div class="box-tools pull-right">
                                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                                    <i class="fa fa-minus"></i>
                                                </button> 
                                            </div>
                                        </div>                                           
                                        <div class="box-body">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                                </button>     
                                                <button type="button" class="btn btn-default btn-sm" @click="resetFiltersSummary">
                                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                                </button>                            
                                            </div>
                                        </div>                                
                                        <div class="box-body">
                                            <div class="row" v-if="loading">
                                                <div class="col-xs-4 col-xs-offset-4">
                                                    <div class="alert text-center">
                                                        <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive setting-group">
                                                <datatable
                                                        v-if="!loading"
                                                        :columns="columnsSummary"
                                                        :data="dataSummary"
                                                        :total="totalSummary"
                                                        :query="querySummary"
                                                        :xprops="xprops"
                                                        />                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                        </div>
                        <div class="col-md-6">
		                    <table class="table table-hover">
		                        <thead>
		                          <tr>
		                            <th>Payment Mode</th>
		                            <th>Amount</th>
		                          </tr>
		                        </thead>
		                        <tbody>
		                          <tr v-for="value in paymentModeDataFinal">
		                            <td>{{value.payment_mode}}</td>
                                            <td> <i class="fa fa-rupee"></i> {{value.amount}}</td>
		                          </tr>
		                        </tbody>
		                    </table>                        	
	                        <div style="float: right; 
	                            font-weight: bold;
	                            font-size: 1.3em;
	                            border: 1px solid #d2d6de;
	                            padding: 10px;
	                            box-shadow: 1px 1px 1px 1px #afd0b3;">
	                           <span>Total  Amount:</span> 
	                            <span 
	                             style="
	                                padding-top: 0.4em;
	                                padding-right: 0.6em;
	                                padding-bottom: 0.3em;
	                                padding-left: 0.6em;">
	                                <i class="fa fa-rupee"></i>{{totalFeesComputed}}
	                            </span>
	                        </div> 		                    
                        </div>                          
                      </div>
                    </div>                           
                </div>
            </div>      
            <div class="row"  v-if="dataSeen && item.gender == 1">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Detail</h3>
                                <div class="box-tools pull-right">
                                    <transition name="bounce">
                                        <div v-if="total != 0" class="btn-group">
                                            <button @click="exportFeeCollectionList" type="button" title="Export to Excel" class="btn btn-box-tool dropdown-toggle upper-background" data-toggle="dropdown" aria-expanded="false">
                                                <i class="fa fa-file-excel-o" style="color: #207245; font-size: 2em;"></i>
                                            </button>
                                        </div>
                                    </transition>
                                </div>  
                        </div>                     	
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                          
                          <div class="totalFeesCount" style="float: right; 
                                font-weight: bold;
                                font-size: 1.3em;
                                border: 1px solid #d2d6de;
                                padding: 10px;
                                box-shadow: 1px 1px 1px 1px #afd0b3;">
                               <span>Total  Amount:</span> 
                                <span 
                                 style="
                                    padding-top: 0.4em;
                                    padding-right: 0.6em;
                                    padding-bottom: 0.3em;
                                    padding-left: 0.6em;">
                                    <i class="fa fa-rupee"></i>{{totalFeesComputed}}
                                </span>
                            </div> 
                          
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data"
                                    class="table-responsive setting-group"
                                    :total="total"
                                    :query="query"
                                    :xprops="xprops"
                                    />                                   
                        </div>
                    </div>
                </div>
            </div>                               
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
import drp from '../../comps/dateRangePicker'
components['date-r-picker'] = drp
import StudentName from '../../dtmodules/StudentName'
import Amount from '../../dtmodules/Amount'

export default {
    components,
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},        	
            dataSeen: false,
            columns: [
                { title: '#', field: 'row_number', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },

                { title: 'Receipt Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                
                // { title: 'Receipt Date', field: 'receipt_date', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Student', tdComp:StudentName, field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Date' },
                { title: 'Manual Receipt no', field: 'manual_receipt_no', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Receipt Type', field: 'receipt_type', sortable: true, thComp: 'FilterTh', tdClass: 'Receipt Type ' },
                { title: 'Total Amount', field: 'receipt_amount', tdComp:Amount, sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' }, //, tdStyle: 'text-align: right;', thStyle: 'text-align: center;'
            ],
            columnsSummary: [
                { title: 'Receipt Date', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Holiday Date'},                  
                // { title: 'Receipt Date', field: 'receipt_date', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Receipt Amount', field: 'receipt_amount', tdComp:Amount, sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' },
            ],             
            querySummary: { sort: 'student_name' },
            query: { sort: 'student_name' },
            xprops: {
                module: 'FeeCollectionReportIndex',
                route: 'fee_collection_report',
                permission_prefix: 'fees_collection_report_'
            }            
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchBranchMasterAll().then(()=>{});
        this.fetchPaymentModeAll().then(()=>{});
        let dateRange = { 
                startDate: moment(),
                endDate: moment(),
            }
        this.updateRange(dateRange)
    },   
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){},
        querySummary: {
            handler(query) {
                this.setQuerySummary(query)
            },
            deep: true
        },
        dataSummary: function(val){}
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('FeeCollectionReportIndex', ['item','data', 'total','dataSummary','totalSummary', 'loading', 'relationships','branchMastersAll','reportsDataAll','paymentModeAll','summaryDataAll']),
        summaryDataComputed() {
          var localvar = [];
          this.reportsDataAll.forEach(function(value,index){
                var index = -1;
                var filteredObj = localvar.find(function(item, i){
                  if(item.original_date_a == value.original_date_a){
                    index = i;
                    return i;
                  }
                });
                if(index == -1) {
                  localvar.push({
                    'original_format_date_a': value.original_format_date_a,
                    'original_date_a': value.original_date_a,
                    'receipt_amount': value.receipt_amount
                  });
                } else {
                   localvar[index].receipt_amount = parseFloat(localvar[index].receipt_amount) + parseFloat(value.receipt_amount)  
                }
          }.bind(this))     
          return localvar;
        },        
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                this.branchMastersAll.unshift({
                    'branch_name': 'All',
                    'id': '0'
                });
            }
            return this.branchMastersAll;
        },        
        paymentModeData() {
          var localvar = [];
          this.reportsDataAll.forEach(function(valueD,index){
          	valueD.receipt_payment_mode.forEach(function(value,index){
		        var index = -1;
		        var filteredObj = localvar.find(function(item, i){
		          if(item.payment_mode == value.payment_mode){
		            index = i;
		            return i;
		          }
		        });
		        if(index == -1) {
	              localvar.push({
	              	'payment_mode': value.payment_mode,
	              	'amount': parseFloat(value.amount)
	              });
		        } else {
		           localvar[index].amount = parseFloat(localvar[index].amount) + parseFloat(value.amount)  
		        }
          	})
          }.bind(this))
          return localvar;
        },
        paymentModeDataFinal() {
        	var localPaymentModeAll = this.paymentModeAll;
	        this.paymentModeData.forEach(function(value,index){
		        var filteredObj = localPaymentModeAll.find(function(item, i){
		          if(item.value_id == value.payment_mode){
		            value.payment_mode = item.name
		          }
		        });
	        })
        	return this.paymentModeData;
        },
        totalFeesComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.receipt_amount);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);             
        },                
    },
    methods: {
        ...mapActions('FeeCollectionReportIndex', ['resetState','fetchBranchMasterAll','getReportsData','setBranch','setQuery','setQuerySummary',
        	'fetchFeeCollectionData','setJoining_date','setExit_date','setGender','fetchPaymentModeAll','setSummaryDataAll','exportFeeCollectionListStore']),
        exportFeeCollectionList() {
            var dataObj = {
                'branch_id': this.item.branch_id.id,
                'joining_date': this.item.joining_date,
                'exit_date': this.item.exit_date
            }
            this.exportFeeCollectionListStore(dataObj)
        },
        updateBranch(value) {
            this.setBranch(value)
            this.dataSeen = false;
        },  
        updateRange(e){
//          console.log(e)
          let st = moment(e.startDate).format('YYYY-MM-DD')
          let ed = moment(e.endDate).format('YYYY-MM-DD')
          this.setJoining_date(st)
          this.setExit_date(ed)
          this.dataSeen = false;
        },
        updateJoining_date(e) {
            if(e && e.target.value != ''){            
                this.exitDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };
                this.setExit_date(null)
            }
            this.setJoining_date(e.target.value)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            this.setExit_date(e.target.value)
            this.dataSeen = false;
        },             
        updateGender(value) {
            this.setGender(value)
            this.dataSeen = false;
        },         
        resetFilters(){
            var filtersField= [];
            filtersField = ['row_number','original_date','original_format_date','student_name','manual_receipt_no','receipt_amount','receipt_type'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
        },        
        resetFiltersSummary(){
            var filtersField= [];
            filtersField = ['original_date_a','original_format_date_a','receipt_amount'];
            this.$root.resetSearchFilters(Vue.delete,this.querySummary,filtersField);
        },           
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.fetchFeeCollectionData()
                    .then(() => {
                        this.setSummaryDataAll(this.summaryDataComputed)
                        this.resetFilters()
                        this.querySummary = {}
                        this.query = {}                        
                    })                     
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
.mybox {
    border: 1px solid #eee;    
}
</style>
 