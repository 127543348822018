 function initialState() {
    return {
        all: [],
        item: {
            search_course_name: null,
            search_batch_name: null,
            search_student_name: null,
            search_batch_id: null,
        },
        relationships: {
            'role': 'title',
            'search_course_name': 'course_name',
            'search_batch_name': 'batch_name',
            'search_student_name': 'student_name',
            'search_batch_id': 'batch_name',
        },
        query: {},
        batchAll:[],
        studentAll:[],
        courseListWithDistinctAll:[],
        loading: false
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['student_name', 'lab_name', 'batch_name','display_course_name','original_format_date','original_date','original_format_date_a','original_date_a','original_format_date_b','original_date_b','batch_status_text'].forEach(field => {
            switch (typeof queryFilter[field]) {
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })

        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['student_name', 'lab_name', 'batch_name','display_course_name','original_format_date','original_date','original_format_date_a','original_date_a','original_format_date_b','original_date_b','batch_status_text'].forEach(field => {
            switch (typeof queryFilter[field]) {
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })

        return rows.length;
    },
    item: state => state.item,
    courseListWithDistinctAll: state => state.courseListWithDistinctAll,
    studentAll: state => state.studentAll,
    batchAll: state => state.batchAll,
    loading:       state => state.loading,
    relationships: state => state.relationships
}

const actions = {
    fetchData({ commit, state , dispatch},date_filter) {
        commit('setLoading', true)
        axios.get('/api/v1/student-batch' ,{params:date_filter})
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {        
        axios.delete('/api/v1/student-batch/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                        resolve();
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
//                          dispatch(
//                              'Alert/setAlert',
//                              { message: message, errors: errors, color: 'danger' },
//                              { root: true })
                          reject(error)
                    })     
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
    markAsCompletedIndexFunction({ commit, state, dispatch }, id) {
        axios.get('/api/v1/markAsCompleted/' + id)
            .then(response => {
                commit('setAll', response.data.data)               
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },  
     fetchCourseListWithDistinctAll({ commit, dispatch }) {
        axios.get('/api/v1/getCourseListWithDistinct')
            .then(response => {
                commit('setCourseListWithDistinctAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    async fetchStudentAll({ commit }) {
        await axios.get('/api/v1/students_list')
            .then(response => {
                commit('setStudentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    }, 
    fetchBatchAll({ commit, dispatch }) {
        axios.get('/api/v1/batchesAllBranchWise')
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    }, 
    putBatchOnHold({ commit ,state ,dispatch }, id) {
        return new Promise((resolve, reject) => {  
            axios.post('/api/v1/put-batch-on-hold',{id:id})
                .then(response => {
                    dispatch('fetchData')
                    resolve()
                })
                .catch(function (error) {
//                              console.log('Error', error.message);
//                          let message = error.response.data.message || error.message
//                          let errors  = error.response.data.errors
//                          dispatch(
//                              'Alert/setAlert',
//                              { message: message, errors: errors, color: 'danger' },
//                              { root: true })
                          reject(error)
                    })     
        })
    }, 
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setCourseListWithDistinctAll(state, value) {
        state.courseListWithDistinctAll = value
    },
    setStudentAll(state, value) {
        state.studentAll = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setError(state, error) {
        state.error = error
    },     
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
