<template>
    <form @submit.stop.prevent="handleSubmit">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-4 col-xs-4">
                            <label for="project">Project Name<p class="astrisk">*</p></label>
                            <v-select
                                name="project"
                                label="project_name"
                                v-validate="'required'"
                                :value="item.project"
                                :options="projectList"
                                disabled
                            />
                            <span class="error" v-show="errors.has('project')">
                                {{ $root.updateErrorMessage('project',this.errors, 'Project Name')}}
                            </span>
                        </div>
                        <div class="col-md-4 col-xs-4">
                            <label for="topic_name">Topics Name<p class="astrisk">*</p></label>
                            <v-select
                                id="topic_name"
                                name="topic_name"
                                label="topic_name"
                                v-validate="'required'"
                                :value="item.topicAll"
                                :options="topicAll"
                                disabled
                            />
                            <span class="error" v-show="errors.has('topic_name')">
                                {{ $root.updateErrorMessage('topic_name',this.errors,'Topics Name')}}
                            </span>
                        </div>
                        <div class="col-md-4 col-xs-4" v-if="item.subtopicAll && item.subtopicAll.length != 0">
                            <label for="topic_name">SubTopics Name<p class="astrisk">*</p></label>
                            <v-select
                                id="topic_name"
                                name="topic_name"
                                label="topic_name"
                                v-validate="'required'"
                                :value="item.subtopicAll"
                                :options="subTopicAll"
                                disabled
                            />
                            <span class="error" v-show="errors.has('topic_name')">
                                {{ $root.updateErrorMessage('topic_name',this.errors,'SubTopics Name')}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">     
                                        <label ref="question" for="question">Question<p class="astrisk">*</p></label>
                                        <b-form-textarea 
                                                id="question"
                                                type="textarea"
                                                name="question"
                                                v-validate="'required'"
                                                @input="updateQuestion"
                                                v-model="item.question"
                                                placeholder="Question">
                                        </b-form-textarea>  
                                        <span class="error" v-show="errors.has('question')">
                                            {{ $root.updateErrorMessage('question',this.errors, 'Question')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="handleCancel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    components: {

    },
    props : ['project_id'],
    computed: {
        ...mapGetters('TutorSingle', ['item', 'loading', 'topicAll', 'subTopicAll', 'projectList']),
    },
    created() {
        this.setRemoveItem('')
        this.fetchProjectAll()
        this.fetchData(this.project_id)
        this.makeEmptyModel()
    },
    methods: {
        ...mapActions('TutorSingle', ['fetchTopicAll', 'fetchSubTopicAll', 'fetchProjectAll', 'fetchData', 'setQuestion', 'setRemoveItem', 'storeData', 'fetchQuestionData', 'checkQuestion']),
        handleCancel(evt) {
            this.makeEmptyModel()
            this.$emit('closeModels')
        },  
        makeEmptyModel() {
            this.setRemoveItem('')
            this.$validator.errors.remove('question')
        },
        handleSubmit () {
            this.$validator.validate().then(result => {
                if (result) {
                    this.checkQuestion({'id' : 0, 'question':this.item.question})
                    .then((res)=>{               
                        if(!res.data.result){
                            this.$validator.errors.add({
                            id: 'question',
                            field: 'question',
                            msg: 'Question is alredy exitsted.',
                            scope: this.$options.scope,
                            });
                            return false;
                        }else{
                            this.storeData()
                            .then(() => {
                                this.$emit('loadQuestionList');
                                this.makeEmptyModel();
                                this.$emit('closeModels');
                                this.$eventHub.$emit('create-success')
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                        }
                    });
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        },
        updateQuestion(value) {
            this.checkQuestion({'id' : 0, 'question':value})
            .then((res)=>{
                if(!res.data.result){
                    this.$validator.errors.add({
                       id: 'question',
                       field: 'question',
                       msg: 'Question is alredy exitsted.',
                       scope: this.$options.scope,
                    });
                    return false;
                }else{
                    this.setQuestion(value)
                }
            });
        },
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>