<template>
    <v-app id="inspire">
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="64">
                    <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            Today
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Day</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Week</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Month</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="1025" class="calendar-font">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"
                    >
                        <template v-slot:event="{ event }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="v-event-draggable"  v-bind="attrs" v-on="on">
                                        <span class="start_time">&nbsp;&nbsp;{{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }} </span>
                                        <span class="end_time" v-if="type == 'day'"> - {{ formatEventTime(event.end) }} </span>
                                        <strong>&nbsp;{{ event.batch_name }}</strong>
                                    </div>
                                </template>
                                <!-- <span>{{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}</span><br> -->
                                {{ event.lab_name }}<br>
                                Total Seat: {{ event.total_seat }}<br>
                                Occupied: {{ event.occupied }}
                            </v-tooltip>
                        </template>
                    </v-calendar>
                    <!-- offset-x -->
                    <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        left
                    >
                        <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                                <v-btn icon>
                                    <v-icon>mdi-book</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{selectedEvent.batch_name}}</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <span v-if="selectedEvent.lab_name"><b>Lab Name : </b>{{selectedEvent.lab_name}}</span><br>
                                <span v-if="selectedEvent.batch_name_and_time"><b>Batch Name : </b>{{selectedEvent.batch_name_and_time}}</span><br>
                                <span v-if="calendarBatchData"><b>Tutor Details : </b></span><br>
                                <!-- <div style="max-height:300px;overflow:auto;" v-if="calendarBatchData">
                                    <table class="table table-bordered striped" v-if="calendarBatchData">
                                        <tr v-if="calendarBatchData">
                                            <th>Tutor Name</th>
                                        </tr>
                                        <tr v-for="(details,index) in calendarBatchData.tutors" :key="index">
                                            <td>{{details.employee_name}}</td>
                                        </tr>
                                    </table>
                                </div> -->
                                <span v-if="calendarBatchData?.student"><b>Student Details : </b></span><br>
                                <div style="max-height:300px;overflow:auto;" v-if="calendarBatchData">
                                    <table class="table table-bordered striped" v-if="calendarBatchData">
                                        <tr v-if="calendarBatchData">
                                            <th>Student Name</th>
                                            <th>Course Name</th>
                                        </tr>
                                        <tr v-for="(details,index) in calendarBatchData.student" :key="index">
                                            <td>{{details.student_name}}</td>
                                            <td>{{details.course_name}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <span v-html="selectedEvent.details"></span>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text class="text-secondary" @click="selectedOpen = false">
                                    Cancel
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
    props:['refreshCount'],
        data: () => ({
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    computed: {
        ...mapGetters("TrainerDashboardSingle", ['calendarData', 'calendarBatchData'])
    },
    mounted () {
        
    },
    created(){
        
    },
    watch:{
        calendarData(value) {
            this.events = []
            value.map((e, feeIndex)=>{
                let color = 'grey darken-1'
                if(e) {
                    color = '#00D746'
                }
                this.events.push ({
                    lab_name: e.lab_name,
                    occupied: e.occupied,
                    total_seat: e.total_seat,
                    batch_name: e.batch.batch_name,
                    batch_name_and_time: e.batch.batch_name + ' ('+moment(e.batch.start_time, ["HH:mm:ss"]).format("h:mm A")+' - '+moment(e.batch.end_time, ["HH:mm:ss"]).format("h:mm A")+')',
                    start: e.session_date_time,
                    end: e.session_end_date_time,
                    color: color,
                    batch_id: e.batch_id,
                    timed: true,
                    course_enroll_id : e.student_course_enroll_id,
                    data: e
                })
            })
        }
    },
    methods: {
        ...mapActions("TrainerDashboardSingle", ['fetchTrainnerCalendarData', 'fetchTrainnerScheduleBatchDetail', 'setScheduleBatchDetails']),
      
        formatEventTime(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("h:mm A");;
        },
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            this.setScheduleBatchDetails()
            this.selectedOpen = false
            this.fetchTrainnerScheduleBatchDetail({'batch_id':event.batch_id,'session_date':event.data.session_date})
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }
            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },
        updateRange ({ start, end }) {
            const events = [];
            const min = start.date;
            const max = end.date;
            this.fetchTrainnerCalendarData({'start':min,'end':max})
            return false;
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        }
    }
}
</script>