<template> 
    <div class="project-card">
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
            <div class="col-md-12">
                <bootstrap-alert />
            </div>

            <div class="form-group col-md-6 col-xs-12">
                <label for="project_name">Project Name<p class="astrisk">*</p></label>
                <input
                        type="text"
                        class="form-control"
                        name="project_name"
                        ref="project_name"
                        maxlength="150"
                        v-validate="'required'"
                        placeholder="Enter Project Name"
                        v-model="form_data.project_name"
                        >
                <span class="error" v-show="errors.has('project_name')">
                    {{ $root.updateErrorMessage('project_name',this.errors,'Project Name')}}
                </span>                                            
            </div> 
            <div class="form-group col-md-6 col-xs-12">
                <label for="project_type">Project Type<p class="astrisk">*</p></label>
                <v-select
                    name="project_type"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Type"
                    v-model="form_data.project_type"
                    :options="projectTypes"
                    />
                <span class="error" v-show="errors.has('project_type')">
                    {{ $root.updateErrorMessage('project_type',this.errors,'Project Type')}}
                </span>                                            
            </div>  

            <div class="form-group col-md-6 col-xs-12">
                <label for="project_brand">Project Brand<p class="astrisk">*</p></label>
                <v-select
                    name="project_brand"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Brands"
                    v-model="form_data.project_brand"
                    :options="projectBrands"
                    multiple
                    />
                <span class="error" v-show="errors.has('project_brand')">
                    {{ $root.updateErrorMessage('project_brand',this.errors,'Project Brand')}}
                </span>                                            
            </div>    

            <div class="form-group col-md-6 col-xs-12">
                <label for="project_kit">Project Kit<p class="astrisk">*</p></label>
                <v-select
                    name="project_kit"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Kits"
                    v-model="form_data.project_kit"
                    :options="projectKits"
                    multiple
                    />
                <span class="error" v-show="errors.has('project_kit')">
                    {{ $root.updateErrorMessage('project_kit',this.errors,'Project Kit')}}
                </span>                                            
            </div>       
            <div class="form-group col-md-12 col-xs-12">
                <label for="project_hours">Project Hours<p class="astrisk">*</p></label>
                <input
                        type="text"
                        class="form-control"
                        name="project_hours"
                        ref="project_hours"
                        maxlength="6"
                        v-validate="'required|min_value:1'"
                        placeholder="Enter Project Hours"
                        v-model="form_data.project_hours"
                        >
                <span class="error" v-show="errors.has('project_hours')">
                    {{ $root.updateErrorMessage('project_hours',this.errors,'Project Hours')}}
                </span>                                            
            </div>             
            <div class="form-group col-md-12 col-xs-12">
                <label for="description">Description<p class="astrisk">*</p></label>
                <textarea
                        rows="3"
                        class="form-control"
                        name="description"
                        ref="description"
                        v-validate="'required'"
                        placeholder="Enter Description"
                        v-model="form_data.description"
                        ></textarea>
                <span class="error" v-show="errors.has('description')">
                    {{ $root.updateErrorMessage('description',this.errors,'Description')}}
                </span>                                            
            </div>                          
           <div class="col-md-12 col-md-12 savebtn">
                <vue-button-spinner
                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                        :isLoading="loading"
                        :disabled="loading"
                        >
                        Save
                </vue-button-spinner>
            </div> 
            </div>
        </form>
    </div>                 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
           form_data:{
               project_name:'',
               project_type:[],
               project_brand:[],
               project_kit:[],
               description:''
           }
        }
    },
    computed: {
        ...mapGetters('ProjectsSingle', ['item', 'loading','projectTypes','projectBrands','projectKits'])
    },
    created() {
        this.fetchTypes();
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchTypes','storeData']),
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
                 
            this.storeData(this.form_data)
                .then((res) => {
                    console.log(res);
                    if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            //console.log("ke",key,value);
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                        this.buttonLoading = false
                        return '';
                    }
                    this.$router.push({ name: 'projects.edit', params: {id: res.data.data.id}});                                   
                    //this.$router.push({ name: 'projects.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
      });
        }
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>
