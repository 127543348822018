function initialState() {
    return {
        item: {
            id: null,
            franchise_name: null,
            franchise_type: {'value':1,'label':'Franchise'},
            franchise_address: null,
            city_list:null,
            state_list:null,
            country_list:{'id':99,'country_name':'India'},            
            username: null,
            owner_name: null,
            owner_mobile: null,
            owner_email: null,
            user_status: 1,
            profile:null,
            franchise_pan_no: null,
        },
        // franchiseTypeAll:['Self','Franchise'],
        franchiseTypeAll:[{value:0,label:'Self'},{value:1,label:'Franchise'}],
        countryAll : [],
        stateAll : [],
        cityAll : [],
        loading: false,
    }
}
function getFranchiseType(fType=0) {
    var fTypeArray = {value:0,label:'Self'};
    if(fType==1){
        fTypeArray = {value:1,label:'Franchise'};
    }else{
        fTypeArray = {value:0,label:'Self'};
    }
    return fTypeArray
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    franchiseTypeAll: state => state.franchiseTypeAll,
    countryAll: state => state.countryAll,
    stateAll: state => state.stateAll,
    cityAll: state => state.cityAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            //console.log(state.item.franchise_type['id']);
            params.set('franchise_type',state.item.franchise_type['value']);
            params.set('country',state.item.country_list['id']);
            params.delete('country_list');
            params.set('state',state.item.state_list['id']);
            params.delete('state_list');       
            params.set('city',state.item.city_list['id']);
            params.delete('city_list');         
            
            axios.post('/api/v1/franchises', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            params.set('franchise_type',state.item.franchise_type['value']);
            params.set('country',state.item.country_list['id']);
            params.delete('country_list');
            params.set('state',state.item.state_list['id']);
            params.delete('state_list');
            params.set('city',state.item.city_list['id']);
            params.delete('city_list');

            axios.post('/api/v1/franchises/' + state.item.id, params)
                .then(response => {
                   // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axios.get('/api/v1/franchises/' + id)
            .then(response => {
                response.data.data.franchise_type = getFranchiseType(response.data.data.franchise_type);
                //console.log("dfdf",response.data.data);
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    }) 
        dispatch('fetchCountryAll');
        // dispatch('fetchStateAll');
        // dispatch('fetchCityAll');
    },
    fetchCountryStates({ commit }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStateAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        
    },
    fetchStateCities({ commit }, state_id) {
        axios.get('/api/v1/stateCitiesList/'+state_id)
            .then(response => {
                commit('setCityAll', response.data.data)
                // console.log(response)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        
    },      
    fetchCountryAll({ commit }) {
        axios.get('/api/v1/countrys/')
            .then(response => {
                commit('setCountryAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchStateAll({ commit }) {
        axios.get('/api/v1/states/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setStateAll', response.data.data)
                }
                else{
                    commit('setStateAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCityAll({ commit }) {
        axios.get('/api/v1/cities/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setCityAll', response.data.data)
                    // console.log(response.data.data)
                }
                else{
                    commit('setCityAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setFranchiseName({ commit }, value) {
        commit('setFranchiseName', value)
    },
    setFranchisePanNo({ commit }, value ) {
        commit('setFranchisePanNo', value)
    },
    setFranchiseType({ commit }, value) {
        commit('setFranchiseType', value)
    },
    setFranchiseAddress({ commit }, value) {
        commit('setFranchiseAddress', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setState({ commit }, value) {
        commit('setState', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setPincode({ commit }, value) {
        commit('setPincode', value)
    },    
    setUsername({ commit }, value) {
        commit('setUsername', value)
    },
    setOwnerName({ commit }, value) {
        commit('setOwnerName', value)
    },
    setOwnerMobile({ commit }, value) {
        commit('setOwnerMobile', value)
    },
    setOwnerEmail({ commit }, value) {
        commit('setOwnerEmail', value)
    },
    setUserStatus({ commit }, value) {
        commit('setUserStatus', value)
    },
    setProfile({ commit }, value) {
        commit('setProfile', value)
    },
    setStateAll({ commit }, value) {
      commit('setStateAll', value)
    },    
    setCityAll({ commit }, value) {
        commit('setCityAll', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setFranchiseName(state, value) {
        state.item.franchise_name = value
    },
    setFranchisePanNo(state, value) {
        state.item.franchise_pan_no = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCity(state, value) {
        state.item.city_list = value
    },
    setState(state, value) {
        state.item.state_list = value
    },
    setCountry(state, value) {
        state.item.country_list = value
    },
    setPincode(state, value) {
        state.item.pincode = value
    },    
    setCountryAll(state, value) {
        state.countryAll = value
    },
    setStateAll(state, value) {
        state.stateAll = value
    },
    setCityAll(state, value) {
        state.cityAll = value
    },       
    setFranchiseType(state, value) {
        state.item.franchise_type = value
    },
    setFranchiseAddress(state, value) {
        state.item.franchise_address = value
    },
    setFranchiseTypeAll(state, value) {
        state.franchiseTypeAll = value
    },
    setUsername(state, value) {
        state.item.username = value
    },
    setOwnerName(state, value) {
        state.item.owner_name = value
    },
    setOwnerMobile(state, value) {
        state.item.owner_mobile = value
    },
    setOwnerEmail(state, value) {
        state.item.owner_email = value
    },
    setUserStatus(state, value) {
        state.item.user_status = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setProfile(state, value) {
        state.item.profile = value
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
