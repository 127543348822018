<template>
  <div class="popup-form add-country">
    <b-modal id="assignLabModel"
            hideFooter
             ref="modal"
             title="Assign Course Lab"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @hide="handleCancel"
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <AssignCourseLab v-if="courseId!=''" @loadItems="loadItems" @closeModel="closeModel" :courseId="courseId"></AssignCourseLab>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AssignCourseLab from '@/components/cruds/Course/AssignCourseLab'
export default {
    components:{
        AssignCourseLab
    },
    props: ['courseId'],
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('BranchsIndex', ['selectedLicenseTab']),
    },
    methods: {
        ...mapActions('CoursesIndex', ['setLabCourse']),
        
        handleCancel(evt){
            //console.log("cancel");
            // this.addShowDataAll();
        }, 
        closeModel(){
            this.$emit('setLabCourse',[]); 
            this.$refs.modal.hide();
        },  
        loadItems(){
            this.$emit('loadItems');
        }, 
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            //console.log("sdsd",this.showId)
            //  this.fetchShowData(this.showId);
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    }
}
</script>
<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    /* border-color: black; */
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>