function initialState() {
    return {
        item: {
            id: null,
            discount_type: null,
            course_vertical:null,
            course_category:null,
            coupon_code:null,
            coupon_amount: null,
            expiry_date: null,
            first_admission:null,
            seat_limit: null,
            branch: [],
            project:[],
            course:[],
            parent:[],
            employee:[],
            min_spends:'',
            max_spends:'',
            coupon_limit:'',
            course_limit:'',
            user_limit:'',
        }, 
        loading: false,
        discountTypeAll: [],
        courseVerticalAll: [],
        courseCategoryAll: [],
        branchAll: [],
        projectAll:[],
        courseAll:[],
        parentAll:[],
        employeeAll:[],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    discountTypeAll: state => state.discountTypeAll,
    courseVerticalAll: state => state.courseVerticalAll,
    courseCategoryAll: state => state.courseCategoryAll,
    branchAll: state => state.branchAll,
    projectAll: state => state.projectAll,
    courseAll: state => state.courseAll,
    parentAll: state => state.parentAll,
    employeeAll: state => state.employeeAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();         

            if (_.isEmpty(state.item.discount_type)) {
                params.set('discount_type', '')
            } else {
                params.set('discount_type', state.item.discount_type.value_id)
            }
            if (_.isEmpty(state.item.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', state.item.course_vertical.id)
            }
            if (_.isEmpty(state.item.course_category)) {
                params.set('course_category', '')
            } else {
                params.set('course_category', state.item.course_category.id)
            }
            params.set('coupon_code', '')
            if (state.item.coupon_code) {
                params.set('coupon_code', state.item.coupon_code)
            }
            params.set('coupon_amount', '')
            if (state.item.coupon_amount) {
                params.set('coupon_amount', state.item.coupon_amount)
            }
            params.set('expiry_date', '')
            if (state.item.expiry_date) {
                params.set('expiry_date', state.item.expiry_date)
            }
            if (state.item.first_admission) {
                params.set('first_admission', 1)
            }else{
                params.set('first_admission', 0)
            }
            if (state.item.branch && state.item.branch.length != 0) {
                var branchIds = state.item.branch;
                for (let index in branchIds) {
                    params.set('branch_id[' + index + ']', branchIds[index].id);
                }
            }else{
                params.set('branch_id', '')
            }
            
            if (state.item.project && state.item.project.length != 0) {
                var projectIds = state.item.project;
                for (let index in projectIds) {
                    params.set('project_id[' + index + ']', projectIds[index].id);
                }
            }else{
                params.set('project_id', '')
            }
            if (state.item.course && state.item.course.length != 0) {
                var courseIds = state.item.course;
                for (let index in courseIds) {
                    params.set('course_id[' + index + ']', courseIds[index].id);
                }
            }else{
                params.set('course_id', '')
            }
            if (state.item.parent && state.item.parent.length != 0) {
                var parentIds = state.item.parent;
                for (let index in parentIds) {
                    params.set('parent_id[' + index + ']', parentIds[index].id);
                }
            }else{
                params.set('parent_id', '')
            }
            if (state.item.employee && state.item.employee.length != 0) {
                var employeeIds = state.item.employee;
                for (let index in employeeIds) {
                    params.set('employee_id[' + index + ']', employeeIds[index].id);
                }
            }else{
                params.set('employee_id', '')
            }
            params.set('min_spends', state.item.min_spends)
            params.set('max_spends', state.item.max_spends)
            params.set('coupon_limit', state.item.coupon_limit)
            params.set('course_limit', state.item.course_limit)
            params.set('user_limit', state.item.user_limit)

            axiosLms.post('/api/v1/coupons/store', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                commit('setLoading', false)
            })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if (_.isEmpty(state.item.discount_type)) {
                params.set('discount_type', '')
            } else {
                params.set('discount_type', state.item.discount_type.value_id)
            }
            if (_.isEmpty(state.item.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', state.item.course_vertical.id)
            }
            if (_.isEmpty(state.item.course_category)) {
                params.set('course_category', '')
            } else {
                params.set('course_category', state.item.course_category.id)
            }
            params.set('coupon_code', '')
            if (state.item.coupon_code) {
                params.set('coupon_code', state.item.coupon_code)
            }
            params.set('coupon_amount', '')
            if (state.item.coupon_amount) {
                params.set('coupon_amount', state.item.coupon_amount)
            }
            params.set('expiry_date', '')
            if (state.item.expiry_date) {
                params.set('expiry_date', state.item.expiry_date)
            }
            if (state.item.first_admission) {
                params.set('first_admission', 1)
            }else{
                params.set('first_admission', 0)
            }
            if (state.item.branch && state.item.branch.length != 0) {
                var branchIds = state.item.branch;
                for (let index in branchIds) {
                    params.set('branch_id[' + index + ']', branchIds[index].id);
                }
            }else{
                params.set('branch_id', '')
            }
            
            if (state.item.project && state.item.project.length != 0) {
                var projectIds = state.item.project;
                for (let index in projectIds) {
                    params.set('project_id[' + index + ']', projectIds[index].id);
                }
            }else{
                params.set('project_id', '')
            }
            if (state.item.course && state.item.course.length != 0) {
                var courseIds = state.item.course;
                for (let index in courseIds) {
                    params.set('course_id[' + index + ']', courseIds[index].id);
                }
            }else{
                params.set('course_id', '')
            }
            if (state.item.parent && state.item.parent.length != 0) {
                var parentIds = state.item.parent;
                for (let index in parentIds) {
                    params.set('parent_id[' + index + ']', parentIds[index].id);
                }
            }else{
                params.set('parent_id', '')
            }
            if (state.item.employee && state.item.employee.length != 0) {
                var employeeIds = state.item.employee;
                for (let index in employeeIds) {
                    params.set('employee_id[' + index + ']', employeeIds[index].id);
                }
            }else{
                params.set('employee_id', '')
            }
            params.set('min_spends', state.item.min_spends)
            params.set('max_spends', state.item.max_spends) 
            params.set('coupon_limit', state.item.coupon_limit)
            params.set('course_limit', state.item.course_limit)
            params.set('user_limit', state.item.user_limit)
                     
            axiosLms.post('/api/v1/coupons/'+ state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchTypes({ commit, dispatch }) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Course Vertical','Course Category', 'Discount Type']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    commit('setCourseVerticalAll', data['Course Vertical'])
                    commit('setCourseCatgoryAll', data['Course Category'])
                    commit('setDiscountTypeAll', data['Discount Type'])
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })                
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/coupons/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
                commit('setLoading', false)
        })            
        dispatch('fetchTypes')
        dispatch('fetchBranchAll')
        dispatch('fetchProjectAll')
        dispatch('fetchCourseAll')
        dispatch('fetchParentAll')
        dispatch('fetchEmployeeAll')
    },
    fetchBranchAll({ commit }) {
        axiosLms.get('/api/v1/branchesList')
            .then(response => {
                commit('setBranchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchProjectAll({ commit }) {
        axiosLms.get('/api/v1/projectList')
            .then(response => {
                commit('setProjectAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchCourseAll({ commit }) {
        axiosLms.get('/api/v1/courseList')
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchParentAll({ commit }) {
        axiosLms.get('/api/v1/parentList')
            .then(response => {
                commit('setParentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchEmployeeAll({ commit }) {
        axiosLms.get('/api/v1/employeeList')
            .then(response => {
                commit('setEmployeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    checkUniqueCode({ commit, state }, coupon_code) {
        return new Promise((resolve, reject) => {        
        axiosLms.post('/api/v1/checkCouponCode', {'coupon_code':coupon_code})
                .then(response => {
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                })  
                .finally(() => {
                    // commit('setError', false)
                })
        })
    }, 
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setCouponCode({ commit }, value) {
        commit('setCouponCode', value)
    },
    setCouponAmount({ commit }, value) {
        commit('setCouponAmount', value)
    },
    setMaxSpends({ commit }, value) {
        commit('setMaxSpends', value)
    },
    setExpiryDate({ commit }, value) {
        commit('setExpiryDate', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },  
    setExpiryDate(state, value) {
        state.item.expiry_date = value
    },  
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setCouponCode(state, value) {
        state.item.coupon_code = value
    },
    setCouponAmount(state, value) {
        state.item.coupon_amount = value
    },
    setMaxSpends(state, value) {
        state.item.max_spends = value
    },
    setCourseVerticalAll(state,course_verticals){
        state.courseVerticalAll = course_verticals;
    },
    setCourseCatgoryAll(state,course_category){
        state.courseCategoryAll = course_category;
    },
    setDiscountTypeAll(state,course_category){
        state.discountTypeAll = course_category;
    },
    setBranchAll(state, value) {
        state.branchAll = value
    }, 
    setProjectAll(state, value) {
        state.projectAll = value
    }, 
    setCourseAll(state, value) {
        state.courseAll = value
    }, 
    setParentAll(state, value) {
        state.parentAll = value
    }, 
    setEmployeeAll(state, value) {
        state.employeeAll = value
    }, 
    setLoading(state, loading) {
        state.loading = loading
    }, 
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
