
<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Evaluation</h1>
        </section>
        <section class="content">
        	<div class="row">
        		<div class="col-xs-12">
        			<div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Evaluation</h3>
                        </div>        				
                    <div class="box-body">
                        <div class="col-md-6 col-xs-12">
                             <div ref="student" class="form-group">
                                <label for="student">Student Name<p class="astrisk">*</p></label>
                                <v-select
                                    id="student"
                                    name="student"
                                    label="student_name"
                                    v-validate="'required'"
                                    :options="studentAll"
                                    @input="updateStudent"
                                    />
                                <span class="error" v-show="errors.has('student')">
                                    {{ $root.updateErrorMessage('student',this.errors,'Student Name')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                             <div ref="course" class="form-group">
                                <label for="course">Course Name<p class="astrisk">*</p></label>
                                <v-select
                                    id="course"
                                    name="course"
                                    label="display_course_name"
                                    v-validate="'required'"
                                    @input="updateCourse"
                                    :value="item.course"
                                    :options="courseAll"
                                    />
                                <span class="error" v-show="errors.has('course')">
                                    {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                </span>
                            </div>
                        </div>                                                
                    </div>     			
        			</div>
        		</div>
        	</div>
            <div class="row" v-if="item.course">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-body">
                            <div class="btn-group">

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Schedules...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableActions from '../../dtmodules/DatatableActions'
import AttendanceStatus from '../../dtmodules/AttendanceStatus'
import TopicStatusListing from '../../dtmodules/TopicStatusListing'
import components from '../../comps/'


export default {
    components,
    AttendanceStatus,
    TopicStatusListing,
    data() {
        return {
            items:[],
            columns: [
                { title: 'Schedule Date', field: 'display_session_date', sortable: true, thComp: 'FilterTh' },
                { title: 'Schedule Day', field: 'schedule_days', sortable: true, thComp: 'FilterTh' },
                { title: 'Batch Name', field: 'batch_name', sortable: true, thComp: 'FilterTh' },
                { title: 'Start Time', field: 'start_time', sortable: true, thComp: 'FilterTh' },
                { title: 'End Time', field: 'end_time', sortable: true, thComp: 'FilterTh' },
                { title: 'Attendance', tdComp:AttendanceStatus, field: 'attendance', sortable: true, thComp: 'FilterTh'},
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh'},
                { title: 'Status', field: 'display_topic_status', sortable: true, thComp: 'FilterTh'},
            ],
            query: { sort: 'schedule_date' },
            xprops: {
                module: 'StudentBatchListingIndex',
                route: 'student_batch_listing',
                permission_prefix: 'student_batch_listing_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('StudentBatchListingIndex', ['item','data', 'total', 'loading', 'relationships', 'studentAll','courseAll','listingAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){
           
        }
    },
    methods: {
        ...mapActions('StudentBatchListingIndex', ['fetchData','setCourse','setStudent','fetchStudentCourseAll','setQuery','resetState','getCountForCalendar']),
        updateStudent(value){
            this.setCourse(null);
            if(value){
                this.setStudent(value);
                this.fetchStudentCourseAll(value.id);
            }
            else{
                this.fetchStudentCourseAll(null);
            }
        },
        updateCourse(value){
            this.setCourse(value);
            if(value){
                this.getCountForCalendar();
            }
        },        
        resetFilters(){
            var filtersField= [];
            filtersField = ['display_session_date','schedule_days','batch_name','start_time','end_time','attendance','topic_name','display_topic_status'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData()
            
        } 
    }
}
</script>


<style scoped>

</style>
 