<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branch Lab</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branch-lab'">Branch Lab</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Branch Lab</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Edit Branch Lab</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="box">
                                        <bootstrap-alert />
                                        <div class="box-body">
                                            <div class="row">
                                                <div class="col-md-6 ">
                                                    <div ref="branch" class="form-group">
                                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                                        <v-select
                                                                name="branch"
                                                                label="branch_name"
                                                                v-validate="'required'"
                                                                @input="updateBranch"
                                                                :value="item.branch"
                                                                :options="branchMastersAll"
                                                                disabled
                                                                />
                                                        <span class="error" v-show="errors.has('branch')">
                                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <div ref="lab_name" class="form-group">
                                                        <label for="lab_name">Lab Name<p class="astrisk">*</p></label>
                                                        <input
                                                                type="text"
                                                                class="form-control"
                                                                name="lab_name"
                                                                ref="lab_name"
                                                                v-validate="'required'"
                                                                maxlength="200"
                                                                placeholder="Enter Lab Name"
                                                                :value="item.lab_name"
                                                                @input="updateLabName"
                                                                >
                                                        <span class="error" v-show="errors.has('lab_name')">
                                                            {{ $root.updateErrorMessage('lab_name',this.errors,'Lab Name')}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-md-6 ">
                                                    <div class="form-group">
                                                        <label for="seat_limit">Seat Limit<p class="astrisk">*</p></label>
                                                        <input
                                                                type="text"
                                                                class="form-control"
                                                                name="seat_limit"
                                                                ref="seat_limit"
                                                                maxlength="8"
                                                                v-validate="'required|numeric|min_value:0.01'"
                                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                                placeholder="Enter Seat Limit"
                                                                :value="item.seat_limit"
                                                                @input="updateSeatLimit"
                                                                >
                                                        <span class="error" v-show="errors.has('seat_limit')">
                                                            {{ $root.updateErrorMessage('seat_limit',this.errors,'Seat Limit','a valid value')}}
                                                        </span>
                                                    </div>                                   
                                                </div>                                                                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                       
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            initialCourseValue: [],
            initialCourses: [],
            initialCourseValueLength: 0,
            count: 0
        }
    },
    computed: {
        ...mapGetters('BranchLabSingle', ['item', 'loading','branchMastersAll','fullCoursesAll','studentAddedInSelectedBranchLab','loadingStudents']),
      
    },
    created() {
        this.fetchData(this.$route.params.id)
        .then(()=>{
            this.updateBranch(this.item.branch)
        })
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('BranchLabSingle', ['fetchData','fetchStudentAddedInSelectedBranchLab', 'updateData', 'resetState', 'setBranch', 'setLabName', 'setSeatLimit','setFullCourse','setLoadingStudents']),
        updateBranch(value) {
            this.setBranch(value)
        },
        updateLabName(e) {
            this.setLabName(e.target.value)
        },
        updateFullCourses(value) {
            if(this.count == 0) {
                for (var i = 0; i < this.item.branch_lab_courses.length; i++) {
                    this.initialCourseValue.push(value[i].id)
                    this.initialCourses.push(value[i])
                }
            }
            var currentValue = [],
            deletedCourse = [],
            courseToBeAddedBack = [];
            for (var i = 0; i < value.length; i++) {
                currentValue.push(value[i].id)
            }
            if(this.count == 0) {
                this.initialCourseValueLength = (currentValue.filter(value => -1 !== this.initialCourseValue.indexOf(value))).length
            }
            if(this.initialCourseValueLength != (currentValue.filter(value => -1 !== this.initialCourseValue.indexOf(value))).length) {
                //code for the condition if existing course is deleted
                for (var i = 0; i < this.initialCourseValue.length; i++) {
                    if(currentValue.indexOf(this.initialCourseValue[i]) == -1) {
                        deletedCourse.push(this.initialCourseValue[i]);
                    }
                }
            }
            if(deletedCourse.length > 0) {
                this.setLoadingStudents(false)
                var obj = {
                    'course_id': deletedCourse,
                    'branch_lab_id': this.$route.params.id
                }
                this.fetchStudentAddedInSelectedBranchLab(obj)
                .then(res => {
                    if(this.studentAddedInSelectedBranchLab.length > 0) {
                            var pushThisValue = {};
                            var filteredObj = this.studentAddedInSelectedBranchLab.find(function(item, i){
                                if(courseToBeAddedBack.indexOf(item.course_id) == -1) {
                                    courseToBeAddedBack.push(item.course_id)
                                }
                                return '';
                            });
                            for (var i = 0; i < this.initialCourses.length; i++) {
                                if(this.initialCourses[i].id == courseToBeAddedBack[0]) {
                                    pushThisValue = this.initialCourses[i];
                                }
                            }
                            this.item.branch_lab_courses.push(pushThisValue)
                                this.$validator.errors.add({
                                    id: 'courses_name',
                                    field: 'courses_name',
                                    msg: 'You can\'t delete the course because student already available in existing lab and course.',
                                    scope: this.$options.scope,
                                });                      
                    }
                })
                .finally(() => {
                    this.setLoadingStudents(true)
                })
            }
            currentValue = []
            deletedCourse = []
            this.setFullCourse(value)
            this.count++;
        },
       updateSeatLimit(e) {
            this.setSeatLimit(e.target.value)
       }, 
        submitForm() {
        this.$validator.validate().then(result => {
        if (result) {
            this.updateData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'lab_name',
                            field: 'lab_name',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'branch_lab.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }    

    }
}
</script>


<style>
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-select .dropdown-toggle .selected-tag {
    width: auto !important;
}
</style>
 