<template>            
    <div>
    <div class="btn-group mb-3">
             <b-btn v-b-modal.addCourseFaqModel v-if="$can(xprops.permission_prefix + 'create')" type="button" class="btn btn-success btn-sm">
                <i class="fa fa-plus"></i> Add New
            </b-btn> 

            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
            </button>     
            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
            </button>                      
        </div>
        <AddFaqPopup @loadItems="loadItems" v-if="$can(xprops.permission_prefix + 'create')"></AddFaqPopup>
        <EditFaqPopup @loadItems="loadItems" :editId="editId" v-if="$can(xprops.permission_prefix + 'edit')"></EditFaqPopup>
        <ShowFaqPopup :showId="showId" @loadItems="loadItems" v-if="$can(xprops.permission_prefix + 'view')"></ShowFaqPopup>
        <bootstrap-alert />
        <div class="table-responsive setting-group">
            <vue-good-table
                ref="table1"
                styleClass="vgt-table table table-bordered striped thead-dark"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :pagination-options="{
                    enabled: true,
                    perPage:10,
                    perPageDropdown: [10, 20, 40, 80, 100],
                    dropdownAllowAll: false,
                }"
                :isLoading.sync="isLoading"
                :select-options="{ 
                    enabled: false,
                }"
                :search-options="{
                    enabled: false,
                    placeholder: 'search',
                }"
                :rows="rows"
                :columns="columns"
                >
                    <div slot="emptystate" class="text-center">
                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span v-if="props.column.field == 'actions'">
                        <CourseFaqActions @editIdModel="editIdModel" @showIdModel="showIdModel" @loadItems="loadItems" :xprops="xprops" :row="props.row"></CourseFaqActions>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
                    <template slot="loadingContent">
                <CustomLoader></CustomLoader>
            </template>
            </vue-good-table>
        </div>
    </div>
              
   
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CourseFaqActions from '../../../dtmodules/DatatableActions/CourseFaqActions'
import DatatableSingle from '../../../dtmodules/DatatableSingle'
import DatatableCheckbox from '../../../dtmodules/DatatableCheckbox'
import components from '../../../comps/'
import store from '@/store' 
import CustomLoader from '@/components/CustomLoader'
import AddFaqPopup from '@/components/cruds/Course/Faqs/AddFaqPopup'
import EditFaqPopup from '@/components/cruds/Course/Faqs/EditFaqPopup'
import ShowFaqPopup from '@/components/cruds/Course/Faqs/ShowFaqPopup'

CourseFaqActions.attachStore('CourseFaqIndex');
export default {
    components,
    components: {
        CourseFaqActions,
        CustomLoader,
        AddFaqPopup,
        EditFaqPopup,
        ShowFaqPopup
    },
    data() {
        return {
            editId:'',
            showId:'',
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Title",
                    field: "title",
                    filterOptions: {
                        styleClass: 'faq_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Title', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Description",
                    field: "description",
                    filterOptions: {
                        styleClass: 'faq_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Description', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: "Actions",
                field: "actions",
                sortable: false
                }

            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'CourseFaqIndex',
                route: 'courses',
                permission_prefix: 'course_'
            }
        }
    },
    created() {
       
    },
    computed: {
        ...mapGetters('CourseFaqIndex', ['data', 'total', 'loading', 'relationships']),
    },  
    methods: {
        ...mapActions('CourseFaqIndex', ['fetchData', 'setQuery', 'resetState']),
        // ...mapActions('Rules', ['getBranchesData']),
        editIdModel(id){
            this.editId = id;
        },
        showIdModel(id){
            this.showId = id;
        },
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axiosLms
            .get("/api/v1/courseFAQ", {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters,
                course_id:this.$route.params.id
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"branch_name":"","display_franchise_name":"","mobile_no":"","branch_email":"","branch_address":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>
<style scoped>

</style>
