function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            enroll_date: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            student_address: null,
            area: null,
            country_list: {'id':99,'country_name':'India'},
            state_list: null,
            city_list: null,
            gender: null,
            pin_code: null,
            birth_date: null,
            start_date: null,
            parent: null,
            tshirt_size: null,
            school: null,
            reference: null,
            standard_id: null,
            student_status: null,           
            status: null,
            next_followup_date: null,
            reason: null,
            status_remarks: null,
            course : null,
            batch : null,
             profile:null,
        },
        admissionFormPdf:[],
        TotalAmount:0,
        TotalPaid:0,
        TotalDue:0,
        TotalOverDue:0,
        TotalRefund:0,
        CreditPoints:0,
        onlySummerGlobal: true,
        countrymastersAll: [],
        feeAdditionalAdjustment: [],
        referenceAll: [],
        statemastersAll: [],
        citymastersAll: [],
        parentAll: [],     
        tshirtSizesAll: [],
        courseComboDetailsAll: [],
        branchAdmissionFeesApplicable: [],
        schoolAll: [],
        standardAll: [],
        studentStatusAll: [],
        studentReasonAll: [],
        reasonAll: [],
        courseListAll: [],
        courseProgressAll: [],
        courseAll: [],
        batchAll: [],
        feeCourseList : [],
        studentFeeCourseList : [],
        feesTypeAvailableListAll : [],
        statusAll:[],
        receiptsAll:[],
        batchAll:[],
        loading: false,
        currentlyLoading: false,
        pendingFeeAll: [],
        admissionImage:'',
    }
}

const getters = {
    item: state => state.item,
    TotalAmount:state => state.TotalAmount,
    TotalPaid:state => state.TotalPaid,
    TotalDue:state => state.TotalDue,
    TotalOverDue:state => state.TotalOverDue,
    TotalRefund:state => state.TotalRefund,
    CreditPoints:state => state.CreditPoints,
    loading: state => state.loading,
    currentlyLoading: state => state.currentlyLoading,
    referenceAll: state => state.referenceAll,
    onlySummerGlobal: state => state.onlySummerGlobal,
    courseComboDetailsAll: state => state.courseComboDetailsAll,
    branchAdmissionFeesApplicable: state => state.branchAdmissionFeesApplicable,
    countrymastersAll: state => state.countrymastersAll,
    feeAdditionalAdjustment: state => state.feeAdditionalAdjustment,
    statemastersAll: state => state.statemastersAll,
    citymastersAll: state => state.citymastersAll,
    parentAll: state => state.parentAll,
    tshirtSizesAll: state => state.tshirtSizesAll,
    schoolAll: state => state.schoolAll,
    standardAll: state => state.standardAll,
    studentStatusAll: state => state.studentStatusAll,
    studentReasonAll: state => state.studentReasonAll,
    reasonAll: state => state.reasonAll,
    courseAll: state => state.courseAll,
    batchAll: state => state.batchAll,
    feeCourseList: state => state.feeCourseList,
    studentFeeCourseList: state => state.studentFeeCourseList,
    feesTypeAvailableListAll: state => state.feesTypeAvailableListAll,
    courseListAll: state => state.courseListAll,
    courseProgressAll: state => state.courseProgressAll,
    statusAll: state => state.statusAll,
    receiptsAll: state => state.receiptsAll,
    batchAll: state => state.batchAll,
    pendingFeeAll: state => state.pendingFeeAll,
    admissionFormPdf: state => state.admissionFormPdf,
    admissionImage: state => state.admissionImage,
}

const actions = {
    async fetchPaymentFeeAll({ commit }, obj) {
        await axios.get('/api/v1/paymentFeeList/'+obj.id+'/'+obj.rid)
            .then(response => {
                commit('setPendingFeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchData({ commit, state, dispatch }, id) {
        commit('setCurrentlyLoading', true)
        await axios.get('/api/v1/student/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
        }) 
    },
    markingCourseAsCompleted({ commit, state, dispatch }, dataObj) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/markingCourseAsCompleted', dataObj)
                .then(response => {
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },       
    fetchCountrymastersAll({ commit }) {
        axios.get('/api/v1/country')
            .then(response => {
                commit('setCountrymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStudentCount({ commit }, student_id) {
        axiosLms.get('/api/v1/pendingFeeList/'+student_id)
            .then(response => {
                commit('setPendingFeeList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    changeStatus({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        let params = new FormData();
        params.set('student_enroll_id', data.student_enroll_id);
        params.set('remark', data.remark);
        params.set('document', state.admissionImage);
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/changeStudentEnrollStatus', params)
                .then(response => {
                    resolve()
                })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        });
    },
    fetchFeeAdditionalAdjustmentCharge({ commit }) {
        axios.get('/api/v1/feeAdditionalAdjustmentCharge')
            .then(response => {
                commit('setFeeAdditionalAdjustmentCharge', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchComboFromCourseDetails({ commit }, obj) {
        await axios.get('/api/v1/courseComboDetails/'+obj)
            .then(response => {
                commit('setCourseComboDetailsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchIfBranchAdmissionFeesApplicable({ commit }, obj) {
        await axios.get('/api/v1/fetchIfBranchAdmissionFeesApplicable/'+obj)
            .then(response => {
                commit('setIfBranchAdmissionFeesApplicable', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStatemastersAll({ commit }) {
        axios.get('/api/v1/states')
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchCitymastersAll({ commit }) {
        axios.get('/api/v1/cities')
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchParentAll({ commit }) {
        axios.get('/api/v1/parentList')
            .then(response => {
                commit('setParentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchTshirtSizesAll({ commit,state }) {
        axios.get('/api/v1/tshirtSizesList')
            .then(response => {
                commit('setTshirtSizesAll', response.data.data)
                for (var i = 0; i < state.tshirtSizesAll.length; i++) {
                    if(state.tshirtSizesAll[i].value_id ==  state.item.tshirt_size) {
                        commit('setTshirt',state.tshirtSizesAll[i])
                    }
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })   
    },    
    fetchSchoolAll({ commit }) {
        // axios.get('/api/v1/school-masters')
        axios.get('/api/v1/branchWiseSchoolListing')
            .then(response => {
                commit('setSchoolAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStandardAll({ commit }) {
        axios.get('/api/v1/standardTypeList')
            .then(response => {
                commit('setStandardAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStudentStatusAll({ commit }) {
        axios.get('/api/v1/studentStatusList')
            .then(response => {
                commit('setStudentStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStudentReasonAll({ commit }) {
        axios.get('/api/v1/studentReasonList')
            .then(response => {
                commit('setStudentReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchReasonAll({ commit }) {
        axios.get('/api/v1/reasonTypeList')
            .then(response => {
                commit('setReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchCourseAll({ commit }) {
        // await axios.get('/api/v1/courses')
        await axios.get('/api/v1/allCoursesList')
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    }, 
    async fetchFeesTypeAvailableListAll({ commit }) {
        await axios.get('/api/v1/feesTypeAvailableList')
            .then(response => {
                commit('setFeesTypeAvailableListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchBatchAll({ commit, dispatch }, id) {
        axios.get('/api/v1/batchCourseList/'+ id)
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    //Fetching add course fees details
    async fetchFeeCourseList({ commit, dispatch }, obj) {
        var course_start_date = (obj.course_start_date).toString(),
        date = course_start_date.split('/'),
        newDateFormat = date[2] + "-" + date[1] + "-" + date[0];
        await axios.get('/api/v1/feeCourseListStudent/'+ obj.id + '/' + newDateFormat)
        //axios.get('/api/v1/studentFeeCourseList/'+ id)
            .then(response => {
                commit('setFeeCourseList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    }, 
    //Fetching edit course fees details
    async fetchStudentFeeCourseList({ commit, dispatch }, id) {
        await axios.get('/api/v1/studentFeeCourseList/'+ id)
            .then(response => {
                commit('setFeeCourseList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchCourseListAll({ commit, dispatch }, id) {
        axios.get('/api/v1/studentCourseList/'+ id)
            .then(response => {
                commit('setCourseListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchReceiptsAll({ commit, dispatch }, id) {
        axios.get('/api/v1/receipts', {params : {student_id:id}})
            .then(response => {
                commit('setReceiptsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchCourseListAll({ commit, dispatch }, id) {
        axios.get('/api/v1/studentCourseList/'+ id)
            .then(response => {
                commit('setCourseListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchCourseProgressAll({ commit, dispatch }, id) {
        await axios.get('/api/v1/studentCourseProgressList/'+ id)
            .then(response => {
                commit('setCourseProgressAll', response.data.data)
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            })     
    },    
    fetchStatusAll({ commit }) {
        axios.get('/api/v1/inqueryStatusList')
            .then(response => {
                commit('setStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchCountryStates({ commit }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchStateCities({ commit }, country_id) {
        axios.get('/api/v1/stateCitiesList/'+country_id)
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchStudentBatch({ commit, state , dispatch},data_filter) {
        commit('setLoading', true)
        await axios.get('/api/v1/student-batch' ,{params:data_filter})
            .then(response => {
                commit('setStudentBatch', response.data.data)
            })
            .catch(function (error) {
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchReferenceAll({ commit, dispatch }) {
        axios.get('/api/v1/referenceTypeList')
            .then(response => {
                commit('setReferenceAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })       
    },      
    async getManualAdmissionForm({ commit }, id) {
        return new Promise((resolve, reject) => {   
            axiosLms.get('/api/v1/getStudentDetailsForMaualPDF/'+id)
            .then(response => {
                if(response.data.result){
                    commit('setadmissionFormPdf', response.data.data);
                }                
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            })
        });
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setIfBranchAdmissionFeesApplicable({ commit }, value) {
        commit('setIfBranchAdmissionFeesApplicable', value)
    },
    setEnrollDate({ commit }, value) {
        commit('setEnrollDate', value)
    },
    setFirst_name({ commit }, value) {
        commit('setFirst_name', value)
    },
    setMiddle_name({ commit }, value) {
        commit('setMiddle_name', value)
    },
    setReference({ commit }, value) {
        commit('setReference', value)
    },    
    setLast_name({ commit }, value) {
        commit('setLast_name', value)
    },
    setStudent_address({ commit }, value) {
        commit('setStudent_address', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCountry_list({ commit }, value) {
        commit('setCountry_list', value)
    },
    setState_list({ commit }, value) {
        commit('setState_list', value)
    },
    setCity_list({ commit }, value) {
        commit('setCity_list', value)
    },
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setPin_code({ commit }, value) {
        commit('setPin_code', value)
    },
    setBirth_date({ commit }, value) {
        commit('setBirth_date', value)
    }, 
    setStart_date({ commit }, value) {
        commit('setStart_date', value)
    },
    setParent({ commit }, value) {
        commit('setParent', value)
    },
    setTshirt({ commit }, value) {
        commit('setTshirt', value)
    },    
    setSchool({ commit }, value) {
        commit('setSchool', value)
    },
    setStandard_id({ commit }, value) {
        commit('setStandard_id', value)
    },
    setStudent_status({ commit }, value) {
        commit('setStudent_status', value)
    },
    setStudent_reason({ commit }, value) {
        commit('setStudent_reason', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setNext_followup_date({ commit }, value) {
        commit('setNext_followup_date', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setStatus_remarks({ commit }, value) {
        commit('setStatus_remarks', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setCourseListAll({ commit }, value) {
        commit('setCourseListAll', value)
    },
    setCourseProgressAll({ commit }, value) {
        commit('setCourseProgressAll', value)
    },
    setFeeCourseList({ commit }, value) {
        commit('setFeeCourseList', value)
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setCourseAll({ commit }, value) {
        commit('setCourseAll', value)
    },
    setBatch({ commit }, value) {
        commit('setBatch', value)
    },
    setFeesTypeAvailableListAll({ commit }, value) {
        commit('setFeesTypeAvailableListAll', value)
    },
    setStatemastersAll({ commit }, value) {
      commit('setStatemastersAll', value)
    },
    setCitymastersAll({ commit }, value) {
        commit('setCitymastersAll', value)
      },
    setProfile({ commit }, value) {
        commit('setProfile', value)
    },
    setOnlySummerCampGlobal({ commit }, value) {
        commit('setOnlySummerCampGlobal', value)
    },
    setPendingFeeList({ commit }, value) {
        commit('setPendingFeeList', value)
    },
    setAdmissionImage({ commit }, value) {
        commit('setAdmissionImage', value)
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },  
    setadmissionFormPdf(state, value) {
        state.admissionFormPdf = value
    },  
    setAdmissionImage(state, value) {
        state.admissionImage = value
    },
    setPendingFeeList(state, value){
        state.TotalAmount = (value.total_fees) ? value.total_fees : 0;
        state.TotalPaid = (value.total_paid) ? value.total_paid :0;
        state.TotalDue = (value.total_due) ? value.total_due : 0;
        state.TotalOverDue = (value.totaloverdue) ? value.totaloverdue : 0;
        state.TotalRefund = (value.total_refund) ? value.total_refund : 0;
        state.CreditPoints = (value.credit_points) ? value.credit_points : 0;
    },
    setCourseComboDetailsAll(state, value) {
        state.courseComboDetailsAll = value
    },    
    setIfBranchAdmissionFeesApplicable(state, value) {
        state.branchAdmissionFeesApplicable = value
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setEnrollDate(state, value) {
        state.item.enroll_date = value
    },
    setReference(state, value) {
        state.item.reference = value
    },    
    setReferenceAll(state, value) {
        state.referenceAll = value
    },    
    setFirst_name(state, value) {
        state.item.first_name = value
    },
    setMiddle_name(state, value) {
        state.item.middle_name = value
    },
    setLast_name(state, value) {
        state.item.last_name = value
    },
    setStudent_address(state, value) {
        state.item.student_address = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCountry_list(state, value) {
        state.item.country_list = value
    },
    setState_list(state, value) {
        state.item.state_list = value
    },
    setCity_list(state, value) {
        state.item.city_list = value
    },
    setGender(state, value) {
        state.item.gender = value
    },
    setPin_code(state, value) {
        state.item.pin_code = value
    },
    setBirth_date(state, value) {
        state.item.birth_date = value
    },
    setStart_date(state, value) {
        state.item.start_date = value
    },
    setParent(state, value) {
        state.item.parent = value
    },
    setTshirt(state, value) {
        state.item.tshirt_size = value
    },    
    setSchool(state, value) {
        state.item.school = value
    },
    setStandard_id(state, value) {
        state.item.standard_id = value
    },
    setStudent_status(state, value) {
        state.item.student_status = value
    },
    setStudent_reason(state, value) {
        state.item.student_reason = value
    },   
    setStatus(state, value) {
        state.item.status = value
    },
    setNext_followup_date(state, value) {
        state.item.next_followup_date = value
    },
    setReason(state, value) {
        state.item.reason = value
    },
    setStatus_remarks(state, value) {
        state.item.status_remarks = value
    },
    setOnlySummerCampGlobal(state, value) {
        state.onlySummerGlobal = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setFeeAdditionalAdjustmentCharge(state, value) {
        state.feeAdditionalAdjustment = value
    },
    setStatemastersAll(state, value) {
        state.statemastersAll = value
    },
    setCitymastersAll(state, value) {
        state.citymastersAll = value
    },
    setParentAll(state, value) {
        state.parentAll = value
    },
    setTshirtSizesAll(state, value) {
        state.tshirtSizesAll = value
    },
    setSchoolAll(state, value) {
        state.schoolAll = value
    },
    setStandardAll(state, value) {
        state.standardAll = value
    },
    setStudentStatusAll(state, value) {
        state.studentStatusAll = value
    },
    setStudentReasonAll(state, value) {
        state.studentReasonAll = value
    },
    setReasonAll(state, value) {
        state.reasonAll = value
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setFeesTypeAvailableListAll(state, value) {
        state.feesTypeAvailableListAll = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setStatusAll(state, value) {
        state.statusAll = value
    },
    setCourseListAll(state, value) {
        state.courseListAll = value
    },
    setCourseProgressAll(state, value) {
        state.courseProgressAll = value
    },
    setFeeCourseList(state, value) {
        state.feeCourseList = value
    },
    setStudentFeeCourseList(state, value) {
        state.studentFeeCourseList = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setCurrentlyLoading(state, loading) {
        state.currentlyLoading = loading
    },    
    setReceiptsAll(state, data) {
        state.receiptsAll = data
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setBatch(state, value) {
        state.item.batch = value
    },
    setStudentBatch(state, value) {
        state.batchAll = value
    },
    setProfile(state, value) {
        state.item.profile = value
    },
    setPendingFeeAll(state, value) {
        state.pendingFeeAll = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
