<template>
  <div class="popup-form add-country">
    <b-modal id="viewAllLicenseModel"
            hideFooter
             ref="modal"
             title="View All Licenses"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @hide="handleCancel"
             @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <div class="card card-primary card-outline card-outline-tabs">
                    <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="project_tab" data-toggle="pill" href="#projects" role="tab" aria-controls="projects" aria-selected="true" @click="setTabSelection('project')">Projects</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="module_tab" data-toggle="pill" href="#modulesTabLink" role="tab" aria-controls="modulesTabLink" aria-selected="false" @click="setTabSelection('module')">Modules</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="course_tab" data-toggle="pill" href="#courses" role="tab" aria-controls="courses" aria-selected="false" @click="setTabSelection('course')">Courses</a>
                    </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="projects" role="tabpanel" aria-labelledby="project_tab" v-if="selectedLicenseTab == 'project'">
                        <Project :branchId="branchId"></Project>
                    </div>
                    <div class="tab-pane fade" id="modulesTabLink" role="tabpanel" aria-labelledby="module_tab" v-if="selectedLicenseTab == 'module'">
                        <!-- <ProjectTopics :project_id="project_id"></ProjectTopics> -->
                        <Module :branchId="branchId"></Module>
                    </div>
                    <div class="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="course_tab" v-if="selectedLicenseTab == 'course'">
                        <!-- <ProjectTopics :project_id="project_id"></ProjectTopics> -->
                        <Course :branchId="branchId"></Course>
                    </div>
                    </div>
                </div> 
            </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
import Project from '@/components/cruds/Branchs/ViewAllLicense/Project'
import Module from '@/components/cruds/Branchs/ViewAllLicense/Module'
import Course from '@/components/cruds/Branchs/ViewAllLicense/Course'
export default {
    components:{
        CustomLoader,
        Project,
        Module,
        Course
    },
    props: ['branchId'],
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('LicenseIndex', ['showData','loading']),
        ...mapGetters('BranchsIndex', ['selectedLicenseTab']),
    },
    methods: {
        ...mapActions('LicenseIndex', ['fetchShowData','addShowDataAll']),
        ...mapActions('BranchsIndex', ['setLicenseTabSelection']),
        setTabSelection(tab){
            this.setLicenseTabSelection(tab);
        },
        handleCancel(evt){
            //console.log("cancel");
            // this.addShowDataAll();
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            //console.log("sdsd",this.showId);
            this.setTabSelection('project');
            //  this.fetchShowData(this.showId);
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    }
}
</script>
<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    /* border-color: black; */
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>