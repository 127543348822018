<template>
    <div class="btn-group btn-group-xs">
        <b-btn v-b-modal.studentScheduleModel @click="passAddId(row.student_enroll_id)" title="View Student Schedule" type="button" variant="primary" class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-calendar"></i>
        </b-btn> 
        <button
            v-if="row.course_completed_date == null"
                @click="makeComplete(row.student_enroll_id,row.student_id,row.course_id)"
                type="button"
                title="Mark as Completed"
                class="btn btn-success edit_delete_btn">
            <i class="fa fa-check" aria-hidden="true"></i>
        </button>


        <router-link
            :to="'/report-card-form/'+row.enroll_id"
            tab="button"
            target="_blank"
            v-if="row.course_completed_date && row.is_allowed_to_generate_report == 1 && row.course_status == 'Completed' && row.graph_data != '' && row.header_data != '' && row.skill_data != ''"
            title="Report Card"
            class="btn btn-success edit_delete_btn"
        >
            <i class="fa fa-file-pdf" aria-hidden="true"></i>
        </router-link>

        <router-link
            :to="'/certificate-form/'+row.enroll_id"
            tab="button"
            target="_blank"
            v-if="row.course_completed_date && row.course_status == 'Completed'"
            title="Certificate"
            class="btn btn-success edit_delete_btn"
        >
            <i class="fa fa-file" aria-hidden="true"></i>
        </router-link>

        <b-btn title="Change Status" type="button" disabled variant="primary" class="btn btn-warning edit_delete_btn" v-if="row.course_status != 'Completed' && row.course_status != 'Cancel' && row.batch_exists == false">
             <i class="fa fa-file"></i>
        </b-btn>

        <router-link
        :to="'/confirm-admission-form/'+row.enroll_id"
        tab="button"
        target="_blank"
        v-if="row.batch_exists == true && row.course_status != 'Pending'"
        title="Admission Form"
        class="btn btn-warning"
    >
        <i class="fa fa-file" aria-hidden="true"></i>
    </router-link>

        <b-btn v-b-modal.studentRemarkModal @click="openRemarkPopup(row.student_enroll_id)" title="Change Status" type="button" variant="primary" class="btn btn-warning edit_delete_btn" v-if="row.course_status == 'Pending' && row.batch_exists == true">
             <i class="fa fa-file"></i>
        </b-btn>

        <b-btn v-b-modal.editStudentCourse @click="passAddId(row.student_enroll_id)" title="Edit Student Course" type="button" variant="primary" class="btn btn-primary edit_delete_btn" v-if="row.course_status != 'Completed' && row.cancel_id == null">
             <i class="fa fa-fw fa-pencil"></i>
        </b-btn>

        <button v-if="row.course_status != 'Completed' && row.course_status != 'Cancel' && row.cancel_id == null"
                @click="makeAdmission(row.student_enroll_id,row.student_id,row.course_id)"
                type="button"
                title="Cancel Admission"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-close" style="color:#ffff;" aria-hidden="true"></i>
        </button> 
        <button v-if="row.course_status != 'Completed' && row.course_status != 'Cancel' && row.cancel_id == null && typeof user !== 'undefined' && user.role[0].id == 1"
                @click="deleteAdmission(row.student_enroll_id,row.student_id,row.course_id)"
                type="button"
                title="Delete Admission"
                class="btn btn-danger">
            <i class="fa fa-trash" style="color:#ffff;" aria-hidden="true"></i>
        </button> 
        <button
            v-if="row.course_status != 'Completed' && row.course_status == 'Cancel' && row.cancel_id != null && $can('cancel_admission_delete')"
                @click="cancelData(row.cancel_id)"
                type="button"
                title="Revert Admission"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-undo" aria-hidden="true"></i>
        </button>

        <b-btn title="Resend Mail" type="button" disabled variant="primary" class="btn btn-default edit_delete_btn" v-if="row.course_status != 'Completed' && row.course_status != 'Cancel' && row.batch_exists == false">
             <i class="fa fa-envelope"></i>
        </b-btn>
        <b-btn @click="resendMail(row.student_id,row.student_enroll_id)" title="Resend Mail" type="button" variant="primary" class="btn btn-default edit_delete_btn" v-if="row.batch_exists == true && row.course_status == 'Pending'" :disabled="btnDisabled">
             <i class="fa fa-envelope"></i>
        </b-btn>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: ['row', 'xprops', 'student_id'],
    data() {
        return {
            namespace: this.xprops.module ,
            btnDisabled : false,
        }
    },
    created() {
        console.log(this.user);
    },
    computed: {
        ...mapGetters('AuthIndex', ['user']),
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))         
    },
    methods: {
        ...mapActions('CancelAdmissionSingle', ['cancelAdmission', 'revertData', 'markingCourseAsCompleted','deleteAdmissionApi']),
        
        
        passAddId(id){
            this.$emit('addIdModel',id) 
        },
        openRemarkPopup(id){
            this.$emit('studentRemark',id)
        },
        resendMail(student_id, student_enroll_id) {
            this.btnDisabled = true;
            axiosLms
            .post("/api/v1/resendMail", {
                    student_id: student_id,
                    student_enroll_id: student_enroll_id
            })
            .then(response => {
                if(response.status == 200){
                    this.btnDisabled = false;
                    this.$eventHub.$emit('send-mail')
                }
            });
        },
        makeComplete(student_enroll_id,student_id, course_id) {
            this.$swal({
                title: 'Are you sure you want to Complete this course?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Complete',
                confirmButtonColor: '#28a745',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) { 
                    var obj = {'student_id' : student_id, 'course_id': course_id, 'student_enroll_id' :student_enroll_id}
                    this.markingCourseAsCompleted(obj)
                    .then((res) => {
                        if(res.data.result){
                            this.$eventHub.$emit('mark-as-comp')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                        }
                    })
                    .catch(error => {
                        console.log('catch', error)
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        deleteAdmission(student_enroll_id,student_id, course_id) {
            this.$swal({
                title: 'Are you sure you want to delete this course?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                cancelButtonText:'Close',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) { 
                    var obj = {'student_id' : student_id, 'course_id': course_id, 'student_enroll_id': student_enroll_id}
                    this.deleteAdmissionApi(obj).then((res) => {
                        if(res.data.result){
                            this.$awn.success(res.data.message)
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                        }
                       
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        makeAdmission(student_enroll_id,student_id, course_id) {
            this.$swal({
                title: 'Are you sure you want to Cancel this course?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Cancel',
                confirmButtonColor: '#dd4b39',
                cancelButtonText:'Close',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) { 
                    var obj = {'student_id' : student_id, 'course_id': course_id, 'student_enroll_id': student_enroll_id}
                    this.cancelAdmission(obj)
                    .then((res) => {
                        if(res.data.result){
                            this.$eventHub.$emit('mark-as-completed')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                        }
                       
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        cancelData(cancel_id) {
            this.$swal({
                title: 'Are you sure you want to revert this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Revert',
                confirmButtonColor: '#28a745',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.revertData(cancel_id)
                    .then((res) => {
                        if(res.data.result){
                            this.$eventHub.$emit('revert-course')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                        }                       
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        
    }
}
</script>
