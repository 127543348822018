<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Employees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/employee-masters'">Employees</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Employee</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Employee</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>Branch Name</th>
                                            <td><span>{{ item.branch_name }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Employee Name</th>
                                            <td><span>{{ item.employee_name }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Employee Address</th>
                                            <td><span>{{ item.employee_address }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Area</th>
                                            <td><span>{{ item.area }}</span></td>
                                        </tr>
                                        <!-- <tr>
                                            <th>City</th>
                                            <td><span>{{ item.city }}</span></td>
                                        </tr> -->
                                        <tr>
                                            <th>City</th>
                                            <td>
                                                <span v-if="item.city !== null">
                                                    {{ item.city.city_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Country</th>
                                            <td>
                                                <span v-if="item.country !== null">
                                                    {{ item.country.country_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                            <td>
                                                <span v-if="item.state !== null">
                                                    {{ item.state.state_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Gender</th>
                                            <td>
                                                 <span v-if="item.gender == 1">
                                                    Male
                                                </span>
                                                 <span v-if="item.gender == 2">
                                                    Female
                                                </span>
                                            </td>
                                            </tr>
                                        <tr>
                                            <th>Birth Date</th>
                                            <td><span>{{ item.birth_date }}</span></td>
                                            </tr>
                                        <tr>
                                            <th>Mobile Number</th>
                                            <td><span>{{ item.mobile_no }}</span></td>
                                            </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td><span>{{ item.email_id }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Login Status</th>
                                            <td><span>{{ (item.user_status == 1)?'Active':'In Active' }}</span></td>
                                        </tr>  
                                                                                <tr>
                                            <th>Highest Education</th>
                                            <td><span>{{ item.highest_education }}</span></td>
                                            </tr>
                                        <tr>
                                            <th>Designation</th>
                                            <td>
                                                <span v-if="item.employee_type !== null">
                                                    {{ item.employee_type.name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="item.employee_type !== null && item.employee_type.value_id == 1">
                                            <th>Trainer Type</th>
                                            <td>
                                                <div v-if="item.trainer_type">
                                                    <span class="label label-success my-multipleCategory" v-for="trainer_type in item.trainer_type">    
                                                    {{ trainer_type.name }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>  
                                        <tr>
                                            <th>Course Vertical</th>
                                            <td >
                                                <div style="display:inline-flex;">
                                                <div v-for="(courseVertical, index) in courseVerticalAll" :key="index">
                                                    <div v-if="view_course_vertical.length == 1" >
                                                        <span v-if="view_course_vertical == courseVertical.id">  {{courseVertical.name}}</span>
                                                    </div>
                                                    <div v-else v-for="(view_cs , inx) in view_course_vertical" :key="inx">
                                                        <span v-if="view_cs == courseVertical.id" ><span v-if="index>0">,&nbsp;</span> {{courseVertical.name}}</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <div class="row text-align-center" style="display: contents;">
                                    <div v-if="item.profile != null">
                                        <img :src="item.profile" class="profile-user-img img-responsive my-profile my-profile-margin img-circle" :alt="item.employee_name"> 
                                        
                                    </div>
                                    <div v-else>
                                        <img src="/assets/img/male.jpg" class="profile-user-img img-responsive my-profile my-profile-margin img-circle" :alt="item.employee_name">
                                        
                                    </div>
                                </div>
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>                                                                        
                                    <tr>
                                        <th>Shift Start Time</th>
                                        <td><span>{{ item.shift_start_time }}</span></td>
                                        </tr>
                                    <tr>
                                        <th>Shift End Time</th>
                                        <td><span>{{ item.shift_end_time }}</span></td>
                                        </tr>
                                    <tr>
                                        <th>Joining Date</th>
                                        <td><span>{{ item.joining_date }}</span></td>
                                        </tr>
                                    <tr>
                                        <th>Exit Date</th>
                                        <td><span>{{ item.exit_date }}</span></td>
                                        </tr>
                                    <!-- <tr>
                                        <th>Designation</th>
                                        <td><span>{{ item.designation }}</span></td>
                                    </tr> -->
                                    <!-- <tr>
                                        <th>Username</th>
                                        <td><span>{{ item.username }}</span></td>
                                    </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
            view_course_vertical:[],
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
        .then(()=>{
                setTimeout(() => {
                    this.view_course_vertical = this.item.course_vertical.split(',');                    
                }, 2000);                
            })
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('EmployeeMastersSingle', ['item','loading', 'courseVerticalAll'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('EmployeeMastersSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>
.my-multipleCategory{
	margin: 0 6px 0 0;
}
</style>
