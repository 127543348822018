<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Evaluation Criteria</h1>
        </section>
        <section class="content">
        	<div class="row">
        		<div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>                    
            			<div class="box">
                            <!-- <div class="box-header with-border">
                                <h3 class="box-title">Evaluation Criteria</h3>
                            </div> -->
                            <br>
                            <div class="box-body">
                                <div class="row">
                                    <div class="col-md-10 col-md-offset-1">
                                        <table class="table table-bordered table-hover" v-if="performanceStatusAll.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>Performance Status</th>
                                                    <th>Percentage from(%)</th>
                                                    <th>Percentage to(%)</th>
                                                    <th>Status Color</th>
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                <tr v-for="(statusData, index) in performanceStatusAll">
                                                    <td>
                                                        <span class="myLabel" v-bind:style="{ backgroundColor: colorValue[index] }">
                                                            {{statusData.name}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="percentage_from"
                                                            id="percentage_from"
                                                            oninput="this.value=this.value
                                                                                    .replace(/[^\d.]/g, '')
                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                    .replace(/(\.[\d]{2})./g, '$1');"
                                                            placeholder="Enter Percentage from"
                                                            v-model="inputParamsFrom[index]"
                                                            @input="updatePercentageFrom"
                                                            v-validate="'required|max_value:100'"
                                                            >                                                            
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="percentage_to"
                                                            oninput="this.value=this.value
                                                                                    .replace(/[^\d.]/g, '')
                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                    .replace(/(\.[\d]{2})./g, '$1');"
                                                            placeholder="Enter Percentage to"
                                                            v-model="inputParamsTo[index]"
                                                            @input="updatePercentageTo"
                                                            v-validate="'required|max_value:100'"
                                                            >
                                                    </td>
                                                    <td class="text-center">
                                                        <input
                                                        type="color"
                                                        name="favcolor"
                                                        v-model="colorValue[index]"
                                                        @input="updateColorCode">
                                                    </td>
                                                    {{addingData()}}
                                                </tr>
                                                <tr class="error_tr not-include-in-data-grid">
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <span class="error" v-show="errors.has('percentage_from')">
                                                          {{ $root.updateErrorMessage('percentage_from',this.errors, 'Percentage From')}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="error" v-show="errors.has('percentage_to')">
                                                          {{ $root.updateErrorMessage('percentage_to',this.errors, 'Percentage To')}}
                                                        </span> 
                                                    
                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                                                                                                           
                                        </table>
                                        <br>
                                    </div>
                                </div>
                            </div>     			
            			</div>
                        <div class="row" v-if="$can('evaluation_criteria_edit')">
                            <div class="col-md-10 col-md-offset-1 col-xs-12 savebtn">
                                <vue-button-spinner
                                        class="std_information_save btn btn-block btn-success new_branch_save"
                                        :isLoading="loading"
                                        :disabled="loading"
                                        >
                                        Save
                                </vue-button-spinner>
                            </div>
                        </div>             
                    </form>
        		</div>
        	</div>            
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'


export default {
    data() {
        return {
            inputParamsFrom: [],
            inputParamsTo: [],
            colorValue: []/*'#323e44'*/,
        }
    },
    created() {
        this.fetchPerformanceStatusAll()
        this.fetchEvaluationDataAll()
         .then((res) => {
            this.dispalyData();
        })
        //setTimeout(function () { this.dispalyData(); }.bind(this), 540)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('EvaluationCriteriaIndex', ['item','loading','performanceStatusAll', 'evaluationDataAll']),
    },
    methods: {
        ...mapActions('EvaluationCriteriaIndex', ['storeData','fetchPerformanceStatusAll', 'fetchEvaluationDataAll','setPerformanceStatus','setPercentageFrom','setColorCode','resetState','setPercentageTo']),
        dispalyData() {
                var percentage_fromLocal = []
                var percentage_toLocal = []
                var color_codeLocal = []
                for (var i = 0; i < this.evaluationDataAll.length; i++) {
                    percentage_fromLocal.push(this.evaluationDataAll[i].percentage_from);
                    percentage_toLocal.push(this.evaluationDataAll[i].percentage_to);
                    color_codeLocal.push(this.evaluationDataAll[i].color_code);
                }   
                this.inputParamsFrom = percentage_fromLocal;
                this.inputParamsTo = percentage_toLocal;
                this.colorValue = color_codeLocal;
        },
        updatePerformanceStatus(value) {
            this.setPerformanceStatus(value)
        },
        updatePercentageFrom(e) {
            this.setPercentageFrom(e.target.value)
        },
        updatePercentageTo(e) {
            this.setPercentageTo(e.target.value)
        },
        updateColorCode(e) {
            this.setColorCode(e.target.value)
        },
        addingData() {
            setTimeout(function () { this.addingFinalData(); }.bind(this), 540)
        },
        addingFinalData() {
            if(this.inputParamsFrom.length > 0 && this.inputParamsTo.length > 0 && this.colorValue.length > 0) {
                var inputParamsFromLocal = this.inputParamsFrom;
                var inputParamsToLocal = this.inputParamsTo;
                var color_codeLocal = this.colorValue;
                var finalDataLocal = this.item.finalData;
                this.performanceStatusAll.forEach(function(statusData, index){
                    finalDataLocal[index] = [(statusData.value_id).toString(), inputParamsFromLocal[index], inputParamsToLocal[index], color_codeLocal[index]]
                });
            }
        },
        resetFilters(){
            var filtersField= [];
            filtersField = ['display_session_date','schedule_days','batch_name','start_time','end_time','attendance','topic_name','display_topic_status'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData()
            
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                     this.storeData()
                    .then((res) => {
                        if(res.data.error_message)
                        {
                            this.$validator.errors.add({
                                id: 'percentage_from',
                                field: 'percentage_from',
                                msg: res.data.message,
                                scope: this.$options.scope,
                            });
                            return '';
                        }                        
                        this.$router.push({ name: 'evaluation_criteria.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                else{
                    //return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
              });  
        }         
    }
}
</script>

<style scoped>
.myLabel {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
</style>
 