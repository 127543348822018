function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            lab_name: null,
            course_ids: null,
            seat_limit: null,
        }, 
        loading: false,
        loadingStudents: true,
        branchMastersAll: [],
        fullCoursesAll: [],
        studentAddedInSelectedBranchLab: [],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loadingStudents: state => state.loadingStudents,
    branchMastersAll: state => state.branchMastersAll,
    fullCoursesAll: state => state.fullCoursesAll,
    studentAddedInSelectedBranchLab: state => state.studentAddedInSelectedBranchLab,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.branch_id)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch_id.id)
            }
            if (_.isEmpty(state.item.course_ids)) {
                params.delete('course_ids')
            } else {
                for (let index in state.item.course_ids) {
                    params.set('course_ids['+index+']', state.item.course_ids[index].id)
                }
            }            
            params.set('lab_name', state.item.lab_name)
            params.set('seat_limit', state.item.seat_limit)
            axios.post('/api/v1/branch-lab', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.item.branch_id)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch_id.id)
            }
            if (_.isEmpty(state.item.course_ids)) {
                params.delete('course_ids')
            } else {
                for (let index in state.item.course_ids) {
                    params.set('course_ids['+index+']', state.item.course_ids[index].id)
                }
            }            
            params.set('lab_name', state.item.lab_name)
            params.set('seat_limit', state.item.seat_limit)            
            axios.post('/api/v1/branch-lab/'+ state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axios.get('/api/v1/branch-lab/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    })            
        dispatch('fetchBranchMasterAll')
        dispatch('fetchCoursesAll')
    },
    async fetchStudentAddedInSelectedBranchLab({ commit }, obj) {
        await axios.post('/api/v1/studentAddedInBranchLab', obj)
            .then(response => {
                commit('setStudentAddedInSelectedBranchLab', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    fetchBranchMasterAll({ commit }) {
        // axios.get('/api/v1/branch')
        axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchCoursesAll({ commit }) {
        axios.get('/api/v1/labcourses')
            .then(response => {                
                commit('setFullCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    setLoadingStudents({ commit }, value) {
        commit('setLoadingStudents', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },    
    setLabName({ commit }, value) {
        commit('setLabName', value)
    },
    setFullCourse({ commit }, value) {
        commit('setFullCourse', value)
    },
    setSeatLimit({ commit }, value){
        commit('setSeatLimit', value)
    },
    setStudentAddedInSelectedBranchLab({ commit }, value){
        commit('setStudentAddedInSelectedBranchLab', value)
    },     
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },    
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setLabName(state, value) {
        state.item.lab_name = value
    },
    setFullCourse(state, value) {
        state.item.course_ids = value
    },
    setSeatLimit(state, value) {
        state.item.seat_limit = value
    },
    setStudentAddedInSelectedBranchLab(state, value) {
        state.studentAddedInSelectedBranchLab = value
    },    
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setFullCoursesAll(state, value) {
        state.fullCoursesAll = value
    },          
    setLoading(state, loading) {
        state.loading = loading
    }, 
    setLoadingStudents(state, loadingStudents) {
        state.loadingStudents = loadingStudents
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
