<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>SMS On Pending Fee Reminder</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="sendFeeReminderPendingSms">
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Fee Reminder Pending List</h3>
                            </div>
                            <div class="box-body text-center" v-if="loading">
                                <h4><i class="fa fa-spin fa-refresh"></i> 
                                    Loading...</h4> 
                            </div>         
                            <div class="box-body text-center" v-else-if="!loading && all.length == 0">
                                <h3>&#9785;</h3>
                                <h4>Looks like there are no data!</h4>
                            </div>                            
                            <div v-show="!(!loading && all.length == 0)" class="box-body">
                                <div class="col-md-10 col-md-offset-1">
                                    <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="">
                                                <th>#</th>
                                                <th>Student</th>
                                                <th>Total Fees</th>
                                                <th>Total Paid</th>
                                                <th>Total Due</th>
                                                <th>Last SMS sent</th>
                                                <th class="text-center" style="z-index: 1;">
                                                    Select All
                                                    <label class="containck">   
                                                        <input 
                                                            type="checkbox"
                                                            id="firstBox"
                                                            @change="checkAllCheckBoxes($event)"
                                                            >
                                                        <span style="left: 30px !important;" class="checkmarkck"></span>
                                                    </label>                                                   
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-center" v-for="(value, index) in all">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{value.student_name}}</td>
                                                <td class="text-left">{{value.TotalFees}}</td>
                                                <td class="text-left">{{value.TotalPaid}}</td>
                                                <td class="text-left">{{value.TotalBalance}}</td>
                                                <td class="text-left">{{value.last_sms_sent}}</td>
                                                <td>
                                                    <label class="containck">   
                                                        <input 
                                                            type="checkbox"
                                                            name="checkAll"
                                                            class="checkAll" 
                                                            v-model="smsToBeSent[index]"
                                                            @change="checkBoxChanged($event)"
                                                            >
                                                        <span style="left: 30px !important;" class="checkmarkck"></span>
                                                    </label>                                      
                                                </td>
                                            </tr>  
                                        </tbody>                            
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!(!loading && all.length == 0)" class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Send SMS
                            </vue-button-spinner>
                        </div>                    
                    </div>
                </form>
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            smsToBeSent: []
        }
    },
    created() {
        this.fetchData()
            .then(response => {
                document.getElementById("firstBox").checked = true
                this.checkAllCheckBoxes({'target': {'checked': true}})
            })
    },
    destroyed() {

    },
    computed: {
        ...mapGetters('SmsOnFeeReminderIfPendingIndex', ['all','loading']),
    },
    methods: {
        ...mapActions('SmsOnFeeReminderIfPendingIndex', ['fetchData','sendFeeReminderPendingSmsStore','setAll']),
        checkBoxChanged(e) {
            if($('.checkAll:checked').length == $('.checkAll').length) {
                document.getElementById("firstBox").checked = true
            }
            if(e.target.checked == false) {
                document.getElementById("firstBox").checked = false   
            }
        },
        sendFeeReminderPendingSms() {
            if($('.checkAll:checked').length == 0) {
                this.$eventHub.$emit('select-at-least-one')
                return
            }            
            var params = []
            for (var i = 0; i < this.all.length; i++) {
                if(this.smsToBeSent[i] == true) {
                    params.push({'student_name': this.all[i] && this.all[i].student_name ? this.all[i].student_name.split('-')[0] : null,
                                 'student_id': this.all[i].student_id,
                                 'due_fees': this.all[i].TotalBalance,
                                 'mother_name': this.all[i].mother_name,
                                 'other_mobile_no': this.all[i].other_mobile_no})
                }
            }
            this.sendFeeReminderPendingSmsStore(params)
                .then(()=>{
                    this.$eventHub.$emit('sms-sent')
                })
                .catch(()=>{
                    this.$eventHub.$emit('sms-not-sent')
                })
                .finally(()=>{
                    this.setAll([])
                    this.fetchData()
                        .then(response => {
                            document.getElementById("firstBox").checked = true
                            this.checkAllCheckBoxes({'target': {'checked': true}})
                        })                    
                })
        },
        checkAllCheckBoxes(e) {
           this.smsToBeSent = []
           var arrMarkMail = document.getElementsByName("checkAll");
           if(e.target.checked) {
               for (var i = 0; i < arrMarkMail.length; i++) {
                  arrMarkMail[i].checked = true;
               }
                for (var i = 0; i < this.all.length; i++) {
                    this.smsToBeSent[i] = true;
                }
           } else {
               for (var i = 0; i < arrMarkMail.length; i++) {
                  arrMarkMail[i].checked = false;
               }            
                for (var i = 0; i < this.all.length; i++) {
                    this.smsToBeSent[i] = false;
                }
           }
        }
    }
}
</script>

<style scoped>
.tableFixHead          { overflow-y: auto; height: 422px; }
.tableFixHead thead th { position: sticky; top: 0; }

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
</style>
 