function initialState() {
    return {
        item: {
            id: null,
            name: null,
            course: null,
            student: null,
            prefer_days: "1,2,3,4,5,6,7",
            batch: null,
            lab_id: null,
            trainer: 0,
            start_date: null,
            button_start_date: null,
            // clickedDate: null,
        },
        active_from_hold:0,
        currentBranchIdForProps: null,
        studentAll: [],
        countAll: [],
        labsAll: [],
        courseAll: [],
        batchAll: [],
        trainerAll: [],
        loading: false,
        loadingCalendar: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loadingCalendar: state => state.loadingCalendar,
    currentBranchIdForProps: state => state.currentBranchIdForProps,
    studentAll: state => state.studentAll,
    countAll: state => state.countAll,
    courseAll: state => state.courseAll,
    batchAll: state => state.batchAll,
    trainerAll: state => state.trainerAll,
    labsAll: state => state.labsAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
            }
            // if (_.isEmpty(state.item.trainer)) {
            //     params.set('employee_id', '')
            // } else {
            //     params.set('employee_id', state.item.trainer)
            // }
            params.set('employee_id', 0)
            if (_.isEmpty(state.item.student)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student.id)
            }
            if (_.isEmpty(state.item.lab_id)) {
                params.set('lab_id', '')
            } else {                
                params.set('lab_id', state.item.lab_id.id)
            }            
            if (_.isEmpty(state.item.batch)) {
                params.set('prefer_batch_id', '')
            } else {
                params.set('prefer_batch_id', state.item.batch.id)
            }
                params.set('prefer_days', state.item.prefer_days.toString())            
           
            axios.post('/api/v1/student-batch', params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
//            if (_.isEmpty(state.item.course)) {
//                params.set('course_id', '')
//            } else {
                params.set('course_id', state.item.course_id)
//            }
            // if (_.isEmpty(state.item.trainer)) {
            //     params.set('employee_id', '')
            // } else {
            //     params.set('employee_id', state.item.trainer)
            // }
            params.set('employee_id', 0)
            
            params.set('active_from_hold', state.active_from_hold)
            
            if (_.isEmpty(state.item.student)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student_id)
            }
            if (_.isEmpty(state.item.lab_id)) {
                params.set('lab_id', '')
            } else {                
                params.set('lab_id', state.item.lab_id.id)
            }
            if (_.isEmpty(state.item.batch)) {
                params.set('prefer_batch_id', '')
            } else {                
                params.set('prefer_batch_id', state.item.batch.id)
            }
            if (_.isEmpty(state.item.prefer_days)) {
                params.set('prefer_days', '')
            } else {
                params.set('prefer_days', state.item.prefer_days.toString())
            }

            axios.post('/api/v1/student-batch/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
getCountForCalendar({ commit, state, dispatch }) {
        commit('setLoadingCalendar', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            /*----------------------------------------------------------
            //PLEASE DO NOT REMOVE THIS IF ELSE LOOP!
            //AS IT IS USED TO PASSING DIFFERENT DATA TO PARAM ON DIFFERENT CONDITION
            ----------------------------------------------------------*/

            if (_.isEmpty(state.item.button_start_date)) {
                params.set('start_date', state.item.start_date)
            } else {
                params.set('start_date', state.item.button_start_date)
            }
            if (_.isEmpty(state.item.lab_id)) {
                params.set('lab_id', '')
            } else {                
                params.set('lab_id', state.item.lab_id.id)
            }            
            params.set('prefer_batch_id', state.item.batch.id)

            axios.post('/api/v1/theCalendarCount', params)
                .then(response => {
                    commit('setCountAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoadingCalendar', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/student-batch/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
        dispatch('fetchStudentAll');
        dispatch('fetchStudentCourseAll');
        dispatch('fetchTrainerAll');
        // dispatch('fetchLabsAll');
    },
   async fetchStudentAll({ commit, dispatch }) {
        await axios.get('/api/v1/students_list_excluding_cancelled')
            .then(response => {
                commit('setStudentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
   async fetchCurrentBranchId({ commit, dispatch }) {
        await axios.get('/api/v1/currentBranchIdFromSession/')
            .then(response => {
                commit('setCurrentBranch', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },    
    async fetchStudentCourseAll({ commit, dispatch },id) {
        await axios.get('/api/v1/students_course_list/'+id)
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    fetchLabsAll({ commit, dispatch }, course_id) {
        axios.get('/api/v1/labs_list/' +course_id)
            .then(response => {
                commit('setLabsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },    
    fetchCourseBatchAll({ commit, dispatch },obj) {
        axios.get('/api/v1/course_batch_list/'+ obj.courseType + '/'+obj.days + '/' + obj.startDate)
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    fetchTrainerAll({ commit, dispatch }){
        axios.get('/api/v1/trainerList/')
            .then(response => {
                commit('setTrainerAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    fetchMinDate({ commit, dispatch },params){
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/minDateForStudentBatch',params)
                .then(response => {
                    resolve(response.data.data[0])
                })
                .catch(function (error) {
                      console.log('Error', error.message);
                      reject(error)
                })     
        })     
    },    
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setStartDate({ commit }, value) {
        commit('setStartDate', value)
    },
    setCurrentBranch({ commit }, value) {
        commit('setCurrentBranch', value)
    },    
    setButtonStartDate({ commit }, value) {
        commit('setButtonStartDate', value)
    },    
    setPreferDays({ commit }, value) {
        commit('setPreferDays', value)
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setBatch({ commit }, value) {
        commit('setBatch', value)
    },
    setLab({ commit }, value) {
        commit('setLab', value)
    },    
    setTrainer({ commit }, value) {
        commit('setTrainer', value)
    },
    setBatchAll({ commit }, value) {
        commit('setBatchAll', value)
    },
    setActiveFromHold({ commit }, value) {
        commit('setActiveFromHold', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setStudent(state, value) {
        state.item.student = value
    },
    setPreferDays(state, value) {
        state.item.prefer_days = value
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setStartDate(state, value) {
        state.item.start_date = value
    },
    setCurrentBranch(state, value) {
        state.currentBranchIdForProps = value
    },
    setButtonStartDate(state, value) {
        state.item.button_start_date = value
    },    
    setBatch(state, value) {
        state.item.batch = value
    },
    setLab(state, value) {
        state.item.lab_id = value
    },
    setTrainer(state, value) {
        state.item.trainer = value
    },
    setStudentAll(state, value) {
        state.studentAll = value
    },
    setLabsAll(state, value) {
        state.labsAll = value
    },
    setCountAll(state, value) {
        state.countAll = value
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setTrainerAll(state, value) {
        state.trainerAll = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setActiveFromHold(state, value) {
        state.active_from_hold = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingCalendar(state, loadingCalendar) {
        state.loadingCalendar = loadingCalendar
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
