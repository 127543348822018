<template>
    <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Trainer Dashboard</h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- <div>Comming soon...</div> -->
        <div class="row">
          <div class="col-sm-6">
            <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <h3 class="card-title">Last 10 Attendance List</h3>
                  <!-- <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync-alt" :class="{'rotate': refershLoad}"></i> Refresh
                  </button> -->
                  <div class="card-tools">
                    <button type="button" class="btn btn-default btn-sm" title="Refresh" @click="loadaItems(1)">
                     <i class="fa fa-sync-alt" :class="{'rotate': refershaLoad}"></i>
                    </button>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                <div class="btn-group inquiry-btn">
                    
                </div>
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Batch Name</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody v-if="getStudentAttandencesList.length > 0">
                      <tr v-for="(attandance_data, index) in getStudentAttandencesList" :key="index">
                        <td>{{ attandance_data.student_name}}</td>
                        <td>{{ attandance_data.batch_name}}</td>
                        <td colspan="2">
                          <a href="javascript:void(0)" class="btn btn-success mr-2 mb-2" title="Present" @click="setPresentaaa(attandance_data.attandance_id)"><i class="fa fa-fw fa-check"></i></a>
                          <a href="javascript:void(0)" class="btn btn-warning mb-2" title="Absent" @click="setAbsentaaaa(attandance_data.attandance_id)"><i class="fa fa-fw fa-times"></i></a>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                          <tr>
                            <td colspan="3">No Records Found</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.table-responsive -->
                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer clearfix">
                  <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                </div> -->
                <!-- /.card-footer -->
              </div>
            <!-- /.card -->
          </div>

          <div class="col-sm-6">
            <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <h3 class="card-title">Last 10 Assessment List</h3>

                  <div class="card-tools">
                    <button type="button" class="btn btn-default btn-sm" title="Refresh" @click="loadAsItems(1)">
                     <i class="fa fa-sync-alt" :class="{'rotate': refershAsLoad}"></i>
                    </button>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Batch Name</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody v-if="getStudentAssessmentList.length > 0">
                      <tr v-for="(assessment_data, index) in getStudentAssessmentList" :key="index">
                        <td>{{ assessment_data.student_name}}</td>
                        <td>{{ assessment_data.batch_name}}</td>
                        <td colspan="2">
                          <a  v-b-modal.addAssessmentModel href="javascript:void(0)" title="Make Assessment" class="btn btn-info" @click="setAssessmentId(assessment_data.attandance_id,assessment_data.assessment_id)"><i class="fa fa-fw fa-book"></i></a>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                          <tr>
                            <td colspan="2">No Records Found</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- /.table-responsive -->
                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer clearfix">
                  <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                </div> -->
                <!-- /.card-footer -->
              </div>
            <!-- /.card -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <h3 class="card-title">Schedule Batches</h3>

                  <div class="card-tools">
                     <button type="button" class="btn btn-default btn-sm" title="Refresh" @click="loadbItems(1)">
                     <i class="fa fa-sync-alt" :class="{'rotate': refershbLoad}"></i>
                    </button>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                  <TrainerBatchSchedule :refreshCount="refreshCount"/>
                  <!-- /.table-responsive -->
                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer clearfix">
                  <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                </div> -->
                <!-- /.card-footer -->
              </div>
            <!-- /.card -->
          </div>
        </div>  
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
    <StudentAssessmentPopup :id="attandance_id" v-if="assessment_id != 0" :assessmentTopicId="assessment_id" @loadItems="loadItems"></StudentAssessmentPopup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StudentAssessmentPopup from '../StudentAttendance/StudentAssessmentPopup'
import TrainerBatchSchedule from './TrainerBatchSchedule.vue'
export default {
  components: {
      StudentAssessmentPopup,
      TrainerBatchSchedule
  },
  data () {
    return {       
        loading: false,
        attandance_id :0,
        assessment_id :0,
        refershaLoad:false,
        refershAsLoad:false,
        refershbLoad:false,
        refreshCount:0,
        // refershLoad:false
    }
  },   
  created() {
    this.getData();
    this.getStudentAttandences(this.user.id);
    this.getStudentAssessment(this.user.id);
    // this.getTutorCurrentBatchData(this.user.id);
  },
  computed: {
    ...mapGetters('AuthIndex', ['user']),
    ...mapGetters("TrainerDashboardSingle", ["getStudentAttandencesList","getStudentAssessmentList","getTodaysBatchData"]),
  } ,
  methods: {
    ...mapActions('TrainerDashboardSingle',['getStudentAttandences','getStudentAssessment','getTutorCurrentBatchData','setPresent','setAbsent']),
    getData() {
        /* Here */   
    },
    setPresentaaa(id) {
        this.$swal({
            title: 'Are you sure you want to set Present?',
            text: 'If you click on save then attendance mark as present.',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'Save',
            confirmButtonColor: '#28a745',
            focusCancel: true,
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                this.setPresent(id)
                .then(result => {
                    if(result.data.result){
                        this.$eventHub.$emit('update-success')
                        this.getStudentAttandences(this.user.id);
                    }
                    else{
                        this.$eventHub.$emit('common',2,result.data.message)
                    }
                    
                })
            }
        })
    },
    // loadItems(flag = 0){
    //   if(flag== 1){
    //     this.refershLoad = true;
    //   }
    //   this.getTutorCurrentBatchData(this.user.id);
    //   this.refreshLoad = false;
    //   },
    async loadaItems(flag = 0){
      if(flag== 1){
        this.refershaLoad = true;
      }
      await this.getStudentAttandences(this.user.id);
        this.refershaLoad = false;
      },
    async loadAsItems(flag = 0){
      if(flag== 1){
        this.refershAsLoad = true;
      }
      await this.getStudentAssessment(this.user.id);
      this.refershAsLoad = false;
      },
    async loadbItems(flag = 0){
      if(flag== 1){
        this.refershbLoad = true;
      }
        this.refreshCount = this.refreshCount + 1;
        this.refershbLoad = false;
      },
    
       
    setAbsentaaaa(id) {
      this.$swal({
          title: 'Are you sure you want to set Absent?',
          text: 'If you click on save then attendance mark as absent.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          confirmButtonColor: '#ffc107',
          focusCancel: true,
          reverseButtons: true
      }).then(result => {
          if (result.value) {
              this.setAbsent(id)
              
              .then(result => {
                  if(result.data.result){
                      this.$eventHub.$emit('update-success')
                      this.getStudentAttandences(this.user.id);
                      if(flag== 1){
                        this.refershLoad = false;
                      }                      
                  }
                  else{
                      this.$eventHub.$emit('common',2,result.data.message)
                      if(flag== 1){
                        this.refershLoad = false;
                      } 
                  }
              })
          }
      })
  },
  setAssessmentId(attandance_id,assessment_id){
    
      console.log(attandance_id, assessment_id);
      this.attandance_id = attandance_id;
      this.assessment_id = assessment_id;
      if(flag== 1){
        this.refershLoad = false;
      }   
  },
  
  },
  
}
</script>