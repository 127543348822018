<template>
  <div class="popup-form add-country">
    <b-btn v-b-modal.modalPrevent2 class="add-button">+</b-btn>
    <b-modal id="modalPrevent2"
             ref="modal"
             title="Add State"
             @cancel="handleCancel"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"             
             @shown="clear">
      <form @submit.stop.prevent="handleSubmit">
      <div class="container-fluid">  
        <div class="row">
          <div class="col-md-12 col-xs-12">
             <div class="form-group">     
                <label for="country" ref="country">Country<p class="astrisk">*</p></label>
                    <v-select
                            name="country"
                            label="country_name"
                            v-model="countryName"
                            disabled
                            :options="countryAll"
                            />
                    <span id="err_country_name" class="error" v-show="errors.has('country')">
                      {{ $root.updateErrorMessage('country',this.errors)}}
                    </span>                   
			    </div>
		   </div>
     </div>
     <div class="row">
          <div class="col-md-12 col-xs-12">
             <div class="form-group"> 
                <label for="state" ref="state">State<p class="astrisk">*</p></label>       	
    		        <b-form-input 
                              type="text"
                              name="state"
                              maxlength="90"
                              v-validate="'required'"                              
                              v-model="stateName"
    		                      placeholder="Enter State">
    		        </b-form-input>
                <span class="error" v-show="errors.has('state')">
                  {{ $root.updateErrorMessage('state',this.errors)}}
                </span>                
			       </div>
  		   </div>		   			
  		</div>
	  </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      countryAll: [],
      stateName: '',
      countryName: '',
    }
  },
  computed: {
        ...mapGetters('FranchisesSingle', ['item', 'loading']),
       
    },
   created() {
       this.fetchCountryAll()
   }, 
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },   
  methods: {
     ...mapActions('FranchisesSingle', ['setState','fetchStateAll', 'fetchCountryStates']),
    clear () {
      // console.log("++++++++++Inside clear function++++++++++++");
      this.stateName = '';
      this.countryName = this.item.country_list;
      this.$validator.errors.remove('state');
    },
    fetchCountryAll() {
        axios.get('/api/v1/country')
            .then(response => {                
                this.countryAll = response.data.data;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })        
    },
    handleCancel(evt){
      this.stateName = '';
      this.$validator.errors.remove('state');
    },    
    handleOk (evt) {
        evt.preventDefault();
        this.$validator.validate().then(result => {
               if (result) {
                  this.handleSubmit()
                }
                else{
                  return this.$root.handleValidationFocus(this.errors,this.$refs);
                }               
            });
    },
    handleSubmit () {
      let params = new FormData();
      params.set('country_id', this.countryName.id);
      params.set('state_name', this.stateName);
      axios.post('/api/v1/states', params)
      .then(response => {
        if(response.data.error_message)
        {
            this.$validator.errors.add({
                id: 'state',
                field: 'state',
                msg: response.data.message,
                scope: this.$options.scope,
            });
            return '';
        }
        else{
            this.clear()
            this.setState(response.data.data);
            this.fetchCountryStates(this.countryName.id);
            this.$refs.modal.hide()
        }

        
       })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    }
  }
}
</script>