<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Inventory Stock Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Inventory Stock Report</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>
                               <div class="col-md-6 col-xs-12">
                                     <div ref="payment_mode" class="form-group">
                                        <label for="item_category_id">Category</label>
                                        <v-select
                                            id="item_category_id"
                                            name="item_category_id"
                                            label="name"
                                            @input="updateCategory"
                                            :value="item.item_category_id"
                                            :options="categoriesAll"
                                            />
                                    </div>
                                </div> 
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="item_id">Item</label>
                                    <v-select
                                      name="item_id"
                                      label="name"                                           
                                      :value="item.item_id"
                                      :options="itemsAll"        
                                      @input="updateItem"
                                      />  
                                    
                                </div>       
                                <div class="clearfix"></div>
                                                                                   
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner 
                            class="std_information_save btn btn-block btn-success new_branch_save"
                            :isLoading="loading"
                            :disabled="loading">                            
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
            <div class="row"  v-if="dataSeen && item.report_type == 2">
                <div class="col-xs-12">  
                    <div v-if="loading" class="panel panel-default">
                      <div class="panel-body">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading Summary...
                                </div>
                            </div>
                      </div>
                    </div>  
                                                                                    
                                               
                </div>
            </div>      
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Detail</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                     	
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                    <datatable
                                            v-if="!loading"
                                            :columns="columns"
                                            :data="data"
                                            :total="total"
                                            :query="query"
                                            :xprops="xprops"
                                            />  
                                </div>                                 
                        </div>
                    </div>
                </div>
            </div>                               
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InventoryStockReportDBActions from '../../dtmodules/DatatableActions/InventoryStockReportDBActions'
import components from '../../comps/'

InventoryStockReportDBActions.attachStore('IncomeIndex');

export default {
    components,
    InventoryStockReportDBActions,
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},        	
            exitDateOptionFormateFirst:{format:window.date_format_moment},  
            dataSeen: false,
            columns: [
                { title: 'Branch', field:'branch_name',  sortable: true, thComp: 'FilterTh', tdClass: 'Branch' },
                { title: 'Category', field: 'category_name', sortable: true, thComp: 'FilterTh', tdClass: 'Category' },
                { title: 'Item', field: 'item_name', sortable: true, thComp: 'FilterTh', tdClass: 'Item' },
                { title: 'Stock Balance', field: 'balance', sortable: true, thComp: 'FilterTh', tdClass: 'Stock Baance' },
//                { title: 'Stock Out', field: 'sold', sortable: true, thComp: 'FilterTh', tdClass: 'Stock Out' },
//                { title: 'Remarks', field: 'remarks', sortable: true, thComp: 'FilterTh', tdClass: 'remarks' },
//                { title: 'Total Amount', field: 'amount', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name', tdStyle: 'text-align: right;', thStyle: 'text-align: center;' },
//                { title: 'Actions', tdComp: InventoryStockReportDBActions, visible: true, thClass: 'text-right', tdClass: '', tdStyle:'text-align: right'}
            ],
                       
            querySummary: { },
            query: {  },
            xprops: {
                module: 'InventoryStockReportIndex',
                route: 'inventory_stock_report',
                permission_prefix: 'inventory_stock_report_'
            }            
        }
    },
    created() {
        this.fetchBranchMasterAll().then(()=>{});
        this.fetchCategoriesAll().then(()=>{});
    },   
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){},
        querySummary: {
            handler(query) {
                this.setQuerySummary(query)
            },
            deep: true
        },
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('InventoryStockReportIndex', ['item','data', 'total','totalSummary', 'loading','branchMastersAll','reportsDataAll','categoriesAll','itemsAll','summaryDataAll']),
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                this.branchMastersAll.unshift({
                    'branch_name': 'All',
                    'id': '0'
                });
            }
            return this.branchMastersAll;
        },
        summaryDataComputed() {
          var localvar = [];
          this.reportsDataAll.forEach(function(value,index){
                var index = -1;
                var filteredObj = localvar.find(function(item, i){
                  if(item.instrument_date == value.instrument_date){
                    index = i;
                    return i;
                  }
                });
                if(index == -1) {
                  localvar.push({
                    'instrument_date': value.instrument_date,
                    'amount': value.amount
                  });
                } else {
                   localvar[index].amount = parseFloat(localvar[index].amount) + parseFloat(value.amount)  
                }
          }.bind(this))     
          return localvar;
        },   
        totalAmountComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.amount);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);             
        }, 
    },
    methods: {
        ...mapActions('InventoryStockReportIndex', ['resetState','fetchBranchMasterAll','getReportsData','setBranch','setItemCategoryId','setQuery','setQuerySummary',
        	'setItemId','setFromDate','setExit_date','setItemsAll','setReportType','fetchCategoriesAll','fetchItemsAll','setSummaryDataAll','fetchReportData']),
        updateBranch(value) {
            this.setBranch(value)
            this.dataSeen = false;
        },
        updateCategory(e) {
            this.dataSeen = false;
            this.setItemCategoryId(e)            
            this.setItemId(null)
            this.setItemsAll([])
            if(e){
              this.fetchItemsAll(e.value_id);
            }
        },  
        updateItem(e) {
            this.dataSeen = false;
            this.setItemId(e)
        },  
        updateFromDate(e) {
            if(e && e.target.value != ''){            
                this.exitDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };
                this.setExit_date(null)
            }
            this.setFromDate(e.target.value)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            this.setExit_date(e.target.value)
            this.dataSeen = false;
        },             
        updateReportType(value) {
            this.setReportType(value)
            this.dataSeen = false;
        },         
        resetFilters(){
            var filtersField= [];
            filtersField = ['branch_name','category_name','item_name','balance'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
        },        
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.fetchReportData()
                    .then(() => {
                        this.setSummaryDataAll(this.summaryDataComputed)
                        this.resetFilters()
                        this.querySummary = {}
                        this.query = {}                        
                    })                     
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
.mybox {
    border: 1px solid #eee;    
}
</style>
 