<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Modules</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/modules'">Modules</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Module</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Module</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                <div class="col-md-12">
                                    <bootstrap-alert />
                                </div>

                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="module_name">Module Name<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="module_name"
                                            ref="module_name"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter Module Name"
                                            @input="updateModuleName"
                                            :value="moduleName"
                                            >
                                    <span class="error" v-show="errors.has('module_name')">
                                        {{ $root.updateErrorMessage('module_name',this.errors,'Module Name')}}
                                    </span><br>
                                </div>  

                                <div class="form-group col-md-12 col-xs-12 mb-0">
                                    <label for="module_name">Project Selection<p class="astrisk">*</p></label>
                                </div>
                                 <div class="form-group col-md-12 col-xs-12 slectBoxIcon">
                                    <div class="subject-info-box-1">
                                    <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                    </div>
                                    <div class="subject-info-arrows text-center">
                                    <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                    <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                    <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                    <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                    <span class="top-margin" style="color: red;" id="movingError"></span>
                                    </div>
                                    <div class="subject-info-box-2">
                                    <select multiple="multiple" id='lstBox2' class="form-control"></select>
                                    </div>
                                </div>  
                           
                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="module_hours">Module Hours<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="module_hours"
                                            ref="module_hours"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter Module Name"
                                            @input="updateModuleHours"
                                            :value="moduleHours"
                                            disabled="true"
                                            >
                                    <span class="error" v-show="errors.has('module_hours')">
                                        {{ $root.updateErrorMessage('module_hours',this.errors,'Module Hours')}}
                                    </span><br>
                                </div>  

                                                     
                                <div class="col-md-12 col-md-12 savebtn">
                                    <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            type="submit"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                    </vue-button-spinner>
                                </div> 
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            gender: 1,
            moduleName: null,
            moduleHours:0,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            localProjectAll:[]        
        }
    },
    created() {
        var localListBox = $('#lstBox1'),
        optionsToBeAppend = [],
        optionsToBeAppendLeftBox = [];
        this.fetchProjectAll();
        this.fetchEditData(this.$route.params.id);
    },
    destroyed() {
        // this.resetForm()
        // this.resetState()
    },
    computed: {
        ...mapGetters('AuthIndex', ['user']),
        ...mapGetters('ModulesIndex', ['editDataAll','loading','projectAll']),   
    },
    watch: {
        '$route': function(){
            this.sameRouterPushFunction();
        },
        editDataAll(value){
           //console.log("edit",value);
            let projects = value.module_project;
            //console.log("len",projects.length)
            this.localProjectAll = [];
            if(projects){
                for (var i = 0; i < projects.length; i++) {
                // console.log(projects[i]);
                    this.localProjectAll.push(projects[i].id)
                }
            }
            
            // this.localProjectAll = projects;
            // console.log("dfd",this.localProjectAll)
        },
        projectAll(value){
            //console.log("project all",value);
            let optionsToBeAppendLeftBox = [],optionsToBeAppend = [];
            //console.log("prject",value);
            let localProjectAll = this.localProjectAll;
            //console.log("loca",localProjectAll);
            this.setBox([]);
            this.setOptions([]);
            //let projectAll = this.projectAll;
           //console.log("proejct",this.projectAll);
            for (var i = 0; i < this.projectAll.length; i++) {
               // console.log("dsfsdf",this.localProjectAll.indexOf(value[i].id))
                if(localProjectAll.indexOf(this.projectAll[i].id) == -1) {
                    optionsToBeAppendLeftBox.push({'class': 'project', 'value': this.projectAll[i].id, 'text': this.projectAll[i].project_name,'hours':this.projectAll[i].project_hours})                                
                } else if(localProjectAll.indexOf(this.projectAll[i].id) != -1) {
                    optionsToBeAppend.push({'class': 'project', 'value': this.projectAll[i].id, 'text': this.projectAll[i].project_name, 'hours':this.projectAll[i].project_hours})
                }
            }
            this.setBox(optionsToBeAppendLeftBox)
            this.setOptions(optionsToBeAppend)
        },
        localProjectAll(value){
            //console.log(value);
             let optionsToBeAppendLeftBox = [],optionsToBeAppend = [];
            //console.log("prject",value);
            this.setBox([]);
            this.setOptions([]);
            //let projectAll = this.projectAll;
           //console.log("proejct",this.projectAll);
            for (var i = 0; i < this.projectAll.length; i++) {
               // console.log("dsfsdf",this.localProjectAll.indexOf(value[i].id))
                if(value.indexOf(this.projectAll[i].id) == -1) {
                    optionsToBeAppendLeftBox.push({'class': 'project', 'value': this.projectAll[i].id, 'text': this.projectAll[i].project_name,'hours':this.projectAll[i].project_hours})                                
                } else if(value.indexOf(this.projectAll[i].id) != -1) {
                    optionsToBeAppend.push({'class': 'project', 'value': this.projectAll[i].id, 'text': this.projectAll[i].project_name, 'hours':this.projectAll[i].project_hours})
                }
            }
            this.setBox(optionsToBeAppendLeftBox)
            this.setOptions(optionsToBeAppend)
        },
    },
    methods: {
        ...mapActions('ModulesIndex', ['resetState','setLoading','fetchEditData','fetchProjectAll','editModule']),
        setBox(optionsToBeAppendLeftBox) {
            //console.log("box",optionsToBeAppendLeftBox);
            if(optionsToBeAppendLeftBox && optionsToBeAppendLeftBox.length > 0) {
                //setTimeout(function(){
                    for (var i = 0; i < optionsToBeAppendLeftBox.length; i++) {
                        $('#lstBox1')
                            .append($("<option data-hours='"+optionsToBeAppendLeftBox[i].hours+"'></option>")
                                .attr("class",optionsToBeAppendLeftBox[i].class)
                                .attr("value",optionsToBeAppendLeftBox[i].value)
                                .text(optionsToBeAppendLeftBox[i].text));          
                    }
               // },200)
            }
            else{
                $('#lstBox1').html('')
            }
        },
        setOptions(optionsToBeAppend) {
            this.setLoading(false)
            this.moduleName = this.editDataAll.module_name;
            let modules = this
            if(optionsToBeAppend && optionsToBeAppend.length > 0) {
            //var localGroupName = this.editDataAll[0].group_name;
          //  setTimeout(function() {
                //$('#group_name').val(localGroupName)
                for (var i = 0; i < optionsToBeAppend.length; i++) {
                        modules.moduleHours = modules.moduleHours + Number(optionsToBeAppend[i].hours);
                        $('#lstBox2')
                            .append($("<option data-hours='"+optionsToBeAppend[i].hours+"'></option>")
                                .attr("class",optionsToBeAppend[i].class)
                                .attr("value",optionsToBeAppend[i].value)
                                .text(optionsToBeAppend[i].text));
                }
            //},200)  
            }
            else{
                modules.moduleHours = 0;
                $("#lstBox2").html('');
            } 
        },
        updateModuleName(e) {
            this.moduleName = e.target.value
        },
        updateModuleHours(e){
            this.moduleHours = e.target.value
        },
        moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;             
            }
          
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + $(selectedOpts[i]).data('hours');
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + $(selectedOpts[i]).data('hours');
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            
            var selectedOpts = $('#lstBox2 option.project:selected');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move') 
                return false;              
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - $(selectedOpts[i]).data('hours');
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
           
            var selectedOpts = $('#lstBox2 option.project');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - $(selectedOpts[i]).data('hours');  
            }
            $(selectedAllOpts).remove();
        },
        submitForm() {
            var allProjects = [];
            $('#lstBox2 option.project').each(function() {
                allProjects.push({'name': $(this).text(), 'id': $(this).val()})
            });
           
            var params = {
                'id': this.$route.params.id,
                'module_name': this.moduleName,
                'allprojectNames': JSON.stringify(allProjects),
                'total_hours': this.moduleHours,
                'total_fee':0,
                'branch_id':this.user.api_session_branch_id
            }

            this.$validator.validate().then(result => {
                
                if(result) {
                    if($('#lstBox2 option').length == 0) {
                        this.$eventHub.$emit('common',3,'Please select atleast one project.')
                        return false;
                    }
                    this.buttonLoading = true;                    
                    this.editModule(params)
                        .then((res)=>{
                            //console.log(res)
                            if(!res.data.result)
                            {
                                if((res.data.data).length > 0){
                                    for (const [key, value] of Object.entries(res.data.data)) {
                                        //console.log("ke",key,value);
                                        if(key == "allSelectedLists"){
                                            this.allSelectError = value[0];
                                        }
                                        else{
                                            this.$validator.errors.add({
                                                id: key,
                                                field: key,
                                                msg: value[0],
                                                scope: this.$options.scope,
                                            });
                                        }
                                    }
                                }
                                else{
                                    this.$eventHub.$emit('common',3,res.data.message);
                                }
                                this.buttonLoading = false
                                return '';
                            }                            
                            this.$router.push({name: 'modules.index'})
                            // this.$router.push({name: 'sms_on_dynamic_template.edit', params: { id: idForNextRoute }})
                            // this.resetForm()
                            this.$eventHub.$emit('update-success')
                        })
                        .catch(()=>{
                            this.$eventHub.$emit('common',3,'Something went wrong.')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>