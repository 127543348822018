import Vue from 'vue'
import Vuex from 'vuex'
import Alert from './modules/alert'
import ChangePassword from './modules/change_password'
import Rules from './modules/rules'
import Session from './modules/session'
import PermissionsIndex from './modules/Permissions'
import PermissionsSingle from './modules/Permissions/single'
import RolesIndex from './modules/Roles'
import RolesSingle from './modules/Roles/single'
import UsersIndex from './modules/Users'
import UsersSingle from './modules/Users/single'
import FranchisesIndex from './modules/Franchises'
import FranchisesSingle from './modules/Franchises/single'
// import CoursesIndex from './modules/Courses'
// import CoursesSingle from './modules/Courses/single'
import ProjectsIndex from './modules/Projects'
import ProjectsSingle from './modules/Projects/single'
import LicenseIndex from './modules/License'
import LicenseSingle from './modules/License/single'

import ModulesIndex from './modules/Modules'
import ModulesSingle from './modules/Modules/single'
import CoursesIndex from './modules/Courses'
import CoursesSingle from './modules/Courses/single'
import ProjectTopicsIndex from './modules/ProjectTopics'
import ProjectTopicsSingle from './modules/ProjectTopics/single'
import ProjectSubTopicsIndex from './modules/ProjectSubTopics'
import ProjectSubTopicsSingle from './modules/ProjectSubTopics/single'
import QuizIndex from './modules/Quiz'
import QuizSingle from './modules/Quiz/single'
import QuizSkillSingle from './modules/Quiz/skillsingle'
import ProjectFeesIndex from './modules/ProjectFees'
import ProjectFeesSingle from './modules/ProjectFees/single'
import CourseFeeAdjustmentIndex from './modules/CourseFeeAdjustments'
import CourseFeeAdjustmentSingle from './modules/CourseFeeAdjustments/single'
import BranchsIndex from './modules/Branchs'
import SmsOnAttendanceIndex from './modules/SmsOnAttendance'
import SmsOnDynamicTemplateIndex from './modules/SmsOnDynamicTemplate'
import SmsOnAdmissionConfirmationIndex from './modules/SmsOnAdmissionConfirmation'
import SmsOnFeeReminderIfPendingIndex from './modules/SmsOnFeeReminderIfPending'
import BranchsSingle from './modules/Branchs/single'
import LeaveReportIndex from './modules/LeaveReport'
import FeesMastersIndex from './modules/FeesMasters'
import FeesMastersSingle from './modules/FeesMasters/single'
import CancelAdmissionIndex from './modules/CancelAdmission'
import CancelAdmissionSingle from './modules/CancelAdmission/single'
import FeeAdjustmentIndex from './modules/FeeAdjustment'
import FeeAdjustmentSingle from './modules/FeeAdjustment/single'
import StatesIndex from './modules/States'
import StatesSingle from './modules/States/single'
import MultipleBranchAccessIndex from './modules/MultipleBranchAccess'
import MultipleBranchAccessSingle from './modules/MultipleBranchAccess/single'
import ByPassAssessmentIndex from './modules/ByPassAssessment'
import ByPassAssessmentSingle from './modules/ByPassAssessment/single'
import HolidaysIndex from './modules/Holidays'
import HolidaysSingle from './modules/Holidays/single'
import LeaveAllocationsIndex from './modules/LeaveAllocations'
import LeaveAllocationsSingle from './modules/LeaveAllocations/single'
import SchoolMastersIndex from './modules/SchoolMasters'
import SchoolMastersSingle from './modules/SchoolMasters/Single'
import BatchMastersIndex from './modules/BatchMasters'
import BatchMastersSingle from './modules/BatchMasters/single'
import EmployeeMastersIndex from './modules/EmployeeMasters'
import EmployeeMastersSingle from './modules/EmployeeMasters/single'
import StudentCompletedCourseReportsIndex from './modules/StudentCompletedCourseReports'
import TrainerStatisticsIndex from './modules/TrainerStatistics'
import CourseTopicMastersIndex from './modules/CourseTopicMasters'
import CourseTopicMastersSingle from './modules/CourseTopicMasters/single'
import ParentsIndex from './modules/Parents'
import ParentsSingle from './modules/Parents/single'
import CouponsIndex from './modules/Coupons'
import GSTSingle from './modules/GSTManage/single'
import GSTIndex from './modules/GSTManage'
import CouponsSingle from './modules/Coupons/single'
import LocationMastersIndex from './modules/LocationMasters'
import LocationMastersSingle from './modules/LocationMasters/single'
import InquiriesIndex from './modules/Inquiries'
import InquiriesSingle from './modules/Inquiries/single'
import CourseAssesmentsIndex from './modules/CourseAssesments'
import CourseAssesmentsSingle from './modules/CourseAssesments/single'
import BatchAllocationsIndex from './modules/BatchAllocations'
import BatchAllocationsSingle from './modules/BatchAllocations/single'
import LeaveRequestsIndex from './modules/LeaveRequests'
import LeaveRequestsSingle from './modules/LeaveRequests/single'
import CommonMastersIndex from './modules/CommonMasters'
import CommonMastersSingle from './modules/CommonMasters/single'
import LeaveApprovalIndex from './modules/LeaveApproval'
import LeaveApprovalSingle from './modules/LeaveApproval/single'
import DashboardIndex from './modules/Dashboard/single'
import SuperAdminDashboardSingle from './modules/SuperAdminDashboard/single'
import TrainerDashboardSingle from './modules/TrainerDashboard/single'
import ParentDashboardSingle from './modules/ParentDashboard/single'
import DashboardSingle from './modules/Dashboard/single'
import RefundsIndex from './modules/Refund'
import RefundsSingle from './modules/Refund/single'
import ReceiptsIndex from './modules/Receipts'
import ReceiptsSingle from './modules/Receipts/single'
import StudentIndex from './modules/Student'
import StudentSingle from './modules/Student/single'
import StudentShowSingle from './modules/Student/showsingle'
import StudentBatchIndex from './modules/StudentBatch'
import StudentBatchSingle from './modules/StudentBatch/single'
import StudentAssessmentIndex from './modules/StudentAssessment'
import StudentAttendanceIndex from './modules/StudentAttendance'
import StudentAssessmentSingle from './modules/StudentAssessment/single'
import StudentBatchListingIndex from './modules/StudentBatchListing'
import TrainerScheduleListingIndex from './modules/TrainerScheduleListing'
import BatchWiseStudentListingIndex from './modules/BatchWiseStudentListing'
import DailyDigestTrainingReportIndex from './modules/DailyDigestTrainingReport'
import EvaluationCriteriaIndex from './modules/EvaluationCriteria'
import BranchAdmissionFeeIndex from './modules/BranchAdmissionFee'
import BranchAdmissionFeeSingle from './modules/BranchAdmissionFee/single'
import BranchLabIndex from './modules/BranchLab'
import BranchLabSingle from './modules/BranchLab/single'
import ProgressReportIndex from './modules/ProgressReport'
import StudentProspectIndex from './modules/StudentProspect'
import ReportsIndex from './modules/Reports'
import SmsSettingIndex from './modules/SmsSetting'
import FeesPaidandBalanceReportIndex from './modules/FeesPaidandBalanceReport'
import FeeCollectionReportIndex from './modules/FeeCollectionReport'
import InquiryAdmissionReportIndex from './modules/InquiryAdmissionReport'
import TrainingManagementReportIndex from './modules/TrainingManagementReport'

//Incomes & Expenses
import IncomeIndex from './modules/Income'
import IncomeSingle from './modules/Income/single'
import ExpenseIndex from './modules/Expense'
import ExpenseSingle from './modules/Expense/single'
import IncomeReportIndex from './modules/IncomeReport'
import ExpenseReportIndex from './modules/ExpenseReport'
//Email Temlate Setup
import EmailTemplateIndex from './modules/EmailTemplate-old'
import EmailTemplateSingleOld from './modules/EmailTemplate-old/single'

import EmailTemplateSingle from './modules/EmailTemplate/single'

//Inventory Item
import InventoryItemIndex from './modules/InventoryItem'
import InventoryItemSingle from './modules/InventoryItem/single'
import InventoryItemPurchaseIndex from './modules/InventoryItemPurchase'
import InventoryItemPurchaseSingle from './modules/InventoryItemPurchase/single'
import InventoryItemSellIndex from './modules/InventoryItemSell'
import InventoryItemSellSingle from './modules/InventoryItemSell/single'
import InventoryStockReportIndex from './modules/InventoryStockReport'
import AdmissionDigestReportIndex from './modules/AdmissionDigestReport'
import WorkshopIndex from './modules/Workshop'
import WorkshopSingle from './modules/Workshop/single'
import RemindersIndex from './modules/Reminders'
import RemindersSingle from './modules/Reminders/single'
import AuthIndex from './modules/Auth'
import StudentScheduleIndex from './modules/StudentSchedule'

import createPersistedState from 'vuex-persistedstate';

import BatchSingle from './modules/Batch/single'
import TutorSingle from './modules/Tutor/Single'
import SkillHeaderMasterSingle from './modules/SkillHeaderMaster/Single'
import SkillMasterSingle from './modules/SkillMaster/Single'
import AdmissionFormIndex from './modules/AdmissionForm'
import ReportCardPDF from './modules/ReportCardPDF'
import CertificatePDF from './modules/CertificatePDF'
import AdminSliderSingle from './modules/AdminSlider/single'
import CommonInquirySingle from './modules/CommonInquiry/single'

import CourseFaqIndex from './modules/CourseFaq/Index'
import CourseCaseStudyIndex from './modules/CourseCaseStudy/Index'

import CertificateSingle from './modules/Certificate/single'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: ['AuthIndex'],
      })],
    modules: {
        AuthIndex,
        Alert,
        ChangePassword,
        Rules,
        Session,
        PermissionsIndex,
        PermissionsSingle,
        RolesIndex,
        RolesSingle,
        UsersIndex,
        UsersSingle,
        FranchisesIndex,
        FranchisesSingle,
        StatesIndex,
        StatesSingle,
        MultipleBranchAccessIndex,
        MultipleBranchAccessSingle,
        ByPassAssessmentIndex,
        ByPassAssessmentSingle,  
        HolidaysIndex,
        HolidaysSingle,        
        BranchsIndex,
        BranchsSingle,
        LeaveReportIndex,
        FeesMastersIndex,
        FeesMastersSingle,
        CancelAdmissionIndex,
        CancelAdmissionSingle,        
        FeeAdjustmentIndex,
        FeeAdjustmentSingle,
        SchoolMastersIndex,
        SchoolMastersSingle,
        BatchMastersIndex,
        BatchMastersSingle,
        EmployeeMastersIndex,
        EmployeeMastersSingle,
        CourseTopicMastersIndex,
        StudentCompletedCourseReportsIndex,
        TrainerStatisticsIndex,
        CourseTopicMastersSingle, 
        ParentsIndex,
        ParentsSingle,       
        LocationMastersIndex,
        LocationMastersSingle,      
        CourseAssesmentsIndex,
        CourseAssesmentsSingle,        
        InquiriesIndex,
        InquiriesSingle,
        BatchAllocationsIndex,
        SmsOnAttendanceIndex,
        SmsOnDynamicTemplateIndex,
        SmsOnAdmissionConfirmationIndex,
        SmsOnFeeReminderIfPendingIndex,
        BatchAllocationsSingle,
        LeaveRequestsIndex,
        LeaveRequestsSingle,
        LeaveAllocationsIndex,
        LeaveAllocationsSingle,        
        CommonMastersIndex,
        CommonMastersSingle,  
        LeaveApprovalIndex,
        LeaveApprovalSingle,
        DashboardIndex,
        SuperAdminDashboardSingle,     
        ParentDashboardSingle,     
        TrainerDashboardSingle,     
        DashboardSingle,     
        RefundsIndex,
        RefundsSingle,
        ReceiptsIndex,
        ReceiptsSingle, 
        StudentIndex,
        StudentBatchIndex,
        StudentBatchSingle,         
        StudentSingle,         
        StudentShowSingle,     
        StudentAttendanceIndex,    
        StudentAssessmentIndex,
        StudentAssessmentSingle,
        StudentBatchListingIndex,
        TrainerScheduleListingIndex,
        BatchWiseStudentListingIndex,
        DailyDigestTrainingReportIndex,
        EvaluationCriteriaIndex,
        BranchAdmissionFeeIndex,
        BranchAdmissionFeeSingle,
        BranchLabIndex,
        BranchLabSingle,
        ProgressReportIndex,   
        StudentProspectIndex,
        ReportsIndex,
        FeesPaidandBalanceReportIndex,
        FeeCollectionReportIndex,
        InquiryAdmissionReportIndex,
        SmsSettingIndex,
        TrainingManagementReportIndex,
        IncomeIndex,
        IncomeSingle,
        ExpenseIndex,
        ExpenseSingle,
        IncomeReportIndex,
        ExpenseReportIndex,
        EmailTemplateIndex,
        EmailTemplateSingleOld,
        InventoryItemIndex,
        InventoryItemSingle,
        InventoryItemPurchaseIndex,
        InventoryItemPurchaseSingle,
        InventoryItemSellIndex,
        InventoryItemSellSingle,
        InventoryStockReportIndex,
        AdmissionDigestReportIndex,
        WorkshopIndex,
        WorkshopSingle,
        RemindersIndex,
        RemindersSingle,
        //lms
        LicenseIndex,
        LicenseSingle,
        ProjectsIndex,
        ProjectsSingle,
        ModulesIndex,
        ModulesSingle,
        CoursesIndex,
        CoursesSingle,
        ProjectTopicsIndex,
        ProjectTopicsSingle,
        ProjectSubTopicsIndex,
        ProjectSubTopicsSingle,
        ProjectFeesIndex,
        ProjectFeesSingle,
        CourseFeeAdjustmentIndex,
        CourseFeeAdjustmentSingle,
        StudentScheduleIndex,
        QuizIndex,
        QuizSingle,
        QuizSkillSingle,
        BatchSingle,
        TutorSingle,
        CouponsIndex,
        CouponsSingle,
        GSTIndex,
        GSTSingle,
        SkillHeaderMasterSingle,
        SkillMasterSingle,
        AdmissionFormIndex,
        ReportCardPDF,
        CertificatePDF,
        AdminSliderSingle,
        CourseFaqIndex,
        CourseCaseStudyIndex,
        CommonInquirySingle,
        CertificateSingle,
        EmailTemplateSingle
    },
})
