function initialState() {
    return {
        all: [],
        item: {
            search_course_name: null,
        },
        relationships: {
            'course_type_id': 'name',
            'search_course_name': 'course_name',
            'course_level_id': 'name',
            'standard': 'name',
        },
        query: {},
        filterQuery:[],
        courseListWithDistinctAll:[],
        loading: false
    }
}

const getters = {
    item: state => state.item,
    courseListWithDistinctAll: state => state.courseListWithDistinctAll,
    loading:       state => state.loading,
    relationships: state => state.relationships
}

const actions = {
    fetchData({ commit, state, dispatch }, date_filter) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/courses',{params:date_filter})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/projects/' + id)
                .then(response => {
                    resolve(response);
                    })
                .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
     fetchCourseListWithDistinctAll({ commit }) {
        axios.get('/api/v1/getCourseListWithDistinct')
            .then(response => {
                commit('setCourseListWithDistinctAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setCourseListWithDistinctAll(state, value) {
        state.courseListWithDistinctAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },        
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
