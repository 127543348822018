<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Common Inquries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/common-inquiries'">Common Inquries</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Common Inquiry</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Edit Common Inquiry</h3>
                        </div>
                        <bootstrap-alert />
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="utm_source" class="form-group">
                                            <label for="utm_source">Utm Source</label>
                                            <v-select
                                                name="utm_source"
                                                label="name"
                                                v-model="item.utm_source"
                                                :options="utmSourceAll"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="utm_medium" class="form-group">
                                            <label for="utm_medium">Utm Medium</label>
                                            <v-select
                                                name="utm_medium"
                                                label="name"
                                                v-model="item.utm_medium"
                                                :options="utmMediumAll"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="utm_campaign" class="form-group">
                                            <label for="utm_campaign">Utm Campaign</label>
                                            <v-select
                                                name="utm_campaign"
                                                label="name"
                                                v-model="item.utm_campaign"
                                                :options="utmCampaignAll"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="branch_id" class="form-group">
                                            <label for="branch_id">Branch</label>
                                            <v-select
                                                name="branch_id"
                                                label="branch_name"
                                                v-model="item.branch_id"
                                                :options="branchAll"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <label for="showAge" class="cursor-pointer">
                                                    <input
                                                    id="showAge"
                                                    type="checkbox"
                                                    name="showAge"
                                                    v-model="item.show_age"
                                                    >
                                                     Show Age
                                            </label>
                                        </div>
                                    </div>
                                </div>  
                                 <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <label for="showStandard" class="cursor-pointer">
                                                    <input
                                                    id="showStandard"
                                                    type="checkbox"
                                                    name="showStandard"
                                                    v-model="item.show_standard"
                                                    >
                                                     Show Standard
                                            </label>
                                        </div>
                                    </div>
                                </div>  
                                <!-- <div class="col-md-6">
                                    <label for="common_url">Common URL<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="common_url"
                                        ref="urcommon_urll"
                                        v-validate="{url: {require_protocol: false }}" 
                                        data-vv-as="field"
                                        placeholder="Enter Common URL"
                                        v-model="item.common_url"
                                    >
                                    <span class="error" v-show="errors.has('common_url')">
                                        {{ $root.updateErrorMessage('common_url',this.errors,'Common URL')}}
                                    </span>
                                </div> -->
                                <div class="col-md-12 savebtn" style="margin-top:20px;">
                                    <vue-button-spinner
                                        class="std_information_save col-md-2 col-md-2 btn btn-block btn-success new_branch_save"
                                        :isLoading="loading"
                                        :disabled="loading"
                                    >
                                        Save
                                    </vue-button-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {  },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('CommonInquirySingle', ['item', 'loading', 'utmSourceAll','utmMediumAll','utmCampaignAll','branchAll']),
    },
    created() {
        this.fetchSources()
        this.fetchMedium()
        this.fetchCampaign()
        this.fetchBranch()
        this.fetchEditData(this.$route.params.id)
    },
     watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchEditData(this.$route.params.id)
        },
    },
    mounted() {
        
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('CommonInquirySingle', ['fetchSources','fetchMedium','fetchCampaign','fetchBranch','setUtmSource','setUtmMedium','setUtmCampaign','setBranch','setUrl','storeData', 'resetState','fetchEditData','updateData']),
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.updateData()
                    .then((res) => {
                        if(res.data.result == false){
                           this.$eventHub.$emit('inquiry-exists') 
                        } else {
                            this.$router.push({ name: 'common-inquiries.index' })
                            this.$eventHub.$emit('update-success')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        }
    }
}
</script>