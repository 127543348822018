function initialState() {
    return {
        item: {},
        remindersToAll: [],
        remindersToIdAll: [],
        reminder_details: [],
        loading: false,
        query: {},
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    remindersToAll: state => state.remindersToAll,
    remindersToIdAll: state => state.remindersToIdAll,
    data: state => {
        if(!state.item || !(state.item.history) || !(state.item.history).length){
            return [];
        }
        
        let rows = state.item.history
        if (state.query.sort) {
            rows = _.orderBy(state.item.history, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['reminder_date','remarks', 'dis_created_at'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
          
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        if(!state.item || !(state.item.history) || !(state.item.history).length){
            return 0;
        }
        let rows = state.item.history
        
        if (state.query.sort) {
            rows = _.orderBy(state.item.history, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['reminder_date','remarks', 'dis_created_at'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 

        return rows.length;
    },
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.reminder_details) {
                let fieldValue = state.reminder_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/reminders', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.reminder_details) {
                let fieldValue = state.reminder_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/reminders/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                    // resolve(response.data.data)


                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/reminders/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchRemindersToAll({ commit }) {
        axios.get(`/api/v1/reminders-to/`)
            .then(response => {
                commit('setRemindersToAll', response.data.data)
            })
    },
    fetchRemindersToEmployee({ commit }) {
        axios.get(`/api/v1/reminders-to-employee/`)
            .then(response => {
                commit('setRemindersToIdAll', response.data.data)
            })
    },
    fetchRemindersToStudent({ commit }) {
        axios.get(`/api/v1/reminders-to-student/`)
            .then(response => {
                commit('setRemindersToIdAll', response.data.data)
            })
    },
    setReminderDetails({ commit }, value) {
        commit('setReminderDetails', value)
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },  
    setItem({ commit }, value) {
        commit('setItem', purify(value))
    },  
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        item.reminder_to = {
            name:item.reminder_to_name,
            value_id:item.reminder_to
        };
        item.reminder_to_id = {
            name:item.reminder_to_id_name,
            id:item.reminder_to_id
        };
        state.item = item
    },
    setRemindersToAll(state, value) {
        state.remindersToAll = value
    },
    setRemindersToIdAll(state, value) {
        state.remindersToIdAll = value
    },
    setReminderDetails(state, value) {
        state.reminder_details = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
