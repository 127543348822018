function initialState() {
    return {
        item: {
            id: null,
            student: null,
            refund_date: /*moment().format('DD/MM/YYYY')*/null,
            manual_refund_no: null,
            receipt_amount: null,
            paymentMode:[{value_id:1,name:'Cash'}],
            feeAll:[],
            receiptFeeAll:[],
        },
        total_due: 0,
        recover_amount: 0,
        studentAll: [],
        paymentModeAll: [],
        pendingFeeAll: [],
        NewpendingFeeAll:[],
        loading: false,
        // paymentModeAll:[{id:1,name:'Cash'},{id:2,name:'Bank'}],
        paymentDetails : [],
        // paymentDetails : [{
        //     payment_mode : {id:1,name:'Cash'},
        //     mode_amount_paid : 0,
        // }],
    }
}

const getters = {
    item: state => state.item,
    total_due: state => state.total_due,
    recover_amount: state => state.recover_amount,
    loading: state => state.loading,
    studentAll: state => state.studentAll,
    pendingFeeAll: state => state.pendingFeeAll,
    NewpendingFeeAll: state => state.NewpendingFeeAll,
    paymentModeAll: state => state.paymentModeAll,
    paymentDetails: state => state.paymentDetails,
    // paymentModeTypeAll: state => state.paymentModeTypeAll,
}

const actions = {
    storeData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){                
                params.set('submitFee',JSON.stringify(finalData['submitFee']));
                params.set('student_id',state.item.student);
            }
            if(state.item.refund_date){
                var formatedFromDate = state.item.refund_date.split('/');
                var finalDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]  
                
                params.set('refund_date',finalDate);
            }
            params.set('RefundFinalTotal', 0);
            if(state.item.receipt_amount){
                params.set('RefundFinalTotal',state.item.receipt_amount);
            }
            
            params.set('recoverTotal',state.recover_amount);
            if(state.paymentDetails){
                state.paymentDetails.paymentMode.forEach(function(paymentMode, index){
                    params.set('paymentMode['+index+'][payment_mode]',paymentMode.payment_mode);
                    if(paymentMode.payment_mode !=1)
                    {
                        params.set('paymentMode['+index+'][instrument_no]',paymentMode.instrument_no);
                        params.set('paymentMode['+index+'][instrument_date]',paymentMode.instrument_date);
                        params.set('paymentMode['+index+'][bank_name]',paymentMode.bank_name);
                    }
                    params.set('paymentMode['+index+'][amount]',paymentMode.amount);
                })
            }
            axiosLms.post('/api/v1/refund/store', params)
                .then(response => {
                    commit('resetState')
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){                
                params.set('submitFee',JSON.stringify(finalData['submitFee']));
                params.set('student_id',state.item.student_id);
            }
            if(state.item.refund_date){
                params.set('refund_date',state.item.refund_date);
            }
            params.set('RefundFinalTotal', 0);
            if(state.item.receipt_amount){
                params.set('RefundFinalTotal',state.item.receipt_amount);
            }
            params.set('recoverTotal',state.recover_amount);
            if(state.paymentDetails){
                state.paymentDetails.paymentMode.forEach(function(paymentMode, index){
                    params.set('paymentMode['+index+'][payment_mode]',paymentMode.payment_mode);
                    if(paymentMode.payment_mode !=1)
                    {
                        params.set('paymentMode['+index+'][instrument_no]',paymentMode.instrument_no);
                        params.set('paymentMode['+index+'][instrument_date]',paymentMode.instrument_date);
                        params.set('paymentMode['+index+'][bank_name]',paymentMode.bank_name);
                    }
                    params.set('paymentMode['+index+'][amount]',paymentMode.amount);                    
                })
            }   
            axiosLms.post('/api/v1/refund/update/' + state.item.id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/refund/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setFeeAll', response.data.data.refund_detail)
                commit('setReceiptFeeAll', response.data.data.receipt_detail)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
        dispatch('fetchStudentAll');
        dispatch('fetchPaymentModeAll');
        // dispatch('fetchPaymentModeTypeAll');
    },
    fetchPaymentModeAll({ commit }) {
        axiosLms.get('/api/v1/receipt/getPaymentModeList')
            .then(response => {                
                commit('setPaymentModeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    // fetchPaymentModeTypeAll({ commit }) {
    //     axios.get('/api/v1/paymentModeTypeList')
    //         .then(response => {                
    //             commit('setPaymentModeTypeAll', response.data.data)
    //         })
    // },            
    async fetchStudentAll({ commit }) {
        /* await axios.get('/api/v1/fetchAllStudents')
            .then(response => {
                commit('setStudentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })  */
    },
    async fetchPendingFeeRefund({ commit }, ids) {
        //await axiosLms.get('/api/v1/getRefundData/'+id)
        await axiosLms.get('/api/v1/getStudentEnrollCoursesForEditRefund/'+ids.student_id+'/'+ids.refund_id)
            .then(response => {
                commit('setPendingFeeAll', response.data.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    async fetchPendingFeeAll({ commit }, id) {
        //await axiosLms.get('/api/v1/getRefundData/'+id)
        await axiosLms.get('/api/v1/getStudentEnrollCoursesForAddRefund/'+id)
            .then(response => {
               // console.log(response);
                commit('setPendingFeeAll', response.data.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    async fetchPaymentFeeAll({ commit }, obj) {
        await axios.get('/api/v1/paymentFeeRefundList/'+obj.id+'/'+obj.rid)
            .then(response => {
                commit('setPendingFeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    async fetchPaymentMode({ commit }, rid) {
        await axiosLms.get('/api/v1/paymentModeRefund/'+rid)
            .then(response => {
               // console.log(response);
                commit('setPaymentDetails', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    }, 
    setRefundDate({ commit }, value) {
        commit('setRefundDate', value)
    }, 
    setManualReceiptNo({ commit }, value) {
        commit('setManualReceiptNo', value)
    }, 
    setReceiptAmount({ commit }, value) {
        commit('setReceiptAmount', value)
    },    
    setPaymentMode({ commit }, value) {
        commit('setPaymentMode', value)
    },    
    setPaymentDetails({ commit }, value) {
        commit('setPaymentDetails', value)
    },    
    setTotalDue({ commit }, value) {
        commit('setTotalDue', value)
    },     
    setPendingFeeAll({ commit }, value) {
        commit('setPendingFeeAll', value)
    },  
    setNewPendingFeeAll({ commit }, value) {
        console.log('test',value);
        commit('setNewPendingFeeAll', value)
    }, 
    setRecoverAmount({ commit }, value) {
        commit('setRecoverAmount', value)
    },    
    setFeeAll({ commit }, value) {
        commit('setFeeAll', value)
    },  
    setReceiptFeeAll({ commit }, value) {
        value = (value) ? value : [];
        commit('setReceiptFeeAll', value)
    },     
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    // setPaymentModeTypeAll(state, value) {
    //     state.paymentModeTypeAll = value
    // },    
    setStudent(state, value) {
        state.item.student = value
    },
    setPaymentMode(state, value) {
        state.item.paymentMode = value
    },
    setRefundDate(state, value) {
        state.item.refund_date = value
    },
    setManualReceiptNo(state, value) {
        state.item.manual_refund_no = value
    },
    setReceiptAmount(state, value) {
        state.item.receipt_amount = value
    },
    setTotalDue(state, value) {
        state.total_due = value
    },
    setRecoverAmount(state, value) {
        state.recover_amount = value
    },        
    setLoading(state, loading) {
        state.loading = loading
    },
    setStudentAll(state, value) {
        state.studentAll = value
    },
    setPendingFeeAll(state, value) {
       // console.log(value);
        state.pendingFeeAll = value
    },
    setNewPendingFeeAll(state, value) {
     //   console.log(value);
        state.NewpendingFeeAll = value
    },
    setPaymentModeAll(state, value) {
        state.paymentModeAll = value
    },
    setPaymentDetails(state, value) {
      //  console.log(value);
        state.paymentDetails = value
    },
    setFeeAll(state, value) {
        state.item.FeeAll = value
    },
    setReceiptFeeAll(state, value) {
        value = (value) ? value : '';
        console.log(value);
        state.item.receiptFeeAll = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
