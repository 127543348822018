function initialState() {
    return {
        item: {
            schoolName: '',
            schoolBoard: [],
            country: { 'id': 99, 'country_name': 'India' },
        },
        countrymastersAll: [],
        schoolBoardAll: [],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    countrymastersAll: state => state.countrymastersAll,
    schoolBoardAll: state => state.schoolBoardAll,
    loading: state => state.loading,
}
const actions = {
    fetchCountrymastersAll({ commit }) {
        axios.get('/api/v1/countrys')
        .then(response => {
            commit('setCountrymastersAll', response.data.data)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })
    },
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['School Board']})
        .then(response => {
            if (response.data.result) {
                let data = response.data.data
                commit('setSchoolBoardAll', data['School Board'])
            } else {
                commit('setSchoolBoardAll', [])
            }
        })
        .catch(function (error) {
            console.log('Error', error.message)
        })                
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.schoolName)) {
            params.set('school_name', '')
        } else {
            params.set('school_name', state.item.schoolName)
        }

        if (_.isEmpty(state.item.schoolBoard)) {
            params.set('school_board', '')
        } else {
            params.set('school_board', state.item.schoolBoard.value_id)
        }
        
        if (_.isEmpty(state.item.country)) {
            params.set('country', '')
        } else {
            params.set('country', state.item.country.id)
        }
       
        axios.post('/api/v1/school/create', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/schoolList')
        .then(response => {
            commit('setItem',  response.data.data)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        }) 
        .finally(() => {
            commit('setLoading', false)
        })
    },
    async fetchSchoolData({ commit, dispatch }, id) {
        await axios.get('/api/v1/school/show/' + id)
        .then(response => {
            if(response.data.result) {
                commit('setItem', response.data.data)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    updateData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.school_name)) {
            params.set('school_name', '')
        } else {
            params.set('school_name', state.item.school_name)
        }

        if (_.isEmpty(state.item.school_board)) {
            params.set('school_board', '')
        } else {
            params.set('school_board', state.item.school_board.value_id)
        }
        
        if (_.isEmpty(state.item.country)) {
            params.set('country', '')
        } else {
            params.set('country', state.item.country.id)
        }
        
        axios.post('/api/v1/school/update/' + id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/v1/school/delete/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            })
        })
    },

    setSchoolName({ commit }, value) {
        commit('setSchoolName', value)
    },
    setSchoolBoard({ commit }, value) {
        commit('setSchoolBoard', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setSchoolName(state, value) {
        state.item.schoolName = value
    },
    setSchoolBoardAll(state, value) {
        state.schoolBoardAll = value
    },
    setSchoolBoard(state, value) {
        state.item.schoolBoard = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setCountry(state, value) {
        state.item.country = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setItem(state,value) {
        state.item = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
