<template>
    <div class="popup-form add-country">
    <b-modal id="addParentModel"
             ref="modal"
             title="Add Parent"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <Create @closeModel="closeModel" @loadItems="loadItems"></Create>
    </b-modal>
  </div>
</template>
<script>
import Create from '@/components/cruds/Parents/Create'
export default {
    components:{
        Create
    },
    methods: {
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){
            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(){
            this.$refs.modal.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>


