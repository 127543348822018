<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <!-- <h1 class="m-0">Branch Dashboad</h1> -->
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <!-- Inquiry -->
        <div class="row">
          <div class="col-md-6">
            <h3 class="m-0">Inquiry</h3>
          </div>
          <div class="col-md-6 align-right-input">
            <date-range-picker
              v-model="pickerDates"
              class="fullwidth align-left-input"
              :ranges=false
              @update="updateValues"
              :localeData="localeData"
              :max-date="new Date()"
              ref="datepicker"
              :calendar-placement="'left'"
            />
          </div>
        </div>
        <div class="row mt-3 small-box-inline">
          <div class="col-lg-3 col-6">
            <div class="small-box bg-warning" v-if="item && !isEmpty(item)">
              <div class="inner">
                <h3 class="text-white">{{item.total}}</h3>
                <p class="text-white">Total Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/total-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a v-if="$can('inquiry_access')" @click="RedirectToTotal" class="small-box-footer" style="cursor: pointer;"><span class="text-white">More info</span> <i class="fas fa-arrow-circle-right text-white"></i></a>
              <a v-else style="cursor: pointer;" href="" class="small-box-footer"><span class="text-white">More info</span> <i class="fa fa-arrow-circle-right text-white"></i></a>
            </div>
            <div class="small-box bg-yellow" v-else-if="loading">
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh text-white"></i></h3>
                <p class="text-white">Total Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/total-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer"><span class="text-white">More info</span> <i class="fa fa-arrow-circle-right text-white"></i></a>
            </div>
            <div class="small-box bg-yellow" v-else-if="item && isEmpty(item)">
              <div class="inner">
                <h3 class="text-white">N/A</h3>
                <p class="text-white">Total Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/total-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer"><span class="text-white">More info</span> <i class="fa fa-arrow-circle-right text-white"></i></a>
            </div>
            <div class="small-box bg-yellow" v-else>
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Total Inquiry </p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/total-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer text-white">More info <i class="fa fa-arrow-circle-right text-white"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-info" v-if="item && !isEmpty(item)">
              <div class="inner">
                <h3>{{item.pending}}</h3>
                <p>Pending Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/pending-inquieries.svg" class="mb-5" width="60px"></i>
              </div>
              <a v-if="$can('inquiry_access')" @click="RedirectToPending" class="small-box-footer" style="cursor: pointer;">More info <i class="fas fa-arrow-circle-right"></i></a>
              <a v-else style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-info" v-else-if="loading">
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Pending Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/pending-inquieries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-info" v-else-if="item && isEmpty(item)">
              <div class="inner">
                <h3>N/A</h3>
                <p>Pending Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/pending-inquieries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-info" v-else>
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Pending Inquiry </p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/pending-inquieries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-primary" v-if="item && !isEmpty(item)">
              <div class="inner">
                <h3>{{item.followup}}</h3>
                <p>Followup Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/follow-up.svg" class="mb-5" width="60px"></i>
              </div>
              <a v-if="$can('inquiry_access')" @click="RedirectToFollowup" class="small-box-footer" style="cursor: pointer;">More info <i class="fas fa-arrow-circle-right"></i></a>
              <a v-else style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-primary" v-else-if="loading">
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Followup Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/follow-up.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-primary" v-else-if="item && isEmpty(item)">
              <div class="inner">
                <h3>N/A</h3>
                <p>Followup Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/follow-up.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-primary" v-else>
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Followup Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/follow-up.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-success" v-if="item && !isEmpty(item)">
              <div class="inner">
                <h3>{{item.enrolled}}</h3>
                <p>Enrolled Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/enrollment-inqiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a v-if="$can('inquiry_access')" @click="RedirectToEnrolled" class="small-box-footer" style="cursor: pointer;">More info <i class="fas fa-arrow-circle-right"></i></a>
              <a v-else style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-success" v-else-if="loading">
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Enrolled Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/enrollment-inqiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-success" v-else-if="item && isEmpty(item)">
              <div class="inner">
                <h3>N/A</h3>
                <p>Enrolled Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/enrollment-inqiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-success" v-else>
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Enrolled Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/enrollment-inqiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-danger" v-if="item && !isEmpty(item)">
              <div class="inner">
                <h3>{{item.closed}}</h3>
                <p>Close Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/close-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a v-if="$can('inquiry_access')" @click="RedirectToClosed" class="small-box-footer" style="cursor: pointer;">More info <i class="fas fa-arrow-circle-right"></i></a>
              <a v-else style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-danger" v-else-if="loading">
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Close Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/close-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-danger" v-else-if="item && isEmpty(item)">
              <div class="inner">
                <h3>N/A</h3>
                <p>Close Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/close-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
            <div class="small-box bg-danger" v-else>
              <div class="inner">
                <h3><i class="fa fa-spin fa-refresh"></i></h3>
                <p>Close Inquiry</p>
              </div>
              <div class="icon">
                <i style="opacity:0.15;"><img src="/assets/images/close-inquiries.svg" class="mb-5" width="60px"></i>
              </div>
              <a style="cursor: pointer;" href="" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
            </div>
          </div>
        </div>
        <!--/.Inquiry -->
        
        <!-- Today Followup List -->
        <div class="row">
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Today Inquiry Followup List</h3>
                <div class="card-tools">
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}"  v-if="!inquiryFollowupLoader && todayFollowupsData && todayFollowupsData.length > 0">
                <table id="example1" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Contact Number</th>
                      <th>Followup Date & Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(followup, index) in todayFollowupsData" :key="index">
                      <td class="Name">
                        <router-link 
                            v-if="$can('inquiry_view')"
                            :to="'inquiries/'+followup.id+'/show'"
                            :class="{label: true, 'label-primary': (followup.inqType == 2), 'label-danger': (followup.inqType == 1)}"
                            title="View Inquiry Details">
                            {{followup.full_name}}
                        </router-link>
                        <span v-else>
                          <span>
                            {{followup.full_name}}
                          </span>
                        </span>
                      </td>
                      <td class="Mobile Number">{{followup.contact_number}}</td>
                      <td class="Followup Date & Time">
                        <StatusPopup :inquiry_id="followup.id" :fetchListFunc="fetchTodaysFollowupList" :btnText="followup.next_followup_date" ></StatusPopup>
                      </td>
                      <td>
                        <InquiryHistory v-if="$can('inquiry_view')" :followup="followup"></InquiryHistory>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-body" v-else-if="inquiryFollowupLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="followupList['todayFollowups'] && followupList['todayFollowups'].length == 0 && todayFollowupsData && todayFollowupsData.length == 0">
                <h3>&#9785;</h3>
                <h5>Looks like there are no Today Inquiry Followups!</h5>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Past Inquiry Followup List</h3>
                <div class="card-tools">
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove"><i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}" v-if="!inquiryFollowupLoader && pastFollowupsData && pastFollowupsData.length > 0">
                <table id="example1" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Contact Number</th>
                      <th style="min-width: 120px !important;">Followup Date & Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(followup, index) in pastFollowupsData" :key="index">
                      <td class="Name">
                        <router-link 
                          v-if="$can('inquiry_view')"
                          :to="'inquiries/'+followup.id+'/show'"
                          :class="{label: true, 'label-primary': (followup.inqType == 2), 'label-danger': (followup.inqType == 1)}"
                          title="View Inquiry Details">
                          {{followup.full_name}}
                        </router-link>
                        <span v-else>
                          <span>
                            {{followup.full_name}}
                          </span>
                        </span>
                      </td>
                      <td class="Mobile Number">{{followup.contact_number}}</td>
                      <td class="Followup Date & Time">
                        <StatusPopup :inquiry_id="followup.id" :fetchListFunc="fetchTodaysFollowupList" :btnText="followup.next_followup_date" ></StatusPopup>
                      </td>
                      <td>
                        <InquiryHistory v-if="$can('inquiry_view')" :followup="followup"></InquiryHistory>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-body" v-else-if="inquiryFollowupLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="followupList['pastFollowups'] && followupList['pastFollowups'].length == 0 && pastFollowupsData && pastFollowupsData.length == 0">
                <h3>&#9785;</h3>
                <h4>Looks like there are no Past Inquiry Followups!</h4>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/.Today Followup List -->
        
        <!-- Student Prospect -->
        <div class="row mt-4 mb-3">
          <div class="col-lg-6">
            <h3 class="">Student Prospect</h3>
          </div>
          <div class="col-lg-6 align-right-input">
            <div class="">
              <date-range-picker
                v-model="pickerProspectDates"
                class="fullwidth align-left-input"
                :ranges=false
                @update="updateProspectValues"
                :localeData="localeData"
                :max-date="new Date()"
                ref="datepicker"
                :calendar-placement="'left'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box" style="cursor: pointer;" @click="redirectToAvailableProspect" v-if="prospect && !isEmpty(prospect)">
              <span class="info-box-icon bg-success elevation-1">
                <i class="fa fa-align-justify"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Available Prospect</span>
                <span v-if="$can('inquiry_access')" class="info-box-number">
                  {{ prospect.prospectAvailable }}
                </span>
                <span v-else style="cursor: pointer;" href="" class="info-box-number">{{ prospect.prospectAvailable }}</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToAvailableProspect" v-else-if="loading">
              <span class="info-box-icon bg-success elevation-1">
                <i class="fa fa-align-justify"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Available Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToAvailableProspect" v-else-if="prospect && isEmpty(prospect)">
              <span class="info-box-icon bg-success elevation-1">
                <i class="fa fa-align-justify"></i>
              </span>
              <div class="info-box-content">
                  <span class="info-box-text text-uppercase">Available Prospect</span>
                  <span style="cursor: pointer;" href="" class="info-box-number">N/A</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToAvailableProspect" v-else>
              <span class="info-box-icon bg-success elevation-1">
                <i class="fa fa-spin fa-refresh"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Available Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3" style="cursor: pointer;" @click="redirectToPendingProspect" v-if="prospect && !isEmpty(prospect)">
              <span class="info-box-icon bg-primary elevation-1">
                <i class="fa fa-clock-o"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Pending Prospect</span>
                <span v-if="$can('inquiry_access')" class="info-box-number">{{ prospect.pendingProspect }}</span>
                <span v-else style="cursor: pointer;" href="" class="info-box-number">{{ prospect.pendingProspect }}</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToPendingProspect" v-else-if="loading">
              <span class="info-box-icon bg-primary elevation-1">
                <i class="fa fa-clock-o"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Pending Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToPendingProspect" v-else-if="prospect && isEmpty(prospect)">
              <span class="info-box-icon bg-primary elevation-1">
                <i class="fa fa-clock-o"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Pending Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">N/A</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToPendingProspect" v-else>
              <span class="info-box-icon bg-primary elevation-1">
                <i class="fa fa-clock-o"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Pending Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box" style="cursor: pointer;" @click="redirectToFollowupProspect" v-if="prospect && !isEmpty(prospect)">
              <span class="info-box-icon bg-warning elevation-1">
                <i class="fa fa-phone text-white"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Followup Prospect</span>
                <span v-if="$can('inquiry_access')" class="info-box-number">
                  {{ prospect.followupProspect }}
                </span>
                <span v-else style="cursor: pointer;" href="" class="info-box-number">{{ prospect.followupProspect }}</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToFollowupProspect" v-else-if="loading">
              <span class="info-box-icon bg-warning elevation-1">
                <i class="fa fa-phone text-white"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Followup Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToFollowupProspect" v-else-if="prospect && isEmpty(prospect)">
              <span class="info-box-icon bg-warning elevation-1">
                <i class="fa fa-phone text-white"></i>
              </span>
              <div class="info-box-content">
                  <span class="info-box-text text-uppercase">Followup Prospect</span>
                  <span style="cursor: pointer;" href="" class="info-box-number">N/A</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToFollowupProspect" v-else>
              <span class="info-box-icon bg-warning elevation-1">
                <i class="fa fa-spin fa-refresh"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Followup Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box" style="cursor: pointer;" @click="redirectToClosedProspect" v-if="prospect && !isEmpty(prospect)">
              <span class="info-box-icon bg-danger elevation-1">
                <i class="fa fa-lock text-white"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Closed Prospect</span>
                <span v-if="$can('inquiry_access')" class="info-box-number">
                  {{ prospect.closedProspect }}
                </span>
                <span v-else style="cursor: pointer;" href="" class="info-box-number">{{ prospect.closedProspect }}</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToClosedProspect" v-else-if="loading">
              <span class="info-box-icon bg-danger elevation-1">
                <i class="fa fa-lock text-white"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Closed Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToClosedProspect" v-else-if="prospect && isEmpty(prospect)">
              <span class="info-box-icon bg-danger elevation-1">
                <i class="fa fa-lock text-white"></i>
              </span>
              <div class="info-box-content">
                  <span class="info-box-text text-uppercase">Closed Prospect</span>
                  <span style="cursor: pointer;" href="" class="info-box-number">N/A</span>
              </div>
            </div>
            <div class="info-box" style="cursor: pointer;" @click="redirectToClosedProspect" v-else>
              <span class="info-box-icon bg-danger elevation-1">
                <i class="fa fa-spin fa-refresh"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Closed Prospect</span>
                <span style="cursor: pointer;" href="" class="info-box-number">
                  <i class="fa fa-spin fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3" v-if="todayAdmissionData && !isEmpty(todayAdmissionData)">
              <span class="info-box-icon bg-info elevation-1">
                <i class="fa fa-user"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Today Admission</span>
                <span v-if="$can('inquiry_access')" class="info-box-number">{{ todayAdmissionData.totalTodayAdmission }}</span>
                <span v-else href="" class="info-box-number">{{ todayAdmissionData.totalTodayAdmission }}</span>
              </div>
            </div>
            <div class="info-box mb-3" v-else-if="loading">
              <span class="info-box-icon bg-info elevation-1">
                <i class="fa fa-user"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Today Admission</span>
                <span href="" class="info-box-number"><i class="fa fa-spin fa-refresh"></i></span>
              </div>
            </div>
            <div class="info-box mb-3" v-else-if="todayAdmissionData && isEmpty(todayAdmissionData)">
              <span class="info-box-icon bg-info elevation-1">
                <i class="fa fa-user"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Today Admission</span>
                <span href="" class="info-box-number">N/A</span>
              </div>
            </div>
            <div class="info-box mb-3" v-else>
              <span class="info-box-icon bg-info elevation-1">
                <i class="fa fa-user"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text text-uppercase">Today Admission</span>
                <span href="" class="info-box-number"><i class="fa fa-spin fa-refresh"></i></span>
              </div>
            </div>
          </div>
        </div>
        <!--/.Student Prospect -->
        
        <div class="row">
          <!-- Course Pending Student -->
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Course Pending Student</h3>
                <div class="card-tools">
                  <button type="button" class="btn-sm btn-info mr-2" Title="Clear" @click="pendingFilterClear">
                    Clear
                  </button>
                  <span
                    v-if="!pendingCoursesLoader && pendingCoursesData.total_count"
                    data-toggle="tooltip" 
                    title="Number of Course Pending Student" 
                    class="badge bg-secondary p-2 mr-2">{{pendingCoursesData.total_count}}
                  </span>
                  <span 
                    v-else-if="pendingCoursesLoader"
                    data-toggle="tooltip" 
                    title="Fetching Total Course Pending Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <span 
                    v-else-if="pendingCoursesData.total_count == 0"
                    data-toggle="tooltip" 
                    title="No Data Available" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-exclamation text-white"></i> 
                  </span>
                  <span 
                    v-else
                    data-toggle="tooltip" 
                    title="Fetching Total Course Pending Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-1" title="Vertical Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu">
                      <a href="javascript:void(0);" class="dropdown-item" @click="pendingVerticalFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_vertical, index) in pendingCourse?.allCourseVertical" :key="index" @click="filter_pending_course_vertical(course_vertical)" :class="{ active: course_vertical.value_id === pendingCourse?.course_vertical?.value_id }" :value="course_vertical.value_id">{{course_vertical.name}}</a>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-2" Title="Category Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu" style="margin-left: -45px;">
                      <a href="javascript:void(0);" class="dropdown-item" @click="pendingCategoryFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_category, index) in pendingCourse?.allCourseCategory" :key="index" @click="filter_pending_course_category(course_category)" :class="{ active: course_category.value_id === pendingCourse?.course_category?.value_id }" :value="course_category.value_id">{{course_category.name}}</a>
                    </div>
                  </div>
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove"><i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}" v-if="!pendingCoursesLoader && pendingCoursesData?.data && pendingCoursesData?.data.length > 0">
                <div class="container">
                  <div class="progress-group mt-3" v-for="(course, index) in pendingCoursesData?.data" :key="index">
                    {{course.course_name}}
                    <span class="float-right">{{course.total}}</span>
                    <div class="progress progress-sm">
                      <div class="progress-bar" :style="getProgress(course)"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="pendingCoursesLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="pendingCoursesData?.data && pendingCoursesData?.data.length == 0">
                <h3>&#9785;</h3>
                <h4>Looks like there is no data!</h4>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <!--/.Course Pending Student -->
          
          <!-- Course Active Student -->
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Course Active Student</h3>
                <div class="card-tools">
                  <button type="button" class="btn-sm btn-info mr-2" Title="Clear" @click="activeFilterClear">
                    Clear
                  </button>
                  <span
                    v-if="!activeCoursesLoader && activeCoursesData.total_count"
                    data-toggle="tooltip" 
                    title="Number of Course Active Student" 
                    class="badge bg-secondary p-2 mr-2">{{activeCoursesData.total_count}}
                  </span>
                  <span 
                    v-else-if="activeCoursesLoader"
                    data-toggle="tooltip" 
                    title="Fetching Total Course Active Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <span 
                    v-else-if="activeCoursesData.total_count == 0"
                    data-toggle="tooltip" 
                    title="No Data Available" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-exclamation text-white"></i> 
                  </span>
                  <span 
                    v-else
                    data-toggle="tooltip" 
                    title="Fetching Total Course Active Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-1" title="Vertical Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu">
                      <a href="javascript:void(0);" class="dropdown-item" @click="activeVerticalFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_vertical, index) in activeCourse?.allCourseVertical" :key="index" @click="filter_active_course_vertical(course_vertical)" :class="{ active: course_vertical.value_id === activeCourse?.course_vertical?.value_id }" :value="course_vertical.value_id">{{course_vertical.name}}</a>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-2" Title="Category Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu" style="margin-left: -45px;">
                      <a href="javascript:void(0);" class="dropdown-item" @click="activeCategoryFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_category, index) in activeCourse?.allCourseCategory" :key="index" @click="filter_active_course_category(course_category)" :class="{ active: course_category.value_id === activeCourse?.course_category?.value_id }" :value="course_category.value_id">{{course_category.name}}</a>
                    </div>
                  </div>
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove"><i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}" v-if="!activeCoursesLoader && activeCoursesData?.data && activeCoursesData?.data.length > 0">
                <div class="container">
                  <div class="progress-group mt-3" v-for="(course, index) in activeCoursesData?.data" :key="index">
                    {{course.course_name}}
                    <span class="float-right">{{course.total}}</span>
                    <div class="progress progress-sm">
                      <div class="progress-bar" :style="getProgress(course)"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="activeCoursesLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="activeCoursesData?.data && activeCoursesData?.data.length == 0">
                <h3>&#9785;</h3>
                <h4>Looks like there is no data!</h4>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <!--/.Course Active Student -->
          
          <!-- Batch Hold Student -->
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Batch Hold Student</h3>
                <div class="card-tools">
                  <button type="button" class="btn-sm btn-info mr-2" Title="Clear" @click="holdFilterClear">
                    Clear
                  </button>
                  <span
                    v-if="!holdCoursesLoader && holdCoursesData.total_count"
                    data-toggle="tooltip" 
                    title="Number of Course Hold Student" 
                    class="badge bg-secondary p-2 mr-2">{{holdCoursesData.total_count}}
                  </span>
                  <span 
                    v-else-if="holdCoursesLoader"
                    data-toggle="tooltip" 
                    title="Fetching Total Course Hold Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <span 
                    v-else-if="holdCoursesData.total_count == 0"
                    data-toggle="tooltip" 
                    title="No Data Available" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-exclamation text-white text-white"></i> 
                  </span>
                  <span 
                    v-else
                    data-toggle="tooltip" 
                    title="Fetching Total Course Hold Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-1" title="Vertical Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu">
                      <a href="javascript:void(0);" class="dropdown-item" @click="holdVerticalFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_vertical, index) in holdCourse?.allCourseVertical" :key="index" @click="filter_hold_course_vertical(course_vertical)" :class="{ active: course_vertical.value_id === holdCourse?.course_vertical?.value_id }" :value="course_vertical.value_id">{{course_vertical.name}}</a>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-2" Title="Category Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu" style="margin-left: -45px;">
                      <a href="javascript:void(0);" class="dropdown-item" @click="holdCategoryFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_category, index) in holdCourse?.allCourseCategory" :key="index" @click="filter_hold_course_category(course_category)" :class="{ active: course_category.value_id === holdCourse?.course_category?.value_id }" :value="course_category.value_id">{{course_category.name}}</a>
                    </div>
                  </div>
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove"><i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}" v-if="!holdCoursesLoader && holdCoursesData?.data && holdCoursesData?.data.length > 0">
                <div class="container">
                  <div class="progress-group mt-3" v-for="(course, index) in holdCoursesData?.data" :key="index">
                    {{course.course_name}}
                    <span class="float-right">{{course.total}}</span>
                    <div class="progress progress-sm">
                      <div class="progress-bar" :style="getProgress(course)"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="holdCoursesLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="holdCoursesData?.data && holdCoursesData?.data.length == 0">
                <h3>&#9785;</h3>
                <h4>Looks like there is no data!</h4>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <!--/.Batch Hold Student -->
          
          <!-- Course Completed Student -->
          <div class="col-md-6 mt-3">
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header">
                <h3 class="card-title">Course Completed Student</h3>
                <div class="card-tools">
                  <button type="button" class="btn-sm btn-info mr-2" Title="Clear" @click="completedFilterClear">
                    Clear
                  </button>
                  <span
                    v-if="!completedCoursesLoader && completedCoursesData.total_count"
                    data-toggle="tooltip" 
                    title="Number of Course Completed Student" 
                    class="badge bg-secondary p-2 mr-2">{{completedCoursesData.total_count}}
                  </span>
                  <span 
                    v-else-if="completedCoursesLoader"
                    data-toggle="tooltip" 
                    title="Fetching Total Course Completed Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <span 
                    v-else-if="completedCoursesData.total_count == 0"
                    data-toggle="tooltip" 
                    title="No Data Available" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-exclamation text-white"></i> 
                  </span>
                  <span 
                    v-else
                    data-toggle="tooltip" 
                    title="Fetching Total Course Completed Student" 
                    class="badge bg-yellow p-2 mr-2"><i style="color: black;" class="fa fa-spin fa-refresh text-white"></i> 
                  </span>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-1" title="Vertical Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu">
                      <a href="javascript:void(0);" class="dropdown-item" @click="completedVerticalFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_vertical, index) in completedCourse?.allCourseVertical" :key="index" @click="filter_completed_course_vertical(course_vertical)" :class="{ active: course_vertical.value_id === completedCourse?.course_vertical?.value_id }" :value="course_vertical.value_id">{{course_vertical.name}}</a>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" class="btn-sm dropdown-toggle mr-2" Title="Category Filter" data-toggle="dropdown">
                      <i class="fas fa-bars"></i>
                    </button>
                    <div class="dropdown-menu float-right" role="menu" style="margin-left: -45px;">
                      <a href="javascript:void(0);" class="dropdown-item" @click="completedCategoryFilterClear">Clear</a>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:void(0);" class="dropdown-item" v-for="(course_category, index) in completedCourse?.allCourseCategory" :key="index" @click="filter_completed_course_category(course_category)" :class="{ active: course_category.value_id === completedCourse?.course_category?.value_id }" :value="course_category.value_id">{{course_category.name}}</a>
                    </div>
                  </div>
                  <button type="button" class="mr-3" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="" data-card-widget="remove"><i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div :class="{'card-body': true, 'moreThanFive': true}" v-if="!completedCoursesLoader && completedCoursesData?.data && completedCoursesData?.data.length > 0">
                <div class="container">
                  <div class="progress-group mt-3" v-for="(course, index) in completedCoursesData?.data" :key="index">
                    {{course.course_name}}
                    <span class="float-right">{{course.total}}</span>
                    <div class="progress progress-sm">
                      <div class="progress-bar" :style="getProgress(course)"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="completedCoursesLoader">
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
              <div class="card-body text-center m-5" v-else-if="completedCoursesData?.data && completedCoursesData?.data.length == 0">
                <h3>&#9785;</h3>
                <h4>Looks like there is no data!</h4>
              </div>
              <div class="card-body" v-else>
                <div class="alert text-center m-5">
                  <i class="fa fa-spin fa-refresh"></i> 
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <!--/.Course Completed Student -->
        </div>
        
        <!-- Batch Schedule -->
        <div class="mt-3">
          <div class="card direct-chat direct-chat-primary">
            <div class="card-header">
              <h3 class="card-title">Student Calendar</h3>
              <div class="card-tools">
                <button type="button" class="mr-3" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
                <button type="button" class="" data-card-widget="remove">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <BatchSchedule />
            </div>
          </div>
        </div>
        <!--/.Batch Schedule -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import InquiryHistory from '../../dtmodules/PopupActions/InquiryHistory'
import StatusPopup from '../../cruds/Inquiries/StatusPopup'
import BatchSchedule from '../../cruds/Dashboard/batchSchedule'

export default {
  components: {
    DateRangePicker,
    InquiryHistory,
    StatusPopup,
    BatchSchedule
  },
  props: ['statusFromProps'],
  data() {
    return {
      localeData: {
        format: 'dd/mm/yyyy',
        separator: ' - ',
      }
    }
  },
  computed: {
    ...mapGetters('DashboardIndex', ['item', 'pickerDates', 'followupList', 'prospect', 'pickerProspectDates', 'pendingDropdownFilters', 'pendingCourse', 'activeDropdownFilters', 'activeCourse', 'holdDropdownFilters', 'todayAdmissionData', 'holdCourse', 'completedDropdownFilters', 'completedCourse', 'pendingCoursesData', 'activeCoursesData', 'holdCoursesData', 'completedCoursesData', 'loading', 'prospectLoader', 'inquiryFollowupLoader', 'pendingCoursesLoader', 'activeCoursesLoader', 'holdCoursesLoader', 'completedCoursesLoader', 'todayAdmissionLoader']),
    
    todayFollowupsData() {
      if (this.followupList && this.followupList['todayFollowups']) {
        return this.followupList['todayFollowups']
      }
      return []
    },
    pastFollowupsData() {
      if (this.followupList && this.followupList['pastFollowups']) {
        return this.followupList['pastFollowups']
      }
      return []
    }
  },
  watch: {
    
  }, 
  created() {
    this.inquiryCurrentDate()
    this.fetchTodaysFollowupList()
    this.prospectCurrentDate()
    this.fetchPendingDropdownFilters()
    this.fetchActiveDropdownFilters()
    this.fetchHoldDropdownFilters()
    this.fetchCompletedDropdownFilters()
    this.fetchPendingCoursesData()
    this.fetchActiveCoursesData()
    this.fetchHoldCoursesData()
    this.fetchCompletedCoursesData()
    this.fetchTodayAdmission()
  },
  mounted() {
    
  },
  methods: {
    ...mapActions('DashboardIndex', ['fetchInquiryAll', 'fetchTodaysFollowupList', 'fetchPendingDropdownFilters', 'fetchProspectAll', 'fetchActiveDropdownFilters', 'fetchHoldDropdownFilters', 'fetchCompletedDropdownFilters', 'fetchPendingCoursesData', 'fetchActiveCoursesData', 'fetchHoldCoursesData', 'fetchCompletedCoursesData', 'fetchTodayAdmission', 'setPendingCourseVertical', 'setPendingCourseCategory', 'setActiveCourseVertical', 'setActiveCourseCategory', 'setHoldCourseVertical', 'setHoldCourseCategory', 'setCompletedCourseVertical', 'setCompletedCourseCategory']),
    
    updateValues (values) {
      this.pickerDates.startDate = moment(values.startDate).format("YYYY-MM-DD")
      this.pickerDates.endDate = moment(values.endDate).format("YYYY-MM-DD")
      this.fetchInquiryAll()
    },
    updateProspectValues (values) {
      this.pickerProspectDates.startDate = moment(values.startDate).format("YYYY-MM-DD")
      this.pickerProspectDates.endDate = moment(values.endDate).format("YYYY-MM-DD")
      this.fetchProspectAll()
    },
    getPickerDates() {
      return {
        'from_date': (this.pickerDates.startDate !='')?moment(this.pickerDates.startDate).format("DD/MM/YYYY"):'',
        'to_date': (this.pickerDates.endDate != '')?moment(this.pickerDates.endDate).format("DD/MM/YYYY"):'',
      }
    },
    inquiryCurrentDate() {
      // this.pickerDates.startDate = moment(new Date()).format("YYYY-MM-DD")
      // this.pickerDates.endDate = moment(new Date()).format("YYYY-MM-DD")
      this.pickerDates.startDate = ''
      this.pickerDates.endDate = ''
      this.fetchInquiryAll()
    },
    RedirectToTotal() {
      this.$router.push({
        name: 'inquiries.index',
        params: {
          'pickerDates': this.getPickerDates()
        }
      })
      this.inquiryCurrentDate()
    },
    RedirectToPending() {
      this.$router.push({
        name: 'inquiries.index',
        params: {
          'statusFromProps': 1,
          'pickerDates': this.getPickerDates()
        }
      })
      this.inquiryCurrentDate()
    },
    RedirectToFollowup() {
      this.$router.push({
        name: 'inquiries.index',
        params: {
          'statusFromProps': 2,
          'pickerDates': this.getPickerDates()
        }
      })
      this.inquiryCurrentDate()
    },
    RedirectToEnrolled() {
      this.$router.push({
        name: 'inquiries.index',
        params: {
          'statusFromProps': 3,
          'pickerDates': this.getPickerDates()
        }
      })
      this.inquiryCurrentDate()
    },
    RedirectToClosed() {
      this.$router.push({
        name: 'inquiries.index',
        params: {
          'statusFromProps': 4,
          'pickerDates': this.getPickerDates()
        }
      })
      this.inquiryCurrentDate()
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
          return false
      }
      return true;
    },
    prospectCurrentDate() {
      // this.pickerProspectDates.startDate = moment(new Date()).format("YYYY-MM-DD")
      // this.pickerProspectDates.endDate = moment(new Date()).format("YYYY-MM-DD")
      this.pickerProspectDates.startDate = ''
      this.pickerProspectDates.endDate = ''
      this.fetchProspectAll()
    },
    redirectToAvailableProspect() {
      this.$router.push({
        name: 'student_prospect.index',
        params: {
          'prospectTypeProps': '0'
        }
      })
      this.prospectCurrentDate()
    },
    redirectToPendingProspect() {
      this.$router.push({
        name: 'student_prospect.index',
        params: {
          'prospectTypeProps': '1'
        }
      })
      this.prospectCurrentDate()
    },
    redirectToFollowupProspect() {
      this.$router.push({
        name: 'student_prospect.index',
        params: {
          'prospectTypeProps': '2'
        }
      })
      this.prospectCurrentDate()
    },
    redirectToClosedProspect() {
      this.$router.push({
        name: 'student_prospect.index',
        params: {
          'prospectTypeProps': '3'
        }
      })
      this.prospectCurrentDate()
    },
    filter_pending_course_vertical(value) {
      this.setPendingCourseVertical(null)
      let params = {
        'course_vertical_id': value.id,
        'course_category_id': '',
      }
      this.setPendingCourseVertical(value)
      this.fetchPendingCoursesData(params)
    },
    filter_pending_course_category(value) {
      this.setPendingCourseCategory(null)
      let params = {
        'course_vertical_id': '',
        'course_category_id': value.id,
      }
      this.setPendingCourseCategory(value)
      this.fetchPendingCoursesData(params)
    },
    filter_active_course_vertical(value) {
      this.setActiveCourseVertical(null)
      let params = {
        'course_vertical_id': value.id,
        'course_category_id': '',
      }
      this.setActiveCourseVertical(value)
      this.fetchActiveCoursesData(params)
    },
    filter_active_course_category(value) {
      this.setActiveCourseCategory(null)
      let params = {
        'course_vertical_id': '',
        'course_category_id': value.id,
      }
      this.setActiveCourseCategory(value)
      this.fetchActiveCoursesData(params)
    },
    filter_hold_course_vertical(value) {
      this.setHoldCourseVertical(null)
      let params = {
        'course_vertical_id': value.id,
        'course_category_id': '',
      }
      this.setHoldCourseVertical(value)
      this.fetchHoldCoursesData(params)
    },
    filter_hold_course_category(value) {
      this.setHoldCourseCategory(null)
      let params = {
        'course_vertical_id': '',
        'course_category_id': value.id,
      }
      this.setHoldCourseCategory(value)
      this.fetchHoldCoursesData(params)
    },
    filter_completed_course_vertical(value) {
      this.setCompletedCourseVertical(null)
      let params = {
        'course_vertical_id': value.id,
        'course_category_id': '',
      }
      this.setCompletedCourseVertical(value)
      this.fetchCompletedCoursesData(params)
    },
    filter_completed_course_category(value) {
      this.setCompletedCourseCategory(null)
      let params = {
        'course_vertical_id': '',
        'course_category_id': value.id,
      }
      this.setCompletedCourseCategory(value)
      this.fetchCompletedCoursesData(params)
    },
    pendingFilterClear() {
      let params = {
        'course_vertical_id': '',
        'course_category_id': '',
      }
      this.setPendingCourseVertical(null)
      this.setPendingCourseCategory(null)
      this.fetchPendingCoursesData(params)
    },
    activeFilterClear() {
      let params = {
        'course_vertical_id': '',
        'course_category_id': '',
      }
      this.setActiveCourseVertical(null)
      this.setActiveCourseCategory(null)
      this.fetchActiveCoursesData(params)
    },
    holdFilterClear() {
      let params = {
        'course_vertical_id': '',
        'course_category_id': '',
      }
      this.setHoldCourseVertical(null)
      this.setHoldCourseCategory(null)
      this.fetchHoldCoursesData(params)
    },
    completedFilterClear() {
      let params = {
        'course_vertical_id': '',
        'course_category_id': '',
      }
      this.setCompletedCourseVertical(null)
      this.setCompletedCourseCategory(null)
      this.fetchCompletedCoursesData(params)
    },
    pendingVerticalFilterClear() {
      let params = {
        'course_vertical_id': '',
      }
      this.setPendingCourseVertical(null)
      this.fetchPendingCoursesData(params)
    },
    pendingCategoryFilterClear() {
      let params = {
        'course_category_id': '',
      }
      this.setPendingCourseCategory(null)
      this.fetchPendingCoursesData(params)
    },
    activeVerticalFilterClear() {
      let params = {
        'course_vertical_id': '',
      }
      this.setActiveCourseVertical(null)
      this.fetchActiveCoursesData(params)
    },
    activeCategoryFilterClear() {
      let params = {
        'course_category_id': '',
      }
      this.setActiveCourseCategory(null)
      this.fetchActiveCoursesData(params)
    },
    holdVerticalFilterClear() {
      let params = {
        'course_vertical_id': '',
      }
      this.setHoldCourseVertical(null)
      this.fetchHoldCoursesData(params)
    },
    holdCategoryFilterClear() {
      let params = {
        'course_category_id': '',
      }
      this.setHoldCourseCategory(null)
      this.fetchHoldCoursesData(params)
    },
    completedVerticalFilterClear() {
      let params = {
        'course_vertical_id': '',
      }
      this.setCompletedCourseVertical(null)
      this.fetchCompletedCoursesData(params)
    },
    completedCategoryFilterClear() {
      let params = {
        'course_category_id': '',
      }
      this.setCompletedCourseCategory(null)
      this.fetchCompletedCoursesData(params)
    },
    getProgress(course) {
      return {
        'width': course.percentage + '%',
        'background-color': course.color
      }
    }
  }
}
</script>
