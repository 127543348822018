<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Requests</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Leave Request</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body"> 
                            <div class="col-md-7 col-xs-12">                          
                                <div class="form-group">
                                    <label for="leave_type">Leave Type<p class="astrisk">*</p></label>
                                    <v-select
                                            name="leave_type"
                                            label="name"
                                            v-validate="'required'"
                                            @input="updateLeave_type"
                                            :value="item.leave_type"
                                            :options="leaveTypeAll"
                                            />
                                            <span class="error" v-show="errors.has('leave_type')">
                                              {{ $root.updateErrorMessage('leave_type',this.errors,'Leave Type')}}
                                            </span>  
                                    <div v-if="leaveSeen && allocateLeave && allocateLeave !='' && (allocateLeave.allocated !='' || allocateLeave.leave_applied !='' || allocateLeave.allocated !='')"> 
                                        <label class="col-md-4 col-xs-12">Leave Allocation : {{allocateLeave.allocated}}</label>
                                        <label class="col-md-4 col-xs-12">Leave Applied : {{allocateLeave.leave_applied}}</label>
                                        <label class="col-md-4 col-xs-12">Balance Leave : {{allocateLeave.balance}}</label>
                                    </div>                                          
                                </div>
                            </div>
                           <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="leave_option">Leave Option<p class="astrisk">*</p></label>
                                    <v-select
                                            name="leave_option"
                                            label="name"
                                            v-validate="'required'"
                                            id="leave_option"
                                            @input="updateLeave_option"
                                            :value="item.leave_option"
                                            :options="leaveOptionAll"
                                            />
                                            <span class="error" v-show="errors.has('leave_option')">
                                              {{ $root.updateErrorMessage('leave_option',this.errors,'Leave Option')}}
                                            </span>
                                </div>
                            </div>
                            <div class="col-md-7 col-xs-12">
                                <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="leave_start_date">Leave Start Date<p class="astrisk">*</p></label>
                                       <!--  <date-picker
                                                :value="item.leave_start_date"
                                                :config="$root.dpconfigDate"
                                                name="leave_start_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                id="leave_start_date"
                                                
                                                placeholder="Enter Leave Start Date"
                                                @dp-change="updateLeave_start_date"
                                                >
                                        </date-picker> -->
                                          <date-picker
                                                :value="item.leave_start_date"
                                                :config="startDateOptionFormate"
                                                name="leave_start_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                id="leave_start_date"
                                                autocomplete="off"
                                                placeholder="Enter Leave Start Date"
                                                @dp-change="updateLeave_start_date"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_start_date')">
                                          {{ $root.updateErrorMessage('leave_start_date',this.errors,'Leave Start Date')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="leave_end_date">Leave End Date<p class="astrisk">*</p></label>
                                       <!--  <date-picker
                                                :value="item.leave_end_date"
                                                :config="$root.dpconfigDate"
                                                id="leave_end_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                name="leave_end_date"
                                                placeholder="Enter Leave End Date"
                                                @dp-change="updateLeave_end_date"
                                                >
                                        </date-picker> -->
                                         <date-picker
                                                :value="item.leave_end_date"
                                                :config="endDateOptionFormate"
                                                id="leave_end_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                name="leave_end_date"
                                                placeholder="Enter Leave End Date"
                                                @dp-change="updateLeave_end_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_end_date')">
                                          {{ $root.updateErrorMessage('leave_end_date',this.errors,'Leave End Date')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                              <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="days">Days<p class="astrisk">*</p></label>
                                    <input
                                            type="number"
                                            class="form-control"
                                            name="days"
                                            v-validate="'required'"
                                            readonly=""
                                            min="0"
                                            placeholder="Enter Days"
                                            :value="getDaysDiffrentFromDate(item.leave_option)"
                                            @input="updateDays"
                                            >
                                </div>
                                <span class="error" v-show="errors.has('days')">
                                  {{ $root.updateErrorMessage('days',this.errors)}}
                                </span>
                            </div>
                            
                            <div class="col-md-7 col-xs-12" v-if="seen">
                            <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label for="start_time">Start Time<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.start_time"
                                            :config="$root.dpconfigTime"
                                            name="start_time"
                                            v-validate="'required'"
                                            placeholder="Enter Start Time"
                                            @dp-change="updateStart_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('start_time')">
                                      {{ $root.updateErrorMessage('start_time',this.errors,'Start Time')}}
                                    </span>                                    
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label for="end_time">End Time<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.end_time"
                                            :config="$root.dpconfigTime"
                                            name="end_time"
                                            v-validate="'required'"
                                            placeholder="Enter End Time"
                                            @dp-change="updateEnd_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('end_time')">
                                      {{ $root.updateErrorMessage('end_time',this.errors,'End Time')}}
                                    </span>
                                    <span class="error" v-show="errors.has('valid_end_time')">
                                        {{ $root.updateErrorMessage('valid_end_time',this.errors)}}
                                    </span>                                     
                                </div>
                            </div>
                            </div>
                        </div>
                          <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="reason">Reason<p class="astrisk">*</p></label>
                                    <textarea
                                            rows="3"
                                            class="form-control"
                                            name="reason"
                                            maxlength="200"
                                            placeholder="Enter Reason"
                                            :value="item.reason"
                                            @input="updateReason"
                                            >
                                    </textarea>
                                </div>
                            </div>                           
                            </div>  
                        </div>
                         <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>   
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            seen:false,
            dateDisplay:false,
            leaveSeen:false,
            endValidDate:false,
            endDateOptionFormate:{format:window.date_format_moment},
            startDateOptionFormate:{format:window.date_format_moment, minDate: new Date(),maxDate:false},
            startCurrentTime:{format:window.time_format_moment,}
        }
    },
    computed: {
        ...mapGetters('LeaveRequestsSingle', ['item', 'loading', 'leaveTypeAll', 'leaveOptionAll','allocateLeave'])
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function(){
            if(this.item.leave_option && this.item.leave_option.value_id != 2){
                 this.seen=true; 
            }else{
                this.seen=false; 
            }

        }
    },
    methods: {
        ...mapActions('LeaveRequestsSingle', ['fetchData', 'updateData', 'resetState', 'setBranch_id', 'setLeave_type', 'setLeave_start_date', 'setLeave_end_date', 'setLeave_option', 'setStart_time', 'setEnd_time', 'setLeave_status', 'setReason', 'setDays','fetchAllocateLeaveAll']),
        //get date diffrent from date
        getDaysDiffrentFromDate(value){
            var startDate = $('#leave_start_date').val();
            var endDate = $('#leave_end_date').val();

            if(startDate != '' && endDate !=''){
                var start = moment(startDate, "YYYY-MM-DD");
                var end = moment(endDate, "YYYY-MM-DD");
                var dd = moment.duration(end.diff(start)).asDays();
                if(value && value.value_id === 2){
                    var work_days = this.leaveCountAll;
                    return work_days;
                }else if(value && value.value_id === 1){
                    this.setDays(0.5);
                    return 0.5;
                }else if(value && value.value_id === 3){
                    this.setLeave_start_date(this.getDate());
                    this.setLeave_end_date(this.getDate());
                    this.setDays(0);
                    return 0;
                }else{                    
                    this.setDays(0);
                    return 0;
                }
            }else{
                this.setDays(0);
                return 0;
            }
           
        },
        // getDaysDiffrentFromDate(value){
        //     var startDate = $('#leave_start_date').val();
        //     var endDate = $('#leave_end_date').val();

        //     if(startDate != '' && endDate !='' && value !=''){
        //         var start = moment(startDate, "YYYY-MM-DD");
        //         var end = moment(endDate, "YYYY-MM-DD");
        //         var dd = moment.duration(end.diff(start)).asDays();
        //         if(value && value.value_id === 2){
        //             this.setDays(dd + 1);
        //             return dd + 1;
        //         }else if(value && value.value_id === 1){
        //             this.setDays(0.5);
        //             return 0.5;
        //         }else if(value && value.value_id === 3){
        //             this.setLeave_start_date(this.getDate());
        //             this.setLeave_end_date(this.getDate());
        //             this.setDays(0);
        //             return 0;
        //         }else{                    
        //             this.setDays(0);
        //             return 0;
        //         }
        //     }else{
        //         this.setDays(0);
        //         return 0;
        //     }
           
        // },
        getDate () {
          const toTwoDigits = num => num < 10 ? '0' + num : num;
          let today = new Date();
          let year = today.getFullYear();
          let month = toTwoDigits(today.getMonth() + 1);
          let day = toTwoDigits(today.getDate());
          return `${year}-${month}-${day}`;
        },
         //getLeaveOptionChnage(value){
          // if(value && value.value_id != 2){
          //       this.seen=true; 
          //   }else{
          //       this.seen=false; 
          //   }
          //   this.setLeave_option(value);
          //   if(value && value.value_id == 3){
          //       this.dateDisplay=true; 
          //       $('#leave_start_date').val(this.getDate());
          //       $('#leave_end_date').val(this.getDate());                              
          //   }else{
          //       console.log('call or not');
          //       $('#leave_start_date').val('');
          //       $('#leave_end_date').val('');
          //       this.dateDisplay=false;
          //   }           
       // },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateLeave_type(value) {

            if(value && value.value_id != 2){
                this.seen=true; 
            }else{
                this.seen=false; 
            }
            this.setLeave_option(value);
            
            if(value && value.value_id == 3){
                this.dateDisplay=false; 
                // $('#leave_start_date').val(this.getDate());
                // $('#leave_end_date').val(this.getDate());                              
            }else{
                $('#leave_start_date').val('');
                $('#leave_end_date').val('');
                this.dateDisplay=false;
            }           
             // if(value && value.value_id)
            this.setStartToEndDate();  


            if(value && value.value_id !='' && value.value_id != 2){
                this.leaveSeen = true;
                this.fetchAllocateLeaveAll(value.value_id);                   
            }else{
                this.leaveSeen = false;
            }
            this.setLeave_type(value)
        },
        setStartToEndDate(leaveOption = 0){
            if(!this.item.leave_option || this.item.leave_option == null || !this.item.leave_option.value_id )
            {
                console.log(this.item.leave_option);
                return '';
            }
            leaveOption =this.item.leave_option.value_id;
            
            //this.endDateOptionFormate = {format:window.date_format_moment,minDate: this.endValidDate};
            if (leaveOption == 1 ) {
               this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: new Date(),
                    maxDate: false,   
               };
            }
            else if (leaveOption == 2) {
                this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: new Date(),
                    maxDate: false,   
               };
            }else if (leaveOption == 3) {
               this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: new Date(),
                    //maxDate: new Date(),   
                    maxDate: false,
               };
            }
            else {
              //  this.endDateOptionFormate = {
              //   format:window.date_format_moment,
              //   minDate: false,
              //   maxDate: false,
              // };
            }                    
           
            return this.endDateOptionFormate;
            
            
        },
        updateLeave_start_date(e) {
            this.endValidDate = e.target.value;
            if(this.item.leave_option && this.item.leave_option.value_id == 3)
            {
            this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : this.endValidDate                    
               };         
           }else if(this.item.leave_option && this.item.leave_option.value_id == 2)
            {
                 this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : false
               };
           }else if(this.item.leave_option && this.item.leave_option.value_id == 1)
            {
            this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : this.endValidDate
               };       
           }
            this.setLeave_start_date(e.target.value)
        },
        updateLeave_end_date(e) {
            this.setLeave_end_date(e.target.value)
        },
        updateLeave_option(value) {
             if(value && value.value_id != 2){
                this.seen=true; 
            }else{
                this.seen=false; 
            }
            // this.setLeave_option(value);
            if(value && value.value_id == 3){
                this.dateDisplay=true; 
                //$('#leave_start_date').val(this.getDate());
                //$('#leave_end_date').val(this.getDate());                              
            }else{
                $('#leave_start_date').val('');
                $('#leave_end_date').val('');
                this.dateDisplay=false;
            }      
            this.setLeave_option(value)
        },
        updateStart_time(e) {
            this.setStart_time(e.target.value)
        },
        updateEnd_time(e) {
            this.setEnd_time(e.target.value)
        },
        isValidateEndTime(){
            var flag = false;
            if(this.item.end_time !=null && this.item.start_time !=null)
            {
                var endTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.end_time);
                var startTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.start_time);
                if(endTime >= startTime){
                    flag = true;
                }
            }
            return flag
        },          
        updateLeave_status(value) {
            this.setLeave_status(value)
        },
        updateReason(e) {
            this.setReason(e.target.value)
        },
        updateDays(e) {
            this.setDays(e.target.value)
        },
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
                if(!this.isValidateEndTime())
                 {
                    this.$validator.errors.add({
                      id: 'valid_end_time',
                      field: 'valid_end_time',
                      msg: 'End Time should be greater than Start Time.',
                      scope: this.$options.scope,
                    });

                    return '';
                 }
                this.updateData()
                    .then(() => {
                        this.$router.push({ name: 'leave_requests.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }else{            
            }
          });
        }
    }
}
</script>


<style scoped>

</style>
