<template>
    <form @submit.stop.prevent="submitForm" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div ref="course_name" class="form-group">
                                <label for="course">Course Name<p class="astrisk">*</p></label>
                                <v-select
                                    name="course_name" 
                                    label="course_name"
                                    id="course"
                                    v-validate="'required'"
                                    @input="updateCourseName"
                                    :value="item.course_name"
                                    :options="coursesAll"
                                />
                                <span class="error" v-show="errors.has('course_name')">
                                    {{ $root.updateErrorMessage('course_name',this.errors, 'Course Name')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="start_date" class="form-group">
                                <label for="start_date">Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="start_date"
                                    :config="$root.dpconfigDate"
                                    name="start_date"
                                    v-validate="'required'"
                                    @input="update_date"
                                    placeholder="Start Date"
                                    autocomplete="off"
                                    >
                                </date-picker>
                                <span class="error" v-show="errors.has('start_date')">
                                    {{ $root.updateErrorMessage('start_date',this.errors,'Start Date')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="end_date" class="form-group">
                                <label for="end_date">End Date<p class="astrisk">*</p></label>
                                <input :value="end_date" class="form-control" placeholder="End Date" readonly />
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12" v-if="batchSchedules.length > 0">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="course_days">Course Days</label>
                                </div>
                               
                                <div class="col-md-10">
                                    <label for="course_days">Batch Time</label>
                                </div>
                            </div>
                            <div class="row" v-for="(batch, index) in batchSchedules" :key="index">
                                <div class="col-md-2">
                                    <div class="form-group weekdays">
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    name="workingDay"
                                                    type="checkbox"
                                                    v-model="batch.daySelect">
                                                    {{batch.dayname}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="form-group">
                                            <v-select
                                                :name="'lab_'+batch.dayname"
                                                label="lab_name"
                                                placeholder="Select Lab"
                                                v-validate="{required:batch.daySelect}"
                                                v-model="batch.selected_lab"
                                                :options="batch.labs"
                                                @change="setBatchTimeDropdown(index)"
                                                />
                                                
                                            <span class="error" v-show="errors.has('lab_'+batch.dayname)">
                                                {{ $root.updateErrorMessage('batch_time_'+batch.dayname,errors,'Batch time')}}
                                            </span>
                                        </div>
                                </div> -->
                                <div class="col-md-7">
                                    <div class="form-group">
                                            <v-select
                                                :name="'batch_time_'+batch.dayname"
                                                label="batch_name"
                                                placeholder="Select Batch Time"
                                                v-validate="{required:batch.daySelect}"
                                                v-model="batch.selected_batch"
                                                :options="batch.batches"
                                                multiple
                                                />
                                                
                                            <span class="error" v-show="errors.has('batch_time_'+batch.dayname)">
                                                {{ $root.updateErrorMessage('batch_time_'+batch.dayname,errors,'Batch time')}}
                                            </span>
                                        </div>
                                </div>
                            </div>
                            <span class="error" v-if="custError != ''">
                                {{ custError }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1">
            <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
            <button type="submit" :disabled='isDisabled' class="btn btn-success">Save</button>
        </footer>
        <template slot="loadingContent">
            <CustomLoader></CustomLoader>
        </template>
    </form>
</template>

<script>
import CustomLoader from '@/components/CustomLoader'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default({
    components: {
        CustomLoader
    },
    props: ['student_id', 'isAddBatch', 'closeAddBatchTab','batchShow'],
    data() {
        return {
            addCourseParams:null,
            checkedDays: [],
            workingCheckedDays: [],
            dayName: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            start_date: null,
            end_date: null,
            batchSchedules: [],
            override: 0,
            isDisabled:false,
            custError:'',
        }
    },
    computed: {
        ...mapGetters('BatchSingle', ['item', 'coursesAll','batchAll']),
    }, 
    created() {
        this.$root.dpconfigDate.minDate = false;
        //console.log("sdtudent",this.student_id,typeof this.isAddBatch);
        this.reset();
        if(this.isAddBatch == true) {
            this.fetchCoursesAll(this.student_id);
        }
    },
    watch: {
        batchAll(value){
            let _this = this;
            _this.batchSchedules = [];
            Object.keys(value).forEach((key, batch) => {
                _this.batchSchedules.push({daySelect:false,dayname:_this.dayName[key-1],day_id:key,batches:value[key]['batch'],selected_batch:null});
            });
        }
    },
    methods: {
        ...mapActions('BatchSingle', ['storeData','fetchBatchAll','fetchCoursesAll', 'fetchCourseWorkingDaysAll', 'setCoursesAll', 'setCourseId', 'setCourseName', 'setCourseMonth']),
        toogleModelSubTable(id ,child){
            var className = $('#modelshow_'+id).attr('class');
            if($('#modelshow_'+id).length > 0 && className == "allhide"){
                $('#modelshow_'+id).removeClass('allhide').addClass('allshow');
                $('#modelangle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');
            }else{
                $('#modelshow_'+id).removeClass('allshow').addClass('allhide');
                $('#modelangle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
            }    
        },
        setBatchTimeDropdown(id){
            this.batchSchedules[id]['selected_batch'] = null;
            this.batchSchedules[id]['batch_time'] = this.batchSchedules[id]['batches'][this.batchSchedules[id].selected_lab.id];
        },
        closeModel(){
            this.isDisabled = false;
            this.reset()
            this.$emit('closeModel')
        },
        submitForm() {
            this.isDisabled = true;
            this.$validator.validate().then(result => {
                if (result) {

                    let flag = 0;
                    for (let index in this.batchSchedules) {
                        if(this.batchSchedules[index]['daySelect']){
                            flag = 1;
                        }          
                    }
                    if(flag == 0){
                        this.custError = 'Please select atleast one batch.';
                        this.isDisabled = false;
                        return false;
                    }
                    let data = {
                        'student_id':this.student_id,
                        'course_id':this.item.course_id,
                        'start_date':this.start_date,
                        'end_date':this.end_date,
                        'batchSchedule':this.batchSchedules,
                        'override':this.override,
                    }
                    this.storeData(data)
                    .then(response => {
                        if (response.data.result) {
                            if(this.batchShow == true){
                                this.$emit('viewloadbatch');
                            }else{
                                this.$emit('closeAddBatchTab');  
                            }

                            this.override = 0;
                            // this.$router.push({ name: 'batch.show' })
                            this.$emit('setPStudentId',this.student_id);
                            this.closeModel();
                             this.$emit('loadItems');
                            // this.fetchCoursesAll()
                            this.$eventHub.$emit('create-success')
                            this.isDisabled = false;
                        } else {
                            if(response.data.data.override) {
                                this.$swal({
                                    title: 'Are you sure want to override batch schedule?',
                                    html: response.data.data.override[0],
                                    type: 'warning',
                                    cancelButtonText: "No",   
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    confirmButtonColor: '#dd4b39',
                                    focusCancel: true,
                                    reverseButtons: true
                                }).then(result => {
                                    if (result.value) {
                                        this.override = 1;
                                        this.submitForm();
                                    }
                                    else{
                                        this.isDisabled = false;
                                    }
                                })
                            } else {
                                for (const [key, value] of Object.entries(response.data.data)) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.isDisabled = false;
                                return '';
                            }  
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                    this.isDisabled = false;
                    return this.$root.handleValidationFocus(this.errors, this.$refs);
                }
            });
        },
        updateCourseName(value) {
            if(value != null) {
                this.setCourseId(value.course_id)
                this.setCourseName(value.course_name)
                this.setCourseMonth(value.course_months)
                this.fetchBatchAll(value.course_id)
            } else {
                this.reset()
            }
        },
        reset() {
            this.setCourseId(null)
            this.setCourseName(null)
            this.setCourseMonth(null)
            this.start_date = null
            this.end_date = null
        },
        update_date(e) {
            this.start_date = e
            var index = e.split('/')
            const dateFormat = index[1] + '/' + index[0] + '/' + index[2]
            this.end_date = moment(dateFormat).add(this.item.course_months, 'M').format('DD/MM/YYYY')
        },
        handleCancel(evt){
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.addCourseParams = null;
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            const current = new Date();
            var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            this.addCourseParams = null;
        },
    }
})
</script>

<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
