function initialState() {
    return {
        item: {},
        loading: false,
        loadingUpcomingSchedule: false,
        studentAll: [],
        studentVal: null,
        courseAll: [],
        courseVal: null,        
        upcomingSchedule: [],        
        progressReportData: [],        
        query: {},
    }
} 

const getters = {
    item: state => state.item,
    data: state => {
        let rows = state.upcomingSchedule
        if (state.query.sort) {
            rows = _.orderBy(state.upcomingSchedule, state.query.sort, state.query.order)
        }
        rows = _.filter(rows, function(item,key){
            item.s_no =  key + 1
            return item
        })
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['s_no','display_session_date','schedule_days','batch_name','lab_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.upcomingSchedule

        if (state.query.sort) {
            rows = _.orderBy(state.upcomingSchedule, state.query.sort, state.query.order)
        }
        rows = _.filter(rows, function(item,key){
            item.s_no =  key + 1
            return item
        })
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['s_no','display_session_date','schedule_days','batch_name','lab_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    loading: state => state.loading,
    loadingUpcomingSchedule: state => state.loadingUpcomingSchedule,
    studentAll: state => state.studentAll,
    studentVal: state => state.studentVal,
    courseAll: state => state.courseAll,
    courseVal: state => state.courseVal,    
    upcomingSchedule: state => state.upcomingSchedule,    
    progressReportData: state => state.progressReportData,    
}

const actions = {
    fetchAllStudents({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllChildOfParent')
                .then(response => {
                    commit('setStudentAll',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },    
    fetchAllCoursesOfStudent({ commit, state, dispatch }) {
        commit('setLoading', true)
        commit('setLoadingUpcomingSchedule', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllCoursesOfStudent/' + (state.studentVal ? state.studentVal.id : null))
                .then(response => {
                    commit('setCourseAll',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },            
    fetchData({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchParentBlocksData/' + (state.studentVal ? state.studentVal.id : null))
                .then(response => {
                    commit('setItem',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                      console.log('Error', error);
                      reject(error)
                }) 
                .finally(() => {
                    // dispatch('fetchUpcomingSchedule')
                })
        })
    },
    fetchUpcomingSchedule({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchUpcomingSchedule/' 
                + (state.studentVal ? state.studentVal.id : null) + '/' 
                + (state.courseVal ? state.courseVal.id : null) + '/'
                + (state.courseVal ? state.courseVal.course_status : null) + '/'
                + (state.courseVal ? state.courseVal.course_start_date : null))
                .then(response => {
                    commit('setUpcomingSchedule',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    // dispatch('fetchStudentProgressData')
                })
        })
    }, 
    fetchStudentProgressData({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchStudentProgressDataForParentDashboard/' 
                + (state.studentVal ? state.studentVal.id : null) + '/' 
                + (state.courseVal ? state.courseVal.id : null) + '/'
                + (state.courseVal ? state.courseVal.course_status : null) + '/'
                + (state.courseVal ? state.courseVal.course_type : null)) 
                .then(response => {
                    commit('setProgressReportData',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    commit('setLoading', false)
                    commit('setLoadingUpcomingSchedule', false)
                })
        })
    },        
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setUpcomingSchedule({ commit }, value) {
        commit('setUpcomingSchedule', value)
    },
    setProgressReportData({ commit }, value) {
        commit('setProgressReportData', value)
    },
    setLoading({ commit }, loading) {
        commit('setLoading', loading)
    },
    setLoadingUpcomingSchedule({ commit }, loadingUpcomingSchedule) {
        commit('setLoadingUpcomingSchedule', loadingUpcomingSchedule)
    },    
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingUpcomingSchedule(state, loadingUpcomingSchedule) {
        state.loadingUpcomingSchedule = loadingUpcomingSchedule
    },
    setQuery(state, query) {
        state.query = query
    },
    setStudentAll(state, value) {
        state.studentAll = value
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },    
    setStudent(state, value) {
        state.studentVal = value
    },    
    setCourse(state, value) {
        state.courseVal = value
    },       
    setUpcomingSchedule(state, value) {
        state.upcomingSchedule = value
    },
    setProgressReportData(state, value) {
        state.progressReportData = value
    },     
    setItem(state, item) {
        state.item = item
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
