function initialState() {
    return {
        item: {
            id: null,
            student_name: null,
            fees_amount: null,
            fees_discount: null,
            gst_amount: null,
            is_gst_applicable: null,
            gst_rate: null,
            total_fees: null,
            branch: null,
            fees_type: null,
        },
        studentNameAll: [],
        branchMastersAll: [],
        feesTypeAll: [],
        
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    loading: state => state.loading,
    studentNameAll: state => state.studentNameAll,
    branchMastersAll: state => state.branchMastersAll,
    feesTypeAll: state => state.feesTypeAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            params.set('student_course_id', 0)
            params.set('course_id', 0)
            if (_.isEmpty(state.item.student_name)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student_name.id)
            }

            if (_.isEmpty(state.item.fees_type)) {
                params.set('fees_type_id', '')
            } else {
                params.set('fees_type', state.item.fees_type.value_id)
            }
            if (_.isEmpty(state.item.fees_discount)) {
                params.set('fees_discount', 0)
            } else {
                params.set('fees_discount', state.item.fees_discount)
            }  
            if (_.isEmpty(state.item.fees_discount)) {
                params.set('discount', 0)
            } else {
                params.set('discount', state.item.fees_discount)
            }  
            if (_.isEmpty(state.item.total_fees)) {
                params.set('fees_after_discount', 0)
            } else {
                params.set('fees_after_discount', state.item.total_fees)
            }  
            if (_.isEmpty(state.item.total_fees)) {
                params.set('total_fees', 0)
            } else {
                params.set('total_fees', state.item.total_fees)
            }            

            axios.post('/api/v1/fees-adjustment', params)
                .then(response => {
                    // commit('resetState')
                    // resolve()
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')
            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                         params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            if (_.isEmpty(state.item.fees_type)) {
                params.set('fees_type_id', '')
            } else {
                params.set('fees_type', state.item.fees_type.value_id)
            }
            if (_.isEmpty(state.item.fees_amount)) {
                params.set('fees_amount', 0)
            } else {
                params.set('fees_amount', state.item.fees_amount)
            } 
            if (_.isEmpty(state.item.fees_discount)) {
                params.set('fees_discount', 0)
            } else {
                params.set('fees_discount', state.item.fees_discount)
            }  
            if (_.isEmpty(state.item.fees_discount)) {
                params.set('discount', 0)
            } else {
                params.set('discount', state.item.fees_discount)
            }  
            if (_.isEmpty(state.item.total_fees)) {
                params.set('fees_after_discount', 0)
            } else {
                params.set('fees_after_discount', state.item.total_fees)
            }  
            if (_.isEmpty(state.item.total_fees)) {
                params.set('total_fees', 0)
            } else {
                params.set('total_fees', state.item.total_fees)
            }   
            // if (_.isEmpty(state.item.gst_amount)) {
            //     params.set('gst_amount', 0)
            // } else {
            //     params.set('gst_amount', state.item.gst_amount)
            // }    

            axios.post('/api/v1/fees-adjustment/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/fees-adjustment/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    },
    fetchStudentNameAll({ commit }) {
        axios.get('/api/v1/students_list_excluding_cancelled')
            .then(response => {
                commit('setStudentNameAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchBranchMasterAll({ commit }) {
        axios.get('/api/v1/branch')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchFeesTypeAll({ commit }) {
        axios.get('/api/v1/feesTypeList')
            .then(response => {
                commit('setFeesTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setStudentName({ commit }, value) {
        commit('setStudentName', value)
    },
    setFees_amount({ commit }, value) {
        commit('setFees_amount', value)
    }, 
    setGstAmount({ commit }, value) {
        commit('setGstAmount', value)
    },
    setFees_discount({ commit }, value) {
        commit('setFees_discount', value)
    },
    setIs_gst_applicable({ commit }, value) {
        commit('setIs_gst_applicable', value)
    },
    setGst_rate({ commit }, value) {
        commit('setGst_rate', value)
    },
    setTotal_fees({ commit }, value) {
        commit('setTotal_fees', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setFees_type({ commit }, value) {
        commit('setFees_type', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setStudentName(state, value) {
        state.item.student_name = value
    },
    setFees_amount(state, value) {
        state.item.fees_amount = value
    },
    setFees_discount(state, value) {
        state.item.fees_discount = value
    },
    setGstAmount(state, value) {
        state.item.gst_amount = value
    },
    setIs_gst_applicable(state, value) {
        state.item.is_gst_applicable = value
    },
    setGst_rate(state, value) {
        state.item.gst_rate = value
    },
    setTotal_fees(state, value) {
        state.item.total_fees = value
    },
    setBranch(state, value) {
        state.item.branch = value
    },
    setFees_type(state, value) {
        state.item.fees_type = value
    },
    setStudentNameAll(state, value) {
        state.studentNameAll = value
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setFeesTypeAll(state, value) {
        state.feesTypeAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
