function initialState() {
    return {
        relationships: {},
        item: {
            branch_id: null,
            joining_date: null,
            exit_date: null,
            gender: 2,
        },
        query: {},
        querySummary: {},
        branchMastersAll: [], 
        reportsDataAll: [],    
        summaryDataAll: [],    
        paymentModeAll: [],    
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,
    data: state => {
        let rows = state.reportsDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['row_number','original_date','original_format_date','student_name','manual_receipt_no','receipt_amount','receipt_type'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.reportsDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['row_number','original_date','original_format_date','student_name','manual_receipt_no','receipt_amount','receipt_type'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },        
    dataSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','receipt_amount'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.querySummary.offset, state.querySummary.offset + state.querySummary.limit)
    },
    totalSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','receipt_amount'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },     
    loading: state => state.loading,
    branchMastersAll: state => state.branchMastersAll,
    paymentModeAll: state => state.paymentModeAll,
    reportsDataAll: state => state.reportsDataAll,
    summaryDataAll: state => state.summaryDataAll,    
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async exportFeeCollectionListStore({ commit }, dataObj) {
      window.location.href = location.protocol + "//" + location.host + '/exportFeeCollectionList/' + dataObj.branch_id+ '/' + dataObj.joining_date + '/' + dataObj.exit_date;
    },      
    fetchFeeCollectionData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            params.set('branch_id', state.item.branch_id.id);
            if (_.isEmpty(state.item.joining_date)) {
                params.set('from_date', '')
            } else {
                params.set('from_date', state.item.joining_date)
            }
            if (_.isEmpty(state.item.exit_date)) {
                params.set('to_date', '')
            } else {
                params.set('to_date', state.item.exit_date)
            }              
            axios.post('/api/v1/feeCollectionReportData', params)
                .then(response => {
                    commit('setFeesPaidAndBalanceReportData', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },   
    async fetchPaymentModeAll({ commit }) {
        await axios.get('/api/v1/paymentModeListAll')
            .then(response => {
                commit('setPaymentModeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },         
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setQuerySummary({ commit }, value) {
        commit('setQuerySummary', purify(value))
    },     
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setFeesPaidAndBalanceReportData({ commit }, value) {
        commit('setFeesPaidAndBalanceReportData', value)
    },    
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },    
    setGender({ commit }, value) {
        commit('setGender', value)
    }, 
    setSummaryDataAll({ commit }, value) {
        commit('setSummaryDataAll', value)
    },       
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {  
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },    
    setQuerySummary(state, querySummary) {
        state.querySummary = querySummary
    },
    setError(state, error) {
        state.error = error
    },   
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },  
    setPaymentModeAll(state, value) {
        state.paymentModeAll = value
    },
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setJoining_date(state, value) {
        state.item.joining_date = value
    },
    setExit_date(state, value) {
        state.item.exit_date = value
    },
    setGender(state, value) {
        state.item.gender = value
    },         
    setFeesPaidAndBalanceReportData(state, value) {
        state.reportsDataAll = value
    }, 
    setSummaryDataAll(state, value) {
        state.summaryDataAll = value
    },      
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
