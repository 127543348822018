<template>
  <form @submit.prevent="submitForm" novalidate>
    <bootstrap-alert />
    <div class="row">
      <div class="col-md-12 p-0">
        <div ref="enroll_date" class="form-group col-md-4 col-xs-12">
          <label for="enroll_date"
            >Admission Date
            <p class="astrisk">*</p></label
          >
          <date-picker
            :value="item.enroll_date"
            :config="$root.dpconfigDatePast"
            name="enroll_date"
            v-validate="'required'"
            @input="updateEnrollDate"
            placeholder="Select Admission Date"
            autocomplete="off"
          >
          </date-picker>
          <span class="error" v-show="errors.has('enroll_date')">
            {{ $root.updateErrorMessage("enroll_date", this.errors, "Admission Date") }}
          </span>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-12 row">
        <div class="form-group col-md-4 col-xs-12">
          <label for="first_name"
            >First Name
            <p class="astrisk">*</p></label
          >
          <input
            type="text"
            class="form-control"
            name="first_name"
            ref="first_name"
            maxlength="90"
            v-validate="'required'"
            placeholder="Enter First Name"
            :value="item.first_name"
            @input="updateFirst_name"
          />
          <span class="error" v-show="errors.has('first_name')">
            {{ $root.updateErrorMessage("first_name", this.errors, "First Name") }}
          </span>
        </div>
        <div class="form-group col-md-4 col-xs-12">
          <label for="middle_name">Middle Name</label>
          <input
            type="text"
            class="form-control"
            name="middle_name"
            ref="middle_name"
            maxlength="90"
            placeholder="Enter Middle Name"
            :value="item.middle_name"
            @input="updateMiddle_name"
          />
        </div>
        <div class="form-group col-md-4 col-xs-12">
          <label for="last_name"
            >Last Name
            <p class="astrisk">*</p></label
          >
          <input
            type="text"
            class="form-control"
            name="last_name"
            ref="last_name"
            maxlength="90"
            v-validate="'required'"
            placeholder="Enter Last Name"
            :value="item.last_name"
            @input="updateLast_name"
          />
          <span class="error" v-show="errors.has('last_name')">
            {{ $root.updateErrorMessage("last_name", this.errors, "Last Name") }}
          </span>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="col-md-12 row">
        <div class="form-group col-md-4 col-xs-12">
          <label for="gender"
            >Gender
            <p class="astrisk">*</p></label
          >
          <br />

          <div class="radio-inline">
            <label class="contain">
              <input
                type="radio"
                name="gender"
                ref="gender"
                v-validate="'required|included:1,2'"
                :value="1"
                :checked="item.gender === 1"
                @change="updateGender(1)"
              />
              <span class="checkmark"></span> Male
            </label>
          </div>
          <div class="radio-inline">
            <label class="contain">
              <input
                type="radio"
                name="gender"
                ref="gender"
                :value="2"
                :checked="item.gender === 2"
                @change="updateGender(2)"
              />
              <span class="checkmark"></span> Female
            </label>
          </div>
          <br />
          <span class="error" v-show="errors.has('gender')">
            {{ $root.updateErrorMessage("gender", this.errors) }}
          </span>
        </div>

        <div ref="birth_date" class="form-group col-md-4 col-xs-12">
          <label for="birth_date"
            >Birth Date
            <p class="astrisk">*</p></label
          >
          <date-picker
            :value="item.birth_date"
            :config="dobDateOptionFormate"
            name="birth_date"
            v-validate="'required'"
            placeholder="Select Birth Date"
            @dp-change="updateBirth_date"
            :endDate="disabledDates"
            autocomplete="off"
          >
          </date-picker>
          <span class="error" v-show="errors.has('birth_date')">
            {{ $root.updateErrorMessage("birth_date", this.errors, "Birth Date") }}
          </span>
        </div>
        <div ref="age" class="form-group col-md-4 col-xs-12" v-if="item.birth_date">
          <label for="age">Age</label>
          <p>{{ item.age }}</p>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="form-group col-md-4 col-xs-12">
        <label for="student_address"
          >Student Address
          <p class="astrisk">*</p></label
        >
        <textarea
          rows="2"
          class="form-control"
          name="student_address"
          ref="student_address"
          maxlength="200"
          v-validate="'required'"
          placeholder="Enter Student Address"
          :value="item.student_address"
          @input="updateStudent_address"
        >
        </textarea>
        <span class="error" v-show="errors.has('student_address')">
          {{
            $root.updateErrorMessage("student_address", this.errors, "Student Address")
          }}
        </span>
      </div>
      <!-- <div class="clearfix"></div> -->
      <div class="form-group col-md-4 col-xs-12">
        <label for="area"
          >Area
          <p class="astrisk">*</p></label
        >
        <input
          type="text"
          class="form-control"
          name="area"
          ref="area"
          v-validate="'required'"
          maxlength="90"
          placeholder="Enter Area"
          :value="item.area"
          @input="updateArea"
        />
        <span class="error" v-show="errors.has('area')">
          {{ $root.updateErrorMessage("area", this.errors, "Area") }}
        </span>
      </div>
      <div class="form-group col-md-4 col-xs-12">
        <label for="pin_code"
          >PIN/ZIP Code
          <p class="astrisk">*</p></label
        >
        <input
          type="text"
          class="form-control"
          name="pin_code"
          ref="pin_code"
          v-validate="'required'"
          maxlength="20"
          placeholder="Enter PIN/ZIP Code"
          :value="item.pin_code"
          @input="updatePin_code"
        />
        <span class="error" v-show="errors.has('pin_code')">
          {{ $root.updateErrorMessage("pin_code", this.errors, "Pin Code") }}
        </span>
      </div>
      <div class="clearfix"></div>

      <div ref="country_list" class="form-group col-md-4 col-xs-12">
        <label for="country"
          >Country
          <p class="astrisk">*</p></label
        >
        <v-select
          name="country_list"
          label="country_name"
          @input="updateCountry_list"
          :value="item.country_list"
          v-validate="'required'"
          placeholder="Select Country"
          :options="countrymastersAll"
        />
        <span class="error" v-show="errors.has('country_list')">
          {{ $root.updateErrorMessage("country_list", this.errors, "Country") }}
        </span>
      </div>
      <div ref="state_list" class="form-group col-md-4 col-xs-12">
        <label for="state"
          >State
          <p class="astrisk">*</p></label
        >
        <v-select
          name="state_list"
          label="state_name"
          @input="updateState_list"
          :value="item.state_list"
          v-validate="'required'"
          placeholder="Select State"
          :options="statemastersAll"
        />

        <span class="error" v-show="errors.has('state_list')">
          {{ $root.updateErrorMessage("state_list", this.errors, "State") }}
        </span>
      </div>
      <div ref="city_list" class="form-group col-md-4 col-xs-12">
        <label for="city"
          >City
          <p class="astrisk">*</p></label
        >
        <v-select
          name="city_list"
          label="city_name"
          @input="updateCity_list"
          :value="item.city_list"
          v-validate="'required'"
          placeholder="Select City"
          :options="citymastersAll"
        />

        <span class="error" v-show="errors.has('city_list')">
          {{ $root.updateErrorMessage("city_list", this.errors, "City") }}
        </span>
      </div>
      <div class="clearfix"></div>
      <div ref="parent" class="form-group col-md-4 col-xs-12">
        <label for="parent"
          >Parent Name
          <p class="astrisk">*</p></label
        >
        <v-select
          name="parent"
          label="parent_mobile"
          maxlength="90"
          v-validate="'required'"
          @input="updateParent"
          :value="item.parent"
          class="parentName"
          :options="parentAll"
        />
        <span class="error" v-show="errors.has('parent')">
          {{ $root.updateErrorMessage("parent", this.errors, "Parent Name") }}
        </span>
      </div>
      <div ref="school" class="form-group col-md-4 col-xs-12">
        <label for="school"
          >School Name
          <p class="astrisk">*</p></label
        >
        <v-select
          name="school"
          label="school_name"
          @input="updateSchool"
          v-validate="'required'"
          :value="item.school"
          class="parentName"
          :options="schoolAll"
        />
        <span class="error" v-show="errors.has('school')">
          {{ $root.updateErrorMessage("school", this.errors, "School Name") }}
        </span>
        <input
          v-if="item.school != null && item.school.id == 0"
          type="text"
          style="margin-top: 10px"
          class="form-control"
          name="other_school_name"
          v-validate="'required'"
          ref="other_school_name"
          maxlength="100"
          placeholder="Enter Other School Name"
          :value="item.other_school_name"
          @input="updateOtherSchoolName"
        />
        <span class="error" v-show="errors.has('other_school_name')">
          {{
            $root.updateErrorMessage(
              "other_school_name",
              this.errors,
              "Other School Name"
            )
          }}
        </span>
      </div>
      <div ref="school" class="form-group col-md-4 col-xs-12">
        <label for="standard_id"
          >Standard
          <p class="astrisk">*</p></label
        >
        <v-select
          name="standard_id"
          label="name"
          @input="updateStandard_id"
          v-validate="'required'"
          :value="item.standard_id"
          :options="standardAll"
        />
        <span class="error" v-show="errors.has('standard_id')">
          {{ $root.updateErrorMessage("standard_id", this.errors, "Standard") }}
        </span>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-4 col-xs-12">
        <div class="form-group">
          <label for="logo">Profile </label>
          <input
            type="file"
            name="profile"
            id="profile"
            v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg'"
            class="form-control"
            @change="updateProfile"
          />
          <span class="error" v-show="errors.has('profile')">
            {{ $root.updateErrorMessage("profile", this.errors, "Profile") }}
          </span>
          <ul v-if="item.profile" class="list-unstyled text-center">
            <li>
              <div class="" style="">
                <div v-if="item.profile != null">
                  <img
                    :src="item.profile"
                    id="blah"
                    class="student_image profile-user-img my-profile img-responsive img-circle"
                    :alt="item.employee_name"
                  />
                </div>
                <div v-else>
                  <img
                    src="/assets/img/male.jpg"
                    class="img-circle my-profile"
                    :alt="item.employee_name"
                  />
                </div>
              </div>
              <button class="btn-xs btn-danger" type="button" @click="removeProfile">
                Remove file
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="my-breck-div"></div>
      <div class="col-md-4">
        <div ref="tshirt_size" class="form-group">
          <label for="tshirt_size">T-shirt Size</label>
          <v-select
            name="tshirt_size"
            label="name"
            @input="updateTshirtSize"
            :value="item.tshirt_size"
            :options="tshirtSizesAll"
          />
        </div>
      </div>
      <div ref="reference" class="form-group col-md-4 col-xs-12">
        <label for="reference"
          >Reference Name
          <p class="astrisk">*</p></label
        >
        <v-select
          name="reference"
          label="name"
          @input="updateReference"
          v-validate="'required'"
          :value="item.reference"
          :options="referenceAll"
        />
        <span class="error" v-show="errors.has('reference')">
          {{ $root.updateErrorMessage("reference", this.errors, "Reference Name") }}
        </span>
      </div>

      <!-- <div class="col-md-2 col-xs-12 savebtn" v-if="courseList"> 
        <vue-button-spinner
                class="std_information_save btn btn-block btn-success new_branch_save"
                :isLoading="loading"
                :disabled="loading"
                >
            Save
        </vue-button-spinner>
    </div> -->
    </div>
    <footer class="modal-footer modal-footer1">
      <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button
      ><button type="submit" class="btn btn-success" :disabled="loading">Save</button>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      onlySummerCamp: true,
      addButtonSeen: false,
      hasError: false,
      courseForm: false,
      courseList: true,
      arrIndex: false,
      inputParams: [],
      inputAddMoreFeeTypeParams: null,
      deleteFeeDetailParams: [],
      inputFeeTypeParams: [],
      inputFeeAmountParams: [],
      inputFeeDiscountParams: [],
      inputFeeGSTValue: [],
      inputFeeGSTParams: [],
      inputFeeTotalParams: 0,
      newsubTotal: [],
      subTotal: [],
      sumSubTotal: 0,
      subFinalTotal: [],
      fee_list: [],
      inqCourse: "",
      saveStartDate: "",
      inqBatchTime: "",
      courseEdit: false,
      courseEditIndex: "",
      preferDaysChecked: [],
      checkedEdit: true,
      seenStudentReason: false,
      feeAvailableCourseList: [],
      addbtnHide: 0,
      stateFlag: true,
      cityFlag: true,
      feeSeen: false,
      dobDateOptionFormate: {
        format: window.date_format_moment,
        maxDate: this.getDOBDate(),
      },
      // Code...
    };
  },
  computed: {
    ...mapGetters("StudentSingle", [
      "item",
      "loading",
      "countrymastersAll",
      "feeAdditionalAdjustment",
      "statemastersAll",
      "citymastersAll",
      "parentAll",
      "schoolAll",
      "standardAll",
      "studentStatusAll",
      "studentReasonAll",
      "statusAll",
      "reasonAll",
      "courseListAll",
      "feesTypeAvailableListAll",
      "courseAll",
      "batchAll",
      "feeCourseList",
      "statemastersAll",
      "tshirtSizesAll",
      "courseComboDetailsAll",
      "branchAdmissionFeesApplicable",
      "referenceAll",
    ]),
  },
  created() {
    this.fetchData(this.id).then((res) => {});
  },
  destroyed() {
    this.resetState();
  },
  watch: {
    "$route.params.id": function () {
      this.resetState();
      this.fetchData(this.$route.params.id);
    },
    item: function () {
      // this.sumSubTotal = this.item.totalAdditionalPrice;
    },
  },
  methods: {
    ...mapActions("StudentSingle", [
      "setOtherSchoolName",
      "fetchData",
      "updateData",
      "resetState",
      "setEnrollDate",
      "setBranch_id",
      "setFirst_name",
      "setMiddle_name",
      "setLast_name",
      "setStudent_address",
      "setArea",
      "setCountry_list",
      "setState_list",
      "setCity_list",
      "setGender",
      "setPin_code",
      "setBirth_date",
      "setParent",
      "setSchool",
      "setStandard_id",
      "setStudent_status",
      "setStudent_reason",
      "setStatus",
      "setNext_followup_date",
      "setReason",
      "setStatus_remarks",
      "setCourseListAll",
      "setCourse",
      "fetchBatchAll",
      "fetchFeeCourseList",
      "fetchStudentFeeCourseList",
      "fetchFeesTypeAvailableListAll",
      "setFeeCourseList",
      "setCourse",
      "setBatch",
      "setPreferDays",
      "setStart_date",
      "fetchCountryStates",
      "fetchStateCities",
      "setFeesTypeAvailableListAll",
      "setCourseAll",
      "fetchCourseAll",
      "setStatemastersAll",
      "setCitymastersAll",
      "fetchTshirtSizesAll",
      "setTshirt",
      "fetchComboFromCourseDetails",
      "setProfile",
      "setOnlySummerCampGlobal",
      "fetchIfBranchAdmissionFeesApplicable",
      "setReference",
    ]),
    closeModel() {
      this.$emit("closeModel");
    },
    disabledDates() {
      from: new Date(Date.now());
    },
    updateReference(value) {
      this.setReference(value);
    },
    removeProfile(e, id) {
      this.$swal({
        title: "Are you sure you want to delete this image?",
        text: "To fully delete the file submit the form.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true,
      }).then((result) => {
        if (typeof result.dismiss === "undefined") {
          this.setProfile("");
          $("#profile").val("");
        }
      });
    },
    updateProfile(e) {
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        if (
          e.target.files[0].size <= 2000000 &&
          (e.target.files[0].type == "image/gif" ||
            e.target.files[0].type == "image/jpeg" ||
            e.target.files[0].type == "image/png")
        ) {
          reader.onload = function (e) {
            $("#blah").attr("src", e.target.result).width(150).height(200);
          };

          reader.readAsDataURL(e.target.files[0]);
          this.setProfile(e.target.files[0]);
          this.$forceUpdate();
        } else {
          var message = "";
          if (
            e.target.files[0].size > 2000000 &&
            (e.target.files[0].type == "image/gif" ||
              e.target.files[0].type == "image/jpeg" ||
              e.target.files[0].type == "image/png")
          ) {
            message = "File size should be less then 2MB.";
          } else {
            message = "Only JPG, JPEG, PNG, GIF files are allowed";
          }
          this.$swal({
            title: message,
            type: "error",
            focusCancel: true,
          }).then((result) => {
            if (typeof result.dismiss === "undefined") {
              $("#profile").val("");
              this.$validator.errors.remove("profile");
            }
          });
        }
      }
    },
    /*this function use to store fees total*/
    sumTotalFee() {
      var ifOnlySummerCampLocal = true;
      var total = 0;
      var total1 = 0;
      if (this.feeAdditionalAdjustment.length > 0) {
        var total1 = this.feeAdditionalAdjustment[0].additionalFees;
      }
      var onlyFiveArray = [],
        otherThanFiveArray = [],
        allCourseType = [];
      var filteredObj = this.courseListAll.find(function (item, i) {
        total = parseFloat(total) + parseFloat(item.total_fees);
        // if(item.course_level != 5){
        //   otherThanFiveArray.push(item.course_level);
        // } else {
        //   onlyFiveArray.push(item.course_level);
        // }
        if (allCourseType.indexOf(item.course_type) == -1) {
          allCourseType.push(item.course_type);
        }
      });
      this.fetchIfBranchAdmissionFeesApplicable(allCourseType).then(() => {
        // ifOnlySummerCampLocal = this.branchAdmissionFeesApplicable[0];
        this.onlySummerCamp = this.branchAdmissionFeesApplicable[0];
        this.setOnlySummerCampGlobal(this.branchAdmissionFeesApplicable[0]);
      });
      // if(otherThanFiveArray.length == 0 && onlyFiveArray.length >= 1) {
      //     ifOnlySummerCampLocal = false;
      // }

      total = parseFloat(total) + parseFloat(this.item.totalAdditionalPrice);
      // return this.sumSubTotal = total.toFixed(2);
      return "";
    },
    updateFeeAmount(index) {
      if (!this.inputFeeAmountParams[index]) {
        this.inputFeeDiscountParams[index] = "";
        this.calculateNewDiscount(index);
      } else if (
        parseFloat(this.inputFeeAmountParams[index]) <
        parseFloat(this.inputFeeDiscountParams[index])
      ) {
        this.inputFeeDiscountParams[index] = "";
        this.calculateNewDiscount(index);
      }
    },
    calculateDiscount(feeAmount, index) {
      if (parseFloat(this.inputParams[index]) > feeAmount) {
        this.inputParams[index] = parseFloat(feeAmount).toFixed(2);
      }
      // else{
      //     if(this.inputParams[index] =='')
      //     {
      //         this.inputParams[index] = "0";
      //     }
      // }
    },
    calculateNewDiscount(index) {
      if (!this.inputFeeAmountParams[index]) {
        this.inputFeeDiscountParams[index] = "";
      } else if (
        parseFloat(this.inputFeeDiscountParams[index]) >
        parseFloat(this.inputFeeAmountParams[index])
      ) {
        this.inputFeeDiscountParams[index] = parseFloat(
          this.inputFeeAmountParams[index]
        ).toFixed(2);
      }
    },
    addCourseFeesData(myCustomeIndex) {
      if (
        !this.inputAddMoreFeeTypeParams &&
        (!this.courseEdit || typeof myCustomeIndex == "undefined")
      ) {
        this.$validator.errors.remove("addMoreFeesType");
        this.$validator.errors.remove("fee_amount");
        this.$validator.errors.add({
          id: "addMoreFeesType",
          field: "addMoreFeesType",
          msg: "Fees Type field is required.",
          scope: this.$options.scope,
        });
        return true;
      } else {
        this.$validator.errors.remove("addMoreFeesType");
        if (this.inputAddMoreFeeTypeParams) {
          this.inputFeeTypeParams[
            this.inputAddMoreFeeTypeParams && this.inputAddMoreFeeTypeParams.value_id
              ? this.inputAddMoreFeeTypeParams.value_id
              : this.deleteFeeDetailParams[myCustomeIndex]
          ] = this.inputAddMoreFeeTypeParams;
        }
        this.feeAvailableCourseList.push({
          deleteFeeDetailParams: myCustomeIndex
            ? myCustomeIndex
            : this.inputAddMoreFeeTypeParams.value_id,
        });
        this.addbtnHide = this.addbtnHide + 1;
        this.fetchFeesTypeAvailableListAll().then((res) => {
          //setTimeout(function () {
          var feesTypeUseList = "";
          feesTypeUseList = purify(this.feesTypeAvailableListAll);

          this.feeCourseList.forEach(
            function (fee, index) {
              feesTypeUseList.forEach(
                function (allFee, allIndex) {
                  var feeValueId = null;
                  if (typeof fee.fees_type.value_id == "undefined") {
                    feeValueId = fee.fees_type;
                  } else {
                    feeValueId = fee.fees_type.value_id;
                  }
                  if (allFee.value_id == feeValueId) {
                    feesTypeUseList.splice(allIndex, 1);
                  }
                }.bind(this)
              );
            }.bind(this)
          );
          /*added value*/
          this.feeAvailableCourseList.forEach(
            function (selectedCourseList, addedIndex) {
              feesTypeUseList.forEach(
                function (allFee, allIndex) {
                  if (
                    selectedCourseList.deleteFeeDetailParams &&
                    this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams] &&
                    this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams]
                      .value_id == allFee.value_id
                  ) {
                    feesTypeUseList.splice(allIndex, 1);
                  }
                }.bind(this)
              );
            }.bind(this)
          );
          this.inputAddMoreFeeTypeParams = null;
          /*end added value*/
          if (feesTypeUseList.length == 0) {
            this.addButtonSeen = false;
          } else {
            this.addButtonSeen = true;
          }
          this.setFeesTypeAvailableListAll(feesTypeUseList);
          //}.bind(this), 440)
        });
      }
      this.inputAddMoreFeeTypeParams = null;
    },
    removeNewCourseFeesData(myCustomeIndex) {
      this.feeAvailableCourseList.forEach(
        function (selectedCourseList, addedIndex) {
          if (
            selectedCourseList &&
            selectedCourseList.deleteFeeDetailParams == myCustomeIndex
          ) {
            delete this.inputFeeTypeParams[myCustomeIndex];
            delete this.deleteFeeDetailParams[myCustomeIndex];
            delete this.inputFeeDiscountParams[myCustomeIndex];
            delete this.inputFeeAmountParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            this.feeAvailableCourseList.splice(addedIndex, 1);
            return false;
          }
        }.bind(this)
      );
      this.fetchFeesTypeAvailableListAll()
        //setTimeout(function () {
        .then((res) => {
          var feesTypeUseList = "";
          feesTypeUseList = purify(this.feesTypeAvailableListAll);

          this.feeCourseList.forEach(
            function (fee, index) {
              feesTypeUseList.forEach(
                function (allFee, allIndex) {
                  var feeValueId = null;
                  if (typeof fee.fees_type.value_id == "undefined") {
                    feeValueId = fee.fees_type;
                  } else {
                    feeValueId = fee.fees_type.value_id;
                  }
                  if (allFee.value_id == feeValueId) {
                    feesTypeUseList.splice(allIndex, 1);
                  }
                }.bind(this)
              );
            }.bind(this)
          );
          /*added value*/
          this.feeAvailableCourseList.forEach(
            function (selectedCourseList, addedIndex) {
              feesTypeUseList.forEach(
                function (allFee, allIndex) {
                  if (
                    selectedCourseList.deleteFeeDetailParams &&
                    this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams] &&
                    this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams]
                      .value_id == allFee.value_id
                  ) {
                    feesTypeUseList.splice(allIndex, 1);
                  }
                }.bind(this)
              );
            }.bind(this)
          );
          this.inputAddMoreFeeTypeParams = null;
          if (feesTypeUseList.length == 0) {
            this.addButtonSeen = false;
          } else {
            this.addButtonSeen = true;
          }
          this.setFeesTypeAvailableListAll(feesTypeUseList);
        });
      //}.bind(this), 740)
    },
    getDOBDate() {
      var d = new Date();
      var pastYear = d.getFullYear() - 5;
      d.setFullYear(pastYear);
      return d;
    },
    updateEnrollDate(e) {
      this.setEnrollDate(e);
    },
    updateBranch_id(e) {
      this.setBranch_id(e.target.value);
    },
    updateFirst_name(e) {
      this.setFirst_name(e.target.value);
    },
    updateMiddle_name(e) {
      this.setMiddle_name(e.target.value);
    },
    updateLast_name(e) {
      this.setLast_name(e.target.value);
    },
    updateStudent_address(e) {
      this.setStudent_address(e.target.value);
    },
    updateArea(e) {
      this.setArea(e.target.value);
    },
    updateCountry_list(value) {
      if (this.stateFlag == true && this.cityFlag == true) {
        this.setState_list(null);
        this.setCity_list(null);
        this.setCitymastersAll([]);
      }
      if (value !== null) {
        this.fetchCountryStates(value.id);
      } else {
        this.setStatemastersAll([]);
      }
      this.setCountry_list(value);
    },
    updateState_list(value) {
      this.stateFlag = true;
      if (this.cityFlag == true) {
        this.setCity_list(null);
        this.setCitymastersAll([]);
      }
      if (value !== null) {
        this.fetchStateCities(value.id);
      } else {
        this.setCitymastersAll([]);
        this.setCity_list(null)
      }
      this.setState_list(value);
    },
    // updateCity(e) {
    //     this.setCity(e.target.value)
    // },
    updateCity_list(value) {
      this.cityFlag = true;
      this.setCity_list(value);
    },
    updateGender(value) {
      this.setGender(value);
    },
    updatePin_code(e) {
      this.setPin_code(e.target.value);
    },
    updateOtherSchoolName(e) {
      this.setOtherSchoolName(e.target.value);
    },
    updateBirth_date(e) {
      this.setBirth_date(e.target.value);
      this.setAge(e.target.value);
    },
    updateStart_date(e) {
      if (e && e.target) {
        this.setStart_date(e.target.value);
      } else {
        if (e) {
          this.setStart_date(e);
        }
      }
      this.fetchBatch(this.item.course);
    },
    updateParent(value) {
      this.setParent(value);
    },
    updateTshirtSize(value) {
      this.setTshirt(value);
    },
    updateSchool(value) {
      this.setSchool(value);
    },
    updateStudent_status(value) {
      if (value && value.value_id == 2) {
        this.seenStudentReason = true;
      } else {
        this.seenStudentReason = false;
      }
      this.setStudent_status(value);
    },
    updateStudent_reason(value) {
      this.setStudent_reason(value);
    },
    updateStandard_id(value) {
      this.setStandard_id(value);
    },
    updateStatus(value) {
      this.setStatus(value);
    },
    updateNext_followup_date(e) {
      this.setNext_followup_date(e.target.value);
    },
    updateReason(value) {
      this.setReason(value);
    },
    updateStatus_remarks(e) {
      this.setStatus_remarks(e.target.value);
    },
    submitForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          //   if(this.courseListAll.length == 0){
          //     this.$validator.errors.add({
          //           id: 'nocourseAdded',
          //           field: 'nocourseAdded',
          //           msg: 'At least one course need to added.',
          //           scope: this.$options.scope,
          //     });
          //     return false;
          // }else{
          //     this.$validator.errors.remove('nocourseAdded');
          // }
          this.updateData()
            .then((res) => {
              if (!res.data.result) {
                this.$validator.errors.add({
                  id: "first_name",
                  field: "first_name",
                  msg: res.data.message,
                  scope: this.$options.scope,
                });
                return this.$root.handleValidationFocus(this.errors, this.$refs);
              }
              this.$emit("closeModel");
              this.$emit("loadItems");
              // if(res.data.error_message == 2) {
              //     this.$eventHub.$emit('delete-error')
              //     return;
              // }
              // this.$router.push({ name: 'student.index' })
              this.$eventHub.$emit("update-success");
              //This code Refreshes the page - DENISH
              // setTimeout(function () { this.$router.go() }.bind(this), 1000)
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          return this.$root.handleValidationFocus(this.errors, this.$refs);
        }
      });
    },

    removeCourceList(id) {
      this.$swal({
        title: "Are you sure you want to delete this item?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var courceListLocal = [];
          courceListLocal = purify(this.courseListAll);
          courceListLocal.splice(id, 1);
          this.setCourseListAll(courceListLocal);
          //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course
          //this.$eventHub.$emit('delete-success')
        }
      });
    },
    addCource() {
      this.fetchFeesTypeAvailableListAll(); //fetch fees list data
      this.seenStudentReason = false; //default false student reason
      this.$validator.errors.remove("course");
      this.$validator.errors.remove("nocourseAdded"); //when course not available

      //This code is used for preventing duplicate entry in Course & fee information (and also prevents adding Full course and individual course individually)
      var courseSelectedList = "";
      //delete this.feeList;
      courseSelectedList = purify(this.courseAll);
      var sendCourseData = [];
      this.courseListAll.forEach(function (courseList, index) {
        sendCourseData.push(courseList.course_id);
      });
      if (sendCourseData.length > 0) {
        this.fetchComboFromCourseDetails(sendCourseData).then((res) => {
          if (this.courseComboDetailsAll) {
            this.setCourseAll(this.courseComboDetailsAll);
          }
        });
      }
      //delete this.feeList;
      this.courseEdit = false;
      this.courseEditIndex = "";
      this.fee_list = [];
      this.setFeeCourseList("");
      this.setCourse(null);
      this.setBatch(null);
      this.setStart_date(null);
      this.setStudent_status(null);
      this.setStudent_reason(null);
      this.inputParams = [];
      this.inputFeeTypeParams = [];
      this.inputFeeDiscountParams = [];
      this.inputFeeAmountParams = [];
      this.inputFeeGSTParams = [];

      this.subTotal = [];

      this.courseForm = true;
      this.courseList = false;
      this.arrIndex = false;
    },
    editCource(id) {
      this.$validator.errors.remove("nocourseAdded"); //when course not available
      this.checkedEdit = true;
      this.courseEdit = true;
      this.courseEditIndex = id;
      this.inputParams = [];
      this.deleteFeeDetailParams = [];
      this.inputFeeTypeParams = [];
      this.inputFeeDiscountParams = [];
      this.inputFeeAmountParams = [];
      this.inputFeeGSTParams = [];
      this.feeAvailableCourseList = [];
      this.fetchFeesTypeAvailableListAll();
      this.fetchCourseAll().then((res) => {
        var courceListLocal = [];

        courceListLocal = purify(this.courseListAll);
        var courceLocal = {
          id: courceListLocal[id]["course_id"],
          course_level: courceListLocal[id]["course_level"],
          course_type: courceListLocal[id]["course_type"],
          enroll_course_status: courceListLocal[id]["enroll_course_status"],
          batch_course_id: courceListLocal[id]["batch_course_id"],
          display_course_name: courceListLocal[id]["display_course_name"],
          student_course_id: courceListLocal[id].student_course_id
            ? courceListLocal[id].student_course_id
            : courceListLocal[id]["id"],
        };
        /*
    Below code is use for the remove fees list from array
*/
        var courseSelectedList = "";
        courseSelectedList = purify(this.courseAll);
        this.courseListAll.forEach(
          function (courseList, index) {
            courseSelectedList.forEach(
              function (selectedCourseList, allIndex) {
                if (
                  courseList.course_id == selectedCourseList.id &&
                  courceListLocal[id]["course_id"] != selectedCourseList.id
                ) {
                  courseSelectedList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );
        if (courseSelectedList) {
          this.setCourseAll(courseSelectedList);
        }
        /*End fees list code*/
        var studentCourseCommanData = courceListLocal[id].course_status;
        var studentCourseReasonCommonData = "";
        if (courceListLocal[id] && courceListLocal[id].status_reason) {
          var studentCourseReasonCommonData = courceListLocal[id].status_reason;
        }
        //var batchLocal = {'id':courceListLocal[id]['prefer_batch_id'],'batch_time':courceListLocal[id]['batch_time']};
        var feeDiscountLocal = [];

        var new_fees_index = 0;
        // this.fee_list = courceListLocal[id].fee_list
        // console.log('this.fee_list',purify(this.fee_list));
        //this is use when add more fees detail that time run following code(Rajesh Rathod 02-10-2018)

        courceListLocal[id].fee_list.forEach(
          function (fee, index) {
            if (fee && fee.is_manual_fee === 1) {
              this.inputFeeTypeParams[fee.fees_type.value_id] = fee.fees_type;
              this.deleteFeeDetailParams[fee.fees_type.value_id] = fee.fees_type.value_id;
              //this.inputFeeTypeParams[new_fees_index]= fee.fees_type.name;
              this.inputFeeDiscountParams[fee.fees_type.value_id] = fee.discount;
              this.inputFeeAmountParams[fee.fees_type.value_id] = fee.fees_amount;
              this.inputFeeGSTParams[fee.fees_type.value_id] = fee.gst_rate;
              // this.addCourseFeesData(fee.fees_type.value_id);
              setTimeout(
                function () {
                  this.addCourseFeesData(fee.fees_type.value_id);
                }.bind(this),
                420
              );
              new_fees_index++;
            } else {
              if (fee && fee.discount) {
                feeDiscountLocal[index] = fee.discount;
              }
            }
          }.bind(this)
        );

        this.setStart_date(courceListLocal[id].course_start_date);
        // this.inputParams = feeDiscountLocal;
        this.updateStudent_status(studentCourseCommanData);
        this.updateStudent_reason(studentCourseReasonCommonData);
        this.setCourse(courceLocal);
        this.fetchBatch(courceLocal);
        //   this.updateBatch(batchLocal);

        this.courseForm = true;
        this.courseList = false;
        this.arrIndex = id;
        setTimeout(
          function () {
            this.assignDiscount(feeDiscountLocal);
          }.bind(this),
          440
        );
      });
    },
    assignDiscount(feeDiscountLocal) {
      // this.inputParams = [];
      this.inputParams = feeDiscountLocal;
    },
    backCourse() {
      this.$validator.errors.remove("addMoreFeesType");
      this.fetchFeesTypeAvailableListAll().then((res) => {
        this.fetchCourseAll();
      });
      //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
      this.checkedEdit = false;
      this.courseEdit = false;
      this.courseEditIndex = false;
      this.courseForm = false;
      this.courseList = true;
      this.arrIndex = false;
      this.feeAvailableCourseList = [];
    },
    saveCourse() {
      //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
      this.$validator.errors.remove(
        "addMoreFeesType"
      ); /*this is remove validation add more course list*/
      this.hasError = false;
      var course = {};
      course.prefer_days = this.preferDaysChecked.join();
      if (this.inqCourse == null || typeof this.inqCourse.id === "undefined") {
        this.$validator.errors.remove("course");
        this.$validator.errors.add({
          id: "err_select_course",
          field: "course",
          msg: "Course Name field is required.",
          scope: this.$options.scope,
        });
        this.hasError = true;
      } else {
        this.$validator.errors.remove("course");
      }
      if (this.hasError) {
        return false;
      }

      course.course_id = this.inqCourse.id;
      course.course_level = this.inqCourse.course_level;
      course.course_type = this.inqCourse.course_type;
      course.enroll_course_status = this.inqCourse.enroll_course_status;
      course.batch_course_id = this.inqCourse.batch_course_id;
      course.student_course_id = this.inqCourse.student_course_id;
      course.display_course_name = this.inqCourse.display_course_name
        ? this.inqCourse.display_course_name
        : "";
      course.course_start_date = this.item.start_date ? this.item.start_date : "";
      course.course_status = this.item.student_status ? this.item.student_status : "";
      course.status_reason =
        this.item.student_reason &&
        this.item.student_reason &&
        this.item.student_status.value_id == 2
          ? this.item.student_reason
          : "";

      var new_fees_detail = [];
      var errFlag = false;
      //this is use when add more fees detail that time run following code(Rajesh Rathod 02-10-2018)
      this.feeAvailableCourseList.forEach(
        function (fee, index) {
          new_fees_detail[fee.deleteFeeDetailParams] = {};
          new_fees_detail[fee.deleteFeeDetailParams].is_manual_fee = 1; //this is use for flag add more value available
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].student_course_id = this.inqCourse.student_course_id;
          new_fees_detail[fee.deleteFeeDetailParams].course_id = this.inqCourse.id;
          new_fees_detail[fee.deleteFeeDetailParams].deleteFeeDetailParams =
            fee.deleteFeeDetailParams;
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].discount = this.inputFeeDiscountParams[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].fees_amount = this.inputFeeAmountParams[fee.deleteFeeDetailParams];
          new_fees_detail[fee.deleteFeeDetailParams].fees_type = this.inputFeeTypeParams[
            fee.deleteFeeDetailParams
          ];
          new_fees_detail[fee.deleteFeeDetailParams].gst_rate = this.inputFeeGSTParams[
            fee.deleteFeeDetailParams
          ];
          new_fees_detail[fee.deleteFeeDetailParams].total_fees = this.newsubTotal[
            fee.deleteFeeDetailParams
          ];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].fees_after_discount = this.newsubtotalCalc(fee.deleteFeeDetailParams);
          new_fees_detail[fee.deleteFeeDetailParams].subtotal =
            new_fees_detail[fee.deleteFeeDetailParams].fees_after_discount;
          if (
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == "" ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == null ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == 0 ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_type == null ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_type == "undefined"
          ) {
            errFlag = true;
          }
        }.bind(this)
      );
      if (errFlag) {
        this.$validator.errors.remove("addMoreFeesType");
        this.$validator.errors.add({
          id: "fee_amount",
          field: "fee_amount",
          msg: "Fee amount should be valid.",
          scope: this.$options.scope,
        });
        return false;
      } else {
        this.$validator.errors.remove("fee_amount");
      }
      this.fetchFeesTypeAvailableListAll(); //fetch fees list data
      this.inputAddMoreFeeTypeParams = null;
      course.fee_list = this.fee_list.concat(new_fees_detail);
      var totalFee = 0;
      course.fee_list.forEach(function (fee) {
        //totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
        if (fee.subtotal) {
          totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
        } else {
          totalFee = parseFloat(totalFee) + parseFloat(fee.fees_amount);
        }
      });
      course.total_fees = totalFee.toFixed(2);
      var courceListLocal = [];

      courceListLocal = purify(this.courseListAll);
      if (this.courseEdit === true) {
        courceListLocal.splice(this.courseEditIndex, 1);
        this.courseEdit = false;
        this.courseEditIndex = "";
      }
      courceListLocal.push(course);
      this.setCourseListAll(courceListLocal);

      this.courseForm = false;
      this.courseList = true;
      this.arrIndex = false;
      this.feeAvailableCourseList = [];
    },
    updateCourse(value) {
      this.fetchFeesTypeAvailableListAll();

      //this.setCourse(value);
      if (this.courseEdit) {
        // this.feeAvailableCourseList=[];/*30-10-2018*/
      } else {
        this.feeAvailableCourseList = []; /*30-10-2018*/
        this.inputParams = [];
        this.deleteFeeDetailParams = [];
        this.inputFeeTypeParams = [];
        this.inputFeeDiscountParams = [];
        this.inputFeeAmountParams = [];
        this.inputFeeGSTParams = [];
        this.feeAvailableCourseList = [];
      }
      if (value) {
        this.addButtonSeen = true;
        //setTimeout(function () {
        var feesTypeUseList = purify(this.feesTypeAvailableListAll);
        this.feeCourseList.forEach(
          function (fee, index) {
            feesTypeUseList.forEach(
              function (allFee, allIndex) {
                var feeValueId = null;
                if (typeof fee.fees_type.value_id == "undefined") {
                  feeValueId = fee.fees_type;
                } else {
                  feeValueId = fee.fees_type.value_id;
                }
                if (allFee.value_id == feeValueId) {
                  feesTypeUseList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );
        this.setFeesTypeAvailableListAll(feesTypeUseList);

        //}.bind(this), 740)
      }

      this.inputAddMoreFeeTypeParams = null;
      this.assignFeeCourseList();
      //setTimeout(function () { this.feeSeen = true; }.bind(this), 420)

      //})
    },
    updateBatch(value) {
      this.setBatch(value);
    },
    fetchBatch(value) {
      this.feeSeen = false;
      this.inputParams = [];
      this.addbtnHide = 0;
      this.inqCourse = value;

      this.inqBatchTime = "";
      if (value != null) {
        // this.feeSeen = true;
        this.$validator.errors.remove("course");
        this.fetchBatchAll(value.id);
        if (value.student_course_id && typeof value.student_course_id != "undefined") {
          //this is use for edit fees detail
          this.fetchStudentFeeCourseList(value.student_course_id).then((res) => {
            this.feeSeen = true;
            this.updateCourse(value);
          });
        } else {
          //this is use for add fees detail
          var obj = {
            id: value.id,
            course_start_date: this.item.start_date,
          };
          this.fetchFeeCourseList(obj).then((res) => {
            this.feeSeen = true;
            this.updateCourse(value);
            // this.setCourse(value);
          });
        }
        // setTimeout(function () { this.assignFeeCourseList() }.bind(this), 420)
      } else {
        this.setCourse(value); // save course detail to send it again when start date is change.
        this.$validator.errors.remove("course");
        this.$validator.errors.add({
          id: "err_select_course",
          field: "course",
          msg: "Course Name field is required.",
          scope: this.$options.scope,
        });
        return false;
      }
    },
    assignFeeCourseList() {
      var feeList = [];
      feeList = purify(this.feeCourseList);

      var feeListCourseOnly = [];
      if (feeList.length > 0) {
        feeList.forEach(function (fee) {
          if (fee && fee.is_manual_fee != 1) {
            feeListCourseOnly.push(fee);
          }
        });
        this.fee_list = feeListCourseOnly;
        this.setFeeCourseList(feeListCourseOnly);
      }
    },
    fetchBatchTime(value) {
      this.inqBatchTime = value;
    },
    subtotalCalc(value, index) {
      if (this.fee_list.length === 0) {
        this.assignFeeCourseList();
      }
      //var sumSubTotal = 0;
      if (purify(this.inputParams).length > 0 && this.fee_list.length > 0) {
        if (typeof this.inputParams[index] === "string") {
          var inputParams = this.inputParams[index];
          if (!(inputParams > 0)) {
            inputParams = 0;
          }
          this.subTotal[index] = parseFloat(value - inputParams).toFixed(2);
          this.fee_list[index].discount = parseFloat(inputParams).toFixed(2);
          this.fee_list[index].subtotal = parseFloat(this.subTotal[index]).toFixed(2);
          //this.sumSubTotal = this.sumSubTotal + this.subTotal[index];
          return this.subTotal[index];
        } else {
          this.subTotal[index] = parseFloat(value).toFixed(2);
          //this.sumSubTotal = this.sumSubTotal + this.subTotal[index];
          return this.subTotal[index];
        }
      } else {
        //this.sumSubTotal = this.sumSubTotal + value;
        return (this.subTotal[index] = value);
      }
    },
    /**
     * Public function to count GST rate value.
     *
     * @FileName      Student Edit vue
     * @FunctionName  countExistGSTValue
     * @author        Rajesh R. Rathod
     * @since         31th Oct 2018
     * @description   Function  to count GST rate value. e.q 1000 * 10 /100 = 100
     * value = sub total
     */
    countExistGSTValue(value, GSTValue) {
      //var inputFeeGSTValue = parseFloat((value)?value:0) * (parseFloat((GSTValue)?GSTValue:0) / 100);
      var gstRate = parseFloat(GSTValue ? parseFloat(GSTValue) + 100 : 0);
      var totalAmt = parseFloat(value ? value : 0);
      var inputFeeGSTValue = 0;
      if (gstRate > 0) {
        inputFeeGSTValue = parseFloat(totalAmt - (totalAmt * 100) / gstRate);
      }
      return (inputFeeGSTValue ? inputFeeGSTValue : 0).toFixed(2);
    },
    /**
     * Public function to count GST rate value in exist value from the list.
     *
     * @FileName      Student Edit vue
     * @FunctionName  countGSTValue
     * @author        Rajesh R. Rathod
     * @since         31th Oct 2018
     * @description   Function  to count GST rate value in exist value from the list. e.q 1000 * 10 /100 = 100
     * value = sub total
     */
    countGSTValue(value, index) {
      var gstRate = parseFloat(
        this.inputFeeGSTParams[index]
          ? parseFloat(this.inputFeeGSTParams[index]) + 100
          : 0
      );
      var totalAmt = parseFloat(value ? value : 0);
      this.inputFeeGSTValue[index] = 0;
      if (gstRate > 0) {
        this.inputFeeGSTValue[index] = parseFloat(totalAmt - (totalAmt * 100) / gstRate);
      }
      // this.inputFeeGSTValue[index] = parseFloat((value)?value:0) * (parseFloat(this.inputFeeGSTParams[index]) / 100);
      return (this.inputFeeGSTValue[index] ? this.inputFeeGSTValue[index] : 0).toFixed(2);
    },
    newsubtotalCalc(index) {
      if (
        typeof this.inputFeeAmountParams[index] === "string" ||
        typeof this.inputFeeDiscountParams[index] === "string"
      ) {
        var myNewVal = parseFloat(
          parseFloat(
            this.inputFeeAmountParams[index] ? this.inputFeeAmountParams[index] : 0
          ) -
            parseFloat(
              this.inputFeeDiscountParams[index] ? this.inputFeeDiscountParams[index] : 0
            )
        ).toFixed(2);
        this.newsubTotal[index] = parseFloat(myNewVal).toFixed(2);
      } else {
        this.newsubTotal[index] = 0;
      }
      return this.newsubTotal[index];
    },
    finaltotalCalc(value, gstRate, index) {
      if (
        typeof this.fee_list != "undefined" &&
        typeof this.fee_list[index] != "undefined"
      ) {
        if (typeof this.subTotal[index] != "undefined") {
          value = parseFloat(this.subTotal[index]);
        }
        var gstValue = this.countExistGSTValue(value, gstRate);
        this.fee_list[index].total_fees = parseFloat(
          parseFloat(value) - parseFloat(gstValue)
        ).toFixed(2);
        return this.fee_list[index].total_fees;
      }

      // if (typeof this.fee_list != "undefined" && typeof this.fee_list[index] != "undefined") {
      //     if(gstRate > 0)
      //     {
      //         if(typeof this.subTotal[index] != 'undefined' ){
      //             var subT = parseFloat(this.subTotal[index]);
      //             var fTotal = parseFloat(subT + (subT*parseFloat(gstRate)/100)).toFixed(2);
      //             this.fee_list[index].total_fees = parseFloat(fTotal).toFixed(2);
      //             return this.fee_list[index].total_fees;
      //         }else{
      //             return value;
      //         }
      //     }
      //     else{
      //         if(typeof this.subTotal[index] != 'undefined'){
      //             this.fee_list[index].total_fees = parseFloat(this.subTotal[index]).toFixed(2);
      //             return this.fee_list[index].total_fees;
      //         }else{
      //             return value;
      //         }
      //     }
      // }
    },
    newfinaltotalCalc(index) {
      this.countGSTValue(this.newsubTotal[index], index);
      var total = this.newsubTotal[index];
      if (this.inputFeeGSTParams[index] ? this.inputFeeGSTParams[index] : 0 > 0) {
        total =
          parseFloat(this.newsubTotal[index]) -
          parseFloat(this.inputFeeGSTValue[index]).toFixed(2);
      }
      return parseFloat(total).toFixed(2);

      // if((this.inputFeeGSTParams[index])?this.inputFeeGSTParams[index]:0 > 0)
      // {
      //     if(typeof this.inputFeeGSTParams[index] === 'string'){
      //         var myNewVal = parseFloat(this.newsubTotal[index]) + (parseFloat(this.newsubTotal[index]) * parseFloat((this.inputFeeGSTParams[index])?parseFloat(this.inputFeeGSTParams[index]):0)/100);
      //                return this.newsubTotal[index] = parseFloat(myNewVal).toFixed(2);
      //     }else{
      //             return this.newsubTotal[index];
      //         }
      // }
      // else{
      //     return this.newsubTotal[index];
      // }
    },
    setAge(value) {
      if (value) {
        var dateArr = value.split("/");
        var date = dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
        this.item.age = this.showAge(date);
      }
    },
    showAge(value) {
      var age = "";
      var user_date = new Date(value);
      var today_date = new Date();
      var diff_date = Math.abs(user_date.getTime() - today_date.getTime());
      var num_years = diff_date / 31536000000;
      var num_months = (diff_date % 31536000000) / 2628000000;
      var num_days = ((diff_date % 31536000000) % 2628000000) / 86400000;
      if (Math.floor(num_years)) {
        age += Math.floor(num_years) + " Years";
      }

      if (Math.floor(num_months)) {
        age += ", " + Math.floor(num_months) + " Months";
      }
      return age;
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.myEno {
  font-size: 16px;
}
.modal-footer1 {
  padding: 12px 15px 0;
  margin: 0 -15px;
}
.student_image {
  position: relative;
  width: 150px !important;
  height: 150px !important;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 25px 0px;
}
</style>
