<template>
    <div class="btn-group btn-group-xs" >
        <router-link
            v-if="$can(xprops.permission_prefix + 'view')"
            :to="{ name: xprops.route + '.show', params: { id: row.student_attendance_id } }"
            class="btn btn-success edit_delete_btn"
            title="View"
            >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>

        <b-btn v-b-modal.studentScheduleModel @click="passAddId(row.student_course_enroll_id)" title="View Student Schedule" type="button" variant="primary" class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-calendar"></i>
        </b-btn> 

        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.schedule_topic_id != null  && (row.status != 0 && row.status != null)" 
            @click="setAssessmentId(row.student_attendance_id,row.schedule_topic_id)"
            v-b-modal.addAssessmentModel
            class="btn btn-info edit_delete_btn"
            title="Make Assessment"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>

        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.schedule_project_id != null && row.session_date == todayDate && (row.status != 0 && row.status != null)"
            @click="setCompleteProjectId(row.student_attendance_id,row.schedule_project_id)"
            
            v-b-modal.addCompleteProjectModel
            class="btn btn-info edit_delete_btn"
            title="Complete Project"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>

        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.schedule_project_manual_id != null && row.session_date == todayDate && (row.status != 0 && row.status != null)"
            @click="setManualCompleteProjectId(row.student_attendance_id,row.schedule_project_manual_id)"
            v-b-modal.addManualCompleteProjectModel
            class="btn btn-info edit_delete_btn"
            title="Manually Complete Project"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>
        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.checkProjectStart == 0 && row.session_date == todayDate && (row.status != 0 && row.status != null)"
            @click="setStartProject(row.student_attendance_id,row.student_course_enroll_id,row.course_id,row.course_name)"
            v-b-modal.addStartProjectModel
            class="btn btn-info edit_delete_btn"
            title="Start Project"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>
        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.completion_course_status == 3 && row.is_manual_complete_course == 0 && row.session_date == todayDate && (row.status != 0 && row.status != null)"
            @click="setCompleteCourseId(row.student_attendance_id,row.student_course_enroll_id)"
            v-b-modal.addCompleteCourseModel
            class="btn btn-info edit_delete_btn"
            title="Complete Course"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>
        <!-- && row.session_date == todayDate -->
        <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.completion_course_status == 3 && row.is_manual_complete_course == 1 && row.session_date == todayDate && (row.status != 0 && row.status != null)"
            @click="setManualCompleteCourseId(row.student_attendance_id,row.student_course_enroll_id)"
            v-b-modal.addManualCompleteCourseModel
            class="btn btn-info edit_delete_btn"
            title="Manual Complete Course"
            >
            <i class="fa fa-fw fa-book"></i>
        </button>
        <!-- project completion request 6-6-23 -->
        <!-- <button
            v-show="$can(xprops.permission_prefix + 'edit') && row.completeProjectStatus == 1 && row.session_date == todayDate"
            @click="setCompleteProjectRequestId(row.student_attendance_id,row.schedule_project_request_id,row.student_id)"
            v-b-modal.addCompleteProjectRequestModel
            class="btn btn-info edit_delete_btn"
            title="Complete Project Request"
            >
            <i class="fa fa-fw fa-book"></i>
        </button> -->
        <!-- <router-link
            v-if="$can(xprops.permission_prefix + 'edit') && row.status.value_id != 3"
            title="Present"
            :to="{ name: xprops.route + '.edit', params: { id: row.student_attendance_id } }"
            class="btn btn-block btn-success edit_delete_btn"
            >
            <i class="fa fa-fw fa-check"></i>
        </router-link> -->

        <!-- && row.session_date == todayDate -->
        <button
            v-if="$can(xprops.permission_prefix + 'edit') && row.status != 1"
            @click="setPresentaaa({'id':row.student_attendance_id,'up_id':row.up_id})"
            class="btn btn-success edit_delete_btn"
            title="Present"
            >
            <i class="fa fa-fw fa-check"></i>
        </button>

        <!-- && row.session_date == todayDate -->
        <button
            v-if="$can(xprops.permission_prefix + 'edit') && row.status != 2 && (row.assessmentProjectTopics).length <= 0"
            @click="setAbsentaaaa({'id':row.student_attendance_id,'up_id':row.up_id})"
            class="btn btn-warning edit_delete_btn"
            title="Absent"
            >
            <i class="fa fa-fw fa-times"></i>
        </button>

        <!-- && row.session_date == todayDate -->
        <button
            v-if="$can(xprops.permission_prefix + 'delete') && row.is_manual == 1 && row.session_date == todayDate"
            @click="destroyDataaaa(row.student_attendance_id)"
            type="button"
            title="Delete"
            class="btn btn-block btn-danger edit_delete_btn"
            >
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    props: ['row', 'xprops'],
    data() {
        return {
            todayDate: moment().format('YYYY-MM-DD'),
            namespace: this.xprops.module ,
            BranchView: false,
            branch_id: 0
        }
    },
    created() {
        
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData','setPresent','setAbsent']))
    },
    methods: {
        passAddId(id){
            this.$emit('addIdModel',id) 
        },
        setAttendanceId(id){
            this.$emit('setInquiryId',id);
        },
        setAssessmentId(id,schedule_topic_id){
            //console.log("click",id,schedule_topic_id);
            this.$emit('setAssessmentData',{'id':id,'schedule_topic_id':schedule_topic_id});
        },
        setStartProject(id,enroll_id,course_id,course_name){
            this.$emit('setStartProject',{'id':id,'enroll_id':enroll_id,'course_id':course_id,'course_name':course_name});
        },
        setCompleteProjectId(id,schedule_project_id){
            // console.log("data",schedule_project_id,id)
            this.$emit('setCompleteProjectData',{'id':id,'schedule_project_id':schedule_project_id});
        },

        setManualCompleteProjectId(id,schedule_project_manual_id){
            this.$emit('setManualCompleteProjectData',{'id':id,'schedule_manual_project_id':schedule_project_manual_id});
        },
        
        //  project completion request 6-6-23 
        setCompleteProjectRequestId(id,schedule_project_id,student_id){
            // console.log("sc pro id",id,schedule_project_id,student_id);
            this.$emit('updateProjectStatus',{'id':id,'schedule_project_id':schedule_project_id,'student_id':student_id})
        },

        setCompleteCourseId(id,course_enroll_id){
            this.$emit('setCompleteCourseData',{'id':id,'student_enroll_id':course_enroll_id});
        },
        setManualCompleteCourseId(id,course_enroll_id){
            this.$emit('setManualCompleteCourseData',{'id':id,'student_enroll_id':course_enroll_id});
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(result => {
                        if(result.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',2,result.data.message)
                        }
                        
                    })
                }
            })
        },
        setPresentaaa(id) {
            this.$swal({
                title: 'Are you sure you want to set Present?',
                text: 'If you click on save then attendance mark as present.',
                type: 'success',
                showCancelButton: true,
                confirmButtonText: 'Save',
                confirmButtonColor: '#28a745',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                   // alert("hrt");
                    this.setPresent(id)
                    .then(result => {
                        if(result.data.result){
                            this.$eventHub.$emit('update-success')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',2,result.data.message)
                        }
                        
                    })
                }
            })
        },
        setAbsentaaaa(id) {
            this.$swal({
                title: 'Are you sure you want to set Absent?',
                text: 'If you click on save then attendance mark as absent.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Save',
                confirmButtonColor: '#ffc107',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.setAbsent(id)
                    .then(result => {
                        if(result.data.result){
                            this.$eventHub.$emit('update-success')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',2,result.data.message)
                        }
                        
                    })
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
