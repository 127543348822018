<template>
    <div class="btn-group btn-group-xs">
       
       <!--  <button
                v-if="xprops.route === 'leave_approval' && row.leave_status.value_id == 1"
                @click="rejectData(row.id)"
                type="button"
                title="Reject"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-close" aria-hidden="true"></i>
        </button>         
        <button
                v-if="xprops.route === 'leave_approval' && row.leave_status.value_id == 1"
                @click="approveData(row.id)"
                type="button"
                title="Cancel"
                class="btn btn-block btn-success edit_delete_btn">
            <i class="fa fa-check" aria-hidden="true"></i>
        </button>    -->    
        <router-link
                v-if="$can(xprops.permission_prefix + 'view')"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
  
          <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && row.leave_status == 1"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
       
        rejectData(id) {
            this.$swal({
                title: 'Are you sure want to reject?',
                text: 'You won\'t be able to revert this!',
                input: 'text',
                 cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if(result.dismiss){

                }else if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/rejectData',id+'==='+result.value
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }else{
                     this.$swal({
                          title: 'Please add something in textbox.',                          
                        }).then(result => {
                            this.rejectData(id);
                            return false;
                        })
                }
            })
        },
        approveData(id) {
            this.$swal({
                title: 'Are you sure want to approve?',
                text: 'You won\'t be able to revert this!',
                type: 'success',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/approveData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }

    }
}
</script>


<style scoped>

</style>
