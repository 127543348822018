<template>
    <div class="btn-group btn-group-xs">
        <button
                v-b-modal.modalShowHoldHistory
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles'"
                @click="openHoldHistoryShowPopup(row.id,'view')"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module,
        }
    },
    watch: {  
        
    },
    created() {

    },
    attachStore (namespace) {
        
    },
    methods: {
        ...mapActions(['BatchSingle']),
        openHoldHistoryShowPopup(id,page){
            this.$emit('holdHistoryIdModel1',id, page)
            this.$emit('loadItems',id, 0)
            this.$emit('modalClose');
        },
    }
}
</script>