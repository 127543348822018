import store from '../store'

export default function authHeader() {
    const user = store.state.AuthIndex.user;
  
    if (user && user.access_token) {
      // for Node.js Express back-end
      return { Authorization: 'Bearer ' + user.access_token };
    } else {
      return {};
    }
  }