<template>
  <div class="popup-form add-country">
    <b-btn v-b-modal.modalPrevent class="add-button">+</b-btn>
    <!-- Main UI -->
    <!-- <div class="mt-3 mb-3">
      Submitted Names:
      <ul>
        <li v-for="n in names">{{n}}</li>
      </ul>
    </div> -->
    <!-- Modal Component -->
    <b-modal id="modalPrevent"
             ref="modal"
             title="Country name"
             @ok="handleOk"
             @shown="clear">
      <form @submit.stop.prevent="handleSubmit">
        <b-form-input type="text"
                      placeholder="Enter country name"
                      v-model="name"></b-form-input>
        <span class="error" v-if="isErrorsCountry">{{errorsCountryMessage}}
                                                </span>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      name: '',
      // names: [],
      isErrorsCountry: false,
      errorsCountryMessage: '',
    }
  },
  computed: {
        ...mapGetters('StatesSingle', ['item', 'loading', 'countryAll']),
       
    },
  methods: {
     ...mapActions('StatesSingle', ['fetchCountryAll','setCountryId']),
    clear () {
      this.name = ''
    },
    handleOk (evt) {
      // Prevent modal from closing
      evt.preventDefault()
      if (!this.name) {
        this.isErrorsCountry = true;
        this.errorsCountryMessage = 'Country name field is required.'
      } else {
        this.handleSubmit()
      }
    },
    handleSubmit () {
      let params = new FormData();
      params.set('country_name', this.name);
      axios.post('/api/v1/country', params)
      .then(response => {
        if(response.data.error_message)
        {
            this.isErrorsCountry = true;
            this.errorsCountryMessage = response.data.message;
            return '';
        }
        else {
            this.isErrorsCountry = false;
            this.errorsCountryMessage = '';
            this.setCountryId(response.data.data);    
            this.fetchCountryAll();
            this.clear()
            this.$refs.modal.hide()
        }

        
       })
      
    }
  }
}
</script>