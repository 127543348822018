<template> 
    <div class="popup-form">
        <b-modal id="modalPreventAddQuiz"
             ref="modal"
             title="Add Quiz"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="openModel" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
             <QuizCreate :project_id="project_id" @loadQuestionList="loadQuestionList" @closeModels="handleCancel"></QuizCreate>
        </b-modal>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import QuizCreate from './QuizCreate.vue';
/* import BlankComponent from './BlankComponent' */
export default {
    data() {
        return {
            // Code...
        }
    },
    components: {
        QuizCreate,
    },
    props : ['project_id','topic_id'],
    created() {
       
    },
    methods: {
        ...mapActions('QuizSingle', ['fetchQuizTopicDetails']),
        handleCancel(evt){
            this.makeEmptyModel();
            this.$refs.modal.hide();
            this.$emit('closeModels');
        },  
        makeEmptyModel(){
          
        },  
        handleOk (evt) {
            evt.preventDefault();
        },
        loadQuestionList(){
            this.$emit('loadQuestionList');
        },
        openModel () {
            //console.log("fdf",this.topic_id);
            if(this.topic_id != ''){
                this.fetchQuizTopicDetails(this.topic_id)
            }
        },
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 90%;
}

.matchBorder{
   box-shadow: 3px 8px 6px -6px #00000045;
   margin-bottom: 10px;
}
.col-md-5 {
    flex: 0 0 48.666667%;
    max-width: 48.666667%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>