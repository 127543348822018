<template>
    <div class="btn-group btn-group-xs">
        <button
                v-if="xprops.route === 'leave_requests' && row.leave_status == 1"
                @click="cancelData(row.id)"
                type="button"
                title="Cancel"
                class="btn  btn-info edit_delete_btn">
            <i class="fa fa-close" aria-hidden="true"></i>
        </button>

        <b-btn v-b-modal.showFaqModel @click="passShowId(row.id)"  v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' "
                title="View" type="button" variant="success" class="btn btn-success edit_delete_btn">
            <i class="fa fa-fw fa-eye"></i>
        </b-btn> 

         <b-btn v-b-modal.editCourseFaqModel @click="passEditId(row.id)"   v-if="$can(xprops.permission_prefix + 'edit') && xprops.route !== 'leave_requests' && row.status != 2"
                title="Edit" type="button" variant="primary" class="btn btn-primary edit_delete_btn">
            <i class="fa fa-fw fa-pencil"></i>
        </b-btn> 

        <button
                v-if="$can(xprops.permission_prefix + 'delete') && xprops.route !== 'leave_requests' && row.status != 2"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn  btn-danger edit_delete_btn">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        passShowId(id){
            this.$emit('showIdModel',id) 
        },
        passEditId(id){
            this.$emit('editIdModel',id) 
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                        //console.log('then')
                        this.$eventHub.$emit('delete-success')
                        //This code Refreshes the page - DENISH
                        //setTimeout(function () { this.$router.go() }.bind(this), 1000) 
                            this.$emit('loadItems') 
                        }else{
                            this.$eventHub.$emit('common',2,response.data.message)
                        }                     
                    })
                    .catch(error => {
                        //console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })


                     // this.storeData()
                     //    .then(() => {
                     //        this.$router.push({ name: 'receipts.index' })
                     //        this.$eventHub.$emit('create-success')
                     //    })
                     //    .catch((error) => {
                     //        console.error(error)
                     //    })
                }
            })


            // .then(result => {
            //     if (result.value) {
            //         this.$store.dispatch(
            //             this.xprops.module + '/destroyData',
            //             id
            //         ).then(result => {
            //             this.$eventHub.$emit('delete-success')
            //         })
            //     }
            // })
        },
        cancelData(id) {
            this.$swal({
                title: 'Are you sure want to cancel?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/cancelData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }

    }
}
</script>


<style scoped>

</style>
