<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Students</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Students List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>


        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Students List</h3>
                    </div>
                    <div class="card-body">
                    <div class="btn-group mb-3">
                            <!-- <router-link
                                    v-if="$can(xprops.permission_prefix + 'create')"
                                    :to="{ name: xprops.route + '.create' }"
                                    class="btn btn-success btn-sm"
                                    >
                                <i class="fa fa-plus"></i> Add New
                            </router-link> -->
                            <b-btn  v-if="$can(xprops.permission_prefix + 'create')"
                                 v-b-modal.addStudent title="Add Student" id="add_student" type="button" variant="success" class="btn btn-success btn-sm">
                                <i class="fa fa-plus"></i> Add New
                            </b-btn> 

                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>                     
                        </div>

                        <button type="button" class="btn btn-info float-right" @click="showFliterByDate">Filter By Date</button>        

                        <div class="row mb-3 align-items-end" v-show="filterByDate" style="justify-content: flex-end;">
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">Admission From Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="from_date" placeholder="Select from date" v-model="date_filter.from_date" autocomplete="off"></date-picker>
                                <span class="error" style="position: absolute;" v-if="dateSearchError != ''">
                                    {{dateSearchError}}
                                </span>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <label for="to_date">Admission To Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="to_date" placeholder="Select to date" v-model="date_filter.to_date" autocomplete="off"></date-picker>
                            </div>
                            <div class="col-md-1 col-sm-1">
                                <button type="button" class="btn btn-success mt-30 float-right" @click="searchDataDateWise()">Search</button>
                            </div>
                            
                        </div>
                        <div class="clearfix"></div>
                         <div class="row mb-3">
                            <div class="col-md-12">
                                <div class="form-group col-md-3 pl-0">
                                    <input type="text" class="form-control" name="search" @keyup="searchFunction" v-model="searchValue" placeholder="Search">
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" id="customCheckbox1" @change="searchFunction" v-model="isGlobal" value="1">
                                    <label for="customCheckbox1" class="custom-control-label">Global Search</label>
                                </div>
                            </div>
                            
                        </div>
                        <bootstrap-alert />
                        <StudentAddCoursePopup @setBStudentId="setBStudentId" @setStudentCourse="setStudentCourse" @setStudentId='setStudentId' :studId="studentId" :id="addId" :studentCourse='studentCourse'></StudentAddCoursePopup>
                        <AddBatch @setPStudentId="setPStudentId" @setBStudentId="setBStudentId"  :bstudentId="bstudentId" :isAddBatch="isAddBatch" student_id="" ></AddBatch>
                        <AddReceipt @setPStudentId="setPStudentId" :isAddReceipt="isAddReceipt" :isOpen="isOpen" :pstudentId="pstudentId" student_id=""></AddReceipt>
                        <StudentAddPopup :inquiryId="inquiryId" @setStudentCourse="setStudentCourse" @setStudentId="setStudentId" @loadItems="loadItems"></StudentAddPopup>
                        <StudentEditPopup :id="editId" @loadItems="loadItems"></StudentEditPopup>
                        <div class="table-responsive setting-group">
                            <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                                >
                                   <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'profile'">
                                        <DatatableProfileField :row="props.row"></DatatableProfileField>
                                    </span>
                                    <span v-else-if="props.column.field == 'actions'">
                                        <StudentActions @editIdModel="editIdModel" @addIdModel="addIdModel" @loadItems="loadItems" :xprops="xprops" :row="props.row"></StudentActions>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <AddCourse></AddCourse> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StudentActions from '../../dtmodules/DatatableActions/StudentActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import Gender from '../../dtmodules/Gender'
import DatatableProfileField from '../../dtmodules/DatatableProfileField'
import CustomLoader from '@/components/CustomLoader'
import StudentAddCoursePopup from '@/components/cruds/Student/StudentAddCoursePopup'
import StudentAddPopup from '@/components/cruds/Student/StudentAddPopup'
import StudentEditPopup from '@/components/cruds/Student/StudentEditPopup'
import AddBatch from '@/components/cruds/Batch/Create'
import AddReceipt from '@/components/cruds/Receipts/AddReceipt'

import components from '../../comps/'
import store from '../../../store'
// import AddCourse from './AddCourse'

StudentActions.attachStore('StudentIndex');
export default {
    components,
      components: {
        StudentActions,
        CustomLoader,
        StudentAddCoursePopup,
        DatatableProfileField,
        StudentAddPopup,
        StudentEditPopup,
        AddBatch,
        AddReceipt
        
        // AddCourse
    },
    data() {
        return {
            branch_id:(store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0,
            inquiryId:'',
            studentId:'',
            studentCourse:'',
            bstudentId:'',
            pstudentId:'',
            isAddBatch:true,
            isAddReceipt:false,
            isOpen:false,
            editId:'',
            dateSearchError:'',
            filterByDate:false,
            addId:'',
            searchValue:'',
            isGlobal:'',
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Profile",
                    field: "profile",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: false, // enable filter for this column
                        placeholder: 'Profile', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Admission Date",
                    field: "enroll_date1",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Admission Date', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Student Name",
                    field: "student_name",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Student Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Gender",
                    field: "inqGender",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Gender', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },              
                {
                    label: "Mother Name",
                    field: "mother_name",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Mother Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Mother Mobile",
                    field: "other_mobile_no",
                    filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Mother Mobile', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: "Actions",
                field: "actions",
                sortable: false
                }

            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                domainName: window.location.origin,
                module: 'StudentIndex',
                route: 'student',
                permission_prefix: 'student_'
            },
            date_filter : {from_date : '', to_date: ''}
        }
    },
    created() {
        if(this.branch_id == 1){
            this.$router.push({name:'admin_dashboard.show'});
        }
        this.$bvModal.show('addStudentCoursemodal')
        //console.log(":sds",this.$refs['addStudentCoursemodal'])
        this.$root.relationships = this.relationships
        if(this.$route.params.inquiry_id){
            this.inquiryId = atob(this.$route.params.inquiry_id);
        }
    },
    // destroyed() {
    //     this.resetState()
    // },
    watch:{
        studentInquiryId(value){
            console.log("test",value);
        },
        branch_id: function() { 
        return (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0;
        },
    },
    computed: {
        ...mapGetters('Rules', ['parentLoggedIn']),
        ...mapGetters('StudentIndex', ['studentInquiryId','data', 'total', 'loading', 'relationships','excelLoader','universalFlag','excelUpdateDetails']),
    },
    methods: {
        ...mapActions('StudentIndex', ['fetchData', 'setQuery', 'setExcelFile','importingExcelStore','exportingExcelStore','resetState','deleteTempExcelData','setUniversalFlag','exportStudentListStore','fetchExcelUpdateDetails','setExcelUpdateDetails','exportReferenceDataListStore']),
         setStudentId(id){
            this.studentId = id
         },
         setStudentCourse(course){
            this.studentCourse = course
         },
         setBStudentId(id){
            console.log("sytrr",id)
            this.bstudentId = id
         },
          setPStudentId(id){
            console.log("pdsd",id)
            this.pstudentId = id;
         },
         editIdModel(id){
           // console.log("e id",id);
            this.editId = id;
        },
        showFliterByDate(){
            this.filterByDate = !this.filterByDate;
        },
        addIdModel(id){
            this.addId = id;
        },
        searchDataDateWise(){
            var start_date = moment(this.date_filter.from_date, "DD/MM/YYYY").toDate();
            var end_date = moment(this.date_filter.to_date, "DD/MM/YYYY").toDate();
            
            if(this.date_filter.from_date == '' || this.date_filter.to_date == ''){
                this.dateSearchError = 'Please select From date and Todate.';
                return false;
            }
            if(end_date < start_date){

                this.dateSearchError = 'To Date greater than or equal to From Date.';
                return false;
            }

            if(start_date > end_date){
                this.dateSearchError = 'From Date less than or equal to To Date.';
                return false;
            }
            this.loadItems();
        },
        searchFunction(){
            //console.log("sdsd",this.searchValue);
            this.loadItems();
        },
        searchStudentData() {
            if (this.date_filter.from_date) {
                this.date_filter.from_date = this.date_filter.from_date.replace(/\//g, '-');    
            }
            if (this.date_filter.to_date) {
                this.date_filter.to_date = this.date_filter.to_date.replace(/\//g, '-');
            }
            this.fetchData(this.date_filter);
        },
         updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        this.dateSearchError = '';
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        // let search = this.serverParams.searchTerm
        //     ? this.serverParams.searchTerm
        //     : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axios
            .get("/api/v1/student", {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: this.searchValue,
                isGlobal: this.isGlobal,
                order: order,
                field: field,
                from_date: this.date_filter.from_date,
                to_date:this.date_filter.to_date,
                columnFilters:this.serverParams.columnFilters,
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
        resetFilters(){
            this.isGlobal = '';
            this.searchValue = '';
            this.date_filter.from_date='';
            this.date_filter.to_date = '';
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>


<style scoped>
.m-t-25 {
    margin-top: 25px
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.upper-background {
    border: 1px solid #008b8b;
    background: #fffafa;
    z-index: 10021 !important;
}
ul li {
    margin-left: 10px;
    margin-right: 10px;    
}
.dropdown-buttons {
    width: 150px;
}
</style>
