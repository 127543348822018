<template>
    <div class="btn-group btn-group-xs">
        <button
                v-if="$can('student_schedule_listing_delete') && row.student_attendance_id && this.row.is_last_attendance == 1 && row.course_status != 4 && !parentLoggedIn.parentLoggedIn"
                @click="destroyDataaaa(row.student_attendance_id)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
        <div id="loader_placeholder" v-show="processing">
            <div id="loadingDiv">
                <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
            </div>
        </div>         
   </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module,
            processing: false
        }
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData','getCountForCalendar']))
    },
    computed: {
        ...mapGetters('Rules', ['parentLoggedIn'])
    },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                	this.processing = true;
                    this.destroyData(id)
                    .then(() => {
                        this.$eventHub.$emit('delete-success')
                        this.getCountForCalendar()
                    })
                    .catch(error => {
                        this.$eventHub.$emit('action-error')
                    })
		            .finally(() => {
		                this.processing = false
		            })
                }
            })
        },        
    }
}
</script>
<style scoped>
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
</style>
