<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Admin Sliders</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/admin-slider'">Admin Sliders</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Admin Slider</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Admin Slider</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row text-align-center" style="display: contents;">
                                    <div v-if="item.image_url != null">
                                        <img :src="item.image_url" class="slider-img my-image slider_image img-responsive mb-3" :alt="item.name"> 
                                    </div>
                                    <div v-else>
                                        <img src="/assets/img/male.jpg" class="slider-img my-image slider_image img-responsive mb-3" :alt="item.name">
                                    </div>
                                </div>
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>User</th>
                                            <td>{{ item.name ? item.name : '-' }}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{{ item.city ? item.city_name : '-'}}</td>
                                            </tr>
                                        <tr>
                                            <th>Minimum Age</th>
                                            <td>{{ item.min_age ? item.min_age : '-'}}</td>
                                            </tr>
                                        <tr>
                                            <th>Maximum Age</th>
                                            <td>{{ item.max_age ? item.max_age : '-'}}</td>
                                        </tr>
                                        <tr>
                                            <th>URL</th>
                                            <td>{{ item.url ? item.url : '-'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
        }
    },
    created() {
        this.fetchEditData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('AdminSliderSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchEditData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('AdminSliderSingle', ['fetchEditData', 'resetState'])
    }
}
</script>

<style scoped>
.my-multipleCategory{
	margin: 0 6px 0 0;
}
.slider_image {
    position: relative;
    width: 1666px !important;
    height: 300px !important;
    object-fit: cover;
    margin: 25px 0px;
}
.slider-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    text-align: center;
    margin: 30px;
}
.img-responsive{
    max-width: 100%;
    height: auto;
}
</style>
