<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Edit Stock</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Stock Item</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="category_id" class="form-group">
                                                <label for="type_">Category<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="category_id"
                                                        label="name"
                                                        id="category_id"
                                                        v-validate="'required'"
                                                        @input="updateCategory"
                                                        :value="item.item_category_id"
                                                        :options="categoriesAll"
                                                        autocomplete="off"
                                                        disabled
                                                        />
                                                <span class="error" v-show="errors.has('category_id')">
                                                    {{ $root.updateErrorMessage('category_id',this.errors, 'Category')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="item_id" class="form-group">
                                                <label for="type_">Item<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="item_id"
                                                        label="name"
                                                        id="item_id"
                                                        v-validate="'required'"
                                                        @input="updateItem"
                                                        :value="item.item_id"
                                                        :options="itemsAll"
                                                        autocomplete="off"
                                                        disabled
                                                        />
                                                <span class="error" v-show="errors.has('item_id')">
                                                    {{ $root.updateErrorMessage('item_id',this.errors, 'Item')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="purchase_date" class="form-group">
                                                <label for="purchase_date">Added Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    v-model= "item.purchase_date"
                                                    :config="$root.dpconfigDate"
                                                    name="purchase_date"
                                                    placeholder="Select Added Date"
                                                    v-validate="'required'"
                                                    autocomplete="off"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('purchase_date')">
                                                    {{ $root.updateErrorMessage('purchase_date',this.errors,'Added date')}}
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="quantity">Quantity<p class="astrisk">*</p></label>
                                                <!--<label for="balance" class="align-right">Balance : {{balance}}</label>-->
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="quantity"
                                                        name="quantity"
                                                        id="quantity"
                                                        maxlength="17"
                                                        v-validate="'required|min_value:'+ getMin() +''"
                                                        placeholder="Enter Quantity"
                                                        v-model="item.quantity"
                                                        oninput="this.value=this.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/\./g, '');"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('quantity')">
                                                          {{ validateErrors(this.errors) ? validateErrors(this.errors) : $root.updateErrorMessage('quantity',this.errors)}}
                                                        </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="remarks">Remarks</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="remarks"
                                                        name="remarks"
                                                        id="remarks"
                                                        v-validate="'max:255'"
                                                        placeholder="Enter Remarks"
                                                        v-model="item.remarks"
                                                        autocomplete="off"
                                                    ></textarea>
                                                        <span class="error" v-show="errors.has('remarks')">
                                                          {{ $root.updateErrorMessage('remarks',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="file">File (Allowed files : jpg,png,jpeg,pdf,csv,ods,xls, xlsx,odt,txt,docx,doc) </label>
                                                <input
                                                        type="file"
                                                        name="file"
                                                        ref="file"
                                                        id="file"
                                                        v-validate="'size:2048|ext:jpg,png,jpeg,pdf,csv,ods,xls,xlsx,odt,txt,docx,doc'"
                                                        class="form-control"
                                                        @change="updateFile"
                                                        v-if="showFileUpload"
                                                    >
                                            <span class="error" v-show="errors.has('file')">
                                              {{ $root.updateErrorMessage('file',this.errors,'file')}}
                                            </span>
                                                <ul v-if="showRemoveFile" class="list-unstyled">
                                                  <li>
                                                 
                                                   <a :href="item.file" class="btn btn-xs btn-primary" target="_blank">View File</a>
                                                  
                                                    <button class="btn btn-xs btn-danger"
                                                            type="button"
                                                            @click="removeFile"
                                                    >
                                                        Remove file
                                                    </button>
                                                  </li>
                                              </ul>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

  export default {
    data() {

        return {
          deleted : 0,
          balance: 0,
          showFileUpload: 1,
          showRemoveFile: 0,
        }
    },
    computed: {
        ...mapGetters('InventoryItemPurchaseSingle', ['item', 'loading', 'categoriesAll', 'itemsAll'])
    },
    created() {
        this.fetchCategoriesAll()
        this.fetchData(this.$route.params.id)
        var self = this;
//        setTimeout(function(){ self.item.balance = 5 },2000)
        
    },
    destroyed() {
        this.resetState()
    },
    watch:{
      'item.quantity':function(value,old){
//        console.log('value',value,'old',old);
          value = value ? value : 0;
          old = old === null ? value : (old ? old : 0);
          this.balance = parseInt(this.balance) + parseInt(value) - parseInt(old);
      },
      'item.balance':function(value,old){
          this.balance = value;
      },
      'item.file':function(val){
        if(val && typeof val ==='string'){
          this.showFileUpload = 0
          this.showRemoveFile = 1
        }
      },
    },
    methods: {
        ...mapActions('InventoryItemPurchaseSingle', ['updateData', 'fetchData', 'setItemCategoryId', 'setItemId', 'setItemsAll','setFile', 'setPurchaseDetails' , 'fetchCategoriesAll', 'fetchItemsAll','resetState']),
        updateCategory(e) {
            this.setItemCategoryId(e)            
            // this.setItemId(null)
            this.setItemsAll([])
            if(e){
              this.fetchItemsAll(e.value_id);
            }
        },
        updateItem(e) {
            this.setItemId(e)
        },
        updateFile(e) {
          if(e && e.target && e.target.files && e.target.files[0]){
              this.setFile(e.target.files[0]);
              this.showFileUpload = 1
              this.showRemoveFile = 0
          }else{
            this.setFile(null);
          } 
            
        },
        removeFile(){
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setFile('');
                     $("#file").val('');
                     this.deleted = 1;
                     this.showFileUpload = 1
                     this.showRemoveFile = 0
                }
            })
        },
        getMin(){
          if(this.item.balance > this.item.quantity_to_check){
//              return (this.item.balance - this.item.quantity_to_check)
//              return this.item.quantity_to_check
              return 1
          }else{
            let diff = this.item.quantity_to_check - this.item.balance;
            return diff ? diff : 1 
          }
        },
        validateErrors(errors){
          if(errors.has('quantity')){
            if(errors.firstByRule('quantity','min_value')){
              let msg = this.$root.updateErrorMessage('quantity',this.errors);
              msg = `${msg} Because some stock already issued.`
              return msg
            }
          }
          return 0;
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var itemDetails = {
//                     item_category_id: this.item.item_category_id.value_id,
//                     item_id: this.item.item_id.id,
                     purchase_date: this.item.purchase_date,
                     quantity: this.item.quantity,
                     remarks: this.item.remarks,
                     file: this.item.file,
                     file_deleted: this.deleted,
                 };
                this.setPurchaseDetails(itemDetails);
                this.updateData()
                     .then((res) => {
                        if(res.data.error_custome)
                            {
                                this.$store.dispatch(
                                'Alert/setAlert',
                                { message: res.data.error_custome, errors: false, color: 'danger' },
                                { root: true })
                                return '';    
                            }        
                        this.$router.push({ name: 'inventory_item_purchase.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        
    }
}
</script>


<style scoped>
  .align-right{
      float: right;
  }
</style>
