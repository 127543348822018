<template>
        <form @submit.stop.prevent="submitForm" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div ref="lab_name" class="form-group">
                                <label for="lab_name">Lab<p class="astrisk">*</p></label>
                                <v-select
                                    name="lab_name" 
                                    label="lab_name"
                                    id="course"
                                    v-validate="'required'"
                                    v-model="branch_lab"
                                    :options="courseLabs"
                                    placeholder="Select Lab"
                                />
                                <span class="error" v-show="errors.has('lab_name')">
                                    {{ $root.updateErrorMessage('lab_name',this.errors, 'Lab')}}
                                </span>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1">
            <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
            <button type="submit" class="btn btn-success">Save</button>
        </footer>
        <template slot="loadingContent">
            <!-- <CustomLoader></CustomLoader> -->
        </template>
    </form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props:['courseId'],
    data() {
        return {
            branch_lab: '',
        };
    },
    created(){
        this.fetchBranchLabData(this.courseId);
    },
    computed: {
        ...mapGetters('CoursesIndex', ['courselab','courseLabs','loading']),
    },
    watch:{
        courselab(value){
            this.branch_lab = '';
            if(value != null && value != ''){
                this.branch_lab = value;
            }
        }
    },
    methods: {
        ...mapActions('CoursesIndex', ['fetchBranchLabData','storeCourseLab']),
        closeModel(){
            this.$emit('closeModel');
        },
        submitForm(){
            this.$validator.validate().then(result => {
                if (result) {                  
                    this.storeCourseLab({'course_id':this.courseId,'lab_id':this.branch_lab})
                    .then((res) => {
                        if(!res.data.result)
                        {
                            if(res.data.data == ''){
                                 this.$eventHub.$emit('common',3,res.data.message);
                                 return false;
                            }
                            else{
                                for (const [key, value] of Object.entries(res.data.data)) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                           
                        }
                            return this.$root.handleValidationFocus(this.errors,this.$refs);
                        }  
                        this.$emit('closeModel');
                        this.$emit('loadItems');
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                         this.$eventHub.$emit('common',3,'Something went wrong.')
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
            });
        }
    }
}
</script>
<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>


