function initialState() {
    return {
        item: {
            branch_id:null,
            from_date:null,
            to_date:null,
            report_type:2,
        },
        queryPresentData: {},
        queryAbsentData: {},
        queryAssessmentDoneData: {},
        queryAssessmentData: {},
        queryAttendanceData: {},
        queryPreviousDue: {},
        branchMastersAll: [],
        branchAll: [],    
        presentData: [],
        absentData: [],
        assessmentDoneData: [],
        attendanceNotDoneData: [],
        assessmentDueData: [],
        previousDueData: [],        
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,
    data: state => {
        let rows = state.presentData

        if (state.queryPresentData.sort) {
            rows = _.orderBy(state.presentData, state.queryPresentData.sort, state.queryPresentData.order)
        }
        var queryFilter = JSON.stringify(state.queryPresentData);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryPresentData.offset, state.queryPresentData.offset + state.queryPresentData.limit)
    },
    total: state => {
        let rows = state.presentData

        if (state.queryPresentData.sort) {
            rows = _.orderBy(state.presentData, state.queryPresentData.sort, state.queryPresentData.order)
        }
        var queryFilter = JSON.stringify(state.queryPresentData);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    absentDataTableData: state => {
        let rows = state.absentData

        if (state.queryAbsentData.sort) {
            rows = _.orderBy(state.absentData, state.queryAbsentData.sort, state.queryAbsentData.order)
        }
        var queryFilter = JSON.stringify(state.queryAbsentData);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryAbsentData.offset, state.queryAbsentData.offset + state.queryAbsentData.limit)
    },
    absentTotal: state => {
        let rows = state.absentData

        if (state.queryAbsentData.sort) {
            rows = _.orderBy(state.absentData, state.queryAbsentData.sort, state.queryAbsentData.order)
        }
        var queryFilter = JSON.stringify(state.queryAbsentData);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    }, 
    assessmentDoneDataTableData: state => {
        let rows = state.assessmentDoneData

        if (state.queryAssessmentDoneData.sort) {
            rows = _.orderBy(state.assessmentDoneData, state.queryAssessmentDoneData.sort, state.queryAssessmentDoneData.order)
        }
        var queryFilter = JSON.stringify(state.queryAssessmentDoneData);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryAssessmentDoneData.offset, state.queryAssessmentDoneData.offset + state.queryAssessmentDoneData.limit)
    },
    assessmentDoneTotal: state => {
        let rows = state.assessmentDoneData

        if (state.queryAssessmentDoneData.sort) {
            rows = _.orderBy(state.assessmentDoneData, state.queryAssessmentDoneData.sort, state.queryAssessmentDoneData.order)
        }
        var queryFilter = JSON.stringify(state.queryAssessmentDoneData);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },     
    attendanceNotDoneDatatableData: state => {
        let rows = state.attendanceNotDoneData

        if (state.queryAttendanceData.sort) {
            rows = _.orderBy(state.attendanceNotDoneData, state.queryAttendanceData.sort, state.queryAttendanceData.order)
        }
        var queryFilter = JSON.stringify(state.queryAttendanceData);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryAttendanceData.offset, state.queryAttendanceData.offset + state.queryAttendanceData.limit)
    },
    attendanceNotDoneTotal: state => {
        let rows = state.attendanceNotDoneData

        if (state.queryAttendanceData.sort) {
            rows = _.orderBy(state.attendanceNotDoneData, state.queryAttendanceData.sort, state.queryAttendanceData.order)
        }
        var queryFilter = JSON.stringify(state.queryAttendanceData);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    }, 
    assessementDueDatatableData: state => {
        let rows = state.assessmentDueData

        if (state.queryAssessmentData.sort) {
            rows = _.orderBy(state.assessmentDueData, state.queryAssessmentData.sort, state.queryAssessmentData.order)
        }
        var queryFilter = JSON.stringify(state.queryAssessmentData);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryAssessmentData.offset, state.queryAssessmentData.offset + state.queryAssessmentData.limit)
    },
    assessementDueTotal: state => {
        let rows = state.assessmentDueData

        if (state.queryAssessmentData.sort) {
            rows = _.orderBy(state.assessmentDueData, state.queryAssessmentData.sort, state.queryAssessmentData.order)
        }
        var queryFilter = JSON.stringify(state.queryAssessmentData);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },   
    previousDueDatatableData: state => {
        let rows = state.previousDueData

        if (state.queryPreviousDue.sort) {
            rows = _.orderBy(state.previousDueData, state.queryPreviousDue.sort, state.queryPreviousDue.order)
        }
        var queryFilter = JSON.stringify(state.queryPreviousDue);
        var queryFilter = JSON.parse(queryFilter);
        try {

          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
          }catch(err){
            console.log(err)
          } 
        return rows.slice(state.queryPreviousDue.offset, state.queryPreviousDue.offset + state.queryPreviousDue.limit)
    },
    previousDueTotal: state => {
        let rows = state.previousDueData

        if (state.queryPreviousDue.sort) {
            rows = _.orderBy(state.previousDueData, state.queryPreviousDue.sort, state.queryPreviousDue.order)
        }
        var queryFilter = JSON.stringify(state.queryPreviousDue);
        var queryFilter = JSON.parse(queryFilter);
          ['srno','student_name','course_name','batch_name','lab_name','original_date','original_format_date','attendance','topic_name','display_topic_status','def_trainer_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },                
    loading: state => state.loading,
    branchMastersAll: state => state.branchMastersAll,
    presentData: state => state.presentData,
    absentData: state => state.absentData,
    attendanceNotDoneData: state => state.attendanceNotDoneData,
    assessmentDueData: state => state.assessmentDueData,
    previousDueData: state => state.previousDueData,
    error: state => state.error
}

const actions = {
    fetchDailyDigestTrainingReportData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/dailyDigestTrainingReport', {params:params})
                .then(response => {       
                    commit('setPresentData', response.data.data['presentData'])
                    commit('setAbsentData', response.data.data['absentData'])
                    commit('setAssessmentDoneData', response.data.data['assessmentDoneData'])
                    commit('setAttendanceNotDoneData', response.data.data['attendanceNotDoneData'])
                    commit('setAssessmentDueData', response.data.data['assessmentDueData'])
                    commit('setPreviousDueData', response.data.data['previousDueData'])
                    resolve()
                })
            .catch(function (error) {
                console.log('Error', error);
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },    
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })      
    },
    setQueryPresent({ commit }, value) {
        commit('setQueryPresent', purify(value))
    },
    setQueryAbsent({ commit }, value) {
        commit('setQueryAbsent', purify(value))
    },
    setQueryAttendance({ commit }, value) {
        commit('setQueryAttendance', purify(value))
    },
    setQueryAssessmentDone({ commit }, value) {
        commit('setQueryAssessmentDone', purify(value))
    },    
    setQueryAssessment({ commit }, value) {
        commit('setQueryAssessment', purify(value))
    },
    setQueryPrevious({ commit }, value) {
        commit('setQueryPrevious', purify(value))
    },                
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setPresentData({ commit }, value) {
        commit('setPresentData', value)
    },
    setAbsentData({ commit }, value) {
        commit('setAbsentData', value)
    },
    setAssessmentDoneData({ commit }, value) {
        commit('setAssessmentDoneData', value)
    },    
    setAttendanceNotDoneData({ commit }, value) {
        commit('setAttendanceNotDoneData', value)
    },
    setAssessmentDueData({ commit }, value) {
        commit('setAssessmentDueData', value)
    },
    setPreviousDueData({ commit }, value) {
        commit('setPreviousDueData', value)
    },                
    setFromDate({ commit }, value) {
        commit('setFromDate', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },    
    setReportType({ commit }, value) {
        commit('setReportType', value)
    }, 
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {  
    setLoading(state, loading) {
        state.loading = loading
    },
    setAdmissionLoading(state, loading) {
        state.admissionLoading = loading
    },
    setCourseEnrollLoading(state, loading) {
        state.courseEnrollLoading = loading
    },
    setCourseCompleteLoading(state, loading) {
        state.courseCompleteLoading = loading
    },
    setFeeCollectionLoading(state, loading) {
        state.feeCollectionLoading = loading
    },
    setQueryPresent(state, query) {
        state.queryPresentData = query
    },
    setQueryAbsent(state, query) {
        state.queryAbsentData = query
    },    
    setQueryAttendance(state, query) {
        state.queryAttendanceData = query
    },
    setQueryAssessmentDone(state, query) {
        state.queryAssessmentDoneData = query
    },    
    setQueryAssessment(state, query) {
        state.queryAssessmentData = query
    },
    setQueryPrevious(state, query) {
        state.queryPreviousDue = query
    },                
    setError(state, error) {
        state.error = error
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },  
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setFromDate(state, value) {
        state.item.from_date = value
    },
    setExit_date(state, value) {
        state.item.to_date = value
    },
    setReportType(state, value) {
        state.item.report_type = value
    },         
    setPresentData(state, value) {
        state.presentData = value
    },
    setAbsentData(state, value) {
        state.absentData = value
    },
    setAssessmentDoneData(state, value) {
        state.assessmentDoneData = value
    },    
    setAttendanceNotDoneData(state, value) {
        state.attendanceNotDoneData = value
    },
    setAssessmentDueData(state, value) {
        state.assessmentDueData = value
    },
    setPreviousDueData(state, value) {
        state.previousDueData = value
    },   
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
