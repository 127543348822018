<template>
  <div>
      <label for="date_range"> {{ title ? title : 'Select Date Range ' }}</label>
      <div class="form-group date-range-css">
        <date-range-picker 
            v-model="dateRange" 
            @update="updateRange"
            :locale-data="locale"
            :opens="opens"  
            :autoApply="true"
            :ranges="ranges"
            
        >

        </date-range-picker>
      </div>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import './vue-daterange-picker.min.css'
export default {
  components: {
    DateRangePicker,
  },
  props: ['title','event'],
  data() {
    return {
            dateRange: { // used for v-model prop
                startDate: moment(),
                endDate: moment(),
            },
            opens: "center",//which way the picker opens, default "center", can be "left"/"right"
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD/MM/YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1, //ISO first day of week - see moment documenations for details
                showWeekNumbers: true //show week numbers on each row of the calendar
            },
            ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Current week': [moment().startOf('week'), moment().endOf('week')],
                'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'This month': [moment().startOf('month'), moment().endOf('month')],
                'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This year': [moment().startOf('year'), moment().endOf('year')],
                'Last year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
            },
    }
  },
  methods: {
    updateRange(e) {
      if(this.event){
        this.$emit(this.event,e)
      }else{
        this.$emit('updateRange',e)
      }
        
    },  
  },
}
</script>
<style>

.date-range-css .row{
  margin-left: 0px;
}
.date-range-css .reportrange-text{
  border-radius: 4px;
}
</style>
