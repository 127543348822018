<template>
    <div class="login-box mt-5">
        <div class="login-logo">            
        </div>

        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">
                    <a :to="'/'">
                        <img
                            src="/assets/images/logo.svg"
                            width="100%"
                            class="img-responsive"
                        >
                    </a>
                </p>
                <div class="alert alert-success" role="alert" v-if="successMsg">
                    {{ this.successMsg }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="errorMsg">
                    {{ this.errorMsg }}
                </div>
                <form @submit.prevent="handleSubmit" novalidate>
                    <div class="mb-3">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                name="mobile_number"
                                id="mobile_number"
                                placeholder="Mobile Number"
                                v-model="user.mobile_number"
                                v-validate="{ required: true, regex:/^[0-9]*$/ }"
                            >
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                        </div>
                        <span class="error" v-show="errors.has('mobile_number')">
                            {{ $root.updateErrorMessage('mobile_number',this.errors, 'Mobile Number')}}
                        </span>
                    </div>
                    <div class=" mb-3">
                        <div class="input-group">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                class="form-control"
                                placeholder="Password"
                                v-model="user.password"
                                v-validate="{ required: true }"
                            >
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                        </div>
                        <span class="error" v-show="errors.has('password')">
                            {{ $root.updateErrorMessage('password',this.errors, 'Password')}}
                        </span>
                    </div> 
                    <div class="row">
                        <div class="col-8">
                            <div class="icheck-primary">
                                <label>
                                <input
                                    type="checkbox"
                                    id="remember"
                                    for="remember">
                                        Remember Me
                                    </label>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
                                <span class="alert text-center" v-if="loading">
                                <i class="fa fa-spinner fa-sync rotate"></i> Loading...</span>
                                <span v-else>Sign In</span>
                            </button>
                        </div>

                    </div>
                </form>
                <p class="mb-1">
                    <router-link to="/forgot-password">I forgot my password</router-link>
                </p>
            </div>

        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            user: {
                mobile_number: '',
                password: ''
            },
            submitted: false,
            successMsg:'',
            errorMsg:'',
            loading: false,
        }
    },
    methods:{
        ...mapActions('AuthIndex', ['storeUserData', 'getBranchesData']),
        handleSubmit(e) {
            /* Used for login user into admin... */
            this.submitted = true;
            this.$validator.validate().then(valid => {
                let currntObj = this;
                let formData = new FormData();
                    formData.append("mobile_number", this.user.mobile_number);
                    formData.append("password", this.user.password);
                if (valid) {
                    this.loading = true
                    axios.post('/api/v1/login', formData).then(response => {
                        if(response.data.data.access_token){   
                            console.log(response.data.data); 
                            let res = response.data.data;
                            this.storeUserData(res);
                            this.successMsg = response.message;  
                            if(res.fetchIfParent != null){
                                this.$router.push('/parent-home');  
                            }
                            let fetchIfTrainer = res.fetchIfTrainer;
                            //STEM & Robotic Coach and Centre Technical/Trainer Head
                            if(fetchIfTrainer && (fetchIfTrainer.employee_type == 1 || fetchIfTrainer.employee_type == 9)) {
                                //TRAINER DASHBOARD
                                this.$router.push('/trainer-home');                                  
                            } else if(res.result.id == 1 && res.result.api_session_branch_id == 1){
                                //SUPER ADMIN DASHBOARD TO BE DEVELOPED
                                this.$router.push('/super-admin-home');
                            } else {
                                //CENTER HEAD DASHBOARD
                                this.$router.push('/home'); 
                            }
                        }else{
                            this.errorMsg = response.data.message;  
                        }            
                        this.loading = false      
                    }).catch(function (error) {
                        console.log(error);
                        this.errorMsg = 'Oops! Something went wrong. Please try again';
                    });                                       
                }
            });
        }
    }
}
</script>
