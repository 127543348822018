<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Projects</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/projects'">Projects</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Project</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                 <div class="card card-primary card-outline card-outline-tabs">
              <div class="card-header p-0 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Add Project</a>
                  </li>
                  <li class="nav-item" >
                    <a class="nav-link" id="custom-tabs-four-profile-tab"  role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Topic - SubTopic</a>
                  </li>
                   <li class="nav-item" >
                    <a class="nav-link" id="custom-tabs-four-profile-tab"  role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Quiz</a>
                  </li>
                   <li class="nav-item" >
                    <a class="nav-link" id="custom-tabs-four-profile-tab"  role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Task For Tutor</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                  <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                    <ProjectAdd></ProjectAdd>
                  </div>
                  <div class="tab-pane fade" v-if="$can('project_topic_access')" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                    <!-- <ProjectTopics></ProjectTopics> -->
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectAdd from '../../dtmodules/ProjectAdd'
// import ProjectTopics from '../../dtmodules/ProjectTopics'

export default {
    components: {
        ProjectAdd,
        // ProjectTopics
    },
    data() {
        return {
            // Code...
           form_data:{
               project_name:'',
               project_type:[],
               project_brand:[],
               project_kit:[],
               description:''
           }
        }
    },
    computed: {
        ...mapGetters('ProjectsSingle', ['item', 'loading','projectTypes','projectBrands','projectKits'])
    },
    created() {
        this.fetchTypes();
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchTypes','storeData']),
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>