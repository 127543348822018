<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Reminders</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Reminders List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        :to="{ name: 'reminders.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                                      
                            </div>
                        </div>
                        
                        <div class="box-body" >
                          <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <label for="status">Reminder Status</label>
                                  <v-select
                                    name="reminder_status"
                                    label="name"                                           
                                    :value="status"
                                    :options="reminderStatusAll"        
                                    @input="updateReminderStatus"
                                    />                                
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div ref="reminder_to" class="form-group">
                                    <label for="reminder_to">Reminder To</label>
                                    <v-select
                                            name="reminder_to"
                                            label="name"
                                            id="reminder_to"
                                            @input="updateReminderTo"
                                            :value="reminder_to"
                                            :options="remindersToAll"
                                            autocomplete="off"
                                            />
                                    
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <label for="from_date">From Date</label>
                                  <date-picker
                                      v-model= "filterData.from_date"
                                      :config="$root.dpconfigDate"
                                      name="from_date"
                                      placeholder="Select From Reminder Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <label for="to_date">To Date</label>
                                  <date-picker
                                      v-model= "filterData.to_date"
                                      :config="$root.dpconfigDate"
                                      name="to_date"
                                      placeholder="Select To Reminder Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <button type="button" class="btn btn-success m-t-25" @click="searchData()">Search</button>
                            
                            </div>
                          </div>
                          
                        </div>
                      
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import RemindersActions from '../../dtmodules/DatatableActions/RemindersActions'
import components from '../../comps/'

RemindersActions.attachStore('RemindersIndex');
export default {
    components,
    RemindersActions, 
    data() {
        return {
            columns: [
                { title: 'Title', field:'title',  sortable: true, thComp: 'FilterTh' },
                { title: 'Reminder Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                
                // { title: 'Reminder Date', field: 'reminder_date', sortable: true, thComp: 'FilterTh' },
                { title: 'Do Contact ?', field: 'do_contact_text', sortable: true, thComp: 'FilterTh' },
                { title: 'Reminder To', field: 'reminder_to_name', sortable: true, thComp: 'FilterTh' },
                { title: 'Reminder To Name', field: 'reminder_to_id_name', sortable: true, thComp: 'FilterTh' },
                { title: 'Status', field: 'status_text', sortable: true, thComp: 'FilterTh' },
                { title: 'Actions ', tdComp: RemindersActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'RemindersIndex',
                route: 'reminders',
                permission_prefix: 'reminders_'
            },
            filterData: {
              status: null,
              reminder_to: null,
              from_date: null,
              to_date: null,
            },
            status: null,
            reminderStatusAll: [
              {
                value_id: 1,
                name: 'Open'
              },
              {
                value_id: 2,
                name: 'Closed'
              },
            ],
            reminder_to: null,
        }
    },

    created() {
        this.fetchData()
        this.fetchRemindersToAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('RemindersIndex', ['data', 'total', 'loading']),
        ...mapGetters('RemindersSingle', ['remindersToAll']),

    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        
    },
    methods: {
        ...mapActions('RemindersIndex', ['fetchData', 'setQuery', 'resetState']),
        ...mapActions('RemindersSingle', ['fetchRemindersToAll']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['title','original_date','original_format_date','do_contact_text','reminder_to_name','reminder_to_id_name','status_text'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.updateReminderStatus(null)
            this.updateReminderTo(null)
            this.filterData.from_date = null;
            this.filterData.to_date = null;
            this.fetchData()
            
        },
        updateReminderStatus(val){
          this.filterData.status = null
          this.status = null
          if(val){
            this.filterData.status = val.value_id
            this.status = val
          }
        },
        updateReminderTo(val){
          this.reminder_to = null
          this.filterData.reminder_to = null
          if(val){
            this.reminder_to = val
            this.filterData.reminder_to = val.value_id
          }
        },
        searchData(){
          if (this.filterData.from_date) {
            this.filterData.from_date = this.filterData.from_date.replace(/\//g, '-');    
          }
          if (this.filterData.to_date) {
            this.filterData.to_date = this.filterData.to_date.replace(/\//g, '-');
          }
          this.fetchData(this.filterData)
        },
    }
}
</script>


<style scoped>

</style>
