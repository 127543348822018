function initialState() {
    return {
        item: {
            id: null,
            branch_name: null,
            user_name: null,
            role_name: null,
        },
        branchAll: [], 
        userAll: [], 
        roleAll: [], 
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    branchAll: state => state.branchAll,
    userAll: state => state.userAll,
    roleAll: state => state.roleAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('branch_id',state.item.branch_name['id']);
            params.set('user_id',state.item.user_name['user_id']);
            params.set('role_id',state.item.role_name['id']);
            axios.post('/api/v1/multipleBranchAccess', params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })   
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')
            params.set('branch_id',state.item.branch_name['id']);
            params.set('user_id',state.item.user_name['user_id']);
            params.set('role_id',state.item.role_name['id']);
            axios.post('/api/v1/multipleBranchAccess/' + state.item.id, params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        const result = await axios.get('/api/v1/multipleBranchAccess/' + id)
            .then(response => {
                dispatch('fetchRoles', response.data.data.branch_id)
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    commit('setLoading', false)
                    return error;
            })
        dispatch('fetchBranches');
        return result
    },  
    async fetchBranches({ commit }) {
        await axios.get('/api/v1/allBranch')
            .then(response => {                
                commit('setBranchAll', response.data.data)
                return response.data.data
            })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
            }) 
    },      
    async fetchUsers({ commit }) {
        await axios.get('/api/v1/branchUsers')
            .then(response => {                
                commit('setUsersAll', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
            }) 
    },
    async fetchRoles({ commit }, branch_id) {
        await axios.get('/api/v1/branchRoles/'+branch_id)
            .then(response => {                
                commit('setRoleAll', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
            }) 
    },
    setBranchName({ commit }, value) {
        commit('setBranchName', value)
    },
    setUserName({ commit }, value) {
        commit('setUserName', value)
    },
    setRoleName({ commit }, value) {
        commit('setRoleName', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setBranchName(state, value) {
        state.item.branch_name = value
    },
    setUserName(state, value) {
        state.item.user_name = value
    },
    setRoleName(state, value) {
        state.item.role_name = value
    },
    setBranchAll(state, value) {
        state.branchAll = value
    },
    setUsersAll(state, value) {
        state.userAll = value
    },
    setRoleAll(state, value) {
        state.roleAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
