<template>
  <div class="popup-form add-subtopic">
    <b-modal id="modalPreventSubTopic"
             ref="modal"
             title="Add Project Sub Topic"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="clear" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <form @submit.stop.prevent="handleSubmit">
            <div class="container-fluid">  
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-4 col-xs-4">
                                <label for="project_id">Project Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="project_id"
                                        label="project_name"
                                        v-validate="'required'"
                                        @change="updateProjectId"
                                        :value="item.project_id"
                                        :options="projectList"  
                                        disabled                                      
                                        />
                                <span class="error" v-show="errors.has('project_id')">
                                {{ $root.updateErrorMessage('project_id',this.errors, 'Project Name')}}
                                </span> 
                            </div>
                            <div class="col-md-4 col-xs-4">
                                <label for="topic_id">Topic Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="topic_id"
                                        label="topic_name"
                                        v-validate="'required'"
                                        @change="updateTopic"
                                        :value="item.topic"
                                        :options="TopicListing"
                                        disabled
                                        />
                                <span class="error" v-show="errors.has('topic_id')">
                                {{ $root.updateErrorMessage('topic_id',this.errors, 'Topic Name')}}
                                </span> 
                            </div>
                            <div class="col-md-4 col-xs-4">
                                <label ref="subtopic_name" for="subtopic_name">Sub Topic Name<p class="astrisk">*</p></label>
                                <b-form-input 
                                        id="subtopic_name"
                                        type="text"
                                        name="subtopic_name"
                                        maxlength="90"
                                        @input="updateTopicName"
                                        v-validate="'required'"                              
                                        v-model="subtopic_name"
                                        placeholder="Enter SubTopic Name">
                                </b-form-input>
                                <span class="error" v-show="errors.has('subtopic_name')">
                                {{ $root.updateErrorMessage('subtopic_name',this.errors, 'SubTopic Name')}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">     
                                    <label ref="description" for="description">Description<p class="astrisk">*</p></label>
                                    <b-form-textarea 
                                            id="description"
                                            type="textarea"
                                            name="description"
                                            @input="updateDescription"
                                            v-validate="'required'"      
                                            v-model="description"
                                            placeholder="Enter Description">
                                    </b-form-textarea>  
                                    <span class="error" v-show="errors.has('description')">
                                    {{ $root.updateErrorMessage('description',this.errors, 'Description')}}
                                    </span>                   
                                </div>
                            </div>
                        </div>

                        
                        <div class="form-group">
                            <label for="topic_video">Sub-Topic Video</label>
                            <input
                                    type="file"
                                    name="subtopic_video"
                                    id="subtopic_video"
                                    v-validate="'size:500000|ext:mp4,mov,ogg'"
                                    class="form-control"
                                    @change="updateTopicVideo"
                                >
                            <span style="font-size: 13px;"><b>Note :</b> Mp4 files are allowed. Video File size should be less than or equals to 500 MB.</span>
                            <span class="error" v-show="errors.has('subtopic_video')">
                                {{ $root.updateErrorMessage('subtopic_video',this.errors,'SubTopic video')}}
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="subsubtopic_file">Sub-Topic File</label>
                            <input
                                    type="file"
                                    name="subtopic_file"
                                    id="subtopic_file"
                                    v-validate="'size:102400|ext:doc,pdf,txt'"
                                    class="form-control"
                                    @change="updateTopicFile"
                                >
                            <span style="font-size: 13px;"><b>Note :</b> Pdf, Txt, Doc files are allowed. File size should be less than or equals to 100 MB.</span>
                            <span class="error" v-show="errors.has('subtopic_file')">
                                {{ $root.updateErrorMessage('subtopic_file',this.errors,'SubTopic File')}}
                            </span>
                             <br>
                            <span class="error" v-if="fileError != ''">
                                {{fileError}}
                            </span>
                        </div>
                        
                        <div class="form-group mb-0">     
                            <div class="checkbox">
                                <label class="cursor-pointer">
                                    Have Quiz &nbsp;
                                    <!-- <input
                                        type="checkbox"
                                        name="have_quiz"
                                        :value="0"
                                        @change="updateHaveQuiz"
                                        > -->
                                        <input
                                            type="checkbox"
                                            name="subhave_quiz"
                                            id="subhave_quiz"
                                            v-model="item.subhave_quiz"
                                            :checked="(item.subhave_quiz && item.subhave_quiz == 1) ? 'checked' : ''"
                                            >
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0">     
                            <div class="checkbox">
                                <label class="cursor-pointer">
                                    Is Task For Tutor &nbsp;
                                    <!-- <input
                                        type="checkbox"
                                        name="is_task_for_tutor"
                                        :value="0"
                                        @change="updateTaskForTutor"
                                        > -->
                                        <input
                                            id="subis_task_for_tutor"
                                            type="checkbox"
                                            name="subis_task_for_tutor"
                                            v-model="item.subis_task_for_tutor"
                                            :checked="(item.subis_task_for_tutor && item.subis_task_for_tutor == 1) ? 'checked' : ''"
                                            >
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0">     
                            <div class="checkbox ">
                                <label class="cursor-pointer">
                                    Is Access In Mobile &nbsp;
                                    <!-- <input
                                        type="checkbox"
                                        name="is_access_in_mobile"
                                        :value="0"
                                        @change="updateAccessInMobile"
                                        > -->
                                        <input
                                            id="subis_access_in_mobile"
                                            type="checkbox"
                                            name="subis_access_in_mobile"
                                            v-model="item.subis_access_in_mobile"
                                            :checked="(item.subis_access_in_mobile && item.subis_access_in_mobile == 1) ? 'checked' : ''"
                                            >
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="modal-footer modal-footer1">
                <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
    
                <button type="submit" :disabled='loading1' class="btn btn-success"><span class="alert text-center" v-if="loading1">
                                <i class="fa fa-spinner fa-sync-alt rotate"></i> Loading...</span>
                                <span v-else>Save</span></button>
            </footer>
        </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
        subtopic_name:'',
        description:'',
        sort_order:'',
        fileError:'',
    }
  },
  props:[ 'project_id'],
  computed: {
        ...mapGetters('ProjectTopicsSingle', ['TopicListing']),
        ...mapGetters('ProjectSubTopicsSingle', ['item', 'loading', 'projectList','loading1']),
    },
    created() {
        this.fetchProjectAll();  
        this.fetchData(this.project_id) 
    }, 
    watch: {
        "$route.params.id": function() { 
            this.resetState()
            this.fetchProjectAll();  
            this.fetchData(this.$route.params.id)
        },
    },   
    methods: {
        ...mapActions('ProjectTopicsSingle', ['getListing']), 
        ...mapActions('ProjectSubTopicsSingle', ['fetchData', 'storeData', 'setTopic','setTopicName', 'setDescription', 'setHaveQuiz', 'setTaskForTutor', 'setAccessInMobile', 'setTopicFile', 'setTopicVideo', 'setProjectId', 'fetchProjectAll']),
        updateProjectId(value) {
            this.setProjectId(value)
        }, 
        closeModel(){
            this.$refs.modal.hide();
            this.handleCancel();
        },
        updateTopic(value) {
            this.setTopic(value)
        }, 
        updateTopicName(value){
            this.setTopicName(value)
        },
        updateDescription(value){
            this.setDescription(value)
        },
        updateHaveQuiz(e) {
            this.setHaveQuiz(0)
            if(e.target.checked){
                this.setHaveQuiz(1)
            }        
        },
        updateTaskForTutor(e) {
            this.setTaskForTutor(0)
            if(e.target.checked){
                this.setTaskForTutor(1)
            }  
        },
        updateAccessInMobile(e) {
            this.setAccessInMobile(0)
            if(e.target.checked){
                this.setAccessInMobile(1)
            }
        },
        updateTopicFile(e) {
            this.fileError = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword'){
                    const fileVal = [];
                    fileVal.push(e.target.files[0])
                    this.setTopicFile(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only Pdf, Text, Doc files are allowed'
                    }
                        this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_file").val('');
                            this.$validator.errors.remove('topic_file');
                            this.setTopicFile('');
                        }
                    })
                } 
            }           
        },  
        updateTopicVideo(e) {
            this.fileError = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg')){
                    /* reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]); */
                    const fileVal = [];
                    fileVal.push(e.target.files[0])
                    this.setTopicVideo(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg')){
                        message = 'File size should be less then 500MB.'
                    }
                    else {
                        message = 'Only mp4 files are allowed'
                    }
                    this.$swal({
                    title: message,
                    type: 'error',                      
                    focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_video").val('');
                            this.$validator.errors.remove('topic_video');
                            this.setTopicVideo('');
                        }
                    })
                }
            }           
        },
        handleCancel(evt){
            console.log("cancel");
            this.setHaveQuiz(0)
            this.setTaskForTutor(0)
            this.setAccessInMobile(0)
            this.subtopic_name =''
            this.description=''
            this.sort_order = '';
            this.$validator.errors.remove('project_id');
            this.setTopicFile('');
            this.setTopicVideo(''); 
            this.getListing(this.project_id);
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.handleSubmit() 
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            this.setHaveQuiz(0)
            this.setTaskForTutor(0)
            this.setAccessInMobile(0)
            this.subtopic_name =''
            this.setTopicFile('');
            this.setTopicVideo(''); 
            this.description=''
            this.sort_order = '';
            this.$validator.errors.remove('project_id');   
            this.getListing(this.project_id);   
        },
        handleSubmit () {
            // if(this.item.subtopic_file == '' && this.item.subtopic_video == ''){
            //     this.fileError = 'Please select one file or one video.';
            //     return false;
            // }
        this.storeData()
        .then(res => {
                if(res.data.result == false){
                    for (const [key, value] of Object.entries(res.data.data)) {                        
                        if(key == 'topic_name'){ 
                                this.$validator.errors.add({
                                id: 'subtopic_name',
                                field: 'subtopic_name',
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                        this.$validator.errors.add({
                            id: key,
                            field: key,
                            msg: value[0],
                            scope: this.$options.scope,
                        });
                    }
                    return '';
                }else{
                    this.clear();
                    this.$refs.modal.hide()
                    this.setTopicFile('');
                    this.setTopicVideo('');
                    this.$eventHub.$emit('update-success')
                    this.getListing(this.project_id);
                }
            }).catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })     
    }
  }
}
</script>
<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>