function initialState() {
    return {
        studentAll: [],
        courseAll: [],
        listingAll: [],
        relationships: {},
        batchesAll: [],
        branchMastersAll: [],
        labsAll: [],        
        trainerAll: [],
        item: {
            course: null,
            student: null,
            date: null,
            batch_id: null,
            branch_id: null,
            lab_id: null,
            trainer: null,
            onlyDueAttendance: false,
            onlyDueAssessment: false
        },        
        query: {},
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,    
    data: state => {
        let rows = state.listingAll
        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','attendance','lab_name','topic_name','display_topic_status'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.listingAll

        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','attendance','topic_name','display_topic_status'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    loading: state => state.loading,
    courseAll: state => state.courseAll,
    listingAll: state => state.listingAll,
    trainerAll: state => state.trainerAll,
    studentAll: state => state.studentAll,
    batchesAll: state => state.batchesAll,
    branchMastersAll: state => state.branchMastersAll,
    labsAll: state => state.labsAll,    
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        axios.get('/api/v1/students_list')
            .then(response => {
                commit('setAll',  response.data.data)
            })         
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
getBatchWiseStudentDetails({ commit, state, dispatch }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('p_start_date', state.item.date);
            params.set('p_only_due_attendance', state.item.onlyDueAttendance);
            params.set('p_only_due_assessment', state.item.onlyDueAssessment);
            // params.set('p_batch_id', state.item.batch_id.id);
            if (_.isEmpty(state.item.lab_id)) {
                params.set('p_lab_id', '0')
            } else {
                params.set('p_lab_id', state.item.lab_id.id)
            }
            if (_.isEmpty(state.item.branch_id)) {
                params.set('p_branch_id', '0')
            } else {
                params.set('p_branch_id', state.item.branch_id.id)
            }            
            if (_.isEmpty(state.item.batch_id)) {
                params.set('p_batch_id', '0')
            } else {
                params.set('p_batch_id', state.item.batch_id.id)
            }
            if (_.isEmpty(state.item.trainer)) {
                params.set('p_trainer_id', '0')
            } else {
                params.set('p_trainer_id', state.item.trainer.id)
            }                        
            // params.set('p_trainer_id', state.item.trainer.id);
           
            axios.post('/api/v1/batchWiseStudentListing', params)
                .then(response => {
                    commit('setListingAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
    },  
    async fetchLabsAll({ commit },id) {
        await axios.get('/api/v1/branchWiseLabList/'+id)
            .then(response => {
                commit('setLabsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },         
    fetchStudentCourseAll({ commit },id) {
        axios.get('/api/v1/students_course_list/'+id)
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })         
    },
    async fetchBatches({ commit },id) {
        await axios.get('/api/v1/batchesAllBranchWiseTo/'+id)
            .then(response => {
                commit('setBatchesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },    
    fetchCurrentEmployee({ commit }) {
        axios.get('/api/v1/currentEmployee')
            .then(response => {
                commit('setTrainerAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },     
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setLab({ commit }, value) {
        commit('setLab', value)
    },     
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setDate({ commit }, value) {
        commit('setDate', value)
    },
    setBatch_id({ commit }, value) {
        commit('setBatch_id', value)
    },
    setTrainerId({ commit }, value) {
        commit('setTrainerId', value)
    },    
    setOnlyAttendance({ commit }, value) {
        commit('setOnlyAttendance',value)
    },
    setOnlyAssessmentDue({ commit }, value) {
        commit('setOnlyAssessmentDue',value)
    },    
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {
    setAll(state, items) {
        state.studentAll = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setLab(state, value) {
        state.item.lab_id = value
    },     
    setLabsAll(state, value) {
        state.labsAll = value
    },  
    setBranch(state, value) {
        state.item.branch_id = value
    },            
    setTrainerAll(state, value) {
        state.trainerAll = value
    },    
    setTrainerId(state, value) {
        state.item.trainer = value
    },
    setOnlyAttendance(state, value) {
        state.item.onlyDueAttendance = value
    },
    setOnlyAssessmentDue(state, value) {
        state.item.onlyDueAssessment = value
    },    
    setQuery(state, query) {
        state.query = query
    },    
    setError(state, error) {
        state.error = error
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setBatchesAll(state, value) {
        state.batchesAll = value
    },
    setListingAll(state, value) {
        state.listingAll = value
    },
    setDate(state, value) {
        state.item.date = value
    },    
    setBatch_id(state, value) {
        state.item.batch_id = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
