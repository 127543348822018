const datatable = {
    setCogIcon () {
        var windowWidth = $(document).width();
        if (windowWidth <= 786) {   
            setTimeout(function() {
                $("[name='Datatable']").parent().css("margin-top", "20px");
                var isTrainingReport = location.pathname.includes("daily-digest-training-report");
                var topPx = isTrainingReport ? "65px" : "105px";
                $("[name='HeaderSettings']").css("top", topPx);
                $("[name='HeaderSettings']").find(".dropdown-menu").find(".clearfix").find(".pull-right").removeClass("pull-right");
                if (windowWidth <= 414) {
                    var topPx = isTrainingReport ? "125px" : "190px";
                    $("[name='HeaderSettings']").find(".dropdown-menu").css({"top":topPx, "left":""});
                }
            },100);
        }
    }
}

export default {
    namespaced: true,
    datatable
}