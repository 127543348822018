<template>
    <div class="btn-group btn-group-xs">
         <router-link
                v-if="$can('income_' + 'view')"
                :to="'incomes/' + row.mainIncomeId + '/show'"
                class="btn btn-success edit_delete_btn"
                title="View Income Detail"
                target="_blank"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {}
}
</script>


<style scoped>

</style>
