<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Items</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Item</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Item Name</th>
                                            <td>{{ item.name }}</td>
                                            </tr>
                                        <tr>
                                            <th>Item Category</th>
                                            <td>
                                                {{item.item_category_name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Item Code</th>
                                            <td>
                                                {{item.code}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Item Part Number</th>
                                            <td>
                                                {{item.part_number}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Item Description</th>
                                            <td>
                                                {{item.description}}
                                            </td>
                                        </tr>
<!--                                        <tr>
                                            <th>Created On</th>
                                            <td>
                                                {{item.created_at}}
                                            </td>
                                        </tr>-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('InventoryItemSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('InventoryItemSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
