<template>
    <div class="btn-group btn-group-xs">
        <router-link
            v-if="$can(xprops.permission_prefix + 'view')"
            :to="{ name: xprops.route + '.show' , params: { id: row.id } }"
            title="View"
            class="btn btn-success edit_delete_btn"
        >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>

        <router-link
            v-if="$can(xprops.permission_prefix + 'edit')"
            :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
            title="Edit"
            class="btn btn-primary edit_delete_btn"
        >
            <i class="fa fa-fw fa-pencil"></i>
        </router-link>

        <button
            v-if="$can(xprops.permission_prefix + 'delete')"
            @click="destroyDataaaa(row.id)"
            title="Delete"
            type="button"
            class="btn  btn-danger edit_delete_btn"
        >
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>

        <button
            title="Cancel"
            v-if="xprops.route === 'leave_requests' && row.leave_status == 1"
            @click="cancelData(row.id)"
            type="button"
            class="btn btn-block btn-info edit_delete_btn"
        >
            <i class="fa fa-close" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module,
        }
    },
    created() {
        
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
    },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems') 
                        }else{
                            this.$eventHub.$emit('common',2,response.data.message)
                        }                     
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        cancelData(id) {
            this.$swal({
                title: 'Are you sure want to cancel?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/cancelData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
