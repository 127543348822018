<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branches</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branchs'">Branches</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Branch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline card-outline-tabs">
                     <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="edit-branch-tab" data-toggle="pill" href="#edit_branches" role="tab" aria-controls="edit_branches" aria-selected="true" @click="tabSelect('branch')">Edit Branches</a>
                        </li>
                        <li class="nav-item" v-if="$can('license_access')">
                            <a class="nav-link" id="license_tab" data-toggle="pill" href="#license" role="tab" aria-controls="license" aria-selected="false" @click="tabSelect('license')">License Management</a>
                        </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade show active" id="edit_branches" role="tabpanel" aria-labelledby="edit-branch-tab" v-if="selectedTab == 'branch'">
                            <Edit :project_id="project_id"></Edit>
                        </div>
                        <div class="tab-pane fade" id="license" role="tabpanel" aria-labelledby="license_tab" v-if="selectedTab == 'license'">
                            <!-- <ProjectTopics :project_id="project_id"></ProjectTopics> -->
                            <LicenseIndex :project_id="project_id"></LicenseIndex>
                        </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import Edit from '@/components/cruds/Branchs/Edit'
import LicenseIndex from '@/components/cruds/Branchs/LicenseIndex'

export default {
    components: {
        Edit,
        LicenseIndex
    },
    data() {
        return {
            // Code...
           project_id: '',
        }
    },
    computed: {
        ...mapGetters('BranchsIndex', ['selectedTab']),
    },
    created() {
        this.setTabSelection('branch');
        this.project_id = this.$route.params.id;
       // this.getListing(this.$route.params.id);   
    },
    watch: {
        "$route.params.id": function() {
            this.project_id = this.$route.params.id;
           // this.getListing(this.$route.params.id);
        },
    },
    methods: {
        ...mapActions('BranchsIndex', ['setTabSelection']),
        tabSelect(tab){
            this.setTabSelection(tab);
        }
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>
