\<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Fee Adjustment</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Fee Adjustment List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import FeeAdjustmentActions from '../../dtmodules/DatatableActions/FeeAdjustmentActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import StudentName from '../../dtmodules/StudentName'
import Amount from '../../dtmodules/Amount'


FeeAdjustmentActions.attachStore('FeeAdjustmentIndex');
export default {
    components,
    FeeAdjustmentActions,
    data() {
        return {
            columns: [
                { title: 'Student name', tdComp: StudentName, field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name'},
                { title: 'Fee Type', field: 'feesTypeName', sortable: true, thComp: 'FilterTh', tdClass: 'Fee Type'},
                // { title: 'Fee Amount', field: 'fees_amount', sortable: true, thComp: 'FilterTh', tdClass: 'Fee Amount' },
                // { title: 'GST Rate(%)', field: 'gst_rate', sortable: true, thComp: 'FilterTh'},
                { title: 'Fee Amount', field: 'total_fees', tdComp:Amount, sortable: true, thComp: 'FilterTh', tdClass:''},
                { title: 'Actions', tdComp: FeeAdjustmentActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'FeeAdjustmentIndex',
                route: 'fees_adjustment',
                permission_prefix: 'fees_adjustment_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('FeeAdjustmentIndex', ['data', 'total', 'loading', 'relationships']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('FeeAdjustmentIndex', ['fetchData', 'setQuery', 'resetState']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['','student_name','feesTypeName','total_fees'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData()
        }
    }
}
</script>


<style scoped>

</style>
