<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Assessments</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Course Assessments List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                                       
                            </div>
                        </div>
                          <div class="box-body" >
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">Course Name</label>
                                  <v-select
                                    name="search_course_name"
                                    label="course_name"                                           
                                    :value="null"
                                    v-model="date_filter.search_course_name"
                                    :options="courseListWithDistinctAll"        
                                    />                                
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">Sub Topic Name</label>
                                 <v-select
                                    name="search_topic_name"
                                    label="name"
                                    :value="null"
                                    v-model="date_filter.search_topic_name"
                                    :options="topicAll"
                                    />                                  
                            </div> 
                            <div class="col-md-4 col-sm-4">
                                <button type="button" class="btn btn-success m-t-25" @click="searchCourseData()">Search</button>
                            
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CourseAssessmentActions from '../../dtmodules/DatatableActions/CourseAssessmentActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import AnswerYesNo from '../../dtmodules/AnswerYesNo'
import components from '../../comps/'

CourseAssessmentActions.attachStore('CourseAssesmentsIndex');
export default {
    components,
    CourseAssessmentActions,
    data() {
        return {
            items:[],
            columns: [
                { title: 'Course Name', field: 'display_course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Sub Topic', field: 'name', sortable: true, thComp: 'FilterTh', tdClass: 'Sub Topic' },
                { title: 'Question', field: 'question', sortable: true, thComp: 'FilterTh', tdClass: 'Question' },
                { title: 'Fully', field: 'fully', sortable: true, visible: false, thComp: 'FilterTh', tdClass: 'Question' },
                { title: 'Partial', field: 'partial', sortable: true, visible: false, thComp: 'FilterTh', tdClass: 'Question' },
                { title: 'Need Improvement', field: 'need_improvement', visible: false, sortable: true, thComp: 'FilterTh', tdClass: 'Question' },
                { title: 'Actions', tdComp: CourseAssessmentActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'CourseAssesmentsIndex',
                route: 'course_assesments',
                permission_prefix: 'course_assesment_'
            },
            date_filter : {search_topic_name : null, search_course_name : null}   
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
        this.fetchCourseListWithDistinctAll()
        this.fetchTopicAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CourseAssesmentsIndex', ['data', 'total', 'loading', 'relationships','courseListWithDistinctAll','topicAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){
           
        },
/*        'item':function(){
            console.log(this.item.question_answer)
        },*/
    },
    methods: {
        ...mapActions('CourseAssesmentsIndex', ['fetchData','fetchCourseListWithDistinctAll','fetchTopicAll', 'setQuery', 'resetState']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['display_course_name','name','question', 'fully', 'partial', 'need_improvement'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.query = {};
            this.date_filter.search_topic_name = null;
            this.date_filter.search_course_name = null;
            this.fetchData()            
        },
        searchCourseData() {
            this.query = {};            
            // console.log(this.date_filter.search_branch_name)
            if (this.date_filter.search_topic_name && this.date_filter.search_topic_name.name) {
                this.date_filter.search_topic_name = this.date_filter.search_topic_name.name;    
            }
            if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
                this.date_filter.search_course_name = this.date_filter.search_course_name.course_name;    
            }
            this.fetchData(this.date_filter);
        }
    }
}
</script>


<style scoped>

</style>
  