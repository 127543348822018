<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>States</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/states'">States</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add State</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Add State</h3>
                        </div>
                        <bootstrap-alert />
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="state_name">State<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="state_name"
                                            ref="state_name"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter State"
                                            :value="item.state_name"
                                            @input="updateStateName"
                                            >
                                            <span class="error" v-show="errors.has('state_name')">
                                                {{ $root.updateErrorMessage('state_name',this.errors, 'State')}}
                                            </span>
                                </div>
                                </div>
                                <div class="col-md-6">
                                    <div ref="country_name_id" class="form-group">
                                        <label for="country_id">Country<p class="astrisk">*</p></label>
                                        <v-select
                                                name="country_name_id"
                                                label="country_name"
                                                id="country_id"
                                                v-validate="'required'"
                                                @input="updateCountryId"
                                                :value="item.country_name_id"
                                                :options="countryAll"
                                                />
                                        <span class="error" v-show="errors.has('country_name_id')">
                                            {{ $root.updateErrorMessage('country_name_id',this.errors, 'Country')}}
                                        </span>
                                    </div>                            
                                </div>  
                            </div>
                            <div class="col-md-12 col-md-12 savebtn">
                               <vue-button-spinner
                                    class="std_information_save col-md-2 col-md-2 btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                                </vue-button-spinner>
                            </div>                  
                        </div>
                    </div>                      
                </form>                    
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    components: { CommonMasterPopup },
    data() {
        return {
            // Code...
            //errorName : ''
        }
    },
    computed: {
        ...mapGetters('StatesSingle', ['item', 'loading', 'countryAll']),
       
    },
    created() {
        //this.fetchStateTypeAll()
        this.fetchCountryAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        
        ...mapActions('StatesSingle', ['storeData', 'resetState', 'setStateName', 'setCountryId', 'fetchCountryAll']),
      
        updateStateName(e) {
            this.setStateName(e.target.value)
        },
       
        updateCountryId(value) {
            this.setCountryId(value)
        },
       
        submitForm() {

        this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'state_name',
                            field: 'state_name',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }
                    this.$router.push({ name: 'states.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


<style scoped>

</style>
 