<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Employees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Employees List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Employees List</h3>
                    </div>
                    <div class="card-body">
                    <div class="btn-group mb-3">
                            <router-link
                                    v-if="$can(xprops.permission_prefix + 'create')"
                                    :to="{ name: xprops.route + '.create' }"
                                    class="btn btn-success btn-sm"
                                    >
                                <i class="fa fa-plus"></i> Add New
                            </router-link>

                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>                      
                        </div>
                        <bootstrap-alert />
                        <div class="table-responsive setting-group">
                            <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                                >
                                   <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'actions'">
                                        <EmployeeActions @loadItems="loadItems" :xprops="xprops" :row="props.row"></EmployeeActions>
                                    </span>
                                    <span v-else-if="props.column.field == 'profile'">
                                        <DatatableProfileField :row="props.row"></DatatableProfileField>
                                    </span>
                                       <span v-else-if="props.column.field == 'ratings'">
                                         <star-rating
                                            :star-size="30"
                                            :show-rating="false"
                                            :max-rating="3"
                                            :read-only="true"
                                            active-color="#00D746"
                                            inactive-color="#737373"
                                            :rating="convertToNumber(props.row.ratings)"
                                        />
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import EmployeeActions from '../../dtmodules/DatatableActions/EmployeeActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import DatatableProfileField from '../../dtmodules/DatatableProfileField'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
EmployeeActions.attachStore('EmployeeMastersIndex');
export default {
    components,
    components: {
        EmployeeActions,
        DatatableProfileField,
        CustomLoader
    },
    data() {
        return {
            isLoading: true,
            refershLoad:false,
            columns: [
                { 
                    label: "Profile",
                    field: 'profile',
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: false, // enable filter for this column
                        placeholder: 'Profile', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    }
                },
                {
                    label: "Employee Name",
                    field: "employee_name",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Employee Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Branch Name",
                    field: "branch_name",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Branch Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "City",
                    field: "city_name",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'City', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Mobile Number",
                    field: "mobile_no",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Mobile Number', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Email",
                    field: "email_id",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Email', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Highest Education",
                    field: "highest_education",
                    filterOptions: {
                        styleClass: 'user_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Highest Education', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Rating",
                    field: "ratings",
                    filterOptions: {
                        styleClass: 'project_filter', // class to be added to the parent th element
                        enabled: false, // enable filter for this column
                        placeholder: 'Hours', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                // {
                //     label: "Username",
                //     field: "username",
                //     filterOptions: {
                //         styleClass: 'user_filter', // class to be added to the parent th element
                //         enabled: true, // enable filter for this column
                //         placeholder: 'Username', // placeholder for filter input
                //         filterValue: '', // initial populated value for this filter
                //         filterDropdownItems: [], // dropdown (with selected values) instead of text input
                //         // trigger: 'enter', //only trigger on enter not on keyup 
                //     },
                // },
                {
                label: "Actions",
                field: "actions",
                sortable: false
                }

            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'EmployeeMastersIndex',
                route: 'employee_masters',
                permission_prefix: 'employee_master_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
      //  this.fetchData()
    },
    computed: {
        ...mapGetters('EmployeeMastersIndex', ['data', 'total', 'loading', 'relationships']),
    },
    methods: {
        ...mapActions('EmployeeMastersIndex', ['fetchData', 'setQuery', 'resetState']),
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axios
            .get("/api/v1/employee-masters", {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
         convertToNumber(value){
            return parseInt(value);
        },
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>


<style scoped>

</style>
