function initialState() {
    return {
        all: [],
        query: {},
        loading: false
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['item_category_name','item_name','original_date','original_format_date','quantity','file','remarks','created_at'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 

        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['item_category_name','item_name','original_date','original_format_date','quantity','file','remarks','created_at'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 

        return rows.length;
    },
    loading:       state => state.loading
}

const actions = {
    fetchData({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/inventory-item-purchase', {params: params})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: false, color: 'danger' },
                    { root: true })

            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {        
        axios.delete('/api/v1/inventory-item-purchase/' + id)
            .then(response => {
                    var rows = state.all
                    rows = rows.filter((item) => {
                        return item.id != id
                    })
                    commit('setAll', rows);
                    
                    var queryLocal = purify(state.query);
                    if(rows.length == queryLocal.offset && rows.length != 0)
                    {
                        queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                        commit('setQuery', queryLocal)
                    }
                    resolve();
                    })
                    .catch(error => {
                        //message = error.response.data.message || error.message
                        var message = error.message
                        commit('setError', message)
                        reject(error);
                    })
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setError(state, error) {
        state.error = error
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
