<template>
    <div class="container certificatePDF">
        <button id="generatePDF" @click="generatePDF()" class="btn btn-default float-right"><i class="fa fa-download" aria-hidden="true"></i></button>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="CertificatePDF"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="[0,0,0,0]"
            pdf-orientation="landscape"
            pdf-content-width="950px"
            @progress="onProgress($event)"
            ref="html2Pdf"
            :html-to-pdf-options="{margin: 0, left:0,}"
            :htmlToPdfOptions="{
                filename: 'Certificate',
                margin: [0,0,0,0],
                scale: 1,
                dpi: 300,
                //pdfQuality:2,
               // pdfOrientation: 'landscape',
               jsPDF: { format: 'a4', orientation: 'landscape', quality:2 },
               html2canvas: {useCORS: true}
            }"
        >
            <div slot="pdf-content" style="background-color:white;">
                <div class="row" v-html="item" v-if="item != null">
                </div>
            </div>
        </vue-html2pdf>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    props:['enroll_id'],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            htmlToPdfOptions: {margin: 0}
        }
    },
    computed: {
        ...mapGetters('CertificatePDF', ['item','loading']),
    },
    watch: {
        "$route.params.id": function() {
            this.getCertificate(this.$route.params.id);     
        },
    }, 
    created(){
        this.getCertificate(this.$route.params.id)
        .then((res) => {
            if(res.data.length == 0) {
               this.$router.push('/404');
            } else {
                //console.log(this.$router)
                $('#generatePDF').trigger('click');
            }
            this.$emit('changeFlag');
      });
    },
    methods:{
        ...mapActions('CertificatePDF', ['getCertificate']),

        onProgress(event) {
            console.log(`Processed: ${event} / 100`);
        },
        hasGenerated() {
            alert("PDF generated successfully!");
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf();
        },
    }
}
</script>
