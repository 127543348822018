function initialState() {
    return {
        
        item: {
            income_type: null,
            payment_mode: null,
            search_word:null,
            from_date:null,
            to_date:null,
            report_type:2,
        },
        query: {},
        querySummary: {},
        incomeTypeAll: [],    
        reportsDataAll: [],    
        summaryDataAll: [],    
        paymentModeAll: [],    
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,
    data: state => {
        let rows = state.reportsDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['income_type_text','payment_mode_text','bank_name','instrument_no','original_date','original_format_date','amount','remarks'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.reportsDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['income_type_text','payment_mode_text','bank_name','instrument_no','original_date','original_format_date','amount','remarks'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },        
    dataSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','amount'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.querySummary.offset, state.querySummary.offset + state.querySummary.limit)
    },
    totalSummary: state => {
        let rows = state.summaryDataAll
        if (state.querySummary.sort) {
            rows = _.orderBy(state.summaryDataAll, state.querySummary.sort, state.querySummary.order)
        }
        var queryFilter = JSON.stringify(state.querySummary);
        var queryFilter = JSON.parse(queryFilter);
           ['original_date_a','original_format_date_a','amount'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },   
    loading: state => state.loading,
    incomeTypeAll: state => state.incomeTypeAll,
    paymentModeAll: state => state.paymentModeAll,
    reportsDataAll: state => state.reportsDataAll,
    summaryDataAll: state => state.summaryDataAll,    
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async exportIncomeReportListStore({ commit }, dataObj) {
      window.location.href = location.protocol + "//" + location.host + '/exportIncomeReportList/'
                           + dataObj.income_type + '/' 
                           + dataObj.payment_mode+ '/' 
                           + dataObj.search_word+ '/' 
                           + dataObj.from_date+ '/' 
                           + dataObj.to_date;
    },      
    fetchIncomeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if (_.isEmpty(state.item.income_type)) {
                params.set('income_type', '')
            } else {
                params.set('income_type', state.item.income_type.value_id)
            }
            
            if (_.isEmpty(state.item.payment_mode)) {
                params.set('payment_mode', '')
            } else {
                params.set('payment_mode', state.item.payment_mode.value_id)
            }
            
            if (_.isEmpty(state.item.search_word)) {
                params.set('search_word', '')
            } else {
                params.set('search_word', state.item.search_word)
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.set('from_date', '')
            } else {
                params.set('from_date', state.item.from_date)
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.set('to_date', '')
            } else {
                params.set('to_date', state.item.to_date)
            }
            
            axios.post('/api/v1/incomeReportData', params)
                .then(response => {
                    commit('setIncomeReportData', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchPaymentModeAll({ commit }) {
        await axios.get('/api/v1/paymentModeListAll')
            .then(response => {
                commit('setPaymentModeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },         
    async fetchIncomeTypeAll({ commit }) {
        await axios.get('/api/v1/income-types')
            .then(response => {
                commit('setIncomeTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },         
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setQuerySummary({ commit }, value) {
        commit('setQuerySummary', purify(value))
    },     
    setIncomeType({ commit }, value) {
        commit('setIncomeType', value)
    },
    setPaymentMode({ commit }, value) {
        commit('setPaymentMode', value)
    },
    setIncomeReportData({ commit }, value) {
        commit('setIncomeReportData', value)
    },    
    setFromDate({ commit }, value) {
        commit('setFromDate', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },    
    setSearchWord({ commit }, value) {
        commit('setSearchWord', value)
    }, 
    setReportType({ commit }, value) {
        commit('setReportType', value)
    }, 
    setSummaryDataAll({ commit }, value) {
        commit('setSummaryDataAll', value)
    },       
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {  
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },    
    setQuerySummary(state, querySummary) {
        state.querySummary = querySummary
    },
    setError(state, error) {
        state.error = error
    },   
    setPaymentModeAll(state, value) {
        state.paymentModeAll = value
    },
    setIncomeTypeAll(state, value) {
        state.incomeTypeAll = value
    },
    setPaymentMode(state, value) {
        state.item.payment_mode = value
    },
    setIncomeType(state, value) {
        state.item.income_type = value
    },
    setFromDate(state, value) {
        state.item.from_date = value
    },
    setExit_date(state, value) {
        state.item.to_date = value
    },
    setSearchWord(state, value) {
        state.item.search_word = value
    },         
    setReportType(state, value) {
        state.item.report_type = value
    },         
    setIncomeReportData(state, value) {
        state.reportsDataAll = value
    }, 
    setSummaryDataAll(state, value) {
        state.summaryDataAll = value
    },      
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
