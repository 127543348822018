<template>
<v-app id="inspire">
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
 
      <v-sheet height="600" class="calendar-font">
        <!-- {{events}} -->
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        >
        <template v-slot:event="{ event }">
          <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="v-event-draggable"  v-bind="attrs"
          v-on="on">
                <span class="start_time">&nbsp;&nbsp;{{ formatEventTime(event.start) }} </span><span class="end_time" v-if="type == 'day'">- {{ formatEventTime(event.end) }} </span>
                <strong>&nbsp;{{ event.name }}</strong>
              </div>
          </template>
          <span>{{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}</span><br>{{ event.name }}
        </v-tooltip>
          
        </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-book</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn> -->
              <!-- <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text>
             
              <span v-if="selectedEvent.lab_name"><b>Lab Name : </b>{{selectedEvent.lab_name}}</span><br>
              <span v-if="selectedEvent.batch_name"><b>Batch Name : </b>{{selectedEvent.batch_name}}</span><br>
              <span v-if="projectDetail.length>0"><b>Project Details:</b></span><br>
               <div style="max-height:300px;overflow:auto;" v-if="projectDetail.length>0">
              <table class="table table-bordered striped" v-if="projectDetail.length>0">
              
                <template v-for="project in projectDetail">
                  <tr :key="project.id">
                    <th colspan="3">{{project.project_name}}</th>
              
                  </tr>
                  <tr v-if="project.topics.length > 0" :key="project.id" >
                    <th>Topic Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                <template v-if="project.topics.length >0">
                  <!-- {{project.topics}} -->
                  <tr v-for="(topic,index) in project.topics" :key="index">
                    
                    <td>{{topic.topic_name}}</td>
                    <td>{{(topic.start_date == null)?'-':topic.start_date}}</td>
                    <td>{{(topic.end_date == null)?'-':topic.end_date}}</td>
                  </tr>
               
                  </template>
                </template>
              
              </table>
              </div>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props:['id'],
    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        // '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    computed: {
    ...mapGetters("StudentScheduleIndex", ['studentCourseSchedule','projectDetail'])
    },
    mounted () {
      //this.$refs.calendar.checkChange()
    },
    created(){
      //console.log("id",this.id);
    },
    watch:{
      studentCourseSchedule(value){
        this.events = [];
        value.map((e, feeIndex)=>{
          let color = 'grey darken-1';
          if(e.status == 2){
            color = 'red';
          }
          if(e.status == 1){
            color = 'green';
          }
         
          this.events.push({
            name: e.course.course_name,
            lab_name:e.lab_name,
            batch_name:e.batch.batch_name + ' ('+moment(e.batch.start_time, ["HH:mm:ss"]).format("h:mm A")+' - '+moment(e.batch.end_time, ["HH:mm:ss"]).format("h:mm A")+')',
            start: e.session_date_time,
            end: e.session_end_date_time,
            color: color,
            project_id:e.project_id,
            timed: true,
            course_enroll_id : e.student_course_enroll_id,
            data:e
          })
        });
        //console.log(value);
      }
    },
    methods: {
      ...mapActions("StudentScheduleIndex", ['fetchStudentCourseSchedule','setScheduleProjectDetails','fetchScheduleProjectDetail']),
      formatEventTime(date) {
        return moment(date, "YYYY-MM-DD HH:mm:ss").format("h:mm A");;
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        this.setScheduleProjectDetails();
        this.selectedOpen = false
        //console.log("event",event);
        this.fetchScheduleProjectDetail({'project_id':event.project_id,'session_date':event.data.session_date,'status':event.data.status})
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        const events = [];
        const min = start.date;
        const max = end.date;
        this.fetchStudentCourseSchedule({'student_course_enroll_id':this.id,'start':min,'end':max})
        return false;
        // const days = (max.getTime() - min.getTime()) / 86400000
        // const eventCount = this.rnd(days, days + 20)
        // for (let i = 0; i < eventCount; i++) {
        //   const allDay = this.rnd(0, 3) === 0
        //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //   const second = new Date(first.getTime() + secondTimestamp)
        //   events.push({
        //     name: this.names[this.rnd(0, this.names.length - 1)],
        //     start: first,
        //     end: second,
        //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        //     timed: !allDay,
        //   })
        // }
        // this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>

<style scoped>


</style>


 

