<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Projects</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/projects'">Projects</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Project</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline card-outline-tabs">
                     <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="changeComp('projects')" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Edit Projects</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" v-if="$can('project_topic_access')" @click="changeComp('topics')" id="custom-tabs-four-topics-tab" data-toggle="pill" href="#custom-tabs-four-topics" role="tab" aria-controls="custom-tabs-four-topics" aria-selected="false">Topic - SubTopic</a>
                            </li>
                            <li class="nav-item" v-if="$can('quiz_access')">
                                <a class="nav-link" @click="changeComp('quiz')" id="custom-tabs-four-quiz-tab" data-toggle="pill" href="#custom-tabs-four-quiz" role="tab" aria-controls="custom-tabs-four-quiz" aria-selected="false"> Quiz</a>
                            </li>
                            <li class="nav-item" v-if="$can('tutor_access')">
                                <a class="nav-link" @click="changeComp('tutor')" id="custom-tabs-four-tutor-tab" data-toggle="pill" href="#custom-tabs-four-tutor" role="tab" aria-controls="custom-tabs-four-tutor" aria-selected="false">Task For Tutor</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div v-if="tabChange == 'projects'" class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                <ProjectEdit :project_id="project_id" ></ProjectEdit>
                            </div>
                            <div v-if="tabChange == 'topics' && $can('project_topic_access')" class="tab-pane fade" id="custom-tabs-four-topics" role="tabpanel" aria-labelledby="custom-tabs-four-topics-tab">
                                <ProjectTopics :project_id="project_id"></ProjectTopics>
                            </div>
                            <div v-if="tabChange == 'quiz' && $can('quiz_access')" class="tab-pane fade" id="custom-tabs-four-quiz" role="tabpanel" aria-labelledby="custom-tabs-four-quiz-tab">
                                <Quiz :project_id="project_id"></Quiz>
                            </div>
                            <div v-if="tabChange == 'tutor' && $can('tutor_access')" class="tab-pane fade" id="custom-tabs-four-tutor" role="tabpanel" aria-labelledby="custom-tabs-four-tutor-tab">
                                <Tutor :project_id="project_id"></Tutor>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectEdit from '../../dtmodules/ProjectEdit'
import ProjectTopics from '../../dtmodules/ProjectTopics'
import Quiz from './Quiz'
import Tutor from '../Tutor/Tutor'

export default {
    components: {
        ProjectEdit,
        ProjectTopics,
        Quiz,
        Tutor
    },
    data() {
        return {
           form_data:{
               project_name:'',
               project_type:[],
               project_brand:[],
               project_kit:[],
               description:''
           },
           project_id: '',
           topic_id: '',
           tabChange:'projects',
        }
    },
    computed: {
        ...mapGetters('ProjectsSingle', ['projectDetail', 'loading','projectTypes','projectBrands','projectKits']),
    },
    created() {
        this.project_id = this.$route.params.id; 
    },
    watch: {
        "$route.params.id": function() {
            this.project_id = this.$route.params.id;
        },
        tabChange(){
            return this.tabChange;
        }
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchData', 'updateData','fetchTypes']),
        changeComp(tabActive){
            this.tabChange = tabActive
        }
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>
