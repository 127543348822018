function initialState() {
    return {
        all: [],
        item: {
            branch_id: null
        },        
        groupAll: [],
        studentAll: [],
        staffAll: [],
        branchMastersAll: [],
        loading: false
    }
} 

const getters = {
    item: state => state.item,    
    all: state => state.all,
    branchMastersAll: state => state.branchMastersAll,
    groupAll: state => state.groupAll,
    studentAll: state => state.studentAll,
    staffAll: state => state.staffAll,
    loading: state => state.loading,
}

const actions = {
    async fetchGroupAll({ commit, state, dispatch },branch_id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllGroups/'+branch_id)
                .then(response => {
                    commit('setGroupsAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    async fetchStudentAll({ commit, state, dispatch },branch_id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllStudentsBranch/'+branch_id)
                .then(response => {
                    commit('setStudentAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
    async fetchStaffAll({ commit, state, dispatch },branch_id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllEmployeeBranch/'+branch_id)
                .then(response => {
                    commit('setStaffAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    sendSmsToSelectedMembersAndGroups({ commit, state, dispatch }, params) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/sendingSmsToSelectedMembersAndGroups', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },
    sendSmsOnDynamicTemplateStore({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/creatingGroup', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                var temp = response.data.data;
                if(temp.length > 1) {
                    temp.unshift({
                        'branch_name': 'All',
                        'id': '0'
                    });
                }
                commit('setBranchMasterAll', temp)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },    
    setAll({ commit }, value) {
        commit('setAll', value)
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },  
    setBranch(state, value) {
        state.item.branch_id = value
    },        
    setAll(state, all) {
        state.all = all
    },
    setGroupsAll(state, groupAll) {
        state.groupAll = groupAll
    },
    setStudentAll(state, studentAll) {
        state.studentAll = studentAll
    },
    setStaffAll(state, staffAll) {
        state.staffAll = staffAll
    },        
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
