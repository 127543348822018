function initialState() {
    return {
        item: {
            performance_status: null,
            percentage_from: null,
            percentage_to: null,
            color_code: null,
            finalData: [],
        },
        performanceStatusAll: [],
        evaluationDataAll: [],
        loading: false,
    }
} 

const getters = {
    item: state => state.item,    
    loading: state => state.loading,
    performanceStatusAll: state => state.performanceStatusAll,
    evaluationDataAll: state => state.evaluationDataAll
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            state.item.finalData.forEach(function(rangeData, index){
                var final = [];
                params.set('output['+index+']', rangeData)
            })
            axios.post('/api/v1/evaluation-criteria',params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchEvaluationDataAll({ commit }) {
        await axios.get('/api/v1/evaluationDataAll')
            .then(response => {
                commit('setEvaluationDataAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchPerformanceStatusAll({ commit }) {
        axios.get('/api/v1/performanceStatusAll')
            .then(response => {
                commit('setPerformanceStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setPerformanceStatus({ commit }, value) {
        commit('setPerformanceStatus', value)
    },
    setPercentageFrom({ commit }, value) {
        commit('setPercentageFrom', value)
    },
    setPercentageTo({ commit }, value) {
        commit('setPercentageTo', value)
    },
    setColorCode({ commit }, value) {
        commit('setColorCode', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },     
    setPerformanceStatusAll(state, value) {
        state.performanceStatusAll = value
    },
    setEvaluationDataAll(state, value) {
        state.evaluationDataAll = value
    },
    setPerformanceStatus(state, value) {
        state.item.performance_status = value
    },
    setPercentageFrom(state, value) {
        state.item.percentage_from = value
    },
    setColorCode(state, value) {
        state.item.color_code = value
    },
    setPercentageTo(state, value) {
        state.item.percentage_to = value
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
