<template>
    <div class="content-wrapper">

        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Common Master</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Common Master</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Common Master</h3>
                </div>
                <div class="card-body">
                  <div class="row col-md-12">
                   <div class="btn-group mb-3">
                        <router-link

                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                                >
                            <i class="fa fa-plus"></i> Add New
                        </router-link>

                        <!-- <router-link
                                v-if="$can(xprops.permission_prefix + 'create')"
                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                                >
                            <i class="fa fa-plus"></i> Add new
                        </router-link> -->

                         <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>                       
                    </div>
                    </div>
                      <!-- <div class="row" v-if="loading">
                        <div class="col-xs-4 col-xs-offset-4">
                            <div class="alert text-center">
                                <i class="fa fa-spinner fa-sync"></i> Loading
                            </div>
                        </div>
                    </div> -->
                    <div class="table-responsive setting-group">
                        <vue-good-table
                              ref="table1"
                              styleClass="vgt-table table table-bordered striped thead-dark"
                              mode="remote"
                              @on-page-change="onPageChange"
                              @on-sort-change="onSortChange"
                              @on-column-filter="onColumnFilter"
                              @on-per-page-change="onPerPageChange"
                              @on-search="onSearch"
                              :totalRows="totalRecords"
                              :pagination-options="{
                                  enabled: true,
                                  perPage:10,
                                  perPageDropdown: [10, 20, 40, 80, 100],
                                  dropdownAllowAll: false,
                              }"
                              :isLoading.sync="isLoading"
                              :select-options="{ 
                                  enabled: false,
                          }"
                              :search-options="{
                                  enabled: false,
                                  placeholder: 'search',
                              }"
                              :rows="rows"
                              :columns="columns"
                          >
                              <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                              <template
                                  slot="table-row"
                                  slot-scope="props"
                              >
                                  <span v-if="props.column.field == 'actions'">
                                      <CommonMasterActions :xprops="xprops" :row="props.row" @loadItems="loadItems"></CommonMasterActions>
                                  </span>
                                  <span v-else>
                                      {{props.formattedRow[props.column.field]}}
                                  </span>
                      </template>
                       <template slot="loadingContent">
                                <CustomLoader></CustomLoader>
                            </template>
                      </vue-good-table>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>


<script>
import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
import CommonMasterActions from "../../dtmodules/DatatableActions/CommonMasterActions";
// import DatatableSingle from "../../dtmodules/DatatableSingle";
// import DatatableList from "../../dtmodules/DatatableList";
// import DatatableCheckbox from "../../dtmodules/DatatableCheckbox";
import components from "../../comps/";
import CustomLoader from '@/components/CustomLoader'
CommonMasterActions.attachStore("CommonMastersIndex");
export default {
  components,
  components: {
    CommonMasterActions,
    CustomLoader
  },
  data() {
    return {
      items: [],
      isLoading: true,
      refershLoad:false,
      columns: [
        {
          label: "Type",
          field: "type_name",
          filterOptions: {
            styleClass: 'common_master_filter', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Type', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
        },
        },
        {
          label: "Name",
          field: "name",
           filterOptions: {
                styleClass: 'common_master_filter', // class to be added to the parent th element
                enabled: true, // enable filter for this column
                placeholder: 'Name', // placeholder for filter input
                filterValue: '', // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                // filterFn: this.filterFn, //custom filter function that
                // trigger: 'enter', //only trigger on enter not on keyup 
            },
        },
        {
          label: "Other Value",
          field: "other_value_with_desc",
          filterOptions: {
                styleClass: 'common_master_filter', // class to be added to the parent th element
                enabled: true, // enable filter for this column
                placeholder: 'Other Value', // placeholder for filter input
                filterValue: '', // initial populated value for this filter
                filterDropdownItems: [], // dropdown (with selected values) instead of text input
                // filterFn: this.filterFn, //custom filter function that
                // trigger: 'enter', //only trigger on enter not on keyup 
            },
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: "",
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: ""
          }
        ],
        page: 1,
        perPage: 10
      },
      xprops: {
        module: "CommonMastersIndex",
        route: "common_masters",
        permission_prefix: "common_master_"
      }
    };
  },
  created() {
    //console.log("sdsf");
    this.$root.relationships = this.relationships;
    //this.loadItems();
  },
  // destroyed() {
  //   this.resetState();
  // },
  computed: {
    ...mapGetters("CommonMastersIndex", [
      "data",
      "total",
      "loading",
      "relationships"
    ])
  },
  methods: {
    ...mapActions("CommonMastersIndex", [
      "fetchData",
      "setQuery",
      "resetState"
    ]),
     updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ],
        page: 1
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      //console.log("column",params);
      this.updateParams(params);
       this.updateParams({page:1});
      this.loadItems();
    },

    onSearch(params) {
      params.page = 1;
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems(flag=0) {
      var order = "";
      var field = "";
      if (
        this.serverParams.sort[0].type != "" &&
        this.serverParams.sort[0].type != "none"
      ) {
        order = this.serverParams.sort[0].type;
        field = this.serverParams.sort[0].field;
      }
      let search = this.serverParams.searchTerm
        ? this.serverParams.searchTerm
        : "";
      if(flag== 1){
                this.refershLoad = true;
            }
      axios
        .get("/api/v1/common-masters", {
          params: {
            max: this.serverParams.perPage,
            page: this.serverParams.page,
            search: search,
            order: order,
            field: field,
            columnFilters:this.serverParams.columnFilters
          }
        })
        .then(response => {
          let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        });
    },
    resetFilters() {
      //console.log("erte",this.columns[0].filterOptions)
     // this.columns[0].filterOptions.filterValue = '';
      this.serverParams.columnFilters = {};
      let columnFilters = {"type_name":"","name":"","other_value_with_desc":""};
      this.updateParams(columnFilters);
      this.updateParams({page:1});
      //this.$refs['table1'].reset();
      this.$refs.table1.reset();
      this.loadItems(1);
    }
  }
};
</script>


<style scoped>
</style>
 