<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Courses</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/courses'">Courses</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Course</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Course</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Course Name</th>
                                    <td><span>{{ showData.course_name }}</span></td>
                                </tr>
                                <tr>
                                    <th>Course List</th>
                                    <td><span class="parent_span" v-for="(list) in showData.course_details" :key="list.id">
                                            <span v-if="list.type == 'project'" style="padding-left:28px;"><i class="project_icon">P</i>{{list.project_name}}</br></span>
                                            <span v-else>
                                                <span style="margin-bottom:8px;display:block;"><i class="module_icon">M</i>{{list.module_name}}<br></span></span>
                                                <span style="padding-left:28px;" v-for="(project) in list.module_project" :key="project.id"><i class="project_icon">P</i>{{project.project_name}}</br></span></span>
                                            </span>
                                        </span></td>
                                </tr>  
                                <tr>
                                    <th>Course Vertical</th>
                                    <td><span>{{ showData.course_vertical }}</span></td>
                                </tr> 
                                <tr>
                                    <th>Course Category</th>
                                    <td><span>{{ showData.course_category }}</span></td>
                                </tr> 
                                <tr>
                                    <th>Course Fee Type</th>
                                    <td><span>{{ showData.fee_type_data }}</span></td>
                                </tr> 
                                <tr>
                                    <th>Course Total Hours</th>
                                    <td><span>{{ showData.total_hours }}</span></td>
                                </tr>

                                <tr>
                                    <th>Referral User Price</th>
                                    <td><span>{{ showData.referral_user_price }}</span></td>
                                </tr>
                                <tr>
                                    <th>Referred User Price</th>
                                    <td><span>{{ showData.referred_user_price }}</span></td>
                                </tr>
                                <tr>
                                    <th>Is Active for referral</th>
                                    <td><span>{{ showData.is_active }}</span></td>
                                </tr>
                                <tr>
                                    <th>End Date</th>
                                    <td><span>{{ showData.end_date }}</span></td>
                                </tr>
                                <tr>
                                    <th>Course report content</th>
                                    <td><span>{{ showData.course_report_content ? showData.course_report_content : '' }}</span></td>
                                </tr>
                                <tr>
                                    <th>Is Active for report</th>
                                    <td><span>{{ showData.is_allowed_to_generate_report ? showData.is_allowed_to_generate_report : '' }}</span></td>
                                </tr>

                                </tbody>
                            </table>
                        </div>                
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData(this.$route.params.id)
    },
    // destroyed() {
    //     this.resetState()
    // },
    computed: {
        ...mapGetters('CoursesIndex', ['showData','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchShowData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('CoursesIndex', ['fetchShowData', 'resetState']),
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    /* border-color: black; */
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>
