function initialState() {
	return {
		item: {},
		countAll: [],
		batchAll: [],
		coursesAll: [],		
		courseFilterValue: null,
		currentBranchIdForProps: null,
		loading: false,
		joining_date: '',
		exit_date: '',	
		employeeStatisticsData: [],
		trainerDropdownDataForEmployeeStatisticsDB: null,
		dateFilterValueForEmployeeStatistics: null,
		courseFilterValueForEmployeeStatistics: null,
		employeeStatisticsLoader: false,
		loadingCalendar: false,
		weekCalendarData: [],
		loadingWeekCalendar: false,				
		courseStatusLoader: false,
		inquiryFollowupLoader: false,
		reminderLoader: false,
		followupList: [],
		remindersList: [],
		prospectFollowupList: [],
		activeCourseList: [],
		pendingStudentList: [],
		activeStudentList: [],
		holdStudentList: [],
		completedStudentList: [],
		pendingToBeMarkedAsCompletedStudentList: [],
		getStudentAttandencesList: [],
		getStudentAssessmentList: [],
		getTodaysBatchData: [],
		calendarData: [],
		calendarBatchData: [],
	}
}

const getters = {
	joining_date: state => state.joining_date,	
	exit_date: state => state.exit_date,	
	coursesAll: state => state.coursesAll,	
	trainerDropdownDataForEmployeeStatisticsDB: state => state.trainerDropdownDataForEmployeeStatisticsDB,	
	dateFilterValueForEmployeeStatistics: state => state.dateFilterValueForEmployeeStatistics,	
	courseFilterValueForEmployeeStatistics: state => state.courseFilterValueForEmployeeStatistics,	
	employeeStatisticsLoader: state => state.employeeStatisticsLoader,	
	employeeStatisticsData: state => state.employeeStatisticsData,
	courseFilterValue: state => state.courseFilterValue,	
	countAll: state => state.countAll,
	item: state => state.item,
	followupList: state => state.followupList,
	batchAll: state => state.batchAll,
	currentBranchIdForProps: state => state.currentBranchIdForProps,
	remindersList: state => state.remindersList,
	prospectFollowupList: state => state.prospectFollowupList,
	activeCourseList: state => state.activeCourseList,
	loading: state => state.loading,
	loadingCalendar: state => state.loadingCalendar,
	weekCalendarData: state => state.weekCalendarData,
	loadingWeekCalendar: state => state.loadingWeekCalendar,	
	courseStatusLoader: state => state.courseStatusLoader,
	pendingStudentList: state => state.pendingStudentList,
	activeStudentList: state => state.activeStudentList,
	holdStudentList: state => state.holdStudentList,
	completedStudentList: state => state.completedStudentList,
	pendingToBeMarkedAsCompletedStudentList: state => state.pendingToBeMarkedAsCompletedStudentList,
	inquiryFollowupLoader: state => state.inquiryFollowupLoader,
	reminderLoader: state => state.reminderLoader,
	getStudentAttandencesList: state => state.getStudentAttandencesList,
	getStudentAssessmentList: state => state.getStudentAssessmentList,
	getTodaysBatchData: state => state.getTodaysBatchData,
	calendarData: state => state.calendarData,
	calendarBatchData: state => state.calendarBatchData,
}

const actions = {
	async getStudentAttandences({
		commit,
		dispatch,
		state
	}, tutor_id) {
		await axiosLms.get('/api/v1/getStudentAttandences/'+tutor_id)
			.then(response => {
				if(typeof response.data.data.student_attandance != 'undefined'){
					commit('getStudentAttandencesList', response.data.data.student_attandance)
				} 
				else{
					commit('getStudentAttandencesList', [])
				}
				
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				// commit('setLoadingCalendar', false)
			})
	},
	async getStudentAssessment({
		commit,
		dispatch,
		state
	}, tutor_id) {
		await axiosLms.get('/api/v1/getStudentAssessment/'+tutor_id)
			.then(response => {
				//console.log("kevun",response.data.data.student_assessment);
				if(typeof response.data.data.student_assessment != 'undefined'){
				// console.log(response.data.data.student_assessment.length);
					commit('getStudentAssessmentList', response.data.data.student_assessment);
				}
				else{
					commit('getStudentAssessmentList', []);
				}
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				// commit('setLoadingCalendar', false)
			})
	},
	async getTutorCurrentBatchData({
		commit,
		dispatch,
		state
	}, tutor_id) {
		await axiosLms.get('/api/v1/getTutorCurrentBatchData/'+tutor_id)
			.then(response => {
				commit('getTodaysBatchData', response.data.data.current_batches)
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				// commit('setLoadingCalendar', false)
			})
	},
	setPresent({ commit, state }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {        
            axiosLms.post('/api/v1/studentAttendance/setPresent',{'id':id})
                .then(response => {
                    resolve(response);
                })
				.catch(function (error) {
					console.log('Error', error.message);
				}) 
				.finally(() => {
					commit('setError', false)
					commit('setLoading', false)
				})
        })
    },
    setAbsent({ commit, state }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {        
            axiosLms.post('/api/v1/studentAttendance/setAbsent',{'id':id})
                .then(response => {
                    resolve(response);
                })
            	.catch(function (error) {
					console.log('Error', error.message);
				}) 
				.finally(() => {
					commit('setError', false)
					commit('setLoading', false)
				})
        })
    },
	async fetchTrainerDropdownDataForEmployeeStatistics({
		commit,
		dispatch
	}) {
		await axios.get('/api/v1/trainerDropdownDataForEmployeeStatistics')
			.then(response => {
				commit('setTrainerDropdownDataForEmployeeStatistics', response.data.data)
			})
			.catch(function(error) {
				console.log('trainerDropdownDataForEmployeeStatistics',error)
			})		
	},	
	getCountForCalendar({
		commit,
		state,
		dispatch
	}, obj) {
		commit('setLoadingCalendar', true)
		// return new Promise((resolve, reject) => {
			let params = new FormData();
			params.set('prefer_batch_id', obj.prefer_batch_id)
			params.set('start_date', obj.start_date)
			params.set('lab_id', 0)

			axios.post('/api/v1/theCalendarCount', params)
				.then(response => {
					commit('setCountAll', response.data.data)
					// resolve()
				})
				.catch(function(error) {
					console.log('Error', error.message);
					// reject(error)
				})
				.finally(() => {
					commit('setLoadingCalendar', false)
				})
		// })
	},
	async fetchWeekCalendar({
		commit,
		state
	}, currentlyShowingWeekDates) {
		commit('setLoadingWeekCalendar', true)
		currentlyShowingWeekDates.push(state.courseFilterValue != null ? state.courseFilterValue.id : 0)
        return new Promise((resolve, reject) => {		
			axios.post('/api/v1/fetchWeekCalendarData', currentlyShowingWeekDates)
				.then(response => {
					commit('setWeekCalendarData', response.data.data)
					resolve(response)
				})
				.catch(function(error) {
					console.log('Error', error)
					reject(error)
				})
				.finally(() => {
					commit('setLoadingWeekCalendar', false)
				})
		})
	},		
	async fetchData({
		commit,
		dispatch
	}) {
		await dispatch('fetchInquiryAll');
		await dispatch('fetchTodaysFollowupList');
		await dispatch('fetchTodaysProspectFollowupList');
		await dispatch('fetchActiveCourseList');
		await dispatch('fetchAllCourses');
		await dispatch('fetchTrainerDropdownDataForEmployeeStatistics');
	},
	async fetchAllBatches({
		commit
	}) {
		// commit('setLoadingCalendar', true)
		await axios.get('/api/v1/allBatchesForDashboardCalendar')
			.then(response => {
				commit('setBatchesAll', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				// commit('setLoadingCalendar', false)
			})
	},
	async fetchInquiryAll({
		commit
	}) {
		commit('setLoading', true)
		await axios.get('/api/v1/trainerDailyList')
			.then(response => {
				if (response && response.data && response.data.data[0] && response.data.data[0] == 'Undefined index encountered') {
					console.log('Undefined index encountered!')
					// alert('Undefined index encountered!')
					// window.location.reload()
				}
				commit('setItem', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				commit('setLoading', false)
			})
	},
	async fetchTodaysFollowupList({
		commit
	}) {
		commit('setInquiryFollowupLoader', true)
		await axios.get('/api/v1/trainerStudentList')
			.then(response => {
				commit('setFollowupList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
			.finally(() => {
				commit('setInquiryFollowupLoader', false)
			})
	},
	async fetchTodaysRemindersList({
		commit
	}) {
		commit('setReminderLoader', true)
		await axios.get('/api/v1/todaysRemindersList')
			.then(response => {
				commit('setRemindersList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
			.finally(() => {
				commit('setReminderLoader', false)
			})
	},
	async fetchTodaysProspectFollowupList({
		commit
	}) {
		commit('setCourseStatusLoader', true)
		await axios.get('/api/v1/todaysProspectFollowupList')
			.then(response => {
				commit('setProspectFollowupList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
			.finally(() => {
				commit('setCourseStatusLoader', false)
			})
	},
	async fetchingPendingStudentList({
		commit
	}, value) {
		await axios.get('/api/v1/fetchingPendingStudentList/' + value)
			.then(response => {
				commit('setPendingStudentList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchingActiveStudentList({
		commit
	}, value) {
		await axios.get('/api/v1/fetchingActiveStudentList/' + value)
			.then(response => {
				commit('setActiveStudentList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchingHoldStudentData({
		commit
	}, value) {
		await axios.get('/api/v1/fetchingHoldStudentData/' + value)
			.then(response => {
				commit('setHoldStudentList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchingCompletedStudentData({
		commit
	}, value) {
		await axios.get('/api/v1/fetchingCompletedStudentData/' + value)
			.then(response => {
				commit('setCompletedStudentList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchingPendingToBeMarkedAsCompletedStudentData({
		commit
	}, value) {
		await axios.get('/api/v1/fetchingPendingToBeMarkedAsCompletedStudentData/' + value)
			.then(response => {
				commit('setPendingToBeMarkedAsCompletedStudentList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchActiveCourseList({
		commit
	}) {
		await axios.get('/api/v1/activeCourseList')
			.then(response => {
				commit('setActiveCourseList', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error.message);
			})
	},
	async fetchCurrentBranchId({
		commit,
		dispatch
	}) {
		await axios.get('/api/v1/currentBranchIdFromSession/')
			.then(response => {
				commit('setCurrentBranch', response.data.data)
			})
			.catch(function(error) {})
	},
	markingCourseAsCompleted({
		commit,
		state,
		dispatch
	}, dataObj) {
		commit('setLoading', true)
		dispatch('Alert/resetState', null, {
			root: true
		})
		return new Promise((resolve, reject) => {
			axios.post('/api/v1/markingCourseAsCompleted', dataObj)
				.then(response => {
					resolve()
				})
				.catch(function(error) {
					console.log('Error', error.message);
				})
				.finally(() => {
					commit('setLoading', false)
				})
		})
	},
	async fetchAllCourses({
		commit
	}) {
		// commit('setLoadingCalendar', true)
		await axios.get('/api/v1/fetchAllCourses')
			.then(response => {
				commit('setAllCourses', response.data.data)
			})
			.catch(function(error) {
				console.log('Error', error);
			})
			.finally(() => {
				// commit('setLoadingCalendar', false)
			})
	},		
	async fetchEmployeeStatisticsData({
		commit,
		dispatch,
		state
	}, course_id) {
		commit('setLoadingEmployeeStatistics', true)
		await axios.get('/api/v1/fetchEmployeeStatisticsData/' + course_id + '/' 
			+ (state.joining_date != '' ? state.joining_date.replace(/\//g,'-') : null) + '/'
			+ (state.exit_date != '' ? state.exit_date.replace(/\//g,'-') : null))
			.then(response => {
				commit('setEmployeeStatisticsData', response.data.data)
			})
			.catch(function(error) {
				console.log('fetchEmployeeStatisticsData',error)
			})
			.finally(() => {
				commit('setLoadingEmployeeStatistics', false)
			})			
	},		
	resetState({
		commit
	}) {
		commit('resetState')
	},
	setCourseStatusLoader({
		commit
	}, value) {
		commit('setCourseStatusLoader', value)
	},
	setLoadingWeekCalendar({
		commit
	}, value) {
		commit('setLoadingWeekCalendar', value)
	},	
	setTrainerDropdownDataForEmployeeStatistics({
		commit
	}, value) {
		commit('setTrainerDropdownDataForEmployeeStatistics', value)
	},
	setWeekCalendarData({
		commit
	}, value) {
		commit('setWeekCalendarData', value)
	},		
	setBatchesAll({
		commit
	}, value) {
		commit('setBatchesAll', value)
	},	
	setCourseFilterValueForEmployeeStatistics({
		commit
	}, value) {
		commit('setCourseFilterValueForEmployeeStatistics', value)
	},	
	setDateFilterValueForEmployeeStatistics({
		commit
	}, value) {
		commit('setDateFilterValueForEmployeeStatistics', value)
	},	
	setEmployeeStatisticsData({
		commit
	}, value) {
		commit('setEmployeeStatisticsData', value)
	},		
	setCourseFilterValue({
		commit
	}, value) {
		commit('setCourseFilterValue', value)
	},	
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },
	getStudentAttandencesList({ commit }, value) {
		commit('getStudentAttandencesList', value)
	},
	getStudentAssessmentList({ commit }, value) {
		commit('getStudentAssessmentList', value)
	},
	getTodaysBatchData({ commit }, value) {
		commit('getTodaysBatchData', value)
	},
	async fetchTrainnerCalendarData({ commit },data) {
		commit('setCalendarLoader', true)
		await axiosLms.post('/api/v1/fetchTrainnerCalendarData',data)
		.then(response => {
			if(response.data.result) {
				commit('setCalendarData', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
		.finally(() => {
			commit('setCalendarLoader', false)
		})
	},
	async fetchTrainnerScheduleBatchDetail({ commit },data) {
        await axiosLms.post('/api/v1/getTrainnerCalenderbatchDetails',data)
		.then(response => {
			commit('setScheduleBatchDetails', response.data.data)
		})
		.catch(function (error) {
			let message = error.response.data.message || error.message
			let errors  = error.response.data.errors
			dispatch(
				'Alert/setAlert',
				{ message: message, errors: errors, color: 'danger' },
				{ root: true })
			reject(error)
		})
    },
	setScheduleBatchDetails({ commit }, value) {
		commit('setScheduleBatchDetails', value)
	},
}

const mutations = {
	setCalendarData(state, value) {
		state.calendarData = value
	},
	setScheduleBatchDetails(state, value) {
		state.calendarBatchData = value
	},
	setCalendarLoader(state, calendarLoader) {
		state.calendarLoader = calendarLoader
	},
	setItem(state, item) {
		state.item = item
	},
	setAllCourses(state, value) {
		state.coursesAll = value
	},	
    setJoining_date(state, value) {
        state.joining_date = value
    },
    setExit_date(state, value) {
        state.exit_date = value
    },		
	setTrainerDropdownDataForEmployeeStatistics(state, value) {
		state.trainerDropdownDataForEmployeeStatisticsDB = value
	},
	setCourseFilterValue(state, value) {
		state.courseFilterValue = value
	},	
	setCurrentBranch(state, value) {
		state.currentBranchIdForProps = value
	},
	setBatchesAll(state, value) {
		state.batchAll = value
	},
	setCountAll(state, value) {
		state.countAll = value
	},
	setLoadingCalendar(state, loadingCalendar) {
		state.loadingCalendar = loadingCalendar
	},
	setLoadingWeekCalendar(state, loadingWeekCalendar) {
		state.loadingWeekCalendar = loadingWeekCalendar
	},		
	setWeekCalendarData(state, value) {
		state.weekCalendarData = value
	},		
	setFollowupList(state, value) {
		state.followupList = value
	},
	setPendingStudentList(state, value) {
		state.pendingStudentList = value
	},
	setActiveStudentList(state, value) {
		state.activeStudentList = value
	},
	setHoldStudentList(state, value) {
		state.holdStudentList = value
	},
	setCompletedStudentList(state, value) {
		state.completedStudentList = value
	},
	setPendingToBeMarkedAsCompletedStudentList(state, value) {
		state.pendingToBeMarkedAsCompletedStudentList = value
	},
	setRemindersList(state, value) {
		state.remindersList = value
	},
	setProspectFollowupList(state, value) {
		state.prospectFollowupList = value
	},
	setActiveCourseList(state, value) {
		state.activeCourseList = value
	},
	setCourseStatusLoader(state, courseStatusLoader) {
		state.courseStatusLoader = courseStatusLoader
	},
	setInquiryFollowupLoader(state, inquiryFollowupLoader) {
		state.inquiryFollowupLoader = inquiryFollowupLoader
	},
	setReminderLoader(state, loader) {
		state.reminderLoader = loader
	},
	setLoading(state, loading) {
		state.loading = loading
	},
	setLoadingEmployeeStatistics(state, loader) {
		state.employeeStatisticsLoader = loader
	},	
	setCourseFilterValueForEmployeeStatistics(state, value) {
		state.courseFilterValueForEmployeeStatistics = value
	},
	setDateFilterValueForEmployeeStatistics(state, value) {
		state.dateFilterValueForEmployeeStatistics = value
	},	
	setEmployeeStatisticsData(state, value) {
		state.employeeStatisticsData = value
	},	
	resetState(state) {
		state = Object.assign(state, initialState())
	},
	getStudentAttandencesList(state, value) {
		state.getStudentAttandencesList = value
	},
	getStudentAssessmentList(state, value) {
		state.getStudentAssessmentList = value
	},
	getTodaysBatchData(state, value) {
		state.getTodaysBatchData = value
	},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations
}