function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            batch_name: null,
            course_type: null,
            start_time: null,            
            end_time: null,            
            batch_start_date: null,
            batch_end_date: null,            
            seat_limit: null,
            location: null,
            variation_seat: null,
            workingDay1:null,
            workingDay2:null,
            workingDay3:null,
            workingDay4:null,
            workingDay5:null,
            workingDay6:null,
            workingDay7:null,
        },
        coursesAll: [],
        locationAll: [],
        branchWorkingDaysAll : [],
        loading: false,
        courseVertical:[],
        courseCategory:[],
        branchLabs:[],
        branchTutors:[],
        editBatchData:[],
        showBatchData:[]
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    coursesAll: state => state.coursesAll,
    branchWorkingDaysAll: state => state.branchWorkingDaysAll,
    locationAll: state => state.locationAll,
    courseVertical: state => state.courseVertical,
    courseCategory: state => state.courseCategory,
    branchLabs: state => state.branchLabs,
    branchTutors: state => state.branchTutors,
    getEditBatchData: state => state.editBatchData,
    getShowBatchData: state => state.showBatchData,
}

const actions = {
    async fetchBranchLab({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/branchLabList')
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setBranchLab', data)
                }
                else{
                    commit('setBranchLab', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    async fetchBranchTutor({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/tutorList')
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setBranchTutor', data)
                }
                else{
                    commit('setBranchTutor', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Course Vertical','Course Category']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setCourseVertical', data['Course Vertical'])
                    commit('setCourseCatgory', data['Course Category'])
                }
                else{
                    commit('setCourseVertical', [])
                    commit('setCourseCatgory', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    async fetchShowBatchDetail({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/batch-masters/'+id)
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setShowBatchDetail', data)
                    commit('setLoading', false)
                }
                else{
                    commit('setShowBatchDetail', [])
                    commit('setLoading', false)
                }
            })
            .catch(function (error) {
                commit('setLoading', false)
                          console.log('Error', error.message);
                    })                
    },
    async fetchEditBatchDetail({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/batch-masters/'+id)
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setEditBatchDetail', data)
                }
                else{
                    commit('setEditBatchDetail', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    storeData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            //console.log("dfdfd",data);
            for (let fieldName in data) {
                let fieldValue = data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } 
            }

            if (_.isEmpty(data.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', data.course_vertical.id)
            }

            if (_.isEmpty(data.branch_lab)) {
                params.set('branch_lab_id', '')
            } else {
                params.set('branch_lab_id', data.branch_lab.id)
            }

            if (_.isEmpty(data.course_category)) {
                params.set('course_category_id', '')
            } else {
                for (let index in data.course_category) {
                    params.set('course_category_id['+index+']',data.course_category[index].id)
                }
            }

            if (_.isEmpty(data.tutor)) {
                params.set('tutor_id', '')
            } else {
                for (let index in data.tutor) {
                    params.set('tutor_id['+index+']',data.tutor[index].id)
                }
            }

            if (_.isEmpty(data.checked_days)) {
                params.set('workingDay', '')
            } else {
                params.set('workingDay',data.checked_days.join());
            }


          

            // if (_.isEmpty(state.item.course_type)) {
            //     params.set('course_type_id', '')
            // } else {
            //     params.set('course_type_id', state.item.course_type.value_id)
            // }
            // if (_.isEmpty(state.item.location)) {
            //     params.set('location_id', '')
            // } else {
            //     params.set('location_id', state.item.location.id)
            // }

            // if (_.isEmpty(state.item.batch_start_date)) {
            //     params.set('batch_start_date', '')
            // } else {
            //     params.set('batch_start_date', state.item.batch_start_date)
            // }

            // if (_.isEmpty(state.item.batch_end_date)) {
            //     params.set('batch_end_date', '')
            // } else {
            //     params.set('batch_end_date', state.item.batch_end_date)
            // }
            // params.set('seat_limit', 0)
            // params.set('variation_seat', 0)
            axiosLms.post('/api/v1/batch-masters', params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            //console.log("dfdfd",data);
            for (let fieldName in data) {
                let fieldValue = data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } 
            }

            if (_.isEmpty(data.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', data.course_vertical.id)
            }

            if (_.isEmpty(data.branch_lab)) {
                params.set('branch_lab_id', '')
            } else {
                params.set('branch_lab_id', data.branch_lab.id)
            }

            if (_.isEmpty(data.course_category)) {
                params.set('course_category_id', '')
            } else {
                for (let index in data.course_category) {
                    params.set('course_category_id['+index+']',data.course_category[index].id)
                }
            }

            if (_.isEmpty(data.tutor)) {
                params.set('tutor_id', '')
            } else {
                for (let index in data.tutor) {
                    params.set('tutor_id['+index+']',data.tutor[index].id)
                }
            }

            if (_.isEmpty(data.checked_days)) {
                params.set('workingDay', '')
            } else {
                params.set('workingDay',data.checked_days.join());
            }

            axiosLms.post('/api/v1/batch-masters/'+data.id, params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/batch-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
        dispatch('fetchCoursesAll');
        dispatch('fetchLocationAll');
    },
    fetchCoursesAll({ commit }) {
        axios.get('/api/v1/courseTypeList')
            .then(response => {
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    fetchLocationAll({ commit }) {
        // axios.get('/api/v1/location-masters')
        axios.get('/api/v1/locationBranchWiseListing')
            .then(response => {
                commit('setLocationAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })          
    },
    async fetchBranchWorkingDaysAll({ commit }) {
        await axios.get('/api/v1/getBranchWorkingDays')
            .then(response => {
                //console.log('aaaa',response.data.data.working_days)
                commit('setBranchWorkingDaysAll', (response.data.data.working_days).split(","))
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })            
    },   
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setBatch_name({ commit }, value) {
        commit('setBatch_name', value)
    },
    setCourse_type({ commit }, value) {
        commit('setCourse_type', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setLocation({ commit }, value) {
        commit('setLocation', value)
    },
    setBatchStart_date({ commit }, value) {
        commit('setBatchStart_date', value)
    },
    setBatchEnd_date({ commit }, value) {
        commit('setBatchEnd_date', value)
    },
    setWorkingDay1({ commit }, value) {
        commit('setWorkingDay1', value)
    },
    setWorkingDay2({ commit }, value) {
        commit('setWorkingDay2', value)
    },
    setWorkingDay3({ commit }, value) {
        commit('setWorkingDay3', value)
    },
    setWorkingDay4({ commit }, value) {
        commit('setWorkingDay4', value)
    },
    setWorkingDay5({ commit }, value) {
        commit('setWorkingDay5', value)
    },
    setWorkingDay6({ commit }, value) {
        commit('setWorkingDay6', value)
    },
    setWorkingDay7({ commit }, value) {
        commit('setWorkingDay7', value)
    },
    setSeat_limit({ commit }, value) {
        commit('setSeat_limit', value)
    },
    setBranchWorkingDaysAll({ commit }, value) {
          commit('setBranchWorkingDaysAll', value)
    },
    setVariation_seat({ commit }, value) {
        commit('setVariation_seat', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setShowBatchDetail(state,data){
        state.showBatchData = data
    },
    setEditBatchDetail(state,data){
        state.editBatchData = data
    },
    setBranchTutor(state,tutors){
        state.branchTutors = tutors;
    },
    setBranchLab(state,labs){
        state.branchLabs = labs;
    },
    setCourseVertical(state,course_verticals){
        state.courseVertical = course_verticals;
    },
    setCourseCatgory(state,course_category){
        state.courseCategory = course_category;
    },
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setBatch_name(state, value) {
        state.item.batch_name = value
    },
    setCourse_type(state, value) {
        state.item.course_type = value
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setBatchStart_date(state, value) {
        state.item.batch_start_date = value
    },
    setBatchEnd_date(state, value) {
        state.item.batch_end_date = value
    },
    setLocation(state, value) {
        state.item.location = value
    },
    setLocationAll(state, value) {
        state.locationAll = value
    },
    setSeat_limit(state, value) {
        state.item.seat_limit = value
    },
    setVariation_seat(state, value) {
        state.item.variation_seat = value
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },
    setWorkingDay1(state, value) {
        state.item.workingDay1 = value
    },
    setWorkingDay2(state, value) {
        state.item.workingDay2 = value
    },
    setWorkingDay3(state, value) {
        state.item.workingDay3 = value
    },
    setWorkingDay4(state, value) {
        state.item.workingDay4 = value
    },
    setWorkingDay5(state, value) {
        state.item.workingDay5 = value
    },
    setWorkingDay6(state, value) {
        state.item.workingDay6 = value
    },
    setWorkingDay7(state, value) {
        state.item.workingDay7 = value
    },
    setBranchWorkingDaysAll(state, value) {
        state.branchWorkingDaysAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
