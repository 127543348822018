<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Admin Sliders</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/admin-slider'">Admin Sliders</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Admin Slider</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Edit Admin Slider</h3>
                        </div>
                        <bootstrap-alert />
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="name" class="form-group">
                                            <label for="user">User<p class="astrisk">*</p></label>
                                            <v-select
                                                name="name"
                                                label="name"
                                                id="user"
                                                v-validate="'required'"
                                                v-model="item.name"
                                                :options="userAll"
                                            />
                                            <span class="error" v-show="errors.has('name')">
                                                {{ $root.updateErrorMessage('name',this.errors, 'User')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div ref="city" class="form-group">
                                            <label for="city">City</label>
                                            <v-select
                                                name="city"
                                                label="city_name"
                                                placeholder="Select City"
                                                @change="updateCity"
                                                :value="item.city"
                                                :options="cityAll"
                                                />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="age">Minimun Age</label>
                                        <v-select
                                            name="age"
                                            label="age"
                                            @input="updateMinAge"
                                            v-validate="{required:(this.maxAge != null),max_value:this.maxAge}"
                                            v-model="minAge"
                                            :options="ageAll1"
                                        />
                                        <span class="error" v-show="errors.has('age')">
                                            {{ $root.updateErrorMessage('age',this.errors, 'Min age')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="age">Maximum Age</label>
                                    <v-select
                                        name="max_age"
                                        label="age"
                                        @input="updateMaxAge"
                                        v-validate="{required:(this.minAge != null),min_value:this.minAge}"
                                        v-model="maxAge"
                                        :options="ageAll1"
                                    />
                                    <span class="error" v-show="errors.has('max_age')">
                                        {{ $root.updateErrorMessage('max_age',this.errors, 'Max age')}}
                                    </span>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="logo">Image<p class="astrisk">*</p></label>
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            v-validate="!this.img?'required|':''+'size:2048|ext:jpg,png,jpeg'"
                                            class="form-control"
                                            @change="updateImage"
                                        >
                                        <span style="font-size: 13px;"><b>Note :</b> JPG, JPEG, PNG files are allowed. File size should be less than or equals to 2 MB.</span><br/>
                                        <span class="error" v-show="errors.has('image')">
                                            {{ $root.updateErrorMessage('image',this.errors,'image')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="city">URL</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="url"
                                        ref="url"
                                        v-validate="{url: {require_protocol: true }}" 
                                        data-vv-as="field"
                                        placeholder="Enter URL"
                                        v-model="item.url"
                                    >
                                    <span class="error" v-show="errors.has('url')">
                                        {{ $root.updateErrorMessage('url',this.errors,'URL')}}
                                    </span>
                                </div>
                                <div class="col-md-12">
                                    <span v-if="item.image_url">
                                        <div class="" style="">
                                            <div v-if="item.image_url != null">
                                                <img :src="item.image_url" id="blah" class="slider-img my-image slider_image img-responsive mt-3 mb-3" :alt="item.image" >
                                            </div>
                                            <div v-else>
                                                <img src="/assets/img/sliderimages.png" class="slider-img my-image slider_image img-responsive mt-3 mb-3" :alt="item.image" >
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-xs btn-danger mb-3 mt-2"
                                                type="button"
                                                @click="removeImage"
                                            >
                                                Remove file
                                            </button>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 col-md-12 savebtn">
                               <vue-button-spinner
                                    class="std_information_save col-md-2 col-md-2 btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                >
                                    Save
                                </vue-button-spinner>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {  },
    data() {
        return {
            minAge:null,
            maxAge:null,
            ageAll1:[6,7,8,9,10,11,12,13,14,15,16,17,18],
            img:null
        }
    },
    computed: {
        ...mapGetters('AdminSliderSingle', ['item', 'loading', 'userAll','getEditData', 'editData','cityAll']),
    },
    created() {
        this.fetchUserAll()
        this.fetchCityAll()
        this.fetchEditData(this.$route.params.id)
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchEditData(this.$route.params.id)
        },
        getEditData(value) {
            this.minAge = value.min_age
            this.maxAge = value.max_age
            this.img = value.image
        }
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('AdminSliderSingle', ['setUser', 'fetchUserAll', 'setCity', 'setMinAge', 'setMaxAge', 'setImage', 'setImageURL', 'setUrl', 'updateData', 'resetState','fetchCityAll','setCity','setCityAll', 'fetchEditData']),

        updateMinAge(e) {
            this.setMinAge(e)
        },
        updateMaxAge(e) {
            this.setMaxAge(e)
        },
        removeImage(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.img = null;
                    this.setImage('');
                    this.setImageURL('');
                    $("#image").val('');
                }
            })
        },
        updateCity(value) {
            console.log(value)
            this.setCity(value)
        },
        updateImage(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setImage(e.target.files[0]);
                    this.setImageURL(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#image").val('');
                            this.$validator.errors.remove('image');
                            this.setImage('');
                            this.setImageURL('');
                        }
                    })
                }
            }
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.updateData()
                    .then((res) => {
                        if(res.data.result == false){
                           this.$eventHub.$emit('inquiry-exists') 
                        } else {
                            this.$router.push({ name: 'admin-slider.index' })
                            this.$eventHub.$emit('update-success')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        }
    }
}
</script>

<style>
.slider_image {
    position: relative;
    width: 1666px !important;
    height: 250px !important;
    object-fit: cover;
    margin: 25px 0px;
}
.slider-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
}
.img-responsive{
    max-width: 100%;
    height: auto;
}
.break-url {
    word-break: break-word;
}
</style>
