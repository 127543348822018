<template>
    <div class="container remove-top-bottom">
        <button id="generatePDF" @click="generatePDF()" v-if="item != '' && item.skill_data != '' && item.skill_data != ''" class="btn btn-default float-right" title="Download Report Card"><i class="fa fa-download" aria-hidden="true"></i></button>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="reportcardpdf"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="[0,0,0,0]"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            ref="html2Pdf"
            :html-to-pdf-options="{margin: 0, left:0,}"
            :htmlToPdfOptions="{
                filename: item.student_name+'-'+item.report_no,
                margin: [0, 0, 0, 0],
                html2canvas: {
					useCORS: true
				},
            }"
        >
            <div slot="pdf-content" style="background-color:white;" v-if="item != '' && item.skill_data != '' && item.skill_data != ''">
                <div align="center" class="report-card-cover1 pdf-item">
                    <img src="/assets/images/Logo.svg" alt="">
                    <p class="v1"></p>
                    <h1 class="title">Report Card</h1>
                    <p class="v1"></p>
                    <p class="name">{{item.course_name ? item.course_name : ''}}</p>
                    <p class="name">{{item.student_name ? item.student_name : ''}}</p>
                </div>
                <div class="html2pdf__page-break"/>
                <div class="row report-card-cover2 pdf-item">
                    <div class="col-md-8 rfllogo">
                        <img src="/assets/images/Logo.svg" alt="">
                    </div>
                    <div class="col-md-4 header-date">
                        <p>Issue Date- {{item.issue_date ? item.issue_date : ''}}</p>
                        <p>Report No.- {{item.report_no ? item.report_no : ''}}</p>
                    </div>
                    <div class="col-md-12 course">
                        <h3 class="sub-title">{{item.course_name ? item.course_name : ''}} - Report card</h3>
                        <p class="name1"><span class="boder">{{item.student_name ? item.student_name : ''}}</span></p>
                        <div class="report-card-container">
                            <p>{{item.course_content ? item.course_content : ''}}</p>
                        </div>
                    </div>
                    <div class="col-md-12 score" v-if="item.graph_data != ''">
                        <p class="name2"><span class="boder">Score Card</span></p>
                        <p class="name4"><small>Overall Performance</small></p>
                        <div class="fortinet-wrapper">
                            <div class="vertical-arrow">
                                <div class="vertical-arrow-up"></div>
                                <div class="vertical-arrow-down"></div>
                            </div>
                            <div class="horizontal-arrow">
                                <div class="horizontal-arrow-left"></div>
                                <div class="horizontal-arrow-right"></div>
                                <div class="horizontal-bottom-text">
                                    <span>RISING STAR</span>
                                    <span>ALL STAR</span>
                                    <span>CHAMPION</span>
                                </div>
                            </div>
                            <div class="fortinet-row"  v-for="(value, index) in item.graph_data" :key="index">
                                <div class="fortinet-icon" v-if="value.image != null">
                                    <img :src="value.image" width="20px" height="20px" style="margin-left:15px;">
                                </div>
                                <div class="fortinet-icon" v-else>
                                    <img src="/assets/images/logo.svg" width="20px" height="20px" style="margin-left:15px;">
                                </div>
                                <div class="fortinet-info">
                                    <div class="fortinet-bar" style="height:20px;" :style="'width:'+value.per+'%'">
                                        <div class="fortinet-bar-point green-bar" v-if="value.student_category == 'Champion'"></div>
                                        <div class="fortinet-bar-point yellow-bar" v-else-if="value.student_category == 'All star'"></div>
                                        <div class="fortinet-bar-point red-bar" v-else></div>
                                    </div>
                                    <div class="fortinet-title">
                                        <span>{{value.skill_name ? value.skill_name : ''}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <star-rating
                            class="scoreCard"
                            :star-size="30"
                            :increment="0.01"
                            :show-rating="false"
                            :read-only="true"
                            active-color="#00D746"
                            inactive-color="#737373"
                            :rating="item.final_percentage?Math.floor(item.final_percentage/20 * 100)/100:0"
                            :key="item.final_percentage"
                        />
                        <p class="name3"><small>{{item.final_student_category ? item.final_student_category : ''}}</small></p>
                    </div>
                    <div class="row footer">
                    <div class="col-md-8">
                        <p><small>www.robofunlab.com</small></p>
                    </div>
                    <div class="col-md-4">
                        <p><small>
                            <img src="/assets/images/fb.png"/>
                            <img src="/assets/images/insta.png"/>
                            <img src="/assets/images/youtube.png"/>
                            rflacademy
                        </small></p>
                    </div>
                </div>
                </div>
                <div class="row report-card-cover3" v-if="item.skill_data != ''">
                    <div class="col-md-8 rfllogo">
                        <img src="/assets/images/Logo.svg" alt="">
                    </div>
                    <div class="col-md-4 header-date">
                        <p>Issue Date- {{item.issue_date ? item.issue_date : ''}}</p>
                        <p>Report No.- {{item.report_no ? item.report_no : ''}}</p>
                    </div>
                    <div class="col-md-12 rubricsheet" >
                        <p class="name2"><span class="boder">Rubric Sheet</span></p>
                        <table class="pdf-item">
                            <tr>
                                <th>SR NO.</th>
                                <th>HEADER</th>
                                <th>SKILL</th>
                                <th>RISSING STAR</th>
                                <th>ALL STAR</th>
                                <th>CHAMPION</th>
                            </tr>
                            <tr v-for="(value,index) in filteredArray" :key="index">
                                <td
                                    class="index-td"
                                    :rowspan="value.addRowspan ? value.count : 1"
                                    v-if="value.addRowspan"
                                >
                                    {{value.sr_no}}
                                </td>
                                <td
                                    class="header-td"
                                    :rowspan="value.addRowspan ? value.count : 1"
                                    v-if="value.addRowspan"
                                >
                                    <span>{{value.header_name ? value.header_name : ''}}</span>
                                </td>
                                <td>
                                    {{value.skill_name ? value.skill_name : ''}}
                                </td>
                                <td>
                                    {{value.rising_star ? value.rising_star : ''}}
                                </td>
                                <td>
                                    {{value.all_star ? value.all_star : ''}}
                                </td>
                                <td>
                                    {{value.champion ? value.champion : ''}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row footer" v-if="item.skill_data != ''">
                    <div class="col-md-8">
                        <p><small>www.robofunlab.com</small></p>
                    </div>
                    <div class="col-md-4">
                        <p><small>
                            <img src="/assets/images/fb.png"/>
                            <img src="/assets/images/insta.png"/>
                            <img src="/assets/images/youtube.png"/>
                            rflacademy
                        </small></p>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-danger">There is no report card for this course.</p>
            </div>
        </vue-html2pdf>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    props:['enroll_id'],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            htmlToPdfOptions: {margin: 0}
        }
    },
    computed: {
        ...mapGetters('ReportCardPDF', ['item','loading']),

        filteredArray() {
            const arr = this.item.skill_data || []
            const counts = {}
            arr.forEach((obj) => {
                if(counts[obj.skill_header_id] === undefined) {
                    counts[obj.skill_header_id] = 1
                } else {
                    counts[obj.skill_header_id]++
                }
            })
            let array = []
            let i = 1
            arr.forEach((obj) => {
                if(!array.includes(obj.skill_header_id)) {
                    array.push(obj.skill_header_id)
                    obj.addRowspan = true
                    obj.sr_no = i
                    i++
                }
                obj.count = counts[obj.skill_header_id]
            })
            return arr
        }
    },
    watch: {
        "$route.params.id": function() {
            this.getReportCard(this.$route.params.id)     
        },
    }, 
    created(){
        this.getReportCard(this.$route.params.id)
        .then((res) => {
            if(res.data.length == 0) {
               this.$router.push('/404')
            } else {
                $('#generatePDF').trigger('click')
            }
            this.$emit('changeFlag')
            // if(res.data.result == false) {
            //     this.$awn.alert('Course is not completed.')
            // }
      })
    },
    methods:{
        ...mapActions('ReportCardPDF', ['getReportCard']),
        
        onProgress(event) {
            console.log(`Processed: ${event} / 100`)
        },
        hasGenerated() {
            alert("PDF generated successfully!")
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf()
        }
    }
}
</script>
