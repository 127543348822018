<template>
    <div class="popup-form add-country">
    <b-modal id="editStudentCourse"
             ref="modal"
             title="Edit Student Course"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <StudentEditCourse @loadItems="loadItems" v-if="id" @closeModel="closeModel" :id="id"></StudentEditCourse>
    </b-modal>
  </div>
</template>
<script>
import StudentEditCourse from '@/components/cruds/Student/StudentEditCourse'
export default {
    props:['id'],
    components:{
        StudentEditCourse
    },
    methods: {
        loadItems(){
            this.$emit('loadItems');
        },
         handleCancel(evt){
            
        }, 
        closeModel(){
            this.$refs.modal.hide();
        },   
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.licenseName = '';
            this.listOption = 1;
            this.start_date = '';
            this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

