<template>
    <div class="popup-form add-country">
    <b-modal id="addCompleteCourseModel"
             ref="addCompleteCourseModel"
             title="Complete Course"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <addCompleteCourse :id="id" :studentEnrollId="studentEnrollId" @closeModel="closeModel" @loadItems="loadItems"></addCompleteCourse>
    </b-modal>
  </div>
</template>
<script>
import addCompleteCourse from '@/components/cruds/StudentAttendance/addCompleteCourse'
export default {
    props: ['id','studentEnrollId'],
    components:{
        addCompleteCourse
    },
    methods: {
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){
            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(){
            this.$refs.addCompleteCourseModel.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

