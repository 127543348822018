<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Inquiries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/inquiries'">Inquiries</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Inquiry</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Inquiry</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                       <div class="col-md-6 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Front Inquiry Type</th>
                                    <td>
                                        <span class="label label-primary">{{ inquiryShowData.front_inquiry_name }}</span>
                                    </td>
                                </tr>  
                                <tr>
                                    <th>Inquiry Type</th>
                                    <td>
                                        <span class="label label-primary">{{ inquiryShowData.inquiry_type1 }}</span>
                                    </td>
                                </tr>                                            
                                <tr>
                                    <th>Inquiry Date</th>
                                    <td>{{ inquiryShowData.original_date }}</td>
                                </tr>
                                <tr>
                                    <th>Student Name</th>
                                    <td>{{ inquiryShowData.student_name }}</td>
                                </tr>
                                <tr>
                                    <th>Age</th>
                                    <td>{{ inquiryShowData.age }}</td>
                                </tr>  
                                <tr>
                                    <th>Organization Name</th>
                                    <td>{{ inquiryShowData.organization_name }}</td>
                                </tr> 
                                <tr>
                                    <th>Contact Name</th>
                                    <td>{{ inquiryShowData.contact_name }}</td>
                                </tr>
                                <tr>
                                    <th>Contact Number</th>
                                    <td>{{ inquiryShowData.contact_number }}</td>
                                </tr>  
                                 <tr>
                                    <th>Standard</th>
                                    <td>{{ inquiryShowData.standard }}</td>
                                </tr>  
                                <tr>
                                    <th>School Name</th>
                                    <td>{{ inquiryShowData.school_name }}</td>
                                </tr> 
                                 <tr>
                                    <th>Course Name</th>
                                    <td>{{ inquiryShowData.course_name }}</td>
                                </tr>    
                                <tr>
                                    <th>Assigned To</th>
                                    <td>{{ inquiryShowData.employee_name }}</td>
                                </tr>       
                                <tr>
                                    <th>Message</th>
                                    <td>{{ inquiryShowData.message }}</td>
                                </tr>                                         
                                </tbody>
                            </table>
                        </div>        
                        <div class="col-md-6 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Inquiry Remark</th>
                                    <td>{{ (inquiryShowData.inquiry_remark != '' && inquiryShowData.inquiry_remark != null)?inquiryShowData.inquiry_remark:'-' }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 150px;">Source</th>
                                    <td>
                                        <span v-if="inquiryShowData.source_name !== null">
                                            {{ inquiryShowData.source_name }}
                                        </span>
                                    </td>
                                </tr> 
                                <tr>
                                    <th>Medium</th>
                                    <td>
                                        <span v-if="inquiryShowData.medium_name !== null">
                                            {{ inquiryShowData.medium_name }}
                                        </span>
                                    </td>
                                </tr>  
                                <tr>
                                    <th>Campaign</th>
                                    <td>
                                        <span v-if="inquiryShowData.campaign_name !== null">
                                            {{ inquiryShowData.campaign_name }}
                                        </span>
                                    </td>
                                </tr>                                                  
                                <tr>
                                    <th>Inquiry Status</th>
                                    <td>
                                        <span v-if="inquiryShowData.inquiry_status == 1" class="badge badge-info">
                                            {{ inquiryShowData.status.name }}
                                        </span>
                                        <span v-if="inquiryShowData.inquiry_status == 2" class="badge badge-primary">
                                            {{ inquiryShowData.status.name }}
                                        </span>
                                        <span v-if="inquiryShowData.inquiry_status == 3" class="badge badge-success">
                                            {{ inquiryShowData.status.name }}
                                        </span>
                                        <span v-if="inquiryShowData.inquiry_status == 4" class="badge badge-danger">
                                            {{ inquiryShowData.status.name }}
                                        </span>                                                                                                                                                
                                    </td>
                                </tr>
                                <tr>
                                    <th>Next Followup Date</th>
                                    <td>{{ inquiryShowData.next_followup_date }}</td>
                                    </tr>
                                <tr>
                                    <th>Reason</th>
                                    <td>
                                        <span class="badge badge-info" v-if="inquiryShowData.reason !== null && inquiryShowData.reason !== '-'">
                                            {{ inquiryShowData.reason }}
                                        </span>
                                        <span v-else>-</span>

                                    </td>
                                </tr>
                                <tr>
                                    <th>Status Remarks</th>
                                    <td>{{ inquiryShowData.status_remarks }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                   
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchInquiryData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('InquiriesSingle', ['inquiryShowData','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchInquiryData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('InquiriesSingle', ['fetchInquiryData', 'resetState']),        
         /*this function use to store fees total*/
    }
}
</script>


<style scoped>

</style>
