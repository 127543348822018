<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventTreeView"
                ref="modal"
                title="View All Topics/SubTopics and their Question/s Details"
                @shown="openModel" 
                size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" v-if="!loading">
                                <div class="row align_centre">
                                    <div class="col-md-12" v-if="AllTopicsSubTopics.length > 0">
                                        <div class="timeline" v-for="(row, index) in AllTopicsSubTopics" :key="index">
                                            <div class="time-label">
                                            <span class="bg-success">{{row.project_name}}</span>
                                            </div>
                                            <div class="timeline" v-if="row.topics.length > 0">
                                                <div v-for="(topic, k) in row.topics" :key="k">
                                                    <i class="fas fa-book bg-success" title="Topic"></i>
                                                    <div class="timeline-item">
                                                        <h3 class="timeline-header">{{topic.topic_name}}</h3>
                                                        <div class="timeline pt-4 m-0" v-if="topic.questions.length > 0">
                                                            <div class="time-label" @click="openTopicQuestion">
                                                                <span class="bg-gray ml-2">Topic Question/s</span>
                                                            </div>
                                                            <div class="timeline tm-que"  v-if="topic.questions.length > 0">
                                                                <div class="question" v-for="(que, j) in topic.questions" :key="j">
                                                                    <i class="fas fa-list bg-grey" title="Topic Question/s"></i>
                                                                    <div class="timeline-item">
                                                                        <h3 class="timeline-header">{{que.quiz_text}}</h3>
                                                                        <div v-if="que.quiz_image_url !='' && que.quiz_image != null">
                                                                            <img :src="que.quiz_image_url" class="profile-user-img my-profile img-responsive img-circle" :alt="que.quiz_image_url" > 
                                                                        </div>
                                                                        <div v-if="que.quiz_skill.length > 0">
                                                                            <span class="timeline-header" style="padding:10px;" v-for="(skill_point, k) in que.quiz_skill" :key="k">{{skill_point.skill.skill_name}} | P : {{Number(skill_point.points)}} | W : {{Number(skill_point.weightage)}} <span v-if="k+1 != que.quiz_skill.length">,</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="timeline pt-4 m-0" v-if="topic.task_for_tutors.length > 0">
                                                            <div class="time-label" @click="openTopicQuestion">
                                                                <span class="bg-gray ml-2">Topic Task For Tutors</span>
                                                            </div>
                                                            <div class="timeline tm-que"  v-if="topic.task_for_tutors.length > 0">
                                                                <div class="question" v-for="(que, j) in topic.task_for_tutors" :key="j">
                                                                    <i class="fas fa-list bg-grey" title="Topic Task For Tutors"></i>
                                                                    <div class="timeline-item">
                                                                        <h3 class="timeline-header">{{que.question}}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="timeline pt-4" v-if="topic.subtopics.length > 0">
                                                            <div class="time-label">
                                                                <span class="bg-blue ml-2">SubTopics</span>
                                                            </div>
                                                            <div class="subtopic" v-for="(subtopic, i) in topic.subtopics" :key="i">
                                                                <i class="fa fa-folder-open bg-blue" title="SubTopic"></i>
                                                                <div class="timeline-item">
                                                                    <h3 class="timeline-header">{{subtopic.topic_name}}</h3>
                                                                    <div class="timeline pt-4 m-0" v-if="subtopic.questions.length > 0">
                                                                        <div class="time-label">
                                                                            <span class="bg-gray ml-2">SubTopic Question/s</span>
                                                                        </div>
                                                                        <div class="timeline tm-que" >
                                                                            <div class="question" v-for="(subque, j) in subtopic.questions" :key="j">
                                                                                <i class="fas fa-list bg-grey" title="SubTopic Question/s"></i>
                                                                                <div class="timeline-item">
                                                                                    <h3 class="timeline-header">{{subque.quiz_text}}</h3>
                                                                                    <div v-if="subque.quiz_image_url !='' && subque.quiz_image != null">
                                                                                        <img :src="subque.quiz_image_url" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="subque.quiz_image_url" > 
                                                                                    </div>
                                                                                    <div v-if="subque.quiz_skill.length > 0">
                                                                                        <span class="timeline-header" style="padding:10px;" v-for="(skill_point, k) in subque.quiz_skill" :key="k">{{skill_point.skill.skill_name}} | P : {{Number(skill_point.points)}} | W : {{Number(skill_point.weightage)}}<span v-if="k+1 != subque.quiz_skill.length">,</span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="timeline pt-4 m-0" v-if="subtopic.task_for_tutors.length > 0">
                                                                        <div class="time-label">
                                                                            <span class="bg-gray ml-2">SubTopic Task For Tutors</span>
                                                                        </div>
                                                                        <div class="timeline tm-que" >
                                                                            <div class="question" v-for="(subque, j) in subtopic.task_for_tutors" :key="j">
                                                                                <i class="fas fa-list bg-grey" title="SubTopic Task For Tutors"></i>
                                                                                <div class="timeline-item">
                                                                                    <h3 class="timeline-header">{{subque.question}}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="timeline m-0" v-else>
                                                <span class="timeline-header header-left">No data available for this Project.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align_centre" v-else>
                                        <span>No data available.</span>
                                    </div>
                                </div>
                            </div>
                             <div class="card-body" v-else>
                                <CustomLoader></CustomLoader>
                            </div>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>

<style scoped>
.card {
    min-height: 185px !important;
}
</style>
 

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
            isTopcs:false,
            isSubTopic:false,
            topicQuestion:false,
            subtopicQuestion:false,
        }
    },
    props : ['project_id'],
    created() {
        //this.fetchData(this.$route.params.id)
    },
    computed: {
        ...mapGetters('ProjectsSingle', ['AllTopicsSubTopics','loading'])
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchAllTopicsSubTopics', 'resetState']),
        openModel(){
            this.resetState()
            this.fetchAllTopicsSubTopics(this.project_id)
            this.isSubTopic = false
            this.topicQuestion = false
            this.subtopicQuestion = false
        },
        openTopicQuestion(){
            if(!this.topicQuestion){
                this.topicQuestion = true;
            }else {this.topicQuestion = false;}
            
        },
        openSubTopicQuestion(){
            if(!this.subtopicQuestion){
                this.subtopicQuestion = true;
            }else {this.subtopicQuestion = false;}
            
        },
        openSubTopic(){
            if(!this.isSubTopic){
                this.isSubTopic = true;
            }else {this.isSubTopic = false;}
            
        }
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
.question:last-child{
    margin-bottom: 0px;
}
.subtopic:last-child, .subtopic .question:last-child,  .tm-que{
    padding-bottom: 15px;
}
.align_centre{
    justify-content: center;
}
.bg-yellow {
    color: #ffff!important;
}
.profile-user-img{
    height:100px !important;
}
.header-left{
    margin-left: 40px;
}
</style>
