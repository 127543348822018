<template>
  <div :class="'btn-group '+ xprops.table_id" >
    {{ title }}
    <a href="javascript:;" data-toggle="dropdown">
      <i class="fa fa-filter" :class="{ 'text-muted': !keyword }"></i>
    </a>
    <ul class="dropdown-menu" style="padding: 3px">
      <div class="input-group input-group-sm">
            <date-picker
                :id="`search_${field}${xprops.table_id ? xprops.table_id : '' }`" 
                v-model='keyword'
                type="search"
                class="form-control"
                :config="$root.dpconfigDate"
                :placeholder="`${title}`"
                @keydown.enter="search" 
                autocomplete="off"
                >
            </date-picker>
          <span class="input-group-btn">
            <button class="btn btn-default fa fa-search" @click="search"></button>
          </span>
      </div>
    </ul>
  </div>
</template>
<script>

export default {
  props: ['field', 'title', 'query','column','data','xprops'],
  data: () => ({
    keyword: ''
  }),
  mounted () {
    $(this.$el).on('shown.bs.dropdown', e => this.$refs.input.focus())
  },
  watch: {
    keyword (kw) {
      // reset immediately if empty
      this.search()
    }
  },
  methods: {
    search () {
      const { query } = this
        console.log('this.',this)
        console.log('query.',query)
      // `$props.query` would be initialized to `{ limit: 10, offset: 0, sort: '', order: '' }` by default
      // custom query conditions must be set to observable by using `Vue.set / $vm.$set`
      this.$set(query, this.field, this.keyword)
      //console.log('filter',purify(query));
      query.offset = 0 // reset pagination
    }
  }
}
</script>
<style>
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  cursor: pointer;
}
</style>
