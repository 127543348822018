function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            father_name: null,
            mother_name: null,
            mobile_no: null,
            whatsapp_no: null,
            other_mobile_no: null,
            email: null,
            other_email: null,
            username: null,
            role: null,
            user_status: null,
        },
        rolesAll: [],
        
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    loading: state => state.loading,
    rolesAll: state => state.rolesAll,    
}
const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.role)) {
                params.set('role_id', '')
            } else {
                params.set('role_id', state.item.role.id)
            }

            axios.post('/api/v1/parents', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.role)) {
                params.set('role_id', '')
            } else {
                params.set('role_id', state.item.role.id)
            }
            if (_.isEmpty(state.item.whatsapp_no)) {
                params.set('whatsapp_no', '')
            } else {
                params.set('whatsapp_no', state.item.whatsapp_no)
            }
            axios.post('/api/v1/parents/' + state.item.id, params)
                .then(response => {
                    //commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/parents/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        dispatch('fetchRolesAll')
    },
    fetchRolesAll({ commit }) {
        axios.get('/api/v1/employeeRoleList')
            .then(response => {
                commit('setRolesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setFather_name({ commit }, value) {
        commit('setFather_name', value)
    },
    setMother_name({ commit }, value) {
        commit('setMother_name', value)
    },
    setMobile_no({ commit }, value) {
        commit('setMobile_no', value)
    },
    setWhatsappNo({ commit }, value) {
        commit('setWhatsappNo', value)
    },    
    setOther_mobile_no({ commit }, value) {
        commit('setOther_mobile_no', value)
    },
    setEmail({ commit }, value) {
        commit('setEmail', value)
    },
    setOther_email({ commit }, value) {
        commit('setOther_email', value)
    },
    setUsername({ commit }, value) {
        commit('setUsername', value)
    },
    setRole({ commit }, value) {
        commit('setRole', value)
    },
    setUserStatus({ commit }, value) {
        commit('setUserStatus', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setFather_name(state, value) {
        state.item.father_name = value
    },
    setMother_name(state, value) {
        state.item.mother_name = value
    },
    setMobile_no(state, value) {
        state.item.mobile_no = value
    },
    setWhatsappNo(state, value) {
        state.item.whatsapp_no = value
    },    
    setOther_mobile_no(state, value) {
        state.item.other_mobile_no = value
    },
    setEmail(state, value) {
        state.item.email = value
    },
    setOther_email(state, value) {
        state.item.other_email = value
    },
    setUsername(state, value) {
        state.item.username = value
    },
    setRole(state, value) {
        state.item.role = value
    },
    setUserStatus(state, value) {
        state.item.user_status = value
    },
    setRolesAll(state, value) {
        state.rolesAll = value
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
