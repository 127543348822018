<template>
    <div class="">
        <button style="display:none" id="generatePDF" @click="generatePDF()" >generate pdf</button>
         <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="AdmissionformPdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        :pdf-margin="[0,0,0,0]"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        ref="html2Pdf"
        >
          <div slot="pdf-content" style="margin:0px !important;background-color:white; border-top:5px solid #51B848; padding-top: 30px;">
            <table width="800" align="center" style="background-color: white;padding: 15px;display:block;">
               <tbody>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="font-size: 14px;width: 240px;">Enrollment No: {{admissionFormPdf.enrollment_no}}</td>
                                       <!-- <td align="center" style="color:#51B848; font-size:25px; font-weight: bold;">Admission Form</td> -->
                                       <td align="right"><img src="/assets/images/logo.svg" alt="" width="140px"></td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td align="center" style="color:#51B848; font-size:20px; font-weight: bold; padding: 20px 0;">Admission Form</td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 130px; font-size: 14px;">Admission Date:</td>
                                       <td style="font-size: 14px;">{{admissionFormPdf.admission_date}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 137px; font-size: 14px;">Student Name:</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 153px;">{{admissionFormPdf.full_name}}</td>
                                       <td style="width: 10px;"></td>
                                       <td style="font-size: 14px; width: 40px;">Age:</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 100px;width: 153px;">{{admissionFormPdf.age}}</td>
                                       <td style="width: 15px;"></td>
                                       <td style="font-size: 14px;width: 40px;">DOB:</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 153px;">{{admissionFormPdf.birth_date}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 150px; font-size: 14px;">Address:</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;">{{admissionFormPdf.student_address}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 167px; font-size: 14px;">Email Id:</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{admissionFormPdf.email}}</td>
                                       <td style="width: 10px;"></td>
                                       <td style="width: 70px; font-size: 14px;">Mobile(F):</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{admissionFormPdf.father_mob_no}}</td>
                                       <td style="width: 10px;"></td>
                                       <td style="width: 70px; font-size: 14px;">Mobile(M):</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{admissionFormPdf.mother_mob_no}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <td style="width: 141px; font-size: 14px;">School Name</td>
                                    <td  align="center" style="border-bottom:solid 1px #ccc;font-size: 14px; width: 400px;">{{admissionFormPdf.school_name}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="width: 70px; font-size: 14px;">Standard</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{admissionFormPdf.standard}}</td>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 141px; font-size: 14px;">Course Details</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;">{{admissionFormPdf.course_name}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 144px; font-size: 14px;">Course Start Date</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 264px;">{{admissionFormPdf.course_start_date}}</td>
                                       <td style="width: 10px;"></td>
                                       <td style="width: 90px; font-size: 14px;">Course Finish</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 264px;">{{admissionFormPdf.course_start_end}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <!-- <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 170px; font-size: 14px;">Batch Name- Lab Name</td>
                                       <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 620px;">
                                          <div v-for="(row, i) in admissionFormPdf.batch_names"  :key="i">
                                             <span v-for="(r, inx) in row" :key="inx">
                                                <span v-if="inx">,</span>
                                                {{r}}-{{i}}
                                             </span>
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr> -->
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 150px; font-size: 14px;">Selected Days</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width:81%;">
                                          <table width="100%" align="center">
                                             <thead>
                                                <tr>
                                                   <th style="border:solid 1px #ccc;padding:5px;">Day Name</th>
                                                   <th style="border:solid 1px #ccc;padding:5px;">Batch Name</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr v-for="(row, i) in admissionFormPdf.selected_days"  :key="i">
                                                   <td style="border:solid 1px #ccc;padding:5px;">{{ i }}</td>
                                                   <td style="border:solid 1px #ccc;padding:5px;"><span v-html="row"></span></td>
                                                </tr>
                                             </tbody>
                                          </table>   
                                       </td>
                                       <!-- <td style="width: 10px;"></td>
                                       <td style="width: 80px; font-size: 14px;">T-shirt size</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 100px;">{{admissionFormPdf.tshirt_size}}</td> -->
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 150px; font-size: 14px;">T-shirt size</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;">{{admissionFormPdf.tshirt_size}}</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr style="height: 10px;"></tr>
                     <tr>
                        <td style="border-bottom:solid 3px #51B848;"></td>
                     </tr>
                     <tr style="height: 10px;"></tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td>
                                             <h2 style="margin: 0; font-size:18px">Terms & Conditions:</h2>
                                             <ul style="padding:0; font-size:14px; list-style-type:none; margin: 10px 0 0 0;">
                                                <li style="line-height:20px;padding-bottom: 5px;">1. All rights of admission reserved by RoboFun Lab.</li>
                                                <li style="line-height:20px;padding-bottom: 5px;">2. If the child is found tempering or breaking any electronic component, he will be eligible to pay the full amount of that 
                                                   particular component.</li>
                                                <li style="line-height:20px;padding-bottom: 5px;">3. Child should not harm any other child or coaches or the property of RFL. RFL reserve all rights to cancel child's admission 
                                                   if found doing so after 3 warnings, with no refund</li>  
                                                <li style="line-height:20px;padding-bottom: 5px;">4. Registration fees will give universal RFL membership & includes administrative charges as well.</li>  
                                                <li style="line-height:20px;padding-bottom: 5px;">5. The course has to be finish in the dedicated time/date/session as per mention course finish date. Maximum 2 Extra session 
                                                   per course will be excepted for extended finish the course. After that each extra sessions will be charge at 500/- per session.
                                                </li>  
                                                <li style="line-height:20px;padding-bottom: 5px;">6. I agree & understand that photographs, video clips, voice recording & other acitivity recording will me made of my child 
                                                   enrolling in RoboFun Lab Courses. I consent to those photographs, video tapes & other recording use thereof as a part of the 
                                                   course & to promote RoboFun Lab as well as its programmes.
                                                </li>  
                                                <li style="line-height:20px;">7. Registration & Course Fees will be non Refundable and non Transferable.</li>
                                             </ul>
                                             

                                       </td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="width: 15px; font-size: 14px;">I</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 435px;">{{(admissionFormPdf.father_name) ? admissionFormPdf.mother_name+"/"+admissionFormPdf.father_name : admissionFormPdf.mother_name}}</td>
                                       <td style="width: 10px;"></td>
                                       <td style="width: 70px; font-size: 14px;">parent of</td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 435px;">{{admissionFormPdf.full_name}}</td>
                                       <td style="width: 150px; font-size: 14px;">hereby declare that </td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="font-size: 14px;">we accept the terms & conditions of RFL and details provided by us are completely true at the best of our knowledge.</td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="font-size: 14px; width: 50px;">Date:</td>
                                       <td style="width: 100px; font-size: 14px;">{{currentDate()}}</td>
                                       <td style="font-size: 14px;">Father Signature: </td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 200px;"></td>
                                       <td style="font-size: 14px;">Mother Signature: </td>
                                       <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 200px;"></td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr style="height: 50px;"></tr>
                     <tr>
                        <td style="border-bottom:solid 3px #51B848;"></td>
                     </tr>
                     <tr style="height: 10px;"></tr>
                     <tr>

                        <td>
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <td style="font-size: 14px;">
                                             <h2 style="font-size: 14px; color: #51B848; margin: 0;">RoboFun Lab (P) Ltd</h2>
                                             {{admissionFormPdf.branch_address}}
                                       </td>
                                       <td align="right" style="font-size: 14px;">
                                             {{admissionFormPdf.branch_mobile_no}} <span v-if="admissionFormPdf.branch_phone_no">| {{admissionFormPdf.branch_phone_no}} </span><br> {{admissionFormPdf.branch_email}}
                                       </td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>       
                     </tr>
                     <tr>
                        <td style="padding:0px;">
                           <table width="100%" align="center">
                                 <tbody>
                                    <tr>
                                       <!-- <td style="background-color:#51B848; text-align:center; font-size:14px; color: #fff; padding: 10px 35px;">
                                             <strong>Regd. Off.</strong>  105, Maher Park - B, Athwagate, Ring Road, Surat - 395001 (Gujarat) India T. +91 261 2478 828
                                             www.robofunlab.com
                                       </td> -->
                                       <td style="padding:0px;"><img src="/assets/images/footer-bg.png" alt="" width="100%"></td>
                                    </tr>
                                 </tbody>
                           </table>
                        </td>
                     </tr>
                     <tr style="height: 20px;"></tr>
               </tbody>
            </table>
          </div>
        </vue-html2pdf>
      <div v-if="Pre_loading">
          <CustomLoader></CustomLoader>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
import CustomLoader from '@/components/CustomLoader'
export default {
   components: {
        VueHtml2pdf,
        CustomLoader
    },
    props:['id'],
    data() {
        return {
            Pre_loading:false
        }
    },
    computed: {
      ...mapGetters('StudentShowSingle', ['admissionFormPdf','loading']),
    },
    watch: {
        "$route.params.id": function() { 
            this.getManualAdmissionForm(this.$route.params.id);     
        },
    }, 
    created(){
      this.Pre_loading = true
      $('.wrapper').css('opacity', '0')
      $('.main-sidebar').css('display', 'none !important')
      this.getManualAdmissionForm(this.$route.params.id)
      .then((res)=>{
         $('#generatePDF').trigger('click');   
      })
    },
    methods:{
      ...mapActions('StudentShowSingle', ['getManualAdmissionForm', 'storedata']),
    currentDate() {
         const current = new Date();
         const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
         return date;
    },
    onProgress(event) {
        console.log(`Processed: ${event} / 100`);
        console.log(event);
       if(event == 100){
         $('.wrapper').css('opacity', '1')
         this.Pre_loading = false
         $('.layout-container .unset-all').css('display', 'block')
      }
    },
    hasGenerated() {
        alert("PDF generated successfully!");
    },
    generatePDF() {
        this.$refs.html2Pdf.generatePdf();
    },
     
        
    }
}
</script>
<style scoped>
.layout-container .content-wrapper{
    /* margin-left: 0px !important; */
}
</style>