<template>
    <div class="">
        <StudentEditCoursePopup :id="addId" @loadItems="loadItems"></StudentEditCoursePopup>
        <StudentCourseSchedulePopup :id="addId" @loadItems="loadItems"></StudentCourseSchedulePopup>
        <StudentEnrollChangeStatus :id="studentEnrollId" @loadItems="loadItems"></StudentEnrollChangeStatus>
        
        <div class="table-responsive setting-group">
            <table v-if="rows.length > 0" class="table table-bordered">
                <thead class="headColor">
                    <tr>
                        <th>Course Name</th>
                        <th>Enroll Date</th>
                        <th>Fees</th>
                        <th>Status</th>
                        <th style="width:15%;">Action</th>
                    </tr>
                </thead>
                <tbody v-for="(row, index) in rows" :key="index">
                    <tr v-if="row">
                        <td>
                            <div class="row col-xs-12">
                                <div class="col-xs-1">
                                    <div class="cursor-pointer table-arrow" @click="toogleSubTable(row.student_enroll_id, row.children)"><i :id="'angle_'+row.student_enroll_id" :class="angle" aria-hidden="true"></i></div>
                                </div>
                                <div class="col-xs-1">&nbsp;&nbsp;</div>
                                <div class="col-xs-10">
                                    <div>{{row.course_name}}</div>
                                </div>
                            </div>
                        </td>
                        <td>{{row.enrolldate}}</td>
                        <td>{{row.totalFee}}</td>
                        <td>{{row.course_status}}</td>
                        <td><CancelAdmissionActions @addIdModel="addIdModel" @studentRemark="studentRemark" :student_id="student_id" @loadItems="loadItems" :xprops="xprops" :row="row"></CancelAdmissionActions></td>
                    </tr>                   
                    <tr class="allhide" :id="'show_'+row.student_enroll_id" >
                        <td colspan="5" class="innerTable">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Fee Type</th>
                                        <th>Fee Amount</th>
                                        <th>Total Paid</th>
                                        <th>Total Due</th>
                                        <th>Final Amount</th>
                                        <th>GST Rate(%)</th>
                                        <th style="width:23%;">GST Amount</th>                                        
                                    </tr>
                                </thead>
                                <tbody v-if="row.children.length > 0">
                                    <tr v-for="r in row.children" :key="r.fees_type">
                                        <td>
                                            {{r.fees_type_name}}<br/>
                                            <div v-if="r.coupon_amount && r.coupon_amount > 0">
                                                <span style="font-size:12px;">(Full Amount - Coupon Discount)</span>
                                            </div>
                                            <div v-if="r.credit_points && r.credit_points > 0">
                                                <span style="font-size:12px;color:red">(Paid Using Credit)</span>
                                            </div>
                                        </td>
                                        <td><i class="fa fa-rupee"></i>&nbsp;{{r.total_fees}}<br/>
                                            <span style="font-size:12px;" v-if="r.coupon_amount && r.coupon_amount > 0">({{r.main_amount}} - {{r.coupon_amount}})</span>
                                        </td>
                                        <td><i class="fa fa-rupee"></i>&nbsp;{{r.total_paid}} <br />
                                        <span style="font-size:12px;color:red" v-if="r.credit_points && r.credit_points > 0">({{r.credit_points}})</span>
                                        </td>
                                        <td><i class="fa fa-rupee"></i>&nbsp;{{r.total_due}}</td>
                                        <td><i class="fa fa-rupee"></i>&nbsp;{{(r.total_fees - r.gst_amount).toFixed(2)}}</td>
                                        <td>{{r.gst_rate}}</td>    
                                        <td><i class="fa fa-rupee"></i>&nbsp;{{r.gst_amount}}</td>                                        
                                    </tr>                                    
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="6" class="text-align-center">No Records found!</td>
                                    </tr>
                                </tbody>                                
                            </table>
                        </td>
                    </tr>        
                </tbody>
            </table>
            <table v-else class="table table-bordered">
                <thead>
                    <tr>
                        <th>Course Name</th>
                        <th>Enroll Date</th>
                        <th>Fees</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td colspan="5" class="text-align-center">No records Found!</td></tr>      
                </tbody>
            </table>
            <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__navigation vgt-pull-right">
                    <div data-v-347cbcfa="" class="footer__navigation__page-info">
                        <div data-v-347cbcfa="">
                            {{totalRecords}} of {{totalRecords}}
                    </div></div>
            </div></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CancelAdmissionActions from '../../dtmodules/DatatableActions/CancelAdmissionActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
import StudentEditCoursePopup from '@/components/cruds/Student/StudentEditCoursePopup'
import StudentEnrollChangeStatus from '@/components/cruds/Student/StudentEnrollChangeStatus'
import StudentCourseSchedulePopup from '@/components/cruds/Student/StudentCourseSchedulePopup'
CancelAdmissionActions.attachStore('StudentShowSingle');
export default {
    components,
     components: {
        CancelAdmissionActions,
        CustomLoader,
        StudentEditCoursePopup,
        StudentCourseSchedulePopup,
        StudentEnrollChangeStatus
    },    
    props:['tabShow'],
    data() {
        return {
            addId:'',
            studentEnrollId:'',
            //isLoading: true,
            student_id:0,
            show:false,
            angle:'fa fa-angle-right',
            rows: [],
            totalRecords:0,
            refershLoad:false,
            xprops: {
                module: 'CoursesIndex',
                route: 'course',
                permission_prefix: 'course_'
            }
        }
    },
    watch: {
        "$route.params.id": function() {
            this.student_id = this.$route.params.id;
        },    
        tabShow: function() {
            if(this.tabShow){
                this.loadItems();
                this.student_id = this.$route.params.id;
            }
        },            
                
    },
    created() {
        if(this.tabShow){
            this.loadItems();
        }
        this.student_id = this.$route.params.id;
    },
    destroyed() {
       // this.loadItems();        
    },
    computed: {
        ...mapGetters('StudentShowSingle', ['item', 'loading']),
    },
    methods: {
        ...mapActions('StudentShowSingle', ['fetchData']),
        addIdModel(id){
            this.addId = id;
        },
        studentRemark(id){
            this.studentEnrollId = id;
        },
        toogleSubTable(id ,child){
            var className = $('#show_'+id).attr('class');
            //if(child.length != 0){
                if($('#show_'+id).length > 0 && className == "allhide"){
                    $('#show_'+id).removeClass('allhide').addClass('allshow');
                    $('#angle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');                    
                }else{
                    $('#show_'+id).removeClass('allshow').addClass('allhide');
                    $('#angle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
                }
            //}            
        },
         updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
            var order = "";
            var field = "";
            var search = '';
            axiosLms.get("/api/v1/getStudentEnrollCourses/"+this.$route.params.id, {
                params: {
                    max: 25,
                    page: 1,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:'',
                }
                })
                .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
            
            });
        }, 
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"project_name":"","project_type":"","project_brand":"","project_kit":"","project_hours":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        },  
        searchCourseData() {
            this.query = {};            
            if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
                this.date_filter.search_course_name = this.date_filter.search_course_name.course_name;    
            }
            this.fetchData(this.date_filter);
        }
    }
}
</script>

<style scoped>
.allhide {
display: none;
}
.allShow {
display: block;
}
.innerTable{
    margin: 10px;
    padding-left: 20px;
}
.innerTable table{
    background-color:#888a8d1c;
}
.headColor{
    background:linear-gradient(#f4f5f8,#f1f3f6);
}
.table-arrow{
    width: 10px;
    background: #888a8d29;
    padding: 0px 12px 0px 6px;
    border-radius: 3px;
}
.table-arrow i{
  margin: -2px;
}
</style>
