<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
             <div class="col-md-12 col-xs-12 row">
                <label for="course_name" class="col-md-4">Course Name :</label> <span class="col-md-8" v-if="course_name != ''">{{course_name}}</span>
            </div>  
            <div class="col-md-12 col-xs-12">
                <span>Do you want to start project for this course?</span>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" class="btn btn-success" :disabled="loading">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props:['id','studentEnrollId','course_name','course_id'],
    data() {
        return {
            selectedProject:'',
            selected_date:moment().format("DD/MM/YYYY")
        }
    },
    computed: {
        ...mapGetters('StudentAttendanceIndex', ['studentCourseEnrollDetail','item', 'loading']),
       
    },
    created() {
        console.log(this.studentEnrollId);
        //this.fetchStudentCourseEnrollDetails(this.studentEnrollId);
        // this.fetchPendingProjectList()
    },
    watch:{
        
    },
    mounted() {
       
    },
    methods: {
        ...mapActions('StudentAttendanceIndex', ['startProjectForCourse']),
        closeModel(){
            this.$emit('closeModel');
        },
        submitForm () {
            this.$validator.validate().then(result => {
                
                if (result) {
                    let data = {
                        'enroll_id':this.studentEnrollId,
                        'course_id':this.course_id,
                        'attendance_id':this.id
                    };
                    
                    this.startProjectForCourse(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        this.$eventHub.$emit('common',3,res.data.message)
                        return false;
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('common',1,res.data.message)
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>