function initialState() {
    return {
        item: {
            header_name: '',
            skill_name: ''
        },
        header_name: '',
        headerDetail: '',
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    header_name: state => state.header_name,
    headerDetail: state => state.headerDetail,
}

const actions = {
    storeData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.header_name)) {
                params.set('header_name', '')
            } else {
                params.set('header_name', form_data.header_name)
            }

            axiosLms.post('/api/v1/skill-header', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/skill-header/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },
    fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/skill-header/' + id)
        .then(response => {
            if(response.data.result){
                commit('setHeaderDetail', response.data.data)
                commit('setLoading', false)
            }
        })
        .catch(function (error) {
            commit('setLoading', false)
        })
    },
    fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/skill-header/' + id)
        .then(response => {
            if(response.data.result){
                commit('setHeaderDetail', response.data.data)
                commit('setLoading', false)
            }
            else{
                commit('setHeaderDetail', [])
            }
        })
        .catch(function (error) {
            commit('setLoading', false)
        })
    },
    updateData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData()

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.header_name)) {
                params.set('header_name', '')
            } else {
                params.set('header_name', form_data.header_name)
            }

            axiosLms.post('/api/v1/skill-header/' + form_data.id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async getList({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/getHeaderWithSkill/'+id)
        .then(response => {
            if(response.data.result) {
                commit('setItem', response.data.data[0])
                dispatch('fetchData', response.data.data[0].id)
                commit('setLoading', false)
            }
        })
        .catch(function (error) {
            commit('setLoading', false)
        })
    },

    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, value){
        state.item = value
    },
    setHeaderName(state,header_name){
        state.header_name = header_name;
    },
    setHeaderDetail(state,headerDetail){
        state.headerDetail = headerDetail;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
