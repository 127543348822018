<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Project Fees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/project-fees'">Project Fees</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Project Fees</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Project Fees</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                    <div class="col-md-12">
                                        <bootstrap-alert />
                                    </div>

                                    <div class="row col-md-12 col-xs-12">    
                                        <div class="form-group col-md-6 col-xs-6">
                                            <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                            <v-select
                                                name="branch"
                                                label="branch_name"
                                                @change="updateBranch"
                                                disabled=""
                                                :value="item.branch_details"
                                                :options="branchMastersAll"
                                            />
                                            <span class="error" v-show="errors.has('branch')">
                                                {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                            </span>
                                        </div>  
                                        <div class="form-group col-md-6 col-xs-6">
                                            <label for="project_name">Project Name<p class="astrisk">*</p></label>
                                            <v-select
                                                name="project_name"
                                                label="project_name"
                                                value="project_name"
                                                v-validate="'required'"
                                                placeholder="Select Project Name"
                                                v-model="item.project_details"
                                                :options="mainProjectAll"
                                                disabled=""
                                                />
                                            <span class="error" v-show="errors.has('project_name')">
                                            {{ $root.updateErrorMessage('project_name',this.errors,'Project Name')}}
                                            </span>
                                        </div> 
                                    </div> 
                                    <div class="row col-md-12 col-xs-12">    
                                        <div class="form-group col-md-6 col-xs-6">
                                            <label for="start_date">Start Date</label>
                                            <input
                                                type="text"
                                                disabled
                                                class="form-control"
                                                name="start_date"
                                                ref="start_date"
                                                placeholder="Fetching old start date..."             
                                                :value="computedOldDate">
                                            <span class="error" v-show="errors.has('start_date')">
                                                    {{ $root.updateErrorMessage('start_date',this.errors,'Fee Amount','a valid value')}}
                                            </span>
                                        </div>  
                                        <!-- <div class="form-group col-md-6 col-xs-6">
                                            <label for="start_date">New Start Date<p class="astrisk">*</p></label>
                                            <date-picker
                                                    :value="item.start_date"
                                                    :config="$root.dpconfigDate"
                                                    name="start_date"
                                                    v-validate="'required'"
                                                    placeholder="Select New Start Date"
                                                    @dp-change="updateStartDate"
                                                    >
                                            </date-picker>
                                            <span class="error" v-show="errors.has('start_date')">
                                            {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                            </span>
                                        </div>  -->
                                    </div> 
                                    <div class="clearfix"></div>
                                    <div class=" col-md-10 col-md-offset-1" v-if="courseDetailedData.length > 0">
                                        <div class="form-group">
                                            <table class="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 150px;">Fee type</th>
                                                            <th style="width: 280px;">Old fee</th>
                                                            <th style="width: 280px;">New fee</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-for="(feesType, i) in feesTypeAll">
                                                        <tr>
                                                            <td>{{feesType.name}}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="percentage_from"
                                                                    id="percentage_from"
                                                                    disabled
                                                                    oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                    placeholder="Fetching old fee..."
                                                                    :value="computedOldFee(feesType.id)"
                                                                    >
                                                            </td> 
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="percentage_from"
                                                                    id="percentage_from"
                                                                    maxlength="10"
                                                                    oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                    placeholder="Enter new fee"
                                                                    v-validate="'required'"
                                                                    :value="newFees && newFees[feesType.id] ? newFees[feesType.id] : 0"
                                                                    @input="updateNewFeeAmount($event, feesType.id)" 
                                                                    >
                                                            </td>         
                                                        </tr>                                                        
                                                    </tbody>
                                            </table><br>                               
                                            <span class="error" v-show="errors.has('percentage_from')">
                                                Enter At least one new fee data.
                                            </span><br>                                                   
                                        </div>
                                    </div>             
                                    <div class="col-md-12 col-md-12 savebtn">
                                        <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                        </vue-button-spinner>
                                    </div> 
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            newFees: [],
            totalCountFees:0,    
            totalCountGST:0,
        }

    },
    computed: {
        ...mapGetters('ProjectFeesSingle', ['item', 'project','loading','loadingFeesAvailable', 'coursesAll', 'branchMastersAll', 'feesTypeAll','courseDetailedData','allCourseLevels','feesAvailable','nextFeeStartDate','mainProjectAll', 'branch']),
        computedOldDate() {
            var lengthC = (this.courseDetailedData.length - 1)
            if(this.courseDetailedData && this.courseDetailedData[lengthC]) {
                var formatedFromDate = this.courseDetailedData[lengthC].original_date;
                var finalOldDate = formatedFromDate;
                return finalOldDate;
            }
        }
    },
    created() {
        this.fetchEditData(this.$route.params.project_fee_id)
            .then(res=>{
                if(this.item && this.item.branch_details) {
                    this.updateBranch(this.item.branch_details)
                }
            })
        // this.fetchEditData('id':13,'from_date':'2019-05-29')            
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            // this.fetchEditData(this.$route.params.id,)
            this.fetchEditData({'id':this.$route.params.id, 'project_fee_id' : this.$route.params.project_fee_id })
        },
        "item": function() {
          this.totalCountFees = this.item.fees_amount;  
          this.totalCountGST = this.item.gst_rate;
        },
    },
    methods: {
        ...mapActions('ProjectFeesSingle', ['fetchEditData', 'resetState', 'setProject', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type', 'fetchFeesTypeAll', 'fetchBranchMasterAll','fetchCourseDetailedData','fetchCoursesLevel','setStartDate','EditBunchData','fetchIfFeesAvailable','setMainProject','fetchProjects', 'setNextFeeStartDate']),
        disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },
        countTotalFees(){
            // var myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            // this.setTotal_fees(myVal)
            // return myVal;
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            }
            this.setTotal_fees(myVal.toFixed(2));            
            return myVal.toFixed(2);
        },
        computedOldFee(feesType) {
            var oldFee = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                 if(feesType == this.courseDetailedData[i].fees_type) {
                    oldFee = this.courseDetailedData[i].fees_amount
                 }
             }
            return oldFee;
        },
        updateStartDate(e) {
            this.setStartDate(e.target.value)
            var formatedFromDate = e.target.value.split('/');
            var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0] 
            this.setNextFeeStartDate(finalOldDate);
        },        
        updateErrorMessage(errFieldName) {
            if(errFieldName)
            {
                if(this.errors.first(errFieldName))
                {
                    return this.errors.first(errFieldName).replace(/_/g,' ');
                }
            }
            else
            {
                return "";
            }
        },
        updateCourse(value) {
            this.setCourse(value)
            // this.fetchCourseDetailedData(value.main_course_id)
            this.fetchCourseDetailedData({'main_course_id':value.main_course_id,'from_date':this.item.from_date,'branch_id':this.item.branch_id})
                .then((res) => {
                    // this.dispalyData();
                })
            this.fetchCoursesLevel(value.main_course_id)
        },
        updateNewFeeAmount(e, index) {
            // console.log(e.target.value,index);
            this.$set(this.newFees, parseInt(index),e.target.value)
        },
        dispalyData() {
                var oldFeesDataLocal = []
                for (var i = 0; i < this.courseDetailedData.length; i++) {
                    // oldFeesDataLocal[] = this.courseDetailedData[i].
                }   
                this.oldFeesData = oldFeesDataLocal;
        },
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },
        updateIs_gst_applicable(e) {
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                if(e){
                    this.setGst_rate(e)
                }else{
                    this.totalCountGST = 0;
                    this.setGst_rate(0)    
                }
                
            }            
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },
        updateBranch(value) {
            this.setBranch(value)
        },
        updateFees_type(value) {
            this.totalCountGST = (value.other_value)?value.other_value:0;
            this.setGst_rate(this.totalCountGST)    
            this.setFees_type(value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                var finalData = [],
                detailedData = [],
                newFeesLocal = this.newFees;
                for (var j = 0; j < this.feesTypeAll.length; j++) {
                    var localIndex = this.feesTypeAll[j].id;
                    // console.log(newFeesLocal);
                    if(newFeesLocal[localIndex] !== undefined) {
                        //console.log("Sdsd",this.item);
                        // var formatedFromDate = this.item.start_date.split('/');
                        
                        // var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0] 
                        // this.setNextFeeStartDate(finalOldDate);
                        var obj = {
                            'id': this.item.id,
                            'fees_type': this.feesTypeAll[j].id,
                            'fees_amount': 0,
                            'is_gst_applicable': this.feesTypeAll[j] && this.feesTypeAll[j].other_value != 0 ? 1 : 0,
                            'gst_rate': this.feesTypeAll[j].other_value,
                            'total_fees': 0,
                           // 'from_date': finalOldDate,
                            'start_date': this.courseDetailedData[0].original_date
                        } ;
                        //console.log("sdsd",this.courseDetailedData[0]);
                        obj.fees_amount = newFeesLocal[localIndex];
                        finalData.push(obj);
                    }
                }       
                // console.log(finalData); 
                // return false;        
                this.EditBunchData(finalData)
                    .then((res) => {                  
                        this.$router.push({ name: 'project_fees.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
          });
        }
    }
}
</script>

<style scoped>
</style>