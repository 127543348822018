function initialState() {
    return {
        item: {
            id: '',
            template_key: '',
            title: '',
            placeholders: '',
            content: ''
        },
        editData:[],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    getEditData: state => state.editData,
    loading: state => state.loading,
}

const actions = {
    storeData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in form_data) {   
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            axios.post('/api/v1/email-templates', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error);
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axios.get('/api/v1/email-templates/' + id)
        .then(response => {
            commit('setItem', response.data.data)
            commit('setEditData', response.data.data)
            commit('setLoading', false)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
            commit('setLoading', false)
        })
    },
    async updateData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {

            let params = new FormData();
            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            axios.post('/api/v1/email-templates/' + state.item.id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },

    setEditData({ commit }, value) {
        commit('setEditData', value)
    },
    setTemplateKey({ commit }, value) {
        commit('setTemplateKey', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setPlaceholder({ commit }, value) {
        commit('setPlaceholder', value)
    },
    setContent({ commit }, value) {
        commit('setContent', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEditData(state, item) {
        state.editData = item
    },
    setTemplateKey(state, item) {
        state.item.template_key = item
    },
    setTitle(state, item) {
        state.item.title = item
    },
    setPlaceholder(state, item) {
        state.item.placeholders = item
    },
    setContent(state, item) {
        state.item.content = item
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}