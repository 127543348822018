<template>
    <div class="btn-group btn-group-xs">
         
         <b-btn v-b-modal.batchHoldHistoryModel @click="openBatchShowPopup(row.id,'history')" title="Batch Hold History" type="button" variant="primary" class="btn btn-warning edit_delete_btn">
             <i class="fa fa-history"></i>
        </b-btn>
         
         <b-btn v-b-modal.studentScheduleModel @click="passAddId(row.student_course_enroll_id)" title="View Student Schedule" type="button" variant="primary" class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-calendar"></i>
        </b-btn> 

         <button
                v-show="row.status == 2" 
                v-b-modal.modalExtendEndDateBatch
                @click="openBatchShowPopup(row.id,'extend')"
                type="button"
                title="Extend End Date"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-fw fa-plus" aria-hidden="true"></i>
        </button>
         <!-- <button
                v-if="row.status == 2" 
                v-b-modal.modalEditStudentBatch
                @click="unHoldBatch(row.id)"
                @click="openBatchShowPopup(row.id,'edit')"
                type="button"
                title="UnHold"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-fw fa-play" aria-hidden="true"></i>
        </button> -->
        <button
                v-show="row.status == 2" 
                v-b-modal.modalEditStudentBatch
                @click="openBatchShowPopup(row.id,'edit')"
                type="button"
                title="UnHold"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-fw fa-play" aria-hidden="true"></i>
        </button>

        <button
                v-show="row.status != 2  && row.status != 3"
                @click="holdBatch(row.id)"
                type="button"
                title="Hold"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-pause" aria-hidden="true"></i>
        </button>
        <button
                v-b-modal.modalShowSingleStudentBatch
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles'"
                @click="openBatchShowPopup(row.id,'view')"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </button>
        <button
                v-b-modal.modalEditStudentBatch
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route !== 'roles' && row.status != 3  && row.status != 2"
                title="Edit"
                @click="openBatchShowPopup(row.id,'edit')"
                class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </button>
         
        <button
                v-if="$can(xprops.permission_prefix + 'delete') && xprops.route !== 'roles' && row.status != 3  && row.status != 2"
                @click="deleteBatchData(row.id)"
                type="button"
                title="Delete"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
        }
    },
    watch: {  

    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
    },
    methods: {
        ...mapActions('BatchSingle', ['holdBatch1','unHoldBatch1']),
        passAddId(id){
            this.$emit('addIdModel',id) 
        },
        holdBatch(batchId){
            this.$swal({
                title: ' Are you sure all the attendance and assessment for this student are completed till now?',
                text: 'Are you sure you want to put this on Hold?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#28a745',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.holdBatch1(batchId)
                    .then(() => {
                        this.$eventHub.$emit('put-on-hold')
                        this.$emit('loadItems')
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('smsNotSent')
                    })
                }
            });
        },
        unHoldBatch(batchId){
            console.log("hdfdf");
            this.unHoldBatch1(batchId);
            this.$emit('loadItems')
        },
        deleteBatchData(batchId){
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(batchId)
                    .then((res) => {
                        if(res.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                        }
                    })
                    .catch(error => {
                        console.log('catch', error)
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        openBatchShowPopup(id,page){
            console.log("hsdsds",id,page);
            this.$emit('batchIdModel',id, page);
        },
    }
}
</script>