function initialState() {
    return {
        editDataAll:[],
        showData:'',
        loading:false,
        caseStudyImage:'',
        title:'',
        backgroundImage:'',
        profile:'',
        isPublish:0
    }
} 

const getters = {
    editDataAll:state => state.editDataAll,
    showData:state => state.showData,
    loading:state => state.loading,
    caseStudyImage:state => state.caseStudyImage,
    title:state => state.title,
    backgroundImage:state => state.backgroundImage,
    profile:state => state.profile,
    isPublish:state => state.isPublish
}

const actions = {
    async fetchEditCaseStudyData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/caseStudy/edit/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                    commit('setLoading', false)
                }
                else{
                    commit('setEditDataAll', '')
                    resolve(response)
                    commit('setLoading', false)
                }
            })
            .catch(function (error) {
                reject(error)
                commit('setLoading', false)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/caseStudy/show/' + id)
                .then(response => {
                    if(response.data.result){
                        commit('setShowDataAll', response.data.data)
                        resolve(response)
                        commit('setLoading', false)
                    }
                    else{
                        commit('setShowDataAll', '')
                        resolve(response)
                        commit('setLoading', false)
                    }
                })
                .catch(function (error) {
                    reject(error)
                    commit('setLoading', false)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async updateData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        console.log('f',form_data);
        return new Promise((resolve, reject) => {
            
            let params = new FormData()

            for (let fieldName in form_data) {
                let fieldValue = form_data[fieldName]
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue)
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue)
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index])
                        }
                    }
                }
            }
            
            if (_.isEmpty(form_data.case_study_details)) {
                params.set('case_study_details', '')
            } else {
                let i = 0
                for (let index in form_data.case_study_details) {
                    params.set('case_study_details['+i+'][title]', form_data.case_study_details[index]['title'])
                    params.set('case_study_details['+i+'][description]', form_data.case_study_details[index]['description'])
                    params.set('case_study_details['+i+'][image]', form_data.case_study_details[index]['image'])  
                    i++
                }
            }
            
            axiosLms.post('/api/v1/caseStudy/' + form_data.id, params)
                .then(response => {
                        //commit('setEditDataAll', response.data.data)
                    resolve(response)
                    
                })
                .catch(function (error) {
                    //console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
      
    storeData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            
            let params = new FormData()

            for (let fieldName in form_data) {
                let fieldValue = form_data[fieldName]
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue)
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue)
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index])
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.case_study_details)) {
                params.set('case_study_details', '')
            } else {
                let i = 0
                for (let index in form_data.case_study_details) {
                    params.set('case_study_details['+i+'][title]', form_data.case_study_details[index]['title'])
                    params.set('case_study_details['+i+'][description]', form_data.case_study_details[index]['description'])
                    params.set('case_study_details['+i+'][image]', form_data.case_study_details[index]['image'])  
                    i++
                }
            }
            
            axiosLms.post('/api/v1/caseStudy/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/caseStudy/' + id)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            })
        })
    },
    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setCaseStudyImage({ commit }, value) {
        commit('setCaseStudyImage', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setBackgroundImage({ commit }, value) {
        commit('setBackgroundImage', value)
    },
    setProfile({ commit }, value) {
        commit('setProfile', value)
    },
    setIsPublish({ commit }, value) {
        commit('setIsPublish', value)
    }
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setShowDataAll(state, all) {
        state.showData = all
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    },
    setCaseStudyImage(state,value){
        state.caseStudyImage = value
    },
    setTitle(state,value){
        state.title = value
    },
    setBackgroundImage(state,value){
        state.backgroundImage = value
    },
    setProfile(state,value){
        state.profile = value
    },
    setIsPublish(state,value){
        state.isPublish = value
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
