<template>
    <div>
      
        <!-- <router-link 
            v-if="$can('student_view') && row.student_id"
            :to="'student/'+row.student_id+'/show'"
            title="View Student Details"
            class="student-name"
            target="_blank">
            {{row.student_concat_name ? row.student_concat_name : row.student_name}}
        </router-link> -->
        <router-link 
            v-if="$can('student_view') && row.student_id"
            :to="'student/'+row.student_id+'/show'"
            title="View Student Details"
            class="student-name"
            target="_blank">
            {{row.student_concat_name ? row.student_concat_name : row.student_name}}
        </router-link>
        <span v-else>
          {{row.student_concat_name ? row.student_concat_name : row.student_name}}
        </span>
      
    </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

