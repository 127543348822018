<template>
    <div>
      <topics :row='rowFromParent'></topics>
<!--        <span class="">
            {{row.student_name}}
        </span>-->
    </div>
</template>


<script>
import Topics from './PopupActions/StudentCompletedTopics'
export default {
    components: { Topics },
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            rowFromParent: {...this.row,student_name: this.field == 'course_name' ? this.row.course_name : this.row.student_name },
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

