<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Certificates</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/certificate'">Certificates</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Certificate</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Edit Certificate</h3>
                        </div>
                        <bootstrap-alert />
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-6">
                                    <label for="name">Name<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        ref="name"
                                        maxlength="40"
                                        placeholder="Enter name"
                                        v-validate="'required'"
                                        v-model="item.name"
                                        @change="updateName"
                                    >
                                    <span class="error" v-show="errors.has('name')">
                                        {{ $root.updateErrorMessage('name',this.errors, 'Name')}}
                                    </span>
                                </div>
                                <div class="col-md-6 col-xs-6">
                                    <label for="description">Description<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="description"
                                        ref="description"
                                        maxlength="40"
                                        placeholder="Enter description"
                                        v-validate="'required'"
                                        v-model="item.description"
                                        @change="updateDescription"
                                    >
                                    <span class="error" v-show="errors.has('description')">
                                        {{ $root.updateErrorMessage('description',this.errors, 'Description')}}
                                    </span>
                                </div>
                                <div class="col-md-12 col-xs-6 mt-3">
                                    <label for="description">Placeholders : </label>&nbsp;
                                    <span v-for="(placeholder,index) in certificatePlaceHolders" :key="index">{{placeholder}}<span v-if="certificatePlaceHolders.length != index+1">, {{certificatePlaceHolders.length}}</span></span>
                                </div>
                                <div class="col-md-6 col-xs-6 mt-3">
                                    <div class="form-group">
                                        <label for="file">File</label>
                                        <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            v-validate="'size:2048|ext:php,html'"
                                            class="form-control"
                                            @change="updateFile"
                                        >
                                        <span style="font-size: 13px"><b>Note :</b> PHP, Html files are allowed. File size should be less than or equals to 2 MB.</span>
                                        <span class="error" v-show="errors.has('file')">
                                            {{ $root.updateErrorMessage('file',this.errors,'File')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6" style="top: 40px">
                                    <div class="form-group" v-if="item.file">
                                        <a target="blank" :href="item.file">Link to view last uploded Document</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-md-12 savebtn">
                               <vue-button-spinner
                                    class="std_information_save col-md-2 col-md-2 btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                >
                                    Save
                                </vue-button-spinner>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {  },
    data() {
        return {
            name: '',
            description: '',
            file: ''
        }
    },
    computed: {
        ...mapGetters('CertificateSingle', ['item', 'loading','getEditData', 'editData','certificatePlaceHolders']),
    },
    created() {
         this.fetchCertificatePlaceHolder();
        this.fetchEditData(this.$route.params.id)
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchEditData(this.$route.params.id)
        },
        getEditData(value) {
            this.name = value.name
            this.description = value.description
            this.file = value.file
        }
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('CertificateSingle', ['fetchCertificatePlaceHolder','fetchEditData', 'setName', 'setDescription', 'setFile', 'updateData', 'resetState']),

        updateName(e) {
            this.setName(e.target.value)
        },
        updateDescription(e) {
            this.setDescription(e.target.value)
        },
        updateFile(e) {
            this.setFile('')
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'text/html' || e.target.files[0].type == 'application/x-php'){
                    const fileVal = []
                    fileVal.push(e.target.files[0])
                    this.setFile(e.target.files[0])
                    this.$forceUpdate()
                }else{
                    var message = ""
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'text/html' || e.target.files[0].type == 'application/x-php')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only PHP, Html files are allowed'
                    }
                        this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#file").val('')
                            this.$validator.errors.remove('file')
                            this.setFile('')
                        }
                    })
                } 
            }
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.updateData()
                    .then((res) => {
                        if(res.data.result) {
                            this.$router.push({ name: 'certificate.index' })
                            this.$eventHub.$emit('update-success')
                        }
                        else{
                            for (const [key, value] of Object.entries(res.data.data)) {
                                console.log("ke",key,value);
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }

                            return false;
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs)
                }
            })
        }
    }
}
</script>

<style>
.slider_image {
    position: relative;
    width: 1666px !important;
    height: 250px !important;
    object-fit: cover;
    margin: 25px 0px;
}
.slider-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
}
.img-responsive {
    max-width: 100%;
    height: auto;
}
.break-url {
    word-break: break-word;
}
</style>
