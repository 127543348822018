<template>
  <div class='topicModal'>
  <div class="popup-form ">
    <span class="student-name" @click="getData"  title="View Course Details">{{row.student_name}}</span>
    <b-modal :id="row.student_name"
             ref="studentCompletedCourseSummary"
             :title="`${row.student_name}`"
             @ok="handleOk"
             okTitle="Ok"
             okVariant="success"
             @cancel="handleCancel"
             modal-class=""
             :hide-footer="true"
             >
      <!--@shown="clear"-->
      <div class="container-fluid topic-modal-class">  
<!--         <b> Course : {{row.course_name}} </b> -->
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>
                    <th>#</th>
                    <th>Course Name</th>
                    <th>Course Status</th>
                    <th>Result</th>
                    <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if='courseProgressAll.length <= 0'>
                  <td colspan="3">
                    <div class="row" v-if="loadingTopics">
                        <div class="col-xs-4 col-xs-offset-4">
                            <div class="alert text-center">
                                <i class="fa fa-spin fa-refresh"></i> Loading...
                            </div>
                        </div>
                    </div>
                    <div v-else>
                      No data.
                    </div>
                  </td>
                </tr>
                <tr v-else v-for="(course, index) in courseProgressAll" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{course.display_course_name}}</td>
                    <td>{{course.final_course_status_name}}</td>
                    <td>
                        <router-link
                                v-if="$can('progress_report_access')"
                                :to="{ path: '/progress-report?student_id='+row.student_id+'&course_id='+course.course_id, params:{ student_id: row.student_id, course_id: course.course_id } }"
                                target="_blank"
                                title="View Progress Report"
                                >
                            <span class="myLabel" v-bind:style="{ backgroundColor: course.final_grade_color }">
                                {{course.final_grade}}
                            </span>
                        </router-link>
                    </td>
                    <td>
                        <router-link
                              v-if="$can('student_batch_listing_access') && !course.noBatchAvailable"
                              :to="{ path: '/student-batch-listing?student_id='+row.student_id+'&course_id='+course.course_id }"
                              target="_blank"
                              title="View Schedule Details"
                              >
                              <button
                                type="button" 
                                class="btn btn-info btn-xs">
                                    <i class="fa fa-calendar"></i>
                              </button>
                      </router-link>
                      <span v-if="course.course_status == 1 && $can('can_completed_course_from_student_master')" style="margin-left:5px;">
                        <button 
                            type="button"
                            title="Mark this Course as Completed" 
                            style="background-color: #44946f; !important;" 
                            class="btn btn-success btn-xs"
                            @click="markCourseAsCompleted(course, course.display_course_name)">
                        Mark as Completed
                        </button>
                       </span>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopicStatusTags from '../TopicStatusTags'
function dd(...args){
  for (let i in args){
    console.log(args[i]);
  }
}
export default {
  props:['row'],
  components:{
    TopicStatusTags,
  },
  data () {
    return {
        studentIdFromRoute: this.row.student_id,
        courseProgressAll: [],
        loadingTopics: true
    }
  },
  create() {
    console.log('klsjdfklasklfas',this.row)
  },
  methods: {
    handleCancel(evt){
       this.$refs.modal.hide()
    },    
    handleOk (evt) {
       this.$refs.modal.hide()
    },
    handleSubmit () {
      this.$refs.modal.hide()
    },
    getData(){
        this.loadingTopics = true
        axios.get('/api/v1/studentCourseProgressList/'+ this.row.student_id)
            .then(response => {
                var finalData = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    if(response.data.data[i].course_status == 3) {
                        finalData.push(response.data.data[i])
                    }
                } 
                this.courseProgressAll = finalData;
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            })  
            .finally(() => {
                this.loadingTopics = false
            })      
        this.$refs['studentCompletedCourseSummary'].show()
    },
    
  }
}
</script>
<style >
  .topicModal .popup-form{
      position: relative;
      top: unset;
      right: unset;
  }
  .topic-modal-class{
      height: 400px;
      overflow: auto;
  }
  .student-name {
      cursor: pointer;
      color: #3c8dbc;
  }
.myLabel {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}  
</style>