<template> 
    <div class="project-card">        
        <div class="">
            <div class="col-md-12 no-pad-show">
                <div class="card-header clearfix">
                    <div class="float-left col-md-5">
                        <label for="topic_name">Topic<p class="astrisk">*</p></label>
                         <v-select
                            id="topic_name"
                            name="topic_name"
                            label="topic_name"
                            v-validate="'required'"
                            @input="updateTopicId"
                            :value="item.topicAll"
                            :options="topicAll"
                            />
                            <span class="error" v-show="errors.has('topic_name')">
                            {{ $root.updateErrorMessage('topic_name',this.errors,'Topics Name')}}
                            </span>
                    </div>
                     <div class="float-left col-md-5">
                        <label for="subtopic_name">SubTopic<p class="astrisk">*</p></label>
                         <v-select
                            id="subtopic_name"
                            name="subtopic_name"
                            label="topic_name"
                            @input="updateSubtopicId"
                            :value="item.subtopicAll"
                            :options="subTopicAll"
                            />
                            <span class="error" v-show="errors.has('subtopic_name')">
                            {{ $root.updateErrorMessage('subtopic_name',this.errors,'SubTopics Name')}}
                            </span>
                    </div>
                    <b-btn :disabled="item.topicAll.length == 0 || (item.topicAll.have_quiz != 1 && item.subtopicAll.have_quiz != 1)" @click="openAdd" v-b-modal.modalPreventAddQuiz class="btn btn-success btn-sm float-right mt-4">+ Add Quiz</b-btn>  
                    <AddQuiz v-if="addView" :project_id="project_id" :topic_id="''" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></AddQuiz>  
                </div>
            </div>
            <div class="col-md-12"><hr/></div>
            <div class="col-md-12">
                <div class="card-body project-drag-view" v-if="QuestionListing.length > 0">
                    <draggable
                        v-bind="dragOptions"
                        tag="ul"
                        :value="QuestionListing"
                        class="dragArean todo-list"
                        @input="emitter"
                        :move="onMove"
                    >
                        <li :class="'parent ' + el.id" :key="el.id" v-for="el in QuestionListing">
                            <div v-if="el.topic_subtopic_id !=0">
                                <span class="handle handlemore ">
                                <i class="fas fa-ellipsis-v"></i>
                                <i class="fas fa-ellipsis-v"></i>
                                </span>
                                <span class="text"><p>{{ el.quiz_text }}</p></span>
                                <div class="tools float-right" style="top:0px;">
                                    <div class="btn-group btn-group-sm">
                                        <!-- This below v-b-modal.modalPreventView call at show page modal -->
                                        <button v-b-modal.modalPreventView v-if="$can('quiz_view')" @click="getView(el.id)" class="btn btn-primary edit_delete_btn" title="View"><i class="fa fa-fw fa-eye"></i></button>
                                        <button v-b-modal.modalPreventSkills v-if="$can('quiz_view')" @click="getSkills(el.id)" class="btn btn-warning edit_delete_btn" title="Skills"><i class="fas fa-book-reader"></i></button>
                                        <!-- This below v-b-modal.modalPreventEdit call at Edit page modal -->
                                        <button v-b-modal.modalPreventEdit v-if="$can('quiz_edit')" @click="getEdit(el.id)" class="btn btn-success edit_delete_btn" title="Edit"><i class="fa fa-fw fa-pencil"></i></button>
                                        <button v-if="$can('quiz_delete')"
                                                @click="destroyDataaaa(el.id)"
                                                type="button"
                                                title="Delete"
                                                class="btn btn-danger edit_delete_btn">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>  
                                    </div>                  
                                </div>
                            </div>
                        </li>
                    </draggable>
                </div>
                <div class="text-align-center" v-else>
                    <span>Oops! Data not found. Please select Topic - SubTopic first!</span>
                </div>
             </div>
            <div v-if="isLoading" slot="loadingContent">
                <CustomLoader></CustomLoader>
            </div>
            <!-- /.card-body -->            
        </div>
        <EditQuiz v-if="editView" :project_id="project_id" :question_id="question_id" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></EditQuiz>
        <ViewQuiz v-if="view_quiz" :project_id="project_id" :question_id="question_id" @closeModels="closeModels"></ViewQuiz>   
       <QuizSkills v-if="is_skill" :project_id="project_id" :topic_id="item.topicAll" :subtopic_id="item.subTopicAll" :quiz_id="quiz_id" @closeModels="closeModels"></QuizSkills>                
    </div>                 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
import draggable from "vuedraggable";
import AddQuiz from '../Quiz/Create'
import EditQuiz from '../Quiz/Edit'
import ViewQuiz from '../Quiz/Show'
import QuizSkills from '../Quiz/QuizSkills'
export default {
    components: {
        CustomLoader,
        draggable,
        AddQuiz,
        EditQuiz,
        ViewQuiz,
        QuizSkills,
    },
    props:[ 'project_id' ],
    data() {
        return {
            isLoading: false,
            selectedTopic:false,
            addView:false,
            editView:false,
            question_id:0,
            topic_subtopic_id:'',
            quiz_id:'',
            id:0,
            view_quiz:false,
            is_skill:false,
        }
    },
    computed: {
        ...mapGetters('QuizSingle', ['item', 'loading', 'topicAll', 'subTopicAll', 'QuizList', 'QuestionListing']),
        dragOptions() {
            return {
                animation: 0,
                group: "people",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    created() {
        this.selectedTopic = false;
        this.setItemTopicAll([]);
        this.setSubTopicAll([]);
        this.setItemSubTopicAll([]);
        this.setQuestionListing([]);
        this.fetchTopicAll(this.project_id)
        this.loadQuestionList();
    },
    watch:{
        "$route.params.id": function() {
            this.selectedTopic = false;
        }
    },
    methods: {
        ...mapActions('QuizSingle', ['fetchTopicAll', 'fetchSubtopicAll','fetchTopicSubtopicAll', 'setItemTopicAll', 'setSubTopicAll', 'setItemSubTopicAll', 'fetchQuizData', 'fetchQuestionData', 'destroyData', 'updateElements', 'setQuestionListing']), 
        updateTopicId(value){
            this.selectedTopic = false;
            value = (value) ? value : [];
            this.setItemTopicAll(value);
            if(this.editView != true && this.view_quiz != true && this.addView != true){
                this.setItemSubTopicAll([]);
            }
            if(value && value.length != 0){
                this.fetchQuestionData(value);
                this.fetchSubtopicAll({'project_id': this.project_id, 'topic_id':value.id});
                this.selectedTopic = true;
            }else{
                this.loadQuestionList();
            }
        },
        updateSubtopicId(value){
            value = (value) ? value : [];
            this.setItemSubTopicAll(value);
            if(value && value.length != 0){
                this.fetchQuestionData(value);
            }else{
                this.loadQuestionList();
            }
        },
        closeModels(){
            this.editView = false;
            this.view_quiz = false;
            this.addView = false;
        },
        openAdd(){
            this.addView = true;
        },
        getEdit(id){
            if(id > 0){
                this.question_id = id;
                this.editView = true;
            }
        },
        getView(id){
            if(id > 0){
                this.question_id = id;
                this.view_quiz = true;
            }
        },
        getSkills(id){
            if(id > 0){
                this.quiz_id = id;
                this.is_skill = true;
            }
        },
        emitter(value) {
            this.updateElements(value);
            this.loadQuestionList();
        },
        loadQuestionList(){
            console.log("here");
            if(this.item.subtopicAll && this.item.subtopicAll.length != 0){
                this.fetchQuestionData(this.item.subtopicAll);
            }else if(this.item.topicAll && this.item.topicAll.length != 0){
                this.fetchQuestionData(this.item.topicAll);
            }else{
                this.setItemTopicAll([]);
                this.setSubTopicAll([]);
                this.setItemSubTopicAll([]);
                this.setQuestionListing([]);
            }
        },
        onMove(evt){
           $(evt.related).find('.dragArean').css('min-height','70px').css('border','1px dotted black');
           setTimeout(function(){
                $('.dragArean').css('min-height','0px').css('border','unset');;
           },300); 
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            this.$eventHub.$emit('delete-success')
                        }else{
                            this.$eventHub.$emit('common',3,response.data.message)
                        }                     
                        this.loadQuestionList();
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}

.project-card{
    margin: 10px;
}
.todo-list>li .text {
    margin-left: 45px;
    max-width: 900px;
    padding-top: 3px;
}
.project-drag-view ul.dragArean li.parent {
   position: relative;
}
.project-drag-view ul.dragArean li.parent p {
    margin-bottom: 15px;
}
.project-drag-view ul.dragArean li.parent span.handlemore {
    position: absolute;
    top: 5px;
    left: 10px;
}
</style>
