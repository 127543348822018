function initialState() {
    return {
        item: {
            project: [],
            topic_name:'',
            quiz:[],
            tips_description:'',
            tips_file:'',
            topicSubtopicAll:[],
            question: '',
            quiz_image:'',
            topicAll:[],
            subtopicAll:[],
        },
        OptionsData:[],
        IsImageA:[],
        IsImageNext:[],
        OptionsOriginalData:[],
        projectList:[],
        topicSubtopicAll:[],
        loading: false,
        QuestionListing:[],  
        quizList:[],
        optionImage:[],
        topicAll:[],
        subTopicAll:[],
    }
}

const getters = {
    item: state => state.item,
    addParams: state => state.addParams,
    loading: state => state.loading,
    projectList: state => state.projectList,
    topicSubtopicAll: state => state.topicSubtopicAll,
    topicAll: state => state.topicAll,
    subTopicAll: state => state.subTopicAll,
    QuestionListing:state => state.QuestionListing,
    quizList:state => state.quizList,
    OptionsData:state => state.OptionsData,
    OptionsOriginalData:state => state.OptionsOriginalData,
    optionImage:state => state.optionImage,
    IsImageA:state => state.IsImageA,
    IsImageNext:state => state.IsImageNext,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.project)) {
            params.set('project_id', '')
        } else {
            params.set('project_id', state.item.project.id)
        }   
        if (state.item.subtopicAll && state.item.subtopicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.subtopicAll.id)
        } else if (state.item.topicAll && state.item.topicAll.length != 0) {
            params.set('topic_subtopic_id', state.item.topicAll.id)
        } else{
            params.set('topic_subtopic_id', '')
        }
        if (_.isEmpty(state.item.quiz)) {
            params.set('quiz_value_id', '')
        } else {
            params.set('quiz_value_id', state.item.quiz.value_id)
        } 
        if (_.isEmpty(state.item.question)) {
            params.set('quiz_text', '')
        } else {
            params.set('quiz_text', state.item.question)
        } 
        params.set('quiz_image', '')
        if(state.item.quiz_image && typeof state.item.quiz_image.name == 'string'){
            params.set('quiz_image', state.item.quiz_image)
        }
        params.set('tips_file', '')
        if(state.item.tips_file && typeof state.item.tips_file.name == 'string'){
            params.set('tips_file', state.item.tips_file)
        }
        params.set('tips_description', state.item.tips_description)
        if (_.isEmpty(state.OptionsData)) {
            params.set('option', '')
        } else {
            if(state.item.quiz.value_id == 2){
                state.OptionsData.forEach(function(v, i){
                    params.set('option[' + i + ']', v.options);
                    params.set('option_key[' + i + ']', 'option_key_'+v.row_number);
                })
            }else if(state.item.quiz.value_id == 3){
                state.OptionsData.forEach(function(v, i){
                    params.set('option[' + i + ']', v.options);
                    params.set('option_second[' + i + ']', v.option_second);
                    params.set('opt_is_img[' + i + ']', state.IsImageA[i]);
                    params.set('opt_is_img_second[' + i + ']', state.IsImageNext[i]);
                })
            }else if(state.item.quiz.value_id == 4){
                state.OptionsData.forEach(function(v, i){
                    params.set('option[' + i + ']', v.options);
                    params.set('correct_answer[' + i + ']', v.option_second);
                })
            }else if(state.item.quiz.value_id == 5){
                state.OptionsData.forEach(function(v, i){
                    params.set('option[' + i + ']', v.options);
                    params.set('correct_answer[' + i + ']', v.option_second);
                })
            }else if(state.item.quiz.value_id == 6){
                state.OptionsData.forEach(function(v, i){
                    state.optionImage.forEach(function(val, inx){
                        if(val.row_number == v.row_number){
                            params.set('option[' + i + ']', val.image);
                        }
                    });
                    //params.set('option[' + i + ']', state.optionImage[i]);
                    params.set('correct_answer[' + i + ']', v.option_second);
                })
            }
        } 
       
        axiosLms.post('/api/v1/quiz', params,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                commit('setLoading', false)
            })                    
           // dispatch('getListing', state.item.project.id)
        })        
    },
    updateData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
    
            if (_.isEmpty(state.item.project)) {
                params.set('project_id', '')
            } else {
                params.set('project_id', state.item.project.id)
            }   
            if (state.item.subtopicAll && state.item.subtopicAll.length != 0) {
                params.set('topic_subtopic_id', state.item.subtopicAll.id)
            } else if (state.item.topicAll && state.item.topicAll.length != 0) {
                params.set('topic_subtopic_id', state.item.topicAll.id)
            } else{
                params.set('topic_subtopic_id', '')
            }
            if (_.isEmpty(state.item.quiz)) {
                params.set('quiz_value_id', '')
            } else {
                params.set('quiz_value_id', state.item.quiz.value_id)
            } 
            if (_.isEmpty(state.item.question)) {
                params.set('quiz_text', '')
            } else {
                params.set('quiz_text', state.item.question)
            } 
            params.set('quiz_image', '')
            if(state.item.quiz_image && typeof state.item.quiz_image.name == 'string'){
                params.set('quiz_image', state.item.quiz_image)
            }
            params.set('tips_file', '')
            if(state.item.tips_file && typeof state.item.tips_file.name == 'string'){
                params.set('tips_file', state.item.tips_file)
            }
            params.set('tips_description', state.item.tips_description)
            if (_.isEmpty(state.OptionsData)) {
                params.set('option', '')
            } else {
                if(state.item.quiz.value_id == 2){
                    state.OptionsData.forEach(function(v, i){
                        params.set('option[' + i + '][id]',v.id);
                        params.set('option[' + i + '][option]',v.options);
                        params.set('option_key[' + i + ']', 'option_key_'+v.row_number);
                    })
                }else if(state.item.quiz.value_id == 3){
                    state.OptionsData.forEach(function(v, i){
                        params.set('option[' + i + '][id]',v.id);
                        params.set('option[' + i + '][option]',v.options);
                        if(state.IsImageA[i] == 1 && typeof v.options == 'string'){
                            params.set('option[' + i + '][option]', '');
                        }
                        params.set('option_second[' + i + ']', v.option_second);
                        if(state.IsImageNext[i] == 1 && typeof v.option_second == 'string'){
                            params.set('option_second[' + i + ']', '');
                        }
                        params.set('opt_is_img[' + i + ']', state.IsImageA[i]);
                        params.set('opt_is_img_second[' + i + ']', state.IsImageNext[i]);
                    })
                }else if(state.item.quiz.value_id == 4){
                    state.OptionsData.forEach(function(v, i){
                        params.set('option[' + i + '][id]',v.id);
                        params.set('option[' + i + '][option]',v.options);
                        params.set('correct_answer[' + i + ']', v.option_second);
                    })
                }else if(state.item.quiz.value_id == 5){
                    state.OptionsData.forEach(function(v, i){
                        params.set('option[' + i + '][id]',v.id);
                        params.set('option[' + i + '][option]',v.options);
                        params.set('correct_answer[' + i + ']', v.option_second);
                    })
                }else if(state.item.quiz.value_id == 6){
                    state.OptionsData.forEach(function(v, i){
                        params.set('option[' + i + '][id]',v.id);
                        params.set('option[' + i + '][option]', 0);
                        state.optionImage.forEach(function(val, inx){
                            if(val.row_number == v.row_number){
                                params.set('option[' + i + '][option]', val.image);
                            }
                        });
                        params.set('correct_answer[' + i + ']', v.option_second);
                    })
                }
            } 
           
            axiosLms.post('/api/v1/quiz/'+id, params,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                            console.log('Error', error.message);
                            let message = error.response.data.message || error.message
                            let errors  = error.response.data.errors
                            dispatch(
                                'Alert/setAlert',
                                { message: message, errors: errors, color: 'danger' },
                                { root: true })
                            reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })                    
               // dispatch('getListing', state.item.project.id)
            })  
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/quiz/' + id)
                .then(response => {
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                }) 
                .finally(() => {
                    //commit('setError', false)
                })
            // dispatch('getListing', state.item.project.id)
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projects/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectId', response.data.data)
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            }) 
                          
    },
    fetchTopicAll({ commit }, project_id) {
        axiosLms.get('/api/v1/getQuizTopic/'+project_id)
            .then(response => {
                commit('setTopicAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    fetchSubtopicAll({ commit }, ids) {
        axiosLms.get('/api/v1/getQuizSubTopic/'+ids.project_id+'/'+ids.topic_id)
            .then(response => {
                commit('setSubTopicAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    fetchTopicSubtopicAll({ commit }, project_id) {
        axiosLms.get('/api/v1/getTopicSubTopic/'+project_id)
            .then(response => {
                commit('setTopicSubtopicAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    fetchProjectAll({ commit }) {
        axiosLms.get('/api/v1/getAllProjects')
            .then(response => {
                commit('setProjectAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projects/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectId', response.data.data)
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            }) 
                          
    },
    async fetchQuestionData({ commit, dispatch }, data) {
        await axiosLms.get('/api/v1/getQuizBytopicSubtopic/' + data.id)
        .then(response => {
            if(response.data.result){
                commit('setQuestionListing', response.data.data)
            }
        })
        .catch(function (error) {
                console.log('Error', error.message);
        })             
    },
    async fetchQuizTopicDetails({ commit, dispatch }, data) {
        await axiosLms.get('/api/v1/getQuizTopicDetail/' + data)
        .then(response => {
            if(response.data.result){
                //console.log(response.data.data);
                if(response.data.data.topic_detail.parent_topic_id != 0){
                    commit('setItemTopicAll', response.data.data.parent_topic_detail)
                    commit('setItemSubTopicAll', response.data.data.topic_detail)
                }
                else{
                    commit('setItemTopicAll', response.data.data.topic_detail)
                    commit('setItemSubTopicAll', [])
                }
            }
        })
        .catch(function (error) {
                console.log('Error', error.message);
        })             
    },
    async fetchQuizData({ commit, dispatch, state }, id) {
        await axiosLms.get('/api/v1/quiz/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setItem', response.data.data)
                    commit('setItemTopicAll', response.data.data.topicAll)
                    commit('setItemSubTopicAll', response.data.data.subtopicAll)
                    var quizData = response.data.data.quiz;
                    if(quizData && response.data.data.question_options){
                        var alldetails = response.data.data.question_options;
                        alldetails.forEach(function(v,i){
                            v.row_number = i+1; 
                            var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                            state.IsImageA[i] = 0
                            if(regexp.test(v.options)){
                                state.IsImageA[i] = 1
                            }
                            state.IsImageNext[i] = 0;
                            if(regexp.test(v.option_second)){
                                state.IsImageNext[i] = 1;
                            }
                            if(quizData.value_id != 3){
                                v.option_second = v.correct_answer;
                            }
                        })
                        commit('setOriginalOption', alldetails)
                    }
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            }) 
                          
    },
    async fetchQuizType({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/getQuizType')
            .then(response => {
                if(response.data.result){
                    commit('setQuizType', response.data.data)
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            }) 
                          
    },
    setSubTopicAll({ commit }, value) {
        commit('setSubTopicAll', value)
    },
    setItemTopicAll({ commit }, value) {
        commit('setItemTopicAll', value)
    },
    setItemSubTopicAll({ commit }, value) {
        commit('setItemSubTopicAll', value)
    },
    setItemQuizSubTopicAll({ commit }, value) {
        commit('setItemQuizSubTopicAll', value)
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setRemoveItem({ commit }, value) {
        commit('setRemoveItem', value)
    },
    setDescription(state, value){
        commit('setDescription', value)
    },
    setTopicFiles({ commit }, value) {
        commit('setTopicFiles', value)
    },
    setProjectId({ commit }, value) {
        commit('setProjectId', value)
    },
    setQuestionListing({ commit }, value) {
        commit('setQuestionListing', value)
    },
    setQuizType({ commit }, value) {
        commit('setQuizType', value)
    },
    setQuiz({ commit }, value) {
        commit('setQuiz', value)
    },
    setQuestion({ commit }, value) {
        commit('setQuestion', value)
    },
    setOption({ commit }, value) {
        commit('setOption', value)
    },
    setOriginalOption({ commit }, value) {
        commit('setOriginalOption', value)
    },
    setOptionImage({ commit }, value) {
        commit('setOptionImage', value)
    },
    setQuestionImage({ commit }, value) {
        commit('setQuestionImage', value)
    },
    setMatchImageA({ commit }, value) {
        commit('setMatchImageA', value)
    },
    setInputA({ commit }, value) {
        commit('setInputA', value)
    },
    setInputSecond({ commit }, value) {
        commit('setInputSecond', value)
    },
    setMatchImageNext({ commit }, value) {
        commit('setMatchImageNext', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    },
    async updateElements({ commit }, payload) {
        await axiosLms.post('/api/v1/quesUpdateSortOrder', payload)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })   
    },    
}

const mutations = {
    setItem(state,value){
        state.item = value;
        state.OptionsData =  value.question_options;
    },
    setRemoveItem(state,value){
        state.item.quiz = [];
        state.item.tips_file = '';
        state.item.tips_description = '';
        state.item.question = '';
        state.OptionsData = [];
        state.optionImage = [];
        state.OptionsOriginalData = [];
    },
    setProjectAll(state,value){
        state.projectList = value;
    },
    setTopicSubtopicAll(state,value){
        state.topicSubtopicAll = value
    },
    setTopicAll(state,value){
        state.topicAll = value
    },
    setSubTopicAll(state,value){
        state.subTopicAll = value
    },
    setItemTopicAll(state, value) {
        state.item.topicAll = value
    },
    setItemSubTopicAll(state, value) {
        state.item.subtopicAll = value
    },
    setProjectAll(state,value){
        state.projectList = value;
    },
    setQuestionListing(state,value){
        state.QuestionListing = value;
    },
    setDescription(state, value){
        state.item.tips_description = value
    },
    setTopicFiles(state, value) {
       state.item.tips_file = value;
    },
    setProjectId(state, value) {
        state.item.project = value;
    },
    setQuizType(state, value) {
        state.quizList = value;
    },
    setQuiz(state, value) {
        state.item.quiz = value;
    },
    setQuestion(state, value) {
        state.item.question = value;
    },
    setQuestionImage(state, value) {
        state.item.quiz_image = value;
    },
    setOptionImage(state, value) {
        if(value){
            var details = {};
            details['image'] = value.data;
            details['row_number'] = value.row_number
            state.optionImage.push(details);
        }
    },
    setOption(state, value) {
        state.OptionsData = [];
        if(value){
            state.OptionsData = value;
        }
    },
    setInputA(state, value) {
        state.OptionsData.forEach(function(v, i){
            if(value.index == i){
                state.OptionsData[i].options = value.data
                state.IsImageA[i] = 0;
            }
        });
    },
    setMatchImageA(state, value) {
        state.OptionsData.forEach(function(v, i){
            if(value.data && value.index == i){
                state.OptionsData[i].options = value.data
                state.IsImageA[i] = 1;
            }
        });
    },
    setInputSecond(state, value) {
        state.OptionsData.forEach(function(v, i){
            if(value.index == i){
                state.OptionsData[i].option_second = value.data
                state.IsImageNext[i] = 0;
            }
        });
    },
    setMatchImageNext(state, value) {
        state.OptionsData.forEach(function(v, i){
            if(value.data && value.index == i){
                state.OptionsData[i].option_second = value.data
                state.IsImageNext[i] = 1;
            }
        });
    },
    setOriginalOption(state, value) {
        state.OptionsOriginalData = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
