<template>
    <div>
        <input type="checkbox" disabled :checked="value">
    </div>
</template>


<script>
export default {
    props: ['value'],
    data() {
        return {
            // Code...
        }
    },
    computed: {
        // Code...
    },
    created() {
        // Code...
    },
    destroyed() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>
