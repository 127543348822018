<template>
    <div>
         <span class="label label-info" v-if="value !== null && value.value_id ==1">
            {{ value[$root.relationships[field]] }}
        </span>
         <span class="label label-success" v-if="value !== null && value.value_id ==2">
            {{ value[$root.relationships[field]] }}
        </span>
         <span class="label label-danger" v-if="value !== null && value.value_id ==3">
            {{ value[$root.relationships[field]] }}
        </span>
         <span class="label label-primary" v-if="value !== null && value.value_id ==4">
            {{ value[$root.relationships[field]] }}
        </span>
       
    </div>
</template>


<script>
export default {
    props: ['value', 'field'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

