<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Email Templates</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/email-template'">Email Templates</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Email Template</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Email Template</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>Template Key</th>
                                            <td>{{ item.template_key ? item.template_key : '-'}}</td>
                                        </tr>
                                        <tr>
                                            <th>Title</th>
                                            <td>{{ item.title ? item.title : '-'}}</td>
                                        </tr>
                                        <tr>
                                            <th>Placeholder</th>
                                            <td>{{ item.placeholders ? item.placeholders : '-'}}</td>
                                        </tr>
                                        <tr>
                                            <th>Content</th>
                                            <td v-html="item.content"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
        }
    },
    created() {
        this.fetchEditData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('EmailTemplateSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchEditData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('EmailTemplateSingle', ['fetchEditData', 'resetState'])
    }
}
</script>

<style scoped>
.my-multipleCategory{
	margin: 0 6px 0 0;
}
.slider_image {
    position: relative;
    width: 1666px !important;
    height: 300px !important;
    object-fit: cover;
    margin: 25px 0px;
}
.slider-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    text-align: center;
    margin: 30px;
}
.img-responsive{
    max-width: 100%;
    height: auto;
}
</style>
