<template>
    <div class="btn-group btn-group-xs">
         <router-link
                v-if="$can(xprops.permission_prefix + 'view')" 
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"         
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-primary">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                v-if="$can(xprops.permission_prefix + 'delete')"         
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-danger">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module
        }
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {        
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            console.log('then')
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }else{
                            this.$eventHub.$emit('common',2,response.data.message)
                        }
                       
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
    }
}
</script>


<style scoped>

</style>
