<template>
   <div class="">
      <div class="p-10 m-10">
        Application done
      </div>
   </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
          //  loading:false
        }
    },
    computed: {
     //...
    },
    created(){
      //..
    },
    methods:{
    //  ...mapActions('AdmissionFormIndex', ['getAdmissionForm', 'storedata']),
      
        
    }
}
</script>