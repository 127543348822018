<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Progress Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Progress Report</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="student" class="form-group">
                                        <label for="student">
                                            Student Name
                                            <p class="astrisk">*</p>
                                        </label>
                                        <v-select
                                            id="student"
                                            name="student"
                                            label="student_name"
                                            v-validate="'required'"
                                            :options="studentAll"
                                            :value="item.student"
                                            @input="updateStudent"
                                            />
                                        <span class="error" v-show="errors.has('student')">
                                        {{ $root.updateErrorMessage('student',this.errors,'Student Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="course" class="form-group">
                                        <label for="course">
                                            Course Name
                                            <p class="astrisk">*</p>
                                        </label>
                                        <v-select
                                            id="course"
                                            name="course"
                                            label="display_course_name"
                                            v-validate="'required'"
                                            @input="updateCourse"
                                            :value="item.course"
                                            :options="courseAll"
                                            />
                                        <span class="error" v-show="errors.has('course')">
                                        {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="gender">
                                        Report Option
                                        <p class="astrisk">*</p>
                                    </label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                        <input
                                            type="radio"
                                            name="gender"
                                            ref="gender"
                                            v-validate="'required|included:1,2'"
                                            :value="1"
                                            :checked="item.gender === 1"
                                            @change="updateGender(1)"
                                            >
                                        <span class="checkmark"></span> Topic Wise
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label class="contain">
                                        <input
                                            type="radio"
                                            name="gender"
                                            ref="gender"
                                            :value="2"
                                            :checked="item.gender === 2"
                                            @change="updateGender(2)"
                                            >
                                        <span class="checkmark"></span> Learning Outcome Wise
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('gender')">
                                    {{ $root.updateErrorMessage('gender',this.errors,'Report Option')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner class="std_information_save btn btn-block btn-success new_branch_save">
                            Go
                        </vue-button-spinner>
                    </div>
                </form>
            </div>
            <br>
            <div class="row" v-if="dataSeen && item.course && item.course.course_type == 4">
                <div class="col-md-12">
                    <div class="box box-default">
                        <div class="box-body">
                            <div class="row">
                                <div class="col-xs-4 col-xs-offset-4" v-if="loading">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Progress Report...
                                    </div>
                                </div>
                                <div class="col-lg-10 col-lg-offset-1" v-else-if="!loading && listingAll.length > 0">
                                    <br>
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic Name</th>
                                                <th>Status</th>
                                                <th>Status Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(summerData, index) in listingAll" v-if="summerData.topic_status != 3">
                                                <td>{{index+1}}</td>
                                                <td>{{summerData.topic_name}}</td>
                                                <td>
                                                    <span class="label label-primary" v-if="summerData.topic_status == 0">
                                                    In Progress
                                                    </span>
                                                    <span class="label label-success" v-if="summerData.topic_status == 1">
                                                    Completed
                                                    </span>
                                                    <span class="label label-info" v-if="summerData.topic_status == 2">
                                                    Session Extended
                                                    </span>
                                                    <span class="label label-danger" v-if="summerData.topic_status == 3">
                                                    Absent
                                                    </span>
                                                </td>
                                                <td>
                                                    {{summerData.status_remark}}
                                                </td>                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="buttonSeenForSummerCourse && $can('progress_report_edit')" style="float:left; padding-top: 12px;">
                                        <div class="savebtn" style="text-align:center" v-if ="item.course.course_status == 1 &&!loadingMAC">
                                            <button 
                                                type="button"
                                                title="Mark this course as completed" 
                                                class="btn btn-block btn-primary add_course_btn std_information_save"
                                                @click="localMarkCourseAsCompleted()"
                                                >
                                            Mark as Completed
                                            </button>
                                        </div>
                                        <div class="savebtn" style="text-align:center" v-else>
                                            <button 
                                                type="button"
                                                title="This course is already completed"
                                                class="btn btn-block btn-primary add_course_btn std_information_save"
                                                disabled>
                                            Already Completed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="text-center">
                                        <h2>&#9785;</h2>
                                        <h4>Looks like there is no progress report data in here!</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else-if="dataSeen">
                <div class="col-lg-9">
                    <div class="box box-default">
                        <div class="box-body">
                            <div class="row">
                                <div class="col-xs-4 col-xs-offset-4" v-if="loading">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Progress Report...
                                    </div>
                                </div>
                                <div class="col-lg-12" v-else-if="!loading && listingAll.length > 0">
                                    <br>
                                    <table class="table table-bordered table-hover" v-if="item.course.course_status == 3">
                                        <thead>
                                            <tr>
                                                <th v-if="dataSeen && item.gender == 1">#</th>
                                                <th v-if="dataSeen && item.gender == 1">Topic</th>
                                                <th v-else>Learning Outcomes</th>
                                                <th>Grade</th>
                                                <th v-if="item.gender == 1">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(statusData, index) in listingAll">
                                                <td v-if="dataSeen && item.gender == 1" v-show="statusData.report_type == 1">{{ index+1 }}</td>
                                                <td v-if="dataSeen && item.gender == 1" v-show="statusData.report_type == 1">{{statusData.topic_name}}</td>
                                                <td v-else v-show="statusData.report_type == 2">{{statusData.topic_name}}</td>
                                                <td  v-if="dataSeen && item.gender == 1" v-show="statusData.report_type == 1">
                                                    <span v-if="statusData.color_code != null" class="myLabel" v-bind:style="{ backgroundColor: statusData.color_code }">
                                                        {{statusData.name}}
                                                    </span>
                                                    <span v-else>Completed</span>
                                                </td>
                                                <td v-if="dataSeen && item.gender == 2" v-show="statusData.report_type == 2">
                                                    <span class="myLabel" v-bind:style="{ backgroundColor: statusData.color_code }">
                                                    {{statusData.name}}
                                                    </span>
                                                </td>
                                                <td v-if="item.gender == 1 && statusData.color_code != null && statusData.report_type == 1">
                                                    <ViewAssessmentDetailsOfTopicPopup :rows="{student_id: statusData.student_id, course_id: statusData.course_id, topic_id: statusData.topic_id}"></ViewAssessmentDetailsOfTopicPopup>
                                                </td>
                                                <td v-if="item.gender == 1 &&  statusData.color_code == null && (statusData.report_type ? statusData.report_type == 1 : true)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table table-bordered table-hover" v-else>
                                        <thead>
                                            <tr>
                                                <th v-if="dataSeen && item.gender == 1">#</th>
                                                <th v-if="dataSeen && item.gender == 1">Topic</th>
                                                <th v-else>Learning Outcomes</th>
                                                <th>Grade</th>
                                                <th v-if="item.gender == 1">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(statusData, index) in listingAll">
                                                <td v-if="dataSeen && item.gender == 1">{{index+1}}</td>
                                                <td v-if="dataSeen && item.gender == 1">{{statusData.topic_name}}</td>
                                                <td v-else>{{statusData.sub_topic_name}}</td>
                                                <td>
                                                    <span v-if="statusData.topic_id != 0 && statusData.is_assessment_required != 0" class="myLabel" v-bind:style="{ backgroundColor: statusData.color_code }">
                                                    {{statusData.name}}
                                                    </span>
                                                    <span v-else-if="statusData.topic_id != 0 && statusData.is_assessment_required == 0">
                                                    Completed
                                                    </span>
                                                    <span v-else>Completed</span>
                                                </td>
                                                <td v-if="item.gender == 1">
                                                    <ViewAssessmentDetailsOfTopicPopup :rows="{from_schedule_listing: false, student_id: statusData.student_id, course_id: statusData.course_id, topic_id: statusData.topic_id, topic_name: statusData.topic_name}"></ViewAssessmentDetailsOfTopicPopup>
                                                </td>
                                                <td v-show="item.gender == 1" v-else></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <div v-if="buttonSeen && $can('progress_report_edit')" style="float:left; padding-top: 12px;">
                                            <div class="savebtn" style="text-align:center" v-if ="item.course.course_status == 1 &&!loadingMAC">
                                                <button 
                                                    type="button"
                                                    title="Mark this course as completed" 
                                                    class="btn btn-block btn-primary add_course_btn std_information_save"
                                                    @click="localMarkCourseAsCompleted()"
                                                    >
                                                Mark as Completed
                                                </button>
                                            </div>
                                            <div class="savebtn" style="text-align:center" v-else>
                                                <button 
                                                    type="button"
                                                    title="This course is already completed"
                                                    class="btn btn-block btn-primary add_course_btn std_information_save"
                                                    disabled>
                                                Already Completed
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="assessmentRequiredRecordsAvailable" 
                                            style="float: right; 
                                            font-weight: bold;
                                            font-size: 1.3em;
                                            border: 1px solid #d2d6de;
                                            padding: 10px;">
                                            <span>Final Grade:</span> 
                                            <span v-if="item.course.course_status == 3" class="myLabel" v-bind:style="{ backgroundColor: completedFinalGradeColor }" 
                                                style="
                                                padding-top: 0.4em;
                                                padding-right: 0.6em;
                                                padding-bottom: 0.3em;
                                                padding-left: 0.6em;">
                                            {{completedFinalGrade}}
                                            </span>              
                                            <span v-else class="myLabel" v-bind:style="{ backgroundColor: overAllOutcome.color_code }"
                                                style="
                                                padding-top: 0.4em;
                                                padding-right: 0.6em;
                                                padding-bottom: 0.3em;
                                                padding-left: 0.6em;">
                                            {{overAllOutcome.name}}
                                            </span>                                                                                                   
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="text-center">
                                        <h2>&#9785;</h2>
                                        <h4>Looks like there is no progress report data in here!</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="box box-default">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Progress Report Chart</h3>
                                    <div class="box-tools pull-right">
                                        <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                        </button> 
                                    </div>
                                </div>
                                <div class="box-body chart-responsive">
                                    <canvas id="pieChart" style="width: 600px; max-width: 100%;"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="item.course.course_status != 3">
                        <div class="col-md-12">
                            <div class="box box-default">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Specifications for Chart</h3>
                                    <div class="box-tools pull-right">
                                        <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                        </button> 
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ul class="chart-legend clearfix">
                                                <li v-for="(statusData, index) in performanceStatusAll">
                                                    <div class="Color_Box_Div greenColorBox" v-bind:style="{ backgroundColor: statusData.color_code }">
                                                    </div>
                                                    {{statusData.performance_status_name}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>            
        </section>
    </section>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import ViewAssessmentDetailsOfTopicPopup from '../../dtmodules/PopupActions/ViewAssessmentDetailsOfTopicPopup'
    
    export default {
        components:{
          ViewAssessmentDetailsOfTopicPopup
        },        
        props: ['student_id_from_props', 'course_id_from_props'],
        data() {
            return {
                pieChartCanvasHTML: null,
                pieChartDataGlobal: [],
                assessmentRequiredRecordsAvailable: false,
                dataSeen: false,
                completedFinalGrade: '',
                completedFinalGradeColor: '',
                count: 0,
            }
        },
        created() {
            this.$root.relationships = this.relationships
            this.fetchData().then(() => {
                // if(this.studentAll.length == 1) {
                //     this.updateStudent(this.studentAll[0])            
                // }                
                this.redirectedFromStudentFunction()
                });
        },   
        destroyed() {
            this.resetState()
        },    
        computed: {
            ...mapGetters('ProgressReportIndex', ['item', 'loading','performanceStatusAll', 'loadingMAC','relationships', 'studentAll','courseAll','listingAll','overAllOutcome', 'finalGrade','finalGradeColor','topicCount']),
            buttonSeen: function () {
                var ans = false;
                for (var i = 0; i < this.listingAll.length; i++) {
                    if(this.listingAll[i].isLastSessionCompleted == 1) {
                        ans = true;
                        break;
                    }
                }
                return ans;
            },
            buttonSeenForSummerCourse: function () {
                if(this.item.course.course_status == 4) {
                    return false;
                }
                var value = false,
                count = 0;
                for (var i = 0; i < this.listingAll.length; i++) {
                    if(this.listingAll[i].topic_status == 1) {
                        count++;
                    }
                }
                if(this.topicCount[0] == count || (this.item && this.item.course && this.item.course.course_type == 4)) {
                    value = true;
                }
                return value;
            }
        },
        methods: {
            ...mapActions('ProgressReportIndex', ['fetchData','fetchTopicCount','setCourse','setStudent','fetchPerformanceStatusAll', 'setGender','fetchStudentCourseAll','setQuery','getProgressReportData', 'evaluateFinalPercentage', 'resetState', 'markCourseAsCompleted','setFinalGrade', 'setFinalGradeColor']),             
            redirectedFromStudentFunction(){
                if(this.student_id_from_props && this.course_id_from_props) {
                    var studentIdLocal = this.student_id_from_props,
                    localStudentValue = '';
                    this.studentAll.forEach(function(value, index){
                        if(value.id == studentIdLocal){
                            localStudentValue = value;
                        }
                    });
                    this.updateStudent(localStudentValue);
                    this.setGender(1)
                } else {
                    let query = this.$route.query
                    this.fetchStudentCourseAll(query.student_id)
                    .then(()=>{
                        let self =this
                        if(query){
                          if(this.studentAll.length){
                            this.studentAll.forEach(async function(val){
                              if(val.id == query.student_id){
                                await self.updateStudent(val)
                                if(self.courseAll.length){
                                  self.courseAll.forEach(async function(cval){
                                    if(cval.id == query.course_id){
                                      await self.setCourse(cval)
                                      self.submitForm()
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                    })                
                }   
            },
            calculateOverAllPerformance() {
                var totalPercentage = 0,
                scoredPercentage = 0;
                for (var i = 0; i < this.listingAll.length; i++) {
                    if(this.listingAll[i].topic_id != 0 && this.listingAll[i].is_assessment_required != 0) {
                        totalPercentage = totalPercentage + parseFloat(this.listingAll[i].total_score);
                        scoredPercentage = scoredPercentage + parseFloat(this.listingAll[i].obtain_score);
                    }
                    if(this.listingAll[i].is_assessment_required == 1) {
                        this.assessmentRequiredRecordsAvailable = true;
                    }
                }
                if(this.assessmentRequiredRecordsAvailable) {
                    var finalPercentage = parseFloat(parseFloat(scoredPercentage / totalPercentage) * 100);
                    if(isNaN(finalPercentage)){
                      finalPercentage = 0
                    }
                    // if(this.item.course.course_type == 1) {
                        this.evaluateFinalPercentage(finalPercentage).then(()=>this.storeFinalGradeDetails());              
                        this.fetchPerformanceStatusAll()
                    // }
                }
                this.fireThePieChart();
            },
            storeFinalGradeDetails() {
                this.setFinalGrade(this.overAllOutcome.name)
                this.setFinalGradeColor(this.overAllOutcome.color_code)            
            },
        fireThePieChart() {
            if(this.pieChartCanvasHTML != null) {
                this.pieChartCanvasHTML.destroy()
                this.pieChartDataGlobal = []
            }
            var myArr = [],
            myNewArr = [],
            PieData = null,
            localCourseStatus = this.item.course.course_status,
            localCompletedFinalGrade = '',
            localCompletedFinalGradeColor = '';
            if(this.item.course.course_status != 3) {
                this.listingAll.forEach(function(value,index){
                        localCompletedFinalGrade = value.final_grade;
                        localCompletedFinalGradeColor = value.final_grade_color;
                })                
            }
            this.completedFinalGrade = localCompletedFinalGrade;
            this.completedFinalGradeColor = localCompletedFinalGradeColor;                  
          var labels = [],
          datas = [],
          backgroundColor = [];
        if(this.item.course.course_status != 3) {
              for (var i = 0; i < this.listingAll.length; i++) {
                if(this.item.gender == 1) {
                    if(this.listingAll[i].topic_id != 0 && this.listingAll[i].is_assessment_required != 0) {
                        this.pieChartDataGlobal.push({
                            'color_code': this.listingAll[i].color_code,
                            'name': this.listingAll[i].name,
                            'percentage': this.listingAll[i].percentage
                        })
                    }
                } else {
                    this.pieChartDataGlobal.push({
                        'color_code': this.listingAll[i].color_code,
                        'name': this.listingAll[i].name,
                        'percentage': this.listingAll[i].percentage
                    })                
                }
              }
        } else {
              for (var i = 0; i < this.listingAll.length; i++) {
                if(this.item.gender == 1) {
                    if(this.listingAll[i].report_type == 1 && this.listingAll[i].topic_id != 0 && this.listingAll[i].is_assessment_required != 0) {
                        this.pieChartDataGlobal.push({
                            'color_code': this.listingAll[i].color_code,
                            'name': this.listingAll[i].name,
                            'percentage': this.listingAll[i].percentage
                        })
                    }
                } else {
                    if(this.listingAll[i].report_type == 2) {
                        this.pieChartDataGlobal.push({
                            'color_code': this.listingAll[i].color_code,
                            'name': this.listingAll[i].name,
                            'percentage': this.listingAll[i].percentage
                        })
                    }
                }
              }
        }
          for (var i = 0; i < this.pieChartDataGlobal.length; i++) {
            if(labels.indexOf(this.pieChartDataGlobal[i].name) == -1) {
              labels.push(this.pieChartDataGlobal[i].name)
              datas.push(this.pieChartDataGlobal[i].percentage)
              backgroundColor.push(this.pieChartDataGlobal[i].color_code)
            } else {
                datas[labels.indexOf(this.pieChartDataGlobal[i].name)] = parseFloat(datas[labels.indexOf(this.pieChartDataGlobal[i].name)]) + parseFloat(this.pieChartDataGlobal[i].percentage)
            }
          }
          var PieDataFinal = {
            labels: labels,
            datasets: [{
                data: datas,
                backgroundColor: backgroundColor
            }]
          },
          pieChartCanvas = document.getElementById("pieChart").getContext("2d");
          this.pieChartCanvasHTML = new Chart(pieChartCanvas, {
                type: 'pie', 
                data: PieDataFinal,
                  options: {                 
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        }, 
                        legend: {
                            display: false
                        },      
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var label = data.labels[tooltipItem.index] || '';
                                    return label;
                                }
                            }
                        }                                 
                  }                    
            });
          this.count++;
        },        
            localMarkCourseAsCompleted() {
                this.$swal({
                    title: 'Are you sure you want to mark this course as completed?',
                    text: 'You won\'t be able to revert this!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Complete',
                    confirmButtonColor: '#dd4b39',
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.markCourseAsCompleted()
                        this.$eventHub.$emit('mark-as-comp')                                
                    }
                })
                .catch(error => {
                    this.$eventHub.$emit('action-error')
                })
            },
            updateStudent(value){
                // console.log('value',value)
                this.dataSeen = false;  
                this.setCourse(null);
                if(value){
                    this.setStudent(value);
                    this.fetchStudentCourseAll(value.id).then(()=>this.redirectedCourse());
                }
                else{
                    this.setStudent(null);
                    this.fetchStudentCourseAll(null);
                }
            },
            redirectedCourse(){
                if(this.student_id_from_props && this.course_id_from_props) {
                    var courseIdLocal = this.course_id_from_props,
                    localCourseValue = '';
                    this.courseAll.forEach(function(value, index){
                        if(value.id == courseIdLocal){
                            localCourseValue = value;
                        }
                    });
                    this.setCourse(localCourseValue);
                    this.dataSeen = true
                     this.getProgressReportData()
                        .then((res) => {
                            if(this.listingAll.length > 0){
                                this.calculateOverAllPerformance()
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                }
            },
            updateCourse(value){
                this.setCourse(value);
                if(this.student_id_from_props && this.course_id_from_props) {
                    if(this.count >= 1) {
                        this.dataSeen = false;
                    } else {
                        console.log("nothing")
                    }
                }
                else {
                    this.dataSeen = false;
                }
            },
            updateGender(value) {
                this.setGender(value)
                if(this.student_id_from_props && this.course_id_from_props) {
                    if(this.count >= 1) {
                        this.dataSeen = false;
                    } else {
                        console.log("nothing")
                    }
                }
                else {
                    this.dataSeen = false;
                }
            },        
            submitForm() {
                this.$validator.validate().then(result => {
                    if(result){
                        this.dataSeen = true
                        if(this.item.course.course_type == 2) {
                            this.fetchTopicCount(this.item.course.id)
                        }
                        this.getProgressReportData()
                            .then((res) => {
                                    this.calculateOverAllPerformance()
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                      }
                      else{
                        return this.$root.handleValidationFocus(this.errors,this.$refs);
                    }                
                  });  
            },
        }
    }
</script>
<style scoped>
    .myLabel {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    }
</style>