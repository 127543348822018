function initialState() {
    return {
        all: [],
        item: {
            search_topic_name: null,
        },
        relationships: {
            'country_list': 'country_name',
            'state_list': 'state_name',
            'city_list': 'city_name',
            'reference': 'name',
            'school': 'school_name',
            'search_topic_name': 'name',
            'standard': 'name',
            'status': 'name',
            'reason': 'name',
        },
        query: {},
        hotInquiryexcelFile: null,
        coldInquiryExcelFile: null,
        hotExcelUpdateDetails: [],
        coldExcelUpdateDetails: [],
        topicAll:[],
        loading: false,
        excelLoader: false,
        universalColdFlag: true,
        universalHotFlag: true
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['inquiry_type', 'original_format_date','original_date', 'student_name', 'contact_name', 'contact_number','area','city','inqStatus'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total:         state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['inquiry_type', 'original_format_date','original_date', 'student_name', 'contact_name', 'contact_number','area','city','inqStatus'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break             
              default:
                // nothing to do
                break
            }
          })
        return rows.length;
    },
    item: state => state.item,
    all: state => state.all,
    topicAll: state => state.topicAll,    
    hotInquiryexcelFile: state => state.hotInquiryexcelFile,    
    coldInquiryExcelFile: state => state.coldInquiryExcelFile,    
    loading: state => state.loading,
    excelLoader: state => state.excelLoader,
    hotExcelUpdateDetails: state => state.hotExcelUpdateDetails,
    coldExcelUpdateDetails: state => state.coldExcelUpdateDetails,
    universalColdFlag: state => state.universalColdFlag,
    universalHotFlag: state => state.universalHotFlag,
    relationships: state => state.relationships
}

const actions = {
    async exportReferenceDataListStore({ commit }) {
      window.location.href = location.protocol + "//" + location.host + '/exportReferenceDataList';
    },   
    async exportInquiryListStore({ commit }) {
      window.location.href = location.protocol + "//" + location.host + '/exportInquiryList';
    },      
    async fetchData({ commit, state, dispatch }, date_filter) {

        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/inquiries',{params:date_filter})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async importingColdInquiryExcelStore({ commit, state, dispatch }) {
    	commit('setExcelLoader', true)
        let params = new FormData();
        params.set('coldInquiryExcelFile', state.coldInquiryExcelFile)
        return new Promise((resolve, reject) => { 
          axios.post('/api/v1/importColdInquiryExcel', params)
              .then((response) => {
              	if(response.data.data[0] == false) {
              		commit('setUniversalColdFlag',response.data.data[0]);
              		resolve(response);  
              	} else {
  	            	if(response && response.data && response.data.data) {
  	                	window.location.href = window.location.protocol + "//" + window.location.host + '/exportColdInquiryExcel/' + response.data.data[0];
  	            	}
  	                resolve(response); 
              	}
              })
              .catch(function (error) {
              			  console.log('Error',error)
                      reject(error);
                      }) 
              .finally(() => {
                  commit('setExcelLoader', false)
              })            
        })            
    },
    async importingHotInquiryExcelStore({ commit, state, dispatch }) {
        commit('setExcelLoader', true)
        let params = new FormData();
        params.set('hotExcelFile', state.hotInquiryexcelFile)
        return new Promise((resolve, reject) => {        
          axios.post('/api/v1/importHotInquiryExcel', params)
              .then((response) => {
              	if(response.data.data[0] == false) {
              		commit('setUniversalHotFlag',response.data.data[0]);
              		resolve(response);
              	} else {
  	            	if(response && response.data && response.data.data) {
  	                	window.location.href = window.location.protocol + "//" + window.location.host + '/exportHotInquiryExcel/' + response.data.data[0];
  	            	}
  	                resolve(response);	
              	}
              })            
              .catch(function (error) {
                            reject(error);
                      }) 
              .finally(() => {
                  commit('setExcelLoader', false)
              })            
            })            
    },    
    async deleteTempColdInquiryExcelData({ commit, state, dispatch }, uploadId) {
        commit('setExcelLoader', true)
        await axios.delete('/api/v1/deleteTempColdInquiryExcelData')
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setExcelLoader', false)
            })            
    }, 
    async deleteTempHotInquiryExcelData({ commit, state, dispatch }, uploadId) {
        commit('setExcelLoader', true)
        await axios.delete('/api/v1/deleteTempHotInquiryExcelData')
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
            .finally(() => {
                commit('setExcelLoader', false)
            })            
    },          
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/v1/inquiries/' + id)
                .then(response => {
                    resolve(response);
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
                    .finally(() => {
                        commit('setError', false)
                    })
            })                
    },
    async fetchTopicAll({ commit }) {
        await axios.get('/api/v1/inqueryStatusList')
            .then(response => {
                commit('setTopicAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchHotExcelUpdateDetails({ commit, state, dispatch }, id) {
        await axios.get('/api/v1/fetchHotExcelUpdateDetails/'+id)
            .then(response => {
                commit('setHotExcelUpdateDetails', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
            .finally(() => {
              if(state.universalHotFlag) {
                 setTimeout(function () { dispatch('deleteTempHotInquiryExcelData'); }.bind(this), 240)
              }
            })              
    },
    async fetchColdExcelUpdateDetails({ commit, state, dispatch }, id) {
        await axios.get('/api/v1/fetchColdExcelUpdateDetails/'+id)
            .then(response => {
                commit('setColdExcelUpdateDetails', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
            .finally(() => {
              if(state.universalColdFlag) {
                 setTimeout(function () { dispatch('deleteTempColdInquiryExcelData'); }.bind(this), 240)
              }
            })              
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setHotExcelUpdateDetails({ commit }, value) {
        commit('setHotExcelUpdateDetails', value)
    }, 
    setColdExcelUpdateDetails({ commit }, value) {
        commit('setColdExcelUpdateDetails', value)
    },     
    setColdInquiryExcelFile({ commit }, value) {
        commit('setColdInquiryExcelFile', value)
    }, 
    setHotInquiryExcelFile({ commit }, value) {
        commit('setHotInquiryExcelFile', value)
    },    
    setUniversalColdFlag({ commit }, value) {
        commit('setUniversalColdFlag', value)
    }, 
    setUniversalHotFlag({ commit }, value) {
        commit('setUniversalHotFlag', value)
    },        
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setExcelLoader(state, loading) {
        state.excelLoader = loading
    },
    setUniversalColdFlag(state, value) {
        state.universalColdFlag = value
    },
    setUniversalHotFlag(state, value) {
        state.universalHotFlag = value
    },
    setHotExcelUpdateDetails(state, value) {
        state.hotExcelUpdateDetails = value
    },
    setColdExcelUpdateDetails(state, value) {
        state.coldExcelUpdateDetails = value
    },
    setTopicAll(state, value) {
        state.topicAll = value
    },
    setQuery(state, query) {
        state.query = query
    },
    setError(state, error) {
        state.error = error
    },    
    setExcelFile(state, value) {
        state.excelFile = value
    }, 
    setColdInquiryExcelFile(state, value) {
        state.coldInquiryExcelFile = value
    },  
    setHotInquiryExcelFile(state, value) {
        state.hotInquiryexcelFile = value
    },        
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
