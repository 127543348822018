<template>
    <div class="btn-group btn-group-xs">
        <router-link 
            v-if="row.topic_name"
            :to="'/student-assessment/' + $root.stringBtoA(row.student_id+'==='+row.topic_id+'==='+row.course_id+'==='+row.schedule_date+'==='+row.def_trainer_emp_id+'==='+row.student_batch_id+'==='+row.student_lab_id+'==='+row.student_attendance_id+'==='+row.topic_status_id) + '/show'">
            <!-- <i class="fa fa-edit link" aria-hidden="true"></i>             -->
             <img src="/assets/images/assessment.png" title="Review Assessment" alt="Review Assessment" width= "23px">
        </router-link>
        <img v-else style="opacity: 0.5; cursor: not-allowed;" src="/assets/images/assessment.png" title="Please Add Topic First" alt="Please Add Topic First" width= "23px">
<!--             <router-link
                    v-if="row.attendance == '1' && row.is_assessment_required == '1' && row.topic_status != 2" 
                    title="Review Student Assessment"
                    :to="'/student-assessment/' + $root.stringBtoA(row.student_id+'==='+row.topic_session_no+'==='+row.course_id+'==='+row.schedule_date+'==='+row.def_trainer_emp_id+'==='+row.student_batch_id+'==='+row.student_lab_id) + '/show'">
                <span class="label label-warning" v-if="row.topic_status == 0">{{row.display_topic_status}}</span>
                <span class="label label-success" v-else>{{row.display_topic_status}}</span>
            </router-link>
            <span class="label label-primary" v-else="row.attendance == '1' && row.is_assessment_required == '1' && row.topic_status == 2">{{row.display_topic_status}}</span>
            <span class="label label-primary" v-else="row.attendance == '1' && row.is_assessment_required == '0' && row.topic_status == 1">
                {{row.display_topic_status}}
            </span>  -->
   </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
             namespace: this.xprops.module ,
        }
    },
    methods: {
        ...mapActions('StudentAssessmentSingle', ['showAssessmentData','setShowStudentAttendance']),
          manageViewAssessmentData(data) {   
          this.setShowStudentAttendance(purify(data));
          this.showAssessmentData()
            .then(() => {
                this.$router.push({ name: 'student_assessment.ShowAssessment' })                
            })
            .catch((error) => {
                console.error(error)
            })
        },     
    }
}
</script>


<style scoped>
  .link {
    color: #299a4e;
  }
</style>
