<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branch Admission Fee</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branch-admission-fee'">Branch Admission Fee</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Branch Admission Fee</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Branch Admission Fee</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                    <tr>
                                        <th>Branch Name</th>
                                        <td>
                                            <span v-if="typeof item.branch !== 'undefined' && item.branch !== null">
                                                {{ item.branch.branch_name }}
                                            </span>
                                        </td>                                            
                                    </tr>
                                    <tr>
                                        <th>Fees Type</th>
                                        <td><span>{{ item.fee_type_name }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Fees Amount</th>
                                        <td><span> <i class="fa fa-rupee"></i> {{ item.amount }}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('BranchAdmissionFeeSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BranchAdmissionFeeSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
 