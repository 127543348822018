<template> 
    <div class="popup-form">
        <b-modal id="modalPreventTaskTutor"
            ref="modal"
            title="Add Tutor"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"  
            @cancel="handleCancel"
            hide-footer
            @shown="openModel" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true"
            >
            <CreateTutor :project_id="project_id" @loadQuestionList="loadQuestionList" @closeModels="handleCancel"></CreateTutor>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreateTutor from './CreateTutor.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        CreateTutor
    },
    props : ['project_id','topic_id'],
    computed: {
        ...mapGetters('TutorSingle', ['item', 'loading', 'topicAll', 'subTopicAll', 'projectList']),
    },
    created() {
        
    },
    methods: {
        ...mapActions('TutorSingle', ['fetchQuizTopicDetails','fetchTopicAll', 'fetchSubTopicAll', 'fetchProjectAll', 'fetchData', 'setQuestion', 'setRemoveItem', 'storeData', 'fetchQuestionData', 'checkQuestion']),

        handleCancel(evt) {
            this.makeEmptyModel()
            this.$refs.modal.hide();
            this.$emit('closeModels')
        },  
        makeEmptyModel() {
            this.setRemoveItem('')
            //this.$validator.errors.remove('question')
        },
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    //this.handleSubmit()
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        },
        openModel () {
            if(this.topic_id != ''){
                this.fetchQuizTopicDetails(this.topic_id)
            }
        },
        loadQuestionList(){
            this.$emit('loadQuestionList')
        }
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 75%;
}
</style>