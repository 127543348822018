function initialState() {
    return {
        item: {
            skill_header_id: '',
            skill_name:'',
            skill_label:'',
            rising_star:'',
            all_star:'',
            champion:'',
            skill_id:0,
            image:''
        },
        loading: false,
        headerList:[],
        SkillListing:[],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    headerList: state => state.headerList,
    SkillListing: state => state.SkillListing,
}

const actions = {
    async getListing({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/getSkillDetails/'+id)
        .then(response => {
            if(response.data.result){
                let data = response.data.data                 
                commit('setSkillListing', data)
                commit('setLoading', false)
            }
        })
        .catch(function (error) {
            commit('setLoading', false)
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/skill-header-details/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },
    fetchHeaderAll({ commit }) {
        axiosLms.get('/api/v1/skill-header')
        .then(response => {
            commit('setHeaderAll', response.data.data)       
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        }) 
    },
    fetchData({ commit, dispatch }, id) {
        axiosLms.get('/api/v1/skill-header/' + id)
        .then(response => {
            if(response.data.result){
                commit('setHeaderId', response.data.data)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.skill_header_id)) {
                params.set('skill_header_id', '')
            } else {
                params.set('skill_header_id', state.item.skill_header_id.id)
            }

            if (_.isEmpty(state.item.skill_name)) {
                params.set('skill_name', '')
            } else {
                params.set('skill_name', state.item.skill_name)
            }

            if (_.isEmpty(state.item.skill_label)) {
                params.set('skill_label', '')
            } else {
                params.set('skill_label', state.item.skill_label)
            }

            if (_.isEmpty(state.item.rising_star)) {
                params.set('rising_star', '')
            } else {
                params.set('rising_star', state.item.rising_star)
            }

            if (_.isEmpty(state.item.all_star)) {
                params.set('all_star', '')
            } else {
                params.set('all_star', state.item.all_star)
            }

            if (_.isEmpty(state.item.champion)) {
                params.set('champion', '')
            } else {
                params.set('champion', state.item.champion)
            }

            axiosLms.post('/api/v1/skill-header-details', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
            dispatch('getListing', state.item.skill_header_id.id)
        })        
    },
    fetchSkillEditData({ commit, dispatch }, id) {
        axiosLms.get('/api/v1/skill-header-details/' + id)
        .then(response => {
            if(response.data.result) {
                commit('setSkillId', response.data.data.id)
                commit('setSkillName', response.data.data.skill_name)
                commit('setSkillLabel', response.data.data.skill_label)
                commit('setRisingStar', response.data.data.rising_star)
                commit('setAllStar', response.data.data.all_star)
                commit('setChampion', response.data.data.champion)
                commit('setImage', response.data.data.image)
                dispatch('fetchData', response.data.data.skill_header_id)
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    },

    updateData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData()

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.skill_header_id)) {
                params.set('skill_header_id', '')
            } else {
                params.set('skill_header_id', state.item.skill_header_id.id)
            }

            if (_.isEmpty(state.item.skill_name)) {
                params.set('skill_name', '')
            } else {
                params.set('skill_name', state.item.skill_name)
            }

            if (_.isEmpty(state.item.rising_star)) {
                params.set('rising_star', '')
            } else {
                params.set('rising_star', state.item.rising_star)
            }

            if (_.isEmpty(state.item.all_star)) {
                params.set('all_star', '')
            } else {
                params.set('all_star', state.item.all_star)
            }

            if (_.isEmpty(state.item.champion)) {
                params.set('champion', '')
            } else {
                params.set('champion', state.item.champion)
            }

            axiosLms.post('/api/v1/skill-header-details/' + id, params)
                .then(response => {
                    resolve(response)
                })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },

    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    },
    setHeaderId({ commit }, value) {
        commit('setHeaderId', value)
    },
    setSkillId({ commit }, value) {
        commit('setSkillId', value)
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    },
    setRemoveItem({ commit }, value) {
        commit('setRemoveItem', value)
    },
    setSkillName({ commit }, value) {
        commit('setSkillName', value)
    },
    setSkillLabel({ commit }, value) {
        commit('setSkillLabel', value)
    },
    setRisingStar({ commit }, value) {
        commit('setRisingStar', value)
    },
    setAllStar({ commit }, value) {
        commit('setAllStar', value)
    },
    setChampion({ commit }, value) {
        commit('setChampion', value)
    },
    setImage({ commit }, value) {
        commit('setImage', value)
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setItem(state, value){
        state.item = value
    },
    setRemoveItem(state,value) {
        state.item.skill_name = ''
        state.item.skill_label = ''
        state.item.rising_star = ''
        state.item.all_star = ''
        state.item.champion = ''
        state.item.skill_id = ''
        state.item.image = ''
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setSkillListing(state,value) {
        state.SkillListing = value
    },
    setSkillId(state, value) {
        state.item.skill_id = value;
    },
    setHeaderId(state, value) {
        state.item.skill_header_id = value
    },
    setSkillName(state, value) {
        state.item.skill_name = value
    },
    setSkillLabel(state, value) {
        state.item.skill_label = value
    },
    setRisingStar(state, value) {
        state.item.rising_star = value
    },
    setAllStar(state, value) {
        state.item.all_star = value
    },
    setChampion(state, value) {
        state.item.champion = value
    },
    setImage(state,value){
        state.item.image = value
    },
    setHeaderAll(state,header_detail){
        state.headerList = header_detail
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
