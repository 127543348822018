<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Bypass Assessment</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Bypass Assessment</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="col-md-6">
                                    <div ref="course_name_id" class="form-group">
                                        <label for="course">Course Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="course_name_id"
                                                label="display_course_name"
                                                id="course"
                                                v-validate="'required'"
                                                @input="updateCourseId"
                                                :value="item.course_name_id"
                                                :options="coursesAll"
                                                />
                                        <span class="error" v-show="errors.has('course_name_id')">
                                            {{ $root.updateErrorMessage('course_name_id',this.errors, 'Course Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="topic" class="form-group">
                                        <label for="topic">Topic Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="topic"
                                                label="topic_name"
                                                id="topic"
                                                v-validate="'required'"
                                                @input="updateCourseTopicId"
                                                :value="item.course_topic"
                                                :options="coursetopicsAll"
                                                />
                                        <span class="error" v-show="errors.has('topic')">
                                            {{ $root.updateErrorMessage('topic',this.errors,'Topic Name')}}
                                        </span>
                                    </div>
                                </div> 
                                <div class="col-md-6 col-xs-12">
                                    <div ref="sub_topic" class="form-group">
                                        <label for="sub_topic">Sub Topic Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="sub_topic"
                                                label="name"
                                                id="sub_topic"
                                                v-validate="'required'"
                                                @input="updateSubTopicId"
                                                :value="item.sub_topic"
                                                :options="subtopicsAll"
                                                />
                                        <span class="error" v-show="errors.has('sub_topic')">
                                            {{ $root.updateErrorMessage('sub_topic',this.errors,'Sub Topic Name')}}
                                        </span>
                                    </div>
                                </div>                                                              
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                    <div id="loader_placeholder" v-show="loadingData">
                        <div id="loadingDiv">
                           <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            loadingData: false
        }
    },
    computed: {
        ...mapGetters('ByPassAssessmentSingle', ['item', 'loading', 'coursesAll', 'coursetopicsAll', 'subtopicsAll']),
    },
    created() {
        this.loadingData = true;
        this.fetchCoursesAll()
            .then(()=>{
                this.fetchSubTopicsAll()
                    .then(()=>{
                        this.fetchData(this.$route.params.id)
                            .then(()=>{
                                this.fetchCourseTopicsAll(this.item.course_id)
                                    .then(()=>{
                                        this.loadingData = false;
                                    })
                            })
                    })
            })
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('ByPassAssessmentSingle', ['fetchCoursesAll','setCourseId','fetchSubTopicsAll','fetchCourseTopicsAll', 'setCourseTopicId','setSubTopicId','fetchData', 'updateData', 'resetState', 'setHolidayName', 'setHoliday_date']),
        updateCourseId(value) {
            this.setCourseTopicId(null)
            if(value && value.id) {
                this.fetchCourseTopicsAll(value.id)
                this.setCourseId(value)
            }
        },      
        updateCourseTopicId(value) { 
            this.setCourseTopicId(value)
        },        
        updateSubTopicId(value) {
            this.setSubTopicId(value)
        },   
        submitForm() {
        this.$validator.validate().then(result => {
        if (result) {
            this.updateData()
                .then((res) => {
                    if(res.data.error_message)
                    {
                        this.$validator.errors.add({
                            id: 'sub_topic',
                            field: 'sub_topic',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'bypass_assessment.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }    

    }
}
</script>


<style scoped>
   #loadingDiv {
   background-color: #000;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 10020;
   filter: alpha(opacity=70);
   -moz-opacity: .7;
   -khtml-opacity: .7;
   opacity: .7;
   }
   .ajax-loader {
   position: fixed;
   left: 50%;
   top: 50%;
   margin-left: -32px;
   margin-top: -32px;
   display: block;
   z-index: 10020!important;
   }
   img {
   vertical-align: middle;
   }
   img {
   border: 0;
   }
</style>
 