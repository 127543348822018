<template>
    <div class="btn-group btn-group-xs">
        <!--  <a
            :href="xprops.domainName + '/generateReceiptInvoice/' + row.id"
            class="btn btn-info edit_delete_btn"
            title="Generate Receipt"
            target="_blank"
            >
            <i class="fa fa-fw fa-file-pdf-o"></i>
        </a> -->
        <button title="View" v-b-modal.modalPreventReceiptView v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' " @click="openReceiptId(row.id, 'view')" class="btn btn-success edit_delete_btn"><i class="fa fa-fw fa-eye"></i></button>
        <!-- <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' "
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link> -->
        <button title="Edit" v-b-modal.modalPreventReceiptEdit v-if="$can(xprops.permission_prefix + 'edit') && xprops.route != 'roles' && row.refund_ref_id == null && row.receipt_type == 1" @click="openReceiptId(row.id, 'edit')" class="btn btn-block btn-primary edit_delete_btn"><i class="fa fa-fw fa-pencil"></i></button>
        <!-- <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link> -->
    <!-- v-if="$can(xprops.permission_prefix + 'delete') && row.notRefundedYet" -->
        <button v-if="$can(xprops.permission_prefix + 'delete') && row.refund_ref_id == null && row.is_refund == null  && row.receipt_type == 1"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['row', 'xprops', 'student_id'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    computed: {
         ...mapGetters('StudentShowSingle', ['item', 'loading', 'TotalAmount', 'TotalPaid', 'TotalDue','TotalOverDue', 'TotalRefund']),
   //     ...mapGetters('ReceiptsSingle', ['item', 'loading','pendingFeeAll','paymentModeAll','paymentDetails']),
    },
    methods: {
        ...mapActions('StudentShowSingle', ['fetchData', 'fetchStudentCount']),
        //...mapActions('ReceiptsSingle', ['fetchData', 'fetchPendingFeeAll','fetchPaymentModeAll', 'setReceiptDate', 'setManualReceiptNo', 'setStudent','fetchPaymentFeeAll','setPaymentMode','setPaymentDetails','setPendingFeeAll','fetchPaymentMode']),
        openReceiptId(id, page){
            this.$emit('receiptIdModel',id) 
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(() => {
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                        this.$emit('loadItems')
                        this.fetchStudentCount(this.student_id);
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })    
        },        
    }
}
</script>


<style scoped>

</style>
