<template>
   <section class="content-wrapper" style="min-height: 960px;">
      <section class="content-header">
         <h1>Student Assessment</h1>
      </section>
      <section class="content">
         <div class="row">
            <div class="col-xs-12">
               <div class="row m-top-15">
                  <div class="col-md-3 col-xs-12">
                     <div class="form-group">
                        <label>Date</label>
                        <div class="input-group date">
                           <div class="input-group-addon" ref="schedule_date">
                              <i class="fa fa-calendar"></i>
                           </div>
                           <date-picker                                            
                              :config="$root.dpconfigDatePast"
                              name="schedule_date"
                              :value="item.schedule_date"
                              v-validate="'required'"
                              placeholder="Select Date"
                              @dp-change="updateSchedule_date"
                              autocomplete="off"
                              >
                           </date-picker>
                        </div>
                        <span class="error" v-show="errors.has('schedule_date')">
                        {{ $root.updateErrorMessage('schedule_date',this.errors,'Date')}}
                        </span>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="row" v-if="loading">
                     <div class="col-md-12">
                        <br>
                        <div class="alert text-center" style="font-size:1.6em;">
                           <i class="fa fa-spin fa-refresh"></i> <span>&nbsp;Loading Batches...</span> 
                        </div>
                     </div>
                  </div>
                  <div class="col-md-offset-3" v-else-if="mySelectedDetails == null && batchSheduleAll && batchSheduleAll.length <= 0">
                     <div class="warning-msg text-center">                                
                        There is no batch available on this date
                     </div>
                  </div>
                  <div v-else class="col-md-3 assessment_batch m-top-15">
                     <ul class="nav nav-tabs">
                        <li class="active" v-for="(shedule, index) in batchSheduleAll">
                           <div class="batch_time">
                              <a 
                                 data-toggle="tab"
                                 @click="updateBatchSheduleDetail({'id':shedule.batch_id,'date':shedule.schedule_date})"
                                 style="cursor: pointer;">
                                 <div :class="(activeID == shedule.batch_id)?'small-box bg-yellow':'small-box bg-green'">
                                    <div class="inner">
                                       <h4>{{shedule.batch_name}} - {{shedule.course_type}}</h4>
                                       <h3>{{shedule.start_time}} - {{shedule.end_time}}</h3>
                                    </div>
                                 </div>
                              </a>
                           </div>
                        </li>
                        <li>
                           <table v-if="batchSheduleAll.length > 0">
                              <tbody>
                                 <tr>
                                    <td>
                                       <div class="Color_Box_Div" style="background-color:#009933;"></div>
                                       <span>Listed Batches</span>
                                    </td>
                                    <td>
                                       <div class="Color_Box_Div" style="background-color:#FF9933;"></div>
                                       <span>Selected Batch</span>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-9 assessment_table m-top-15" 
                     v-if="batchSheduleAll && batchSheduleAll.length > 0 && !loading"
                     >
                     <div class="row">
                        <div class="col-xs-12">
                           <div class="box box-primary box-body">
                              <div class="box-header with-border">
                            <div class="text-left pull-right col-md-5 col-xs-12 no-padding">
                                <div class="col-md-12 col-xs-12 no-padding">
                                    <div class="col-md-3 no-padding student-assessment-specifications-text">Specifications:</div>
                                    <div class="col-md-3 no-padding student-assessment-inprogress-text">
                                        <div class="Color_Box_Div inProgressColor"></div>
                                        <span>In Progress</span>
                                    </div>
                                    <div class="col-md-3 no-padding student-assessment-session-text">
                                        <div class="Color_Box_Div sessionExtendedColor"></div>
                                        <span>Session Extended</span>
                                    </div>
                                    <div class="col-md-3 no-padding student-assessment-completed-text">
                                        <div class="Color_Box_Div completedColor"></div>
                                        <span>Completed</span>
                                    </div>
                                </div>
                            </div>
                              </div>
                              <div class="col-md-5 col-xs-12" v-if="tempActiveID">
                                 <label for="lab">
                                    Lab Name
                                    <p class="astrisk">*</p>
                                 </label>
                                 <div ref="lab" class="form-group">
                                    <v-select
                                       id="mySelect"
                                       name="lab"
                                       label="lab_name"
                                       @input="updateLab"
                                       v-validate="'required'"
                                       :value="item.lab_id"
                                       :options="labsAll"                                    
                                       />
                                    <span class="error" v-show="errors.has('lab')">
                                    {{ $root.updateErrorMessage('lab',this.errors,'Lab Name')}}
                                    </span>
                                 </div>
                              </div>
                              <br>                                            
                              <button type="button" class="btn btn-default btn-sm" @click="resetFilters" v-if="activeID">
                              <i class="fa fa-undo" :class="{'fa-spin': loadingSheduleDetail}"></i> Reset Filters
                              </button>
                              <div class="row" v-if="loadingSheduleDetail">
                                 <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                       <i class="fa fa-spin fa-refresh"></i> 
                                       Loading
                                    </div>
                                 </div>
                              </div>
                              <datatable
                                 v-if="!loadingSheduleDetail && !localLabLoader"
                                 :columns="columns"
                                 :data="data"
                                 :total="total"
                                 :query="query"
                                 :xprops="xprops"
                                 />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div id="loader_placeholder" v-show="loadingSheduleDetail || myLoaderShown">
            <div id="loadingDiv">
               <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
            </div>
         </div>
      </section>
   </section>
</template>
<script>
   import { mapGetters, mapActions } from 'vuex'
   import components from '../../comps/'
   import StudentAssessmentActions from '../../dtmodules/StudentAssessmentActions'
   import StudentAssessmentTopicActions from '../../dtmodules/StudentAssessmentTopicActions'
   import topicStatus from '../../dtmodules/TopicStatus'
   import { EventBus } from "../../../event-bus.js";
   import StudentAssessment from '../../dtmodules/StudentAssessment'
   import StudentName from '../../dtmodules/StudentName'
   export default {
       components,
       StudentAssessmentActions,
       StudentAssessmentTopicActions,
       StudentAssessment,
       StudentName,
           data() {
                   return {
                        localLabLoader: false,
                        tempActiveID: null,
                        newStaticmySelectedDetails: null,
                        empIdForFirstTime: '',
                        myLoaderShown: false,
                        columns: [
                               { title: 'Name', tdComp: StudentName, field: 'student_name', sortable: true,  thComp: 'FilterTh', tdClass: 'Name'},
                               { title: 'Course', tdComp:StudentAssessment ,field: 'course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course'},
                               { title: 'Topic', tdComp: StudentAssessmentTopicActions, field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Topic'},
                               { title: 'Assessment', tdComp: StudentAssessmentActions, tdClass: 'Inquiry Status', tdClass: 'text-center' }
                           ],
                           query: { sort: 'id', order: 'desc' },
                           xprops: {
                                   module: 'StudentAssessmentSingle',
                                   route: 'student-assessment',
                                   permission_prefix: 'student_assessment_'
                           },
                           activeID:0,
                           mySelectedDate:null,  
                           mySelectedDetails:null,  
                           studentValue:[],         
                   }
           },
           computed: {
                   ...mapGetters('StudentAssessmentSingle', ['item','data', 'total', 'relationships', 'loading','loadingSheduleDetail','batchSheduleAll','batchSheduleDetailAll','labsAll','computedAssessmentData']),
           },    
           created() {
               let query = this.$route.query
               if(!this.isEmpty(query)) {
                   this.fetchLabsAll()
                    .then((res) => {
                            this.myCreatedFunc(query)
                            for (var i = 0; i < this.labsAll.length; i++) {
                                if(this.labsAll[i].id == query.labIdFromTrainerDashboard) {
                                    this.setLab(this.labsAll[i]);
                                } 
                            }
                    })
               } else {
                   if(this.item && this.item.selected_date){
                            this.myLoaderShown = true;
                           var localSelectedLabId = this.item.selected_detail.lab_id;
                           this.fetchLabsAll()
                                .then((res) => {
                                               var tobeSet = [];
                                               for (var i = 0; i < this.labsAll.length; i++) {
                                                       if(this.labsAll[i].id == localSelectedLabId) {
                                                               tobeSet = this.labsAll[i];
                                                       }
                                               }
                                       this.setLab(tobeSet);
                                })  
                           // this.myLoaderShown = true;
                           this.mySelectedDate = this.item.selected_date;
                           this.mySelectedDetails = this.item.selected_detail;
                           this.newStaticmySelectedDetails = this.item.selected_detail;
                            if(this.mySelectedDetails){
                                    this.getBatchShedule(this.mySelectedDate.replace(/\//g, '-'))
                                    .then((res) => {
                                           this.redirectedCreatedFunction(this.mySelectedDetails)
                                           .then(()=>{
                                              this.mySelectedDetails = {};
                                           })
                                    })
                            }            
                   }
                   else{
                       this.fetchLabsAll()
                        .then((res) => {
                               this.setLab(this.labsAll[0]);
                        })
                       this.myCreatedFunc(query)
                   }
               }
           },
           destroyed() {
                   this.resetState()
           },     
           watch: {
                    query: {
                           handler(query) {
                                   this.setQuery(query)
                           },
                           deep: true
                   },
                   "item":function(){    
                   },
           },
           methods: {
                   ...mapActions('StudentAssessmentSingle', ['storeData', 'resetState','setQuery', 'setSchedule_date'/*,'fetchEmployeeAll'*/,'fetchBatchSheduleAll','fetchBatchSheduleDetailAll','setBatchSheduleDetailAll','setStudentAttendance','setBatchSheduleAll','setLoading'/*, 'setEmployee'*/,'setLab','fetchLabsAll','setComputedAssessmentData','setLoadingSheduleDetail']),
                    //FUNCTION TO CHECK IF OBJECT IS ISEMPTY
                    isEmpty(obj) {
                        for(var prop in obj) {
                            if(obj.hasOwnProperty(prop))
                                return false;
                        }
                        return true;
                    },
                   computingAssessmentDetailsData() {
                           this.localLabLoader = true
                       if(this.item && this.item.lab_id && this.item.lab_id.id) {
                           var localBatchScheduleDetailAll = [],
                           localLabValue = this.item.lab_id.id;
                           this.batchSheduleDetailAll.forEach(function(value,index){
                                   if(value.student_lab_id == localLabValue) {
                                       localBatchScheduleDetailAll.push(value)
                                   }
                           }.bind(this))
                           this.setComputedAssessmentData(localBatchScheduleDetailAll);
                           setTimeout(function () { this.localLabLoader = false }.bind(this), 240)
                       }            
                   },
                   async redirectedCreatedFunction(id) {
                           if(id){
                                   this.activeID = id.id;
                                   this.tempActiveID = id.id;
                                   await this.fetchBatchSheduleDetailAll(id)
                                                   .then(response => {
                                                           this.computingAssessmentDetailsData()
                                                           this.myLoaderShown = false;
                                                           this.mySelectedDate = null;
                                                   })                    
   
                           }                    
                   },
                   resetFilters(){
                           var filtersField= [];
                           filtersField = ['student_name','course_name','topic_name','topic_session_no','display_topic_status','status_update_by','status_update_date'];
                           this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
   
                           this.updateBatchSheduleDetail({'id':this.tempActiveID ? this.tempActiveID : this.activeID,/*'emp_id':this.item.employee.id,*/'date':this.item.schedule_date})
                   },    
                   objectLength(obj) {
                           var size = 0, key;
                           for (key in obj) {
                                   if (obj.hasOwnProperty(key)) size++;
                           }
                           return size;            
                   },
                   updateLab(value){
                           this.setLab(value)
                           this.computingAssessmentDetailsData()
                   },
                   myCreatedFunc(query){
                           var today = new Date();
                           var dd = today.getDate();
                           var mm = today.getMonth() + 1; //January is 0!
                           var yyyy = today.getFullYear();
   
                           if (dd < 10) {
                               dd = '0' + dd;
                           }
   
                           if (mm < 10) {
                               mm = '0' + mm;
                           }
   
                           today = dd + '/' + mm + '/' + yyyy;
                           this.setSchedule_date(today);
                            if(!this.isEmpty(query)) {
                                this.getBatchShedule(today.replace(/\//g, '-'))
                                    .then(()=> {
                                        this.updateBatchSheduleDetail({'id':query.batchIdFromTrainerDashboard,'date':today.replace(/\//g, '-')
                                    })
                                })
                            } else {
                                this.getBatchShedule(today.replace(/\//g, '-'))
                            }                                   
                           // }  
                   },
                   updateStudentAttendance(){
                        this.setStudentAttendance(this.studentValue.toString());
                   },
                   updateSchedule_date(e) {
                    if(this.objectLength(this.mySelectedDetails) == 0) {
                           if(e && e.target.value && !this.mySelectedDate){
                           this.setSchedule_date((this.mySelectedDate)?this.mySelectedDate.replace(/\//g, '-'):e.target.value.replace(/\//g, '-'));
                            this.getBatchShedule(e.target.value.replace(/\//g, '-'))
                               .then(() => {
                                   if(this.objectLength(this.mySelectedDetails) == 0) {
                                               var ifAvailabel = [],
                                               tempActiveIDLocal = this.tempActiveID ? this.tempActiveID : this.activeID;
                                               var filteredObj = this.batchSheduleAll.find(function(item, i){
                                                   if(item.batch_id == tempActiveIDLocal){
                                                       ifAvailabel.push(item.batch_id);
                                                   }
                                               });
                                               if(ifAvailabel.length <= 0) {
                                                       this.tempActiveID = null;                              
                                               } else {
                                                       this.tempActiveID = this.activeID;
                                                       if(this.tempActiveID) {
                                                           this.updateBatchSheduleDetail({'id': this.tempActiveID,'date':this.item.schedule_date})
                                                       }
                                               }
                                   }
                                       })
                                       .catch((error) => {
                                               console.error(error)
                                       })                    
                           
                           }   

                    }
                   },
                   async getBatchShedule(date){
                       this.setBatchSheduleAll([]);
                       this.setBatchSheduleDetailAll([]);
                       await this.fetchBatchSheduleAll(date);          
                   },  
                   async updateBatchSheduleDetail(id)
                   {
                           if(id){
                                   this.activeID = id.id;
                                   this.tempActiveID = id.id;
                                   await this.fetchBatchSheduleDetailAll(id)
                                                   .then(response => {
                                                           this.computingAssessmentDetailsData()
                                                   })                        
                                   setTimeout(function () {
                                           this.mySelectedDetails = null;
                                   }.bind(this), 200)
   
                           }
                           
                   },    
           },
   }
</script>
<style scoped>
   .Color_Box_Div {
   height: 15px;
   width: 15px;
   margin-top: 3px;
   margin-right: 4px;
   margin-left: 4px;
   float: left;
   border-radius: 3px;
   }
   .inProgressColor {
   background-color: #3c8dbc;
   }
   .sessionExtendedColor {
   background-color: #f39c12;
   }
   .completedColor {
   background-color: #00a65a;
   }
   #loadingDiv {
   background-color: #000;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 10020;
   filter: alpha(opacity=70);
   -moz-opacity: .7;
   -khtml-opacity: .7;
   opacity: .7;
   }
   .ajax-loader {
   position: fixed;
   left: 50%;
   top: 50%;
   margin-left: -32px;
   margin-top: -32px;
   display: block;
   z-index: 10020!important;
   }
   img {
   vertical-align: middle;
   }
   img {
   border: 0;
   }
   #mySelect .dropdown-toggle .clear {
   display: none;
   }
   #mySelect .v-select .dropdown-toggle {
   border: none;
   }
</style>