<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Trainer Schedule Listing</h1>
        </section>
        <section class="content">
        	<div class="row">
        		<div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>                    
        			<div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Trainer Schedule Listing</h3>
                        </div>        				
                    <div class="box-body">
                        <div class="col-md-6 col-xs-12">
                             <div ref="employee" class="form-group">
                                <label for="employee">Employee Name<p class="astrisk">*</p></label>
                                <v-select
                                    id="employee"
                                    name="employee"
                                    label="employee_name"
                                    v-validate="'required'"
                                    :value="item.employee_id"
                                    @input="updateEmployeeId"
                                    :options="employeeAll"
                                    />
                                <span class="error" v-show="errors.has('employee')">
                                    {{ $root.updateErrorMessage('employee',this.errors,'Employee Name')}}
                                </span>
                            </div>
                        </div>
                                <div class="clearfix"></div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="joining_date" class="form-group">
                                        <label for="joining_date">Schedule From Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.joining_date"
                                                :config="$root.dpconfigDate"
                                                name="joining_date"
                                                v-validate="'required'"
                                                placeholder="Select Schedule From Date"
                                                @dp-change="updateJoining_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                         <span class="error" v-show="errors.has('joining_date')">
                                            {{ $root.updateErrorMessage('joining_date',this.errors,'Schedule From Date')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="exit_date" class="form-group">
                                        <label for="exit_date">Schedule To Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.exit_date"
                                                :config="exitDateOptionFormate"
                                                name="exit_date"
                                                v-validate="'required'"
                                                placeholder="Select Schedule To Date"
                                                @dp-change="updateExit_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('exit_date')">
                                            {{ $root.updateErrorMessage('exit_date',this.errors,'Schedule To Date')}}
                                        </span>                                    
                                    </div>
                                </div> 
                            </div>                                                            
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner
                                class="std_information_save btn btn-block btn-success new_branch_save"
                                >
                            Go
                        </vue-button-spinner>
                    </div>    
                </form>      
        			</div>
        		</div>
        	</div>
            <br>
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-body">
                            <div class="btn-group">

                                <button type="button" class="btn btn-default btn-sm" @click="getScheduleDetails">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Schedules...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableActions from '../../dtmodules/DatatableActions'
import components from '../../comps/'


export default {
    components,
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},            
            items:[],
            dataSeen: false,
            columns: [
                { title: 'Schedule Date', field: 'date', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Date' },
                { title: 'Schedule Day', field: 'day', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Day' },
                { title: 'Course Name', field: 'course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Lab Name', field: 'lab_name', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' },
                { title: 'Batch Schedule', field: 'batch_start_time_end_time', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                // { title: 'Start Time', field: 'start_time', sortable: true, thComp: 'FilterTh', tdClass: 'Start Time' },
                // { title: 'End Time', field: 'end_time', sortable: true, thComp: 'FilterTh', tdClass: 'End Time' },
            ],
            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'TrainerScheduleListingIndex',
                route: 'trainer_schedule_listing',
                permission_prefix: 'trainer_schedule_listing_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchCurrentEmployee();        
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('TrainerScheduleListingIndex', ['item','data', 'total', 'loading', 'relationships','listingAll','employeeAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){
           
        }
    },
    methods: {
        ...mapActions('TrainerScheduleListingIndex', ['fetchData','setQuery','resetState','getScheduleDetails','fetchCurrentEmployee','setJoining_date','setExit_date','setEmployee_id']),

        updateJoining_date(e) {
            if(e && e.target.value != ''){            
                this.exitDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };
                this.setExit_date(null)
            }
            this.setJoining_date(e.target.value)
        },
        updateExit_date(e) {
            this.setExit_date(e.target.value)      
        },
        updateEmployeeId(value) {
            this.setEmployee_id(value);
        },
        submitForm() {

        this.$validator.validate().then(result => {
            if(result){
                this.dataSeen = true
                 this.getScheduleDetails()
                .then(() => {
                    // this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
          });  
        },        
        resetFilters(){
            var filtersField= [];
            filtersField = ['date','day','course_name','batch_start_time_end_time','lab_name'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData() 
        } 
    }
}
</script>


<style scoped>

</style>
 