<template>
    <div>
        <span class="" v-if="value==1">
            Yes
        </span>
        <span class="" v-if="value==2">
            No
        </span>
    </div>
</template>


<script>
export default {
    props: ['value', 'field'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

