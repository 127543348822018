function initialState() {
    return {
        rules: null,
        parentLoggedIn: false,
        branches: [],
    }
}

const getters = {
    rules: state => state.rules,
    parentLoggedIn: state => state.parentLoggedIn,
    branches:state => state.branches, 
}

const actions = {
    async parentLoginFlag({ commit }, rules) {
        /* Used for get parentloggedin flag data on page load every time... */
      //  return new Promise((resolve, reject) => {
            await axios.get('/api/v1/fetchIfParentLoggedIn')
            .then(response => {
                commit('setParentLoggedIn', response.data.data)
                //resolve(response)
            })
            .catch(function (error) {
                  console.log(error.response);
                  // let message = error.response.data.message || error.message
                  // let errors  = error.response.data.errors
                  // dispatch(
                  //     'Alert/setAlert',
                  //     { message: message, errors: errors, color: 'danger' },
                  //     { root: true })
                //  reject(error)
            })     
       // })
    },
    fetchData({ commit }, rules) {
        /* Used for get riles data on page load every time... */
        return new Promise((resolve, reject) => {
             axios.get('/api/v1/rules')
            .then(response => {
                commit('setRules', response.data.data)
                resolve(response)
            })
            .catch(function (error) {
                          console.log(error);
                          /* let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true }) */
                          reject(error)
                    })     
        })
    },
    getBranchesData({ commit }) {
        /* Used for get branch data on page load every time... */
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/getHeaderBranchList')
           .then(response => {
               commit('setBranches', response.data.data)
               resolve(response)
             }).catch(function (error) {
                console.log(error);
                reject(error)
            })
        })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setRules(state, rules) {
        state.rules = rules
    },
    setParentLoggedIn(state, parentLoggedIn) {
        state.parentLoggedIn = parentLoggedIn
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setBranches(state,data){
        state.branches = (data) ? data : null;                                    
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
