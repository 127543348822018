<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>User Branchwise Role</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/multiple-branch-access'">User Branchwise Role</router-link>
                            </li>
                            <li class="breadcrumb-item active">View User Branchwise Role</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View User Branchwise Role</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Branch Name</th>
                                    <td>
                                        <span v-if="item.branch_name !== null">
                                            {{ item.branch_name.branch_name }}    
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Employee Name</th>
                                    <td>
                                        <span v-if="item.user_name !== null">
                                            {{ item.user_name.employee_name }}    
                                        </span>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <th>Username</th>
                                    <td>
                                        <span v-if="item.user_name !== null">
                                            {{ item.user_name.username }}    
                                        </span>
                                    </td>
                                </tr> -->
                                <tr>
                                    <th>Role Name</th>
                                    <td>
                                        <span v-if="item.role_name !== null">
                                            {{ item.role_name.title }}    
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>                     
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('MultipleBranchAccessSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('MultipleBranchAccessSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
 