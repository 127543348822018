function initialState() {
    return {
        item: {
            project_id: '',
            topic:'',
            subtopic_name:'',
            description:'',
            subtopic_video:'',
            subtopic_file:'',
            sort_order:0,
            subis_access_in_mobile:0,
            subhave_quiz:0,
            subis_task_for_tutor:0,
            subtopic_id:0,
            subproject_topics_file:'',
            subproject_topics_video:'',
        },
        projectList:[],
        TopicListing:[],
        loading: false,
        SubTopicListing:[],
        loading1: false
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loading1: state => state.loading1,
    projectList: state => state.projectList,
    TopicListing:state => state.TopicListing,
    SubTopicListing:state => state.SubTopicListing,    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading1', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise(async (resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.project_id)) {
            params.set('project_id', '')
        } else {
            params.set('project_id', state.item.project_id.id)
        } 
        if (_.isEmpty(state.item.topic)) {
            params.set('parent_topic_id', '')
        } else {
            params.set('parent_topic_id', state.item.topic.id)
        }   
        if (_.isEmpty(state.item.subtopic_name)) {
            params.set('topic_name', '')
        } else {
            params.set('topic_name', state.item.subtopic_name)
        } 
        if (_.isEmpty(state.item.description)) {
            params.set('description', '')
        } else {
            params.set('description', state.item.description)
        } 
        var subhave_quiz = (state.item.subhave_quiz) ? 1 : 0;
        var subis_task_for_tutor = (state.item.subis_task_for_tutor) ? 1 : 0;
        var subis_access_in_mobile = (state.item.subis_access_in_mobile) ? 1 : 0;

        params.set('sort_order', state.item.sort_order)
        params.set('topic_file', state.item.subtopic_file)
        params.set('topic_video', state.item.subtopic_video)
        params.set('is_task_for_tutor', subis_task_for_tutor)
        params.set('have_quiz', subhave_quiz)
        params.set('is_access_in_mobile', subis_access_in_mobile)
        await axiosLms.post('/api/v1/projectSubTopics/store', params,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
                //commit('resetState')
                resolve(response)
            })
            .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                commit('setLoading1', false)
            })                    
            dispatch('getListing', state.item.project_id.id)
        })        
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading1', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise(async (resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')
            console.log(state.item);
            var subhave_quiz = (state.item.subhave_quiz) ? 1 : 0;
            var subis_task_for_tutor = (state.item.subis_task_for_tutor) ? 1 : 0;
            var subis_access_in_mobile = (state.item.subis_access_in_mobile) ? 1 : 0;
            params.set('project_id', state.item.project_id.id)
            params.set('parent_topic_id', state.item.topic.id)
            params.set('topic_name', state.item.subtopic_name)
            params.set('description', state.item.description)
            params.set('sort_order', state.item.sort_order)
            params.set('topic_file', state.item.subtopic_file)
            params.set('topic_video', state.item.subtopic_video)
            params.set('is_task_for_tutor', subis_task_for_tutor)
            params.set('have_quiz', subhave_quiz)
            params.set('is_access_in_mobile', subis_access_in_mobile)
            await axiosLms.post('/api/v1/projectSubTopics/' + state.item.subtopic_id, params,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading1', false)
                })
        })
    },
    destroySubData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/projectSubTopics/' + id)
                .then(response => {
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setError', false)
                })
            //dispatch('getListing', state.item.project_id.id)
        })
    },
    async getListing({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/getProjectsData/'+id)
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;    
                    commit('setTopicListing', data)    
                    //commit('setSubTopicListing', data)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })                
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projects/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectId', response.data.data)
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            })                           
    },
    async TopicData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projectTopics/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setTopic', response.data.data)  
                    dispatch('fetchData', response.data.data.project_id)                      
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })  
                   
    }, 
    async SubTopicData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projectSubTopics/' + id)
            .then(response => {
                if(response.data.result){
                    response.data.data = response.data.data[0];
                    console.log(response.data.data);
                    commit('setTopicId', response.data.data.id)
                    commit('setTopicName', response.data.data.topic_name)
                    commit('setDescription', response.data.data.description)
                    commit('setHaveQuiz', response.data.data.have_quiz)
                    commit('setTaskForTutor', response.data.data.is_task_for_tutor)
                    commit('setAccessInMobile', response.data.data.is_access_in_mobile)
                    commit('setProjectTopicsFile', response.data.data.project_topics_file)
                    commit('setProjectTopicsVideo', response.data.data.project_topics_video)
                    dispatch('TopicData', response.data.data.parent_topic_id)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })  
                   
    }, 
    fetchProjectAll({ commit }) {
        axiosLms.get('/api/v1/getAllProjects')
            .then(response => {
                commit('setProjectAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    setTopicId({ commit }, value) {
        commit('setTopicId', value)
    },
    setTopic({ commit }, value) {
        commit('setTopic', value)
    },
    setTopicName({ commit }, value) {
        commit('setTopicName', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setProjectId({ commit }, value) {
        commit('setProjectId', value)
    },
    setTopicFile({ commit }, value) {
        commit('setTopicFile', value)
    },
    setTopicVideo({ commit }, value) {
        commit('setTopicVideo', value)
    },
    setProjectTopicsFile({ commit }, value) {
        commit('setProjectTopicsFile', value)
    },
    setProjectTopicsVideo({ commit }, value) {
        commit('setProjectTopicsVideo', value)
    },
    setHaveQuiz({ commit }, value) {
        commit('setHaveQuiz', value)
    },
    setTaskForTutor({ commit }, value) {
        commit('setTaskForTutor', value)
    },
    setAccessInMobile({ commit }, value) {
        commit('setAccessInMobile', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setError(state, error) {
        state.error = error
    },  
    setProjectAll(state,project_detail){
        state.projectList = project_detail;
        //state.item = project_detail;
    },
    setTopicId(state, value) {
        state.item.subtopic_id = value;
    },
    setTopicFile(state, value) {
        state.item.subtopic_file = value;
    },
    setTopicName(state, value) {
        state.item.subtopic_name = value;
    },
    setDescription(state, value) {
        state.item.description = value;
    },
    setTopicVideo(state, value) {
        state.item.subtopic_video = value;
    },
    setTopic(state, value) {
        state.item.topic = value;
    },
    setProjectId(state, value) {
        state.item.project_id = value;
    },
    setProjectName(state,value){
        state.item.roject_name = value;
    },
    setHaveQuiz(state,value){
        state.item.subhave_quiz = value;
    },
    setTaskForTutor(state,value){
        state.item.subis_task_for_tutor = value;
    },
    setAccessInMobile(state,value){
        state.item.subis_access_in_mobile = value;
    },
    setTopicListing(state,value){
        state.TopicListing = value.project_topics
    },
    setProjectTopicsFile(state, value) {
        state.item.subproject_topics_file = value;
    },
    setProjectTopicsVideo(state, value) {
        state.item.subproject_topics_video = value;
    },
    /* setSubTopicListing(state,value){
        state.SubTopicListing = value.project_topics
    }, */
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoading1(state, loading) {
        state.loading1 = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
