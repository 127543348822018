<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Student Prospect</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Student Prospect List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Student Prospect List</h3>
                </div>
                <div class="card-body">
                   <div class="btn-group mb-3">
                        <!-- <router-link
                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                                >
                            <i class="fa fa-plus"></i> Add new
                        </router-link> -->

                        <!-- <router-link
                                v-if="$can(xprops.permission_prefix + 'create')"
                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                                >
                            <i class="fa fa-plus"></i> Add new
                        </router-link>-->

                        <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                            <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                        </button>     
                        <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                            <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                        </button>                      
                    </div>
                    <div class="form-group radio-btn">
                                    <label for="gender">Prospect Option:</label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="0"
                                                    :checked="item.gender === '0'"
                                                    @change="updateGender('0')"                                                    
                                                    >
                                            <span class="checkmark"></span> Prospect Available
                                        </label>
                                    </div>
                                    <!-- <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="1"
                                                    :checked="item.gender === '1'"
                                                    @change="updateGender('1')"                                                    
                                                    >
                                            <span class="checkmark"></span> Pending
                                        </label>
                                    </div> -->
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="2"
                                                    :checked="item.gender === '2'"
                                                    @change="updateGender('2')"
                                                    >
                                            <span class="checkmark"></span> Followup
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    :value="3"
                                                    :checked="item.gender === '3'"
                                                    @change="updateGender('3')"                                                    
                                                    >
                                            <span class="checkmark"></span> Closed
                                        </label>
                                    </div>
                                </div>  
                      <!-- <div class="row" v-if="loading">
                        <div class="col-xs-4 col-xs-offset-4">
                            <div class="alert text-center">
                                <i class="fa fa-spin fa-refresh"></i> Loading
                            </div>
                        </div>
                    </div> -->
                    <bootstrap-alert />

                        <!-- <div class="row" v-if="loading">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading
                                </div>
                            </div>
                        </div> -->
                            <div class="table-responsive setting-group">
                                <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                            }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                            >
                                <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'prospect_status'">
                                        <StudentProspectActions :key="props.row.id" :xprops="xprops" :row="props.row" :value="props.row.prospect_status" :field="props.column.field"></StudentProspectActions>
                                    </span>
                                    <span v-else-if="props.column.field == 'student_concat_name'">
                                        <StudentName :row="props.row"></StudentName>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                        </template>
                        <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                        </vue-good-table>
                        </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import StudentProspectActions from '../../dtmodules/StudentProspectActions'
import components from '../../comps/'
import StudentName from '../../dtmodules/StudentName'
import CustomLoader from '@/components/CustomLoader'
import store from '../../../store'
export default {
    components,
    components: {
        StudentProspectActions,
        StudentName,
        CustomLoader
    },
    props: ['prospectTypeProps'],     
    data() {
        return {
            branch_id:(store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0,
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Student Name",
                    field: "student_concat_name",
                    filterOptions: {
                        styleClass: 'student_prospect_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Student Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Last Completed Course",
                    field: "prev_course_name",
                    filterOptions: {
                        styleClass: 'student_prospect_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Last Completed Course', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Contact Name",
                    field: "contact_name",
                    filterOptions: {
                        styleClass: 'student_prospect_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Contact Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                 {
                    label: "Contact Number",
                    field: "contact_no",
                    filterOptions: {
                        styleClass: 'student_prospect_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Contact Number', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                // {
                //     label: "Prospect Status",
                //     field: "prospect_status_value",
                //     filterOptions: {
                //         styleClass: 'student_prospect_filter', // class to be added to the parent th element
                //         enabled: true, // enable filter for this column
                //         placeholder: 'Prospect Status', // placeholder for filter input
                //         filterValue: '', // initial populated value for this filter
                //         filterDropdownItems: [], // dropdown (with selected values) instead of text input
                //         // trigger: 'enter', //only trigger on enter not on keyup 
                //     },
                // },
                {
                label: "Prospect Status",
                field: "prospect_status",
                sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'StudentProspectIndex',
                route: 'student_prospect',
                permission_prefix: 'student_prospect_'
            },
            prospect_status_filter: 0,
        }
    },
    created() {
        if(this.branch_id == 1){
            this.$router.push({name:'admin_dashboard.show'});
        }
        this.$root.relationships = this.relationships
        if(this.prospectTypeProps) {
            this.setGender(this.prospectTypeProps)
            //this.fetchData(this.prospectTypeProps)
            this.loadItems(0,this.prospectTypeProps)
        } else {
            //this.fetchData('0')
            this.loadItems(0,0)
        }
    },
    // destroyed() {
    //     this.resetState()
    // },
    computed: {
        ...mapGetters('StudentProspectIndex', ['data', 'total', 'item','loading', 'relationships','all']),
    },
    watch:{
        branch_id: function() { 
        return (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0;
        },
    },
    // watch: {
    //     query: {
    //         handler(query) {
    //             this.setQuery(query)
    //         },
    //         deep: true
    //     },
    //     data: function(val){
           
    //     }
    // },
    mounted() {
        this.$emit('executeSQL', 'Pending')        
    },
    methods: {
        ...mapActions('StudentProspectIndex', ['fetchData', 'setQuery', 'resetState', 'setGender']),   
         updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems(0,this.prospect_status_filter);
        },

        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems(0,this.prospect_status_filter);
        },

        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems(0,this.prospect_status_filter);
        },

        onColumnFilter(params) {
          //  console.log("fileter",this.prospect_status_filter);
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems(0,this.prospect_status_filter);
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems(0,this.prospect_status_filter);
        },

        // load items is what brings back the rows from server
        loadItems(flag = 0,status=0) {
        //console.log("status",flag, status);

        status = this.item.gender
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axios
            .get("/api/v1/student-prospect/"+status, {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        },
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            //this.$refs['table1'].reset();
            this.$refs.table1.reset();
            this.loadItems(1,this.prospect_status_filter);
        },      
        updateGender(value) {
            console.log(value,this.prospect_status_filter);
            this.prospect_status_filter = value
            //this.fetchData(this.prospect_status_filter);
            this.setGender(value)
            this.loadItems(0,this.prospect_status_filter);
            
        },         
    }
}
</script>


<style scoped>

</style>
 