import { render, staticRenderFns } from "./BranchLabActions.vue?vue&type=template&id=5fae9cd4&scoped=true&"
import script from "./BranchLabActions.vue?vue&type=script&lang=js&"
export * from "./BranchLabActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fae9cd4",
  null
  
)

export default component.exports