<template> 
    <div class="project-card">        
        <div class="">
            <div class="col-md-12">
                <div class="card-header clearfix">
                    <b-btn v-b-modal.modalPrevent2 class="btn btn-success btn-sm float-right">+ Add Topic</b-btn>    
                    <AddTopics :project_id="project_id"></AddTopics> 
                </div>
                <CustomLoader v-if="loading"></CustomLoader>
                <nested-draggable :project_id="project_id" v-model="TopicListing" />
            </div>
            <!-- /.card-body -->            
        </div>            
    </div>                 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import draggable from 'vuedraggable'
import nestedDraggable from "./TopicsDrag";
import AddTopics from '../dtmodules/PopupActions/AddTopics'
import CustomLoader from '@/components/CustomLoader'
export default {
    components: {
        // draggable,
        nestedDraggable,
        AddTopics,
        CustomLoader
    },
    props:[ 'project_id' ],
    computed: {
        ...mapGetters('ProjectTopicsSingle', ['loading','TopicListing']),
        TopicListing: {
            get() {
                return this.$store.state.ProjectTopicsSingle.TopicListing;
            },
            set(value) {
                value.project_id = this.project_id
                this.$store.dispatch("ProjectTopicsSingle/updateElements", value);
                this.getListing(this.project_id);
            }
        }
    },
    created() {
        this.getListing(this.project_id);
    },
    methods: {
        ...mapActions('ProjectTopicsSingle', ['getListing', 'updateElements']), 
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>
