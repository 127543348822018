<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Inquiries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/inquiries'">Inquiries</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Inquiry</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Inquiry</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <!-- <div class="box-header with-border">
                                    <h3 class="box-title">Add Inquiry</h3>
                                </div>

                                <div class="box-body">
                                    <back-buttton></back-buttton>
                                </div> -->

                                <div class="col-md-12">
                                    <bootstrap-alert />
                                </div>

                                <!-- <div class="box-body row"> -->
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="inquiry_type">Inquiry Type<p class="astrisk">*</p></label>
                                            <v-select
                                                    name="inquiry_type"
                                                    label="type_name"
                                                    v-validate="'required'"
                                                    @input="updateInquiryType"
                                                    :value="item.inquiry_type"
                                                    :options="inqTypesAll"
                                                    />
                                            <span class="error" v-show="errors.has('inquiry_type')">
                                            {{ $root.updateErrorMessage('inquiry_type',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_date" class="form-group"> -->
                                            <label for="inquiry_date">Inquiry Date<p class="astrisk">*</p></label>
                                            <date-picker
                                                :value="item.inquiry_date"
                                                :config="$root.dpconfigOnlyDateCurrent"
                                                name="inquiry_date"
                                                v-validate="'required'"
                                                @input="updateInquiryDate"
                                                placeholder="Select Inquiry Date"
                                                autocomplete="off"
                                                >
                                            </date-picker>                                      
                                            <span class="error" v-show="errors.has('inquiry_date')">
                                                    {{ $root.updateErrorMessage('inquiry_date',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="contact_name">Contact Name<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="contact_name"
                                                ref="contact_name"
                                                maxlength="90"
                                                v-validate="'required'"
                                                :placeholder="'Enter Contact Name'"
                                                :value="item.contact_name"
                                                @input="updateContactName"
                                                >
                                        <span class="error" v-show="errors.has('contact_name')">
                                        {{ $root.updateErrorMessage('contact_name',this.errors,'Contact Name')}}
                                        </span>                        
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="contact_no">Contact Number<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                v-validate="'required'"
                                                maxlength="10"
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                name="contact_no"
                                                ref="contact_no"
                                                :placeholder="'Enter Contact Number'"
                                                :value="item.contact_number"
                                                @input="updateContactNo"
                                                >
                                        <span class="error" v-show="errors.has('contact_no')">
                                        {{ $root.updateErrorMessage('contact_no',this.errors,'Contact Number')}}
                                        </span>                  
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="contact_email">Contact Email</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                v-validate="'email'"
                                                name="contact_email"
                                                ref="contact_email"
                                                :placeholder="'Enter Contact Email'"
                                                :value="item.contact_email"
                                                @input="updateContactEmail"
                                                >
                                        <span class="error" v-show="errors.has('contact_email')">
                                        {{ $root.updateErrorMessage('contact_email',this.errors,'Contact Email')}}
                                        </span>                  
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="student_name">Student Name<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="student_name"
                                                ref="student_name"
                                                maxlength="90"
                                                v-validate="'required'"
                                                :placeholder="'Enter Student Name'"
                                                :value="item.student_name"
                                                @input="updateStudentName"
                                                >
                                        <span class="error" v-show="errors.has('student_name')">
                                        {{ $root.updateErrorMessage('student_name',this.errors,'Student Name')}}
                                        </span>                        
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div class="form-group"> -->
                                            <label for="age">Age(Years)<p class="astrisk">*</p></label>
                                            <!-- <input
                                                    type="text"
                                                    maxlength="3"
                                                    v-validate="'numeric'"
                                                    class="form-control"
                                                    name="age"
                                                    ref="age"
                                                    placeholder="Enter Age"
                                                    :value="item.age"
                                                    @input="updateAge"
                                                    > -->
                                            <v-select
                                                    name="age"
                                                    label="age"
                                                    v-validate="'required'"
                                                    @input="updateAge"
                                                    :value="item.age"
                                                    :options="ageAll"
                                                    />
                                            <span class="error" v-show="errors.has('age')">
                                                {{ $root.updateErrorMessage('age',this.errors,'Age')}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="source">Source<p class="astrisk">*</p></label>
                                            <v-select
                                                    name="source"
                                                    label="name"
                                                    v-validate="'required'"
                                                    @input="updateSource"
                                                    :value="item.source"
                                                    :options="referenceAll"
                                                    />
                                            <span class="error" v-show="errors.has('source')">
                                            {{ $root.updateErrorMessage('source',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div ref="school" class="form-group col-md-6 col-xs-12">
                                        <label for="school">School Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="school"
                                                label="school_name"
                                                v-validate="'required'"
                                                @input="updateSchool"
                                                @change="checkSchool"
                                                :value="item.school"
                                                class=""
                                                :options="schoolAll"
                                                />
                                        <span class="error" v-show="errors.has('school')">
                                        {{ $root.updateErrorMessage('school',this.errors,'School Name')}}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="utm_medium">Medium</label>
                                            <v-select
                                                    name="utm_medium"
                                                    label="name"
                                                    v-validate=""
                                                    @input="updateMedium"
                                                    :value="item.medium"
                                                    :options="mediumAll"
                                                    />
                                            <span class="error" v-show="errors.has('utm_medium')">
                                            {{ $root.updateErrorMessage('utm_medium',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12" v-if="showOtherSchool">
                                        <!-- <div class="form-group"> -->
                                            <label for="other_school_name">Other School name<p class="astrisk">*</p></label>
                                            <input
                                                    type="text"
                                                    v-validate="'required'"
                                                    class="form-control"
                                                    name="other_school_name"
                                                    ref="other_school_name"
                                                    placeholder="Enter Other School name"
                                                    :value="item.other_school_name"
                                                    @input="updateSchoolName"
                                                    >
                                            <span v-if="item.school && item.school.id == 0" class="error" v-show="errors.has('other_school_name')">
                                                {{ $root.updateErrorMessage('other_school_name',this.errors,'Other School name')}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="utm_campaign">Campaign</label>
                                            <v-select
                                                    name="utm_campaign"
                                                    label="name"
                                                    v-validate=""
                                                    @input="updateCampaign"
                                                    :value="item.campaign"
                                                    :options="campaignAll"
                                                    />

                                            <span class="error" v-show="errors.has('utm_campaign')">
                                            {{ $root.updateErrorMessage('utm_campaign',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="course_offered">Course offered<p class="astrisk">*</p></label>
                                            <v-select
                                                    name="course_offered"
                                                    label="name"
                                                    v-validate="'required'"
                                                    @input="updateCourse"
                                                    @change="checkCourse"
                                                    :value="item.course_details"
                                                    :options="courseAllData"
                                                    />

                                            <span class="error" v-show="errors.has('course_offered')">
                                            {{ $root.updateErrorMessage('course_offered',this.errors)}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <!-- <div ref="inquiry_type" class="form-group"> -->
                                            <label for="inquiry_type">Grade<p class="astrisk">*</p></label>

                                            <v-select
                                            name="standard_id"
                                            label="name"
                                            v-validate="'required'"
                                            @input="updateStandard_id"
                                            :value="item.standard_id"
                                            :options="standardAll"
                                            />

                                            <span class="error" v-show="errors.has('standard_id')">
                                            {{ $root.updateErrorMessage('standard_id',this.errors,'Grade')}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12" v-if="showOtherCourse">
                                        <!-- <div class="form-group"> -->
                                            <label for="other_course_name">Other Course name<p class="astrisk">*</p></label>
                                            <input
                                                    type="text"
                                                    v-validate="'required'"
                                                    class="form-control"
                                                    name="other_course_name"
                                                    placeholder="Enter Other Course name"
                                                    :value="item.other_course_name"
                                                    @input="updateCourseName"
                                                    >
                                            <span v-if="item.course_details && item.course_details.id == 0" class="error" v-show="errors.has('other_course_name')">
                                                {{ $root.updateErrorMessage('other_course_name',this.errors,'Other Course name')}}
                                            </span>
                                        <!-- </div> -->
                                    </div>
                                    <div ref="country_list" class="form-group col-md-6 col-xs-12">
                                        <label for="country_list">Country<p class="astrisk">*</p></label>
                                        <v-select
                                                name="country_list"
                                                label="country_name"
                                                v-validate="'required'"
                                                placeholder="Select Country"
                                                @input="updateCountry_list"
                                                @change="updateCountry_list"
                                                :value="item.country_list"
                                                :options="countrymastersAll"
                                                />
                                        <span class="error" v-show="errors.has('country_list')">
                                        {{ $root.updateErrorMessage('country_list',this.errors,'Country')}}
                                        </span>
                                    </div>
                                    <div ref="state_list" class="form-group col-md-6 col-xs-12">
                                        <label for="state_list">State<p class="astrisk">*</p></label>
                                        <v-select
                                                name="state_list"
                                                label="state_name"
                                                v-validate="'required'"
                                                @input="updateState_list"
                                                :value="item.state_list"
                                                placeholder="Select State"
                                                class="parentName"
                                                :options="statemastersAll"
                                                />
                                        <AddStatePopupEmployee class="add_state_btn" v-if="$can('state_create') && statePopupButton"></AddStatePopupEmployee>

                                        <span class="error" v-show="errors.has('state_list')">
                                        {{ $root.updateErrorMessage('state_list',this.errors,'State')}}
                                        </span>
                                    </div>
                                    <div ref="city_list" class="form-group col-md-6 col-xs-12">
                                        <label for="city_list">City<p class="astrisk">*</p></label>
                                        <v-select
                                                name="city_list"
                                                label="city_name"
                                                v-validate="'required'"
                                                @input="updateCity_list"
                                                :value="item.city_list"
                                                placeholder="Select City"
                                                :options="citymastersAll"
                                                />
                                        <span class="error" v-show="errors.has('city_list')">
                                        {{ $root.updateErrorMessage('city_list',this.errors,'City')}}
                                        </span>
                                    </div>
                                    
                                        <!-- <input
                                                type="text"
                                                class="form-control"
                                                v-validate="'required'"
                                                name="city"
                                                ref="city"
                                                maxlength="90"
                                                placeholder="Enter City"
                                                :value="item.city"
                                                @input="updateCity"
                                                > -->
                                        
                                    <div class="col-md-12 col-xs-12 savebtn"> 
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                            Save
                                        </vue-button-spinner>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </form>
                    </div>
                </div>    
            </div>
        </section>
    </div>    
</template>        

<script>
import { mapGetters, mapActions } from 'vuex'
import AddStatePopupEmployee from '../../dtmodules/PopupActions/AddStatePopupEmployee'
// import AddStatePopupInquiry from '../../dtmodules/PopupActions/AddStatePopupInquiry'
// import AddSchoolPopupInquiry from '../../dtmodules/PopupActions/AddSchoolPopupInquiry'

export default {
    components: { AddStatePopupEmployee },
    data() {
        return {
            onlySummerCamp: true,
            statePopupButton: true,
            hasError : false,
            courseForm : false,
            courseList : true,
            arrIndex : false,
            inputParams : [],
            subTotal : [],
            fee_list : [],
            inqCourse :'',
            inqBatchTime  : '',
            courseEdit : false,
            courseEditIndex : '',
            preferDaysChecked : [],
            checkedEdit : true,
            diabledEdit : false, 
            finalGstAmount: [],
            showOtherCourse:false,
            showOtherSchool:false,
            dobDateOptionFormate:{format:window.date_format_moment, maxDate: this.getDOBDate()}                 
            // Code...
        }
    },

    computed: {
        ...mapGetters('InquiriesSingle', ['item', 'feeAdditionalAdjustment','loading', 'countrymastersAll', 'statemastersAll','citymastersAll', 'referenceAll', 'schoolAll', 'standardAll', 'statusAll', 'reasonAll','courseListAll','courseAllData','batchAll','feeCourseList','statemastersAll','courseComboDetailsAll','inqTypesAll','branchAdmissionFeesApplicable','mediumAll','campaignAll','ageAll']),
    },
    created() {
        this.fetchCountrymastersAll(),
        // this.fetchStatemastersAll(),
        // this.fetchCitymastersAll(),
        this.fetchReferenceAll()
        this.fetchSchoolAll()
        this.fetchUTMMedium()
        this.fetchUTMCampaign()
        this.fetchCourseAll()
        this.fetchStandardAll()
        this.updateInquiryDate(moment(new Date()).format("DD/MM/YYYY"));
    },
    mounted() {
        this.item.inquiry_type = {'type_name': 'Cold', 'value': 2};
        this.item.age= {'age': '6', 'value': 6}; 
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {

        ...mapActions('InquiriesSingle', ['fetchFeeAdditionalAdjustmentCharge','storeData', 'resetState', 'setBranch_id', 'setInquiryType','setInquiryDate','setContactName', 'setMiddle_name', 'setLast_name', 'setStudent_address', 'setArea', 'setCountry_list', 'setState_list', 'setCity_list', 'setGender', 'setPin_code', 'setBirth_date', 'setAge','setCourseName','setSource','setMedium', 'setSchool', 'setStandard_id', 'setFather_name', 'setMother_name', 'setMobile_no', 'setContactNo' ,'setStudentName', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchCountrymastersAll','fetchStatemastersAll','fetchCitymastersAll','fetchComboFromCourseDetails','fetchReferenceAll','fetchUTMMedium','fetchUTMCampaign','fetchSchoolAll','fetchStandardAll','fetchCourseAll','setCourseListAll','fetchBatchAll','fetchFeeCourseList','setFeeCourseList','setBatch','fetchCountryStates','fetchStatesCities','setCourseAll','setBatchAll','setStatemastersAll','setCitymastersAll','setInquiryRemark','setOnlySummerCampGlobal','fetchIfBranchAdmissionFeesApplicable','setUTMMedium','setUTMCampaign','setCourseOffered','setSchoolName','setContactEmail']),

        updateInquiryType(e) {
            this.setInquiryType(e)
            this.backCourse();
        },
        backCourse(){
            setTimeout(function () { this.fetchCourseAll(); }.bind(this), 240)
        },
        updateInquiryDate(e) {
            this.setInquiryDate(e);
        },
        updateContactName(e) {
            this.setContactName(e.target.value)
        },
        updateContactNo(e) {
            this.setContactNo(e.target.value)
        },
        updateContactEmail(e) {
            this.setContactEmail(e.target.value)
        },
        updateStudentName(e) {
            this.setStudentName(e.target.value)
        },
        updateAge(e) {
            this.setAge(e)
        },
        updateCourseName(e){
            this.setCourseName(e.target.value)
        },
        updateSource(value) {
            this.setSource(value)
        },
        updateSchool(value) {
            this.setSchool(value)
        },
        updateSchoolName(e) {
            this.setSchoolName(e.target.value)
        },
        updateMedium(value){
            this.setUTMMedium(value)
        },
        updateCampaign(value){
            this.setUTMCampaign(value)
        },
        updateCourse(value){
            this.setCourseOffered(value)
        },
        checkCourse(value){
            if(value.id == 0) {
                this.showOtherCourse = true;
            }else{
                this.showOtherCourse = false;
            }
        },
        checkSchool(value){
            if(value.id == 0) {
                this.showOtherSchool = true;
            }else{
                this.showOtherSchool = false;
            }
        },
        updateStandard_id(value) {
            this.setStandard_id(value)
        },
       
        updateCountry_list(value) {
            this.setState_list(null);
            this.setCity_list(null);
          if(value !== null && value !==''){
                this.fetchCountryStates(value.id);
                this.statePopupButton = true;
            }
            else {
                this.setCitymastersAll([]);
                this.setStatemastersAll([]);
                this.statePopupButton = false;
            }
            this.setCitymastersAll([]);
            this.setCountry_list(value)
        },
        updateState_list(value) {
            this.setCity_list(null);
  if(value !== null && value !==''){
        this.fetchStatesCities(value.id);
        // this.statePopupButton = true;             
    }
  else{
    this.setCitymastersAll([]);
    // this.statePopupButton = false;
  }
    this.setState_list(value)
        },
        updateCity_list(value) {
            this.setCity_list(value)
        },
        // updateCity(e) {
        //     this.setCity(e.target.value)
        // },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.storeData()
                    .then((res) => {
                        if(res.data.result == false){
                           this.$eventHub.$emit('inquiry-exists') 
                        } else {
                            this.$router.push({ name: 'inquiries.index' })
                            this.$eventHub.$emit('create-success')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        },
        getDOBDate(){
            var d = new Date();
            var pastYear = d.getFullYear() - 5;
            d.setFullYear(pastYear);
            return d;
        },
    }
}
</script>