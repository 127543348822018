<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Headers</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/skill-master'">Headers</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Header</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Header</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>Header Name</th>
                                            <td>{{ headerDetail.header_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Skill Name</th>
                                            <td>
                                                <span v-if="item.skill_name">
                                                    {{ item.skill_name }}
                                                </span>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    props : [ 'header_id', 'Id' ],
    components:{
        CustomLoader
    },
    data() {
        return {
            form_data: {
                id: '',
                header_name: ''
            }
        }
    },
    created() {
        this.getList(this.$route.params.id)
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('SkillHeaderMasterSingle', ['item', 'headerDetail', 'loading'])
    },
    watch: {
        "headerDetail":function(value) {
            this.form_data.id = value.id
            this.form_data.header_name = value.header_name
        },
        "$route.params.id": function() {
            this.getList(this.$route.params.id)
            this.setRemoveItem('')
        }
    },
    methods: {
       ...mapActions('SkillHeaderMasterSingle', ['getList', 'setRemoveItem', 'resetState', 'fetchData']),
    }
}
</script>

<style scoped>
.card {
    min-height: 185px !important;
}
</style>
