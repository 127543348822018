function initialState() {
    return {
        all: [],
        loading: false,
    }
} 

const getters = {
    all: state => state.all,
    loading: state => state.loading,
}

const actions = {
    async fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/sendingSmsForFeePendingReminderData')
                .then(response => {
                    commit('setAll',  response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    sendFeeReminderPendingSmsStore({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/sendingSmsForFeePendingReminder', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                              console.log('Error', error);
                              reject(error)
                        }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setAll({ commit }, value) {
        commit('setAll', value)
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, all) {
        state.all = all
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
