<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>SMS Setting</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">SMS Setting</h3>
                            </div>
                            <div class="box-body">
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="toggle">Enabled<p class="astrisk">*</p></label>
                                    <br>
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="toggle"
                                                    ref="toggle"
                                                    :value="2"
                                                    :checked="item.enabled === 1"
                                                    @change="updateToggle(1)"
                                                    >
                                            <span class="checkmark"></span> Yes
                                        </label>
                                    </div>                                    
                                    <div class="radio-inline">
                                        <label class="contain">
                                            <input
                                                    type="radio"
                                                    name="toggle"
                                                    ref="toggle"
                                                    v-validate="'required|included:1,2'"
                                                    :value="1"
                                                    :checked="item.enabled === 0"
                                                    @change="updateToggle(0)"
                                                    >
                                            <span class="checkmark"></span> No
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('toggle')">
                                      {{ $root.updateErrorMessage('toggle',this.errors,'Enabled')}}
                                    </span>
                                </div>  
                                <div class="clearfix"></div>                            	
                                <div class="col-md-6 col-xs-12">
                                    <div ref="sending_method" class="form-group">
                                        <label for="sending_method">Sending Method<p class="astrisk">*</p></label>
                                        <v-select
                                                name="sending_method"
                                                label="sending_method_name"
                                                v-validate="'required'"
                                                @input="updateSendingMethod"
                                                :value="item.sending_method"
                                                :options="sendingMethodAll"
                                                />
                                        <span class="error" v-show="errors.has('sending_method')">
                                            {{ $root.updateErrorMessage('sending_method',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
	                                <div ref="sms_url" class="form-group">
	                                    <label for="sms_url">SMS URL<p class="astrisk">*</p></label>
	                                    <input
	                                            type="text"
	                                            class="form-control"
	                                            name="sms_url"
	                                            ref="sms_url"
	                                            maxlength="90"
	                                            v-validate="'required'"
	                                            placeholder="Enter SMS URL"
	                                            :value="item.sms_url"
	                                            @input="updateSMSURL"
	                                            >
	                                    <span class="error" v-show="errors.has('sms_url')">
	                                      {{ $root.updateErrorMessage('sms_url',this.errors,'SMS URL')}}
	                                    </span>
	                                </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-12 col-xs-12">
	                                <div ref="sms_url_params" class="form-group">
	                                    <label for="sms_url_params">SMS Parameters<p class="astrisk">*</p></label>
		                                <textarea
			                                    rows="3"
			                                    class="form-control"
			                                    name="sms_url_params"
			                                    v-validate="'required|max:1000'"
			                                    :value="item.sms_url_params"
			                                    @input="updateSMSURLParams"
			                                    placeholder="Enter SMS Parameters"		                                        
		                                        >
		                                </textarea>
		                                <span class="error" v-show="errors.has('sms_url_params')">
		                                  {{ $root.updateErrorMessage('sms_url_params',this.errors,'SMS URL Parameters')}}
		                                </span><br>  
	                                </div>
                                </div>                                                                      
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner class="std_information_save btn btn-block btn-success new_branch_save">
                            Save
                        </vue-button-spinner>
                    </div>  
                </form>
                <div id="loader_placeholder" v-if="loading">
                    <div id="loadingDiv">
                        <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                    </div>
                </div>                     
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {  
    data() {
        return {
        	sendingMethodAll: [{'sending_method_name': 'GET', 'id': '0'},{'sending_method_name': 'POST', 'id': '1'}]
        	}
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchSmsData().then((res)=>{
                this.setToggle(this.fetchedSmsDataAll.enabled)
                this.setSendingMethod(this.sendingMethodAll[this.fetchedSmsDataAll.sending_method])
                this.setSMSURL(this.fetchedSmsDataAll.sms_url)
                this.setSMSURLParams(this.fetchedSmsDataAll.sms_url_params)
        })
    },   
    computed: {
        ...mapGetters('SmsSettingIndex', ['item','loading','smsSettingAll','fetchedSmsDataAll']),
    },
    methods: {
        ...mapActions('SmsSettingIndex', ['saveSMSSetting','setSendingMethod','setSMSURL','setSMSURLParams','setToggle','fetchSmsData']),

        updateSendingMethod(value) {
            this.setSendingMethod(value)
        },
        updateSMSURL(e) {
            this.setSMSURL(e.target.value)
        },
        updateSMSURLParams(e) {
            this.setSMSURLParams(e.target.value)
        },
        updateToggle(value) {
            this.setToggle(value)
        },                
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.saveSMSSetting()
                    .then(() => {
                        this.$eventHub.$emit('create-success')                        
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
</style>
 
