function initialState() {
    return {
        all: [],
        editDataAll: [],
        indexAll: [],
        query: {},
        studentAll: [],
        staffAll: [],
        showData: [],
        loading: false
    }
} 

const getters = {
    data: state => {
        let rows = state.indexAll
        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['group_name','total_member'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.indexAll

        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['group_name','total_member'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    showData: state => state.showData,
    editDataAll: state => state.editDataAll,
    indexAll: state => state.indexAll,
    query: state => state.query,
    all: state => state.all,
    studentAll: state => state.studentAll,
    staffAll: state => state.staffAll,
    loading: state => state.loading,
}

const actions = {
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/fetchShowData/' + id)
                .then(response => {
                    commit('setShowDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async editGroupStore({ commit, state, dispatch }, params) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/editGroupStore/' + params.id, params)
                .then(response => {
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchEditData({ commit, state, dispatch }, id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchEditDataForGroups/' + id)
                .then(response => {
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchIndexData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchIndexDataForGroups')
                .then(response => {
                    commit('setIndexDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },     
    async fetchStudentAll({ commit, state, dispatch }) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllStudents')
                .then(response => {
                    commit('setStudentAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchStaffAll({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllEmployee')
                .then(response => {
                    commit('setStaffAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },        
    sendSmsOnDynamicTemplateStore({ commit, state, dispatch }, params) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/creatingGroup', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
        axios.delete('/api/v1/delete-group/' + id)
                .then(response => {

                    var rows = state.indexAll
                    rows = rows.filter((item) => {
                        return item.id != id
                    })
                    commit('setIndexDataAll', rows);
                    
                    var queryLocal = purify(state.query);
                    if(rows.length == queryLocal.offset && rows.length != 0)
                    {
                        queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                        commit('setQuery', queryLocal)
                    }
                    resolve();
                    })
                    .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })  
                    .finally(() => {
                        // commit('setError', false)
                    })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setAll({ commit }, value) {
        commit('setAll', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }    
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, all) {
        state.all = all
    },
    setShowDataAll(state, all) {
        state.showData = all
    },
    setQuery(state, query) {
        state.query = query
    },    
    setStudentAll(state, studentAll) {
        state.studentAll = studentAll
    },
    setIndexDataAll(state, indexAll) {
        state.indexAll = indexAll
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    },    
    setStaffAll(state, staffAll) {
        state.staffAll = staffAll
    },        
    resetState(state) {
        state = Object.assign(state, initialState())
    }    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
