function initialState() {
    return {
        item: {
            all: [],
            id: null,
            branch_id: null,
            inquiry_date: null,
            inquiry_type: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            student_address: null,
            area: null,
            country_list: {'id':99,'country_name':'India'},
            // country_list: null,
            state_list: null,
            // city: null,
            city_list:null,
            gender: null,
            pin_code: null,
            birth_date: null,
            age: null,
            inquiry_remark: null,
            source: null,
            school: null,
            standard_id: null,
            father_name: null,
            mother_name: null,
            mobile_no: null,
            contact_no: null,
            status: null,
            next_followup_date: null,
            reason: null,
            status_remarks: null,
            course : null,
            batch : null,
            contact_name : null,
            student_name:null,
            contact_email:null,
            other_course_name:null,
            other_school_name:null,
            relationships: {},            
            branches:[],
        },
        onlySummerGlobal: true,
        inqTypesAll: [
            // {'type_name': 'Individual', 'value': 1},
            // {'type_name': 'Other', 'value': 2}
            {'type_name': 'Hot', 'value': 1},
            {'type_name': 'Cold', 'value': 2}
        ],
        ageAll: [
            {'age': '6', 'value': 6},
            {'age': '7', 'value': 7},
            {'age': '8', 'value': 8},
            {'age': '9', 'value': 9},
            {'age': '10', 'value': 10},
            {'age': '11', 'value': 11},
            {'age': '12', 'value': 12},
            {'age': '13', 'value': 13},
            {'age': '14', 'value': 14},
            {'age': '15', 'value': 15},
            {'age': '16', 'value': 16},
            {'age': '17', 'value': 17},
            {'age': '18', 'value': 18},
        ],
        feeAdditionalAdjustment: [],
        branchAdmissionFeesApplicable: [],
        countrymastersAll: [],
        statemastersAll: [],
        citymastersAll: [],
        courseComboDetailsAll: [],
        referenceAll: [],
        mediumAll: [],
        campaignAll: [],
        inqHistoryAll: [],
        schoolAll: [],
        standardAll: [],
        reasonAll: [],
        courseListAll: [],
        courseAllData: [],
        batchAll: [],
        feeCourseList : [],
        statusAll:[],
        query: {},
        loading: false,
        error: false,
        inquiryShowData:[],
        inquiryStatusData:[],
        followupReason:[],
        closeReason:[],
        branchesDetails:[],
        branch: [],
        employeeDetails:[],
        importFile:'',
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    onlySummerGlobal: state => state.onlySummerGlobal,
    branchAdmissionFeesApplicable: state => state.branchAdmissionFeesApplicable,
    feeAdditionalAdjustment: state => state.feeAdditionalAdjustment,
    courseComboDetailsAll: state => state.courseComboDetailsAll,
    countrymastersAll: state => state.countrymastersAll,
    statemastersAll: state => state.statemastersAll,
    citymastersAll: state => state.citymastersAll,
    referenceAll: state => state.referenceAll,
    mediumAll: state => state.mediumAll,
    campaignAll: state => state.campaignAll,
    inqHistoryAll: state => state.inqHistoryAll,
    schoolAll: state => state.schoolAll,
    standardAll: state => state.standardAll,
    inqTypesAll: state => state.inqTypesAll,
    ageAll: state => state.ageAll,
    reasonAll: state => state.reasonAll,
    courseAllData: state => state.courseAllData,
    batchAll: state => state.batchAll,
    feeCourseList: state => state.feeCourseList,
    courseListAll: state => state.courseListAll,
    statusAll: state => state.statusAll,
    loading:       state => state.loading,
    relationships: state => state.relationships,
    error: state => state.error,
    inquiryShowData:state => state.inquiryShowData,
    inquiryStatusData:state => state.inquiryStatusData,
    followupReasons: state => state.followupReason,
    closeReasons: state => state.closeReason,
    branchesDetails: state => state.branchesDetails,
    branch: state => state.branch,
    employeeDetails: state => state.employeeDetails,
}

const actions = {
    importInquiryData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise(async (resolve, reject) => {
        let params = new FormData();

        params.set('import_file', state.importFile)
     
        await axios.post('/api/v1/inquiries/import', params,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
                //commit('resetState')
                commit('setLoading', false)
                resolve(response)
                
            })
            .catch(function (error) {
                commit('setLoading1', false)
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                
            })                    
        })        
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            // || state.item.inquiry_type.value == 2

            if (_.isEmpty(state.item.inquiry_type)) {
                params.set('inquiry_type', '')
            } else {
                params.set('inquiry_type', state.item.inquiry_type.value)
            }
            if (_.isEmpty(state.item.country_list)) {
                params.set('country', '')
            } else {
                params.set('country', state.item.country_list.id)
            }
            if (_.isEmpty(state.item.state_list)) {
                params.set('state', '')
            } else {
                params.set('state', state.item.state_list.id)
            }
            if (_.isEmpty(state.item.city_list)) {
                params.set('city', '')
            } else {
                params.set('city', state.item.city_list.id)
            }
            if (_.isEmpty(state.item.source)) {
                params.set('source', '')
            } else {
                params.set('source', state.item.source.value_id)
            }
            if (_.isEmpty(state.item.school)) {
                params.set('school_id', '')
            } else {
                params.set('school_id', state.item.school.id)
            }
            if (_.isEmpty(state.item.standard_id)) {
                params.set('standard', '')
            } else {
                params.set('standard', state.item.standard_id.value_id)
            }
            if (_.isEmpty(state.item.status)) {
                params.set('inquiry_status', '')
            } else {
                params.set('inquiry_status', state.item.status.value_id)
            }

            if (_.isEmpty(state.item.medium)) {
                params.set('medium', '')
            } else {
                params.set('medium', state.item.medium.value_id)
            }
            if (_.isEmpty(state.item.campaign)) {
                params.set('campaign', '')
            } else {
                params.set('campaign', state.item.campaign.value_id)
            }
            if (_.isEmpty(state.item.course_details)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course_details.id)
            }
            if (_.isEmpty(state.item.age)) {
                params.set('age', '')
            } else {
                params.set('age', state.item.age.value)
            }
            // if (_.isEmpty(state.item.reason)) {
            //     params.set('reason_id', '')
            // } else {
            //     params.set('reason_id', state.item.reason.value_id)
            // }
            // params.set('onlySummerGlobal', state.onlySummerGlobal)
            // state.courseListAll.forEach(function(course, index){
            //     params.set('course['+index+'][inquiry_id]',state.item.id);
            //     params.set('course['+index+'][course_id]',course["course_id"]);    
            //     params.set('course['+index+'][prefer_batch_id]',course["prefer_batch_id"]); 
            //     params.set('course['+index+'][prefer_days]',course["prefer_days"]);    
            //     if(typeof course.fee_list !== 'undefined')
            //     {
            //         course.fee_list.forEach(function(fee, feeIndex){
            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_amount]',fee["fees_amount"]);  
                        
            //             // New code - start (AJ)
            //             var discount = parseFloat((fee["discount"])?fee["discount"]:0);
            //             var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                        
            //             if ("other_value" in fee)  {
            //                 var gstRate = parseFloat((fee["other_value"])?parseFloat(fee["other_value"])+100:0);
            //                 var gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))
            //                 //var gstAmount = (parseFloat(afterDiscount)*parseFloat(fee["other_value"])/100);
            //             }
            //             else{
            //                 var gstAmount = parseFloat(fee["gst_amount"]);
            //             }
                        
            //             var totalFees = parseFloat(afterDiscount);
            //             params.set('course['+index+'][fee]['+feeIndex+'][discount]',discount); 
            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_after_discount]',afterDiscount);       
            //             params.set('course['+index+'][fee]['+feeIndex+'][gst_amount]',gstAmount);    
            //             // New code - end (AJ)
            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee["fees_type"]);    
            //             params.set('course['+index+'][fee]['+feeIndex+'][total_fees]',totalFees);  
            //         })
            //     }
            // })
            axios.post('/api/v1/inquiries', params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    setImportFile({ commit }, value) {
        commit('setImportFile', value)
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            

            if (_.isEmpty(state.item.inquiry_type)) {
                params.set('inquiry_type', '')
            } else {
                if(typeof state.item.inquiry_type == 'string') {
                    if(state.item.inquiry_type == 'Cold') {
                        params.set('inquiry_type', 2);
                    } else if (state.item.inquiry_type == 'Hot') {
                        params.set('inquiry_type', 1);
                    }
                } else {
                    params.set('inquiry_type', state.item.inquiry_type.value)
                }
            }

            if (_.isEmpty(state.item.country_list)) {
                params.set('country', '')
            } else {
                params.set('country', state.item.country_list.id)
            }
            if (_.isEmpty(state.item.state_list)) {
                params.set('state', '')
            } else {
                params.set('state', state.item.state_list.id)
            }
            if (_.isEmpty(state.item.city_list)) {
                params.set('city', '')
            } else {
                params.set('city', state.item.city_list.id)
            }
            if (_.isEmpty(state.item.source)) {
                params.set('source', '')
            } else {
                params.set('source', state.item.source.value_id)
            }
            if (_.isEmpty(state.item.school)) {
                params.set('school_id', '')
            } else {
                params.set('school_id', state.item.school.id)
            }
            if (_.isEmpty(state.item.standard_id)) {
                params.set('standard', '')
            } else {
                params.set('standard', state.item.standard_id.value_id)
            }
            if (_.isEmpty(state.item.age)) {
                params.set('age', '')
            } else {
                params.set('age', state.item.age.value)
            }
            // if (_.isEmpty(state.item.reason)) {
            //     params.set('reason_id', '')
            // } else {
            //     params.set('reason_id', state.item.reason.value_id)
            // }
            if (_.isEmpty(state.item.status)) {
                params.set('inquiry_status', '')
            } else {
                params.set('inquiry_status', state.item.status.value_id)
            }
            if (_.isEmpty(state.item.medium)) {
                params.set('medium', '')
            } else {
                params.set('medium', state.item.medium.value_id)
            }
            if (_.isEmpty(state.item.campaign)) {
                params.set('campaign', '')
            } else {
                params.set('campaign', state.item.campaign.value_id)
            }
            if (_.isEmpty(state.item.course_details)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course_details.id)
            }
            // params.set('onlySummerGlobal', state.onlySummerGlobal)
            // state.courseListAll.forEach(function(course, index){
            //     params.set('course['+index+'][inquiry_id]',state.item.id);
            //     params.set('course['+index+'][course_id]',course["course_id"]);    
            //     params.set('course['+index+'][prefer_batch_id]',(course["prefer_batch_id"])?course["prefer_batch_id"]:0); 
            //     params.set('course['+index+'][prefer_days]',(course["prefer_days"])?course["prefer_days"]:0);    
            //     if(typeof course.fee_list !== 'undefined')
            //     {
            //         course.fee_list.forEach(function(fee, feeIndex){

            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_amount]',fee["fees_amount"]);
            //             var discount = parseFloat((fee["discount"])?fee["discount"]:0);
            //             var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                        
                        
            //             if ("other_value" in fee)  {
            //                 var gstRate = parseFloat((fee["other_value"])?parseFloat(fee["other_value"])+100:0);
            //                 var gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))
            //                 //var gstAmount = (parseFloat(afterDiscount)*parseFloat(fee["other_value"])/100);
            //             }
            //             else{
            //                 var gstAmount = parseFloat(fee["gst_amount"]);
            //             }
                        
            //             var totalFees = parseFloat(afterDiscount);
            //             params.set('course['+index+'][fee]['+feeIndex+'][discount]',discount); 
            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_after_discount]',afterDiscount);       
            //             params.set('course['+index+'][fee]['+feeIndex+'][gst_amount]',gstAmount);    
            //             // New code - end (AJ)
            //             params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee["fees_type"]);    
            //             params.set('course['+index+'][fee]['+feeIndex+'][total_fees]',totalFees);    
            //         })
            //     }
            // })
            axios.post('/api/v1/inquiries/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },

    updateStatusData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.inquiry_status)) {
                params.set('inquiry_status', '')
            } else {
                params.set('inquiry_status', form_data.inquiry_status.value_id)
            }

            if (_.isEmpty(form_data.reason_id)) {
                params.set('reason_id', '')
            } else {
                params.set('reason_id', form_data.reason_id.value_id)
            }

            
            axios.post('/api/v1/inquiries/updateInquiryStatus/'+form_data.id, params)
                .then(response => {
                    //commit('setItem', response.data.data)
                    resolve(response.data.data)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Followup Reason','Close Reason']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setFollowupReason', data['Followup Reason'])
                    commit('setCloseReason', data['Close Reason'])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    async fetchData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/inquiries/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
        // dispatch('fetchFeeAdditionalAdjustmentCharge')
        dispatch('fetchCountrymastersAll')
        // dispatch('fetchStatemastersAll')
        // dispatch('fetchCitymastersAll')
        dispatch('fetchReferenceAll')
        dispatch('fetchUTMMedium')
        dispatch('fetchUTMCampaign')
        // dispatch('fetchSchoolAll')
        dispatch('fetchStandardAll')
        dispatch('fetchReasonAll')
        dispatch('fetchCourseAll')
        // await dispatch('fetchCourseListAll',id)
        // dispatch('fetchInquiryHistoryAll',id)
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    }, 

    async fetchStatusData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/inquiries/statusData/' + id)
            .then(response => {
                
                commit('setInquiryStatusData', response.data.data)
                commit('setLoading', false)
            })
    }, 

    async fetchInquiryData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/inquiries/show/' + id)
            .then(response => {
                commit('setInquiryShowData', response.data.data)
                commit('setLoading', false)
                //commit('setLoading', false)
            })
            .finally(() => {
                commit('setLoading', false)
            })
       
    },

    fetchCountrymastersAll({ commit ,dispatch }) {
        axios.get('/api/v1/country')
            .then(response => {
                commit('setCountrymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })      
    },
    fetchCitymastersAll({ commit ,dispatch}) {
        axios.get('/api/v1/cities/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setCitymastersAll', response.data.data)
                }
                else{
                    commit('setCitymastersAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchIfBranchAdmissionFeesApplicable({ commit, dispatch }, obj) {
        await axios.get('/api/v1/fetchIfBranchAdmissionFeesApplicable/'+obj)
            .then(response => {
                commit('setIfBranchAdmissionFeesApplicable', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error in fetchIfBranchAdmissionFeesApplicable', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
//                          reject(error)
                    })      
    },    
    fetchFeeAdditionalAdjustmentCharge({ commit , dispatch}) {
        axios.get('/api/v1/feeAdditionalAdjustmentCharge')
            .then(response => {
                commit('setFeeAdditionalAdjustmentCharge', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },    
    async fetchComboFromCourseDetails({ commit, dispatch }, obj) {
        await axios.get('/api/v1/courseComboDetails/'+obj)
            .then(response => {
                commit('setCourseComboDetailsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchStatemastersAll({ commit, dispatch }) {
        axios.get('/api/v1/states')
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCountryStates({ commit, dispatch }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })             
    },
    fetchStatesCities({ commit ,dispatch}, state_id) {
        axios.get('/api/v1/stateCitiesList/'+state_id)
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchReferenceAll({ commit, dispatch }) {
        axios.get('/api/v1/referenceTypeList')
            .then(response => {
                commit('setReferenceAll', response.data.data) 
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },
    fetchUTMMedium({ commit, dispatch }) {
        axios.get('/api/v1/getCommonMasterType/UTM medium')
            .then(response => {
                commit('setMedium', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },
    fetchUTMCampaign({ commit, dispatch }) {
        axios.get('/api/v1/getCommonMasterType/UTM campaign')
            .then(response => {
                commit('setCampaign', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },
    setIfBranchAdmissionFeesApplicable({ commit }, value) {
        commit('setIfBranchAdmissionFeesApplicable', value)
    },
    fetchSchoolAll({ commit, dispatch }) {
        // axios.get('/api/v1/school-masters')
        axios.get('/api/v1/branchWiseSchoolListing')
            .then(response => {
                commit('setSchoolAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })          
    },
    fetchStandardAll({ commit, dispatch }) {
        axios.get('/api/v1/standardTypeList')
            .then(response => {
                commit('setStandardAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    fetchReasonAll({ commit, dispatch }) {
        axios.get('/api/v1/reasonTypeList')
            .then(response => {
                commit('setReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },
    async fetchCourseAll({ commit, dispatch }) {
        // await axios.get('/api/v1/courses')
        await axiosLms.get('/api/v1/getCoursesForInquries')
            .then(response => {
                console.log(response.data.data);
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })          
    },
    // fetchBatchAll({ commit, dispatch }, id) {
    //     axios.get('/api/v1/batchCourseList/'+ id)
    //         .then(response => {
    //             commit('setBatchAll', response.data.data)
    //         })
    // },
    async fetchBatchAll({ commit, dispatch },obj) {
        await axios.get('/api/v1/course_batch_list/'+ obj.courseType + '/'+obj.days)
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    async fetchFeeCourseList({ commit, dispatch }, id) {
        await axios.get('/api/v1/feeCourseList/'+ id)
            .then(response => {
                commit('setFeeCourseList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchCourseListAll({ commit, dispatch }, id) {
        await axios.get('/api/v1/inqueryCourseList/'+ id)
            .then(response => {
                commit('setCourseListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
    },
    fetchInquiryHistoryAll({ commit, dispatch }, id) {
        axios.get('/api/v1/inqHistoryList/'+ id)
            .then(response => {
                commit('setinqHistoryAll', response.data.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },     
    async fetchStatusAll({ commit, dispatch }) {
        await axios.get('/api/v1/inqueryStatusList')
            .then(response => {
                commit('setStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })         
    },
    async fetchBranchDetails({ commit, dispatch }, project_fee_id) {
        await axios.get('/api/v1/getBranchesForProjectFees')
        .then(response => {
            commit('setBranchDetails', response.data.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
            reject(error)
        })
    },
    async fetchEmployeeDetails({ commit, dispatch }, id) {
        await axios.get('/api/v1/inquiries/getEmployeeDataFromBranch/'+id)
        .then(response => {
            commit('setEmployeeDetails', response.data.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
            reject(error)
        })
    },
    setEmployeeDetails({commit}){
        commit('setEmployeeDetails', []);
    },
    storeAssignEmployeeData({ commit, state, dispatch }, data) {
        //console.log("data",data)
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        for (let fieldName in data) {
            let fieldValue = data[fieldName];
            if (typeof fieldValue !== 'object') {
                params.set(fieldName, fieldValue);
            } else {
                if (fieldValue && typeof fieldValue[0] !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    for (let index in fieldValue) {
                        params.set(fieldName + '[' + index + ']', fieldValue[index]);
                    }
                }
            }
        }
       
        axios.post('/api/v1/inquiries/assignInquiry', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    storeTransferData({ commit, state, dispatch }, data) {
        console.log("data",data)
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
        let params = new FormData();

        for (let fieldName in data) {
            let fieldValue = data[fieldName];
            if (typeof fieldValue !== 'object') {
                params.set(fieldName, fieldValue);
            } else {
                if (fieldValue && typeof fieldValue[0] !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    for (let index in fieldValue) {
                        params.set(fieldName + '[' + index + ']', fieldValue[index]);
                    }
                }
            }
        }
       
        axios.post('/api/v1/inquiries/transfer', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setInquiryDate({ commit }, value) {
        commit('setInquiryDate', value)
    }, 
    setInquiryType({ commit }, value) {
        commit('setInquiryType', value)
    },    
    setContactName({ commit }, value) {
        commit('setContactName', value)
    },
    setContactEmail({ commit }, value) {
        commit('setContactEmail', value)
    },
    setStudentName({ commit }, value) {
        commit('setStudentName', value)
    },
    setMiddle_name({ commit }, value) {
        commit('setMiddle_name', value)
    },
    setLast_name({ commit }, value) {
        commit('setLast_name', value)
    },
    setStudent_address({ commit }, value) {
        commit('setStudent_address', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCountry_list({ commit }, value) {
        commit('setCountry_list', value)
    },
    setState_list({ commit }, value) {
        commit('setState_list', value)
    },
    setCity_list({ commit }, value) {
        commit('setCity_list', value)
    },
    // setCity({ commit }, value) {
    //     commit('setCity', value)
    // },
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setPin_code({ commit }, value) {
        commit('setPin_code', value)
    },
    setBirth_date({ commit }, value) {
        commit('setBirth_date', value)
    },
    setAge({ commit }, value) {
        commit('setAge', value)
    },
    setCourseName({ commit }, value) {
        commit('setCourseName', value)
    },
    setSchoolName({ commit }, value) {
        commit('setSchoolName', value)
    },
    setInquiryRemark({ commit }, value) {
        commit('setInquiryRemark', value)
    },    
    setSource({ commit }, value) {
        commit('setSource', value)
    },
    setUTMMedium({ commit }, value) {
        commit('setUTMMedium', value)
    },
    setUTMCampaign({ commit }, value) {
        commit('setUTMCampaign', value)
    },
    setSchool({ commit }, value) {
        commit('setSchool', value)
    },
    setStandard_id({ commit }, value) {
        commit('setStandard_id', value)
    },
    setFather_name({ commit }, value) {
        commit('setFather_name', value)
    },
    setMother_name({ commit }, value) {
        commit('setMother_name', value)
    },
    setMobile_no({ commit }, value) {
        commit('setMobile_no', value)
    },
    setContactNo({ commit }, value) {
        commit('setContactNo', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setNext_followup_date({ commit }, value) {
        commit('setNext_followup_date', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setStatus_remarks({ commit }, value) {
        commit('setStatus_remarks', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setCourseListAll({ commit }, value) {
        commit('setCourseListAll', value)
    },
    setinqHistoryAll({ commit }, value) {
        commit('setinqHistoryAll', value)
    },
    setFeeCourseList({ commit }, value) {
        commit('setFeeCourseList', value)
    },
    setCourseOffered({ commit }, value) {
        commit('setCourseOffered', value)
    },
    setBatch({ commit }, value) {
        commit('setBatch', value)
    },
    setBatchAll({ commit }, value) {
        commit('setBatchAll', value)
    },
    setStatemastersAll({ commit }, value) {
      commit('setStatemastersAll', value)
    },
    setCitymastersAll({ commit }, value) {
        commit('setCitymastersAll', value)
      },
    setItem({ commit }, value) {
        commit('setItem', purify(value))
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setOnlySummerCampGlobal({ commit }, value) {
        commit('setOnlySummerCampGlobal', value)
    },    
    setIfBranchAdmissionFeesApplicable({ commit }, value) {
        commit('setIfBranchAdmissionFeesApplicable', value)
    },
    setInquiryShowData({ commit },item){
        commit('setInquiryShowData', item)
    },   
    setBranches({ commit }, value) {
        commit('setBranches', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
}

const mutations = {
    setImportFile(state, value) {
        state.importFile = value;
    },
    setEmployeeDetails(state, data){
        console.log("data",data);
        state.employeeDetails = data;
    },
    setFollowupReason(state,reason){
        //let r = reason;
        reason.push({id:0,name:"Other Reason",type_id:reason[0].type_id,value_id:0});
        state.followupReason = reason;
    },
    setCloseReason(state,reason){
        reason.push({id:0,name:"Other Reason",type_id:reason[0].type_id,value_id:0});
        state.closeReason = reason
    },
    setInquiryStatusData(state,item){
        state.inquiryStatusData = item
    },
    setInquiryShowData(state,item){
        // console.log("sdffdf",item);
        state.inquiryShowData = item
    },
    setItem(state, item) {
        state.item = item
    },
    setAll(state, items) {
        state.all = items
    },
    setFeeAdditionalAdjustmentCharge(state, value) {
        state.feeAdditionalAdjustment = value
    },    
    setIfBranchAdmissionFeesApplicable(state, value) {
        state.branchAdmissionFeesApplicable = value
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    setError(state, error) {
        state.error = error
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setInquiryDate(state, value) {
        state.item.inquiry_date = value
    },
    setInquiryType(state, value) {
        state.item.inquiry_type = value
    },
    setContactName(state, value) {
        state.item.contact_name = value
    },
    setContactEmail(state, value) {
        state.item.contact_email = value
    },
    setStudentName(state, value) {
        state.item.student_name = value
    },
    setMiddle_name(state, value) {
        state.item.middle_name = value
    },
    setLast_name(state, value) {
        state.item.last_name = value
    },
    setStudent_address(state, value) {
        state.item.student_address = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCountry_list(state, value) {
        state.item.country_list = value
    },
    setState_list(state, value) {
        state.item.state_list = value
    },
    setCity_list(state, value) {
        state.item.city_list = value
    },
    // setCity(state, value) {
    //     state.item.city = value
    // },
    setGender(state, value) {
        state.item.gender = value
    },
    setPin_code(state, value) {
        state.item.pin_code = value
    },
    setBirth_date(state, value) {
        state.item.birth_date = value
    },
    setAge(state, value) {
        state.item.age = value
    },
    setCourseName(state, value) {
        state.item.other_course_name = value
    },
    setSchoolName(state, value) {
        state.item.other_school_name = value
    },
    setInquiryRemark(state, value) {
        state.item.inquiry_remark = value
    },    
    setSource(state, value) {
        state.item.source = value
    },
    setSchool(state, value) {
        state.item.school = value
    },
    setStandard_id(state, value) {
        state.item.standard_id = value
    },
    setFather_name(state, value) {
        state.item.father_name = value
    },
    setMother_name(state, value) {
        state.item.mother_name = value
    },
    setMobile_no(state, value) {
        state.item.mobile_no = value
    },
    setContactNo(state, value) {
        state.item.contact_number = value
    },
    setStatus(state, value) {
        state.item.status = value
    },
    setNext_followup_date(state, value) {
        state.item.next_followup_date = value
    },
    setReason(state, value) {
        state.item.reason = value
    },
    setStatus_remarks(state, value) {
        state.item.status_remarks = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setCourseComboDetailsAll(state, value) {
        state.courseComboDetailsAll = value
    },
    setStatemastersAll(state, value) {
        state.statemastersAll = value
    },
    setCitymastersAll(state, value) {
        state.citymastersAll = value
    },
    setReferenceAll(state, value) {
        state.referenceAll = value
    },
    setMedium(state, value) {
        state.mediumAll = value
    },
    setCampaign(state, value) {
        state.campaignAll = value
    },
    setCourseAll(state, value) {
        state.courseAllData = value
    },
    setUTMMedium(state, value) {
        state.item.medium = value
    },
    setUTMCampaign(state, value) {
        state.item.campaign = value
    },
    setinqHistoryAll(state, value) {
        state.inqHistoryAll = value
    },
    setSchoolAll(state, value) {
        value.push({id:0,school_name:"Other School",school_board:null,country:null,created_at:null,updated_at:null,deleted_at:null});
        state.schoolAll = value
    },
    setStandardAll(state, value) {
        state.standardAll = value
    },
    setReasonAll(state, value) {
        state.reasonAll = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setStatusAll(state, value) {
        state.statusAll = value
    },
    setCourseListAll(state, value) {
        state.courseListAll = value
    },
    setFeeCourseList(state, value) {
        state.feeCourseList = value
    },
    setOnlySummerCampGlobal(state, value) {
        state.onlySummerGlobal = value
    },    
    setCourseOffered(state, value) {
        state.item.course_details = value
    },
    setBatch(state, value) {
        state.item.batch = value
    },
    setBranches(state, value) {
        state.item.branches = value
    },
    setBranchDetails(state, item) {
        state.branchesDetails = item
    },
    setBranch(state, value) {
        state.branch = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
