import { render, staticRenderFns } from "./StudentAssessmentTopicPoup.vue?vue&type=template&id=d1e25f98&scoped=true&"
import script from "./StudentAssessmentTopicPoup.vue?vue&type=script&lang=js&"
export * from "./StudentAssessmentTopicPoup.vue?vue&type=script&lang=js&"
import style0 from "./StudentAssessmentTopicPoup.vue?vue&type=style&index=0&id=d1e25f98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1e25f98",
  null
  
)

export default component.exports