<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Batches Allocation</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Batch Allocation</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                            <div class="col-md-7 col-xs-12">
                                <div ref="batch" class="form-group">
                                    <label for="batch">Batch Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="batch"
                                            label="batch_name"
                                            v-validate="'required'"
                                            @input="updateBatch"
                                            :value="item.batch"
                                            :options="batchAll"
                                            
                                            />
                             <div v-if="locationSeen && (getLocationName() !='' || item.location_name !='')">
                                <!-- <label>Batch Details</label><br> -->
                                <label  v-if="getLocationName() !='' || item.location_name !=''">Location : {{(getLocationName() != '')?getLocationName():item.location_name}}</label><br>
                                <label v-if="getLocationStartDate() !='' || item.batch_start_date !=''">Start Date : {{(getLocationStartDate() != '')?getLocationStartDate():item.batch_start_date}}</label><br>
                                <label v-if="getLocationEndDate() !='' || item.batch_end_date !=''">End Date : {{(getLocationEndDate() != '')?getLocationEndDate():item.batch_end_date}}</label>
                            </div>
                                            <span class="error" v-show="errors.has('batch')">
                                              {{ $root.updateErrorMessage('batch',this.errors,'Batch Name')}}
                                            </span>  
                                </div>
                                </div>
                                <div class="col-md-7 col-xs-12">
                                <div ref="employee" class="form-group">
                                    <label for="employee">Trainer Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="employee"
                                            label="employee_name"
                                            v-validate="'required'"
                                            @input="updateEmployee"
                                            :value="item.employee"
                                            :options="employeeAll"
                                            />
                                            <span class="error" v-show="errors.has('employee')">
                                              {{ $root.updateErrorMessage('employee',this.errors,'Trainer Name')}}
                                            </span>
                                </div>
                            </div>
                            </div>

                            
                        </div>
                          <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            locationData:'',
            locationSeen:false,
        }
    },
    computed: {
        ...mapGetters('BatchAllocationsSingle', ['item', 'loading', 'batchAll', 'employeeAll']),
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BatchAllocationsSingle', ['fetchData', 'updateData', 'resetState', 'setBranch_id', 'setBatch', 'setEmployee']),
        getLocationName(){
            if(this.locationData.location_name !== undefined){
                return this.locationData.location_name;
            }else{
                return '';
            }            
        },
        getLocationStartDate(){
            if(this.locationData.batch_start_date !== undefined){
                return this.locationData.batch_start_date;
            }else{
                return '';
            }            
        },
        getLocationEndDate(){
            if(this.locationData.batch_end_date !== undefined){
                return this.locationData.batch_end_date;
            }else{
                return '';
            }            
        },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateBatch(value) {
            if(value != null){
                this.locationSeen = true;
                this.locationData = value;                     
            }else{
                this.locationSeen = false;
            }            
            this.setBatch(value)
        },
        updateEmployee(value) {
            this.setEmployee(value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
            if (result) {
                this.updateData()
                    .then((res) => {
                      if(res.data.error_message == 1)
                        {
                            this.$validator.errors.add({
                              id: 'batch',
                              field: 'batch',
                              msg: res.data.message,
                              scope: this.$options.scope,
                            });
                            return '';    
                        }
                      else if(res.data.error_message == 2)
                        {
                            this.$validator.errors.add({
                              id: 'employee',
                              field: 'employee',
                              msg: res.data.message,
                              scope: this.$options.scope,
                            });
                            return '';    
                        }   
                        this.$router.push({ name: 'batch_allocations.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
            });
        }
    }
}
</script>


<style scoped>

</style>
