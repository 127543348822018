<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Reminders</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Reminder</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Title</th>
                                            <td>{{ item.title }}</td>
                                            </tr>
                                        <tr>
                                            <th>Reminder Date</th>
                                            <td>
                                                {{item.reminder_date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Do Contact ?</th>
                                            <td>
                                                {{item.do_contact_text}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1">
                                            <th>Reminder To</th>
                                            <td>
                                                {{item.reminder_to.name}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 1">
                                            <th>Student Name</th>
                                            <td>
                                                {{item.reminder_to_id.name}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 1">
                                            <th>Contact Name</th>
                                            <td>
                                                {{item.parent_contact_name}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 1">
                                            <th>Contact Number</th>
                                            <td>
                                                {{item.contact_number}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 2">
                                            <th>Employee Name</th>
                                            <td>
                                                {{item.reminder_to_id.name}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 2">
                                            <th>Contact Number</th>
                                            <td>
                                                {{item.contact_number}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 3">
                                            <th>Contact Name</th>
                                            <td>
                                                {{item.contact_name}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.do_contact == 1 && item.reminder_to.value_id == 3">
                                            <th>Contact Number</th>
                                            <td>
                                                {{item.contact_number}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Remarks</th>
                                            <td>
                                                {{item.remarks}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Reminder Status</th>
                                            <td>
                                                {{item.status_text}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('RemindersSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('RemindersSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
