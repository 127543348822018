<template>
    <div class="btn-group btn-group-xs">
            <span v-if="row.inquiry_type1 == 'Hot'" class="badge badge-danger">
                {{row.inquiry_type1}}
            </span>
            <span v-if="row.inquiry_type1 == 'Cold'" class="badge badge-primary">
                {{row.inquiry_type1}}
            </span>
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field']
}
</script>


<style scoped>

</style>
