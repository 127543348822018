function initialState() {
    return {
        item: {
            id: null,
            name: null,
            description: null,
            item_category: null,
            code: null,
            part_number: null,
        },
        categoriesAll: [],
        item_details: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    categoriesAll: state => state.categoriesAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item_details) {
                let fieldValue = state.item_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          if(error.response && error.response.status && error.response.status == 422){
                              Object.keys(errors).forEach(function(val,key){
                                  for(let i in errors[val]){
                                      dispatch(
                                            'Alert/setAlert',
                                            { message: errors[val][i], errors: errors, color: 'danger' },
                                            { root: true })
                                  }
                                  
                              });
                          }else{
                              dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          }
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item_details) {
                let fieldValue = state.item_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                    // resolve(response.data.data)


                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          if(error.response && error.response.status && error.response.status == 422){
                              Object.keys(errors).forEach(function(val,key){
                                  for(let i in errors[val]){
                                      dispatch(
                                            'Alert/setAlert',
                                            { message: errors[val][i], errors: errors, color: 'danger' },
                                            { root: true })
                                  }
                                  
                              });
                          }else{
                              dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          }
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/inventory-item/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCategoriesAll({ commit ,dispatch}) {
        axios.get('/api/v1/inventory-categories')
            .then(response => {
                commit('setCategoriesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setItemCategory({ commit }, value) {
        commit('setItemCategory', value)
    },
    setItemDetails({ commit }, value) {
        commit('setItemDetails', value)
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setCode({ commit }, value) {
        commit('setCode', value)
    },
    setPartNumber({ commit }, value) {
        commit('setPartNumber', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        item.item_category = {
            name:item.item_category_name,
            value_id:item.item_category
        };
        state.item = item
    },
    setItemCategory(state, value) {
        state.item.item_category = value
    },
    setCategoriesAll(state, value) {
        state.categoriesAll = value
    },
    setItemDetails(state, value) {
        state.item_details = value
    },
    setName(state, value) {
        state.item.name = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setCode(state, value) {
        state.item.code = value
    },
    setPartNumber(state, value) {
        state.item.part_number = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
