function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            location_name: null,
            location_address: null,
            contact_no: null,
            area: null,
            city: null,
            pin: null,
            country: {'id':99,'country_name':'India'},
            state: null,
        },
        countrymastersAll: [],
        statemastersAll: [],
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    countrymastersAll: state => state.countrymastersAll,
    statemastersAll: state => state.statemastersAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.country)) {
                params.set('country_id', '')
            } else {
                params.set('country', state.item.country.id)
            }
            if (_.isEmpty(state.item.state)) {
                params.set('state_id', '')
            } else {
                params.set('state', state.item.state.id)
            }

            axios.post('/api/v1/location-masters', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.country)) {
                params.set('country', '')
            } else {
                params.set('country', state.item.country.id)
            }
            if (_.isEmpty(state.item.state)) {
                params.set('state', '')
            } else {
                params.set('state', state.item.state.id)
            }

            axios.post('/api/v1/location-masters/' + state.item.id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/location-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        dispatch('fetchCountrymastersAll')
        dispatch('fetchStatemastersAll')
    },
    fetchCountrymastersAll({ commit }) {
        axios.get('/api/v1/countrys')
            .then(response => {
                commit('setCountrymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchStatemastersAll({ commit }) {
        axios.get('/api/v1/states')
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCountryStates({ commit }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setLocation_name({ commit }, value) {
        commit('setLocation_name', value)
    },
    setLocation_address({ commit }, value) {
        commit('setLocation_address', value)
    },
    setContact_no({ commit }, value) {
        commit('setContact_no', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setPin({ commit }, value) {
        commit('setPin', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setState({ commit }, value) {
        commit('setState', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setStatemastersAll({ commit }, value) {
      commit('setStatemastersAll', value)
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setLocation_name(state, value) {
        state.item.location_name = value
    },
    setLocation_address(state, value) {
        state.item.location_address = value
    },
    setContact_no(state, value) {
        state.item.contact_no = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCity(state, value) {
        state.item.city = value
    },
    setPin(state, value) {
        state.item.pincode = value
    },
    setCountry(state, value) {
        state.item.country = value
    },
    setState(state, value) {
        state.item.state = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setStatemastersAll(state, value) {
        state.statemastersAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
  