<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
            <div class="col-md-12 col-xl-12">
                <a @click="downloadSampleExcel" class="float-right" title="Sample File"><i class="fa fa-download"></i></a>
            </div> 
            <div class="col-md-12 col-xs-12">
                <div ref="import_file" class="form-group" >
                    <label for="import_file">Upload File <p class="astrisk">*</p> (Upload .xlsx file)</label>
                    <input type="file" class="form-control" name="import_file" @change="updateImportFile"  v-validate="'required|ext:xlsx'">
                    <span class="error" v-show="errors.has('import_file')">
                        {{ $root.updateErrorMessage('import_file',this.errors,'File')}}
                    </span>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    props:['inquiryId'],
    components: { CommonMasterPopup },
    data() {
        return {
            branch_id:'',
            employee_id:''
        }
    },
    computed: {
        ...mapGetters('InquiriesSingle', ['item', 'loading','branchesDetails','employeeDetails']),
       
    },
    created() {
        console.log(window.axios.defaults.baseURL);
        this.setEmployeeDetails();
        this.fetchBranchDetails()
    },
   
    methods: {
        ...mapActions('InquiriesSingle', ['importInquiryData','setImportFile','storeAssignEmployeeData','setEmployeeDetails','fetchBranchDetails','setBranches','fetchEmployeeDetails']),
        closeModel(){
            this.$emit('closeModel');
        },
        downloadSampleExcel() {
            window.location = window.axios.defaults.baseURL+'download-sample-inquiry-excel';
        },
        updateImportFile(e) {
            this.setImportFile('');
            this.fileError = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
               // console.log(e.target.files[0].type);
               // return false;
                if(e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    this.setImportFile(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg')){
                        message = 'File size should be less then 500MB.'
                    }
                    else {
                        message = 'Only .xlsx files are allowed'
                    }
                    this.$swal({
                    title: message,
                    type: 'error',                      
                    focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_video").val('');
                            this.$validator.errors.remove('topic_video');
                            this.setImportFile('');
                        }
                    })
                }
            }           
        },
        submitForm () {
            this.$validator.validate().then(result => {
                if (result) {
                    this.importInquiryData()
                    .then((res) => {
                        console.log(res);
                    if(!res.data.result)
                    {
                        this.$emit('closeModel');
                        this.$emit('loadItems');
                        this.$eventHub.$emit('common',2,res.data.message)
                    } 
                    else{
                        this.$emit('closeModel');
                        this.$emit('loadItems');
                        this.$eventHub.$emit('common',1,res.data.message)
                    }
                   
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>