<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Student Batches</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Student Batches List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                           <div class="box-body row" >
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label for="from_date">Course Name</label>
                                  <v-select
                                    name="search_course_name"
                                    label="course_name"                                           
                                    :value="null"
                                    v-model="date_filter.search_course_name"
                                    :options="courseListWithDistinctAll"        
                                    />                                
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label for="from_date">Batch Name</label>
                                 <v-select
                                    name="search_batch_name"
                                    label="batch_name"
                                    :value="null"
                                    v-model="date_filter.search_batch_name"
                                    :options="batchAll"
                                    />                                  
                            </div> 
                            <div class="col-md-4 col-sm-6 col-xs-12">
                                <label for="from_date"><!-- Student Name --></label>
                                    <div class="my-search">
                                         <!-- <v-select
                                            name="search_student_name"
                                            label="student_name"
                                            class=" my-search-select"
                                            :value="null"
                                            v-model="date_filter.search_student_name"
                                            :options="studentAll"
                                            /> -->
                                        <button type="button" class="btn btn-success" @click="searchCourseData()">Search</button>
                                    </div> 
                            </div> 
                            
                            
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import StudentBatchActions from '../../dtmodules/DatatableActions/StudentBatchActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import StudentName from '../../dtmodules/StudentName'

StudentBatchActions.attachStore('StudentBatchIndex');
export default {
    props: ['student_id_from_props'],    
    components,
    StudentBatchActions,
    data() {
        return {
            items:[],
            columns: [
               { title: 'Student Name', tdComp:StudentName, field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
               { title: 'Lab Name', field: 'lab_name', sortable: true, thComp: 'FilterTh', tdClass: 'Lab Name' },
               { title: 'Batch Name', field: 'batch_name', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
               { title: 'Course Name', field: 'display_course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },

                { title: 'Start Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                // { title: 'Batch Original Start Date', visible: false, field: 'original_format_date_b', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                // { title: '', field: 'original_date_b', thClass: 'inqStats', tdClass: 'Holiday Date'},                

                { title: 'End Date', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Holiday Date'},
               // { title: 'Start Date', field: 'start_date', sortable: true, thComp: 'FilterTh', tdClass: 'Start Date' },
               // { title: 'Batch Original Start Date', field: 'org_start_date',visible: false, sortable: true, thComp: 'FilterTh', tdClass: 'Batch Original Start Date' },
               // { title: 'End Date', field: 'end_date', sortable: true, thComp: 'FilterTh', tdClass: 'End Date' },
               { title: 'Batch Status', field: 'batch_status_text', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Status' },
               { title: 'Actions', tdComp: StudentBatchActions, visible: true, thClass: 'text-right', tdClass: '', tdStyle:'text-align: right' /*colStyle: 'width: 185px;'*/ }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'StudentBatchIndex',
                route: 'student_batch',
                permission_prefix: 'student_batch_'
            },
            date_filter : {search_student_name : null,search_batch_name : null, search_course_name : null,search_batch_id : null}    
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchCourseListWithDistinctAll()
        this.fetchBatchAll()
        if(this.student_id_from_props) {
            this.fetchStudentAll()
                .then(res=> {
                    var localStudentIdFromProps = this.student_id_from_props,
                    finalStudentDate = null;
                    this.studentAll.filter(function (i,n){
                        if(i.id == localStudentIdFromProps){
                            finalStudentDate = i;
                        }
                    });
                    this.date_filter.search_student_name = finalStudentDate
                    this.searchCourseData()
                })
        } else {
            this.fetchData()
        }   
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('StudentBatchIndex', ['data', 'total', 'loading', 'relationships','courseListWithDistinctAll','studentAll','batchAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
    },
    methods: {
        ...mapActions('StudentBatchIndex', ['fetchData','fetchCourseListWithDistinctAll','fetchStudentAll','fetchBatchAll','setQuery', 'resetState']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['student_name','lab_name','batch_name','display_course_name','original_format_date','original_date','original_format_date_a','original_date_a','original_format_date_b','original_date_b','batch_status_text'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.query = {};
            this.date_filter.search_batch_name = null;
            this.date_filter.search_student_name = null;
            this.date_filter.search_course_name = null;
            this.date_filter.search_batch_id = null;
            this.fetchData()  
        },
          searchCourseData() {
            this.query = {};
            if(this.date_filter.search_batch_name && this.date_filter.search_batch_name.batch_name) {
                this.date_filter.search_batch_id = this.date_filter.search_batch_name.id;    
                this.date_filter.search_batch_name = this.date_filter.search_batch_name.batch_name;    
            }
            if (this.date_filter.search_student_name && this.date_filter.search_student_name.student_name) {
                this.date_filter.search_student_name = this.date_filter.search_student_name.student_name;    
            }
            if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
                this.date_filter.search_course_name = this.date_filter.search_course_name.course_name;    
            }

            this.fetchData(this.date_filter);
        }
    }
}
</script>


<style scoped>

</style>