<template>
    <div class="card tableCard">
            <div class="table-responsive setting-group">
                <!-- <button v-b-modal.modalPreventReceipt v-if="$can('receipt_edit')" @click="openEditReceipt" class="btn btn-primary float-right edit_delete_btn">Edit Payment</button> -->
                <vue-good-table
                    ref="table1"
                    styleClass="vgt-table table table-bordered striped thead-dark"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    :totalRows="totalRecords"
                    :sort-options="{
                        enabled: false,
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage:10,
                        perPageDropdown: [10, 20, 40, 80, 100],
                        dropdownAllowAll: false,
                    }"
                    :isLoading.sync="isLoading"
                    :select-options="{ 
                        enabled: false,
                    }"
                    :search-options="{
                        enabled: false,
                        placeholder: 'search',
                    }"
                    :rows="rows"
                    :columns="columns"
                    >
                        <div slot="emptystate" class="text-center">
                        <span v-if="!isLoading">{{'No Records Found.'}}</span>
                    </div>
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <span v-if="props.column.field == 'actions'">
                            <ReceiptActions @loadItems="loadItems" :student_id="student_id" @receiptIdModel="receiptIdModel" :xprops="xprops" :row="props.row"></ReceiptActions>
                        </span>
                        <span v-else>
                            {{props.formattedRow[props.column.field]}}
                        </span>
                    </template>
                    <template slot="loadingContent">
                        <CustomLoader></CustomLoader>
                    </template>
                </vue-good-table>
            </div>
            <!-- <EditReceipt :isEditReceipt="isEditReceipt" :student_id="student_id"></EditReceipt> -->  
             <ViewReceipt @loadItems="loadItems" :receiptId="receiptId" :student_id="student_id"></ViewReceipt>          
             <EditReceipt @loadItems="loadItems" :receiptId="receiptId" :student_id="student_id"></EditReceipt>          
    </div>
     
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReceiptActions from '../../dtmodules/DatatableActions/ReceiptActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
import StudentName from '../../dtmodules/StudentName'
import Amount from '../../dtmodules/Amount'
import ViewReceipt from '../Receipts/Show'
import EditReceipt from '../Receipts/EditReceipt'

ReceiptActions.attachStore('ReceiptsIndex');
export default {
    components,
    components: {
        ReceiptActions,
        CustomLoader,
        ViewReceipt,
        EditReceipt,
    },
    props:['paymentShow'],
    data() {
        return {
            student_id:null,
            isLoading: true,
            refershLoad:false,
            modalShow:false,
            receiptId:0,
            details:[],
            columns: [
                {
                    label: "Courses",
                    field: "courses",
                    /* filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: false, // enable filter for this column
                        placeholder: 'Profile', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    }, */
                },
                {
                    label: "Receipt Date",
                    field: "receipt_date",
                    /* filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: false, // enable filter for this column
                        placeholder: 'Profile', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    }, */
                },
                {
                    label: "Receipt Amount",
                    field: "receipt_amount",
                   /*  filterOptions: {
                        styleClass: 'student_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Admission Date', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    }, */
                }, 
                {
                label: "Actions",
                field: "actions",
                sortable: false
                }

            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                domainName: window.location.origin,
                module: 'ReceiptsIndex',
                route: 'receipts',
                permission_prefix: 'receipt_'
            },
        }
    },
    watch: {
        "$route.params.id": function() {
            this.$root.relationships = this.relationships
            this.student_id = this.$route.params.id
        },    
        paymentShow: function() {
            if(this.paymentShow){
                this.loadItems();
                this.student_id = this.$route.params.id
            }
        },                
    },
    created() {
        this.$root.relationships = this.relationships
        if(this.paymentShow){
            this.student_id = this.$route.params.id
            this.loadItems();            
        }
    },
    computed: {
        ...mapGetters('ReceiptsIndex', ['data', 'total', 'loading', 'relationships']),
    },
    methods: {
        ...mapActions('ReceiptsIndex', ['fetchData', 'setQuery', 'resetState','destroyData']),
        openAddReceipt(){
            this.isEditReceipt = true
        },
        receiptIdModel(id){
            console.log("iud",id)
            this.receiptId = id;
        },
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axiosLms.get("/api/v1/receipt", {
            params: {
                student_id:this.$route.params.id,
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>


<style scoped>
.m-t-25 {
    margin-top: 25px
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.upper-background {
    border: 1px solid #008b8b;
    background: #fffafa;
    z-index: 10021 !important;
}
ul li {
    margin-left: 10px;
    margin-right: 10px;    
}
.dropdown-buttons {
    width: 150px;
}
.tableCard {
    min-height: 180px !important;
}
</style>
