<template>
    <div class="popup-form add-country">
        <b-modal id="modalPrevent2"
            ref="modal"
            title="Add Skill"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"
            @cancel="handleCancel"
            hideFooter
            @shown="clear" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true"
        >
            <form @submit.stop.prevent="handleSubmit" >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-4 col-xs-6">
                                    <label for="skill_header_id">Header Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="skill_header_id"
                                        label="header_name"
                                        v-validate="'required'"
                                        :value="item.skill_header_id"
                                        disabled
                                    />
                                    <span class="error" v-show="errors.has('skill_header_id')">
                                        {{ $root.updateErrorMessage('skill_header_id',this.errors, 'Header Name')}}
                                    </span>
                                </div>
                                <div class="col-md-4 col-xs-6">
                                    <label ref="skill_name" for="skill_name">Skill Name<p class="astrisk">*</p></label>
                                    <b-form-input 
                                        id="skill_name"
                                        type="text"
                                        name="skill_name"
                                        maxlength="90"
                                        v-validate="'required'"
                                        v-model="item.skill_name"
                                        placeholder="Enter Skill Name">
                                    </b-form-input>
                                    <span class="error" v-show="errors.has('skill_name')">
                                        {{ $root.updateErrorMessage('skill_name',this.errors, 'Skill Name')}}
                                    </span>
                                </div>
                                <div class="col-md-4 col-xs-6">
                                    <label ref="skill_label" for="skill_label">Skill Label<p class="astrisk">*</p></label>
                                    <b-form-input 
                                        id="skill_label"
                                        type="text"
                                        name="skill_label"
                                        maxlength="90"
                                        v-validate="'required'"
                                        v-model="item.skill_label"
                                        placeholder="Enter Skill Label">
                                    </b-form-input>
                                    <span class="error" v-show="errors.has('skill_label')">
                                        {{ $root.updateErrorMessage('skill_label',this.errors, 'Skill Label')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12 mt-3">
                                    <div class="form-group">
                                        <label ref="rising_star" for="rising_star">Rising Star<p class="astrisk">*</p></label>
                                        <b-form-textarea 
                                            id="rising_star"
                                            type="textarea"
                                            name="rising_star"
                                            v-validate="'required'"
                                            v-model="item.rising_star"
                                            placeholder="Enter Rising Star">
                                        </b-form-textarea>
                                        <span class="error" v-show="errors.has('rising_star')">
                                            {{ $root.updateErrorMessage('rising_star',this.errors, 'Rising Star')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <label ref="rising_star" for="rising_star">All Star<p class="astrisk">*</p></label>
                                        <b-form-textarea 
                                            id="all_star"
                                            type="textarea"
                                            name="all_star"
                                            v-validate="'required'"
                                            v-model="item.all_star"
                                            placeholder="Enter All Star">
                                        </b-form-textarea>
                                        <span class="error" v-show="errors.has('all_star')">
                                            {{ $root.updateErrorMessage('all_star',this.errors, 'All Star')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <label ref="champion" for="champion">Champion<p class="astrisk">*</p></label>
                                        <b-form-textarea 
                                            id="champion"
                                            type="textarea"
                                            name="champion"
                                            v-validate="'required'"
                                            v-model="item.champion"
                                            placeholder="Enter Champion">
                                        </b-form-textarea>  
                                        <span class="error" v-show="errors.has('champion')">
                                            {{ $root.updateErrorMessage('champion',this.errors, 'Champion')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="form-group">
                                <label for="logo">Image </label>
                                <input
                                    type="file"
                                    name="image"
                                    id="image"
                                    v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg,svg'"
                                    class="form-control"
                                    @change="updateImage"
                                >
                                <span class="error" v-show="errors.has('image')">
                                    {{ $root.updateErrorMessage('image',this.errors,'image')}}
                                </span>
                                <ul v-if="item.image" class="list-unstyled text-center">
                                    <li>
                                        <div class="" style="">
                                            <div v-if="item.image != null">
                                                <img :src="item.image" id="blah" class="skill-image profile-user-img img-responsive" :alt="item.skill_name"> 
                                            </div>
                                        </div>
                                        <button class="btn btn-xs btn-danger"
                                            type="button"
                                            @click="removeImage"
                                        >
                                            Remove file
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="modal-footer modal-footer1">
                    <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
                    <button type="submit" class="btn btn-success" :disabled="loading">Save</button>
                </footer>
            </form>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
        skill_name:'',
        skill_label:'',
        rising_star:'',
        all_star:'',
        champion:'',
        Image: ''
    }
  },
  props:[ 'skill_header_id' ],
  computed: {
        ...mapGetters('SkillMasterSingle', ['item', 'loading', 'headerList']),
    },
    created() {
        this.fetchHeaderAll()
        this.fetchData(this.skill_header_id)
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('SkillMasterSingle', ['getListing', 'setHeaderId', 'setSkillName', 'setSkillLabel', 'setRisingStar', 'setAllStar', 'setChampion', 'setImage', 'fetchHeaderAll', 'fetchData', 'storeData', 'resetState', 'setRemoveItem']),

        closeModel(){
            this.$refs.modal.hide();
        },
        updateHeaderId(value) {
            this.setHeaderId(value)
        },
        updateSkillName(value){
            this.setSkillName(value)
        },
        updateSkillLabel(value){
            this.setSkillLabel(value)
        },
        updateRisingStar(value){
            this.setRisingStar(value)
        },
        updateAllStar(value){
            this.setAllStar(value)
        },
        updateChampion(value){
            this.setChampion(value)
        },
        handleCancel(evt){
            this.setRemoveItem()
            this.fetchData(this.$route.params.id)
            this.getListing(this.item.skill_header_id.id)
            this.$validator.errors.remove()
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.handleSubmit()
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                } 
            });
        },
        clear () {
            this.$validator.errors.remove() 
            this.fetchData(this.$route.params.id)
            .then((res)=>{
                //this.setRemoveItem()
            })
        },
        removeImage(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setImage('');
                     $("#image").val('');
                }
            })
        }, 
        updateImage(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader()
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200)
                    }
                    reader.readAsDataURL(e.target.files[0])
                    this.setImage(e.target.files[0])
                    this.$forceUpdate()
                }else{
                    var message = ""
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/svg+xml')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, SVG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',
                        focusCancel: true,
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#image").val('')
                            this.$validator.errors.remove('image')
                            this.setImage('')
                        }
                    })
                }
            }
        },
        handleSubmit () {
            this.storeData()
            .then(res => {
                if(res.data.result == false){
                    for (const [key, value] of Object.entries(res.data.data)) {
                        this.$validator.errors.add({
                            id: key,
                            field: key,
                            msg: value[0],
                            scope: this.$options.scope,
                        });
                    }
                    return '';
                }else{
                    this.setRemoveItem()
                    this.$validator.errors.remove()
                    this.$refs.modal.hide()
                    this.$eventHub.$emit('update-success')
                    this.$emit('loadItems')
                }
            }).catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
        }
    }
}
</script>