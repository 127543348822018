import { render, staticRenderFns } from "./Amount.vue?vue&type=template&id=123d1029&scoped=true&"
import script from "./Amount.vue?vue&type=script&lang=js&"
export * from "./Amount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123d1029",
  null
  
)

export default component.exports