function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            enroll_date: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            student_address: null,
            area: null,
            country_list: {'id':99,'country_name':'India'},
            state_list: null,
            city_list: null,
            gender: 1,
            pin_code: null,
            other_school_name:null,
            birth_date: null,
            start_date: null,
            parent: null,
            tshirt_size: null,
            school: null,
            reference: null,
            standard_id: null,
            student_status: null,           
            status: null,
            next_followup_date: null,
            reason: null,
            status_remarks: null,
            course : null,
            batch : null,
            profile:null,
            coupon_code:null,
            refferal_code:null,
        },
        onlySummerGlobal: true,
        countrymastersAll: [],
        feeAdditionalAdjustment: [],
        branchMastersAll: [],
        referenceAll: [],
        statemastersAll: [],
        citymastersAll: [],
        parentAll: [],     
        tshirtSizesAll: [],
        courseComboDetailsAll: [],
        branchAdmissionFeesApplicable: [],
        schoolAll: [],
        standardAll: [],
        studentStatusAll: [],
        studentReasonAll: [],
        reasonAll: [],
        courseListAll: [],
        courseProgressAll: [],
        courseAll: [],
        batchAll: [],
        feeCourseList : [],
        studentFeeCourseList : [],
        feesTypeAvailableListAll : [],
        statusAll:[],
        receiptsAll:[],
        batchAll:[],
        loading: false,
        courseCategory:[],
        studentEditCourseData:[],
        pay_credit:0,
        per_value:0,
        final_pay_credit:0,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    branchMastersAll: state => state.branchMastersAll,
    referenceAll: state => state.referenceAll,
    onlySummerGlobal: state => state.onlySummerGlobal,
    courseComboDetailsAll: state => state.courseComboDetailsAll,
    branchAdmissionFeesApplicable: state => state.branchAdmissionFeesApplicable,
    countrymastersAll: state => state.countrymastersAll,
    feeAdditionalAdjustment: state => state.feeAdditionalAdjustment,
    statemastersAll: state => state.statemastersAll,
    citymastersAll: state => state.citymastersAll,
    parentAll: state => state.parentAll,
    tshirtSizesAll: state => state.tshirtSizesAll,
    schoolAll: state => state.schoolAll,
    standardAll: state => state.standardAll,
    studentStatusAll: state => state.studentStatusAll,
    studentReasonAll: state => state.studentReasonAll,
    reasonAll: state => state.reasonAll,
    courseAll: state => state.courseAll,
    batchAll: state => state.batchAll,
    feeCourseList: state => state.feeCourseList,
    studentFeeCourseList: state => state.studentFeeCourseList,
    feesTypeAvailableListAll: state => state.feesTypeAvailableListAll,
    courseListAll: state => state.courseListAll,
    courseProgressAll: state => state.courseProgressAll,
    statusAll: state => state.statusAll,
    receiptsAll: state => state.receiptsAll,
    batchAll: state => state.batchAll,
    courseCategory: state => state.courseCategory,
    studentEditCourseData: state => state.studentEditCourseData,
    pay_credit:state => state.pay_credit,
    per_value:state => state.per_value,
    final_pay_credit:state => state.final_pay_credit,
}

const actions = {
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Course Category']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    commit('setCourseCatgory', data['Course Category'])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

                if (_.isEmpty(state.item.country_list)) {
                    params.set('country', '')
                } else {
                    params.set('country', state.item.country_list.id)
                }
                if (_.isEmpty(state.item.state_list)) {
                    params.set('state', '')
                } else {
                    params.set('state', state.item.state_list.id)
                } 
                if (_.isEmpty(state.item.city_list)) {
                    params.set('city', '')
                } else {
                    params.set('city', state.item.city_list.id)
                } 
                if (_.isEmpty(state.item.parent)) {
                    params.set('parent_mobile', '')
                } else {
                    params.set('parent_mobile', state.item.parent.other_mobile_no)
                }                           
                if (_.isEmpty(state.item.parent)) {
                    params.set('parent_name', '')
                } else {
                    params.set('parent_name', state.item.parent.mother_name)
                }
                if (_.isEmpty(state.item.parent)) {
                    params.set('parent_id', '')
                } else {
                    params.set('parent_id', state.item.parent.id)
                }

                if (_.isEmpty(state.item.school)) {
                    params.set('school_id', '')
                } else {
                    params.set('school_id', state.item.school.id)
                }
                if (_.isEmpty(state.item.standard_id)) {
                    params.set('standard', '')
                } else {
                    params.set('standard', state.item.standard_id.value_id)
                }
                if (_.isEmpty(state.item.reference)) {
                    params.set('reference_id', '')
                } else {
                    params.set('reference_id', state.item.reference.value_id)
                }                
                if (_.isEmpty(state.item.tshirt_size)) {
                    params.set('tshirt_size', '')
                } else {
                    params.set('tshirt_size', state.item.tshirt_size.value_id)
                }
                params.set('onlySummerGlobal', state.onlySummerGlobal)
            state.courseListAll.forEach(function(course, index){
                params.set('course['+index+'][inquiry_id]',state.item.id);
                params.set('course['+index+'][course_id]',course["course_id"]);    
                params.set('course['+index+'][course_start_date]',course["course_start_date"]);  
                params.set('course['+index+'][course_status]', (course["course_status"])?course["course_status"].value_id:'')
                params.set('course['+index+'][status_reason]', (course["status_reason"])?course["status_reason"].value_id:'')
                if(typeof course.fee_list !== 'undefined')
                {
                    course.fee_list.forEach(function(fee, feeIndex){
                        if(fee)
                        {
                            params.set('course['+index+'][fee]['+feeIndex+'][fees_amount]',fee["fees_amount"]);    
                            var discount = parseFloat((fee["discount"])?fee["discount"]:0);
                            discount = parseFloat((discount > 0)?discount:0);
                            var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                            
                            var gstRate = (fee["gst_rate"])?fee["gst_rate"]:0;
                            gstRate = parseFloat((gstRate)?parseFloat(gstRate)+100:0);
                            var gstAmount = (parseFloat(afterDiscount)*parseFloat(gstRate)/100);
                            gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))    
                            //var totalFees = (parseFloat(afterDiscount)+parseFloat(gstAmount));
                            var totalFees = parseFloat(afterDiscount);



                            params.set('course['+index+'][fee]['+feeIndex+'][discount]',discount); 
                            params.set('course['+index+'][fee]['+feeIndex+'][fees_after_discount]',afterDiscount);       
                            params.set('course['+index+'][fee]['+feeIndex+'][gst_amount]',gstAmount);    
                            // New code - end (AJ)
                            if(fee.fees_type && fee.fees_type.value_id){
                                params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee.fees_type.value_id);                                
                            }else{
                                params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee["fees_type"]);                            
                            }
                            params.set('course['+index+'][fee]['+feeIndex+'][total_fees]',totalFees); 
                            params.set('course['+index+'][fee]['+feeIndex+'][gst_rate]',fee["gst_rate"]); 

                            if(fee && fee["is_manual_fee"]) {
                                params.set('course['+index+'][fee]['+feeIndex+'][is_manual_fee]',fee["is_manual_fee"]);        
                            }  
                        }
                        
                    })
                }
            })
            axios.post('/api/v1/student', params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    //Apply Coupon Code
    applyCouponCode({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                params.set('student_id', data.id)
                state.courseListAll.forEach(function(course, index){
                    params.set('inquiry_id',state.item.id);
                    params.set('course_id',course["course_id"]);    
                    params.set('course_start_date',course["course_start_date"]);  
                    params.set('course_status', (course["course_status"])?course["course_status"].value_id:'')
                    params.set('status_reason', (course["status_reason"])?course["status_reason"].value_id:'')
                    if(typeof course.fee_list !== 'undefined')
                    {
                        course.fee_list.forEach(function(fee, feeIndex){
                            if(fee)
                            {
                                params.set('fee['+feeIndex+'][fees_amount]',fee["fees_amount"]);    
                                var discount = parseFloat((fee["discount"])?fee["discount"]:0);
                                discount = parseFloat((discount > 0)?discount:0);
                                var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                                
                                var gstRate = (fee["gst_rate"])?fee["gst_rate"]:0;
                                gstRate = parseFloat((gstRate)?parseFloat(gstRate)+100:0);
                                var gstAmount = (parseFloat(afterDiscount)*parseFloat(gstRate)/100);
                                gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))    
                                //var totalFees = (parseFloat(afterDiscount)+parseFloat(gstAmount));
                                var totalFees = parseFloat(afterDiscount);
    
    
    
                                params.set('fee['+feeIndex+'][discount]',discount); 
                                params.set('fee['+feeIndex+'][fees_after_discount]',afterDiscount);       
                                params.set('fee['+feeIndex+'][gst_amount]',gstAmount);    
                                // New code - end (AJ)
                                if(fee.fees_type && fee.fees_type.value_id){
                                    params.set('fee['+feeIndex+'][fees_type]',fee.fees_type.value_id);                                
                                }else{
                                    params.set('fee['+feeIndex+'][fees_type]',fee["fees_type"]);                            
                                }
                                params.set('fee['+feeIndex+'][total_fees]',totalFees); 
                                params.set('fee['+feeIndex+'][gst_rate]',fee["gst_rate"]); 
    
                                if(fee && fee["is_manual_fee"]) {
                                    params.set('fee['+feeIndex+'][is_manual_fee]',fee["is_manual_fee"]);        
                                }  
                            }
                            
                        })
                    }
                })
            
            /* If coupon code Apply */
            params.set('coupon_code','');  
            if(state.item.coupon_code){
                params.set('coupon_code',state.item.coupon_code);  
            }

            axiosLms.post('/api/v1/applyCouponCode', params)
            .then(response => {
                // commit('resetState')
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    //for course add
    storeCourseData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                params.set('student_id', data.id)
            
                params.set('onlySummerGlobal', state.onlySummerGlobal)
            state.courseListAll.forEach(function(course, index){
                params.set('inquiry_id',state.item.id);
                params.set('course_id',course["course_id"]);    
                params.set('course_start_date',course["course_start_date"]);  
                params.set('course_status', (course["course_status"])?course["course_status"].value_id:'')
                params.set('status_reason', (course["status_reason"])?course["status_reason"].value_id:'')
                if(typeof course.fee_list !== 'undefined')
                {
                    course.fee_list.forEach(function(fee, feeIndex){
                        if(fee)
                        {
                            params.set('fee['+feeIndex+'][fees_amount]',fee["fees_amount"]);    
                            var discount = parseFloat((fee["discount"])?fee["discount"]:0);
                            discount = parseFloat((discount > 0)?discount:0);
                            var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                            
                            var gstRate = (fee["gst_rate"])?fee["gst_rate"]:0;
                            gstRate = parseFloat((gstRate)?parseFloat(gstRate)+100:0);
                            var gstAmount = (parseFloat(afterDiscount)*parseFloat(gstRate)/100);
                            gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))    

                            //var totalFees = (parseFloat(afterDiscount)+parseFloat(gstAmount));
                            var totalFees = parseFloat(afterDiscount);
                            
                            if( state.item.couponPriceSave > 0 && fee.fee_type_details.id==state.item.couponFeeType ){
                                gstAmount = parseFloat((afterDiscount-state.item.couponPriceSave) - ((afterDiscount-state.item.couponPriceSave)*100/gstRate));
                            }

                            params.set('fee['+feeIndex+'][discount]',discount); 
                            params.set('fee['+feeIndex+'][fees_after_discount]',afterDiscount);       
                            params.set('fee['+feeIndex+'][gst_amount]',gstAmount);    
                            // New code - end (AJ)
                            if(fee.fees_type && fee.fees_type.value_id){
                                params.set('fee['+feeIndex+'][fees_type]',fee.fees_type.value_id);                                
                            }else{
                                params.set('fee['+feeIndex+'][fees_type]',fee["fees_type"]);                            
                            }
                            params.set('fee['+feeIndex+'][total_fees]',totalFees); 
                            params.set('fee['+feeIndex+'][gst_rate]',fee["gst_rate"]); 

                            if(fee && fee["is_manual_fee"]) {
                                params.set('fee['+feeIndex+'][is_manual_fee]',fee["is_manual_fee"]);        
                            }  
                        }
                        
                    })
                }

                if((data.fee_adjustments.length) != 0 )
                {
                   // console.log("fdfdf",data.fee_adjustments);
                   // return false
                    data.fee_adjustments.forEach(function(fee, feeIndex){
                       // console.log("loop",fee.fee_type);
                        // return false;
                        if(fee)
                        {
                            params.set('fee_adjustment['+feeIndex+'][fees_amount]',fee.amount);    
                            if(fee.fees_type && fee.fees_type.id){
                                params.set('fee_adjustment['+feeIndex+'][fees_type]',fee.fee_type.id);                                
                            }else{
                                params.set('fee_adjustment['+feeIndex+'][fees_type]',fee.fee_type.id);                            
                            }
                            params.set('fee_adjustment['+feeIndex+'][gst_rate]',fee.fee_type.other_value); 
                        }
                        
                    })
                }
            })
            // console.log("end");
            // return false;
            /* If coupon code Apply */
            params.set('coupon_code','');  
            if(state.item.coupon_code){
                params.set('coupon_code',state.item.coupon_code);  
            }
            /* If reffaral code Apply */
            params.set('refferal_code','');  
            if(state.item.refferal_code){
                params.set('refferal_code',state.item.refferal_code);  
            }
            params.set('pay_credit','0'); 
            if(state.item.final_pay_credit){
                params.set('pay_credit',state.item.final_pay_credit);  
            }
            params.set('paidUsingCredit',data.paid_using_credit);
            
            axiosLms.post('/api/v1/storeStudentCourseFee', params)
            .then(response => {
                // commit('resetState')
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    updateCourseData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                // params.set('student_id', data.id)
            
                // params.set('onlySummerGlobal', state.onlySummerGlobal)
                if((data.fee_adjustments.length) != 0 )
                {
                   // console.log("fdfdf",data.fee_adjustments);
                   // return false
                    data.fee_adjustments.forEach(function(fee, feeIndex){
                       // console.log("loop",fee.fee_type);
                        // return false;
                        if(fee)
                        {
                            params.set('fee_adjustment['+feeIndex+'][fees_amount]',fee.amount);    
                            if(fee.fees_type && fee.fees_type.id){
                                params.set('fee_adjustment['+feeIndex+'][fees_type]',fee.fee_type.id);                                
                            }else{
                                params.set('fee_adjustment['+feeIndex+'][fees_type]',fee.fee_type.id);                            
                            }
                            params.set('fee_adjustment['+feeIndex+'][gst_rate]',fee.fee_type.other_value); 
                        }
                        
                    })
                }
                else{
                    params.set('fee_adjustment',null);
                }
            axiosLms.post('/api/v1/updateStudentCourseFee/'+data.id, params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
                if (_.isEmpty(state.item.country_list)) {
                    params.set('country', '')
                } else {
                    params.set('country', state.item.country_list.id)
                }
                if (_.isEmpty(state.item.state_list)) {
                    params.set('state', '')
                } else {
                    params.set('state', state.item.state_list.id)
                }    
                if (_.isEmpty(state.item.city_list)) {
                    params.set('city', '')
                } else {
                    params.set('city', state.item.city_list.id)
                }                
                if (_.isEmpty(state.item.reference)) {
                    params.set('reference_id', '')
                } else {
                    params.set('reference_id', state.item.reference.value_id)
                }                
                if (_.isEmpty(state.item.parent)) {
                    params.set('parent_id', '')
                } else {
                    params.set('parent_id', state.item.parent.id)
                }

                if (_.isEmpty(state.item.school)) {
                    params.set('school_id', '')
                } else {
                    params.set('school_id', state.item.school.id)
                }
                if (_.isEmpty(state.item.standard_id)) {
                    params.set('standard', '')
                } else {
                    params.set('standard', state.item.standard_id.value_id)
                }
                if (_.isEmpty(state.item.tshirt_size)) {
                    params.set('tshirt_size', '')
                } else {
                    params.set('tshirt_size', state.item.tshirt_size.value_id)
                }
                params.set('onlySummerGlobal', state.onlySummerGlobal)

                // if(state.item && state.item.school) {
                //     params.set('school_id', state.item.school.id)
                // }
                // if(state.item && state.item.standard_id) {
                //     params.set('standard', state.item.standard_id.value_id)
                // }
                // if(state.item && state.item.tshirt_size) {
                //     params.set('tshirt_size', state.item.tshirt_size.value_id)    
                // }

            state.courseListAll.forEach(function(course, index){
                params.set('course['+index+'][inquiry_id]',state.item.id);
                params.set('course['+index+'][course_id]',course["course_id"]);    
                params.set('course['+index+'][course_start_date]',course["course_start_date"]);  
                params.set('course['+index+'][course_status]', (course["course_status"])?course["course_status"].value_id:'')
                params.set('course['+index+'][status_reason]', (course["status_reason"])?course["status_reason"].value_id:'')
                if(typeof course.fee_list !== 'undefined')
                {
                    course.fee_list.forEach(function(fee, feeIndex){
                        if(fee)
                        {
                            params.set('course['+index+'][fee]['+feeIndex+'][fees_amount]',fee["fees_amount"]);    
                            var discount = parseFloat((fee["discount"])?fee["discount"]:0);
                            discount = parseFloat((discount > 0)?discount:0);
                            var afterDiscount = (parseFloat(fee["fees_amount"]) - parseFloat(discount));
                            var gstRate = (fee["gst_rate"])?fee["gst_rate"]:0;
                            gstRate = parseFloat((gstRate)?parseFloat(gstRate)+100:0);
                            var gstAmount = (parseFloat(afterDiscount)*parseFloat(gstRate)/100);
                            gstAmount = parseFloat(afterDiscount - (afterDiscount*100/gstRate))    
                            //var totalFees = (parseFloat(afterDiscount)+parseFloat(gstAmount));
                            var totalFees = parseFloat(afterDiscount);
                            params.set('course['+index+'][fee]['+feeIndex+'][discount]',discount); 
                            params.set('course['+index+'][fee]['+feeIndex+'][fees_after_discount]',afterDiscount);       
                            params.set('course['+index+'][fee]['+feeIndex+'][gst_amount]',gstAmount);    
                            // New code - end (AJ)
                            if(fee.fees_type && fee.fees_type.value_id){
                                params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee.fees_type.value_id);                                
                            }else{
                                params.set('course['+index+'][fee]['+feeIndex+'][fees_type]',fee["fees_type"]);                            
                            }
                            params.set('course['+index+'][fee]['+feeIndex+'][total_fees]',totalFees); 
                            params.set('course['+index+'][fee]['+feeIndex+'][gst_rate]',fee["gst_rate"]); 

                            if(fee && fee["is_manual_fee"]) {
                                params.set('course['+index+'][fee]['+feeIndex+'][is_manual_fee]',fee["is_manual_fee"]);        
                            }  
                        } 
                    })
                }
            })
            axios.post('/api/v1/student/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    // async getCreditPoints({ commit, state, dispatch }, id,course_id) {
    async getCreditPoints({ commit, state, dispatch }, params) {
        await axiosLms.post('/api/v1/getCreditPoints/', params)
            .then(response => {
                console.log(response.data.data);
                commit('setCreditPoint', response.data.data.total_amt)
                commit('setFinalPoints', response.data.data.set_final_amt)
                commit('setPercentageValue', response.data.data.percentage_value)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // reject(error)
                    })
    },
    async fetchData({ commit, state, dispatch }, id) {
        let country_id = 99;
        let state_id = '';
        await axios.get('/api/v1/student/' + id)
            .then(response => {
                if(response.data.result){
                    country_id = response.data.data.country;
                    state_id = response.data.data.state
                    commit('setItem', response.data.data)
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // reject(error)
                    })
        dispatch('fetchCountrymastersAll')
        dispatch('fetchReferenceAll')
        // dispatch('fetchFeeAdditionalAdjustmentCharge')
        dispatch('fetchCountryStates',country_id)
        dispatch('fetchStateCities',state_id)
        dispatch('fetchParentAll')
        dispatch('fetchTshirtSizesAll')
        dispatch('fetchSchoolAll')
        dispatch('fetchStandardAll')
        // dispatch('fetchStudentStatusAll')
        // dispatch('fetchStudentReasonAll')
        // dispatch('fetchReasonAll')
        // dispatch('fetchCourseAll')
        // dispatch('fetchFeesTypeAvailableListAll')
        // dispatch('fetchCourseListAll',id)
        // dispatch('fetchReceiptsAll',id)
        // dispatch('fetchStudentBatch',{search_student_name : state.item.first_name +' '+ state.item.last_name + ' ' + state.item.enroll_no})
        // dispatch('fetchCourseProgressAll',id)
    },
    markingCourseAsCompleted({ commit, state, dispatch }, dataObj) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/markingCourseAsCompleted', dataObj)
                .then(response => {
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },       
    fetchCountrymastersAll({ commit }) {
        axios.get('/api/v1/country')
            .then(response => {
                commit('setCountrymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchFeeAdditionalAdjustmentCharge({ commit }) {
        axios.get('/api/v1/feeAdditionalAdjustmentCharge')
            .then(response => {
                commit('setFeeAdditionalAdjustmentCharge', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchComboFromCourseDetails({ commit }, obj) {
        await axios.get('/api/v1/courseComboDetails/'+obj)
            .then(response => {
                commit('setCourseComboDetailsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchIfBranchAdmissionFeesApplicable({ commit }, obj) {
        await axios.get('/api/v1/fetchIfBranchAdmissionFeesApplicable/'+obj)
            .then(response => {
                commit('setIfBranchAdmissionFeesApplicable', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStatemastersAll({ commit }) {
        axios.get('/api/v1/states')
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchCitymastersAll({ commit }) {
        axios.get('/api/v1/cities')
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchParentAll({ commit }) {
        await axios.get('/api/v1/parentList')
            .then(response => {
                //console.log("par",response.data.data);
                commit('setParentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchTshirtSizesAll({ commit,state }) {
        axios.get('/api/v1/tshirtSizesList')
            .then(response => {
                commit('setTshirtSizesAll', response.data.data)
                for (var i = 0; i < state.tshirtSizesAll.length; i++) {
                    if(state.tshirtSizesAll[i].value_id ==  state.item.tshirt_size) {
                        commit('setTshirt',state.tshirtSizesAll[i])
                    }
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })   
    },    
    async fetchSchoolAll({ commit }) {
        // axios.get('/api/v1/school-masters')
        await axios.get('/api/v1/branchWiseSchoolListing')
            .then(response => {
                commit('setSchoolAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStandardAll({ commit }) {
        axios.get('/api/v1/standardTypeList')
            .then(response => {
                commit('setStandardAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStudentStatusAll({ commit }) {
        axios.get('/api/v1/studentStatusList')
            .then(response => {
                commit('setStudentStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchStudentReasonAll({ commit }) {
        axios.get('/api/v1/studentReasonList')
            .then(response => {
                commit('setStudentReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    fetchReasonAll({ commit }) {
        axios.get('/api/v1/reasonTypeList')
            .then(response => {
                commit('setReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    },
    async fetchBranchCourse({ commit },data) {
       // console.log("jhere",data.enroll_date,data.course_category);
        let course_category = '';
        if(data.course_category){
            course_category = data.course_category.id;
        }
        //console.log("categro",course_category)
        // await axios.get('/api/v1/courses')
        await axios.post('/api/v1/branchCourseList',{'student_id':data.student_id,'course_enroll_date':data.enroll_date,'course_category_id':course_category})
            .then(response => {
                //console.log(response);
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    }, 
    async fetchCourseAll({ commit }) {
        // await axios.get('/api/v1/courses')
        await axios.get('/api/v1/allCoursesList')
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    }, 
    async fetchFeesTypeAvailableListAll({ commit },enroll_id) {
        let params = {};
        if(enroll_id){
            params = {  
                'enroll_id':enroll_id
            }
        }
        //console.log("dfdf",params);
        await axios.get('/api/v1/feesTypeAvailableList/',{params})
            .then(response => {
                commit('setFeesTypeAvailableListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchBatchAll({ commit, dispatch }, id) {
        axios.get('/api/v1/batchCourseList/'+ id)
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    //Fetching add course fees details
    async fetchFeeCourseList({ commit, dispatch }, obj) {
        var course_start_date = (obj.course_start_date).toString(),
        date = course_start_date.split('/'),
        newDateFormat = date[2] + "-" + date[1] + "-" + date[0];
        // await axios.get('/api/v1/feeCourseListStudent/'+ obj.id + '/' + newDateFormat)
        await axiosLms.post('/api/v1/CourseFeeListStudent/',{'course_id':obj.id,'enroll_date':obj.course_start_date})
        //axios.get('/api/v1/studentFeeCourseList/'+ id)
            .then(response => {
                commit('setFeeCourseList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    }, 
    //Fetching edit course fees details
    async fetchStudentFeeCourseList({ commit, dispatch }, id) {
        await axios.get('/api/v1/studentFeeCourseList/'+ id)
            .then(response => {
                commit('setFeeCourseList', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchCourseListAll({ commit, dispatch }, id) {
        axios.get('/api/v1/studentCourseList/'+ id)
            .then(response => {
                commit('setCourseListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchReceiptsAll({ commit, dispatch }, id) {
        axios.get('/api/v1/receipts', {params : {student_id:id}})
            .then(response => {
                commit('setReceiptsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    fetchCourseListAll({ commit, dispatch }, id) {
        axios.get('/api/v1/studentCourseList/'+ id)
            .then(response => {
                commit('setCourseListAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchCourseProgressAll({ commit, dispatch }, id) {
        await axios.get('/api/v1/studentCourseProgressList/'+ id)
            .then(response => {
                commit('setCourseProgressAll', response.data.data)
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            })     
    },    
    fetchStatusAll({ commit }) {
        axios.get('/api/v1/inqueryStatusList')
            .then(response => {
                commit('setStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchCountryStates({ commit }, country_id) {
        await axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchStateCities({ commit }, state_id) {
        await axios.get('/api/v1/stateCitiesList/'+state_id)
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
    },
    async fetchStudentBatch({ commit, state , dispatch},data_filter) {
        commit('setLoading', true)
        await axios.get('/api/v1/student-batch' ,{params:data_filter})
            .then(response => {
                commit('setStudentBatch', response.data.data)
            })
            .catch(function (error) {
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchReferenceAll({ commit, dispatch }) {
        axios.get('/api/v1/referenceTypeList')
            .then(response => {
                commit('setReferenceAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })       
    }, 
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/allBranches')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                reject(error)
            })      
    },  
    async fetchStudenCourseFeeEdit({commit},id){
        // console.log("sdsd",id);
        await axiosLms.get('/api/v1/studenCourseFeeEdit/'+id)
            .then(response => {
                if(response.data.result){
                    commit('setStudentEditCourseData', response.data.data)
                }
                
            })
    },    
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setIfBranchAdmissionFeesApplicable({ commit }, value) {
        commit('setIfBranchAdmissionFeesApplicable', value)
    },
    setEnrollDate({ commit }, value) {
        commit('setEnrollDate', value)
    },
    setFirst_name({ commit }, value) {
        commit('setFirst_name', value)
    },
    setMiddle_name({ commit }, value) {
        commit('setMiddle_name', value)
    },
    setReference({ commit }, value) {
        commit('setReference', value)
    },    
    setLast_name({ commit }, value) {
        commit('setLast_name', value)
    },
    setStudent_address({ commit }, value) {
        commit('setStudent_address', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCountry_list({ commit }, value) {
        commit('setCountry_list', value)
    },
    setState_list({ commit }, value) {
        commit('setState_list', value)
    },
    setCity_list({ commit }, value) {
        commit('setCity_list', value)
    },
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setPin_code({ commit }, value) {
        commit('setPin_code', value)
    },
    setOtherSchoolName({ commit }, value) {
        commit('setOtherSchoolName', value)
    },
    setBirth_date({ commit }, value) {
        commit('setBirth_date', value)
    }, 
    setStart_date({ commit }, value) {
        commit('setStart_date', value)
    },
    setParent({ commit }, value) {
        commit('setParent', value)
    },
    setTshirt({ commit }, value) {
        commit('setTshirt', value)
    },    
    setSchool({ commit }, value) {
        commit('setSchool', value)
    },
    setBranchMasterAll({ commit }, value) {
        commit('setBranchMasterAll', value)
    },
    setCreditPoint({ commit }, value) {
        commit('setCreditPoint', value)
    },
    setFinalPoints({ commit }, value) {
        commit('setFinalPoints', value)
    },
    setPercentageValue({ commit }, value) {
        commit('setPercentageValue', value)
    },
    setStandard_id({ commit }, value) {
        commit('setStandard_id', value)
    },
    setStudent_status({ commit }, value) {
        commit('setStudent_status', value)
    },
    setStudent_reason({ commit }, value) {
        commit('setStudent_reason', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setNext_followup_date({ commit }, value) {
        commit('setNext_followup_date', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setStatus_remarks({ commit }, value) {
        commit('setStatus_remarks', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setCourseListAll({ commit }, value) {
        commit('setCourseListAll', value)
    },
    setCourseProgressAll({ commit }, value) {
        commit('setCourseProgressAll', value)
    },
    setFeeCourseList({ commit }, value) {
        commit('setFeeCourseList', value)
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setCourseAll({ commit }, value) {
        commit('setCourseAll', value)
    },
    setBatch({ commit }, value) {
        commit('setBatch', value)
    },
    setFeesTypeAvailableListAll({ commit }, value) {
        commit('setFeesTypeAvailableListAll', value)
    },
    setStatemastersAll({ commit }, value) {
      commit('setStatemastersAll', value)
    },
    setCitymastersAll({ commit }, value) {
        commit('setCitymastersAll', value)
      },
    setProfile({ commit }, value) {
        commit('setProfile', value)
    },
    setOnlySummerCampGlobal({ commit }, value) {
        commit('setOnlySummerCampGlobal', value)
    },
}

const mutations = {
    setStudentEditCourseData(state,data){
        state.studentEditCourseData = data;
    },
    setCourseCatgory(state,course_category){
        state.courseCategory = course_category;
    },
    setItem(state, item) {
        if(item.school_id == 0){
            item.school = {"id":0,"school_name":"Other School","school_board":null,"country":null,"created_at":null,"updated_at":null,"deleted_at":null};
        }
        //console.log('item',item);
        state.item = item
    },  
    setCourseComboDetailsAll(state, value) {
        state.courseComboDetailsAll = value
    },    
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setCreditPoint(state, value){
        state.pay_credit = value;
    },
    setFinalPoints(state, value){
        state.item.final_pay_credit = value;
    },
    setPercentageValue(state, value){
        state.per_value = value;
    },
    setIfBranchAdmissionFeesApplicable(state, value) {
        state.branchAdmissionFeesApplicable = value
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setEnrollDate(state, value) {
        state.item.enroll_date = value
    },
    setReference(state, value) {
        state.item.reference = value
    },    
    setReferenceAll(state, value) {
        state.referenceAll = value
    },    
    setFirst_name(state, value) {
        state.item.first_name = value
    },
    setMiddle_name(state, value) {
        state.item.middle_name = value
    },
    setLast_name(state, value) {
        state.item.last_name = value
    },
    setStudent_address(state, value) {
        state.item.student_address = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCountry_list(state, value) {
        state.item.country_list = value
    },
    setState_list(state, value) {
        state.item.state_list = value
    },
    setCity_list(state, value) {
        state.item.city_list = value
    },
    setGender(state, value) {
        state.item.gender = value
    },
    setPin_code(state, value) {
        state.item.pin_code = value
    },
    setOtherSchoolName(state,value){
        state.item.other_school_name = value
    },
    setBirth_date(state, value) {
        state.item.birth_date = value
    },
    setStart_date(state, value) {
        state.item.start_date = value
    },
    setParent(state, value) {
        state.item.parent = value
    },
    setTshirt(state, value) {
        state.item.tshirt_size = value
    },    
    setSchool(state, value) {
        state.item.school = value
    },
    setStandard_id(state, value) {
        state.item.standard_id = value
    },
    setStudent_status(state, value) {
        state.item.student_status = value
    },
    setStudent_reason(state, value) {
        state.item.student_reason = value
    },   
    setStatus(state, value) {
        state.item.status = value
    },
    setNext_followup_date(state, value) {
        state.item.next_followup_date = value
    },
    setReason(state, value) {
        state.item.reason = value
    },
    setStatus_remarks(state, value) {
        state.item.status_remarks = value
    },
    setOnlySummerCampGlobal(state, value) {
        state.onlySummerGlobal = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setFeeAdditionalAdjustmentCharge(state, value) {
        state.feeAdditionalAdjustment = value
    },
    setStatemastersAll(state, value) {
        state.statemastersAll = value
    },
    setCitymastersAll(state, value) {
        state.citymastersAll = value
    },
    setParentAll(state, value) {
        state.parentAll = value
    },
    setTshirtSizesAll(state, value) {
        state.tshirtSizesAll = value
    },
    setSchoolAll(state, value) {
        value.push({id:0,school_name:"Other School",school_board:null,country:null,created_at:null,updated_at:null,deleted_at:null});
        state.schoolAll = value
    },
    setStandardAll(state, value) {
        state.standardAll = value
    },
    setStudentStatusAll(state, value) {
        state.studentStatusAll = value
    },
    setStudentReasonAll(state, value) {
        state.studentReasonAll = value
    },
    setReasonAll(state, value) {
        state.reasonAll = value
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setFeesTypeAvailableListAll(state, value) {
        state.feesTypeAvailableListAll = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setStatusAll(state, value) {
        state.statusAll = value
    },
    setCourseListAll(state, value) {
        state.courseListAll = value
    },
    setCourseProgressAll(state, value) {
        state.courseProgressAll = value
    },
    setFeeCourseList(state, value) {
        state.feeCourseList = value
    },
    setStudentFeeCourseList(state, value) {
        state.studentFeeCourseList = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setReceiptsAll(state, data) {
        state.receiptsAll = data
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setBatch(state, value) {
        state.item.batch = value
    },
    setStudentBatch(state, value) {
        state.batchAll = value
    },
    setProfile(state, value) {
        state.item.profile = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
