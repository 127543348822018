<template> 
    <div class="project-card">
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
                <div class="col-md-12">
                    <bootstrap-alert />
                </div>

                <div class="form-group col-md-12 col-xs-12">
                    <label for="header_name">Header Name<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="header_name"
                        ref="header_name"
                        maxlength="150"
                        v-validate="'required'"
                        placeholder="Enter Header Name"
                        v-model="form_data.header_name"
                    >
                    <span class="error" v-show="errors.has('header_name')">
                        {{ $root.updateErrorMessage('header_name',this.errors,'Header Name')}}
                    </span>                                            
                </div>                       
                <div class="col-md-12 col-md-12 savebtn">
                    <vue-button-spinner
                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                        :isLoading="loading"
                        :disabled="loading"
                    >
                        Save
                    </vue-button-spinner>
                </div> 
            </div>
        </form>
    </div>                 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
           form_data:{
               header_name:'',
           }
        }
    },
    computed: {
        ...mapGetters('SkillHeaderMasterSingle', ['loading','header_name'])
    },
    created() {
    },
    methods: {
        ...mapActions('SkillHeaderMasterSingle', ['storeData']),
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
                this.storeData(this.form_data)
                .then((res) => {
                    if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                        this.buttonLoading = false
                        return '';
                    }
                    this.$router.push({ name: 'skill-master.edit', params: {id: res.data.data.id}})
                    // this.$router.push({ name: 'skill-master.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
            } else {
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
      });
        }
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
</style>
