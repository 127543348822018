<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Approvals</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Approve or Reject</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body"> 
                            <div class="col-md-7 col-xs-12">                          
                                <div class="form-group">
                                    <label for="leave_type">Leave Type</label>
                                    <v-select
                                            name="leave_type"
                                            label="name"
                                            @input="updateLeave_type"
                                            :value="item.leave_type"
                                            :options="leaveTypeAll"
                                            disabled=""
                                            />
                                </div>
                            </div>
                           <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="leave_option">Leave Option</label>
                                    <v-select
                                            name="leave_option"
                                            label="name"
                                            v-validate="'required'"
                                            id="leave_option"
                                            @input="updateLeave_option"
                                            :value="item.leave_option"
                                            :options="leaveOptionAll"
                                            :on-change="getLeaveOptionChnage"
                                            disabled=""
                                            />
                                            <span class="error" v-show="errors.has('leave_option')">
                                              {{ $root.updateErrorMessage('leave_option',this.errors)}}
                                            </span>
                                </div>
                            </div>
                            <div class="col-md-7 col-xs-12">
                                <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="leave_start_date">Leave From Date</label>
                                        <date-picker
                                                :value="item.leave_start_date"
                                                :config="$root.dpconfigDate"
                                                name="leave_start_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                id="leave_start_date"
                                                autocomplete="off"
                                                disabled=""
                                                placeholder="Enter Leave start date *"
                                                @dp-change="updateLeave_start_date"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_start_date')">
                                          {{ $root.updateErrorMessage('leave_start_date',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="leave_end_date">Leave Upto Date</label>
                                        <date-picker
                                                :value="item.leave_end_date"
                                                :config="$root.dpconfigDate"
                                                id="leave_end_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                disabled=""
                                                name="leave_end_date"
                                                placeholder="Enter Leave end date *"
                                                @dp-change="updateLeave_end_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_end_date')">
                                          {{ $root.updateErrorMessage('leave_end_date',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                              <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="days">Leave Days</label>
                                    <input
                                            type="number"
                                            class="form-control"
                                            name="days"
                                            v-validate="'required'"
                                            readonly=""
                                            disabled=""
                                            min="0"
                                            placeholder="Enter Leave Days"
                                            :value="getDaysDiffrentFromDate(item.leave_option)"
                                            @input="updateDays"
                                            >
                                </div>
                                <span class="error" v-show="errors.has('days')">
                                  {{ $root.updateErrorMessage('days',this.errors,'Leave Days')}}
                                </span>
                            </div>
                            
                            <div class="col-md-7 col-xs-12" v-if="seen">
                            <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label for="start_time">Start Time</label>
                                    <date-picker
                                            :value="item.start_time"
                                            :config="$root.dpconfigTime"
                                            disabled=""
                                            name="start_time"
                                            placeholder="Enter Start time"
                                            @dp-change="updateStart_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label for="end_time">End Time</label>
                                    <date-picker
                                            :value="item.end_time"
                                            :config="$root.dpconfigTime"
                                            disabled=""
                                            name="end_time"
                                            placeholder="Enter End time"
                                            @dp-change="updateEnd_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                </div>
                            </div>
                            </div>
                        </div>
                          <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="reason">Leave Reason</label>
                                    <textarea
                                            rows="3"
                                            class="form-control"
                                            name="reason"
                                            disabled=""
                                            placeholder="Enter Reason *"
                                            :value="item.reason"
                                            @input="updateReason"
                                            >
                                    </textarea>
                                </div>
                            </div> 
                             <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="reason">Remark</label>
                                        <input
                                            type="text"
                                            rows="3"
                                            maxlength="200"
                                            id="approved_remarks"
                                            class="form-control"
                                            name="approved_remarks"
                                            placeholder="Enter Remark"
                                            :value="item.approved_remarks"
                                            @input="updateApprovedRemarks"
                                            >
                                         
                                    <span class="error" v-if="errorSeen">
                                        Remark field is required.
                                    </span>
                                </div>
                            </div>                              
                            </div>  
                        </div>
                         <div class="col-md-7 col-xs-12 savebtn">
                         <div class="col-lg-12">
                            <div class="leave-action">
                                    <a
                                            class="std_information_save btn btn-block btn-danger new_branch_save"
                                            v-on:click="rejectForm(item.id)"
                                            name="approve"
                                            >
                                            Reject
                                    </a>
                                    <a
                                            class="std_information_save btn btn-block btn-success new_branch_save"
                                            v-on:click="approveForm(item.id)"
                                            name="approve"
                                            >
                                            Approve
                                    </a>
                                </div>                            
                            </div>                            
                        </div>   
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            seen:false,
            errorSeen:false,
            dateDisplay:false
        }
    },
    computed: {
        ...mapGetters('LeaveApprovalSingle', ['item', 'loading', 'leaveTypeAll', 'leaveOptionAll'])
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function(){
            if(this.item.leave_option.value_id != 2){
                 this.seen=true; 
            }else{
                this.seen=false; 
            }

        }
    },
    methods: {

        ...mapActions('LeaveApprovalSingle', ['fetchData', 'updateData', 'resetState', 'setBranch_id', 'setLeave_type', 'setLeave_start_date', 'setLeave_end_date', 'setLeave_option', 'setStart_time','setEnd_time', 'setLeave_status', 'setReason', 'setDays', 'setApprovedRemarks']),
        //get date diffrent from date
        getDaysDiffrentFromDate(value){
            var startDate = $('#leave_start_date').val();
            var endDate = $('#leave_end_date').val();

            if(startDate != '' && endDate !='' && value !=''){
                var start = moment(startDate, "YYYY-MM-DD");
                var end = moment(endDate, "YYYY-MM-DD");
                var dd = moment.duration(end.diff(start)).asDays();
                if(value && value.value_id === 2){
                    this.setDays(dd + 1);
                    return dd + 1;
                }else if(value && value.value_id === 1){
                    this.setDays(0.5);
                    return 0.5;
                }else if(value && value.value_id === 3){
                    //this.setLeave_start_date(this.getDate());
                    //this.setLeave_end_date(this.getDate());
                    this.setDays(0);
                    return 0;
                }else{                    
                    this.setDays(0);
                    return 0;
                }
            }else{
                this.setDays(0);
                return 0;
            }
           
        },
        getDate () {
          const toTwoDigits = num => num < 10 ? '0' + num : num;
          let today = new Date();
          let year = today.getFullYear();
          let month = toTwoDigits(today.getMonth() + 1);
          let day = toTwoDigits(today.getDate());
          return `${year}-${month}-${day}`;
        },
         getLeaveOptionChnage(value){
            if(value.value_id != 2){
                this.seen=true; 
            }else{
                this.seen=false; 
            }
            this.setLeave_option(value);
            if(value.value_id == 3){
                this.dateDisplay=true; 
                //$('#leave_start_date').val(this.getDate());
                //$('#leave_end_date').val(this.getDate());                              
            }else{
                console.log('call or not');
                $('#leave_start_date').val('');
                $('#leave_end_date').val('');
                this.dateDisplay=false;
            }           
        },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateLeave_type(value) {
            this.setLeave_type(value)
        },
        updateLeave_start_date(e) {
            this.setLeave_start_date(e.target.value)
        },
        updateLeave_end_date(e) {
            this.setLeave_end_date(e.target.value)
        },
        updateLeave_option(value) {
            this.setLeave_option(value)
        },
        updateStart_time(e) {
            this.setStart_time(e.target.value)
        },
        updateEnd_time(e) {
            this.setEnd_time(e.target.value)
        },
        updateLeave_status(value) {
            this.setLeave_status(value)
        },
        updateReason(e) {
            this.setReason(e.target.value)
        },
        updateDays(e) {
            this.setDays(e.target.value)
        },        
        updateApprovedRemarks(e) {
            this.setApprovedRemarks(e.target.value)
        },
        rejectForm(id) {
            var reason_value =  $('#approved_remarks').val();
            if(reason_value != ''){
                if(this.item.approved_remarks.trim() != ''){
                    this.errorSeen = false;
                }else{
                    this.errorSeen = true; 
                    return false;
                }
                

            }else{                
                this.errorSeen = true;
                return false;
            }
            this.$store.dispatch(
                        'LeaveApprovalIndex' + '/rejectData',id+'==='+reason_value
                    ).then(result => {
                        this.$router.push({ name: 'leave_approval.index' })
                        this.$eventHub.$emit('status-update')
                    })        
        },
        approveForm(id) {
            // console.log(id); return false;
            var reason_value =  $('#approved_remarks').val();
            this.$store.dispatch(
                'LeaveApprovalIndex' + '/approveData',
                id+'==='+reason_value
            ).then(result => {
                this.$router.push({ name: 'leave_approval.index' })
                this.$eventHub.$emit('status-update')
            }) 
        }
    }
}
</script>


<style scoped>

</style>
