<template>
    <div class="btn-group btn-group-xs">
         
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {}
}
</script>


<style scoped>

</style>
