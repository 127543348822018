<template>
    <div>
        <span class="fa fa-rupee">{{row.TotalBalance}}</span>
    </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

