function initialState() {
    return {
        item: [],
        is_agree: 0,
        loading: false
    }
}

const getters = {
    item: state => state.item,
    is_agree: state => state.is_agree,
    loading: state => state.loading
}

const actions = {
    storedata({ commit, state , dispatch}, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {
                is_terms_condition_accepted: 1,
            }

            axiosLms.post('/api/v1/updateTermsConditions/'+id, params)
                .then(response => {
                    commit('resetState')
                    resolve(response)
                    
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async getAdmissionForm({ commit }, id) {
        return new Promise((resolve, reject) => {   
            axiosLms.get('/api/v1/getStudentDetailsForPDF/'+id)
            .then(response => {
                if(response.data.result){
                    commit('setItem', response.data.data);
                }
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            })
        });
    },
    async getManualAdmissionForm({ commit }, id) {
        return new Promise((resolve, reject) => {   
            axiosLms.get('/api/v1/getStudentDetailsForMaualPDF/'+id)
            .then(response => {
                if(response.data.result){
                    commit('setItem', response.data.data);
                }                
                resolve(response);
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            })
        });
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, value) {
        state.item = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
