function initialState() {
    return {
        item: {
            id: null,
            course: null,
            fees_amount: null,
            is_gst_applicable: null,
            gst_rate: null,
            total_fees: null,
            branch: null,
            fees_type: null,
            start_date: null,
            main_course: null
        },
        coursesAll: [],
        feesAvailable: false,
        nextFeeStartDate:null,
        branchMastersAll: [],
        courseDetailedData: [],
        allCourseLevels: [],
        mainCoursesAll: [],
        feesTypeAll: [],
        loadingFeesAvailable: false,
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loadingFeesAvailable: state => state.loadingFeesAvailable,
    coursesAll: state => state.coursesAll,
    mainCoursesAll: state => state.mainCoursesAll,
    feesAvailable: state => state.feesAvailable,
    nextFeeStartDate: state => state.nextFeeStartDate,
    courseDetailedData: state => state.courseDetailedData,
    allCourseLevels: state => state.allCourseLevels,
    branchMastersAll: state => state.branchMastersAll,
    feesTypeAll: state => state.feesTypeAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
            }
            if (_.isEmpty(state.item.branch)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch.id)
            }
            if (_.isEmpty(state.item.fees_type)) {
                params.set('fees_type_id', '')
            } else {
                params.set('fees_type', state.item.fees_type.value_id)
            }

            axios.post('/api/v1/fees-masters', params)
                .then(response => {
                    // commit('resetState')
                    // resolve()
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
            }
            if (_.isEmpty(state.item.branch)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch.id)
            }
            if (_.isEmpty(state.item.fees_type)) {
                params.set('fees_type_id', '')
            } else {
                params.set('fees_type', state.item.fees_type.value_id)
            }

            axios.post('/api/v1/fees-masters/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateBunchData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {

            axios.post('/api/v1/updateBunchFeesData/' + state.item.course.main_course_id, finalData)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchEditData({ commit, dispatch }, id) {
        await axios.get('/api/v1/fees-masters-edit/' + id.id+'/' + id.from_date+'/' + id.fees_branch_id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchCoursesAll')
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    },
    async fetchShowData({ commit, dispatch }, id) {
        await axios.get('/api/v1/fees-masters-show/' + id.id+'/' + id.from_date+'/' + id.fees_branch_id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchCoursesAll')
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/fees-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchCoursesAll')
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    },
    fetchCoursesAll({ commit }) {
        // axios.get('/api/v1/courses')
        axios.get('/api/v1/allFeeCoursesList')
            .then(response => {
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    fetchMainCoursesAll({ commit }) {
        axios.get('/api/v1/mainCoursesAllForCourseFees')
            .then(response => {
                commit('setMainCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },    
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchIfFeesAvailable({ commit }, id) {
        commit('setLoadingFeesAvailable', true)        
        await axios.post('/api/v1/fetchIfFeesAvailable/' + id.id+'/'+id.branch_id)
            .then(response => {
                commit('setFeesAvailable', response.data.data['flag'])
                commit('setNextFeeStartDate', response.data.data['next_start_date'])
                if(response.data.data['next_start_date'] != ''){
                    commit('setStartDate', response.data.data['next_start_date'])    
                }else{
                    commit('setStartDate', moment().format('DD/MM/YYYY'))    
                    commit('setNextFeeStartDate', moment().format('DD/MM/YYYY'))
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoadingFeesAvailable', false)
            })
    },
    fetchFeesTypeAll({ commit }) {
        axios.get('/api/v1/feesTypeList')
            .then(response => {
                commit('setFeesTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchCourseDetailedData({ commit }, id) {
        await axios.get('/api/v1/fetchingCourseDetailedData/' + id.main_course_id +'/'+id.from_date+'/'+id.branch_id)
            .then(response => {
                commit('setCourseDetailedData', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchCoursesLevel({ commit }, id) {
        await axios.get('/api/v1/fetchCoursesLevel/' + id)
            .then(response => {
                commit('setAllCourseLevels', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setMainCourse({ commit }, value) {
        commit('setMainCourse', value)
    },    
    setFeesAvailable({ commit }, value) {
        commit('setFeesAvailable', value)
    },
    setNextFeeStartDate({ commit }, value) {
        commit('setNextFeeStartDate', value)
    },
    setFees_amount({ commit }, value) {
        commit('setFees_amount', value)
    },
    setIs_gst_applicable({ commit }, value) {
        commit('setIs_gst_applicable', value)
    },
    setGst_rate({ commit }, value) {
        commit('setGst_rate', value)
    },
    setStartDate({ commit }, value) {
        commit('setStartDate', value)
    },    
    setTotal_fees({ commit }, value) {
        commit('setTotal_fees', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setFees_type({ commit }, value) {
        commit('setFees_type', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setMainCourse(state, value) {
        state.item.main_course = value
    },
    setStartDate(state, value) {
        state.item.start_date = value
    },    
    setFees_amount(state, value) {
        state.item.fees_amount = value
    },
    setFeesAvailable(state, value) {
        state.feesAvailable = value
    },
    setNextFeeStartDate(state, value) {
        state.nextFeeStartDate = value
    },
    setIs_gst_applicable(state, value) {
        state.item.is_gst_applicable = value
    },
    setGst_rate(state, value) {
        state.item.gst_rate = value
    },
    setTotal_fees(state, value) {
        state.item.total_fees = value
    },
    setBranch(state, value) {
        state.item.branch = value
    },
    setFees_type(state, value) {
        state.item.fees_type = value
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },
    setMainCourseAll(state, value) {
        state.mainCoursesAll = value
    },
    setCourseDetailedData(state, value) {
        state.courseDetailedData = value
    },
    setAllCourseLevels(state, value) {
        state.allCourseLevels = value
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setFeesTypeAll(state, value) {
        state.feesTypeAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingFeesAvailable(state, loadingFeesAvailable) {
        state.loadingFeesAvailable = loadingFeesAvailable
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
