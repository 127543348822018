<template>
    <div class="btn-group btn-group-xs">
        <button
                v-if="xprops.route === 'leave_requests' && row.leave_status == 1 && row.new_upto_date == null"
                @click="cancelData(row.project_fee_id)"
                type="button"
                title="Cancel"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-close" aria-hidden="true"></i>
        </button>
         <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' "
                :to="{ name: xprops.route + '.show', params: { project_fee_id: row.project_fee_id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route === 'leave_requests' && row.leave_status.value_id == 1 && row.new_upto_date == null"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id, project_fee_id:row.project_fee_id } }"
                class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route !== 'leave_requests' && row.new_upto_date == null"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id, project_fee_id:row.project_fee_id } }"
                class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                v-if="$can(xprops.permission_prefix + 'delete') && xprops.route !== 'leave_requests' && row.new_upto_date == null"
                @click="destroyDataaaa(row.id,row.project_fee_id)"
                type="button"
                title="Delete"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...        
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
    },
    methods: {
        destroyDataaaa(id, project_fee_id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(project_fee_id)
                    .then((res) => {
                        console.log('then', res)
                        if(res.data.result){
                            // console.log('then',response)
                            //this.$router.push('/roles');
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }else{

                            this.$eventHub.$emit('common',3,res.data.message)
                        }      
                    })
                    .catch(error => {
                        console.log('catch', error)
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        cancelData(id) {
            this.$swal({
                title: 'Are you sure want to cancel?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/cancelData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }

    }
}
</script>

<style scoped>

</style>
