<template>
    <div class="btn-group btn-group-xs">
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"                
                title="Edit Status"
                :to="'/student-prospect/'+ row.student_id + '/' + courseId + '/' + row.contact_name+ '/' + row.contact_no +'/' + row.student_concat_name + '/status'"
                >
                 <span class="" v-if="value !== null">
                    <span v-if="value == 0" class="badge bg-success">
                        Prospect Available
                    </span>
                    <span v-else-if="value == 1" class="badge bg-info">
                        Pending
                    </span>
                    <span v-else-if="value == 2" class="badge bg-primary">
                        Followup
                    </span>
                    <span v-else-if="value == 3" class="badge bg-danger">
                        Closed
                    </span>
                    <span v-else>
                        Activate Next Course
                    </span> 
                </span>
        </router-link>
        <div v-else>
             <span class="" v-if="value !== null">
                <span v-if="value == 0" class="badge bg-success">
                    Prospect Available
                </span>
                <span v-else-if="value == 1" class="badge bg-info">
                    Pending
                </span>
                <span v-else-if="value == 2" class="badge bg-primary">
                    Followup
                </span>
                <span v-else-if="value == 3" class="badge bg-danger">
                    Closed
                </span>
                <!-- <span v-else="value == 4">
                    Activate Next Course
                </span>  -->
            </span>        
        </div>                
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            recordData: '',
            courseId: '',
        }
    },
    created() {
        this.courseId = '';
       // console.log("fdgfdg",this.row);
        if ('last_completed_course_id' in this.row) {
            this.courseId = this.row.last_completed_course_id
        } else {
            this.courseId = this.row.course_id
        }
    },
    updated(){
       // console.log("updated")
        if ('last_completed_course_id' in this.row) {
            this.courseId = this.row.last_completed_course_id
        } else {
            this.courseId = this.row.course_id
        }
    },
    methods: {       
        classMethod(id){
            if(id == 1)
            {
                return 'badge bg-info';
            }
            else if(id == 2)
            {
                return 'badge bg-primary';
            }else if(id == 3)
            {
                return 'badge bg-danger';
            }else if(id == 0)
            {
                return 'badge bg-success';
            }
        }
        
    }
}
</script>


<style scoped>

</style>
