<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Review Student Assessment</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title col-md-7 col-xs-12 no-padding">Student Assessment Details</h3>
                            <div class="text-left pull-right col-md-5 col-xs-12 no-padding">
                                <div class="col-md-12 col-xs-12 no-padding">
                                    <div class="col-md-3 no-padding student-assessment-specifications-text">Specifications:</div>
                                    <div class="col-md-3 no-padding student-assessment-inprogress-text">
                                        <div class="Color_Box_Div inProgressColor"></div>
                                        <span>In Progress</span>
                                    </div>
                                    <div class="col-md-3 no-padding student-assessment-session-text">
                                        <div class="Color_Box_Div sessionExtendedColor"></div>
                                        <span>Session Extended</span>
                                    </div>
                                    <div class="col-md-3 no-padding student-assessment-completed-text">
                                        <div class="Color_Box_Div completedColor"></div>
                                        <span>Completed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="box-body">
                            <button type="button" class="btn btn-default btn-sm" @click="fetchData($route.params.id)">
                            <i :class="{'fa-spin': loading, 'fa fa-refresh': true}"></i> Refresh
                            </button>                            
                            <back-buttton></back-buttton>
                        </div>
                        <div class="box-body" v-if="item.detail">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Selected Date</th>
                                        <th>Name</th>
                                        <th>Course</th>
                                        <th>Topic</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="Selected Date">{{item.detail.selected_date}}</td>
                                        <td class="Name">{{item.detail.student_name}}</td>
                                        <td class="Course course-wrap" :title="item.detail.course_name"><span class="my_course_name">{{item.detail.course_name}}</span></td>
                                        <td class="Topic">
                                            <div v-for="(value, index) in item.detail.my_topic_name">
                                                <span class="label label-primary" title="Topic In Progress" v-show="item.detail.my_topic_status[index] == 0">
                                                {{value}}
                                                </span>
                                                <span class="label label-warning" title="Topic Extended" v-show="item.detail.my_topic_status[index] == 2">
                                                {{value}}
                                                </span>
                                                <span class="label label-success" title="Topic Completed" v-show="item.detail.my_topic_status[index] == 1">
                                                {{value}}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <router-link
                                                    v-if="$can('student_batch_listing_access')"
                                                    :to="{ path: '/student-batch-listing?student_id='+item.detail.student_id+'&course_id='+item.detail.course_id, params:{ student_id: item.detail.student_id, course_id: item.detail.course_id } }"
                                                    class="btn btn-sm btn-primary"
                                                    target="_blank"
                                                    title="View Schedule Details"
                                                    >
                                                <i class="fa fa-calendar"></i>
                                            </router-link>
                                            <router-link
                                                    v-if="$can('progress_report_access')"
                                                    :to="{ path: '/progress-report?student_id='+item.detail.student_id+'&course_id='+item.detail.course_id, params:{ student_id: item.detail.student_id, course_id: item.detail.course_id } }"
                                                    class="btn btn-sm btn-primary"
                                                    target="_blank"
                                                    title="View Progress Report"
                                                    >
                                                <i class="fa fa-line-chart"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="item && item.detail && item.detail.studentTopicStatusIndex">
                        <div v-for="(outerValue, outerIndex) in item.detail.studentTopicStatusIndex">
                            <div v-if="item && item.detail && item.detail.isAssessmentRequired[outerIndex] == 1">
                                <div v-if="item && item.questioList && !isEmpty(item.questioList) && item.questioList.hasOwnProperty(computedOuterValue(outerValue))">
                                    <div class="question_ans">
                                        <div class="box box-info collapsed-box">
                                            <div class="box-header with-border box-custom-header" 
                                                data-widget="collapse" >
                                                <h3 class="box-title fa fa-plus">
                                                    Course Topic: 
                                                    <strong>{{item.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                                                </h3>
                                                <div class="box-tools pull-right">
                                                    <span class="label label-success" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 1)">
                                                    Topic Completed
                                                    </span>
                                                    <span class="label label-warning" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 2)">
                                                    Topic Extended
                                                    </span>
                                                    <span class="label label-primary" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0)">
                                                    Topic In Progress
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <button type="button" class="btn btn-default btn-sm" @click="fetchData($route.params.id)">
                                                <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                                </button>                                      
                                                <back-buttton></back-buttton>
                                            </div>
                                            <div class="box-body">
                                                <div class="left-30" v-for="(topicAssessmentDetail,keys,topicIndex) in item.questioList[computedOuterValue(outerValue)]">
                                                    <h4>{{++topicIndex}}. {{keys}}</h4>
                                                    <ul>
                                                        <li class="row" v-for="(assessment,sub_keys) in topicAssessmentDetail">
                                                            <span class="col-md-7 col-xs-12">Q{{sub_keys+1}}. {{assessment.my_question_name}}</span> 
                                                            <span class="col-md-5 col-xs-12">
                                                                <div class="res-icon">
                                                                    <a href="javascript:void(0)" :class="(assessment.my_answer_name == 1)? 'active':''" @click="getQuestionAnswer(assessment,1,computedOuterValue(outerValue))"><img src="/assets/images/happy.png" alt="happy"></a>
                                                                    <a href="javascript:void(0)" :class="(assessment.my_answer_name == 2)? 'active':''"  @click="getQuestionAnswer(assessment,2,computedOuterValue(outerValue))"><img src="/assets/images/Partial.png" alt="Partial"></a>
                                                                    <a href="javascript:void(0)" :class="(assessment.my_answer_name == 3)? 'active':''" @click="getQuestionAnswer(assessment,3,computedOuterValue(outerValue))"><img src="/assets/images/sad.png" alt="sad"></a>
                                                                </div>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-if ="item.detail && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0">
                                                    <div class="row">
                                                        <div class="col-md-10 col-md-offset-1">
                                                            <label for="assessment_remark">Status Remark:</label>
                                                            <textarea
                                                                rows="2"
                                                                class="form-control"
                                                                v-validate="'max:1000'"
                                                                name="assessment_remark"
                                                                placeholder="Enter Status Remark"
                                                                v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                                v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                                >
                                                            </textarea>                        
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 text-center">
                                                            <div class="btn savebtn" 
                                                                style="text-align:center; 
                                                                display: inline-block;">
                                                                <button 
                                                                    type="button"
                                                                    style="background-color: #44946f; !important;" 
                                                                    class="btn btn-success add_course_btn std_information_save"
                                                                    @click="sessionCompleted(computedOuterValue(outerValue), 1)">
                                                                Mark as Completed
                                                                </button>
                                                            </div>
                                                            <div class="savebtn" style="text-align:center; display: inline-block;">
                                                                <button 
                                                                    type="button"
                                                                    style="background-color: #cc8619 !important; border-color: #b17a22 !important;" 
                                                                    class="btn btn-warning add_course_btn std_information_save"
                                                                    @click="sessionCompleted(computedOuterValue(outerValue), 2)">
                                                                Mark as Extended
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="row">
                                                        <div class="col-md-10 col-md-offset-1">
                                                            <label for="assessment_remark">Status Remark:</label>
                                                            <textarea
                                                                rows="2"
                                                                class="form-control"
                                                                v-validate="'max:1000'"
                                                                name="assessment_remark"
                                                                placeholder="Enter Status Remark"
                                                                v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                                v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                                >
                                                            </textarea>                        
                                                        </div>
                                                    </div>
                                                    <br>                              
                                                    <div class="row">
                                                        <div class="col-md-7 col-xs-12 text-center">
                                                            <div style="float: right; 
                                                                font-weight: bold;
                                                                font-size: 1.3em;
                                                                border: 1px solid #d2d6de;
                                                                padding: 10px;">
                                                                <span class="label label-primary" 
                                                                    style="
                                                                    background-color: #146741 !important;
                                                                    padding-top: 0.4em;
                                                                    padding-right: 0.6em;
                                                                    padding-bottom: 0.3em;
                                                                    padding-left: 0.6em;">
                                                                {{item.detail.my_topic_status[computedOuterValue(outerValue)] == 1 ? 'Topic Completed'  : 'Topic Extended'}}
                                                                </span>         
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center" v-else>
                                    <div class="box">
                                        <div class="box-header with-border">
                                                <h3 class="box-title fa fa-plus">
                                                    Course Topic: 
                                                    <strong>{{item.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                                                </h3>
                                        </div>
                                        <div class="box-body">
                                            <h3>&#9785;</h3>
                                            <h4>This topic is marked as assessment required and no assessment questions are added!</h4>
                                            <h5>Please add assessment question for this topic first.</h5>
                                        </div>                                    
                                    </div>                                    
                                </div>
                            </div>
                            <div v-else-if="item && item.detail && item.detail.isAssessmentRequired[outerIndex] == 0 && item.detail.my_topic_id && item.detail.my_topic_id[computedOuterValue(outerValue)] > 0">
                                <div v-if="item && item.detail && item.detail.my_topic_name">
                                    <div class="box box-info collapsed-box">
                                        <div class="box-header with-border box-custom-header" 
                                            data-widget="collapse" >
                                            <h3 class="box-title fa fa-plus">
                                                Course Topic: 
                                                <strong>{{item.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>                                        
                                            </h3>
                                            <div class="box-tools pull-right">
                                                <span class="label label-success" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 1)">
                                                Topic Completed
                                                </span>
                                                <span class="label label-warning" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 2)">
                                                Topic Extended
                                                </span>
                                                <span class="label label-primary" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0)">
                                                Topic In Progress
                                                </span>                                        
                                            </div>
                                        </div>
                                        <div class="box-body">
                                            <button type="button" class="btn btn-default btn-sm" @click="fetchData($route.params.id)">
                                            <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                            </button>                                      
                                            <back-buttton></back-buttton>
                                        </div>
                                        <div class="box-body">
                                            <div v-if ="item.detail && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0">
                                                <div class="row">
                                                    <div class="col-md-10 col-md-offset-1">
                                                        <label for="assessment_remark">Status Remark:</label>
                                                        <textarea
                                                            rows="2"
                                                            class="form-control"
                                                            v-validate="'max:1000'"
                                                            name="assessment_remark"
                                                            placeholder="Enter Status Remark"
                                                            v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                            v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                            >
                                                        </textarea>                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 text-center">
                                                        <div class="btn savebtn" 
                                                            style="text-align:center; 
                                                            display: inline-block;">
                                                            <button 
                                                                type="button"
                                                                style="background-color: #44946f; !important;" 
                                                                class="btn btn-success add_course_btn std_information_save"
                                                                @click="sessionCompleted(computedOuterValue(outerValue), 1)">
                                                            Mark as Completed
                                                            </button>
                                                        </div>
                                                        <div class="savebtn" style="text-align:center; display: inline-block;">
                                                            <button 
                                                                type="button"
                                                                style="background-color: #cc8619 !important; border-color: #b17a22 !important;" 
                                                                class="btn btn-warning add_course_btn std_information_save"
                                                                @click="sessionCompleted(computedOuterValue(outerValue), 2)">
                                                            Mark as Extended
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row">
                                                    <div class="col-md-10 col-md-offset-1">
                                                        <label for="assessment_remark">Status Remark:</label>
                                                        <textarea
                                                            rows="2"
                                                            class="form-control"
                                                            v-validate="'max:1000'"
                                                            name="assessment_remark"
                                                            placeholder="Enter Status Remark"
                                                            v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                            v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                            >
                                                        </textarea>                        
                                                    </div>
                                                </div>
                                                <br>                              
                                                <div class="row">
                                                    <div class="col-md-7 col-xs-12 text-center">
                                                        <div style="float: right; 
                                                            font-weight: bold;
                                                            font-size: 1.3em;
                                                            border: 1px solid #d2d6de;
                                                            padding: 10px;">
                                                            <span class="label label-primary" 
                                                                style="
                                                                background-color: #146741 !important;
                                                                padding-top: 0.4em;
                                                                padding-right: 0.6em;
                                                                padding-bottom: 0.3em;
                                                                padding-left: 0.6em;">
                                                            {{item.detail.my_topic_status[computedOuterValue(outerValue)] == 1 ? 'Topic Completed'  : 'Topic Extended'}}
                                                            </span>         
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="item && item.detail && item.detail.my_topic_name">
                                    <div class="box box-info collapsed-box">
                                        <div class="box-header with-border box-custom-header" 
                                            data-widget="collapse" >
                                            <h3 class="box-title fa fa-plus">
                                                Course Topic: 
                                                <strong>{{item.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                                            </h3>
                                            <div class="box-tools pull-right">
                                                <span class="label label-success" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 1)">
                                                Topic Completed
                                                </span>
                                                <span class="label label-warning" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 2)">
                                                Topic Extended
                                                </span>
                                                <span class="label label-primary" v-if="(item.detail && item.detail.my_topic_status && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0)">
                                                Topic In Progress
                                                </span>                                        
                                            </div>
                                        </div>
                                        <div class="box-body">
                                            <button type="button" class="btn btn-default btn-sm" @click="fetchData($route.params.id)">
                                            <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                            </button>                                      
                                            <back-buttton></back-buttton>
                                        </div>
                                        <div class="box-body">
                                            <div v-if ="item.detail && item.detail.my_topic_status[computedOuterValue(outerValue)] == 0">
                                                <div class="row">
                                                    <div class="col-md-10 col-md-offset-1">
                                                        <label for="assessment_remark">Status Remark:</label>
                                                        <textarea
                                                            rows="2"
                                                            class="form-control"
                                                            v-validate="'max:1000'"
                                                            name="assessment_remark"
                                                            placeholder="Enter Status Remark"
                                                            v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                            v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                            >
                                                        </textarea>                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 text-center">
                                                        <div class="btn savebtn" 
                                                            style="text-align:center; 
                                                            display: inline-block;">
                                                            <button 
                                                                type="button"
                                                                style="background-color: #44946f; !important;" 
                                                                class="btn btn-success add_course_btn std_information_save"
                                                                @click="zeroQuestionTopicCompleted(outerValue, 1)">
                                                            Mark as Completed
                                                            </button>
                                                        </div>
                                                        <div class="savebtn" style="text-align:center; display: inline-block;">
                                                            <button 
                                                                type="button"
                                                                style="background-color: #cc8619 !important; border-color: #b17a22 !important;" 
                                                                class="btn btn-warning add_course_btn std_information_save"
                                                                @click="zeroQuestionTopicCompleted(outerValue, 2)">
                                                            Mark as Extended
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row">
                                                    <div class="col-md-10 col-md-offset-1">
                                                        <label for="assessment_remark">Status Remark:</label>
                                                        <textarea
                                                            rows="2"
                                                            class="form-control"
                                                            v-validate="'max:1000'"
                                                            name="assessment_remark"
                                                            placeholder="Enter Status Remark"
                                                            v-model="item.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                                            v-on:blur="createCategory(computedOuterValue(outerValue), item.detail.my_topic_status_remark[computedOuterValue(outerValue)])"
                                                            >
                                                        </textarea>                        
                                                    </div>
                                                </div>
                                                <br>                              
                                                <div class="row">
                                                    <div class="col-md-7 col-xs-12 text-center">
                                                        <div style="float: right; 
                                                            font-weight: bold;
                                                            font-size: 1.3em;
                                                            border: 1px solid #d2d6de;
                                                            padding: 10px;">
                                                            <span class="label label-primary" 
                                                                style="
                                                                background-color: #146741 !important;
                                                                padding-top: 0.4em;
                                                                padding-right: 0.6em;
                                                                padding-bottom: 0.3em;
                                                                padding-left: 0.6em;">
                                                            {{item.detail.my_topic_status[computedOuterValue(outerValue)] == 1 ? 'Topic Completed'  : 'Topic Extended'}}
                                                            </span>         
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div id="loader_placeholder" v-show="loadingShowData">
                <div id="loadingDiv">
                    <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                </div>
            </div>
        </section>
    </section>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    
    export default {
        data() {
            return {
                statusFlag: false,
                topicStatusUpdated: false,
            }
        },
        created() {
            this.fetchData(this.$route.params.id)              
        },
        updated(){
          
        },
        destroyed() {
            this.resetState()
        },
        computed: {
            ...mapGetters('StudentAssessmentSingle', ['item','loading','loadingShowData','courseListAll','assessmentRemark','isLastAttendance']),
        },
        watch: {
            "$route.params.id": function() {
                this.resetState()
                this.fetchData(this.$route.params.id)
            },
            "item":function(){
                if(this.item.detail && this.item.detail.selected_date){
                    this.setSelected_date(this.item.detail.selected_date);
                    this.setSelected_Detail({'id':this.item.detail.batch_id,/*'emp_id': this.item.detail.emp_id,*/'date':this.item.detail.selected_date.replace(/\//g, '-'),'lab_id': this.item.detail.lab_id});
                }
            },
        },
        methods: {
            ...mapActions('StudentAssessmentSingle', ['fetchData', 'storeAssessmentRemark','resetState','setStudentId','setCourseId','setTopicId','setCourseAssessmentId','setAnswer','setcourseListAll','updateData','setSelected_date','setSelected_Detail','setTopicStatus','updateStatus','updateStatusZeroQuestion','setFully','setPartial','setNeedImprovement','setAssessmentRemark','markingCourseAsCompleted']),     
            computedOuterValue(outerValue) {
                return "'"+outerValue+"'";
            },
            isEmpty(obj) {
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop))
                        return false;
                }
                return true;
            },            
            getQuestionAnswer(obj,answer,studentTopicStatusId){
                if(this.item.detail && this.item.detail.my_topic_status[studentTopicStatusId] == 0)
                {
                    this.updateStudentId(obj.my_student_id);
                    this.updateCourseId(obj.my_course_id);
                    this.updateFully(obj.fully);
                    this.updatePartial(obj.partial);
                    this.updateNeedImprovement(obj.need_improvement);
                    this.updateTopicId(obj.my_topic_id);
                    this.updateCourseAssessmentId(obj.my_course_assessment_id);
                    this.updateAnswer(answer);
                    this.updateData();
                }
            },
           createCategory(studentTopicStatusId, topicStatusRemark) {
                var dataObj = {
                    studentTopicStatusId: studentTopicStatusId,
                    topicStatusRemark: topicStatusRemark
                }
                this.storeAssessmentRemark(dataObj);
           },
            updateStudentId(value) {
                this.setStudentId(value);
            },
            updateCourseId(value) {
                this.setCourseId(value);
            },
            updateTopicId(value) {
                this.setTopicId(value);
            },
            updateFully(value) {
                this.setFully(value);
            },
            updatePartial(value) {
                this.setPartial(value);
            },
            updateNeedImprovement(value) {
                this.setNeedImprovement(value);
            },
            updateCourseAssessmentId(value) {
                this.setCourseAssessmentId(value);
            },
            updateAnswer(value) {
                this.setAnswer(value);
            },
            updateSelectedDate(value) {
                this.setSelected_date(value);
            },
            updateSelectedDetails(value) {
                //{'id':shedule.batch_id,'date':(item.schedule_date})}
                this.setSelected_Detail(value);
            },
            sessionCompleted(topicId, statusToBeMarked)
            {  
                this.statusFlag = false;
                        _.each(this.item.questioList, function(value , key) {
                            if(key == topicId) {
                                _.each(value, function(topic, topicKey) {
                                    if(topic[0].my_topic_id = topicId) {
                                        topic.forEach(function(question){
                                            if(parseFloat(question.my_answer_name) <= 0)
                                            {
                                                this.statusFlag = true;
                                            }
                                        }.bind(this))
                                    }
                                }.bind(this))
                           }
                        }.bind(this));
                        if(!this.statusFlag && statusToBeMarked == 1)
                        {
                            this.$swal({
                                title: 'Are you sure you want to complete this assessment?',
                                text: 'You won\'t be able to revert this!',
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                confirmButtonColor: '#dd4b39',
                                focusCancel: true,
                                reverseButtons: true
                            }).then(result => {
                                if (result.value) {
                                    var dataObj = {
                                        topicId: topicId,
                                        statusToBeMarked: statusToBeMarked
                                    }
                                    this.updateStatus(dataObj)
                                        .then(()=>{
                                            this.$eventHub.$emit('update-success')
                                            this.fetchData(this.$route.params.id)                                                 
                                            if(this.item.detail.current_course_status != 4 && this.isLastAttendance == 1 && dataObj.statusToBeMarked != 2) {
                                                this.$swal({
                                                    title: 'Do you want to mark course as completed?',
                                                    text: 'You won\'t be able to revert this!',
                                                    type: 'success',
                                                    showCancelButton: true,
                                                    confirmButtonText:'<i class="fa fa-thumbs-up"></i> Complete',
                                                    confirmButtonColor: '#49ab4c',
                                                    focusConfirm: true,
                                                    reverseButtons: true,
                                                    showCloseButton: true,      
                                                    customClass: 'widthPopup',
                                                    allowOutsideClick: false,
                                                    allowEscapeKey: false,
                                                    allowEnterKey: false,
                                                    footer: 'It was the last topic for this course!'
                                                }).then(result=>{
                                                    if(result.value) {
                                                            //CODE FOR MARKING COURSE AS COMPLETED
                                                            this.markingCourseAsCompleted()
                                                            const Toast = this.$swal.mixin({
                                                              toast: true,
                                                              position: 'top-end',
                                                              showConfirmButton: false,
                                                              timer: 3000
                                                            });
                                                            this.$eventHub.$emit('mark-as-comp')
                                                            this.fetchData(this.$route.params.id) 
                                                    }
                                                })
                                                return;
                                            }                                      
                                        })
                                    // this.topicStatusUpdated = true;
                                }
                            })
                        } else if (statusToBeMarked == 2){
                            this.$swal({
                                title: 'Are you sure you want to mark this topic as extend?',
                                text: 'You won\'t be able to revert this!',
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                confirmButtonColor: '#dd4b39',
                                focusCancel: true,
                                reverseButtons: true
                            }).then(result => {
                                if (result.value) {
                                    var dataObj = {
                                        topicId: topicId,
                                        statusToBeMarked: statusToBeMarked
                                    }
                                    this.updateStatus(dataObj)
                                        .then(()=>{
                                            this.$eventHub.$emit('update-success')
                                            this.fetchData(this.$route.params.id)                                           
                                        })                            
                                }
                            })
                        }
                        else
                        {
                            this.$swal({
                                title: 'Please complete all the assessment first',
                                type: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#dd4b39',
                                reverseButtons: true
                            })
                        }
            },
            zeroQuestionTopicCompleted(studentTopicStatusId, statusToBeMarked) {
                if(statusToBeMarked == 1)
                {
                    this.$swal({
                        title: 'Are you sure you want to complete this assessment?',
                        text: 'You won\'t be able to revert this!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        confirmButtonColor: '#dd4b39',
                        focusCancel: true,
                        reverseButtons: true
                    }).then(result => {
                        if (result.value) {
                            var dataObj = {
                                studentTopicStatusTableIndex: studentTopicStatusId,
                                statusToBeMarked: statusToBeMarked
                            }
                            this.updateStatusZeroQuestion(dataObj)
                                .then(()=>{
                                    this.$eventHub.$emit('update-success')
                                    this.fetchData(this.$route.params.id)                                        
                                    if(this.item.detail.current_course_status != 4 && this.isLastAttendance == 1 && dataObj.statusToBeMarked != 2) {
                                        this.$swal({
                                            title: 'Do you want to mark course as completed?',
                                            text: 'You won\'t be able to revert this!',
                                            type: 'success',
                                            showCancelButton: true,
                                            confirmButtonText:'<i class="fa fa-thumbs-up"></i> Complete',
                                            confirmButtonColor: '#49ab4c',
                                            focusConfirm: true,
                                            reverseButtons: true,
                                            showCloseButton: true,      
                                            customClass: 'widthPopup',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            allowEnterKey: false,
                                            footer: 'It was the last topic for this course!'                                                 
                                        }).then(result=>{
                                                if(result.value) {
                                                    //CODE FOR MARKING COURSE AS COMPLETED
                                                    this.markingCourseAsCompleted()
                                                    this.$eventHub.$emit('mark-as-comp')
                                                    this.fetchData(this.$route.params.id) 
                                                }
                                        })
                                        return;
                                    }                                       
                                })
                            // this.topicStatusUpdated = true;
                        }
                    })
                } else if (statusToBeMarked == 2){
                    this.$swal({
                        title: 'Are you sure you want to mark this topic as extend?',
                        text: 'You won\'t be able to revert this!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        confirmButtonColor: '#dd4b39',
                        focusCancel: true,
                        reverseButtons: true
                    }).then(result => {
                        if (result.value) {
                            var dataObj = {
                                studentTopicStatusTableIndex: studentTopicStatusId,
                                statusToBeMarked: statusToBeMarked
                            }
                            this.updateStatusZeroQuestion(dataObj)
                                .then(()=>{
                                    this.$eventHub.$emit('update-success')
                                    this.fetchData(this.$route.params.id)                                           
                                })                            
                        }
                    })
                }
            },
        }
    }
</script>
<style scoped>
    .Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
    }
    .inProgressColor {
    background-color: #3c8dbc;
    }
    .sessionExtendedColor {
    background-color: #f39c12;
    }
    .completedColor {
    background-color: #00a65a;
    }
    .widthPopup {
    width: 45em !important;
    }
    .box-custom-header {
    cursor: pointer;
    }
    .box-custom-header:hover {
    background-color: #27666d29;
    }
    .myLabel {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    }
    .left-30{
    margin-left: 30px;
    }
    .fa-fw.fa-circle
    {
    vertical-align: middle;
    font-size: 12px;
    }
    #loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
    }
    .ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
    }
    img {
    vertical-align: middle;
    }
    img {
    border: 0;
    }
</style>