<template>
  <div class="popup-form add-country">
    <b-modal id="showFaqModel"
            hideFooter
             ref="modal"
             title="View Course FAQ"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @hide="handleCancel"
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" >
    
            <div  v-if="!loading">
                 <table class="table table-bordered table-striped not-responsive-grid">
                <tbody>
                <tr>
                    <th width="20%">Titile</th>
                    <td><span>{{ showData.title }}</span></td>
                </tr>
                <tr>
                    <th width="20%">Description</th>
                    <td><span>{{ showData.description }}</span></td>
                </tr>
                
                </tbody>
            </table>
            </div>
            <div class="card-body" v-else>
                <CustomLoader></CustomLoader>
            </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader,
    },
    props: ['showId'],
    created() {        
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('CourseFaqIndex', ['showData','loading']),
    },
    methods: {
        ...mapActions('CourseFaqIndex', ['fetchShowData']),
        handleCancel(evt){
            //console.log("cancel");
            //this.addShowDataAll();
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
           // this.setTabSelection('project')
            //console.log("sdsd",this.showId);
            
             this.fetchShowData(this.showId);
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    }
}
</script>
<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
span.parent_span {
    /* border-color: black; */
    border: 1px solid #dee2e6;
    display: block;
    margin: 5px 0;
    padding: 10px;
}
</style>