<template> 
   
    <form @submit.prevent="submitForm" novalidate>
        <div class="box col-md-12">
            <bootstrap-alert />
            <div class="box-body row">
                <div class="form-group col-md-6 col-xs-12">
                    <label for="branch_name">Branch Name<p class="astrisk">*</p></label>
                    <input
                            type="text"
                            class="form-control"
                            name="branch_name"
                            ref="branch_name"
                            v-validate="'required'"
                            maxlength="150"
                            placeholder="Enter Branch Name"
                            :value="item.branch_name"
                            @input="updateBranchName"
                            >
                    <span class="error" v-show="errors.has('branch_name')">
                        {{ $root.updateErrorMessage('branch_name',this.errors,'Branch Name')}}
                    </span>                                                  
                </div>
                <div class="form-group col-md-6 col-xs-12">
                    <label for="franchise">Franchise Name<p class="astrisk">*</p></label>
                    <v-select
                            name="franchise"
                            ref="franchise"
                            v-validate="'required'"
                            label="display_franchise_name"
                            @change="updateFranchiseId"
                            :value="item.franchise"
                            disabled=""
                            :options="franchiseAll"
                            />
                            <span class="error" v-show="errors.has('franchise')">
                            {{ $root.updateErrorMessage('franchise',this.errors, 'Franchise Name')}}
                            </span>
                </div>
                <div class="form-group col-md-8 col-xs-12">
                    <label for="branch_address">Branch Address<p class="astrisk">*</p></label>
                    <textarea
                            class="form-control"
                            name="branch_address"
                            ref="branch_address"
                            v-validate="'required'"
                            maxlength="200"
                            placeholder="Enter Branch Address"
                            :value="item.branch_address"
                            @input="updateBranchAddress"
                            ></textarea>
                            <span class="error" v-show="errors.has('branch_address')">
                                {{ $root.updateErrorMessage('branch_address',this.errors,'Branch Address')}}
                            </span>
                </div>

                <div class="form-group col-md-4 col-xs-12">
                    <label for="branch_name">Branch Area</label>
                    <input
                            type="text"
                            class="form-control"
                            name="area"
                            ref="area"
                            maxlength="90"
                            placeholder="Enter Branch Area"
                            :value="item.area"
                            @input="updateArea"
                            >
                            <span class="error" v-show="errors.has('area')">
                            {{ $root.updateErrorMessage('area',this.errors,'Branch Area')}}
                            </span>
                </div>

                <div class="clearfix"></div>
                <div ref="country_list" class="form-group col-md-4 col-xs-12">
                    <label for="country">Branch Country<p class="astrisk">*</p></label>
                    <v-select
                            name="country_list"
                            label="country_name"
                            v-validate="'required'"
                            placeholder="Select Country"
                            @change="updateCountry"
                            :value="item.country_list"
                            :options="countryAll"
                            />
                            <span class="error" v-show="errors.has('country_list')">
                            {{ $root.updateErrorMessage('country_list',this.errors,'Branch Country')}}
                            </span>
                            
                </div>
                
                <div ref="state_list" class="form-group col-md-4 col-xs-12">
                    <label for="state">Branch State<p class="astrisk">*</p></label>
                    <v-select
                            name="state_list"
                            label="state_name"
                            v-validate="'required'"
                            placeholder="Select State"
                            @change="updateState"
                            :value="item.state_list"
                            :options="stateAll"
                            />
                            <span class="error" v-show="errors.has('state_list')">
                            {{ $root.updateErrorMessage('state_list',this.errors,'Branch State')}}
                            </span>
                </div>
                <div class="form-group col-md-4 col-xs-12">
                    <label for="city">Branch City<p class="astrisk">*</p></label>
                     <v-select
                            name="city_list"
                            label="city_name"
                            v-validate="'required'"
                            placeholder="Select City"
                            @change="updateCity"
                            :value="item.city_list"
                            :options="cityAll"
                            />
                            <span class="error" v-show="errors.has('city_list')">
                            {{ $root.updateErrorMessage('city_list',this.errors,'Branch City')}}
                            </span>
                </div>
                <div class="form-group col-md-6 col-xs-12">
                    <label for="pincode">Branch PIN/ZIP Code</label>
                    <input
                            type="text"
                            class="form-control"
                            name="pincode"
                            ref="pincode"
                            maxlength="25"
                            placeholder="Enter Branch Pin/Zip Code"
                            :value="item.pincode"
                            @input="updatePincode"
                            >
                            <span class="error" v-show="errors.has('pincode')">
                            {{ $root.updateErrorMessage('pincode',this.errors,'Branch PIN/ZIP Code')}}
                            </span>
                </div>
                <div class="form-group col-md-6 col-xs-12">
                    <label for="branch_email">Branch Email</label>
                    <input
                            type="text"
                            class="form-control"
                            maxlength="200"
                            name="branch_email"
                            ref="branch_email"
                            v-validate="'required|email'"
                            placeholder="Enter Branch Email"
                            :value="item.branch_email"
                            @input="updateBranchEmail"
                            >
                            <span class="error" v-show="errors.has('branch_email')">
                            {{ $root.updateErrorMessage('branch_email',this.errors,'Branch Email')}}
                            </span>
                </div>
                <div class="form-group col-md-4 col-xs-12">
                    <label for="mobile_no">Branch Mobile Number<p class="astrisk">*</p></label>
                    <input
                        type="text"
                            min="0"
                            class="form-control"
                            name="mobile_no"
                            ref="mobile_no"
                            v-validate="'required|digits:10'"
                            maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Enter Branch Mobile Number"
                            :value="item.mobile_no"
                            @input="updateMobileNo"
                            >
                            <span class="error" v-show="errors.has('mobile_no')">
                            {{ $root.updateErrorMessage('mobile_no',this.errors,'Branch Mobile number')}}
                            </span>
                </div>
                <div class="form-group col-md-4 col-xs-12">
                    <label for="whatsapp_no">Whatsapp Number</label>
                    <label for="checkboxWp" style="font-weight: 400; cursor:pointer;">(Same as a Mobile Number?</label>
                        <input
                            id="checkboxWp"
                            type="checkbox"
                            @change="updateWPCheckbox">
                    <label style="font-weight: 400; cursor:pointer;">)</label>                                    
                    <input
                        type="text"
                            min="0"
                            class="form-control"
                            name="whatsapp_no"
                            ref="whatsapp_no"
                            v-validate="'digits:10'"
                            maxlength="10"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Enter Whatsapp Number"
                            :value="item.whatsapp_no"
                            @input="updateWhatsappNo"
                            >
                            <span class="error" v-show="errors.has('whatsapp_no')">
                            {{ $root.updateErrorMessage('whatsapp_no',this.errors,'Whatsapp number')}}
                            </span>
                </div>                                                                
                <div class="form-group col-md-4 col-xs-12">
                    <label for="phone_no">Branch Phone Number</label>
                    <input
                            type="text"
                            class="form-control"
                            name="phone_no"
                            ref="phone_no"
                            maxlength="25"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Enter Branch Phone Number"
                            :value="item.phone_no"
                            @input="updatePhoneNo"
                            >
                            <span class="error" v-show="errors.has('phone_no')">
                            {{ $root.updateErrorMessage('phone_no',this.errors, 'Branch Phone number')}}
                            </span>
                </div>
                <div class="form-group col-md-6 col-xs-12">
                    <label for="branch_gst_no">Branch GST Number</label>
                    <input
                            type="text"
                            class="form-control"
                            name="branch_gst_no"                                          
                            ref="branch_gst_no"                                          
                            v-validate="{ regex: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}/ }"
                            placeholder="Enter Branch GST Number"
                            :value="item.branch_gst_no"
                            @input="updateBranchGstNo"
                            >
                            <span class="error" v-show="errors.has('branch_gst_no')">
                            {{ $root.updateErrorMessage('branch_gst_no',this.errors,'Branch GST number')}}
                            </span>
                </div>
                <div class="form-group col-md-4 col-xs-12">
                    <label for="branch link">Branch Link<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="branch link"
                        ref="branch link"
                        v-validate="'required|url'"
                        placeholder="Enter Branch Link"
                        :value="item.branch_link"
                        @input="updateBranchLink"
                        >
                        <span class="error" v-show="errors.has('branch link')">
                            {{ $root.updateErrorMessage('branch link',this.errors,'Branch Link')}}
                        </span>
                </div>
                <!-- <div id="branch_pan_no_wrapper" class="form-group col-md-6 col-xs-12" style="display:none;">
                    <label for="branch_pan_no">Branch PAN Number</label>
                    <input
                            type="text"
                            class="form-control"
                            name="branch_pan_no"
                            ref="branch_pan_no"
                            placeholder="Enter Branch PAN Number"
                            v-validate="{ regex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/ }"
                            :value="item.branch_pan_no"
                            @input="updateBranchPanNo"
                            >
                            <span class="error" v-show="errors.has('branch_pan_no')">
                            {{ $root.updateErrorMessage('branch_pan_no',this.errors,'Branch PAN number')}}
                            </span>
                </div> -->
                <div class="form-group check col-md-12 col-xs-12 branch-create">
                    <div class="row">
                    <label for="branch_pan_no" class="m_left">Branch Working Days<p class="astrisk">*</p></label><br>
                        <div class="weekdays">
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                v-validate="'required|included:1,2,3,4,5,6,7'"
                                                :value="1"
                                                :checked="item.workingDay1 == true"
                                                @change="updateWorkingDay1"
                                                >
                                        Monday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="2"
                                                :checked="item.workingDay2 == true"
                                                @change="updateWorkingDay2"
                                                >
                                        Tuesday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group" >
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="3"
                                                :checked="item.workingDay3 == true"
                                                @change="updateWorkingDay3"
                                                >
                                        Wednesday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="4"
                                                :checked="item.workingDay4 == true"
                                                @change="updateWorkingDay4"
                                                >
                                        Thursday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="5"
                                                :checked="item.workingDay5 == true"
                                                @change="updateWorkingDay5"
                                                >
                                        Friday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="6"
                                                :checked="item.workingDay6 == true"
                                                @change="updateWorkingDay6"
                                                >
                                        Saturday
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input
                                                type="checkbox"
                                                name="workingDay"
                                                :value="7"
                                                :checked="item.workingDay7 == true"
                                                @change="updateWorkingDay7"
                                                >
                                        Sunday
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span id="err_workingDay" class="error" v-show="errors.has('workingDay')">
                        {{ $root.updateErrorMessage('workingDay',this.errors,'Branch Working Days')}}
                    </span>                                        
                </div>
                <!-- <div class="col-md-12 col-xs-12">
                    <div class="form-group">
                    <input 
                        id="automatic_batch_creation" 
                        name="automatic_batch_creation"
                        @change="updatedAutomaticBatchCreation" 
                        type="checkbox" >
                    <label class="checkbox-label" for="automatic_batch_creation" style="font-weight: 400; cursor:pointer;">&nbsp;Automatic Batch Creation</label>
                    </div>
                </div> -->
                <div class="col-md-12 col-xs-12">
                    <div class="form-group">
                    <input 
                        id="allow_online_courses" 
                        name="allow_online_courses"
                        @change="updatedAllowOnlineCourses" 
                        type="checkbox" >
                    <label class="checkbox-label" for="allow_online_courses" style="font-weight: 400; cursor:pointer;">&nbsp;Allow online courses</label>
                    </div>
                </div>                                  
            </div>

        </div>
        <!-- <div class="box col-md-12" v-if="item && item.franchise && item.franchise.franchise_type == 1">
            <div class="box-header with-border">
                <h3 class="box-title">Branch Licence Detail</h3>
            </div>

            
            <div class="box-body row">
            <div ref="licence_start_date" class="form-group col-md-6 col-xs-12">
                    <label for="licence_start_date">Licence Start Date<p class="astrisk">*</p></label>
                    <date-picker
                            :value="item.licence_start_date"
                            :config="licenceStartDateOptionFormate"
                            name="licence_start_date"
                            class="form-control"
                            v-validate="(item && item.franchise && item.franchise.franchise_type == 1) ? 'required' : ''"
                            placeholder="Select Licence Start Date"
                            @dp-change="updateLicenceStartDate"
                            >
                    </date-picker>
                            <span class="error" v-show="errors.has('licence_start_date')">
                            {{ $root.updateErrorMessage('licence_start_date',this.errors,'Licence Start Date')}}
                            </span>
                </div>
                
                <div ref="licence_end_date" class="form-group col-md-6 col-xs-12">
                    <label for="licence_end_date">Licence End Date<p class="astrisk">*</p></label>
                    <date-picker
                            :value="item.licence_end_date"
                            :config="licenceEndDateOptionFormate"
                            name="licence_end_date"
                            class = "form-control"
                            v-validate="(item && item.franchise && item.franchise.franchise_type == 1) ? 'required' : ''"
                            placeholder="Select Licence End Date"
                            @dp-change="updateLicenceEndDate"          
                            >
                    </date-picker>
                            <span class="error" v-show="errors.has('licence_end_date')">
                            {{ $root.updateErrorMessage('licence_end_date',this.errors,'Licence End Date')}}
                            </span>
                </div>
            </div>
        </div> -->
        <div class="col-md-12 col-xs-12">
            <vue-button-spinner
                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                    type="submit"
                    :isLoading="loading"
                    :disabled="loading"
                    >
                Save
            </vue-button-spinner>
        </div>
    </form>
                           
</template>

<script>
import { mapGetters, mapActions } from 'vuex'


export default {
    data() {
        return {
            count:0,
            count1:0,
            // automatic_batch_creation:1,
            exitDateOptionFormate:{format:window.date_format_moment},
            licenceStartDateOptionFormate:{format:window.date_format_moment,minDate:false},
            licenceEndDateOptionFormate:{format:window.date_format_moment,minDate:false},
        }
    },
    computed: {
        ...mapGetters('BranchsSingle', ['item', 'loading','franchiseAll','countryAll','stateAll','cityAll']),
      
    },
    created() {
        this.fetchData(this.$route.params.id)
            .then(()=>{
                if(this.item.automatic_batch_creation == 1) {
                    document.getElementById("automatic_batch_creation").checked = true;
                }
                if(this.item.allow_online_courses == 1) {
                    document.getElementById("allow_online_courses").checked = true;
                }
                if(this.item.mobile_no == this.item.whatsapp_no) {
                    document.getElementById("checkboxWp").checked = true;
                }                
            })        
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function () {
            if(this.item && this.item.licence_start_date != '' && this.item.licence_start_date != null ){    
                var date = this.item.licence_start_date;
                var newdate = date.split("/").reverse().join("-");
                this.licenceEndDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: newdate,
                        maxDate : false                    
                   };
            }
        },        
    },
    methods: {
        ...mapActions('BranchsSingle', ['fetchData', 'updateData', 'resetState', 'setBranchName','setFranchiseId','setMobileNo','setWhatsappNo', 'setBranchEmail','setBranchAddress', 'setArea', 'setCity', 'setState', 'setCountry', 'setPincode', 'setPhoneNo', 'setBranchGstNo', 'setBranchPanNo', 'setLicenceStartDate', 'setLicenceEndDate','setWorkingDay1','setWorkingDay2','setWorkingDay3','setWorkingDay4','setWorkingDay5','setWorkingDay6','setWorkingDay7', 'setBranchLink','fetchCountryStates','fetchStatesCities','setStateAll','setCityAll','setAutomaticBranchCreation', 'setAllowOnlineCourses']),
        updateBranchName(e) {
            this.setBranchName(e.target.value)
        },
        updateWPCheckbox(e){
            if(e.target.checked == true) {
                this.setWhatsappNo(this.item.mobile_no)
            } else {
                this.setWhatsappNo(null)
            }
        },         
        updatedAutomaticBatchCreation(e){
            if(e.target.checked == true) {
                this.setAutomaticBranchCreation(1)
            } else {
                this.setAutomaticBranchCreation(0)
            }
        },    
        updatedAllowOnlineCourses(e){
            if(e.target.checked == true) {
                this.setAllowOnlineCourses(1)
            } else {
                this.setAllowOnlineCourses(0)
            }
        },      
        updateFranchiseId(value) {
            this.setFranchiseId(value)
        },
        updateMobileNo(e) {
            this.setMobileNo(e.target.value)
        },
        updateWhatsappNo(e) {
            this.setWhatsappNo(e.target.value)
            if(e.target.value == this.item.mobile_no) {
              document.getElementById('checkboxWp').checked = true
            } else {
              document.getElementById('checkboxWp').checked = false
            }
        },               
        updateBranchEmail(e) {
            this.setBranchEmail(e.target.value)
        },
        updateBranchAddress(e) {
            this.setBranchAddress(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        // updateCity(e) {
        //     this.setCity(e.target.value)
        // },
        updateCity(value) {
            this.setCity(value)
        },
        
        updateState(value) {
             if(value !== null){
                    this.fetchStatesCities(value.id);
                 }else{
                	this.setCityAll([]);
                    this.setCity(null);
                }               
                if(this.count > 2) {
                    this.setCity(null);
                }
            this.count++;
            this.setState(value)
        },
        updateCountry(value) {
                if(value != null && value != ''){
                    this.fetchCountryStates(value.id);
                }else{
                	this.setStateAll([]);
                }
                if(this.count > 1) {
                    this.setState(null);
                    this.setCity(null);
                }
            this.count++;                
            this.setCountry(value)
        },
        updatePincode(e) {
            this.setPincode(e.target.value)
        },
        updatePhoneNo(e) {
            this.setPhoneNo(e.target.value)
        },
        updateBranchGstNo(e) {
            if(e) {
                this.setBranchGstNo(e.target.value)
            } else {
                this.setBranchGstNo(null)
            }
        },
        updateBranchPanNo(e) {
            this.setBranchPanNo(e.target.value)
        },
        updateLicenceStartDate(e) {
            if(e && e.target.value != ''){
                this.licenceEndDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };
            }
            var start = moment(e.target.value, "DD/MM/YYYY");
            var end = moment(this.item.licence_end_date, "DD/MM/YYYY");
            var difference = moment.duration(start.diff(end)).asDays();
            //if(this.count1 > 0){
            if(difference > 0){
                   this.setLicenceEndDate(null)
            }
            this.count1++;
            this.setLicenceStartDate(e.target.value)
        },
        updateLicenceEndDate(e) {
            this.setLicenceEndDate(e.target.value)            
        },
        // updateLicenceStartDate(value) {
        //     console.log(value);
        //     console.log('+++++++++++++++');
        //     // var year = value.getFullYear();
        //     // var month = value.getMonth()+1;
        //     // var day = value.getDate();
        //     // var finalStartDate = year +'-'+month+'-'+day +' 00:00:00';
        //     var finalStartDate = value;
        //     // var finalStartDate = value +' 00:00:00';
        //     this.setLicenceStartDate(finalStartDate)
        // },
        // updateLicenceEndDate(value) {
            
        //     // var year = value.getFullYear();
        //     // var month = value.getMonth()+1;
        //     // var day = value.getDate();
        //     // var finalEndDate = year +'-'+month+'-'+day +' 23:59:59';
        //     var finalEndDate = value;
        //     // var finalEndDate = value +' 23:59:59';
        //     this.setLicenceEndDate(finalEndDate)
        // },


        updateWorkingDay1(e) {
            this.setWorkingDay1(e.target.checked)
        },
        updateWorkingDay2(e) {
            this.setWorkingDay2(e.target.checked)
        },
        updateWorkingDay3(e) {
            this.setWorkingDay3(e.target.checked)
        },
        updateWorkingDay4(e) {
            this.setWorkingDay4(e.target.checked)
        },
        updateWorkingDay5(e) {
            this.setWorkingDay5(e.target.checked)
        },
        updateWorkingDay6(e) {
            this.setWorkingDay6(e.target.checked)
        },
        updateWorkingDay7(e) {
            this.setWorkingDay7(e.target.checked)
        },
        updateBranchLink(e) {
            this.setBranchLink(e.target.value)
        },
        submitForm() {
            
        this.$validator.validate().then(result => {
         if (result) {
            // console.log("jerere");
            this.updateData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // console.log("ke",key,value);
                            if( value !== null ) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                        }
                        /*this.$validator.errors.add({
                            id: 'branch_gst_no',
                            field: 'branch_gst_no',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });*/
                        return '';
                    }
                    this.$router.push({ name: 'branchs.index' })
                    this.$eventHub.$emit('update-success')
                    //This code Refreshes the page - urvi
                   
                    //setTimeout(function () { this.$router.push({ name: 'branchs.index' }) }.bind(this), 1000)
                })
                .catch((error) => {
                    console.error(error)
                })
            }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }            
          });
        }
    }
}
</script>


<style scoped>

</style>
