function initialState() {
    return {
        item: {
            branch_id: null,
            leave_type: null,
            leave_start_date: null,
            leave_end_date: null,
            days: null,
        }, 
        selectedEmployee: null,
        loading: false,
        leaveTypeAll: [],
        employeeAll: [],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    leaveTypeAll: state => state.leaveTypeAll,    
    employeeAll: state => state.employeeAll,    
    selectedEmployee: state => state.selectedEmployee,    
}

const actions = {
    storeDataIndividual({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            params.set('from_date', state.item.leave_start_date)
            params.set('to_date', state.item.leave_end_date)
            params.set('leave_type', state.item.leave_type.value_id)
            if (_.isEmpty(state.selectedEmployee)) {
                params.set('emp_id', '')
            } else {
                params.set('emp_id', state.selectedEmployee.id)
            }
            axios.post('/api/v1/leaveAllocationsIndividual', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            params.set('from_date', state.item.leave_start_date)
            params.set('to_date', state.item.leave_end_date)
            params.set('leave_type', state.item.leave_type.value_id)
            axios.post('/api/v1/leave-allocations', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/holidays/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    fetchLeaveTypeAll({ commit }) {
        axios.get('/api/v1/leaveTypeListWithOutLWP')
            .then(response => {
                commit('setLeaveTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },  
    fetchEmployeeAll({ commit }) {
        axios.get('/api/v1/fetchAllEmployee')
            .then(response => {
                commit('setEmployeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },        
    setLeave_type({ commit }, value) {
        commit('setLeave_type', value)
    },
    setLeave_start_date({ commit }, value) {
        commit('setLeave_start_date', value)
    },
    setSelectedEmployee({ commit }, value) {
        commit('setSelectedEmployee', value)
    },    
    setLeave_end_date({ commit }, value) {
        commit('setLeave_end_date', value)
    },
    setEmployeeAll({ commit }, value) {
        commit('setEmployeeAll', value)
    },
    setDays({ commit }, value) {
        commit('setDays', value)
    },        
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setLeave_type(state, value) {
        state.item.leave_type = value
    },
    setLeave_start_date(state, value) {
        state.item.leave_start_date = value
    },
    setLeave_end_date(state, value) {
        state.item.leave_end_date = value
    },
    setDays(state, value) {
        state.item.days = value
    },
    setLeaveTypeAll(state, value) {
        state.leaveTypeAll = value
    },
    setSelectedEmployee(state, value) {
        state.selectedEmployee = value
    },
    setEmployeeAll(state, value) {
        state.employeeAll = value
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
