<template>
  <div class="popup-form add-country">
    <b-modal id="addLicenseModel"
             ref="modal"
             title="Add License"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <form @submit.prevent="submitForm" id="addLicenseForm" novalidate>
                                <div class="row">
                                <div class="col-md-12">
                                    <bootstrap-alert />
                                </div>

                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="license_name">License Name<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="license_name"
                                            ref="license_name"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter License Name"
                                            @input="updateLicenseName"
                                            :value="licenseName"
                                            >
                                    <span class="error" v-show="errors.has('license_name')">
                                        {{ $root.updateErrorMessage('license_name',this.errors,'License Name')}}
                                    </span><br>
                                </div>  

                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <label for="listOption" style="vertical-align:middle;">
                                            Select List Option
                                            <p class="astrisk">*</p>
                                        </label>
                                        <div class="radio-inline">
                                            <label class="contain">
                                            <input
                                                type="radio"
                                                name="listOption"
                                                ref="listOption"
                                                v-validate="'required|included:1,2,3'"
                                                value="1"
                                                v-model="listOption"
                                                @change="updateList(1)"
                                                >
                                            <span class="checkmark"></span>
                                            Projects
                                            </label>
                                        </div>
                                        <div class="radio-inline" style="margin-left:15px;">
                                            <label class="contain">
                                            <input
                                                type="radio"
                                                name="listOption"
                                                ref="listOption"
                                                value="2"
                                                v-model="listOption"
                                                @change="updateList(2)"
                                                >
                                            <span class="checkmark"></span>
                                            Modules
                                            </label>
                                        </div>
                                        <div class="radio-inline" style="margin-left:15px;">
                                            <label class="contain">
                                            <input
                                                type="radio"
                                                name="listOption"
                                                ref="listOption"
                                                value="3"
                                                v-model="listOption"
                                                @change="updateList(3)"
                                                >
                                            <span class="checkmark"></span>
                                            Courses
                                            </label>
                                        </div>
                                        <br>
                                        <span class="error" v-show="errors.has('listOption')">
                                        {{ $root.updateErrorMessage('listOption',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-xs-12 mb-0">
                                    <label for="module_name">Project / Module / Course Selection<p class="astrisk">*</p></label>
                                </div>
                                 <div class="form-group col-md-12 col-xs-12 slectBoxIcon">
                                    <div class="subject-info-box-1">
                                    <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                    </div>
                                    <div class="subject-info-arrows text-center">
                                    <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                    <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                    <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                    <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                    <span class="top-margin" style="color: red;" id="movingError"></span>
                                    </div>
                                    <div class="subject-info-box-2">
                                    <select multiple="multiple" name="lstBox2" id='lstBox2' class="form-control"></select>
                                     <span class="error" v-if="allSelectError != ''">
                                            {{allSelectError}}
                                        </span>
                                    </div>
                                </div>  
                           
                                <div class="form-group col-md-6 col-xs-12">
                            <div ref="start_date" class="form-group">
                                <label for="start_date">License Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="start_date"
                                    :config="$root.dpconfigDate"
                                    name="start_date"
                                    v-validate="'required'"
                                    @input="updateHoliday_date"
                                    placeholder="Start Date"
                                    autocomplete="off"
                                    >
                                </date-picker>	                                    
                                    <span class="error" v-show="errors.has('start_date')">
                                            {{ $root.updateErrorMessage('start_date',this.errors,'License Start Date')}}
                                    </span>
                            </div>                                    
                        </div>

                         <div class="form-group col-md-6 col-xs-12">
                            <div ref="end_date" class="form-group">
                                <label for="end_date">License End Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="end_date"
                                    :config="$root.dpconfigDate"
                                    name="end_date"
                                    v-validate="'required'"
                                    placeholder="End Date"
                                    @input="updateHoliday_end_date"
                                    autocomplete="off"
                                    >
                                </date-picker>	                                    
                                    <span class="error" v-show="errors.has('end_date')">
                                            {{ $root.updateErrorMessage('end_date',this.errors,'License End Date') }}
                                    </span>
                            </div>                                    
                        </div>

                                                     
                                <!-- <div class="col-md-12 col-md-12 savebtn text-align-center">
                                    <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                    </vue-button-spinner>
                                </div>  -->
                                </div>
                            </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            allSelectError:'',
            course_vertical:'',
            course_category:'',
            listOption: 1,
            course_fee_type:216,
            licenseName: null,
            start_date:'',
            end_date:'',
            courseHours:0,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ]            
        }
    },
    created() {
        this.fetchProjectAll()
        this.fetchModuleAll()
        this.fetchCourseAll()
            // .then((res) => {
            //     //console.log("res",res);
            //     var localStaffAll = this.projectAll;
            //     setTimeout(function() {
            //             for (var i = 0; i < localStaffAll.length; i++) {
            //                 $('#addLicenseForm #lstBox1')
            //                     .append($("<option></option>")
            //                         .attr("class","project")
            //                         .attr("value",localStaffAll[i].id)
            //                         .text(localStaffAll[i].employee_name));
            //             }
            //     },200)  
            // })
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('LicenseIndex', ['all','loading','moduleAll','projectAll','courseAll','courseVertical','courseCategory','courseFeeType']),
        ...mapGetters('AuthIndex', ['user']),
    },
    methods: {
        ...mapActions('LicenseIndex', ['resetState','fetchTypes','fetchCourseAll','fetchModuleAll','fetchProjectAll','licenseStore','fetchCourseAll']),
        handleCancel(evt){
            this.topic_name =''
            this.description=''
            this.sort_order = '';
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.licenseName = '';
            this.listOption = 1;
            this.start_date = '';
            this.end_date = '';

            $('#addLicenseForm #lstBox1').html('');
            for (var i = 0; i < this.projectAll.length; i++) {
                console.log("loop",this.projectAll[i]);
                $('#addLicenseForm #lstBox1')
                    .append($("<option data-hours='"+this.projectAll[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",this.projectAll[i].id)
                        .text(this.projectAll[i].project_name));
            }
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
        updateLicenseName(e) {
            this.licenseName = e.target.value
        },
        updateHoliday_date(e) {
            this.start_date = e
            //this.setHoliday_date(e);
        }, 
        updateHoliday_end_date(e) {
            this.end_date = e
           // this.setHoliday_end_date(e);
        },
        updateCourseHours(e){
            this.courseHours = e.target.value
        },
        moveToTheRight() {
            var selectedOpts = $('#addLicenseForm #lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')             
            }
            
            for (let i = 0; i < selectedOpts.length; i++) {
                this.courseHours = this.courseHours + $(selectedOpts[i]).data('hours');
            }
            $('#addLicenseForm #lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#addLicenseForm #lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.courseHours = this.courseHours + $(selectedOpts[i]).data('hours');
            }
            $('#addLicenseForm #lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            
             if(this.listOption == 1) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.project:selected');
            } else if(this.listOption == 2) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.module:selected');
            }
              else if(this.listOption == 3) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.course:selected');
            }
            $('#addLicenseForm #lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#addLicenseForm #lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')               
            }
            var selectedOptsh = $('#addLicenseForm #lstBox2 option:selected');
            for (let i = 0; i < selectedOptsh.length; i++) {
                this.courseHours = this.courseHours - $(selectedOptsh[i]).data('hours');
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
           
             if(this.listOption == 1) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.project');
            } else if(this.listOption == 2) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.module');
            }
              else if(this.listOption == 3) {
                var selectedOpts = $('#addLicenseForm #lstBox2 option.course');
            }
            $('#addLicenseForm #lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#addLicenseForm #lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            for (let i = 0; i < selectedAllOpts.length; i++) {
                this.courseHours = this.courseHours - $(selectedAllOpts[i]).data('hours');
            }
            $(selectedAllOpts).remove();
        },
        updateList(value) {
            this.listOption == parseInt(value)
            $('#addLicenseForm #lstBox1').empty()
            var allopt = [],
            options = $('#addLicenseForm #lstBox2 option'),
            values = $.map(options ,function(option) {
                allopt.push(option.value)
            }),
            allProject = $('.project'),
            allModule = $('.module'),
            allCourse = $('.course'),
            allSelectedProjectValue = [],
            allSelectedModuleValue = [],
            allSelectedCourseValue = [];
            for (var i = 0; i < allProject.length; i++) {
                allSelectedProjectValue.push(parseInt(allProject[i].value))
            }
            for (var i = 0; i < allModule.length; i++) {
                allSelectedModuleValue.push(parseInt(allModule[i].value))
            }
            for (var i = 0; i < allCourse.length; i++) {
                allSelectedCourseValue.push(parseInt(allCourse[i].value))
            }
            if(value == 1) {
                //console.log("up",this.projectAll);
                for (var i = 0; i < this.projectAll.length; i++) {
                    if(allSelectedProjectValue.indexOf(this.projectAll[i].id) == -1) {
                        $('#addLicenseForm #lstBox1')
                            .append($("<option data-hours='"+this.projectAll[i].project_hours+"'></option>")
                                .attr("class","project")
                                .attr("value",this.projectAll[i].id)
                                .text(this.projectAll[i].project_name));
                    }
                }                
            } else if(value == 2) {
                for (var i = 0; i < this.moduleAll.length; i++) {
                    if(allSelectedModuleValue.indexOf(this.moduleAll[i].id) == -1) {
                        $('#addLicenseForm #lstBox1')
                            .append($("<option data-hours='"+this.moduleAll[i].project_total_hours+"'></option>")
                                .attr("class","module")
                                .attr("value",this.moduleAll[i].id)
                                .text(this.moduleAll[i].module_name));
                    }
                }                  
            }
            else if(value == 3) {
               // console.log("dfsdf",this.courseAll);
                for (var i = 0; i < this.courseAll.length; i++) {
                    if(allSelectedCourseValue.indexOf(this.courseAll[i].id) == -1) {
                        $('#addLicenseForm #lstBox1')
                            .append($("<option data-hours='"+this.courseAll[i].total_hours+"'></option>")
                                .attr("class","course")
                                .attr("value",this.courseAll[i].id)
                                .text(this.courseAll[i].course_name));
                    }
                }                  
            }
        },
        submitForm() {
            this.allSelectError = '';
            var allProjects = [];
            $('#addLicenseForm #lstBox2 option').each(function() {
                //console.log($(this).attr('class'));
                allProjects.push({'name': $(this).text(), 'id': $(this).val(),'type':$(this).attr('class')})
            });
           // return false;
           
            
            this.$validator.validate().then(result => {
                if(result) {
                    if($('#addLicenseForm #lstBox2 option').length == 0) {
                        this.$eventHub.$emit('common',2,'Please select atlease one project or one module')
                        return false;
                    }

                    var start_date = moment(this.start_date, "DD/MM/YYYY").toDate();
                    var end_date = moment(this.end_date, "DD/MM/YYYY").toDate();
                
                    if(end_date < start_date){

                        this.$validator.errors.add({
                                id: 'end_date',
                                field: 'end_date',
                                msg: 'End Date greater than or equal to Start Date',
                                scope: this.$options.scope,
                            });
                        return false;
                    }

                    if(start_date > end_date){
                        this.$validator.errors.add({
                                id: 'start_date',
                                field: 'start_date',
                                msg: 'Start Date less than or equal to End Date',
                                scope: this.$options.scope,
                            });
                        return false;
                    }
                   
                    this.buttonLoading = true;   
                    var params = {
                        'license_name': this.licenseName,
                        'allSelectedLists': JSON.stringify(allProjects),
                        'start_date' : this.start_date,
                        'end_date':this.end_date,
                        'branch_id' : this.$route.params.id,
                        'user_id':this.user.id
                    };                 
                    this.licenseStore(params)
                        .then((res)=>{
                            if(!res.data.result)
                            {
                                // this.$validator.errors.add({
                                //     id: 'module_name',
                                //     field: 'module_name',
                                //     msg: res.data.message,
                                //     scope: this.$options.scope,
                                // });
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    //console.log("ke",key,value);
                                    if(key == "allSelectedLists"){
                                        this.allSelectError = value[0];
                                    }
                                    else{
                                        this.$validator.errors.add({
                                            id: key,
                                            field: key,
                                            msg: value[0],
                                            scope: this.$options.scope,
                                        });
                                    }
                                    
                                }
                                return '';
                            }           
                            this.modalOpened();
                            this.$refs.modal.hide();
                            this.$emit('loadItems');                 
                            this.$eventHub.$emit('create-success')
                            
                            
                            //this.$router.push({name: 'branchs.edit',params: {id:this.$route.params.id}});
                        })
                        .catch(()=>{
                           this.$eventHub.$emit('common',2,'Something went wrong.')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>
<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}



.contain .checkmark:after {
    left: 6.5px;
    top: 6.5px;
}
</style>