function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            batch: null,
            employee: null,
        },
        batchAll: [],
        employeeAll: [],
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    batchAll: state => state.batchAll,
    employeeAll: state => state.employeeAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.batch)) {
                params.set('batch_id', '')
            } else {
                params.set('batch_id', state.item.batch.id)
            }
            if (_.isEmpty(state.item.employee)) {
                params.set('employee_id', '')
            } else {
                params.set('employee_id', state.item.employee.id)
            }

            axios.post('/api/v1/batch-allocations', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.batch)) {
                params.set('batch_id', '')
            } else {
                params.set('batch_id', state.item.batch.id)
            }
            if (_.isEmpty(state.item.employee)) {
                params.set('employee_id', '')
            } else {
                params.set('employee_id', state.item.employee.id)
            }

            axios.post('/api/v1/batch-allocations/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/batch-allocations/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            

        dispatch('fetchBatchAll')
    dispatch('fetchEmployeeAll')
    },
    fetchBatchAll({ commit }) {
        axios.get('/api/v1/batchAllocationwiseListing')
            .then(response => {
                commit('setBatchAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
    },
    fetchEmployeeAll({ commit }) {
        axios.get('/api/v1/trainerList')
            .then(response => {
                commit('setEmployeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })             
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setBatch({ commit }, value) {
        commit('setBatch', value)
    },
    setEmployee({ commit }, value) {
        commit('setEmployee', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setBatch(state, value) {
        state.item.batch = value
    },
    setEmployee(state, value) {
        state.item.employee = value
    },
    setBatchAll(state, value) {
        state.batchAll = value
    },
    setEmployeeAll(state, value) {
        state.employeeAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
