var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card tableCard"},[_c('div',{staticClass:"table-responsive setting-group"},[_c('vue-good-table',{ref:"table1",attrs:{"styleClass":"vgt-table table table-bordered striped thead-dark","mode":"remote","totalRows":_vm.totalRecords,"sort-options":{
                    enabled: false,
                },"pagination-options":{
                    enabled: true,
                    perPage:10,
                    perPageDropdown: [10, 20, 40, 80, 100],
                    dropdownAllowAll: false,
                },"isLoading":_vm.isLoading,"select-options":{ 
                    enabled: false,
                },"search-options":{
                    enabled: false,
                    placeholder: 'search',
                },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('ReceiptActions',{attrs:{"student_id":_vm.student_id,"xprops":_vm.xprops,"row":props.row},on:{"loadItems":_vm.loadItems,"receiptIdModel":_vm.receiptIdModel}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[(!_vm.isLoading)?_c('span',[_vm._v(_vm._s('No Records Found.'))]):_vm._e()]),_c('template',{slot:"loadingContent"},[_c('CustomLoader')],1)],2)],1),_c('ViewReceipt',{attrs:{"receiptId":_vm.receiptId,"student_id":_vm.student_id},on:{"loadItems":_vm.loadItems}}),_c('EditReceipt',{attrs:{"receiptId":_vm.receiptId,"student_id":_vm.student_id},on:{"loadItems":_vm.loadItems}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }