<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Change Password</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active">Change Password</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Change Password</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form @submit.prevent="submitForm" novalidate>
                                <bootstrap-alert />
                                
                                
                                    <div class="form-group">
                                        <label for="current_password">Current Password<p class="astrisk">*</p></label>
                                        <input
                                                placeholder="Enter Current Password"
                                                type="password"
                                                class="form-control"
                                                v-validate="'required'"
                                                name="current_password"
                                                :value="current_password"
                                                @input="updateCurrentPassword"
                                                >
                                        <span class="error" v-show="errors.has('current_password')">
                                                {{ $root.updateErrorMessage('current_password',this.errors)}}
                                        </span>
                                    </div>
                                
                                
                                    <div class="form-group">
                                        <label for="new_password">New Password<p class="astrisk">*</p></label>
                                        <input
                                                placeholder="Enter New Password"
                                                type="password"
                                                class="form-control"
                                                v-validate="'required'"
                                                name="new_password"
                                                ref="pw_confirm"
                                                :value="new_password"
                                                @input="updateNewPassword"
                                                >
                                        <span class="error" v-show="errors.has('new_password')">
                                                {{ $root.updateErrorMessage('new_password',this.errors,'New Password')}}
                                        </span>
                                    </div>

                                <div class="form-group">
                                        <label for="new_password_confirmation">Confirm password<p class="astrisk">*</p></label>
                                        <input
                                                placeholder="Enter Confirm Password"
                                                type="password"
                                                class="form-control"
                                                v-validate="'required|confirmed:pw_confirm'"
                                                name="new_password_confirmation"
                                                :value="new_password_confirmation"
                                                @input="updateNewPasswordConfirmation"
                                                >
                                        <span class="error" v-show="errors.has('new_password_confirmation')">
                                              {{ $root.updateErrorMessage('new_password_confirmation',this.errors,'Confirm password')}}
                                        </span>
                                    </div>
                           
                                <div class="col-md-12 col-md-12 savebtn">
                                    <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                    </vue-button-spinner>
                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('ChangePassword', ['current_password', 'new_password', 'new_password_confirmation', 'loading'])
    },
    created() {
        // Code...
    },
    destroyed() {
        this.resetState();
    },
    methods: {
        ...mapActions('ChangePassword', ['storeData', 'resetState', 'setCurrentPassword', 'setNewPassword', 'setNewPasswordConfirmation']),
        updateCurrentPassword(e) {
            this.setCurrentPassword(e.target.value)
        },
        updateNewPassword(e) {
            this.setNewPassword(e.target.value)
        },
        updateNewPasswordConfirmation(e) {
            this.setNewPasswordConfirmation(e.target.value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
            if (result) {
            this.storeData()
                .then((res) => {
                    //console.log("res",res)
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                          id: 'current_password',
                          field: 'current_password',
                          msg: res.data.message,
                          scope: this.$options.scope,
                        });
                        return '';    
                    }
                    else{
                        this.$eventHub.$emit('update-success')
                    }  
                    
                })
                .catch((error) => {
                    console.error(error)
                })
            }
          else{            
        }
      });
        }
    }
}
</script>


<style scoped>

</style>
