function initialState() {
    return {
        all: [],
        query: {},
        loading: false,
        excelFile: null,
        excelUpdateDetails: [],
        excelLoader: false,
        universalFlag: true        
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['original_format_date','original_date','student_name','age','contact_name','contact_number','duration','workshop_type_text','status_text'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 

        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
         var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['original_format_date','original_date','student_name','age','contact_name','contact_number','duration','workshop_type_text','status_text'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 

        return rows.length;
    },
    loading:       state => state.loading,
    relationships: state => state.relationships,
    excelLoader: state => state.excelLoader,
    excelUpdateDetails: state => state.excelUpdateDetails,
    universalFlag: state => state.universalFlag    
}

const actions = {
    async exportReferenceDataListStore({ commit }) {
      window.location.href = location.protocol + "//" + location.host + '/exportWorkshopReferenceDataList';
    }, 
    async importingExcelStore({ commit, state, dispatch }) {
        commit('setExcelLoader', true)
        let params = new FormData();
        params.set('excelFile', state.excelFile)
        return new Promise((resolve, reject) => { 
            axios.post('/api/v1/importWorkshop', params)
                .then((response) => {
                  if(response.data.data[0] == false) {
                    commit('setUniversalFlag',response.data.data[0]);
                    resolve(response);
                  } else {
                    if(response && response.data && response.data.data) {
                        window.location.href = location.protocol + "//" + location.host + '/exportWorkshop/' + response.data.data[0];
                    }
                      resolve(response);  
                  }
                })            
                .catch(function (error) {
                              console.log('Error', error.message);
                              reject(error)
                        }) 
                .finally(() => {
                    commit('setExcelLoader', false)         
                })            
        })            
    },
    async fetchExcelUpdateDetails({ commit, state, dispatch }, id) {
        await axios.get('/api/v1/fetchExcelUpdateDetailsForWorkshop/'+id)
            .then(response => {
                commit('setExcelUpdateDetails', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
            .finally(() => {
              if(state.universalFlag) {
                 setTimeout(function () { dispatch('deleteTempExcelData'); }.bind(this), 240)
              }
            })              
    },      
    async deleteTempExcelData({ commit, state, dispatch }, uploadId) {
        commit('setLoading', true)
        await axios.delete('/api/v1/deleteTempWorkshopExcelData')
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })            
    },         
    fetchData({ commit, state, dispatch }, param) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/workshop', {params: param})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {        
        axios.delete('/api/v1/workshop/' + id)
            .then(response => {
                    var rows = state.all
                    rows = rows.filter((item) => {
                        return item.id != id
                    })
                    commit('setAll', rows);
                    
                    var queryLocal = purify(state.query);
                    if(rows.length == queryLocal.offset && rows.length != 0)
                    {
                        queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                        commit('setQuery', queryLocal)
                    }
                    resolve();
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
    convertToInquiry({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {        
        axios.post('/api/v1/workshop/convert-to-inquiry', {id:id})
            .then(response => {
                var rows = state.all

                dispatch('fetchData')

                var queryLocal = purify(state.query);
                if(rows.length == queryLocal.offset && rows.length != 0)
                {
                    queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                    commit('setQuery', queryLocal)
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },
    convertToStudent({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {        
        axios.post('/api/v1/workshop/convert-to-student', {id:id})
            .then(response => {
                var rows = state.all

                dispatch('fetchData')

                var queryLocal = purify(state.query);
                if(rows.length == queryLocal.offset && rows.length != 0)
                {
                    queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                    commit('setQuery', queryLocal)
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setExcelFile({ commit }, value) {
        commit('setExcelFile', value)
    },
    setExcelUpdateDetails({ commit }, value) {
        commit('setExcelUpdateDetails', value)
    },         
    setUniversalFlag({ commit }, value) {
        commit('setUniversalFlag', value)
    },     
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setExcelLoader(state, loading) {
        state.excelLoader = loading
    },
    setExcelUpdateDetails(state, value) {
        state.excelUpdateDetails = value
    },    
    setUniversalFlag(state, value) {
        state.universalFlag = value
    },
    setExcelFile(state, value) {
        state.excelFile = value
    },      
    setAll(state, items) {
        state.all = items
    },
    setError(state, error) {
        state.error = error
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
