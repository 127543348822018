<template>
    <form @submit.stop.prevent="submitForm" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div ref="course_name" class="form-group">
                                <label for="course">Course Name<p class="astrisk">*</p></label>
                                <v-select
                                    name="course_name" 
                                    label="course_name"
                                    id="course"
                                    v-validate="'required'"
                                    @input="updateCourseName"
                                    :value="item.course_name"
                                    :options="coursesAll"
                                    disabled="true"
                                />
                                <span class="error" v-show="errors.has('course_name')">
                                    {{ $root.updateErrorMessage('course_name',this.errors, 'Course Name')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="start_date" class="form-group">
                                <label for="start_date">Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="start_date"
                                    :config="start_date_config"
                                    name="start_date"
                                    v-validate="'required'"
                                    :disabled="(batchData.isBatchHold || batchData.isStartDateDisabled)"
                                    @input="update_date"
                                    placeholder="Start Date"
                                    autocomplete="off"
                                    >
                                </date-picker>
                                <span class="error" v-show="errors.has('start_date')">
                                    {{ $root.updateErrorMessage('start_date',this.errors,'Start Date')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="end_date" class="form-group">
                                <label for="end_date">End Date<p class="astrisk">*</p></label>
                                <input :value="end_date" class="form-control" placeholder="End Date" readonly />
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <div ref="extend_months" class="form-group">
                                <label for="course">No Of Month To Be Extend<p class="astrisk">*</p></label>
                                <v-select
                                    name="extend_months" 
                                    id="extend_months"
                                    v-validate="'required'"
                                    v-model="extentMonth"
                                    :options="extentMonths"
                                    @change="updateNewEndDate"
                                    placeholder="Select Months"
                                />
                                <span class="error" v-show="errors.has('extend_months')">
                                    {{ $root.updateErrorMessage('extend_months',this.errors, 'No Of Month To Be Extend')}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="resume_date" class="form-group">
                                <label for="resume_date">New End Date<p class="astrisk">*</p></label>
                                <date-picker
                                    v-model="new_end_date"
                                    :config="new_end_date_config"
                                    name="new_end_date"
                                    placeholder="New End Date"
                                    disabled="true"
                                    autocomplete="off"
                                    >
                                </date-picker>
                                <span class="error" v-show="errors.has('new_end_date')">
                                    {{ $root.updateErrorMessage('new_end_date',this.errors,'New End Date')}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1">
            <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
            <button type="submit" class="btn btn-success">Save</button>
        </footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default({
    props: ['student_id', 'batchId' , 'closeAddBatchTab'],
    data() {
        return {
            addCourseParams:null,
            checkedDays: [],
            workingCheckedDays: [],
            dayName: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            start_date: null,
            end_date: null,
            batchSchedules: [],
            override: 0,
            resume_date: null,
            start_date_config : this.$root.dpconfigDate,
            new_end_date_config : this.$root.dpconfigDate,
            new_end_date:'',
            custError:'',
            extentMonths:[1,2,3,4,5,6,7,8,9,10,11,12],
            extentMonth:null
        }
    },
    computed: {
        ...mapGetters('BatchSingle', ['item', 'coursesAll','batchAll','batchData']),
    }, 
    created() {
        this.$root.dpconfigDate.minDate = false;
        this.$root.dpconfigDate.maxDate = false;
        this.reset();
        this.fetchCoursesAll(this.student_id);
        this.getBatchData(this.batchId);
    },
    watch: {
        // batchAll(value){
        //     let _this = this;
        //     _this.batchSchedules = [];
        //     let batch_details = this.batchData.batch_details;
        //     // console.log("dfdf",batch_details);
        //     Object.keys(value).forEach((key, batch) => {
        //         _this.batchSchedules.push({daySelect:false,dayname:_this.dayName[key-1],day_id:key,batch_time:value[key],selected_batch:''});
        //     });
            

        //      Object.keys(batch_details).forEach((key, batch) => {
        //         //console.log("sds",key,batch);
        //         _this.batchSchedules[key-1]['daySelect'] = true;
        //         _this.batchSchedules[key-1]['selected_batch'] = batch_details[key];
        //     });
        // },
        batchData(value){
           
             this.start_date_config.minDate = false;
            //console.log(value);
            this.setCourseId(value.course.id)
            this.setCourseName(value.course)
            this.fetchBatchAll(value.course.id)
            this.start_date = value.start_date;
           // console.log("dfdf",value.isBatchHold);
            this.end_date = value.end_date;
           
            if(value.last_attendance_date){
                //console.log(":hrtt");
                this.start_date_config.minDate = value.last_attendance_date;
               
            }
            // var newdate = date.split("/").reverse().join("-"); 
            // console.log("sdsd",(value.start_date).split("/").reverse().join("-"));
            // this.resume_date_config.minDate = (value.start_date).split("/").reverse().join("-");
        }
    },
    methods: {
        ...mapActions('BatchSingle', ['extendEndData','setBatchData','updateData','getBatchData','storeData','fetchBatchAll','fetchCoursesAll', 'fetchCourseWorkingDaysAll', 'setCoursesAll', 'setCourseId', 'setCourseName', 'setCourseMonth']),
        toogleModelSubTable(id ,child){
            var className = $('#modelshow_'+id).attr('class');
            if($('#modelshow_'+id).length > 0 && className == "allhide"){
                $('#modelshow_'+id).removeClass('allhide').addClass('allshow');
                $('#modelangle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');
            }else{
                $('#modelshow_'+id).removeClass('allshow').addClass('allhide');
                $('#modelangle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
            }    
        },
        closeModel(){
            this.reset()
            this.$emit('closeModel')
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                  
                    let data = {
                        'id':this.batchData.id,
                        'student_id':this.student_id,
                        'course_id':this.item.course_id,
                        'start_date':this.start_date,
                        'resume_date':this.resume_date,
                        'end_date':this.end_date,
                        'new_end_date':this.new_end_date
                    }
                    this.extendEndData(data)
                    .then(response => {
                        if (response.data.result) {
                            
                            // this.$router.push({ name: 'batch.show' })
                            this.closeModel();
                             this.$emit('loadItems');
                            // this.fetchCoursesAll()
                            this.$eventHub.$emit('update-success')
                        } else {
                           
                            for (const [key, value] of Object.entries(response.data.data)) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                            return '';
                     
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                } else {
                    return this.$root.handleValidationFocus(this.errors, this.$refs);
                }
            });
        },
        updateCourseName(value) {
            // console.log("vaye",value);
            // if(value != null) {
            //     this.setCourseId(value.course_id)
            //     this.setCourseName(value.course_name)
            //     // this.setCourseMonth(value.course_months)
            //     this.fetchBatchAll(value.course_id)
            // } else {
            //     this.reset()
            // }
        },
        reset() {
            this.setCourseId(null)
            this.setCourseName(null)
            this.setCourseMonth(null)
            //  this.setBatchData([]);
            this.start_date = null
            this.end_date = null
            this.resume_date = null
        },
        update_date(e) {
           
            this.start_date = e
            var index = e.split('/')
            const dateFormat = index[1] + '/' + index[0] + '/' + index[2]
            // this.end_date = moment(dateFormat).add(this.batchData.course.course_months, 'M').format('DD/MM/YYYY')
        },
        updateNewEndDate(){
            console.log("dfdfd",this.extentMonth);
            if(this.extentMonth == null){
                this.new_end_date = '';
            }
            else{
                var index = this.end_date.split('/')
                const dateFormat = index[1] + '/' + index[0] + '/' + index[2]
                this.new_end_date = moment(dateFormat).add(this.extentMonth, 'M').format('DD/MM/YYYY')
            }
           
        },
        handleCancel(evt){
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.addCourseParams = null;
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            const current = new Date();
            var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            this.addCourseParams = null;
        },
    }
})
</script>

<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
