<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Requests</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Leave Request</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body"> 
                            <div class="col-md-7 col-xs-12">                          
                                <div ref="leave_type" class="form-group">
                                    <label for="leave_type">Leave Type<p class="astrisk">*</p></label>
                                    <v-select
                                            name="leave_type"
                                            label="name"
                                            id="leave_type"
                                            v-validate="'required'"
                                            @input="updateLeave_type"
                                            :value="item.leave_type"
                                            :options="leaveTypeAll"
                                            />
                                            <span class="error" v-show="errors.has('leave_type')" id="">
                                              {{ $root.updateErrorMessage('leave_type',this.errors,'Leave Type')}}
                                            </span>
                                                <div v-show="errors.has('leave_type') && leaveSeen">
                                                    <br>
                                                </div>
                                 <div v-if="leaveSeen && allocateLeave && allocateLeave !='' && (allocateLeave.allocated !='' || allocateLeave.leave_applied !='' || allocateLeave.allocated !='')"> 
                                    <label class="col-md-4 col-xs-12">Leave Allocation : {{allocateLeave.allocated}}</label>
                                    <label class="col-md-4 col-xs-12">Leave Applied : {{allocateLeave.leave_applied}}</label>
                                    <label class="col-md-4 col-xs-12">Balance Leave : {{allocateLeave.balance}}</label>
                                </div>
                                </div>
                                 
                            </div>
                             <div class="col-md-7 col-xs-12">
                                <div ref="leave_option" class="form-group">
                                    <label for="leave_option">Leave Option<p class="astrisk">*</p></label>
                                    <v-select
                                            name="leave_option"
                                            label="name"
                                            v-validate="'required'"
                                            id="leave_option"
                                            @input="updateLeave_option"
                                            :value="item.leave_option"
                                            :options="leaveOptionAll"
                                            />
                                            <span class="error" v-show="errors.has('leave_option')">
                                              {{ $root.updateErrorMessage('leave_option',this.errors,'Leave Option')}}
                                            </span>
                                </div>
                            </div>
                            <div class="col-md-7 col-xs-12">
                                <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="leave_start_date" class="form-group">
                                        <label for="leave_start_date">Leave From Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.leave_start_date"
                                                :config="startDateOptionFormate"
                                                name="leave_start_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                id="leave_start_date"
                                                placeholder="Select Leave From Date"
                                                @dp-change="updateLeave_start_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <!-- {format:'YYYY-MM-DD', minDate: new Date() } -->
                                        <!-- $root.dpconfigDate -->
                                        <span class="error" v-show="errors.has('leave_start_date')">
                                          {{ $root.updateErrorMessage('leave_start_date',this.errors,'Leave From Date')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="leave_end_date" class="form-group">
                                        <label for="leave_end_date">Leave Upto Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.leave_end_date"
                                                :config="endDateOptionFormate"
                                                id="leave_end_date"
                                                v-validate="'required'"
                                                :readonly="dateDisplay"
                                                name="leave_end_date"
                                                placeholder="Select Leave Upto Date"
                                                @dp-change="updateLeave_end_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_end_date')">
                                          {{ $root.updateErrorMessage('leave_end_date',this.errors,'Leave Upto Date')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                             <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="days">Leave Days</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="days"
                                            ref="days"
                                            v-validate="'required'"
                                            readonly=""
                                            id="daysData"
                                            min="0"
                                            :value="item.days"
                                            placeholder="Leave Count"
                                            >
                                    <!-- <span class="error" v-show="errors.has('days')">
                                      {{ $root.updateErrorMessage('days',this.errors)}}
                                    </span> -->
                                </div>
                                
                            </div>
                            <div class="col-md-7 col-xs-12" v-if="seen">
                            <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <div ref="start_time" class="form-group">
                                    <label for="start_time">Start Time<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.start_time"
                                            :config="$root.dpconfigTime"
                                            name="start_time"
                                            v-validate="'required'"
                                            placeholder="Select Start Time"
                                            @dp-change="updateStart_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('start_time')">
                                      {{ $root.updateErrorMessage('start_time',this.errors,'Start Time')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div ref="end_time" class="form-group">
                                    <label for="end_time">End Time<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.end_time"
                                            :config="$root.dpconfigTime"
                                            name="end_time"
                                            v-validate="'required'"
                                            placeholder="Select End Time"
                                            @dp-change="updateEnd_time"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('end_time')">
                                      {{ $root.updateErrorMessage('end_time',this.errors,'End Time')}}
                                    </span>
                                    <span class="error" v-show="errors.has('valid_end_time')">
                                        {{ $root.updateErrorMessage('valid_end_time',this.errors)}}
                                    </span>                                    
                                </div>
                            </div>
                            </div>
                        </div>
                          <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="reason">Leave Reason<p class="astrisk">*</p></label>
                                    <textarea
                                            rows="3"
                                            class="form-control"
                                            name="reason"
                                            ref="reason"
                                            maxlength="200"
                                            v-validate="'required'"
                                            placeholder="Enter Leave Reason"
                                            :value="item.reason"
                                            @input="updateReason"
                                            >
                                    </textarea>
                                    <span class="error" v-show="errors.has('reason')">
                                      {{ $root.updateErrorMessage('reason',this.errors,'Leave Reason')}}
                                    </span>
                                </div>
                            </div>                           
                            </div>                        
                        </div>
                         <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>      
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
     
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            timeValidate: false,
            seen:false,
            dateDisplay:true,
            leaveSeen:false,
            endValidDate:false,
            endDateOptionFormate:{format:window.date_format_moment},
            startDateOptionFormate:{format:window.date_format_moment, minDate:false, useCurrent: false},
            startCurrentTime:{format:window.time_format_moment,}
        }
    },
    computed: {
        ...mapGetters('LeaveRequestsSingle', ['item', 'loading', 'leaveTypeAll', 'leaveOptionAll', 'usersAll','allocateLeave', 'leaveCountAll'])
    },
    created() {
        this.fetchLeaveTypeAll()
        this.fetchLeaveOptionAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('LeaveRequestsSingle', ['storeData', 'resetState', 'setBranch_id', 'setUser_id', 'setLeave_type', 'setLeave_start_date', 'setLeave_end_date', 'setLeave_option', 'setStart_time', 'setEnd_time', 'setLeave_status', 'setReason', 'setDays', 'fetchLeaveTypeAll', 'fetchLeaveOptionAll', 'fetchAllocateLeaveAll', 'fetchLeaveCountAll','setLeaveCountAll']),

        //SET DAYS FIELD TO NUMBER OF LEAVES BETWEEN SELECTED START AND END DATE
        getDaysDiffrentFromDate(){
            var startDate = this.item.leave_start_date;
            var endDate =  this.item.leave_end_date;
            var daysCount = 0;
            
            if(startDate !== '' && endDate !== ''){

                var start = moment(startDate, "DD/MM/YYYY");
                var end = moment(endDate, "DD/MM/YYYY");
                var dd = moment.duration(end.diff(start)).asDays();
                //CONDITION FOR FULL DAY LEAVE
                if(this.item.leave_option && this.item.leave_option.value_id === 2){
                    daysCount = this.leaveCountAll;
                }
                //CONDITION FOR HALF DAY LEAVE
                else if(this.item.leave_option && this.item.leave_option.value_id === 1){
                        daysCount = 0.5;
                }
                //CONDITION FOR PARTIAL TIME LEAVE
                else if(this.item.leave_option && this.item.leave_option.value_id === 3){    
                    daysCount = 0;
                }
            }

            if(this.leaveCountAll < daysCount)
            {
                this.setDays(this.leaveCountAll);
            }
            else{
                this.setDays(daysCount);    
            }
            this.checkLeaveCount();
        },
        checkLeaveCount(){
            if(this.leaveCountAll == 0){
                this.$validator.errors.add({
                      id: 'leave_end_date',
                      field: 'leave_end_date',
                      msg: 'Selected Date is already a Holiday.',
                      scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('leave_end_date');
                return true;
            }
        }, 
        checkLeaveLimit(){
            if(this.item.leave_type && this.item.leave_type.value_id != 2 && this.item.leave_option && this.item.leave_option.value_id != 3){  // LWP && // Partial
                if(!this.allocateLeave || (parseFloat(this.allocateLeave.balance) < parseFloat(this.item.days))){
                    this.$validator.errors.add({
                          id: 'leave_type',
                          field: 'leave_type',
                          msg: 'Cannot take leave beyond the limit.',
                          scope: this.$options.scope,
                    });                         
                    return false;
                }
                else
                {
                    this.$validator.errors.remove('leave_type');
                    return true;
                }
            }
            else
            {
                this.$validator.errors.remove('leave_type');
                return true;
            }
            
        },
        checkTimeValidation(){
            if(this.timeValidate){
                if(!this.isValidateEndTime())
                {
                    this.$validator.errors.add({
                      id: 'valid_end_time',
                      field: 'valid_end_time',
                      msg: 'End Time should be greater than Start Time.',
                      scope: this.$options.scope,
                    });
                    return false;
                }
                else
                {
                    this.$validator.errors.remove('valid_end_time');
                    return true;
                }
            }
            else
            {
                this.$validator.errors.remove('valid_end_time');
                return true;
            }
            
        },
        getDate () {
          const toTwoDigits = num => num < 10 ? '0' + num : num;
          let today = new Date();
          let year = today.getFullYear();
          let month = toTwoDigits(today.getMonth() + 1);
          let day = toTwoDigits(today.getDate());
          return `${year}-${month}-${day}`;
        },
        setStartToEndDate(leaveOption = 0){
            if(!this.item.leave_option || this.item.leave_option == null || !this.item.leave_option.value_id )
            {
                return '';
            }
            if(leaveOption ==0){
                leaveOption =this.item.leave_option.value_id;
            }
            
            if (leaveOption == 1 ) {
               this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate:false,
                    maxDate: false,   
               };
            }
            else if (leaveOption == 2) {
                this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate:false,
                    maxDate: false,   
               };
            }else if (leaveOption == 3) {
               this.startDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate:false,
                    //maxDate: new Date(),   
                    maxDate: false,
               };
            }
            return this.endDateOptionFormate;
            
            
        },
        getAllLeave() {
            return true;
        },
        getLeaveCount() {

            if(this.item.leave_start_date != null && this.item.leave_end_date != null && this.item.leave_start_date != '' && this.item.leave_end_date != ''){
                var leave_dates = [this.item.leave_start_date.replace(/\//g, '-'), this.item.leave_end_date.replace(/\//g, '-')];
                this.fetchLeaveCountAll(leave_dates)
                .then((res) => {
                    this.getDaysDiffrentFromDate()
                  })
                //setTimeout(function () { this.getDaysDiffrentFromDate(); }.bind(this), 240)
                //this.getDaysDiffrentFromDate();
            }
        },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        
        updateLeave_type(value) {
            if(value && value.value_id !='' && value.value_id != 2){
                this.leaveSeen = true;
                this.fetchAllocateLeaveAll(value.value_id);
            }else{
                this.leaveSeen = false;
            }
             this.setLeave_type(value);
        },
        updateLeave_start_date(e) {
            if(e.target.value == '' || e.target.value == null)
            {
                this.endValidDate = false;    
            }
            else{
                this.endValidDate = e.target.value;
                this.endValidDate = moment(this.endValidDate, window.date_format_moment)
            }
            
            if(this.item.leave_option && this.item.leave_option.value_id == 3)
            {
            this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : this.endValidDate                    
               };         
           }else if(this.item.leave_option && this.item.leave_option.value_id == 2)
            {
                 this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : false
               };

           }else if(this.item.leave_option && this.item.leave_option.value_id == 1)
            {
            this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate : this.endValidDate
               };       
           }else{
            this.endDateOptionFormate = {
                    format:window.date_format_moment,
                    minDate: this.endValidDate,
                    maxDate: this.endValidDate
               };  
           }
            this.setLeave_start_date(e.target.value)
            this.getLeaveCount();
        },
        updateLeave_end_date(e) {
            this.setLeave_end_date(e.target.value)
            this.getLeaveCount();
        },
        updateLeave_option(value) {
            if(value && value.value_id != 2){
                this.seen=true; 
            }else{
                this.seen=false; 
            }
            if(value && (value.value_id == 1 || value.value_id == 3)){
                this.timeValidate = true;
            }
            else{
                this.timeValidate = false;
            }
            if(value!= null && value != ''){
                this.dateDisplay=true; 
            }else{
                this.dateDisplay=false; 
            }
            this.setLeave_option(value);
            
            if(value && value.value_id == 3){
                this.dateDisplay=false; 
                //$('#leave_start_date').val(this.getDate());
                //$('#leave_end_date').val(this.getDate());                              
            }else{
                $('#leave_start_date').val('');
                $('#leave_end_date').val('');
                this.dateDisplay=false;
            }           
              if(value && value.value_id){
                this.setStartToEndDate(value.value_id);    
                }else{
                    this.setStartToEndDate();    
                }
                this.setLeave_start_date(null);
                this.setLeave_end_date(null);            
            this.setLeave_option(value)
        },
        updateStart_time(e) {
            this.setStart_time(e.target.value)
        },
        updateEnd_time(e) {
            this.setEnd_time(e.target.value)
        },
        isValidateEndTime(){
            var flag = false;
            if(this.item.end_time !=null && this.item.start_time !=null)
            {
                var endTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.end_time);
                var startTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.start_time);
                if(endTime >= startTime){
                    flag = true;
                }
            }
            return flag
        },        
        updateLeave_status(value) {
            this.setLeave_status(value)
        },
        updateReason(e) {
            this.setReason(e.target.value)
        },
        
        submitForm() {
                
            this.$validator.validate().then(result => {
                if (result) {
                    if(!this.checkLeaveCount() || !this.checkLeaveLimit() || !this.checkTimeValidation()){
                        return false;
                    }
                    this.storeData()
                    .then((res) => {
                        if(res.data.error_message)
                        {
                            this.$validator.errors.add({
                                  id: 'leave_start_date',
                                  field: 'leave_start_date',
                                  msg: res.data.message,
                                  scope: this.$options.scope,
                            });
                            return '';
                        }
                        this.$router.push({ name: 'leave_requests.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }            
              });
        }
    }
}
</script>


<style scoped>

</style>
