<!-- addCompleteProjectRequest 6-6-23 -->
<template>
    <div class="popup-form add-country">
    <b-modal id="addCompleteProjectRequestModel"
             ref="addCompleteProjectRequestModel"
             title="Approval For Project Complete Request"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <addCompleteProjectRequest :id="id" v-if="scheduleProjectId != ''" :scheduleProjectId="scheduleProjectId" :studentId="studentId" @closeModel="closeModel" @loadItems="loadItems"></addCompleteProjectRequest>
    </b-modal>
  </div>
</template>
<script>
import addCompleteProjectRequest from '@/components/cruds/StudentAttendance/addCompleteProjectRequest'
export default {
    props: ['id','scheduleProjectId','studentId'],
    components:{
        addCompleteProjectRequest
    },
    methods: {
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){
            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(){
            this.$emit('resetId');
            this.$refs.addCompleteProjectRequestModel.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

