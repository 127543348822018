<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Fee</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Course Fee</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                            <div class="col-md-6">
                                <div ref="branch" class="form-group">
                                    <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="branch"
                                            label="branch_name"
                                            v-validate="'required'"
                                            disabled=""
                                            @input="updateBranch"
                                            :value="item.branch"
                                            :options="branchMastersAll"
                                            />
                                         <span class="error" v-show="errors.has('branch')">
                                              {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                            </span>
                                </div>
                                </div>
                               
                            <div class="col-md-6">
                                <div ref="course" class="form-group">
                                    <label for="course">Course Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="course"
                                            label="course_name"
                                            v-validate="'required'"
                                            disabled=""
                                            @input="updateCourse"
                                            :value="item.course"
                                            :options="coursesAll"
                                            />
                                         <span class="error" v-show="errors.has('course')">
                                              {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                        </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="fees_amount">Old Start Date</label>
                                    <input
                                            type="text"
                                            disabled
                                            class="form-control"
                                            name="fees_amount"
                                            ref="fees_amount"
                                            placeholder="Fetching old start date..."             
                                            :value="computedOldDate">
                                    <span class="error" v-show="errors.has('fees_amount')">
                                              {{ $root.updateErrorMessage('fees_amount',this.errors,'Fee Amount','a valid value')}}
                                    </span>
                                </div>
                            </div>
                            <div ref="start_date" class="form-group col-md-6 col-xs-12">
                                <label for="start_date">New Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                        :value="item.start_date"
                                        :config="$root.dpconfigDate"
                                        name="start_date"
                                        v-validate="'required'"
                                        placeholder="Select New Start Date"
                                        @dp-change="updateStartDate"
                                        autocomplete="off"
                                        >
                                </date-picker>
                                <span class="error" v-show="errors.has('start_date')">
                                  {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                </span>
                            </div>
                            <div class="clearfix"></div>
                            <div class=" col-md-10 col-md-offset-1" v-if="courseDetailedData.length > 0">
                                <div class="form-group">
                                        <section v-for="(courseLevel, index) in allCourseLevels" v-bind:key="index">
                                            <table class="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 150px;">Course Level</th>
                                                            <th style="width: 150px;">Fee type</th>
                                                            <th style="width: 280px;">Old fee</th>
                                                            <th style="width: 280px;">New fee</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-for="(feesType, i) in feesTypeAll">
                                                        <tr>
                                                            <td>{{courseLevel.course_level_name}}</td>
                                                            <td>{{feesType.name}}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="percentage_from"
                                                                    id="percentage_from"
                                                                    disabled
                                                                    oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                    placeholder="Fetching old fee..."
                                                                    :value="computedOldFee(courseLevel.course_level_id,feesType.value_id)"
                                                                    >
                                                            </td> 
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="percentage_from"
                                                                    id="percentage_from"
                                                                    oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                    placeholder="Enter new fee"
                                                                    v-validate="'required'"
                                                                    :value="newFees && newFees[courseLevel.course_level_id+''+feesType.value_id] ? newFees[courseLevel.course_level_id+''+feesType.value_id] : 0"
                                                                    @input="updateNewFeeAmount($event, courseLevel.course_level_id+''+feesType.value_id)" 
                                                                    >
                                                            </td>         
                                                        </tr>                                                        
                                                    </tbody>
                                            </table><br>                               
                                        </section>
                                        <span class="error" v-show="errors.has('percentage_from')">
                                            Enter At least one new fee data.
                                        </span><br>                                                   
                                 </div>
                            </div>                            
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            newFees: [],
            totalCountFees:0,    
            totalCountGST:0,
        }

    },
    computed: {
        ...mapGetters('FeesMastersSingle', ['item', 'loading', 'coursesAll','branchMastersAll', 'feesTypeAll','courseDetailedData','allCourseLevels']),
        computedOldDate() {
            if(this.courseDetailedData && this.courseDetailedData[0]) {
                var formatedFromDate = this.courseDetailedData[0].from_date.split('-');
                var finalOldDate = formatedFromDate[2]+'/'+formatedFromDate[1]+'/'+formatedFromDate[0]
                return finalOldDate;
            }
        }
    },
    created() {
        this.fetchEditData({'id':this.$route.params.id,'from_date':this.$route.params.from_date,'fees_branch_id':this.$route.params.fees_branch_id})
            .then(res=>{
                if(this.item && this.item.course) {
                    this.updateCourse(this.item.course)
                }
            })
        // this.fetchEditData('id':13,'from_date':'2019-05-29')            
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            // this.fetchEditData(this.$route.params.id,)
            this.fetchEditData({'id':this.$route.params.id,'from_date':this.$route.params.from_date,'fees_branch_id':this.$route.params.fees_branch_id})
        },
        "item": function() {
          this.totalCountFees = this.item.fees_amount;  
          this.totalCountGST = this.item.gst_rate;
        },
    },
    methods: {
        ...mapActions('FeesMastersSingle', ['fetchEditData', 'updateBunchData', 'resetState', 'setCourse', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type','fetchCouseData','fetchCourseDetailedData','fetchCoursesLevel','setStartDate']),      
        countTotalFees(){
            // var myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            // this.setTotal_fees(myVal)
            // return myVal;
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            }
            this.setTotal_fees(myVal.toFixed(2));            
            return myVal.toFixed(2);
        },
        computedOldFee(courseLevel, feesType) {
            var oldFee = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                 if(courseLevel == this.courseDetailedData[i].course_level_id && feesType == this.courseDetailedData[i].fees_type) {
                    oldFee = this.courseDetailedData[i].fees_amount
                 }
             }
            return oldFee;
        },
        updateStartDate(e) {
            this.setStartDate(e.target.value)
        },        
        updateErrorMessage(errFieldName) {
            if(errFieldName)
            {
                if(this.errors.first(errFieldName))
                {
                    return this.errors.first(errFieldName).replace(/_/g,' ');
                }
            }
            else
            {
                return "";
            }
        },
        updateCourse(value) {
            console.log('inside updateCourse')
            this.setCourse(value)
            // this.fetchCourseDetailedData(value.main_course_id)
            this.fetchCourseDetailedData({'main_course_id':value.main_course_id,'from_date':this.item.from_date,'branch_id':this.item.branch_id})
                .then((res) => {
                    // this.dispalyData();
                })
            this.fetchCoursesLevel(value.main_course_id)
        },
        updateNewFeeAmount(e, index) {
            // console.log(e.target.value,index);
            this.$set(this.newFees, parseInt(index),e.target.value)
        },
        dispalyData() {
                var oldFeesDataLocal = []
                for (var i = 0; i < this.courseDetailedData.length; i++) {
                    // oldFeesDataLocal[] = this.courseDetailedData[i].
                }   
                this.oldFeesData = oldFeesDataLocal;
        },
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },
        updateIs_gst_applicable(e) {
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                if(e){
                    this.setGst_rate(e)
                }else{
                    this.totalCountGST = 0;
                    this.setGst_rate(0)    
                }
                
            }            
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },
        updateBranch(value) {
            this.setBranch(value)
        },
        updateFees_type(value) {
            this.totalCountGST = (value.other_value)?value.other_value:0;
            this.setGst_rate(this.totalCountGST)    
            this.setFees_type(value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                var finalData = [],
                detailedData = [],
                newFeesLocal = this.newFees;
                for (var i = 0; i < this.allCourseLevels.length; i++) {
                    for (var j = 0; j < this.feesTypeAll.length; j++) {
                        var localIndex = this.allCourseLevels[i].course_level_id+''+this.feesTypeAll[j].value_id;
                        // console.log(newFeesLocal);
                        var formatedFromDate = this.item.start_date.split('/');
                        var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]                            
                        var obj = {
                            'id': this.item.id,
                            'course_id': this.allCourseLevels[i].course_id,
                            'branch_id': this.item.branch.id,
                            // 'course_id': fetch course id with course_level and course_id,
                            'fees_type': this.feesTypeAll[j].value_id,
                            'fees_amount': 0,
                            'is_gst_applicable': this.feesTypeAll[j] && this.feesTypeAll[j].other_value != 0 ? 1 : 0,
                            'gst_rate': this.feesTypeAll[j].other_value,
                            'total_fees': 0,
                            'from_date': finalOldDate,
                            'old_date': this.courseDetailedData[0].from_date
                        } ;
                        if(newFeesLocal[localIndex] !== undefined) {
                            obj.fees_amount = newFeesLocal[localIndex];
                        } 
                        finalData.push(obj);
                    }
                }
                this.updateBunchData(finalData)
                    .then((res) => {                  
                        this.$router.push({ name: 'fees_masters.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
          });
        }
    }
}
</script>


<style scoped>

</style>
