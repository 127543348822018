export default {
    namespaced: true,
    state(){
        return{
            user: [],
            loggedIn: false,
            rules:[],
            branches:[],
            fetchIfParent:[],
            fetchIfTrainer:[],           
        }
    },
    getters: {
        user:state => state.user,
        loggedIn:state => state.loggedIn,
        rules:state => state.rules,      
        branches:state => state.branches,
        fetchIfParent:state => state.fetchIfParent,
        fetchIfTrainer:state => state.fetchIfTrainer
    },
    actions: {
        storeUserData({ commit }, data) {
            /* Used for add commit user into state using mutation... */
            commit('setUserData', data);
            commit('setLoggedIn', data);
        },
        loggedOutUser({ commit }){
            /* Used for make empty commit user into state using mutation... */
            commit('loggedOutUser', '');
            commit('setLoggedIn', '');
        },   
        updateUserStateOnChange({ commit }, data){
            /* Used for update commit user into state using mutation... */
            commit('setUserStateOnChange', data);
            if(data.rules){
                commit('setRules', data.rules);
            }            
        }     
    },
    mutations: {
        setLoggedIn(state, user){
            state.loggedIn = (user) ? true : false;
        },
        setUserData(state,data){
            state.user = (data.result) ? data.result :''; 
            state.rules = (data.rules) ? data.rules :'';
            state.branches = (data.branch_header) ? data.branch_header :'';
            state.fetchIfParent = (data.fetchIfParent) ? data.fetchIfParent : null;
            state.fetchIfTrainer = (data.fetchIfTrainer) ? data.fetchIfTrainer : null;
            if(data.access_token != undefined){
                state.user.access_token = (data.access_token) ? data.access_token : null;                
            }            
        },        
        setUserStateOnChange(state, data){
            state.user.api_session_branch_id = data.api_session_branch_id;
           // state.user.branch_id = data.api_session_branch_id;
            state.user.api_session_franchise_id = data.api_session_franchise_id;
           // state.user.franchise_id = data.api_session_franchise_id;           
        },
        setRules(state, rules) {
            state.rules = rules
        },
        loggedOutUser(state,data){
            state.user = data;  
            state.rules = data;
            state.branches = data;
        },
    }    
}