function initialState() {
    return {
        item: {
            id: null,
            course_topic: null,
            course_name_id: null,
            sub_topic: null,
            question: null,
            question_answer: 1,
            sort_order: null,
            fully: null,
            partial: null,
            need_improvement: null,
        },
        coursesAll: [],
        coursetopicsAll: [],
        subtopicsAll: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    coursesAll: state => state.coursesAll,
    coursetopicsAll: state => state.coursetopicsAll,
    subtopicsAll: state => state.subtopicsAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
             
            params.set('course_id',state.item.course_name_id['id']);
            params.delete('course_name_id'); 

            // params.set('topic_id',state.item.course_topic['id']);
            // params.delete('course_topic');

            params.set('sub_topic_id',state.item.sub_topic['value_id']);
            params.delete('sub_topic');
                
            axios.post('/api/v1/course-assesments', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            params.set('course_id',state.item.course_name_id['id']);
            params.delete('course_name_id'); 

            // params.set('topic_id',state.item.course_topic['id']);
            // params.delete('course_topic');

            params.set('sub_topic_id',state.item.sub_topic['value_id']);
            params.delete('sub_topic');

            axios.post('/api/v1/course-assesments/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/course-assesments/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })           
            dispatch('fetchCoursesAll');
            dispatch('fetchCourseTopicsAll');
            dispatch('fetchSubTopicsAll');
    },    
    fetchCoursesAll({ commit }) {
        // axios.get('/api/v1/courses')
        axios.get('/api/v1/getCourseListWithOutFullCourse')
            .then(response => {                
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    fetchCourseTopicsAll({ commit }) {
        axios.get('/api/v1/course-topic-masters')
            .then(response => {                
                commit('setCoursetopicsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })              
    },
    fetchSubTopicsAll({ commit }) {
        axios.get('/api/v1/subtopicList')
            .then(response => {                
                commit('setSubtopicsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    fetchCot({ commit }, course_id) {
        axios.get('/api/v1/ctopicsList/'+course_id)
            .then(response => {
                commit('setCoursetopicsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },    
/*    setCourseTopicName({ commit }, value) {
        commit('setCourseTopicName', value)
    },*/
    setQuestion({ commit }, value){
        commit('setQuestion', value)
    },
    setQuestionAnswer({ commit }, value){
        commit('setQuestionAnswer', value)
    },
    setQuestionNumber({ commit }, value){
        commit('setQuestionNumber', value)
    },
    setCourseId({ commit }, value) {
        commit('setCourseId', value)
    },
    setCourseTopicId({ commit }, value) {
        commit('setCourseTopicId', value)
    }, 
    setSubTopicId({ commit }, value) {
        commit('setSubTopicId', value)
    },
    setFully({ commit }, value) {
        commit('setFully', value)
    },
    setPartial({ commit }, value) {
        commit('setPartial', value)
    },
    setNeedImprovement({ commit }, value) {
        commit('setNeedImprovement', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
/*    setCourseTopicName(state, value) {
        state.item.topic_name = value
    },*/
    
    setQuestion(state, value) {
        state.item.question = value
    },
    setFully(state, value) {
        state.item.fully = value
    },
    setPartial(state, value) {
        state.item.partial = value
    },
    setNeedImprovement(state, value) {
        state.item.need_improvement = value
    },
    setQuestionAnswer(state, value) {
        state.item.question_answer = value
    },
    setQuestionNumber(state, value) {
        state.item.sort_order = value
    },
    setCourseId(state, value) {
        state.item.course_name_id = value
    },
    setCourseTopicId(state, value) {
        state.item.course_topic = value
    },
    setSubTopicId(state, value) {
        state.item.sub_topic = value
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },
    setCoursetopicsAll(state, value) {
        state.coursetopicsAll = value
    },
    setSubtopicsAll(state, value) {
        state.subtopicsAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
