<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Holidays</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/holidays'">Holidays</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Holiday</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <form @submit.prevent="submitForm" novalidate>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Holiday</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                              
                        <div class="col-md-12">
                             <bootstrap-alert />
                        </div> 

                        <div class="col-md-12 col-xs-12">
                            <div ref="branches" class="form-group">
                                <label for="branches">Branches</label>
                                <select class="form-control select2 branches" placeholder="Select Branches" multiple v-model="branches">
                                    <!-- <option value="-1" selected>Select Branches</option> -->
                                    <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                                        <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id">
                                            {{branch.branch_name}}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>  
                            
                        <div class="form-group col-md-6 col-xs-12">
                            <div ref="holiday_date" class="form-group">
                                <label for="holiday_date">Holiday Start Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="item.holiday_date"
                                    :config="$root.dpconfigDate"
                                    name="holiday_date"
                                    v-validate="'required'"
                                    @input="updateHoliday_date"
                                    placeholder="Start Date"
                                    autocomplete="off"
                                    >
                                </date-picker>	                                    
                                    <span class="error" v-show="errors.has('holiday_date')">
                                            {{ $root.updateErrorMessage('holiday_date',this.errors,'Start Date')}}
                                    </span>
                            </div>                                    
                        </div>

                         <div class="form-group col-md-6 col-xs-12">
                            <div ref="holiday_end_date" class="form-group">
                                <label for="holiday_end_date">Holiday End Date<p class="astrisk">*</p></label>
                                <date-picker
                                    :value="item.holiday_end_date"
                                    :config="$root.dpconfigDate"
                                    name="holiday_end_date"
                                    v-validate="'required'"
                                    placeholder="End Date"
                                    @input="updateHoliday_end_date"
                                    autocomplete="off"
                                    >
                                </date-picker>	                                    
                                    <span class="error" v-show="errors.has('holiday_end_date')">
                                            {{ $root.updateErrorMessage('holiday_end_date',this.errors,'End Date') }}
                                    </span>
                            </div>                                    
                        </div>

                        <div class="form-group col-md-12 col-xs-12">
                            <label for="holiday_name">Holiday Name<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="holiday_name"
                                    ref="holiday_name"
                                    maxlength="90"
                                    v-validate="'required'"
                                    placeholder="Enter Holiday Name"
                                    :value="item.holiday_name"
                                    @input="updateHoliday_name"
                                    >
                                        <span class="error" v-show="errors.has('holiday_name')">
                                        {{ $root.updateErrorMessage('holiday_name',this.errors,'Holiday Name')}}
                                    </span>
                        </div>

                         <div class="col-md-12 col-md-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div> 
                       
                    </div>
                </div>
            </div>
           
            </form>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    components: { CommonMasterPopup },
    data() {
        return {
            branchesDetails:[],
            branches:[],
            // Code...
            //errorName : ''
        }
    },
    computed: {
        ...mapGetters('HolidaysSingle', ['item', 'loading']),
       
    },
    created() {
        this.branchesDetails = this.$store.state.AuthIndex.branches;
    },
    destroyed() {
        this.resetState()
    },
    mounted() {
        let _this = this;
        $(".branches").select2({placeholder: "Select Branches",}).on('change', function () {
            _this.updateBranches($(this).select2("val"))
        // this.myVueVariable = $(this).select2("val");
        });
//         $('.branches').select2().on('select2:change', (e) => {
//             console.log("e",e);
//       this.branches = e.params.data.id;
//    });
    },
    methods: {
        
        ...mapActions('HolidaysSingle', ['storeData', 'resetState', 'setHolidayName', 'setHoliday_date','setHoliday_end_date','setBranches']),
      
        updateHoliday_name(e) {
            this.setHolidayName(e.target.value)
        },
        updateHoliday_date(e) {
            this.setHoliday_date(e);
        }, 
        updateHoliday_end_date(e) {
            this.setHoliday_end_date(e);
        },
        updateBranches(val){
           //console.log("val",val);
            this.setBranches(val);
        },    
        submitForm() {
        this.$validator.validate().then(result => {
             if (result) {
                 var start_date = moment(this.item.holiday_date, "DD/MM/YYYY").toDate();
                 var end_date = moment(this.item.holiday_end_date, "DD/MM/YYYY").toDate();
            
                 if(end_date < start_date){

                      this.$validator.errors.add({
                            id: 'holiday_end_date',
                            field: 'holiday_end_date',
                            msg: 'End Date greater than or equal to Start Date',
                            scope: this.$options.scope,
                        });
                     return false;
                 }

                 if(start_date > end_date){
                      this.$validator.errors.add({
                            id: 'holiday_date',
                            field: 'holiday_date',
                            msg: 'Start Date less than or equal to End Date',
                            scope: this.$options.scope,
                        });
                     return false;
                 }
                
                //return false;
                 
                 this.storeData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'holiday_date',
                            field: 'holiday_date',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        // console.log("vb",this.item.branches);
                        // console.log("array",[1,2]);
                        setTimeout(function () { $(".branches").val(this.item.branches).trigger('change'); }.bind(this), 1000);
                        
                        // $(".branches").select2('val', this.item.branches).change();
                        //$(".branches").val(this.item.branches).trigger('change');
;
                        return '';
                    }
                    this.$router.push({ name: 'holidays.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


 