<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Employees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/employee-masters'">Employees</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Employee</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <form @submit.prevent="submitForm" novalidate>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Employee</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="employee_name">Employee Name<p class="astrisk">*</p></label>
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            name="employee_name"
                                                            ref="employee_name"
                                                            maxlength="150"
                                                            v-validate="'required|alpha_spaces'"
                                                            placeholder="Enter Employee Name"
                                                            :value="item.employee_name"
                                                            @input="updateEmployee_name"
                                                            >
                                                            <span class="error" v-show="errors.has('employee_name')">
                                                            {{ $root.updateErrorMessage('employee_name',this.errors,'Employee Name')}}
                                                            </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-8 col-xs-12">
                                                <div class="form-group">
                                                    <label for="employee_address">Employee Address<p class="astrisk">*</p></label>
                                                    <textarea
                                                            rows="3"
                                                            class="form-control"
                                                            maxlength="250"
                                                            name="employee_address"
                                                            ref="employee_address"
                                                            v-validate="'required'"
                                                            placeholder="Enter Employee Address"
                                                            :value="item.employee_address"
                                                            @input="updateEmployee_address"
                                                            >
                                                    </textarea>
                                                    <span class="error" v-show="errors.has('employee_address')">
                                                        {{ $root.updateErrorMessage('employee_address',this.errors,'Employee Address')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="col-md-4 col-xs-12">
                                                <div ref="country" class="form-group">
                                                    <label for="country">Country<p class="astrisk">*</p></label>
                                                    <v-select
                                                            name="country"
                                                            v-validate="'required'"
                                                            placeholder="Select Country"
                                                            label="country_name"
                                                            @change="updateCountry"
                                                            :value="item.country"
                                                            :options="countrymastersAll"
                                                            />
                                                            <span class="error" v-show="errors.has('country')">
                                                            {{ $root.updateErrorMessage('country',this.errors, 'Country')}}
                                                            </span>
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-md-4 col-xs-12">
                                                <div ref="state" class="form-group">
                                                    <label for="state">State<p class="astrisk">*</p></label>
                                                            <div class="state_box pos-relative">
                                                                <v-select
                                                                name="state"
                                                                placeholder="Select State"
                                                                v-validate="'required'"
                                                                label="state_name"
                                                                @change="updateState"
                                                                :value="item.state"
                                                                class="parentName"
                                                                :options="statemastersAll"
                                                                />
                                                                <AddStatePopupEmployee class="add_state_btn" v-if="$can('state_create') && statePopupButton"></AddStatePopupEmployee>
                                                            </div>
                                                            <span class="error" v-show="errors.has('state')">
                                                            {{ $root.updateErrorMessage('state',this.errors, 'State')}}
                                                            </span>
                                                    </div>
                                                </div>
                                            <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="city">City<p class="astrisk">*</p></label>
                                                    <!-- <input
                                                            type="text"
                                                            class="form-control"
                                                            name="city"
                                                            ref="city"
                                                            maxlength="90"
                                                            v-validate="'required'"
                                                            placeholder="Enter City"
                                                            :value="item.city"
                                                            @input="updateCity"
                                                            > -->
                                                            <v-select
                                                                name="city"
                                                                placeholder="Select City"
                                                                v-validate="'required'"
                                                                label="city_name"
                                                                @change="updateCity"
                                                                :value="item.city"
                                                                :options="citymastersAll"
                                                                />
                                                            <span class="error" v-show="errors.has('city')">
                                                            {{ $root.updateErrorMessage('city',this.errors, 'City')}}
                                                            </span>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="area">Area</label>
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            name="area"
                                                            ref="area"
                                                            maxlength="90"                                            
                                                            placeholder="Enter Area"
                                                            :value="item.area"
                                                            @input="updateArea"
                                                            >
                                                            <span class="error" v-show="errors.has('area')">
                                                            {{ $root.updateErrorMessage('area',this.errors,'Area')}}
                                                            </span>                                            
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="gender">Gender<p class="astrisk">*</p></label><br>
                                                    <div class="radio-inline">
                                                        <label class="contain">
                                                            <input
                                                                    type="radio"
                                                                    name="gender"
                                                                    ref="gender"
                                                                    v-validate="'required|included:1,2'"
                                                                    value="1"
                                                                    :checked="item.gender === '1'"
                                                                    @change="updateGender('1')"
                                                                    >
                                                            <span class="checkmark"></span>
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div class="radio-inline">
                                                        <label class="contain">
                                                            <input
                                                                    type="radio"
                                                                    name="gender" 
                                                                    ref="gender"
                                                                    value="2"
                                                                    :checked="item.gender === '2'"
                                                                    @change="updateGender('2')"
                                                                    >
                                                            <span class="checkmark"></span>
                                                            Female
                                                        </label>
                                                    </div><br>
                                                    <span class="error" v-show="errors.has('gender')">
                                                        {{ $root.updateErrorMessage('gender',this.errors, 'Gender')}}
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-md-4 col-xs-12">
                                                <div ref="birth_date" class="form-group">
                                                    <label for="birth_date">Birth Date</label>
                                                    <date-picker
                                                            :value="item.birth_date"
                                                            :config="dobDateOptionFormate"
                                                            name="birth_date"
                                                            placeholder="Select Birth Date"
                                                            @dp-change="updateBirth_date"
                                                            autocomplete="off"
                                                            >
                                                    </date-picker>
                                                    <span class="error" v-show="errors.has('birth_date')">
                                                            {{ $root.updateErrorMessage('birth_date',this.errors,'Birth Date')}}
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="mobile_no">Mobile Number<p class="astrisk">*</p></label>
                                                    <input
                                                            type="text"
                                                            min="0"
                                                            class="form-control"
                                                            name="mobile_no"
                                                            ref="mobile_no"
                                                            v-validate="'required|digits:10'"
                                                            maxlength="10"
                                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                            placeholder="Enter Mobile Number"
                                                            :value="item.mobile_no"
                                                            @input="updateMobile_no"
                                                            >
                                                            <span class="error" v-show="errors.has('mobile_no')">
                                                            {{ $root.updateErrorMessage('mobile_no',this.errors,'Mobile Number')}}
                                                            </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-xs-12">
                                                <div class="form-group">
                                                    <label for="email_id">Email<p class="astrisk">*</p></label>
                                                    <input
                                                            type="email"
                                                            class="form-control"
                                                            name="email_id"
                                                            ref="email_id"
                                                            v-validate="'required|email'"
                                                            maxlength="200"
                                                            placeholder="Enter Email"
                                                            :value="item.email_id"
                                                            @input="updateEmail_id"
                                                            >
                                                            <span class="error" v-show="errors.has('email_id')">
                                                            {{ $root.updateErrorMessage('email_id',this.errors,'Email')}}
                                                            </span>
                                                    </div>
                                                    </div>
                                            
                                                <div class="col-md-4 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="logo">Profile </label>
                                                        <input
                                                                type="file"
                                                                name="profile"
                                                                id="profile"
                                                                v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg'"
                                                                class="form-control"
                                                                @change="updateProfile"
                                                            >
                                                            <span class="error" v-show="errors.has('profile')">
                                                            {{ $root.updateErrorMessage('profile',this.errors,'Profile')}}
                                                            </span>
                                                        <ul v-if="item.profile" class="list-unstyled">
                                                            <li>
                                                            <div class="" style="">
                                                                <div v-if="item.profile != null">
                                                                    <img :src="item.profile" id="blah" class="profile-user-img my-profile employee_image img-responsive img-circle" :alt="item.employee_name" > 
                                                                
                                                                </div>
                                                                <div v-else>
                                                                    <img src="/assets/img/male.jpg" class="img-circle employee_image my-profile" :alt="item.employee_name" >
                                                                </div>
                                                            </div>
                                                                <button class="btn btn-xs btn-danger"
                                                                        type="button"
                                                                        @click="removeProfile"
                                                                >
                                                                    Remove file
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                             
                       
                       
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Professional Detail</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <div class="form-group">
                                <label for="highest_education">Highest Education</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        maxlength="90"
                                        name="highest_education"
                                        ref="highest_education"
                                        placeholder="Enter Highest Education"
                                        :value="item.highest_education"
                                        @input="updateHighest_education"
                                        >
                            </div>
                        </div>
                        <!-- <div class="col-md-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="designation">Designation<p class="astrisk">*</p></label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        name="designation"
                                                        ref="designation"
                                                        maxlength="150"
                                                        v-validate="'required'"
                                                        placeholder="Enter Designation"
                                                        :value="item.designation"
                                                        @input="updateDesignation"
                                                        >
                                                        <span class="error" v-show="errors.has('designation')">
                                                        {{ $root.updateErrorMessage('designation',this.errors)}}
                                                        </span>
                                                </div>
                                            </div>  -->  
                                            <div class="clearfix"></div>                             
                                            <div class="col-md-6 col-xs-12">
                                            <div ref="employee_type" class="form-group">
                                                <label for="employee_type">Designation<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="employee_type"
                                                        label="name"
                                                        v-validate="'required'"
                                                        @change="updateEmployee_type"
                                                        :value="item.employee_type"
                                                        :options="employeeTypeAll"
                                                        />
                                                        <span class="error" v-show="errors.has('employee_type')">
                                                        {{ $root.updateErrorMessage('employee_type',this.errors,'Designation')}}
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12" v-if="seenTrainerType">
                                            <div ref="trainer_type" class="form-group">
                                                <label for="trainer_type">Type of Training<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="trainer_type"
                                                        label="name"
                                                        v-validate="'required'"
                                                        @change="updateTrainer_type"
                                                        :value="item.trainer_type"
                                                        :options="trainerTypeAll"
                                                        multiple
                                                        />
                                                        <span class="error" v-show="errors.has('trainer_type')">
                                                        {{ $root.updateErrorMessage('trainer_type',this.errors,'Trainer Type')}}
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="col-md-6 col-xs-12">
                                            <div ref="shift_start_time" class="form-group">
                                                <label for="shift_start_time">Shift Start Time<p class="astrisk">*</p></label>
                                                <date-picker
                                                        :value="item.shift_start_time"
                                                        :config="$root.dpconfigTime"
                                                        name="shift_start_time"
                                                        v-validate="'required'"
                                                        placeholder="Select Shift Start Time"
                                                        @dp-change="updateShift_start_time"
                                                        autocomplete="off"
                                                        >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('shift_start_time')">
                                                        {{ $root.updateErrorMessage('shift_start_time',this.errors,'Shift Start Time')}}
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                            <div name="shift_end_time" class="form-group">
                                                <label for="shift_end_time">Shift End Time<p class="astrisk">*</p></label>
                                                <date-picker
                                                        :value="item.shift_end_time"
                                                        :config="$root.dpconfigTime"
                                                        name="shift_end_time"
                                                        v-validate="'required'"
                                                        placeholder="Select Shift End Time"
                                                        @dp-change="updateShift_end_time"
                                                        autocomplete="off"
                                                        >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('shift_end_time')">
                                                        {{ $root.updateErrorMessage('shift_end_time',this.errors,'Shift End Time')}}
                                                </span>
                                                <span class="error" v-show="errors.has('valid_end_time')">
                                                    {{ $root.updateErrorMessage('valid_end_time',this.errors)}}
                                                </span>                                    
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                            <div ref="joining_date" class="form-group">
                                                <label for="joining_date">Joining Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                        :value="item.joining_date"
                                                        :config="$root.dpconfigDate"
                                                        name="joining_date"
                                                        v-validate="'required'"
                                                        placeholder="Select Joining Date"
                                                        @dp-change="updateJoining_date"
                                                        autocomplete="off"
                                                        >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('joining_date')">
                                                            {{ $root.updateErrorMessage('joining_date',this.errors,'Joining Date')}}
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                            <div ref="exit_date" class="form-group">
                                                <label for="exit_date">Exit Date</label>
                                                <date-picker
                                                        :value="item.exit_date"
                                                        :config="exitDateOptionFormate"
                                                        name="exit_date"
                                                        placeholder="Select Exit Date"
                                                        @dp-change="updateExit_date"
                                                        autocomplete="off"
                                                        >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('exit_date')">
                                                            {{ $root.updateErrorMessage('exit_date',this.errors,'Exit Date')}}
                                                        </span>                                    
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-xs-12">
                                            <div class="form-group check-inline" >
                                                <label for="Course Vertical">Course Vertical :</label>
                                                <div v-for="(courseVertical, index) in courseVerticalAll" :key="index">
                                                        <input 
                                                                :id="'course_vertical_'+courseVertical.id"
                                                                type="checkbox"
                                                                name="course_vertical[]"
                                                                @change="updatedCourseVertical" 
                                                                :value="courseVertical.id" >
                                                        <label class="checkbox-label" :for="'course_vertical_'+courseVertical.id" style="font-weight: 400; cursor:pointer;">   &nbsp;{{courseVertical.name}}</label>
                                                </div>
                                            </div>
                                        </div> 
                                         <div class="col-md-12 col-xs-12">
                                            <div class="form-group check-inline">
                                            <label for="Course Category">Course Category : </label>
                                            <input 
                                                id="competition_batch" 
                                                name="competition_batch"
                                                @change="updatedCompetitionBatch" 
                                                type="checkbox" >
                                            <label class="checkbox-label" for="competition_batch" style="font-weight: 400; cursor:pointer;"> &nbsp;Competition Batch</label>
                                            </div>
                                        </div> 
                                         <div class="col-md-12 col-md-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                                type="submit"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div> 
                       
                    </div>
                </div>
            </div>
            <!-- <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Employee Login Detail</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                       
                        <div class="form-group col-md-6 col-xs-12">
                            <label for="username">Username<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="username"
                                    ref="username"
                                    maxlength="14"
                                    v-validate="'required|alpha_dash|min:6|max:14'"
                                    placeholder="Enter Username"
                                    :value="item.username"
                                    @input="updateUsername"
                                    >
                                    <span id="err_username" class="error" v-show="errors.has('username')">
                                    {{ $root.updateErrorMessage('username',this.errors, 'Username')}}
                                    </span>
                        </div>
                                                            
                        <div class="col-md-12 col-md-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div> 
                       
                    </div>
                </div>
            </div> -->
            </form>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import AddStatePopupEmployee from '../../dtmodules/PopupActions/AddStatePopupEmployee'

export default {
    components: { AddStatePopupEmployee },
    data() {
        return {
            statePopupButton: true,
            seenTrainerType: false,
            exitDateOptionFormate:{format:window.date_format_moment},
            dobDateOptionFormate:{format:window.date_format_moment, maxDate: this.getDOBDate()},
            courseVerticals:[],
        }
    },
    computed: {
        ...mapGetters('EmployeeMastersSingle', ['item', 'loading', 'countrymastersAll', 'statemastersAll','citymastersAll' ,'trainerTypeAll', 'employeeTypeAll', 'rolesAll', 'courseVerticalAll'])},
    created() {
        this.fetchCountrymastersAll(),
        this.fetchStatemastersAll(),
        // this.fetchCitymastersAll(),
        this.fetchTrainerTypeAll(),
        this.fetchEmployeeTypeAll(),
        this.fetchRolesAll(),
        this.fetchCourseVertical()        
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('EmployeeMastersSingle', ['storeData', 'resetState','setUsername', 'setEmployee_name', 'setBranch_id', 'setEmployee_address', 'setArea', 'setCity', 'setCountry', 'setState', 'setGender', 'setBirth_date', 'setMobile_no', 'setEmail_id', 'setHighest_education', 'setEmployee_type', 'setTrainer_type', 'setShift_start_time', 'setShift_end_time', 'setJoining_date', 'setExit_date', 'setDesignation', 'setUser_id', 'setRole', 'fetchCountrymastersAll', 'fetchStatemastersAll', 'fetchCitymastersAll', 'fetchTrainerTypeAll', 'fetchEmployeeTypeAll', 'fetchRolesAll','fetchCountryStates','fetchStatesCities','setStatemastersAll','setCitymastersAll','setProfile', 'setCourseVertical', 'setCompetitionBatch', 'fetchCourseVertical']),
         getDOBDate(){

            var d = new Date();
            var pastYear = d.getFullYear() - 15;
            d.setFullYear(pastYear);
            console.log(d);
            return d;
        },
        updatedCourseVertical(e){
            let carray = [];
            if(e.target.checked == true){
                this.courseVerticals.push(e.target.value);
            }else{
                const filtersList = this.courseVerticals.filter(element => element !== e.target.value)
                this.courseVerticals=filtersList;
            }
            carray = this.courseVerticals
            carray = carray.join(',');
            this.setCourseVertical(carray)
            //if(carray){ }
        },
        updatedCompetitionBatch(e){
            if(e.target.checked == true) {
                this.setCompetitionBatch(1)
            } else {
                this.setCompetitionBatch(0)
            }
        },
        updateUsername(e) {
            this.setUsername(e.target.value)
        },
        updateEmployee_name(e) {
            this.setEmployee_name(e.target.value)
        },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateEmployee_address(e) {
            this.setEmployee_address(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        // updateCity(e) {
        //     this.setCity(e.target.value)
        // },
        updateCountry(value) {
                if(value !== null){
                    this.fetchCountryStates(value.id);
                    this.statePopupButton = true;
                }            
                  else{
                    this.setStatemastersAll([]);
                    this.statePopupButton = false;
                  }
            this.setState(null)
            this.setCountry(value)
        },
        updateState(value) {                          
             if(value !== null){
                    this.fetchStatesCities(value.id);
                    // this.stateFlag = true
                }            
                  else{
                    this.setCitymastersAll([]);                    
                  }
            this.setCity(null)
            this.setState(value)
        },
        updateCity(value) {
            this.setCity(value)
        },
        updateGender(value) {
            this.setGender(value)
        },
        updateBirth_date(e) {
            this.setBirth_date(e.target.value)
        },
        updateMobile_no(e) {
            this.setMobile_no(e.target.value)
        },
        updateEmail_id(e) {
            this.setEmail_id(e.target.value)
        },
        updateHighest_education(e) {
            this.setHighest_education(e.target.value)
        },
        updateEmployee_type(value) {
            if(value && value.value_id == 1){
                this.seenTrainerType = true;
            }else{
                this.seenTrainerType = false;
            }
            this.setEmployee_type(value)
        },
        updateTrainer_type(value) {
            this.setTrainer_type(value)
        },
        updateShift_start_time(e) {
            this.setShift_start_time(e.target.value)
        },
        updateShift_end_time(e) {
            this.setShift_end_time(e.target.value)
        },
        isValidateEndTime(){
            var flag = false;
            if(this.item.shift_end_time !=null && this.item.shift_start_time !=null)
            {
                var endTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.shift_end_time);
                var startTime = '2011-01-01 '+this.$root.getTimeFormat(this.item.shift_start_time);
                if(endTime >= startTime){
                    flag = true;
                }
            }
            return flag
        },        
        updateJoining_date(e) {
            if(e && e.target.value != ''){            
                this.exitDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };
                this.setExit_date(null)
            }
            this.setJoining_date(e.target.value)
        },
        updateExit_date(e) {
            this.setExit_date(e.target.value)
        },
        updateDesignation(e) {
            this.setDesignation(e.target.value)
        },      
        updateRole(value) {
            this.setRole(value)
        },
        removeProfile(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setProfile('');
                     $("#profile").val('');
                }
            })
        }, 
        updateProfile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setProfile(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#profile").val('');
                            this.$validator.errors.remove('profile');
                            this.setProfile('');
                        }
                    })
                }
            }           
        }, 
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
                if(!this.isValidateEndTime())
                 {
                    this.$validator.errors.add({
                      id: 'valid_end_time',
                      field: 'valid_end_time',
                      msg: 'Shift End Time should be greater than Shift Start Time.',
                      scope: this.$options.scope,
                    });

                    return '';
                 }                
            this.storeData()
              .then((res) => {
                  if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // console.log("ke",key,value);
                            if( value !== null ) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                        }
                        return '';    
                    }        
                    //this.$router.push({ name: 'employee_masters.index' })
                    //setTimeout(function () { this.$router.push({ name: 'employee_masters.index' }) }.bind(this), 100)
                    this.$router.push({ name: 'employee_masters.index' })                   
                    this.$eventHub.$emit('create-success')
                })                    
                .catch((error) => {
                    console.error(error)
                })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });
        }
    }
}
</script>


<style scoped>
.popup-form.add-country{
    bottom:18px;
}
.check-inline{
    display: inline-flex;
}
.check-inline div{
    padding: 0 10px 0 10px;
}
.employee_image
{
    position: relative;
    width: 150px !important;
    height: 150px !important;
    object-fit: cover;
    margin: 25px 0px;
}

</style>
