<template>
    <div class="btn-group btn-group-xs">
        <router-link
            title="View"
            :to="{ name: xprops.route + '.show', params: { id: row.id } }"
            class="btn btn-success edit_delete_btn"
        >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
            title="Edit"
            :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
            class="btn btn-block btn-primary edit_delete_btn"
        >
            <i class="fa fa-fw fa-pencil"></i>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module
        }
    },
    created() {
        
    },
    attachStore (namespace) {
        
    }
}
</script>