function initialState() {
    return {
        studentAll: [],
        courseAll: [],
        listingAll: [],
        relationships: {},
        employeeAll: [],
        item: {
            course: null,
            student: null,
            joining_date: null,
            exit_date: null,
            employee_id: null,
        },        
        query: {},
        loading: false,
        error: false,
        studentCourseSchedule:[],
        projectDetail:[]
    }
} 

const getters = {
    item: state => state.item,    
    loading: state => state.loading,
    studentCourseSchedule: state => state.studentCourseSchedule,
    projectDetail:state => state.projectDetail,
    error: state => state.error
}

const actions = {
    async fetchStudentCourseSchedule({ commit },data) {
        await axiosLms.post('/api/v1/getStudentCourseSchedule',data)
            .then(response => {
                commit('setStudentCourseSchedule', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    async fetchScheduleProjectDetail({ commit },data) {
        await axiosLms.post('/api/v1/getStudentCourseScheduleProjectDetail',data)
            .then(response => {
                //console.log("response",response.data.data);
                commit('setScheduleProjectDetail', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setScheduleProjectDetails({ commit }){
        commit('setScheduleProjectDetail', [])
    }
}

const mutations = {
    setStudentCourseSchedule(state, events) {
        state.studentCourseSchedule = events
    },
    setScheduleProjectDetail(state,detail){
        state.projectDetail = detail
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
