var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container certificatePDF"},[_c('button',{staticClass:"btn btn-default float-right",attrs:{"id":"generatePDF"},on:{"click":function($event){return _vm.generatePDF()}}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":false,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"CertificatePDF","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-margin":[0,0,0,0],"pdf-orientation":"landscape","pdf-content-width":"950px","html-to-pdf-options":{margin: 0, left:0,},"htmlToPdfOptions":{
            filename: 'Certificate',
            margin: [0,0,0,0],
            scale: 1,
            dpi: 300,
            //pdfQuality:2,
           // pdfOrientation: 'landscape',
           jsPDF: { format: 'a4', orientation: 'landscape', quality:2 },
           html2canvas: {useCORS: true}
        }},on:{"progress":function($event){return _vm.onProgress($event)}}},[_c('div',{staticStyle:{"background-color":"white"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(_vm.item != null)?_c('div',{staticClass:"row",domProps:{"innerHTML":_vm._s(_vm.item)}}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }