<template>
    <form
        @submit.prevent="submitForm"
        novalidate
    >
        <div class="row">
                  <div class="col-md-12">
                    
                   
                    <span class="error" :v-show="errors.has('nocourseAdded')">
                    {{ 
                    $root.updateErrorMessage('nocourseAdded',this.errors)
                    }}
                    </span>
                    <div class="col-xs-12 add_course_section show">
                              <!--  <div class="card card-primary box_body_bg">
                                 <div class="card-header with-border">
                                   <h3 class="card-title">Course</h3>
                                 </div>
                                 </div> -->
                              <div class="form-group col-md-4 col-xs-12">
                                 <label for="birth_date">
                                    Course Enroll Date
                                    <p class="astrisk">*</p>
                                 </label>
                                 <!-- :disabled="true" -->
                                 <date-picker
                                    :value="studentEditCourseData.new_course_start_date"
                                    :config="$root.dpconfigDate"
                                    id="start_date"
                                    name="start_date"
                                   
                                    v-validate="'required'"
                                    placeholder="Select Course Enroll Date"
                                     autocomplete="off"                 
                                    >
                                 </date-picker>
                                 <span class="error" v-show="errors.has('start_date')">
                                 {{ $root.updateErrorMessage('start_date',this.errors,'Course Enroll Date')}}
                                 </span>
                              </div>
                              <section v-show="studentEditCourseData.start_date != ''">
                                  <!-- <div class="form-group col-md-12 col-xs-12">
                                    <label for="course_category">Course Category</label>
                                     <v-select
                                        name="course_category"
                                        label="name"
                                        value=""
                                        @change="filterCourse"
                                        placeholder="Select Course Category"
                                        :options="courseCategory"
                                        v-model="course_category"
                                        />
                                    <span class="error" v-show="errors.has('course_category')">
                                        {{ $root.updateErrorMessage('course_category',this.errors,'Course Category')}}
                                    </span>                                            
                                </div>   -->
                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="course">
                                       Course Name
                                       <p class="astrisk">*</p>
                                    </label>
                                    <v-select
                                       name="course"
                                       label="course_name"                                 
                                       :value="studentEditCourseData.course"
                                       :options="courseAll"
                                       :disabled="true"
                                       v-validate="'required'"
                                       />
                                    <span id="err_select_course" class="error" v-show="errors.has('course')">
                                    {{ $root.updateErrorMessage('course',this.errors)}}
                                    </span>
                                 </div>
                                 <!-- <div class="form-group col-xs-12 astrisk" v-if="feeCourseList.length == 0">
                                    Fee is not available for selected Course.
                                 </div> -->
                                 <div class="col-xs-12 col-md-12 course_detail_section">
                                    <!-- <div v-for="(fee, index) in studentEditCourseData.fee_type_details"> {{fee.fees_amount}}<br></div> -->
                                    <div class="card card-warning box_body_bg table-responsive">
                                      <!-- {{studentEditCourseData.length}} -->
                                       <table class="table table-bordered table-hover" v-if="Object.keys(studentEditCourseData).length > 0">
                                          <thead>
                                             <tr>
                                                <th>Fee Type</th>
                                                <!-- <th>Fee</th> -->
                                                <th style="display:none;">Discount</th>
                                                
                                                <th>Fee Amount</th>
                                                <th>GST Rate(%)</th>
                                                <th>GST Amount</th>
                                                <th>Final Total</th>
                                             </tr>
                                          </thead>
                                          <tbody>

                                             
                                              <tr  v-for="(fee, index) in studentEditCourseData.fee_type_details" :key="index">
                                                 <td>
                                                    {{fee.fee_type_name}}
                                                    <span v-if="studentEditCourseData.feeTypeAuth==fee.fees_type && parseFloat(fee.coupon_amount)>0" class="small d-block" >(Full Amount - Coupon Discount)</span>
                                                 </td>
                                                 <td> <i class="fa fa-rupee"></i> {{( studentEditCourseData.feeTypeAuth==fee.fees_type && parseFloat(fee.coupon_amount)>0 )?((fee.fees_amount-parseFloat(fee.coupon_amount))-fee.gst_amount).toFixed(2):(fee.fees_amount-fee.gst_amount)}}</td>
                                                 <td>{{(fee.gst_rate)?fee.gst_rate:0}}</td>
                                                 <td> <i class="fa fa-rupee"></i> {{fee.gst_amount}}</td>
                                                 <td> <i class="fa fa-rupee"></i> {{( studentEditCourseData.feeTypeAuth==fee.fees_type && parseFloat(fee.coupon_amount)>0 )?(fee.fees_amount-parseFloat(fee.coupon_amount)).toFixed(2):fee.fees_amount}}
                                                 <span v-if="studentEditCourseData.feeTypeAuth==fee.fees_type && parseFloat(fee.coupon_amount)>0" class="small d-block" >
                                                  ({{fee.fees_amount}} - {{parseFloat(fee.coupon_amount)}})
                                                </span>
                                                 </td>
                                              </tr>
                                             <!-- <tr class="error_tr">
                                                <td></td>
                                                <td><span class="error" v-show="errors.has('fee_amount')">
                                                   {{ $root.updateErrorMessage('fee_amount',this.errors)}}
                                                   </span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                             </tr> -->
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                  <div class="col-md-12 col-xs-12">
                                  <label for="birth_date">
                                      Fee Adjustment
                                  </label>
                                    <div class="row" v-if="fee_adjustments.length > 0">
                                      <span class="col-md-3 col-xs-12"><b>Fee Type</b></span>
                                      <span class="col-md-2 col-xs-12"><b>Fee Amount</b></span>
                                      <span class="col-md-2 col-xs-12"><b>GST Rate(%)</b></span>
                                      <span class="col-md-2 col-xs-12"><b>GST Amount</b></span>
                                      <span class="col-md-2 col-xs-12"><b>Final Amount</b></span>
                                      <span class="col-md-1 col-xs-12"></span>
                                    </div>
                                    <div class="row" v-for="(fee_adjustment,index) in fee_adjustments" :key="fee_adjustment.id">
                                      <div class="form-group col-md-3 col-xs-12"> 
                                        <v-select
                                        name="fee_type"
                                        label="name"                                 
                                        :options="feesTypeAvailableListAll"
                                        placeholder="Select Fee Type"
                                        v-model="fee_adjustment.fee_type"
                                        @change="setGSTAmount(index)"
                                        :disabled="fee_adjustment.isRemove == 1"
                                        />
                                         <!-- <input class="form-control" maxlength="10" v-else disabled v-model="fee_adjustment.fee_type.name" placeholder="Enter Fee Amount"> -->
                                      </div>
                                      <div class="form-group col-md-2 col-xs-12">
                                        <input class="form-control" maxlength="10" oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');" disabled v-model="fee_adjustment.fee_amount" placeholder="Enter Fee Amount">
                                      </div>
                                      <div class="form-group col-md-2 col-xs-12">
                                        <input class="form-control" maxlength="10" oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');" disabled v-model="fee_adjustment.gst_rate" placeholder="Enter GST Rate">
                                      </div>
                                      <div class="form-group col-md-2 col-xs-12">
                                        <input class="form-control" maxlength="10" disabled oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');" v-model="fee_adjustment.gst_amount" placeholder="Enter GST Amount">
                                      </div>
                                      <div class="form-group col-md-2 col-xs-12">
    
                                        <input class="form-control" maxlength="10" oninput="this.value=this.value
                                                      .replace(/[^\d.]/g, '')
                                                      .replace(/(\..*)\./g, '$1')
                                                      .replace(/(\.[\d]{2})./g, '$1');" @input="setGSTAmount(index)" :disabled="fee_adjustment.isRemove == 1" v-model="fee_adjustment.amount" placeholder="Enter Final Amount">
                                      </div>
                                      <div class="form-group col-md-1 col-xs-12">
                                       <button type="button" title="Remove Fee Adjustment" @click="removeFee(index)" v-if="fee_adjustment.isRemove == 0" class="btn btn-danger"><i class="fa fa-times"></i></button></button>
                                      </div>
                                    </div>
                                    <span class="error" style="display:block;" v-if="this.fee_adjustment_error != ''">
                                    {{ 
                                    fee_adjustment_error
                                    }}
                                    </span>
                                    <br>
                                    <button type="button" @click="AddField" class="btn btn-info">
                                      + Add Specific Fess
                                    </button>
                                </div>
                                 <span class="error" :v-show="errors.has('nocourseAdded')">
                                 {{ 
                                 $root.updateErrorMessage('nocourseAdded',this.errors,'Please add at least one course.')
                                 }}
                                 </span>
                                <div class="row mt-3">
                                  <div class="col-md-12 col-xs-12">
                                      <div class="form-group ds-flex">
                                        <div class="custom-control custom-radio mr-2">
                                          <input class="custom-control-input" disabled type="radio" id="customRadio2" name="customRadio" :checked="is_coupon" value="1">
                                          <label for="customRadio2" class="custom-control-label">Coupon Code</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input class="custom-control-input" type="radio" id="customRadio" name="customRadio" :checked="is_refferal" disabled value="2">
                                          <label for="customRadio" class="custom-control-label">Referral Code</label>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-12 col-xs-12" id="coupon_code" v-if="is_coupon">
                                      <div class="form-group ds-flex">
                                        <input
                                              disabled
                                              type="text"
                                              class="form-control"
                                              name="coupon_code"
                                              placeholder="Enter Coupon Code"
                                              v-model="studentEditCourseData.coupon_code" 
                                              >
                                      </div>
                                  </div>
                                  <div class="col-md-12 col-xs-12" id="refferal_code" v-if="is_refferal">
                                      <div class="form-group ds-flex">
                                        <input
                                              disabled
                                              type="text"
                                              class="form-control"
                                              name="refferal_code"
                                              placeholder="Enter Referral Code"
                                              v-model="studentEditCourseData.refferal_code" 
                                              >
                                      </div>
                                  </div>

                                  <div class="col-md-12 col-xs-12" id="">
                                      <div class="form-group ds-flex">
                                        <div class="custom-control custom-checkbox mr-2">
                                          <input disabled class="custom-control-input" type="checkbox" id="paidUsingCredit" name="paidUsingCredit" v-model="paidUsingCredit">
                                          <label for="paidUsingCredit" class="custom-control-label">Paid using credits</label>
                                        </div>
                                      </div>
                                  </div>

                                  <div class="col-md-12 col-xs-12" id="" v-if="studentEditCourseData.paid_using_credit>0">
                                      <div class="form-group">
                                        <input
                                              disabled
                                              type="text"
                                              class="form-control"
                                              id="final_pay_credit"
                                              maxlength="6"
                                              v-model="studentEditCourseData.paid_using_credit"
                                              placeholder="Please Enter Credits"
                                              >
                                      </div>
                                  </div>

                                </div>
                              </section>
                              <!-- ######### Fee List - End ############ -->
                              <!-- <div class="form-group col-xs-12" v-if="feeSeen && addButtonSeen && (feeCourseList.length > 0)?true:false && feeCourseList.length > 0 "> 
                                 <div class="row">
                                     <div class="col-md-3 col-xs-12">
                                          <v-select
                                             name="feesTypeAvailable"
                                             label="name"
                                             :options="feesTypeAvailableListAll"
                                             v-model="inputAddMoreFeeTypeParams"
                                             />
                                             <span class="error" :v-show="errors.has('addMoreFeesType')">
                                             {{ 
                                             $root.updateErrorMessage('addMoreFeesType',this.errors)
                                             }}
                                         </span>
                                     </div>
                                      <div class="form-group col-md-1 col-xs-12">
                                         <button  type="button" class="btn btn-primary text-left"  v-on:click="addCourseFeesData()">
                                             Add More
                                         </button>
                                     </div>
                                     <div class="clearfix"></div>
                                 
                                 </div>
                                 <div class="row">
                                     <div class="col-md-3 col-xs-12">
                                     </div>
                                 </div>
                                 </div> -->
                              <!-- <div class="col-xs-12 col-md-12 savebtn back">
                                 <button 
                                    type="submit" 
                                    class="btn btn-block btn-primary add_course_save std_information_save"
                                    
                                    >SAVE COURSE
                                 </button>
                              </div> -->
                           </div>
                    
                  </div>
               </div>
               <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>


<script>
import { mapGetters, mapActions } from "vuex";


export default {
  props:['id'],
  components: {
  },
  data() {
    return {
      fee_adjustment_error:'',
      course_category:'',
      onlySummerCamp: true,
      addButtonSeen: false,
      statePopupButton: true,
      hasError: false,
      courseForm: false,
      courseList: true,
      arrIndex: false,
      inputParams: [],
      inputAddMoreFeeTypeParams: null,
      deleteFeeDetailParams: [],
      inputFeeTypeParams: [],
      inputFeeAmountParams: [],
      inputFeeDiscountParams: [],
      inputFeeGSTValue: [],
      inputFeeGSTParams: [],
      inputFeeTotalParams: 0,
      newsubTotal: [],
      subTotal: [],
      subFinalTotal: [],
      fee_list: [],
      inqCourse: "",
      saveStartDate: "",
      inqBatchTime: "",
      courseEdit: false,
      courseEditIndex: "",
      preferDaysChecked: [],
      checkedEdit: true,
      diabledEdit: false,
      seenStudentReason: false,
      feeAvailableCourseList: [],
      addbtnHide: 0,
      feeSeen: false,
      dobDateOptionFormate: {
        format: window.date_format_moment,
        maxDate: this.getDOBDate()
      },
      fee_adjustments: [],
      is_coupon:false,
      is_refferal:false,
      paidUsingCredit:false,
      // Code...
    };
  },

  computed: {
    ...mapGetters("StudentSingle", [
      "item",
      "loading",
      "feeAdditionalAdjustment",
      "countrymastersAll",
      "statemastersAll",
      "citymastersAll",
      "parentAll",
      "tshirtSizesAll",
      "schoolAll",
      "standardAll",
      "studentStatusAll",
      "studentReasonAll",
      "statusAll",
      "reasonAll",
      "courseListAll",
      "courseAll",
      "batchAll",
      "feeCourseList",
      "feesTypeAvailableListAll",
      "courseComboDetailsAll",
      "branchAdmissionFeesApplicable",
      "referenceAll",
      'courseCategory',
      'studentEditCourseData', "coupon_code", "refferal_code"
    ])
  },
  async created() {
    this.fetchTypes();
    // console.log("this",this.id)
    this.fetchFeesTypeAvailableListAll(this.id);
    this.fetchStudenCourseFeeEdit(this.id);
   // console.log(this.studentEditCourseData.adjusment_details);
   
    // this.fetchCountrymastersAll();
     // this.fetchFeeAdditionalAdjustmentCharge(),
      // this.fetchStatemastersAll(),
      // this.fetchCitymastersAll(),
      // this.fetchParentAll(),
      // this.fetchTshirtSizesAll(),
      // this.fetchSchoolAll(),
      // this.fetchStandardAll(),
      // this.fetchStudentStatusAll(),
      // this.fetchStudentReasonAll(),
      // this.fetchCourseAll(),
      // this.fetchReferenceAll(),
      // this.fetchFeesTypeAvailableListAll();
    // this.fetchBranchMasterAll();

    //this.fetchStatusAll()
  },
  destroyed() {
    this.resetState();
  },
  watch: {
     studentEditCourseData(value){
        let _this = this;
        // console.log("sdds",value.adjusment_details)
        value.adjusment_details.map((fee, feeIndex)=>{
          //console.log("sdsd",fee)
          _this.fee_adjustments.push({ fee_type: fee.fee_type,amount: fee.fees_amount,isEdit:true,isRemove:fee.is_remove });
            ///console.log("dff",fee,feeIndex)
            
        });
        this.paidUsingCredit = false;
        if(value.paid_using_credit>0){
          this.paidUsingCredit=true;
        }
        this.changeCode(value.refferal_code,value.coupon_code);
      },
    // "$route.params.id": function() {
    //   this.resetState();
    //   this.fetchData(this.$route.params.id);
    // }
  },
  methods: {
    ...mapActions("StudentSingle", [
      "fetchTypes",
      "storeCourseData",
      "fetchBranchMasterAll",
      "resetState",
      "setOnlySummerCampGlobal",
      "setBranch_id",
      "setEnrollDate",
      "setFirst_name",
      "setMiddle_name",
      "setLast_name",
      "setStudent_address",
      "setArea",
      "setCountry_list",
      "setState_list",
      "setCity_list",
      "setGender",
      "setPin_code",
      "setBirth_date",
      "setParent",
      "setTshirt",
      "setTshirt",
      "setSchool",
      "setStudentSta",
      "setStandard_id",
      "setStudent_status",
      "setStudent_reason",
      "setStatus",
      "setNext_followup_date",
      "setReason",
      "setStatus_remarks",
      "fetchFeeAdditionalAdjustmentCharge",
      "fetchCountrymastersAll",
      "fetchStatemastersAll",
      "fetchCitymastersAll",
      "fetchParentAll",
      "fetchTshirtSizesAll",
      "fetchSchoolAll",
      "fetchStandardAll",
      "fetchCourseAll",
      "setCourseListAll",
      "setCourse",
      "fetchBatchAll",
      "fetchFeeCourseList",
      "setFeeCourseList",
      "setBatch",
      "fetchStudentStatusAll",
      "fetchStudentReasonAll",
      "fetchCountryStates",
      "fetchStateCities",
      "setStart_date",
      "fetchFeesTypeAvailableListAll",
      "setFeesTypeAvailableListAll",
      "setCourseAll",
      "setStatemastersAll",
      "setCitymastersAll",
      "fetchComboFromCourseDetails",
      "setProfile",
      "fetchIfBranchAdmissionFeesApplicable",
      "setReference",
      "fetchReferenceAll",
      "fetchBranchCourse",
      "fetchStudenCourseFeeEdit",
      "updateCourseData"
    ]),
    changeCode(refferal_code,coupon_code){
      this.is_coupon = false
      this.is_refferal = false
      if(refferal_code){
        this.is_refferal = true
      }else{
        if(coupon_code){
          this.is_coupon = true
        }
      }
    },
     setGSTAmount(index){
      // console.log("u")
      let fee_obj = this.fee_adjustments[index].fee_type;
      if(fee_obj != '' && fee_obj != null){
       //console.log("dsdsd",this.fee_adjustments[index].amount,this.fee_adjustments[index].gst_rate);
        this.fee_adjustments[index].gst_rate = fee_obj.other_value;
        this.fee_adjustments[index].gst_amount = this.countExistGSTValue(this.fee_adjustments[index].amount,this.fee_adjustments[index].gst_rate);
        this.fee_adjustments[index].fee_amount = this.fee_adjustments[index].amount - this.fee_adjustments[index].gst_amount;
      }
      else{
        // console.log("hsdsd else");
        this.fee_adjustments[index].gst_rate = 0;
        this.fee_adjustments[index].gst_amount = this.countExistGSTValue(this.fee_adjustments[index].amount,this.fee_adjustments[index].gst_rate);
        this.fee_adjustments[index].fee_amount = this.fee_adjustments[index].amount - this.fee_adjustments[index].gst_amount;
      }
      
    },
    removeFee(index){
      //console.log("index",index);
     // return false;
      this.fee_adjustment_error = '';
      //console.log("index",index);
      this.fee_adjustments.splice(index, 1);

    },
    AddField() {
      // console.log("123")
      this.fee_adjustments.push({fee_type: null,fee_amount:0,gst_rate:0,gst_amount:0,amount: 0,isEdit:false,isRemove:0 });
    },
    closeModel(){
            this.$emit('closeModel');
        },
    disabledDates() {
      from: new Date(Date.now());
    },
    /*this function use to store fees total*/
    sumTotalFee() {
      var ifOnlySummerCampLocal = true;
      var total = 0;
      var total1 = 0;
      if (this.feeAdditionalAdjustment.length > 0) {
        var total1 = this.feeAdditionalAdjustment[0].additionalFees;
      }
      var onlyFiveArray = [],
        otherThanFiveArray = [],
        allCourseType = [];
      var filteredObj = this.courseListAll.find(function(item, i) {
        total = parseFloat(total) + parseFloat(item.total_fees);
        // if(item.course_level != 5){
        //   otherThanFiveArray.push(item.course_level);
        // } else {
        //   onlyFiveArray.push(item.course_level);
        // }
        if (allCourseType.indexOf(item.course_type) == -1) {
          allCourseType.push(item.course_type);
        }
      });
      this.fetchIfBranchAdmissionFeesApplicable(allCourseType).then(() => {
        // ifOnlySummerCampLocal = this.branchAdmissionFeesApplicable[0];
        this.onlySummerCamp = this.branchAdmissionFeesApplicable[0];
        this.setOnlySummerCampGlobal(this.branchAdmissionFeesApplicable[0]);
      });
      // if(otherThanFiveArray.length == 0 && onlyFiveArray.length >= 1) {
      //     ifOnlySummerCampLocal = false;
      // }

      // this.onlySummerCamp = ifOnlySummerCampLocal;
      // this.setOnlySummerCampGlobal(ifOnlySummerCampLocal);
      if (this.onlySummerCamp) {
        total1 = parseFloat(total1) + parseFloat(total);
        // return this.sumSubTotal = total1.toFixed(2);
        return "";
      } else {
        // return this.sumSubTotal = total.toFixed(2);
        return "";
      }
    },

    //this is use for add more  btn from add or edit course
    // setCourseListAll
    updateFeeAmount(index) {
      if (!this.inputFeeAmountParams[index]) {
        this.inputFeeDiscountParams[index] = "";
        this.calculateNewDiscount(index);
      } else if (
        parseFloat(this.inputFeeAmountParams[index]) <
        parseFloat(this.inputFeeDiscountParams[index])
      ) {
        this.inputFeeDiscountParams[index] = "";
        this.calculateNewDiscount(index);
      }
    },
    addCourseFeesData(myCustomeIndex) {
      if (
        !this.inputAddMoreFeeTypeParams &&
        (!this.courseEdit || typeof myCustomeIndex == "undefined")
      ) {
        this.$validator.errors.remove("addMoreFeesType");
        this.$validator.errors.remove("payment_amount_check");
        this.$validator.errors.add({
          id: "addMoreFeesType",
          field: "addMoreFeesType",
          msg: "Fees Type field is required.",
          scope: this.$options.scope
        });
        return true;
      } else {
        this.$validator.errors.remove("addMoreFeesType");
        if (this.inputAddMoreFeeTypeParams) {
          // this.inputFeeTypeParams[this.feeAvailableCourseList.length-1] =  this.inputAddMoreFeeTypeParams;  ahsish logic
          this.inputFeeTypeParams[
            this.inputAddMoreFeeTypeParams &&
            this.inputAddMoreFeeTypeParams.value_id
              ? this.inputAddMoreFeeTypeParams.value_id
              : this.deleteFeeDetailParams[myCustomeIndex]
          ] = this.inputAddMoreFeeTypeParams;
        }
        this.feeAvailableCourseList.push({
          deleteFeeDetailParams: myCustomeIndex
            ? myCustomeIndex
            : this.inputAddMoreFeeTypeParams.value_id
        });
        // this.feeAvailableCourseList.push({'deleteFeeDetailParams':(this.inputAddMoreFeeTypeParams && this.inputAddMoreFeeTypeParams.value_id)?this.inputAddMoreFeeTypeParams.value_id:this.deleteFeeDetailParams[index]});
        this.addbtnHide = this.addbtnHide + 1;
        setTimeout(
          function() {
            var feesTypeUseList = "";
            feesTypeUseList = purify(this.feesTypeAvailableListAll);

            this.feeCourseList.forEach(
              function(fee, index) {
                feesTypeUseList.forEach(
                  function(allFee, allIndex) {
                    var feeValueId = null;
                    if (typeof fee.fees_type.value_id == "undefined") {
                      feeValueId = fee.fees_type;
                    } else {
                      feeValueId = fee.fees_type.value_id;
                    }
                    if (allFee.value_id == feeValueId) {
                      feesTypeUseList.splice(allIndex, 1);
                    }
                  }.bind(this)
                );
              }.bind(this)
            );
            /*added value*/
            this.feeAvailableCourseList.forEach(
              function(selectedCourseList, addedIndex) {
                feesTypeUseList.forEach(
                  function(allFee, allIndex) {
                    if (
                      selectedCourseList.deleteFeeDetailParams &&
                      this.inputFeeTypeParams[
                        selectedCourseList.deleteFeeDetailParams
                      ] &&
                      this.inputFeeTypeParams[
                        selectedCourseList.deleteFeeDetailParams
                      ].value_id == allFee.value_id
                    ) {
                      feesTypeUseList.splice(allIndex, 1);
                    }
                  }.bind(this)
                );
              }.bind(this)
            );
            this.inputAddMoreFeeTypeParams = null;
            /*end added value*/

            if (feesTypeUseList.length == 0) {
              this.addButtonSeen = false;
            } else {
              this.addButtonSeen = true;
            }

            this.setFeesTypeAvailableListAll(feesTypeUseList);
          }.bind(this),
          440
        );
      }
    },
    //this is use for remove btn from add or edit course
    removeNewCourseFeesData(myCustomeIndex) {
      // this.addbtnHide = this.addbtnHide - 1;
      // this.feeAvailableCourseList.splice(index,1);
      // this.addbtnHide = this.addbtnHide - 1;
      // $('#deletetTr').find('tr[data-myCustomeIndex='+myCustomeIndex+']').remove();

      // $('#deletetTr').find('tr[data-index='+myCustomeIndex+']').remove();
      // this.feeAvailableCourseList.splice(myCustomeIndex, 1);
      this.feeAvailableCourseList.forEach(
        function(selectedCourseList, addedIndex) {
          if (
            selectedCourseList &&
            selectedCourseList.deleteFeeDetailParams == myCustomeIndex
          ) {
            delete this.inputFeeTypeParams[myCustomeIndex];
            delete this.deleteFeeDetailParams[myCustomeIndex];
            delete this.inputFeeDiscountParams[myCustomeIndex];
            delete this.inputFeeAmountParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            this.feeAvailableCourseList.splice(addedIndex, 1);
            return false;
          }
        }.bind(this)
      );
      this.fetchFeesTypeAvailableListAll().then(res => {
        var feesTypeUseList = "";
        feesTypeUseList = purify(this.feesTypeAvailableListAll);

        this.feeCourseList.forEach(
          function(fee, index) {
            feesTypeUseList.forEach(
              function(allFee, allIndex) {
                var feeValueId = null;
                if (typeof fee.fees_type.value_id == "undefined") {
                  feeValueId = fee.fees_type;
                } else {
                  feeValueId = fee.fees_type.value_id;
                }
                if (allFee.value_id == feeValueId) {
                  feesTypeUseList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );
        /*added value*/
        this.feeAvailableCourseList.forEach(
          function(selectedCourseList, addedIndex) {
            feesTypeUseList.forEach(
              function(allFee, allIndex) {
                if (
                  selectedCourseList.deleteFeeDetailParams &&
                  this.inputFeeTypeParams[
                    selectedCourseList.deleteFeeDetailParams
                  ] &&
                  this.inputFeeTypeParams[
                    selectedCourseList.deleteFeeDetailParams
                  ].value_id == allFee.value_id
                ) {
                  feesTypeUseList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );
        this.inputAddMoreFeeTypeParams = null;
        if (feesTypeUseList.length == 0) {
          this.addButtonSeen = false;
        } else {
          this.addButtonSeen = true;
        }
        this.setFeesTypeAvailableListAll(feesTypeUseList);
      });
      //setTimeout(function () {

      //}.bind(this), 740)
    },
    updateStart_date(e) {
      //console.log("e cgabge",e)
      if (e && e.target) {
        this.setStart_date(e.target.value);
        this.fetchBranchCourse({"enroll_date":e.target.value,"course_category":this.course_category});
      } else {
        if (e) {
          this.setStart_date(e);
        } else {
          this.setStart_date(null);
        }
      }
      this.fetchBatch(this.item.course);
    },
    filterCourse(){
      this.fetchBranchCourse({"enroll_date":this.item.start_date,"course_category":this.course_category});
    },
    calculateDiscount(feeAmount, index) {
      if (parseFloat(this.inputParams[index]) > feeAmount) {
        this.inputParams[index] = parseFloat(feeAmount).toFixed(2);
      }
      // else{
      //     if(this.inputParams[index] =='')
      //     {
      //         this.inputParams[index] = "0";
      //     }
      // }
    },
    calculateNewDiscount(index) {
      if (!this.inputFeeAmountParams[index]) {
        this.inputFeeDiscountParams[index] = "";
      } else if (
        parseFloat(this.inputFeeDiscountParams[index]) >
        parseFloat(this.inputFeeAmountParams[index])
      ) {
        this.inputFeeDiscountParams[index] = parseFloat(
          this.inputFeeAmountParams[index]
        ).toFixed(2);
      }
    },
    getDOBDate() {
      var d = new Date();
      var pastYear = d.getFullYear() - 5;
      d.setFullYear(pastYear);
      return d;
    },
    updateBranch_id(e) {
      this.setBranch_id(e.target.value);
    },
    updateEnrollDate(e) {
      this.setEnrollDate(e);
    },
    submitForm() {
      this.fee_adjustment_error = '';
      this.$validator.validate().then(result => {
        if (result) {
          // if (this.courseListAll.length == 0) {
          //   this.$validator.errors.add({
          //     id: "nocourseAdded",
          //     field: "nocourseAdded",
          //     msg: "At least one course need to added.",
          //     scope: this.$options.scope
          //   });
          //   return false;
          // } else {
          //   this.$validator.errors.remove("nocourseAdded");
          // }
          this.saveCourse()
          if((this.fee_adjustments).length != 0){
            let fee_type_ids = [];
            let _this = this;
            this.fee_adjustments.forEach(function(fee, feeIndex){
              if((fee.amount == '' || fee.amount == 0) || (fee.fee_type == '' || fee.fee_type == null)){
                _this.fee_adjustment_error = "Please select proper values.";
                return false;
              }
              if(fee_type_ids.includes(fee.fee_type.id)){
                _this.fee_adjustment_error = "Fee adjustment fee type already exist.";
                return false;
              }
              fee_type_ids.push(fee.fee_type.id);
            });
          }

          if(this.fee_adjustment_error !=''){
            return false;
          }
          
          this.updateCourseData({'id':this.id,'fee_adjustments':this.fee_adjustments})
            .then(res => {
              if (!res.data.result) {
                this.$validator.errors.add({
                  id: "enroll_data",
                  field: "enroll_data",
                  msg: res.data.message,
                  scope: this.$options.scope
                });
                return this.$root.handleValidationFocus(
                  this.errors,
                  this.$refs
                );
              }
              // this.$router.push({ name: "student.index" });
              this.$emit('closeModel');
              this.$emit('loadItems');
              this.$eventHub.$emit("update-success");
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          return this.$root.handleValidationFocus(this.errors, this.$refs);
        }
      });
    },

    removeCourceList(id) {
      //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
      var courceListLocal = [];
      courceListLocal = purify(this.courseListAll);
      courceListLocal.splice(id, 1);
      this.setCourseListAll(courceListLocal);
      var sendCourseData = [0]; //temp solution for listing all course after removing selected course.
      this.fetchComboFromCourseDetails(sendCourseData).then(res => {
        if (this.courseComboDetailsAll) {
          this.setCourseAll(this.courseComboDetailsAll);
        }
      });
    },
    updatePreferDays(e) {
      this.checkedEdit = false;
      var updatePermissionVal = e.target.value.toString();
      var indexVal = $.inArray(updatePermissionVal, this.preferDaysChecked);
      if (indexVal == -1) {
        this.preferDaysChecked.push(updatePermissionVal);
      } else {
        this.preferDaysChecked.splice(indexVal, 1);
      }
    },
    checkedPreferDays(value) {
      if (this.checkedEdit && this.courseEdit) {
        var preferdaysLocal = "";
        preferdaysLocal = purify(
          this.courseListAll[this.courseEditIndex].prefer_days
        );
        preferdaysLocal = preferdaysLocal.split(",");

        var ret = preferdaysLocal.filter(function(el) {
          return el == value;
        });
        if (ret.length > 0) {
          if ($.inArray(ret[0], this.preferDaysChecked) == -1) {
            this.preferDaysChecked.push(ret[0]);
            return true;
          }
        }
      }
    },
    addCource() {
      this.seenStudentReason = false; //default false student reason
      this.fetchFeesTypeAvailableListAll(); //fetch fees list data
      this.$validator.errors.remove("course"); //when course cancel
      this.$validator.errors.remove("nocourseAdded"); //when course not available
      this.diabledEdit = false;
      //delete this.feeList;
      // var p = this.fetchCourseAll();
      // this.setCourseAll(p);

      //This code is used for preventing duplicate entry in Course & fee information (and also prevents adding Full course and individual course individually)
      var courseSelectedList = "";
      //delete this.feeList;
      courseSelectedList = purify(this.courseAll);
      var sendCourseData = [];
      this.courseListAll.forEach(function(courseList, index) {
        sendCourseData.push(courseList.course_id);
      });
      if (sendCourseData.length > 0) {
        this.fetchComboFromCourseDetails(sendCourseData).then(res => {
          if (this.courseComboDetailsAll) {
            this.setCourseAll(this.courseComboDetailsAll);
          }

          // this.fetchCourseAll()
          // .then((res) => {
          //     var courseSelectedList = '';
          //     courseSelectedList = purify(this.courseAll);
          //     this.courseListAll.forEach(function(courseList,index){
          //             courseSelectedList.forEach(function(selectedCourseList,allIndex){
          //             if(courseList.course_id == selectedCourseList.id)
          //             {
          //                 courseSelectedList.splice(allIndex,1)
          //             }
          //         }.bind(this))
          //     }.bind(this))
          //     if(courseSelectedList){
          //         this.setCourseAll(courseSelectedList);
          //     }
        });
      }
      this.feeAvailableCourseList = [];
      this.courseEdit = false;

      this.setCourse(null);
      this.setBatch(null);
      this.setStart_date(null);
      this.setStudent_status(null);
      this.setStudent_reason(null);

      // this.newsubTotal=[];

      this.inputParams = [];
      this.inputFeeTypeParams = [];
      this.inputFeeDiscountParams = [];
      this.inputFeeAmountParams = [];
      this.inputFeeGSTParams = [];
      this.courseEditIndex = "";
      this.fee_list = [];
      this.setFeeCourseList("");
      this.inputParams = [];
      this.subTotal = [];

      this.courseForm = true;
      this.courseList = false;
      this.arrIndex = false;
    },
    editCource(id) {
      this.$validator.errors.remove("nocourseAdded"); //when course not available
      //delete this.feeList;
      //this.fetchFeesTypeAvailableListAll(); //fetch fees list data
      // this.setCourseAll();
      this.checkedEdit = true;
      this.courseEdit = true;
      this.diabledEdit = true;
      this.courseEditIndex = id;
      this.deleteFeeDetailParams = [];
      this.inputParams = [];
      this.inputFeeTypeParams = [];
      this.inputFeeDiscountParams = [];
      this.inputFeeAmountParams = [];
      this.inputFeeGSTParams = [];
      var courceListLocal = [];
      this.fetchCourseAll().then(res => {
        courceListLocal = purify(this.courseListAll);
        var courceLocal = {
          id: courceListLocal[id]["course_id"],
          course_level: courceListLocal[id]["course_level"],
          course_type: courceListLocal[id]["course_type"],
          enroll_course_status: courceListLocal[id]["enroll_course_status"],
          display_course_name: courceListLocal[id]["display_course_name"],
          student_course_id: courceListLocal[id].student_course_id
            ? courceListLocal[id].student_course_id
            : courceListLocal[id]["id"]
        };

        var batchLocal = {
          id: courceListLocal[id]["prefer_batch_id"],
          batch_time: courceListLocal[id]["batch_time"]
        };
        var feeDiscountLocal = [];
        var new_fees_index = 0;
        /*
                Below code is use for the remove fees list from array
            */
        var courseSelectedList = "";
        courseSelectedList = purify(this.courseAll);
        this.courseListAll.forEach(
          function(courseList, index) {
            courseSelectedList.forEach(
              function(selectedCourseList, allIndex) {
                if (
                  courseList.course_id == selectedCourseList.id &&
                  courceListLocal[id]["course_id"] != selectedCourseList.id
                ) {
                  courseSelectedList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );

        if (courseSelectedList) {
          this.setCourseAll(courseSelectedList);
        }
        /*End fees list code*/
        var studentCourseCommanData = courceListLocal[id].course_status;
        var studentCourseReasonCommonData = "";
        if (courceListLocal[id] && courceListLocal[id].status_reason) {
          var studentCourseReasonCommonData = courceListLocal[id].status_reason;
        }
        //this.feeList = courceListLocal[id].fee_list
        //this is use when add more fees detail that time run following code(Rajesh Rathod 02-10-2018)
        courceListLocal[id].fee_list.forEach(
          function(fee, index) {
            // feeDiscountLocal[index] = fee.discount;
            if (fee && fee.is_manual_fee === 1) {
              this.inputFeeTypeParams[fee.fees_type.value_id] = fee.fees_type;
              this.deleteFeeDetailParams[fee.fees_type.value_id] =
                fee.fees_type.value_id;
              //this.inputFeeTypeParams[new_fees_index]= fee.fees_type.name;
              this.inputFeeDiscountParams[fee.fees_type.value_id] =
                fee.discount;
              this.inputFeeAmountParams[fee.fees_type.value_id] =
                fee.fees_amount;
              this.inputFeeGSTParams[fee.fees_type.value_id] = fee.gst_rate;
              // this.addCourseFeesData(fee.fees_type.value_id);
              setTimeout(
                function() {
                  this.addCourseFeesData(fee.fees_type.value_id);
                }.bind(this),
                420
              );
              new_fees_index++;
            } else {
              if (fee && fee.discount) {
                feeDiscountLocal[index] = fee.discount;
              }
            }
          }.bind(this)
        );

        // this.inputParams = feeDiscountLocal;
        this.setCourse(courceLocal);
        this.updateStart_date(courceListLocal[id].course_start_date);
        this.updateStudent_status(studentCourseCommanData);
        this.updateStudent_reason(studentCourseReasonCommonData);
        this.fetchBatch(courceLocal);
        this.updateBatch(batchLocal);

        this.courseForm = true;
        this.courseList = false;
        this.arrIndex = id;
        setTimeout(
          function() {
            this.assignDiscount(feeDiscountLocal);
          }.bind(this),
          420
        );
      });
    },
    assignDiscount(feeDiscountLocal) {
      this.inputParams = feeDiscountLocal;
    },
    backCourse() {
      this.$validator.errors.remove("addMoreFeesType");
      this.fetchFeesTypeAvailableListAll(); //re fetch fees list data
      //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select  course dropdown
      this.checkedEdit = false;
      this.courseEdit = false;
      this.courseEditIndex = false;
      this.courseForm = false;
      this.courseList = true;
      this.arrIndex = false;
      this.feeAvailableCourseList = [];
    },
    saveCourse() {
      //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
      this.$validator.errors.remove("nocourseAdded"); //when course not available
      this.hasError = false;
      var course = {};
      // course.prefer_days = this.preferDaysChecked.join();
      if (this.inqCourse == null || typeof this.inqCourse.id === "undefined") {
        this.$validator.errors.remove("course");
        this.$validator.errors.add({
          id: "err_select_course",
          field: "course",
          msg: "Course Name field is required.",
          scope: this.$options.scope
        });
        this.hasError = true;
      } else {
        this.$validator.errors.remove("course");
      }

      if (this.hasError) {
        return false;
      }
      course.course_level = this.inqCourse.course_level;
      course.course_type = this.inqCourse.course_type;
      course.course_id = this.inqCourse.id;
      course.course_type = this.inqCourse.course_type;
      course.display_course_name = this.inqCourse.display_course_name
        ? this.inqCourse.display_course_name
        : "";
      // course.student_course_id = this.inqCourse.student_course_id;
      course.course_start_date = this.item.start_date
        ? this.item.start_date
        : "";
      course.course_status = this.item.student_status
        ? this.item.student_status
        : "";
      course.status_reason =
        this.item.student_reason &&
        this.item.student_reason &&
        this.item.student_status.value_id == 2
          ? this.item.student_reason
          : "";

      // var totalFee = 0;
      // this.fee_list.forEach(function(fee){
      //     if(parseFloat(fee.subtotal) > 0)
      //     {
      //         totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
      //     }
      //     else
      //     {   totalFee = parseFloat(totalFee) + parseFloat(fee.fees_amount);
      //     }

      // })
      // this.newsubTotal.forEach(function(fee,index){
      //     totalFee = parseFloat(totalFee) + parseFloat(fee);
      // })
      // course.total_fees = totalFee.toFixed(2);

      var new_fees_detail = [];
      var errFlag = false;
      //this is use when add more fees detail that time run following code(Rajesh Rathod 02-10-2018)
      this.feeAvailableCourseList.forEach(
        function(fee, index) {
          new_fees_detail[fee.deleteFeeDetailParams] = {};
          new_fees_detail[fee.deleteFeeDetailParams].is_manual_fee = 1; //this is use for flag add more value available
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].student_course_id = this.inqCourse.student_course_id;
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].course_id = this.inqCourse.id;
          new_fees_detail[fee.deleteFeeDetailParams].deleteFeeDetailParams =
            fee.deleteFeeDetailParams;
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].discount = this.inputFeeDiscountParams[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].fees_amount = this.inputFeeAmountParams[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].fees_type = this.inputFeeTypeParams[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].gst_rate = this.inputFeeGSTParams[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].total_fees = this.newsubTotal[fee.deleteFeeDetailParams];
          new_fees_detail[
            fee.deleteFeeDetailParams
          ].fees_after_discount = this.newsubtotalCalc(
            fee.deleteFeeDetailParams
          );
          new_fees_detail[fee.deleteFeeDetailParams].subtotal =
            new_fees_detail[fee.deleteFeeDetailParams].fees_after_discount;
          if (
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == "" ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == null ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_amount == 0 ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_type == null ||
            new_fees_detail[fee.deleteFeeDetailParams].fees_type == "undefined"
          ) {
            errFlag = true;
          }
        }.bind(this)
      );
      if (errFlag) {
        this.$validator.errors.remove("addMoreFeesType");
        this.$validator.errors.add({
          id: "fee_amount",
          field: "fee_amount",
          msg: "Fee amount should be valid.",
          scope: this.$options.scope
        });
        return false;
      } else {
        this.$validator.errors.remove("fee_amount");
      }
      this.fetchFeesTypeAvailableListAll(); //fetch fees list data
      this.inputAddMoreFeeTypeParams = null;
      course.fee_list = this.fee_list.concat(new_fees_detail);
      var courceListLocal = [];

      /**********************/
      var totalFee = 0;
      course.fee_list.forEach(function(fee) {
        if (fee.subtotal) {
          totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
        } else {
          totalFee = parseFloat(totalFee) + parseFloat(fee.fees_amount);
        }
        //totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
      });
      course.total_fees = totalFee.toFixed(2);
      /**********************/

      courceListLocal = purify(this.courseListAll);
      if (this.courseEdit === true) {
        courceListLocal.splice(this.courseEditIndex, 1);
        this.courseEdit = false;
        this.courseEditIndex = "";
      }
      courceListLocal.push(course);
      this.setCourseListAll(courceListLocal);

      this.courseForm = false;
      this.courseList = true;
      this.arrIndex = false;
      this.feeAvailableCourseList = [];
    },
    updateCourse(value) {
      this.fetchFeesTypeAvailableListAll();
      //.then((res) => {
      //this.setCourse(value);
      if (!this.courseEdit) {
        this.feeAvailableCourseList = []; /*30-10-2018*/
      }

      if (value) {
        this.addButtonSeen = true;

        //setTimeout(function () {
        var feesTypeUseList = purify(this.feesTypeAvailableListAll);
        this.feeCourseList.forEach(
          function(fee, index) {
            feesTypeUseList.forEach(
              function(allFee, allIndex) {
                var feeValueId = null;
                if (typeof fee.fees_type.value_id == "undefined") {
                  feeValueId = fee.fees_type;
                } else {
                  feeValueId = fee.fees_type.value_id;
                }
                if (allFee.value_id == feeValueId) {
                  feesTypeUseList.splice(allIndex, 1);
                }
              }.bind(this)
            );
          }.bind(this)
        );
        this.setFeesTypeAvailableListAll(feesTypeUseList);
        //}.bind(this), 740)
      }
      this.inputAddMoreFeeTypeParams = null;
      this.assignFeeCourseList();
      //setTimeout(function () { this.feeSeen = true; }.bind(this), 420)
      //})

      //var feesTypeUseList = purify(this.feesTypeAvailableListAll);
    },
    updateBatch(value) {
      this.setBatch(value);
    },
    fetchBatch(value) {
      this.setCourseListAll([]);
      //console.log("fetch batch",value)
      this.feeSeen = false;
      if (!this.courseEdit) {
        this.inputParams = [];
      }

      this.addbtnHide = 0;
      this.feeAvailableCourseList = [];
      this.inqCourse = value;
      this.inqBatchTime = "";
      // this.fetchFeesTypeAvailableListAll();
      if (value != null) {
        // this.feeSeen = true;
        // setStart_date,setStudent_reason
        this.$validator.errors.remove("course");
        //this.fetchBatchAll(value.id);
        if (
          value.student_course_id &&
          typeof value.student_course_id != "undefined"
        ) {
          //this is use for edit fees detail
          this.fetchStudentFeeCourseList(value.student_course_id).then(res => {
            this.feeSeen = true;
            this.updateCourse(value);
          });
        } else {
          //this is use for add fees detail
          var obj = {
            id: value.id,
            course_start_date: this.item.start_date
          };
          this.fetchFeeCourseList(obj).then(res => {
            this.feeSeen = true;
            this.updateCourse(value);
            this.setCourse(value); // save course detail to send it again when start date is change.
          });
        }
        // this.fetchFeeCourseList(value.id);

        //setTimeout(function () { this.assignFeeCourseList() }.bind(this), 400)
      } else {
        this.setCourse(value); // save course detail to send it again when start date is change.
        this.$validator.errors.remove("course");
        this.$validator.errors.add({
          id: "err_select_course",
          field: "course",
          msg: "Course Name field is required.",
          scope: this.$options.scope
        });
        return false;
      }
    },
    assignFeeCourseList() {
      this.fee_list = purify(this.feeCourseList);
    },
    fetchBatchTime(value) {
      this.inqBatchTime = value;
    },
    subtotalCalc(value, index) {
      if (this.fee_list.length === 0) {
        this.assignFeeCourseList();
      }
      if (purify(this.inputParams).length > 0 && this.fee_list.length > 0) {
        if (typeof this.inputParams[index] === "string") {
          var inputParams = this.inputParams[index];
          if (!(inputParams > 0)) {
            inputParams = 0;
          }
          this.subTotal[index] = parseFloat(value - inputParams).toFixed(2);
          this.fee_list[index].discount = parseFloat(inputParams).toFixed(2);
          this.fee_list[index].subtotal = parseFloat(
            this.subTotal[index]
          ).toFixed(2);
          return this.subTotal[index];
        } else {
          this.subTotal[index] = parseFloat(value).toFixed(2);
          return this.subTotal[index];
        }
      } else {
        return (this.subTotal[index] = value);
      }
    },

    // subtotalCalc(value, index)
    // {
    //     if(this.fee_list.length === 0){
    //         this.fee_list = purify(this.feeCourseList);
    //     }
    //     if(typeof this.inputParams[index] === 'string'){
    //         this.subTotal[index] = value - this.inputParams[index];
    //         this.fee_list[index].discount = this.inputParams[index];
    //         this.fee_list[index].subtotal = this.subTotal[index];

    //     }else{
    //         this.subTotal[index] = value
    //     }
    //     return this.subTotal[index];
    // },
    newsubtotalCalc(index) {
      if (
        typeof this.inputFeeAmountParams[index] === "string" ||
        typeof this.inputFeeDiscountParams[index] === "string"
      ) {
        var myNewVal = parseFloat(
          parseFloat(
            this.inputFeeAmountParams[index]
              ? this.inputFeeAmountParams[index]
              : 0
          ) -
            parseFloat(
              this.inputFeeDiscountParams[index]
                ? this.inputFeeDiscountParams[index]
                : 0
            )
        ).toFixed(2);
        this.newsubTotal[index] = parseFloat(myNewVal).toFixed(2);
      } else {
        this.newsubTotal[index] = 0;
      }
      return this.newsubTotal[index];
    },
    finaltotalCalc(value, gstRate, index) {
      if (
        typeof this.fee_list != "undefined" &&
        typeof this.fee_list[index] != "undefined"
      ) {
        if (typeof this.subTotal[index] != "undefined") {
          value = parseFloat(this.subTotal[index]);
        }
        var gstValue = this.countExistGSTValue(value, gstRate);
        this.fee_list[index].total_fees = parseFloat(
          parseFloat(value) - parseFloat(gstValue)
        ).toFixed(2);
        return this.fee_list[index].total_fees;
      }
      // if (typeof this.fee_list != "undefined" && typeof this.fee_list[index] != "undefined") {
      //     if(gstRate > 0)
      //     {
      //         if(typeof this.subTotal[index] != 'undefined' ){
      //             var subT = parseFloat(this.subTotal[index]);
      //             var fTotal = parseFloat(subT + (subT*parseFloat(gstRate)/100)).toFixed(2);
      //             this.fee_list[index].total_fees = parseFloat(fTotal).toFixed(2);
      //             return this.fee_list[index].total_fees;
      //         }else{
      //             return value;
      //         }
      //     }
      //     else{
      //         if(typeof this.subTotal[index] != 'undefined'){
      //             this.fee_list[index].total_fees = parseFloat(this.subTotal[index]).toFixed(2);
      //             return this.fee_list[index].total_fees;
      //         }else{
      //             return value;
      //         }
      //     }
      // }
    },
    newfinaltotalCalc(index) {
      this.countGSTValue(this.newsubTotal[index], index);
      var total = this.newsubTotal[index];
      if (
        this.inputFeeGSTParams[index] ? this.inputFeeGSTParams[index] : 0 > 0
      ) {
        total =
          parseFloat(this.newsubTotal[index]) -
          parseFloat(this.inputFeeGSTValue[index]).toFixed(2);
      }
      return parseFloat(total).toFixed(2);
      // if((this.inputFeeGSTParams[index])?this.inputFeeGSTParams[index]:0 > 0)
      // {
      //     if(typeof this.inputFeeGSTParams[index] === 'string'){
      //         var myNewVal = parseFloat(this.newsubTotal[index]) + (parseFloat(this.newsubTotal[index]) * parseFloat((this.inputFeeGSTParams[index])?parseFloat(this.inputFeeGSTParams[index]):0)/100);
      //                return this.newsubTotal[index] = parseFloat(myNewVal).toFixed(2);
      // }else{
      //         return this.newsubTotal[index];
      //     }
      // }
      // else{
      //     return this.newsubTotal[index];
      // }
    },

    /**
     * Public function to count GST rate value.
     *
     * @FileName      Student Edit vue
     * @FunctionName  countExistGSTValue
     * @author        Rajesh R. Rathod
     * @since         31th Oct 2018
     * @description   Function  to count GST rate value. e.q 1000 * 10 /100 = 100
     * value = sub total
     */
    countExistGSTValue(value, GSTValue) {
      //console.log("sdsd",value,GSTValue);
      //var inputFeeGSTValue = parseFloat((value)?value:0) * (parseFloat((GSTValue)?GSTValue:0) / 100);
      var gstRate = parseFloat(GSTValue ? parseFloat(GSTValue) + 100 : 0);
      var totalAmt = parseFloat(value ? value : 0);
      var inputFeeGSTValue = 0;
      if (gstRate > 0) {
        inputFeeGSTValue = parseFloat(totalAmt - totalAmt * 100 / gstRate);
      }
      return (inputFeeGSTValue ? inputFeeGSTValue : 0).toFixed(2);
    },
    /**
     * Public function to count GST rate value in exist value from the list.
     *
     * @FileName      Student Edit vue
     * @FunctionName  countGSTValue
     * @author        Rajesh R. Rathod
     * @since         31th Oct 2018
     * @description   Function  to count GST rate value in exist value from the list. e.q 1000 * 10 /100 = 100
     * value = sub total
     */
    countGSTValue(value, index) {
      var gstRate = parseFloat(
        this.inputFeeGSTParams[index]
          ? parseFloat(this.inputFeeGSTParams[index]) + 100
          : 0
      );
      var totalAmt = parseFloat(value ? value : 0);
      this.inputFeeGSTValue[index] = 0;
      if (gstRate > 0) {
        this.inputFeeGSTValue[index] = parseFloat(
          totalAmt - totalAmt * 100 / gstRate
        );
      }
      //this.inputFeeGSTValue[index] = parseFloat((value)?value:0) * (parseFloat(this.inputFeeGSTParams[index]) / 100);
      return (this.inputFeeGSTValue[index]
        ? this.inputFeeGSTValue[index]
        : 0
      ).toFixed(2);
    },
    setAge(value) {
      if (value) {
        var dateArr = value.split("/");
        var date = dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
        this.item.age = this.showAge(date);
      }
    },
    showAge(value) {
      var age = "";
      var user_date = new Date(value);
      var today_date = new Date();
      var diff_date = Math.abs(user_date.getTime() - today_date.getTime());
      var num_years = diff_date / 31536000000;
      var num_months = (diff_date % 31536000000) / 2628000000;
      var num_days = ((diff_date % 31536000000) % 2628000000) / 86400000;
      if (Math.floor(num_years)) {
        age += Math.floor(num_years) + " Years";
      }

      if (Math.floor(num_months)) {
        age += ", " + Math.floor(num_months) + " Months";
      }

      return age;
    }
  }
};
</script>


<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
.ds-flex{
  display: flex;
}
</style>
