<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Batches</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/batch-masters'">Batches</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Batch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Batch</h3>
                    </div>
                    <div class="card-body">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="row">
                                       <div class="col-md-12">
                                       <bootstrap-alert /></div>
                                    
                                       <div class="col-md-6 form-group">
                                          <label for="batch_name">
                                             Batch Name
                                             <p class="astrisk">*</p>
                                          </label>
                                          <input
                                             autocomplete="off"
                                             type="text"
                                             class="form-control"
                                             name="batch_name"
                                             ref="batch_name"
                                             v-validate="'required'"
                                             maxlength="150"
                                             placeholder="Enter Batch Name"
                                             v-model="batch_name"
                                             >
                                          <span class="error" v-show="errors.has('batch_name')">
                                          {{ $root.updateErrorMessage('batch_name',this.errors,'Batch Name')}}
                                          </span>                                            
                                       </div>

                                       <div class="form-group col-md-6">
                                             <label for="course_vertical">Course Vertical<p class="astrisk">*</p></label>
                                             <v-select
                                                name="course_vertical"
                                                label="name"
                                                value="id"
                                                v-validate="'required'"
                                                placeholder="Select Course Vertical"
                                                :options="courseVertical"
                                                v-model="course_vertical"
                                                />
                                             <span class="error" v-show="errors.has('course_vertical')">
                                                {{ $root.updateErrorMessage('course_vertical',this.errors,'Course Vertical')}}
                                             </span>                                            
                                       </div>  

                                       <div class="form-group col-md-6">
                                             <label for="branch_lab">Branch Lab<p class="astrisk">*</p></label>
                                             <v-select
                                                name="branch_lab"
                                                label="lab_name"
                                                value="id"
                                                v-validate="'required'"
                                                placeholder="Select Branch Lab"
                                                :options="branchLabs"
                                                v-model="branch_lab"
                                                />
                                             <span class="error" v-show="errors.has('branch_lab')">
                                                {{ $root.updateErrorMessage('branch_lab',this.errors,'Branch Lab')}}
                                             </span>                                            
                                       </div>  
                                       <div class="form-group col-md-6">
                                             <label for="course_category">Allow Course Category<p class="astrisk">*</p></label>
                                             <v-select
                                                name="course_category"
                                                label="name"
                                                value="id"
                                                v-validate="'required'"
                                                placeholder="Select Course Category"
                                                :options="courseCategory"
                                                v-model="course_category"
                                                multiple
                                                />
                                             <span class="error" v-show="errors.has('course_category')">
                                                {{ $root.updateErrorMessage('course_category',this.errors,'Course Category')}}
                                             </span>                                            
                                       </div>  

                                       <div ref="start_time" class="col-md-6 form-group">
                                          <label for="start_time">
                                             Batch Start Time
                                             <p class="astrisk">*</p>
                                          </label>
                                          <date-picker
                                 
                                             :config="$root.dpconfigTime"
                                             name="start_time"
                                             v-validate="'required'"
                                             placeholder="Select Batch Start Time"
                                             v-model="start_time"
                                             autocomplete="off"
                                             >                                            
                                          </date-picker>
                                          <span class="error" v-show="errors.has('start_time')">
                                          {{ $root.updateErrorMessage('start_time',this.errors,'Batch Start Time')}}
                                          </span>
                                       </div>

                                       <div ref="end_time" class="col-md-6 form-group">
                                          <label for="end_time">
                                             Batch End Time
                                             <p class="astrisk">*</p>
                                          </label>
                                          <date-picker
                                             :config="$root.dpconfigTime"
                                             name="end_time"
                                             v-validate="'required'"
                                             placeholder="Select Batch End Time"
                                             v-model="end_time"
                                             autocomplete="off"
                                             >
                                          </date-picker>
                                          <span class="error" v-show="errors.has('end_time')">
                                          {{ $root.updateErrorMessage('end_time',this.errors,'Batch End Time')}}
                                          </span>
                                          <span class="error" v-show="errors.has('valid_end_time')">
                                          {{ $root.updateErrorMessage('valid_end_time',this.errors)}}
                                          </span>
                                       </div>

                                         <div class="form-group mb-0 check col-md-12 col-xs-12 batch-create">
                                            <div class="row batch_div">
                                            
                                                <label class="m_left">Batch Days<p class="astrisk">*</p></label>
                                                <div class="weekdays">
                                                    <div class="form-group col-md-1 col-xs-1" v-for="(day, index) in daysAll" :key="index">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input 
                                                                  name="workingDay"
                                                                  @change="updateWorkingDay1" 
                                                                  :disabled="workingCheckedDays[index]"
                                                                  type="checkbox"
                                                                  :value="index" 
                                                                  v-validate="'required'"
                                                                  v-model="checkedDays">
                                                                  {{day}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span id="err_workingDay" class="error" v-show="errors.has('workingDay')">
                                             {{ $root.updateErrorMessage('workingDay',this.errors,'Batch Days')}}
                                             </span>                                  
                                        </div>
                                        <div class="form-group mt-3 col-md-12">
                                             <label for="tutors">Tutors<p class="astrisk">*</p></label>
                                             <v-select
                                                name="tutors"
                                                label="employee_name"
                                                value="id"
                                                v-validate="'required'"
                                                placeholder="Select Tutors"
                                                :options="branchTutors"
                                                v-model="tutor"
                                                multiple
                                                />
                                             <span class="error" v-show="errors.has('tutors')">
                                                {{ $root.updateErrorMessage('tutors',this.errors,'Tutor')}}
                                             </span>                                            
                                       </div> 
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div> 
                                    </div>
                                </form>
                           
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
   import { mapGetters, mapActions } from 'vuex'
   import AddLocationPopupBatchMaster from '../../dtmodules/PopupActions/AddLocationPopupBatchMaster'
   
   export default {
       components: { AddLocationPopupBatchMaster },
       data() {
           return {
               seen: false,
               checkedDays: [],
               workingCheckedDays: [],
               batch_name:'',
               course_category:'',
               course_vertical:'',
               branch_lab:'',
               tutor:'',
               end_time:'',
               start_time:'',
               override:0,

               daysAll:{'1':'Monday','2':'Tuesday','3':'Wednesday','4':'Thursday','5':'Friday','6':'Saturday','7':'Sunday'},
               exitDateOptionFormate:{format:window.date_format_moment},
   
           }
       },
       computed: {
           ...mapGetters('BatchMastersSingle', ['branchLabs','branchTutors','item', 'loading', 'coursesAll','locationAll','branchWorkingDaysAll','courseVertical','courseCategory'])
   
       },
       created() {
           this.fetchTypes();
           this.fetchBranchLab();
           this.fetchBranchTutor();
           // this.fetchBranchWorkingDaysAll()
           this.fetchBranchWorkingDaysAll().then(()=>this.getWorkingDaysDisabledOrNot())        
           // setTimeout(function () {
           //     this.checkedDays = (this.branchWorkingDaysAll.working_days).split(",");   
           //     this.updateWorkingDay1(this.checkedDays);
           // }.bind(this), 480)
   
       },
       destroyed() {
           this.resetState()
       },
       methods: {
           ...mapActions('BatchMastersSingle', ['fetchBranchTutor','fetchBranchLab','fetchTypes','storeData', 'resetState', 'setBranch_id', 'setBatch_name', 'setCourse_type', 'setStart_time', 'setEnd_time', 'setLocation', 'setBatchStart_date', 'setBatchEnd_date','setWorkingDay1','setWorkingDay2','setWorkingDay3','setWorkingDay4','setWorkingDay5','setWorkingDay6','setWorkingDay7', 'setSeat_limit', 'setVariation_seat', 'fetchCoursesAll','fetchLocationAll','fetchBranchWorkingDaysAll']),
           updateBranch_id(e) {
               this.setBranch_id(e.target.value)
           },
           enableWorkingDays(){
               this.workingCheckedDays = [];
               for(var index = 1; index <= 7; index++){
                   this.workingCheckedDays[index] = false;
               }
           },
           getWorkingDaysDisabledOrNot() {
               this.workingCheckedDays = [];
               this.checkedDays = this.branchWorkingDaysAll;
               if(this.branchWorkingDaysAll && this.branchWorkingDaysAll !=''){
                   var workingData = this.branchWorkingDaysAll;
                   for(var index = 1; index <= 7; index++){
                       var a = workingData.indexOf(index.toString());
                       if(a >= 0){
                           
                           this.workingCheckedDays[index] = false;
                           
                       }else{
                           if(this.checkedDays.indexOf(index) >= 0){
                               this.checkedDays.splice(this.checkedDays.indexOf(index),1);
                           }
                           this.workingCheckedDays[index] = true;
                       }
                  }
               }
           },
           updateBatch_name(e) {
               this.setBatch_name(e.target.value)
           },
           updateCourse_type(value) {
               this.setCourse_type(value)
                
               if(value && value.value_id != 1)
               {
                   this.enableWorkingDays();
                   this.checkedDays = [];
                   this.seen = true;
               }else{
                   this.checkedDays = this.branchWorkingDaysAll;   
                   this.updateWorkingDay1(this.checkedDays);
                   
                   this.getWorkingDaysDisabledOrNot()
                   this.seen = false;
               }
             
           },
           updateStart_time(e) {
               this.setStart_time(e.target.value)
           },
           updateEnd_time(e) {
               this.setEnd_time(e.target.value)
           },
           isValidateEndTime(){
               var flag = false;
               if(this.end_time !=null && this.start_time !=null)
               {
                   var endTime = '2011-01-01 '+this.$root.getTimeFormat(this.end_time);
                   var startTime = '2011-01-01 '+this.$root.getTimeFormat(this.start_time);
                   if(endTime > startTime){
                       flag = true;
                   }
               }
               return flag
           },
           updateLocation(value) {
               this.setLocation(value)
           },
           updateBatchStart_date(e) {
               if(e && e.target.value != ''){            
                   this.exitDateOptionFormate = {
                           format:window.date_format_moment,
                           minDate: e.target.value,
                           maxDate : false                    
                      };
                   this.setBatchEnd_date(null)
               }
               this.setBatchStart_date(e.target.value)
           },
           updateBatchEnd_date(e) {
            if(e){
                this.setBatchEnd_date(e.target.value)
               }
           },
           
           updateSeat_limit(e) {
               this.setSeat_limit(e.target.value)
           },
           
           updateVariation_seat(e) {
               this.setVariation_seat(e.target.value)
           },
          
           updateWorkingDay1(e) {
               this.setWorkingDay1(this.checkedDays)
           },
           // updateWorkingDay2(e) {
           //     this.setWorkingDay2(e.target.checked)
           // },
           // updateWorkingDay3(e) {
           //     this.setWorkingDay3(e.target.checked)
           // },
           // updateWorkingDay4(e) {
           //     this.setWorkingDay4(e.target.checked)
           // },
           // updateWorkingDay5(e) {
           //     this.setWorkingDay5(e.target.checked)
           // },
           // updateWorkingDay6(e) {
           //     this.setWorkingDay6(e.target.checked)
           // },
           // updateWorkingDay7(e) {
           //     this.setWorkingDay7(e.target.checked)
           // },
   
           submitForm() {
            
            this.$validator.validate().then(result => {
                if (result) {
                   if(!this.isValidateEndTime())
                    {
                       this.$validator.errors.add({
                         id: 'end_time',
                         field: 'end_time',
                         msg: 'Batch End Time should be greater than Batch Start Time.',
                         scope: this.$options.scope,
                       });
   
                       return '';
                    }

                    let data = {
                     'batch_name':this.batch_name,
                     'checked_days':this.checkedDays,
                     'course_category':this.course_category,
                     'course_vertical':this.course_vertical,
                     'branch_lab':this.branch_lab,
                     'tutor':this.tutor,
                     'start_time':this.start_time,
                     'end_time':this.end_time,
                     'override':this.override,
                    }
                  
               this.storeData(data)
                   .then((response) => {
                        if(response.data.result){
                           this.override = 0;
                           this.$router.push({ name: 'batch_masters.index' })
                           this.$eventHub.$emit('create-success')
                        }
                        else{
                           if(response.data.data.override){
                              this.$swal({
                                 title: 'Are you sure want to override tutor?',
                                 text: response.data.data.override[0],
                                 type: 'warning',
                                 cancelButtonText: "No",   
                                 showCancelButton: true,
                                 confirmButtonText: 'Yes',
                                 confirmButtonColor: '#dd4b39',
                                 focusCancel: true,
                                 reverseButtons: true
                              }).then(result => {
                                 if (result.value) {
                                    this.override = 1;
                                    this.submitForm();
                                 }
                              })
                           }
                           else{
                              for (const [key, value] of Object.entries(response.data.data)) {
                                 this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                 });
                              }
                           // this.buttonLoading = false
                              return '';
                           }
                           
                        }
                       
                   })
                   .catch((error) => {
                       console.error(error)
                   })
               }
                 else{
                   return this.$root.handleValidationFocus(this.errors,this.$refs);
               }            
           });
           }
       }
   }
</script>
<style scoped>
.batch_div label{
    margin-bottom: 0 !important;
}
.batch_div .form-group{
    margin-bottom: 0 !important;
}
</style>