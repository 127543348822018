
<template>
    <div>
        <span v-if="value==null">
            
        </span>        
        <span v-else-if="value==0">
            In Progress
        </span>
        <span v-else-if="value==1">
            Completed
        </span>
    </div>
</template>


<script>
export default {
    props: ['value', 'field'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

