<template>
  <div class="popup-form add-country">
    <b-btn v-b-modal.modalPrevent2 class="add-button">+</b-btn>
    <b-modal id="modalPrevent2"
             ref="modal"
             title="Add Location"
             okTitle="Save"
             okVariant="success"
             @ok="handleOk"
             @cancel="handleCancel"
             @shown="clear">
      <form @submit.stop.prevent="handleSubmit">
      <div class="container-fluid">  
        <div class="row">
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="name" for="name">Location Name<p class="astrisk">*</p></label>
                <b-form-input 
                              id="name"
                              type="text"
                              name="name"
                              maxlength="90"
                              v-validate="'required'"
                              v-model="locationName"
                              placeholder="Enter Location Name">
                </b-form-input>
                <span class="error" v-show="errors.has('name')">
                  {{ $root.updateErrorMessage('name',this.errors,'Location Name')}}
                </span> 
			       </div>
		      </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="address" for="address">Location Address<p class="astrisk">*</p></label>
                <b-form-input 
                              id="address"
                              type="text"
                              name="address"
                              maxlength="90"
                              v-model="address"
                              v-validate="'required'"
                              placeholder="Enter Location Address">
                </b-form-input>
                <span class="error" v-show="errors.has('address')">
                  {{ $root.updateErrorMessage('address',this.errors,'Location Address')}}
                </span> 
             </div>
          </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="contact" for="contact">Contact Number</label>
                <b-form-input 
                              id="contact"
                              type="text"
                              name="contact"
                              v-validate="'numeric'"
                              maxlength="90"
                              v-model="contact"
                              placeholder="Enter Contact Number">
                </b-form-input>
                <span class="error" v-show="errors.has('contact')">
                  {{ $root.updateErrorMessage('contact',this.errors)}}
                </span>                 
             </div>
          </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="area" for="area">Location Area</label>
                <b-form-input 
                              id="area"
                              type="text"
                              name="area"
                              v-model="area"
                              maxlength="90"
                              placeholder="Enter Location Area">
                </b-form-input>
             </div>
          </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="city" for="city">City<p class="astrisk">*</p></label>
                <b-form-input 
                              id="city"
                              type="text"
                              name="city"
                              maxlength="90"
                              v-model="city"
                              v-validate="'required'"
                              placeholder="Enter City">
                </b-form-input>
                <span class="error" v-show="errors.has('city')">
                  {{ $root.updateErrorMessage('city',this.errors)}}
                </span> 
             </div>
          </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">     
                <label ref="pincode" for="pincode">PIN/ZIP Code</label>
                <b-form-input 
                              id="pincode"
                              type="text"
                              name="pincode"
                              v-model="pincode"
                              maxlength="90"
                              placeholder="Enter PIN/ZIP Code">
                </b-form-input>
             </div>
          </div>
            <div class="col-md-6 col-xs-12">
               <div class="form-group">
                  <label for="country" ref="country">Country<p class="astrisk">*</p></label>
                    <v-select
                          name="country"
                          v-model="countryName"
                          v-validate="'required'"
                          :value = "countryName"
                          label="country_name"
                          @input="stateCountry"
                          :options="countryAll"                          
                          >
                      </v-select>                      
                       <span class="error" v-show="errors.has('country')">
                        {{ $root.updateErrorMessage('country',this.errors)}}
                      </span>
                </div>                
            </div>
            <div class="col-md-6 col-xs-12">
               <div class="form-group">
                <label for="state" ref="state">State<p class="astrisk">*</p></label>
                    <v-select
                            name="state"
                            v-validate="'required'"
                            label="state_name"
                            v-model="stateName"
                            :options="statesAll"
                            />                  
                    <span class="error" v-show="errors.has('state')">
                      {{ $root.updateErrorMessage('state',this.errors)}}
                    </span>
                </div>                
            </div>          
  		</div>
	  </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      countryAll: [],
      statesAll: [],
      stateName: null,
      countryName: null,
      locationName: '',
      area: '',
      address: '',
      contact: '',
      city: '',
      pincode: '',
      // count: 0,
    }
  },
  computed: {
        ...mapGetters('BatchMastersSingle', ['item']),
       
    },
   created() {
       this.fetchCountrymastersAll()
       console.log(this.$refs);
   }, 
   mounted() {

   },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },   
  methods: {
     ...mapActions('BatchMastersSingle', ['setLocation', 'fetchLocationAll']),
    clear () {
      this.locationName = '';
      this.area = '';
      this.address = '';
      this.contact = '';
      this.city = '';
      this.pincode = '';
      this.countryName = null;/*this.countryAll[98].country_name;*/
      this.stateName = null;  
      this.$validator.errors.remove('name');      
      this.$validator.errors.remove('address');      
      this.$validator.errors.remove('city');      
      this.$validator.errors.remove('country');      
      this.$validator.errors.remove('state');      
    },
    fetchCountrymastersAll() {      
        axios.get('/api/v1/countrys')
            .then(response => {
                this.countryAll = response.data.data;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })      
    },
    fetchCountryStates(id) {
      if(id){
        axios.get('/api/v1/countryStatesList/' + id)
            .then(response => {
                this.statesAll = response.data.data;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
      }
    },   
    stateCountry(value) {
        if(value !== null){
            this.fetchCountryStates(value.id);
        }        
        else {
          this.statesAll = [];
        }
        this.stateName = null;           
    },    
    handleCancel(evt){
      this.locationName = '';
      this.area = '';
      this.address = '';
      this.contact = '';
      this.city = '';
      this.pincode = '';
      this.countryName = null;
      this.stateName = null;  
      this.$validator.errors.remove('name');      
      this.$validator.errors.remove('address');      
      this.$validator.errors.remove('city');      
      this.$validator.errors.remove('country');      
      this.$validator.errors.remove('state'); 
    },    
    handleOk (evt) {
        evt.preventDefault();
        this.$validator.validate().then(result => {
               if (result) {
                  this.handleSubmit()
                }
                else{
                  return this.$root.handleValidationFocus(this.errors,this.$refs);
                } 
            });
    },

    handleSubmit () {
      let params = new FormData();
      params.set('location_name', this.locationName);
      params.set('area', this.area);
      params.set('location_address', this.address);
      params.set('contact_no', this.contact);
      params.set('city', this.city);
      params.set('pincode', this.pincode);
      params.set('state', this.stateName.id);
      params.set('country', this.countryName.id);
      axios.post('/api/v1/location-masters', params)
      .then(response => {
        if(response.data.error_message)
        {
            this.$validator.errors.add({
                id: 'name',
                field: 'name',
                msg: response.data.message,
                scope: this.$options.scope,
            });
            return '';
        }
        else{
            this.clear();
            this.setLocation(response.data.data);
            this.fetchLocationAll();
            this.$refs.modal.hide()
        }

        
       })
      
    }
  }
}
</script>