<template>
  <div class="popup-form edit">
     <b-modal id="modalPreventsubEdit" 
             ref="modal"
             title="Edit Project Topic"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="clear" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">  
        <form @submit.stop.prevent="handleSubmit">
            <div class="container-fluid">  
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-4 col-xs-4">
                                <label for="project_id">Project Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="project_id"
                                        label="project_name"
                                        v-validate="'required'"
                                        @change="updateProjectId"
                                        :value="item.project_id"
                                        :options="projectList"  
                                        disabled                                      
                                        />
                                <span class="error" v-show="errors.has('project_id')">
                                {{ $root.updateErrorMessage('project_id',this.errors, 'Project Name')}}
                                </span> 
                            </div>
                            <div class="col-md-4 col-xs-4">
                                <label for="topic_id">Topic Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="topic_id"
                                        label="topic_name"
                                        v-validate="'required'"
                                        @change="updateTopic"
                                        :value="item.topic"
                                        :options="TopicListing"
                                        disabled
                                        />
                                <span class="error" v-show="errors.has('topic_id')">
                                {{ $root.updateErrorMessage('topic_id',this.errors, 'Topic Name')}}
                                </span> 
                            </div>
                            <div class="col-md-4 col-xs-4">
                                <label ref="subtopic_name" for="subsubtopic_name">SubTopic Name<p class="astrisk">*</p></label>
                                <b-form-input 
                                        id="subtopic_name"
                                        type="text"
                                        name="subtopic_name"
                                        maxlength="90"
                                        @input="updateTopicName"
                                        v-validate="'required'"                              
                                        v-model="item.subtopic_name"
                                            placeholder="Enter SubTopic Name">
                                </b-form-input>
                                <span class="error" v-show="errors.has('topic_name')">
                                {{ $root.updateErrorMessage('topic_name',this.errors, 'Topic Name')}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">     
                                    <label ref="description" for="description">Description<p class="astrisk">*</p></label>
                                    <b-form-textarea 
                                            id="description"
                                            type="textarea"
                                            name="description"
                                            @input="updateDescription"
                                            v-validate="'required'"      
                                            v-model="item.description"
                                            placeholder="Enter Description">
                                    </b-form-textarea>  
                                    <span class="error" v-show="errors.has('description')">
                                    {{ $root.updateErrorMessage('description',this.errors, 'Description')}}
                                    </span>                   
                                </div>
                            </div>                            
                        </div>

                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-6 col-xs-6">
                                    <div class="form-group">
                                        <label for="subtopic_video">Sub-Topic Video</label>
                                        <input
                                                type="file"
                                                name="subtopic_video"
                                                id="subtopic_video"
                                                v-validate="'size:512000|ext:mp4,mov,ogg'"
                                                class="form-control"
                                                @change="updateTopicVideo"
                                            >
                                            <span style="font-size: 13px;"><b>Note :</b> Mp4 files are allowed. Video File size should be less than or equals to 500 MB.</span>
                                        <span class="error" v-show="errors.has('subtopic_video')">
                                            {{ $root.updateErrorMessage('subtopic_video',this.errors,'SubTopic video')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6" style="top: 40px;">
                                    <div class="form-group" v-if="item.subproject_topics_video">
                                        <a target="blank" :href="item.subproject_topics_video" >Link to view last uploded Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-6 col-xs-6">
                                    <div class="form-group">
                                        <label for="subtopic_file">Sub-Topic File</label>
                                        <input
                                                type="file"
                                                name="subtopic_file"
                                                id="subtopic_file"
                                                v-validate="'size:102400|ext:doc,pdf,txt,docx'"
                                                class="form-control"
                                                @change="updateTopicFile"
                                            >
                                        <span style="font-size: 13px;"><b>Note :</b> Pdf, Txt, Doc files are allowed. File size should be less than or equals to 100 MB.</span>
                                        <span class="error" v-show="errors.has('subtopic_file')">
                                            {{ $root.updateErrorMessage('subtopic_file',this.errors,'SubTopic File')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6" style="top: 40px;">
                                    <div class="form-group" v-if="item.subproject_topics_file">
                                        <a target="blank" :href="item.subproject_topics_file">Link to view last uploded Document</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group mb-0">     
                            <div class="checkbox">
                                <label class="cursor-pointer">
                                    Have Quiz &nbsp;
                                    <!-- <input
                                        type="checkbox"
                                        name="subhave_quiz"
                                        id="subhave_quiz"
                                        :checked="item.subhave_quiz ==1 ? 'checked':''"
                                        :value="item.subhave_quiz"
                                        @change="updateHaveQuiz"
                                        > -->
                                        <input
                                            type="checkbox"
                                            name="subhave_quiz"
                                            id="subhave_quiz"
                                            v-model="item.subhave_quiz"
                                            :checked="(item.subhave_quiz && item.subhave_quiz == 1) ? 'checked' : ''"
                                            >
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0">     
                            <div class="checkbox">
                                <label class="cursor-pointer">
                                    Is Task For Tutor &nbsp;
                                    <!-- <input
                                        id="subis_task_for_tutor"
                                        type="checkbox"
                                        name="subis_task_for_tutor"
                                        :checked="item.subis_task_for_tutor ==1 ? 'checked':''"
                                        :value="item.subis_task_for_tutor"
                                        @change="updateTaskForTutor"
                                        > -->
                                        <input
                                        id="subis_task_for_tutor"
                                        type="checkbox"
                                        name="subis_task_for_tutor"
                                        v-model="item.subis_task_for_tutor"
                                        :checked="(item.subis_task_for_tutor && item.subis_task_for_tutor == 1) ? 'checked' : ''"
                                        >
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0">     
                            <div class="checkbox ">
                                <label class="cursor-pointer">
                                    Is Access In Mobile &nbsp;
                                   <!--  <input
                                        id="subis_access_in_mobile"
                                        type="checkbox"
                                        name="subis_access_in_mobile"
                                        :checked="item.subis_access_in_mobile ==1 ? 'checked':''"
                                        :value="item.subis_access_in_mobile"
                                        @change="updateAccessInMobile"
                                        > -->
                                        <input
                                        id="subis_access_in_mobile"
                                        type="checkbox"
                                        name="subis_access_in_mobile"
                                        v-model="item.subis_access_in_mobile"
                                        :checked="(item.subis_access_in_mobile && item.subis_access_in_mobile == 1) ? 'checked' : ''"
                                        >
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="modal-footer modal-footer1">
                <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
    
                <button type="submit" :disabled='loading1' class="btn btn-success"><span class="alert text-center" v-if="loading1">
                                <i class="fa fa-spinner fa-sync-alt rotate"></i> Loading...</span>
                                <span v-else>Save</span></button>
            </footer>
        </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
        subtopic_name:'',
        description:'',
        sort_order:'',
        subhave_quiz:0,
        subis_task_for_tutor:0,
        subis_access_in_mobile:0,
        sub_id:'',
    }
  },
  props:[ 'project_id', 'details', 'subt_id' ],
  computed: {
        ...mapGetters('ProjectTopicsSingle', ['TopicListing']),
        ...mapGetters('ProjectSubTopicsSingle', ['item', 'loading','loading1', 'projectList', 'TopicListing']),
        item: {
            get() {
                return this.$store.state.ProjectSubTopicsSingle.item;
            },
            set(value) {
                this.$store.dispatch("ProjectSubTopicsSingle/SubTopicData", value);
            }
        },
        
    },
    watch:{
        sub_id(){
            return this.subt_id
        },    
    },
    created() {
       //console.log("INNNNNNNNNNNNNNNN", this.item)
    }, 
    methods: {
        ...mapActions('ProjectTopicsSingle', ['getListing']), 
        ...mapActions('ProjectSubTopicsSingle', ['fetchData', 'SubTopicData','updateData', 'setTopic','setTopicName', 'setDescription', 'setHaveQuiz', 'setTaskForTutor', 'setAccessInMobile', 'setTopicFile', 'setTopicVideo', 'setProjectId', 'fetchProjectAll']),
        updateProjectId(value) {
            this.setProjectId(value)
        }, 
        updateTopic(value) {
            this.setTopic(value)
        },
        updateTopicName(value){
            this.setTopicName(value)
        },
        closeModel(){
            this.$refs.modal.hide();
            this.handleCancel();
        },
        updateDescription(value){
            this.setDescription(value)
        },
        updateHaveQuiz(e) {
            this.setHaveQuiz(0)
            if(e.target.checked){
                this.setHaveQuiz(1)
            }        
        },
        updateTaskForTutor(e) {
            this.setTaskForTutor(0)
            if(e.target.checked){
                this.setTaskForTutor(1)
            }  
        },
        updateAccessInMobile(e) {
            this.setAccessInMobile(0)
            if(e.target.checked){
                this.setAccessInMobile(1)
            }
        },
        updateTopicFile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword'  || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    const fileVal = [];
                    fileVal.push(e.target.files[0])
                    this.setTopicFile(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only Pdf, Text, doc files are allowed'
                    }
                        this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_file").val('');
                            this.$validator.errors.remove('topic_file');
                            this.setTopicFile('');
                        }
                    })
                } 
            }           
        },  
        updateTopicVideo(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg')){
                    /* reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]); */
                    const fileVal = [];
                    fileVal.push(e.target.files[0])
                    this.setTopicVideo(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg')){
                        message = 'File size should be less then 500MB.'
                    }
                    else {
                        message = 'Only mp4 files are allowed'
                    }
                    this.$swal({
                    title: message,
                    type: 'error',                      
                    focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_video").val('');
                            this.$validator.errors.remove('topic_video');
                            this.setTopicVideo('');
                        }
                    })
                }
            }           
        }, 
        handleCancel(evt){
            this.topic_name =''
            this.description=''
            this.sort_order = '';
            this.$validator.errors.remove('project_id');
            this.setTopicFile('');
            this.setTopicVideo(''); 
            this.getListing(this.project_id);
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.handleSubmit() 
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            this.topic_name =''
            this.description=''
            this.sort_order = '';
            this.$validator.errors.remove('project_id');
            this.setTopicFile('');
            this.setTopicVideo(''); 
            this.getListing(this.project_id);      
        },
        handleSubmit() {
            console.log("dssdsd");
            this.$validator.validate().then(result => {
            if (result) {
                this.updateData()
                    .then((res) => {
                        if(!res.data.result)
                        {
                            for (const [key, value] of Object.entries(res.data.data)) {
                                //console.log("ke",key,value);
                                if(key == 'topic_name'){ 
                                     this.$validator.errors.add({
                                        id: 'subtopic_name',
                                        field: 'subtopic_name',
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                            return '';
                        }else{
                            this.clear();
                            this.$refs.modal.hide()
                            this.setTopicFile('');
                            this.setTopicVideo('');
                            this.$eventHub.$emit('update-success')
                            //this.getListing(this.project_id)
                            //this.$store.dispatch("ProjectTopicsSingle/updateElements", value);
                        }                    
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }            
            });
        }
    }
}
</script>
<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
