function initialState() {
    return {
        all: [],
        editDataAll: [],
        indexAll: [],
        query: {},
        projectAll: [],
        moduleAll: [],
        showData: [],
        loading: false,
        courseVertical:[],
        courseFeeType:[],
        courseCategory:[],
        courseMonthFormula:[],
        is_active:0,
        courseLabs:[],
        courselab:null,
        courseImage:'',
        report_is_active:0,
        certificates:[]
    }
} 

const getters = {
    certificateAll: state => state.certificates,
    data: state => {
        let rows = state.indexAll
        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['group_name','total_member'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.indexAll

        if (state.query.sort) {
            rows = _.orderBy(state.indexAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['group_name','total_member'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    showData: state => state.showData,
    editDataAll: state => state.editDataAll,
    indexAll: state => state.indexAll,
    query: state => state.query,
    all: state => state.all,
    moduleAll: state => state.moduleAll,
    projectAll: state => state.projectAll,
    loading: state => state.loading,
    courseVertical: state => state.courseVertical,
    courseCategory: state => state.courseCategory,
    courseFeeType: state => state.courseFeeType,
    courseMonthFormula: state => state.courseMonthFormula,
    courseLabs: state => state.courseLabs,
    courselab:state => state.courselab,
    courseImage:state => state.courseImage
}

const actions = {
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/course/show/' + id)
                .then(response => {
                    commit('setShowDataAll', response.data.data)
                    resolve(response)
                    commit('setLoading', false)
                })
                .catch(function (error) {
                    reject(error)
                    commit('setLoading', false)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async editCourse({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', form_data.course_vertical.id)
            }
            if (_.isEmpty(form_data.standard)) {
                params.set('standard', '')
            } else {
                params.set('standard', form_data.standard.value_id)
            }

            if (_.isEmpty(form_data.course_category)) {
                params.set('course_category', '')
            } else {
                params.set('course_category', form_data.course_category.id)
            }

            if (_.isEmpty(form_data.more_details)) {
                params.set('more_details', '')
            } else {
                let i = 0;
                for (let index in form_data.more_details) {
                    params.set('more_details['+i+'][title]', form_data.more_details[index]['title']) 
                    params.set('more_details['+i+'][image]', form_data.more_details[index]['image'])  
                    i++;   
                }
            }          

            axiosLms.post('/api/v1/course/' + form_data.id, params)
                .then(response => {
                        //commit('setEditDataAll', response.data.data)
                    resolve(response)
                    
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                     commit('setLoading', false)
                })
        })
    },  
    async editCourseWebsite({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.more_details)) {
                params.set('more_details', '')
            } else {
                console.log(form_data.more_details);
                let i = 0;
                for (let index in form_data.more_details) {
                    params.set('more_details['+i+'][title]', form_data.more_details[index]['title']) 
                    params.set('more_details['+i+'][image]', form_data.more_details[index]['image'])  
                    i++;   
                }
            }

             if (_.isEmpty(form_data.extra_details)) {
                params.set('extra_details', '')
            } else {
                console.log(form_data.extra_details);
                let i = 0;
                for (let index in form_data.extra_details) {
                    params.set('extra_details['+i+'][label]', form_data.extra_details[index]['label']) 
                    params.set('extra_details['+i+'][value]', form_data.extra_details[index]['value'])  
                    i++;   
                }
            }

            if (_.isEmpty(form_data.standard)) {
                params.set('standard', '')
            } else {
                params.set('standard', form_data.standard.value_id)
            }

            if (_.isEmpty(form_data.certificate)) {
                params.set('certificate', '')
            } else {
                params.set('certificate', form_data.certificate.id)
            }

            if (_.isEmpty(form_data.course_report_content)) {
                params.set('course_report_content', '')
            } else {
                params.set('course_report_content', form_data.course_report_content)
            }

            axiosLms.post('/api/v1/course/updateWebsiteDetail/' + form_data.id, params)
                .then(response => {
                        //commit('setEditDataAll', response.data.data)
                    resolve(response)
                    
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                     commit('setLoading', false)
                })
        })
    },  
    async fetchEditData({ commit, state, dispatch }, id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/course/edit/' + id)
                .then(response => {
                    console.log(response.data.data);
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchIndexData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchIndexDataForGroups')
                .then(response => {
                    commit('setIndexDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },     
    async fetchModuleAll({ commit, state, dispatch }) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllModules')
                .then(response => {
                    commit('setModuleAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Formula For Calculate Months','Course Vertical','Course Category','Course Fee Type']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    commit('setCourseMonthFormula', data['Formula For Calculate Months'])
                    commit('setCourseVertical', data['Course Vertical'])
                    commit('setCourseCatgory', data['Course Category'])
                    commit('setCourseFeeType', data['Course Fee Type'])
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })                
    },
    async fetchProjectAll({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllProjects')
                .then(response => {
                    if(response.data.result){
                        commit('setProjectAll', response.data.data)
                        resolve(response)
                    }
                    else{
                        commit('setProjectAll', [])
                        resolve([])
                    }
                    
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },        
    courseStore({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.course_vertical)) {
                params.set('course_vertical', '')
            } else {
                params.set('course_vertical', form_data.course_vertical.id)
            }

            if (_.isEmpty(form_data.course_category)) {
                params.set('course_category', '')
            } else {
                params.set('course_category', form_data.course_category.id)
            }

            axiosLms.post('/api/v1/course/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
        axiosLms.delete('/api/v1/course/' + id)
        .then(response => {
            resolve(response);
            })
            .catch(function (error) {
                    // let message = error.response.data.message || error.message
                    // let errors  = error.response.data.errors
                    // dispatch(
                    //     'Alert/setAlert',
                    //     { message: message, errors: errors, color: 'danger' },
                    //     { root: true })
                    reject(error)
            })  
            .finally(() => {
                // commit('setError', false)
            })
        })
    },    
    updateIsfeatured({ commit, state }, data) {
        return new Promise((resolve, reject) => {        
        axiosLms.post('/api/v1/updateIsfeatured',data)
        .then(response => {
            resolve(response);
            })
            .catch(function (error) {
                    // let message = error.response.data.message || error.message
                    // let errors  = error.response.data.errors
                    // dispatch(
                    //     'Alert/setAlert',
                    //     { message: message, errors: errors, color: 'danger' },
                    //     { root: true })
                    reject(error)
            })  
            .finally(() => {
                // commit('setError', false)
            })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setAll({ commit }, value) {
        commit('setAll', value)
    },
    setIsActive({ commit }, value) {
        commit('setIsActive', value)        
    },
    setReportIsActive({ commit }, value) {
        commit('setReportIsActive', value)        
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setLabCourse({commit}){
        commit('setBranchLab', [])
    },
    async fetchBranchLabData({ commit, state, dispatch }, course_id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/fetchBranchLabData',{'course_id':course_id})
            .then(response => {
                if(response.data.result){
                    commit('setBranchLab', response.data.data.labs)
                    commit('setCourseLab',response.data.data.course_lab)
                }
                else{
                    commit('setBranchLab', [])
                    commit('setCourseLab',null);
                }
                
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                // commit('setLoading', false)
            })
        })
    },  
    async fetchCertificates({ commit, state, dispatch }, course_id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/fetchCertificates')
            .then(response => {
                if(response.data.result){
                    commit('setCertificates', response.data.data)
                   
                }
                else{
                    commit('setCertificates', [])
                  
                }
                
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                // commit('setLoading', false)
            })
        })
    },  
      
    storeCourseLab({ commit, state, dispatch }, data) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if (_.isEmpty(data.lab_id)) {
                params.set('lab_name', '')
            } else {
                params.set('lab_name', data.lab_id.id)
            }
            if (data.course_id == '') {
                params.set('course_id', '')
            } else {
                params.set('course_id', data.course_id)
            }

            axiosLms.post('/api/v1/storeCourseBranchLab', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                    commit('setLoading', false)
            })
        })
    },   
    setCourseImage({ commit }, value) {
        commit('setCourseImage', value)
    },
}

const mutations = {
    setCertificates(state,certificates){
        state.certificates = certificates;
    },
    setCourseVertical(state,course_verticals){
        state.courseVertical = course_verticals;
    },
    setCourseCatgory(state,course_category){
        state.courseCategory = course_category;
    },
    setCourseFeeType(state,fee_types){
        state.courseFeeType = fee_types;
    },
    setCourseMonthFormula(state,formula){
        state.courseMonthFormula = formula;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, all) {
        state.all = all
    },
    setIsActive(state, value) {
        state.item.is_active = value
    },
    setShowDataAll(state, all) {
        state.showData = all
    },
    setQuery(state, query) {
        state.query = query
    },    
    setModuleAll(state, moduleAll) {
        state.moduleAll = moduleAll
    },
    setIndexDataAll(state, indexAll) {
        state.indexAll = indexAll
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    },    
    setProjectAll(state, projectAll) {
        state.projectAll = projectAll
    },        
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setBranchLab(state,value){
        state.courseLabs = value
    },
    setCourseLab(state,value){
        state.courselab = value
    },
    setCourseImage(state,value){
        state.courseImage = value
    },
    setReportIsActive(state, value) {
        state.item.report_is_active = value
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
