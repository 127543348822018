function initialState() {
    return {
        editDataAll:'',
        showData:'',
        loading:false
    }
} 

const getters = {
    editDataAll:state => state.editDataAll,
    showData:state => state.showData,
    loading:state => state.loading
}

const actions = {
    async fetchEditFaqData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/courseFAQ/edit/' + id)
                .then(response => {
                    if(response.data.result){
                        commit('setEditDataAll', response.data.data)
                        resolve(response)
                        commit('setLoading', false)
                    }
                    else{
                        commit('setEditDataAll', '')
                        resolve(response)
                        commit('setLoading', false)
                    }
                   
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                    commit('setLoading', false)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/courseFAQ/show/' + id)
                .then(response => {
                    if(response.data.result){
                        commit('setShowDataAll', response.data.data)
                        resolve(response)
                        commit('setLoading', false)
                    }
                    else{
                        commit('setShowDataAll', '')
                        resolve(response)
                        commit('setLoading', false)
                    }
                   
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                    commit('setLoading', false)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async updateData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            axiosLms.post('/api/v1/courseFAQ/' + form_data.id, params)
                .then(response => {
                        //commit('setEditDataAll', response.data.data)
                    resolve(response)
                    
                })
                .catch(function (error) {
                    //console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
      
    storeData({ commit, state, dispatch }, form_data) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            axiosLms.post('/api/v1/courseFAQ/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
        axiosLms.delete('/api/v1/courseFAQ/' + id)
                .then(response => {
                    resolve(response);
                    })
                    .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })  
                    .finally(() => {
                        // commit('setError', false)
                    })
        })
    },    
    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setShowDataAll(state, all) {
        state.showData = all
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    } 
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
