<template>
   <div class="" style="border-top:5px solid #51B848;">
      <div class="p-10 m-10" style="display: flex;justify-content: center;padding-top:30px !important; background-color:white;">
         <table width="800" align="center" styyle="m-5" style="background-color: white;padding: 15px 0;display:block;">
            <tbody>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="font-size: 14px;width: 240px;">Enrollment No: {{item.enrollment_no}}</td>
                                    <td align="right"><img src="/assets/images/logo.svg" alt="" width="140px"></td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                        <td align="center" style="color:#51B848; font-size:20px; font-weight: bold; padding: 20px 0;">Admission Form</td>
                     </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 130px; font-size: 14px;">Admission Date:</td>
                                    <td style="font-size: 14px;">{{item.admission_date}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 137px; font-size: 14px;">Student Name:</td>
                                    <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 153px;">{{item.full_name}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="font-size: 14px; width: 40px;">Age:</td>
                                    <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 100px;width: 153px;">{{item.age}}</td>
                                    <td style="width: 15px;"></td>
                                    <td style="font-size: 14px;width: 40px;">DOB:</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 153px;">{{item.birth_date}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 150px; font-size: 14px;">Address:</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;">{{item.student_address}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 167px; font-size: 14px;">Email Id:</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{item.email}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="width: 70px; font-size: 14px;">Mobile(F):</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{item.father_mob_no}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="width: 70px; font-size: 14px;">Mobile(M):</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{item.mother_mob_no}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <td style="width: 141px; font-size: 14px;">School Name:</td>
                                 <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px; width: 400px;">{{item.school_name}}</td>
                                 <td style="width: 10px;"></td>
                                 <td style="width: 70px; font-size: 14px;">Standard:</td>
                                 <td style="border-bottom:solid 1px #ccc;font-size: 14px; width: 153px;">{{item.standard}}</td>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 141px; font-size: 14px;">Course Details:</td>
                                    <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px;">{{item.course_name}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 144px; font-size: 14px;">Course Start Date:</td>
                                    <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 264px;">{{item.course_start_date}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="width: 90px; font-size: 14px;">Course Finish:</td>
                                    <td align="left" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 264px;">{{item.course_start_end}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <!-- <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 150px; font-size: 14px;">Batch Name- Lab Name</td>
                                    <td align="center" style="border-bottom:solid 1px #ccc;font-size: 14px;width: 620px;">
                                       <div v-for="(row, i) in item.batch_names"  :key="i">
                                          <span v-for="(r, inx) in row" :key="inx">
                                             <span v-if="inx">,</span>
                                             {{r}}-{{i}}
                                          </span>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr> -->
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 150px; font-size: 14px;">Selected Days:</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width:81%;">
                                       <table width="100%" align="center">
                                          <thead>
                                             <tr>
                                                <th style="border:solid 1px #ccc;padding:5px;">Day Name</th>
                                                <th style="border:solid 1px #ccc;padding:5px;">Batch Name</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr v-for="(row, i) in item.selected_days"  :key="i">
                                                <td style="border:solid 1px #ccc;padding:5px;">{{ i }}</td>
                                                <td style="border:solid 1px #ccc;padding:5px;"><span v-html="row"></span></td>
                                             </tr>
                                          </tbody>
                                       </table>   
                                    </td>
                                    <!-- <td style="width: 10px;"></td>
                                    <td style="width: 80px; font-size: 14px;">T-shirt size</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 100px;">{{item.tshirt_size}}</td> -->
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 150px; font-size: 14px;">T-shirt size:</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;">{{item.tshirt_size}}</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr style="height: 10px;"></tr>
                  <tr>
                     <td style="border-bottom:solid 3px #51B848;"></td>
                  </tr>
                  <tr style="height: 10px;"></tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td>
                                          <h2 style="margin: 0; font-size:18px;">Terms & Conditions:</h2>
                                          <ul style="padding:0; font-size:14px; list-style-type:none; margin: 10px 0 0 0;">
                                             <li style="line-height:20px;padding-bottom: 5px;">1. All rights for admissions reserved by RoboFun Lab Pvt. Ltd. (RFL).</li>
                                             <li style="line-height:20px;padding-bottom: 5px;">2. If the child is found tempering / breaking any asset at RFL, s/he is mandated to pay the damage cost at actuals.</li>
                                             <li style="line-height:20px;padding-bottom: 5px;">3. Child must not perform any harmful act towards any of the RFL staff or his fellow learners. RFL reserves all rights to cancel the child’s admission with no refund in such circumstances.</li>  
                                             <li style="line-height:20px;padding-bottom: 5px;">4. The course must be completed within the given timeline, as mentioned in the “Course Completion Date” in your admission form. If the sessions exceed beyond this date, each extra session will be charged as per the pro-rate of the existing fees structure of the course per session.</li>  
                                             <li style="line-height:20px;padding-bottom: 5px;">5. RFL reserves all rights to shoot photos and videos of the students inside the campus, while they are learning, building and coding robots and use it for any RFL related promotional materials in the future.
                                             </li>  
                                             <li style="line-height:20px;padding-bottom: 5px;">6. Fees paid for the course is non-refundable under all circumstances.
                                             </li>  
                                             <li style="line-height:20px;padding-bottom: 5px;">7. Batch timing and days, once decided, cannot be changed until the completion of the ongoing course.</li>
                                             <li style="line-height:20px;">8. Communication SoP – Parent must approach the branch manager to raise the concern, if any. Parent is strictly not allowed to communicate with the RFL coach directly, either at lab or otherwise.</li>
                                          </ul>
                                          

                                    </td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="width: 15px; font-size: 14px;">I</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 435px;">{{(item.father_name) ? item.mother_name+"/"+item.father_name : item.mother_name}}</td>
                                    <td style="width: 10px;"></td>
                                    <td style="width: 70px; font-size: 14px;">parent of</td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 435px;">{{item.full_name}}</td>
                                    <td style="width: 150px; font-size: 14px;">hereby declare that </td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="font-size: 14px;">we accept the terms & conditions of RFL and details provided by us are completely true at the best of our knowledge.</td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="font-size: 14px; width: 50px;">Date:</td>
                                    <td style="font-size: 14px;">{{currentDate()}}</td>
                                    <!-- <td style="font-size: 14px;width: 110px;">Father Signature: </td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 200px;"></td>
                                    <td style="font-size: 14px;width: 110px;">Mother Signature: </td>
                                    <td style="border-bottom:solid 1px #ccc;font-size: 14px;width: 200px;"></td> -->
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr v-if="item.is_tc_accepted == 0">
                     <td style="text-align: center;padding: 10px;padding-top: 40px;">
                        <button class="btn btn-success" @click="aggredParent()">I Agree</button>
                     </td>
                  </tr>
                  <tr v-else-if="item.is_tc_accepted == 1">
                     <td style="color: #51B848;text-align: center;padding-top: 10px;">You have already signed an agreement and now your courses are active.</td>
                  </tr>
                  <tr style="height: 50px;"></tr>
                  <tr>
                     <td style="border-bottom:solid 3px #51B848;"></td>
                  </tr>
                  <tr style="height: 10px;"></tr>
                  <tr>

                     <td>
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <td style="font-size: 14px;">
                                          <h2 style="font-size: 14px; color: #51B848; margin: 0;">RoboFun Lab (P) Ltd</h2>
                                          {{item.branch_address}}
                                    </td>
                                    <td align="right" style="font-size: 14px;">
                                          <!-- {{ item.father_mob_no }} | {{item.mother_mob_no}} <br> {{item.email}} -->
                                           {{item.branch_mobile_no}} <span v-if="item.branch_phone_no">| {{item.branch_phone_no}} </span> <br> {{item.branch_email}}
                                    </td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>       
                  </tr>
                  <tr>
                     <td style="padding:0px;">
                        <table width="100%" align="center">
                              <tbody>
                                 <tr>
                                    <!-- <td style="background-color:#51B848; text-align:center; font-size:14px; color: #fff; padding: 10px 35px;">
                                          <strong>Regd. Off.</strong>  105, Maher Park - B, Athwagate, Ring Road, Surat - 395001 (Gujarat) India T. +91 261 2478 828
                                          www.robofunlab.com
                                    </td> -->
                                    <td style="padding:0px;"><img src="/assets/images/footer-bg.png" alt="" width="100%"></td>
                                 </tr>
                              </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr style="height: 20px;"></tr>
            </tbody>
         </table>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
   components: {
        VueHtml2pdf
    },
    data() {
        return {
          //  loading:false
        }
    },
    computed: {
      ...mapGetters('AdmissionFormIndex', ['item','loading']),
    },
    watch: {
        "$route.params.id": function() { 
            this.getAdmissionForm(this.$route.params.id);     
        },
    }, 
    created(){
      this.getAdmissionForm(this.$route.params.id)
      .then((res) => {
            console.log(res.data);
            if(res.data.length == 0){
               this.$router.push('/404');
            }
      });
    },
    methods:{
      ...mapActions('AdmissionFormIndex', ['getAdmissionForm', 'storedata']),
      currentDate() {
         const current = new Date();
         const date = `${current.getDate()}/${("0" + (current.getMonth() + 1)).slice(-2)}/${current.getFullYear()}`;
         return date;
      },
      onProgress(event) {
         console.log(`Processed: ${event} / 100`);
      },
      hasGenerated() {
         alert("PDF generated successfully!");
      },
      generatePDF() {
         this.$refs.html2Pdf.generatePdf();
      },
      aggredParent(){
         this.storedata(this.$route.params.id)
         .then((res) => {
            if(res.data.result){
               this.$router.push('/admissionformpdf/'+this.$route.params.id)
            }
         });
      }  
   }
}
</script>