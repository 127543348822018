<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Student Batches</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Student Batch</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12 table-responsive my-branch">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                            <tr>
                                                <th>Student Name / Enrollment Number</th>
                                                <td>{{ item.student_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Course Name</th>
                                                <td>{{ item.display_course_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Lab Name</th>
                                                <td v-if="item.lab_id && item.lab_id.lab_name != null">{{ item.lab_id.lab_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Batch Time</th>
                                                <td v-if="item.batch && item.batch.batch_name != null">{{ item.batch.batch_name }}</td>
                                            </tr>
                                            <!-- <tr>
                                                <th>Trainer Name</th>
                                                <td v-if="item.trainer && item.trainer.employee_name != null">{{ item.trainer.employee_name }}</td>
                                            </tr> -->
                                            <tr>
                                                <th>Course Start Date</th>
                                                <td>{{ item.start_date }}</td>
                                            </tr>
                                            <tr v-if="item.end_date != null">
                                                <th>Course End Date</th>
                                                <td>
                                                    <span class="">
                                                        {{ item.end_date }}
                                                    </span>
                                                </td>
                                            </tr>                         
                                            <tr>
                                                <th>Batch Status</th>
                                                <td>
                                                    <span class="">
                                                        {{ item.batch_status_text }}
                                                    </span>
                                                </td>
                                            </tr>                         
                                            <tr>
                                                <th>Course Days</th>
                                                <td>{{ item.workingDaysName }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('StudentBatchSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('StudentBatchSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
