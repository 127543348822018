<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Batches Allocation</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Batch Allocation</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-xs-6">
                                    <table class="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                        <th>Batch Name</th>
                                            <td>
                                                <span v-if="item.batch !== null">
                                                    {{ item.batch.batch_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                        <th>Trainer Name</th>
                                            <td>
                                                <span v-if="item.employee !== null">
                                                    {{ item.employee.employee_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                        <th>Location</th>
                                            <td>
                                                {{ item.location_name }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div> 
                                <div class="col-xs-6">
                                    <table class="table table-bordered table-striped">
                                        <tbody>                                                                       
                                        <tr>
                                        <th>Batch Start Date</th>
                                            <td>
                                                {{ item.batch_start_date }}
                                            </td>
                                        </tr>
                                        <tr>
                                        <th>Batch End Date</th>
                                            <td>
                                                {{ item.batch_end_date }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('BatchAllocationsSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BatchAllocationsSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
