<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Email Template</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active">Email Template List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Email Template List</h3>
                    </div>
                    <div class="card-body">
                        <div class="btn-group mb-3">
                            <router-link
                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                            >
                                <i class="fa fa-plus"></i> Add New
                            </router-link>

                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>     
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>
                        </div>
                        <bootstrap-alert />
                        <div class="table-responsive setting-group">
                            <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                                >
                                   <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'actions'">
                                        <EmailTemplateActions @loadItems="loadItems" :xprops="xprops" :row="props.row"></EmailTemplateActions>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import EmailTemplateActions from '../../dtmodules/DatatableActions/EmailTemplateActions'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'

EmailTemplateActions.attachStore('EmailTemplateIndex');
export default {
    components,
    components: {
        EmailTemplateActions,
        CustomLoader
    },
    data() {
        return {
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Template Key",
                    field: "template_key",
                    filterOptions: {
                        styleClass: 'email_template_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Template Key', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Title",
                    field: "title",
                    filterOptions: {
                        styleClass: 'email_template_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Title', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Placeholder",
                    field: "placeholders",
                    filterOptions: {
                        styleClass: 'email_template_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Placeholder', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                // {
                //     label: "Content",
                //     field: "content",
                //     filterOptions: {
                //         styleClass: 'email_template_filter', // class to be added to the parent th element
                //         enabled: true, // enable filter for this column
                //         placeholder: 'Content', // placeholder for filter input
                //         filterValue: '', // initial populated value for this filter
                //         filterDropdownItems: [], // dropdown (with selected values) instead of text input
                //         // trigger: 'enter', //only trigger on enter not on keyup 
                //     },
                // },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                    {
                        field: "",
                        type: ""
                    }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'EmailTemplateIndex',
                route: 'email-template',
                permission_prefix: 'email_template_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.loadItems();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ],
                page: 1
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.updateParams({page:1});
            this.loadItems();
        },
        onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm? this.serverParams.searchTerm: "";
            if(flag== 1){
                this.refershLoad = true;
            }
            axios.get("/api/v1/email-templates", {
                params: {
                    max: this.serverParams.perPage,
                    page: this.serverParams.page,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:this.serverParams.columnFilters
                }
            }).then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag==1){
                        this.refershLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
            });
        },
        resetFilters() {
            this.serverParams.columnFilters = {};
            let columnFilters = {"template_Key":"", "title":"", "placeholder":"", "content":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>