<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventView"
            ref="modal"
            title="View Skill"
            @shown="openModel" 
            @close="handleClose"
            size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true"
        >
            <section class="content">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                            <tr>
                                                <th>Header Name</th>
                                                <td>{{ item.skill_header_id.header_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Skill Name</th>
                                                <td>{{ item.skill_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Skill Label</th>
                                                <td>{{ item.skill_label }}</td>
                                            </tr>
                                            <tr>
                                                <th>Rising Star</th>
                                                <td>{{ item.rising_star }}</td>
                                            </tr>
                                            <tr>
                                                <th>All Star</th>
                                                <td>{{ item.all_star }}</td>
                                            </tr>
                                            <tr>
                                                <th>Champion</th>
                                                <td>{{ item.champion }}</td>
                                            </tr>
                                            <tr>
                                                <th>Image</th>
                                                <td>
                                                    <div v-if="item.image != '' && item.image != null">
                                                        <img :src="item.image" class="student_image profile-user-img my-profile img-responsive" :alt="item.skill_name"> 
                                                    </div>
                                                    <div v-else>
                                                        <img src="/assets/images/logo.svg" class="student_image profile-user-img my-profile img-responsive" :alt="item.skill_name">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : [ 'header_id', 'Id' ],
    data() {
        return {
            
        }
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('SkillMasterSingle', ['item', 'loading', 'headerList']),
    },
    methods: {
        ...mapActions('SkillMasterSingle', ['setRemoveItem', 'fetchSkillEditData', 'fetchData', 'resetState']),

        openModel () {
            this.setRemoveItem()
            this.fetchSkillEditData(this.Id)
            .then(()=>{
                this.fetchData(this.header_id)
            })
        },
        handleClose(){
            this.setRemoveItem()
            this.$emit('closeModels')
        },
    }
}
</script>

<style scoped>
.card {
    min-height: 185px !important;
}
</style>
 