<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Reminders</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Reminder</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid col-md-offset-3 col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <label for="title">Title<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="title"
                                                        name="title"
                                                        id="title"
                                                        maxlength="255"
                                                        v-validate="'required'"
                                                        placeholder="Enter Reminder Title"
                                                        v-model="title"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('title')">
                                                          {{ $root.updateErrorMessage('title',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="reminder_date" class="form-group">
                                                <label for="reminder_date">Reminder Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    v-model= "reminder_date"
                                                    :config="$root.dpconfigDateFuture"
                                                    name="reminder_date"
                                                    placeholder="Select Reminder Date"
                                                    v-validate="{required:this.dateRequired}"
                                                    autocomplete="off"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('reminder_date')">
                                                    {{ $root.updateErrorMessage('reminder_date',this.errors)}}
                                                  </span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <h5><b>Do Contact ?</b></h5>
                                                <label class="switch" for="role">

                                                  <input
                                                        type="checkbox"
                                                        name="do_contact"
                                                        :checked="do_contact == 1"
                                                        @change="updateDoContact"
                                                        :disabled="true"
                                                        > 
                                                  <span class="slider round"></span>
                                                </label>   

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="do_contact == 1">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="reminder_to" class="form-group">
                                                <label for="reminder_to">Reminder To<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="reminder_to"
                                                        label="name"
                                                        id="reminder_to"
                                                        v-validate="'required'"
                                                        @input="updateReminderTo"
                                                        :value="reminder_to"
                                                        :options="remindersToAll"
                                                        autocomplete="off"
                                                        :disabled="true"
                                                        />
                                                <span class="error" v-show="errors.has('reminder_to')">
                                                    {{ $root.updateErrorMessage('reminder_to',this.errors, 'Reminder To')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="reminder_to_id" v-if="reminder_to && reminder_to.value_id != 3" class="form-group">
                                                <label for="reminder_to_id">Reminder To Name<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="reminder_to_id"
                                                        label="name"
                                                        id="reminder_to_id"
                                                        v-validate="'required'"
                                                        @input="updateReminderToId"
                                                        :value="reminder_to_id"
                                                        :options="remindersToIdAll"
                                                        autocomplete="off"
                                                        :disabled="true"
                                                        />
                                                <span class="error" v-show="errors.has('reminder_to_id')">
                                                    {{ $root.updateErrorMessage('reminder_to_id',this.errors, 'Reminder to name')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="do_contact == 1 && reminder_to.value_id == 3">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="contact_name">Contact Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="contact_name"
                                                        name="contact_name"
                                                        id="contact_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter Contact Name"
                                                        v-model="contact_name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('contact_name')">
                                                          {{ $root.updateErrorMessage('contact_name',this.errors,'Contact Name')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="contact_number">Contact Number<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="contact_number"
                                                        name="contact_number"
                                                        id="contact_number"
                                                        maxlength="20"
                                                        v-validate="'required|digits:10'"
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                        placeholder="Enter Contact Number"
                                                        v-model="contact_number"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('contact_number')">
                                                          {{ $root.updateErrorMessage('contact_number',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <label for="remarks">Remarks</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="remarks"
                                                        name="remarks"
                                                        id="remarks"
                                                        v-validate="'max:500'"
                                                        placeholder="Enter Remarks"
                                                        v-model="remarks"
                                                        autocomplete="off"
                                                        ></textarea>
                                                        <span class="error" v-show="errors.has('remarks')">
                                                          {{ $root.updateErrorMessage('remarks',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                  
                                  <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <h5><b>Close Reminder ?</b> 
                                                  <i 
                                                      data-toggle="tooltip" 
                                                      title="By closing reminder you will not be able to update this reminder next time" 
                                                      class="fa fa-info-circle">
                                                    
                                                  </i>
                                                </h5>
                                                <label class="switch" for="role">

                                                  <input
                                                        type="checkbox"
                                                        name="status"
                                                        :checked="status == 2"
                                                        @change="updateStatus"
                                                        > 
                                                  <span class="slider round"></span>
                                                </label>   

                                            </div>
                                        </div>
                                  </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
          <br>          
            <div class="row" v-if="item.history && item.history.length > 0">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Reminder History</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                </button> 
                            </div>                            
                        </div>
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>                     
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
export default {
    components,
    data() {
        
        return {
            title: '',
            reminder_date: '',
            do_contact: 0,
            contact_name: '',
            contact_number: '',
            reminder_to: null,
            reminder_to_id: null,
            status: 1,
            remarks: '',
            dateRequired:true,
            columns: [
                { title: 'Reminder Date', field: 'reminder_date', sortable: true, thComp: 'FilterTh' },
                { title: 'Remarks', field: 'remarks', sortable: true, thComp: 'FilterTh' },
                { title: 'Created On', field: 'dis_created_at', sortable: true, thComp: 'FilterTh' },
            ],
            query: {},
            xprops: {
                module: 'RemindersSingle',
                route: 'reminders/:id/edit',
                permission_prefix: 'reminders_'
            }
          }
    },
    computed: {
        ...mapGetters('RemindersSingle', ['data','total','item', 'loading','remindersToAll','remindersToIdAll'])
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    watch:{
      item: function(val){
        this.title = val.title
        this.reminder_date = val.reminder_date
        this.do_contact = val.do_contact
        this.remarks = val.remarks
        this.status = val.status
        
        if(this.do_contact == 1){
          this.reminder_to = val.reminder_to
          if(this.reminder_to.value_id == 3){
            this.contact_name = val.contact_name
            this.contact_number = val.contact_number
          }else{
            this.reminder_to_id = val.reminder_to_id
          }
        }
        let today = moment();
        let reminderDate = moment(this.reminder_date, 'DD/MM/YYYY')
        let diff = today.diff(reminderDate, 'days')
        
        if(diff > 0){
          this.reminder_date = '';
        }
      },
      query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('RemindersSingle', ['fetchData','updateData', 'setQuery', 'setReminderDetails' , 'fetchRemindersToAll','fetchRemindersToStudent', 'fetchRemindersToEmployee','resetState']),
        updateDoContact(e){
          let {checked} = e.target
          this.do_contact = 0;
          if(checked){
            this.do_contact = 1;
            if(!this.remindersToAll.length){
              this.fetchRemindersToAll()
            }
          }
        },
        updateStatus(e){
          let {checked} = e.target
          this.dateRequired = true;
          this.status = 1;
          if(checked){
            this.status = 2;
            this.dateRequired = false;
          }
        },
        updateReminderTo(val){
          this.reminder_to = val
          if(val.value_id == 1){
            this.fetchRemindersToStudent()
          }else if(val.value_id == 2){
            this.fetchRemindersToEmployee()
          }
        },
        updateReminderToId(val){
          this.reminder_to_id = val
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var reminderDetails = {
                      title: this.title,
                      do_contact: this.do_contact,
                      remarks: this.remarks,
                      status: this.status,
                 };
                 if(this.do_contact && this.reminder_to.value_id == 3 ){
                    reminderDetails.contact_name = this.contact_name;
                    reminderDetails.contact_number = this.contact_number;
                    reminderDetails.reminder_to = this.reminder_to.value_id;
                 }else if(this.do_contact && this.reminder_to.value_id != 3){
                    reminderDetails.reminder_to = this.reminder_to.value_id;
                    reminderDetails.reminder_to_id = this.reminder_to_id.id;
                 }
                 
                 if(this.status == 1){
                   reminderDetails.reminder_date = this.reminder_date
                 }else if(this.status == 2 && this.reminder_date){
                   reminderDetails.reminder_date = this.reminder_date
                 }
                 
                this.setReminderDetails(reminderDetails);
                this.updateData()
                     .then((res) => {
                          if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                  id: 'name',
                                  field: 'name',
                                  msg: res.data.message,
                                  scope: this.$options.scope,
                                });

                                return '';    
                            }        
                        this.$router.push({ name: 'reminders.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        resetFilters(){
            var filtersField= [];
            filtersField = ['reminder_date','remarks', 'dis_created_at'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData(this.$route.params.id)
        },
    }
}
</script>


<style scoped>

</style>
