<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Email Templates</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/email-template'">Email Templates</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Email Template</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Edit Email Template</h3>
                        </div>
                        <bootstrap-alert />
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <label for="template_key">Template_key<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="template_key"
                                        ref="template_key"
                                        maxlength="40"
                                        placeholder="Enter Template Key Eg: email_template or emailtemplate"
                                        v-validate="'required'"
                                        v-model="template_key"
                                    >
                                    <span class="error" v-show="errors.has('template_key')">
                                        {{ $root.updateErrorMessage('template_key',this.errors, 'Template Key')}}
                                    </span>
                                </div>
                                <div class="col-md-12 col-xs-12 mt-3">
                                    <label for="title">Title<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="title"
                                        ref="title"
                                        maxlength="40"
                                        placeholder="Enter Title"
                                        v-validate="'required'"
                                        v-model="title"
                                    >
                                    <span class="error" v-show="errors.has('title')">
                                        {{ $root.updateErrorMessage('title',this.errors, 'Title')}}
                                    </span>
                                </div>
                                <div class="col-md-12 col-xs-12 mt-3">
                                    <label for="placeholder">Placeholder<p class="astrisk">*</p></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="placeholder"
                                        ref="placeholder"
                                        placeholder="Enter Placeholder"
                                        v-validate="'required'"
                                        v-model="placeholders"
                                    >
                                    <span style="font-size: 13px;"><b>Note :</b> If you added your name (Eg: rahul) then to used that name dynamic in Content (Eg: Double Curly Braces {{}} inside write rahul).</span><br/>
                                    <span class="error" v-show="errors.has('placeholder')">
                                        {{ $root.updateErrorMessage('placeholder',this.errors, 'Placeholder')}}
                                    </span>
                                </div>
                                <div class="form-group col-md-12 col-xs-12 mt-3">
                                    <label for="content">Content<p class="astrisk">*</p></label>
                                    <ckeditor v-model="content" name="content" v-validate="'required'" placeholder="Enter Content" :editor="editor"></ckeditor>
                                    <span class="error" v-show="errors.has('content')">
                                        {{ $root.updateErrorMessage('content',this.errors,'Content')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 col-md-12 savebtn">
                               <vue-button-spinner
                                    class="std_information_save col-md-2 col-md-2 btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                >
                                    Save
                                </vue-button-spinner>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>    
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2'
export default {
    data() {
        return {
            template_key: '',
            title: '',
            placeholders: '',
            content: '',
            editorData: '',
            editor: ClassicEditor,
        }
    },
    created(){
        this.fetchEditData(this.$route.params.id)
    },
    watch:{
        getEditData(value) {
            this.template_key = value.template_key
            this.title = value.title
            this.placeholders = value.placeholders
            this.content = value.content
        }
    },
    computed: {
        ...mapGetters('EmailTemplateSingle', ['item', 'loading','getEditData', 'editData']),
    },
    components: {
        ckeditor: CKEditor.component
    },  
    methods:{
        ...mapActions('EmailTemplateSingle', ['setTemplateKey', 'setTitle', 'setPlaceholder', 'setContent', 'fetchEditData', 'setName', 'setDescription', 'setFile', 'updateData', 'resetState']),

        submitForm(){
            this.$validator.validate().then(result => {
                if (result) {    
                    let params = {
                        'template_key':this.template_key,
                        'title':this.title,
                        'placeholders':this.placeholders,
                        'content':this.content
                    };
                    this.updateData(params)
                    .then((res) => {
                        if(!res.data.result)
                        {
                           for (const [key, value] of Object.entries(res.data.data)) {
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                            return this.$root.handleValidationFocus(this.errors,this.$refs);
                        }  
                       
                        this.$router.push({ name: 'email-template.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        }
    }
}
</script>

<style>
.slider_image {
    position: relative;
    width: 1666px !important;
    height: 250px !important;
    object-fit: cover;
    margin: 25px 0px;
}
.slider-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
}
.img-responsive {
    max-width: 100%;
    height: auto;
}
.break-url {
    word-break: break-word;
}
</style>