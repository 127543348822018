<template>
    <div class="popup-form add-country">
    <b-modal id="addStudent"
             ref="addStudentModel"
             title="Add Student"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <Create @closeModel="closeModel" :inquiryId="inquiryId" @loadItems="loadItems"></Create>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Create from '@/components/cruds/Student/Create'
export default {
    components:{
        Create
    },
    data(){
        return {
            inquiryId:''
        }
    },
    watch:{
        inquiryId(value){
            if(value != ''){
                this.$refs.addStudentModel.show();
                this.setStudentInquiryId('');
            }
        }
    },
    created(){
        if(this.studentInquiryId != ''){
            this.inquiryId = this.studentInquiryId;
        }
        
    },
      computed: {
        ...mapGetters('StudentIndex', ['studentInquiryId']),
    },
    methods: {
         ...mapActions('StudentIndex', ['setStudentInquiryId','setInquiryShowData']),
         ...mapActions('InquiriesSingle', ['setInquiryShowData']),
         setInquiryId(value){
            this.inquiryId = value;
         },
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){

            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(data = ''){
            let id = '',course='';
            if(data != ''){
                id = data.id;
                course = data.course;
            }
            this.inquiryId = '';
            this.setStudentInquiryId('');
            this.setInquiryShowData('');
            
            this.$emit('setStudentId',id); 
            this.$emit('setStudentCourse',course); 
            this.$refs.addStudentModel.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

