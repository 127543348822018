<template>
    <div class="popup-form add-country">
        <b-modal id="modalPreventRefund"
                ref="modal"
                title="Add Refund"
                @ok="handleOk"
                okTitle="Save"
                okVariant="success"  
                @cancel="handleCancel"
                hideFooter
                @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
                <form @submit.stop.prevent="handleSubmit" >
                    <div class="container-fluid">  
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-4 col-xs-12">
                                        <div ref="refund_date" class="form-group">
                                            <label for="refund_date">Refund Date<p class="astrisk">*</p></label>
                                            <date-picker
                                                :value="item.refund_date"
                                                :config="$root.dpconfigDateCurrentFalse"
                                                name="refund_date"
                                                v-validate="'required'"
                                                @input="updateRefundDate"
                                                placeholder="Select Refund Date"
                                                autocomplete="off"
                                                >
                                            </date-picker>
                                            <span class="error" v-show="errors.has('refund_date')">
                                                {{ $root.updateErrorMessage('refund_date',this.errors,'Refund Date')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-xs-12">
                                        <label for="refund_date">Payment Mode<p class="astrisk">*</p></label>
                                        
                                                <v-select
                                                    name="payment_mode"
                                                    label="name"
                                                    :options="paymentModeAll"
                                                    v-model= "addPaymentModeParams"
                                                />
                                                <span class="error" v-show="errors.has('select_payment_mode')">
                                                    {{ $root.updateErrorMessage('select_payment_mode',this.errors) }}                                    
                                                </span>
                                            
                                    </div>
                                    <div class="col-md-4 app_payment_btn">
                                        
                                        <button type="button" class="btn btn-block btn-primary add_course_btn" @click="addPaymentRow()">Add Payment</button>
                                            
                                    </div>
                                </div>
                                <!-- ######### Payment Mode - Start ############ --> 
                                <div class="row">
                                    <div class="col-md-12 col-xs-12 paymentView course_detail_section" v-if="paymentDetails.length > 0"> 
                                        <div class="box box-warning box_body_bg table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Payment Mode</th>
                                                        <th>Amount</th>
                                                        <th>Bank Name</th>
                                                        <th>Cheque/Transaction Number</th>
                                                        <th>Date</th>
                                                        <th style="text-align: right;">Actions</th>
                                                    </tr> 
                                                    <tr v-for="(row, index) in paymentDetails" :key="row.id">
                                                        <td ref="payment_mode">
                                                        {{(row && row.payment_mode_detail && row.payment_mode_detail.name)?row.payment_mode_detail.name:''}}   
                                                        </td>
                                                        <td class="relative">
                                                        <i  class="fa fa-rupee"></i>&nbsp;
                                                            <input
                                                                type="text"
                                                                maxlength="15"
                                                                class="form-control fieldInput"
                                                                placeholder="Enter Amount"
                                                                name="mode_amount_paid"
                                                                ref="mode_amount_paid"
                                                                :disabled="paymentModeParams[row.row_number].payment_mode_detail && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                                oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                v-model= "paymentInputParams[row.row_number]"
                                                                v-validate="'required'"
                                                                >
                                                        </td>
                                                        <td>
                                                                <input
                                                                type="text"
                                                                class="form-control"
                                                                name="bank_name"
                                                                ref="bank_name"
                                                                maxlength="50"
                                                                placeholder="Enter Bank Name"
                                                                :disabled="paymentModeParams[row.row_number] &&  paymentModeParams[row.row_number].payment_mode_detail && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                                v-model= "bankNameParams[row.row_number]"
                                                                v-validate="'required'"
                                                                >
                                                        </td>
                                                        <td>
                                                                <input
                                                                type="text"
                                                                class="form-control"
                                                                name="instrument_no"
                                                                ref="instrument_no"
                                                                maxlength="50"
                                                                placeholder="Enter Cheque/Transaction Number"
                                                                :disabled="paymentModeParams[row.row_number] && paymentModeParams[row.row_number].payment_mode_detail && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                                v-model= "instrumentNumberParams[row.row_number]"
                                                                v-validate="'required'"
                                                                >
                                                        </td>
                                                        <td class="relative tabaleDatepicker" ref="instrument_date">
                                                            <date-picker
                                                                v-model= "instrumentDateParams[row.row_number]"
                                                                :config="$root.dpconfigDate"
                                                                name="instrument_date"
                                                                :disabled="paymentModeParams[row.row_number] && paymentModeParams[row.row_number].payment_mode_detail && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                                placeholder="Select Date"
                                                                v-validate="'required'"
                                                                autocomplete="off"
                                                                >
                                                            </date-picker>
                                                        </td>
                                                            <td><button v-if="index > 1" type="button" class="btn btn-block btn-danger add_course_btn" @click="removePaymentRow(row.row_number)">Remove</button>
                                                            </td>
                                                    </tr>
                                                        <tr class="total">
                                                        <td align="right"><b>Total</b></td>
                                                        <td><i class="fa fa-rupee"></i>&nbsp;{{doTotalPaymentDetail()}}{{totalPaymentDetail}}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>      
                                                    <tr class="error_tr" style="border:0px;">
                                                        <td class="border-0">
                                                            <span class="error" v-show="errors.has('payment_mode')">
                                                            {{ $root.updateErrorMessage('payment_mode',this.errors,'Payment Mode')}}
                                                            </span>
                                                        </td>
                                                        <td class="border-0">
                                                            <span class="error" v-show="errors.has('mode_amount_paid')">
                                                            {{ $root.updateErrorMessage('mode_amount_paid',this.errors,'Amount')}}
                                                            </span>
                                                        </td>
                                                        <td class="border-0">
                                                            <span class="error" v-show="errors.has('bank_name')">
                                                            {{ $root.updateErrorMessage('bank_name',this.errors)}}
                                                        </span>                    
                                                        </td>
                                                        <td class="border-0">
                                                            <span class="error" v-show="errors.has('instrument_no')">
                                                            {{ $root.updateErrorMessage('instrument_no',this.errors,'Cheque/Transaction #')}}
                                                        </span>                    
                                                        </td>
                                                        <td class="border-0">
                                                        <span class="error" v-show="errors.has('instrument_date')">
                                                            {{ $root.updateErrorMessage('instrument_date',this.errors,'Date')}}
                                                        </span>
                                                        </td>
                                                        <td class="border-0">   
                                                        </td>
                                                    </tr>                         
                                                </thead>
                                            </table>                                   
                                        </div>
                                    </div> 
                                </div>
                                <!-- ####### Payment Mode - End ############ -->
                                <!-- ######### Fee List - Start ############ --> 
                                <div class="row" v-if="pendingFeeAll.length > 0">
                                    <div class="col-md-12 col-xs-12 course_detail_section" > 
                                        <div class="box box-warning box_body_bg  table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="headColor">
                                                    <tr>
                                                        <th>Course Name</th>
                                                        <th>Total Fee</th>
                                                        <th>Total Paid</th>
                                                    </tr>                               
                                                </thead>
                                                <tbody v-for="(fees, idx) in pendingFeeAll" :key="fees.student_enroll_id">
                                                    <tr>
                                                        <td>
                                                            <div class="row col-xs-12">
                                                                <div class="col-xs-1">
                                                                    <div class="cursor-pointer table-arrow" @click="toogleModelSubTable(fees.student_enroll_id, fees.children)"><i :id="'refundCreateAngle_'+fees.student_enroll_id" :class="angle" aria-hidden="true"></i></div>
                                                                </div>
                                                                <div class="col-xs-1">&nbsp;&nbsp;</div>
                                                                <div class="col-xs-10">
                                                                    <div>{{fees.course_name}}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><i class="fa fa-rupee"></i>&nbsp; {{fees.TotalFees}}</td>
                                                        <td><i class="fa fa-rupee"></i>&nbsp; {{(fees.TotalPaid) ? fees.TotalPaid : 0 }}</td>
                                                    </tr>
                                                    <tr class="allhide" :id="'refundCreate_'+fees.student_enroll_id" >
                                                        <td colspan="3" class="innerTable">
                                                            <table class="table table-bordered table-hover">
                                                                <thead class="headColor">
                                                                    <tr>
                                                                        <th style="width:15%">Fee Type</th>
                                                                        <th>Total Fee</th>
                                                                        <th>Total Paid</th>
                                                                        <th>Total Due</th>
                                                                        <th>Adjusted Due</th>
                                                                        <th>Total Refund</th>
                                                                        <th>Amount to Refund</th>
                                                                        <th>Due adjustment</th>
                                                                        <th>Refund+Due adjustment</th>
                                                                        <th>GST Rate(%)</th>
                                                                        <th>GST Amount</th>
                                                                        <th>Fee Amount</th>
                                                                        
                                                                    </tr>                               
                                                                </thead>
                                                                <tbody>
                                                                    <tr  v-for="(fee, index) in fees.children" :key="fee.id">
                                                                        <td>{{fee.fees_type_name}} (<i class="fa fa-rupee"></i>)<br/>
                                                                            <div v-if="fee.coupon_amount && fee.coupon_amount > 0">
                                                                                <span style="font-size:12px;">(Full Amount - Coupon Discount)</span>
                                                                            </div>
                                                                            <div v-if="fee.credit_points && fee.credit_points > 0">
                                                                                <span style="font-size:12px;color:red">(Paid Using Credit *)</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>{{fee.total_fees}}<br/>
                                                                            <span style="font-size:12px;" v-if="fee.coupon_amount && fee.coupon_amount > 0">({{fee.main_amount}} - {{fee.coupon_amount}})</span>
                                                                        </td>
                                                                        <td>{{fee.total_paid}}<br/><span style="font-size:12px;color:red" v-if="fee.credit_points && fee.credit_points > 0">({{fee.credit_points}})</span></td>
                                                                        <td>{{fee.total_due}}</td>
                                                                        <td>{{fee.adjustedDue}}</td>
                                                                        <td>{{fee.total_refund}}</td>
                                                                        <td class="relative">
                                                                            <input
                                                                                type="text"
                                                                                maxlength="10"
                                                                                class="form-control"
                                                                                name="fee_paid"
                                                                                ref="fee_paid"
                                                                                oninput="this.value=this.value
                                                                                                        .replace(/[^\d.]/g, '')
                                                                                                        .replace(/(\..*)\./g, '$1')
                                                                                                        .replace(/(\.[\d]{2})./g, '$1');"
                                                                                placeholder="Enter Amount to Refund"
                                                                                v-model="inputParams[fees.student_enroll_id+''+index]"
                                                                                @input="calculateAmount(fees.student_enroll_id, index, fee.gst_rate, idx)"
                                                                                >
                                                                        </td>
                                                                        <td class="relative">
                                                                            <input
                                                                                type="text"
                                                                                maxlength="10"
                                                                                class="form-control"
                                                                                name="fee_paid"
                                                                                ref="fee_paid"
                                                                                oninput="this.value=this.value
                                                                                                        .replace(/[^\d.]/g, '')
                                                                                                        .replace(/(\..*)\./g, '$1')
                                                                                                        .replace(/(\.[\d]{2})./g, '$1');"                  
                                                                                placeholder="Enter Due adjustment"
                                                                                v-model="recoverParams[fees.student_enroll_id+''+index]"
                                                                                @input="calculateRefundPlusRecover(fees.student_enroll_id, index, fee.gst_rate, idx)"
                                                                                >
                                                                        </td>
                                                                        <td>{{refundPlusRecover[fees.student_enroll_id+''+index]?refundPlusRecover[fees.student_enroll_id+''+index]:0}}</td>
                                                                        <td>{{fee.gst_rate}}</td>
                                                                        <td>{{(gstParams[fees.student_enroll_id+''+index])?gstParams[fees.student_enroll_id+''+index]:0}}</td>
                                                                        <td>{{(amountParams[fees.student_enroll_id+''+index])?amountParams[fees.student_enroll_id+''+index]:0}}</td>
                                                                    
                                                                    </tr>
                                                                    <tr class="total">
                                                                        <td colspan="6" align="right"><b>Total</b></td>
                                                                        <td><i class="fa fa-rupee"></i> {{(totalPayment[fees.student_enroll_id]) ? totalPayment[fees.student_enroll_id] : 0}}</td>
                                                                        <td><i class="fa fa-rupee"></i> {{(totalRecover[fees.student_enroll_id])?totalRecover[fees.student_enroll_id]:0}}</td>
                                                                        <td><i class="fa fa-rupee"></i> {{(totalRefundRecover[fees.student_enroll_id])?totalRefundRecover[fees.student_enroll_id]:0}}</td>
                                                                        <td></td>
                                                                        <td><i class="fa fa-rupee"></i> {{(totalGST[fees.student_enroll_id]) ? totalGST[fees.student_enroll_id]:0}}</td>
                                                                        <td><i class="fa fa-rupee"></i> {{(totalAmount[fees.student_enroll_id]) ? totalAmount[fees.student_enroll_id]:0}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                </div>
                                <div v-else>
                                    <table class="table table-bordered table-hover">
                                        <thead class="headColor">
                                            <tr>
                                                <th>Course Name</th>
                                                <th>Total Fee</th>
                                                <th>Total Paid</th>
                                            </tr>                               
                                        </thead>
                                        <tbody>
                                            <tr><td colspan="3" class="text-align-center"><div>No course remain for Refund.</div></td></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- ######### Fee List - End ############ --> 
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <span class="col-xs-12 error" v-show="errors.has('payment_amount_check')">
                                            {{ 
                                            $root.updateErrorMessage('payment_amount_check',this.errors)
                                            }}
                                        </span> 
                                    </div>
                                </div>
                            </div>                   
                        </div>
                    </div>
                    <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
                </form>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['student_id', 'isAddRefund', 'refundShow'],   
    data() {
        return {
            disable: [],
            inputParams:[],
            recoverParams:[],
            paymentInputParams:[],
            refundPlusRecover:[],
            instrumentDateParams:[new Date()],
            instrumentNumberParams:[],
            bankNameParams:[],
            addPaymentModeParams:null,
            // paymentModeParams:[{value_id:1,name:'Cash'}],
            paymentModeParams:[],
            gstParams:[],
            amountParams:[],
            studentSeen:false,
            totalGST:[],
            totalAmount:[],
            totalPayment:[],
            totalRecover:[],
            totalRefundRecover:[],
            sumTotalAmount:0,
            sumTotalPaid:0,
            sumTotalBalance:0,
            sumTotalRefund:0,
            paymentModeAmount:0,
            angle:'fa fa-angle-right',
            // Code...
        }
    },
    computed: {
        ...mapGetters('RefundsSingle', ['item', 'loading','studentAll','pendingFeeAll','paymentModeAll','paymentDetails', 'recover_amount']),
    },
    watch: {
        "$route.params.id": function() {
            /* this.resetState()
            this.theCalendarCount(this.$route.params.id)
            this.fetchPaymentMode(this.$route.params.id) */
        },
        paymentModeAll:function(){
            
        }
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },          
    async created() {
        this.updateStudent(this.student_id);
        this.fetchPaymentModeAll();
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('RefundsSingle', ['fetchData', 'storeData', 'resetState', 'setStudent', 'setRefundDate', 'setManualReceiptNo', 'setReceiptAmount','fetchPendingFeeAll','setPaymentMode','setPaymentDetails','setPendingFeeAll','fetchPaymentModeAll','setTotalDue','setRecoverAmount']),
        ...mapActions('StudentShowSingle', ['fetchStudentCount']),

        closeModel(){
            this.$refs.modal.hide();
        },
        toogleModelSubTable(id ,child){
            var className = $('#refundCreate_'+id).attr('class');
            //if(child.length != 0){
                if($('#refundCreate_'+id).length > 0 && className == "allhide"){
                    $('#refundCreate_'+id).removeClass('allhide').addClass('allshow');
                    $('#refundCreateAngle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');                    
                }else{
                    $('#refundCreate_'+id).removeClass('allshow').addClass('allhide');
                    $('#refundCreateAngle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
                }
            //}            
        },
        modalOpened(){
            //..
            this.addPaymentModeParams = null;
            this.$emit('closeAddTab'); 
            this.$validator.errors.remove('payment_amount_check');
             const current = new Date();
            var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            this.updateRefundDate(date);
            this.updateStudent(this.student_id);
            this.fetchPaymentModeAll();
        },
        updateStudent(value) {
            if(this.student_id)
            {
                this.studentSeen = true;
                // Reset for element - Start
                this.inputParams = [];
                this.recoverParams = [];
                this.refundPlusRecover = [];
                this.paymentInputParams = [];
                this.paymentInputParams[0] = 0;
                this.paymentInputParams[1] = 0;
                this.bankNameParams = [];
                this.instrumentNumberParams = [];
                this.instrumentDateParams = [];
                // this.paymentModeParams = [{value_id:1,name:'Cash'}];
                this.paymentModeParams = [{value_id:1,row_number:0,payment_mode_detail:{value_id:1,name:'Cash'}},
                                        {value_id:5,row_number:1,payment_mode_detail:{value_id:5,name:'Due adjustment'}}];
                this.totalGST=[];
                this.totalAmount=[];
                this.totalPayment=[];
                this.totalRecover=[];
                this.totalRefundRecover=[];
                this.sumTotalAmount=0;
                this.sumTotalPaid=0;
                this.sumTotalBalance=0;
                this.sumTotalRefund=0;
                this.paymentModeAmount=0;
                this.gstParams=[]
                this.amountParams=[]
                // Reset for element - END
                this.setPaymentDetails(this.paymentModeParams);
                this.fetchPendingFeeAll(this.student_id)
                .then((res) => {
                   // this.getSumTotalValue()
                  })
            }else{
                this.studentSeen = false;
            }
            this.setStudent(this.student_id)
        },
        getSumTotalValue(){
            var sumTotalAmount = 0;
            var sumTotalPaid = 0;
            var sumTotalBalance = 0;
            var sumTotalRefund = 0;
            /* this.pendingFeeAll.forEach(function(fee){
                sumTotalAmount = sumTotalAmount + parseFloat(fee.TotalFees) || 0;
                sumTotalPaid = sumTotalPaid + parseFloat(fee.TotalPaid) || 0;
                sumTotalRefund = sumTotalRefund + parseFloat(fee.TotalRefund) || 0;
            }) */
            this.sumTotalAmount = parseFloat(sumTotalAmount).toFixed(0);
            this.sumTotalPaid = parseFloat(sumTotalPaid).toFixed(0);
            this.sumTotalBalance = parseFloat(sumTotalAmount - sumTotalPaid).toFixed(0);
            this.sumTotalRefund = parseFloat(sumTotalRefund).toFixed(0);

        },
        doTotalPaymentDetail(){            
            var tot = 0;
            this.paymentInputParams.forEach(function(fee, index){
                if(index != 1) {
                    if(!fee)
                    {
                        fee = 0;
                    }
                    tot = parseFloat(tot) + parseFloat(fee);
                }
            })
            this.totalPaymentDetail = parseFloat(tot).toFixed(0);
        },
        updateRefundDate(value) {
            if(value){
                var formatedFromDate = value.split('/');
                var finalDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]  
                this.setRefundDate(value)
            }
        },
        updateManualRefundNo(e) {
            this.setManualReceiptNo(e.target.value)
        },
        updateRefundAmount(e) {
            this.setReceiptAmount(e.target.value)
        },
        updatePaymentMode(index){
            // if(this.paymentModeParams[index] && this.paymentModeParams[index].value_id == 1){
            //     this.disable[index] = true;
            // } else if(this.paymentModeParams[index] && this.paymentModeParams[index].value_id == 2) {
            //     this.disable[index] = false;
            // }
        },
        
        addPaymentRow(){
            this.$validator.errors.remove('payment_amount_check');
            if(this.addPaymentModeParams == null)
            {
                this.$validator.errors.add({
                  id: 'select_payment_mode',
                  field: 'select_payment_mode',
                  msg: 'Please Select payment mode.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('select_payment_mode');
            }
            var newRow = {'payment_mode_detail':{'value_id':this.addPaymentModeParams.value_id,'name':this.addPaymentModeParams.name,'paymentModeValueId':this.addPaymentModeParams.value_id},'row_number':this.paymentDetails[this.paymentDetails.length - 1].row_number + 1}
            var paymentDetailsLocal = purify(this.paymentDetails);
            paymentDetailsLocal.push(newRow);
            if(this.addPaymentModeParams != null){
                    if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 1){
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = true;
                    } else// if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 2) 
                    {
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = false;
                    }
                this.paymentModeParams[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = newRow;
            }
            this.setPaymentDetails(paymentDetailsLocal);
            this.addPaymentModeParams = null;
        },

        removePaymentRow(index){
            // var paymentDetailsLocal = purify(this.paymentDetails);
            // paymentDetailsLocal.splice(index,1);
            // this.setPaymentDetails(paymentDetailsLocal);
            // this.paymentInputParams.splice(index,1);
            var paymentDetailsLocal = purify(this.paymentDetails);
            this.paymentDetails.forEach(function(selectedPaymentList,addedIndex){               
                if (selectedPaymentList && selectedPaymentList.row_number == index){
                    delete this.paymentModeParams[index];
                    delete this.paymentInputParams[index];
                    delete this.instrumentDateParams[index];
                    delete this.instrumentNumberParams[index];
                    delete this.bankNameParams[index];
                    paymentDetailsLocal.splice(addedIndex, 1);                       
                    return false;   
                }               
            }.bind(this))   
            this.setPaymentDetails(paymentDetailsLocal);    
        },
        totalDueAmount(totalFees,totalPaid){
            return parseFloat(parseFloat(totalFees)-parseFloat(totalPaid)).toFixed(0);
        },
        calculateAmount(student_enroll_id, index, gst_rate, idx)
        {
            var inputFees = [],
            localInputParams = (this.inputParams[student_enroll_id+''+index]) ? this.inputParams[student_enroll_id+''+index] : 0,
            inputFees = this.inputParams;
            if(!(inputFees[student_enroll_id+''+index] > 0))
            {
                inputFees[student_enroll_id+''+index] = 0;   
            }
            this.amountParams[student_enroll_id+''+index] = 0;
            this.gstParams[student_enroll_id+''+index] = 0;
            this.inputParams[student_enroll_id+''+index] = parseInt(this.inputParams[student_enroll_id+''+index])
            var balance = {};
            balance[student_enroll_id+''+index] = 0;
            this.pendingFeeAll.forEach(function(fees,i){
                var balanceTotal = 0;  
                fees.children.forEach(function(fee,x){
                    if(fees.student_enroll_id+''+x == student_enroll_id+''+index){
                        if(fee.total_paid == null){ fee.total_paid = 0 }
                        if(fee.total_refund == null){ fee.total_refund = 0 }
                        if(fee.total_paid > 0){
                            balanceTotal = parseFloat(parseFloat(fee.total_paid) - parseFloat(fee.total_refund) -parseFloat(fee.credit_points)).toFixed(0);
                        }
                    }
                })  
                if(balanceTotal != 0){ balance[student_enroll_id+''+index] = balanceTotal; }
            }.bind(this))
            if(balance[student_enroll_id+''+index] == undefined){
                balance[student_enroll_id+''+index] = 0;
            }
            if(parseFloat(inputFees[student_enroll_id+''+index]) > balance[student_enroll_id+''+index])
            {
                inputFees[student_enroll_id+''+index] = balance[student_enroll_id+''+index];
                localInputParams = balance[student_enroll_id+''+index];
            }
            if(gst_rate > 0)
            {
                gst_rate = parseFloat(gst_rate);
                this.amountParams[student_enroll_id+''+index] = (parseFloat(inputFees[student_enroll_id+''+index])*100/(100+gst_rate)).toFixed(0);
                this.gstParams[student_enroll_id+''+index] = (parseFloat(inputFees[student_enroll_id+''+index])-parseFloat(this.amountParams[student_enroll_id+''+index])).toFixed(0);
            }
            else{
                this.amountParams[student_enroll_id+''+index] = parseFloat(inputFees[student_enroll_id+''+index]).toFixed(0);
                this.gstParams[student_enroll_id+''+index] = 0;
            }
            this.refundPlusRecover[student_enroll_id+''+index] = ((parseFloat(this.inputParams[student_enroll_id+''+index]) || 0) + (parseFloat(this.recoverParams[student_enroll_id+''+index]) || 0)).toFixed(0);           
            var totalPayment = {};  
            var totalGST = {};
            var totalAmt = {};  
            var gstDetails = this.gstParams;
            var amountParams = this.amountParams;
            var recovePlusRefund = [];
            recovePlusRefund = this.refundPlusRecover;
            var totalSubPlusRecover = [];
            this.pendingFeeAll.forEach(function(fees,inx){     
                var total = 0;  
                var tgst = 0;
                var totalAmount = 0;
                var localTotalRefundRecover = 0;
                fees.children.forEach(function(fee,x){
                    if(inputFees[fees.student_enroll_id+''+x] != undefined){
                        total  = (parseFloat(total)+parseFloat(inputFees[fees.student_enroll_id+''+x])).toFixed(0);                        
                    }
                    if(gstDetails[fees.student_enroll_id+''+x] != undefined){
                        tgst = (parseFloat(tgst) + parseFloat(gstDetails[fees.student_enroll_id+''+x])).toFixed(0);
                    }  
                    if(amountParams[fees.student_enroll_id+''+x] != undefined){
                        totalAmount = (parseFloat(totalAmount) + parseFloat(amountParams[fees.student_enroll_id+''+x])).toFixed(0);
                    }   
                    if(recovePlusRefund[fees.student_enroll_id+''+x] != undefined){
                       localTotalRefundRecover  = (parseFloat(localTotalRefundRecover)+parseFloat(recovePlusRefund[fees.student_enroll_id+''+x])).toFixed(0);                        
                    }                 
                });
                if(total != 0){ totalPayment[fees.student_enroll_id] = total }
                if(tgst != 0){ totalGST[fees.student_enroll_id] = tgst }
                if(totalAmount != 0){ totalAmt[fees.student_enroll_id] = totalAmount }
                if(localTotalRefundRecover != 0){ totalSubPlusRecover[fees.student_enroll_id] = localTotalRefundRecover }
            });            
            if(totalPayment[student_enroll_id] == undefined){ totalPayment[student_enroll_id] = 0}
            this.totalPayment[student_enroll_id] = totalPayment[student_enroll_id]; 
            this.totalGST[student_enroll_id] = totalGST[student_enroll_id];
            this.totalAmount[student_enroll_id]= totalAmt[student_enroll_id];
            this.inputParams = inputFees; 
            //this.inputParams[student_enroll_id+''+index] = (localInputParams).toFixed(0); 
            this.totalRefundRecover[student_enroll_id] = totalSubPlusRecover[student_enroll_id]; 
                  
        },
        calculateRefundPlusRecover(student_enroll_id, index, gst_rate, idx)
        {
            var totalDue = [];
            this.recoverParams[student_enroll_id+''+index] = (this.recoverParams[student_enroll_id+''+index]) ? this.recoverParams[student_enroll_id+''+index] : 0;
            this.pendingFeeAll.forEach(function(fees,i){
                fees.children.forEach(function(fee,x){
                    if(fees.student_enroll_id+''+x == student_enroll_id+''+index){
                        if(fee.total_fees == null){ fee.total_fees = 0 }
                        if(fee.total_paid == null){ fee.total_paid = 0 }
                        if(parseFloat(fee.total_fees) >= parseFloat(fee.total_paid)){
                            totalDue[fees.student_enroll_id+''+x] = parseFloat(fee.total_due).toFixed(0);
                        }
                    }
                })  
            }.bind(this))
            if(parseFloat(this.recoverParams[student_enroll_id+''+index]) > totalDue[student_enroll_id+''+index])
            {
                this.recoverParams[student_enroll_id+''+index] = totalDue[student_enroll_id+''+index];
                localRecoverParams = totalDue[student_enroll_id+''+index];
            }            
            var localRecoverParams = [];
            localRecoverParams = this.recoverParams;
            this.refundPlusRecover[student_enroll_id+''+index] = ((parseFloat(this.inputParams[student_enroll_id+''+index]) || 0) + (parseFloat(this.recoverParams[student_enroll_id+''+index]) || 0)).toFixed(0);
            var recovePlusRefund = [];
            recovePlusRefund = this.refundPlusRecover;
            var totalSubRecover = [];
            var totalSubPlusRecover = [];
            this.pendingFeeAll.forEach(function(fees,inx){ 
                totalSubRecover[fees.student_enroll_id] = 0;  
                totalSubPlusRecover[fees.student_enroll_id] = 0;  
                var localTotalRecover = 0; 
                var localTotalRefundRecover = 0; 
                fees.children.forEach(function(fee,x){
                    if(localRecoverParams[fees.student_enroll_id+''+x] != undefined){
                       localTotalRecover  = (parseFloat(localTotalRecover)+parseFloat(localRecoverParams[fees.student_enroll_id+''+x])).toFixed(0);                        
                    }
                    if(recovePlusRefund[fees.student_enroll_id+''+x] != undefined){
                       localTotalRefundRecover  = (parseFloat(localTotalRefundRecover)+parseFloat(recovePlusRefund[fees.student_enroll_id+''+x])).toFixed(0);                        
                    }
                });
                if(localTotalRecover != 0){ totalSubRecover[fees.student_enroll_id] = localTotalRecover }
                if(localTotalRefundRecover != 0){ totalSubPlusRecover[fees.student_enroll_id] = localTotalRefundRecover }
            });
            this.totalRecover[student_enroll_id] = totalSubRecover[student_enroll_id];
            this.totalRefundRecover[student_enroll_id] = totalSubPlusRecover[student_enroll_id];
            var inputTotal = 0;
            this.totalRecover.forEach(function(tlt, i){
                if(tlt == undefined){tlt=0;}
                inputTotal = parseInt(inputTotal)+parseInt(tlt);
            });
            this.paymentInputParams[1] = inputTotal;
            this.setRecoverAmount(inputTotal); 
        },
        preparePayment(){
            // Calculate all payment value for submit
            var newFeesLocal = this.inputParams;
            var newRecoverLocal = this.recoverParams;
            var pendingFeeAllLocal =  {};
            var ths = this;
            this.pendingFeeAll.forEach(function(fees, index){
                var submitFee = [];
                var recoverFee = [];
                fees.children.forEach(function(fee, i){
                    var localIndex = fees.student_enroll_id+''+i;
                    if(newFeesLocal[localIndex] == undefined) {
                        newFeesLocal[localIndex] = 0;
                    } 
                    if(newRecoverLocal[localIndex] == undefined) {
                        newRecoverLocal[localIndex] = 0;
                    }
                    /* if(newFeesLocal[localIndex] != 0 && newRecoverLocal[localIndex] != 0) {
                    } else { */
                        var feesAmount = 0;
                        var gstAmount = 0;
                        if(parseFloat(fee.gst_rate) > 0) {
                            feesAmount = (parseFloat(newRecoverLocal[localIndex])*100/(100+parseFloat(fee.gst_rate))).toFixed(0);
                            gstAmount = (parseFloat(newRecoverLocal[localIndex])-(parseFloat(newRecoverLocal[localIndex])*100/(100+parseFloat(fee.gst_rate)))).toFixed(0);
                        } else {
                            feesAmount = parseFloat(newRecoverLocal[localIndex]);
                            gstAmount = 0;
                        }
                        var obj = {
                            'student_id': fee.student_id,
                            'course_id': fee.course_id,
                            'total_fees':fee.fees_amount,
                            'total_refund': fee.fees_amount,
                            'amount_to_refund': newFeesLocal[localIndex],
                            'recover_from_refund':newRecoverLocal[localIndex],
                            'gst_amount': ths.gstParams[localIndex],
                            'fee_amount':ths.amountParams[localIndex],
                            'recover_gst_amount': gstAmount,
                            'recover_fee_amount':feesAmount,
                            'fees_type': fee.fees_type,
                            'student_enroll_id': fees.student_enroll_id,
                        }
                        submitFee.push(obj);                       
                    //}
                });
                pendingFeeAllLocal[index] = submitFee;
                pendingFeeAllLocal[index]['RefundFinalTotal'] = ths.totalPayment;
                pendingFeeAllLocal[index]['recoverTotal'] = ths.paymentInputParams[1];
                pendingFeeAllLocal[index]['course_id'] = fees.course_id;
                pendingFeeAllLocal[index]['student_id'] = fees.student_id;
            }); 
            return pendingFeeAllLocal;
        },
        preparePaymentMode(){
            // Calculate all payment mode for submit
            var paymentDetailsLocal =  purify(this.paymentDetails);
            var paymentMode = [];
            this.paymentModeAmount = 0;
            this.paymentInputParams.forEach(function(amt, index){
                if(index != 1) {
                    paymentMode[index] = {};
                    paymentMode[index].amount = parseFloat(amt);
                    if(this.paymentModeParams[index].payment_mode_detail.value_id != 5){
                        this.paymentModeAmount += parseFloat(amt);
                    }
                    
                    paymentMode[index].value_id = this.paymentModeParams[index].value_id;
                    if(this.paymentModeParams != null && typeof this.paymentModeParams[index] == 'object')
                    {
                        paymentMode[index].payment_mode = this.paymentModeParams[index].payment_mode_detail.value_id;    
                    }
                    else{
                        paymentMode[index].payment_mode = null;       
                    }
                    if(this.instrumentNumberParams != null && typeof this.instrumentNumberParams[index] === 'string')
                    {
                        paymentMode[index].instrument_no = this.instrumentNumberParams[index];
                    }
                    else{
                        paymentMode[index].instrument_no = null;       
                    }
                    if(this.instrumentDateParams != null && typeof this.instrumentDateParams[index] === 'string')
                    {
                        paymentMode[index].instrument_date = this.instrumentDateParams[index];
                    }
                    else{
                        paymentMode[index].instrument_date = null;       
                    }
                    if(this.bankNameParams != null && typeof this.bankNameParams[index] === 'string')
                    {
                        paymentMode[index].bank_name = this.bankNameParams[index];
                    }
                    else{
                        paymentMode[index].bank_name = null;       
                    }
                }
            }.bind(this))
            paymentDetailsLocal['paymentMode'] = paymentMode;
            this.setPaymentDetails(paymentDetailsLocal);
        },
        handleCancel(evt){
            if(this.refundShow == true){
                this.$emit('viewloadrefund');
            }else{
                this.$emit('closeAddTab');  
            }
            this.updateStudent(this.student_id);
            this.updateRefundDate('');
            this.$validator.errors.remove('payment_amount_check');
            this.addPaymentModeParams = null;   
            this.fetchStudentCount(this.student_id);         
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.handleSubmit()                        
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        handleSubmit() {
            this.setTotalDue(this.sumTotalBalance)
            var details = [];
            details['submitFee'] = this.preparePayment();
            details['student_id'] = this.student_id;
            this.preparePaymentMode();
            this.$validator.errors.remove('payment_amount_check');
            var finalAmount = Object.values(this.totalPayment).reduce(function(a, b) { return parseInt(a) + parseInt(b); }, 0);
            if(this.totalRecover == undefined){ this.totalRecover = 0;}
            var finalAdj = Object.values(this.totalRecover).reduce(function(a, b) { return parseInt(a) + parseInt(b); }, 0);
            if(finalAdj == NaN || finalAdj == 'NaN'){console.log('test123');}
            this.setReceiptAmount(this.paymentModeAmount);
            if(this.paymentDetails.length == 0){
                this.$validator.errors.add({
                id: 'payment_amount_check',
                field: 'payment_amount_check',
                msg: 'Please add payment detail.',
                scope: this.$options.scope,
                });
                return false;   
            }else if(finalAdj == 0 && finalAmount == 0){
                this.$validator.errors.add({
                id: 'payment_amount_check',
                field: 'payment_amount_check',
                msg: 'Note: Amount to Refund and Payment Amount should be same.',
                scope: this.$options.scope,
                });
                return false;
            }else if(this.paymentModeAmount != finalAmount || this.recover_amount != finalAdj)
            {
                this.$validator.errors.add({
                id: 'payment_amount_check',
                field: 'payment_amount_check',
                msg: 'Note: Amount to Refund and Payment Amount should be same.',
                scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('payment_amount_check');
            }
            this.$validator.validate().then(result => {
                if (result) {
                    this.storeData(details)
                    .then(() => {
                        if(this.refundShow == true){
                            this.$emit('viewloadrefund');
                        }else{
                            this.$emit('closeAddTab');  
                        }
                        this.$eventHub.$emit('create-success')
                        this.$emit('loadItems')
                        this.fetchStudentCount(this.student_id);
                        //setTimeout(function(){
                            this.$refs.modal.hide();
                    // }, 300);
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }             
            })
        }    

    }
}
</script>


<style scoped>
.innerTable{
    margin: 10px;
    padding-left: 20px;
}
.innerTable table{
    background-color:#888a8d1c;
}
.headColor{
    background:linear-gradient(#f4f5f8,#f1f3f6);
}
.fieldInput{
    display: inline-block;
    max-width: 90%;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
position: initial;
}
.border-0{
    border: 0px;
}
.table-arrow{
    width: 10px;
    background: #888a8d29;
    padding: 0px 12px 0px 6px;
    border-radius: 3px;
}
.table-arrow i{
  margin: -2px;
}
.allhide {
display: none;
}
.allShow {
display: block;
}
td.tabaleDatepicker .bootstrap-datetimepicker-widget{
    inset: initial !important;
}
</style>
 