<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Multiple Branch Access</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/multiple-branch-access'">User Branchwise Role</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Multiple Branch Access</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Multiple Branch Access</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form @submit.prevent="submitForm" novalidate>
                                
                                    <bootstrap-alert />
                                
                                <div ref="user_name" class="form-group">
                                    <label for="user_name">Employee Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="user_name"
                                            label="employee_name"
                                            id="user_name"
                                            v-validate="'required'"
                                            @input="updateUserName"
                                            :value="item.user_name"
                                            :options="userAll"
                                            />
                                    <span class="error" v-show="errors.has('user_name')">
                                        {{ $root.updateErrorMessage('user_name',this.errors,'Employee Name')}}
                                    </span>
                                </div>
                                <div ref="branch_name" class="form-group">
                                    <label for="branch_name">Branch Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="branch_name"
                                            label="branch_name"
                                            id="branch_name"
                                            v-validate="'required'"
                                            @input="updateBranchName"
                                            :value="item.branch_name"
                                            :options="branchAll"
                                            />
                                    <span class="error" v-show="errors.has('branch_name')">
                                        {{ $root.updateErrorMessage('branch_name',this.errors, 'Branch Name')}}
                                    </span>
                                </div>

                                <div ref="role_name" class="form-group">
                                    <label for="role_name">Role Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="role_name"
                                            label="title"
                                            id="role_name"
                                            v-validate="'required'"
                                            @input="updateRoleName"
                                            :value="item.role_name"
                                            :options="roleAll"
                                            />
                                    <span class="error" v-show="errors.has('role_name')">
                                        {{ $root.updateErrorMessage('role_name',this.errors, 'Role Name')}}
                                    </span>
                                </div>
                           
                                <div class="col-md-12 col-md-12 savebtn">
                                    <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                    </vue-button-spinner>
                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    computed: {
        ...mapGetters('MultipleBranchAccessSingle', ['item', 'loading', 'branchAll','userAll','roleAll']) 
    },
    async created() {
        await this.fetchBranches()
        await this.fetchUsers()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        
        ...mapActions('MultipleBranchAccessSingle', ['storeData', 'resetState', 'setBranchName', 'setUserName', 'setRoleName','fetchBranches','fetchUsers','fetchRoles']),
        updateBranchName(value) {
            console.log("value",value);
            this.setRoleName(null)
            this.fetchRoles(value.id)
            this.setBranchName(value)
        },
        updateUserName(value) {
            this.setUserName(value)
        },
        updateRoleName(value) {
            this.setRoleName(value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.storeData()
                    .then((res) => {
                        if(!res.data.result)
                        {
                            this.$validator.errors.add({
                                id: 'user_name',
                                field: 'user_name',
                                msg: res.data.message,
                                scope: this.$options.scope,
                            });
                            return '';
                        }
                        this.$router.push({ name: 'multiple_branch_access.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        }
    }
}
</script>


<style scoped>

</style>
 