<template>
  <div class="wrapper" v-if="user!='' && currentRouteName!='confirm-admission-form' && currentRouteName!='admissionformpdf' && currentRouteName!='report-card-form' && currentRouteName!='reportcardpdf' && currentRouteName != 'certificate-form' && currentRouteName != 'certificatepdf'">
    <NavBar></NavBar>
    <SideBar></SideBar>
    <event-hub></event-hub>
    <router-view></router-view>

    <footer class="main-footer" style="text-align: end;right: 0px;">
        <strong>Copyright &copy; {{ currentDateTime() }}
          <a :to="'/'">RoboFun Lab</a>.</strong> All rights reserved.
    </footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>

  </div>
  <div class="wrapper" v-else>
      <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SideBar from '@/views/layout/SideBar'
import NavBar from '@/views/layout/NavBar'
export default {
  components:{
    SideBar,
    NavBar
  },
  computed: {
        ...mapGetters('AuthIndex', ['user']),
        currentRouteName() {
          return this.$route.name;
        }
    },
  watch:{
    user(){
      this.loginClass();
    }
  }, 
  created(){    
    this.loginClass();
  }, 
  methods: {
    currentDateTime() {
      const current = new Date();
      return current.getFullYear()      
    },
    loginClass(){
      if(this.user!=''){
        $('body').removeClass('login-page').addClass('sidebar-mini');
      }
      else{
        $('body').removeClass('sidebar-mini').addClass('login-page');
      }
    }
  },
}
</script>
<style>
.fa-search:before {
    content: "\f002";
    font-weight: 900;
}
</style>


