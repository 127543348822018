<template>
    <div class="card tableCard">
        <div class="table-responsive setting-group">
            <vue-good-table
                ref="table1"
                styleClass="vgt-table table table-bordered striped thead-dark"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :sort-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    perPage:10,
                    perPageDropdown: [10, 20, 40, 80, 100],
                    dropdownAllowAll: false,
                }"
                :isLoading.sync="isLoading"
                :select-options="{ 
                    enabled: false,
                }"
                :search-options="{
                    enabled: false,
                    placeholder: 'search',
                }"
                :rows="rows"
                :columns="columns"
                >
                    <div slot="emptystate" class="text-center">
                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span v-if="props.column.field == 'actions'">
                        <StudentBatchesActions @addIdModel="addIdModel"  @loadItems="loadItems" :xprops="xprops" :row="props.row" @batchIdModel="batchIdModel"></StudentBatchesActions>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
                <template slot="loadingContent">
                    <CustomLoader></CustomLoader>
                </template>
            </vue-good-table>
        </div>
        <SingleStudentBatch @loadItems="loadItems" :batchId="batchId" :student_id="student_id"></SingleStudentBatch>

        <HoldHistoryBatch @getId="getId" @loadItems="loadItems" :batchId="batchId" v-if="batchId >0 && pageName=='history'" :student_id="student_id"></HoldHistoryBatch>
        <HoldHistoryBatchShow @loadHoldHistory="loadHoldHistory" :history_id="history_id" v-if="pageName=='history'"></HoldHistoryBatchShow>
        <ExtendBatchPopup @loadItems="loadItems" :batchId="batchId" :student_id="student_id"></ExtendBatchPopup>
        <EditStudentBatch @loadItems="loadItems" :batchId="batchId" v-if="batchId >0 && pageName=='edit'" :student_id="student_id"></EditStudentBatch>
        <StudentCourseSchedulePopup :id="addId"  @loadItems="loadItems"></StudentCourseSchedulePopup>
    </div>
</template>

<script>
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
import SingleStudentBatch from '../Batch/Show'
import HoldHistoryBatch from '../Batch/HoldHistory'
import HoldHistoryBatchShow from '../Batch/HoldHistoryShow.vue'
import EditStudentBatch from '../Batch/Edit'
import StudentCourseSchedulePopup from '@/components/cruds/Student/StudentCourseSchedulePopup'
import ExtendBatchPopup from '@/components/cruds/Batch/ExtendBatchPopup'
import StudentBatchesActions from '../../dtmodules/DatatableActions/StudentBatchesActions'

StudentBatchesActions.attachStore('BatchSingle');
export default {
    components,
    components: {
        CustomLoader,
        StudentBatchesActions,
        SingleStudentBatch,
        EditStudentBatch,
        StudentCourseSchedulePopup,
        HoldHistoryBatch,
        HoldHistoryBatchShow,
        ExtendBatchPopup
    },
    props:['batchShow'],
    data() {
        return {
            addId:null,
            student_id:null,
            isLoading: true,
            batchLoad:false,
            modalShow:false,
            batchId:0,
            history_id:null,
            pageName:null,
            columns: [
                {
                    label: "Course Name",
                    field: "course_name",
                },
                {
                    label: "Start Date",
                    field: "start_date",
                },
                {
                    label: "End Date",
                    field: "end_date",
                },
                {
                    label: "Batch Status",
                    field: "batch_status",
                }, 
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                domainName: window.location.origin,
                module: 'BatchSingle',
                route: 'student-batch',
                permission_prefix: 'student_batch_'
            },
        }
    },
    watch: {
        "$route.params.id": function() {
            this.$root.relationships = this.relationships
            this.student_id = this.$route.params.id
        },    
        batchShow: function() {
            if(this.batchShow){
                this.loadItems();
                this.student_id = this.$route.params.id
            }
        },                
    },
    created() {
        this.$root.relationships = this.relationships
        if(this.batchShow){
            this.student_id = this.$route.params.id
            this.loadItems();            
        }
    },
    methods: {
        addIdModel(id){
            this.addId = id;
        },
        getId(id) {
            this.history_id = id;
        },
        openAddRefund(){
            this.isEditRefund = true
        },
        batchIdModel(id, page){
            this.batchId = id;
            this.pageName = page;
            console.log("emiy",this.batchId,this.pageName)
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.loadItems();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params[0].type,
                    field: params[0].field
                }],
                page: 1
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.updateParams({page:1});
            this.loadItems();
        },

        onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.batchLoad = true;
                }
            axiosLms.get("/api/v1/student-batchs", {
            params: {
                student_id:this.$route.params.id,
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
            });
        },
        loadHoldHistory(flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.batchLoad = true;
                }
            axiosLms.get("/api/v1/history/show/" + this.history_id, {
            params: {
                history_id:this.$route.params.id,
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag == 1){
                        this.batchLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
            });
        },
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"title":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>
<style scoped>
.m-t-25 {
    margin-top: 25px
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.upper-background {
    border: 1px solid #008b8b;
    background: #fffafa;
    z-index: 10021 !important;
}
ul li {
    margin-left: 10px;
    margin-right: 10px;
}
.dropdown-buttons {
    width: 150px;
}
.tableCard {
    min-height: 180px !important;
}
</style>
