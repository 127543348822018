<template> 
    <div class="project-card">
        <div class="">
            <div class="col-md-12 no-pad-show">
                <div class="card-header clearfix">
                    <div class="float-left col-md-5">
                        <label for="topic_name">Topic<p class="astrisk">*</p></label>
                         <v-select
                            id="topic_name"
                            name="topic_name"
                            label="topic_name"
                            v-validate="'required'"
                            @input="updateTopic"
                            :value="item.topicAll"
                            :options="topicAll"
                        />
                        <span class="error" v-show="errors.has('topic_name')">
                            {{ $root.updateErrorMessage('topic_name',this.errors,'Topic Name')}}
                        </span>
                    </div>
                    <div class="float-left col-md-5">
                        <label for="topic_name">SubTopic<p class="astrisk">*</p></label>
                        <v-select
                            id="topic_name"
                            name="topic_name"
                            label="topic_name"
                            @input="updateSubTopic"
                            :value="item.subtopicAll"
                            :options="subTopicAll"
                        />
                    </div>
                    <b-btn :disabled="item.topicAll.length == 0 || (item.topicAll.is_task_for_tutor != 1 && item.subtopicAll.is_task_for_tutor != 1)" @click="openAdd" v-b-modal.modalPreventTaskTutor class="btn btn-success btn-sm float-right mt-3">+ Add Task For Tutor</b-btn>
                    <AddTutor v-if="addView" :project_id="project_id" :topic_id="''" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></AddTutor>
                </div>
            </div>
            <div class="col-md-12"><hr/></div>
            <div class="col-md-12">
                <div class="card-body project-drag-view" v-if="QuestionListing.length > 0">
                    <draggable
                        v-bind="dragOptions"
                        tag="ul"
                        :value="QuestionListing"
                        class="dragArean todo-list"
                        @input="emitter"
                        :move="onMove"
                        >
                        <li class="parent" :class="el.id" :key="el.id" v-for="el in QuestionListing">
                            <div v-if="el.topic_id !=0">
                                <span class="handle handlemore">
                                    <i class="fas fa-ellipsis-v"></i>
                                    <i class="fas fa-ellipsis-v"></i>
                                </span>
                                <span class="text"><p>{{ el.question }}</p></span>
                                <div class="tools float-right" style="top:0px;">
                                    <div class="btn-group btn-group-sm">
                                        <button v-b-modal.modalPreventView v-if="$can('tutor_view')" @click="getView(el.id)" class="btn btn-primary edit_delete_btn" title="View"><i class="fa fa-fw fa-eye"></i></button>
                                        <button v-b-modal.modalPreventEdit v-if="$can('tutor_edit')" @click="getEdit(el.id)" class="btn btn-success edit_delete_btn" title="Edit"><i class="fa fa-fw fa-pencil"></i></button>
                                        <button v-if="$can('tutor_delete')"
                                            @click="destroyDataaaa(el.id)"
                                            type="button"
                                            title="Delete"
                                            class="btn btn-danger edit_delete_btn"
                                            >
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </draggable>
                </div>
                <div class="text-align-center" v-else>
                    <span>Oops! Data not found. Please select Topic - SubTopic first!</span>
                </div>
            </div>
            <div v-if="isLoading" slot="loadingContent">
                <CustomLoader></CustomLoader>
            </div> 
        </div>
        <ViewTutor v-if="view_tutor" :project_id="project_id" :question_id="question_id" @closeModels="closeModels"></ViewTutor>
        <EditTutor v-if="editView" :project_id="project_id" :question_id="question_id" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></EditTutor>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from "vuedraggable";
import CustomLoader from '@/components/CustomLoader'
import AddTutor from '../Tutor/Create'
import ViewTutor from '../Tutor/show'
import EditTutor from '../Tutor/Edit'

export default {
    components: {
        CustomLoader,
        draggable,
        AddTutor,
        ViewTutor,
        EditTutor
    },
    props:[ 'project_id', 'topic_id' ],
    data() {
        return {
            isLoading: false,
            addView:false,
            view_tutor:false,
            question_id:0,
            editView:false,
        }
    },
    computed: {
        ...mapGetters('TutorSingle', ['item', 'loading', 'topicAll', 'subTopicAll', 'QuestionListing']),
        dragOptions() {
            return {
                animation: 0,
                group: "people",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    created() {
        this.setItemTopicAll([])
        this.setSubTopicAll([])
        this.setItemSubTopicAll([])
        this.setQuestionListing([])
        this.fetchTopicAll(this.project_id)
    },
    watch:{
        
    },
    methods: {
        ...mapActions('TutorSingle', ['fetchTopicAll', 'fetchSubTopicAll', 'setSubTopic', 'setItemTopicAll', 'setSubTopicAll', 'setItemSubTopicAll', 'setQuestionListing', 'fetchQuestionData', 'destroyData', 'updateElements']),
        closeModels(){
            this.editView = false;
            this.view_tutor = false;
            this.addView = false;
        },
        loadQuestionList(){
            if(this.item.subtopicAll && this.item.subtopicAll.length != 0){
                this.fetchQuestionData(this.item.subtopicAll);
            }else if(this.item.topicAll && this.item.topicAll.length != 0){
                this.fetchQuestionData(this.item.topicAll);
            }else{
                this.setItemTopicAll([])
                this.setSubTopicAll([])
                this.setItemSubTopicAll([])
                this.setQuestionListing([])
            }
        },
        updateTopic(value){
            value = (value) ? value : [];
            this.setItemTopicAll(value);
            if(this.editView != true && this.view_quiz != true && this.addView != true){
                this.setItemSubTopicAll([]);
            }
            if(value && value.length != 0){
                this.fetchQuestionData(value);
                this.fetchSubTopicAll({"project_id" : this.project_id, "topic_id" : value.id})
            }else{
                this.loadQuestionList();
            }
        },
        updateSubTopic(value){
            value = (value) ? value : [];
            this.setItemSubTopicAll(value);
            if(value && value.length != 0){
                this.fetchQuestionData(value);
            }else{
                this.loadQuestionList();
            }
        },
        openAdd(){
            this.addView = true;
        },
        emitter(value) {
            this.updateElements(value)
            this.loadQuestionList()
        },
        onMove(evt){
            $(evt.related).find('.dragArean').css('min-height','70px').css('border','1px dotted black')
            setTimeout(function() {
                $('.dragArean').css('min-height','0px').css('border','unset')
            },300);
        },
        getView(id) {
            if(id > 0) {
                this.question_id = id
                this.view_tutor = true
            }
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            this.$eventHub.$emit('delete-success')
                        }else{
                            this.$eventHub.$emit('common',3,response.data.message)
                        }
                        this.loadQuestionList()
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        getEdit(id){
            if(id > 0) {
                this.question_id = id
                this.editView = true
            }
        },
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.todo-list>li .text {
    margin-left: 45px;
    max-width: 900px;
    padding-top: 3px;
}
.project-drag-view ul.dragArean li.parent {
   position: relative;
}
.project-drag-view ul.dragArean li.parent p {
    margin-bottom: 15px;
}
.project-drag-view ul.dragArean li.parent span.handlemore {
    position: absolute;
    top: 5px;
    left: 10px;
}
</style>
