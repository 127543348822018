<template>
    <div class="popup-form add-country">
    <b-modal id="modalExtendEndDateBatch"
             ref="modalExtendEndDateBatch"
             title="Extend End Date"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <ExtendBatch @loadItems="loadItems" v-if="batchId" @closeModel="closeModel" :batchId="batchId" :student_id="student_id"></ExtendBatch>
    </b-modal>
  </div>
</template>
<script>
import ExtendBatch from '@/components/cruds/Batch/ExtendBatch'
export default {
    props:['batchId','student_id'],
    components:{
        ExtendBatch
    },
    methods: {
        loadItems(){
            this.$emit('loadItems');
        },
         handleCancel(evt){
            
        }, 
        closeModel(){
            this.$refs.modalExtendEndDateBatch.hide();
        },   
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.licenseName = '';
            this.listOption = 1;
            this.start_date = '';
            this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

