<template>
    <div class="row">
        <div class="col-md-6 col-xs-12 table-responsive ">
            <table class="table table-bordered table-striped not-responsive-grid parent-table">
                <tbody>
                <tr>
                    <th>Father Name</th>
                    <td>{{ item.father_name }}</td>
                    </tr>
                <tr>
                    <th>Mother Name</th>
                    <td>{{ item.mother_name }}</td>
                    </tr>
                <tr>
                    <th>Father Mobile Number</th>
                    <td>{{ item.mobile_no }}</td>
                    </tr>
                <tr>
                    <th>Mother Mobile Number</th>
                    <td>{{ item.other_mobile_no }}</td>
                </tr>
                <tr>
                    <th>Login Status</th>
                    <td>{{ (item.user_status == 1)?'Active':'In Active' }}</td>
                </tr>
                </tbody>
            </table>
        </div>                                            
        <div class="col-md-6 col-xs-12 table-responsive">
            <table class="table table-bordered table-striped not-responsive-grid  parent-table">
                <tbody>                                            
                <tr>
                    <th>Email</th>
                    <td>{{ item.email }}</td>
                    </tr>
                <tr>
                    <th>Other Email</th>
                    <td>{{ item.other_email }}</td>
                    </tr>
                <!-- <tr>
                    <th>Username</th>
                    <td>{{ item.username }}</td>
                    </tr>
                <tr> -->
                    <tr>
                    <th>Role</th>
                    <td>
                        <span v-if="item.role !== null">
                            {{ item.role.title }}
                        </span>
                    </td>
                    </tr>
                    <tr>
                    <th>Referral Code</th>
                    <td>{{ item.referral_code }}</td>
                </tr>
                <tr>
                    <th>Available Points</th>
                    <td>{{ item.total_available_credits }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props:['showId'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.showId)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('ParentsSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('ParentsSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
