function initialState() {
    return {
        projectTypes:[],
        projectBrands:[],
        projectKits:[],
        loading: false,
        projectDetail:'',
    }
}

const getters = {
    
    loading: state => state.loading,
    projectTypes: state => state.projectTypes,
    projectBrands:state => state.projectBrands,
    projectKits:state => state.projectKits,
    projectDetail:state => state.projectDetail,
}

const actions = {
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/projects/' + id)
                .then(response => {
                    resolve(response);
                    })
                .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
    async fetchTypes({ commit, dispatch }, id) {
        await axios.post('/api/v1/getSelectedCommonMasterType',{'types':['Project Type','Project Brand','Project Kit']})
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;
                    
                    commit('setProjectTypes', data['Project Type'])
                    commit('setProjectBrands', data['Project Brand'])
                    commit('setProjectKits', data['Project Kit'])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })                
    },
    storeData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })



        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.project_type)) {
                params.set('project_type', '')
            } else {
                params.set('project_type', form_data.project_type.id)
            }

           
            if (_.isEmpty(form_data.project_brand)) {
                params.delete('project_brand')
            } else {
                for (let index in form_data.project_brand) {
                    params.set('project_brand['+index+']',form_data.project_brand[index].id)
                }
            } 

            if (_.isEmpty(form_data.project_kit)) {
                params.delete('project_kit')
            } else {
                for (let index in form_data.project_kit) {
                    params.set('project_kit['+index+']', form_data.project_kit[index].id)
                }
            } 
            axiosLms.post('/api/v1/projects/store', params)
                .then(response => {

                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch },form_data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(form_data.project_type)) {
                params.set('project_type', '')
            } else {
                params.set('project_type', form_data.project_type.id)
            }

           
            if (_.isEmpty(form_data.project_brand)) {
                params.delete('project_brand')
            } else {
                for (let index in form_data.project_brand) {
                    params.set('project_brand['+index+']',form_data.project_brand[index].id)
                }
            } 

            if (_.isEmpty(form_data.project_kit)) {
                params.delete('project_kit')
            } else {
                for (let index in form_data.project_kit) {
                    params.set('project_kit['+index+']', form_data.project_kit[index].id)
                }
            } 
            axiosLms.post('/api/v1/projects/' + form_data.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/projects/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectDetail', response.data.data)
                    commit('setLoading', false)
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          commit('setLoading', false)
                    })                
    },
    async fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/projects/edit/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectDetail', response.data.data)
                    commit('setLoading', false)
                }
                else{
                    commit('setProjectDetail', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          commit('setLoading', false)
                    })                
    },
    storeDeleteMainCourse({ state }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/deleteMainCourse/' + state.item.course.id)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                })
        })        
    },
    async fetchCoursesAll({ commit }) {
        await axios.get('/api/v1/combocourses')
            .then(response => {                
                commit('setFullCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
    }, 

    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setProjectDetail(state,project_detail){
        state.projectDetail = project_detail;
    },
    setProjectTypes(state,project_types){
        state.projectTypes = project_types;
    },
    setProjectBrands(state,project_brands){
        state.projectBrands = project_brands;
    },
    setProjectKits(state,project_kits){
        state.projectKits = project_kits;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
