<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Franchises</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/franchises'">Franchises</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Franchise</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Franchise</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Franchise Name</th>
                                            <td><span>{{ item.franchise_name }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Franchise Type</th>
                                            <td><span>{{ item.franchise_type.label }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Franchise Address</th>
                                            <td><span>{{ item.franchise_address }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Franchise Area</th>
                                            <td><span>{{ item.area }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Franchise City</th>
                                            <td>
                                                <span v-if="item.city_list !== null">
                                                    {{ item.city_list.city_name }} 
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Franchise State</th>
                                            <td>
                                                <span v-if="item.state_list !== null">
                                                    {{ item.state_list.state_name }}    
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Franchise Country</th>
                                            <td>
                                                <span v-if="item.country_list !== null">
                                                    {{ item.country_list.country_name }}    
                                                </span>
                                            </td>
                                        </tr>     
                                        <tr>
                                            <th>Franchise PIN/ZIP Code</th>
                                            <td><span>{{ item.pincode }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Franchise PAN Number</th>
                                            <td><span>{{ item.franchise_pan_no }}</span></td>
                                        </tr>                                   
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                 <div class="row text-align-center" style="display: contents;">
                                        <div v-if="item.profile != null" class="text-center">
                                            <img :src="item.profile" class="profile-user-img img-responsive img-circle my-profile my-profile-margin" :alt="item.employee_name" > 
                                          
                                        </div>
                                        <div v-else class="text-center">
                                            <img src="/assets/img/male.jpg" class="profile-user-img img-responsive my-profile my-profile-margin img-circle" :alt="item.employee_name" >
                                          
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                                                                                         
                                        <!-- <tr>
                                            <th>Username</th>
                                            <td><span>{{ item.username }}</span></td>
                                        </tr>                                                                                                           -->
                                        <tr>
                                            <th>Owner Name</th>
                                            <td><span>{{ item.owner_name }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Owner Mobile</th>
                                            <td><span>{{ item.owner_mobile }}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Owner Email</th>
                                            <td><span>{{ item.owner_email }}</span></td>
                                        </tr>
                                         <tr>
                                            <th>Login Status</th>
                                            <td><span>{{ (item.user_status == 1)?'Active':'In Active' }}</span></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>                 
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('FranchisesSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('FranchisesSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
