<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Fee</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Course Fee</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>                                   
                            </div>

                            <bootstrap-alert />                              
                            <div class="box-body">

                             <div class="col-md-6">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch"
                                                :options="branchMastersAll"
                                                />
                                             <span class="error" v-show="errors.has('branch')">
                                                {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                            </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div ref="course" class="form-group">
                                        <label for="course">Course Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="course"
                                                label="course_name"
                                                v-validate="'required'"
                                                @input="updateMainCourse"
                                                :value="item.main_course"
                                                :options="mainCoursesAll"
                                                />
                                             <span class="error" v-show="errors.has('course')">
                                              {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                            </span>
                                    </div>
                                </div>
                                <div class="col-md-6" v-show="false">
                                    <div ref="course" class="form-group">
                                        <label for="course">Course Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="course"
                                                label="display_course_name"
                                                v-validate="'required'"
                                                @input="updateCourse"
                                                :value="item.course"
                                                :options="coursesAll"
                                                />
                                             <span class="error" v-show="errors.has('course')">
                                              {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                            </span>
                                    </div>
                                </div>
                                <div class="row" v-if="!myFlag && loadingFeesAvailable">
                                  <div class="col-md-12"><br>
                                      <div class="alert text-center" style="font-size:1.6em;">
                                          <i class="fa fa-spin fa-refresh"></i> <span>&nbsp;Loading...</span> 
                                      </div>
                                  </div>
                                </div>                                 
                                <section v-if="item && item.branch && item.course && myFlag && !loadingFeesAvailable">
                                <div ref="start_date" class="form-group col-md-6 col-xs-12">
                                    <label for="start_date">New Start Date<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.start_date"
                                            :config="$root.dpconfigDate"
                                            name="start_date"
                                            v-validate="'required'"
                                            placeholder="Select New Start Date"
                                            @dp-change="updateStartDate"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('start_date')">
                                      {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                    </span>
                                </div>
                                <div class="clearfix"></div>
                                <div class=" col-md-10 col-md-offset-1">
                                    <div class="form-group">
                                            <section v-for="(courseLevel, index) in allCourseLevels" v-bind:key="index">
                                                <table class="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 150px;">Course Level</th>
                                                                <th style="width: 150px;">Fee type</th>
                                                                <th style="width: 280px;">New fee</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="(feesType, i) in feesTypeAll">
                                                            <tr>
                                                                <td>{{courseLevel.course_level_name}}</td>
                                                                <td>{{feesType.name}}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        name="percentage_from"
                                                                        id="percentage_from"
                                                                        oninput="this.value=this.value
                                                                                                .replace(/[^\d.]/g, '')
                                                                                                .replace(/(\..*)\./g, '$1')
                                                                                                .replace(/(\.[\d]{2})./g, '$1');"
                                                                        placeholder="Enter new fee"
                                                                        v-validate="'required'"
                                                                        :value="(newFees[courseLevel.course_level_id+''+feesType.value_id])?newFees[courseLevel.course_level_id+''+feesType.value_id]:0"
                                                                        @input="updateNewFeeAmount($event, courseLevel.course_level_id+''+feesType.value_id)"  
                                                                        >                                                            
                                                                </td>         
                                                            </tr>
                                                        </tbody>
                                                </table><br>                               
                                            </section>
                                        <span class="error" v-show="errors.has('percentage_from')">
                                          Enter At least one new fee data.
                                        </span><br>
                                     </div>
                                </div>
                                </section>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {        
        return {
            myFlag: true,
            newFees: [],
            totalCountFees:0,    
            totalCountGST:0,
            minValue:0,
            clLeave:'',
            lwpLeave:'',
            dpconfigDateFuture:this.$root.dpconfigDateFuture,
        }
    },
    computed: {
        ...mapGetters('FeesMastersSingle', ['item', 'loading','loadingFeesAvailable', 'coursesAll', 'branchMastersAll', 'feesTypeAll','courseDetailedData','allCourseLevels','feesAvailable','nextFeeStartDate','mainCoursesAll']),
        computedOldDate() {
            if(this.courseDetailedData && this.courseDetailedData[0]) {
                var formatedFromDate = this.courseDetailedData[0].from_date.split('-');
                var finalOldDate = formatedFromDate[2]+'/'+formatedFromDate[1]+'/'+formatedFromDate[0]
                return finalOldDate;
            }
        }                
    },
    created() {
        this.fetchCoursesAll(),
        this.fetchMainCoursesAll(),
        this.fetchFeesTypeAll()
        this.fetchBranchMasterAll()
            .then(res =>{
                this.setBranch(this.branchMastersAll[0])
            })
    },
    destroyed() {
        this.resetState()

        this.setFees_type(null);
        this.setFees_amount('');
    },
    methods: {
        ...mapActions('FeesMastersSingle', ['storeData', 'resetState', 'setCourse', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type', 'fetchCoursesAll', 'fetchFeesTypeAll', 'fetchBranchMasterAll','fetchCourseDetailedData','fetchCoursesLevel','setStartDate','updateBunchData','fetchIfFeesAvailable','setMainCourse','fetchMainCoursesAll']),
        countTotalFees(){
            // var myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            // this.setTotal_fees(myVal)
            // return myVal;
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            }
            this.setTotal_fees(myVal.toFixed(2));            
            return myVal.toFixed(2);
        },
        computedOldFee(courseLevel, feesType) {
            var oldFee = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                 if(courseLevel == this.courseDetailedData[i].course_level_id && feesType == this.courseDetailedData[i].fees_type) {
                    oldFee = this.courseDetailedData[i].fees_amount
                 }
             }
            return oldFee;
        },        
        updateMainCourse(value) {
            var localFlag = true;
            this.setMainCourse(value);
            if(value && value.course_type) {
                for (var i = 0; i < this.coursesAll.length; i++) {
                    if(this.coursesAll[i].main_course_id ==  value.id) {
                        this.updateCourse(this.coursesAll[i]);
                        localFlag = false;
                    }
                }
            }else{
                // this.$validator.errors.add({
                //                 id: 'course',
                //                 field: 'course',
                //                 msg: 'Course fees details not added.',
                //                 scope: this.$options.scope,
                //             });
            }
            if(localFlag) {
                this.updateCourse(null)
            }
        },
        updateCourse(value) {
            var localFlag = true;
            this.setCourse(value)
            if(value && value.course_name) {
                // value.from_date = this.nextFeeStartDate;
                // this.fetchCourseDetailedData(value.main_course_id)
                this.fetchCourseDetailedData(value)
                this.fetchCoursesLevel(value.main_course_id)
                // console.log(value);
                if(!this.item.branch){
                    
                    this.$validator.errors.add({
                        id: 'branch',
                        field: 'branch',
                        msg: 'please selecte branch',
                        scope: this.$options.scope,
                    });
                    localFlag = false
                    this.myFlag = false;
                    return false;
                }
                let params = {
                    // id:value.id,
                    id:value.main_course_id,
                    branch_id:this.item.branch.id,
                };
                // this.fetchIfFeesAvailable(value.id,this.item.branch.id)
                this.fetchIfFeesAvailable(params)
                    .then(res => {
                        if(this.feesAvailable == true) {
                            this.$validator.errors.add({
                                id: 'branch',
                                field: 'branch',
                                msg: 'Fees is already added for selected course in this branch',
                                scope: this.$options.scope,
                            });
                            localFlag = false
                            this.myFlag = false;
                        } 
                        // else {
                        //     this.$validator.errors.clear()
                        //     this.myFlag = true
                        // }

                        this.dpconfigDateFuture.minDate = moment(this.nextFeeStartDate,'DD/MM/YYYY');
                    })
            }else{
                console.log('course value not found');
                this.$validator.errors.add({
                    id: 'course',
                    field: 'course',
                    msg: 'Cource is not setup now',
                    scope: this.$options.scope,
                });
                localFlag = false
                this.myFlag = false;
            }

            if(localFlag) {
                this.$validator.errors.clear()
                this.myFlag = true                        
            }
        },
        updateNewFeeAmount(e, index) {
            this.$set(this.newFees, parseInt(index),e.target.value)
            var myvalue = 0;
            /*this is use for amount total*/
            this.newFees.forEach(function(fee,index){
                 myvalue = parseInt(myvalue) + parseInt((fee)?fee:0);
            }.bind(this))
            this.minValue = myvalue;
        },        
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },
        updateStartDate(e) {
            this.setStartDate(e.target.value)
        },           
        updateIs_gst_applicable(e) {

            // this.setIs_gst_applicable(e.target.value)
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                this.totalCountGST = 0;
                this.setGst_rate(0)
            }
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },
        updateBranch(value) {
            this.setBranch(value)
            this.updateCourse(this.item.course)
        },
        updateFees_type(value) {
            if(value && value.other_value != null){
                        this.totalCountGST = (value.other_value)?value.other_value:0;
                        this.setGst_rate(this.totalCountGST);
                        this.setFees_type(value)
                    }
        },       
        // submitForm() {
        //     this.$validator.validate().then(result => {
        //         if (result) {
        //                 this.storeData()
        //                    .then((res) => {
        //                           if(res.data.error_message)
        //                             {
        //                             this.$validator.errors.add({
        //                               id: 'fees_type',
        //                               field: 'fees_type',
        //                               msg: res.data.message,
        //                               scope: this.$options.scope,
        //                             });
        //                             return '';    
        //                         }    
        //                         this.$router.push({ name: 'fees_masters.index' })
        //                         this.$eventHub.$emit('create-success')
        //                     })
        //                     .catch((error) => {
        //                         console.error(error)
        //                     })
        //         }
        //         else{
        //             return this.$root.handleValidationFocus(this.errors,this.$refs);
        //         }          
        //     });
        // }
        submitForm() {
            if(this.minValue == 0){
                console.log('this.minValue',this.minValue)
             this.$validator.errors.add({
                    id: 'percentage_from',
                    field: 'percentage_from',
                    msg: 'Enter At least one new fee data0.',
                    scope: this.$options.scope,
                });
             return false;
            }
            if(this.myFlag) {
                this.$validator.validate().then(result => {
                 if (result) {
                    var finalData = [],
                    detailedData = [],
                    newFeesLocal = this.newFees;
                    for (var i = 0; i < this.allCourseLevels.length; i++) {
                        for (var j = 0; j < this.feesTypeAll.length; j++) {
                            var localIndex = this.allCourseLevels[i].course_level_id+''+this.feesTypeAll[j].value_id;
                            if(newFeesLocal[localIndex] === undefined) {
                            } else {
                                var formatedFromDate = this.item.start_date.split('/');
                                var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]                            
                                var obj = {
                                    'id': this.item.id,
                                    'branch_id': this.item.branch.id,
                                    'course_id': this.allCourseLevels[i].course_id,
                                    // 'course_id': fetch course id with course_level and course_id,
                                    'fees_type': this.feesTypeAll[j].value_id,
                                    'fees_amount': newFeesLocal[localIndex],
                                    'is_gst_applicable': this.feesTypeAll[j] && this.feesTypeAll[j].other_value != 0 ? 1 : 0,
                                    'gst_rate': this.feesTypeAll[j].other_value,
                                    'total_fees': 0,
                                    'from_date': finalOldDate
                                } 
                                finalData.push(obj);
                            }
                        }
                    }
                    this.updateBunchData(finalData)
                        .then((res) => {                  
                            this.$router.push({ name: 'fees_masters.index' })
                            this.$eventHub.$emit('update-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                    }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });
            }
        }        
    }
}
</script>


<style scoped>

</style>
