function initialState() {
    return {
        student:  null,
        coursesAll: [],
        studentAll: [],
        showDataAll: [],
        query: {},
        loading: false
    }
} 

const getters = {
    student: state => state.student,
    coursesAll: state => state.coursesAll,
    showDataAll: state => state.showDataAll,
    studentAll: state => state.studentAll,
    query: state => state.query,
    loading: state => state.loading,
}

const actions = {
    async fetchStudentData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchStudentDataForCancelAdmission')
                .then(response => {
                    commit('setStudentAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchCourseData({ commit, state, dispatch },id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/fetchCourseDataForCancelAdmission/' + id)
                .then(response => {
                    commit('setCoursesAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    revertData({ commit, state }, cancel_id) {
        return new Promise((resolve, reject) => {        
            axiosLms.post('/api/v1/deleteCancelAdmission/' + cancel_id)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
            })
    }, 
    async cancelAdmission({ commit, state, dispatch }, ids) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/fetchCourseDataForCancelAdmission/'+ids.student_id+'/'+ids.course_id+'/'+ids.student_enroll_id)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async deleteAdmissionApi({ commit, state, dispatch }, ids) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/fetchCourseDataForDeleteAdmission/',ids)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    markingCourseAsCompleted({ commit, state, dispatch }, ids) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/markAsCompleteCourse/'+ids.student_id+'/'+ids.course_id+'/'+ids.student_enroll_id)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchShowDataForCancelledAdmission/' + id)
                .then(response => {
                    commit('setShowDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setCoursesAll({ commit }, value) {
        commit('setCoursesAll', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }    
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setStudent(state, value) {
        state.student = value
    },
    setQuery(state, query) {
        state.query = query
    },
    setStudentAll(state, studentAll) {
        state.studentAll = studentAll
    },
    setCoursesAll(state, coursesAll) {
        state.coursesAll = coursesAll
    },
    setShowDataAll(state, showDataAll) {
        state.showDataAll = showDataAll
    },     
    resetState(state) {
        state = Object.assign(state, initialState())
    }    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
