<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventView"
                ref="modal"
                title="View Quiz Question"
                @shown="openModel" 
                @close="handleClose"
                size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-6 col-xs-12">
                                        <table class="table table-bordered table-striped not-responsive-grid">
                                            <tbody>
                                            <tr>
                                                <th>Project Name</th>
                                                <td>{{ item.project.project_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Topic Name</th>
                                                <td>{{ item.topicAll.topic_name }}</td>
                                            </tr>
                                            <tr v-if="item.subtopicAll && item.subtopicAll.length != 0">
                                                <th>SubTopic Name</th>
                                                <td>{{ item.subtopicAll.topic_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Quiz Type</th>
                                                <td>{{ item.quiz.name }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Question Detail</h3>
                            </div>
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <table class="table table-bordered table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <th class="col-md-1">Question</th>
                                                    <td class="col-md-11">{{ item.question }}</td>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 4">
                                                    <th class="col-md-6">Image Question</th>
                                                    <th class="col-md-6">
                                                        <div v-if="item.quiz_image != null">
                                                            <img :src="item.quiz_image" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="item.quiz_image" style="width: 20%;height: 100px;" > 
                                                        </div>
                                                        <div v-else>
                                                            <img src="/assets/img/male.jpg" class="franchise_image img-circle my-profile" :alt="item.quiz_image" style="width: 20%;height: 100px;">
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table v-if="item.question_options && item.question_options.length > 0" class="table table-bordered table-hover">
                                            <tbody>
                                                <tr v-if="item.quiz.value_id == 2">
                                                    <th class="col-md-6">Correct Answer</th>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 3">
                                                    <th class="col-md-6">Options</th>
                                                    <th class="col-md-6">Correct Match Options</th>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 4">
                                                    <th class="col-md-6">Options</th>
                                                    <th class="col-md-6">Correct Answer</th>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 5">
                                                    <th class="col-md-6">Options</th>
                                                    <th class="col-md-6">Correct Answer</th>
                                                </tr>
                                                 <tr v-if="item.quiz.value_id == 6">
                                                    <th class="col-md-6">Options</th>
                                                    <th class="col-md-6">Correct Answer</th>
                                                </tr>
                                            </tbody>
                                            <tbody v-for="(row, index) in item.question_options" :key="row.id">
                                                <tr v-if="item.quiz.value_id == 2">
                                                    <th class="col-md-6">{{row.options}}</th>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 3">
                                                    <th class="col-md-6" v-if="IsImageA[index] == 0">{{row.options}}</th>
                                                    <td class="col-md-6" v-else><img :src="row.options" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="row.options" style="width: 20%;height: 100px;" ></td>
                                                    <td class="col-md-6" v-if="IsImageNext[index] == 0">{{ row.option_second }}</td>
                                                    <td class="col-md-6" v-else><img :src="row.option_second" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="row.options" style="width: 20%;height: 100px;" ></td>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 4">
                                                    <th class="col-md-6">{{row.options}}</th>
                                                    <td class="col-md-6">{{(row.correct_answer && row.correct_answer ==1) ? 'Yes' : 'No' }}</td>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 5">
                                                    <th class="col-md-6">{{row.options}}</th>
                                                    <td class="col-md-6">{{(row.correct_answer && row.correct_answer ==1) ? 'Yes' : 'No' }}</td>
                                                </tr>
                                                <tr v-if="item.quiz.value_id == 6">
                                                    <th class="col-md-6">
                                                        <div v-if="row.options != null">
                                                            <img :src="row.options" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="row.options" style="width: 20%;height: 100px;" > 
                                                        </div>
                                                        <div v-else>
                                                            <img src="/assets/img/male.jpg" class="franchise_image img-circle my-profile" :alt="row.options" style="width: 20%;height: 100px;">
                                                        </div>
                                                    </th>
                                                    <td class="col-md-6">{{(row.correct_answer && row.correct_answer ==1) ? 'Yes' : 'No' }}</td>
                                                </tr>
                                            </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Tips Details</h3>
                            </div>
                            <div class="card-body" >
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <table class="table table-bordered table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <th width="25%">Tips Document/Video</th>
                                                <td>
                                                    <span v-if="item.tips_file">
                                                        <a target="_blank" :href="item.tips_file_url" >Click Here to View</a>
                                                    </span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="25%">Tips Description</th>
                                                <td>{{ (item.tips_description != '') ? item.tips_description : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Quiz Skill Details</h3>
                            </div>
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <table class="table table-bordered table-striped not-responsive-grid table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Skill</th>
                                                    <th>Points</th>
                                                    <th>Weightage</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="skillsDetails.length>0">
                                                <tr v-for="row in skillsDetails">
                                                    <td>{{row.skills.skill_name}}</td>
                                                    <td>{{Number(row.points)}}</td>
                                                    <td>{{Number(row.weightage)}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : ['project_id', 'question_id'],
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('QuizSingle', ['IsImageA', 'IsImageNext','item', 'loading','topicAll', 'subTopicAll', 'projectList', 'quizList', 'OptionsData', 'OptionsOriginalData']),
        ...mapGetters('QuizSkillSingle', ['skillsDetails'])
    },
    methods: {
        ...mapActions('QuizSingle', ['storeData', 'fetchTopicSubtopicAll', 'setDescription', 'setTopicFiles', 'fetchProjectAll', 'fetchData', 'fetchQuizType', 'setQuiz', 'setItem', 'setRemoveItem', 'setQuestion', 'setOption', 'fetchQuizData', 'updateData', 'fetchQuestionData', 'setOptionImage', 'setCorrectAnswer', 'setQuestionImage', 'setOriginalOption', 'resetState']),
        ...mapActions('QuizSkillSingle', ['fetchQuizSkillData','resetState']),
        openModel () {
            this.setRemoveItem('');
            this.fetchQuizData(this.question_id)
            .then(()=>{
                this.fetchData(this.project_id); 
                this.fetchQuizType()
            });
            this.fetchQuizSkillData(this.question_id)
        },
        handleClose(){
            this.$emit('closeModels');
        },  
    }
}
</script>


<style scoped>
.card {
    min-height: 185px !important;
}
</style>
 