function initialState() {
    return {
        item: {
            id: null,
            fee_id: null,
            amount: null,
            branch: null,
            lab_name: null,
        }, 
        branchName: 'Fetching Branch Name...',
        loading: false,
        feesTypeAll: [],
        branchMastersAll: [],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    feesTypeAll: state => state.feesTypeAll,
    branchMastersAll: state => state.branchMastersAll,
    branchName: state => state.branchName,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if (_.isEmpty(state.item.fee_id)) {
                params.set('fee_id', '')
            } else {
                params.set('fee_id', state.item.fee_id.value_id)
            }   
            if (_.isEmpty(state.item.branch)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch.id)
            }         
            params.set('amount', state.item.amount)
            
            axios.post('/api/v1/branch-admission-fee', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            if (_.isEmpty(state.item.fee_id)) {
                params.set('fee_id', '')
            } else {
                params.set('fee_id', state.item.fee_id.value_id)
            }   
            if (_.isEmpty(state.item.branch)) {
                params.set('branch_id', '')
            } else {
                params.set('branch_id', state.item.branch.id)
            }            
            params.set('amount', state.item.amount)
            axios.post('/api/v1/branch-admission-fee/'+ state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.da
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axios.get('/api/v1/branch-admission-fee/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    })           
        dispatch('fetchFeesTypeAll')
        //dispatch('fetchBranchName')            
        dispatch('fetchBranchMasterAll')            
    },
    fetchBranchName({ commit }) {
         axios.get('/api/v1/branchNameFromSession')
            .then(response => {
                commit('setBranchName', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchBranchMasterAll({ commit }) {
        // axios.get('/api/v1/branch')
        axios.get('/api/v1/branchList?isDropdown=1')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })             
    },    
    fetchFeesTypeAll({ commit }) {
        axios.get('/api/v1/feesTypeList')
            .then(response => {
                commit('setFeesTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },
    setFees_type({ commit }, value) {
        commit('setFees_type', value)
    },
    setFees_amount({ commit }, value) {
        commit('setFees_amount', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setLabName({ commit }, value) {
        commit('setLabName', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setFees_type(state, value) {
        state.item.fee_id = value
    },
    setFees_amount(state, value) {
        state.item.amount = value
    },
    setBranch(state, value) {
        state.item.branch = value
    },
    setLabName(state, value) {
        state.item.lab_name = value
    },
    setFeesTypeAll(state, value) {
        state.feesTypeAll = value
    },
    setBranchName(state, value) {
        state.branchName = value.branch_name
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
