/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window._ = require('lodash')
window.axios = require('axios')
window.axiosLms = window.axios.create({
    // baseURL: 'http://192.168.2.26:8005/'    // Akshay
    // baseURL: 'http://203.88.157.74:7522/'
    // baseURL: 'http://192.168.2.98:8001/'    // Kishan
    baseURL: 'https://stageapi.robofunlab.com/'     // Kevin
});
//window.axios.defaults.baseURL = 'http://192.168.2.26:8003/'     // Akshay
// window.axios.defaults.baseURL = 'http://203.88.157.74:7565'
// window.axios.defaults.baseURL = 'http://192.168.2.98:9091/'    // Kishan
window.axios.defaults.baseURL = 'https://stageapi.robofunlab.in/'    // Kevin

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axiosLms.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
 
// check a 401 response interceptor for 1st laravel api url
window.axios.interceptors.response.use(function (response) {
    if(response && response.data && response.data.flag == 8) {
        window.location.href = location.protocol + "//" + location.host + "/"
        return;
    }
    setTimeout(function(){
        addDataAttrInTableGrid();
    },200);
    return response;
},  function (error) {
    if(error.response.status == 401){
        window.localStorage.removeItem('vuex')
        window.location.href = location.protocol + "//" + location.host + "/";
    }
    return Promise.reject(error);
});
/* below is for 2nd LMS's axios which is defined as axiosLms */
window.axiosLms.interceptors.response.use(function (response) {
    if(response && response.data && response.data.flag == 8) {        
        window.location.href = location.protocol + "//" + location.host + "/"
        return;
    }
    setTimeout(function(){
        addDataAttrInTableGrid();
    },200);
    return response;
},  function (error) {
    if(error.response.status == 401){
        window.localStorage.removeItem('vuex')
        window.location.href = location.protocol + "//" + location.host + "/";
    }
    return Promise.reject(error);
});
window.purify = o => JSON.parse(JSON.stringify(o))
let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
    window.axiosLms.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}
 
// let date_format     = document.head.querySelector('meta[name="dp-date"]')
// let time_format     = document.head.querySelector('meta[name="dp-time"]')
// let datetime_format = document.head.querySelector('meta[name="dp-datetime"]')
// let app_locale      = document.head.querySelector('meta[name="app-locale"]')
let date_format     = document.head.querySelector('meta[name="dp-date"]')
let time_format     = document.head.querySelector('meta[name="dp-time"]')
let datetime_format = document.head.querySelector('meta[name="dp-datetime"]')
let app_locale      = document.head.querySelector('meta[name="app-locale"]')

var dt = moment().millisecond(0).second(0).minute(0).hour(0)

if (date_format && time_format && datetime_format) {
    window.date_format_moment     = date_format.content
    window.time_format_moment     = time_format.content
    window.datetime_format_moment = datetime_format.content
    window.app_locale             = app_locale.content
} else {
    console.error('Moment.js date and time formats not found')
}

$.fn.ignore = function(sel){
    return this.clone().find(sel||">*").remove().end();
};
 
function addDataAttrInTableGrid() {
    $("table").each(function(){
        var th_att = [];
        $(this).find("th").each(function() {
            var thName = "";
            if ($(this).find("div").html()) {
                thName = $.trim($(this).find("div").ignore("a").ignore("ul").html());
            } else if ($(this).find("label").html()) {
                thName = $.trim($(this).find("label").html());
            } else {
                thName = $.trim($(this).html());
            }
            
            if ($(this).hasClass("inqStats")) {
                thName = $.trim($(this).prev().find("div").ignore("a").ignore("ul").html());
            }

            thName = thName.replace(/<!---->/gi, "");
            thName = thName.replace(/&amp;/gi, "&");
            thName = thName.includes("Actions") ? "" : thName;

            if (!thName) {
                th_att.push("");
            } else {
                th_att.push(thName);
            }
        });

        $(this).find("tr").each(function() {
            if (!$(this).hasClass("not-include-in-data-grid")) {
                $(this).find("td").each(function(i) {
                    var tdHtml = $.trim($(this).html());
                    if (th_att[i]) {
                        if (!tdHtml) {
                            $(this).html('&nbsp')
                        }
                        if (tdHtml != "( No Data )") {
                            $(this).attr("data-label", th_att[i]);
                        }
                    }
                });
            }
        });
    });
}

$(document).on("click", ".page-link", function() {
    setTimeout(function(){
        addDataAttrInTableGrid();
    },20);
});
 
 
// let token = document.head.querySelector('meta[name="csrf-token"]')

// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
// } else {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
// }

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import 'vue-awesome-notifications/dist/styles/style.scss';

//css

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue()

import VeeValidate from 'vee-validate';
import Datatable from 'vue2-datatable-component'
import VueAWN from 'vue-awesome-notifications'
import vSelect from 'vue-select'
import datePicker from 'vue-bootstrap-datetimepicker'
import VueSweetalert2 from 'vue-sweetalert2'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ability from './config/ability'
import { abilitiesPlugin } from '@casl/vue'
import VueCkeditor from 'vue-ckeditor2'
// import vBModal from 'bootstrap-vue/esm/directives/modal/modal';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import Modal from 'bootstrap-vue/esm/components';
// import bButton from 'bootstrap-vue/esm/components/button/button.js';
// import bFormInput from 'bootstrap-vue/esm/components/form-input/form-input';
// import $ from 'jquery'

// import 'vuetify/dist/vuetify.min.css'
import '@/assets/css/custom.css'
import '@/assets/css/all.css'
import '@/assets/icons/css/ionicons.min.css'

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import '@mdi/font/css/materialdesignicons.css'

import StarRating from 'vue-star-rating'

Vue.use(VueGoodTablePlugin)
// Vue.use(axiosLms);
// console.log("bbutoon",bButton);
// console.log("b-modal",vBModal);
// Vue.component('b-btn', bButton);
// Vue.directive('b-modal', vBModal);
// Vue.use(Modal);
// Vue.component('b-form-input', bFormInput);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Datatable)
Vue.use(VueAWN, { position: 'top-right' })
// import 'vue-awesome-notifications/dist/styles/style.scss';
Vue.use(datePicker)
// Vue.use($)
Vue.use(VeeValidate)
Vue.use(VueSweetalert2)
Vue.use(abilitiesPlugin, ability)
Vue.use(VueCkeditor)
Vue.component('back-buttton', () => import('./components/BackButton.vue'))
Vue.component('bootstrap-alert', () => import('./components/Alert.vue'))
Vue.component('event-hub', () => import('./components/EventHub.vue'))
Vue.component('vue-button-spinner', () => import('./components/VueButtonSpinner.vue'))
Vue.component('v-select', vSelect)
Vue.component('vue-ckeditor', VueCkeditor)
Vue.component('star-rating', StarRating)

// TODO: the next line is added for debugging purposes and should be removed for production build
// window.ability = ability

const app = new Vue({
    data: {
        count: 0,
        trackLiveSession: null,
        relationships: {},
        dpconfigDate: {
            format: window.date_format_moment
        },
        dpconfigYearOnly: {
            format: 'YYYY',
            viewMode: 'years',
            useCurrent: false
        },        
        dpconfigDateCurrentFalse: {
            format: window.date_format_moment,
            useCurrent: false
        },
        dpconfigTime: {
            format: window.time_format_moment
            //minTime:moment().add(offset, 'm').format("HH:mm")
        },
        dpconfigDatetime: {
            format: window.datetime_format_moment,
            sideBySide: false,
        },
        dpconfigMonthYear: {
            format: 'MM/YYYY'
        },
        dpconfigDateCurrent: {
            format: window.date_format_moment,
            minDate: new Date(Date.now()-1),
            useCurrent:false
        },
        dpconfigOnlyDateCurrent: {
            format: window.date_format_moment,
            // maxDate : new Date(Date.now()),
            // minDate : new Date(Date.now()-1),
            //useCurrent:true
        },
        dpconfigDateCurrentPlusOne: {
            format: window.date_format_moment,
            minDate: moment(new Date()).add(1, 'days'),
            useCurrent:false
        },
        dpconfigDateFuture: {
            format: window.date_format_moment,
            minDate : dt,
            maxDate: false,
            useCurrent: false,
        },
        dpconfigDateFutureTemp: {
            format: window.date_format_moment,
            minDate : false,
            maxDate: false,
            useCurrent: false,
        },        
        dpconfigDatePast: {
            format: window.date_format_moment,
            maxDate : new Date(Date.now())
        },
    },
    mounted() {
        this.updateRules()
        //this.trackingSession()
        
        /* Register service workers in navigator object. */
        // if ('serviceWorker' in navigator) {
        //   window.addEventListener('load', () =>
        //     navigator.serviceWorker.register(window.origin+'/assets/script/sw.js')
        //       .then(registration => console.log('Service Worker registered'))
        //       .catch(err => 'SW registration failed'));
        // }        
    },
    watch: {
        '$route': function() {
            this.updateRules()
            this.$eventHub.$emit('rules-update')
            //this.trackingSession()
        }
    },
    created(){
        
    },
    methods: {
        timerIncrement() {
            if (!document.hidden)
            {
                var idleTime = 0,
                expireTime = 3601;//SECONDS

                var interval = setInterval( increment, 1000);
                function increment(){
                    idleTime = idleTime % 360 + 1;
                    var secondRemain = expireTime - idleTime;
                    if(idleTime >= expireTime) {
                        window.location.href = location.protocol + "//" + location.host + "/sessionExpired"
                    }
                    $(this).keypress(function () {
                        idleTime = 0;
                    });
                    $(this).click(function () {
                        idleTime = 0;
                    });
                }  
            }
        },
        getTimeFormat(time) {
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if (AMPM == "PM" && hours < 12) hours = hours + 12;
            if (AMPM == "AM" && hours == 12) hours = hours - 12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            return (sHours+':'+sMinutes+':00');
        },
        async updateRules() {
            this.timerIncrement()
            var publicPages = ['login', 'forgot-password', 'reset-password', 'confirm-admission-form', 'application-done', 'admissionformpdf', 'report-card-form', 'reportcardpdf','certificate-form', 'certificatepdf'];
            if(!publicPages.includes(this.$route.name)){
                //store.dispatch('Rules/fetchData')
                //store.dispatch('Rules/getBranchesData')
                await store.dispatch('Rules/parentLoginFlag')
                .then((res) => {
                    this.$eventHub.$emit('rules-update') //Required for rules get from AuthIndex...
                })
            }
        },
        trackingSession() {
            if(this.count > 0) {
                store.dispatch('Session/fetchData')
                    .then((response) => {
                        if(response && response.data && response.data.data[0] && response.data.data[0] == 'Undefined index encountered') {
                            // router.go()
                            console.log('got undefined in Store Session/fetchData')
                        }
                        this.trackLiveSession = response.data.data.branch_id + "-" + response.data.data.franchise_id;
                    })
                    .catch(function (error) {
                        console.log('error in trackingSession',error)
                        console.log(error == "TypeError: Cannot read property 'branch_id' of undefined")
                        if (error.response) {
                        window.location.href = location.protocol + "//" + location.host + "/sessionExpired";
                        }
                    })
            }
            this.count++;
        },
        handleValidationFocus(errors,refs) {
        const firstField = Object.keys(errors.collect())[0];
        refs[`${firstField}`].scrollIntoView();
        refs[`${firstField}`].focus();
        },
        updateErrorMessage(errFieldName,that,message = '',validValue = '') {
        if(errFieldName)
            { //console.log(that);
                if(that.first(errFieldName))
                {
                    var returnMessage = ''
                    returnMessage =  that.first(errFieldName).replace('The ','');
                    returnMessage =  returnMessage.replace('field ','');
                    if(validValue !== '')
                    {
                        returnMessage =  returnMessage.replace('0.01 or more',validValue);

                    }
                    if(message !== '')
                    {
                        returnMessage =  returnMessage.replace(errFieldName,message);
                    }
                    else{
                        returnMessage = returnMessage.replace(/_/g,' ');
                    }
                    let msgValidate = returnMessage.charAt(0).toUpperCase() + returnMessage.slice(1);
                    let detailsVal = msgValidate;
                    if(msgValidate.lastIndexOf('.') < 0){
                    detailsVal = msgValidate+'.'
                    }
                    return detailsVal
                }
            }
            else
            {
                return "";
            }
        },
        stringBtoA(value){
            return window.btoa(value);
        },
        stringAtoB(value){
            return window.atob(value);
        },
        resetSearchFilters(vueDelete,query, arrFieldName, classOrId='')
        {
            var queryFilter = JSON.stringify(query);
            var queryFilter = JSON.parse(queryFilter);
            arrFieldName.forEach(function(element) {
                if(typeof queryFilter[element] === 'string')
                {
                    vueDelete(query, element);
                    if(classOrId){
                        let id = '#search_'+element+classOrId
                        $(document).ready(function(){
                            $(id).val('');
                        })
                    }else{
                        $('#search_'+element).val('');
                    }
                    
                }
            });
        },
        getDayList(days = '') {
            if(days !== '')
            {
                var day = [];
                //var result = [];
                if(days.length > 1)
                {
                    var result = days.split(",");
                }
                else
                {
                    var result = [days.toString()];
                }

                result.forEach(function(value){
                    switch (value) {
                        case '1': 
                            day.push('Monday');
                            return day;
                            break;
                        case '2': 
                            day.push('Tuesday');
                            return day;
                            break;
                        case '3': 
                            day.push('Wednesday');
                            return day;
                            break;
                        case '4': 
                            day.push('Thursday');
                            return day;
                            break;
                        case '5': 
                            day.push('Friday');
                            return day;
                            break;
                        case '6': 
                            day.push('Saturday');
                            return day;
                            break;
                        case '7': 
                            day.push('Sunday');
                            return day;
                            break;
                    }
                })
                return day.join();
            }
        
        },
    },
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
