<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Manage GST</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/gst'">Manage GST</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add GST</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add GST</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                    <div class="col-md-12">
                                        <bootstrap-alert />
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <div class="row">
                                            <div class=" col-md-6 col-xs-6" v-if="item.old_start_date!=null && item.old_start_date!=''">
                                                <div ref="start_date" class="form-group">
                                                    <label for="start_date">Old Start Date<p class="astrisk">*</p></label>
                                                    <date-picker
                                                            disabled
                                                            v-model="item.old_start_date"
                                                            :config="$root.dpconfigDate"
                                                            name="old_start_date"
                                                            placeholder="Old Start Date"
                                                            autocomplete="off"
                                                            >
                                                    </date-picker>
                                                </div>
                                            </div>
                                            <div class=" col-md-6 col-xs-6">
                                                <div ref="start_date" class="form-group">
                                                    <label for="start_date">New Start Date<p class="astrisk">*</p></label>
                                                    <div v-if="item.old_start_date!=null && item.old_start_date!=''">
                                                        <date-picker
                                                            v-model="item.start_date"
                                                            :config="{ format: 'DD/MM/YYYY', minDate:dateMin,maxDate: false,useCurrent: false }"
                                                            name="start_date"
                                                            v-validate="'required'"
                                                            placeholder="Select New Start Date"
                                                            autocomplete="off"
                                                            >
                                                        </date-picker>
                                                        <span class="error" v-show="errors.has('start_date')">
                                                            {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                                        </span>
                                                    </div>
                                                    <div v-else>
                                                        <date-picker
                                                            v-model="item.start_date"
                                                            :config="$root.dpconfigDate"
                                                            name="start_date"
                                                            v-validate="'required'"
                                                            placeholder="Select New Start Date"
                                                            autocomplete="off"
                                                            >
                                                        </date-picker>
                                                        <span class="error" v-show="errors.has('start_date')">
                                                            {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <div class="row">
                                            <div class="clearfix"></div>
                                            <div class="col-md-12 col-md-offset-1">
                                                <div class="form-group">
                                                        <section v-if="feesTypeAll">
                                                            <table class="table table-bordered table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width: 150px;">Fee type</th>
                                                                            <th style="width: 280px;">New GST Rate(%)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-for="(feesType, i) in feesTypeAll" :key="i">
                                                                        <tr>
                                                                            <td>{{feesType.name}}</td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    name="percentage_from"
                                                                                    id="percentage_from"
                                                                                    maxlength="3"
                                                                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                                                    placeholder="Enter new GST Rate"
                                                                                    v-validate="'max_value:100'"
                                                                                    :value="(newFees[feesType.id])?newFees[feesType.id]:''"
                                                                                    @input="updateNewFeeAmount($event, feesType.id)"  
                                                                                    >
                                                                                    <span class="error" v-if="rateCheck.length > 0 && rateCheck[feesType.id] == false">
                                                                                        GST Rate should equal to 100 or less than that.
                                                                                    </span>                     
                                                                            </td>         
                                                                        </tr>
                                                                    </tbody>
                                                            </table><br>                               
                                                        </section>
                                                    <span class="error" v-show="checkValue">
                                                    Enter At least one new GST Rate.
                                                    </span><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-2 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                               
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {        
        return {
            newFees: [],
            rateCheck:[],
            minValue:0,
            dateMin:null,
            form_data:{
               fee_type:[],
               project_id:[],
               branch_id:[],
           },
           checkValue:false 
        }
    },
    computed: {
        ...mapGetters('GSTSingle', ['item', 'loading', 'feesTypeAll', 'nextFeeStartDate']),
    },
    async created() {
        this.fetchFeesTypeAll() 
        await this.fetchActiveData()
        var dateFormat = "DD-MM-YYYY";
        var MinDate = this.item.old_start_date;
        this.dateMin = moment(MinDate, dateFormat);
        this.$validator.errors.remove('start_date');
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('GSTSingle', ['storeData', 'resetState', 'fetchFeesTypeAll', 'updateBunchData', 'fetchActiveData']),
        updateNewFeeAmount(e, index) {
            this.rateCheck[index] = true;
            if(e.target.value > 100){
                this.rateCheck[index] = false
            }
            this.$set(this.newFees, parseInt(index),e.target.value)
            var myvalue = 0;
            /*this is use for amount total*/
            this.newFees.forEach(function(fee,index){
                 myvalue = parseInt(myvalue) + parseInt((fee)?fee:0);
            }.bind(this))
            console.log(myvalue);
            this.minValue = myvalue;
        }, 
        submitForm() {
            var myvalue = 0
            this.newFees.forEach(function(fee,index){
                 myvalue = parseInt(myvalue) + parseInt((fee)?fee:0);
            }.bind(this))
             this.checkValue = false
            if(myvalue == 0){
                this.checkValue = true
                return false
            }
            if(this.item.start_date == this.item.old_start_date){
                this.$validator.errors.add({
                    id: 'start_date',
                    field: 'start_date',
                    msg: 'New Start Date must be greater than Old Start Date',
                    scope: this.$options.scope,
                });
                return false;
            }
            this.$validator.validate().then(result => {      
                if(result){
                    var finalData = [],
                    newFeesLocal = this.newFees;
                    for (var j = 0; j < this.feesTypeAll.length; j++) {
                        var localIndex = this.feesTypeAll[j].id;
                        if(newFeesLocal[localIndex] === undefined) {
                        } else {
                            var formatedFromDate = this.item.start_date.split('/');
                            var finalDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]                            
                            var obj = {
                                'id': this.item.id,
                                'fees_type': this.feesTypeAll[j].id,
                                'gst_rate': newFeesLocal[localIndex],
                                'from_date': finalDate
                            }                            
                            finalData.push(obj);
                        }
                    }
                    this.updateBunchData(finalData)
                        .then((res) => {                  
                            if(!res.data.result){
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.buttonLoading = false
                                return '';
                            }
                            this.$router.push({ name: 'gst.index' })
                            this.$eventHub.$emit('create-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        }) 
                }else{
                    //return this.$root.handleValidationFocus(this.errors);
                }              
            });            
        }        
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>