<template>
    <div class="project-drag-view">
        <draggable
            v-bind="dragOptions"
            tag="ul"
            class="dragArean todo-list"
            :list="list"
            :value="value"
            @input="emitter"
            :move="onMove"
        >
            <li :class="'parent ' + el.id" :key="el.id" v-for="el in realValue">
                <div v-if="el.parent_topic_id ==0" class="handle-more-outer">
                    <div class="handle-more-wrapper">
                    <span class="handle handlemore ">
                    <i class="fas fa-ellipsis-v"></i>
                    <i class="fas fa-ellipsis-v"></i>
                    </span>
                    <span class="text"><p>{{ el.topic_name }}</p></span>
                    </div>
                    
                    <div class="tools float-right" style="top:0px;">
                        <div class="btn-group btn-group-sm">
                            <!-- Add subtopics under each topics -->
                            <b-btn v-b-modal.modalPreventSubTopic v-if="$can('project_topic_create')" @click="openSubTopics(el.id)" class="btn btn-success btn-sm mr-2">+ Add SubTopic</b-btn>  
                            
                            <button v-b-modal.modalPreventAddQuiz v-if="$can('quiz_create') && el.have_quiz == 1" @click="setQuizTopic(el.id)"  class="btn btn-secondary btn-sm mr-2" title="Add Quiz">+ Add Quiz</button>
                            <button v-b-modal.modalPreventTaskTutor v-if="$can('tutor_create') && el.is_task_for_tutor == 1" @click="setQuizTopic(el.id)"  class="btn btn-secondary btn-sm mr-2" title="Add Task For Tutor">+ Add Task For Tutor</button>
                            <!-- This below v-b-modal.modalPreventEdit call at EditTopics page modal -->
                            <button v-b-modal.modalPreventEdit v-if="$can('project_topic_edit')" @click="getDetails(el.id)" class="btn btn-primary edit_delete_btn" title="Edit"><i class="fa fa-fw fa-pencil"></i></button>
                            <button v-if="$can('project_topic_delete')"
                                    @click="destroyDataaaa(el.id)"
                                    type="button"
                                    title="Delete"
                                    class="btn btn-danger edit_delete_btn">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>  
                        </div>                  
                    </div>
                </div>
                <draggable v-bind="dragOptions" tag="ul" class="dragArean todo-list item-sub" :list="el.project_sub_topics" @end="emitterChild" :move="onMove">
                    <li class="parent " :key="ele.id" v-for="ele in el.project_sub_topics">
                        <div class="handle-more-outer">
                            <div class="handle-more-wrapper">
                                <span class="handle handlemore">
                                <i class="fas fa-ellipsis-v"></i>
                                <i class="fas fa-ellipsis-v"></i>
                                </span>
                                <span class="text"><p>{{ ele.topic_name }}</p></span>
                            </div>
                            <div class="tools">
                                <div class="btn-group btn-group-sm">
                                    <!-- This below v-b-modal.modalPreventsubEdit call at EditTopics page modal -->
                                    <button v-b-modal.modalPreventAddQuiz v-if="$can('quiz_create') && ele.have_quiz == 1" @click="setQuizTopic(ele.id)" class="btn btn-secondary btn-sm mr-2" title="Add Quiz">+ Add Quiz</button>
                                    <button v-b-modal.modalPreventTaskTutor v-if="$can('tutor_create') && ele.is_task_for_tutor == 1" @click="setQuizTopic(ele.id)"  class="btn btn-secondary btn-sm mr-2" title="Add Task For Tutor">+ Add Task For Tutor</button>
                                    <button v-b-modal.modalPreventsubEdit v-if="$can('project_topic_edit')" @hidden="clearClientData" @click="getSubDetails(el.id,ele.id)" class="btn btn-primary edit_delete_btn" title="Edit"><i class="fa fa-fw fa-pencil-alt"></i></button>
                                    <button v-if="$can('project_topic_delete')"
                                            @click="destroySubDataaaa(ele.id)"
                                            type="button"
                                            title="Delete"
                                            class="btn btn-danger edit_delete_btn">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>  
                                </div>   
                            </div>
                        </div>
                    </li>
                </draggable>
            </li>
        </draggable>
        <EditTopics></EditTopics>
        <AddQuiz :topic_id="t_id" :project_id="project_id" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></AddQuiz>  
        <AddTutor :project_id="project_id" :topic_id="t_id" @loadQuestionList="loadQuestionList" @closeModels="closeModels"></AddTutor>
        <EditSubTopics :project_id="project_id" :details="subTopicdata" :subt_id="subt_id"></EditSubTopics>
        <AddSubTopics :project_id="project_id"></AddSubTopics>
    </div>

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from "vuedraggable";
import EditTopics from '../dtmodules/PopupActions/EditTopics'
import EditSubTopics from '../dtmodules/PopupActions/EditSubTopics'
import AddSubTopics from '../dtmodules/PopupActions/AddSubTopics'
import AddQuiz from '../cruds/Quiz/Create.vue';
import AddTutor from '../cruds/Tutor/Create.vue';

export default {
    props: ['project_id', 'value', 'list'],
    data () {
    return {
        subTopicdata:[],
        subt_id : '',
        parentMove:1,
        t_id :'',
        }
    },
    computed: {
        ...mapGetters('ProjectTopicsSingle', ['item', 'TopicListing','loading']),
        dragOptions() {
            return {
                animation: 0,
                group: "people",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        realValue() {
            return this.value ? this.value : this.list;
        },
    },
    watch: {
        "$route.params.id": function() { 
            this.resetState()
            this.fetchProjectAll();  
            this.fetchData(this.$route.params.id)
        },
        
    }, 
    created() {
        //this.getListing(this.project_id);
    },
    updated(){
        $('.dragArean').css('min-height','0px');
    },
    methods:{
        ...mapActions('ProjectTopicsSingle', ['fetchTopicData', 'getListing', 'destroyData', ]),
        ...mapActions('ProjectSubTopicsSingle', ['TopicData', 'destroySubData', 'SubTopicData']),
        loadQuestionList(){

        },
        setQuizTopic(id){
            this.t_id = id
        },
        closeModels(){
            this.t_id = '';
        },
        dropEvent(){
            $('.dragArean').css('min-height','0px');
        },
        onMove(evt){
           $(evt.related).find('.dragArean').css('min-height','70px').css('border','1px dotted black');
           setTimeout(function(){
                $('.dragArean').css('min-height','0px').css('border','unset');;
           },300); 
        },        
        emitterChild(value) {
            if($(value.to).attr('class') == 'dragArean todo-list item-sub'){
                this.$emit("input", this.value);
            }            
        },
        emitter(value) {
            this.$emit("input", value);
        },
        clearClientData() {
            // Clear teh info by setting it to an empty object
            this.subTopicdata = []
        },
        getDetails(topic_id){
            this.fetchTopicData(topic_id)
            .then(()=>{
                if(this.item.have_quiz == 1) {
                    document.getElementById("have_quiz").checked = true;
                }
                if(this.item.is_task_for_tutor == 1) {
                    document.getElementById("is_task_for_tutor").checked = true;
                }
                if(this.item.is_access_in_mobile == 1) {
                    document.getElementById("is_access_in_mobile").checked = true;
                }               
            }) 
        },
        openSubTopics(topicId){
            this.TopicData(topicId);
        },
        getSubDetails(topic_id, subtopic_id){
            //const ths = this;
            this.SubTopicData(subtopic_id)
            .then((res)=>{
                this.subTopicdata = res;
                this.subt_id = subtopic_id;
                
            }) 
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this Topic and its Quiz Details!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.getListing(this.project_id); 
                        }else{
                            this.$eventHub.$emit('common',3,response.data.message)
                        }                     
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        destroySubDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this SubTopic and its Quiz Details!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroySubData(id)
                    .then(response => {
                        if(response.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.getListing(this.project_id); 
                        }else{
                            this.$eventHub.$emit('common',3,response.data.message)
                        }                     
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        
    },
    components: {
        EditTopics,
        AddSubTopics,
        EditSubTopics,
        draggable,
        AddQuiz,
        AddTutor
    },
    name: "nested-draggable"
};
</script>
<style scoped>
/* .dragArean {
    min-height: 50px; 
}  */

/* .parent.sortable-chosen.ghost .dragArean{
    min-height:100px;
    background-color: red;
} */

</style>