<template>
    <div class="btn-group btn-group-xs">
        <router-link
            v-if="$can(xprops.permission_prefix + 'view')"
            :to="{ name: xprops.route + '.show', params: { id: row.id } }"
            class="btn btn-success edit_delete_btn"
            title="View"
        >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
            title="Edit"
            v-if="$can(xprops.permission_prefix + 'edit')"
            :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
            class="btn btn-block btn-primary edit_delete_btn"
        >
            <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
            v-if="$can(xprops.permission_prefix + 'delete')"
            @click="destroyDataaaa(row.id)"
            type="button"
            title="Delete"
            class="btn btn-block btn-danger edit_delete_btn"
        >
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module
        }
    },
    created() {

    },
    attachStore (namespace) {
        // Object.assign(this.methods, mapActions(namespace, ['destroyData']))
    },
    methods: {
        ...mapActions('CertificateSingle', ['destroyData']),
        
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(() => {
                        this.$eventHub.$emit('delete-success')
                        this.$emit('loadItems')
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        cancelData(id) {
            this.$swal({
                title: 'Are you sure want to cancel?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/cancelData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }
    }
}
</script>