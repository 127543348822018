function initialState() {
    return {
        studentAll: [],
        courseAll: [],
        listingAll: [],
        overAllOutcome: [],
        relationships: {},
        item: {
            course: null,
            student: null,
            gender: 1,
        },
        performanceStatusAll: [],        
        topicCount: 0,        
        loading: false,
        finalGrade: false,
        finalGradeColor: false,
        loadingMAC: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,    
    finalGrade: state => state.finalGrade,
    topicCount: state => state.topicCount,
    finalGradeColor: state => state.finalGradeColor,
    loading: state => state.loading,
    performanceStatusAll: state => state.performanceStatusAll,
    loadingMAC: state => state.loadingMAC,
    courseAll: state => state.courseAll,
    listingAll: state => state.listingAll,
    studentAll: state => state.studentAll,
    overAllOutcome: state => state.overAllOutcome,
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        await axios.get('/api/v1/students_list')
            .then(response => {
                commit('setAll',  response.data.data)
            })         
            .catch(error => {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: false, color: 'danger' },
                    { root: true })

            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    markCourseAsCompleted({ commit, state }) {
        commit('setLoadingMAC', true)
            let params = new FormData();

            params.set('finalGrade', state.finalGrade)
            params.set('finalGradeColor', state.finalGradeColor)
            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
                params.set('course_level', state.item.course.course_level)
            }
            if (_.isEmpty(state.item.student)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student.id)
            }        
        axios.post('/api/v1/markAsCompletedProgressReport', params)
            .then(response => {
                // commit('setAll', response.data.data)               
            })
            .catch(error => {
                commit('setLoadingMAC', false)
                message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    }, 
    getProgressReportData({ commit, state, dispatch }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.item.course)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course.id)
                params.set('course_level', state.item.course.course_level)
                params.set('course_type', state.item.course.course_type)
            }
            if (_.isEmpty(state.item.course)) {
                params.set('course_status', '')
            } else {
                params.set('course_status', state.item.course.course_status)
            }
            if (_.isEmpty(state.item.student)) {
                params.set('student_id', '')
            } else {
                params.set('student_id', state.item.student.id)
            }
            if (_.isEmpty(state.item.student)) {
                params.set('start_date', '')
            } else {
                params.set('start_date', state.item.course.start_date)
            }
            params.set('prefer_batch_id', '0')
            params.set('employee_id', '0')
           
            axios.post('/api/v1/theProgressReportData', params)
                .then(response => {
                    commit('setListingAll', response.data.data)
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchStudentCourseAll({ commit },id) {
        await axios.get('/api/v1/studentsCoursesList/'+id)
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
    },
    async fetchTopicCount({ commit },id) {
        await axios.get('/api/v1/topicsCountForSummerCamp/'+id)
            .then(response => {
                commit('setTopicCount', response.data.data)
            })
    },
    async fetchPerformanceStatusAll({ commit }) {
        await axios.get('/api/v1/evaluationDataAll')
            .then(response => {
                commit('setPerformanceStatusAll', response.data.data)
            })
    },    
    async evaluateFinalPercentage({ commit },inputPercentage) {
        await axios.get('/api/v1/evaluateFinalPercentage/'+inputPercentage)
            .then(response => {
                commit('setOverAllOutcome', response.data.data)
            })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setTopicCount({ commit }, value) {
        commit('setTopicCount', value)
    },    
    setFinalGrade({ commit }, value) {
        commit('setFinalGrade', value)
    }, 
    setFinalGradeColor({ commit }, value) {
        commit('setFinalGradeColor', value)
    },        
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {
    setAll(state, items) {
        state.studentAll = items
    },
    setFinalGrade(state, value) {
        state.finalGrade = value
    },    
    setFinalGradeColor(state, value) {
        state.finalGradeColor = value
    }, 
    setTopicCount(state, value) {
        state.topicCount = value
    },        
    setLoading(state, loading) {
        state.loading = loading
    },
    setPerformanceStatusAll(state, value) {
        state.performanceStatusAll = value
    },    
    setLoadingMAC(state, loadingMAC) {
        state.loadingMAC = loadingMAC
    },
    setQuery(state, query) {
        state.query = query
    },    
    setError(state, error) {
        state.error = error
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setListingAll(state, value) {
        state.listingAll = value
    },
    setOverAllOutcome(state, value) {
        state.overAllOutcome = value
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setStudent(state, value) {
        state.item.student = value
    },
    setGender(state, value) {
        state.item.gender = value
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
