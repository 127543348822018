function initialState() {
    return {
        item: {
            id: null,
            item_category_id: null,
            item_id: null,
            purchase_date: null,
            quantity: null,
            rate: 0,
            total_amount: 0,
            file: null,
            remarks: null,
        },
        categoriesAll: [],
        itemsAll: [],
        purchase_details: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    categoriesAll: state => state.categoriesAll,
    itemsAll: state => state.itemsAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.purchase_details) {
                let fieldValue = state.purchase_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item-purchase', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.purchase_details) {
                let fieldValue = state.purchase_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item-purchase/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                    // resolve(response.data.data)


                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/inventory-item-purchase/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
    },
    fetchCategoriesAll({ commit }) {
        axios.get('/api/v1/inventory-categories')
            .then(response => {
                commit('setCategoriesAll', response.data.data)
            })
    },
    fetchItemsAll({ commit }, id) {
        axios.get(`/api/v1/inventory-category-items/${id}`)
            .then(response => {
                commit('setItemsAll', response.data.data)
            })
    },
    setItemCategoryId({ commit }, value) {
        commit('setItemCategoryId', value)
    },
    setItemId({ commit }, value) {
        commit('setItemId', value)
    },
    setFile({ commit }, value) {
        commit('setFile', value)
    },
    setItemsAll({ commit }, value) {
        commit('setItemsAll', value)
    },
    setPurchaseDetails({ commit }, value) {
        commit('setPurchaseDetails', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        item.item_category_id = {
            name:item.item_category_name,
            value_id:item.item_category_id
        };
        item.item_id = {
            name:item.item_name,
            id:item.item_id
        };
        item.quantity_to_check = item.quantity;
        state.item = item
    },
    setItemCategoryId(state, value) {
        state.item.item_category_id = value
    },
    setItemId(state, value) {
        state.item.item_id = value
    },
    setCategoriesAll(state, value) {
        state.categoriesAll = value
    },
    setItemsAll(state, value) {
        state.itemsAll = value
    },
    setFile(state, value) {
        state.item.file = value
    },
    setPurchaseDetails(state, value) {
        state.purchase_details = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
