function initialState() {
    return {
        item: {
            id: null,
            name: null,
            other_value: null,
            type_name_id: {'id':4,'type_name':'Standard'},
        },
        typeAll: [], 
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    typeAll: state => state.typeAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }   
    params.set('type_id',state.item.type_name_id['id']);
    params.delete('type_name_id');
                
            axios.post('/api/v1/common-masters', params)
                .then(response => {
                    /*commit('resetState')*/
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            //  if (_.isEmpty(state.item.type_id)) {
            //     params.delete('type_id')
            // } else {
            //     for (let index in state.item.type_id) {
            //         params.set('type_id['+index+']', state.item.type_id[index].id)
            //     }
            // }
            params.set('type_id',state.item.type_name_id['id']);
            params.delete('type_name_id');

            axios.post('/api/v1/common-masters/' + state.item.id, params)
                .then(response => {
                    /*commit('setItem', response.data.data)*/
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axios.get('/api/v1/common-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    })           
            dispatch('fetchTypeAll');
    },    
    fetchTypeAll({ commit }) {
        axios.get('/api/v1/typeList')
            .then(response => {                
                commit('settypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })        
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setTypeId({ commit }, value) {
        commit('setTypeId', value)
    },
    setOtherValue({ commit }, value) {
        commit('setOtherValue', value)
    },
    setAdmissionFeeRequired({ commit }, value) {
        commit('setAdmissionFeeRequired', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setName(state, value) {
        state.item.name = value
    },
    setTypeId(state, value) {
        state.item.type_name_id = value
    },

    settypeAll(state, value) {
        state.typeAll = value
    },
    setOtherValue(state, value) {
        state.item.other_value = value
    },
    setAdmissionFeeRequired(state, value) {
        state.item.other_value = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
