function initialState() {
    return {
        item: null,
        loading: false
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading
}

const actions = {
    getCertificate({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/studentCertificate/'+id)
            .then(response => {
                if(response.data.result){
                    commit('setItem', response.data.data)
                }
                else{
                    commit('setItem', null)
                }
                
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            })     
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, value) {
        state.item = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
