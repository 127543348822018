<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Groups</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Group</h3>
                        </div>
                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row" v-if="showData &&  showData.length > 0">
                                <div class="col-xs-12 col-sm-6">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                             <th><span style="font-style: italic;">Group Name:</span> {{computedGroupName}}</th>
                                         </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                       
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="clearfix"></div>                               
                                <div class="col-xs-12 col-sm-6">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                  <th>Student Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                              
                                                <td>
                                                    <span v-if="!checkIfStudentAvailable">
                                                        No Student Added
                                                    </span>
                                                    <span v-for="(val,i) in showData" v-if="val.member_type == 2">
                                                        *&nbsp;{{ val.member_name }}<br>
                                                    </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div>
                                        <table class="table table-bordered table-striped staff-table">
                                            <thead>
                                                <tr>
                                                    <th>Staff Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                
                                                <td>
                                                    <span v-if="!checkIfStaffAvailable">
                                                        No Staff Added
                                                    </span>                                           
                                                    <div>     
                                                        <span v-for="(val,i) in showData" v-if="val.member_type == 1">
                                                            *&nbsp;{{ val.member_name }}</br>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('SmsOnDynamicTemplateIndex', ['showData']),
        computedGroupName() {
            var employee_count = 0,
            student_count = 0,
            group_name = null;
            for (var i = 0; i < this.showData.length; i++) {
                group_name = this.showData[i].group_name
                if(this.showData[i].member_type == 1) {
                    employee_count += 1 
                } else if(this.showData[i].member_type == 2) {
                    student_count += 1 
                }
            }
            return group_name+ " (" + employee_count + " Staff & " + student_count + " Student)" 
        },
        checkIfStaffAvailable() {
            var data = false;
            for (var i = 0; i < this.showData.length; i++) {
                if(this.showData[i].member_type == 1) {
                    data = true;
                }
            }
            return data;
        },
        checkIfStudentAvailable() {
            var data = false;
            for (var i = 0; i < this.showData.length; i++) {
                if(this.showData[i].member_type == 2) {
                    data = true;
                }
            }
            return data;
        },        
    },
    watch: {
        "$route.params.id": function() {
            this.fetchShowData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('SmsOnDynamicTemplateIndex', ['fetchShowData','resetState'])
    }
}
</script>


<style scoped>

</style>
 