function initialState() {
    return {
        item: {
            id: null,
            fees_amount: null,
            is_gst_applicable: null,
            gst_rate: null,
            total_fees: null,
            fees_type: null,
            start_date: null,
        },
        branchCourseAll:[],
        branchCourseFee:[],
        project: [],
        branch: [],            
        coursesAll: [],
        feesAvailable: false,
        nextFeeStartDate:null,
        branchMastersAll: [],
        courseDetailedData: [],
        allCourseLevels: [],
        mainProjectAll: [],
        feesTypeAll: [],
        loadingFeesAvailable: false,
        loading: false,
        setEditCourseFeeData:[]
    }
}
const getters = {
    item: state => state.item,
    project: state => state.project,
    loading: state => state.loading,
    loadingFeesAvailable: state => state.loadingFeesAvailable,
    coursesAll: state => state.coursesAll,
    mainProjectAll: state => state.mainProjectAll,
    feesAvailable: state => state.feesAvailable,
    nextFeeStartDate: state => state.nextFeeStartDate,
    courseDetailedData: state => state.courseDetailedData,
    allCourseLevels: state => state.allCourseLevels,
    branchMastersAll: state => state.branchMastersAll,
    feesTypeAll: state => state.feesTypeAll,
    branch: state => state.branch,
    branchCourseAll:state => state.branchCourseAll,
    branchCourseFee:state => state.branchCourseFee,
    setEditCourseFeeData:state => state.setEditCourseFeeData
}

const actions = {
    storeData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in data) {
                //console.log("sdd",fieldName,typeof data[fieldName]);
                let fieldValue = data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } 
            }

            //create course fee adjustment object            
            if(data['course_fees'].length != 0){
                data['course_fees'].forEach(function(fee, feeIndex){
                    if(fee['discount'] != 0){
                        // console.log("Sds",fee)
                        params.set('course_fee['+feeIndex+'][fee_type]',fee["fee_type"]);
                        params.set('course_fee['+feeIndex+'][discount]',fee["discount"]); 
                        params.set('course_fee['+feeIndex+'][fee_name]',fee["fee_name"]); 
                    }
                });
            }
            else{
                params.set('course_fee',null);
            }

            

            // if (_.isEmpty(state.project)) {
            //     params.set('course_id', '')
            // } else {
            //     params.set('course_id', state.project.id)
            // }
            // if (_.isEmpty(state.branch)) {
            //     params.set('branch_id', '')
            // } else {
            //     params.set('branch_id', state.branch.id)
            // }
            // if (_.isEmpty(state.item.fees_type)) {
            //     params.set('fees_type_id', '')
            // } else {
            //     params.set('fees_type', state.item.fees_type.value_id)
            // }

            axiosLms.post('/api/v1/course-fee-adjustment', params)
                .then(response => {
                    // commit('resetState')
                    // resolve()
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in data) {
                //console.log("sdd",fieldName,typeof data[fieldName]);
                let fieldValue = data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } 
            }

            //create course fee adjustment object            
            if(data['course_fees'].length != 0){
                data['course_fees'].forEach(function(fee, feeIndex){
                    if(fee['discount'] != 0){
                        //console.log("Sds",fee)
                        params.set('course_fee['+feeIndex+'][fee_type]',fee["fee_type"]);
                        params.set('course_fee['+feeIndex+'][discount]',fee["discount"]); 
                        params.set('course_fee['+feeIndex+'][fee_name]',fee["fee_name"]); 
                    }
                });
            }
            else{
                params.set('course_fee',null);
            }

            

            // if (_.isEmpty(state.project)) {
            //     params.set('course_id', '')
            // } else {
            //     params.set('course_id', state.project.id)
            // }
            // if (_.isEmpty(state.branch)) {
            //     params.set('branch_id', '')
            // } else {
            //     params.set('branch_id', state.branch.id)
            // }
            // if (_.isEmpty(state.item.fees_type)) {
            //     params.set('fees_type_id', '')
            // } else {
            //     params.set('fees_type', state.item.fees_type.value_id)
            // }

            axiosLms.post('/api/v1/course-fee-adjustment/update', params)
                .then(response => {
                    // commit('resetState')
                    // resolve()
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateBunchData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData.fee_type){
                params.set('fee_type', JSON.stringify(finalData.fee_type));
            }
            if(finalData.project_id){
                for (let index in finalData.project_id) {
                    params.set('project_id[' + index + ']', finalData.project_id[index]);
                }
            }
            if(finalData.branch_id){
                for (let index in finalData.branch_id) {
                    params.set('branch_id[' + index + ']', finalData.branch_id[index]);
                }
            }
            axiosLms.post('/api/v1/fees-masters/store', params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                          console.log('Error', error.message);
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
    EditBunchData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){
                params.set('fee_type', JSON.stringify(finalData));
            }
            if(state.item.project_details){
                params.set('project_id', state.item.project_details.id);
            }
            if(state.item.branch_details){
                params.set('branch_id', state.item.branch_details.id);                
            }
            if(state.nextFeeStartDate){
                params.set('start_date', state.nextFeeStartDate);
            }
            axiosLms.post('/api/v1/fees-masters/'+state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                          console.log('Error', error.message);
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchEditData({ commit, dispatch }, project_fee_id) {
        var b_id = [];
        await axiosLms.get('/api/v1/fees-masters/edit/' + project_fee_id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setBranch', response.data.data.branch_details)
                commit('setCourseDetailedData', response.data.data.project_fees)
                b_id.push(response.data.data.branch_details.id)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    dispatch('fetchProjects', b_id)
    },
    async fetchShowData({ commit, dispatch }, project_fee_id) {
        await axiosLms.get('/api/v1/fees-masters/edit/'+ project_fee_id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setCourseDetailedData', response.data.data.project_fees)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/fees-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    dispatch('fetchCoursesAll')
    dispatch('fetchBranchMasterAll')
    dispatch('fetchFeesTypeAll')
    
    },
    fetchCoursesAll({ commit }) {
        axiosLms.get('/api/v1/allFeeCoursesList')
            .then(response => {
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    fetchMainCoursesAll({ commit }) {
        axios.get('/api/v1/mainCoursesAllForCourseFees')
            .then(response => {
               // commit('setMainProjectAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },    
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList?isDropdown=1')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchIfFeesAvailable({ commit }, id) {
        commit('setLoadingFeesAvailable', true)        
        await axios.post('/api/v1/fetchIfFeesAvailable/' + id.id)
            .then(response => {
                commit('setFeesAvailable', response.data.data['flag'])
                commit('setNextFeeStartDate', response.data.data['next_start_date'])
                if(response.data.data['next_start_date'] != ''){
                    commit('setStartDate', response.data.data['next_start_date'])    
                }else{
                    commit('setStartDate', moment().format('DD/MM/YYYY'))    
                    commit('setNextFeeStartDate', moment().format('DD/MM/YYYY'))
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoadingFeesAvailable', false)
            })
    },
    fetchFeesTypeAll({ commit }) {
        axios.get('/api/v1/feesTypeList')
            .then(response => {
                commit('setFeesTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchCourseDetailedData({ commit }, id) {
        await axios.get('/api/v1/fetchingCourseDetailedData/' + id.main_course_id +'/'+id.from_date+'/'+id.branch_id)
            .then(response => {
                commit('setCourseDetailedData', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    async fetchCoursesLevel({ commit }, id) {
        await axios.get('/api/v1/fetchCoursesLevel/' + id)
            .then(response => {
                commit('setAllCourseLevels', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
    },
    fetchProjects({ commit }, branch_ids) {
        axiosLms.post('/api/v1/projectCourseList', { 'branch_id': branch_ids })
            .then(response => {
                commit('setMainProjectAll', response.data.data.data)
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    async fetchBranchCourses({ commit }, branch_id) {
        axiosLms.post('/api/v1/branchWiseCourseList', { 'branch_id': branch_id })
            .then(response => {
                if(response.data.result){
                    //console.log("re",response.data.data)
                    commit('setBranchCourseAll', response.data.data)
                }
                else{
                    commit('setBranchCourseAll', [])
                }
                
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    async fetchCourseFees({ commit }, data) {
        axiosLms.post('/api/v1/branchCourseFee', data)
            .then(response => {
                if(response.data.result){
                    //console.log("re",response.data.data)
                    commit('setBranchCourseFee', response.data.data)
                }
                else{
                    commit('setBranchCourseFee', [])
                }
                
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    async getEditData({ commit }, data) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/course-fee-adjustment/'+data.branch_id+'/'+data.course_id)
            .then(response => {
                if(response.data.result){
                    //console.log("re",response.data.data)
                    commit('setEditCourseFeeData', response.data.data)
                }
                else{
                    commit('setEditCourseFeeData', [])
                }
                commit('setLoading', false)
                
            })
            .catch(function (error) {
                commit('setLoading', false)
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    async getShowData({ commit }, data) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/course-fee-adjustment/show/'+data.branch_id+'/'+data.course_id)
            .then(response => {
                if(response.data.result){
                    //console.log("re",response.data.data)
                    commit('setEditCourseFeeData', response.data.data)
                }
                else{
                    commit('setEditCourseFeeData', [])
                }
                commit('setLoading', false)
                
            })
            .catch(function (error) {
                commit('setLoading', false)
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    destroyData({ commit, state }, data) {
        return new Promise((resolve, reject) => {  
            axiosLms.delete('/api/v1/course-fee-adjustment/'+data.branch_id+'/'+data.course_id)
            .then(response => {
                resolve(response);
                })
            .catch(function (error) {
                        console.log('Error', error.message);
                        reject(error)
                }) 
                .finally(() => {
                    //commit('setError', false)
                })
        })
    },
    setProject({ commit }, value) {
        commit('setProject', value)
    },
    setMainProject({ commit }, value) {
        commit('setMainProject', value)
    },    
    setFeesAvailable({ commit }, value) {
        commit('setFeesAvailable', value)
    },
    setNextFeeStartDate({ commit }, value) {
        commit('setNextFeeStartDate', value)
    },
    setFees_amount({ commit }, value) {
        commit('setFees_amount', value)
    },
    setIs_gst_applicable({ commit }, value) {
        commit('setIs_gst_applicable', value)
    },
    setGst_rate({ commit }, value) {
        commit('setGst_rate', value)
    },
    setStartDate({ commit }, value) {
        commit('setStartDate', value)
    },    
    setTotal_fees({ commit }, value) {
        commit('setTotal_fees', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setFees_type({ commit }, value) {
        commit('setFees_type', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setProject(state, value) {
        state.project = value
    },
    setMainProject(state, value) {
        state.project = value
    },
    setStartDate(state, value) {
        state.item.start_date = value
    },    
    setFees_amount(state, value) {
        state.item.fees_amount = value
    },
    setFeesAvailable(state, value) {
        state.feesAvailable = value
    },
    setNextFeeStartDate(state, value) {
        state.nextFeeStartDate = value
    },
    setIs_gst_applicable(state, value) {
        state.item.is_gst_applicable = value
    },
    setGst_rate(state, value) {
        state.item.gst_rate = value
    },
    setTotal_fees(state, value) {
        state.item.total_fees = value
    },
    setBranch(state, value) {
        state.branch = value
    },
    setFees_type(state, value) {
        state.item.fees_type = value
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },
    setMainProjectAll(state, value) {
        state.mainProjectAll = value
    },
    setBranchCourseAll(state,value){
        state.branchCourseAll = value
    },
    setBranchCourseFee(state,value){
        state.branchCourseFee = value
    },
    setEditCourseFeeData(state,value){
        state.setEditCourseFeeData = value
    },
    setCourseDetailedData(state, value) {
        state.courseDetailedData = value
    },
    setAllCourseLevels(state, value) {
        state.allCourseLevels = value
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },
    setFeesTypeAll(state, value) {
        state.feesTypeAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingFeesAvailable(state, loadingFeesAvailable) {
        state.loadingFeesAvailable = loadingFeesAvailable
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
