<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branch Lab</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branch-lab'">Branch Lab</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Branch Lab</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Branch Lab</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                            <tbody>
                                <tr>
                                    <th>Branch Name</th>
                                    <td><span>{{ item.branch_name }}</span></td>
                                </tr>
                                <tr>
                                    <th>Lab Name</th>
                                    <td><span>{{ item.lab_name }}</span></td>
                                </tr>
                                <tr>
                                    <th>Seat Limit</th>
                                    <td><span>{{ item.seat_limit }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                                               
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('BranchLabSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BranchLabSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
</style>
 