<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Items</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Item</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="item_name">Item Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="item_name"
                                                        name="item_name"
                                                        id="item_name"
                                                        maxlength="100"
                                                        v-validate="'required|max:100'"
                                                        placeholder="Enter Item Name"
                                                        v-model="item.name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('item_name')">
                                                          {{ $root.updateErrorMessage('item_name',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="category_id" class="form-group">
                                                <label for="type_">Category<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="category_id"
                                                        label="name"
                                                        id="category_id"
                                                        v-validate="'required'"
                                                        @input="updateCategory"
                                                        :value="item.item_category"
                                                        :options="categoriesAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('category_id')">
                                                    {{ $root.updateErrorMessage('category_id',this.errors, 'Category')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="item_code">Item Code</label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="item_code"
                                                        name="item_code"
                                                        id="item_code"
                                                        v-validate="'max:50'"
                                                        placeholder="Enter Item Code"
                                                        v-model="item.code"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('item_code')">
                                                          {{ $root.updateErrorMessage('item_code',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="item_part_numbre">Item Part Number</label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="item_part_numbre"
                                                        name="item_part_numbre"
                                                        id="item_part_numbre"
                                                        v-validate="'max:100'"
                                                        placeholder="Enter Item Part Number"
                                                        v-model="item.part_number"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('item_part_numbre')">
                                                          {{ $root.updateErrorMessage('item_part_numbre',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <label for="description">Description</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="description"
                                                        name="description"
                                                        id="remarks"
                                                        v-validate="'max:255'"
                                                        placeholder="Enter Description"
                                                        v-model="item.description"
                                                        autocomplete="off"
                                                    ></textarea>
                                                        <span class="error" v-show="errors.has('description')">
                                                          {{ $root.updateErrorMessage('description',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
   
export default {
    data() {

        return {}
    },
    computed: {
        ...mapGetters('InventoryItemSingle', ['item', 'loading', 'categoriesAll'])
    },
    created() {
        this.fetchCategoriesAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('InventoryItemSingle', ['storeData', 'setItemCategory', 'setItemDetails' , 'fetchCategoriesAll','resetState']),
        updateCategory(e) {
            this.setItemCategory(e)            
        },
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var itemDetails = {
                     name: this.item.name,
                     item_category: this.item.item_category.value_id,
                     description: this.item.description,
                     code: this.item.code,
                     part_number: this.item.part_number,
                 };
                this.setItemDetails(itemDetails);
                this.storeData()
                     .then((res) => {
                          if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                  id: 'name',
                                  field: 'name',
                                  msg: res.data.message,
                                  scope: this.$options.scope,
                                });
                                console.log(res.data.errors);
                                (res.data.errors).forEach(function(a){
                                  console.log(a);
                                });
                                return '';    
                            }        
                        this.$router.push({ name: 'inventory_item.index' })
                        this.$eventHub.$emit('create-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        
    }
}
</script>


<style scoped>

</style>
