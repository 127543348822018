<template>
    <div class="popup-form add-country">
    <b-modal id="modalPreventBatch"
            ref="modalPreventBatch"
            title="Add Batch"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"  
            hide-footer
             @hide="modelHide"
            @cancel="handleCancel"
            @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <batchCreate @setPStudentId="setPStudentId" @closeModel="closeModel" @loadItems="loadItems" v-if="cid != ''" :student_id="cid" :isAddBatch="isAddBatch" @closeAddBatchTab="closeAddBatchTab" :batchShow="batchShow" @viewloadbatch="viewloadbatch" />
    </b-modal>
  </div>
</template>

<script>
import batchCreate from './batchCreate.vue'

export default {
    props: ['student_id', 'bstudentId' ,'isAddBatch','batchShow'],
    components: {
        batchCreate,
    },
    data(){
        return {
            cid:''
        }
    },
    watch:{
         'bstudentId':function(value){
            if(this.bstudentId != ''){
                this.cid = this.bstudentId;
                console.log("batch create",this.cid);
                this.$refs.modalPreventBatch.show();
            }
        }
    },
    methods:{
        closeAddBatchTab(){
            this.$emit('closeAddBatchTab');
        },
        viewloadbatch(){
            this.$emit('viewloadbatch');
        },
        loadItems(){
            this.$emit('loadItems');
        },
        handleCancel(evt){
            
        }, 
          setPStudentId(id){
            //console.log("fdgdfg",id)
             this.$emit('setPStudentId',id);
        },
         loadItems(){
             this.$emit('loadItems');
        },
        modelHide(evt){
             console.log("model hide");
             this.$emit('setBStudentId','');
        },
        closeModel(){
             this.$emit('setBStudentId','');
            this.$refs.modalPreventBatch.hide();
        },   
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
            if (result) {
                    this.submitForm()
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        },
        modalOpened() {
            this.cid = this.student_id;
            //console.log("Dfdf",this.cid);
            if(typeof this.bstudentId != "undefined"){
                this.cid = this.bstudentId;
            }
            // console.log("fg",this.cid)
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';
        },
    }  
}
</script>
<style scoped>
.innerTable{
    margin: 10px;
    padding-left: 20px;
}
.innerTable table{
    background-color:#888a8d1c;
}
.headColor{
    background:linear-gradient(#f4f5f8,#f1f3f6);
}
.fieldInput{
    display: inline-block;
    max-width: 90%;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    position: initial;
}
.border-0{
    border: 0px;
}
.table-arrow{
    width: 10px;
    background: #888a8d29;
    padding: 0px 12px 0px 6px;
    border-radius: 3px;
}
.table-arrow i{
    margin: -2px;
}
.allhide {
    display: none;
}
.allShow {
    display: block;
}
td.tabaleDatepicker .bootstrap-datetimepicker-widget{
    z-index: 999999;
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
}
</style>
 
