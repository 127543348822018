<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>SMS To Groups/Staff/Students</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="sentSmsOnDynamicTemplate">
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">SMS To Groups/Staff/Students</h3>
                            </div>
                            <div class="box-body text-center" v-if="loading || localLoader">
                                <h4><i class="fa fa-spin fa-refresh"></i> 
                                    Loading...</h4> 
                            </div>
                            <div v-else class="box-body">
                                <div class="row">                
                                    <div class="col-md-6 col-xs-12">
                                        <div ref="branch" class="form-group">
                                            <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                            <v-select
                                                    name="branch"
                                                    label="branch_name"
                                                    v-validate="'required'"
                                                    @input="updateBranch"
                                                    :value="item.branch_id"
                                                    :options="branchMastersAll"
                                                    />
                                            <span class="error" v-show="errors.has('branch')">
                                                {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                            </span>
                                        </div>
                                    </div>                                                 
                                    <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="gender">
                                                Select List Option
                                            </label>
                                            <br>
                                            <div class="radio-inline">
                                                <label class="contain">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    value="0"
                                                    v-model="gender"
                                                    @change="updateGender(0)"
                                                    >
                                                <span class="checkmark"></span>
                                                Group
                                                </label>
                                            </div>
                                            <div class="radio-inline">
                                                <label class="contain">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    value="1"
                                                    v-model="gender"
                                                    @change="updateGender(1)"
                                                    >
                                                <span class="checkmark"></span>
                                                Staff
                                                </label>
                                            </div>
                                            <div class="radio-inline" style="margin-left:15px;">
                                                <label class="contain">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    value="2"
                                                    v-model="gender"
                                                    @change="updateGender(2)"
                                                    >
                                                <span class="checkmark"></span>
                                                Student
                                                </label>
                                            </div>
                                            <br>
                                            <span class="error" v-show="errors.has('gender')">
                                            {{ $root.updateErrorMessage('gender',this.errors)}}
                                            </span>
                                        </div>
                                    </div>
                                </div>  <br>                              
                                <div class="subject-info-box-1">
                                  <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                </div>
                                <div class="subject-info-arrows text-center">
                                  <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                  <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                  <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                  <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                  <span class="top-margin" style="color: red;" id="movingError"></span>
                                </div>
                                <div class="subject-info-box-2">
                                  <select multiple="multiple" id='lstBox2' class="form-control"></select>
                                </div>
                                <div class="clearfix"></div>                                
                                <div class="row">
                                    <div class="col-md-10 col-md-offset-1 col-xs-12">
                                        <div class="form-group">
                                            <label ref="sms_content" for="sms_content">Sms Content<p class="astrisk">*</p></label>
                                            <textarea
                                                rows="2"
                                                id="sms_content"
                                                v-model="smsContent"
                                                class="form-control"
                                                maxlength="160"
                                                v-validate="'required|max:160'"
                                                name="sms_content"
                                                placeholder="Enter Sms Content"
                                                >
                                            </textarea>  
                                            <span class="error" v-show="errors.has('sms_content')">
                                              {{ $root.updateErrorMessage('sms_content',this.errors, 'Sms content')}}
                                            </span>
                                        </div>                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading" class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading || buttonLoading"
                                    :disabled="loading || buttonLoading"
                                    >
                                    Send SMS
                            </vue-button-spinner>
                        </div>                    
                    </div>
                </form>
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            gender: 0,
            groupName: null,
            localLoader: false,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ]            
        }
    },
    created() {
        var self = this;
        this.fetchBranchMasterAll()
        .then(() => {
            if(self.branchMastersAll.length == 1) {
                self.updateBranch(self.branchMastersAll[0])            
            }
            self.fetchStudentAll(self.branchMastersAll[0].id)
            self.fetchStaffAll(self.branchMastersAll[0].id)
            self.fetchGroupAll(self.branchMastersAll[0].id)
                .then((res) => {
                    if(self.groupAll.length > 0) {
                        for (var i = 0; i < self.groupAll.length; i++) {
                            $('#lstBox1')
                                .append($("<option></option>")
                                    .attr("class","group")
                                    .attr("value",self.groupAll[i].id)
                                    .text(self.groupAll[i].group_name));
                        }
                    }
                    self.localLoader = false;
                })
        });    
    },
    destroyed() {
        this.resetForm()
    },
    computed: {
        ...mapGetters('SmsOnAttendanceIndex', ['all','loading','groupAll','studentAll','staffAll','branchMastersAll','item']),    
    },
    methods: {
        ...mapActions('SmsOnAttendanceIndex', ['fetchGroupAll','setBranchMasterAll','setBranch','fetchBranchMasterAll','fetchStudentAll','fetchStaffAll','sendSmsToSelectedMembersAndGroups','setLoading']),
        updateGroupName(e) {
            this.groupName = e.target.value
        },
        updateBranch(value) {
            $('#lstBox1').empty();
            $('#lstBox2').empty() ;
            this.gender = false;
            if(value && value.id && (this.item.branch_id == null || ((typeof this.item.branch_id === 'object' && value.id != this.item.branch_id.id) || (typeof this.item.branch_id != 'object' && value.id != this.item.branch_id)))) {
                this.fetchStudentAll(value.id);
                this.fetchStaffAll(value.id);
                this.fetchGroupAll(value.id);
            }
            this.setBranch(value);
        },          
        updateGender(value) {
            this.gender == parseInt(value)
            $('#lstBox1').empty()
            var allStaff = $('.staff'),
            allStudent = $('.student'),
            allGroups = $('.group'),
            allSelectedStaffValue = [],
            allSelectedStudentValue = [],
            allSelectedGroupValue = [];
            for (var i = 0; i < allGroups.length; i++) {
                allSelectedGroupValue.push(parseInt(allGroups[i].value))
            }
            for (var i = 0; i < allStaff.length; i++) {
                allSelectedStaffValue.push(parseInt(allStaff[i].value))
            }
            for (var i = 0; i < allStudent.length; i++) {
                allSelectedStudentValue.push(parseInt(allStudent[i].value))
            }
            if(value == 1) {
                for (var i = 0; i < this.staffAll.length; i++) {
                    if(allSelectedStaffValue.indexOf(this.staffAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option></option>")
                                .attr("class","staff")
                                .attr("value",this.staffAll[i].id)
                                .text(this.staffAll[i].employee_name));
                    }
                }                
            } else if(value == 2) {
                for (var i = 0; i < this.studentAll.length; i++) {
                    if(allSelectedStudentValue.indexOf(this.studentAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option></option>")
                                .attr("class","student")
                                .attr("value",this.studentAll[i].id)
                                .text(this.studentAll[i].student_name));
                    }
                }                  
            } else if(value == 0) {
                for (var i = 0; i < this.groupAll.length; i++) {
                    if(allSelectedGroupValue.indexOf(this.groupAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option></option>")
                                .attr("class","group")
                                .attr("value",this.groupAll[i].id)
                                .text(this.groupAll[i].group_name));
                    }
                }                  
            }
        },
        moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')             
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            if(this.gender == 1) {
                var selectedOpts = $('#lstBox2 option.staff:selected');
            } else if(this.gender == 2) {
                var selectedOpts = $('#lstBox2 option.student:selected');
            } else if(this.gender == 0) {
                var selectedOpts = $('#lstBox2 option.group:selected');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')               
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
            if(this.gender == 1) {
                var selectedOpts = $('#lstBox2 option.staff');
            } else if(this.gender == 2) {
                var selectedOpts = $('#lstBox2 option.student');
            } else if(this.gender == 0) {
                var selectedOpts = $('#lstBox2 option.group');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            $(selectedAllOpts).remove();
        },
        updateMemberType(e) {
            if(e.member_type == 1) {
                this.staffSeen = true
                this.fetchStaffAll();
            } else if(e.member_type == 2) {
                this.staffSeen = false
                this.fetchStudentAll();
            }
        },
        resetForm() {
            this.gender = 0
            this.groupName = null
            this.buttonLoading = false
            this.staffSeen = false
            this.memberType = null
            this.studentId = null
            this.staffId = null
            this.smsContent = null
            if($('#lstBox2 option').length > 0) {
                this.moveAllLeft()
            }
            $('#lstBox1').empty()
            for (var i = 0; i < this.groupAll.length; i++) {
                $('#lstBox1')
                    .append($("<option></option>")
                        .attr("class","group")
                        .attr("value",this.groupAll[i].id)
                        .text(this.groupAll[i].group_name));
            }            
        },
        sentSmsOnDynamicTemplate() {
            var allEmployeeMembers = [],
            allStudentMembers = [],
            allGroupMembers = [];
            $('#lstBox2 option.staff').each(function() {
                allEmployeeMembers.push({'name': $(this).text(), 'id': $(this).val()})
            });
            $('#lstBox2 option.student').each(function() {
                allStudentMembers.push({'name': $(this).text(), 'id': $(this).val()})
            });
            $('#lstBox2 option.group').each(function() {
                allGroupMembers.push({'name': $(this).text(), 'id': $(this).val()})
            });
            var params = {
                'smsContent': this.smsContent,
                'allGroupMembers': allGroupMembers,
                'allEmployeeMembers': allEmployeeMembers,
                'allStudentMembers': allStudentMembers
            }
            this.$validator.validate().then(result => {
                if($('#lstBox2 option').length == 0) {
                    this.$eventHub.$emit('add-at-least-one-member')
                }
                if(result && $('#lstBox2 option').length > 0) {
                    this.buttonLoading = true;                    
                    this.sendSmsToSelectedMembersAndGroups(params)
                        .then((res)=>{
                            if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                    id: 'group_name',
                                    field: 'group_name',
                                    msg: res.data.message,
                                    scope: this.$options.scope,
                                });
                                return '';
                            }
                            this.$eventHub.$emit('sms-sent')
                            this.resetForm()
                        })
                        .catch(()=>{
                            this.$eventHub.$emit('sms-not-sent')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                            this.$validator.errors.remove('sms_content');
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>