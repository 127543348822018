function initialState() {
	return {
		item: {},
		pickerDates: {
			startDate: '',
			endDate: ''
		},
		prospect: {},
		pickerProspectDates: {
			startDate: '',
			endDate: ''
		},
		followupList: [],
		pendingDropdownFilters: [],
		pendingCourse: {
			course_vertical: null,
			course_category: null
		},
		activeDropdownFilters: [],
		activeCourse: {
			course_vertical: null,
			course_category: null
		},
		holdDropdownFilters: [],
		holdCourse: {
			course_vertical: null,
			course_category: null
		},
		completedDropdownFilters: [],
		completedCourse: {
			course_vertical: null,
			course_category: null
		},
		pendingCoursesData: [],
		activeCoursesData: [],
		holdCoursesData: [],
		completedCoursesData: [],
		calendarData: [],
		calendarBatchData: [],
		todayAdmissionData: [],
		loading: false,
		prospectLoader: false,
		inquiryFollowupLoader: false,
		pendingCoursesLoader: false,
		activeCoursesLoader: false,
		activeCoursesLoader: false,
		holdCoursesLoader: false,
		completedCoursesLoader: false,
		calendarLoader: false,
		todayAdmissionLoader: false
	}
}

const getters = {
	item: state => state.item,
	pickerDates: state => state.pickerDates,
	prospect: state => state.prospect,
	pickerProspectDates: state => state.pickerProspectDates,
	followupList: state => state.followupList,
	pendingDropdownFilters: state => state.pendingDropdownFilters,
	pendingCourse: state => state.pendingCourse,
	activeDropdownFilters: state => state.activeDropdownFilters,
	activeCourse: state => state.activeCourse,
	holdDropdownFilters: state => state.holdDropdownFilters,
	holdCourse: state => state.holdCourse,
	completedDropdownFilters: state => state.completedDropdownFilters,
	completedCourse: state => state.completedCourse,
	pendingCoursesData: state => state.pendingCoursesData,
	activeCoursesData: state => state.activeCoursesData,
	holdCoursesData: state => state.holdCoursesData,
	completedCoursesData: state => state.completedCoursesData,
	calendarData: state => state.calendarData,
	calendarBatchData: state => state.calendarBatchData,
	todayAdmissionData: state => state.todayAdmissionData,
    loading: state => state.loading,
	prospectLoader: state => state.prospectLoader,
	inquiryFollowupLoader: state => state.inquiryFollowupLoader,
	pendingCoursesLoader: state => state.pendingCoursesLoader,
	activeCoursesLoader: state => state.activeCoursesLoader,
	holdCoursesLoader: state => state.holdCoursesLoader,
	completedCoursesLoader: state => state.completedCoursesLoader,
	calendarLoader: state => state.calendarLoader,
	todayAdmissionLoader: state => state.todayAdmissionLoader
}

const actions = {
	async fetchInquiryAll({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async(resolve, reject) => {
        let params = new FormData()
		
        if (_.isEmpty(state.pickerDates.startDate)) {
            params.set('from_date', '')
        } else {
            params.set('from_date', state.pickerDates.startDate)
        }
		
        if (_.isEmpty(state.pickerDates.endDate)) {
            params.set('to_date', '')
        } else {
            params.set('to_date', state.pickerDates.endDate)
        }
		
        await axios.post('/api/v1/inquiryList', params)
            .then(response => {
                resolve(response)
				commit('setItem', response.data.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
	async fetchProspectAll({ commit, state, dispatch }) {
        commit('setProspectLoading', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async(resolve, reject) => {
        let params = new FormData()
		
        if (_.isEmpty(state.pickerProspectDates.startDate)) {
            params.set('from_date', '')
        } else {
            params.set('from_date', state.pickerProspectDates.startDate)
        }
		
        if (_.isEmpty(state.pickerProspectDates.endDate)) {
            params.set('to_date', '')
        } else {
            params.set('to_date', state.pickerProspectDates.endDate)
        }
		
        await axios.post('/api/v1/studentProspectData', params)
            .then(response => {
                resolve(response)
				commit('setProspect', response.data.data.data)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setProspectLoading', false)
            })
        })
    },
	async fetchTodaysFollowupList({ commit }) {
		commit('setInquiryFollowupLoader', true)
		await axios.get('/api/v1/todaysFollowupList')
		.then(response => {
			if(response.data.result) {
				commit('setFollowupList', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
		.finally(() => {
			commit('setInquiryFollowupLoader', false)
		})
	},
	async fetchPendingDropdownFilters({ commit }) {
		await axios.get('/api/v1/dropdownFilters')
		.then(response => {
			if(response.data.result) {
				commit('setPendingDropdownFilters', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
	},
	async fetchActiveDropdownFilters({ commit }) {
		await axios.get('/api/v1/dropdownFilters')
		.then(response => {
			if(response.data.result) {
				commit('setActiveDropdownFilters', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
	},
	async fetchHoldDropdownFilters({ commit }) {
		await axios.get('/api/v1/dropdownFilters')
		.then(response => {
			if(response.data.result) {
				commit('setHoldDropdownFilters', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
	},
	async fetchCompletedDropdownFilters({ commit }) {
		await axios.get('/api/v1/dropdownFilters')
		.then(response => {
			if(response.data.result) {
				commit('setCompletedDropdownFilters', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
	},
	async fetchPendingCoursesData({ commit, state, dispatch }) {
        commit('setPendingCoursesLoader', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async(resolve, reject) => {
        let params = new FormData()
		
		if (_.isEmpty(state.pendingCourse.course_vertical)) {
            params.set('course_vertical_id', '')
        } else {
            params.set('course_vertical_id', state.pendingCourse.course_vertical.id)
        }
		
        if (_.isEmpty(state.pendingCourse.course_category)) {
            params.set('course_category_id', '')
        } else {
            params.set('course_category_id', state.pendingCourse.course_category.id)
        }
       
        await axios.post('/api/v1/pendingCoursesData', params)
            .then(response => {
                resolve(response)
				if(response.data.result) {
					commit('setPendingCoursesData', response.data.data)
				}
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setPendingCoursesLoader', false)
            })
        })
    },
	async fetchActiveCoursesData({ commit, state, dispatch }) {
        commit('setActiveCoursesLoader', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async(resolve, reject) => {
        let params = new FormData()
		
		if (_.isEmpty(state.activeCourse.course_vertical)) {
            params.set('course_vertical_id', '')
        } else {
            params.set('course_vertical_id', state.activeCourse.course_vertical.id)
        }
		
        if (_.isEmpty(state.activeCourse.course_category)) {
            params.set('course_category_id', '')
        } else {
            params.set('course_category_id', state.activeCourse.course_category.id)
        }
       
        await axios.post('/api/v1/activeCoursesData', params)
            .then(response => {
                resolve(response)
				if(response.data.result) {
					commit('setActiveCoursesData', response.data.data)
				}
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setActiveCoursesLoader', false)
            })
        })
    },
	async fetchHoldCoursesData({ commit, state, dispatch }) {
        commit('setHoldCoursesLoader', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async(resolve, reject) => {
        let params = new FormData()
		
		if (_.isEmpty(state.holdCourse.course_vertical)) {
            params.set('course_vertical_id', '')
        } else {
            params.set('course_vertical_id', state.holdCourse.course_vertical.id)
        }
		
        if (_.isEmpty(state.holdCourse.course_category)) {
            params.set('course_category_id', '')
        } else {
            params.set('course_category_id', state.holdCourse.course_category.id)
        }
		
        await axios.post('/api/v1/batchHoldCouseData', params)
            .then(response => {
                resolve(response)
				if(response.data.result) {
					commit('setHoldCoursesData', response.data.data)
				}
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setHoldCoursesLoader', false)
            })
        })
    },
	async fetchCompletedCoursesData({ commit, state, dispatch }) {
        commit('setCompletedCoursesLoader', true)
        dispatch('Alert/resetState', null, { root: true })
		
        return new Promise(async (resolve, reject) => {
        let params = new FormData()
		
		if (_.isEmpty(state.completedCourse.course_vertical)) {
            params.set('course_vertical_id', '')
        } else {
            params.set('course_vertical_id', state.completedCourse.course_vertical.id)
        }
		
        if (_.isEmpty(state.completedCourse.course_category)) {
            params.set('course_category_id', '')
        } else {
            params.set('course_category_id', state.completedCourse.course_category.id)
        }
       
        await axios.post('/api/v1/completedCourseData', params)
            .then(response => {
                resolve(response)
				if(response.data.result) {
					commit('setCompletedCoursesData', response.data.data)
				}
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setCompletedCoursesLoader', false)
            })
        })
    },
	async fetchCalendarData({ commit },data) {
		commit('setCalendarLoader', true)
		await axiosLms.post('/api/v1/fetchCalendarData',data)
		.then(response => {
			if(response.data.result) {
				commit('setCalendarData', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
		.finally(() => {
			commit('setCalendarLoader', false)
		})
	},
	async fetchScheduleBatchDetail({ commit },data) {
        await axios.post('/api/v1/getCalenderbatchDetails',data)
		.then(response => {
			commit('setScheduleBatchDetails', response.data.data)
		})
		.catch(function (error) {
			let message = error.response.data.message || error.message
			let errors  = error.response.data.errors
			dispatch(
				'Alert/setAlert',
				{ message: message, errors: errors, color: 'danger' },
				{ root: true })
			reject(error)
		})
    },
	async fetchTodayAdmission({ commit }) {
		commit('setTodayAdmissionLoader', true)
		await axios.get('/api/v1/todayAdmission')
		.then(response => {
			if(response.data.result) {
				commit('setTodayAdmissionData', response.data.data)
			}
		})
		.catch(function(error) {
			console.log('Error', error.message)
		})
		.finally(() => {
			commit('setTodayAdmissionLoader', false)
		})
	},
	
	setItem({ commit }, value) {
        commit('setItem', value)
    },
	setPickerDate({ commit }, value) {
        commit('setPickerDate', value)
    },
	setProspect({ commit }, value) {
		commit('setProspect', value)
	},
	setPickerProspectDates({ commit }, value) {
        commit('setPickerProspectDates', value)
    },
	setPendingDropdownFilters({ commit }, value) {
		commit('setPendingDropdownFilters', value)
	},
	setActiveDropdownFilters({ commit }, value) {
		commit('setActiveDropdownFilters', value)
	},
	setHoldDropdownFilters({ commit }, value) {
		commit('setHoldDropdownFilters', value)
	},
	setCompletedDropdownFilters({ commit }, value) {
		commit('setCompletedDropdownFilters', value)
	},
	setCourseCategory({ commit }, value) {
		commit('setCourseCategory', value)
	},
	setPendingCourseVertical({ commit }, value) {
		commit('setPendingCourseVertical', value)
	},
	setPendingCourseCategory({ commit }, value) {
		commit('setPendingCourseCategory', value)
	},
	setActiveCourseVertical({ commit }, value) {
		commit('setActiveCourseVertical', value)
	},
	setActiveCourseCategory({ commit }, value) {
		commit('setActiveCourseCategory', value)
	},
	setHoldCourseVertical({ commit }, value) {
		commit('setHoldCourseVertical', value)
	},
	setHoldCourseCategory({ commit }, value) {
		commit('setHoldCourseCategory', value)
	},
	setCompletedCourseVertical({ commit }, value) {
		commit('setCompletedCourseVertical', value)
	},
	setCompletedCourseCategory({ commit }, value) {
		commit('setCompletedCourseCategory', value)
	},
	setCalendarData({ commit }, value) {
		commit('setCalendarData', value)
	},
	setScheduleBatchDetails({ commit }, value) {
		commit('setScheduleBatchDetails', value)
	},
	setTodayAdmissionData({ commit }, value) {
		commit('setTodayAdmissionData', value)
	},
	setLoading({ commit }, value) {
        commit('setLoading', value)
    },
	setProspectLoading({ commit }, value) {
        commit('setProspectLoading', value)
    },
	setPendingCoursesLoader({ commit }, value) {
		commit('setPendingCoursesLoader', value)
	},
	setActiveCoursesLoader({ commit }, value) {
		commit('setActiveCoursesLoader', value)
	},
	setHoldCoursesLoader({ commit }, value) {
		commit('setHoldCoursesLoader', value)
	},
	setCompletedCoursesLoader({ commit }, value) {
		commit('setCompletedCoursesLoader', value)
	},
	setCalendarLoader({ commit }, value) {
		commit('setCalendarLoader', value)
	},
	setTodayAdmissionLoader({ commit }, value) {
		commit('setTodayAdmissionLoader', value)
	},
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
	setItem(state, item) {
		state.item = item
	},
	setPickerDate(state, value) {
		state.pickerDates = value
	},
	setProspect(state, item) {
		state.prospect = item
	},
	setPickerProspectDates(state, value) {
		state.pickerProspectDates = value
	},
	setInquiryFollowupLoader(state, inquiryFollowupLoader) {
		state.inquiryFollowupLoader = inquiryFollowupLoader
	},
	setFollowupList(state, value) {
		state.followupList = value
	},
	setPendingDropdownFilters(state, value) {
		state.pendingCourse = value
	},
	setActiveDropdownFilters(state, value) {
		state.activeCourse = value
	},
	setHoldDropdownFilters(state, value) {
		state.holdCourse = value
	},
	setCompletedDropdownFilters(state, value) {
		state.completedCourse = value
	},
	setPendingCourseVertical(state, value) {
		state.pendingCourse.course_vertical = value
	},
	setPendingCourseCategory(state, value) {
		state.pendingCourse.course_category = value
	},
	setActiveCourseVertical(state, value) {
		state.activeCourse.course_vertical = value
	},
	setActiveCourseCategory(state, value) {
		state.activeCourse.course_category = value
	},
	setHoldCourseVertical(state, value) {
		state.holdCourse.course_vertical = value
	},
	setHoldCourseCategory(state, value) {
		state.holdCourse.course_category = value
	},
	setCompletedCourseVertical(state, value) {
		state.completedCourse.course_vertical = value
	},
	setCompletedCourseCategory(state, value) {
		state.completedCourse.course_category = value
	},
	setPendingCoursesData(state, value) {
		state.pendingCoursesData = value
	},
	setActiveCoursesData(state, value) {
		state.activeCoursesData = value
	},
	setHoldCoursesData(state, value) {
		state.holdCoursesData = value
	},
	setCompletedCoursesData(state, value) {
		state.completedCoursesData = value
	},
	setCalendarData(state, value) {
		state.calendarData = value
	},
	setScheduleBatchDetails(state, value) {
		state.calendarBatchData = value
	},
	setTodayAdmissionData(state, value) {
		state.todayAdmissionData = value
	},
	setLoading(state, loading) {
		state.loading = loading
	},
	setProspectLoading(state, loading) {
		state.prospectLoader = loading
	},
	setPendingCoursesLoader(state, pendingCoursesLoader) {
		state.pendingCoursesLoader = pendingCoursesLoader
	},
	setActiveCoursesLoader(state, activeCoursesLoader) {
		state.activeCoursesLoader = activeCoursesLoader
	},
	setHoldCoursesLoader(state, holdCoursesLoader) {
		state.holdCoursesLoader = holdCoursesLoader
	},
	setCompletedCoursesLoader(state, completedCoursesLoader) {
		state.completedCoursesLoader = completedCoursesLoader
	},
	setCalendarLoader(state, calendarLoader) {
		state.calendarLoader = calendarLoader
	},
	setTodayAdmissionLoader(state, calendarLoader) {
		state.todayAdmissionLoader = calendarLoader
	},
	resetState(state) {
		state = Object.assign(state, initialState())
	}
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations
}