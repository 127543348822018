<template>
    <div>
        <span class="fa fa-rupee" v-if="row.refund_amount">{{row.refund_amount}}</span>
        <span class="fa fa-rupee" v-if="row.receipt_amount">{{row.receipt_amount}}</span>
        <span class="fa fa-rupee" v-if="row.amount">{{row.amount}}</span>
        <span class="fa fa-rupee" v-if="row.total_fees">{{row.total_fees}}</span>
        <span class="fa fa-rupee" v-if="row.TotalFees">{{row.TotalFees}}</span>
    </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            
        }
    },
    created() {
        // Code...
    },
    methods: {
        // Code...
    }
}
</script>


<style scoped>

</style>

