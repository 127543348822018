<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Courses</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/courses'">Courses</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Course</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline card-outline-tabs">
                     <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="changeComp('course')" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Course</a>
                            </li>
                              <li class="nav-item">
                                <a class="nav-link" style="pointer-events:none;" aria-selected="false">Website</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" style="pointer-events:none;" aria-selected="false">FAQs</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  style="pointer-events:none;"  aria-selected="false">Case Study</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div v-if="tabChange == 'course'">
                                <CreateCourse></CreateCourse>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>

    </div>
</template>



<script>
import { mapGetters, mapActions } from 'vuex'
import CreateCourse from '@/components/cruds/Course/CreateCourse'
import FaqIndex from '@/components/cruds/Course/Faqs/Index.vue'
import CaseStudyIndex from '@/components/cruds/Course/CaseStudy/Index.vue'
export default {
    data() {
        return {
            tabChange:'course',
        }
    },
    components:{
        CreateCourse,
        FaqIndex,
        CaseStudyIndex
    },
    computed: {
        // ...mapGetters('CoursesIndex', ['courseMonthFormula','all','loading','editDataAll','moduleAll','projectAll','courseVertical','courseCategory','courseFeeType']),
        ...mapGetters('AuthIndex', ['user']),
    },
    watch:{
         tabChange(){
            return this.tabChange;
        }
    },
    methods: {
        changeComp(tabActive){
            this.tabChange = tabActive
        }
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}



.contain .checkmark:after {
    left: 6.5px;
    top: 6.5px;
}
.content-wrapper .content {
    padding: 0 0.5rem;
    padding-bottom: 70px;
}
</style>