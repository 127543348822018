<template>
    <div class="popup-form add-country">
    <b-modal id="modalPreventReceipt"
             ref="modalPreventReceipt"
             title="Add Receipt"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             hide-footer
            @hide="modelHide"
            @cancel="handleCancel"
            @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <Create :student_id="cid"  v-if="cid!=''" :isAddReceipt="isAddReceipt" :paymentShow="paymentShow" :isOpen="isOpen" @closeModel="closeModel"></Create>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Create from '@/components/cruds/Receipts/Create'
export default {
    props: ['student_id', 'pstudentId' ,'isAddReceipt', 'paymentShow','isOpen'],  
    components:{
        Create
    },  
    data() {
        return {
            cid:'',
            disable: [],
            inputParams:[],
            paymentInputParams:[],
            instrumentDateParams:[new Date()],
            instrumentNumberParams:[],
            bankNameParams:[],
            addPaymentModeParams:null,
            // paymentModeParams:[{value_id:1,name:'Cash'}],
            paymentModeParams:[],
            gstParams:[],
            amountParams:[],
            studentSeen:false,
            totalGST:{},
            totalAmount:[],
            totalPayment:{},
            sumTotalAmount:0,
            sumTotalPaid:0,
            sumTotalBalance:0,
            sumTotalDue:0,
            paymentModeAmount:0,
            angle:'fa fa-angle-right',
            courseTotalPaid:[],
            // Code...
        }
    },
    computed: {
        ...mapGetters('ReceiptsSingle', ['item', 'loading','studentAll','pendingFeeAll','paymentModeAll','paymentDetails']),
      
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },          
    created() { 
        // console.log("dsdsd");
        // if(this.isOpen == true){
        //     console.log("isOpen",this.isOpen);
        //      this.$refs.modalPreventReceipt.show();
        // }
        // if(this.isAddReceipt){
        //     this.updateStudent();       
        //     this.fetchPaymentModeAll();
        // }        
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        'pstudentId':function(value){
            if(this.pstudentId != ''){
                this.cid = this.pstudentId;
                // console.log("batch create",this.cid);

                this.$refs.modalPreventReceipt.show();
            }
        }
        /* isAddReceipt: function() {
            console.log('sdf');
            if(this.isAddReceipt){
                this.updateStudent();
                this.fetchPaymentModeAll();
            }
        },  */ 
    },
    methods: {
        ...mapActions('ReceiptsSingle', ['fetchData', 'storeData', 'resetState', 'setStudent', 'setReceiptDate', 'setManualReceiptNo', 'setReceiptAmount','fetchStudentAll','fetchPendingFeeAll','setPaymentMode','setPaymentDetails','setPendingFeeAll', 'setNewPendingFeeAll','fetchPaymentModeAll','setTotalDue']),
        ...mapActions('StudentShowSingle', ['fetchStudentCount']),
        modelHide(evt){
            //  console.log("model hide");
             this.$emit('setPStudentId','');
        },
        closeModel(){
            this.$emit('setPStudentId','');
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.fetchStudentCount(this.student_id);
            this.$refs.modalPreventReceipt.hide();
        },
        handleCancel(evt){
            //this.isAddReceipt = false;
            console.log(this.paymentShow);
            this.$emit('setPStudentId','');
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.receipt_date ='';
            this.setReceiptDate('')
            this.updateStudent();
            
            this.addPaymentModeParams = null; 
            this.fetchStudentCount(this.student_id);
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.handleSubmit()                        
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened () {
            //this.isAddReceipt = false;
           this.cid = this.student_id;
        //    console.log("stude",this.cid);
            //console.log("Dfdf",this.cid);
            if(typeof this.pstudentId != "undefined"){
                this.cid = this.pstudentId;
            }
            // console.log("pstude",this.cid);
        }
    }
}
</script>


<style scoped>
.innerTable{
    margin: 10px;
    padding-left: 20px;
}
.innerTable table{
    background-color:#888a8d1c;
}
.headColor{
    background:linear-gradient(#f4f5f8,#f1f3f6);
}
.fieldInput{
    display: inline-block;
    max-width: 90%;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
position: initial;
}
.border-0{
    border: 0px;
}
.table-arrow{
    width: 10px;
    background: #888a8d29;
    padding: 0px 12px 0px 6px;
    border-radius: 3px;
}
.table-arrow i{
  margin: -2px;
}
.allhide {
display: none;
}
.allShow {
display: block;
}
td.tabaleDatepicker .bootstrap-datetimepicker-widget{
    z-index: 999999;
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
</style>
 