<template> 
  <div class="content-wrapper">
      <section class="content-header">
          <div class="container-fluid">
              <div class="row mb-2">
                  <div class="col-sm-6">
                      <h1>Student Details</h1>
                  </div>
                  <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item">
                              <router-link :to="'/home'">Home</router-link>
                          </li>
                          <li class="breadcrumb-item">
                              <router-link :to="'/Student'">Student</router-link>
                          </li>
                          <li class="breadcrumb-item active">View Student Details</li>
                      </ol>
                  </div>
              </div>
          </div>
      </section>
      <section class="content">
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                <div class="edit-btn">
                  <button v-b-modal.editStudent @click="passEditId()" v-if="$can('student_edit')" type="button" variant="primary" class="btn btn-primary float-right" title="Profile Edit"><i class="fa fa-pen"></i></button>
                </div>
                <div class="card card-primary card-outline">
                  <span style="padding:10px">{{item.enroll_date}}</span>
                  <div class="card-body box-profile " >
                    
                    <div class="text-center">
                      <img v-if="item.profile != null"
                          :src="item.profile"
                          class="profile-user-img img-fluid img-circle"
                          alt="User profile picture"
                      >
                      <img v-else
                          src="/assets/img/male.jpg"
                          class="profile-user-img img-fluid img-circle"
                          alt="User profile picture"
                      >
                    </div>
                    <h3 class="profile-username text-center">{{item.first_name}}&nbsp;{{item.middle_name}}&nbsp;{{item.last_name}}</h3>
                    <ul class="list-group list-group-unbordered mb-3">
                      <!-- <li class="list-group-item">
                      <b>Mobile No</b> <div class="float-right"><span v-if="item.mobile_no">{{item.mobile_no}}</span><span v-else>-</span></div>
                      </li> -->
                      <li class="list-group-item">
                      <b>Age</b> <span class="float-right">{{setAge(item.birth_date)}}</span>
                      </li>
                      <li class="list-group-item">
                      <b>School Name</b> <span class="float-right">{{ (item.school) ? item.school.school_name : item.other_school_name }}</span>
                      </li>
                      <li class="list-group-item">
                      <b>Standard</b> <span class="float-right">{{ (item.standard) ? item.standard : '-' }}</span>
                      </li>
                    </ul>
                    <!-- <div class="row">
                      <div class="col-md-6">
                        <p class="text-muted text-left" v-if="item.mobile_no"><i class="fa fa-mobile"  aria-hidden="true"></i>&nbsp;&nbsp;<span>{{item.mobile_no}}</span></p>
                      </div>
                      <div class="col-md-6">
                        <p class="text-muted text-right"><i class="fa fa-birthday-cake" aria-hidden="true"></i>&nbsp;&nbsp;<span>{{item.birth_date}}</span></p>
                      </div>                        
                    </div> -->
                  </div>
                </div>
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Other Details</h3>
                    </div>
                    <div class="card-body">
                    <strong><i class="fas fa-map-marker-alt mr-1"></i> Location</strong>
                      <p class="text-muted text-sm">
                        <span v-if="item.student_address">{{item.student_address}},</span><span v-if="item.area">&nbsp;{{item.area}},</span><span v-if="item.pin_code">&nbsp;{{item.pin_code}},</span><span v-if="item.city_list">&nbsp;{{(item.city_list) ? item.city_list.city_name : ''}},</span><span v-if="item.state_list">&nbsp;{{(item.state_list) ? item.state_list.state_name : ''}},</span><span v-if="item.country_list">&nbsp;{{(item.country_list) ? item.country_list.country_name : ''}}</span>
                      </p> 
                      <div v-if="item.parent">
                          <hr>
                          <strong><i class="fas fa-user mr-1"></i> Parents</strong>
                          <p class="text-muted ">
                            <ul style="list-style-type:none;padding: 0px;" >
                              <li class="text-muted text-sm text-left" v-if="item.parent.father_name"><b>Father Name:</b> <span>{{ item.parent.father_name }}</span></li>
                              <li class="text-muted text-sm text-left" v-if="item.parent.other_mobile_no"><b>Father Mobile:</b> <span>{{item.parent.other_mobile_no}}</span></li>
                              <li class="text-muted text-sm text-left" v-if="item.parent.other_email"><b>Father Email:</b> <span>{{item.parent.other_email}}</span></li>
                              <li class="text-muted text-sm text-left"  v-if="item.parent.mother_name"><b>Mother Name:</b> <span>{{item.parent.mother_name}}</span></li>
                              <li class="text-muted text-sm text-left"  v-if="item.parent.mobile_no"><b>Mother Mobile:</b> <span>{{item.parent.mobile_no}}</span></li>
                              <li class="text-muted text-sm text-left"  v-if="item.parent.email"><b>Mother Email:</b> <span>{{item.parent.email}}</span></li>
                            </ul>   
                          </p> 
                      </div>              
                    </div>
                  </div>              
              </div>
              <div class="col-md-9">
                <div class="card card-primary card-outline card-outline-tabs">
                  <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-four-admissiondetails-tab" data-toggle="pill" href="#custom-tabs-four-admissiondetails" role="tab" aria-controls="custom-tabs-four-admissiondetails" aria-selected="true" @click="loadadmissiondetails('custom-tabs-four-adminssion')">Admission Details</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-parent-tab" v-if="$can('parent_access')" data-toggle="pill" href="#custom-tabs-four-parent" role="tab" aria-controls="custom-tabs-four-parent" @click="loadparent('custom-tabs-four-parent')">Parents</a>
                      </li>
                      <li class="nav-item" >
                        <a class="nav-link" id="custom-tabs-four-profile-tab"  role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Commin soon...</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body other-tabs">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                      <div class="tab-pane fade show active" id="custom-tabs-four-admissiondetails" role="tabpanel" aria-labelledby="custom-tabs-four-admissiondetails-tab" v-if="adtabshow">
                        <AdminssionDetails :adtabshow="adtabshow"></AdminssionDetails>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-four-parent" role="tabpanel" aria-labelledby="custom-tabs-four-parent-tab" v-if="parentShow">
                        <TabIndex :parentShow="parentShow"></TabIndex>
                      </div>
                      <div class="tab-pane fade childBody" v-if="$can('project_topic_access')" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                        <!-- <ProjectTopics></ProjectTopics> -->
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>
              </div>
            </div>
          </div>
      </section>
       <StudentEditPopup :id="student_id"></StudentEditPopup>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import AdminssionDetails from './AdminssionDetails'
import TabIndex from '@/components/cruds/Parents/TabIndex'
import StudentEditPopup from '@/components/cruds/Student/StudentEditPopup'
export default {
  components: {
      AdminssionDetails,
      StudentEditPopup,
      TabIndex,
  },
  data() {
      return {
        student_id:0,
        adtabshow:true,
        parentShow:false,
      }
  },
  watch: {
      "$route.params.id": function() {
          this.student_id = this.$route.params.id;
      },  
      adtabshow:function(){
        if(this.adtabshow){
          console.log('123');
            this.student_id = this.$route.params.id;            
        }
      }  
  },
  computed: {
        ...mapGetters('StudentShowSingle', ['item', 'loading']),
  },
  created() {
      this.student_id = this.$route.params.id;
      this.fetchData(this.$route.params.id);
  },
  methods: {
    ...mapActions('StudentShowSingle', ['fetchData']),
    passEditId(){
        this.$emit('editIdModel',student_id) 
    },
    loadadmissiondetails(tabId){
      this.adtabshow = true;
      this.parentShow = false;   
    },
    loadparent(tabId){
      this.parentShow = true;
      this.adtabshow = false;   
    },
    setAge(value) {
          if (value) {
              var dateArr = value.split("/");
              var date = dateArr[2]+"/"+dateArr[1]+"/"+dateArr[0];
              return this.showAge(date);
          }
      },
    showAge(value) {
          var age = '';
          var user_date = new Date(value);
          var today_date = new Date();
          var diff_date =  Math.abs(user_date.getTime() - today_date.getTime());
          var num_years = diff_date/31536000000;
          var num_months = (diff_date % 31536000000)/2628000000;
          var num_days = ((diff_date % 31536000000) % 2628000000)/86400000;
          if (Math.floor(num_years)) {
              age += Math.floor(num_years)+ " Years";
          }

          if (Math.floor(num_months)) {
              age += ", "+Math.floor(num_months)+ " Months";
          }
          return age;
      }
  }
}
</script>


<style scoped>
.other-tabs  {
padding:0px
}
.delete-button {
  position: absolute;
  margin: 0 auto;
  left: 100%;
  top: 26px;
  bottom: 0;    
}
.Color_Box_Div {
  height: 15px;
  width: 15px;
  margin-top: 3px;
  margin-right: 4px;
  margin-left: 4px;
  float: left;
  border-radius: 3px;
}
#loadingDiv {
  background-color: #000;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10020;
  filter: alpha(opacity=70);
  -moz-opacity: .7;
  -khtml-opacity: .7;
  opacity: .7;
}
.ajax-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  display: block;
  z-index: 10020!important;
}
img {
  vertical-align: middle;
}
img {
  border: 0;
}
.project-card{
  margin: 10px;
}
.profile-user-img{
box-shadow: 10px 10px 30px 10px #80808040;
height:95px;
}
.edit-btn{
  position: absolute;
  top: 17px;
  z-index: 9;
  right: 15px;
}
.p-t-50{
padding-top: 50px;
}
.text-muted i{
color:#202529;
}
</style>