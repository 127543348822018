<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Workshops</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Workshop</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Workshop Date</th>
                                            <td>{{ item.workshop_date }}</td>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>
                                                {{item.first_name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>
                                                {{item.last_name}}
                                            </td>
                                        </tr>   
                                        <tr>
                                            <th>Gender</th>
                                            <td v-if="item.gender == 1">
                                                Male
                                            </td>
                                            <td v-else>
                                                Female
                                            </td>                                            
                                        </tr>                                         
                                        <tr>
                                            <th>Birth Date</th>
                                            <td>
                                                {{item.birth_date}}
                                            </td>
                                        </tr>                                                                           
                                        <tr>
                                            <th>Student Age (Years)</th>
                                            <td>
                                                {{item.age}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Workshop Duration (Hours)</th>
                                            <td>
                                                {{item.duration}}
                                            </td>
                                        </tr>                                            
                                        <tr>
                                            <th>Course Offered</th>
                                            <td v-if="item && item.coursename">
                                                {{item.coursename.display_course_name}}
                                            </td>                               
                                        </tr>                                                                                
                                        <tr>
                                            <th>Contact Name</th>
                                            <td>
                                                {{item.contact_name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Contact Number</th>
                                            <td>
                                                {{item.contact_number}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Workshop Type</th>
                                            <td>
                                                {{item.workshop_type_text}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Workshop Details</th>
                                            <td>
                                                {{item.workshop_details}}
                                            </td>
                                        </tr>
                                        <tr v-if="item.status == 2">
                                            <th>Status</th>
                                            <td>
                                                Converted to inquiry
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>                                
                            </div>
                          <div class="row" v-if="item.workshop_type == 1">
                            <div class="col-md-12">
                              <h4>Payment Details</h4>
                            </div>
                            <div class="col-md-12 table-responsive">
                              <table class="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th>Payment Mode</th>
                                        <th>Manual Receipt Number</th>
                                        <th>Bank Name</th>
                                        <th>Instrument Date</th>
                                        <th>Cheque/Transaction Number</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{{item.receipt.receipt_payment_mode[0].payment_mode.name}}</td>
                                        <td>{{item.receipt.manual_receipt_no}}</td>
                                        <td>{{item.receipt.receipt_payment_mode[0].bank_name}}</td>
                                        <td>{{item.receipt.receipt_payment_mode[0].instrument_date}}</td>
                                        <td>{{item.receipt.receipt_payment_mode[0].instrument_no}}</td>
                                        <td><i class="fa fa-inr"></i> {{item.receipt.receipt_amount}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('WorkshopSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('WorkshopSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
