<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branches</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branchs'">Branches</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Branch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Branch</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="box col-md-12">
                                    <bootstrap-alert />
                                    <div class="box-body row create-flex">
                                        <div class="form-group col-md-6 col-xs-12">
                                            <label for="branch_name">Branch Name<p class="astrisk">*</p></label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="branch_name"
                                                    ref="branch_name"
                                                    v-validate="'required'"
                                                    maxlength="150"
                                                    placeholder="Enter Branch Name"
                                                    :value="item.branch_name"
                                                    @input="updateBranchName"
                                                    >
                                            <span class="error" v-show="errors.has('branch_name')">
                                                {{ $root.updateErrorMessage('branch_name',this.errors,'Branch Name')}}
                                            </span>                                               
                                        </div>
                                        <div ref="franchise" class="form-group col-md-6 col-xs-12">
                                            <label for="franchise">Franchise Name<p class="astrisk">*</p></label>
                                            <v-select
                                                    id="franchise"
                                                    name="franchise"
                                                    label="display_franchise_name"
                                                    v-validate="'required'"
                                                    @change="updateFranchiseId"
                                                    :value="item.franchise"
                                                    :options="franchiseAll"
                                                    />
                                                    <span class="error" v-show="errors.has('franchise')">
                                                    {{ $root.updateErrorMessage('franchise',this.errors,'Franchise Name')}}
                                                    </span>
                                        </div>                      
                                        <div class="form-group col-md-8 col-xs-12">
                                            <label for="branch_address">Branch Address<p class="astrisk">*</p></label>
                                                    <label  v-if="item.franchise" for="checkbox" style="font-weight: 400; cursor:pointer;">(Same as a Franchise Address?</label>
                                                    <!-- <span v-if="item.franchise"> -->
                                                        <!-- <label class="containck"> -->
                                                        <input
                                                            id="checkbox"
                                                            v-show="item.franchise"                       
                                                            type="checkbox"
                                                            @change="updateCheckbox">
                                                            <!-- <label for="checkbox">description</label> -->

                                                    <label v-if="item.franchise" style="font-weight: 400; cursor:pointer;">)</label>
                                                    <!--       <span class="checkmarkck"></span>
                                                        </label>
                                                    </span> -->
                                            <textarea
                                                    class="form-control"
                                                    name="branch_address"
                                                    ref="branch_address"
                                                    v-validate="'required'"
                                                    placeholder="Enter Branch Address"
                                                    :value="item.branch_address"
                                                    maxlength="200"
                                                    @input="updateBranchAddress"
                                                    ></textarea>
                                                    <!--    <span v-if="item.franchise">Same as a Franchise Address?</span>
                                                    <span v-if="item.franchise">
                                                        <label class="containck">
                                                        <input
                                                            type="checkbox"
                                                            @input="updateCheckbox">
                                                            <span class="checkmarkck" style="left: 0px; top: -27px; left: 180px;"></span>
                                                        </label>
                                                    </span> -->
                                                    <span class="error" v-show="errors.has('branch_address')">
                                                        {{ $root.updateErrorMessage('branch_address',this.errors,'Branch Address')}}
                                                    </span>
                                        </div>

                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="branch_name">Branch Area</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="area"
                                                    ref="area"
                                                    maxlength="90"
                                                    placeholder="Enter Branch Area"
                                                    :value="item.area"
                                                    @input="updateArea"
                                                    >
                                                    <span class="error" v-show="errors.has('area')">
                                                    {{ $root.updateErrorMessage('area',this.errors,'Branch Area')}}
                                                    </span>
                                        </div>

                                        <div class="clearfix"></div>
                                        <div ref="country_list" class="form-group col-md-4 col-xs-12">
                                            <label for="country">Branch Country<p class="astrisk">*</p></label>
                                            
                                            <v-select
                                                    name="country_list"
                                                    label="country_name"
                                                    v-validate="'required'"
                                                    placeholder="Select Country"
                                                    @change="updateCountry"
                                                    :value="item.country_list"
                                                    :options="countryAll"
                                                    />
                                                    <span class="error" v-show="errors.has('country_list')">
                                                    {{ $root.updateErrorMessage('country_list',this.errors,'Branch Country')}}
                                                    </span>
                                        </div>
                                        
                                        <div ref="state_list" class="form-group col-md-4 col-xs-12">
                                            <label for="state">Branch State<p class="astrisk">*</p></label>
                                            
                                            <v-select
                                                    name="state_list"
                                                    label="state_name"
                                                    v-validate="'required'"
                                                    placeholder="Select State"
                                                    @change="updateState"
                                                    class="parentName"
                                                    :value="item.state_list"
                                                    :options="stateAll"
                                                    />
                                                    <AddStatePopupBranch v-if="$can('state_create') && statePopupButton"></AddStatePopupBranch>
                                                    <span class="error" v-show="errors.has('state_list')">
                                                    {{ $root.updateErrorMessage('state_list',this.errors,'Branch State')}}
                                                    </span>
                                        </div>
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="city">Branch City<p class="astrisk">*</p></label>
                                            <!-- <input
                                                    type="text"
                                                    class="form-control"
                                                    name="city"
                                                    ref="city"
                                                    maxlength="40"
                                                    v-validate="'required'"
                                                    placeholder="Enter Branch City"
                                                    :value="item.city"
                                                    @input="updateCity"
                                                    > -->
                                             <v-select
                                                    name="city_list"
                                                    label="city_name"
                                                    v-validate="'required'"
                                                    @change="updateCity"
                                                    placeholder="Select City"
                                                    :value="item.city_list"
                                                    :options="cityAll"
                                                    />
                                                    <span class="error" v-show="errors.has('city_list')">
                                                    {{ $root.updateErrorMessage('city_list',this.errors,'Branch City')}}
                                                    </span>
                                        </div>
                                        <div class="form-group col-md-6 col-xs-12">
                                            <label for="pincode">Branch PIN/ZIP Code</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="pincode"
                                                    ref="pincode"
                                                    maxlength="25"
                                                    placeholder="Enter Branch PIN/ZIP Code"
                                                    :value="item.pincode"
                                                    @input="updatePincode"
                                                    >
                                                    <span class="error" v-show="errors.has('pincode')">
                                                    {{ $root.updateErrorMessage('pincode',this.errors,'Branch PIN/ZIP Code')}}
                                                    </span>
                                        </div>
                                        <div class="form-group col-md-6 col-xs-12">
                                            <label for="branch_email">Branch Email<p class="astrisk">*</p></label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="branch_email"
                                                    ref="branch_email"
                                                    maxlength="200"
                                                    v-validate="'required|email'"
                                                    placeholder="Enter Branch Email"
                                                    :value="item.branch_email"
                                                    @input="updateBranchEmail"
                                                    >
                                                    <span class="error" v-show="errors.has('branch_email')">
                                                    {{ $root.updateErrorMessage('branch_email',this.errors,'Branch Email')}}
                                                    </span>
                                        </div>
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="mobile_no">Branch Mobile Number<p class="astrisk">*</p></label>                                 
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="mobile_no"
                                                    ref="mobile_no"
                                                    v-validate="'required|digits:10'"
                                                    maxlength="10"
                                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                    placeholder="Enter Branch Mobile Number"
                                                    :value="item.mobile_no"
                                                    @input="updateMobileNo"
                                                    >
                                                    <span class="error" v-show="errors.has('mobile_no')">
                                                    {{ $root.updateErrorMessage('mobile_no',this.errors,'Branch Mobile number')}}
                                                    </span>
                                        </div>   
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="whatsapp_no">Whatsapp Number</label>
                                            <label for="checkboxWp" style="font-weight: 400; cursor:pointer;">(Same as a Mobile Number?</label>
                                                <input
                                                    id="checkboxWp"
                                                    type="checkbox"
                                                    @change="updateWPCheckbox">
                                            <label style="font-weight: 400; cursor:pointer;">)</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="whatsapp_no"
                                                    ref="whatsapp_no"
                                                    v-validate="'digits:10'"
                                                    maxlength="10"
                                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                    placeholder="Enter WhatsApp Number"
                                                    :value="item.whatsapp_no"
                                                    @input="updateWhatsappNo"
                                                    >
                                                    <span class="error" v-show="errors.has('whatsapp_no')">
                                                    {{ $root.updateErrorMessage('whatsapp_no',this.errors,'Whatsapp number')}}
                                                    </span>
                                        </div>                                                                
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="phone_no">Branch Phone Number</label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        name="phone_no"
                                                        ref="phone_no"
                                                        maxlength="25"
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                        placeholder="Enter Branch Phone Number"
                                                        :value="item.phone_no"
                                                        @input="updatePhoneNo"
                                                        >
                                                        <span class="error" v-show="errors.has('phone_no')">
                                                        {{ $root.updateErrorMessage('phone_no',this.errors, 'Branch Phone number')}}
                                                        </span>
                                        </div>
                                        <div class="form-group col-md-6 col-xs-12">
                                            <label for="branch_gst_no">Branch GST Number</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="branch_gst_no"
                                                    ref="branch_gst_no"
                                                    v-validate="{ regex: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}/ }"
                                                    maxLength=15
                                                    placeholder="Enter Branch GST Number"
                                                    :value="item.branch_gst_no"
                                                    @input="updateBranchGstNo"
                                                    >
                                                    <span class="error" v-show="errors.has('branch_gst_no')">
                                                    {{ $root.updateErrorMessage('branch_gst_no',this.errors,'Branch GST Number')}}
                                                    </span>
                                        </div>
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="branch link">Branch Link<p class="astrisk">*</p></label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="branch link"
                                                ref="branch link"
                                                v-validate="'required|url'"
                                                placeholder="Enter Branch Link"
                                                :value="item.branch_link"
                                                @input="updateBranchLink"
                                                >
                                                <span class="error" v-show="errors.has('branch link')">
                                                    {{ $root.updateErrorMessage('branch link',this.errors,'Branch Link')}}
                                                </span>
                                        </div>
                                        <!-- <div id="branch_pan_no_wrapper" class="form-group col-md-6 col-xs-12" style="display:nno">
                                            <label for="branch_pan_no">Branch PAN Number</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    name="branch_pan_no"
                                                    ref="branch_pan_no"
                                                    v-validate="{ regex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/ }"
                                                    maxLength=10
                                                    placeholder="Enter Branch PAN Number"
                                                    :value="item.branch_pan_no"
                                                    @input="updateBranchPanNo"
                                                    >
                                                    <span class="error" v-show="errors.has('branch_pan_no')">
                                                    {{ $root.updateErrorMessage('branch_pan_no',this.errors,'Branch PAN number')}}
                                                    </span>
                                        </div>                                  -->
                                        <div class="form-group check col-md-12 col-xs-12 branch-create">
                                            <div class="row">
                                            
                                                <label class="m_left">Branch Working Days<p class="astrisk">*</p></label>
                                                <div class="weekdays">
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        v-validate="'required|included:1,2,3,4,5,6,7'"
                                                                        :value="1"
                                                                        :checked="item.workingDay1 == true"
                                                                        @change="updateWorkingDay1"
                                                                        >
                                                                Monday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="2"
                                                                        :checked="item.workingDay2 == true"
                                                                        @change="updateWorkingDay2"
                                                                        >
                                                                Tuesday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="3"
                                                                        :checked="item.workingDay3 == true"
                                                                        @change="updateWorkingDay3"
                                                                        >
                                                                Wednesday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="4"
                                                                        :checked="item.workingDay4 == true"
                                                                        @change="updateWorkingDay4"
                                                                        >
                                                                Thursday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="5"
                                                                        :checked="item.workingDay5 == true"
                                                                        @change="updateWorkingDay5"
                                                                        >
                                                                Friday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="6"
                                                                        :checked="item.workingDay6 == true"
                                                                        @change="updateWorkingDay6"
                                                                        >
                                                                Saturday
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-1 col-xs-1">
                                                        <div class="checkbox">
                                                            <label>
                                                                <input
                                                                        type="checkbox"
                                                                        name="workingDay"
                                                                        :value="7"
                                                                        :checked="item.workingDay7 == true"
                                                                        @change="updateWorkingDay7"
                                                                        
                                                                        >
                                                                Sunday
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <span id="err_workingDay" class="error" v-show="errors.has('workingDay')">
                                                {{ $root.updateErrorMessage('workingDay',this.errors,'Branch Working Days')}}
                                            </span>                                    
                                        </div>
                                        <!-- <div class="col-md-12 col-xs-12">
                                            <div class="form-group">
                                            <input 
                                                id="automatic_batch_creation" 
                                                name="automatic_batch_creation"
                                                @change="updatedAutomaticBatchCreation" 
                                                type="checkbox" >
                                            <label class="checkbox-label" for="automatic_batch_creation" style="font-weight: 400; cursor:pointer;">&nbsp;Automatic Batch Creation</label>
                                            </div>
                                        </div>   -->
                                        <div class="col-md-12 col-xs-12">
                                            <div class="form-group">
                                            <input 
                                                id="allow_online_courses" 
                                                name="allow_online_courses"
                                                @change="updatedAllowOnlineCourses" 
                                                type="checkbox" >
                                            <label class="checkbox-label" for="allow_online_courses" style="font-weight: 400; cursor:pointer;">&nbsp;Allow online courses</label>
                                            </div>
                                        </div> 
                                        </div>
                                        <div id="workdays">                                        
                                        </div>
                                    </div>
                                    <!-- <div class="box col-md-12" v-if="item && item.franchise && item.franchise.franchise_type == 1">
                                        <div class="box-header with-border">
                                            <h3 class="box-title">Branch Licence Detail</h3>
                                        </div>

                                        
                                        <div class="box-body row">
                                        <div ref="licence_start_date" class="form-group col-md-6 col-xs-12">
                                                <label for="licence_start_date">Licence Start Date<p class="astrisk">*</p></label>
                                                    <date-picker
                                                            :config="$root.dpconfigDate"
                                                            name="licence_start_date"
                                                            class="form-control"
                                                            v-validate="(item && item.franchise && item.franchise.franchise_type == 1) ? 'required' : ''"
                                                            placeholder="Select Licence Start Date"
                                                            :value="item.licence_start_date"
                                                            @dp-change="updateLicenceStartDate"
                                                            
                                                            >
                                                    </date-picker>
                                                        <span class="error" v-show="errors.has('licence_start_date')">
                                                        {{ $root.updateErrorMessage('licence_start_date',this.errors,'Licence Start Date')}}
                                                        </span>
                                            </div>                                
                                            <div ref="licence_end_date" class="form-group col-md-6 col-xs-12">
                                                <label for="licence_end_date">Licence End Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                        :config="exitDateOptionFormate"
                                                        name="licence_end_date"
                                                        v-validate="(item && item.franchise && item.franchise.franchise_type == 1) ? 'required' : ''"
                                                        placeholder="Select Licence End Date"
                                                        class="form-control"
                                                        :value="item.licence_end_date"
                                                        @dp-change="updateLicenceEndDate"
                                                        >
                                                </date-picker>        
                                                        <span class="error" v-show="errors.has('licence_end_date')">
                                                        {{ $root.updateErrorMessage('licence_end_date',this.errors,'Licence End Date')}}
                                                        </span>
                                            </div>
                                        </div> 
                                    </div> -->
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                                type="submit"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div> 
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddStatePopupBranch from '../../dtmodules/PopupActions/AddStatePopupBranch'

export default {
    components: { AddStatePopupBranch },
    data() {
        return {
            // Code...
            //errorName : ''
            statePopupButton: true,
            exitDateOptionFormate:{format:window.date_format_moment},
        }
    },
    computed: {
        ...mapGetters('BranchsSingle', ['item', 'loading','franchiseAll','addressAll','countryAll','stateAll','cityAll']),
       
    },
    created() {
        this.fetchFranchiseAll();
        this.fetchCountryAll();
        this.fetchStateAll();
        // this.fetchCityAll();
        // this.invokeCal();
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('BranchsSingle', ['storeData', 'resetState', 'setBranchName','setFranchiseId','setMobileNo','setWhatsappNo', 'setBranchEmail','setBranchAddress', 'setArea', 'setCity', 'setState', 'setCountry', 'setPincode', 'setPhoneNo', 'setBranchGstNo', 'setBranchPanNo', 'setLicenceStartDate', 'setLicenceEndDate','fetchFranchiseAll', 'fetchAddressAll','fetchCountryAll','fetchStateAll','fetchCityAll','fetchCountryStates','fetchStatesCities','setWorkingDay1','setWorkingDay2','setWorkingDay3','setWorkingDay4','setWorkingDay5','setWorkingDay6','setWorkingDay7','setStateAll','setCityAll','fetchAddressAll','setAutomaticBranchCreation', 'setAllowOnlineCourses', 'setBranchLink']),
       updatedAutomaticBatchCreation(e){
            if(e.target.checked == true) {
                this.setAutomaticBranchCreation(1)
            } else {
                this.setAutomaticBranchCreation(0)
            }
       }, 
       updatedAllowOnlineCourses(e){
            if(e.target.checked == true) {
                this.setAllowOnlineCourses(1)
            } else {
                this.setAllowOnlineCourses(0)
            }
       },
        updateBranchName(e) {
            this.setBranchName(e.target.value)
        },
        validateDates() {
            return true;
            // if(this.item.franchise && this.item.franchise.franchise_type)
        },
        updateCheckbox(e){
            if(e.target.checked == true && this.item.franchise && this.item.franchise.id !== null) {
                this.fetchAddressAll(this.item.franchise.id)
                .then((res) => {
                    this.updateAddressData();
                })
                //setTimeout(function () { this.updateAddressData();}.bind(this), 240)
            }
        },
        updateWPCheckbox(e){
            if(e.target.checked == true) {
                console.log("chekc",this.item.mobile_no);
                this.updateWhatsappNo(this.item.mobile_no)
            } else {
                this.updateWhatsappNo(null)
            }
        },        
        updateAddressData() {
            this.setCountry(this.addressAll.country_list)
            .then((res) => {
                this.setState(this.addressAll.state_list);
            })
            this.setBranchAddress(this.addressAll.franchise_address)
            this.setArea(this.addressAll.area)
            this.setCity(this.addressAll.city)
            this.setPincode(this.addressAll.pincode)
            this.setState(this.addressAll.state_list);
            //setTimeout(function () { this.setState(this.addressAll.state_list); }.bind(this), 400)
            // this.setState(this.addressAll.state_list)
        },
        updateFranchiseId(value) {
            this.setFranchiseId(value)
            
            if(this.item.franchise && this.item.franchise.id !== null){
                // Uncheck The Same as franchise address checkbox
                document.getElementById("checkbox").checked = false;

                // fetch franchise and update pan number
                this.fetchAddressAll(this.item.franchise.id)
                .then((res) => {
                    this.setBranchPanNo( this.addressAll.branch_pan_no );
                    document.getElementById('branch_pan_no_wrapper').style.display = 'none';
                })
            } else {
                this.setBranchPanNo(null);
                document.getElementById('branch_pan_no_wrapper').style.display = 'block';
            }            
        },
        updateMobileNo(e) {
            this.setMobileNo(e.target.value)
        },
        updateWhatsappNo(e) {
            let num = e;
            if(typeof e === "object"){
                num = e.target.value;
            }
            //console.log("e",typeof e);
            
            this.setWhatsappNo(num)
            if(num == this.item.mobile_no) {
              document.getElementById('checkboxWp').checked = true
            } else {
              document.getElementById('checkboxWp').checked = false
            }        
        },        
        updateBranchEmail(e) {
            this.setBranchEmail(e.target.value)
        },
        updateBranchAddress(e) {
            this.setBranchAddress(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        // updateCity(e) {
        //     this.setCity(e.target.value)
        // },
         updateCountry(value) {
                if(value !== null){
                    this.fetchCountryStates(value.id);
                    this.statePopupButton = true;
                }            
                  else{
                    this.setStateAll([]);
                    this.statePopupButton = false;
                  }
            this.setState(null)
            this.setCountry(value)
        },
        updateState(value) {                          
             if(value !== null){
                    this.fetchStatesCities(value.id);
                    // this.stateFlag = true
                }            
                  else{
                    this.setCityAll([]);
                    
                  }
            this.setCity(null)
            this.setState(value)
        },
        updateCity(value) {
            this.setCity(value)
        },
        updatePincode(e) {
            this.setPincode(e.target.value)
        },
        updatePhoneNo(e) {
            this.setPhoneNo(e.target.value)
        },
        updateBranchGstNo(e) {
            this.setBranchGstNo(e.target.value)
        },
        updateBranchPanNo(e) {
            this.setBranchPanNo(e.target.value)
        },
/*        updateLicenceStartDate(value) {
            console.log(value);
            // var year = value.getFullYear();
            // var month = value.getMonth()+1;
            // var day = value.getDate();
            // var finalStartDate = year +'-'+month+'-'+day +' 00:00:00';
            var finalStartDate = value +' 00:00:00';
            this.setLicenceStartDate(finalStartDate)
        },*/
        updateLicenceStartDate(e) {
            if(e && e.target.value != ''){
                this.exitDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };  
                   this.setLicenceEndDate(null)
            }
            this.setLicenceStartDate(e.target.value)
        },        
/*        updateLicenceEndDate(value) {
            console.log(value);
            // var year = value.getFullYear();
            // var month = value.getMonth()+1;
            // var day = value.getDate();
            // var finalEndDate = year +'-'+month+'-'+day +' 23:59:59';
            var finalEndDate = value +' 23:59:59';
            this.setLicenceEndDate(finalEndDate)
        },*/
        updateLicenceEndDate(e) {

            this.setLicenceEndDate(e.target.value)
        },
        updateWorkingDay1(e) {
            this.setWorkingDay1(e.target.checked)
        },
        updateWorkingDay2(e) {
            this.setWorkingDay2(e.target.checked)
        },
        updateWorkingDay3(e) {
            this.setWorkingDay3(e.target.checked)
        },
        updateWorkingDay4(e) {
            this.setWorkingDay4(e.target.checked)
        },
        updateWorkingDay5(e) {
            this.setWorkingDay5(e.target.checked)
        },
        updateWorkingDay6(e) {
            this.setWorkingDay6(e.target.checked)
        },
        updateWorkingDay7(e) {
            this.setWorkingDay7(e.target.checked)
        },
        updateBranchLink(e) {
            this.setBranchLink(e.target.value)
        },
        // handleValidationFocus() {
        //   const firstField = Object.keys(this.errors.collect())[0];
        //   this.$refs[`${firstField}`].scrollIntoView();
        //   this.$refs[`${firstField}`].focus();
        // },



        submitForm() {

         this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {
                    if(!res.data.result)
                    {
                       
                        for (const [key, value] of Object.entries(res.data.data)) {
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                        this.buttonLoading = false
                        return '';
                    }
                    this.$router.push({ name: 'branchs.index' })                    
                    this.$eventHub.$emit('create-success')
                    //This code Refreshes the page - DENISH
                    //setTimeout(function () { this.$router.push({ name: 'branchs.index' }) }.bind(this), 1000)                    
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


<style scoped>

</style>
