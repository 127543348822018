<template> 
    <form @submit.prevent="submitForm" novalidate>
        
           
        <div class="row">
            <div class="card-header col-md-12 mb-10">
            <h3 class="card-title">Add Course</h3>
        </div>
        <div class="col-md-12">
            <bootstrap-alert />
        </div>
        <div class="form-group col-md-12 col-xs-12">
            <label for="course_name">Course Name<p class="astrisk">*</p></label>
            <input
                    type="text"
                    class="form-control"
                    name="course_name"
                    ref="course_name"
                    maxlength="90"
                    v-validate="'required'"
                    placeholder="Enter Course Name"
                    @input="updateCourseName"
                    :value="courseName"
                    >
            <span class="error" v-show="errors.has('course_name')">
                {{ $root.updateErrorMessage('course_name',this.errors,'Course Name')}}
            </span>
        </div>  
        <!-- <v-select taggable push-tags multiple></v-select> -->

        <div class="col-md-12 col-xs-12">
            <div class="form-group">
                <label for="listOption" style="vertical-align:middle;">
                    Select List Option
                    <p class="astrisk">*</p>
                </label>
                <div class="radio-inline">
                    <label class="contain">
                    <input
                        type="radio"
                        name="listOption"
                        ref="listOption"
                        v-validate="'required|included:1,2'"
                        value="1"
                        v-model="listOption"
                        @change="updateList(1)"
                        >
                    <span class="checkmark"></span>
                    Projects
                    </label>
                </div>
                <div class="radio-inline" style="margin-left:15px;">
                    <label class="contain">
                    <input
                        type="radio"
                        name="listOption"
                        ref="listOption"
                        value="2"
                        v-model="listOption"
                        @change="updateList(2)"
                        >
                    <span class="checkmark"></span>
                    Modules
                    </label>
                </div>
                
                <span class="error" v-show="errors.has('listOption')">
                {{ $root.updateErrorMessage('listOption',this.errors)}}
                </span>
            </div>
        </div>
        <div class="form-group col-md-12 col-xs-12 mb-0">
            <label for="module_name">Project / Module Selection<p class="astrisk">*</p></label>
        </div>
            <div class="form-group col-md-12 col-xs-12 slectBoxIcon">
            <div class="subject-info-box-1">
            <select multiple="multiple" id='lstBox1' class="form-control"></select>
            </div>
            <div class="subject-info-arrows text-center">
            <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
            <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
            <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
            <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
            <span class="top-margin" style="color: red;" id="movingError"></span>
            </div>
            <div class="subject-info-box-2">
            <select multiple="multiple" name="lstBox2" id='lstBox2' class="form-control"></select>
                <span class="error" v-if="allSelectError != ''">
                    {{allSelectError}}
                </span>
            </div>
        </div>  
    
        <div class="form-group col-md-6 col-xs-12">
            <label for="course_vertical">Course Vertical<p class="astrisk">*</p></label>
                <v-select
                name="course_vertical"
                label="name"
                value="id"
                v-validate="'required'"
                placeholder="Select Course Vertical"
                :options="courseVertical"
                v-model="course_vertical"
                />
            <span class="error" v-show="errors.has('course_vertical')">
                {{ $root.updateErrorMessage('course_vertical',this.errors,'Course Vertical')}}
            </span>                                            
        </div>  
        <div class="form-group col-md-6 col-xs-12">
            <label for="course_category">Course Category<p class="astrisk">*</p></label>
                <v-select
                name="course_category"
                label="name"
                value="id"
                v-validate="'required'"
                placeholder="Select Course Category"
                :options="courseCategory"
                v-model="course_category"
                />
            <span class="error" v-show="errors.has('course_category')">
                {{ $root.updateErrorMessage('course_category',this.errors,'Course Category')}}
            </span>                                            
        </div>  
        <div class="col-md-12 col-xs-12">
            <div class="form-group">
                <label for="courseFeeType" style="vertical-align:middle;">
                    Select Course Fee Type
                    <p class="astrisk">*</p>
                </label>
                <div class="radio-inline" v-for="feeType in courseFeeType" :key="feeType.id">
                    <label class="contain">
                    <input
                        type="radio"
                        name="courseFeeType"
                        ref="courseFeeType"
                        v-validate="'required'"
                        :value="feeType.id"
                        v-model="course_fee_type"
                        >
                    <span class="checkmark"></span>
                    {{feeType.name}}
                    </label>
                </div>
                
                
                <span class="error" v-show="errors.has('courseFeeType')">
                {{ $root.updateErrorMessage('courseFeeType',this.errors,'Course Fee Type')}}
                </span>
            </div>
        </div>
        <div class="form-group col-md-6 col-xs-12">
            <label for="course_hours">Course Hours<p class="astrisk">*</p></label>
            <input
                    type="text"
                    class="form-control"
                    name="course_hours"
                    ref="course_hours"
                    maxlength="90"
                    v-validate="'required'"
                    placeholder="Enter Course Name"
                    @input="updateCourseHours"
                    :value="courseHours"
                    disabled="true"
                    >
            <span class="error" v-show="errors.has('course_hours')">
                {{ $root.updateErrorMessage('course_hours',this.errors,'Course Hours')}}
            </span>
        </div>  
        <div class="form-group col-md-3 col-xs-12">
            <label for="course_months">Default Course Months<p class="astrisk">*</p></label>
            <input
                    type="text"
                    class="form-control"
                    name="course_months"
                    ref="course_months"
                    maxlength="10"
                    v-validate="'required'"
                    placeholder="Enter Course Months"
                    @input="updateCourseMonths"
                    :value="courseMonths"
                    disabled="true"
                    >
            <span class="error" v-show="errors.has('course_months')">
                {{ $root.updateErrorMessage('course_months',this.errors,'Course Months')}}
            </span>
        </div> 
        <div class="form-group col-md-3 col-xs-12">
            <label for="course_months">Course Months<p class="astrisk">*</p></label>
            <input
                    type="text"
                    class="form-control"
                    name="course_c_months"
                    ref="course_c_months"
                    maxlength="10"
                    v-validate="{required:true,min_value:courseMonths,numeric:true}"
                    oninput="this.value=this.value
                        .replace(/[^\d.]/g, '')
                        .replace(/(\..*)\./g, '$1')
                        .replace(/(\.[\d]{2})./g, '$1');"
                    placeholder="Enter Course Months"
                    @input="updateCourseCMonths"
                    :value="courseCMonths"
                    >
            <span class="error" v-show="errors.has('course_c_months')">
                {{ $root.updateErrorMessage('course_c_months',this.errors,'Course Months')}}
            </span>
        </div>

        
        </div>                  
            <div class="row">
                 <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">Referral Settings</h3>
                </div>
                    
                        <div class="form-group col-md-6 col-xs-12">
                            <label for="referral_user_price">Referral User Price<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="referral_user_price"
                                    ref="referral_user_price"
                                    v-validate="{required:true,min_value:1,max_value:100000,numeric:true}"
                                    oninput="this.value=this.value
                                        .replace(/[^\d.]/g, '')
                                        .replace(/(\..*)\./g, '$1')
                                        .replace(/(\.[\d]{2})./g, '$1');"
                                    placeholder="Enter Referral User Price"
                                    maxlength="6"
                                    @input="updateReferralUserPrice"
                                    :value="ReferralUserPrice"
                                    >
                            <span class="error" v-show="errors.has('referral_user_price')">
                                {{ $root.updateErrorMessage('referral_user_price',this.errors,'Referral User Price')}}
                            </span>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                            <label for="referred_user_price">Referred User Price<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="referred_user_price"
                                    ref="referred_user_price"
                                    v-validate="{required:true,min_value:1,max_value:100000,numeric:true}"
                                    oninput="this.value=this.value
                                        .replace(/[^\d.]/g, '')
                                        .replace(/(\..*)\./g, '$1')
                                        .replace(/(\.[\d]{2})./g, '$1');"
                                    maxlength="6"
                                    placeholder="Enter Referred User Price"
                                    @input="updateReferredUserPrice"
                                    :value="ReferredUserPrice"
                                    >
                            <span class="error" v-show="errors.has('referred_user_price')">
                                {{ $root.updateErrorMessage('referred_user_price',this.errors,'Referred User Price')}}
                            </span>
                        </div>
                        <div class="form-group col-md-6 col-xs-12">
                                <label for="end_date">End Date<p class="astrisk">*</p></label>
                                <date-picker
                                        :value="endDate"
                                        :config="$root.dpconfigDateFuture"
                                        name="end_date"
                                        ref="end_date"
                                        v-validate="isActive && isActive == 1 ? 'required' : ''"
                                        @input="updateEndDate"
                                        placeholder="Select End Date"
                                        :min-date='new Date()'
                                        autocomplete="off"
                                        >
                                </date-picker>                                      
                                <span class="error" v-show="errors.has('end_date')" v-if="isActive == 1">
                                            {{ $root.updateErrorMessage('end_date',this.errors)}}
                                </span>
                        </div>
                        <!-- <div class="col-md-6 col-xs-12">
                            <div class="form-group check-inline">
                                <label for="is_active">Is Active : </label>
                                <input 
                                    id="is_active" 
                                    name="is_active"
                                    @change="updatedIsActive" 
                                    type="checkbox">
                            </div>
                        </div> -->

                        <div class="col-md-6 col-xs-12" id="">
                            <label for="end_date">&nbsp;</label>
                            <div class="form-group ds-flex">
                            <div class="custom-control custom-checkbox mr-2">
                                <input class="custom-control-input" type="checkbox" id="is_active" name="is_active" @change="updatedIsActive">
                                <label for="is_active" class="custom-control-label">Is Active for referral </label>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-md-12 savebtn">
                        <vue-button-spinner
                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                :isLoading="loading"
                                :disabled="loading"
                                >
                                Save
                        </vue-button-spinner>
                    </div>
                    
            </div>   
        </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            allSelectError:'',
            course_vertical:'',
            course_category:'',
            listOption: 1,
            course_fee_type:216,
            courseName: null,
            ReferralUserPrice:null,
            ReferredUserPrice:null,
            endDate:null,
            courseHours:0,
            courseMonths:0,
            courseCMonths:0,
            isActive:0,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            description:'',
            more_details: [],
            extra_details:[],
            more_detail_error:'',
            extra_detail_error:[],            
            course_skills:[],
            moreDetailDescription:'',
            moreDetailTitle:'',
            selected_year:'',
            selected_standard:'',
            isPopular:false,
            seo_title:'',
            seo_keywords:[],
            seo_description:'',
            yearsAll:[6,7,8,9,10,11,12,13,14,15,16,17,18],
            // courseImage:null,
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ]            
        }
    },
    created() {
        this.fetchStandardAll();
        this.fetchTypes();
        this.fetchProjectAll()
        this.fetchModuleAll()
            // .then((res) => {
            //     //console.log("res",res);
            //     var localStaffAll = this.projectAll;
            //     setTimeout(function() {
            //             for (var i = 0; i < localStaffAll.length; i++) {
            //                 $('#lstBox1')
            //                     .append($("<option></option>")
            //                         .attr("class","project")
            //                         .attr("value",localStaffAll[i]updateMoreDetailDescription.id)
            //                         .text(localStaffAll[i].employee_name));
            //             }
            //     },200)  
            // })
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    mounted(){
        let _this = this;
        $(".courseSkill").select2({placeholder: "Select Course Skill",tags: true,tokenSeparators: [',']}).on('change', function () {
            _this.updateCourseSkill($(this).select2("val"))
        });
        $(".seo_keywords").select2({placeholder: "Select SEO Keywords",tags: true,tokenSeparators: [',']}).on('change', function () {
            _this.updateSEOKeywords($(this).select2("val"))
        });
    },
    computed: {
        ...mapGetters('CoursesIndex', ['courseImage','courseMonthFormula','all','loading','moduleAll','projectAll','courseVertical','courseCategory','courseFeeType']),
        ...mapGetters('AuthIndex', ['user']),
        ...mapGetters('StudentSingle', ['standardAll']),
    },
    watch:{
        courseHours(value){
            if(this.courseMonthFormula[0].name){
                let fomula = this.courseMonthFormula[0].name.replace('H',value);
                console.log(fomula);
                this.courseMonths = Math.ceil(eval(fomula));
            }
            else{
                this.courseMonths = 0;
            }
           
        },
        projectAll(value){
            for (var i = 0; i < value.length; i++) {
                //console.log("loop",value[i]);
                $('#lstBox1')
                    .append($("<option data-hours='"+value[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",value[i].id)
                        .text(value[i].project_name));
            }
        }
    },
    methods: {
        ...mapActions('CoursesIndex', ['setCourseImage','resetState','fetchTypes','fetchModuleAll','fetchProjectAll','courseStore','setIsActive']),
        ...mapActions('StudentSingle', ['fetchStandardAll']),
        updateCourseSkill(value){
            this.course_skills = value;
        },
        updateSEOKeywords(value){
            this.seo_keywords = value
        },
        updateCourseName(e) {
            this.courseName = e.target.value
        },
        AddField() {
        this.more_details.push({ title: '',image: '',image_url:'',rand_key:Math.floor((Math.random() * 100) + 1)});
        },
        AddExtraField(){
            this.extra_details.push({ label: '',value: '',rand_key:Math.floor((Math.random() * 100) + 1)});
        },
        updateMoreDetailTitle(e)
        {
            this.moreDetailTitle = e.target.value
        },
        updateMoreDetailDescription(e)
        {
            this.moreDetailDescription = e.target.value
        },
        removeFee(index){
    
            this.more_detail_error = '';
        
            index = this.more_details.findIndex(p => p.rand_key == index);
            this.more_details.splice(index, 1);

            },
        removeExtraDetail(index){
            this.extra_detail_error = '';
        
            index = this.extra_details.findIndex(p => p.rand_key == index);
            this.extra_details.splice(index, 1);
        },
        updateImage(e,index){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                let index1 = this.more_details.findIndex(p => p.rand_key == index);
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                       // console.log("dfdf",e.target.result)
                        $('#blah_'+index)
                            .attr('src', e.target.result)
                            .width(100)
                            .height(100);
                    };
                    // console.log("resd",reader);
                    reader.readAsDataURL(e.target.files[0]);
                    this.more_details[index1].image = e.target.files[0];
                    this.more_details[index1].image_url = reader;
                    this.$forceUpdate();
                }else{
                    this.more_details[index1].image =  '';
                    this.more_details[index1].image_url = '';
                    
                }
            }           
        },
        updateProfile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(150);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setCourseImage(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#course_image").val('');
                            this.$validator.errors.remove('course_image');
                            this.setCourseImage('');
                            // this.courseImage = null;
                        }
                    })
                }
            }           
        }, 
        removeProfile(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setCourseImage('');
                     $("#course_image").val('');
                }
            })
        }, 
        updateCourseDescription(e){
            this.description = e.target.value
        },
        updateReferralUserPrice(e) {
            this.ReferralUserPrice = e.target.value
        },
        updateReferredUserPrice(e) {
            this.ReferredUserPrice = e.target.value
        },
        updatedIsActive(e){
            if(e.target.checked == true) {
                this.isActive = 1;
                this.setIsActive(1)
            } else {
                this.isActive = 0;
                this.setIsActive(0)
            }
        },
        updatedCoursePopular(e){
             if(e.target.checked == true) {
                    this.isPopular = 1;
                    //this.setIsActive(1)
                } else {
                    this.isPopular = 0;
                    //this.setIsActive(0)
                }
        },
        updateEndDate(e) {
            this.endDate = e;
        },
        updateCourseHours(e){
            this.courseHours = e.target.value
        },
        updateCourseMonths(e){
            this.courseMonths = e.target.value
        },
        updateCourseCMonths(e){
          this.courseCMonths = e.target.value  
        },
        moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')             
            }
            
            for (let i = 0; i < selectedOpts.length; i++) {
                this.courseHours = this.courseHours + Number($(selectedOpts[i]).data('hours'));
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.courseHours = this.courseHours + Number($(selectedOpts[i]).data('hours'));
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            
             if(this.listOption == 1) {
                var selectedOpts = $('#lstBox2 option.project:selected');
            } else if(this.listOption == 2) {
                var selectedOpts = $('#lstBox2 option.module:selected');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')               
            }
            var selectedOptsh = $('#lstBox2 option:selected');
            for (let i = 0; i < selectedOptsh.length; i++) {
                this.courseHours = this.courseHours - Number($(selectedOptsh[i]).data('hours'));
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
           
             if(this.listOption == 1) {
                var selectedOpts = $('#lstBox2 option.project');
            } else if(this.listOption == 2) {
                var selectedOpts = $('#lstBox2 option.module');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            for (let i = 0; i < selectedAllOpts.length; i++) {
                this.courseHours = this.courseHours - Number($(selectedAllOpts[i]).data('hours'));
            }
            $(selectedAllOpts).remove();
        },
        updateList(value) {
            this.listOption == parseInt(value)
            $('#lstBox1').empty()
            var allopt = [],
            options = $('#lstBox2 option'),
            values = $.map(options ,function(option) {
                allopt.push(option.value)
            }),
            allProject = $('.project'),
            allModule = $('.module'),
            allSelectedProjectValue = [],
            allSelectedModuleValue = [];
            for (var i = 0; i < allProject.length; i++) {
                allSelectedProjectValue.push(parseInt(allProject[i].value))
            }
            for (var i = 0; i < allModule.length; i++) {
                allSelectedModuleValue.push(parseInt(allModule[i].value))
            }
            if(value == 1) {
                //console.log("up",this.projectAll);
                for (var i = 0; i < this.projectAll.length; i++) {
                    if(allSelectedProjectValue.indexOf(this.projectAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option data-hours='"+this.projectAll[i].project_hours+"'></option>")
                                .attr("class","project")
                                .attr("value",this.projectAll[i].id)
                                .text(this.projectAll[i].project_name));
                    }
                }                
            } else if(value == 2) {
                for (var i = 0; i < this.moduleAll.length; i++) {
                    if(allSelectedModuleValue.indexOf(this.moduleAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option data-hours='"+this.moduleAll[i].project_total_hours+"'></option>")
                                .attr("class","module")
                                .attr("value",this.moduleAll[i].id)
                                .text(this.moduleAll[i].module_name));
                    }
                }                  
            }
        },
        submitForm() {
            this.allSelectError = '';
            var allProjects = [];
             if((this.more_details).length > 0){
                let more_title = [];
                let _this = this;
                this.more_details.forEach(function(detail, index){
                
                if(more_title.includes(detail.title)){
                    _this.more_detail_error = "All title must be unique.";
                    return false;
                }
                more_title.push(detail.title);
                });
            }

            if((this.extra_details).length > 0){
                let extra_label = [];
                let _this = this;
                this.extra_details.forEach(function(detail, index){
                
                if(extra_label.includes(detail.label)){
                    _this.extra_detail_error = "All label must be unique.";
                    return false;
                }
                extra_label.push(detail.label);
                });
            }
            $('#lstBox2 option').each(function() {
                //console.log($(this).attr('class'));
                allProjects.push({'name': $(this).text(), 'id': $(this).val(),'type':$(this).attr('class')})
            });
           // return false;
           
            var params = {
                'course_name': this.courseName,
                'allSelectedLists': JSON.stringify(allProjects),
                'course_category': this.course_category,
                'course_vertical': this.course_vertical,
                'course_fee_type': this.course_fee_type,
                'total_hours': this.courseHours,
                'total_fee':0,
                'course_months':this.courseCMonths,
                'branch_id':this.user.api_session_branch_id,
                'referral_user_price' : this.ReferralUserPrice,
                'referred_user_price' : this.ReferredUserPrice,
                'is_active' : this.isActive,
                'end_date' : this.endDate,
            }
            this.$validator.validate().then(result => {
                if(result && $('#lstBox2 option').length > 0) {
                    if($('#lstBox2 option').length == 0) {
                        this.$eventHub.$emit('common',2,'Please select atleast one project or one module')
                        return false;
                    }
                    this.buttonLoading = true;                    
                    this.courseStore(params)
                        .then((res)=>{
                            if(!res.data.result)
                            {
                                // this.$validator.errors.add({
                                //     id: 'module_name',
                                //     field: 'module_name',
                                //     msg: res.data.message,
                                //     scope: this.$options.scope,
                                // });
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    //console.log("ke",key,value);
                                    if(key == "allSelectedLists"){
                                        this.allSelectError = value[0];
                                    }
                                    else{
                                        this.$validator.errors.add({
                                            id: key,
                                            field: key,
                                            msg: value[0],
                                            scope: this.$options.scope,
                                        });
                                    }
                                    
                                }
                                this.buttonLoading = false
                                return '';
                            }                            
                            this.$eventHub.$emit('create-success')
                            this.$router.push({name: 'courses.index'})
                        })
                        // .catch(()=>{
                        //    this.$eventHub.$emit('common',3,'Something went wrong.')
                        // })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}



.contain .checkmark:after {
    left: 6.5px;
    top: 6.5px;
}
.content-wrapper .content {
    padding: 0 0.5rem;
    padding-bottom: 70px;
}
</style>