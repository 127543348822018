<template>
    <div class="btn-group btn-group-xs">
        <router-link
                v-if="$can('inquiry_' + 'edit')"
                title="Edit Status"
                :to="'inquiries/' + row.mainInqId + '/status'"
                :class="classMethod(row.statusId)"
                target="_blank">
                 <span class="" v-if="row !== null">
                    {{row.inqStatus}}
                </span>
        </router-link>
        <span v-else>
            <span class="" v-if="row !== null">
                {{row.inqStatus}}
            </span>
        </span>

       
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        classMethod(id){
            if(id == 1)
            {
                return 'label label-info';
            }
            else if(id == 2)
            {
                return 'label label-primary';
            }else if(id == 3)
            {
                return 'label label-success';
            }else if(id == 4)
            {
                return 'label label-danger';
            }
        }
        
    }
}
</script>


<style scoped>

</style>

