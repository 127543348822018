<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Projects</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/projects'">Projects</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Project</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Project</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Project Name</th>
                                    <td><span>{{ projectDetail.project_name }}</span></td>
                                </tr>
                                <tr>
                                    <th>Project Type</th>
                                    <td><span>{{ projectDetail.project_type }}</span></td>
                                </tr>
                                 <tr>
                                    <th>Project Brand</th>
                                    <td><span>{{ projectDetail.project_brand }}</span></td>
                                </tr>
                                <tr>
                                    <th>Project Kit</th>
                                    <td><span>{{ projectDetail.project_kit }}</span></td>
                                </tr>
                                <tr>
                                    <th>Project Hours</th>
                                    <td><span>{{ projectDetail.project_hours }}</span></td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td><span>{{ projectDetail.description }}</span></td>
                                </tr>
                                                                  
                                </tbody>
                            </table>
                        </div>                
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    // destroyed() {
    //     this.resetState()
    // },
    computed: {
        ...mapGetters('ProjectsSingle', ['projectDetail','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
</style>
