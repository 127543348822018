<template>
    <div class="btn-group btn-group-xs">
        <!-- <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles'"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-primary"
                >
            View
        </router-link>

        <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-warning">
            Edit
        </router-link>

        <button
                v-if="$can(xprops.permission_prefix + 'delete')"
                @click="destroyData(row.id)"
                type="button"
                class="btn btn-danger">
            Delete
        </button> -->
        <button
                v-if="xprops.route === 'leave_requests' && row.leave_status == 1"
                @click="cancelData(row.id)"
                type="button"
                title="Cancel"
                class="btn btn-info">
            <i class="fa fa-close" aria-hidden="true"></i>
        </button>
         <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' "
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>


        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route === 'leave_requests' && row.leave_status.value_id == 1"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-primary">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route !== 'leave_requests'"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-primary">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>

        <button
                v-if="$can(xprops.permission_prefix + 'delete') && xprops.route !== 'leave_requests'"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-danger">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops','loadItems'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                            // console.log('then',response)
                            //this.$router.push('/roles');
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }else{
                            this.$eventHub.$emit('common',2,response.data.message)
                        }                       
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })


                     // this.storeData()
                     //    .then(() => {
                     //        this.$router.push({ name: 'receipts.index' })
                     //        this.$eventHub.$emit('create-success')
                     //    })
                     //    .catch((error) => {
                     //        console.error(error)
                     //    })
                }
            })


            // .then(result => {
            //     if (result.value) {
            //         this.$store.dispatch(
            //             this.xprops.module + '/destroyData',
            //             id
            //         ).then(result => {
            //             this.$eventHub.$emit('delete-success')
            //         })
            //     }
            // })
        },
        cancelData(id) {
            this.$swal({
                title: 'Are you sure want to cancel?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/cancelData',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('status-update')
                    })
                }
            })
        }

    }
}
</script>


<style scoped>

</style>
