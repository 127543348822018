<template>
  <div class="popup-form add-country">
    <b-btn v-b-modal.modalPrevent1 class="add-button">+</b-btn>
    <b-modal id="modalPrevent1"
             ref="modal"
             title="Add School"
             @cancel="handleCancel"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"             
             @shown="clear">
      <form @submit.stop.prevent="handleSubmit">
        <div class="container-fluid">  
          <div class="row">
            
            <div class="col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="school_name">School Name<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="school_name"
                        ref="school_name"
                        maxlength="150"
                        placeholder="Enter School Name"
                        v-validate="'required'"
                        @input="updateSchoolName"
                        :value="item.schoolName"
                    >
                    <span class="error" v-show="errors.has('school_name')">
                        {{ $root.updateErrorMessage('school_name',this.errors,'School Name')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="school_board">School Board<p class="astrisk">*</p></label>
                    <v-select
                        name="school_board"
                        label="name"
                        placeholder="Select School Board"
                        v-validate="'required'"
                        @input="updateSchoolBoard"
                        :value="item.schoolBoard"
                        :options="schoolBoardAll"
                    />
                    <span class="error" v-show="errors.has('school_board')">
                        {{ $root.updateErrorMessage('school_board',this.errors,'School Board')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="country">Country<p class="astrisk">*</p></label>
                    <v-select
                        name="country"
                        label="country_name"
                        placeholder="Select Country"
                        v-validate="'required'"
                        @input="updateCountry"
                        :value="item.country"
                        :options="countrymastersAll"
                    />
                    <span class="error" v-show="errors.has('country')">
                        {{ $root.updateErrorMessage('country',this.errors,'Country')}}
                    </span>
                </div>
            </div>
          </div>                   
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      count: 0,
      statesAll: [],
      countryAll: [],
      schoolName: '',
      schoolAddress: '',
      contactNumber: '',
      areaF: '',
      CityName: '',
      pinZipCode: '',
      countryName: '',
      stateName: '',
    }
  },
  computed: {
        ...mapGetters('StudentSingle', ['item', 'loading']),
        ...mapGetters('SchoolMastersSingle', ['item', 'loading', 'countrymastersAll', 'schoolBoardAll'])
       
    },
  created() {
        this.fetchCountrymastersAll()
        this.fetchTypes()
   },    
  methods: {
     ...mapActions('StudentSingle', ['setSchool', 'fetchSchoolAll']),
      ...mapActions('SchoolMastersSingle', ['fetchCountrymastersAll', 'setSchoolName', 'setCountry', 'fetchTypes', 'setSchoolBoard', 'storeData']),
    clear () {
       this.setSchoolName('');
        this.setSchoolBoard('');
        this.setCountry({ 'id': 99, 'country_name': 'India' });
      this.countryName = this.item.country_list;
      this.stateName = this.item.state_list;  

      setTimeout(function () {       
        this.$validator.errors.remove('school_name');      
        this.$validator.errors.remove('school_board');      
        this.$validator.errors.remove('country');      
      }.bind(this), 10);    
    },
    handleCancel(evt){
      this.setSchoolName('');
      this.setSchoolBoard('');
      this.setCountry({ 'id': 99, 'country_name': 'India' });
      setTimeout(function () {             
        this.$validator.errors.remove('school_name');      
        this.$validator.errors.remove('school_board');        
        this.$validator.errors.remove('country'); 
      }.bind(this), 10)      
    },    
    handleOk (evt) {
        evt.preventDefault();
        this.$validator.validate().then(result => {
               if (result) {
                  this.handleSubmit()
                }
                else{
                  return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
    },
    updateSchoolName(e) {
          this.setSchoolName(e.target.value)
      },
      updateSchoolBoard(value) {
          this.setSchoolBoard(value)
      },
      updateCountry(value) {
          this.setCountry(value)
      },
    handleSubmit () {
      this.$validator.validate().then(result => {
        if (result) {
            this.storeData().then((res) => {
                if(!res.data.result)
                {
                    for (const [key, value] of Object.entries(res.data.data)) {
                        this.$validator.errors.add({
                            id: key,
                            field: key,
                            msg: value[0],
                            scope: this.$options.scope,
                        });
                    }
                    this.buttonLoading = false
                    return '';
                }                          
                this.clear()
                this.setSchool(res.data.data);    
                this.fetchSchoolAll();
                this.$refs.modal.hide()                  
            })
            .catch((error) => {
                console.error(error)
            })
        } else {
            return this.$root.handleValidationFocus(this.errors,this.$refs);
        }
    })
    }
  }
}
</script>