function initialState() {
    return {
        
        item: {
            branch_id:null,
            from_date:null,
            to_date:null,
            report_type:2,
        },
        query: {},
        pastQuery: {},
        FollowupQuery: {},
        admissionQuery: {},
        courseEnrollQuery: {},
        courseCompleteQuery: {},
        feeCollectionQuery: {},
        querySummary: {},
        branchMastersAll: [],
        pastInquiryDataAll: [],
        inqFollowupDataAll: [],
        reportsDataAll: [],    
        admissionDataAll: [],    
        courseEnrollDataAll: [],    
        courseCompleteDataAll: [],    
        feeCollectionDataAll: [],    
        branchAll: [],    
        loading: false,
        admissionLoading: false,
        courseEnrollLoading: false,
        courseCompleteLoading: false,
        feeCollectionLoading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,
    inqFollowupDataAll: state => state.inqFollowupDataAll,
    data: (state) => (status) => {
        
        let rows = state.reportsDataAll[status]
        if(!rows){
            rows = []
        }
        let query = state.query[status] ? state.query[status] : {}
        if (query && query.sort) {
            rows = _.orderBy(state.reportsDataAll[status], query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    total: (state) => (status) => {
        let rows = state.reportsDataAll[status]
        if(!rows){
            rows = []
        }
        let query = state.query[status] ? state.query[status] : {}
        if (query && query.sort) {
            rows = _.orderBy(state.reportsDataAll[status], query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },     
    followupInquiryData: (state) => {
        
        let rows = state.inqFollowupDataAll
        if(!rows){
            rows = []
        }
        let query = state.FollowupQuery
        if (query && query.sort) {
            rows = _.orderBy(state.inqFollowupDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','next_followup_date','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    followupInquiryTotal: (state) => {
        let rows = state.inqFollowupDataAll
        if(!rows){
            rows = []
        }
        let query = state.FollowupQuery
        if (query && query.sort) {
            rows = _.orderBy(state.inqFollowupDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','next_followup_date','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    pastInquiryData: (state) => {
        
        let rows = state.pastInquiryDataAll
        if(!rows){
            rows = []
        }
        let query = state.pastQuery
        if (query && query.sort) {
            rows = _.orderBy(state.pastInquiryDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','next_followup_date','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    pastInquiryTotal: (state) => {
        let rows = state.pastInquiryDataAll
        if(!rows){
            rows = []
        }
        let query = state.pastQuery
        if (query && query.sort) {
            rows = _.orderBy(state.pastInquiryDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['inquiry_type','origin_date','origin_format_date','student_name','next_followup_date','contact_name','contact_number'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    admissionData: (state) => {
        
        let rows = state.admissionDataAll
        if(!rows){
            rows = []
        }
        let query = state.admissionQuery
        if (query && query.sort) {
            rows = _.orderBy(state.admissionDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','origin_date','origin_format_date','gender'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    admissionTotal: (state) => {
        let rows = state.admissionDataAll
        if(!rows){
            rows = []
        }
        let query = state.admissionQuery
        if (query && query.sort) {
            rows = _.orderBy(state.admissionDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','origin_date','origin_format_date','gender'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    courseEnrollData: (state) => {
        
        let rows = state.courseEnrollDataAll
        if(!rows){
            rows = []
        }
        let query = state.courseEnrollQuery
        if (query && query.sort) {
            rows = _.orderBy(state.courseEnrollDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['origin_date','origin_format_date','display_course_name','student_name','gender','TotalFees'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    courseEnrollTotal: (state) => {
        let rows = state.courseEnrollDataAll
        if(!rows){
            rows = []
        }
        let query = state.courseEnrollQuery
        if (query && query.sort) {
            rows = _.orderBy(state.courseEnrollDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['origin_date','origin_format_date','display_course_name','student_name','gender','TotalFees'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    courseCompleteData: (state) => {
        
        let rows = state.courseCompleteDataAll
        if(!rows){
            rows = []
        }
        let query = state.courseCompleteQuery
        if (query && query.sort) {
            rows = _.orderBy(state.courseCompleteDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['course_completed_date','display_course_name','student_name','gender'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    courseCompleteTotal: (state) => {
        let rows = state.courseCompleteDataAll
        if(!rows){
            rows = []
        }
        let query = state.courseCompleteQuery
        if (query && query.sort) {
            rows = _.orderBy(state.courseCompleteDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['origin_date','origin_format_date','display_course_name','student_name','gender','TotalFees'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    feeCollectionData: (state) => {
        
        let rows = state.feeCollectionDataAll
        if(!rows){
            rows = []
        }
        let query = state.feeCollectionQuery
        if (query && query.sort) {
            rows = _.orderBy(state.feeCollectionDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['origin_date','origin_format_date','student_name','manual_receipt_no','receipt_amount','receipt_type'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(query.offset, query.offset + query.limit)
    },
    feeCollectionTotal: (state) => {
        let rows = state.feeCollectionDataAll
        if(!rows){
            rows = []
        }
        let query = state.feeCollectionQuery
        if (query && query.sort) {
            rows = _.orderBy(state.feeCollectionDataAll, query.sort, query.order)
        }
        var queryFilter = JSON.stringify(query);
        var queryFilter = JSON.parse(queryFilter);
           ['origin_date','origin_format_date','student_name','manual_receipt_no','receipt_amount','receipt_type'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
     
    pastInquiryDataAll: state => state.pastInquiryDataAll,
    loading: state => state.loading,
    admissionLoading: state => state.admissionLoading,
    courseEnrollLoading: state => state.courseEnrollLoading,
    courseCompleteLoading: state => state.courseCompleteLoading,
    feeCollectionLoading: state => state.feeCollectionLoading,
    branchMastersAll: state => state.branchMastersAll,
    reportsDataAll: state => state.reportsDataAll,
    admissionDataAll: state => state.admissionDataAll,
    courseEnrollDataAll: state => state.courseEnrollDataAll,
    courseCompleteDataAll: state => state.courseCompleteDataAll,
    feeCollectionDataAll: state => state.feeCollectionDataAll,
    error: state => state.error
}

const actions = {
    fetchReportData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/admission-digest-report', {params:params})
                .then(response => {
                    commit('setAdmissionDigestReportData', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchFollowupInqData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/inquiry-followup-data', {params:params})
                .then(response => {
                    commit('setInquiryFollowupData', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    fetchPastInquiryData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/past-inquiry-data', {params:params})
                .then(response => {
                    commit('setPastInquiryData', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },        
    fetchAdmissionData({ commit, state, dispatch }) {
        commit('setAdmissionLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/get-admissions', {params:params})
                .then(response => {
                    commit('setAdmissionDataAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setAdmissionLoading', false)
                })
        })
    },    
    fetchCourseEnrollData({ commit, state, dispatch }) {
        commit('setCourseEnrollLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/get-course-enrolled', {params:params})
                .then(response => {
                    commit('setCourseEnrollDataAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setCourseEnrollLoading', false)
                })
        })
    },    
    fetchCourseCompleteData({ commit, state, dispatch }) {
        commit('setCourseCompleteLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/get-course-completed', {params:params})
                .then(response => {
                    commit('setCourseCompleteDataAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setCourseCompleteLoading', false)
                })
        })
    },    
    fetchFeeCollectionData({ commit, state, dispatch }) {
        commit('setFeeCollectionLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = {};

            if (_.isEmpty(state.item.branch_id)) {
                params.branch_id = ''
            } else {
                params.branch_id = state.item.branch_id.id
            }
            
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            
            axios.get('/api/v1/get-fee-collection', {params:params})
                .then(response => {
                    commit('setFeeCollectionDataAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setFeeCollectionLoading', false)
                })
        })
    },    
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })      
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setAdmissionQuery({ commit }, value) {
        commit('setAdmissionQuery', purify(value))
    },
    setPastQuery({ commit }, value) {
        commit('setPastQuery', purify(value))
    },    
    setFollowupQuery({ commit }, value) {
        commit('setFollowupQuery', purify(value))
    },        
    setInquiryFollowupData({ commit }, value) {
        commit('setInquiryFollowupData', purify(value))
    },        
    setCourseEnrollQuery({ commit }, value) {
        commit('setCourseEnrollQuery', purify(value))
    },
    setCourseCompleteQuery({ commit }, value) {
        commit('setCourseCompleteQuery', purify(value))
    },
    setFeeCollectionQuery({ commit }, value) {
        commit('setFeeCollectionQuery', purify(value))
    },
    setQuerySummary({ commit }, value) {
        commit('setQuerySummary', purify(value))
    },     
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setAdmissionDigestReportData({ commit }, value) {
        commit('setAdmissionDigestReportData', value)
    }, 
    setPastInquiryData({ commit }, value) {
        commit('setPastInquiryData', value)
    },        
    setAdmissionDataAll({ commit }, value) {
        commit('setAdmissionDataAll', value)
    },    
    setCourseEnrollDataAll({ commit }, value) {
        commit('setCourseEnrollDataAll', value)
    },    
    setCourseCompleteDataAll({ commit }, value) {
        commit('setCourseCompleteDataAll', value)
    },    
    setFeeCollectionDataAll({ commit }, value) {
        commit('setFeeCollectionDataAll', value)
    },    
    setFromDate({ commit }, value) {
        commit('setFromDate', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },    
    setReportType({ commit }, value) {
        commit('setReportType', value)
    }, 
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {  
    setLoading(state, loading) {
        state.loading = loading
    },
    setAdmissionLoading(state, loading) {
        state.admissionLoading = loading
    },
    setCourseEnrollLoading(state, loading) {
        state.courseEnrollLoading = loading
    },
    setCourseCompleteLoading(state, loading) {
        state.courseCompleteLoading = loading
    },
    setFeeCollectionLoading(state, loading) {
        state.feeCollectionLoading = loading
    },
    setQuery(state, query) {
        Vue.set(state.query, query.currentStatus, query)
    },    
    setAdmissionQuery(state, query) {
        state.admissionQuery = query
    },
    setCourseEnrollQuery(state, query) {
        state.courseEnrollQuery = query
    },
    setPastQuery(state, query) {
        state.pastQuery = query
    },
    setFollowupQuery(state, query) {
        state.FollowupQuery = query
    },    
    setCourseCompleteQuery(state, query) {
        state.courseCompleteQuery = query
    },
    setFeeCollectionQuery(state, query) {
        state.feeCollectionQuery = query
    },
    setQuerySummary(state, querySummary) {
        state.querySummary = querySummary
    },
    setError(state, error) {
        state.error = error
    },
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    }, 
    setPastInquiryData(state, value) {
        state.pastInquiryDataAll = value
    },      
    setInquiryFollowupData(state, value) {
        state.inqFollowupDataAll = value
    },          
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setFromDate(state, value) {
        state.item.from_date = value
    },
    setExit_date(state, value) {
        state.item.to_date = value
    },
    setReportType(state, value) {
        state.item.report_type = value
    },         
    setAdmissionDigestReportData(state, value) {
        state.reportsDataAll = value
    }, 
    setAdmissionDataAll(state, value) {
        state.admissionDataAll = value
    }, 
    setCourseEnrollDataAll(state, value) {
        state.courseEnrollDataAll = value
    }, 
    setCourseCompleteDataAll(state, value) {
        state.courseCompleteDataAll = value
    }, 
    setFeeCollectionDataAll(state, value) {
        state.feeCollectionDataAll = value
    }, 
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
