<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
             <div class="col-md-12 col-xs-12 row">
                <label for="project_name" class="col-md-4">Completed Project Name :</label> <span class="col-md-8">{{completeProjectDetails.project.project_name}}</span>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="attendance_date" class="form-group">
                    <label for="attendance_date">Date<p class="astrisk">*</p></label>
                   <date-picker                                            
                        :config="$root.dpconfigDatePast"
                        name="attendance_date"
                        v-model="selected_date"
                        v-validate="'required'"
                        placeholder="Select Date"
                        disabled
                        autocomplete="off"
                        ></date-picker>
                    <span class="error" v-show="errors.has('attendance_date')">
                        {{ $root.updateErrorMessage('attendance_date',this.errors,'Attendance Date')}}
                    </span>
                </div>
            </div>
             <div class="form-group col-md-12 col-xs-12">
                <label for="project">Assign Next Pending Project</label>
                <v-select
                    name="project"
                    label="project_name"
                    value="id"
                    placeholder="Select Project"
                    v-model="selectedProject"
                    :options="completeProjectDetails.pendingProject"
                    />
                <span class="error" v-show="errors.has('project')">
                    {{ $root.updateErrorMessage('project',this.errors,'Assign next project')}}
                </span>                                            
            </div>  
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    props:['id','scheduleManualProjectId'],
    components: { CommonMasterPopup },
    data() {
        return {
            selectedProject:'',
            selected_date:moment().format("DD/MM/YYYY")
        }
    },
    computed: {
        ...mapGetters('StudentAttendanceIndex', ['loading','completeProjectDetails','item']),
       
    },
    created() {
        this.fetchManualCompleteProjectDetails({'date':this.selected_date,'schedule_project_id':this.scheduleManualProjectId});
        // this.fetchPendingProjectList()
    },
    watch:{
        
    },
    mounted() {
       
    },
    methods: {
        ...mapActions('StudentAttendanceIndex', ['storeManualCompleteProjectData','fetchManualCompleteProjectDetails']),
        closeModel(){
            this.$emit('closeModel');
        },
        submitForm () {
            this.$validator.validate().then(result => {
                
                if (result) {
                    let data = {
                        'attendance_id':this.id,
                        'id': this.scheduleManualProjectId,
                        'selectedProject' : this.selectedProject,
                        'selectDate':this.selected_date
                    };
                    
                    this.storeManualCompleteProjectData(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        if(res.data.data.length > 0){
                             for (const [key, value] of Object.entries(res.data.data)) {
                                // console.log("ke",key,value);
                                if( value !== null ) {
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                            }
                            return '';    
                        }
                        else{
                            this.$eventHub.$emit('common',3,res.data.message)
                            return false;
                        }
                       
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>