function initialState() {
    return {
        all: [],
        item: {
            search_employee_name: null,
        },
        relationships: {
            'leave_type': 'name',
            'leave_option': 'name',
            'leave_status': 'name',
        },
        query: {},
        queryBal: {},
        employeeAll:[],
        leaveTypeAll:[],
        allocateLeave:[],
        loading: false,
        loadingBal: false,
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['username','original_date', 'original_format_date','original_date_a', 'original_format_date_a', 'start_time', 'end_time','days','leaveType','leaveOption','leaveStatus','name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
          ['username','original_date', 'original_format_date','original_date_a', 'original_format_date_a', 'start_time', 'end_time','days','leaveType','leaveOption','leaveStatus','name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
        return rows.length;
    },
    dataBal: state => {
        let rows = state.allocateLeave

        if (state.queryBal.sort) {
            rows = _.orderBy(state.allocateLeave, state.queryBal.sort, state.queryBal.order)
        }
        var queryFilter = JSON.stringify(state.queryBal);
        var queryFilter = JSON.parse(queryFilter);
          ['employee_name','leaveType','total','applied','balance'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
        return rows.slice(state.queryBal.offset, state.queryBal.offset + state.queryBal.limit)
    },
    totalBal: state => {
        let rows = state.allocateLeave

        if (state.queryBal.sort) {
            rows = _.orderBy(state.allocateLeave, state.queryBal.sort, state.queryBal.order)
        }
        var queryFilter = JSON.stringify(state.queryBal);
        var queryFilter = JSON.parse(queryFilter);
          ['employee_name','leaveType','total','applied','balance'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          })
        return rows.length;
    },    
    item: state => state.item,
    employeeAll: state => state.employeeAll, 
    leaveTypeAll: state => state.leaveTypeAll, 
    allocateLeave: state => state.allocateLeave, 
    loading: state => state.loading,
    loadingBal: state => state.loadingBal,
    relationships: state => state.relationships
}

const actions = {
    async fetchData({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        await axios.post('/api/v1/fetchingLeaveReportData',params)
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async fetchAllocateLeaveAll({ commit, dispatch }, params) {
        commit('setLoadingBal', true)
        await axios.post('/api/v1/fetchLeaveBalanceReport', params)
            .then(response => {
                commit('setAllocateLeave', response.data.data)
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            }) 
            .finally(() => {
                commit('setLoadingBal', false)
            })            
    },    
    fetchEmployeeAll({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/allAndCurrentEmployee')
            .then(response => {
                commit('setEmployeeAll', response.data.data)
                resolve(response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
      }) 
    },    
    async fetchLeaveTypeAll({ commit }) {
        await axios.get('/api/v1/leaveTypeList')
            .then(response => {
                commit('setLeaveTypeAll', response.data.data)
            })
            .catch(function (error) {
                  console.log('Error', error.message);
            }) 
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setBalQuery({ commit }, value) {
        commit('setBalQuery', purify(value))
    },    
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingBal(state, loadingBal) {
        state.loadingBal = loadingBal
    },    
    setLeaveTypeAll(state, value) {
        state.leaveTypeAll = value
    },    
    setAllocateLeave(state, value) {
       state.allocateLeave = value
    },    
    setEmployeeAll(state, value) {
        state.employeeAll = value
    },
    setQuery(state, query) {
        state.query = query
    },
    setBalQuery(state, queryBal) {
        state.queryBal = queryBal
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
