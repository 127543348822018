<template>
    <div class='topicModal'>
        <div class="popup-form">
            <div v-for="(value, index) in row.topicNames" >
            <div style="display: inline-block;">
                <span class="label label-primary" title="Topic In Progress" v-if="row.topicStatus[index] == 0">{{value}}&nbsp;&nbsp;</span>
                <span class="label label-warning" title="Topic Extended" v-else-if="row.topicStatus[index] == 2">{{value}}&nbsp;&nbsp;</span>
                <span class="label label-success" title="Topic Completed" v-else="row.topicStatus[index] == 1">{{value}}&nbsp;&nbsp;</span>
                <span>&nbsp;</span>
            </div><br>
            </div>
            <span v-if="row.topicNames && row.assessment_flag == 1" style="display: inline-block;" class="student-name" @click="getData"  title="Edit Topic">
            <i class="fa fa-edit link" aria-hidden="true"></i>
            </span>
            <span v-else-if="!row.topicNames && row.assessment_flag == 1" style="display: inline-block;" class="student-name" @click="getData"  title="Add Topic">
            <i class="fa fa-plus link" aria-hidden="true"></i>
            </span>      
            <b-modal id="modalPrevent"
                ref="modal"
                :title="`${row.student_name}`"
                ok-only
                @ok="handleOk"
                okTitle="Close"
                okVariant="primary"
                no-close-on-backdrop
                no-close-on-esc
                centered
                hide-header-close
                >
                <!--@shown="clear"-->
                <div class="container-fluid topic-modal-class">
                    <b> Course : {{row.course_name}} </b>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <form @submit.stop.prevent="submitForm">
                                <div class="col-md-10 col-md-offset-1 col-xs-12">
                                    <!-- <label for="courses_name">Topics<p class="astrisk">*</p></label> -->
                                    <div v-if="!topicDescriptionSeen" ref="courses_name" class="form-group">
                                        <v-select
                                            name="courses_name"
                                            label="topic_name"
                                            id="course"
                                            v-validate="'required'"
                                            :value="topic"
                                            @input="updateTopics"
                                            :options="topicsAll"
                                            />
                                        <span class="error" v-show="errors.has('courses_name')">
                                        {{ $root.updateErrorMessage('courses_name',this.errors,'Topic')}}
                                        </span>
                                    </div>
                                    <div v-else ref="topic_description" class="form-group">
                                        <textarea
                                            rows="2"
                                            class="form-control"
                                            v-validate="'required|max:1000'"
                                            name="topic_description"
                                            placeholder="Enter Topic Description"
                                            v-model="topic"
                                            >
                                        </textarea>
                                        <span class="error" v-show="errors.has('topic_description')">
                                        {{ $root.updateErrorMessage('topic_description',this.errors,'Topic Description')}}
                                        </span>
                                    </div><br>
                                </div>
                                <div v-if="!topicDescriptionSeen" class="col-md-10 col-md-offset-1 col-xs-12 savebtn">
                                    <vue-button-spinner
                                        class="std_information_save btn btn-block btn-sm btn-success new_branch_save"
                                        :isLoading="loading"
                                        :disabled="loading"
                                        >
                                        Add Topic
                                    </vue-button-spinner>
                                </div>
                                <div v-else class="col-md-10 col-md-offset-1 col-xs-12 savebtn">
                                    <vue-button-spinner
                                        class="std_information_save btn btn-block btn-sm btn-success new_branch_save"
                                        :isLoading="loading"
                                        :disabled="loading"
                                        >
                                        Add Topic Description
                                    </vue-button-spinner>
                                </div>
                            </form>
                        </div>
                        <div class="clearfix"></div>
                        <br>
                        <div class="col-md-10 col-md-offset-1">
                            <div class="tableFixHead">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Topic/Task</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="loadingSavedData">
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <i class="fa fa-spin fa-refresh"></i> Loading
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr v-else :class="{'info': value.topic_description}" v-for="(value, index) in savedTopicData">
                                            <td>{{value.sr_no}})</td>
                                            <td>{{value.topic_name ? value.topic_name : value.topic_description}}</td>
                                            <td>{{value.topic_status}}</td>
                                            <td>
                                                <button
                                                    @click="destroyData(value.course_topic_id)"
                                                    type="button"
                                                    title="Delete Topic"
                                                    class="btn btn-sm btn-danger">
                                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    // import { EventBus } from "../../../app";
    export default {
      props:['row'],
      data () {
        return {
          ifDescription: false,
          savedTopicData: [],
          topicDescriptionSeen: false,
          topicsAll: [],
          topic: null,
          loading: false,
          loadingSavedData: false
        }
      }, 
      // mounted() {
      //   console.log('row',this.row)
        // this.fetchCourseType(this.row.course_id)
      // },
      methods: {
            ...mapActions('StudentAssessmentSingle', ['setLoadingSheduleDetail','setBatchSheduleDetailAll','setComputedAssessmentData','setSchedule_date']),    
        resetData() {
          // this.ifDescription = false,
          this.savedTopicData = [],
          this.topicsAll = [],
          this.topic = null,
          this.loading = false,
          this.loadingSavedData = false      
        },
        updateTopics(value) {
            this.topic = value
        },    
        submitForm() {
            this.$validator.validate().then(result => {
                 if (result) {
                    this.loading = true
                    let data = {
                      student_attendance_id : this.row.student_attendance_id,
                      student_id : this.row.student_id,
                      course_id : this.row.course_id,
                      course_topic_id : this.topic && this.topic.id ? this.topic.id : this.topic,
                      is_assessment_required : this.row.is_assessment_required,
                      // topic_description : this.row.topic_description
                    };
                    axios.post('/api/v1/saveStudentTopicStatusData', data)
                        .then(response => {                
                            // this.topicsAll = response.data.data;
                        })
                        .catch(function (error) {
                            console.log('Error', error.message);
                        })
                        .finally(() => {
                            this.resetData()
                            this.$validator.reset();                                                   
                            this.getData()                        
                        })
          } else {
              // return this.$root.handleValidationFocus(this.errors,this.$refs);
          }
        });      
        },
        handleCancel(evt){
           this.resetData()
           this.$refs.modal.hide()
        },    
        handleOk (evt) {  
            this.resetData()
            this.$refs.modal.hide()
            var dataObj = {
                schedule_date: this.row.schedule_date,
                studentBatchId: this.row.student_batch_id,
                studentLabId: this.row.student_lab_id,
            }
            // EventBus.$emit('updateFromEventBus', dataOj);
        this.setLoadingSheduleDetail(true)
        this.setSchedule_date(dataObj.schedule_date)
        axios.get('/api/v1/getBatchSheduleDetailAssessment/'+ dataObj.studentBatchId+'/'+ dataObj.schedule_date+'/'+ dataObj.studentLabId)
            .then(response => {
                this.settingBatchAllData(dataObj)
                this.setComputedAssessmentData(response.data.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                    })     
            .finally(() => {
                // this.setLoadingSheduleDetail(false)
            })       
        },
        settingBatchAllData(dataObj) {        
            axios.get('/api/v1/getBatchSheduleDetailAssessment/'+ dataObj.studentBatchId+'/'+ dataObj.schedule_date+'/'+ "0")
                .then(response => {
                    this.setBatchSheduleDetailAll(response.data.data)
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                        })     
                .finally(() => {
                    this.setLoadingSheduleDetail(false)
                })                        
        },
        handleSubmit () {
          this.$refs.modal.hide()
        },
        fetchSavedTopicData() {
            this.loadingSavedData = true
            let data = {
              course_id:this.row.course_id,
              student_id:this.row.student_id,
              student_attendance_id:this.row.student_attendance_id,
            };      
            axios.post('/api/v1/fetchSavedTopicData', data)
                .then(response => {                
                    this.savedTopicData = response.data.data;
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })    
                .finally(() => {
                    this.loadingSavedData = false
                })             
        }, 
        fetchAvailableTopics(data) {
            axios.post('/api/v1/availableTopicAssessment', data)
                .then(response => {                
                    this.topicsAll = response.data.data;
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
        },
        destroyData(data) {
            let dataObj = {
              student_attendance_id:this.row.student_attendance_id,
              course_id:this.row.course_id,
              student_id:this.row.student_id,
              course_topic_id: data
            };        
            axios.post('/api/v1/deleteSavedStudentTopicStatus', dataObj)
                .then(() => {                
                    // this.getData();
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })  
                .finally(() => {
                    this.resetData()
                    this.getData()                        
                })               
        },        
        getData(){
          let data = {
            course_id:this.row.course_id,
            student_id:this.row.student_id,
            start_date:this.row.schedule_date,
            student_attendance_id:this.row.student_attendance_id,
          };
          // this.fetchCourseType(this.row.course_id)
        axios.post('/api/v1/checkIfTopicDescriptionSeen', data)
            .then(response => {
                this.topicDescriptionSeen = response.data.data[0]
            })
            .catch(function (error) {
                console.log('Error', error.message);
                    })     
            .finally(() => {
                // this.setLoadingSheduleDetail(false)
            })

          this.fetchSavedTopicData()
          this.fetchAvailableTopics(data)
          this.$refs.modal.show()
        },
        handleSubmit () {
          this.clear();
          this.$refs.modal.hide()
        }    
      }
    }
</script>
<style scoped>
    .link {
    color: #3c8dbc;
    }
    .tableFixHead { 
    overflow-y: auto; 
    height: 220px;      
    }
    .tableFixHead thead th { 
    background:#eee;
    position: sticky; 
    top: 0;
    }
    .student-name {
    cursor: pointer;
    color:blue;
    }
</style>