<template>
    <div>
      <topics :row='rowFromParent'></topics>
    </div>
</template>

<script>
import Topics from './PopupActions/StudentAssessmentTopicPoup'
export default {
    components: { Topics },
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            rowFromParent:this.row,
        }
    },
}
</script>
