<template> 
    <div class="header-card">
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
                <div class="col-md-12">
                    <bootstrap-alert />
                </div>
                <div class="form-group col-md-12 col-xs-12">
                    <label for="header_name">Header Name<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="header_name"
                        ref="header_name"
                        maxlength="150"
                        v-validate="'required'"
                        placeholder="Enter Header Name"
                        v-model="form_data.header_name"
                    >
                    <span class="error" v-show="errors.has('header_name')">
                        {{ $root.updateErrorMessage('header_name',this.errors,'Header Name')}}
                    </span>
                </div>
                <div class="col-md-12 col-md-12 savebtn">
                    <vue-button-spinner
                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                        :isLoading="loading"
                        :disabled="loading"
                    >
                        Save
                    </vue-button-spinner>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            form_data: {
                id: '',
                header_name: ''
            }
        }
    },
    computed: {
        ...mapGetters('SkillHeaderMasterSingle', ['headerDetail', 'loading']),
    },
    created() {
        console.log('');
        // this.fetchTypes();
        this.fetchEditData(this.$route.params.id);
    },
    watch: {
        "headerDetail":function(value) {
            this.form_data.id = value.id;
            this.form_data.header_name = value.header_name; 
        },
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
            // this.getListing(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('SkillHeaderMasterSingle', ['fetchEditData', 'updateData','fetchTypes']),
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.updateData(this.form_data)
                    .then((res) => {
                        if(!res.data.result)
                        {
                             for (const [key, value] of Object.entries(res.data.data)) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                })
                            }
                            this.buttonLoading = false
                            return ''
                        }
                        this.$router.push({ name: 'skill-master.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                } else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
