function initialState() {
    return {
        item: {
            id: null,
            item_category_id: null,
            item_id: null,
            sell_date: null,
            quantity: null,
            rate: 0,
            total_amount: 0,
            file: null,
            remarks: null,
            sell_to: null,
            sell_to_id: null,
            other: null,
            balance:0,
        },
        categoriesAll: [],
        itemsAll: [],
        sellToAll: [],
        sellToIdAll: [],
        sell_details: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    categoriesAll: state => state.categoriesAll,
    itemsAll: state => state.itemsAll,
    sellToAll: state => state.sellToAll,
    sellToIdAll: state => state.sellToIdAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.sell_details) {
                let fieldValue = state.sell_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item-sell', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.sell_details) {
                let fieldValue = state.sell_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/inventory-item-sell/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                    // resolve(response.data.data)


                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/inventory-item-sell/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
    },
    fetchCategoriesAll({ commit }) {
        axios.get('/api/v1/inventory-categories')
            .then(response => {
                commit('setCategoriesAll', response.data.data)
            })
    },
    fetchItemsAll({ commit }, obj) {
        axios.get(`/api/v1/inventory-category-items/${obj.id}/${obj.all}`)
            .then(response => {
                commit('setItemsAll', response.data.data)
            })
    },
    fetchSellToAll({ commit }) {
        axios.get(`/api/v1/inventory-sellto/`)
            .then(response => {
                commit('setSellToAll', response.data.data)
            })
    },
    fetchSellToBranch({ commit }) {
        axios.get(`/api/v1/inventory-branch/`)
            .then(response => {
                commit('setSellToIdAll', response.data.data)
            })
    },
    fetchSellToEmployee({ commit }) {
        axios.get(`/api/v1/inventory-employee/`)
            .then(response => {
                commit('setSellToIdAll', response.data.data)
            })
    },
    fetchSellToStudent({ commit }) {
        axios.get(`/api/v1/inventory-student/`)
            .then(response => {
                commit('setSellToIdAll', response.data.data)
            })
    },
    setItemCategoryId({ commit }, value) {
        commit('setItemCategoryId', value)
    },
    setItemId({ commit }, value) {
        commit('setItemId', value)
    },
    setBalance({ commit }, value) {
        commit('setBalance', value)
    },
    setQuantity({ commit }, value) {
        commit('setQuantity', value)
    },
    setSellTo({ commit }, value) {
        commit('setSellTo', value)
    },
    setSellToId({ commit }, value) {
        commit('setSellToId', value)
    },
    setSellToIdAll({ commit }, value) {
        commit('setSellToIdAll', value)
    },
    setFile({ commit }, value) {
        commit('setFile', value)
    },
    setItemsAll({ commit }, value) {
        commit('setItemsAll', value)
    },
    setSellDetails({ commit }, value) {
        commit('setSellDetails', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        item.item_category_id = {
            name:item.item_category_name,
            value_id:item.item_category_id
        };
        item.item_id = {
            name:item.item_name,
            id:item.item_id,
            balance:item.balance
        };
        item.sell_to = {
            name:item.sell_to_name,
            value_id:item.sell_to
        };
        item.sell_to_id_on_load = {
            name:item.sell_to_id_name,
            id:item.sell_to_id
        };
        item.sell_to_id = {
            name:item.sell_to_id_name,
            id:item.sell_to_id
        };
        item.quantity_to_check = item.quantity;
        state.item = item
    },
    setItemCategoryId(state, value) {
        state.item.item_category_id = value
    },
    setItemId(state, value) {
        state.item.item_id = value
    },
    setBalance(state, value) {
        state.item.balance = value
    },
    setQuantity(state, value) {
        state.item.quantity = value
    },
    setCategoriesAll(state, value) {
        state.categoriesAll = value
    },
    setSellTo(state, value) {
        state.item.sell_to = value
    },
    setSellToId(state, value) {
        state.item.sell_to_id = value
    },
    setSellToAll(state, value) {
        state.sellToAll = value
    },
    setSellToIdAll(state, value) {
        state.sellToIdAll = value
    },
    setItemsAll(state, value) {
        state.itemsAll = value
    },
    setFile(state, value) {
        state.item.file = value
    },
    setSellDetails(state, value) {
        state.sell_details = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
