<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branch Lab</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branch-lab'">Branch Lab</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Branch Lab</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Branch Lab</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="box">
                                        <bootstrap-alert />
                                        <div class="box-body">
                                            <div class="col-md-12 row">
                                                <div class="col-md-6">
                                                    <div ref="branch" class="form-group">
                                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                                        <v-select
                                                                name="branch"
                                                                label="branch_name"
                                                                v-validate="'required'"
                                                                @input="updateBranch"
                                                                :value="item.branch_id"
                                                                :options="branchMastersAll"
                                                                />
                                                        <span class="error" v-show="errors.has('branch')">
                                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div ref="lab_name" class="form-group">
                                                        <label for="lab_name">Lab Name<p class="astrisk">*</p></label>
                                                        <input
                                                                type="text"
                                                                class="form-control"
                                                                name="lab_name"
                                                                ref="lab_name"
                                                                v-validate="'required'"
                                                                maxlength="200"
                                                                placeholder="Enter Lab Name"
                                                                :value="item.lab_name"
                                                                @input="updateLabName"
                                                                >
                                                        <span class="error" v-show="errors.has('lab_name')">
                                                            {{ $root.updateErrorMessage('lab_name',this.errors,'Lab Name')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="col-md-12 row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="seat_limit">Seat Limit<p class="astrisk">*</p></label>
                                                        <input
                                                                type="text"
                                                                class="form-control"
                                                                name="seat_limit"
                                                                ref="seat_limit"
                                                                maxlength="8"
                                                                v-validate="'required|numeric|min_value:0.01'"
                                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                                placeholder="Enter Seat Limit"
                                                                :value="item.seat_limit"
                                                                @input="updateSeatLimit"
                                                                >
                                                        <span class="error" v-show="errors.has('seat_limit')">
                                                            {{ $root.updateErrorMessage('seat_limit',this.errors,'Seat Limit','a valid value')}}
                                                        </span>
                                                    </div>                                   
                                                </div>                                                                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                       
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('BranchLabSingle', ['item', 'loading','branchMastersAll','fullCoursesAll']),
       
    },
    created() {
        this.fetchBranchMasterAll()
        this.fetchCoursesAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        
        ...mapActions('BranchLabSingle', ['storeData', 'resetState', 'setBranch', 'setLabName', 'setSeatLimit','setFullCourse','fetchBranchMasterAll', 'fetchCoursesAll']),
      
        updateBranch(value) {
            this.setBranch(value)
        },
        updateLabName(e) {
            this.setLabName(e.target.value)
        },
        updateFullCourses(value) {            
            this.setFullCourse(value)
        },
       updateSeatLimit(e) {
            this.setSeatLimit(e.target.value)
       },                
        submitForm() {
        this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'lab_name',
                            field: 'lab_name',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'branch_lab.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });
        }
    }
}
</script>


<style scoped>

</style>
 