function initialState() {
    return {
        item: {
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            branch_id: null,
            // common_url: null,
            branch_name: null,
            encoded_url: null,
            show_age:false,
            show_standard:false
        },
        // editData:[],
        utmSourceAll: [],
        utmMediumAll: [],
        utmCampaignAll: [],
        branchAll: [],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    utmSourceAll: state => state.utmSourceAll,
    utmMediumAll: state => state.utmMediumAll,
    utmCampaignAll: state => state.utmCampaignAll,
    branchAll: state => state.branchAll,
    loading: state => state.loading,
    // getEditData: state => state.editData
}

const actions = {
    fetchSources({ commit, dispatch }) {
        axios.get('/api/v1/referenceTypeList').then(response => {
            commit('setUtmSourceAll', response.data.data) 
        }).catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })       
    },

    fetchMedium({ commit, dispatch }) {
        axios.get('/api/v1/getCommonMasterType/UTM%20medium').then(response => {
            commit('setUtmMediumAll', response.data.data) 
        }).catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })       
    },

    fetchCampaign({ commit, dispatch }) {
        axios.get('/api/v1/getCommonMasterType/UTM%20campaign').then(response => {
            commit('setUtmCampaignAll', response.data.data) 
        }).catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })       
    },

    fetchBranch({ commit, dispatch }) {
        axios.get('/api/v1/allBranch').then(response => {
            commit('setBranchAll', response.data.data) 
        }).catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
        })       
    },

    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.utm_source)) {
                params.set('utm_source', '0')
            } else {
                params.set('utm_source', state.item.utm_source.value_id)
            }
            
            if (_.isEmpty(state.item.utm_medium)) {
                params.set('utm_medium', '0')
            } else {
                params.set('utm_medium', state.item.utm_medium.value_id)
            }

            if (_.isEmpty(state.item.utm_campaign)) {
                params.set('utm_campaign', '0')
            } else {
                params.set('utm_campaign', state.item.utm_campaign.value_id)
            }

            if (_.isEmpty(state.item.branch_id)) {
                params.set('branch_id', '0')
            } else {
                params.set('branch_id', state.item.branch_id.id)
            }

            params.set('show_age', state.item.show_age);
            params.set('show_standard', state.item.show_standard);

            // if (_.isEmpty(state.item.common_url)) {
            //     params.set('common_url', '')
            // } else {
            //     params.set('common_url', state.item.common_url)
            // }

            axiosLms.post('/api/v1/common_inquiry/store', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },

    fetchSingleData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/common_inquiry/show/' + id)
        .then(response => {
            commit('setItem', response.data.data)
            commit('setLoading', false)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
            commit('setLoading', false)
        })
    },

    fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        axiosLms.get('/api/v1/common_inquiry/edit/' + id)
        .then(response => {
            commit('setItem', response.data.data)
            commit('setLoading', false)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
            commit('setLoading', false)
        })
    },

    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.utm_source)) {
                params.set('utm_source', '0')
            } else {
                params.set('utm_source', state.item.utm_source.value_id)
            }
            
            if (_.isEmpty(state.item.utm_medium)) {
                params.set('utm_medium', '0')
            } else {
                params.set('utm_medium', state.item.utm_medium.value_id)
            }

            if (_.isEmpty(state.item.utm_campaign)) {
                params.set('utm_campaign', '0')
            } else {
                params.set('utm_campaign', state.item.utm_campaign.value_id)
            }

            if (_.isEmpty(state.item.branch_id)) {
                params.set('branch_id', '0')
            } else {
                params.set('branch_id', state.item.branch_id.id)
            }

            params.set('show_age', state.item.show_age);
            params.set('show_standard', state.item.show_standard);

            // if (_.isEmpty(state.item.common_url)) {
            //     params.set('common_url', '')
            // } else {
            //     params.set('common_url', state.item.common_url)
            // }

            axiosLms.post('/api/v1/common_inquiry/update/' + state.item.id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },

    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/common_inquiry/delete/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },

    setUtmSourceAll({ commit }, value) {
        commit('setUtmSourceAll', value)
    },
    setUtmMediumAll({ commit }, value) {
        commit('setUtmMediumAll', value)
    },
    setUtmCampaignAll({ commit }, value) {
        commit('setUtmCampaignAll', value)
    },
    setBranchAll({ commit }, value) {
        commit('setBranchAll', value)
    },
    setUtmSource({ commit }, value) {
        commit('setUtmSource', value)
    },
    setUtmMedium({ commit }, value) {
        commit('setUtmMedium', value)
    },
    setUtmCampaign({ commit }, value) {
        commit('setUtmCampaign', value)
    },
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setUrl({ commit }, value) {
        commit('setUrl', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setUtmSourceAll(state, value) {
        state.utmSourceAll = value
    },
    setUtmMediumAll(state, value) {
        state.utmMediumAll = value
    },
    setUtmCampaignAll(state, value) {
        state.utmCampaignAll = value
    },
    setBranchAll(state, value) {
        state.branchAll = value
    },
    setUtmSource(state, value) {
        state.item.utm_source = value
    },
    setUtmMedium(state, value) {
        state.item.utm_medium = value
    },
    setUtmCampaign(state, value) {
        state.item.utm_campaign = value
    },
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setUrl(state, value) {
        state.item.common_url = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}