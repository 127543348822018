function initialState() {
    return {
        studentAll: [],
        courseAll: [],
        listingAll: [],
        relationships: {},
        employeeAll: [],
        item: {
            course: null,
            student: null,
            joining_date: null,
            exit_date: null,
            employee_id: null,
        },        
        query: {},
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,    
    data: state => {
        let rows = state.listingAll
        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['date','day','course_name','batch_start_time_end_time','lab_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.listingAll

        if (state.query.sort) {
            rows = _.orderBy(state.listingAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['date','day','course_name','batch','start_time','end_time'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    loading: state => state.loading,
    courseAll: state => state.courseAll,
    listingAll: state => state.listingAll,
    studentAll: state => state.studentAll,
    employeeAll: state => state.employeeAll,
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    fetchData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        axios.get('/api/v1/students_list')
            .then(response => {
                commit('setAll',  response.data.data)
            })         
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
getScheduleDetails({ commit, state, dispatch }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();

            params.set('p_emp_id', state.item.employee_id.id);
            params.set('p_start_date', state.item.joining_date);
            params.set('p_end_date', state.item.exit_date);
            params.set('p_batch_id', '0');
            params.set('p_course_id', '0');
            params.set('p_student_id', '0');
           
            axios.post('/api/v1/theScheduleListingData', params)
                .then(response => {
                    commit('setListingAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    fetchStudentCourseAll({ commit },id) {
        axios.get('/api/v1/students_course_list/'+id)
            .then(response => {
                commit('setCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    fetchCurrentEmployee({ commit }) {
        axios.get('/api/v1/currentEmployee')
            .then(response => {
                commit('setCurrentEmployee', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setCourse({ commit }, value) {
        commit('setCourse', value)
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    },
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },
    setEmployee_id({ commit }, value) {
        commit('setEmployee_id', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {
    setAll(state, items) {
        state.studentAll = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },    
    setError(state, error) {
        state.error = error
    },
    setCourseAll(state, value) {
        state.courseAll = value
    },
    setCurrentEmployee(state, value) {
        state.employeeAll = value
    },
    setListingAll(state, value) {
        state.listingAll = value
    },
    setCourse(state, value) {
        state.item.course = value
    },
    setStudent(state, value) {
        state.item.student = value
    },
    setJoining_date(state, value) {
        state.item.joining_date = value
    },
    setExit_date(state, value) {
        state.item.exit_date = value
    },    
    setEmployee_id(state, value) {
        state.item.employee_id = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
