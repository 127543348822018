<template> 
    <div class="popup-form">
        <b-modal id="modalPreventEdit"
             ref="modal"
             title="Edit Quiz"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="openModel" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <form @submit.stop.prevent="handleSubmit" >
            <div class="container-fluid">  
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-3 col-xs-3">
                                <label for="project">Project Name<p class="astrisk">*</p></label>
                                    <v-select
                                        name="project"
                                        label="project_name"
                                        v-validate="'required'"
                                        :value="item.project"
                                        :options="projectList"  
                                        disabled                                      
                                        />
                                <span class="error" v-show="errors.has('project')">
                                {{ $root.updateErrorMessage('project',this.errors, 'Project Name')}}
                                </span> 
                            </div>
                            <div class="col-md-3 col-xs-3">
                                <label for="topic_name">Topic Name<p class="astrisk">*</p></label>
                                <v-select
                                    id="topic_name"
                                    name="topic_name"
                                    label="topic_name"
                                    v-validate="'required'"
                                    :value="item.topicAll"
                                    :options="topicAll"
                                    disabled
                                    />
                                    <span class="error" v-show="errors.has('topic_name')">
                                    {{ $root.updateErrorMessage('topic_name',this.errors,'Topic Name')}}
                                    </span>
                            </div>
                            <div class="col-md-3 col-xs-3" v-if="item.subtopicAll && item.subtopicAll.length != 0">
                                <label for="topic_name">SubTopic Name<p class="astrisk">*</p></label>
                                <v-select
                                    id="subtopic_name"
                                    name="subtopic_name"
                                    label="topic_name"
                                    v-validate="'required'"
                                    :value="item.subtopicAll"
                                    :options="subTopicAll"
                                    disabled
                                    />
                                    <span class="error" v-show="errors.has('subtopic_name')">
                                    {{ $root.updateErrorMessage('subtopic_name',this.errors,'SubTopic Name')}}
                                    </span>
                            </div>
                            <div class="col-md-3 col-xs-3">
                                <label for="quiz_type">Quiz Type<p class="astrisk">*</p></label>
                                <v-select
                                    id="quiz_type"
                                    name="name"
                                    label="name"
                                    v-validate="'required'"
                                    @change="updateQuiz"
                                    :options="quizList"
                                    :value="item.quiz"
                                    disabled
                                    />
                                    <span class="error" v-show="errors.has('name')">
                                    {{ $root.updateErrorMessage('name',this.errors,'Quiz Type')}}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div v-if="is_blank" class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-group">     
                                            <label ref="question" for="question">Question Text<p class="astrisk">*</p></label>
                                            <b-form-textarea 
                                                    id="question"
                                                    type="textarea"
                                                    name="question"
                                                    v-validate="'required'"
                                                    @input="updateQuestion"
                                                    v-model="item.question"
                                                    placeholder="How does Robotics {blank} work.">
                                            </b-form-textarea>  
                                            <span style="font-size: 13px;"><b>Note :</b> Please use this '{blank}' word for blank space with whitespace before this word. For i.e:How does Robotics {blank} work?</span>
                                            <span class="error" v-show="errors.has('question')">
                                                {{ $root.updateErrorMessage('question',this.errors, 'Question Text')}}
                                            </span>                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group"> 
                                            <label>&nbsp;</label>
                                            <button type="button" class="btn btn-block btn-success" @click="addAnswerow()">+ Add Correct Answer/s</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="OptionsData && OptionsData.length > 0">
                                    <div class="col-md-12">
                                        <label ref="Optionstext" for="Optionstext">Correct Answer/s:<p class="astrisk">*</p></label>
                                    </div>
                                    <div class="col-md-12" v-for="row in OptionsData" :key="row.row_number">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group relative">
                                                    <b-form-input 
                                                        maxlength="200"
                                                        id="Optionstext"
                                                        type="text"
                                                        name="Optionstext"
                                                        v-model= "row.options"  
                                                        placeholder="Correct Answer"
                                                        >
                                                    </b-form-input> 
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <button type="button" title="Remove" class="btn btn-block btn-danger col-6 add_course_btn" @click="removeRow(row.row_number)"><i class="fa fa-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="error" v-if="OptionstextError != ''">
                                    {{OptionstextError}}
                                </span>
                            </div>
                            <div v-if="is_match" class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-group">     
                                            <label ref="question" for="question">Question Text<p class="astrisk">*</p></label>
                                            <b-form-textarea 
                                                    id="question"
                                                    type="textarea"
                                                    name="question"
                                                    v-validate="'required'"
                                                    @input="updateQuestion"
                                                    v-model="item.question"
                                                    placeholder="Match the following.">
                                            </b-form-textarea>
                                            <span class="error" v-show="errors.has('question')">
                                                {{ $root.updateErrorMessage('question',this.errors, 'Question Text')}}
                                            </span>                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group"> 
                                            <label>&nbsp;</label>
                                            <button type="button" class="btn btn-block btn-success" @click="addMatchLine()">+ Add Matches</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="OptionsData && OptionsData.length > 0">
                                    <div class="col-md-12">
                                        <label ref="Optionstext" for="Optionstext">Matches Option/s:<p class="astrisk">*</p></label>
                                    </div>
                                    <div class="col-md-12" v-for="(row, index) in OptionsData" :key="row.row_number">
                                        <div class="row">
                                            <div class="col-md-11 matchBorder">
                                                <div class="row">
                                                    <div class="col-md-5 imageMatch">
                                                        <div class="form-group relative">
                                                            <b-form-input 
                                                                maxlength="200"
                                                                id="OptionstextA"
                                                                :class="'OptionstextA_'+row.row_number"
                                                                type="text"
                                                                name="OptionstextA"
                                                                :value= "(IsImageA[index] == 0 && typeof row.options == 'string') ? row.options : ''" 
                                                                @input="updateInputA($event, index)" 
                                                                placeholder="Add Option 1 A"
                                                                >
                                                            </b-form-input>
                                                            <span>OR</span><br/>
                                                            <div style="display:flex;">
                                                                <input
                                                                    type="file"
                                                                    name="OptionsimageA"
                                                                    id="OptionsimageA"
                                                                    :class="'OptionsimageA_'+index"
                                                                    v-validate="'size:102400|ext:jpg,png,jpeg'"
                                                                    @change="updateMatchMcqFilesA($event, index, row.row_number)"
                                                                >
                                                                <div v-if="IsImageA[index] == 1 && row.options != null">
                                                                    <img :src="row.options" :id="'blahMatchImage_'+row.row_number" class="img-responsive img-circle" :alt="row.options" style="height: 80px;width: 80px;" > 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 matchDiv"><div class="divider"></div></div>
                                                    <div class="col-md-5 imageMatch">
                                                        <div class="form-group relative">
                                                            <b-form-input 
                                                                maxlength="200"
                                                                id="OptionstextB"
                                                                type="text"
                                                                name="OptionstextB"
                                                                :class="'OptionstextB_'+row.row_number"
                                                                :value= "(IsImageNext[index] == 0 && typeof row.option_second == 'string') ? row.option_second : ''" 
                                                                @input="updateInputSecond($event, index)" 
                                                                placeholder="Add Option 1 B"
                                                                >
                                                            </b-form-input>
                                                            <span>OR</span><br/>
                                                            <div style="display:flex;">
                                                                <input
                                                                    type="file"
                                                                    name="OptionsimageB"
                                                                    id="OptionsimageB"
                                                                    :class="'OptionsimageB_'+index"
                                                                    v-validate="'size:102400|ext:jpg,png,jpeg'"
                                                                    @change="updateMatchMcqFilesB($event, index, row.row_number)"
                                                                >
                                                                <div v-if="IsImageNext[index] == 1 && row.option_second != null">
                                                                    <img :src="row.option_second" :id="'blahMatchImageB_'+row.row_number" class="img-responsive img-circle" :alt="row.option_second" style="height: 80px;width: 80px;" > 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <button type="button" title="Remove" class="btn btn-block btn-danger col-6 add_course_btn" @click="removeRow(row.row_number)"><i class="fa fa-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="error" v-if="OptionsMatchError != ''">
                                    {{OptionsMatchError}}
                                </span>
                            </div>
                            <div v-if="is_image_mcq" class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-group">     
                                            <label ref="question" for="question">Question Text<p class="astrisk">*</p></label>
                                            <b-form-textarea 
                                                    id="question"
                                                    type="textarea"
                                                    name="question"
                                                    v-validate="'required'"
                                                    @input="updateQuestion"
                                                    v-model="item.question"
                                                    placeholder="Please add a question for Image MCQ.">
                                            </b-form-textarea>  
                                            <span class="error" v-show="errors.has('question')">
                                                {{ $root.updateErrorMessage('question',this.errors, 'Question Text')}}
                                            </span>                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group"> 
                                            <label>&nbsp;</label>
                                            <button type="button" class="btn btn-block btn-success" @click="addImagerow()">+ Add MCQ Answer/s</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="OptionsData && OptionsData.length > 0">
                                    <div class="col-md-12">
                                        <label ref="Optionstext" for="Optionstext">Image MCQ Option/s:<p class="astrisk">*</p></label>
                                    </div>
                                    <div class="col-md-12" v-for="row in OptionsData" :key="row.row_number">
                                        <div class="row">
                                            <div class="col-md-2" v-if="row.id!=0 && row.options">
                                                <ul class="list-unstyled text-center">
                                                    <li>
                                                        <div class="" style="">
                                                            <div v-if="row.options !='' && row.options != null">
                                                                <img :src="row.options" :id="'blah_'+row.row_number" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="row.options" > 
                                                            </div>
                                                            <div v-else>
                                                                <img src="/assets/img/male.jpg" class="franchise_image img-circle my-profile" :alt="row.options">
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group relative">
                                                    <input
                                                        type="file"
                                                        name="Optionstext"
                                                        id="Optionstext"
                                                        v-validate="'size:102400|ext:jpg,png,bmp,gif,jpeg'"
                                                        class="form-control"
                                                        @change="updateMcqFiles($event, row.row_number)"
                                                    >
                                                    <span style="font-size: 13px;"><b>Note :</b> Jpg, png, jpeg  files are allowed. Image File size should be less than or equals to 100 MB.</span>
                                                </div>
                                            </div>
                                             <div class="col-md-2">
                                                <div class="checkbox">
                                                    <label class="cursor-pointer">
                                                        Correct Answer &nbsp;
                                                        <input
                                                            type="checkbox"
                                                            name="option_second"
                                                            v-model="row.correct_answer"
                                                            @change="updateCorrectAnswer($event, row.row_number)"
                                                            >
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <button type="button" title="Remove" class="btn btn-block btn-danger col-6 add_course_btn" @click="removeRow(row.row_number)"><i class="fa fa-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="error" v-if="OptionsImageMcqError != ''">
                                    {{OptionsImageMcqError}}
                                </span>
                            </div>
                            <div v-if="is_image_que" class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-group"> 
                                            <label ref="question" for="question">Question Text<p class="astrisk">*</p></label>
                                            <b-form-textarea 
                                                    id="question"
                                                    type="textarea"
                                                    name="question"
                                                    v-validate="'required'"
                                                    @input="updateQuestion"
                                                    v-model="item.question"
                                                    placeholder="Please add question according image.">
                                            </b-form-textarea>  
                                            <span class="error" v-show="errors.has('question')">
                                                {{ $root.updateErrorMessage('question',this.errors, 'Question Text')}}
                                            </span>   
                                        </div>
                                        <div class="form-group">     
                                            <label ref="quiz_image" for="quiz_image">Question Image<p class="astrisk">*</p></label>
                                            <div v-if="item.quiz_image != null">
                                                <input
                                                    type="file"
                                                    name="quiz_image"
                                                    id="quiz_image"
                                                    v-validate="'size:2048|ext:jpg,png,jpeg'"
                                                    class="form-control"
                                                    @change="updateQuestionImage"
                                                />
                                            </div>
                                            <div v-else>
                                                <input
                                                    type="file"
                                                    name="quiz_image"
                                                    id="quiz_image"
                                                    v-validate="'required|size:102400|ext:jpg,png,jpeg'"
                                                    class="form-control"
                                                    @change="updateQuestionImage"
                                                />
                                            </div>
                                            <span style="font-size: 13px;"><b>Note :</b> Jpg, Png, Jpeg  files are allowed. Image File size should be less than or equals to 100 MB.</span>
                                            <span class="error" v-show="errors.has('quiz_image')">
                                                {{ $root.updateErrorMessage('quiz_image',this.errors, 'Question Image')}}
                                            </span>                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group"> 
                                            <label>&nbsp;</label>
                                            <button type="button" class="btn btn-block btn-success" @click="addOptionLine()">+ Add Option/s</button>
                                        </div>
                                        <div class="form-group"> 
                                            <ul v-if="item.quiz_image" class="list-unstyled text-center">
                                                <li>
                                                    <div class="" style="">
                                                        <div v-if="item.quiz_image != null">
                                                            <img :src="item.quiz_image" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="item.quiz_image" style="height:100px;" > 
                                                        </div>
                                                        <div v-else>
                                                            <img src="/assets/img/male.jpg" class="franchise_image img-circle my-profile" :alt="item.quiz_image">
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="OptionsData && OptionsData.length > 0">
                                    <div class="col-md-12">
                                        <label ref="Optionstext" for="Optionstext">MCQ Option/s:<p class="astrisk">*</p></label>
                                    </div>
                                    <div class="col-md-12" v-for="row in OptionsData" :key="row.row_number">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group relative">
                                                    <b-form-input 
                                                        maxlength="200"
                                                        id="OptionstextA"
                                                        type="text"
                                                        name="OptionstextA"
                                                        v-model= "row.options"  
                                                        placeholder="Add Options"
                                                        >
                                                    </b-form-input> 
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="checkbox">
                                                    <label class="cursor-pointer">
                                                        Correct Answer &nbsp;
                                                        <input
                                                            type="checkbox"
                                                            name="option_second"
                                                            v-model="row.correct_answer"
                                                            @change="updateCorrectAnswer($event, row.row_number)"
                                                            >
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <button type="button" title="Remove" class="btn btn-block btn-danger col-6 add_course_btn" @click="removeRow(row.row_number)"><i class="fa fa-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="error" v-if="OptionsImageError != ''">
                                    {{OptionsImageError}}
                                </span>
                            </div>
                            <div v-if="is_que_mcq" class="col-md-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="form-group">     
                                            <label ref="question" for="question">Question Text<p class="astrisk">*</p></label>
                                            <b-form-textarea 
                                                    id="question"
                                                    type="textarea"
                                                    name="question"
                                                    v-validate="'required'"
                                                    @input="updateQuestion"
                                                    v-model="item.question"
                                                    placeholder="Please add question for MCQ.">
                                            </b-form-textarea>
                                            <span class="error" v-show="errors.has('question')">
                                                {{ $root.updateErrorMessage('question',this.errors, 'Question Text')}}
                                            </span>                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group"> 
                                            <label>&nbsp;</label>
                                            <button type="button" class="btn btn-block btn-success" @click="addOptionLine()">+ Add Option/s</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="OptionsData && OptionsData.length > 0">
                                    <div class="col-md-12">
                                        <label ref="Optionstext" for="Optionstext">MCQ Options:<p class="astrisk">*</p></label>
                                    </div>
                                    <div class="col-md-12" v-for="row in OptionsData" :key="row.row_number">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group relative">
                                                    <b-form-input 
                                                        maxlength="200"
                                                        id="OptionstextA"
                                                        type="text"
                                                        name="OptionstextA"
                                                        v-model= "row.options"  
                                                        placeholder="Add Options"
                                                        >
                                                    </b-form-input> 
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="checkbox">
                                                    <label class="cursor-pointer">
                                                        Correct Answer &nbsp;
                                                        <input
                                                            type="checkbox"
                                                            name="option_second"
                                                            v-model="row.correct_answer"
                                                            @change="updateCorrectAnswer($event, row.row_number)"
                                                            >
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <button type="button" title="Remove" class="btn btn-block btn-danger col-6 add_course_btn" @click="removeRow(row.row_number)"><i class="fa fa-close"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="error" v-if="OptionsOptionError != ''">
                                    {{OptionsOptionError}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-6 col-xs-6 form-group">
                                <label for="topic_files">Document / Video</label>
                                <input
                                        type="file"
                                        name="topic_files"
                                        id="topic_files"
                                        v-validate="'size:512000|ext:mp4,doc,pdf,txt,docx,jpg,png,jpeg'"
                                        class="form-control"
                                        @change="updateTopcFiles"
                                    >
                                <span v-if="item.tips_file">
                                    <a target="_blank" :href="item.tips_file_url" >Click Here to View uploaded Document/Video Url </a>
                                </span><br/>
                                <span style="font-size: 13px;"><b>Note :</b> Mp4, doc, pdf, txt, docx, jpg, png, jpeg  files are allowed. Video File size should be less than or equals to 500 MB.</span>
                                <span class="error" v-if="fileError != ''">
                                    {{fileError}}
                                </span>
                            </div>
                            <div class="col-md-6 col-xs-6 form-group">
                                <label for="topic_file">Description</label>
                                <b-form-textarea 
                                        type="textarea"
                                        name="tips_description"
                                        id="tips_description"
                                        class="form-control"
                                        v-model="item.tips_description"
                                        >
                                    </b-form-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="modal-footer modal-footer1"><button type="button" @click="handleCancel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
        </form>
        </b-modal>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
/* import BlankComponent from './BlankComponent' */
export default {
    data() {
        return {
            // Code...
            fileError:'',
            is_blank:false,
            optionVls:[],
            blankError:'',
            Params:[],
            optVals:[],
            OptionstextError:'',
            is_match:false,
            OptionsMatchError:'',
            is_image_mcq:false,
            OptionsImageMcqError:'',
            is_image_que: false,
            OptionsImageError:'',
            is_que_mcq:false,
            OptionsOptionError:'',
        }
    },
    components: {
        //BlankComponent,
    },
    props : ['project_id', 'question_id'],
    computed: {
        ...mapGetters('QuizSingle', ['item', 'loading','topicSubtopicAll', 'topicAll', 'subTopicAll', 'projectList', 'quizList', 'OptionsData', 'OptionsOriginalData', 'optionImage', 'IsImageA', 'IsImageNext'])
    },
    created() {
        console.log('sadvas');
        //this.fetchTypes();
    },
    methods: {
        ...mapActions('QuizSingle', ['storeData', 'fetchTopicSubtopicAll', 'setDescription', 'setTopicFiles', 'fetchProjectAll', 'fetchData', 'fetchQuizType', 'setQuiz', 'setItem', 'setRemoveItem', 'setQuestion', 'setOption', 'fetchQuizData', 'updateData', 'fetchQuestionData', 'setOptionImage', 'setCorrectAnswer', 'setQuestionImage', 'setOriginalOption', 'setMatchImageA', 'setMatchImageNext', 'setInputA', 'setInputSecond']),
        openModel () {
            this.setRemoveItem('');
            this.setQuestionImage('');
            this.blankError = '';
            this.fetchQuizData(this.question_id)
            .then(()=>{
                this.fetchData(this.project_id); 
                this.fetchQuizType()
            });
        },
        updateDescription(value){
            this.setDescription(value);
        },
        updateQuiz(value){
            this.setQuiz(value);
            if(value){
                this.is_blank = false
                this.is_match = false
                this.is_image_mcq = false
                this.is_image_que = false
                this.is_que_mcq = false
                if(value.value_id == 2){
                    this.is_blank = true
                }else if(value.value_id == 3){
                    this.is_match = true
                }else if(value.value_id == 4){
                    this.is_image_que = true;
                }else if(value.value_id == 5){
                    this.is_que_mcq = true;
                }else if(value.value_id == 6){
                    this.is_image_mcq = true;
                }
                if(value.id == this.item.quiz_type_id){
                    this.setOption(this.OptionsOriginalData);
                }
            }
        },
        updateTopcFiles(e) {
            this.setTopicFiles('');
            this.fileError = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].size <= 2000000 && e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                    this.setTopicFiles(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 500000000 && (e.target.files[0].type == 'video/mp4' || e.target.files[0].type == 'video/quicktime' || e.target.files[0].type == 'audio/ogg' || e.target.files[0].size > 2000000 && e.target.files[0].type == 'text/plain' || e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'application/msword' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 500MB.'
                    }
                    else {
                        message = 'Only mp4, Pdf, Text, doc, jpg, png, jpeg  files are allowed'
                    }
                    this.$swal({
                    title: message,
                    type: 'error',                      
                    focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#topic_files").val('');
                            this.$validator.errors.remove('topic_files');
                            this.setTopicFiles('');
                        }
                    })
                }
            }           
        },
        updateQuestionImage(e){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setQuestionImage(e.target.files[0]); 
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                        this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#profile").val('');
                            this.$validator.errors.remove('question');
                            this.setQuestionImage('');
                        }
                    })
                }
            }
        },
        updateQuestion(value){
            this.setQuestion(value);
        },
        addAnswerow(){
            this.OptionstextError = '';
            if(this.item.question == '' || this.item.question == null)
            {
                 this.$validator.errors.add({
                  id: 'question',
                  field: 'question',
                  msg: 'Question Text is required.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
               this.$validator.errors.remove('question');
            }        

            var counts = 0;
            var queArray = (this.item.question).split(' ');
            queArray.forEach(function(v, i){
                if (v && v.indexOf('{blank}') > -1) {
                    counts = parseInt(counts) +1;
                    console.log(counts);
                }
            });
            var num  = (this.OptionsData && this.OptionsData.length > 0) ? (this.OptionsData.length)+1 : 1;
            if(this.item.question != ''){
                if(counts > 0 && (counts >= num || num == 1)){
                    var newRow = {'id':0,'options':'', 'option_second':'option_key_'+num,'row_number':num}
                    var DetailsLocal = purify(this.OptionsData);
                    DetailsLocal.push(newRow);
                    if(this.item.question != null){
                        this.Params[(this.OptionsData.row_number) + 1] = newRow;
                    }
                    this.setOption(DetailsLocal);
                }
            }    
        },
        addMatchLine(){
            this.OptionsMatchError = '';
            if(this.item.question == '' || this.item.question == null)
            {
                 this.$validator.errors.add({
                  id: 'question',
                  field: 'question',
                  msg: 'Question Text is required.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
               this.$validator.errors.remove('question');
            }        
            var num  = (this.OptionsData && this.OptionsData.length > 0) ? (this.OptionsData.length)+1 : 1;
            if(this.item.question != '' && num <= 10){
                var newRow = {'id':0,'options':'', 'option_second' :'', 'row_number':num}
                var DetailsLocal = this.OptionsData;
                DetailsLocal.push(newRow);
                this.setOption(DetailsLocal);
            }    
        },
        addImagerow(){
            this.OptionsImageMcqError = '';
            if(this.item.question == '' || this.item.question == null)
            {
                 this.$validator.errors.add({
                  id: 'question',
                  field: 'question',
                  msg: 'Question Text is required.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
               this.$validator.errors.remove('question');
            }        
            var num  = (this.OptionsData && this.OptionsData.length > 0) ? (this.OptionsData.length)+1 : 1;
            if(this.item.question != '' && num <= 10){
                var newRow = {'id':0,'options':'', 'option_second' :0, 'row_number':num}
                var DetailsLocal = purify(this.OptionsData);
                DetailsLocal.push(newRow);
                this.setOption(DetailsLocal);
            }    
        },
        addOptionLine(){
            this.OptionsImageError = '';
            this.OptionsOptionError = '';
            if(this.item.question == '' || this.item.question == null)
            {
                 this.$validator.errors.add({
                  id: 'question',
                  field: 'question',
                  msg: 'Question Text is required.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
               this.$validator.errors.remove('question');
            }        
            var num  = (this.OptionsData && this.OptionsData.length > 0) ? (this.OptionsData.length)+1 : 1;
            if(this.item.question != '' && num <= 10){
                var newRow = {'id':0,'options':'', 'option_second' :'', 'row_number':num}
                var DetailsLocal = purify(this.OptionsData);
                DetailsLocal.push(newRow);
                this.setOption(DetailsLocal);
            }    
        },
        updateCorrectAnswer(e, row_number){
            var DetailsLocal = purify(this.OptionsData);
            DetailsLocal.forEach(function(v, i){ 
                if(row_number == v.row_number){
                    v.option_second = (e.target.checked) ? 1 : 0;
                }
            });
            this.setOption(DetailsLocal);    
        },
        updateMcqFiles(e, row_number){
            //var imageData = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah_'+row_number)
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    var dataS = [];
                    dataS['data'] = e.target.files[0];
                    dataS['row_number'] = row_number;
                    this.setOptionImage(dataS);  
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#profile").val('');
                            this.$validator.errors.remove('profile');
                            this.setOptionImage('', row_number);
                        }
                    })
                }
            }   
        },
        removeRow(index){
            var DetailsLocal = purify(this.OptionsData);
            this.OptionsData.forEach(function(selectedList,addedIndex){ 
                if (selectedList && selectedList.row_number == index){
                    DetailsLocal.splice(addedIndex, 1);              
                    return false;
                }               
            }.bind(this))   
            this.setOption(DetailsLocal);    
        },
        updateInputA(value, index){
            $('.OptionsimageA_'+index).val('');
            var details = []
            details['index'] = index;
            details['data'] = value;
            this.setInputA(details); 
        },
        updateInputSecond(value, index){
            $('.OptionsimageB_'+index).val('');
            var details = []
            details['index'] = index;
            details['data'] = value;
            this.setInputSecond(details); 
        },
        updateMatchMcqFilesA(e, index, row_number){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blahMatchImage_'+row_number)
                            .attr('src', e.target.result)
                            .width(80)
                            .height(80);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    var details = []
                    details['index'] = index;
                    details['data'] = e.target.files[0];
                    this.setMatchImageA(details); 
                    $('.OptionstextA_'+row_number).val('');
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            var details = []
                            details['index'] = index;
                            details['data'] = '';
                            this.setMatchImageA(details);
                        }
                    })
                }
            }   
        },
        updateMatchMcqFilesB(e, index, row_number){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 100000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blahMatchImageB_'+row_number)
                            .attr('src', e.target.result)
                            .width(80)
                            .height(80);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    var details = []
                    details['index'] = index;
                    details['data'] = e.target.files[0];
                    this.setMatchImageNext(details); 
                    $('.OptionstextB_'+row_number).val('');
                }else{
                    var message = "";
                    if(e.target.files[0].size > 100000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 100MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            var details = []
                            details['index'] = index;
                            details['data'] = '';
                            this.setMatchImageNext(details);
                        }
                    })
                }
            }   
        },
        handleCancel(evt){
            this.makeEmptyModel();
            this.$emit('closeModels');
        },  
        makeEmptyModel(){
            this.setRemoveItem('');
            this.is_blank = false
            this.is_match = false
            this.is_image_mcq=false
            this.is_image_que=false
            this.is_que_mcq=false
            this.blankError = '';
            this.OptionstextError = '';
            this.OptionsMatchError = '';
            this.OptionsImageMcqError = '';
            this.OptionsImageError = '';
            this.OptionsOptionError = '';
            this.$validator.errors.remove('name');
            this.$validator.errors.remove('question');
            this.$validator.errors.remove('quiz_image');
            this.fetchTopicSubtopicAll(this.project_id);
        },
        handleOk (evt) {
            evt.preventDefault();
            var checkErrors = false;
            var matchErrors = false;
            var OptionsImageErrorFlag = false;
            var OptionsImageMcqErrorFlag = false;
            var OptionsFlag = false;
            this.$validator.errors.remove('question');
            this.$validator.errors.remove('quiz_image');
            if(this.is_blank){
                this.OptionsData.forEach((v, i) => {
                    if(v.options == ''){ checkErrors = true; return false;}
                });
                var counts = 0;
                var queArray = (this.item.question).split(' ');
                queArray.forEach(function(v, i){
                    if (v && v.indexOf('{blank}') > -1) {
                        counts = parseInt(counts) +1;
                    }
                }); 
                if(this.OptionsData.length != counts || this.OptionsData.length == 0){
                    checkErrors = true;
                }
            }
            if(this.is_match){
                this.OptionsData.forEach((v, i) => {
                    if(v.options == ''){ matchErrors = true; return false;}
                });
                if(this.OptionsData.length == 0){matchErrors = true;}
            }
            if(this.is_image_mcq){
                var cImage = 0;
                if(this.optionImage && this.optionImage.length >0){
                    this.OptionsData.forEach((v, i) => {
                        this.optionImage.forEach((val, inx) => {
                            if(val.row_number == v.row_number){
                                cImage = parseInt(cImage)+1
                            }
                        });
                    });
                    if(cImage == 0){OptionsImageMcqErrorFlag = true;}
                }else if(this.OptionsData.length > 0){
                    console.log('sdas');
                   this.OptionsData.forEach((v, i) => {
                        if(v.options == ''){ OptionsImageMcqErrorFlag = true; return false;}
                    }); 
                }else{
                    OptionsImageMcqErrorFlag = true;
                }
            }
            if(this.is_image_que){
                this.OptionsData.forEach((v, i) => {
                    if(v.options == ''){ OptionsImageErrorFlag = true; return false;}
                });
                if(this.OptionsData.length == 0){OptionsImageErrorFlag = true;}
            }
            if(this.is_que_mcq){
                this.OptionsData.forEach((v, i) => {
                    if(v.options == ''){ OptionsFlag = true; return false;}
                });
                if(this.OptionsData.length == 0){ OptionsFlag = true;}
            }
            if(this.item.quiz == '' || this.item.quiz == null){
                this.$validator.errors.add({
                  id: 'name',
                  field: 'name',
                  msg: 'Please select Quiz Type.',
                  scope: this.$options.scope,
                });
            }else if(this.item.question == '' || this.item.question == null){
                this.$validator.errors.add({
                  id: 'question',
                  field: 'question',
                  msg: 'Question Text is required.',
                  scope: this.$options.scope,
                });
            }else if(checkErrors){
                this.OptionstextError = 'Please add correct answer/s according your blank/s.'
            }else if(matchErrors){
                this.OptionsMatchError = 'Please add correct match option/s with answer/s.'
            }else if(OptionsImageMcqErrorFlag){
                this.OptionsImageMcqError = 'Please add image in option/s.'
            }else if(OptionsImageErrorFlag){
                if(this.item.quiz_image == '' || this.item.quiz_image == null){
                    this.$validator.errors.add({
                    id: 'quiz_image',
                    field: 'quiz_image',
                    msg: 'Question Image is required.',
                    scope: this.$options.scope,
                    });
                    return false;
                }
                this.OptionsImageError = 'Please add remaining option/s.'
            }else if(OptionsFlag){
                this.OptionsOptionError = 'Please add remaining option/s.'
            }else{
                this.$validator.validate().then(result => {
                if (result) {
                        this.handleSubmit()                        
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
            }
        },
        handleSubmit () { 
            this.updateData(this.question_id)
                .then(() => {
                   /*  if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                             console.log("ke",key,value);
                            if( value !== null ) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                        }

                        return '';    
                    } */
                    this.$emit('loadQuestionList');
                    this.$eventHub.$emit('update-success')
                    this.makeEmptyModel();
                    this.$emit('closeModels');
                    this.$refs.modal.hide();
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }
}
</script>


<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;    
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 75%;
}

.matchBorder{
   box-shadow: 3px 8px 6px -6px #00000045;
   margin-bottom: 10px;
}
.col-md-5 {
    flex: 0 0 48.666667%;
    max-width: 48.666667%;
}
</style>