<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Workshops</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Workshops List</h3>
                            <div class="box-tools pull-right">
                                <div class="btn-group">
                                    <button @click="backgroundCoverOnDropdown = !backgroundCoverOnDropdown" type="button" title="Excel Operations" class="btn btn-box-tool dropdown-toggle upper-background" data-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-file-excel-o" style="color: #207245; font-size: 2em;"></i>
                                    </button>
                                    <ul @click="backgroundCoverOnDropdown = !backgroundCoverOnDropdown" class="dropdown-menu upper-background" style="width: max-content;" role="menu">
                                        <li style="margin-top: 20px;">
                                            <div class="text-center">
                                                <div style="display: inline-block;">
                                                    <button
                                                        v-if="$can(xprops.permission_prefix + 'access')"
                                                        type="button" 
                                                        class="btn btn-primary btn-sm dropdown-buttons"
                                                        style="color: black;" 
                                                        @click="exportReferenceDataList">
                                                        <i class="fa fa-download" :class="{'fa-spin': loading}"></i> Master Data
                                                    </button>   
                                                </div>
                                            </div>
                                        </li>
                                        <br>
                                        <li style="margin-bottom: 20px;">
                                            <div class="text-center">
                                                <div style="display: inline-block;">
                                                    <label
                                                        v-if="$can(xprops.permission_prefix + 'create')"
                                                        for="sample-download" 
                                                        class="btn btn-danger btn-sm dropdown-buttons">
                                                        <a style="color: black;" href="/assets/document/Workshop Import Sample.xls" download>
                                                            <i class="fa fa-download" :class="{'fa-spin': loading}"></i> Excel Sample
                                                        </a>
                                                    </label>
                                                </div>
                                                <div style="display: inline-block;">
                                                    <label 
                                                        @click="backgroundCoverOnDropdown = !backgroundCoverOnDropdown" 
                                                        v-if="$can(xprops.permission_prefix + 'create')"
                                                        for="fileUpload" 
                                                        class="btn btn-warning btn-sm dropdown-buttons" 
                                                        style="color: black;">
                                                        <i class="fa fa-upload" :class="{'fa-spin': loading}"></i> Import Excel
                                                    </label>
                                                    <input 
                                                        id="fileUpload" 
                                                        name="fileUpload" 
                                                        type="file" 
                                                        @change="importingExcel" 
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx"/>
                                                    <span class="error" v-show="errors.has('fileUpload')">
                                                      Input file must be xls or xlsx.
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>                               
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can('workshop_create')"
                                        :to="{ name: 'workshop.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                                      
                            </div>
                        </div>
                        <div class="box-body" >
                          <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label for="workshop_type">Workshop Type</label>
                                  <v-select
                                    name="workshop_type"
                                    label="name"                                           
                                    :value="workshop_type"
                                    :options="workshopTypeAll"        
                                    @input="updateWorkshopType"
                                    />                                
                            </div>
                            
                            <div class="col-md-3 col-sm-12">
                                <label for="from_date">From Workshop Date</label>
                                  <date-picker
                                      v-model= "filterData.from_date"
                                      :config="$root.dpconfigDate"
                                      name="from_date"
                                      placeholder="Select From Workshop Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label for="to_date">To Workshop Date</label>
                                  <date-picker
                                      v-model= "filterData.to_date"
                                      :config="$root.dpconfigDate"
                                      name="to_date"
                                      placeholder="Select To Workshop Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <button type="button" class="btn btn-success m-t-25" @click="searchData()">Search</button>
                            
                            </div>
                          </div>
                          
                        </div>
                      
                        <bootstrap-alert />
                        <div class="box-body setting-group-btn">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                        <div @click="backgroundCoverOnDropdown = !backgroundCoverOnDropdown" id="loader_placeholder" v-if="backgroundCoverOnDropdown">
                            <div id="loadingDiv">
                            </div>
                        </div>
                        <div id="loader_placeholder" v-if="excelLoader">
                            <div id="loadingDiv">
                                <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                            </div>
                        </div>                                                     
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import WorkshopActions from '../../dtmodules/DatatableActions/WorkshopActions'
import components from '../../comps/'

WorkshopActions.attachStore('WorkshopIndex');
export default {
    components,
    WorkshopActions, 
    data() {
        return {
          backgroundCoverOnDropdown: false,
            columns: [
                { title: 'Workshop Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                { title: 'Student Name', field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Age (Yrs)', field: 'age', sortable: true, thComp: 'FilterTh', tdClass: 'Age', visible: false },
                { title: 'Contact Name', field: 'contact_name', sortable: true, thComp: 'FilterTh', tdClass: 'Contact Name' },
                { title: 'Contact No', field: 'contact_number', sortable: true, thComp: 'FilterTh', tdClass: 'Contact No' },
                { title: 'Duration (Hrs)', field: 'duration', sortable: true, thComp: 'FilterTh', tdClass: 'Duration' },
                { title: 'Workshop Type', field: 'workshop_type_text', sortable: true, thComp: 'FilterTh', tdClass: 'Workshop Type' },
                { title: 'Actions ', tdComp: WorkshopActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'WorkshopIndex',
                route: 'workshop',
                permission_prefix: 'workshop_'
            },
            filterData: {
              workshop_type: null,
              from_date: null,
              to_date: null,
            },
            workshop_type: null,
            workshopTypeAll: [
              {
                value_id: 0,
                name: 'Free'
              },
              {
                value_id: 1,
                name: 'Paid'
              },
            ],
        }
    },

    created() {
        this.fetchData()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('WorkshopIndex', ['data', 'total', 'loading','excelLoader','universalFlag','excelUpdateDetails']),

    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        
    },
    methods: {
        ...mapActions('WorkshopIndex', ['fetchData', 'setQuery', 'resetState','exportReferenceDataListStore', 'setExcelFile','importingExcelStore','deleteTempExcelData','setUniversalFlag','fetchExcelUpdateDetails','setExcelUpdateDetails','exportReferenceDataListStore']),
        exportReferenceDataList() {
            this.exportReferenceDataListStore()
        },   
        getExtension(fileName){
          let parts = fileName.split('.');
          return parts[parts.length - 1];
        },        
        importingExcel(e) {
            if (e.target.files && e.target.files[0]) {
                let ext = this.getExtension(e.target.files[0].name)
                let type = e.target.files[0].type
                if(type == 'xls' || type == 'xlsx' || type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                        || type == 'application/vnd.ms-excel' || ext == 'xls' || ext == 'xlsx'){
                        this.setExcelFile(e.target.files[0]);
                        this.importingExcelStore()
                            .then(response=>{
                                if(this.universalFlag == false) {
                                    this.$swal({
                                        title: 'Invalid file please refer to sample file for your reference.',
                                        type: 'error',                      
                                        focusCancel: true,                       
                                    }).then(result => {
                                        if (typeof result.dismiss === "undefined") {
                                            $("#fileUpload").val('');
                                            this.$validator.errors.remove('fileUpload');                         
                                        }
                                    })
                                    this.setUniversalFlag(true)
                                } else {
                                    this.fetchExcelUpdateDetails(response.data.data[0])
                                    .then(() => {
                                        var updateMessage = 'Excel Sheet has been imported successfully<br> <strong style="color:#03540b;">Total success entries:&nbsp;' + this.excelUpdateDetails['successDataCount'] + '</strong><br><strong style="color:#543503;">Total failure entries:&nbsp;'+this.excelUpdateDetails['failedDataCount'] + '</strong>'
                                        this.$eventHub.$emit('excel-update',updateMessage)
                                        this.setExcelUpdateDetails(null)
                                    })
                                    this.fetchData()                                      
                                }
                            })
                            .catch(error => {
                                this.$eventHub.$emit('action-error')
                            })
                } else{
                    var message = 'Only xls, xlsx files are allowed';
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss == "undefined") {
                            $("#fileUpload").val('');
                            this.$validator.errors.remove('fileUpload');                         
                        }
                    })
                }
            }
            e.target.value = '';
        },                 
        resetFilters(){
            var filtersField= [];
            filtersField = ['original_format_date','original_date','student_name','age','contact_name','contact_number','duration','workshop_type_text','status_text'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.updateWorkshopType(null)
            this.filterData.from_date = null;
            this.filterData.to_date = null;
            this.fetchData()
            
        },
        updateWorkshopType(val){
          this.filterData.workshop_type = null
          this.workshop_type = null
          if(val){
            this.filterData.workshop_type = val.value_id
            this.workshop_type = val
          }
        },
        searchData(){
          if (this.filterData.from_date) {
            this.filterData.from_date = this.filterData.from_date.replace(/\//g, '-');    
          }
          if (this.filterData.to_date) {
            this.filterData.to_date = this.filterData.to_date.replace(/\//g, '-');
          }
          this.fetchData(this.filterData)
        },
    }
}
</script>


<style scoped>
.m-t-25 {
    margin-top: 25px
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.upper-background {
    border: 1px solid #008b8b;
    background: #fffafa;
    z-index: 10021 !important;
}
ul li {
    margin-left: 10px;
    margin-right: 10px;    
}
.dropdown-buttons {
    width: 150px;
}
</style>
