<template>
    <div class="container">
        <button id="generatePDF" v-if="cust_error == ''" @click="download()" class="btn btn-default float-right"><i class="fa fa-download" aria-hidden="true"></i></button>
        <div v-else>
            <span style="text-align:center;color:red;">{{cust_error}}</span>
        </div>
        <div class="row" v-html="item" v-if="item != null">
            
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    props:['enroll_id'],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            htmlToPdfOptions: {margin: 0},
            starStyle: {
                fullStarColor: '#00D746',
                emptyStarColor: '#737373',
                starWidth: 30,
                starHeight: 30
            },
            cust_error:""
        }
    },
    computed: {
        ...mapGetters('CertificatePDF', ['item','loading']),
    },
    watch: {
        "$route.params.id": function() {
            this.getCertificate(this.$route.params.id)   
        }
    }, 
    created(){
        this.getCertificate(this.$route.params.id)
        .then((res) => {
            this.cust_error = "";
            console.log(res);
            if(res.data.length == 0) {
               this.$router.push('/404')
            }
            if(res.data.result == false) {
                this.cust_error = res.data.message;
              // this.$eventHub.$emit(res.data.message)
            }
        })
        //this.$forceUpdate()
    },
    methods:{
        ...mapActions('CertificatePDF', ['getCertificate']),

        onProgress(event) {
            console.log(`Processed: ${event} / 100`)
        },
        hasGenerated() {
            alert("PDF generated successfully!")
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf()
        },
        download() {
            this.$router.push('/certificatepdf/'+this.$route.params.id)
        }
    }
}
</script>
