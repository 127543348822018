<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Student Batches</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>

                        <div class="box col-md-12">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Student Batch</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body row">
                                <div class="col-md-6 col-xs-12">
                                     <div ref="student" class="form-group">
                                        <label for="student">Student Name / Enrollment Number<p class="astrisk">*</p></label>
                                        <v-select
                                            id="student"
                                            name="student"
                                            label="student_name"
                                            v-validate="'required'"
                                            @input="updateStudent"
                                            :value="item.student"
                                            :options="studentAll"
                                            :disabled="disableStudent"
                                            />
                                        <span class="error" v-show="errors.has('student')">
                                            {{ $root.updateErrorMessage('student',this.errors,'Student Name / Enrollment Number')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="box-body row" v-if="studentSeen">
                                <div class="col-md-6 col-xs-12" >
                                     <div ref="course" class="form-group">
                                        <label for="course">Course Name<p class="astrisk">*</p></label>
                                        <v-select
                                            name="course"
                                            label="display_course_name"
                                            @input="updateCourse"
                                            :value="item.course"
                                            :options="courseAll"  
                                            v-validate="'required'"    
                                            :disabled="disableCourse"
                                            />
                                        <span id="err_select_course" class="error" v-show="errors.has('course')">
                                          {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div ref="start_date" class="form-group col-md-6 col-xs-12">
                                    <label for="start_date">Start Date<p class="astrisk">*</p></label>
                                    <date-picker
                                            :value="item.start_date"
                                            :config="dateConfig"
                                            name="start_date"
                                            v-validate="'required'"
                                            placeholder="Select Start Date"
                                            @dp-change="updateStartDate"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('start_date')">
                                      {{ $root.updateErrorMessage('start_date',this.errors,'Start Date')}}
                                    </span>
                                </div>
                                <div class="clearfix"></div>
                                <div class="form-group check col-md-12 col-xs-12">
                                    <div ref="prefer_days" class="form-group days-label">
                                        <label for="prefer_days">Course Days<p class="astrisk">*</p></label>
                                    </div>
                                    <div ref="prefer_days" class="form-group" v-for="(day, index) in daysAll" :key="index">
                                      <span class="checkbox days-span">
                                        <label>
                                        <input 
                                            name="prefer_days"
                                            @change="updatePreferDays" 
                                            type="checkbox" 
                                            :value="index" 
                                            v-validate="'required'"
                                            v-model="checkedDays">
                                        <span class="checkbox-label" :for="day">{{day}}</span>
                                        </label>
                                      </span>
                                    </div>
                                    <span id="err_workingDay" class="error" v-show="errors.has('prefer_days')">
                                        {{ $root.updateErrorMessage('prefer_days',this.errors,'Course Days')}}
                                    </span>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-6 col-xs-12">
                                     <div ref="lab" class="form-group">
                                        <label for="lab">Lab Name<p class="astrisk">*</p></label>
                                        <v-select
                                            name="lab"
                                            label="lab_name"
                                            v-validate="'required'"
                                            @input="updateLab"
                                            :value="item.lab_id"
                                            :options="labsAll"                 
                                            />
                                        <span class="error" v-show="errors.has('lab')">
                                          {{ $root.updateErrorMessage('lab',this.errors,'Lab Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                     <div ref="batch" class="form-group">
                                        <label for="batch">Batch Time<p class="astrisk">*</p></label>
                                        <v-select
                                            name="batch"
                                            label="batch_name"
                                            @input="updateBatch"
                                            :value="item.batch"
                                            :options="batchAll"
                                            v-validate="'required'"                                    
                                            />
                                        <span id="err_select_batch" class="error" v-show="errors.has('batch')">
                                          {{ $root.updateErrorMessage('batch',this.errors,'Batch Time')}}
                                        </span>
                                    </div>
                                </div>
<!--                                 <div class="col-md-6 col-xs-12">
                                     <div ref="trainer" class="form-group">
                                        <label for="trainer">Trainer<p class="astrisk">*</p></label>
                                        <v-select
                                            name="trainer"
                                            label="employee_name"
                                            @input="updateTrainer"
                                            :value="item.trainer"
                                            :options="trainerAll"
                                            v-validate="'required'"                                    
                                            />
                                        <span class="error" v-show="errors.has('trainer')">
                                          {{ $root.updateErrorMessage('trainer',this.errors)}}
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-md-12 col-xs-12" style="text-align:center">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                Save
                            </vue-button-spinner>
                        </div>
                    </form>
                </div>
            </div>
            <br>            
            <div class="row"  v-if="false">
                <div class="col-xs-12">
                    <div class="box col-md-12">
                        <div class="box-header with-border">
                            <h3 class="box-title">Number of Students in Selected Batch</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                </button> 
                            </div>                            
                        </div>
                        <div class="box-header with-border">
                              <table class="text-left">
                                  <tbody>
                                      <tr>
                                          <td>
                                              Specifications:
                                          </td>
                                          <td>
                                              <div class="Color_Box_Div greenColorBox" style="background-color: #eee;"></div>
                                              <span>Available</span>
                                          </td>
                                          <td>
                                              <div class="Color_Box_Div yellowColorBox"></div>
                                              <span>Filling Fast</span>
                                          </td>
                                          <td>
                                              <div class="Color_Box_Div redColorBox"></div>
                                              <span>Full</span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>   
                        </div>                         
                        <div class="box-body row">                
                            <div class="col-md-12 col-xs-12">
                                <div class="month">      
                                  <ul>
                                    <li class="prev" @click="prevMonth">&#10094;</li>
                                    <li class="next" @click="nextMonth">&#10095;</li>
                                    <li style="cursor: default;">
                                      {{currentMonth}}
                                      <span style="font-size:18px">{{currentYear}}</span>
                                    </li>
                                  </ul>
                                </div>                                    
                                <div>
                                    <ul class="weekdays">
                                        <li>SUN</li>
                                        <li>MON</li>
                                        <li>TUE</li>
                                        <li>WED</li>
                                        <li>THU</li>
                                        <li>FRI</li>
                                        <li>SAT</li>
                                    </ul>
                                    <div class="row days" v-if="loadingCalendar">
                                        <div class="col-xs-4 col-xs-offset-4">
                                            <div class="alert text-center">
                                                <i class="fa fa-spin fa-refresh"></i> Loading Calendar...
                                            </div>
                                        </div>
                                    </div>                             
                                    <ul class="days" v-else>
                                        <li v-for="(day, index) in newDays" :key="index">                                            
                                            <span v-for="(newDate, newKey) in day" :key="newKey">
                                                <span style="color: #777; opacity: 0.4;">
                                                    {{newDate}}
                                                </span>
                                                <span :class="[countAll[newDate+'-class'] ? countAll[newDate+'-class'] : 'greenColor', newKey ? newKey: '']" v-if="newDate != ' '" v-on:click="redirectToBatchData(newDate)" style="cursor: pointer;">
                                                    &nbsp;{{countAll[newDate+'-'] ? countAll[newDate+'-'] : 0}}
                                                </span>
                                                <span :class="newKey" v-if="newDate == ' '">
                                                    &nbsp;
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div id="loader_placeholder" v-show="loadingCalendar || loadingMinStartDate">
                <div id="loadingDiv">
                    <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                </div>
            </div>                    
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['student_id_from_props','course_id_from_props','end_date_from_props','batch_id_from_props'],        
    data() {
        return {
            loadingMinStartDate:false,
            studentSeen:false,
            // checkedDays: [1,2,3,4,5],
            checkedDays: [],
            calenderSeen: false,
            daysAll:{'1':'Monday','2':'Tuesday','3':'Wednesday','4':'Thursday','5':'Friday','6':'Saturday','7':'Sunday'},
            days: [],
            newDays: {},
            dt: {},
            calenderDate: null,            
            startDateFormate:{format:window.date_format_moment},
            disableStudent : false,
            disableCourse : false,
            dateConfig:this.$root.dpconfigDateFutureTemp
        }
    },
    computed: {
        ...mapGetters('StudentBatchSingle', ['item', 'loading', 'loadingCalendar','studentAll','courseAll','batchAll', 'trainerAll', 'countAll','labsAll','currentBranchIdForProps']),
        currentMonth: function () {
          var date = this.currentDate();
          var monthStr = this.getMonth(date[1]);
          return monthStr;
        },
        currentYear: function () {
          var date = this.currentDate();
          var year = date[2];
          return year;
        },
        currentDay: function () {
          var date = this.currentDate();
          var day = date[0];
          return day;
        },       
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },    
    created() {
      this.dateConfig.minDate = false
      let self = this;
        this.fetchStudentAll()
        .then(async function(res)  {
            if(self.student_id_from_props) {
                var studentIdLocal = self.student_id_from_props,
                localStudentValue = '';
                self.studentAll.forEach(function(value, index){
                    if(value.id == studentIdLocal){
                        localStudentValue = value;
                        self.disableStudent = true;
                    }
                });
                
                let up = await self.updateStudent(localStudentValue);
                if(self.course_id_from_props){
                  let courseIdLocal = self.course_id_from_props
                  self.courseAll.forEach(function(value, index){
                      if(value.id == courseIdLocal){
                          self.setCourse(value)
                          self.disableCourse = true
                      }
                  });
                }
                if(self.end_date_from_props){
                  let dateArr = self.end_date_from_props.split('/')
                  let newDate = `${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`
                  let date = moment(newDate,'DD-MM-YYYY').add(1,'days').format('DD/MM/YYYY')
                  self.dateConfig.minDate = date
                  self.item.start_date = date; 
                }
                if(self.batch_id_from_props){
                  self.item.student_batch_id = self.batch_id_from_props
                }
            }            
        })        
    },
    mounted() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd<10) {
            dd = '0'+dd
        } 

        if(mm<10) {
            mm = '0'+mm
        } 

        today = dd + '/' + mm + '/' + yyyy;
 
        this.item.button_start_date = today;
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('StudentBatchSingle', ['storeData', 'resetState','setStudent', 'setLab','setCourse','setBatch', 'setTrainer','setStartDate','setBatchAll','fetchStudentAll', 'fetchLabsAll','fetchStudentCourseAll','fetchCourseBatchAll', 'getCountForCalendar','fetchTrainerAll','setPreferDays','setButtonStartDate','fetchCurrentBranchId','fetchMinDate']),
        redirectToBatchData(newDate){
            var date = this.currentDate();
            var newObjectOfDate = new Date(date[1]+"/"+date[0]+"/"+date[2]);
            newObjectOfDate.setDate(newDate);

            var nowDate = newObjectOfDate;
            var dd = nowDate.getDate();
            var mm = nowDate.getMonth()+1; //January is 0!
            var yyyy = nowDate.getFullYear();

            if(dd<10) {
                dd = '0'+dd
            }
            if(mm<10) {
                mm = '0'+mm
            }
            nowDate = dd + '/' + mm + '/' + yyyy; 
            // this.$router.push({ name: 'batch_wise_student_listing.index', params: { 'batchIdFromProps': this.item.batch, 'dateFromProps': nowDate } })
            this.$router.push({ name: 'batch_wise_student_listing.index', params: { 'batchIdFromProps': this.item.batch, 'dateFromProps': nowDate, 'labIdFromProps': this.item.lab_id, 'branchIdFromProps': this.currentBranchIdForProps[0] } })            
        },        
        furtherCalendar(nextPrev) {
            var date = this.currentDate();
            var curDate = new Date(date[1]+"/"+date[0]+"/"+date[2]);
            if(nextPrev == "prev")
            {
                curDate.setMonth(curDate.getMonth()-1, 15);
            }
            else
            {
                curDate.setMonth(curDate.getMonth()+1, 15);
            }
            this.calenderDate = curDate;
                var today = this.calenderDate;
                var dd = today.getDate();
                var mm = today.getMonth()+1; //January is 0!
                var yyyy = today.getFullYear();

                if(dd<10) {
                    dd = '0'+dd
                }
                if(mm<10) {
                    mm = '0'+mm
                }
                today = dd + '/' + mm + '/' + yyyy;
            this.setButtonStartDate(today)
            this.getTheDays();
        },
        prevMonth() {
            this.furtherCalendar("prev");
        },
        nextMonth() {            
            this.furtherCalendar("next");            
        },        
        currentDate() {
            //Return this.item.start_date if available or return Current Date
            if(this.calenderDate != null){
                var today = this.calenderDate;
                var dd = today.getDate();
                var mm = today.getMonth()+1; //January is 0!
                var yyyy = today.getFullYear();

                if(dd<10) {
                    dd = '0'+dd
                }
                if(mm<10) {
                    mm = '0'+mm
                }
                today = dd + '/' + mm + '/' + yyyy;
                return today.split('/');                
            } else if(this.item && this.item.start_date) {
                return this.item.start_date.split('/');
            }
            else {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth()+1; //January is 0!
                var yyyy = today.getFullYear();

                if(dd<10) {
                    dd = '0'+dd
                }
                if(mm<10) {
                    mm = '0'+mm
                }
                today = dd + '/' + mm + '/' + yyyy;
                return today.split('/');
            }
        },
        getMonth(month) {
            if(month == '01') {
                return "JANUARY";
            }
            else if(month == '02'){
                return "FEBRUARY";
            }
            else if(month == '03'){
                return "MARCH";
            }
            else if(month == '04'){
                return "APRIL";
            }
            else if(month == '05'){
                return "MAY";
            }
            else if(month == '06'){
                return "JUNE";
            }
            else if(month == '07'){
                return "JULY";
            }
            else if(month == '08'){
                return "AUGUST";
            }
            else if(month == '09'){
                return "SEPTEMBER"
            }
            else if(month == '10'){
                return "OCTOBER";
            }
            else if(month == '11'){
                return "NOVEMBER";
            }
            else if(month == '12'){
                return "DECEMBER";
            }        
        },
        async updateStudent(value) {
            this.resetState()
            this.fetchCurrentBranchId()            
            this.fetchStudentAll()
            // this.fetchTrainerAll()
            // var today = new Date();
            // var dd = today.getDate();
            // var mm = today.getMonth()+1; //January is 0!
            // var yyyy = today.getFullYear();

            // if(dd<10) {
            //     dd = '0'+dd
            // } 

            // if(mm<10) {
            //     mm = '0'+mm
            // } 

            // today = dd + '/' + mm + '/' + yyyy;
     
            // this.item.button_start_date = today;      
            // this.item.start_date = today;      
            this.updateCourse(null)
            this.setStudent(value);
            this.setPreferDays(this.checkedDays.toString());
            // if(this.item.prefer_days) {
            //     this.checkedDays = (this.item.prefer_days).split(",");            
            // }            
            if(value && value!=null && value != '')
            {
                this.studentSeen = true;
                await this.fetchStudentCourseAll(value.id)
                // this.fetchTrainerAll()
            }
            else
            {
                this.studentSeen = false;
                this.calenderSeen = false;
            }
        },
        updateCourse(value) {
            this.dateConfig.minDate = false;            
            this.setCourse(value);
            this.item.start_date = null;
            this.setStartDate(null)        
            this.$validator.errors.remove({
                id: 'course',
                field: 'course'});         
                console.log('value',this.student_id_from_props)       
                console.log('this.student_id_from_props',this.student_id_from_props !== null)       
            if(value !== null)
            {
                var params = {
                    'student_id': this.item.student.id,
                    'course_id': value.id
                }
                // if(!this.student_id_from_props) {
                    this.loadingMinStartDate = true;
                    this.fetchMinDate(params)
                        .then((res)=>{
                            if(res.max_end_date != null) {
                                // this.item.start_date = null;
                                var dateArr = res.max_end_date.split('/')
                                var newDate = `${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`
                                var date = moment(newDate,'DD-MM-YYYY').add(1,'days').format('DD/MM/YYYY')
                                this.dateConfig.minDate = date
                                this.item.start_date = date
                                dateArr = null;newDate = null; date = null;
                            } else {
                                var today = new Date();
                                var dd = today.getDate();
                                var mm = today.getMonth()+1; //January is 0!
                                var yyyy = today.getFullYear();

                                if(dd<10) {
                                    dd = '0'+dd
                                } 
                                if(mm<10) {
                                    mm = '0'+mm
                                } 
                                today = dd + '/' + mm + '/' + yyyy;
                                this.item.button_start_date = today;      
                                this.item.start_date = today;                                  
                            }
                        })
                        .finally(() => {
                            this.loadingMinStartDate = false;
                            this.fetchBatch();
                            // this.fetchLabsAll(value.id)
                            // // this.updatePreferDays([]);
                            // this.checkedDays = [];
                            // this.updateLab(null);
                            // this.updateBatch(null);                
                        })
                // }
                this.fetchLabsAll(value.id)
                // this.updatePreferDays([]);
                this.checkedDays = [];
                this.updateLab(null);
                this.updateBatch(null);            
            }            
        },
        updateLab(value){
            this.setLab(value);
            if(value && value != null){
                this.getTheDays();     
                if(this.item && this.item.start_date && this.item.batch){                
                    this.calenderSeen = true
                }
            }
            else{
                this.calenderSeen = false
            }
        },        
        updatePreferDays(value) {
            this.setBatch(null);
            this.setPreferDays(this.checkedDays.toString());
            this.fetchBatch();
        },
        updateStartDate(e) {
            this.setBatch(null);
            if(e.target.value){
                this.setStartDate(e.target.value)
                this.setButtonStartDate(null);
                this.calenderDate = null;            
                if(this.item && this.item.batch && this.item.lab_id){
                    this.getTheDays();   
                }
            }           
            this.fetchBatch()
        },
        fetchBatch(){
            if(this.item.start_date != null) {
                var objCourse = this.item.course;
                var formatedFromDate = this.item.start_date.split('/');
                var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]
                var daysString = (this.checkedDays.length)?this.checkedDays:0;
                if(objCourse !== '' && objCourse !== null) {
                    this.fetchCourseBatchAll({'courseType':objCourse.course_type,'days':daysString,'startDate':finalOldDate})    
                }
                else {
                    this.setBatchAll([]);
                }
            }
        },
        updateBatch(value) {
            this.setBatch(value);
            this.calenderDate = null;
            if(this.item && this.item.start_date && this.item.lab_id){                    
                this.getTheDays();   
            }                     
        },
        // updateTrainer(value) {
        //     this.setTrainer(value);
        // },
        applyClasses(){            
            setTimeout(function () { 
                    $('.greenColor').closest('li').addClass('greenColorBox');
                    $('.yellowColor').closest('li').addClass('yellowColorBox');
                    $('.redColor').closest('li').addClass('redColorBox');
             }.bind(this), 450)
        }, 
        getTheDays() {
        //     this.calenderSeen = false;
        //     if(this.item.batch && this.item.lab_id) {
        //         this.calenderSeen = true;
        //     }
        // this.days = [];
        // // THE DATE OBJECT.
        // this.dt = new Date(this.currentMonth+" "+this.currentDay+","+" "+this.currentYear+" "+"11:13:00");

        // // GET THE MONTH AND YEAR OF THE SELECTED DATE.
        // var month = this.dt.getMonth(),
        //     year = this.dt.getFullYear();

        // // GET THE FIRST AND LAST DATE OF THE MONTH.
        // var FirstDay = new Date(year, month, 1);
        // var LastDay = new Date(year, month + 1, 0);
        // var lastDate = LastDay.getDate();

        // // FINALLY, GET THE DAY.
        // var weekday = new Array();
        // weekday[0] = "Sunday";
        // weekday[1] = "Monday";
        // weekday[2] = "Tuesday";
        // weekday[3] = "Wednesday";
        // weekday[4] = "Thursday";
        // weekday[5] = "Friday";
        // weekday[6] = "Saturday";

        // if (typeof weekday[FirstDay.getDay()] != 'undefined') {     // CHECK FOR 'undefined'.
        //     var fDay = FirstDay.getDay();
        //     var lDay = LastDay.getDay();
        // }

        // var counter = 1;
        // var newCounter = 1;
        // var againNewCounter = 0;
        // //var newDays = [];
        // var arrIndex = 0;
        // for (var i = 1; i <= 6; i++) {
        //     var k ="";
        //     for (var j = 1; j <= 7; j++) {
        //         arrIndex ++;
        //         this.newDays[`${arrIndex}`] = {};
        //         if(j == 1)
        //         {
        //             k = 7;
        //         }
        //         else
        //         {
        //             k = j-1;    
        //         }
                
                
        //         //this.newDays[i][j] = [];
        //         if((newCounter+againNewCounter) > lastDate){
        //             this.days.push("-");
        //             //this.newDays[`${arrIndex}`].date = "-";
        //             if(this.checkedDays.indexOf(k.toString()) < 0)
        //             {
        //                 this.newDays[`${arrIndex}`].inactive = " ";
        //             }
        //             else{
        //                 this.newDays[`${arrIndex}`].activenow = " ";
        //             }
        //         }
        //         else {
        //             if(i == 1) {
        //                 if(j <= fDay){
        //                     this.days.push("-");
        //                     //this.newDays[`${arrIndex}`].date = "-";
        //                     if(this.checkedDays.indexOf(k.toString()) < 0)
        //                     {
        //                         this.newDays[`${arrIndex}`].inactive = " ";
        //                     }
        //                     else{
        //                         this.newDays[`${arrIndex}`].activenow = " ";
        //                     }
        //                     againNewCounter--;
        //                 }
        //                 else {
        //                     if(counter <= lastDate){
        //                         this.days.push(counter);
        //                         //this.newDays[`${arrIndex}`].date = counter;
        //                         if(this.checkedDays.indexOf(k.toString()) < 0)
        //                         {
        //                             this.newDays[`${arrIndex}`].inactive = counter;
        //                         }
        //                         else{
        //                             this.newDays[`${arrIndex}`].activenow = counter;
        //                         }
        //                         counter++;
        //                     }
        //                 }
        //             }
        //             else {
        //                     if(counter <= lastDate){
        //                         this.days.push(counter);
        //                         //this.newDays[`${arrIndex}`].date = counter;
        //                         if(this.checkedDays.indexOf(k.toString()) < 0)
        //                         {
        //                             this.newDays[`${arrIndex}`].inactive = counter;
        //                         }
        //                         else{
        //                             this.newDays[`${arrIndex}`].activenow = counter;
        //                         }
        //                         counter++;
        //                     }
        //             }
        //         }
        //         newCounter++;
        //     }
        // }
        // if(this.item && this.item.batch){
        //     this.getCountForCalendar()
        //     .then(response => {this.applyClasses()})             
        // }
    },
        submitForm() {

         this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {

                    if(res.data.error_message == 1)
                    {
                        this.$validator.errors.add({
                            id: 'course',
                            field: 'course',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }
                    else if(res.data.error_message == 2)
                    {
                        this.$validator.errors.add({
                            id: 'batch',
                            field: 'batch',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }    
                    else if(res.data.error_message == 3)
                    {
                        this.$eventHub.$emit('something-went-wrong');
                        return '';
                    }                                        
                    if(this.routerOldPath == '/student')
                    {
                        this.$router.push({ name: 'student.index' })
                    }
                    else{
                       this.$router.push({ name: 'student_batch.index' }) 
                    }                      
                    // this.$router.push({ name: 'student_batch.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


<style scoped>

.activenow{
    color: #777;
}
.inactive{
    opacity: 0.4 !important;
    color: #777;
}
.redColorBox {
    background-color: rgba(255, 0, 0, 0.5);
}
.redColorBox span span:first-child {
    color: white !important;
    opacity: 1 !important;
}
.yellowColorBox {
    background-color: rgba(255, 165, 0, 0.5);
}
.yellowColorBox span span:first-child {
    color: white !important;
    opacity: 1 !important;
}
.greenColorBox {

}
.greenColor {
    color: black !important;
}
.yellowColor {
    color: black !important;
}
.redColor {
    color: black !important;
}
* {box-sizing: border-box;}
ul {list-style-type: none;}
body {font-family: Verdana, sans-serif;}

.month {
    padding: 15px 25px;
    width: 100%;
    background: #212c32;
    text-align: center;
    opacity: 0.9;    
}

.month ul {
    margin: 0;
    padding: 0;
}

.month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.month .prev {
    float: left;
    cursor: pointer;
}

.month .next {
    float: right;
    cursor: pointer;
}

.weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
}

.weekdays li {
    display: inline-block;
    width: 13.6%;
    color: #666;
    text-align: center;
}

.days {
    background: #eee;
    margin: 0;
    padding-left: 0px;
}
.days li {
    list-style-type: none;
    display: inline-block;
    width: 14.28%;
    text-align: center;
    margin-bottom: 5px;
    font-size:12px;
    color: #777;
}

.days li .active {
    padding: 5px;
    background: #1abc9c;
    color: white !important
}

@media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
}

ul.days li {
    margin: 0px 0px;
    margin-bottom: 0px !important;
    height: 60px;
    border: 1px solid #ccc;
    display: inline-block !important;
    position: relative;
}

ul.days li span > span:first-child {
    position: absolute;
    top: 3px;
    left: 8px;
    font-size: 14px;
    font-weight: bold;
}
ul.days li span > span:last-child {
    line-height: 60px;
    font-size: 18px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
</style>
