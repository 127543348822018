<template>
  <div class="login-box mt-5">
   <div class="login-logo">
      <!-- <a href="/"><b>Admin</b>LTE</a> -->
   </div>
   <div class="card">
      <div class="card-body login-card-body">
         <p class="login-box-msg">
            <a :to="'/'">
               <img
                     src="/assets/images/logo.svg"
                     width="100%"
                     class="img-responsive"
               >
            </a>
         </p>
         <p class="login-box-msg">Reset your password</p>
         <div class="alert alert-success" role="alert" v-if="successMsg">
            {{ this.successMsg }}
         </div>
         <div class="alert alert-danger" role="alert" v-if="errorMsg">
            {{ this.errorMsg }}
         </div>
         <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">
               <div class="input-group">
                  <input type="text"
                              class="form-control"
                              name="mobile_number"
                              id="mobile_number"
                              placeholder="Mobile Number"
                              v-model="user.mobile_number"
                              v-validate="{ required: true, regex:/^[ A-Za-z0-9_@./#&+-]*$/ }" >
                  <div class="input-group-append">
                     <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                     </div>
                  </div>
               </div>
               <span class="error" v-show="errors.has('mobile_number')">
                  {{ $root.updateErrorMessage('mobile_number',this.errors, 'Mobile Number')}}
               </span>
            </div>
            <div class="mb-3">
               <div class="input-group">
                  <input type="password"
                              class="form-control"
                              name="password"
                              id="password"
                              placeholder="Password"
                              v-model="user.password"
                              v-validate="'required'"
                              ref="password" >
                  <div class="input-group-append">
                     <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                     </div>
                  </div>
               </div>
               <span class="error" v-show="errors.has('password')">
                  {{ $root.updateErrorMessage('password',this.errors, 'Password')}}
               </span>
            </div>
            <div class="mb-3">   
               <div class="input-group">
                  <input type="password"
                              class="form-control"
                              name="password_confirmation"
                              id="password_confirmation"
                              placeholder="Confirm Password"
                              v-model="user.password_confirmation"
                              v-validate="'required|confirmed:password'" >
                  <div class="input-group-append">
                     <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                     </div>
                  </div>
               </div>
               <span class="error" v-show="errors.has('password_confirmation')">
                  {{ $root.updateErrorMessage('password_confirmation',this.errors, 'Password Confirm')}}
               </span>
            </div>
            <div class="row">
               <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block" :disabled="loading"><span class="alert text-center" v-if="loading">
                     <i class="fa fa-spinner fa-sync rotate"></i> Loading...</span><span v-else>Reset Password</span></button>
               </div>
            </div>
         </form>
         <p class="mt-3 mb-1">
            <router-link to="/">Login</router-link>
         </p>
      </div>
   </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            user: {
                mobile_number: '',
                password:'',
                password_confirmation:'',
                token:'',
            },
            successMsg:'',
            errorMsg:'',
            loading:false
        }
    },
    methods:{
        ...mapActions('AuthIndex', ['storeUserData']),
        handleSubmit(e) {
            this.successMsg = ''
			   this.errorMsg = ''
            this.$validator.validate().then(valid => {
                if (valid) {
                   this.loading = true;
                   this.user.token = this.$route.params.token;
                    axios.post('/api/v1/resetpassword', this.user).then(response => {
                        var res = response.data;
                        if(res.result){
                           this.successMsg = res.message;
                           this.loading = false;
                        }
                        else{
                           //console.log(res.message)
                           this.errorMsg = res.message;
                           this.loading = false;
                        }
                    });
                   //location.href="/common-masters";
                }
            });
        }
    }
}
</script>