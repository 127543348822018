function initialState() {
	return {
		item: {},
		admissionDataOfSpecificBranch: {},
		feeCollectionDataOfSpecificBranch: {},
		particularBranchDataOfExpense: {},
		loading: false,
	}
}

const getters = {
	item: state => state.item,
	loading: state => state.loading,
	admissionDataOfSpecificBranch: state => state.admissionDataOfSpecificBranch,
	feeCollectionDataOfSpecificBranch: state => state.feeCollectionDataOfSpecificBranch,
	particularBranchDataOfExpense: state => state.particularBranchDataOfExpense
}

const actions = {
	async fetchSuperAdminData({
		commit,
		dispatch
	}, year) {
		await axios.get('/api/v1/superAdminDashboardData/'+year)
			.then(response => {
				commit('setItem', response.data.data)
			})
			.catch(function(error) {
				console.log('fetchSuperAdminData',error)
			})
	},
	async fetchParticularBranchDataOfAdmission({
		commit,
		dispatch
	},data) {
		await axios.get('/api/v1/admissionDataOfSpecificBranch/'+ data.year + '/' + data.branchName)
			.then(response => {
				commit('setAdmissionDataOfSpecificBranch', response.data.data)
			})
			.catch(function(error) {
				console.log('fetchParticularBranchDataOfAdmission',error)
			})
	},	
	async fetchParticularBranchDataOfFeeCollection({
		commit,
		dispatch
	},data) {
		await axios.get('/api/v1/feeCollectionDataOfSpecificBranch/'+ data.year + '/' + data.branchName)
			.then(response => {
				commit('setFeeCollectionDataOfSpecificBranch', response.data.data)
			})
			.catch(function(error) {
				console.log('fetchParticularBranchDataOfFeeCollection',error)
			})
	},	
	resetState({
		commit
	}) {
		commit('resetState')
	},
	async fetchParticularBranchDataOfExpense({
		commit,
		dispatch
	},data) {
		await axios.get('/api/v1/fetchParticularBranchDataOfExpense/'+ data.year + '/' + data.branchName)
			.then(response => {
				commit('setParticularBranchDataOfExpense', response.data.data)
			})
			.catch(function(error) {
				console.log('fetchParticularBranchDataOfExpense',error)
			})
	},	
	resetState({
		commit
	}) {
		commit('resetState')
	},	
}


const mutations = {
	setItem(state, item) {
		state.item = item
	},
	setAdmissionDataOfSpecificBranch(state, value) {
		state.admissionDataOfSpecificBranch =  value
	},
	setFeeCollectionDataOfSpecificBranch(state, value) {
		state.feeCollectionDataOfSpecificBranch =  value
	},	
	setParticularBranchDataOfExpense(state, value) {
		state.particularBranchDataOfExpense =  value
	},		
	setLoading(state, loading) {
		state.loading = loading
	},
	resetState(state) {
		state = Object.assign(state, initialState())
	}
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	actions,
	mutations
}