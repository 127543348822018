<template>
    <div class="btn-group btn-group-xs">
        <button
                v-if="$can(xprops.permission_prefix + 'edit') && row.batch_status == 1"
                @click="holdBatch(row.id)"
                type="button"
                title="Put on hold"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-pause" aria-hidden="true"></i>
        </button>
        <button
                v-if="$can(xprops.permission_prefix + 'edit') && row.batch_status == 2"
                @click="fetchBatchRedirectionDetail(row.id)"
                type="button"
                title="Make Active"
                class="btn btn-block btn-info edit_delete_btn">
            <i class="fa fa-fw fa-play" aria-hidden="true"></i>
        </button>
         <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles'"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && xprops.route !== 'leave_requests' && row.end_date == null && row.batch_status == 1"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                v-if="$can(xprops.permission_prefix + 'delete') && xprops.route !== 'leave_requests' && row.end_date == null && row.batch_status == 1"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
        <!-- <button
                v-if="row.end_date == null && $can(xprops.permission_prefix + 'edit')"
                @click="markAsCompleted(row.id)"
                type="button"
                title="Mark as Closed"
                class="btn btn-block btn-info edit_delete_btn">
            <i class="fa fa-check" aria-hidden="true"></i>
        </button>  -->               
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            // Code...
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(() => {
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                        this.$emit('batchResponse')
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },
        markAsCompleted(id) {
            this.$swal({
                title: 'Are you sure you want to mark this as Closed?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/markAsCompletedIndexFunction',
                        id
                    ).then(result => {
                        this.$eventHub.$emit('mark-as-completed')
                    })
                }
            })
        },
        holdBatch(id){
          this.$swal({
                title: ' Are you sure all the attendance and assessment for this student are completed till now?',
                text: 'Are you sure you want to put this on Hold?',
                type: 'warning',
                cancelButtonText: "No",   
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/putBatchOnHold',
                        id
                    ).then(result => {
                      this.$eventHub.$emit('put-on-hold')
                      this.$emit('batchResponse')
                    }).catch(err => {
                      this.$eventHub.$emit('put-on-hold',2,err.response.data.message)
                    })
                }
            })
        },
        fetchBatchRedirectionDetail(id) {
            let params = { 
                          'id': id,
                          'student_id_from_props': this.row.student_id, 
                          'course_id_from_props':this.row.course_id ,
                          'end_date_from_props':this.row.end_date,
                          'batch_id_from_props': this.row.id
                        }
            this.$router.push({name: 'student_batch.edit', params: params})
        },

    }
}
</script>


<style scoped>

</style>
