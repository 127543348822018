function initialState() {
    return {
        all: [],
        relationships: {
            // "country_name_id": 'country_name',
            'prospect_status': 'name',
        },
        item: {
            gender: '0',
        },
        myData: {
            id: null,
            next_course_id: null,
            prospect_status: null,
            next_followup_date: null,
            reason_id: null,
            status_remarks: null,                  
        },
        query: {},
        statusAll:[],
        reasonAll:[],
        inqHistoryAll: [],        
        nextCourseAll: [],        
        loading: false,
        error: false
    }
} 

const getters = {
    reasonAll: state => state.reasonAll,
    myData: state => state.myData,    
    item: state => state.item,    
    data: state => {
        let rows = state.all
        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_concat_name','prev_course_name'/*, 'next_course_name'*/,'father_name','contact_name','contact_no','prospect_status_value'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_concat_name','prev_course_name'/*, 'next_course_name'*/,'father_name','contact_name','contact_no','prospect_status_value'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    dataProspect: state => {
        let rows = state.inqHistoryAll
        if (state.query.sort) {
            rows = _.orderBy(state.inqHistoryAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['dis_created_at','inquiry', 'next_followup_date', 'reason', 'status_remarks'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    totalProspect: state => {
        let rows = state.inqHistoryAll

        if (state.query.sort) {
            rows = _.orderBy(state.inqHistoryAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['dis_created_at','inquiry', 'next_followup_date', 'reason', 'status_remarks'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },    
    loading:       state => state.loading,
    relationships: state => state.relationships,
    error: state => state.error,
    all: state => state.all,
    statusAll: state => state.statusAll,
    nextCourseAll: state => state.nextCourseAll,
    inqHistoryAll: state => state.inqHistoryAll,        
}

const actions = {
    fetchData({ commit, state, dispatch }, prospect_status_filter) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        axios.get('/api/v1/student-prospect/' + prospect_status_filter)
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateStatusData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.myData) {
                let fieldValue = state.myData[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.myData.prospect_status)) {
                params.set('prospect_status', '')
            } else {
                params.set('prospect_status', state.myData.prospect_status.value_id)
            }
            if (_.isEmpty(state.myData.next_course_id)) {
                params.set('next_course_id', '')
            } else {
                params.set('next_course_id', state.myData.next_course_id.id)
            }            
            if (_.isEmpty(state.myData.next_followup_date)) {
                params.set('next_followup_date', '')
            } else {
                params.set('next_followup_date', state.myData.next_followup_date)
            }
            if (_.isEmpty(state.myData.reason)) {
                params.set('reason_id', '')
            } else {
                params.set('reason_id', state.myData.reason.value_id)
            }
            if (_.isEmpty(state.myData.status_remarks)) {
                params.set('status_remarks', '')
            } else {                
                params.set('status_remarks', state.myData.status_remarks)
            }

            axios.post('/api/v1/updateProspectStatusData/' + state.myData.id, params)
                .then(response => {
                    // commit('setMyData', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },    
    async fetchProspectData({ commit }, obj) {
        await axios.get('/api/v1/prospectData/' + obj.student_id + '/' + obj.course_id + '/' + obj.contact_name + '/' + obj.contact_no)
            .then(response => {

                let aryProspectOptions = ['Pending', 'Followup', 'Closed'];
                
                if( aryProspectOptions.includes( response.data.data.prospect_status.name ) ) {
                    console.log('Resetting the prospect status & status remarks')
                    response.data.data.prospect_status = null;
                    response.data.data.next_followup_date = null;
                    response.data.data.status_remarks = null;
                }

                commit('setMyData', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })        
    },
    fetchNextCourses({ commit }, obj) {
        axios.get('/api/v1/fetchNextProspectCourse/' + obj.student_id)
            .then(response => {
                commit('setNextCourseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
    },
    fetchStatusAll({ commit }) {
        axios.get('/api/v1/prospectStatusList')
            .then(response => {
                commit('setStatusAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
    },    
    fetchReasonAll({ commit, dispatch }) {
        axios.get('/api/v1/reasonTypeList')
            .then(response => {
                commit('setReasonAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })       
    },    
    fetchProspectStatusHistory({ commit, dispatch }, obj) {
        axios.get('/api/v1/prospectHistoryData/' + obj.student_id + '/' + obj.course_id)
            .then(response => {
                commit('setinqHistoryAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },    
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/v1/student-prospect/' + id)
                .then(response => {

                    var rows = state.all
                    rows = rows.filter((item) => {
                        return item.id != id
                    })
                    commit('setAll', rows);
                    
                    var queryLocal = purify(state.query);
                    if(rows.length == queryLocal.offset && rows.length != 0)
                    {
                        queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                        commit('setQuery', queryLocal)
                    }
                    resolve();
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setNext_followup_date({ commit }, value) {
        commit('setNext_followup_date', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setStatus_remarks({ commit }, value) {
        commit('setStatus_remarks', value)
    }, 
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setNextCourse({ commit }, value) {
        commit('setNextCourse', value)
    },    
    setinqHistoryAll({ commit }, value) {
        commit('setinqHistoryAll', value)
    },
    setNextCourseAll({ commit }, value) {
        commit('setNextCourseAll', value)
    },        
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setNextCourse(state, value) {
      state.myData.next_course_id = value
    },
    setReasonAll(state, value) {
        state.reasonAll = value
    },    
    setMyData(state, myData) {
        state.myData = myData
    },    
    setStatus(state, value) {
        state.myData.prospect_status = value
    },
    setNext_followup_date(state, value) {
        state.myData.next_followup_date = value
    },
    setReason(state, value) {
        state.myData.reason = value
    },
    setGender(state, value) {
        state.item.gender = value
    },
    setStatus_remarks(state, value) {
        state.myData.status_remarks = value
    },    
    setStatusAll(state, value) {
        state.statusAll = value
    },
    setinqHistoryAll(state, value) {
        state.inqHistoryAll = value
    },
    setNextCourseAll(state, value) {
        state.nextCourseAll = value
    },    
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    setError(state, error) {
        state.error = error
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
