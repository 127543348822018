<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventView"
            ref="modal"
            title="View Tutor Question"
            @shown="openModel" 
            @close="handleClose"
            size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true"
            >
            <section class="content">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                            <tr>
                                                <th>Project Name</th>
                                                <td>{{ item.project.project_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Topic Name</th>
                                                <td>{{ (item.topicAll) ? item.topicAll.topic_name : '' }}</td>
                                            </tr>
                                            <tr v-if="item.subtopicAll && item.subtopicAll.length != 0">
                                                <th>SubTopic Name</th>
                                                <td>{{ (item.subtopicAll) ? item.subtopicAll.topic_name : '' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Question Detail</h3>
                        </div>
                        <div class="card-body" >
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <table class="table table-bordered table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <th class="col-md-1">Question</th>
                                                <td class="col-md-11">{{ item.question }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : ['project_id', 'question_id'],
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters('TutorSingle', ['item', 'loading'])
    },
    methods: {
       ...mapActions('TutorSingle', ['fetchTutorData']),
        openModel () {
            this.fetchTutorData(this.question_id)
        },
        handleClose(){
            this.$emit('closeModels');
        },
    }
}
</script>


<style scoped>
.card {
    min-height: 185px !important;
}
</style>
