<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventView"
                ref="modal"
                title="View GST Detail"
                @shown="openModel" 
                size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <table class="table table-bordered table-striped not-responsive-grid">
                                            <tbody>
                                            <tr>
                                                <th class="header-w">Start Date</th>
                                                <td>{{ item.start_date }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">End Date</th>
                                                <td>{{ (item.gst_end_date) ? item.gst_end_date : 'Until the next date, this GST will be applicable.' }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table class="table table-bordered table-striped not-responsive-grid" v-if="item.gst_management_details && item.gst_management_details.length > 0">
                                            <tbody>
                                            <tr>
                                                <th class="header-w">Fee Type Name</th>
                                                <th class="header-w">GST Rate(%)</th>
                                            </tr>
                                            <tr v-for="row in item.gst_management_details" :key="row.id">
                                                <td>{{ (row.fee_type_details) ? row.fee_type_details.name : '-' }}</td>
                                                <td>{{ (row.gst_rate) ? row.gst_rate : 0 }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : ['gst_id'],
    data() {
        return {
            // Code...
        }
    },
    created() {
       // console.log(this.item);
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('GSTSingle', ['item', 'loading'])
    },
    watch: {
        //..
    },
    methods: {
        ...mapActions('GSTSingle', ['fetchData', 'resetState', 'setItem']),
        openModel () {
            this.setItem('');
            this.fetchData(this.gst_id)
        },
    }
}
</script>


<style scoped>
.card {
    min-height: 185px !important;
}
.header-w{
    width: 20%;
}
.dis-flex{
    display: flex;
}
</style>
 