<template>
    <div class="btn-group btn-group-xs">
        <span class="label label-primary" @click="scrollToData(row.trainer_id, row.trainer_name)" style="cursor: pointer;">
            {{row.total_assessment_done}}
        </span>
   </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: ['row', 'xprops','value', 'field'],
    methods: {
        ...mapActions('TrainerStatisticsIndex', ['setTrainerStatisticsShown','setQuery','setTrainerFilterInStudentsData']),
        scrollToData(trainer_id, trainer_name) {
            this.setTrainerStatisticsShown(false)
            this.setTrainerFilterInStudentsData(trainer_name)
            this.setTrainerStatisticsShown(true)
            var self = this;
            setTimeout(function(){
                document.getElementById('trainerStudentData').scrollIntoView();
                self.setQuery({
                    'limit': 10,
                    'offset': 0,
                    'order': "",
                    'sort': ""})
            },10)
        }
    }
}
</script>


<style scoped>

</style>
