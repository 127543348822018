<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Fees</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Course Fee</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Branch Name</th>
                                            <td>
                                                <span v-if="item.branch !== null">
                                                    {{ item.branch.branch_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>   
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Course Name</th>
                                            <td>
                                                <span v-if="item.course !== null">
                                                    {{ item.course.course_name }}
                                                </span>
                                            </td>
                                        </tr>                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class=" col-md-10 col-md-offset-1" v-if="courseDetailedData.length > 0">
                                <div class="form-group">
                                            <table class="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Course Level</th>
                                                            <th>Fee type</th>
                                                            <th>Amount</th>
                                                            <th>From Date</th>
                                                            <th>Upto Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(feesType, i) in courseDetailedData">
                                                            <td>{{feesType.course_level}}</td>
                                                            <td>{{feesType.fees_type_name}}</td>
                                                            <td> <i class="fa fa-rupee"></i> {{feesType.fees_amount}}</td>      
                                                            <td>{{formatedFromDate(feesType.from_date)}}</td>      
                                                            <td>{{(feesType.upto_date)?formatedFromDate(feesType.upto_date):'-'}}</td>      
                                                        </tr>
                                                       <!--  <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>Total:</strong> {{computedTotalAmount}}</td>
                                                            <td></td>
                                                        </tr> -->
                                                    </tbody>
                                            </table><br>
                                 </div>
                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData({'id':this.$route.params.id,'from_date':this.$route.params.from_date,'fees_branch_id':this.$route.params.fees_branch_id})

        .then(res=> {
            console.log(res);
            this.fetchCourseDetailedData({'main_course_id':this.item.course.main_course_id,'from_date':this.item.from_date,'branch_id':this.$route.params.fees_branch_id});
        })
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('FeesMastersSingle', ['item','courseDetailedData']),
        computedTotalAmount() {
            var total = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                total = total+parseFloat(this.courseDetailedData[i].fees_amount)
            }
            return total;
        }
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
            this.fetchShowData({'id':this.$route.params.id,'from_date':this.$route.params.from_date,'fees_branch_id':this.$route.params.fees_branch_id})
        }
    },
    methods: {
        ...mapActions('FeesMastersSingle', ['fetchData','fetchShowData', 'resetState','fetchCourseDetailedData']),
        formatedFromDate(dbdate) {
            var local = dbdate.split('-');
            return local[2] + '/' +  local[1] + '/' + local[0]
        }
    }
}
</script>


<style scoped>

</style>
