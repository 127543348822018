<template>
    <div class="btn-group btn-group-xs">
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"
                title="Edit Status"
                :to="{ name: xprops.route + '.status', params: { id: row.id } }"
               >
                <span class="" v-if="value !== null">
                    <span v-if="value == 1" class="badge badge-info">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 2" class="badge badge-primary">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 3" class="badge badge-success">
                        {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 4" class="badge badge-danger">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else>
                         {{ row.inqStatus }}
                    </span> 
                </span>
        </router-link>
        <span v-else>
             <span class="" v-if="value !== null">
                    <span v-if="value == 1" class="badge badge-info">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 2" class="badge badge-primary">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 3" class="badge badge-success">
                        {{ row.inqStatus }}
                    </span>
                    <span v-else-if="value == 4" class="badge badge-danger">
                         {{ row.inqStatus }}
                    </span>
                    <span v-else>
                         {{ row.inqStatus }}
                    </span> 
                </span>
        </span>

       
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops','value', 'field'],
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        classMethod(id){
            if(id == 1)
            {
                return 'badge badge-info';
            }
            else if(id == 2)
            {
                return 'badge badge-primary';
            }else if(id == 3)
            {
                return 'badge badge-success';
            }else if(id == 4)
            {
                return 'badge badge-danger';
            }
        }
        
    }
}
</script>


<style scoped>

</style>
