<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Bypass Assessment</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Bypass Assessment List</h3>
                        </div>
                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import ByPassAssessmentDatatableActions from '../../dtmodules/DatatableActions/ByPassAssessmentDatatableActions'
import components from '../../comps/'


export default {
    components,
    data() {
        return {
            items:[],
            columns: [
                { title: 'Course', field: 'display_course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date' },
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Sub Topic', field: 'sub_topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Name' },
                { title: 'Actions', tdComp: ByPassAssessmentDatatableActions, visible: true, thClass: 'text-right', tdClass: '', tdStyle:'text-align: right' }
            ],
            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'ByPassAssessmentIndex',
                route: 'bypass_assessment',
                permission_prefix: 'bypass_assessment_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('ByPassAssessmentIndex', ['data', 'all','total', 'loading', 'relationships']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){
           
        }
    },
    methods: {
        ...mapActions('ByPassAssessmentIndex', ['fetchData', 'setQuery', 'resetState']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['display_course_name','topic_name','sub_topic_name'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData()
            
        } 
    }
}
</script>


<style scoped>

</style>
 