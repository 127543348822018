function initialState() {
    return {
        item: {
            id: null,
            course_name_id: null,
            course_topic: null,
            sub_topic: null,
        }, 
        coursesAll: [], 
        coursetopicsAll: [],
        subtopicsAll: [],        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    coursesAll: state => state.coursesAll,
    coursetopicsAll: state => state.coursetopicsAll,
    subtopicsAll: state => state.subtopicsAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if (_.isEmpty(state.item.course_name_id)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course_name_id.id)
            }     
            if (_.isEmpty(state.item.course_topic)) {
                params.set('topic_id', '')
            } else {
                params.set('topic_id', state.item.course_topic.id)
            }     
            if (_.isEmpty(state.item.sub_topic)) {
                params.set('sub_topic_id', '')
            } else {
                params.set('sub_topic_id', state.item.sub_topic.value_id)
            }                                   
            axios.post('/api/v1/storingBypassAssessment', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if (_.isEmpty(state.item.course_name_id)) {
                params.set('course_id', '')
            } else {
                params.set('course_id', state.item.course_name_id.id)
            }     
            if (_.isEmpty(state.item.course_topic)) {
                params.set('topic_id', '')
            } else {
                params.set('topic_id', state.item.course_topic.id)
            }     
            if (_.isEmpty(state.item.sub_topic)) {
                params.set('sub_topic_id', '')
            } else {
                params.set('sub_topic_id', state.item.sub_topic.value_id)
            }    
            axios.post('/api/v1/updatingBypassAssessment/'+ state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axios.get('/api/v1/showBypassAssessment/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    }) 
    },
    async fetchCoursesAll({ commit }) {
        // axios.get('/api/v1/courses')
        await axios.get('/api/v1/getCourseListWithOutFullCourse')
            .then(response => {                
                commit('setCoursesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })    
    },    
    async fetchCourseTopicsAll({ commit },course_id) {
        await axios.get('/api/v1/ctopicsList/'+ course_id)
            .then(response => {                
                commit('setCoursetopicsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })              
    },
    async fetchSubTopicsAll({ commit }) {
        await axios.get('/api/v1/subtopicList')
            .then(response => {                
                commit('setSubtopicsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })            
    },    
    setHolidayName({ commit }, value) {
        commit('setHolidayName', value)
    },
    setCourseId({ commit }, value) {
        commit('setCourseId', value)
    },
    setCourseTopicId({ commit }, value) {
        commit('setCourseTopicId', value)
    },
    setSubTopicId({ commit }, value) {
        commit('setSubTopicId', value)
    },        
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setCoursesAll(state, value) {
        state.coursesAll = value
    },    
    setCoursetopicsAll(state, value) {
        state.coursetopicsAll = value
    },
    setSubtopicsAll(state, value) {
        state.subtopicsAll = value
    },    
    setCourseId(state, value) {
        state.item.course_name_id = value
    },    
    setCourseTopicId(state, value) {
        state.item.course_topic = value
    },
    setSubTopicId(state, value) {
        state.item.sub_topic = value
    },    
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
