<template>
        
    <!-- <div class="col-md-12"> -->
        <form @submit.prevent="submitForm" novalidate>
                <bootstrap-alert />
                <div class="row">
                <div class="col-md-12 p-0">
                <div ref="enroll_date" class="form-group col-md-4 col-xs-12">
                    <label for="enroll_date">Admission Date<p class="astrisk">*</p></label>
                    <date-picker
                        :value="item.enroll_date"
                        :config="$root.dpconfigDatePast"
                        name="enroll_date"
                        v-validate="'required'"
                        @input="updateEnrollDate"
                        placeholder="Select Admission Date"
                        autocomplete="off"
                        >
                    </date-picker>                                      
                        <span class="error" v-show="errors.has('enroll_date')">
                                {{ $root.updateErrorMessage('enroll_date',this.errors,'Admission Date')}}
                        </span>
                </div>
                </div>
                <div class="clearfix"></div> 
                <div class="col-md-12 row">
                 <div class="form-group col-md-4 col-xs-12">
                        <label for="first_name">First Name<p class="astrisk">*</p></label>
                        <input
                                type="text"
                                class="form-control"
                                name="first_name"
                                ref="first_name"
                                maxlength="90"
                                v-validate="'required'"
                                placeholder="Enter First Name"
                                :value="item.first_name"
                                @input="updateFirst_name"
                                >
                        <span class="error" v-show="errors.has('first_name')">
                            {{ $root.updateErrorMessage('first_name',this.errors,'First Name')}}
                        </span>
                    </div>
                    <div class="form-group col-md-4 col-xs-12">
                        <label for="middle_name">Middle Name</label>
                        <input
                                type="text"
                                class="form-control"
                                name="middle_name"
                                ref="middle_name"
                                maxlength="90"
                                placeholder="Enter Middle Name"
                                :value="item.middle_name"
                                @input="updateMiddle_name"
                                >
                    </div>
                    <div class="form-group col-md-4 col-xs-12">
                        <label for="last_name">Last Name<p class="astrisk">*</p></label>
                        <input
                                type="text"
                                class="form-control"
                                name="last_name"
                                ref="last_name"
                                maxlength="90"
                                v-validate="'required'"
                                placeholder="Enter Last Name"
                                :value="item.last_name"
                                @input="updateLast_name"
                                >
                        <span class="error" v-show="errors.has('last_name')">
                            {{ $root.updateErrorMessage('last_name',this.errors,'Last Name')}}
                        </span>
                    </div>
                </div>
               
                <div class="clearfix"></div> 
                
                <div class="col-md-12 row">
                <div class="form-group col-md-4 col-xs-12">
                    <label for="gender">Gender<p class="astrisk">*</p></label>
                    <br>
                    
                        <div class="radio-inline">
                        <label class="contain">
                            <input
                                    type="radio"
                                    name="gender"
                                    ref="gender"
                                    v-validate="'required|included:1,2'"
                                    :value="1"
                                    :checked="item.gender === 1"
                                    @change="updateGender(1)"
                                    >
                            <span class="checkmark"></span> Male
                        </label>
                    </div>
                    <div class="radio-inline">
                        <label class="contain">
                            <input
                                    type="radio"
                                    name="gender"
                                    ref="gender"
                                    :value="2"
                                    :checked="item.gender === 2"
                                    @change="updateGender(2)"
                                    >
                            <span class="checkmark"></span> Female
                        </label>
                        
                    </div>
                    <br>
                    <span class="error" v-show="errors.has('gender')">
                        {{ $root.updateErrorMessage('gender',this.errors)}}
                    </span>
                </div>
                    
                    <div ref="birth_date" class="form-group col-md-4 col-xs-12">
                        <label for="birth_date">Birth Date<p class="astrisk">*</p></label>
                        <date-picker
                                :value="item.birth_date"
                                :config="dobDateOptionFormate"
                                name="birth_date"
                                v-validate="'required'"
                                placeholder="Select Birth Date"
                                @dp-change="updateBirth_date"
                                :endDate="disabledDates"
                                autocomplete="off"
                                >
                        </date-picker>
                        <span class="error" v-show="errors.has('birth_date')">
                            {{ $root.updateErrorMessage('birth_date',this.errors,'Birth Date')}}
                        </span>
                    </div>
                    <div ref="age" class="form-group col-md-4 col-xs-12" v-if="item.birth_date">
                        <label for="age">Age</label>
                        <p>{{item.age}}</p>
                    </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="form-group col-md-4 col-xs-12">
                        <label for="student_address">Student Address<p class="astrisk">*</p></label>
                        <textarea
                                rows="2"
                                class="form-control"
                                name="student_address"
                                ref="student_address"
                                v-validate="'required'"
                                maxlength="200"
                                placeholder="Enter Student Address"
                                :value="item.student_address"
                                @input="updateStudent_address"
                                >
                        </textarea>
                        <span class="error" v-show="errors.has('student_address')">
                            {{ $root.updateErrorMessage('student_address',this.errors,'Student Address')}}
                        </span>
                    </div>
                    <!-- <div class="clearfix"></div> -->
                    <div class="form-group col-md-4 col-xs-12">
                        <label for="area">Area<p class="astrisk">*</p></label>
                        <input
                                type="text"
                                class="form-control"
                                name="area"
                                v-validate="'required'"
                                ref="area"
                                maxlength="90"
                                placeholder="Enter Area"
                                :value="item.area"
                                @input="updateArea"
                                >
                         <span class="error" v-show="errors.has('area')">
                            {{ $root.updateErrorMessage('area',this.errors,'Area')}}
                        </span>
                    </div>
                    <div class="form-group col-md-4 col-xs-12">
                        <label for="pin_code">PIN/ZIP Code<p class="astrisk">*</p></label>
                        <input
                                type="text"
                                class="form-control"
                                name="pin_code"
                                v-validate="'required'"
                                ref="pin_code"
                                maxlength="20"
                                placeholder="Enter PIN/ZIP Code"
                                :value="item.pin_code"
                                @input="updatePin_code"
                                >
                         <span class="error" v-show="errors.has('pin_code')">
                            {{ $root.updateErrorMessage('pin_code',this.errors,'Pin Code')}}
                        </span>
                    </div>
                    <div class="clearfix"></div>

                    <div ref="country_list" class="form-group col-md-4 col-xs-12">
                        <label for="country">Country<p class="astrisk">*</p></label>
                        <v-select
                                name="country_list"
                                label="country_name"
                                v-validate="'required'"
                                placeholder="Select Country"
                                @input="updateCountry_list"
                                :value="item.country_list"
                                :options="countrymastersAll"
                                />
                        <span class="error" v-show="errors.has('country_list')">
                            {{ $root.updateErrorMessage('country_list',this.errors,'Country')}}
                        </span>
                    </div>
                    <div ref="state_list" class="form-group col-md-4 col-xs-12 ">
                        <label for="state">State<p class="astrisk">*</p></label>
                        <v-select
                                name="state_list"
                                label="state_name"
                                v-validate="'required'"
                                placeholder="Select State"
                                @input="updateState_list"
                                :value="item.state_list"
                                :options="statemastersAll"
                                class="parentName"
                                />

                        <AddStatePopup class="addButtonStud" v-if="$can('state_create') && statePopupButton"></AddStatePopup>
                        <span class="error" v-show="errors.has('state_list')">
                            {{ $root.updateErrorMessage('state_list',this.errors,'State')}}
                        </span>
                    </div>
                    <div ref="city_list" class="form-group col-md-4 col-xs-12 ">
                        <label for="city">City<p class="astrisk">*</p></label>
                        <v-select
                                name="city_list"
                                label="city_name"
                                v-validate="'required'"
                                placeholder="Select City"
                                @input="updateCity_list"
                                :value="item.city_list"
                                :options="citymastersAll"
                                />
                        <span class="error" v-show="errors.has('city_list')">
                            {{ $root.updateErrorMessage('city_list',this.errors,'City')}}
                        </span>
                    </div>
                    <div class="clearfix"></div>
                    <div ref="parent" class="form-group col-md-4 col-xs-12">
                        <label for="parent">Parent Name<p class="astrisk">*</p></label>
                        <v-select
                                name="parent"
                                label="parent_mobile"
                                maxlength="90"
                                v-validate="'required'"
                                @input="updateParent"
                                :value="item.parent"
                                class="parentName"
                                :options="parentAll"
                                />
                        <AddParentPopup :contactEmail="inquiryShowData.contact_email" :contactName="inquiryShowData.contact_name" :contactNumber="inquiryShowData.contact_number" class="addButtonStud" v-if="$can('parent_create')"></AddParentPopup>                                            
                        <span class="error" v-show="errors.has('parent')">
                            {{ $root.updateErrorMessage('parent',this.errors,'Parent Name')}}
                        </span>
                    </div>
                    <div ref="school" class="form-group col-md-4 col-xs-12">
                        <label for="school">School Name<p class="astrisk">*</p></label>
                        <v-select
                                name="school"
                                label="school_name"
                                @input="updateSchool"
                                v-validate="'required'"
                                :value="item.school"
                                class="parentName"                                        
                                :options="schoolAll"
                                />
                        <AddSchoolPopup class="addButtonStud" v-if="$can('school_master_create')"></AddSchoolPopup>                                            
                        <span class="error" v-show="errors.has('school')">
                            {{ $root.updateErrorMessage('school',this.errors,'School Name')}}
                        </span>
                         <input
                            v-if="item.school != null && item.school.id == 0"
                            type="text"
                            style="margin-top:10px;"
                            class="form-control"
                            name="other_school_name"
                            v-validate="'required'"
                            ref="other_school_name"
                            maxlength="100"
                            placeholder="Enter Other School Name"
                            :value="item.other_school_name"
                            @input="updateOtherSchoolName"
                            >
                        <span class="error" v-show="errors.has('other_school_name')">
                            {{ $root.updateErrorMessage('other_school_name',this.errors,'Other School Name')}}
                        </span>
                    </div>
                    <div ref="school" class="form-group col-md-4 col-xs-12">
                        <label for="standard_id">Standard<p class="astrisk">*</p></label>
                        <v-select
                                name="standard_id"
                                label="name"
                                @input="updateStandard_id"
                                v-validate="'required'"
                                :value="item.standard_id"
                                :options="standardAll"
                                />
                        <span class="error" v-show="errors.has('standard_id')">
                            {{ $root.updateErrorMessage('standard_id',this.errors,'Standard')}}
                        </span>
                    </div>       
                    <div class="clearfix"></div>   
                    <div class="col-md-4 col-xs-12">
                        <div class="form-group">
                            <label for="logo">Profile </label>
                            <input
                                    type="file"
                                    name="profile"
                                    id="profile"
                                    v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg'"
                                    class="form-control"
                                    @change="updateProfile"
                                >
                        <span class="error" v-show="errors.has('profile')">
                            {{ $root.updateErrorMessage('profile',this.errors,'Profile')}}
                        </span>
                            <ul v-if="item.profile" class="list-unstyled text-center">
                                <li>
                                <div class="" style="">
                                    <div v-if="item.profile != null">
                                        <img :src="item.profile" id="blah" class="student_image profile-user-img my-profile img-responsive img-circle" :alt="item.first_name" > 
                                        
                                    </div>
                                    <div v-else>
                                        <img src="/assets/img/male.jpg" class="img-circle my-profile" :alt="item.first_name" > 
                                    </div>
                                </div>
                                    <button class="btn btn-xs btn-danger"
                                            type="button"
                                            @click="removeProfile"
                                    >
                                        Remove file
                                    </button>
                                </li>
                            </ul>

                        </div>
                    </div>   
                    <div class="my-breck-div"></div>                           
                    <div class="col-md-4">
                        <div ref="tshirt_size" class="form-group">
                            <label for="tshirt_size">T-shirt Size</label>
                            <v-select
                                    name="tshirt_size"
                                    label="name"
                                    @input="updateTshirtSize"
                                    :value="item.tshirt_size"
                                    :options="tshirtSizesAll"                                        
                                    />
                        </div>
                    </div>
                    <div ref="reference" class="form-group col-md-4 col-xs-12">
                        <label for="reference">Reference Name<p class="astrisk">*</p></label>
                        <v-select
                                name="reference"
                                label="name"
                                @input="updateReference"
                                v-validate="'required'"
                                :value="item.reference"
                                :options="referenceAll"
                                />
                        <span class="error" v-show="errors.has('reference')">
                            {{ $root.updateErrorMessage('reference',this.errors,'Reference Name')}}
                        </span>                                
                    </div>                                
                                            
            <!-- <div class="col-md-2 col-xs-12 savebtn" v-if="courseList"> 
                <vue-button-spinner
                        class="std_information_save btn btn-block btn-success new_branch_save"
                        :isLoading="loading"
                        :disabled="loading"
                        >
                    Save
                </vue-button-spinner>
            </div> -->
            </div>
            <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
        </form>
        
    <!-- </div> -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import AddParentPopup from '../../dtmodules/PopupActions/AddParentPopup'
import AddSchoolPopup from '../../dtmodules/PopupActions/AddSchoolPopup'
import AddStatePopup from '../../dtmodules/PopupActions/AddStatePopup'
import fetchStudentPopup from '../../dtmodules/PopupActions/fetchStudentPopup'

export default {
components: { AddParentPopup, AddSchoolPopup, AddStatePopup, fetchStudentPopup },
props:['inquiryId'],
data() {
return {
    autoComplete: false,
    onlySummerCamp: true,
    addButtonSeen:false,
    statePopupButton: true,
    hasError: false,
    courseForm : false,
    courseList : true,
    arrIndex : false,
    inputParams : [],
    inputAddMoreFeeTypeParams : null,
    deleteFeeDetailParams : [],
    inputFeeTypeParams : [],
    inputFeeAmountParams : [],
    inputFeeDiscountParams : [],
    inputFeeGSTValue : [],
    inputFeeGSTParams : [],
    inputFeeTotalParams : 0,            
    newsubTotal : [],
    subTotal : [],
    subFinalTotal : [],
    fee_list : [],
    inqCourse :'',
    saveStartDate :'',
    inqBatchTime  : '',
    courseEdit : false,
    courseEditIndex : '',
    preferDaysChecked : [],
    checkedEdit : true,         
    diabledEdit : false,         
    seenStudentReason:false,
    feeAvailableCourseList : [],
    addbtnHide:0,
    feeSeen:false,
    dobDateOptionFormate:{format:window.date_format_moment, maxDate: this.getDOBDate()}
    // Code...
}
},


computed: {
...mapGetters('InquiriesSingle', ['inquiryShowData']),
...mapGetters('StudentSingle', ['item', 'loading', 'feeAdditionalAdjustment','countrymastersAll', 'statemastersAll', 'citymastersAll', 'parentAll','tshirtSizesAll', 'schoolAll', 'standardAll','studentStatusAll','studentReasonAll', 'statusAll', 'reasonAll','courseListAll','courseAll','batchAll','feeCourseList','feesTypeAvailableListAll','courseComboDetailsAll','branchAdmissionFeesApplicable','referenceAll']),
},
created() {

this.fetchCountrymastersAll(),
// this.fetchFeeAdditionalAdjustmentCharge(),
// this.fetchStatemastersAll(),
this.fetchCountryStates(99),
this.fetchStateCities();
this.fetchParentAll(),
this.fetchTshirtSizesAll(),
this.fetchSchoolAll(),
this.fetchStandardAll(),
// this.fetchStudentStatusAll(),
// this.fetchStudentReasonAll(),
// this.fetchCourseAll(),
this.fetchReferenceAll()

// this.fetchFeesTypeAvailableListAll()
// this.fetchBranchMasterAll()

//this.fetchStatusAll()
//console.log("inquiry_id",this.inquiryId);
if(this.inquiryId != ''){
    this.fetchInquiryData(this.inquiryId);
}
// this.fetchInquiryData(21);
},
destroyed() {
this.resetState()
},
watch: {
"$route.params.id": function() {
    this.resetState()
    this.fetchData(this.$route.params.id)
},
inquiryShowData(value){
    if(value != ''){
        let student_name = (value.student_name).split(' ');
        if(student_name.length==1){
            this.setFirst_name(student_name[0])
        }
        else if(student_name.length==2){    
            this.setFirst_name(student_name[0])
            this.setLast_name(student_name[1])
        }
        else{
            this.setFirst_name(student_name[0])
            this.setMiddle_name(student_name[1])
            this.setLast_name(student_name[2])
        }
        //console.log("studenrt",student_name);
        this.setCountry_list(value.country_list);
        this.setState_list(value.state_list);
        this.setCity_list(value.city_list);
        this.setReference(value.source1);
        this.setStandard_id(value.standard_id);
        
        if(value.school_id == 0){
            this.setSchool({"id":0,"school_name":"Other School","school_board":null,"country":null,"created_at":null,"updated_at":null,"deleted_at":null});
            this.setOtherSchoolName(value.other_school_name);
        }
        else{
            this.setSchool(value.school);
        }

    }   
}
},
methods: {
...mapActions('InquiriesSingle', ['fetchInquiryData']),
...mapActions('StudentSingle', ['storeData', 'fetchBranchMasterAll','resetState', 'setOnlySummerCampGlobal','setBranch_id', 'setEnrollDate','setFirst_name', 'setMiddle_name', 'setLast_name', 'setStudent_address', 'setArea', 'setCountry_list', 'setState_list','setCity_list', 'setGender', 'setPin_code','setOtherSchoolName','setInquiryCourse', 'setBirth_date', 'setParent', 'setTshirt', 'setTshirt', 'setSchool', 'setStudentSta', 'setStandard_id','setStudent_status','setStudent_reason', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchFeeAdditionalAdjustmentCharge','fetchCountrymastersAll','fetchStatemastersAll','fetchCitymastersAll','fetchParentAll','fetchTshirtSizesAll','fetchSchoolAll','fetchStandardAll','fetchCourseAll','setCourseListAll','setCourse','fetchBatchAll','fetchFeeCourseList','setFeeCourseList','setBatch','fetchStudentStatusAll','fetchStudentReasonAll','fetchCountryStates','fetchStateCities','setStart_date','fetchFeesTypeAvailableListAll','setFeesTypeAvailableListAll','setCourseAll','setStatemastersAll','setCitymastersAll','fetchComboFromCourseDetails','setProfile','fetchIfBranchAdmissionFeesApplicable','setReference','fetchReferenceAll']),
closeModel(){
    this.$emit('closeModel');
},
disabledDates() {
  from: new Date(Date.now())
},
updateReference(value) {
    this.setReference(value)
},        
removeProfile(e, id) {
    this.$swal({
        title: 'Are you sure you want to delete this image?',
        text: "To fully delete the file submit the form.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dd4b39',
        focusCancel: true,
        reverseButtons: true
    }).then(result => {
        if (typeof result.dismiss === "undefined") {
            this.setProfile('');
             $("#profile").val('');
        }
    })
}, 
updateProfile(e) {
    if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
            reader.onload = function (e) {
                $('#blah')
                    .attr('src', e.target.result)
                    .width(150)
                    .height(200);
            };
            reader.readAsDataURL(e.target.files[0]);
            this.setProfile(e.target.files[0]);
            this.$forceUpdate();
        }else{
            var message = "";
            if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                message = 'File size should be less then 2MB.'
            }
            else {
                message = 'Only JPG, JPEG, PNG, GIF files are allowed'
            }
             this.$swal({
                title: message,
                type: 'error',                      
                focusCancel: true,                       
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    $("#profile").val('');
                    this.$validator.errors.remove('profile');
                    this.setProfile('');
                }
            })
        }
    }           
}, 
 /*this function use to store fees total*/
sumTotalFee(){
    var ifOnlySummerCampLocal = true;
    var total = 0;
    var total1 = 0;
    if(this.feeAdditionalAdjustment.length > 0) {
        var total1 = this.feeAdditionalAdjustment[0].additionalFees;
    }
    var onlyFiveArray = [],
    otherThanFiveArray = [],
    allCourseType = [];
    var filteredObj = this.courseListAll.find(function(item, i){
    total = parseFloat(total) + parseFloat(item.total_fees);
      // if(item.course_level != 5){
      //   otherThanFiveArray.push(item.course_level);
      // } else {
      //   onlyFiveArray.push(item.course_level);
      // }
      if(allCourseType.indexOf(item.course_type) == -1) {
        allCourseType.push(item.course_type);
      }              
    });
    this.fetchIfBranchAdmissionFeesApplicable(allCourseType)
        .then(()=>{
            // ifOnlySummerCampLocal = this.branchAdmissionFeesApplicable[0];
            this.onlySummerCamp = this.branchAdmissionFeesApplicable[0];
            this.setOnlySummerCampGlobal(this.branchAdmissionFeesApplicable[0]);
        })            
    // if(otherThanFiveArray.length == 0 && onlyFiveArray.length >= 1) {
    //     ifOnlySummerCampLocal = false;
    // }
    
    // this.onlySummerCamp = ifOnlySummerCampLocal;
    // this.setOnlySummerCampGlobal(ifOnlySummerCampLocal);
    if(this.onlySummerCamp) {
        total1 = parseFloat(total1) + parseFloat(total);
        // return this.sumSubTotal = total1.toFixed(2);
        return '';
    } else {
        // return this.sumSubTotal = total.toFixed(2);
        return '';
    }
},

//this is use for add more  btn from add or edit course
// setCourseListAll
updateFeeAmount(index){
    if(!this.inputFeeAmountParams[index])
    {
        this.inputFeeDiscountParams[index] = '';
        this.calculateNewDiscount(index);
    }
    else if(parseFloat(this.inputFeeAmountParams[index]) < parseFloat(this.inputFeeDiscountParams[index]))
    {
        this.inputFeeDiscountParams[index] = '';
        this.calculateNewDiscount(index);
    }
},
addCourseFeesData(myCustomeIndex){
    if(!this.inputAddMoreFeeTypeParams && (!this.courseEdit || typeof myCustomeIndex=='undefined')){  
        this.$validator.errors.remove('addMoreFeesType');
        this.$validator.errors.remove('payment_amount_check');
        this.$validator.errors.add({
          id: 'addMoreFeesType',
          field: 'addMoreFeesType',
          msg: 'Fees Type field is required.',
          scope: this.$options.scope,
        });
        return true;
    }else {

       this.$validator.errors.remove('addMoreFeesType');
        if(this.inputAddMoreFeeTypeParams){
         // this.inputFeeTypeParams[this.feeAvailableCourseList.length-1] =  this.inputAddMoreFeeTypeParams;  ahsish logic
         this.inputFeeTypeParams[(this.inputAddMoreFeeTypeParams && this.inputAddMoreFeeTypeParams.value_id)?this.inputAddMoreFeeTypeParams.value_id:this.deleteFeeDetailParams[myCustomeIndex]] =  this.inputAddMoreFeeTypeParams; 
        }
        this.feeAvailableCourseList.push({'deleteFeeDetailParams':(myCustomeIndex)?myCustomeIndex:this.inputAddMoreFeeTypeParams.value_id});    
        // this.feeAvailableCourseList.push({'deleteFeeDetailParams':(this.inputAddMoreFeeTypeParams && this.inputAddMoreFeeTypeParams.value_id)?this.inputAddMoreFeeTypeParams.value_id:this.deleteFeeDetailParams[index]});  
        this.addbtnHide = this.addbtnHide + 1;
        setTimeout(function () { 
        var feesTypeUseList = '';
        feesTypeUseList = purify(this.feesTypeAvailableListAll); 
        
        this.feeCourseList.forEach(function(fee,index){
            feesTypeUseList.forEach(function(allFee,allIndex){
                var feeValueId = null;
                if(typeof fee.fees_type.value_id == 'undefined')
                {
                    feeValueId = fee.fees_type;
                }
                else{
                    feeValueId = fee.fees_type.value_id;   
                }
                if(allFee.value_id == feeValueId)
                {
                    feesTypeUseList.splice(allIndex,1)
                }
               
            }.bind(this))
        }.bind(this))     
         /*added value*/
            this.feeAvailableCourseList.forEach(function(selectedCourseList,addedIndex){
                feesTypeUseList.forEach(function(allFee,allIndex){
                    if(selectedCourseList.deleteFeeDetailParams && this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams] && this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams].value_id == allFee.value_id)
                    {
                        feesTypeUseList.splice(allIndex,1)
                    }                           
                }.bind(this))
            }.bind(this))
            this.inputAddMoreFeeTypeParams=null;
            /*end added value*/ 
            if(feesTypeUseList.length == 0){
                this.addButtonSeen = false;
            }else{
                this.addButtonSeen =true;
            }
        

            this.setFeesTypeAvailableListAll(feesTypeUseList);
        }.bind(this), 440)    
    }               
},
//this is use for remove btn from add or edit course
removeNewCourseFeesData(myCustomeIndex){
    // this.addbtnHide = this.addbtnHide - 1;
    // this.feeAvailableCourseList.splice(index,1);
       // this.addbtnHide = this.addbtnHide - 1;
     // $('#deletetTr').find('tr[data-myCustomeIndex='+myCustomeIndex+']').remove();
    
   
    // $('#deletetTr').find('tr[data-index='+myCustomeIndex+']').remove();
    // this.feeAvailableCourseList.splice(myCustomeIndex, 1);                        
    this.feeAvailableCourseList.forEach(function(selectedCourseList,addedIndex){                
        if (selectedCourseList && selectedCourseList.deleteFeeDetailParams == myCustomeIndex){
            delete this.inputFeeTypeParams[myCustomeIndex];
            delete this.deleteFeeDetailParams[myCustomeIndex];
            delete this.inputFeeDiscountParams[myCustomeIndex];
            delete this.inputFeeAmountParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            delete this.inputFeeGSTParams[myCustomeIndex];
            this.feeAvailableCourseList.splice(addedIndex, 1);                       
            return false;
        }               
    }.bind(this))       
    this.fetchFeesTypeAvailableListAll()
    .then((res) => {
        var feesTypeUseList = '';
        feesTypeUseList = purify(this.feesTypeAvailableListAll); 
        
        this.feeCourseList.forEach(function(fee,index){
            feesTypeUseList.forEach(function(allFee,allIndex){
                var feeValueId = null;
                if(typeof fee.fees_type.value_id == 'undefined')
                {
                    feeValueId = fee.fees_type;
                }
                else{
                    feeValueId = fee.fees_type.value_id;   
                }
                if(allFee.value_id == feeValueId)
                {
                    feesTypeUseList.splice(allIndex,1)
                }
               
            }.bind(this))
        }.bind(this))     
         /*added value*/
            this.feeAvailableCourseList.forEach(function(selectedCourseList,addedIndex){
                feesTypeUseList.forEach(function(allFee,allIndex){
                    if(selectedCourseList.deleteFeeDetailParams && this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams] && this.inputFeeTypeParams[selectedCourseList.deleteFeeDetailParams].value_id == allFee.value_id)
                    {
                        feesTypeUseList.splice(allIndex,1)
                    }                           
                }.bind(this))
            }.bind(this))
            this.inputAddMoreFeeTypeParams=null;  
             if(feesTypeUseList.length == 0){
                this.addButtonSeen = false;
            }else{
                this.addButtonSeen =true;
            }                 
            this.setFeesTypeAvailableListAll(feesTypeUseList); 
    })
    //setTimeout(function () { 
        
        //}.bind(this), 740)    
},
 updateStart_date(e) {
    if(e && e.target){
        this.setStart_date(e.target.value)
    }else{
        if(e){
           this.setStart_date(e)
        }else{
            this.setStart_date(null)
        }
    }
    this.fetchBatch(this.item.course);
},
calculateDiscount(feeAmount, index) {
    if(parseFloat(this.inputParams[index]) > feeAmount){
        this.inputParams[index] = parseFloat(feeAmount).toFixed(2);
    }
    // else{
    //     if(this.inputParams[index] =='')
    //     {
    //         this.inputParams[index] = "0";  
    //     }
    // }
},     
calculateNewDiscount(index) {
    if(!this.inputFeeAmountParams[index])
    {
        this.inputFeeDiscountParams[index] = '';
    }
    else if(parseFloat(this.inputFeeDiscountParams[index]) > parseFloat(this.inputFeeAmountParams[index])){
        this.inputFeeDiscountParams[index] = parseFloat(this.inputFeeAmountParams[index]).toFixed(2);
    }
},     
getDOBDate(){
    var d = new Date();
    var pastYear = d.getFullYear() - 5;
    d.setFullYear(pastYear);
    return d;
},
updateBranch_id(e) {
    this.setBranch_id(e.target.value)
},
updateEnrollDate(e) {
    this.setEnrollDate(e);
},            
updateFirst_name(e) {
    this.setFirst_name(e.target.value)
},
updateMiddle_name(e) {
    this.setMiddle_name(e.target.value)
},
updateLast_name(e) {
    this.setLast_name(e.target.value)
},
updateStudent_address(e) {
    this.setStudent_address(e.target.value)
},
updateArea(e) {
    this.setArea(e.target.value)
},
updateCountry_list(value) {
            this.setState_list(null);
            this.setCity_list(null);
          if(value !== null && value !==''){
                this.fetchCountryStates(value.id);
                this.statePopupButton = true;
            }
            else {
                this.setCitymastersAll([]);
                this.setStatemastersAll([]);
                this.statePopupButton = false;
            }
            this.setCitymastersAll([]);
            this.setCountry_list(value)
},
updateState_list(value) {
    this.setCity_list(null);
  if(value !== null && value !==''){
        this.fetchStateCities(value.id);
        // this.statePopupButton = true;             
    }
  else{
    this.setCitymastersAll([]);
    // this.statePopupButton = false;
  }
    this.setState_list(value)
},
updateCity_list(value) {
    this.setCity_list(value)
},
updateGender(value) {
    this.setGender(value)
},
updatePin_code(e) {
    this.setPin_code(e.target.value)
},
updateOtherSchoolName(e){
    this.setOtherSchoolName(e.target.value)
},
updateBirth_date(e) {
    this.setBirth_date(e.target.value)
    this.setAge(e.target.value)
},
updateParent(value) {
    this.setParent(value)
},
updateTshirtSize(value) {
    this.setTshirt(value)
},
updateSchool(value) {
    this.setOtherSchoolName(null)
    this.setSchool(value)
},
updateStudent_status(value) {
    if(value && value.value_id == 2){
        this.seenStudentReason =  true;
    }else{
        this.seenStudentReason =  false;
    }
    this.setStudent_status(value)
},
updateStudent_reason(value) {
    this.setStudent_reason(value)
},
updateStandard_id(value) {
    this.setStandard_id(value)
},
updateStatus(value) {
    this.setStatus(value)
},
updateNext_followup_date(e) {
    this.setNext_followup_date(e.target.value)
},
updateReason(value) {
    this.setReason(value)
},
updateStatus_remarks(e) {
    this.setStatus_remarks(e.target.value)
},
submitForm() {
    this.$validator.validate().then(result => {
        if (result) {                  
            //  if(this.courseListAll.length == 0){
            //     this.$validator.errors.add({
            //           id: 'nocourseAdded',
            //           field: 'nocourseAdded',
            //           msg: 'At least one course need to added.',
            //           scope: this.$options.scope,
            //     });                         
            //     return false;              
            // }else{
            //     this.$validator.errors.remove('nocourseAdded');
            // }
            this.storeData()
            .then((res) => {
                if(!res.data.result)
                {
                   for (const [key, value] of Object.entries(res.data.data)) {
                    console.log("ke",key,value);
                    this.$validator.errors.add({
                        id: key,
                        field: key,
                        msg: value[0],
                        scope: this.$options.scope,
                    });
                }
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }  
                let obj = {
                    'id':res.data.data.id
                };
                if(this.inquiryShowData.course_id != 0){
                    obj = {
                        'id':res.data.data.id,
                        'course':this.inquiryShowData.course
                    }
                }    
               
                this.$emit('closeModel',obj);
                this.$emit('loadItems');
                this.$eventHub.$emit('update-success')
            })
            .catch((error) => {
                console.error(error)
            })
        }
      else{
        return this.$root.handleValidationFocus(this.errors,this.$refs);
    }                
    });
},

removeCourceList(id){
    //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
    var courceListLocal = [];
    courceListLocal = purify(this.courseListAll);
    courceListLocal.splice(id,1);
    this.setCourseListAll(courceListLocal);
    var sendCourseData = [0];//temp solution for listing all course after removing selected course.
    this.fetchComboFromCourseDetails(sendCourseData)
    .then(res => {
        if(this.courseComboDetailsAll){
            this.setCourseAll(this.courseComboDetailsAll);
        }
    })
  
},
updatePreferDays(e) {
    this.checkedEdit = false;
    var updatePermissionVal = e.target.value.toString();
    var indexVal = $.inArray( updatePermissionVal, this.preferDaysChecked);
    if (indexVal == -1){
            this.preferDaysChecked.push(updatePermissionVal);
        }else{                    
            this.preferDaysChecked.splice(indexVal,1);
        }
},
checkedPreferDays(value) {
    if(this.checkedEdit && this.courseEdit){
        var preferdaysLocal = '';
        preferdaysLocal = purify(this.courseListAll[this.courseEditIndex].prefer_days);
        preferdaysLocal = preferdaysLocal.split(',');
        
        var ret = preferdaysLocal.filter(function(el){
            return el == value;                                
        });
        if(ret.length>0)
        {
             if ($.inArray( ret[0], this.preferDaysChecked) == -1){
                this.preferDaysChecked.push(ret[0]);  
                return true;
             }
        }
    }
},
addCource(){
    this.seenStudentReason =  false; //default false student reason
    this.fetchFeesTypeAvailableListAll(); //fetch fees list data
    this.$validator.errors.remove('course');//when course cancel
    this.$validator.errors.remove('nocourseAdded');//when course not available 
    this.diabledEdit = false;
    //delete this.feeList;
    // var p = this.fetchCourseAll();
    // this.setCourseAll(p);

    //This code is used for preventing duplicate entry in Course & fee information (and also prevents adding Full course and individual course individually)
    var courseSelectedList = '';
    //delete this.feeList;
    courseSelectedList = purify(this.courseAll);
    var sendCourseData = []
    this.courseListAll.forEach(function(courseList,index){
        sendCourseData.push(courseList.course_id);
    })
    if(sendCourseData.length > 0) {
        this.fetchComboFromCourseDetails(sendCourseData)
        .then(res => {
            if(this.courseComboDetailsAll){
                this.setCourseAll(this.courseComboDetailsAll);
            }

    // this.fetchCourseAll()
    // .then((res) => {
    //     var courseSelectedList = '';
    //     courseSelectedList = purify(this.courseAll); 
    //     this.courseListAll.forEach(function(courseList,index){
    //             courseSelectedList.forEach(function(selectedCourseList,allIndex){
    //             if(courseList.course_id == selectedCourseList.id)
    //             {   
    //                 courseSelectedList.splice(allIndex,1)
    //             }
    //         }.bind(this))
    //     }.bind(this))
    //     if(courseSelectedList){
    //         this.setCourseAll(courseSelectedList);
    //     }
    })
  }
        this.feeAvailableCourseList=[];
        this.courseEdit = false;

        this.setCourse(null);
        this.setBatch(null);
        this.setStart_date(null);
        this.setStudent_status(null); 
        this.setStudent_reason(null); 

        // this.newsubTotal=[];
                
        this.inputParams = [];
        this.inputFeeTypeParams= [];
        this.inputFeeDiscountParams= [];
        this.inputFeeAmountParams = [];
        this.inputFeeGSTParams= [];
        this.courseEditIndex = '';
        this.fee_list = [];
        this.setFeeCourseList('');
        this.inputParams = [];
        this.subTotal = [];

        this.courseForm = true;
        this.courseList = false;
        this.arrIndex = false;
},
editCource(id){    
    this.$validator.errors.remove('nocourseAdded');//when course not available         
    //delete this.feeList;
    //this.fetchFeesTypeAvailableListAll(); //fetch fees list data
    // this.setCourseAll();
    this.checkedEdit = true;
    this.courseEdit = true;
    this.diabledEdit = true;
    this.courseEditIndex = id;
    this.deleteFeeDetailParams= [];
    this.inputParams = [];
    this.inputFeeTypeParams= [];
    this.inputFeeDiscountParams= [];
    this.inputFeeAmountParams = [];
    this.inputFeeGSTParams= [];
    var courceListLocal = [];
    
    
},
assignDiscount(feeDiscountLocal){
    this.inputParams = feeDiscountLocal;
},
backCourse(){
    this.$validator.errors.remove('addMoreFeesType');
    this.fetchFeesTypeAvailableListAll(); //re fetch fees list data            
    //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select  course dropdown
    this.checkedEdit = false;
    this.courseEdit = false;
    this.courseEditIndex = false;
    this.courseForm = false;
    this.courseList = true;
    this.arrIndex = false;
    this.feeAvailableCourseList=[];

},
saveCourse(){           
   //setTimeout(function () { this.fetchCourseAll(); }.bind(this), 420)//this is use for refress select course dropdown
   this.$validator.errors.remove('nocourseAdded');//when course not available         
    this.hasError = false;
    var course = {};
   // course.prefer_days = this.preferDaysChecked.join();
    if (this.inqCourse == null || typeof this.inqCourse.id === 'undefined' )
    {
        this.$validator.errors.remove('course');
        this.$validator.errors.add({
          id: 'err_select_course',
          field: 'course',
          msg: 'Course Name field is required.',
          scope: this.$options.scope,
        });
        this.hasError = true;
    }else {
        this.$validator.errors.remove('course');
    }
    
    if(this.hasError) {
        return false;
    }
    course.course_level = this.inqCourse.course_level;
    course.course_type = this.inqCourse.course_type;
    course.course_id = this.inqCourse.id;
    course.course_type = this.inqCourse.course_type;
    course.display_course_name = (this.inqCourse.display_course_name)?this.inqCourse.display_course_name:'';
    // course.student_course_id = this.inqCourse.student_course_id;
    course.course_start_date = (this.item.start_date)?this.item.start_date:'';
    course.course_status = (this.item.student_status)?this.item.student_status:'';
    course.status_reason = (this.item.student_reason && this.item.student_reason && this.item.student_status.value_id == 2 )?this.item.student_reason:'';
    
    // var totalFee = 0;
    // this.fee_list.forEach(function(fee){
    //     if(parseFloat(fee.subtotal) > 0)
    //     {
    //         totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);    
    //     }
    //     else
    //     {   totalFee = parseFloat(totalFee) + parseFloat(fee.fees_amount);       
    //     }
        
    // })
    // this.newsubTotal.forEach(function(fee,index){
    //     totalFee = parseFloat(totalFee) + parseFloat(fee);
    // })
    // course.total_fees = totalFee.toFixed(2);
    
    var new_fees_detail =[];
    var errFlag = false;
    //this is use when add more fees detail that time run following code(Rajesh Rathod 02-10-2018)
    this.feeAvailableCourseList.forEach(function(fee,index){
        new_fees_detail[fee.deleteFeeDetailParams] = {};
        new_fees_detail[fee.deleteFeeDetailParams].is_manual_fee = 1;//this is use for flag add more value available 
        new_fees_detail[fee.deleteFeeDetailParams].student_course_id = this.inqCourse.student_course_id;
        new_fees_detail[fee.deleteFeeDetailParams].course_id = this.inqCourse.id;
        new_fees_detail[fee.deleteFeeDetailParams].deleteFeeDetailParams = fee.deleteFeeDetailParams;
        new_fees_detail[fee.deleteFeeDetailParams].discount = this.inputFeeDiscountParams[fee.deleteFeeDetailParams];
        new_fees_detail[fee.deleteFeeDetailParams].fees_amount = this.inputFeeAmountParams[fee.deleteFeeDetailParams];
        new_fees_detail[fee.deleteFeeDetailParams].fees_type= this.inputFeeTypeParams[fee.deleteFeeDetailParams];
        new_fees_detail[fee.deleteFeeDetailParams].gst_rate= this.inputFeeGSTParams[fee.deleteFeeDetailParams];
        new_fees_detail[fee.deleteFeeDetailParams].total_fees= this.newsubTotal[fee.deleteFeeDetailParams];
        new_fees_detail[fee.deleteFeeDetailParams].fees_after_discount= this.newsubtotalCalc(fee.deleteFeeDetailParams);
        new_fees_detail[fee.deleteFeeDetailParams].subtotal = new_fees_detail[fee.deleteFeeDetailParams].fees_after_discount;
        if(new_fees_detail[fee.deleteFeeDetailParams].fees_amount == '' || new_fees_detail[fee.deleteFeeDetailParams].fees_amount == null || new_fees_detail[fee.deleteFeeDetailParams].fees_amount == 0 || new_fees_detail[fee.deleteFeeDetailParams].fees_type == null || new_fees_detail[fee.deleteFeeDetailParams].fees_type == 'undefined' )
        {
            errFlag = true;
        }
        
    }.bind(this));
    if(errFlag)
    {
        this.$validator.errors.remove('addMoreFeesType');
        this.$validator.errors.add({
          id: 'fee_amount',
          field: 'fee_amount',
          msg: 'Fee amount should be valid.',
          scope: this.$options.scope,
        });
        return false;
    }
    else{
        this.$validator.errors.remove('fee_amount');;
    }
    this.fetchFeesTypeAvailableListAll(); //fetch fees list data
    this.inputAddMoreFeeTypeParams = null;
    course.fee_list = this.fee_list.concat(new_fees_detail);
    var courceListLocal = [];

/**********************/
    var totalFee = 0;
    course.fee_list.forEach(function(fee){
        if(fee.subtotal)
        {
            totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);    
        }
        else
        {   
            totalFee = parseFloat(totalFee) + parseFloat(fee.fees_amount);       
        }
        //totalFee = parseFloat(totalFee) + parseFloat(fee.subtotal);
    })
    course.total_fees = totalFee.toFixed(2);
/**********************/

    
    courceListLocal = purify(this.courseListAll);
    if(this.courseEdit === true)
    {
        courceListLocal.splice(this.courseEditIndex,1);
        this.courseEdit = false;
        this.courseEditIndex = '';
    }
    courceListLocal.push(course);
    this.setCourseListAll(courceListLocal);

    this.courseForm = false;
    this.courseList = true;
    this.arrIndex = false;
    this.feeAvailableCourseList = [];
},       
updateCourse(value){
    this.fetchFeesTypeAvailableListAll();
    //.then((res) => {
        //this.setCourse(value);
        if(!this.courseEdit){
            this.feeAvailableCourseList=[];/*30-10-2018*/   
        }
        
        if(value){
            this.addButtonSeen=true;
            
             //setTimeout(function () {
                var feesTypeUseList = purify(this.feesTypeAvailableListAll); 
                this.feeCourseList.forEach(function(fee,index){
                    feesTypeUseList.forEach(function(allFee,allIndex){
                        var feeValueId = null;
                        if(typeof fee.fees_type.value_id == 'undefined')
                        {
                            feeValueId = fee.fees_type;
                        }
                        else{
                            feeValueId = fee.fees_type.value_id;   
                        }
                        if(allFee.value_id == feeValueId)
                        {
                            feesTypeUseList.splice(allIndex,1)
                        }
                    }.bind(this))
                }.bind(this))      
                this.setFeesTypeAvailableListAll(feesTypeUseList);
            //}.bind(this), 740)            
        }
        this.inputAddMoreFeeTypeParams = null;
        this.assignFeeCourseList();
        //setTimeout(function () { this.feeSeen = true; }.bind(this), 420)
      //})
    
    //var feesTypeUseList = purify(this.feesTypeAvailableListAll); 
},
updateBatch(value){
  this.setBatch(value)  
},
fetchBatch(value){
    // console.log(value);
    this.feeSeen = false;
    if(!this.courseEdit){                
        this.inputParams = [];
    }        
    
    this.addbtnHide = 0;
    this.feeAvailableCourseList = [];
    this.inqCourse = value;
    this.inqBatchTime = '';                
    // this.fetchFeesTypeAvailableListAll();
    if(value != null)
    {
        // this.feeSeen = true;
        // setStart_date,setStudent_reason
        this.$validator.errors.remove('course');
        //this.fetchBatchAll(value.id);
        if(value.student_course_id && typeof value.student_course_id != "undefined"){//this is use for edit fees detail
            this.fetchStudentFeeCourseList(value.student_course_id)
            .then((res) => {
                this.feeSeen = true;
                this.updateCourse(value);
            })
            
        }else{
            //this is use for add fees detail
            var obj = {
                'id' : value.id,
                'course_start_date': this.item.start_date
            }
            this.fetchFeeCourseList(obj)
            .then((res) => {
                this.feeSeen = true;
                this.updateCourse(value);
                this.setCourse(value);// save course detail to send it again when start date is change.
            })
        }
        // this.fetchFeeCourseList(value.id);
        
        //setTimeout(function () { this.assignFeeCourseList() }.bind(this), 400)
    }else{
            this.setCourse(value);// save course detail to send it again when start date is change.
            this.$validator.errors.remove('course');
            this.$validator.errors.add({
              id: 'err_select_course',
              field: 'course',
              msg: 'Course Name field is required.',
              scope: this.$options.scope,
            });
            return false;
        }            
},
 assignFeeCourseList()
{
    this.fee_list = purify(this.feeCourseList);
},
fetchBatchTime(value){
    this.inqBatchTime = value;
},
subtotalCalc(value, index)
{
    if(this.fee_list.length === 0){ 
        this.assignFeeCourseList();
    }
    if(purify(this.inputParams).length > 0 && this.fee_list.length > 0)
    {
        if(typeof this.inputParams[index] === 'string'){
            var inputParams = this.inputParams[index];
            if(!(inputParams>0))
            {
                inputParams = 0;
            }
            this.subTotal[index] = parseFloat(value - inputParams).toFixed(2);
            this.fee_list[index].discount = parseFloat(inputParams).toFixed(2);
            this.fee_list[index].subtotal = parseFloat(this.subTotal[index]).toFixed(2);
            return this.subTotal[index];
            
        }else{
            this.subTotal[index] = parseFloat(value).toFixed(2);
            return this.subTotal[index];
        }
    }
    else
    {
        return this.subTotal[index] = value;
    }

    
},  

// subtotalCalc(value, index)
// {
//     if(this.fee_list.length === 0){ 
//         this.fee_list = purify(this.feeCourseList);
//     }
//     if(typeof this.inputParams[index] === 'string'){
//         this.subTotal[index] = value - this.inputParams[index];
//         this.fee_list[index].discount = this.inputParams[index];
//         this.fee_list[index].subtotal = this.subTotal[index];
        
//     }else{
//         this.subTotal[index] = value
//     }
//     return this.subTotal[index];
// },
newsubtotalCalc(index)
{
    if(typeof this.inputFeeAmountParams[index] === 'string' || typeof this.inputFeeDiscountParams[index] === 'string'){
        var myNewVal = parseFloat(parseFloat((this.inputFeeAmountParams[index]) ? this.inputFeeAmountParams[index]:0) - parseFloat((this.inputFeeDiscountParams[index]) ? this.inputFeeDiscountParams[index]:0)).toFixed(2);
        this.newsubTotal[index] =  parseFloat(myNewVal).toFixed(2);
    }else{
        this.newsubTotal[index] = 0;
    }            
    return this.newsubTotal[index];
},
finaltotalCalc(value,gstRate, index)
{
    if (typeof this.fee_list != "undefined" && typeof this.fee_list[index] != "undefined") {
        if(typeof this.subTotal[index] != 'undefined' ){  
            value = parseFloat(this.subTotal[index]);
        }
        var gstValue = this.countExistGSTValue(value,gstRate);
        this.fee_list[index].total_fees = parseFloat(parseFloat(value) - parseFloat(gstValue)).toFixed(2);
        return this.fee_list[index].total_fees;
    }
    // if (typeof this.fee_list != "undefined" && typeof this.fee_list[index] != "undefined") {
    //     if(gstRate > 0)
    //     {
    //         if(typeof this.subTotal[index] != 'undefined' ){                      
    //             var subT = parseFloat(this.subTotal[index]);
    //             var fTotal = parseFloat(subT + (subT*parseFloat(gstRate)/100)).toFixed(2);
    //             this.fee_list[index].total_fees = parseFloat(fTotal).toFixed(2);
    //             return this.fee_list[index].total_fees;
    //         }else{
    //             return value;     
    //         }
    //     }
    //     else{
    //         if(typeof this.subTotal[index] != 'undefined'){
    //             this.fee_list[index].total_fees = parseFloat(this.subTotal[index]).toFixed(2);
    //             return this.fee_list[index].total_fees;
    //         }else{
    //             return value;    
    //         } 
    //     }
    // }
   
},
newfinaltotalCalc(index)
{
    this.countGSTValue(this.newsubTotal[index],index);
    var total = this.newsubTotal[index]
    if((this.inputFeeGSTParams[index])?this.inputFeeGSTParams[index]:0 > 0){
        total = parseFloat(this.newsubTotal[index]) - parseFloat(this.inputFeeGSTValue[index]).toFixed(2);
        
    }
    return parseFloat(total).toFixed(2);
    // if((this.inputFeeGSTParams[index])?this.inputFeeGSTParams[index]:0 > 0)
    // {
    //     if(typeof this.inputFeeGSTParams[index] === 'string'){
    //         var myNewVal = parseFloat(this.newsubTotal[index]) + (parseFloat(this.newsubTotal[index]) * parseFloat((this.inputFeeGSTParams[index])?parseFloat(this.inputFeeGSTParams[index]):0)/100);
    //                return this.newsubTotal[index] = parseFloat(myNewVal).toFixed(2);
    // }else{
    //         return this.newsubTotal[index];    
    //     }
    // }
    // else{
    //     return this.newsubTotal[index];    
    // }
},

/**
 * Public function to count GST rate value.
 *
 * @FileName      Student Edit vue
 * @FunctionName  countExistGSTValue
 * @author        Rajesh R. Rathod
 * @since         31th Oct 2018
 * @description   Function  to count GST rate value. e.q 1000 * 10 /100 = 100
 * value = sub total 
 */
countExistGSTValue(value,GSTValue)
{
    //var inputFeeGSTValue = parseFloat((value)?value:0) * (parseFloat((GSTValue)?GSTValue:0) / 100);
    var gstRate = parseFloat((GSTValue)?parseFloat(GSTValue)+100:0);
    var totalAmt = parseFloat((value)?value:0);
    var inputFeeGSTValue = 0;
    if(gstRate > 0)
    {
        inputFeeGSTValue = parseFloat(totalAmt - (totalAmt*100/gstRate))    
    }
    return ((inputFeeGSTValue)?inputFeeGSTValue:0).toFixed(2);
},
/**
 * Public function to count GST rate value in exist value from the list.
 *
 * @FileName      Student Edit vue
 * @FunctionName  countGSTValue
 * @author        Rajesh R. Rathod
 * @since         31th Oct 2018
 * @description   Function  to count GST rate value in exist value from the list. e.q 1000 * 10 /100 = 100
 * value = sub total 
 */
countGSTValue(value,index)
{
    var gstRate = parseFloat((this.inputFeeGSTParams[index])?parseFloat(this.inputFeeGSTParams[index])+100:0);
    var totalAmt = parseFloat((value)?value:0)
    this.inputFeeGSTValue[index] = 0;
    if(gstRate > 0)
    {
        this.inputFeeGSTValue[index] = parseFloat(totalAmt - (totalAmt*100/gstRate))    
    }
    //this.inputFeeGSTValue[index] = parseFloat((value)?value:0) * (parseFloat(this.inputFeeGSTParams[index]) / 100);
    return ((this.inputFeeGSTValue[index])?this.inputFeeGSTValue[index]:0).toFixed(2);
},  
setAge(value) {
    if (value) {
        var dateArr = value.split("/");
        var date = dateArr[2]+"/"+dateArr[1]+"/"+dateArr[0];
        this.item.age = this.showAge(date);
    }
},
showAge(value) {
    var age = '';
    var user_date = new Date(value);
    var today_date = new Date();
    var diff_date =  Math.abs(user_date.getTime() - today_date.getTime());
    var num_years = diff_date/31536000000;
    var num_months = (diff_date % 31536000000)/2628000000;
    var num_days = ((diff_date % 31536000000) % 2628000000)/86400000;
    if (Math.floor(num_years)) {
        age += Math.floor(num_years)+ " Years";
    }

    if (Math.floor(num_months)) {
        age += ", "+Math.floor(num_months)+ " Months";
    }

    return age;
}  

}
}
</script>


<style scoped>
.addButtonStud{
position: absolute;
top: 30px;
right: 0px;
}
.modal-footer1{
padding: 12px 15px 0;
margin: 0 -15px;
}
.student_image {
position: relative;
width: 150px !important;
height: 150px !important;
-o-object-fit: cover;
object-fit: cover;
margin: 25px 0px;
}
</style>
