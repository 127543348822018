<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Students</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box col-md-12">
                            <!-- <div class="box-header with-border">
                                <h3 class="box-title">Student Status</h3>
                            </div> -->
                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>
                            <div class="box-body row">
                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="inquiry_status">Student Status<p class="astrisk">*</p></label>
                                    <v-select
                                            name="status"
                                            label="name"
                                            v-validate="'required'"
                                            @input="updateStatus"
                                            :value="item.status"
                                            :options="statusAll"
                                            />
                                    <span class="error" v-show="errors.has('status')">
                                      {{ $root.updateErrorMessage('status',this.errors)}}
                                    </span>
                                </div>

                                <div class="form-group col-md-12 col-xs-12" v-if="followupSeen">
                                    <label for="next_followup_date">Next Followup Date</label>
                                    <date-picker
                                            :value="item.next_followup_date"
                                            :config="$root.dpconfigDatePast"
                                            name="next_followup_date"
                                            placeholder="Enter Next Followup Date"
                                            @dp-change="updateNext_followup_date"
                                            v-validate="'required'"
                                            autocomplete="off"
                                            >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('next_followup_date')">
                                      {{ $root.updateErrorMessage('next_followup_date',this.errors)}}
                                    </span>
                                </div>
                                <div class="form-group col-md-12 col-xs-12" v-if="reasonSeen">
                                    <label for="reason">Reason</label>
                                    <v-select
                                            name="reason"
                                            label="name"
                                            @input="updateReason"
                                            :value="item.reason"
                                            :options="reasonAll"
                                            v-validate="'required'"
                                            />
                                            <span class="error" v-show="errors.has('reason')">
                                              {{ $root.updateErrorMessage('reason',this.errors)}}
                                            </span>
                                </div>
                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="status_remarks">Status Remarks</label>
                                    <textarea
                                            rows="3"
                                            class="form-control"
                                            name="status_remarks"
                                            placeholder="Enter Status Remarks"
                                            :value="item.status_remarks"
                                            @input="updateStatus_remarks"
                                            >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                Save
                            </vue-button-spinner>
                            
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            followupSeen: false,
            reasonSeen: false,
            
        }
    },
    computed: {
        ...mapGetters('StudentSingle', ['item', 'loading', 'countrymastersAll', 'statemastersAll', 'referenceAll', 'schoolAll', 'standardAll', 'reasonAll','statusAll']),
    },
    created() {
        this.fetchData(this.$route.params.id)
        this.fetchStatusAll();
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('StudentSingle', ['fetchData', 'updateData', 'resetState', 'setBranch_id', 'setFirst_name', 'setMiddle_name', 'setLast_name', 'setStudent_address', 'setArea', 'setCountry_list', 'setState_list', 'setCity', 'setGender', 'setPin_code', 'setBirth_date', 'setReference', 'setSchool', 'setStandard_id', 'setFather_name', 'setMother_name', 'setMobile_no', 'setOther_mobile_no', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchStatusAll']),
        disabledDates() {
          from: new Date(Date.now())
        },
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateFirst_name(e) {
            this.setFirst_name(e.target.value)
        },
        updateMiddle_name(e) {
            this.setMiddle_name(e.target.value)
        },
        updateLast_name(e) {
            this.setLast_name(e.target.value)
        },
        updateStudent_address(e) {
            this.setStudent_address(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        updateCountry_list(value) {
            this.setCountry_list(value)
        },
        updateState_list(value) {
            this.setState_list(value)
        },
        updateCity(e) {
            this.setCity(e.target.value)
        },
        updateGender(value) {
            this.setGender(value)
        },
        updatePin_code(e) {
            this.setPin_code(e.target.value)
        },
        updateBirth_date(e) {
            this.setBirth_date(e.target.value)
        },
        updateReference(value) {
            this.setReference(value)
        },
        updateSchool(value) {
            this.setSchool(value)
        },
        updateStandard_id(value) {
            this.setStandard_id(value)
        },
        updateFather_name(e) {
            this.setFather_name(e.target.value)
        },
        updateMother_name(e) {
            this.setMother_name(e.target.value)
        },
        updateMobile_no(e) {
            this.setMobile_no(e.target.value)
        },
        updateOther_mobile_no(e) {
            this.setOther_mobile_no(e.target.value)
        },
        updateStatus(value) {
            if(value && (value != '' || value != null))
            {
                if(value.value_id === 2)
                {
                    this.followupSeen = true;    
                    this.reasonSeen = false;
                }
                else if(value.value_id === 4)
                {
                    this.followupSeen = false;    
                    this.reasonSeen = true;    
                }
                else{
                    this.followupSeen = false;    
                    this.reasonSeen = false;       
                }
                
            }
            
            this.setStatus(value)
        },
        updateNext_followup_date(e) {
            this.setNext_followup_date(e.target.value)
        },
        updateReason(value) {
            this.setReason(value)
        },
        updateStatus_remarks(e) {
            this.setStatus_remarks(e.target.value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.updateData()
                    .then(() => {
                        this.$router.push({ name: 'inquiries.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
            });
        }
        
    }
}
</script>


<style scoped>

</style>
