<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-body">
                             <div class="col-md-6 col-sm-12">
                                <label for="search_employee_name">Employee Name</label>
                                <v-select
                                    :disabled="emplyeeLoggedIn"
                                    name="search_employee_name"
                                    label="employee_name"
                                    v-model="selected_employee"
                                    :options="employeeAll"
                                    />    
                                <span class="error" v-show="errors.has('search_employee_name')">
                                    {{ $root.updateErrorMessage('search_employee_name',this.errors,'Employee Name')}}
                                </span>
                            </div>
                             <div class="col-md-6 col-sm-12">
                                <label for="leave_type">Leave Type<p class="astrisk">*</p></label>
                                <v-select
                                        name="leave_type"
                                        label="name"
                                        v-validate="'required'"
                                        id="leave_type"
                                        v-model="leave_type"
                                        :options="leaveTypeAll"
                                        />  
                                <span class="error" v-show="errors.has('leave_type')">
                                    {{ $root.updateErrorMessage('leave_type',this.errors)}}
                                </span>                                        
                            </div>                            
                            <div class="col-md-12 col-xs-12 savebtn">
                                <button type="button" class="btn btn-success m-t-25" @click="fetchAll()">Go</button>
                            </div>                     
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Leave Balance Report</h3>
                        </div>
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" 
                                    @click="fetchAllocateLeaveAll({
                                        'emp_id': selected_employee_past.id,
                                        'leave_type': leave_type_past.value_id
                                    })"
                                    >
                                    <i class="fa fa-refresh" :class="{'fa-spin': loadingBal}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetBalFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loadingBal}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loadingBal">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loadingBal"
                                        :columns="columnsBal"
                                        :data="dataBal"
                                        :total="totalBal"
                                        :query="queryBal"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Leave Applied Report</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" 
                                    @click="fetchData({
                                        'emp_id': selected_employee_past.id,
                                        'leave_type': leave_type_past.value_id
                                    })"
                                    >
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="col-md-3 col-sm-3">
                                <label for="from_date">From Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="from_date" placeholder="Select from date" v-model="date_filter.from_date" autocomplete="off"></date-picker>
                            </div>
                            <div class="col-md-3 col-sm-3">
                                <label for="to_date">To Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="to_date" placeholder="Select to date" v-model="date_filter.to_date" autocomplete="off"></date-picker>
                            </div>
                            <div class="col-md-3 col-sm-3">
                                <button type="button" class="btn btn-success m-t-25" @click="searchInquiriesData()">Search</button>
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="loader_placeholder" v-if="uniLoader">
                <div id="loadingDiv">
                    <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
                </div>
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableActionsLeaveApproval from '../../dtmodules/DatatableActionsLeaveReport'
import DatatableStatus from '../../dtmodules/DatatableStatus'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'

export default {
    components,
    data() {
        return {
            emplyeeLoggedIn: false,
            uniLoader: false,
            selected_employee: null,
            leave_type: null,
            selected_employee_past: null,
            leave_type_past: null,            
            columns: [
                { title: 'Employee', field: 'name', sortable: true,thComp: 'FilterTh', tdClass: 'Employee Name' },
                { title: 'Type', field: 'leaveType', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Type' },
                { title: 'Option', field: 'leaveOption', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Option' },
                { title: 'From', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                { title: 'To', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Holiday Date'},
                { title: 'Days', field: 'days', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Days' },
                { title: 'Status', field: 'leaveStatus', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Status' },
                // { title: 'Reason', field: 'reason', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Status' },
            ],
            query: { sort: 'id', order: 'desc' },

            columnsBal: [
                { title: 'Employee', field: 'employee_name', sortable: true,thComp: 'FilterTh', tdClass: 'Employee Name' },
                { title: 'Type', field: 'leaveType', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Type' },
                { title: 'Total', field: 'total', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Type' },
                { title: 'Applied', field: 'applied', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Option' },
                { title: 'Balance', field: 'balance', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Option' },
            ],
            queryBal: { sort: 'id', order: 'desc' },

            xprops: {
                module: 'LeaveReportIndex',
                route: 'leave_report',
                permission_prefix: 'leave_report_'
            },
            date_filter : {from_date : '', to_date: '',search_employee_name : null}
        }
    },
    created() {
        this.uniLoader = true;
        this.$root.relationships = this.relationships
        this.fetchEmployeeAll()
            .then((res)=>{
                this.emplyeeLoggedIn = res[0]['emplyeeLoggedIn']
                this.selected_employee = this.employeeAll[0]
                this.fetchLeaveTypeAll()
                    .then(()=>{
                        this.uniLoader = false;
                        this.leave_type = this.leaveTypeAll[0]
                        this.fetchAll()
                    })        
            })
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('LeaveReportIndex', ['data', 'total', 'dataBal', 'totalBal', 'loading', 'loadingBal', 'relationships','employeeAll','leaveTypeAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        queryBal: {
            handler(queryBal) {
                this.setBalQuery(queryBal)
            },
            deep: true
        }        
    },
    methods: {
        ...mapActions('LeaveReportIndex', ['fetchData', 'setQuery','setBalQuery', 'resetState','fetchEmployeeAll','fetchAllocateLeaveAll','fetchLeaveTypeAll']),
        resetFilters(){
            var params = {
                'emp_id': this.selected_employee ? this.selected_employee.id : null,
                'leave_type': this.leave_type ? this.leave_type.value_id : null
            }               
            var filtersField= [];
            filtersField = ['username','original_date', 'original_format_date','original_date_a', 'original_format_date_a', 'start_time', 'end_time','days','leaveType','leaveOption','leaveStatus','name'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.query = {};
            this.date_filter.from_date = '';
            this.date_filter.to_date = '';
            this.date_filter.search_employee_name = null;
            this.fetchData(params)            
        },
        resetBalFilters(){
            var filtersField= [];
            filtersField = ['employee_name','leaveType','total','applied','balance'];
            this.$root.resetSearchFilters(Vue.delete,this.queryBal,filtersField);
            this.queryBal = {};
            var params = {
                'emp_id': this.selected_employee ? this.selected_employee.id : null,
                'leave_type': this.leave_type ? this.leave_type.value_id : null
            }            
            this.fetchAllocateLeaveAll(params)            
        },        
        searchInquiriesData() {
            this.query = {};
            if (this.date_filter.from_date) {
                this.date_filter.from_date = this.date_filter.from_date.replace(/\//g, '-');    
            }
            if (this.date_filter.to_date) {
                this.date_filter.to_date = this.date_filter.to_date.replace(/\//g, '-');
            }
            this.date_filter.emp_id = this.selected_employee_past ? this.selected_employee_past.id : 0
            this.date_filter.leave_type = this.leave_type_past ? this.leave_type_past.value_id : 0
            this.fetchData(this.date_filter);
        },
        fetchAll() {
            this.$validator.validate().then(result => {
            if (result) {            
            this.resetFilters()
            this.resetBalFilters()
            this.query = {}
            this.queryBal = {}
            this.selected_employee_past = this.selected_employee
            this.leave_type_past = this.leave_type
            this.uniLoader = true
            if(this.selected_employee == null && this.leave_type != null) {
                var params = {
                    'emp_id': 0,
                    'leave_type': this.leave_type.value_id
                }
            } else if(this.selected_employee != null && this.leave_type == null) {
                var params = {
                    'emp_id': this.selected_employee.id,
                    'leave_type': 0
                }
            } else if(this.selected_employee == null && this.leave_type == null) {
                var params = {
                    'emp_id': 0,
                    'leave_type': 0
                }
            } else {
                var params = {
                    'emp_id': this.selected_employee.id,
                    'leave_type': this.leave_type.value_id
                }
            }
            this.fetchAllocateLeaveAll(params)
                .then(()=>{
                    this.fetchData(params)
                        .then(()=>{
                            this.uniLoader = false
                        })
                })
            }
            else{
                // return this.$root.handleValidationFocus(this.errors,this.$refs);
            }       
            })                  
        }
    }
}
</script>


<style scoped>
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
</style>
