<template>
  <div class="popup-form add-country">
    <b-btn v-b-modal.modalPrevent class="add-button">+</b-btn>
    <b-modal id="modalPrevent"
             ref="modal"
             title="Add Parent"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"             
             @cancel="handleCancel"
             @shown="modelOpen">
      <form @submit.stop.prevent="handleSubmit">
      <div class="container-fluid">  
        <div class="row">
          <div class="col-md-6 col-xs-12">
             <div class="form-group">
                <label ref="mother_name" for="mother_name">Mother Name<p class="astrisk">*</p></label>
                  <b-form-input 
                        type="text"
                        name="mother_name"
                        maxlength="90"
                        v-validate="'required'"
                        placeholder="Enter Mother Name"
                        v-model="motherName"
                        >
                  </b-form-input>
                  <span class="error" v-show="errors.has('mother_name')">
                  {{ $root.updateErrorMessage('mother_name',this.errors,'Mother Name')}}
                  </span><br>              
              </div>                
          </div>  
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
               <label ref="other_mobile_number" for="other_mobile_number">Mother Mobile Number<p class="astrisk">*</p></label>
                 <b-form-input 
                       type="text"
                       v-validate="'required|digits:10'"
                       maxlength="10"
                       name="other_mobile_number"
                       v-model="otherMobileNumber"
                       placeholder="Enter Mother Mobile Number"
                       >
                 </b-form-input>
                 <span class="error" v-show="errors.has('other_mobile_number')">
                 {{ $root.updateErrorMessage('other_mobile_number',this.errors,'Mother Mobile Number')}}
                 </span><br>                   
             </div>                
         </div>             
          
         </div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
               <label ref="mail" for="mail">Email</label>      
                   <b-form-input 
                         id="mail"
                         type="text"
                        maxlength="200"
                        v-validate="'required|email'"                          
                         name="mail"
                         v-model="emailId"
                         placeholder="Enter Email"
                         >
                   </b-form-input>
                   <span class="error" v-show="errors.has('mail')">
                     <span v-if="this.errors.first('mail') != 'Email has already been taken.'">{{ $root.updateErrorMessage('mail',this.errors,'Email')}}</span><span v-else>{{this.errors.first('mail')}}</span>
                   
                   </span><br>
             </div>                
         </div>
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
               <label ref="father_name" for="father_name">Father Name</label>      
                   <b-form-input 
                         type="text"
                         name="father_name"
                         maxlength="90"
                         placeholder="Enter Father Name"
                         v-model="fatherName">
                   </b-form-input>
                     <span class="error" v-show="errors.has('father_name')">
                     {{ $root.updateErrorMessage('father_name',this.errors,'Father Name')}}
                     </span><br>
             </div>                
         </div>
         
         </div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
               <label ref="mobile_number" for="mobile_number">Father Mobile Number</label>      
                   <b-form-input 
                         type="text"
                         v-validate="'digits:10'"
                         maxlength="10"                        
                         name="mobile_number"                          
                         placeholder="Enter Father Mobile Number"
                         v-model="mobileNumber"            
                         >
                   </b-form-input>
                   <span class="error" v-show="errors.has('mobile_number')">
                   {{ $root.updateErrorMessage('mobile_number',this.errors,'Father Mobile Number')}}
                   </span><br>
             </div>                
         </div>
          <div class="col-md-6 col-xs-12">
             <div class="form-group">
                <label ref="other_email" for="other_email">Other Email</label>
                  <b-form-input 
                        id="otheremail"
                        type="text"
                       maxlength="200"
                       v-validate="'email'"                        
                        name="other_email"
                        v-model="otherEmailId"
                        placeholder="Enter Other Email"
                        >
                  </b-form-input>
                  <span class="error" v-show="errors.has('other_email')">
                  {{ $root.updateErrorMessage('other_email',this.errors,'Other Email')}}
                  </span><br>                  
              </div>                
          </div>
         </div>
        <!-- <div class="row">
          <div class="col-md-6 col-xs-12">
             <div class="form-group">
                <label ref="username" for="username">Login Username<p class="astrisk">*</p></label>      
                    <b-form-input 
                          type="text"
                          name="username"
                          maxlength="14"
                          v-validate="'required|alpha_dash|min:6|max:14'"                          
                          placeholder="Enter Login Username"
                          v-model="usernameF"
                          >
                    </b-form-input>
                    <span id="err_username" class="error" v-show="errors.has('username')">
                      {{ $root.updateErrorMessage('username',this.errors,'Login Username')}}
                    </span><br>                    
              </div>                
          </div>
         </div>                         -->
      </div>       
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
   props:['contactName','contactNumber','contactEmail'],
  data () {
    return {
      rolesAll: [],
      fatherName: '',
      motherName: '',
      mobileNumber: '',
      otherMobileNumber: '',
      emailId: '',
      otherEmailId: '',
      usernameF: '',
    }
  },
   computed: {
       ...mapGetters('StudentSingle', ['item', 'loading']),
       ...mapGetters('InquiriesSingle', ['inquiryShowData'])
   },
   created() {
       this.fetchRolesAll()
       
   }, 
   mounted(){
     
   }, 
   
  methods: {
       ...mapActions('StudentSingle', ['setParent', 'fetchParentAll']),
       modelOpen(){
         if(this.contactName != ''){
          this.motherName = this.contactName
        }
        if(this.contactNumber != ''){
          this.otherMobileNumber = this.contactNumber
        }
        if(this.contactEmail != ''){
          this.emailId = this.contactEmail
        }
       },
    clear1 () {
      //console.log("clear1");
      this.fatherName = '';
      this.motherName = '';
      this.mobileNumber = '';
      this.otherMobileNumber = '';
      this.emailId = '';
      this.otherEmailId = '';
      this.usernameF = '';
      this.$validator.errors.remove('father_name');      
      this.$validator.errors.remove('mother_name');      
      this.$validator.errors.remove('mobile_number');      
      this.$validator.errors.remove('other_mobile_number');      
      this.$validator.errors.remove('mail');      
      this.$validator.errors.remove('other_email');      
      this.$validator.errors.remove('username');      
    },
    fetchRolesAll() {
        axios.get('/api/v1/employeeRoleList')
            .then(response => {
                this.rolesAll = response.data.data;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    handleCancel(evt){
      this.fatherName = '';
      this.motherName = '';
      this.mobileNumber = '';
      this.otherMobileNumber = '';
      this.emailId = '';
      this.otherEmailId = '';
      this.usernameF = '';
      this.$validator.errors.remove('father_name');      
      this.$validator.errors.remove('mother_name');      
      this.$validator.errors.remove('mobile_number');      
      this.$validator.errors.remove('other_mobile_number');      
      this.$validator.errors.remove('mail');      
      this.$validator.errors.remove('other_email');      
      this.$validator.errors.remove('username');
    },    
    handleOk (evt) {
        evt.preventDefault();
        this.$validator.validate().then(result => {
               if (result) {
                  this.handleSubmit();
                }
                else{
                  return this.$root.handleValidationFocus(this.errors,this.$refs);
                }              
            });
    },
    handleSubmit () {
      let params = new FormData();
      params.set('father_name', this.fatherName);
      params.set('mother_name', this.motherName);
      params.set('mobile_no', this.mobileNumber);
      params.set('other_mobile_no', this.otherMobileNumber);
      params.set('email', this.emailId);
      params.set('other_email', this.otherEmailId);
      params.set('username', this.usernameF);
      
      axios.post('/api/v1/parents', params)
      .then(response => {
        if(!response.data.result)
        {

           for (const [key, value] of Object.entries(response.data.data)) {
                  console.log("ke",key,value);
                  if(key == "other_mobile_no"){
                    this.$validator.errors.add({
                        id: key,
                        field: 'other_mobile_number',
                        msg: value[0],
                        scope: this.$options.scope,
                    });
                  }
                  else if(key == "email"){
                     this.$validator.errors.add({
                  id: key,
                  field: 'mail',
                  msg: value[0],
                  scope: this.$options.scope,
              });
                  }
                  else{
                     this.$validator.errors.add({
                        id: key,
                        field: key,
                        msg: value[0],
                        scope: this.$options.scope,
                    });
                  }
             
            }
            return false;    
        } else {
            var output = {parent_mobile:response.data.data.mother_name+"-"+response.data.data.other_mobile_no,id:response.data.data.id};
            this.setParent(output);
            this.fetchParentAll(),
            this.clear1()
            this.$refs.modal.hide()
        }

        
       })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
      
    }
  }
}
</script>