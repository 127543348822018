<template> 
    <form @submit.stop.prevent="submitForm">
        <div class="row assign_create">
            <div class="col-md-12 col-xs-12">
                <div ref="branch" class="form-group" id="branch_div">
                    <label for="branch">Branch<p class="astrisk">*</p></label>
                    <select v-validate="'required'" class="form-control select2 branch" name="branch" placeholder="Select Branch" v-model="branch_id" @change="updateBranch">
                        <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                            <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id">
                                {{branch.branch_name}}
                            </option>
                        </optgroup>
                    </select>
                    <span class="error" v-show="errors.has('branch')">
                        {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                    </span>
                </div>
            </div>
            <div class="col-md-12 col-xs-12">
                <div ref="Employee" class="form-group" id="employee_div">
                    <label for="Employee">Employee<p class="astrisk">*</p></label>
                    <select v-validate="'required'" class="form-control select2 employee" placeholder="Select Employee" name="employee" v-model="employee_id" @change="updateEmployee">
                        
                        <option v-for="(employee, i) in employeeDetails" :key="i" :value="employee.user_id">
                            {{employee.employee_name}}
                        </option>
                        
                    </select>
                    <span class="error" v-show="errors.has('employee')">
                        {{ $root.updateErrorMessage('employee',this.errors,'Employee')}}
                    </span>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonMasterPopup from '../../dtmodules/CommonMasterPopup'

export default {
    props:['inquiryId'],
    components: { CommonMasterPopup },
    data() {
        return {
            branch_id:'',
            employee_id:''
        }
    },
    computed: {
        ...mapGetters('InquiriesSingle', ['item', 'loading','branchesDetails','employeeDetails']),
       
    },
    created() {
        this.setEmployeeDetails();
        this.fetchBranchDetails()
    },
    mounted() {
        let _this = this;
        $(".branch").select2({dropdownParent: $('#branch_div'),placeholder: "Select Branch",}).on('change', function () {
            _this.updateBranch($(this).select2("val"))
        });

        $(".employee").select2({dropdownParent: $('#employee_div'),placeholder: "Select Employee",}).on('change', function () {
            _this.updateEmployee($(this).select2("val"))
        });
    },
    methods: {
        ...mapActions('InquiriesSingle', ['storeAssignEmployeeData','setEmployeeDetails','fetchBranchDetails','setBranches','fetchEmployeeDetails']),
        closeModel(){
            this.$emit('closeModel');
        },
        updateBranch(val) {
            if(typeof val != 'undefined'){
                this.fetchEmployeeDetails(val);
                this.branch_id = val;
            }
        },
        updateEmployee(val) {
            this.employee_id = val;
        },
        submitForm () {
            this.$validator.validate().then(result => {

                if (result) {
                    let data = {
                        'inquiry_id': this.inquiryId,
                        'branch_id' : this.branch_id,
                        'employee_id' :this.employee_id,
                    };
                    this.storeAssignEmployeeData(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // console.log("ke",key,value);
                            if( value !== null ) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                        }
                        return '';    
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
    }
}
</script>

<style scoped>
.assign_create >>> .select2-container {
    width: 100% !important;
}
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card {
    margin: 10px;
}
.matchDiv {
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider {
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>