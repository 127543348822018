<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Courses</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/courses'">Courses</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Course</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline card-outline-tabs">
                     <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="changeComp('course')" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Course</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="changeComp('website')" id="course-website-tab" data-toggle="pill" href="#custom-tabs-four-course-website" role="tab" aria-controls="custom-tabs-four-course-website" aria-selected="false">Website</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="changeComp('faqs')" id="course-faq-tab" data-toggle="pill" href="#custom-tabs-four-course-faqs" role="tab" aria-controls="custom-tabs-four-course-faqs" aria-selected="false">FAQs</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="changeComp('case-study')" id="custom-tabs-four-quiz-tab" data-toggle="pill" href="#custom-tabs-four-quiz" role="tab" aria-controls="custom-tabs-four-quiz" aria-selected="false">Case Study</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div v-if="tabChange == 'course'">
                                <EditCourse></EditCourse>
                            </div>
                            <div v-if="tabChange == 'website'" class="tab-pane fade" id="custom-tabs-four-course-website" role="tabpanel" aria-labelledby="course-website-tab">
                                <EditCourseWebisteDetail></EditCourseWebisteDetail>
                            </div>
                            <div v-if="tabChange == 'faqs'" class="tab-pane fade" id="custom-tabs-four-course-faqs" role="tabpanel" aria-labelledby="course-faq-tab">
                                <FaqIndex></FaqIndex>
                            </div>
                            <div v-if="tabChange == 'case-study'" class="tab-pane fade" id="custom-tabs-four-quiz" role="tabpanel" aria-labelledby="custom-tabs-four-quiz-tab">
                                <CaseStudyIndex></CaseStudyIndex>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>

    </div>
</template>



<script>
import { mapGetters, mapActions } from 'vuex'
import EditCourse from '@/components/cruds/Course/EditCourse.vue'
import FaqIndex from '@/components/cruds/Course/Faqs/Index.vue'
import CaseStudyIndex from '@/components/cruds/Course/CaseStudy/Index.vue'
import EditCourseWebisteDetail from '@/components/cruds/Course/EditCourseWebisteDetail'
export default {
    data() {
        return {
            tabChange:'course',
        }
    },
    components:{
        EditCourse,
        FaqIndex,
        CaseStudyIndex,
        EditCourseWebisteDetail
    },
    computed: {
        // ...mapGetters('CoursesIndex', ['courseMonthFormula','all','loading','editDataAll','moduleAll','projectAll','courseVertical','courseCategory','courseFeeType']),
        ...mapGetters('AuthIndex', ['user']),
    },
    watch:{
         tabChange(){
            return this.tabChange;
        }
    },
    methods: {
        changeComp(tabActive){
            this.tabChange = tabActive
        }
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}



.contain .checkmark:after {
    left: 6.5px;
    top: 6.5px;
}
.content-wrapper .content {
    padding: 0 0.5rem;
    padding-bottom: 70px;
}
</style>