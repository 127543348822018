<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Franchises</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/franchises'">Franchises</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Franchise</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <form @submit.prevent="submitForm" novalidate>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Franchise</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                              
                        <div class="col-md-12">
                             <bootstrap-alert />
                        </div>   
                            
                        <div class="form-group col-md-6 col-xs-12">
                            <label for="franchise_name">Franchise Name<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="franchise_name"
                                    ref="franchise_name"
                                    maxlength="150"
                                    v-validate="'required'"
                                    placeholder="Enter Franchise Name"
                                    :value="item.franchise_name"
                                    @input="updateFranchiseName"
                                    >
                            <span class="error" v-show="errors.has('franchise_name')">
                                {{ $root.updateErrorMessage('franchise_name',this.errors,'Franchise Name')}}
                            </span>                                            
                        </div>

                        <div ref="franchise_type" class="form-group col-md-6 col-xs-12">
                            <label for="franchise_type">Franchise Type<p class="astrisk">*</p></label>
                            <v-select
                                    name="franchise_type"
                                    label="label"
                                    v-validate="'required'"
                                    @input="updateFranchiseType"
                                    :value="item.franchise_type"
                                    :options="franchiseTypeAll"
                                    />
                            <span class="error" v-show="errors.has('franchise_type')">
                                {{ $root.updateErrorMessage('franchise_type',this.errors,'Franchise Type')}}
                            </span>
                            
                        </div>
                        <div class="clearfix"></div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="franchise_address">Franchise Address<p class="astrisk">*</p></label>
                                <textarea
                                        class="form-control"
                                        name="franchise_address"
                                        ref="franchise_address"
                                        maxlength="250"
                                        v-validate="'required'"
                                        placeholder="Enter Franchise Address"
                                        @input="updateFranchiseAddress"
                                        ></textarea>
                                        <span class="error" v-show="errors.has('franchise_address')">
                                            {{ $root.updateErrorMessage('franchise_address',this.errors,'Franchise Address')}}
                                        </span><br>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                            <label for="branch_name">Franchise Area</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="area"
                                    ref="area"
                                    maxlength="90"
                                    placeholder="Enter Franchise Area"
                                    :value="item.area"
                                    @input="updateArea"
                                    >
                                    <span class="error" v-show="errors.has('area')">
                                        {{ $root.updateErrorMessage('area',this.errors,'Franchise Area')}}
                                    </span>
                        </div>

                        <div class="clearfix"></div>
                        <div ref="country_list" class="form-group col-md-6 col-xs-12">
                            <label for="country">Franchise Country<p class="astrisk">*</p></label>
                            
                            <v-select
                                    name="country_list"
                                    label="country_name"
                                    v-validate="'required'"
                                    placeholder="Select Country"
                                    @change="updateCountry"
                                    :value="item.country_list"
                                    :options="countryAll"
                                    />
                                    <span class="error" v-show="errors.has('country_list')">
                                        {{ $root.updateErrorMessage('country_list',this.errors,'Franchise Country')}}
                                    </span>
                        </div>
                        
                        <div ref="state_list" class="form-group col-md-6">
                            <label for="state">Franchise State<p class="astrisk">*</p></label>
                                <div class="state_box pos-relative">
                                <v-select
                                    name="state_list"
                                    label="state_name"
                                    v-validate="'required'"
                                    @input="updateState"
                                    class="parentName"
                                    placeholder="Select State"
                                    :value="item.state_list"
                                    :options="stateAll"
                                    />
                                    <AddStatePopupFranchise class="add_state_btn" v-if="$can('state_create') && statePopupButton"></AddStatePopupFranchise>                                            
                                </div>
                            <span class="error" v-show="errors.has('state_list')">
                                {{ $root.updateErrorMessage('state_list',this.errors,'Franchise State')}}
                            </span>
                        </div>

                        <div ref="city_list" class="form-group col-md-6 col-xs-12">
                                <label for="city">Franchise City<p class="astrisk">*</p></label>
                                <div class="city_box pos-relative">
                                <v-select
                                    name="city_list"
                                    label="city_name"
                                    v-validate="'required'"
                                    @change="updateCity"
                                    placeholder="Select City"
                                    :value="item.city_list"
                                    :options="cityAll"
                                    />
                                </div>
                                        <span class="error" v-show="errors.has('city_list')">
                                            {{ $root.updateErrorMessage('city_list',this.errors,'Franchise City')}}
                                        </span>
                            </div>

                        <div class="form-group col-md-6 col-xs-12">
                            <label for="pincode">Franchise PIN/ZIP Code</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="pincode"
                                    ref="pincode"
                                    maxlength="25"
                                    placeholder="Enter Franchise PIN/ZIP Code"
                                    :value="item.pincode"
                                    @input="updatePincode"
                                    >
                                    <span class="error" v-show="errors.has('pincode')">
                                        {{ $root.updateErrorMessage('pincode',this.errors,'Franchise PIN/ZIP Code')}}
                                    </span>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                            <label for="franchise_pan_no">Franchise PAN Number<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="franchise_pan_no"
                                    ref="franchise_pan_no"
                                    v-validate="{ required: true, regex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/ }"
                                    maxlength="10"
                                    placeholder="Enter Franchise PAN Number"
                                    :value="item.franchise_pan_no"
                                    @input="updateFranchisePanNo"
                                    >
                                    <span class="error" v-show="errors.has('franchise_pan_no')">
                                        {{ $root.updateErrorMessage('franchise_pan_no',this.errors,'Franchise PAN Number')}}
                                    </span>
                        </div>
                        
                        <div class="clearfix"></div> 
                       
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Owner Detail</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                       
                       <!-- <div class="form-group col-md-6 col-xs-12">
                            <label for="username">Login Username<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="username"
                                    ref="username"
                                        maxlength="14"
                                    v-validate="'required|alpha_dash|min:6|max:14'"
                                    placeholder="Enter Username"
                                    :value="item.username"
                                    @input="updateUsername"
                                    >
                                    <span id="err_username" class="error" v-show="errors.has('username')">
                                        {{ $root.updateErrorMessage('username',this.errors, 'Username')}}
                                    </span>
                        </div> -->
                        
                        <div class="form-group col-md-6 col-xs-12">
                            <label for="owner_name">Owner Name<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    maxlength="150"
                                    name="owner_name"
                                    ref="owner_name"
                                    v-validate="'required'"
                                    placeholder="Enter Owner Name"
                                    :value="item.owner_name"
                                    @input="updateOwnerName"
                                    >
                                    <span class="error" v-show="errors.has('owner_name')">
                                        {{ $root.updateErrorMessage('owner_name',this.errors,'Owner Name')}}
                                    </span>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                            <label for="owner_mobile">Owner Mobile<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="owner_mobile"
                                    ref="owner_mobile"
                                    v-validate="'required|digits:10'"
                                    maxlength="10"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    placeholder="Enter Owner Mobile"
                                    :value="item.owner_mobile"
                                    @input="updateOwnerMobile"
                                    >
                                    <span class="error" v-show="errors.has('owner_mobile')">
                                        {{ $root.updateErrorMessage('owner_mobile',this.errors,'Owner Mobile')}}
                                    </span>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                            <label for="owner_email">Owner Email<p class="astrisk">*</p></label>
                            <input
                                    type="text"
                                    class="form-control"
                                    name="owner_email"
                                    v-validate="'required|email'"
                                    ref="owner_email"
                                    maxlength="200"
                                    placeholder="Enter Owner Email"
                                    :value="item.owner_email"
                                    @input="updateOwnerEmail"
                                    >
                                    <span class="error" v-show="errors.has('owner_email')">
                                        {{ $root.updateErrorMessage('owner_email',this.errors,'Owner Email')}}
                                    </span>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-6 col-xs-12">
                            <div class="form-group">
                                <label for="logo">Profile </label>
                                <input
                                        type="file"
                                        name="profile"
                                        id="profile"
                                        v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg'"
                                        class="form-control"
                                        @change="updateProfile"
                                    >
                            <span class="error" v-show="errors.has('profile')">
                                {{ $root.updateErrorMessage('profile',this.errors,'Profile')}}
                            </span>
                                <ul v-if="item.profile" class="list-unstyled text-center">
                                    <li>
                                    <div class="" style="">
                                        <div v-if="item.profile != null">
                                            <img :src="item.profile" id="blah" class="franchise_image profile-user-img my-profile img-responsive img-circle" :alt="item.franchise_name" > 
                                        </div>
                                        <div v-else>
                                            <img src="/assets/img/male.jpg" class="franchise_image img-circle my-profile" :alt="item.franchise_name">
                                        </div>
                                    </div>
                                        <button class="btn btn-xs btn-danger"
                                                type="button"
                                                @click="removeProfile"
                                        >
                                            Remove file
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>                                     
                        <div class="col-md-12 col-md-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import AddStatePopupFranchise from '../../dtmodules/PopupActions/AddStatePopupFranchise'

export default {
    components: { AddStatePopupFranchise },
    data() {
        return {
            statePopupButton: true,
            // Code...
            //errorName : ''
        }
    },
    computed: {
        ...mapGetters('FranchisesSingle', ['item', 'loading','franchiseTypeAll','countryAll','stateAll','cityAll']),
       
    },
    created() {
        //this.fetchFranchiseTypeAll()
        this.fetchCountryAll();
        //this.fetchStateAll();        
        this.fetchCountryStates();
        // this.fetchCityAll();        
        this.fetchStateCities();
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('FranchisesSingle', ['storeData', 'resetState', 'setFranchiseName', 'setStateAll','setCityAll','setFranchiseType', 'setFranchiseAddress', 'setUsername', 'setOwnerName', 'setOwnerMobile', 'setOwnerEmail','fetchCountryAll','fetchStateAll','fetchCityAll','fetchCountryStates','fetchStateCities', 'setArea', 'setCity', 'setState', 'setCountry', 'setPincode','setProfile', 'setFranchisePanNo']),
        removeProfile(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setProfile('');
                     $("#profile").val('');
                }
            })
        }, 
        updateProfile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                console.log(e.target.files[0].type);
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setProfile(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#profile").val('');
                            this.$validator.errors.remove('profile');
                            this.setProfile('');
                        }
                    })
                }
            }           
        },        
        updateFranchiseName(e) {
            this.setFranchiseName(e.target.value)
        },
        updateFranchiseType(value) {
            this.setFranchiseType(value)
        },
        updateFranchiseAddress(e) {
            this.setFranchiseAddress(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        updateCountry(value) {
                if(value !== null){
                    this.fetchCountryStates(value.id);
                    this.statePopupButton = true;
                }            
                  else{
                    this.setStateAll([])
                    this.setCityAll([]);
                    this.statePopupButton = false;
                  }
            this.setCityAll([]);
            this.setState(null)
            this.setCity(null)
            this.setCountry(value)
        },
        updateState(value) {                          
             if(value !== null){
                    this.fetchStateCities(value.id);
                    // this.stateFlag = true
                }            
                  else{
                    this.setCityAll([]);                    
                  }
            this.setCity(null)
            this.setState(value)
        },
        updateCity(value) {
            this.setCity(value)
        },
        // updateState(value) {
        //         // if(value !== null){
        //         //     this.fetchCountryStates(value.id);
        //         // }        
        //     console.log(value);
        //     console.log("updateState");
        //     this.setState(value)
        // },
        // updateCountry(value) {
        //     this.updateState(null);
        //         if(value !== null){
        //             this.fetchCountryStates(value.id);
        //             this.statePopupButton = true;
        //         }
        //           else{
        //             this.setStateAll([]);
        //             this.statePopupButton = false;
        //           }            
        //     this.setState(null)
        //     this.setCountry(value)
        // },
        updatePincode(e) {
            this.setPincode(e.target.value)
        },
        updateFranchisePanNo(e) {
            this.setFranchisePanNo(e.target.value)
        },
        updateUsername(e) {
            this.setUsername(e.target.value)
        },
        updateOwnerName(e) {
            this.setOwnerName(e.target.value)
        },
        updateOwnerMobile(e) {
            this.setOwnerMobile(e.target.value)
        },
        updateOwnerEmail(e) {
            this.setOwnerEmail(e.target.value)
        },
        submitForm() {

         this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        /*this.$validator.errors.add({
                          id: 'username',
                          field: 'username',
                          msg: res.data.message,
                          scope: this.$options.scope,
                        });*/

                        for (const [key, value] of Object.entries(res.data.data)) {
                            console.log("ke",key,value);
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }

                        return false;    
                    }
                    
                    this.$router.push({ name: 'franchises.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


<style scoped>
.franchise_image
{
    position: relative;
    width: 150px !important;
    height: 150px !important;
    object-fit: cover;
    margin: 25px 0px;
}
</style>
