<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Common Master</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/common-masters'">Common Master</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Common</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>


        <section class="content">

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Common</h3>
                </div>
                <div class="card-body">
                   <div class="col-xs-12">
                        <!-- <back-buttton></back-buttton> -->
                    <form @submit.prevent="submitForm" novalidate>
                    
                     <bootstrap-alert />
                     
                     <div class="form-group">
                        <label for="type_">Type<p class="astrisk">*</p></label>
                        <v-select
                                name="type_name_id"
                                label="type_name"
                                v-validate="'required'"
                                :value="item.type_name_id"
                                disabled
                                />                                              
                        <span class="error" v-show="errors.has('type_name_id')">
                            {{ $root.updateErrorMessage('type_name_id',this.errors, 'Type')}}
                        </span>   
                    </div>
                    <div class="form-group">
                       <label for="name">Name<p class="astrisk">*</p></label>
                       <input
                            type="text"
                            class="form-control"
                            name="name"
                            ref="name"
                            maxlength="200"
                            placeholder="Enter Name"
                            v-validate="'required'"
                            :value="item.name"
                            @input="updateName"
                            >
                            <span class="error" v-show="errors.has('name')">
                                {{ $root.updateErrorMessage('name',this.errors)}}
                            </span>
                    </div>
                    <div class="form-group" v-if="otherValueData && item && item.type_name_id.id == 2">
                        <label class="checkbox-label" for="is_admission_fees_required" style="font-weight: 400; cursor:pointer;">
                            <input 
                                id="is_admission_fees_required"
                                name="is_admission_fees_required"
                                @change="updateAdmissionFeeRequired" 
                                type="checkbox" 
                                :value="item.other_value" 
                                v-model="admissionFeesRequired">
                            Is Admission Fee Applicable
                            </label>
                    </div>
                    <div class="form-group" v-if="otherValueData && item && item.type_name_id.id != 2">
                        <label for="name">{{otherValueData}}<p class="astrisk">*</p></label>
                        <input
                            type="text"
                            class="form-control"
                            name="other_value"
                            ref="other_value"
                            maxlength="50"
                            oninput="this.value=this.value
                                                        .replace(/[^\d.]/g, '')
                                                        .replace(/(\..*)\./g, '$1')
                                                        .replace(/(\.[\d]{2})./g, '$1');"     
                            v-validate="'required'"                       
                            :placeholder="'Enter ' +otherValueData"
                            :value="item.other_value"
                            @input="updateOtherValue"
                            >
                            <span class="error" v-show="errors.has('other_value')">
                                {{ $root.updateErrorMessage('other_value',this.errors,otherValueData)}}
                            </span>
                    </div>
                        <div class="col-md-12 col-md-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div> 
                    </form>
                </div>

                </div>
            </div>

        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            otherValueData : '',
            admissionFeesRequired: null
        }
    },
    computed: {
        ...mapGetters('CommonMastersSingle', ['item', 'loading', 'typeAll']),
      
    },
    created() {
        this.fetchData(this.$route.params.id).then(()=>{
            // this.admissionFeesRequired = this.item.other_value
            if(this.item.other_value == true) {
                this.admissionFeesRequired = 1
            } else {
                this.admissionFeesRequired = 0
            }            
        })
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function() {
            this.otherValueData = this.item.type_name_id.other_value_description;
            
        },
    },
    methods: {
        ...mapActions('CommonMastersSingle', ['fetchData', 'updateData', 'resetState', 'setName', 'setTypeId','setOtherValue',
            'setAdmissionFeeRequired']),

        updateName(e) {
            this.setName(e.target.value)
        },
        updateOtherValue(e) {   
            this.setOtherValue(e.target.value)
        },
       updateAdmissionFeeRequired(e){
        if(e.target.checked == true) {
            this.setAdmissionFeeRequired(1);
        } else {
            this.setAdmissionFeeRequired(0);
        }
       },       
        updateTypeId(value) {
             if(value.other_value_description !='' || value.other_value_description != null){
                this.otherValueData = value.other_value_description;
            }
            this.setTypeId(value)
        },
        submitForm() {
        this.$validator.validate().then(result => {
         if (result) {
            this.updateData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'name',
                            field: 'name',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                      
                    this.$router.push({ name: 'common_masters.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
            }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }            
          });
        }
    }
}
</script>
 