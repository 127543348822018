<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Inquiries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/inquiries'">Inquiries</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Inquiry Status</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <form @submit.prevent="submitForm" novalidate>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Inquiry Status</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                          
                        <div class="col-md-12">
                            <span>Student Name:&nbsp;<strong>{{inquiryStatusData.student_name}}</strong></span><br>                                     
                            <span>Contact Name:&nbsp;<strong>{{inquiryStatusData.contact_name}}</strong></span><br>                                
                            <span>Contact Number:&nbsp;<strong>{{inquiryStatusData.contact_number}}</strong></span> 
                        </div>
                        <div class="col-md-12">
                             <bootstrap-alert />
                        </div> 
              
                        <div class="form-group col-md-7 col-xs-12">
                            <label for="inquiry_status">Inquiry Status<p class="astrisk">*</p></label>
                            <v-select
                                    name="status"
                                    label="name"
                                    v-validate="'required'"
                                    :disabled="enrolledDisabled"
                                    v-model="inquiry_status"
                                    :options="statusAll"
                                    />
                            <span class="error" v-show="errors.has('status')">
                                {{ $root.updateErrorMessage('status',this.errors,'Inquiry Status')}}
                            </span>
                        </div>
                        <div class="form-group col-md-7 col-xs-12" v-if="inquiry_status.value_id == 2">
                            <label for="next_followup_date">Next Followup Date<p class="astrisk">*</p></label>
                            <date-picker
                                    :config="$root.dpconfigDatetime"
                                    name="next_followup_date"
                                    placeholder="Select Next Followup Date"
                                    @dp-change="updateNext_followup_date"
                                    v-model="next_followup_date"
                                    v-validate="'required'"
                                    autocomplete="off"
                                    >
                            </date-picker>
                                    <span class="error" v-show="errors.has('next_followup_date')">
                                        {{ $root.updateErrorMessage('next_followup_date',this.errors,'Next Followup Date')}}
                                    </span>
                        </div>
                        <div class="form-group col-md-7 col-xs-12" v-if="inquiry_status.value_id == 2 || inquiry_status.value_id == 4">
                            <label for="reason">Reason<p class="astrisk">*</p></label>
                            <v-select
                                    name="reason"
                                    label="name"
                                    @input="updateReason"
                                    v-model="reason_id"
                                    :options="reasonAll"
                                    v-validate="'required'"
                                    />
                                    <span class="error" v-show="errors.has('reason')">
                                        {{ $root.updateErrorMessage('reason',this.errors)}}
                                    </span>
                        </div>
                        <div class="form-group col-md-7 col-xs-12" v-if="reason_id.value_id == 0">
                            <label for="reason">Other Reason<p class="astrisk">*</p></label>
                            <input
                                type="text"
                                max-length="150"
                                    name="other_reason"
                                    v-validate="'required'"
                                    v-model="other_reason"
                                    class="form-control"
                                    placeholder="Other Reason"
                                    />
                                    <span class="error" v-show="errors.has('other_reason')">
                                        {{ $root.updateErrorMessage('other_reason',this.errors,'Other Reason')}}
                                    </span>
                        </div>
                        <div class="form-group col-md-7 col-xs-12">
                            <label for="status_remarks">Status Remarks</label>
                            <textarea
                                    rows="3"
                                    class="form-control"
                                    name="status_remarks"
                                    placeholder="Enter Status Remarks"
                                    v-model="inquiry_remark"
                                    >
                            </textarea>
                        </div>

                         <div class="col-md-12 col-md-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                    :isLoading="loading"
                                    :disabled="loading || enrolledDisabled"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div> 
                       
                    </div>
                </div>
            </div>
           
            </form>

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Inquiry Status History</h3>
                </div>
                <div class="card-body">
                    <StatusHistory :inquiryId="$route.params.id"></StatusHistory>
                </div>
            </div>

            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
import StatusHistory from '@/components/cruds/Inquiries/StatusHistory'

export default {
    components,
    components:{
        StatusHistory
    },
    data() {
        return {
            inquiry_status:'',
            inquiry_remark:'',
            other_reason:'',
            reason_id:'',
            v_i :0,
            followupSeen: false,
            reasonSeen: false,
            reasonAll:[],
            enrolledDisabled: false,
            columns: [
                { title: 'Date & Time', field: 'dis_created_at', sortable: true, thComp: 'FilterTh' },
                { title: 'Inquiry Status', field: 'inquiry', sortable: true, thComp: 'FilterTh' },
                { title: 'Followup Date & Time', field: 'next_followup_date', sortable: true, thComp: 'FilterTh' },
                { title: 'Reason', field: 'reason', sortable: true, thComp: 'FilterTh' },
                { title: 'Status Remark', field: 'status_remarks', sortable: true, thComp: 'FilterTh' },
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'InquiriesSingle',
                route: 'inquiries/:id/status',
                permission_prefix: 'inquiry_'
            }                        
        }
    },
    computed: {
        ...mapGetters('InquiriesSingle', ['followupReasons','closeReasons','inquiryStatusData','data', 'total', 'loading', 'relationships', 'item', 'countrymastersAll', 'statemastersAll', 'referenceAll', 'schoolAll', 'standardAll','statusAll','inqHistoryAll','onlySummerGlobal','courseListAll','branchAdmissionFeesApplicable']),
    },
    
    created() {
        this.fetchTypes();
        this.fetchStatusAll();
        this.fetchStatusData(this.$route.params.id);
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        inquiryStatusData(value){
           
            if(value.inquiry_status == 3){
                 this.enrolledDisabled = true;
            }
        //    console.log("vaule",value);
            this.inquiry_status = value.status;
            this.inquiry_remark = value.status_remarks;
            this.next_followup_date = moment(value.next_followup_date).format("DD/MM/YYYY hh:mm A");
            //this.reason_id = 0;
          // console.log("dfdf",value);
            if(value.inqStatus == 'Followup'){
                this.reasonAll = this.followupReasons;
            }else if(value.inqStatus == 'Closed')
            {
                this.reasonAll = this.closeReasons;    
            }
            if(value.inqStatus == "Followup" || value.inqStatus == "Closed"){
              //  console.log("w",this.reasonAll);
                let result = this.reasonAll.find(item => item.value_id === value.reason_id);
               // console.log("result",result);
                this.reason_id = result;
                if(value.reason_id == 0){
                    this.other_reason = value.other_reason_name; 
                }
                this.v_i = 1;
            }
            
            
        },
        inquiry_status(value){
            this.updateStatus(value);
        },
        "$route.params.id": function() {
            this.resetState()
            this.fetchStatusData(this.$route.params.id)
        },
        // "item": function(){
        // 	if(this.item && this.item.status && this.item.status.value_id == 3 && !this.enrolledDisabled){
        // 		this.enrolledDisabled = true;
        // 	}
        // }
    },
    methods: {
        ...mapActions('InquiriesSingle', ['fetchTypes','fetchStatusData','fetchData', 'updateStatusData', 'resetState', 'setQuery', 'setBranch_id', 'setFirst_name', 'setMiddle_name', 'setLast_name', 'setStudent_address', 'setArea', 'setCountry_list', 'setState_list', 'setCity', 'setGender', 'setPin_code', 'setBirth_date', 'setReference', 'setSchool', 'setStandard_id', 'setFather_name', 'setMother_name', 'setMobile_no', 'setOther_mobile_no', 'setStatus', 'setNext_followup_date', 'setReason', 'setStatus_remarks','fetchStatusAll','fetchInquiryHistoryAll','setinqHistoryAll','setOnlySummerCampGlobal','fetchIfBranchAdmissionFeesApplicable']),
        ...mapActions('StudentIndex', ['setStudentInquiryId']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['dis_created_at','inquiry', 'next_followup_date', 'reason', 'status_remarks'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.fetchData(this.$route.params.id)
        },
        disabledDates() {
          from: new Date(Date.now())
        },
        updateStatus(value) {
            console.log(this.v_i);
            if(this.v_i != 1){
                this.reason_id = '';
            this.other_reason = '';
            this.reasonAll = [];
            if(value && (value != '' || value != null))
            {
                // console.log("vale",value.value_id);
                if(value.value_id == 2){
                    this.reasonAll = this.followupReasons;
                }else if(value.value_id == 4)
                {
                    this.reasonAll = this.closeReasons;    
                }
            //     if(value.value_id === 2)
            //     {
            //         this.followupSeen = true;    
            //         this.reasonSeen = false;
            //         this.setReason(null);
            //     }
            //     else if(value.value_id === 4)
            //     {
            //         this.followupSeen = false;    
            //         this.setNext_followup_date('')
            //         this.reasonSeen = true;    
            //     }
            //     else if(value.value_id === 1)
            //     {
            //         this.followupSeen = false;    
            //         this.setNext_followup_date('')
            //         this.reasonSeen = false;    
            //         this.setReason(null);
            //     }
            //     else if(value.value_id === 3)
            //     {
            //         this.followupSeen = false;   
            //         this.setNext_followup_date('') ;
            //         this.reasonSeen = false;   
            //         this.setReason(null);
            //     }
            // }
            // else{
            //     this.followupSeen = false;
            //     this.reasonSeen = false;
            // }
            // this.setStatus(value)
            }
            }
            else{
                this.v_i = 0;
            }
           
        },
        updateNext_followup_date(e) {
            this.setNext_followup_date(e.target.value)
        },
        updateReason(value) {
            this.other_reason = '';
            this.setReason(value)
        },
        updateStatus_remarks(e) {
            this.setStatus_remarks(e.target.value)
        },
        submitForm() {
            
            this.$validator.validate().then(result => {
                if (result) {
                    let data = {
                    'id': this.$route.params.id,
                    'inquiry_status' : this.inquiry_status,
                    'status_remarks': this.inquiry_remark,
                    'next_followup_date':this.next_followup_date,
                    'reason_id':this.reason_id,
                    'other_reason':this.other_reason,
                };
                
                this.updateStatusData(data)
                    .then((res) => {
                        this.$eventHub.$emit('update-success')
                        if(res && res.studentData > 0)
                            this.$router.push({ path: '/student/'+res.studentData+'/edit' })
                        else{
                            console.log("dfdf",this.inquiry_status.id)
                            if(this.inquiry_status.value_id == 3){
                                this.setStudentInquiryId(this.$route.params.id);
                                this.$router.push({ name: 'student.index'} );
                            }
                            else{
                                this.$router.push({ name: 'inquiries.index' });
                            }
                            
                        }
                        
                    })
                    .catch((error) => {
                        console.error(error)
                    });
                }else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });
        },  
    }
}
</script>


<style scoped>

</style>
