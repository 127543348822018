<template>
    <div class="btn-group btn-group-xs">
         <router-link
                v-if="$can(xprops.permission_prefix + 'view') && xprops.route != 'roles' "
                :to="{ name: xprops.route + '.show', params: { id: row.main_course_id,from_date: row.new_from_date,fees_branch_id: row.fees_branch_id,'show':'show' } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && !(row.upto_date)"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.main_course_id,from_date: row.new_from_date,fees_branch_id: row.fees_branch_id ,'edit':'edit' } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                v-if="$can(xprops.permission_prefix + 'delete') && !(row.upto_date)"
                @click="destroyDataaaa(row)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
        }
    },
    created() {
        // Code...
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        destroyDataaaa(args) {
            var from_date_arr = (args.from_date != null  ? args.from_date.split('/') : []),
            formatted_from_date = (from_date_arr.length == 3 ? (from_date_arr[2] + '-' + from_date_arr[1]  + '-' + from_date_arr[0]) : null),
            upto_date_arr = (args.upto_date != null ? args.upto_date.split('/') : []),
            formatted_upto_date = (upto_date_arr.length == 3 ? (upto_date_arr[2]  + '-' + upto_date_arr[1]  + '-' + upto_date_arr[0]) : null),
            params = {
                'specific_id': args.id,
                'id': args.main_course_id,
                'from_date': formatted_from_date,
                'upto_date': formatted_upto_date
            };
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(params)
                    .then(() => {
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        },

    }
}
</script>


<style scoped>

</style>
