function initialState() {
    return {
        item: {
            employee: null,
            schedule_date: null,
            relationships: {},
            student_id: null,
            course_id: null,
            topic_id: null,
            lab_id: null,
            fully: null,
            partial: null,
            need_improvement: null,
            course_assessment_id: 0,
            answer: 0,
            student_attendance: [],
            show_student_attendance: [],

        },
        changedTopicData: [],
        assessmentDue: [],
        employeeAll: [],
        topicDueAll: [],
        labsAll: [],
        batchSheduleAll: [],
        batchSheduleDetailAll: [],
        studentAttendanceDataAll: [],
        loading: false,
        loadingSheduleDetail: false,
        loadingLab: false,
        loadingAssessmentDue: false,
        query: {},
        error: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loadingLab: state => state.loadingLab,
    loadingAssessmentDue: state => state.loadingAssessmentDue,
    employeeAll: state => state.employeeAll,
    changedTopicData: state => state.changedTopicData,
    topicDueAll: state => state.topicDueAll,
    labsAll: state => state.labsAll,
    assessmentDue: state => state.assessmentDue,
    indexTopicDue: state => state.indexTopicDue,
    loadingSheduleDetail: state => state.loadingSheduleDetail,
    batchSheduleAll: state => state.batchSheduleAll,
    studentAttendanceDataAll: state => state.studentAttendanceDataAll,
    batchSheduleDetailAll: state => state.batchSheduleDetailAll,
    data: state => {
        let rows = state.batchSheduleDetailAll
        if (state.query.sort) {
            rows = _.orderBy(state.batchSheduleDetailAll, state.query.sort, state.query.order)
        }

        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
        ['student_name', 'course_name', 'topic_name', 'display_topic_status', 'status_update_by', 'status_update_date'].forEach(field => {
            switch (typeof queryFilter[field]) {
                case 'array':
                    rows = _.filter(rows, function(item) {
                        if (queryFilter[field].includes(item[field])) {
                            return queryFilter[field];
                        }
                    });
                    break
                case 'string':
                    rows = _.filter(rows, function(item) {
                        if (item[field] && typeof item[field] === 'number') {
                            if (item[field] == queryFilter[field]) {
                                return item[field];
                            } else if (queryFilter[field] == '') {
                                return item[field];
                            }

                        } else if (item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase()) > -1) {
                            return item[field];
                        } else if (queryFilter[field] == '' || queryFilter[field] == null) {
                            if (item[field] == '' || item[field] == null) {
                                return '  ';
                            } else {
                                return item[field]
                            }

                        }
                    });
                    break
                default:
                    // nothing to do
                    break
            }
        })

        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.batchSheduleDetailAll

        if (state.query.sort) {
            rows = _.orderBy(state.batchSheduleDetailAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
        ['student_name'].forEach(field => {
            switch (typeof queryFilter[field]) {
                case 'array':
                    rows = _.filter(rows, function(item) {
                        if (queryFilter[field].includes(item[field])) {
                            return queryFilter[field];
                        }
                    });
                    break
                case 'string':
                    rows = _.filter(rows, function(item) {
                        if (item[field] && typeof item[field] === 'number') {
                            if (item[field] == queryFilter[field]) {
                                return item[field];
                            } else if (queryFilter[field] == '') {
                                return item[field];
                            }

                        } else if (item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase()) > -1) {
                            return item[field];
                        } else if (queryFilter[field] == '' || queryFilter[field] == null) {
                            if (item[field] == '' || item[field] == null) {
                                return '  ';
                            } else {
                                return item[field]
                            }

                        }
                    });
                    break
                default:
                    // nothing to do
                    break
            }
        })
        return rows.length;
    },
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    storeData({
        commit,
        state,
        dispatch
    }) {
        dispatch('Alert/resetState', null, {
            root: true
        })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('attendanceData', purify(state.studentAttendanceDataAll));
            state.studentAttendanceDataAll.forEach(function(studentAttendance, index) {
                params.set('attendanceData[' + index + '][student_id]', studentAttendance.student_id);
                params.set('attendanceData[' + index + '][course_id]', studentAttendance.course_id);
                params.set('attendanceData[' + index + '][batch_id]', studentAttendance.student_batch_id);
                params.set('attendanceData[' + index + '][trainer_id]', studentAttendance.def_trainer_emp_id);
                params.set('attendanceData[' + index + '][update_trainer]', studentAttendance.update_trainer);
                params.set('attendanceData[' + index + '][session_date]', state.item.schedule_date);
                params.set('attendanceData[' + index + '][session_no]', studentAttendance.session_no);
                params.set('attendanceData[' + index + '][lab_id]', studentAttendance.student_lab_id);

                var indexLocal = -1;
                var filteredObj = state.changedTopicData.find(function(item, i) {
                    if (item.index === index) {
                        indexLocal = i;
                        return i;
                    }
                });
                if (indexLocal == -1) {
                    params.set('attendanceData[' + index + '][topic_session_no]', studentAttendance.topic_session_no)
                    params.set('attendanceData[' + index + '][topic_name]', studentAttendance.topic_name);
                    params.set('attendanceData[' + index + '][is_assessment_required]', studentAttendance.is_assessment_required);
                } else {
                    params.set('attendanceData[' + index + '][is_assessment_required]', state.changedTopicData[indexLocal].is_assessment_required);
                    params.set('attendanceData[' + index + '][topic_name]', state.changedTopicData[indexLocal].display_topic_name);
                    params.set('attendanceData[' + index + '][topic_session_no]', state.changedTopicData[indexLocal].session_no);
                }
                params.set('attendanceData[' + index + '][topic_status]', studentAttendance.topic_status);
                params.set('attendanceData[' + index + '][is_present]', studentAttendance.is_present);
            })

            axios.post('/api/v1/student-assessment', params)
                .then(response => {
                    commit('setBatchSheduleDetailAll', response.data.data)
                    resolve()
                })
                .catch(function(error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors
                    dispatch(
                        'Alert/setAlert', {
                            message: message,
                            errors: errors,
                            color: 'danger'
                        }, {
                            root: true
                        })
                    reject(error)
                })
                .finally(() => {
                    //commit('setLoading', false)
                })
        })
    },
    updateStatus({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, {
            root: true
        })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('topic_status', state.topic_status);
            params.set('student_id', state.item.detail.student_id);
            params.set('course_id', state.item.detail.course_id);
            params.set('batch_id', state.item.detail.batch_id);
            params.set('encode_id', state.item.detail.encode_id);
            params.set('session_date', state.item.detail.selected_date.replace(/\//g, '-'));
            //console.log('state.item.detail.encode_id',state.item.detail.encode_id);

            axios.post('/api/v1/updateTopicStatus', params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
                .catch(function(error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors
                    dispatch(
                        'Alert/setAlert', {
                            message: message,
                            errors: errors,
                            color: 'danger'
                        }, {
                            root: true
                        })
                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },

    updateData({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, {
            root: true
        })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            axios.post('/api/v1/student-assessment/' + state.item.detail.encode_id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
                .catch(function(error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors
                    dispatch(
                        'Alert/setAlert', {
                            message: message,
                            errors: errors,
                            color: 'danger'
                        }, {
                            root: true
                        })
                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    showAssessmentData({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, {
            root: true
        })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('student_id', state.item.show_student_attendance.student_id);
            params.set('course_id', state.item.show_student_attendance.course_id);
            params.set('def_trainer_emp_id', state.item.show_student_attendance.def_trainer_emp_id);
            params.set('topic_id', state.item.show_student_attendance.topic_id);
            params.set('student_batch_id', state.item.show_student_attendance.student_batch_id);
            params.set('schedule_date', state.item.show_student_attendance.schedule_date);

            axios.post('/api/v1/showAssessment/', params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
                .catch(function(error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors
                    dispatch(
                        'Alert/setAlert', {
                            message: message,
                            errors: errors,
                            color: 'danger'
                        }, {
                            root: true
                        })
                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },

    async fetchData({
        commit,
        dispatch
    }, id) {
        await axios.get('/api/v1/student-assessment/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
    },
    async deleteManuallyAddedStudentStore({
        commit,
        dispatch
    }, dataObj) {
        await axios.post('/api/v1/deleteManuallyAddedStudent', dataObj)
            .then(response => {
                // commit('setItem', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
    },      
    async deleteAttendanceIfAvailable({
        commit,
        dispatch
    }, dataObj) {
        await axios.post('/api/v1/deleteAttendanceIfAvailable', dataObj)
            .then(response => {
                // commit('setItem', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
    },    
    async fetchLabsAll({
        commit
    }, params) {
        await axios.get('/api/v1/labList', params)
            .then(response => {
                commit('setLabsAll', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
    },
    async fetchBatchSheduleAll({
        commit,
        dispatch
    }, date) {
        commit('setLoading', true)
        // commit('setLoadingSheduleDetail', true)
        await axios.get('/api/v1/getBatchShedule/' + date)
            .then(response => {
                commit('setBatchSheduleAll', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
            .finally(() => {
                commit('setLoading', false)
                //commit('setLoadingSheduleDetail', false)
            })
    },
    async fetchBatchSheduleDetailAll({
        commit,
        state,
        dispatch
    }, obj) {
        commit('setLoadingSheduleDetail', true)
        commit('setSchedule_date', obj.date);
        await axios.get('/api/v1/getBatchSheduleDetail/' + obj.id + '/' + obj.date + '/' + state.item.lab_id.id)
            .then(response => {
                commit('setBatchSheduleDetailAll', response.data.data)

            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
            .finally(() => {
                commit('setLoadingSheduleDetail', false)
            })
    },
    saveStudentAssessmentData({
        commit,
        dispatch
    }, obj) {
        axios.get('/api/v1/saveStudentAssessmentData/' + obj.id + '/' + obj.date)
            .then(response => {
                // commit('setBatchSheduleDetailAll', response.data.data)
            })
            .catch(function(error) {
                console.log('Error', error.message);
            })
    },
    setLab({
        commit
    }, value) {
        commit('setLab', value)
    },
    setManualEntry({
        commit
    }, value) {
        commit('setManualEntry', value)
    },
    setSchedule_date({
        commit
    }, value) {
        commit('setSchedule_date', value)
    },
    setSelected_date({
        commit
    }, value) {
        commit('setSelected_date', value)
    },
    setSelected_Detail({
        commit
    }, value) {
        commit('setSelected_Detail', value)
    },
    setStudentId({
        commit
    }, value) {
        commit('setStudentId', value)
    },
    setCourseId({
        commit
    }, value) {
        commit('setCourseId', value)
    },
    setCourseAssessmentId({
        commit
    }, value) {
        commit('setCourseAssessmentId', value)
    },
    setStudentAttendance({
        commit
    }, value) {
        commit('setStudentAttendance', value)
    },
    setBatchSheduleAll({
        commit
    }, value) {
        commit('setBatchSheduleAll', value)
    },
    setQuery({
        commit
    }, value) {
        commit('setQuery', purify(value))
    },
    setBatchSheduleDetailAll({
        commit
    }, value) {
        commit('setBatchSheduleDetailAll', value)
    },
    setPushBatchSheduleDetailAll({
        commit
    }, value) {
        commit('setPushBatchSheduleDetailAll', value)
    },
    setStudentAttendanceDataAll({
        commit
    }, value) {
        commit('setStudentAttendanceDataAll', value)
    },
    setShowStudentAttendance({
        commit
    }, value) {
        commit('setShowStudentAttendance', value)
    },
    setLoading({
        commit
    }, loading) {
        commit('setLoading', loading)
    },
    setLoadingLab({
        commit
    }, value) {
        commit('setLoadingLab', value)
    },
    setLoadingAssessmentDue({
        commit
    }, value) {
        commit('setLoadingAssessmentDue', value)
    },
    setLoadingSheduleDetail({
        commit
    }, value) {
        commit('setLoadingSheduleDetail', value)
    },
    resetState({
        commit
    }) {
        commit('resetState')
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setQuery(state, query) {
        state.query = query
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingLab(state, loading) {
        state.loadingLab = loading
    },
    setLoadingAssessmentDue(state, loading) {
        state.loadingAssessmentDue = loading
    },
    setLoadingSheduleDetail(state, loading) {
        state.loadingSheduleDetail = loading
    },
    setLab(state, value) {
        state.item.lab_id = value
    },
    setSchedule_date(state, value) {
        state.item.schedule_date = value
    },
    setSelected_date(state, value) {
        state.item.selected_date = value
    },
    setSelected_Detail(state, value) {
        state.item.selected_detail = value
    },
    setStudentAttendance(state, value) {
        state.item.student_attendance = value
    },
    setShowStudentAttendance(state, value) {
        state.item.show_student_attendance = value
    },
    setError(state, error) {
        state.error = error
    },
    setBatchSheduleAll(state, value) {
        state.batchSheduleAll = value
    },
    setLabsAll(state, value) {
        state.labsAll = value
    },
    setStudentId(state, value) {
        state.item.student_id = value
    },
    setCourseId(state, value) {
        state.item.course_id = value
    },
    setCourseAssessmentId(state, value) {
        state.item.course_assessment_id = value
    },
    setBatchSheduleDetailAll(state, value) {
        state.batchSheduleDetailAll = value
    },
    setPushBatchSheduleDetailAll(state, value) {
        state.batchSheduleDetailAll.push(value)
    },
    setStudentAttendanceDataAll(state, value) {
        state.studentAttendanceDataAll = value
    },
    setTopicStatus(state, value) {
        state.topic_status = value
    },
    setEmployeeAll(state, value) {
        state.employeeAll = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}