import store from '../../../store'
function initialState() {
    return {
        item: {
            id: null,
            branch_name: null,
            franchise : null,
            mobile_no: null,
            whatsapp_no: null,
            branch_email:null,
            branch_address: null,
            area:null,
            // city:null,
            city_list:null,
            state_list:null,
            country_list:{'id':99,'country_name':'India'},
            pincode:null,
            automatic_batch_creation: 0,
            allow_online_courses: 0,
            phone_no:null,
            branch_gst_no:null,
            branch_pan_no:null,
            licence_start_date:null,
            licence_end_date:null,
            workingDay1:true,
            workingDay2:true,
            workingDay3:true,
            workingDay4:true,
            workingDay5:true,
            workingDay6:true,
            workingDay7:null,
            branch_link:null
        },
        franchiseAll : [],
        addressAll: [],
        countryAll : [],
        stateAll : [],
        cityAll : [],
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    loading: state => state.loading,
    franchiseAll: state => state.franchiseAll,
    addressAll: state => state.addressAll,
    countryAll: state => state.countryAll,
    stateAll: state => state.stateAll,
    cityAll: state => state.cityAll,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            params.set('franchise_id',state.item.franchise['id']);
            params.delete('franchise');
            params.set('country',state.item.country_list['id']);
            params.delete('country_list');
            params.set('state',state.item.state_list['id']);
            params.delete('state_list');
            params.set('city',state.item.city_list['id']);
            params.delete('city_list');
            axios.post('/api/v1/branch', params)
                .then(response => {
                    // commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            params.set('franchise_id',state.item.franchise['id']);
            params.delete('franchise');
            params.set('country',state.item.country_list['id']);
            params.delete('country_list');
            params.set('state',state.item.state_list['id']);
            params.delete('state_list');
            params.set('city',state.item.city_list['id']);
            params.delete('city_list');
            if (_.isEmpty(state.item.branch_gst_no)) {
                params.set('branch_gst_no', '')
            } else {
                params.set('branch_gst_no', state.item.branch_gst_no)
            }
            if (_.isEmpty(state.item.whatsapp_no)) {
                params.set('whatsapp_no', '')
            } else {
                params.set('whatsapp_no', state.item.whatsapp_no)
            }         
            axios.post('/api/v1/branch/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)  
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
                
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axios.get('/api/v1/branch/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    })            
        dispatch('fetchFranchiseAll');
        dispatch('fetchCountryAll');
        // dispatch('fetchStateAll');  
        // dispatch('fetchCityAll');
    },
    async fetchAddressAll({ commit }, id) {
        await axios.get('/api/v1/franchises/' + id)
            .then(response => {
                commit('setAddressAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })            
    },    
    fetchFranchiseAll({ commit }) {
        axios.get('/api/v1/allFranchiseList/')
            .then(response => {
                commit('setFranchiseAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })
    },
    fetchCountryAll({ commit }) {
        axios.get('/api/v1/countrys/')
            .then(response => {
                commit('setCountryAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
        
    },
    
    fetchStateAll({ commit }) {
        axios.get('/api/v1/states/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setStateAll', response.data.data)
                }
                else{
                    commit('setStateAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        
    },
    fetchCountryStates({ commit }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStateAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        
    },
    fetchCityAll({ commit }) {
        axios.get('/api/v1/cities/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setCityAll', response.data.data)
                }
                else{
                    commit('setCityAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchStatesCities({ commit }, state_id) {
        axios.get('/api/v1/stateCitiesList/'+state_id)
            .then(response => {
                commit('setCityAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setBranchName({ commit }, value) {
        commit('setBranchName', value)
    },
    setFranchiseId({ commit }, value) {
        commit('setFranchiseId', value)
    },
    setMobileNo({ commit }, value) {
        commit('setMobileNo', value)
    },
    setWhatsappNo({ commit }, value) {
        commit('setWhatsappNo', value)
    },
    setBranchEmail({ commit }, value) {
        commit('setBranchEmail', value)
    },
    setBranchAddress({ commit }, value) {
        commit('setBranchAddress', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setState({ commit }, value) {
        commit('setState', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setPincode({ commit }, value) {
        commit('setPincode', value)
    },
    setPhoneNo({ commit }, value) {
        commit('setPhoneNo', value)
    },
    setBranchGstNo({ commit }, value) {
        commit('setBranchGstNo', value)
    },
    setBranchPanNo({ commit }, value) {
        commit('setBranchPanNo', value)
    },
    setLicenceStartDate({ commit }, value) {
        commit('setLicenceStartDate', value)
    },
    setLicenceEndDate({ commit }, value) {
        commit('setLicenceEndDate', value)
    },
    setWorkingDay1({ commit }, value) {
        commit('setWorkingDay1', value)
    },
    setWorkingDay2({ commit }, value) {
        commit('setWorkingDay2', value)
    },
    setWorkingDay3({ commit }, value) {
        commit('setWorkingDay3', value)
    },
    setWorkingDay4({ commit }, value) {
        commit('setWorkingDay4', value)
    },
    setWorkingDay5({ commit }, value) {
        commit('setWorkingDay5', value)
    },
    setWorkingDay6({ commit }, value) {
        commit('setWorkingDay6', value)
    },
    setWorkingDay7({ commit }, value) {
        commit('setWorkingDay7', value)
    },
    setBranchLink({ commit }, value) {
        commit('setBranchLink', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setStateAll({ commit }, value) {
      commit('setStateAll', value)
    },
    setCityAll({ commit }, value) {
        commit('setCityAll', value)
    },
    setAutomaticBranchCreation({ commit }, value) {
      commit('setAutomaticBranchCreation', value)
    },
    setAllowOnlineCourses({ commit }, value) {
        commit('setAllowOnlineCourses', value)
      },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setAutomaticBranchCreation(state, value) {
        state.item.automatic_batch_creation = value
    },
    setAllowOnlineCourses(state, value) {
        state.item.allow_online_courses = value
    },
    setBranchName(state, value) {
        state.item.branch_name = value
    },
    setFranchiseId(state, value) {
        state.item.franchise = value
    },
    setMobileNo(state, value) {
        state.item.mobile_no = value
    },
    setWhatsappNo(state, value) {
        state.item.whatsapp_no = value
    },
    setBranchEmail(state, value) {
        state.item.branch_email = value
    },
    setBranchAddress(state, value) {
        state.item.branch_address = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCity(state, value) {
        state.item.city_list = value
    },
    setState(state, value) {
        state.item.state_list = value
    },
    setCountry(state, value) {
        state.item.country_list = value
    },
    setPincode(state, value) {
        state.item.pincode = value
    },
    setPhoneNo(state, value) {
        state.item.phone_no = value
    },
    setBranchGstNo(state, value) {
        state.item.branch_gst_no = value
    },
    setBranchPanNo(state, value) {
        state.item.branch_pan_no = value
    },
    setLicenceStartDate(state, value) {
        state.item.licence_start_date = value
    },
    setLicenceEndDate(state, value) {
        state.item.licence_end_date = value
    },
    setWorkingDay1(state, value) {
        state.item.workingDay1 = value
    },
    setWorkingDay2(state, value) {
        state.item.workingDay2 = value
    },
    setWorkingDay3(state, value) {
        state.item.workingDay3 = value
    },
    setWorkingDay4(state, value) {
        state.item.workingDay4 = value
    },
    setWorkingDay5(state, value) {
        state.item.workingDay5 = value
    },
    setWorkingDay6(state, value) {
        state.item.workingDay6 = value
    },
    setWorkingDay7(state, value) {
        state.item.workingDay7 = value
    },
    setBranchLink(state, value) {
        state.item.branch_link = value
    },
    setFranchiseAll(state, value) {
        state.franchiseAll = value
    },
    setAddressAll(state, value) {
        state.addressAll = value
    },
    setCountryAll(state, value) {
        state.countryAll = value
    },
    setStateAll(state, value) {
        state.stateAll = value
    },
    setCityAll(state, value) {
        state.cityAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}

