<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Approvals</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Leave Approval</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Employee Name</th>
                                            <td>{{ item.name }}</td>
                                            </tr>
                                        <tr>
                                            <th>Leave Type</th>
                                            <td>
                                                <span v-if="item.leave_type !== null">
                                                    {{ item.leave_type.name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Leave From Date</th>
                                            <td>{{ item.leave_start_date }}</td>
                                            </tr>
                                        <tr>
                                            <th>Leave Upto Date</th>
                                            <td>{{ item.leave_end_date }}</td>
                                            </tr>
                                        <tr>
                                            <th>Leave Option</th>
                                            <td>
                                                <span v-if="item.leave_option !== null">
                                                    {{ item.leave_option.name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Start Time</th>
                                            <td>{{ item.start_time }}</td>
                                        </tr>
                                         <tr>
                                            <th>End Time</th>
                                            <td>{{ item.end_time }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>         
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>                                                                   
                                       
                                        <tr>
                                            <th>Leave Status</th>
                                            <td>
                                                 <span class="label label-info" v-if="item.leave_status !== null && item.leave_status.value_id ==1">
                                                    {{ item.leave_status.name }}
                                                </span>
                                                 <span class="label label-success" v-if="item.leave_status !== null && item.leave_status.value_id ==2">
                                                     {{ item.leave_status.name }}
                                                </span>
                                                 <span class="label label-danger" v-if="item.leave_status !== null && item.leave_status.value_id ==3">
                                                     {{ item.leave_status.name }}
                                                </span>
                                                 <span class="label label-primary" v-if="item.leave_status !== null && item.leave_status.value_id ==4">
                                                     {{ item.leave_status.name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Leave Reason</th>
                                            <td>{{ item.reason }}</td>
                                            </tr>
                                        <tr>
                                            <th>Leave Days</th>
                                            <td>{{ item.days }}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span v-if="item.leave_status && item.leave_status.value_id == 2">Approved By</span>
                                                <span v-if="item.leave_status && item.leave_status.value_id == 3">Rejected By</span>                                                
                                            </th>
                                            <td>{{ item.approveBy }}</td>
                                        </tr>
                                         <tr>
                                            <th>Remark</th>
                                            <td>{{ item.approved_remarks }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('LeaveApprovalSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('LeaveApprovalSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
