<template>
    <div class="btn-group btn-group-xs">
         <!-- <a
            v-if="$can(xprops.permission_prefix + 'access') && !row.noBatchAvailable"
            :href="xprops.domainName + '/generateAdmissionForm/' + row.id"
            class="btn btn-default edit_delete_btn"
            title="Print Admission Form"
            style="font-size: 1.4em; color: #cc1010;"
            target="_blank"
            >
            <i class="fa fa-fw fa-file-pdf-o"></i>
        </a>  
        <button v-b-modal.modalPrevent2 class="btn btn-info edit_delete_btn" title="Add Course">+</button>
        <button
                v-if="$can('student_batch_create')"
                @click="fetchBatchRedirectionDetail(row.id)"
                type="button"
                title="Batch Details"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-book" aria-hidden="true"></i>
        </button>  -->
        <!-- <button
                type="button"
                title="Add Course"
                class="btn btn-info edit_delete_btn">
            <i class="fa fa-book" aria-hidden="true"></i>
        </button>   -->

        <b-btn v-b-modal.addStudentCourse @click="passAddId(row.id)" title="Add Course" type="button" variant="info" class="btn btn-info edit_delete_btn">
            <i class="fa fa-book"></i>
        </b-btn> 
      
         <!-- <router-link
                v-if="$can('receipt_create')"
                :to="{name: 'receipts.create', params: { 'student_id_from_props': row.id }}"
                class="btn btn-warning edit_delete_btn"
                title="Add Receipt"
                >
            <i class="fa fa-fw fa-rupee"></i>
        </router-link> -->
         <router-link
                v-if="$can(xprops.permission_prefix + 'view')"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>

        <b-btn v-b-modal.editStudent @click="passEditId(row.id)" v-if="$can(xprops.permission_prefix + 'edit')" title="Edit" type="button" variant="primary" class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </b-btn> 
        <!-- <router-link
                v-if="$can(xprops.permission_prefix + 'edit')"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link> -->
        <button
                v-if="$can(xprops.permission_prefix + 'delete')"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-danger edit_delete_btn">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <div id="loader_placeholder" v-show="loadingRedirectionDetails">
            <div id="loadingDiv">
                <img src="/assets/images/loader.png" class="ajax-loader" alt="Loading...">
            </div>
        </div>        
   </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module,
            loadingRedirectionDetails: false
        }
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        passAddId(id){
            this.$emit('addIdModel',id) 
        },
        passEditId(id){
            this.$emit('editIdModel',id) 
        },
        async fetchBatchRedirectionDetail(id) {
            this.loadingRedirectionDetails = true
            // await axios.get('/api/v1/fetchCurrentActiveBatch/'+ id)
            //     .then((response) => {
            //         if(response.data.data.length == 0) {
                        this.$router.push({name: 'student_batch.create', params: { 'student_id_from_props': this.row.id }})
                //     } else {
                //         this.$router.push('/student-batch/' + response.data.data[0].id + '/edit')   
                //     }
                // })
        },
        clearAll() {
           // this.clear();
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(response => {
                        if(response.data.result){
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                        //This code Refreshes the page - DENISH
                        //setTimeout(function () { this.$router.go() }.bind(this), 1000) 
                            this.$emit('loadItems') 
                        }else{
                            this.$eventHub.$emit('common',2,response.data.message)
                        }        
                    })
                    .catch(error => {
                        this.$eventHub.$emit('delete-error')
                    })
                }
            })
        }
    }
}
</script>


<style scoped>
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
</style>
