function initialState() {
    return {
        item: {
            employee: null,
            schedule_date: new Date(),  
            relationships: {},                  
            student_id:null,
            course_id:null,
            topic_id:null,
            lab_id:null,            
            fully:null,
            partial:null,
            need_improvement:null,
            course_assessment_id:0,
            answer:0,
            student_attendance:[],
            show_student_attendance:[],
        }, 
        topicsAll: [],        
        employeeAll: [],        
        batchSheduleAll: [],
        batchSheduleDetailAll: [],      
        computedAssessmentData: [],      
        labsAll: [],              
        studentAttendanceDataAll:[],
        assessmentRemark: '',
        isLastAttendance: false,
        loading: false,
        loadingTopics: false,
        loadingShowData: true,
        loadingSheduleDetail: false,
        courseTopics:[],
        query: {},
        error: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    topics: state => state.topics,
    isLastAttendance: state => state.isLastAttendance,
    loadingTopics: state => state.loadingTopics,
    assessmentRemark: state => state.assessmentRemark,
    employeeAll: state => state.employeeAll,
    labsAll: state => state.labsAll,
    loadingSheduleDetail: state => state.loadingSheduleDetail,
    loadingShowData: state => state.loadingShowData,
    computedAssessmentData: state => state.computedAssessmentData,
    batchSheduleAll: state => state.batchSheduleAll,
    studentAttendanceDataAll: state => state.studentAttendanceDataAll,
    batchSheduleDetailAll: state => state.batchSheduleDetailAll,
    courseTopics: state => state.courseTopics,
    data: state => {
       let rows = state.computedAssessmentData
        if (state.query.sort) {
            rows = _.orderBy(state.computedAssessmentData, state.query.sort, state.query.order)
        }
       
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','topic_name','topic_session_no','display_topic_status','status_update_by','status_update_date'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
          
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.computedAssessmentData

        if (state.query.sort) {
            rows = _.orderBy(state.computedAssessmentData, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','course_name','topic_name','topic_session_no','display_topic_status','status_update_by','status_update_date'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    storeData({ commit, state, dispatch }) {
        //commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            params.set('attendanceData',purify(state.studentAttendanceDataAll));
            
            // return false;
            state.studentAttendanceDataAll.forEach(function(studentAttendance, index){
                //console.log(studentAttendance);
                params.set('attendanceData['+index+'][student_id]',studentAttendance.student_id);
                params.set('attendanceData['+index+'][course_id]',studentAttendance.course_id);
                params.set('attendanceData['+index+'][batch_id]',studentAttendance.student_batch_id);    
                params.set('attendanceData['+index+'][session_date]',state.item.schedule_date);    
                params.set('attendanceData['+index+'][session_no]',studentAttendance.session_no);   
                params.set('attendanceData['+index+'][topic_session_no]',studentAttendance.topic_session_no);
                params.set('attendanceData['+index+'][topic_status]',studentAttendance.topic_status);                
                params.set('attendanceData['+index+'][is_present]',studentAttendance.is_present);                
                params.set('attendanceData['+index+'][is_assessment_required]',studentAttendance.is_assessment_required);                
                
            })
            
            axios.post('/api/v1/student-assessment', params)
                .then(response => {
                    commit('setBatchSheduleDetailAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
                .finally(() => {
                    //commit('setLoading', false)
                })
        })
    },
    storeAssessmentRemark({ commit, state, dispatch }, dataObj) {
        return new Promise((resolve, reject) => {
                axios.post('/api/v1/storeAsessmentRemark', dataObj)
                    .then(response => {
                        //commit('resetState')
                        resolve(response)
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
        })
    },    
    markingCourseAsCompleted({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('student_id',state.item.detail.student_id);
            params.set('course_id',state.item.detail.course_id);
            axios.post('/api/v1/markingCourseAsCompleted', params)
                .then(response => {
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateStatus({ commit, state, dispatch },dataObj) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('student_id',state.item.detail.student_id);
            params.set('course_id',state.item.detail.course_id);
            params.set('course_topic_id',dataObj.topicId);
            params.set('encode_id',state.item.detail.encode_id);
            params.set('statusToBeMarked',dataObj.statusToBeMarked);
            axios.post('/api/v1/updateTopicStatus', params)
                .then(response => {
                    commit('setIfLastAttendance',response.data.data[0])
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateStatusZeroQuestion({ commit, state, dispatch },dataObj) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('student_topic_status_table_index',dataObj.studentTopicStatusTableIndex);
            params.set('statusToBeMarked',dataObj.statusToBeMarked);
            params.set('course_id',state.item.detail.course_id);
            params.set('student_id',state.item.detail.student_id);
            axios.post('/api/v1/updateStatusZeroQuestion', params)
                .then(response => {
                    commit('setIfLastAttendance',response.data.data[0])
                    resolve()
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            axios.post('/api/v1/student-assessment/' + state.item.detail.encode_id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
     showAssessmentData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                params.set('student_id',state.item.show_student_attendance.student_id);
                params.set('course_id',state.item.show_student_attendance.course_id);
                params.set('def_trainer_emp_id',state.item.show_student_attendance.def_trainer_emp_id);
                params.set('topic_id',state.item.show_student_attendance.topic_id);
                // params.set('fully',state.item.show_student_attendance.fully);
                // params.set('partial',state.item.show_student_attendance.partial);
                // params.set('need_improvement',state.item.show_student_attendance.need_improvement);
                params.set('student_batch_id',state.item.show_student_attendance.student_batch_id);
                params.set('schedule_date',state.item.show_student_attendance.schedule_date);
            
            axios.post('/api/v1/showAssessment/', params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },

    async fetchData({ commit, dispatch }, id) {
        commit('setLoadingShowData', true)
        await axios.get('/api/v1/student-assessment/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })        
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
                .finally(() => {
                    commit('setLoadingShowData', false)
                })            
    },
    async fetchEmployeeAll({ commit }) {
        await axios.get('/api/v1/currentEmployee')
            .then(response => {
                commit('setEmployeeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
    },    
    async fetchLabsAll({ commit }, params) {
        await axios.get('/api/v1/labList', params)
            .then(response => {
                commit('setLabsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
    },    
    async fetchBatchSheduleAll({ commit, dispatch }, date) {
        commit('setLoading', true)
        // commit('setLoadingSheduleDetail', true)
        await axios.get('/api/v1/getBatchShedule/'+ date)
            .then(response => {
                commit('setBatchSheduleAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
            .finally(() => {
                commit('setLoading', false)
                //commit('setLoadingSheduleDetail', false)
            })
    },
    async fetchBatchSheduleDetailAll({ commit, dispatch }, obj) {
        commit('setLoadingSheduleDetail', true)
        commit('setSchedule_date', obj.date);
        await axios.get('/api/v1/getBatchSheduleDetailAssessment/'+ obj.id+'/'+ obj.date+'/'+"0")
            .then(response => {
                commit('setBatchSheduleDetailAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
            .finally(() => {
                commit('setLoadingSheduleDetail', false)
            })
    },   
    saveStudentAssessmentData({ commit, dispatch }, obj) {
        axios.get('/api/v1/saveStudentAssessmentData/'+ obj.id+'/'+obj.date)
            .then(response => {
                // commit('setBatchSheduleDetailAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
    },  
    fetchCourseTopics({ commit, state, dispatch },data) {
        commit('setLoadingTopics', true)
        
        return new Promise((resolve, reject) => {
            let params = new FormData();

            params.set('course_id', data.course_id)
            params.set('student_id', data.student_id)
//            params.set('start_date', data.start_date)
            
            params.set('prefer_batch_id', '0')
            params.set('employee_id', '0')
//            data.prefer_batch_id = 0;
//            data.employee_id = 0;
//            console.log(params)
            axios.post('/api/v1/getCourseTopics', params)
                .then(response => {
                    commit('setCourseTopics', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          // reject(error)
                    })     
                .finally(() => {
                    commit('setLoadingTopics', false)
                })
        })
    }, 
    setAssessmentRemark({ commit }, value) {
        commit('setAssessmentRemark', value)
    },
    setEmployee({ commit }, value) {
        commit('setEmployee', value)
    },    
    setSchedule_date({ commit }, value) {
        commit('setSchedule_date', value)
    },
    setSelected_date({ commit }, value) {
        commit('setSelected_date', value)
    }, 
    setSelected_Detail({ commit }, value) {
        commit('setSelected_Detail', value)
    },  
    setStudentId({ commit }, value) {
        commit('setStudentId', value)
    },
    setIfLastAttendance({ commit }, value) {
        commit('setIfLastAttendance', value)
    },    
    setCourseId({ commit }, value) {
        commit('setCourseId', value)
    },
    setTopicId({ commit }, value) {
        commit('setTopicId', value)
    },
    setLab({ commit }, value) {
        commit('setLab', value)
    },    
    setFully({ commit }, value) {
        commit('setFully', value)
    },
    setPartial({ commit }, value) {
        commit('setPartial', value)
    },
    setNeedImprovement({ commit }, value) {
        commit('setNeedImprovement', value)
    },
    setCourseAssessmentId({ commit }, value) {
        commit('setCourseAssessmentId', value)
    },
    setStudentAttendance({ commit }, value) {
        commit('setStudentAttendance', value)
    },
    setAnswer({ commit }, value) {
        commit('setAnswer', value)
    },
    setBatchSheduleAll({ commit }, value) {
      commit('setBatchSheduleAll', value)
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },  
    setBatchSheduleDetailAll({ commit }, value) {
        commit('setBatchSheduleDetailAll', value)
    },
    setStudentAttendanceDataAll({ commit }, value) {
        commit('setStudentAttendanceDataAll', value)
    },
    setShowStudentAttendance({ commit }, value) {
        commit('setShowStudentAttendance', value)
    },
    setComputedAssessmentData({ commit }, value) {
        commit('setComputedAssessmentData', value)
    },    
    setLoading({ commit }, loading) {
        commit('setLoading', loading)
    },
    setLoadingSheduleDetail({ commit }, loading) {
        commit('setLoadingSheduleDetail', loading)
    },
    setLoadingTopics({ commit }, loading) {
        commit('setLoadingTopics', loading)
    },
    setTopicStatus({ commit }, value) {
        commit('setTopicStatus', value)
    },
    setCourseTopics({ commit }, value) {
        commit('setCourseTopics', value)
    },
     resetState({ commit }) {
        commit('resetState')
    },    
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setAssessmentRemark(state, assessmentRemark) {
        state.assessmentRemark = assessmentRemark
    },
    setIfLastAttendance(state, value) {
        state.isLastAttendance = value
    },      
    setComputedAssessmentData(state, value) {
        state.computedAssessmentData = value
    }, 
    setQuery(state, query) {
        state.query = query
    },
    setLoading(state, loading) {
        state.loading = loading
    },    
    setLoadingTopics(state, loading) {
        state.loadingTopics = loading
    },  
    setLab(state, value) {
        state.item.lab_id = value
    },      
    setLoadingSheduleDetail(state, loading) {
        state.loadingSheduleDetail = loading
    },
    setLoadingShowData(state, loading) {
        state.loadingShowData = loading
    },    
    setEmployee(state, value) {
        state.item.employee = value
    },
    setSchedule_date(state, value) {
        state.item.schedule_date = value
    },
    setSelected_date(state, value) {
        state.item.selected_date = value
    },
    setSelected_Detail(state, value) {
        state.item.selected_detail = value
    },
    setStudentAttendance(state, value) {
        state.item.student_attendance = value
    }, 
    setShowStudentAttendance(state, value) {
        state.item.show_student_attendance = value
    },  
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setError(state, error) {
        state.error = error
    },    
    setBatchSheduleAll(state, value) {
        state.batchSheduleAll = value
    },
    setStudentId(state, value) {
        state.item.student_id = value
    },
    setCourseId(state, value) {
        state.item.course_id = value
    },
    setTopicId(state, value) {
        state.item.topic_id = value
    },
    setLabsAll(state, value) {
        state.labsAll = value
    },      
    setFully(state, value) {
        state.item.fully = value
    },
    setPartial(state, value) {
        state.item.partial = value
    },
    setNeedImprovement(state, value) {
        state.item.need_improvement = value
    },
    setCourseAssessmentId(state, value) {
      state.item.course_assessment_id = value
    },
    setAnswer(state, value) {
        state.item.answer = value
    },
    setBatchSheduleDetailAll(state, value) {
        state.batchSheduleDetailAll = value
    }, 
    setStudentAttendanceDataAll(state, value) {
        state.studentAttendanceDataAll = value        
    },   
    setTopicStatus(state, value) {
        state.topic_status = value        
    },   
    setEmployeeAll(state, value) {
        state.employeeAll = value
    },    
    setCourseTopics(state, value) {
        state.courseTopics = value
    }, 
    setTopicsAll(state, value) {
        state.topicsAll = value
    },        
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
