function initialState() {
    return {
        relationships: {},
        item: {
            branch_id: null,
            student_id: null,
            from_date:null,
            to_date:null,
        },
        query: {},
        branchMastersAll: [], 
        studentAll: [], 
        reportsDataAll: [],    
        loading: false,
        error: false
    }
} 

const getters = {
    item: state => state.item,
    data: state => {
        let rows = state.reportsDataAll
        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','regular','camp','competition'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    total: state => {
        let rows = state.reportsDataAll

        if (state.query.sort) {
            rows = _.orderBy(state.reportsDataAll, state.query.sort, state.query.order)
        }
        var queryFilter = JSON.stringify(state.query);
        var queryFilter = JSON.parse(queryFilter);
           ['student_name','regular','camp','competition'].forEach(field => {
            switch (typeof queryFilter[field]) {
              case 'array':
                rows = _.filter(rows, function(item){
                  if(queryFilter[field].includes(item[field]))
                  {
                    return queryFilter[field] ;
                  }
                });
                break
                case 'string':
                rows = _.filter(rows, function(item){
                  if(item[field] && typeof item[field] === 'number')
                  {
                    if(item[field] == queryFilter[field])
                    {
                        return item[field] ;
                    }
                    else if(queryFilter[field] == '')
                    {
                        return item[field] ;   
                    }

                  }
                  else if(item[field] && item[field].toLowerCase().indexOf(queryFilter[field].toLowerCase())> -1)
                  {
                    return item[field] ;
                  }
                   else if(queryFilter[field] == '' || queryFilter[field] == null){
                    if(item[field] == '' || item[field] == null){
                        return '  ' ;
                    }
                    else{
                        return item[field]
                    }
                    
                  }                  
                });
                break 
              default:
                // nothing to do
                break
            }
          }) 
        return rows.length;
    },        
    loading: state => state.loading,
    branchMastersAll: state => state.branchMastersAll,
    studentAll: state => state.studentAll,
    reportsDataAll: state => state.reportsDataAll,
    relationships: state => state.relationships,
    error: state => state.error
}

const actions = {
    async feesPaidAndBalanceReportListStore({ commit }, dataObj) {
      window.location.href = location.protocol + "//" + location.host + '/feesPaidAndBalanceReportList/' + dataObj.branch_id + '/' + dataObj.student_id;
    },    
    fetchFeesandPaidBalanceData({ commit, state }, obj) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = {};
            if (_.isEmpty(state.item.from_date)) {
                params.from_date = '';
            } else {
                params.from_date = state.item.from_date
            }
            if (_.isEmpty(state.item.to_date)) {
                params.to_date = ''
            } else {
                params.to_date = state.item.to_date
            }
            axios.get('/api/v1/studentCompletedCourseData/' + 
                obj.branch_id + '/' + 
                obj.student_id + '/' + 
                params.from_date + '/' + 
                params.to_date)
                .then(response => {
                    commit('setFeesPaidAndBalanceReportData', response.data.data)
                    resolve(response)
                })                
            .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchBranchMasterAll({ commit }) {
        await axios.get('/api/v1/branchList')
            .then(response => {
                commit('setBranchMasterAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
    },
    async fetchStudentAll({ commit }, id) {
        await axios.get('/api/v1/branchWiseStudentAll/' + id)
            .then(response => {
                commit('setStudentAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },        
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },     
    setBranch({ commit }, value) {
        commit('setBranch', value)
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    }, 
    setStudentAll({ commit }, value) {
      commit('setStudentAll', value)
    },
    setFeesPaidAndBalanceReportData({ commit }, value) {
        commit('setFeesPaidAndBalanceReportData', value)
    },     
    setFromDate({ commit }, value) {
        commit('setFromDate', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },       
    resetState({ commit }) {
        commit('resetState')
    },
    
}

const mutations = {  
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },    
    setError(state, error) {
        state.error = error
    },   
    setFromDate(state, value) {
        state.item.from_date = value
    },
    setExit_date(state, value) {
        state.item.to_date = value
    },    
    setBranchMasterAll(state, value) {
        state.branchMastersAll = value
    },  
    setStudentAll(state, value) {
        state.studentAll = value
    },    
    setBranch(state, value) {
        state.item.branch_id = value
    },
    setStudent(state, value) {
        state.item.student_id = value
    },
    setFeesPaidAndBalanceReportData(state, value) {
        state.reportsDataAll = value
    }, 
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
