function initialState() {
    return {
        session: null,
    }
}

const getters = {
    session: state => state.session,
}

const actions = {
    fetchData({ commit }, session) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/sessionDetails')
            .then(response => {
                commit('setSession', response.data.data)
                resolve(response)
            })
            .catch(function (error) {
                          console.log('error',error)
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
        })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setSession(state, session) {
        state.session = session
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
