<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Common Inquries</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/common-inquiries'">Common Inquries</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Common Inquries</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Common Inquiry</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12  table-responsive">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>Utm Source</th>
                                            <td>{{ item.utm_source ? item.utm_source : '-' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Utm Medium</th>
                                            <td>{{ item.utm_medium ? item.utm_medium : '-'}}</td>
                                            </tr>
                                        <tr>
                                            <th>Utm Campaign</th>
                                            <td>{{ item.utm_campaign ? item.utm_campaign : '-'}}</td>
                                            </tr>
                                        <tr>
                                            <th>Branch</th>
                                            <td>{{ item.branch_name ? item.branch_name : '-'}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <th>Common Url</th>
                                            <td>{{ item.common_url ? item.common_url : '-'}}</td>
                                        </tr> -->
                                        <tr>
                                            <th>Url</th>
                                            <td>{{ item.encoded_common_url ? item.encoded_common_url : '-'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
        }
    },
    created() {
        this.fetchSingleData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CommonInquirySingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchSingleData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('CommonInquirySingle', ['fetchSingleData', 'resetState'])
    }
}
</script>