<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Fee Adjustment</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Fee Adjustment</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="col-md-6">
                                    <div ref="course" class="form-group">
                                        <label for="course">Student Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="student_name"
                                                label="first_name"
                                                disabled="" 
                                                v-validate="'required'"
                                                @input="updateStudentName"
                                                :value="item.student_name"
                                                :options="studentNameAll"
                                                />
                                             <span class="error" v-show="errors.has('student_name')">
                                              {{ $root.updateErrorMessage('student_name',this.errors,'Student Name')}}
                                            </span>
                                    </div>
                                </div>
                          
                             <div class="col-md-6">
                                    <div ref="fees_type" class="form-group">
                                        <label for="fees_type">Fee Type<p class="astrisk">*</p></label>
                                        <v-select
                                                name="fees_type"
                                                label="name"
                                                disabled=""
                                                v-validate="'required'"
                                                @input="updateFees_type"
                                                :value="item.fees_type"
                                                :options="feesTypeAll"
                                                />
                                             <span class="error" v-show="errors.has('fees_type')">
                                              {{ $root.updateErrorMessage('fees_type',this.errors,'Fee Type')}}
                                            </span>
                                    </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="fees_amount">Fee Amount<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="fees_amount"
                                            ref="fees_amount"
                                            maxlength="8"
                                            v-validate="'required|min_value:0.01'"
                                            placeholder="Enter Fee Amount"             
                                            v-model="totalCountFees"
                                            @input="updateFees_amount"
                                            oninput="this.value=this.value
                                                                      .replace(/[^\d.]/g, '')
                                                                      .replace(/(\..*)\./g, '$1')
                                                                      .replace(/(\.[\d]{2})./g, '$1');"                                            
                                            >
                                         <span class="error" v-show="errors.has('fees_amount')">
                                              {{ $root.updateErrorMessage('fees_amount',this.errors,'Fee Amount','a valid value')}}
                                            </span>
                                </div>
                            </div>
                            
                              
                          <!--   <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="gst_rate">GST Rate(%)<p class="astrisk">*</p></label>
                                        <input
                                                type="number"
                                                class="form-control"
                                                name="gst_rate"
                                                ref="gst_rate"
                                                v-validate="'between:0,99'"
                                                placeholder="Enter GST Rate(%)"
                                                v-model="totalCountGST"
                                                readonly=""
                                                @input="updateGst_rate"
                                                >
                                            <span class="error" v-show="errors.has('gst_rate')">
                                              {{ $root.updateErrorMessage('gst_rate',this.errors,'GST Rate(%)')}}
                                            </span> 
                                    </div>
                                </div>
                              -->
                         <!--    <div class="col-md-6">
                                <div class="form-group">
                                    <label for="total_fees">Total Fee<p class="astrisk">*</p></label>
                                    <input
                                            type="number"
                                            class="form-control"
                                            name="total_fees"
                                            ref="total_fees"
                                            readonly=""
                                            v-validate="'required'"
                                            placeholder="Enter Total Fee"
                                            :value="countTotalFees()"
                                            @input="updateTotal_fees"
                                            >
                                         <span class="error" v-show="errors.has('total_fees')">
                                              {{ $root.updateErrorMessage('total_fees',this.errors,'Total Fee')}}
                                            </span>
                                </div>
                                </div> -->
                                
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            totalCountFees:0,    
            totalCountFeesDiscount:0,    
            totalCountGST:0,
        }

    },
    computed: {
        ...mapGetters('FeeAdjustmentSingle', ['item', 'loading', 'studentNameAll', 'feesTypeAll']),
        
    },
    created() {
        this.fetchData(this.$route.params.id)        
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        "item": function() {
          this.totalCountFees = this.item.fees_amount;  
          this.totalCountFeesDiscount = this.item.fees_discount;  
          this.totalCountGST = this.item.gst_rate;
        },
    },
    methods: {
        ...mapActions('FeeAdjustmentSingle', ['fetchData', 'fetchStudentAll','updateData', 'resetState', 'setStudentName', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type','setFees_discount','setGstAmount']),
        /**
         * Public function to count Total fees
         * @author        Rajesh R. Rathod
         * @since         1th Jan 2019
         * @description   Function to count Total fees.
         */
        countTotalFees(){        
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) - (parseFloat(this.totalCountFeesDiscount));
            }
            this.setTotal_fees(myVal.toFixed(2));  
            this.updateGstAmount(myVal.toFixed(2));          
            return myVal.toFixed(2);
        },
        updateStudentName(value) {
            this.setStudentName(value)
        },
        updateFees_amount(e) {
            this.setTotal_fees(e.target.value)
            this.setFees_amount(e.target.value)
        },

    /**
     * Public function to set GST  value
     * @author        Rajesh R. Rathod
     * @since         1th Jan 2019
     * @description   Function to count gst value e.q 900*100/100+15 15 = gst rate.
     */
        updateGstAmount(value) {
            var gstValue = 0;
            if(value){
                   gstValue = (parseFloat(value)*100)/(100+parseInt(this.totalCountGST));
                   this.setGstAmount((value - gstValue.toFixed(2)));
            }
        },
        updateFees_discount(e) {
            if(e && e.target.value){             
                this.setFees_discount(e.target.value)
            }else{             
                this.setFees_discount(0)
            }
        },
        updateIs_gst_applicable(e) {
            // this.setIs_gst_applicable(e.target.value)
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                this.totalCountGST = 0;
                this.setGst_rate(0)
            }
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },        
        updateFees_type(value) {
            if(value && value.other_value != null){
                        this.totalCountGST = (value.other_value)?value.other_value:0;
                        this.setGst_rate(this.totalCountGST);
                        this.setFees_type(value)
                    }
        },  
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                this.updateData()
                    .then((res) => {
                                               
                        this.$router.push({ name: 'fees_adjustment.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
          });
        }
    }
}
</script>


<style scoped>

</style>
