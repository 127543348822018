<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Workshops</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Workshop</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="form-group container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="workshop_date" class="form-group">
                                                <label for="workshop_date">Workshop Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    v-model= "workshop_date"
                                                    :config="$root.dpconfigDatePast"
                                                    name="workshop_date"
                                                    placeholder="Select Workshop Date"
                                                    v-validate="'required'"
                                                    autocomplete="off"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('workshop_date')">
                                                    {{ $root.updateErrorMessage('workshop_date',this.errors)}}
                                                  </span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="duration">Duration (Hours)<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="duration"
                                                        name="duration"
                                                        id="duration"
                                                        v-validate="'required|min_value:0.01'"
                                                        placeholder="Enter Workshop duration"
                                                        maxlength="5"
                                                        oninput="this.value=this.value
                                                                          .replace(/[^\d.]/g, '')
                                                                          .replace(/(\..*)\./g, '$1')
                                                                          .replace(/(\.[\d]{2})./g, '$1');"
                                                        v-model="duration"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('duration')">
                                                          {{ $root.updateErrorMessage('duration',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                  <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="first_name">First Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="first_name"
                                                        name="first_name"
                                                        id="first_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter First Name"
                                                        v-model="first_name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('first_name')">
                                                          {{ $root.updateErrorMessage('first_name',this.errors,'First Name')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="last_name">Last Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="last_name"
                                                        name="last_name"
                                                        id="last_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter Last Name"
                                                        v-model="last_name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('last_name')">
                                                          {{ $root.updateErrorMessage('last_name',this.errors,'Last Name')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div ref="birth_date" class="form-group col-sm-12 col-md-6">
                                            <label for="birth_date">Birth Date</label>
                                            <date-picker
                                                    v-model="birth_date"
                                                    :config="dobDateOptionFormate"
                                                    name="birth_date"
                                                    placeholder="Select Birth Date"
                                                    :endDate="disabledDates"
                                                    autocomplete="off"
                                                    >
                                            </date-picker>
                                            <span class="error" v-show="errors.has('birth_date')">
                                              {{ $root.updateErrorMessage('birth_date',this.errors,'Birth Date')}}
                                            </span>
                                        </div>   
                                        <div class="form-group col-md-4 col-xs-12">
                                            <label for="gender">Gender<p class="astrisk">*</p></label>
                                            <br>
                                              <div class="radio-inline">
                                                <label class="contain">
                                                    <input
                                                            type="radio"
                                                            name="gender"
                                                            ref="gender"
                                                            v-validate="'required|included:1,2'"
                                                            :value="1"
                                                            :checked="gender === 1"
                                                            @change="updateGender(1)"
                                                            >
                                                    <span class="checkmark"></span> Male
                                                </label>
                                            </div>
                                           <div class="radio-inline">
                                                <label class="contain">
                                                    <input
                                                            type="radio"
                                                            name="gender"
                                                            ref="gender"
                                                            :value="2"
                                                            :checked="gender === 2"
                                                            @change="updateGender(2)"
                                                            >
                                                    <span class="checkmark"></span> Female
                                                </label>
                                            </div>
                                            <br>
                                            <span class="error" v-show="errors.has('gender')">
                                              {{ $root.updateErrorMessage('gender',this.errors)}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="age">Age (year)<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="age"
                                                        name="age"
                                                        id="age"
                                                        maxlength="3"
                                                        v-validate="'required|min_value:1'"
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                        placeholder="Enter Age"
                                                        v-model="age"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('age')">
                                                          {{ $root.updateErrorMessage('age',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>                                        
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="contact_name">Contact Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="contact_name"
                                                        name="contact_name"
                                                        id="contact_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter Contact Name"
                                                        v-model="contact_name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('contact_name')">
                                                          {{ $root.updateErrorMessage('contact_name',this.errors,'Contact Name')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="contact_number">Contact Number<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="contact_number"
                                                        name="contact_number"
                                                        id="contact_number"
                                                        maxlength="20"
                                                        v-validate="'required|digits:10'"
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                        placeholder="Enter Contact Number"
                                                        v-model="contact_number"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('contact_number')">
                                                          {{ $root.updateErrorMessage('contact_number',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="course_offered" class="form-group">
                                                <label for="course_offered">Course offered<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="course_offered"
                                                        label="display_course_name"
                                                        id="course_offered"
                                                        v-validate="'required'"
                                                        @input="updateCourseOffered"
                                                        :value="item.course_offered"
                                                        :options="courseOfferedAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('course_offered')">
                                                    {{ $root.updateErrorMessage('course_offered',this.errors, 'Course Offered')}}
                                                </span>
                                            </div>
                                        </div>                                  
                                    </div>
                                    <div class="form-group col-md-6 col-sm-12" >
                                        <label for="workshop_type">Workshop Type<p class="astrisk">*</p></label>
                                        <br>
                                        <div class="radio-inline">
                                            <label class="contain">
                                                <input
                                                        type="radio"
                                                        name="workshop_type"
                                                        ref="workshop_type"
                                                        v-validate="'required|included:0,1'"
                                                        :value="0"
                                                        :checked="workshop_type === 0"
                                                        @change="updateWorkshopType(0)"
                                                        >
                                                        <span class="checkmark"></span>
                                                Free
                                            </label>
                                        </div>
                                        <div class="radio-inline">
                                            <label class="contain">
                                                <input
                                                        type="radio"
                                                        name="workshop_type"
                                                        ref="workshop_type"
                                                        :value="1"
                                                        :checked="workshop_type === 1"
                                                        @change="updateWorkshopType(1)"
                                                        >
                                                        <span class="checkmark"></span>
                                                Paid
                                            </label>

                                        </div><br>
                                        <span class="error" v-show="errors.has('workshop_type')">
                                          {{ $root.updateErrorMessage('workshop_type',this.errors)}}
                                        </span>
                                    </div>                                          
                                    <div class="row" v-if="workshop_type == 1">
                                        <div class="col-sm-12 col-md-6">
                                            <div ref="payment_mode_id" class="form-group">
                                                <label for="payment_mode_id">Payment Mode<p class="astrisk">*</p></label>
                                                <v-select
                                                        name="payment_mode_id"
                                                        label="name"
                                                        id="payment_mode_id"
                                                        v-validate="'required'"
                                                        @input="updatePaymentModeId"
                                                        :value="item.payment_mode"
                                                        :options="paymentModeAll"
                                                        autocomplete="off"
                                                        />
                                                <span class="error" v-show="errors.has('payment_mode_id')">
                                                    {{ $root.updateErrorMessage('payment_mode_id',this.errors, 'Payment Mode')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="workshop_type == 1 && item.payment_mode && item.payment_mode.value_id != 1">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="instrument_no">Cheque/Transaction Number<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="instrument_no"
                                                        name="instrument_no"
                                                        id="instrument_number"
                                                        maxlength="100"
                                                        v-validate="'required'"
                                                        placeholder="Enter Cheque/Transaction Number"
                                                        v-model="instrument_no"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('instrument_no')">
                                                          {{ $root.updateErrorMessage('instrument_no',this.errors,'Cheque/Transaction number')}}
                                                        </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="bank_name">Bank Name<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="bank_name"
                                                        name="bank_name"
                                                        id="bank_name"
                                                        maxlength="200"
                                                        v-validate="'required'"
                                                        placeholder="Enter Bank Name"
                                                        v-model="bank_name"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('bank_name')">
                                                          {{ $root.updateErrorMessage('bank_name',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row"  v-if="workshop_type == 1 && item.payment_mode">
                                        <div class="col-sm-12 col-md-6" v-if="item.payment_mode.value_id != 1">
                                            <div ref="instrument_date" class="form-group">
                                                <label for="instrument_date">Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                    v-model= "instrument_date"
                                                    :config="$root.dpconfigDate"
                                                    name="instrument_date"
                                                    placeholder="Select Date"
                                                    v-validate="'required'"
                                                    autocomplete="off"
                                                    >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('instrument_date')">
                                                    {{ $root.updateErrorMessage('instrument_date',this.errors,'Date')}}
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="amount">Amount<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="amount"
                                                        name="amount"
                                                        id="amount"
                                                        maxlength="17"
                                                        v-validate="'required|min_value:0.01'"
                                                        placeholder="Enter Amount"
                                                        v-model="amount"
                                                        oninput="this.value=this.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/(\..*)\./g, '$1')
                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('amount')">
                                                          {{ $root.updateErrorMessage('amount',this.errors,'Amount','a valid value')}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"  v-if="workshop_type == 1 && item.payment_mode">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <label for="manual_receipt_no">Manual Receipt Number</label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        ref="manual_receipt_no"
                                                        name="manual_receipt_no"
                                                        id="manual_receipt_no"
                                                        maxlength="17"
                                                        v-validate="''"
                                                        placeholder="Enter Manual Receipt  Number"
                                                        v-model="manual_receipt_no"
                                                        autocomplete="off"
                                                        >
                                                        <span class="error" v-show="errors.has('manual_receipt_no')">
                                                          {{ $root.updateErrorMessage('manual_receipt_no',this.errors,'Manual Receipt Number')}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <label for="workshop_details">Workshop Details</label>
                                                <textarea
                                                        type="text"
                                                        class="form-control"
                                                        ref="workshop_details"
                                                        name="workshop_details"
                                                        id="workshop_details"
                                                        maxlength="500"
                                                        v-validate="'max:500'"
                                                        placeholder="Enter Workshop Details"
                                                        v-model="workshop_details"
                                                        autocomplete="off"
                                                        ></textarea>
                                                        <span class="error" v-show="errors.has('workshop_details')">
                                                          {{ $root.updateErrorMessage('workshop_details',this.errors)}}
                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                               
                             </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                    
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
   
export default {
    data() {
        return {
            workshop_date: '',
            first_name: '',
            last_name: '',
            birth_date: '',
            gender: 1,            
            age: '',
            contact_name: '',
            contact_number: '',
            workshop_details: '',
            duration: '',
            workshop_type: 0,
            instrument_no: '',
            instrument_date: '',
            manual_receipt_no: '',
            bank_name: '',
            amount: '',
            dobDateOptionFormate:{format:window.date_format_moment, maxDate: this.getDOBDate()}
        }
    },
    computed: {
        ...mapGetters('WorkshopSingle', ['item', 'loading','paymentModeAll','courseOfferedAll'])
    },
    created() {
        this.fetchPaymentModeAll()
        this.fetchCourseOfferedAll()
        this.fetchData(this.$route.params.id)
            .then(()=>{
                // console.log('console',this.item);
            })
    },
    watch: {
      item:function(val){
          this.workshop_date = val.workshop_date;
          this.birth_date = val.birth_date;
          this.gender = val.gender;
          this.first_name =  val.first_name;
          this.last_name =  val.last_name;
          this.age = val.age;
          this.contact_name = val.contact_name;
          this.course_offered = val.course_offered;
          this.setCourseOffered(val.course_offered)
          this.contact_number = val.contact_number;
          this.workshop_details = val.workshop_details;
          this.duration = val.duration;
          this.workshop_type = val.workshop_type;
          if(val.workshop_type == 1){
            this.amount = val.receipt.receipt_amount;
            this.manual_receipt_no = val.receipt.manual_receipt_no;
            if(val.receipt.receipt_payment_mode){
              let paymentMode = val.receipt.receipt_payment_mode
              this.instrument_no = paymentMode[0]['instrument_no']
              this.instrument_date = paymentMode[0]['instrument_date']
              this.bank_name = paymentMode[0]['bank_name']
              this.setPaymentMode(paymentMode[0]['payment_mode'])
            }
          }
          
      }
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('WorkshopSingle', ['fetchData','updateData', 'setPaymentMode', 'setWorkshopDetails' , 'fetchPaymentModeAll','resetState','setBirth_date','setGender','fetchCourseOfferedAll','setCourseOffered']),
        updateWorkshopType(val){
          this.workshop_type = val;
          this.setPaymentMode(null) 
        },
        getDOBDate(){
            var d = new Date();
            var pastYear = d.getFullYear() - 5;
            d.setFullYear(pastYear);
            return d;
        },        
        disabledDates() {
          from: new Date(Date.now())
        },        
        updatePaymentModeId(e) {
            this.setPaymentMode(e)    
        },
        updateCourseOffered(e) {
            this.setCourseOffered(e)    
        },              
        updateGender(value) {
            this.gender = value
        },        
        submitForm() {
            this.$validator.validate().then(result => {
             if (result) {
                 var workshopDetails = {
                      workshop_date: this.workshop_date,
                      first_name: this.first_name,
                      last_name: this.last_name,
                      birth_date: this.birth_date,
                      gender: this.gender,
                      age: this.age,
                      contact_name: this.contact_name,
                      course_offered: this.item.course_offered.id,
                      contact_number: this.contact_number,
                      workshop_details: this.workshop_details,
                      duration: this.duration,
                      workshop_type: this.workshop_type,
                 };
                 if(this.workshop_type == 1){
                    workshopDetails.instrument_no = this.instrument_no
                    workshopDetails.instrument_date = this.instrument_date
                    workshopDetails.manual_receipt_no = this.manual_receipt_no
                    workshopDetails.bank_name = this.bank_name
                    workshopDetails.amount = this.amount
                    workshopDetails.payment_mode = this.item.payment_mode.value_id
                 }
                this.setWorkshopDetails(workshopDetails);
                this.updateData()
                     .then((res) => {
                          if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                  id: 'name',
                                  field: 'name',
                                  msg: res.data.message,
                                  scope: this.$options.scope,
                                });

                                return '';    
                            }        
                        this.$router.push({ name: 'workshop.index' })
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                 
          });
        },
        
    }
}
</script>


<style scoped>

</style>
