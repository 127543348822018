<template>  
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Topics</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Course Topic</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>
 
                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="col-md-7">
                                    <div ref="course_name_id" class="form-group">
                                        <label for="course">Course Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="course_name_id"
                                                label="display_course_name"
                                                id="course"
                                                v-validate="'required'"
                                                @input="updateCourseId"
                                                :value="item.course_name_id"
                                                :options="coursesAll"
                                                />
                                        <span class="error" v-show="errors.has('course_name_id')">
                                            {{ $root.updateErrorMessage('course_name_id',this.errors, 'Course Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label for="topic_name">Topic Name<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="topic_name"
                                                ref="topic_name"
                                                v-validate="'required'"
                                                maxlength="200"
                                                placeholder="Enter Topic Name"
                                                :value="item.topic_name"
                                                @input="updateCourseTopicName"
                                                >
                                        <span class="error" v-show="errors.has('topic_name')">
                                            {{ $root.updateErrorMessage('topic_name',this.errors,'Topic Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                        <label for="sort_order">Session Number<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="sort_order"
                                                ref="sort_order"
                                                maxlength="10"
                                                v-validate="'required|numeric'"
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                placeholder="Enter Session Number"
                                                :value="item.sort_order"
                                                @input="updateQuestionNumber"
                                                >
                                        <span class="error" v-show="errors.has('sort_order')">
                                            {{ $root.updateErrorMessage('sort_order',this.errors,'Session Number')}}
                                        </span>
                                    </div>                                   
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                    <input 
                                        id="is_assessment_required" 
                                        name="is_assessment_required"
                                        @change="updateIsAssessmentRequired" 
                                        type="checkbox" 
                                        :value="assessment_required" 
                                        v-model="assessment_required">
                                    <label class="checkbox-label" for="is_assessment_required" style="font-weight: 400; cursor:pointer;">Is Assessment Required</label>
                                    
                                        <label for="sort_order"></label>
                                        
                                        
                                    </div>
                                </div>                                                               
                            </div>
                        </div>
                        <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            assessment_required:1,
            // Code...
            //errorName : ''
        }
    },
    computed: {
        ...mapGetters('CourseTopicMastersSingle', ['item', 'loading', 'coursesAll']),
       
    },
    created() {
        //this.fetchStateTypeAll()
        this.fetchCoursesAll()
    },
    destroyed() {
        // this.resetState()
        this.setCourseTopicName(null)
        this.setQuestionNumber(null)
    },
    methods: {
        ...mapActions('CourseTopicMastersSingle', ['storeData', 'resetState', 'setCourseTopicName', 'setCourseId', 'setQuestionNumber', 'fetchCoursesAll','setIsAssessmentRequired']),
        updateCourseTopicName(e) {
            this.setCourseTopicName(e.target.value)
        },
       
        updateCourseId(value) {
            this.setCourseId(value)
        },
       updateQuestionNumber(e) {
            this.setQuestionNumber(e.target.value)
       }, 
       updateIsAssessmentRequired(e){
        if(this.assessment_required)
        {
            console.log(this.assessment_required)
            this.setIsAssessmentRequired(1);
        }
        else{
            console.log(this.assessment_required)
            this.setIsAssessmentRequired(0);
        }
            
       },      
        submitForm() {
        this.$validator.validate().then(result => {
             if (result) {
                 this.storeData()
                .then((res) => {
                    if(res.data.error_message == 1)
                    {
                        this.$validator.errors.add({
                            id: 'topic_name',
                            field: 'topic_name',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }
                    else if(res.data.error_message == 2)
                    {
                        this.$validator.errors.add({
                            id: 'sort_order',
                            field: 'sort_order',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'course_topic_masters.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });

           
        }
    }
}
</script>


<style scoped>

</style>
 