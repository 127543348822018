<template>
    <div class="popup-form add-country">
    <b-modal id="addAttendanceModel"
             ref="addAttendanceModel"
             title="Add Student Attendance"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <addAttendance :id="id" @closeModel="closeModel" @loadItems="loadItems"></addAttendance>
    </b-modal>
  </div>
</template>
<script>
import addAttendance from '@/components/cruds/StudentAttendance/addAttendance'
export default {
    props: ['id'],
    components:{
        addAttendance
    },
    methods: {
        loadItems(){
            this.$emit('loadItems') 
        },
         handleCancel(evt){
            //console.log("cancel")
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        closeModel(){
            this.$refs.addAttendanceModel.hide();
        },
        modalOpened() {
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

