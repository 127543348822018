<template>
    <aside
        class="main-sidebar sidebar-dark-primary elevation-4"
        style="padding:0"
    >
        <a
            href="https://stage.robofunlab.in/home"
            class="brand-link"
        >
            <span
                class="logo-lg"
                style="padding-top: 7px;"
            >
                <img
                    src="/assets/images/logo.svg"
                    width="100%"
                    class="img-responsive logo-lg"
                ></span>
                <span
                class="logo-mini"
                style="padding-top: 7px;"
            >
                <img
                    src="/R.svg"
                    class="img-responsive logo-mini"
                ></span>
        </a>
                    <!-- <a
            href="/"
            class="brand-link"
        >
            <img
                src="/assets/images/logo.svg"
                alt="fgfgf"
                class="brand-image img-circle elevation-3"
                style="opacity: .8"
            >
                <span class="brand-text font-weight-light">RoboFun Lab</span>
                </a> -->

            <div class="sidebar">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image" >
                        <img v-if="profilePic != null"
                            :src="profilePic"
                            class="img-circle elevation-2"
                            alt="User Image"
                        >
                        <img v-else
                            src="/assets/img/male.jpg"
                            class="img-circle elevation-2"
                            alt="User Image"
                        >
                        
                    </div>
                    <div class="info">
                        <a
                            href="#"
                            class="d-block"
                        >{{stateUserName}}</a>
                    </div>
                </div>

                <!-- <div class="form-inline">
                    <div
                        class="input-group"
                        data-widget="sidebar-search"
                    >
                        <input
                            class="form-control form-control-sidebar"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        >
                            <div class="input-group-append">
                                <button class="btn btn-sidebar">
                                    <i class="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                </div>
            </div> -->

            <nav class="mt-2">
                <ul
                    class="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                >
                    <li class="nav-item nav-parent nav-div home" id="home">
                        <router-link :to="'/super-admin-home'" v-if="branch_id == 1"
                            class="nav-link" >
                            <i class="nav-icon fas fa-tachometer"></i><p>Dashboard</p>
                        </router-link>  
                        <router-link :to="'/trainer-home'" v-else-if="trainer && (trainer.employee_type == 1 || trainer.employee_type ==9)"
                            class="nav-link" >
                            <i class="nav-icon fas fa-tachometer"></i><p>Dashboard</p>
                        </router-link>   
                        <router-link :to="'/home'" v-else
                            class="nav-link" >
                            <i class="nav-icon fas fa-tachometer"></i><p>Dashboard</p>
                        </router-link>                    
                    </li>  
                    <!-- users -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'users-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('users-management')" id="users-management" v-if="$can('role_access') || $can('user_access') || $can('user_branchwise_role_access')">
                        <div  class="nav-link parent-link">
                            <i class="nav-icon fa fa-users"></i>
                            <p>
                                User Management
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div> 
                        <ul class="nav nav-treeview"  >
                            <li class="nav-item roles nav-div" id="roles" ref="roles" v-if="$can('role_access')">
                                <router-link  :to="'/roles'" class="nav-link">
                                    <i class="fa fa-user-tie nav-icon"></i>
                                    <p>Roles</p>
                                </router-link>                            
                            </li>
                            <li class="nav-item users nav-div" id="users" v-if="$can('user_access')">
                                <router-link  :to="'/users'" class="nav-link">
                                    <i class="fa fa-user nav-icon"></i>
                                    <p>Users</p>
                                </router-link>
                            </li>
                            <li class="nav-item multiple-branch-access nav-div" v-if="$can('user_branchwise_role_access')" id="multiple-branch-access">
                                <router-link  :to="'/multiple-branch-access'" class="nav-link">
                                    <i class="fa fa-user nav-icon"></i>
                                    <p>User Branchwise Role</p>
                                </router-link>
                            </li>
                        </ul>
                    </li> 
                    <!-- branches -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'branch-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('branch-management')" id="branch-management" v-if="$can('franchise_access') || $can('branch_access') || $can('branch_lab_access') || $can('branch_admission_fee_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-cube"></i>
                            <p>Branches<i class="right fas fa-angle-left"></i>
                            </p>
                        </div>    
                        <ul
                            class="nav nav-treeview" 
                            >
                            <li class="nav-item" id="franchises" v-if="$can('franchise_access')">
                                <router-link :to="'/franchises'"
                                    class="nav-link" >
                                    <i class="fa fa-flag nav-icon"></i>
                                    <p>Franchises</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="branchs" v-if="$can('branch_access')">
                                <router-link :to="'/branchs'"
                                    class="nav-link" >
                                    <i class="fa fa-university nav-icon"></i>
                                    <p>Branches</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="branch-admission-fee" v-if="$can('branch_admission_fee_access')">
                                <router-link :to="'/branch-admission-fee'"
                                    class="nav-link" >
                                    <i class="fa fa-rupee-sign nav-icon"></i>
                                    <p>Branch Admission Fee</p>
                                    </router-link>
                            </li>
                            <li class="nav-item" id="branch-lab" v-if="$can('branch_lab_access')">
                                <router-link :to="'/branch-lab'"
                                    class="nav-link" >
                                    <i class="fa fa-building nav-icon"></i>
                                    <p>Branch Lab</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                     <!-- branches -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'course-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('course-management')" id="course-management" v-if="$can('project_access') || $can('project_fees_access') || $can('course_access') || $can('module_access') || $can('course_fee_adjustment_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-book"></i>
                            <p>Course Management<i class="right fas fa-angle-left"></i>
                            </p>
                        </div>    
                        <ul
                            class="nav nav-treeview" 
                            >
                            <li class="nav-item" id="projects" v-if="$can('project_access')">
                                <router-link :to="'/projects'"
                                    class="nav-link" >
                                    <i class="fas fa-project-diagram nav-icon"></i>
                                    <p>Project</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="modules" v-if="$can('module_access')">
                                <router-link :to="'/modules'"
                                    class="nav-link" >
                                    <i class="fa fa-tasks nav-icon"></i>
                                    <p>Module</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="modules" v-if="$can('course_access')">
                                <router-link :to="'/courses'"
                                    class="nav-link" >
                                    <i class="fa fa-book nav-icon"></i>
                                    <p>Courses</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="project-fees" v-if="$can('project_fees_access')">
                                <router-link :to="'/project-fees'"
                                    class="nav-link" >
                                    <i class="fa fa-inr nav-icon"></i>
                                    <p>Project Fees</p>
                                </router-link>
                            </li> 
                            <li class="nav-item" id="course-fee-adjustments" v-if="$can('course_fee_adjustment_access')">
                                <router-link :to="'/course-fee-adjustments'"
                                    class="nav-link" >
                                    <i class="fa fa-inr nav-icon"></i>
                                    <p>Course Fee Adjustments</p>
                                </router-link>
                            </li> 
                        </ul>
                    </li>
                    <!-- Employees -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'employees-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('employees-management')"  id="employees-management" v-if="$can('employee_master_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-user-circle"></i>
                            <p>
                                Employees
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul
                            class="nav nav-treeview" 
                            >
                            <li class="nav-item" id="employee-masters" v-if="$can('employee_master_access')">
                                <router-link :to="'/employee-masters'" class="nav-link">
                                    <i class="fas fa-briefcase nav-icon"></i>
                                    <p>Employees</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                     <li class="nav-item nav-parent" :class="(activeClass == 'batches-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('batches-management')"  id="batches-management" v-if="$can('batch_master_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-database"></i>
                            <p>
                                Batches
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul
                            class="nav nav-treeview" 
                            >
                            <li class="nav-item" id="batch-masters" v-if="$can('batch_master_access')">
                                <router-link :to="'/batch-masters'" class="nav-link">
                                    <i class="fas fa fa-building nav-icon"></i>
                                    <p>Batches</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- Adminssion -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'admission-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('admission-management')" id="admission-management" v-if="$can('inquiry_access') || $can('student_prospect_access') || $can('student_access') || $can('school_master_access') ||  $can('parent_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-child"></i>
                            <p> Admission Management
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" id="inquiries" v-if="$can('inquiry_access')">
                                <router-link :to="'/inquiries'" class="nav-link" >
                                    <i class="fas fa-info-circle nav-icon"></i>
                                    <p>Inquiries</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="student" v-if="$can('student_access') && branch_id != 1">
                                <router-link :to="'/student'" class="nav-link" >
                                    <i class="fas fa-user nav-icon"></i>
                                    <p>Student</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="student-prospect" v-if="$can('student_prospect_access') && branch_id != 1">
                                <router-link :to="'/student-prospect'" class="nav-link" >
                                    <i class="fas fa-user-plus nav-icon"></i>
                                    <p>Student Prospect</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="parents" v-if="$can('parent_access')">
                                <router-link :to="'/parents'" class="nav-link" >
                                    <i class="fas fa-user-secret nav-icon"></i>
                                    <p>Parents</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="school">
                                <router-link :to="'/school-masters'" v-if="$can('school_master_access')" class="nav-link" >
                                    <i class="fa fa-hospital-o nav-icon"></i>
                                    <p>Schools</p>
                                </router-link>
                            </li>
                        </ul>
                    </li> 
                     <li class="nav-item nav-parent" :class="(activeClass == 'training-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('training-management')"  id="training-management" v-if="$can('student_attendance_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-calendar-times-o"></i>
                            <p>
                                Training Management
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul
                            class="nav nav-treeview">
                            <li class="nav-item" id="student-attendance" v-if="$can('student_attendance_access')">
                                <router-link :to="'/student-attendance'" class="nav-link">
                                    <i class="fas fa fa-list-ul nav-icon"></i>
                                    <p>Student Attendance</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- coupons -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'coupons-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('coupons-management')" id="coupons-management" v-if="$can('coupons_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-gifts"></i>
                            <p> Coupons Management
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" id="coupons" v-if="$can('coupons_access')">
                                <router-link :to="'/coupons'" class="nav-link" >
                                    <i class="fas fa-gift nav-icon"></i>
                                    <p>Manage Coupons</p>
                                </router-link>
                            </li>
                        </ul>
                    </li> 
                    <!-- coupons -->
                    <li class="nav-item nav-parent" :class="(activeClass == 'gst-management') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('gst-management')" id="gst-management" v-if="$can('gst_access')">
                        <div class="nav-link parent-link">
                            <i class="fas fa-wallet nav-icon"></i>
                            <p> GST Management
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" id="gst" v-if="$can('gst_access')">
                                <router-link :to="'/gst'" class="nav-link" >
                                    <i class="fas fa-coins nav-icon"></i>
                                    <p>Manage GST</p>
                                </router-link>
                            </li>
                        </ul>
                    </li> 
                    <li class="nav-item nav-parent" :class="(activeClass == 'settings') ? 'menu-is-opening menu-open' : ''" @click="openOnClick('settings')" id="settings" v-if="$can('common_master_access') || $can('state_view') || $can('holiday_access') || $can('skill_master_access') || $can('admin_slider_access') || $can('certificate_access') || $can('email_template_access')">
                        <div class="nav-link parent-link">
                            <i class="nav-icon fa fa-cogs"></i>
                            <p>
                                Settings
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </div>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" id="change-password">
                                <router-link :to="'/change-password'" class="nav-link" >
                                    <i class="fa fa-key nav-icon"></i>
                                    <p>Change Password</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="common-masters" v-if="$can('common_master_access')">
                                <router-link
                                    class="nav-link"
                                    to="/common-masters">
                                    <i class="fa fa-cog nav-icon"></i>
                                    <p>Common Master</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="holidays" v-if="$can('holiday_access')">
                                <router-link
                                    class="nav-link"
                                    to="/holidays">
                                    <i class="fa fa-calendar-plus nav-icon"></i>
                                    <p>Holidays</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="states" v-if="$can('state_access')">
                                <router-link :to="'/states'" class="nav-link" >
                                    <i class="fa fa-globe nav-icon"></i>
                                    <p>States</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="skill" v-if="$can('skill_master_access')">
                                <router-link :to="'/skill-master'" class="nav-link" >
                                    <i class="fa fa-book-reader nav-icon"></i>
                                    <p>Skill Master</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="admin_slider" v-if="$can('admin_slider_access')">
                                <router-link :to="'/admin-slider'" class="nav-link">
                                    <i class="fa fa-sliders nav-icon"></i>
                                    <p>Admin Slider</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="common-inquiries" v-if="$can('common_inquiry_access')">
                                <router-link :to="'/common-inquiries'" class="nav-link">
                                    <i class="fas fa-info-circle nav-icon"></i>
                                    <p>Common Inquiries</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="certificate">
                                <router-link :to="'/certificate'" class="nav-link" v-if="$can('certificate_access')">
                                    <i class="fas fa-file nav-icon"></i>
                                    <p>Certificate</p>
                                </router-link>
                            </li>
                            <li class="nav-item" id="email-template" v-if="$can('email_template_access')">
                                <router-link :to="'/email-template'" class="nav-link">
                                    <i class="fa fa-envelope nav-icon"></i>
                                    <p>Email Tamplate</p>
                                </router-link>
                            </li>
                        </ul>
                    </li> 
                </ul>
            </nav>
        </div>
    </aside>
</template>
<script>
import NavItem from "@/views/layout/NavItem";
import store from '../../store'
export default {
  components: {
    NavItem
  },
  data(){
    return{
        currenturl:'',
        stateUserName:'',
        profilePic:'',
        activeClass:'',
        subActiveClass:'',
        showMobileMenu: false,
        apiSessionBranchId:'',
        branch_id: '',
        trainer:'',
    } 
  },
  created(){
    this.stateUserName = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.name : 'admin';
    this.profilePic = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.profile : 'admin';
    this.apiSessionBranchId = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : ''     
    this.branch_id = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0;
    this.profilePic = (store.state.AuthIndex.user) ? store.state.AuthIndex.user.profile : 'admin';    
    this.trainer = (store.state.AuthIndex.fetchIfTrainer) ? store.state.AuthIndex.fetchIfTrainer : '';  
  }, 
  watch: {
        branch_id: function() { 
           return (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0;
        },
    }, 
  mounted: function() {
    $('[data-widget="treeview"]').Treeview('init');
    this.sidebarChange();
  },  
  methods: {
    openOnClick(divId){    
         this.activeClass = divId;        
        if(!$('#'+divId).hasClass('menu-is-opening')){
            $('.nav-treeview').css('display', 'none');
            $('.nav-parent').removeClass('menu-is-opening menu-open');
            $('.nav-treeview').addClass('hide');
        }
        this.sidebarChange();
    }, 
    sidebarChange(){
        let divparent = $('.router-link-exact-active.router-link-active').parents('.nav-parent'); 
        let divid = divparent.attr('id');
        if(!$('#'+divid).hasClass('menu-is-opening')){
            $('.nav-treeview').css('display', 'none');
            $('.nav-parent').removeClass('menu-is-opening menu-open');
            $('.nav-treeview').addClass('hide');
        }
        $('#'+divid).addClass('menu-is-opening menu-open');   
        $('#'+divid).find('.nav-treeview').removeClass('hide');        
    },
    isActive() {
      if (typeof this.link === "object") {
        return this.$route.name === this.link.name;
      } else {
        return this.$route.path === this.link;
      }
    }
  }
};
</script>
<style scoped>
.active{
    background-color: blanchedalmond;
}

.nav-link.router-link-active{
    background-color: rgba(255,255,255,.9) !important;
    color: #343a40 !important;
}

.router-link-exact-active.router-link-active{
    background-color: rgba(255,255,255,.9) !important;
    color: #343a40 !important;
}
/* .menu-is-opening.menu-open .parent-link{
    background-color: #007bff !important;
    color: #fff !important;
} */
.nav-parent{
    cursor: pointer;
}
.sidebar div.parent-link {
    color: #c2c7d0;
}
.nav-parent{
    cursor: pointer;
}
div.parent-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
</style>
