<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Allocation</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Allocate Leaves</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body"> 
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <div class="radio-inline">
                                        <label class="contain">
                                        <input
                                            type="radio"
                                            name="gender"
                                            ref="gender"
                                            v-validate="'required|included:1,2'"
                                            value="1"
                                            :checked="totalEmp === '1'"
                                            @change="updateGender('1')"
                                            >
                                        <span class="checkmark"></span>
                                        All Employee
                                        </label>
                                    </div>
                                    <div class="radio-inline" style="margin-left:15px;">
                                        <label class="contain">
                                        <input
                                            type="radio"
                                            name="gender"
                                            ref="gender"
                                            value="2"
                                            :checked="totalEmp === '2'"
                                            @change="updateGender('2')"
                                            >
                                        <span class="checkmark"></span>
                                        Individual Employee
                                        </label>
                                    </div>
                                    <br>
                                    <span class="error" v-show="errors.has('gender')">
                                    {{ $root.updateErrorMessage('gender',this.errors)}}
                                    </span>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div v-if="showEmployeeDropdown" class="col-md-7 col-xs-12">                          
                                <div ref="employee" class="form-group">
                                    <label for="employee">Employee<p class="astrisk">*</p></label>
                                    <v-select
                                            name="employee"
                                            label="employee_name"
                                            id="employee"
                                            v-validate="'required'"
                                            @input="updateEmployee"
                                            :value="employeeVal"
                                            :options="employeeAll"
                                            />
                                    <span class="error" v-show="errors.has('employee')">
                                      {{ $root.updateErrorMessage('employee',this.errors,'Employee')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-7 col-xs-12">                          
                                <div ref="leave_type" class="form-group">
                                    <label for="leave_type">Leave Type<p class="astrisk">*</p></label>
                                    <v-select
                                            name="leave_type"
                                            label="name"
                                            id="leave_type1"
                                            v-validate="'required'"
                                            @input="updateLeave_type"
                                            :value="item.leave_type"
                                            :options="leaveTypeAll"
                                            />
                                    <span class="error" v-show="errors.has('leave_type')">
                                      {{ $root.updateErrorMessage('leave_type',this.errors,'Leave Type')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-7 col-xs-12">
                                <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="leave_start_date" class="form-group">
                                        <label for="leave_start_date">Leave From Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.leave_start_date"
                                                :config="startDateOptionFormate"
                                                name="leave_start_date"
                                                v-validate="'required'"
                                                id="leave_start_date"
                                                placeholder="Select Leave From Date"
                                                @dp-change="updateLeave_start_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_start_date')">
                                          {{ $root.updateErrorMessage('leave_start_date',this.errors,'Leave From Date')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="leave_end_date" class="form-group">
                                        <label for="leave_end_date">Leave Upto Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.leave_end_date"
                                                :config="endDateOptionFormate"
                                                id="leave_end_date"
                                                v-validate="'required'"
                                                name="leave_end_date"
                                                placeholder="Select Leave Upto Date"
                                                @dp-change="updateLeave_end_date"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                        <span class="error" v-show="errors.has('leave_end_date')">
                                          {{ $root.updateErrorMessage('leave_end_date',this.errors,'Leave Upto Date')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                             <div class="col-md-7 col-xs-12">
                                <div class="form-group">
                                    <label for="days">Number of Leaves<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="days"
                                            ref="days"
                                            v-validate="validationOption"
                                            oninput="this.value=this.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1').replace(/(\.[\d]{2})./g, '$1');"
                                            id="daysData"
                                            maxlength="3"
                                            placeholder="Enter Number of Leaves"
                                            @input="updateDays"
                                            >
                                <span class="error" v-show="errors.has('days')">
                                  {{ $root.updateErrorMessage('days',this.errors,'Number of Leaves')}}
                                  <!-- {{ $root.updateErrorMessage('days',this.errors,'Number of Leaves','a valid value')}} -->
                                </span>                                    
                                </div>
                            </div>                          
                            </div>                        
                        </div>
                         <div class="col-md-7 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>      
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
     
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            difference: 0,
            totalEmp: '1',
            showEmployeeDropdown: false,
            employeeVal: null,
            endDateOptionFormate:{format:window.date_format_moment},
            startDateOptionFormate:{format:window.date_format_moment, minDate:false},
            validationOption : 'required|min_value:0.5',
        }
    },
    computed: {
        ...mapGetters('LeaveAllocationsSingle', ['item', 'loading', 'leaveTypeAll','employeeAll'])
    },
    created() {
        this.fetchLeaveTypeAll()
        this.fetchEmployeeAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('LeaveAllocationsSingle', ['storeData', 'storeDataIndividual','resetState', 'setLeave_type', 'setLeave_start_date', 'setLeave_end_date', 'setDays', 'fetchLeaveTypeAll','fetchEmployeeAll','setSelectedEmployee']),
        updateGender(value) {
            if(value == 2) {
                this.showEmployeeDropdown = true;
            } else {
                this.showEmployeeDropdown = false;
                this.employeeVal = null
                this.$validator.errors.remove({
                      id: 'employee',
                      field: 'employee'
                });                
            }
            this.totalEmp = value
        },
        updateEmployee(value) {
            this.employeeVal = value
            this.setSelectedEmployee(value)
        },
        getDiffDays(){
            var startDate = $('#leave_start_date').val();
            var endDate = $('#leave_end_date').val();

            if(startDate != '' && endDate !=''){
                var start = moment(startDate, "DD/MM/YYYY");
                var end = moment(endDate, "DD/MM/YYYY");
                this.difference = moment.duration(end.diff(start)).asDays();
                this.difference++;
            }
        },
        updateLeave_type(value) {
             this.setLeave_type(value);
        },
        updateLeave_start_date(e) {
            if(e && e.target.value != ''){
                this.endDateOptionFormate = {
                        format:window.date_format_moment,
                        minDate: e.target.value,
                        maxDate : false                    
                   };  
                   this.setLeave_end_date(null)
            }
            this.setLeave_start_date(e.target.value)
        },
        updateLeave_end_date(e) {
            this.getDiffDays()
            this.validationOption = 'required|max_value:'+this.difference;
            this.setLeave_end_date(e.target.value)
        },
        updateDays(e) {
            // if(e.target.value > this.difference){
            //     this.validationOption = 'required|max_value:'+this.difference;
            //  }
            this.setDays(e.target.value)
        },
        submitForm() {
        this.$validator.validate().then(result => {
            if (result) {
                if(this.employeeVal == null) {
                    this.storeData()
                        .then((res) => {
                            if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                      id: 'days',
                                      field: 'days',
                                      msg: res.data.message,
                                      scope: this.$options.scope,
                                });
                                return '';
                            }
                            this.$router.push({ name: 'leave_allocations.create' })
                            this.$eventHub.$emit('create-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                } else {
                    this.storeDataIndividual()
                        .then((res) => {
                            if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                      id: 'days',
                                      field: 'days',
                                      msg: res.data.message,
                                      scope: this.$options.scope,
                                });
                                return '';
                            }
                            this.$router.push({ name: 'leave_allocations.create' })
                            this.$eventHub.$emit('create-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        })                    
                }
            }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }            
          });
        }
    }
}
</script>


<style scoped>

</style>
