<template> 
   <form @submit.prevent="submitForm" novalidate>
            <div class="row">
                <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">Course Details</h3>
                </div>

                <div class="form-group col-md-12 col-xs-12">
                    <label for="description">Description</label>
                    <textarea
                        rows="2"
                        class="form-control"
                        name="description"
                        ref="description"
            
                        placeholder="Enter Description"
                        :value="description"
                        @input="updateCourseDescription"
                        />
                    <span class="error" v-show="errors.has('description')">
                        {{ $root.updateErrorMessage('description',this.errors,'Description')}}
                    </span>
                </div>
                <div class="form-group col-md-12 col-xs-12">
                    <label for="course_image">Course Image</label>
                    <input
                            type="file"
                            name="course_image"
                            id="course_image"
                            v-validate="'size:2048|ext:jpg,png,bmp,gif,jpeg'"
                            class="form-control"
                            @change="updateProfile"
                        >
                        <span class="error" v-show="errors.has('course_image')">
                            {{ $root.updateErrorMessage('course_image',this.errors,'Course Image')}}
                        </span>
                            <ul v-if="courseImage" class="list-unstyled">
                                <li>
                                <div class="" style="">
                                    <div v-if="courseImage != null">
                                    
                                        <img :src="courseImage" id="blah" class="student_image profile-user-img my-profile img-responsive" :alt="courseImage" > 
                                        
                                    </div>
                                    <div v-else>
                                        <img src="/assets/img/male.jpg" class="img-circle my-profile" :alt="courseImage" > 
                                    </div>
                                </div>
                                    <button class="btn btn-xs btn-danger"
                                            type="button"
                                            @click="removeProfile"
                                    >
                                        Remove file
                                    </button>
                                </li>
                            </ul>
                </div>  
                <div class="form-group col-md-12 col-xs-12">
                    <label for="course_skills">Course Skills<p class="astrisk">*</p></label>
                    <select v-validate="'required'" v-model="course_skills" name="course_skills" multiple label="course" class="form-control select2 courseSkill" placeholder="Select Course Skill" @change="updateCourseSkill">
                
                    </select>
                        <!-- <v-select
                        name="course_skills"
                        label="name"
                        value="id"
                        placeholder="Select Course Vertical"
                        v-model="course_skills"
                        taggable push-tags multiple
                        tag-separator=", "
                        /> -->
                    <span class="error" v-show="errors.has('course_skills')">
                        {{ $root.updateErrorMessage('course_skills',this.errors,'Course Skill')}}
                    </span>                                            
                </div>  
                <div class="form-group col-md-6 col-xs-12">
                    <label for="years">Preferred Year<p class="astrisk">*</p></label>
                    <v-select
                        name="years"
                        value="id"
                        v-validate="'required'"
                        placeholder="Select Preferred Year"
                        :options="yearsAll"
                        v-model="selected_year"
                        />
                    <span class="error" v-show="errors.has('years')">
                        {{ $root.updateErrorMessage('years',this.errors,'Preferred year')}}
                    </span>
                </div>

                <div class="form-group col-md-6 col-xs-12">
                    <label for="standard">Standard<p class="astrisk">*</p></label>
                    <v-select
                        name="standard"
                        value="id"
                        label="name"
                        v-validate="'required'"
                        placeholder="Select Standard"
                        :options="standardAll"
                        v-model="selected_standard"
                        />
                    <span class="error" v-show="errors.has('standard')">
                        {{ $root.updateErrorMessage('standard',this.errors,'Standard')}}
                    </span>
                </div>
                <div class="col-md-6 col-xs-12" id="">
                    <label for="is_popular">&nbsp;</label>
                    <div class="form-group ds-flex">
                    <div class="custom-control custom-checkbox mr-2">
                        <input class="custom-control-input" type="checkbox" id="is_popular" name="is_popular" @change="updatedCoursePopular">
                        <label for="is_popular" class="custom-control-label">Is Course Popular? </label>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-xs-12" id="">
                    <label for="is_publish">&nbsp;</label>
                    <div class="form-group ds-flex">
                    <div class="custom-control custom-checkbox mr-2">
                        <input class="custom-control-input" type="checkbox" id="is_publish" name="is_publish" @change="updatedCoursePublish">
                        <label for="is_publish" class="custom-control-label">Is Course Publish? </label>
                    </div>
                    </div>
                </div>
                <div class="form-group col-md-12 col-xs-12">
                    <label for="description">Extra Details</label>

                        <div class="row" v-if="extra_details.length > 0">
                        <span class="col-md-5 col-xs-12"><b>Label</b></span>
                        <span class="col-md-6 col-xs-12"><b>Value</b></span>
                        <span class="col-md-1 col-xs-12"></span>
                    </div>
                    <div class="row" v-for="(extra_detail,index) in extra_details" :key="extra_detail.rand_key">
                        <div class="form-group col-md-5 col-xs-12">
                        <input class="form-control"  v-validate="'required'" :name="'extra_label_'+extra_detail.rand_key" placeholder="Label" v-model="extra_detail.label">
                            <span class="error" v-show="errors.has('extra_label_'+extra_detail.rand_key)">
                                        {{ $root.updateErrorMessage('extra_label_'+extra_detail.rand_key,errors,'Label')}}
                                    </span>
                        </div>
                        
                        <div class="form-group col-md-6 col-xs-12">
                        <input class="form-control"  v-validate="'required'" :name="'extra_value_'+extra_detail.rand_key" placeholder="Value" v-model="extra_detail.value">
                            <span class="error" v-show="errors.has('extra_value_'+extra_detail.rand_key)">
                                        {{ $root.updateErrorMessage('extra_value_'+extra_detail.rand_key,errors,'Value')}}
                                    </span>
                        </div>
                       
                        <div class="form-group col-md-1 col-xs-12">
                        <button type="button" title="Remove Detail" @click="removeExtraDetail(extra_detail.rand_key)" class="btn btn-danger"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                    <span class="error" style="display:block;" v-if="this.extra_detail_error != ''">
                    {{ 
                    extra_detail_error
                    }}
                    </span>
                    <br>
                    <button type="button" @click="AddExtraField" class="btn btn-info">
                        + Add Extra Detail
                    </button>
                </div>
            </div>
            
            <div class="row">
                <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">Add More Details (Website)</h3>
                </div>
                <div class="form-group col-md-12 col-xs-12">
                    <label for="more_detail_title">Title</label>
                    <input
                            type="text"
                            class="form-control"
                            name="more_detail_title"
                            ref="more_detail_title"
                            placeholder="Enter Course Name"
                            @input="updateMoreDetailTitle"
                            :value="moreDetailTitle"
                            >
                    <span class="error" v-show="errors.has('more_detail_title')">
                        {{ $root.updateErrorMessage('more_detail_title',this.errors,'Title')}}
                    </span>
                </div>  

                <div class="form-group col-md-12 col-xs-12">
                    <label for="description">Description</label>
                    <textarea
                        rows="2"
                        class="form-control"
                        name="description"
                        ref="description"
                        placeholder="Enter Description"
                        :value="moreDetailDescription"
                        @input="updateMoreDetailDescription"
                        />
                    <span class="error" v-show="errors.has('description')">
                        {{ $root.updateErrorMessage('description',this.errors,'Description')}}
                    </span>
                </div>

                <div class="form-group col-md-12 col-xs-12">
                    <label for="description">More Detail Images</label>

                        <div class="row" v-if="more_details.length > 0">
                        <span class="col-md-4 col-xs-12"><b>Title</b></span>
                        <span class="col-md-5 col-xs-12"><b>Image</b></span>
                        <span class="col-md-2 col-xs-12"><b>Preview Image</b></span>
                        <span class="col-md-1 col-xs-12"></span>
                    </div>
                    <div class="row" v-for="(more_detail,index) in more_details" :key="more_detail.rand_key">
                        <div class="form-group col-md-4 col-xs-12">
                        <input class="form-control"  v-validate="'required'" :name="'more_title_'+more_detail.rand_key" maxlength="200" placeholder="Title" v-model="more_detail.title">
                            <span class="error" v-show="errors.has('more_title_'+more_detail.rand_key)">
                                        {{ $root.updateErrorMessage('more_title_'+more_detail.rand_key,errors,'Title')}}
                                    </span>
                        </div>
                        <div class="form-group col-md-5 col-xs-12" :key="'file_upload_'+more_detail.rand_key">
                        <input type="file" v-validate="{required:(more_detail.image_url!='')?false:true,size:2048,ext:['jpg','png','bmp','gif','jpeg']}" 
                                    class="form-control"
                                    @change="updateImage($event,more_detail.rand_key)" :name="'more_file_'+more_detail.rand_key" placeholder="Select Image">
                        <span class="error" v-show="errors.has('more_file_'+more_detail.rand_key)"> 
                            {{ $root.updateErrorMessage('more_file_'+more_detail.rand_key,errors,'Image')}}
                        </span>
                        </div>
                        <div class="form-group col-md-2 col-xs-12">
                            <img :src="more_detail.image_url" v-if="more_detail.image_url != ''" :id="'blah_'+more_detail.rand_key" class="student_image profile-user-img my-profile img-responsive" :alt="courseImage" > 
                        </div>
                        <div class="form-group col-md-1 col-xs-12">
                        <button type="button" title="Remove Detail" @click="removeFee(more_detail.rand_key)" class="btn btn-danger"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                    <span class="error" style="display:block;" v-if="this.more_detail_error != ''">
                    {{ 
                    more_detail_error
                    }}
                    </span>
                    <br>
                    <button type="button" @click="AddField" class="btn btn-info">
                        + Add More Detail
                    </button>
                </div>
                
            </div>
            <div class="row">
                <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">Report Card</h3>
                </div>
                
                    <div class="form-group col-md-6 col-xs-12">
                        <label for="course_report_content">Description<p class="astrisk">*</p></label>
                        <textarea
                            rows="2"
                            class="form-control"
                            name="course_report_content"
                            ref="course_report_content"
                
                            v-validate="reportIsActive && reportIsActive == 1 ? 'required' : 'required'"
                            placeholder="Enter Description"
                            :value="course_report_content"
                            @input="updateCourseReportContent"
                        />
                        <span class="error" v-show="errors.has('course_report_content')" v-if="reportIsActive == 1">
                            {{ $root.updateErrorMessage('course_report_content',this.errors,'course report content')}}
                        </span>
                        <span class="error" v-show="errors.has('course_report_content')" v-else>
                            {{ $root.updateErrorMessage('course_report_content',this.errors,'course report content')}}
                        </span>
                    </div>
                    <div class="col-md-6 col-xs-12" id="">
                        <label for="course_report_content">&nbsp;</label>
                        <div class="form-group ds-flex">
                            <div class="custom-control custom-checkbox mr-2">
                                <input class="custom-control-input" type="checkbox" id="is_allowed_to_generate_report" name="is_allowed_to_generate_report" @change="updatedReportIsActive">
                                <label for="is_allowed_to_generate_report" class="custom-control-label">Is Active for report </label>
                            </div>
                        </div>
                    </div>
               
            </div>
            <div class="row">
                <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">Certificate Details</h3>
                </div>
                    <div class="form-group col-md-12 col-xs-12">
                        <label for="course_report_content">Certificate Name<p class="astrisk">*</p></label>
                        
                        <v-select
                            name="certificate"
                            label="name"
                            value="id"
                            v-validate="'required'"
                            placeholder="Select Certificate"
                            :options="certificateAll"
                            v-model="certificate"
                            />
                        <span class="error" v-show="errors.has('certificate')">
                            {{ $root.updateErrorMessage('certificate',this.errors,'Certificate')}}
                        </span>   
                       
                    </div>
                    
               
            </div>
            <div class="row">
                <div class="card-header col-md-12 mb-10">
                    <h3 class="card-title">SEO Details (Website)</h3>
                </div>

                 <div class="form-group col-md-12 col-xs-12">
                    <label for="seo_title">Title</label>
                    <input
                            type="text"
                            class="form-control"
                            name="seo_title"
                            ref="seo_title"
                            placeholder="Enter SEO Title"
                            v-model="seo_title"
                            >
                    <span class="error" v-show="errors.has('seo_title')">
                        {{ $root.updateErrorMessage('seo_title',this.errors,'SEO Title')}}
                    </span>
                </div>  

                <div class="form-group col-md-12 col-xs-12">
                    <label for="seo_keywords">SEO Keywords</label>
                    <select  v-model="seo_keywords" name="seo_keywords" multiple label="Keywords" class="form-control select2 seo_keywords" placeholder="Select SEO keywords" @change="updateSEOKeywords">
                    </select>
                    <span class="error" v-show="errors.has('seo_keywords')">
                        {{ $root.updateErrorMessage('seo_keywords',this.errors,'SEO Keywords')}}
                    </span>                                            
                </div>  

                <div class="form-group col-md-12 col-xs-12">
                    <label for="seo_description">SEO Description</label>
                    <textarea
                        rows="2"
                        class="form-control"
                        name="seo_description"
                        ref="seo_description"
                        placeholder="Enter Description"
                        v-model="seo_description"
                        />
                    <span class="error" v-show="errors.has('seo_description')">
                        {{ $root.updateErrorMessage('seo_description',this.errors,'SEO Description')}}
                    </span>
                </div>

                <div class="col-md-12 col-md-12 savebtn">
                    <vue-button-spinner
                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                            :isLoading="loading"
                            :disabled="loading"
                            >
                            Save
                    </vue-button-spinner>
                </div>
            </div>               

        </form>
</template>



<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            allSelectError:'',
            localLists:[],
            course_id:'',
            course_vertical:'',
            course_category:'',
            listOption: 1,
            course_fee_type:216,
            courseName: null,
            courseHours:0,
            courseMonths:0,
            courseCMonths:0,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            ReferralUserPrice:null,
            ReferredUserPrice:null,
            endDate:null,
             description:'',
            more_details: [],
             extra_details:[],
             extra_detail_error:[],        
            more_detail_error:'',
            course_skills:[],
            moreDetailDescription:'',
            moreDetailTitle:'',
            isActive:0,
            selected_year:'',
            selected_standard:'',
            seo_title:'',
            seo_keywords:[],
            seo_description:'',
            isPublish:0,
            yearsAll:[6,7,8,9,10,11,12,13,14,15,16,17,18],
            isPopular:0,
            certificate:'',
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ],
            course_report_content:'',
            reportIsActive:0    
        }
    },
    created() {
        this.fetchTypes();
        this.fetchStandardAll();
        // this.fetchProjectAll()
        // this.fetchModuleAll()
        this.fetchCertificates();
        this.fetchEditData(this.$route.params.id);
            // .then((res) => {
            //     //console.log("res",res);
            //     var localStaffAll = this.projectAll;
            //     setTimeout(function() {
            //             for (var i = 0; i < localStaffAll.length; i++) {
            //                 $('#lstBox1')
            //                     .append($("<option></option>")
            //                         .attr("class","project")
            //                         .attr("value",localStaffAll[i].id)
            //                         .text(localStaffAll[i].employee_name));
            //             }
            //     },200)  
            // })
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('CoursesIndex', ['certificateAll','courseImage','courseMonthFormula','all','loading','editDataAll','moduleAll','projectAll','courseVertical','courseCategory','courseFeeType']),
        ...mapGetters('AuthIndex', ['user']),
        ...mapGetters('StudentSingle', ['standardAll']),
    },
    mounted(){
        let _this = this;
        $(".courseSkill").select2({placeholder: "Select Course Skill",tags: true,tokenSeparators: [',']}).on('change', function () {
            let array1 = $(this).select2("val");
            var filtered = array1.filter(function (el) {
                return el != '';
            });
           _this.updateCourseSkill(filtered)
        });
        $(".seo_keywords").select2({placeholder: "Select SEO Keywords",tags: true,tokenSeparators: [',']}).on('change', function () {
           // console.log($(this).select2("val"))
            let array1 = $(this).select2("val");
            var filtered = array1.filter(function (el) {
                return el != '';
            });
            _this.updateSEOKeywords(filtered)
        });
        //$(".courseSkill").val(['test','test1']).trigger("change");
    },
    watch:{
        // courseMonthFormula(value){
        //     if(this.courseMonthFormula[0].name){
        //         console.log("fddf",this.courseHours)
        //         let fomula = this.courseMonthFormula[0].name.replace('H',this.courseHours);
        //         //console.log(fomula);
        //         this.courseMonths = Math.ceil(value/7.5);
        //     }
        //     else{
        //         this.courseMonths = 0;
        //     }
        // },
        courseHours(value){
            //console.log("sds",value);
            //console.log("dfdf",this.courseMonthFormula);
            let _this = this; 
            setTimeout(function(){
                //console.log("jhdfdf",_this.courseMonths);
                if(_this.courseMonthFormula.length != 0){
                    let fomula = _this.courseMonthFormula[0].name.replace('H',value);
                    //console.log("fdgdg",fomula);
                    _this.courseMonths = Math.ceil(eval(value/7.5));
                }
                else{
                    _this.courseMonths = 0;
                }
            },300);
           
           
        },
        courseMonthFormula(value){
            //console.log("dfdf",value);
            let _this = this; 
            setTimeout(function(){
                //console.log("jhdfdf",_this.courseMonths);
                if(value.length != 0){
                    let fomula = value[0].name.replace('H',_this.courseHours);
                    console.log("test",fomula);
                    _this.courseMonths = Math.ceil(eval(_this.courseHours/7.5));
                }
                else{
                    _this.courseMonths = 0;
                }
            },300);
        },
        projectAll(value){
            for (var i = 0; i < value.length; i++) {
                //console.log("loop",value[i]);
                $('#lstBox1')
                    .append($("<option data-hours='"+value[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",value[i].id)
                        .text(value[i].project_name));
            }
        },
        editDataAll(value){
            this.description = value.course_description;
            if(value.course_profile != null){
                this.setCourseImage(value.course_profile);
            }

            this.selected_standard = value.standard;
            this.certificate = value.certificate;
            this.selected_year = value.years;
            this.moreDetailTitle = value.course_additional_title;
            this.moreDetailDescription = value.course_additional_description;
            this.course_report_content = value.course_report_content;
        
            if(value.course_skills != null){
                //this.course_skills = ['test','test1'];
               // $(".courseSkill").val('test','test1').trigger("change");
               let skills = value.course_skills.split(',');
                this.course_skills = skills;
               skills.forEach((skill) => {
                    //console.log(skill);
                     $(".courseSkill").append('<option value="'+skill+'">'+skill+'</option>');
                });
                 $(".courseSkill").val(skills);
                $(".courseSkill").trigger('change');
                
            }

            if(value.is_popular != null){
                if(value.is_popular == 1){
                    $("#is_popular").prop("checked", true);
                    this.isPopular = 1;
                }
                else{
                    this.isPopular = 0;
                }
            }

            if(value.is_published != null){
                if(value.is_published == 1){
                    $("#is_publish").prop("checked", true);
                    this.isPublish = 1;
                }
                else{
                    this.isPublish = 0;
                }
            }

            if(value.is_allowed_to_generate_report != null){
                if(value.is_allowed_to_generate_report == 1){
                    $("#is_allowed_to_generate_report").prop("checked", true);
                    this.reportIsActive = 1;
                }
                else{
                    this.reportIsActive = 0;
                }
            }

            if(value.course_additional != null){

                for (let index in value.course_additional) {
                    this.more_details.push({ title: value.course_additional[index].course_additional_title,image: value.course_additional[index].course_additional_image,image_url:value.course_additional[index].course_additional_image,rand_key:Math.floor((Math.random() * 100) + 1)});
                }
            }


            if(value.course_extra_details != null){

                for (let index in value.course_extra_details) {
                    this.extra_details.push({ label: value.course_extra_details[index].label,value: value.course_extra_details[index].value,rand_key:Math.floor((Math.random() * 100) + 1)});
                }
            }

            if(value.seo_title != null || value.seo_title != ''){
                this.seo_title = value.seo_title
            }

            if(value.seo_keywords != null || value.seo_keywords != ''){
                //this.course_skills = ['test','test1'];
               // $(".courseSkill").val('test','test1').trigger("change");
               let keywords = value.seo_keywords.split(',');
                this.seo_keywords = keywords;
               keywords.forEach((keyword) => {
                    //console.log(skill);
                     $(".seo_keywords").append('<option value="'+keyword+'">'+keyword+'</option>');
                });
                 $(".seo_keywords").val(keywords);
                $(".seo_keywords").trigger('change');
                
            }

            if(value.seo_description != null || value.seo_description != ''){
                this.seo_description = value.seo_description
            }


            

            
            // this.localProjectAll = projects;
            // console.log("dfd",this.localProjectAll)
        }
    },
    methods: {
        ...mapActions('CoursesIndex', ['fetchCertificates','setCourseImage','resetState','fetchTypes','fetchModuleAll','fetchProjectAll','editCourseWebsite','fetchEditData','setIsActive']),
        ...mapActions('StudentSingle', ['fetchStandardAll']),
        updatedCoursePopular(e){
             if(e.target.checked == true) {
                    this.isPopular = 1;
                    //this.setIsActive(1)
                } else {
                    this.isPopular = 0;
                    //this.setIsActive(0)
                }
        },
        updatedCoursePublish(e){
             if(e.target.checked == true) {
                    this.isPublish = 1;
                    //this.setIsActive(1)
                } else {
                    this.isPublish = 0;
                    //this.setIsActive(0)
                }
        },
        updateCourseSkill(value){
            this.course_skills = value;
        },
        updateSEOKeywords(value){
            this.seo_keywords = value
        },
        updateCourseName(e) {
            this.courseName = e.target.value
        },
        AddField() {
        this.more_details.push({ title: '',image: '',image_url:'',rand_key:Math.floor((Math.random() * 100) + 1)});
        },
        AddExtraField(){
            this.extra_details.push({ label: '',value: '',rand_key:Math.floor((Math.random() * 100) + 1)});
        },
        updateMoreDetailTitle(e)
        {
            this.moreDetailTitle = e.target.value
        },
        updateMoreDetailDescription(e)
        {
            this.moreDetailDescription = e.target.value
        },
        removeFee(index){
    
            this.more_detail_error = '';
        
            index = this.more_details.findIndex(p => p.rand_key == index);
            this.more_details.splice(index, 1);

            },
            removeExtraDetail(index){
            this.extra_detail_error = '';
        
            index = this.extra_details.findIndex(p => p.rand_key == index);
            this.extra_details.splice(index, 1);
        },
        updateImage(e,index){
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                let index1 = this.more_details.findIndex(p => p.rand_key == index);
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                       // console.log("dfdf",e.target.result)
                        $('#blah_'+index)
                            .attr('src', e.target.result)
                            .width(100)
                            .height(100);
                    };
                    // console.log("resd",reader);
                    reader.readAsDataURL(e.target.files[0]);
                    this.more_details[index1].image = e.target.files[0];
                    this.more_details[index1].image_url = reader;
                    this.$forceUpdate();
                }else{
                    this.more_details[index1].image =  '';
                    this.more_details[index1].image_url = '';
                    
                }
            }           
        },
        updateProfile(e) {
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(150);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setCourseImage(e.target.files[0]);
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/gif' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        message = 'Only JPG, JPEG, PNG, GIF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#course_image").val('');
                            this.$validator.errors.remove('course_image');
                            this.setCourseImage('');
                            // this.courseImage = null;
                        }
                    })
                }
            }           
        }, 
        removeProfile(e, id) {
            this.$swal({
                title: 'Are you sure you want to delete this image?',
                text: "To fully delete the file submit the form.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (typeof result.dismiss === "undefined") {
                    this.setCourseImage('');
                     $("#course_image").val('');
                }
            })
        }, 
        updateCourseDescription(e){
            this.description = e.target.value
        },
        updateReferralUserPrice(e) {
            this.ReferralUserPrice = e.target.value
        },
        updateReferredUserPrice(e) {
            this.ReferredUserPrice = e.target.value
        },
        updatedIsActive(e){
            if(e.target.checked == true) {
                this.isActive = 1;
                this.setIsActive(1)
            } else {
                this.isActive = 0;
                this.setIsActive(0)
            }
        },
        updateEndDate(e) {
            this.endDate = e;
        },
        updateCourseHours(e){
            this.courseHours = e.target.value
        },
        updateCourseMonths(e){
            this.courseMonths = e.target.value
        },
         updateCourseCMonths(e){
          this.courseCMonths = e.target.value  
        },
        updateCourseReportContent(e){
            this.course_report_content = e.target.value
        },
        updatedReportIsActive(e){
            if(e.target.checked == true) {
                this.reportIsActive = 1;
                this.setReportIsActive(1)
            } else {
                this.reportIsActive = 0;
                this.setReportIsActive(0)
            }
        },
        submitForm() {
            this.allSelectError = '';
            if((this.more_details).length > 0){
                let more_title = [];
                let _this = this;
                this.more_details.forEach(function(detail, index){
                
                if(more_title.includes(detail.title)){
                    _this.more_detail_error = "All title must be unique.";
                    return false;
                }
                more_title.push(detail.title);
                });
            }           
            var params = {
                'id':this.$route.params.id, 
                'course_description': this.description,
                'course_profile':this.courseImage,
                'course_skills': this.course_skills,
                'more_details': this.more_details,
                'course_additional_title':this.moreDetailTitle,
                'course_additional_description':this.moreDetailDescription,
                'years':this.selected_year,
                'standard':this.selected_standard,
                'seo_title':this.seo_title,
                'seo_keywords':this.seo_keywords,
                'seo_description':this.seo_description,
                'extra_details':this.extra_details,
                'is_popular':this.isPopular,
                'is_published':this.isPublish,
                'course_report_content':this.course_report_content,
                'is_allowed_to_generate_report':this.reportIsActive,
                'certificate':this.certificate,
            }
            this.$validator.validate().then(result => {
                //console.log("dsfdsf",result,$('#lstBox2 option').length);
                if(result) {
                    this.buttonLoading = true;                    
                    this.editCourseWebsite(params)
                        .then((res)=>{
                            if(res.data.referrals){
                                this.$eventHub.$emit('common',1,res.data.referrals.message);
                            }
                            if(res.data.course){
                                this.$eventHub.$emit('common',1,res.data.course.message);
                            }

                            if(!res.data.result)
                            {
                                // this.$validator.errors.add({
                                //     id: 'module_name',
                                //     field: 'module_name',
                                //     msg: res.data.message,
                                //     scope: this.$options.scope,
                                // });
                                if((res.data.data).length > 0){
                                    for (const [key, value] of Object.entries(res.data.data)) {
                                        //console.log("ke",key,value);
                                        if(key == "allSelectedLists"){
                                            this.allSelectError = value[0];
                                        }
                                        else{
                                            this.$validator.errors.add({
                                                id: key,
                                                field: key,
                                                msg: value[0],
                                                scope: this.$options.scope,
                                            });
                                        }
                                    }
                                }
                                else{
                                    this.$eventHub.$emit('common',3,res.data.message);
                                }
                                
                                this.buttonLoading = false
                                return '';
                            }                            
                            this.$eventHub.$emit('create-success')
                            this.$router.push({name: 'courses.index'})
                        })
                        .catch(()=>{
                           this.$eventHub.$emit('common',3,'Something went wrong.')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}



.contain .checkmark:after {
    left: 6.5px;
    top: 6.5px;
}
.content-wrapper .content {
    padding: 0 0.5rem;
    padding-bottom: 70px;
}
</style>