<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Manage Coupons</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/coupons'">Coupons</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Coupon</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Add Coupon</h3>
                    </div>
                    <form @submit.stop.prevent="submitForm" >
                        <div class="card-body pl-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12 px-4">
                                            <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                                                <a class="nav-link active" id="general-tab" data-toggle="pill" href="#general" role="tab" aria-controls="general" aria-selected="true">General</a>
                                                <a class="nav-link" id="usage-restriction-tab" data-toggle="pill" href="#usage-restriction" role="tab" aria-controls="usage-restriction" aria-selected="false">Usage Restriction</a>
                                                <a class="nav-link" id="limit-tab" data-toggle="pill" href="#limit" role="tab" aria-controls="limit" aria-selected="false">Usage Limit</a>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 px-3">
                                            <div class="tab-content coupon" id="vert-tabs-tabContent">
                                                    <div class="tab-pane text-left fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="row col-md-12">
                                                                    <div class="form-group col-md-6">
                                                                        <label for="coupon_code">Coupon Code<p class="astrisk">*</p></label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="coupon_code"
                                                                            ref="coupon_code"
                                                                            maxlength="10"
                                                                            v-validate="'required'"
                                                                            placeholder="Enter or Generate Coupon Code"
                                                                            v-model="item.coupon_code"
                                                                            @input="checkUniqueCodeInput"
                                                                            >
                                                                            <span class="error" v-show="errors.has('coupon_code')">
                                                                                {{ $root.updateErrorMessage('coupon_code',this.errors, 'Coupon Code')}}
                                                                            </span>
                                                                    </div>
                                                                    <div class="col-md-6 coupon_create_btn">
                                                                        <label for="coupon_code"></label>
                                                                        <button type="button" class="btn btn-primary btn-sm" @click="GenerateVoucherCode">Generate Coupon Code</button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="discount_type">Discount Type<p class="astrisk">*</p></label>
                                                                        <v-select
                                                                            name="discount_type"
                                                                            label="name"
                                                                            id="discount_type"
                                                                            v-validate="'required'"
                                                                            @change="discountTypeChange"
                                                                            v-model="item.discount_type"
                                                                            :options="discountTypeAll"
                                                                            placeholder="Select Discount Type"
                                                                            />
                                                                            <span class="error" v-show="errors.has('discount_type')">
                                                                                {{ $root.updateErrorMessage('discount_type',this.errors, 'Discount Type')}}
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="coupon_amount">Coupon Amount/Discount<p class="astrisk">*</p></label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="coupon_amount"
                                                                            ref="coupon_amount"
                                                                            v-validate="{required:true,min_value:1,max_value:(item.discount_type && item.discount_type.value_id==1) ? 100 : 100000000}"
                                                                            maxlength="6"
                                                                            oninput="this.value=this.value
                                                                                                    .replace(/[^\d.]/g, '')
                                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                                    .replace(/(\.[\d]{2})./g, '$1');"
                                                                            placeholder="Enter Coupon Amount/Discount"
                                                                            v-model="item.coupon_amount"
                                                                            >
                                                                            <span class="error" v-show="errors.has('coupon_amount')">
                                                                                {{ $root.updateErrorMessage('coupon_amount',this.errors, 'Coupon Amount/Discount')}}
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="min_spends">Apply Minimum Spends per Amount</label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="min_spends"
                                                                            ref="min_spends"
                                                                            maxlength="6"
                                                                            v-validate="{required:false,max_value:(item.max_spends!='') ? item.max_spends :100000}"
                                                                            oninput="this.value=this.value
                                                                                                .replace(/[^\d.]/g, '')
                                                                                                .replace(/(\..*)\./g, '$1')
                                                                                                .replace(/(\.[\d]{2})./g, '$1');"
                                                                            placeholder="Enter Minimum Spends"
                                                                            v-model="item.min_spends"
                                                                            >
                                                                            <span class="error" v-show="errors.has('min_spends')">
                                                                                {{ $root.updateErrorMessage('min_spends',this.errors, 'Minimum Spends')}}
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                                <!-- v-if="(item.discount_type && item.discount_type.value_id==1) -->
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="max_spends">Apply Maximum Spends per Amount</label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="max_spends"
                                                                            ref="max_spends"
                                                                            maxlength="6"
                                                                            v-validate="{required:false,max_value:100000,min_value:item.min_spends}"
                                                                            oninput="this.value=this.value
                                                                                                .replace(/[^\d.]/g, '')
                                                                                                .replace(/(\..*)\./g, '$1')
                                                                                                .replace(/(\.[\d]{2})./g, '$1');"
                                                                            placeholder="Enter Maximum Spends"
                                                                            v-model="item.max_spends"
                                                                            >
                                                                            <span class="error" v-show="errors.has('max_spends')">
                                                                                {{ $root.updateErrorMessage('max_spends',this.errors, 'Maximum Spends')}}
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div ref="expiry_date" class="form-group">
                                                                        <label for="expiry_date">Expiry Date<p class="astrisk">*</p></label>
                                                                        <date-picker
                                                                            v-model="item.expiry_date"
                                                                            :config="$root.dpconfigDateFuture"
                                                                            name="expiry_date"
                                                                            v-validate="'required'"
                                                                            placeholder="Select Expiry Date"
                                                                            :min-date='new Date()'
                                                                            autocomplete="off"
                                                                            >
                                                                        </date-picker>
                                                                        <span class="error" v-show="errors.has('expiry_date')">
                                                                            {{ $root.updateErrorMessage('expiry_date',this.errors,'Expiry Date')}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="course_vertical">Course Vertical</label>
                                                                        <v-select
                                                                            name="course_vertical"
                                                                            label="name"
                                                                            id="course_vertical"
                                                                            v-model="item.course_vertical"
                                                                            :options="courseVerticalAll"
                                                                            placeholder="Select Course Vertical"
                                                                            />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="course_category">Course Category</label>
                                                                        <v-select
                                                                            name="course_category"
                                                                            label="name"
                                                                            id="course_category"
                                                                            v-model="item.course_category"
                                                                            :options="courseCategoryAll"
                                                                            placeholder="Select Course Category"
                                                                            />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group mb-0">     
                                                                        <div class="checkbox ">
                                                                            <label class="cursor-pointer">
                                                                                First Admission &nbsp;
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="is_first_admission"
                                                                                    v-model="item.first_admission"
                                                                                    class="first_admission"
                                                                                    >
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                <div class="tab-pane fade" id="usage-restriction" role="tabpanel" aria-labelledby="usage-restriction-tab">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="branches">Branch/es</label>
                                                                    <v-select
                                                                        multiple
                                                                        name="branches"
                                                                        label="branch_name"
                                                                        id="branches"
                                                                        v-model="item.branch"
                                                                        :options="branchAll"
                                                                        placeholder="Select Branch/es"
                                                                        />
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="projects">Project/s</label>
                                                                    <v-select
                                                                        multiple
                                                                        name="projects"
                                                                        label="project_name"
                                                                        id="projects"
                                                                        v-model="item.project"
                                                                        :options="projectAll"
                                                                        placeholder="Select Project/s"
                                                                        />
                                                                        <span class="error" v-show="errors.has('projects')">
                                                                            {{ $root.updateErrorMessage('projects',this.errors, 'Project/s')}}
                                                                        </span>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="courses">Course/s</label>
                                                                    <v-select
                                                                        multiple
                                                                        name="courses"
                                                                        label="course_name"
                                                                        id="courses"
                                                                        v-model="item.course"
                                                                        :options="courseAll"
                                                                        placeholder="Select Course/s"
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="parent">Parent/s</label>
                                                                    <v-select
                                                                        multiple
                                                                        name="parent"
                                                                        label="full_name"
                                                                        id="parent"
                                                                        v-model="item.parent"
                                                                        :options="parentAll"
                                                                        placeholder="Select Parent/s"
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="employee">Employee/s</label>
                                                                    <v-select
                                                                        multiple
                                                                        name="employee"
                                                                        label="emp_name"
                                                                        id="employee"
                                                                        v-model="item.employee"
                                                                        :options="employeeAll"
                                                                        placeholder="Select Employee/s"
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="limit" role="tabpanel" aria-labelledby="limit-tab">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="coupon_limit">Usage limit per coupon</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        name="coupon_limit"
                                                                        ref="coupon_limit"
                                                                        maxlength="6"
                                                                        oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                        placeholder="Enter Usage limit per Coupon"
                                                                        v-model="item.coupon_limit"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="course_limit">Usage limit per course</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        name="course_limit"
                                                                        ref="course_limit"
                                                                        maxlength="6"
                                                                        oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                        placeholder="Enter Usage limit per Course"
                                                                        v-model="item.course_limit"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="user_limit">Usage limit per User</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        name="user_limit"
                                                                        ref="user_limit"
                                                                        maxlength="6"
                                                                        oninput="this.value=this.value
                                                                                            .replace(/[^\d.]/g, '')
                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                        placeholder="Enter Usage limit per User"
                                                                        v-model="item.user_limit"
                                                                        >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="col-md-12 col-xs-12 savebtn">
                                <vue-button-spinner
                                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                        type="submit"
                                        :isLoading="loading"
                                        :disabled="loading"
                                        >
                                        Save
                                </vue-button-spinner>
                            </div> 
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import voucher_codes from 'voucher-code-generator';

export default {
    data() {
        return {
            generalTab:['coupon_code', 'discount_type', 'coupon_amount', 'expiry_date'],//Checking array for general tab redirection
            limitTab:['min_spends', 'max_spends'],//Checking array for limit tab redirection
            // Code...
        }
    },
    computed: {
        ...mapGetters('CouponsSingle', ['item', 'loading', 'discountTypeAll', 'courseVerticalAll', 'courseCategoryAll',
        'branchAll', 'projectAll', 'courseAll', 'parentAll', 'employeeAll', 'coupon_code']),
    },
    created() {
        this.resetState()
        this.fetchTypes()
        this.fetchBranchAll()
        this.fetchProjectAll()
        this.fetchCourseAll()
        this.fetchParentAll()
        this.fetchEmployeeAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('CouponsSingle', ['storeData', 'setCouponAmount', 'resetState', 'fetchTypes', 'fetchBranchAll', 'fetchProjectAll', 'fetchCourseAll', 'fetchParentAll', 'fetchEmployeeAll', 'storeData', 'setCouponCode', 'checkUniqueCode', 'setMaxSpends', 'setItem']),
        GenerateVoucherCode(){
            var Vcode = voucher_codes.generate({
                length: 8,
                count: 1
            });
            this.CouponCode(Vcode);
        },
        discountTypeChange(val){
            this.setCouponAmount('');
            // this.setMaxSpends(0);
        },
        checkUniqueCodeInput(e){
            var val = e.target.value;
            this.CouponCode(val);
        },
        CouponCode(val){
            this.checkUniqueCode(val)
            .then((res)=>{
                if(!res.data.data.success){
                    this.$validator.errors.add({
                        id: 'coupon_code',
                        field: 'coupon_code',
                        msg: 'This coupon code is alredy existed',
                        scope: this.$options.scope,
                    });
                    return false
                }else{
                    this.setCouponCode(val);
                }                
            });
        },
        updateFirstAdmission(value) {
            this.setDiscountType(value)
        },           
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.checkUniqueCode(this.item.coupon_code)
                        .then((res)=>{
                            console.log(res.data.data.success);
                            if(!res.data.data.success){
                                this.$validator.errors.add({
                                    id: 'coupon_code',
                                    field: 'coupon_code',
                                    msg: 'This coupon code is alredy existed',
                                    scope: this.$options.scope,
                                });
                                return false
                            } else{
                                this.storeData()
                                    .then((res) => {
                                        if(!res.data.result)
                                        {
                                            for (const [key, value] of Object.entries(res.data.data)) {
                                                this.$validator.errors.add({
                                                    id: key,
                                                    field: key,
                                                    msg: value[0],
                                                    scope: this.$options.scope,
                                                });
                                            }
                                            this.buttonLoading = false
                                            return '';
                                        }                            
                                        this.$eventHub.$emit('create-success')
                                        this.$router.push({name: 'coupons.index'})
                                    })
                                    .catch((error) => {
                                        console.error(error)
                                    })
                            }               
                        });
                }
                else{
                    var flag = false;
                    this.$validator.errors.items.forEach((v,i)=>{
                        if(this.generalTab.includes(v.field)){ 
                            flag =true
                            $('#general-tab').click(); 
                            return false
                        }
                    });
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            });
        }
    }
}
</script>


<style scoped>
.nav-link{
    border: 1px solid #333a401a;
    color: #8b949d !important;
}
.nav-link.active{
    color: black !important;
}
.first_admission{
    position: absolute;
    top: 7px;
}
.pt-23{
    padding-top: 2.3rem!important;
}
</style>
 
