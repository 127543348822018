<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Locations</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Add Location</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="location_name">Location Name<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                maxlength="150"
                                                name="location_name"
                                                ref="location_name"
                                                v-validate="'required'"
                                                placeholder="Enter Location Name"
                                                :value="item.location_name"
                                                @input="updateLocation_name"
                                                >
                                                <span class="error" v-show="errors.has('location_name')">
                                                  {{ $root.updateErrorMessage('location_name',this.errors,'Location Name')}}
                                                </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="location_address">Location Address<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                maxlength="190"
                                                name="location_address"
                                                ref="location_address"
                                                v-validate="'required'"
                                                placeholder="Enter Location Address"
                                                :value="item.location_address"
                                                @input="updateLocation_address"
                                                >
                                                <span class="error" v-show="errors.has('location_address')">
                                                  {{ $root.updateErrorMessage('location_address',this.errors,'Location Address')}}
                                                </span>
                                    </div>
                                </div>
                                <div class="col-md-6">    
                                    <div class="form-group">
                                        <label for="contact_no">Contact Number</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                maxlength="25"
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                name="contact_no"
                                                ref="contact_no"
                                                placeholder="Enter Contact Number"
                                                :value="item.contact_no"
                                                @input="updateContact_no"
                                                >
                                                <span class="error" v-show="errors.has('contact_no')">
                                                  {{ $root.updateErrorMessage('contact_no',this.errors, 'Contact Number')}}
                                                </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="area">Location Area</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="area"
                                                ref="area"
                                                maxlength="190"
                                                placeholder="Enter Location Area"
                                                :value="item.area"
                                                @input="updateArea"
                                                >
                                                <span class="error" v-show="errors.has('area')">
                                                  {{ $root.updateErrorMessage('area',this.errors,'Location Area')}}
                                                </span>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="city">City<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="city"
                                                ref="city"
                                                maxlength="190"
                                                v-validate="'required'"
                                                placeholder="Enter City"
                                                :value="item.city"
                                                @input="updateCity"
                                                >
                                                <span class="error" v-show="errors.has('city')">
                                                  {{ $root.updateErrorMessage('city',this.errors)}}
                                                </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="pincode">PIN/ZIP Code</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="pincode"
                                                ref="pincode"
                                                maxlength="20"
                                                placeholder="Enter PIN/ZIP Code"
                                                :value="item.pincode"
                                                @input="updatePin"
                                                >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div ref="country" class="form-group">
                                        <label for="country">Country<p class="astrisk">*</p></label>
                                        <v-select
                                                name="country"
                                                v-validate="'required'"
                                                label="country_name"
                                                @input="updateCountry"
                                                :value="item.country"
                                                :options="countrymastersAll"
                                                />
                                                <span class="error" v-show="errors.has('country')">
                                                  {{ $root.updateErrorMessage('country',this.errors)}}
                                                </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div ref="state" class="form-group">
                                        <label for="state">State<p class="astrisk">*</p></label>
                                        <v-select
                                                name="state"
                                                v-validate="'required'"
                                                label="state_name"
                                                @input="updateState"
                                                :value="item.state"
                                                :options="statemastersAll"
                                                class="parentName"
                                                />
                                                <AddStatePopupLocation v-if="$can('state_create') && statePopupButton"></AddStatePopupLocation>
                                                <span class="error" v-show="errors.has('state')">
                                                  {{ $root.updateErrorMessage('state',this.errors)}}
                                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddStatePopupLocation from '../../dtmodules/PopupActions/AddStatePopupLocation'

export default {
    components: { AddStatePopupLocation },
    data() {
        return {
            // Code...
            statePopupButton: true,
        }
    },
    computed: {
        ...mapGetters('LocationMastersSingle', ['item', 'loading', 'countrymastersAll', 'statemastersAll'])
    },
    created() {
        this.fetchCountrymastersAll(),
        this.fetchStatemastersAll()
    },
    destroyed() {
        this.resetState()
    },
    methods: {
        ...mapActions('LocationMastersSingle', ['storeData', 'resetState', 'setBranch_id', 'setLocation_name', 'setLocation_address', 'setContact_no', 'setArea', 'setCity', 'setPin', 'setCountry', 'setState', 'fetchCountrymastersAll', 'fetchStatemastersAll', 'fetchCountryStates','setStatemastersAll']),
        fetchStatemasters(value) {
                if(value !== null){
                    this.fetchCountryStates(value.id);
                    this.updateCountry(value);
                }
        },        
        updateBranch_id(e) {
            this.setBranch_id(e.target.value)
        },
        updateLocation_name(e) {
            this.setLocation_name(e.target.value)
        },
        updateLocation_address(e) {
            this.setLocation_address(e.target.value)
        },
        updateContact_no(e) {
            this.setContact_no(e.target.value)
        },
        updateArea(e) {
            this.setArea(e.target.value)
        },
        updateCity(e) {
            this.setCity(e.target.value)
        },
        updatePin(e) {
            this.setPin(e.target.value)
        },
        updateCountry(value) {
                if(value !== null){
                    this.fetchCountryStates(value.id);
                    this.statePopupButton = true;
                }
                else{
                    this.setStatemastersAll([]);
                    this.statePopupButton = false;
                }
            this.setState(null)
            this.setCountry(value)
        },
        updateState(value) {
                // if(value !== null){
                //     this.fetchCountryStates(value.id);
                // }                 
           this.setState(value)
        },
        submitForm() {
            this.$validator.validate().then(result => {
            if (result) {
            this.storeData()
                .then(() => {
                    this.$router.push({ name: 'location_masters.index' })
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
            }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
      });
        }
    }
}
</script>

<style scoped>

</style>
