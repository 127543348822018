<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Project Fees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/project-fees'">Project Fee</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Project Fee</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Project Fee</h3>
                </div>
                <div class="card-body" >
                    <div class="row">
                        <div class="col-md-6 col-xs-12 table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th>Branch Name</th>
                                        <td>
                                            <span v-if="item.branch_name !== null">
                                                {{ item.branch_name }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6 col-xs-12  table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th>Project Name</th>
                                        <td>
                                            <span v-if="item.project_name !== null">
                                                {{ item.project_name }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 
                    </div>
                    <div class=" col-md-10 col-md-offset-1" v-if="courseDetailedData.length > 0">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Fee type</th>
                                        <th>Amount</th>
                                        <th>From Date</th>
                                        <th>Upto Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(feesType, i) in courseDetailedData">
                                        <td>{{feesType.fee_type_name}}</td>
                                        <td> <i class="fa fa-rupee"></i> {{feesType.fees_amount}}</td>      
                                        <td>{{feesType.new_from_date}}</td>      
                                        <td>{{(feesType.new_upto_date)?feesType.new_upto_date:'-'}}</td>      
                                    </tr>
                                </tbody>
                            </table><br>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData(this.$route.params.project_fee_id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('ProjectFeesSingle', ['item','courseDetailedData']),
        computedTotalAmount() {
            var total = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                total = total+parseFloat(this.courseDetailedData[i].fees_amount)
            }
            return total;
        }
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
            this.fetchShowData(this.$route.params.project_fee_id)
        }
    },
    methods: {
        ...mapActions('ProjectFeesSingle', ['fetchData','fetchShowData', 'resetState']),
        formatedFromDate(dbdate) {
            var local = dbdate.split('-');
            return local[2] + '/' +  local[1] + '/' + local[0]
        }
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
</style>
