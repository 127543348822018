<template>
    <form @submit.prevent="submitForm" novalidate>
        <bootstrap-alert />
        <div class="row">
            <div class="form-group col-md-12 col-xs-12">
                <label for="title">Title<p class="astrisk">*</p></label>
                <input
                        type="text"
                        class="form-control"
                        name="title"
                        ref="title"
                        v-validate="'required'"
                        placeholder="Enter Title"
                        v-model="title"
                        >
                <span class="error" v-show="errors.has('title')">
                    {{ $root.updateErrorMessage('title',this.errors,'Title')}}
                </span>
            </div>  

                <div class="form-group col-md-12 col-xs-12">
                <label for="description">Description</label>
                <textarea
                    rows="5"
                    class="form-control"
                    name="description"
                    ref="description"
                    maxlength="500"
                    v-validate="'required'"
                    placeholder="Enter Description"
                    v-model="description"
                    />
                <span class="error" v-show="errors.has('description')">
                    {{ $root.updateErrorMessage('description',this.errors,'Description')}}
                </span>
            </div>
        </div>
    <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
</form>         
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props:['editId'],
    data() {
        return {
            description: '',
            title:''
        };
    },
    computed: {
        ...mapGetters('CourseFaqIndex', ['editDataAll', 'loading']),
    }, 
    watch:{
        editDataAll(value){
            if(value != ''){
                this.title = value.title;
                this.description = value.description;
            }
        }
    },
    created(){
        this.fetchEditFaqData(this.editId);
    }, 
    methods:{
    ...mapActions('CourseFaqIndex', ['updateData','fetchEditFaqData','resetState']),
        closeModel(){
            this.$emit('closeModel');
        },
        submitForm(){
            this.$validator.validate().then(result => {
                if (result) {    
                    let params = {
                        'title':this.title,
                        'description':this.description,
                        'course_id':this.$route.params.id,
                        'id':this.editId
                    };              
                    this.updateData(params)
                    .then((res) => {
                        if(!res.data.result)
                        {
                           for (const [key, value] of Object.entries(res.data.data)) {
                           // console.log("ke",key,value);
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                            return this.$root.handleValidationFocus(this.errors,this.$refs);
                        }  
                       
                        this.$emit('closeModel');
                        this.$emit('loadItems');
                        this.$eventHub.$emit('update-success')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
            });
        }
    }
}
</script>
<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>


