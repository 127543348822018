function initialState() {
    return {
        all: [],
        editDataAll: [],
        indexAll: [],
        query: {},
        projectAll: [],
        showData: [],
        loading: false,
    }
} 

const getters = {
    showData: state => state.showData,
    editDataAll: state => state.editDataAll,
    indexAll: state => state.indexAll,
    query: state => state.query,
    all: state => state.all,
    moduleAll: state => state.moduleAll,
    projectAll: state => state.projectAll,
    loading: state => state.loading
}

const actions = {
    fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/modules/show/' + id)
                .then(response => {
                    commit('setShowDataAll', response.data.data)
                    commit('setLoading', false)
                    resolve(response)
                   
                })
                .catch(function (error) {
                   // console.log("ere",error);
                    console.log('Error', error);
                    commit('setLoading', false)
                    reject(error)
                });
        })
    },    
    async editModule({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.put('/api/v1/modules/' + params.id, params)
                .then(response => {
                    //if(response.data.result){
                        //commit('setEditDataAll', response.data.data)
                        resolve(response)
                    //}
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                     commit('setLoading', false)
                })
        })
    },  
    async fetchEditData({ commit, state, dispatch }, id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/modules/show/' + id)
                .then(response => {
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchIndexData({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchIndexDataForGroups')
                .then(response => {
                    commit('setIndexDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },     
    async fetchStudentAll({ commit, state, dispatch }) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/fetchAllStudents')
                .then(response => {
                    commit('setModuleAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchProjectAll({ commit, state, dispatch }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllProjects')
                .then(response => {
                    if(response.data.result){
                        commit('setProjectAll', response.data.data)
                        resolve(response)
                    }
                    else{
                        commit('setProjectAll', [])
                        resolve([])
                    }
                    
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },        
    moduleStore({ commit, state, dispatch }, params) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.post('/api/v1/modules/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
        axiosLms.delete('/api/v1/modules/' + id)
                .then(response => {
                    resolve(response);
                    })
                    .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    })  
                    .finally(() => {
                        // commit('setError', false)
                    })
        })
    },    
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },    
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },
    setAll({ commit }, value) {
        commit('setAll', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }    
}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, all) {
        state.all = all
    },
    setShowDataAll(state, all) {
        state.showData = all
    },
    setQuery(state, query) {
        state.query = query
    },    
    setModuleAll(state, moduleAll) {
        state.moduleAll = moduleAll
    },
    setIndexDataAll(state, indexAll) {
        state.indexAll = indexAll
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    },    
    setProjectAll(state, projectAll) {
        state.projectAll = projectAll
    },        
    resetState(state) {
        state = Object.assign(state, initialState())
    }    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
