<template>
    <div class="btn-group btn-group-xs">

         <button
            title="Inquiry History"
             v-b-modal.modalInquiryHistory
             @click="setInquiryId(row.id)"
            class="btn btn-block btn-primary edit_delete_btn"
            >
            <i class="fa fa-fw fa-history"></i>
        </button>
        <button
            v-if="$can('inquiry_assign_create') && row.status.value_id != 3"
            v-b-modal.modalPreventAssignInquiries
            @click="setInquiryId(row.id)"
            class="btn btn-warning edit_delete_btn"
            title="Assign"
            >
            <i class="fa fa-fw fa-user"></i>
        </button>

        <button
             v-if="$can(xprops.permission_prefix + 'edit') && row.status.value_id != 3"
            v-b-modal.modalPreventTransferInquiries
            class="btn btn-info edit_delete_btn"
            title="Transfer"
            @click="setInquiryId(row.id)"
            >
            <i class="fa fa-fw fa-exchange"></i>
        </button>

        <router-link
            v-if="$can(xprops.permission_prefix + 'view')"
            :to="{ name: xprops.route + '.show', params: { id: row.id } }"
            class="btn btn-success edit_delete_btn"
            title="View"
            >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>

        <router-link
            v-if="$can(xprops.permission_prefix + 'edit') && row.status.value_id != 3"
            title="Edit"
            :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
            class="btn btn-block btn-primary edit_delete_btn"
            >
            <i class="fa fa-fw fa-pencil"></i>
        </router-link>

        <button
            v-if="$can(xprops.permission_prefix + 'delete') && row.status.value_id != 3"
            @click="destroyDataaaa(row.id)"
            type="button"
            title="Delete"
            class="btn btn-block btn-danger edit_delete_btn"
            >
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
   </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module ,
            BranchView: false,
            branch_id: 0
        }
    },
    created() {
        
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
    },
    methods: {
        setInquiryId(id){
            this.$emit('setInquiryId',id);
        },
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(result => {
                        if(result.data.result){
                            this.$eventHub.$emit('delete-success')
                            this.$emit('loadItems')
                        }
                        else{
                            this.$eventHub.$emit('common',2,result.data.message)
                        }
                        
                    })
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
