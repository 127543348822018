<template>
    <div class="popup-form add-country">
        <b-modal id="modalShowHoldHistory"
                ref="modal"
                title="Batch Hold History"
                okTitle="Save"
                okVariant="success" @shown="modalOpened"
                hide-footer
                size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
                <div class="container-fluid">  
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <table class="table table-bordered">
                                    <tr>
                                        <td>Course Name</td>
                                        <td> {{holdHistoryData.course_name}} </td>
                                    </tr>
                                    <tr>
                                        <td>Batch Details</td>
                                        <td>
                                            <div class="row">
                                                <div class="col-md-4 col-xs-12" v-for="(batches, weekdays) in holdHistoryData.batch_details" :key="weekdays">
                                                    <ul>
                                                        {{ weekdays }}
                                                        <li v-for="(batchname, index) in batches" :key="index" class="ml-3">
                                                            {{ batchname }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Batch Start Date</td>
                                        <td>{{holdHistoryData.start_date}}</td>
                                    </tr>
                                    <tr>
                                        <td>Batch End Date</td>
                                        <td>{{holdHistoryData.end_date}}</td>
                                    </tr>
                                    <tr>
                                        <td>Hold Date</td>
                                        <td>
                                            <p v-if="holdHistoryData.hold_date">
                                                {{holdHistoryData.hold_date}}
                                            </p>
                                            <p v-else> - </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Resume Date</td>
                                        <td>
                                            <p v-if="holdHistoryData.resume_date">
                                                {{holdHistoryData.resume_date}}
                                            </p>
                                            <p v-else> - </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['history_id'],
    data() {
        return {

        }
    },
    created() {
        this.getHoldHistoryData(this.history_id)
    },
    watch: {
        "$route.params.id": function() {
            this.getHoldHistoryData(this.history_id)
        },
        holdHistoryData:function() {
        }
    },
    computed: {
        ...mapGetters('BatchSingle', ['holdHistoryData'])
    },
    methods: {
        ...mapActions('BatchSingle', ['getHoldHistoryData']),
        modalOpened() {
            this.getHoldHistoryData(this.history_id)
        }
    },
}
</script>