<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Assessments</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Edit Course Assessment</h3>
                            </div>

                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>

                            <bootstrap-alert />

                            <div class="box-body">
                            <div class="col-md-6 col-xs-12">
                                <div ref="course_name_id" class="form-group">
                                    <label for="course_name_id">Course Name<p class="astrisk">*</p></label>
                                    <v-select
                                            name="course_name_id"
                                            label="display_course_name"
                                            v-validate="'required'"
                                            @input="updateCourseId"
                                            :value="item.course_name_id"
                                            :options="coursesAll"
                                            disabled
                                            />
                                        <span class="error" v-show="errors.has('course_name_id')">
                                            {{ $root.updateErrorMessage('course_name_id',this.errors, 'Course Name')}}
                                        </span>
                                </div>
                            </div>
                            
                                <div class="col-md-6 col-xs-12">
                                    <div ref="sub_topic" class="form-group">
                                        <label for="sub_topic">Sub Topic Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="sub_topic"
                                                label="name"
                                                v-validate="'required'"
                                                @input="updateSubTopicId"
                                                :value="item.sub_topic"
                                                :options="subtopicsAll"
                                                />
                                        <span class="error" v-show="errors.has('sub_topic')">
                                            {{ $root.updateErrorMessage('sub_topic',this.errors,'Sub Topic Name')}}
                                        </span>
                                    </div>  
                                </div>  
                               
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="question">Question<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                maxlength="700"
                                                class="form-control"
                                                name="question"
                                                ref="question"
                                                v-validate="'required'"
                                                placeholder="Enter Question"
                                                :value="item.question"
                                                @input="updateQuestion"
                                                >
                                        <span class="error" v-show="errors.has('question')">
                                            {{ $root.updateErrorMessage('question',this.errors)}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="row">
                                        <div class="col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="fully">Fully<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        maxlength="10"
                                                        name="fully"
                                                        ref="fully"
                                                        oninput="this.value=this.value
                                                                      .replace(/[^\d.]/g, '')
                                                                      .replace(/(\..*)\./g, '$1')
                                                                      .replace(/(\.[\d]{2})./g, '$1');"
                                                        v-validate="'required'"
                                                        placeholder="Enter Fully Weightage"
                                                        @input="updateFully"
                                                        :value="item.fully"
                                                        >
                                                <span class="error" v-show="errors.has('fully')">
                                                    {{ $root.updateErrorMessage('fully',this.errors,'Fully Weightage')}}
                                                </span>
                                            </div>                                   
                                        </div>   
                                        <div class="col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="partial">Partial<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        maxlength="10"
                                                        name="partial"
                                                        ref="partial"
                                                        oninput="this.value=this.value
                                                                      .replace(/[^\d.]/g, '')
                                                                      .replace(/(\..*)\./g, '$1')
                                                                      .replace(/(\.[\d]{2})./g, '$1');"
                                                        v-validate="'required'"
                                                        placeholder="Enter Partial Weightage"
                                                        @input="updatePartial"
                                                        :value="item.partial"
                                                        >
                                                <span class="error" v-show="errors.has('partial')">
                                                    {{ $root.updateErrorMessage('partial',this.errors,'Partial Weightage')}}
                                                </span>
                                            </div>                                   
                                        </div>   
                                        <div class="col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="need_improvement">Need Improvement<p class="astrisk">*</p></label>
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        maxlength="10"
                                                        name="need_improvement"
                                                        ref="need_improvement"
                                                        oninput="this.value=this.value
                                                                      .replace(/[^\d.]/g, '')
                                                                      .replace(/(\..*)\./g, '$1')
                                                                      .replace(/(\.[\d]{2})./g, '$1');"
                                                        v-validate="'required'"
                                                        placeholder="Enter Need Improvement Weightage"
                                                        @input="updateNeedImprovement"
                                                        :value="item.need_improvement"
                                                        >
                                                <span class="error" v-show="errors.has('need_improvement')">
                                                    {{ $root.updateErrorMessage('need_improvement',this.errors,'Need Improvement Weightage')}}
                                                </span>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                                
                             <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                        <label for="sort_order">Sort Order<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="sort_order"
                                                ref="sort_order"
                                                maxlength="10"
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                v-validate="'required|numeric'"
                                                placeholder="Enter Sort Order"
                                                :value="item.sort_order"
                                                @input="updateQuestionNumber"
                                                >
                                        <span class="error" v-show="errors.has('sort_order')">
                                            {{ $root.updateErrorMessage('sort_order',this.errors,'Sort Order')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading"
                                    :disabled="loading"
                                    >
                                    Save
                            </vue-button-spinner>
                        </div>                        
                    </form>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
            count:0
        }
    },
    computed: {
        ...mapGetters('CourseAssesmentsSingle', ['item', 'loading', 'coursesAll', 'coursetopicsAll', 'subtopicsAll']),
      
    },
    created() {
        this.fetchData(this.$route.params.id)

    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('CourseAssesmentsSingle', ['fetchData', 'updateData', 'resetState', 'setCourseId','setCourseTopicId', 'setSubTopicId', 'setQuestion', 'setQuestionAnswer', 'setQuestionNumber', 'fetchCot','setFully','setPartial','setNeedImprovement']),


        updateCourseTopicId(value) {
                // if(value !== null){
                //     this.fetchCot(value.id);
                // }             
            this.setCourseTopicId(value)
        },
        updateSubTopicId(value) {
            this.setSubTopicId(value)
        },
        updateQuestion(e) {
            this.setQuestion(e.target.value)
        },
        updateFully(e){
            this.setFully(e.target.value);
        },
        updatePartial(e){
            this.setPartial(e.target.value);
        },
        updateNeedImprovement(e){
            this.setNeedImprovement(e.target.value);
        },
        updateQuestionAnswer(value) {
            this.setQuestionAnswer(value)
        },
        updateQuestionNumber(e) {
            this.setQuestionNumber(e.target.value)
        },
        updateCourseId(value) {
                if(value != null && value != ''){
                    this.fetchCot(value.id);
                }
                if(this.count > 0) {
                    this.setCourseTopicId(null);
                }
            this.count++;               
            this.setCourseId(value)
        },
        isWetageValidationError()
        {
            var fully = parseFloat(this.item.fully);
            var partial = parseFloat(this.item.partial);
            var need_improvement = parseFloat(this.item.need_improvement);
            var message = '';
            if(fully <= partial)
            {
                message = 'partial value must be less than fully value';
                this.$validator.errors.add({
                    id: 'partial',
                    field: 'partial',
                    msg: message,
                    scope: this.$options.scope,
                });
                return true;
            }
            if(partial <= need_improvement)
            {
                message = 'need_improvement value must be less than partial value';
                this.$validator.errors.add({
                    id: 'need_improvement',
                    field: 'need_improvement',
                    msg: message,
                    scope: this.$options.scope,
                });
                return true;
            }
            return false;
        },
        submitForm() {
        this.$validator.validate().then(result => {
        if (result) {
             var error = this.isWetageValidationError();
            if (error) {
                return false;
            }
            this.updateData()
                .then((res) => {
                    if(res.data.error_message)
                    {
                        this.$validator.errors.add({
                            id: 'question',
                            field: 'question',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'course_assesments.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }
    }
}
</script>


<style scoped>

</style>
  