<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Student Schedule Listing</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Student Schedule Listing</h3>
                        </div>                      
                        <div class="box-body">
                            <div class="col-md-6 col-xs-12">
                                 <div ref="student" class="form-group">
                                    <label for="student">Student Name<p class="astrisk">*</p></label>
                                    <v-select
                                        id="student"
                                        name="student"
                                        label="student_name"
                                        v-validate="'required'"
                                        :options="studentAll"
                                        @input="updateStudent"
                                        :value="item.student"
                                        />
                                    <span class="error" v-show="errors.has('student')">
                                        {{ $root.updateErrorMessage('student',this.errors,'Student Name')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                 <div ref="course" class="form-group">
                                    <label for="course">Course Name<p class="astrisk">*</p></label>
                                    <v-select
                                        id="course"
                                        name="course"
                                        label="display_course_name"
                                        v-validate="'required'"
                                        @input="updateCourse"
                                        :value="item.course"
                                        :options="courseAll"
                                        />
                                    <span class="error" v-show="errors.has('course')">
                                        {{ $root.updateErrorMessage('course',this.errors,'Course Name')}}
                                    </span>
                                </div>
                            </div>                                                
                        </div>              
                    </div>
                </div>
            </div>
            <div class="row" v-if="item.course">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                              <table class="text-left pull-right">
                                  <tbody>
                                      <tr>
                                          <td>
                                              Specifications:
                                          </td>
                                          <td>
                                              <div class="Color_Box_Div inProgressColor"></div>
                                              <span>In Progress</span>
                                          </td>
                                          <td>
                                              <div class="Color_Box_Div sessionExtendedColor"></div>
                                              <span>Session Extended</span>
                                          </td>
                                        <td>
                                              <div class="Color_Box_Div completedColor"></div>
                                              <span>Completed</span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>   
                        </div>                           
                        <div class="box-body">
                            <div class="btn-group pull-right">
                              <table class="text-left pull-right table table-bordered">
                                  <tbody>
                                      <tr>
                                          <td>
                                              <span><b>Total Presents: {{totalPresents}}</b></span>
                                          </td>
                                          <td>
                                              <span><b>Total Absents: {{totalAbsents}}</b></span>
                                          </td>
                                          <td>
                                              <span><b>Total Pending: {{totalPending}}</b></span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>                                 
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="getCountForCalendar">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Schedules...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import StudentBatchListingActions from '../../dtmodules/DatatableActions/StudentBatchListingActions'
import DatatableActions from '../../dtmodules/DatatableActions'
import AttendanceStatus from '../../dtmodules/AttendanceStatus'
import TopicStatusListing from '../../dtmodules/TopicStatusListing'
import TopicStatusTags from '../../dtmodules/TopicStatusTags'
import components from '../../comps/'

StudentBatchListingActions.attachStore('StudentBatchListingIndex');
export default {
    StudentBatchListingActions,   
    components,
    AttendanceStatus,
    TopicStatusListing,
    TopicStatusTags,
    data() {
        return {
            items:[],
            columns: [
                { title: 'S.No', field: 's_no', sortable: true, thComp: 'FilterTh', tdClass: '' },

                { title: 'Schedule Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                // { title: 'Schedule Date', field: 'display_session_date', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Date' },
                { title: 'Schedule Day', field: 'schedule_days', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Day' },
                { title: 'Batch Schedule', field: 'batch_name', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Lab Name', field: 'lab_name', sortable: true, thComp: 'FilterTh', tdClass: 'Start Time' },
                // { title: 'End Time', field: 'end_time', sortable: true, thComp: 'FilterTh', tdClass: 'End Time' },
                { title: 'Attendance', field: 'attendance', sortable: true, thComp: 'FilterTh', tdClass: 'Attendance' },
                { title: 'Attendance By', field: 'attendance_update_by', sortable: true, visible: false, thComp: 'FilterTh', tdClass: 'Attendance' },
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'inqStats'},
                { title: '', tdComp: TopicStatusTags, thClass: 'inqStats', tdClass: 'Inquiry Status'},
//                { title: 'Status', field: 'display_topic_status', sortable: true, thComp: 'FilterTh', tdClass: 'Status'},
                { title: 'Actions', tdComp: StudentBatchListingActions, visible: true, thClass: 'text-right', tdClass: '', tdStyle:'text-align: right'}
            ],
            query: { sort: 'display_session_date', order: 'asc' },
            xprops: {
                module: 'StudentBatchListingIndex',
                route: 'student_batch_listing',
                permission_prefix: 'student_batch_listing_'
            }
        }
    },
    async created() {
        this.$root.relationships = this.relationships
        await this.fetchData()
        if(this.studentAll.length == 1) {
            this.updateStudent(this.studentAll[0])            
        }
        let query = this.$route.query
        let self =this
        if(query){
          if(this.studentAll.length){
            this.studentAll.forEach(async function(val){
              if(val.id == query.student_id){
                await self.updateStudent(val)
                if(self.courseAll.length){
                  self.courseAll.forEach(function(cval){
                    if(cval.id == query.course_id){
                      self.setCourse(cval)
                      self.updateCourse(cval)
                    }
                  })
                }
                return
              }
            })
          }
        }
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('StudentBatchListingIndex', ['item','data', 'total', 'loading', 'relationships', 'studentAll','courseAll','listingAll']),
        totalPresents() {
            var count = 0;
            for (var i = 0; i < this.listingAll.length; i++) {
                   if(this.listingAll[i].attendance == 'Present') {
                        count++;
                   }
               }
            return count;
        },
        totalAbsents() {
            var count = 0;
            for (var i = 0; i < this.listingAll.length; i++) {
                   if(this.listingAll[i].attendance == 'Absent') {
                        count++;
                   }
               }
            return count;
        }, 
        totalPending() {
            var count = 0;
            for (var i = 0; i < this.listingAll.length; i++) {
                   if(this.listingAll[i].attendance == '') {
                        count++;
                   }
               }
            return count;
        },  
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
    },
    methods: {
        ...mapActions('StudentBatchListingIndex', ['fetchData','setCourse','setStudent','fetchStudentCourseAll','setQuery','resetState','getCountForCalendar']),
        async updateStudent(value){
            this.setCourse(null);
            if(value){
                this.setStudent(value);
              await this.fetchStudentCourseAll(value.id);
            }
            else{
                this.fetchStudentCourseAll(null);
            }
        },
        updateCourse(value){
            this.setCourse(value);
            if(value){
                this.getCountForCalendar();
                this.resetFilters()
                this.query = {}                
            }
        },        
        resetFilters(){
            var filtersField= [];
            filtersField = ['s_no','original_date','original_format_date','attendance','attendance_update_by','schedule_days','batch_name','lab_name','attendance','topic_name','display_topic_status'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.getCountForCalendar()
            
        } 
    }
}
</script>


<style scoped>
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
.inProgressColor {
    background-color: #3c8dbc;
}
.sessionExtendedColor {
    background-color: #f39c12;
}
.completedColor {
    background-color: #00a65a;
}
</style>
 