<template>
	<div>
	    <div v-for="topic in getTopics(row)">
	        <!--{{ getTopics(row)}}-->
            <ViewAssessmentDetailsOfTopicPopup 
                :rows="{topic_title: topic.toolTip, topic_class: 'm-1 label '+ topic.class, topic_text: topic.text, topic_name: topic.text, from_schedule_listing: true, student_id: row.student_id, course_id: row.course_id, topic_id: topic.id}">
            </ViewAssessmentDetailsOfTopicPopup><br>
	    </div>
	    <br> 
	</div> 
</template>
<script>
import ViewAssessmentDetailsOfTopicPopup from '../dtmodules/PopupActions/ViewAssessmentDetailsOfTopicPopup'
export default {
    components:{
      ViewAssessmentDetailsOfTopicPopup
    },        
    props: ['row'],
    data() {
        return {
            topics: [],
        }
    },
    created() {
        console.log('this.row',this.row)
    },
    methods: {
        
        getTopics(row){
          let topics = []
          if(row.topic_status === '' || row.topic_status === null){
            return []
          }
          if(typeof row.topic_status === 'number'){
            row.topic_status = row.topic_status.toString()
          }
          let local_topic_id = row.topic_id.split(',')
          let status = row.topic_status.split(',')
          let statusText = row.topic_name.split('^')
          status.filter( ( value, key ) => {
            let text = '', cls = '', toolTip = ''
            
            if(value == 1){
              cls = 'label-success'
              text = statusText[key]
              toolTip = 'Completed'
            }
            else if(value == 2){
              cls = 'label-warning'
              text = statusText[key]
              toolTip = 'Extended'
            }
            else if(value == 3){
              cls = 'label-danger'
              text = statusText[key]
              toolTip = 'Absent'
            }
            else{
              cls = 'label-primary'
              text = statusText[key]
              toolTip = 'In Progress'
            }
            let topic = { id: local_topic_id[key], text: text, class: cls ,toolTip : toolTip}
            topics.push(topic)
          })
//          console.log(topics)
          return topics
        },
    }
}
</script>


<style scoped>
  .m-1{
      margin: 1px;
  }
</style>

