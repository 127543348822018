<template>
    <div class="btn-group btn-group-xs">
        <router-link
                v-if="$can(xprops.permission_prefix + 'view')"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>
   </div>
</template>


<script>
export default {
    props: ['row', 'xprops'],
}
</script>


<style scoped>

</style>
