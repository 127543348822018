<template>
    <form @submit.stop.prevent="handleSubmit" >
            <div class="container-fluid">  
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <div ref="receipt_date" class="form-group">
                                    <label for="receipt_date">Receipt Date<p class="astrisk">*</p></label>
                                    <date-picker
                                        :value="item.receipt_date"
                                        :config="$root.dpconfigDateCurrentFalse"
                                        name="receipt_date"
                                        v-validate="'required'"
                                        @input="updateReceiptDate"
                                        placeholder="Select Receipt Date"
                                        autocomplete="off"
                                        >
                                    </date-picker>
                                    <span class="error" v-show="errors.has('receipt_date')">
                                        {{ $root.updateErrorMessage('receipt_date',this.errors,'Receipt Date')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <label for="receipt_date">Payment Mode<p class="astrisk">*</p></label>
                                
                                        <v-select
                                            name="payment_mode"
                                            label="name"
                                            :options="paymentModeAll"
                                            v-model= "addPaymentModeParams"
                                        />
                                        <span class="error" v-show="errors.has('select_payment_mode')">
                                        {{ 
                                        $root.updateErrorMessage('select_payment_mode',this.errors)
                                        }}                                    
                                    </span>
                                    
                            </div>
                            <div class="col-md-4 app_payment_btn">
                            
                              <button type="button" class="btn btn-block btn-primary add_course_btn" @click="addPaymentRow()">Add Payment</button>
                                    
                            </div>
                        </div>
                        
                        <!-- ######### Payment Mode - Start ############ --> 
                        <div class="row">
                            <div class="col-md-12 col-xs-12 paymentView course_detail_section" v-if="paymentDetails.length > 0"> 
                                <div class="box box-warning box_body_bg table-responsive">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Payment Mode</th>
                                                <th>Amount</th>
                                                <th>Bank Name</th>
                                                <th>Cheque/Transaction Number</th>
                                                <th>Date</th>
                                                <th style="text-align: right;">Actions</th>
                                            </tr> 
                                            <tr v-for="(row, index) in paymentDetails" :key="row.id">
                                                <td ref="payment_mode">
                                                {{(row && row.payment_mode_detail && row.payment_mode_detail.name)?row.payment_mode_detail.name:''}}                                                   
                                                    
                                                </td>
                                                <td class="relative">
                                                <i  class="fa fa-rupee"></i>&nbsp;
                                                    <input
                                                        type="text"
                                                        maxlength="10"
                                                        class="form-control fieldInput"
                                                        placeholder="Enter Amount"
                                                        name="mode_amount_paid"
                                                        ref="mode_amount_paid"
                                                        oninput="this.value=this.value
                                                                                    .replace(/[^\d.]/g, '')
                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                    .replace(/(\.[\d]{2})./g, '$1');"
                                                        v-model= "paymentInputParams[row.row_number]"
                                                        v-validate="'required'"
                                                        >
                                                </td>
                                                <td>
                                                        <input
                                                        type="text"
                                                        class="form-control"
                                                        name="bank_name"
                                                        ref="bank_name"
                                                        maxlength="50"
                                                        placeholder="Enter Bank Name"
                                                        :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                        v-model= "bankNameParams[row.row_number]"
                                                        v-validate="'required'"
                                                        >
                                                </td>
                                                <td>
                                                        <input
                                                        type="text"
                                                        class="form-control"
                                                        name="instrument_no"
                                                        ref="instrument_no"
                                                        maxlength="50"
                                                        placeholder="Enter Cheque/Transaction Number"
                                                        :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                        v-model= "instrumentNumberParams[row.row_number]"
                                                        v-validate="'required'"
                                                        >
                                                </td>
                                                <td class="relative tabaleDatepicker" ref="instrument_date">
                                                    <date-picker
                                                        v-model= "instrumentDateParams[row.row_number]"
                                                        :config="$root.dpconfigDate" @dp-hide="test"
                                                        name="instrument_date"
                                                        :disabled="paymentModeParams[row.row_number] && (paymentModeParams[row.row_number].payment_mode_detail.value_id == 1 || paymentModeParams[row.row_number].payment_mode_detail.value_id == 5)"
                                                        placeholder="Select Date"
                                                        v-validate="'required'"
                                                        autocomplete="off"
                                                        >
                                                    </date-picker>
                                                </td>
                                                    <td><button v-if="index > 0" type="button" class="btn btn-block btn-danger add_course_btn" @click="removePaymentRow(row.row_number)">Remove</button>
                                                    </td>
                                            </tr>
                                                <tr class="total">
                                                <td align="right"><b>Total</b></td>
                                                <td><i class="fa fa-rupee"></i>&nbsp;{{doTotalPaymentDetail()}}{{totalPaymentDetail}}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>      
                                            <tr class="error_tr" style="border:0px;">
                                                <td class="border-0">
                                                    <span class="error" v-show="errors.has('payment_mode')">
                                                    {{ $root.updateErrorMessage('payment_mode',this.errors,'Payment Mode')}}
                                                    </span>
                                                </td>
                                                <td class="border-0">
                                                    <span class="error" v-show="errors.has('mode_amount_paid')">
                                                    {{ $root.updateErrorMessage('mode_amount_paid',this.errors,'Amount')}}
                                                    </span>
                                                </td>
                                                <td class="border-0">
                                                    <span class="error" v-show="errors.has('bank_name')">
                                                    {{ $root.updateErrorMessage('bank_name',this.errors)}}
                                                </span>                    
                                                </td>
                                                <td class="border-0">
                                                    <span class="error" v-show="errors.has('instrument_no')">
                                                    {{ $root.updateErrorMessage('instrument_no',this.errors,'Cheque/Transaction #')}}
                                                </span>                    
                                                </td>
                                                <td class="border-0">
                                                <span class="error" v-show="errors.has('instrument_date')">
                                                    {{ $root.updateErrorMessage('instrument_date',this.errors,'Date')}}
                                                </span>
                                                </td>
                                                <td class="border-0">   
                                                </td>
                                            </tr>                         
                                        </thead>
                                    </table>                                   
                                </div>
                            </div> 
                        </div>
                        <!-- ####### Payment Mode - End ############ -->
                        <!-- ######### Fee List - Start ############ --> 
                        <div class="row" v-if="pendingFeeAll.length > 0">
                            <div class="col-md-12 col-xs-12 course_detail_section" > 
                                <div class="box box-warning box_body_bg  table-responsive">
                                    <table class="table table-bordered table-hover">
                                        <thead class="headColor">
                                            <tr>
                                                <th>Course Name</th>
                                                <th>Total Fee</th>
                                                <th>Total Paid</th>
                                            </tr>                               
                                        </thead>
                                        <tbody v-for="(fees, idx) in pendingFeeAll" :key="fees.student_enroll_id">
                                            <tr>
                                                <td>
                                                    <div class="row col-xs-12">
                                                        <div class="col-xs-1">
                                                            <div class="cursor-pointer table-arrow" @click="toogleModelSubTable(fees.student_enroll_id, fees.children)"><i :id="'modelangle_'+fees.student_enroll_id" :class="angle" aria-hidden="true"></i></div>
                                                        </div>
                                                        <div class="col-xs-1">&nbsp;&nbsp;</div>
                                                        <div class="col-xs-10">
                                                            <div>{{fees.course_name}}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><i class="fa fa-rupee"></i>&nbsp; {{fees.TotalFees}}</td>
                                                <td><i class="fa fa-rupee"></i>&nbsp; {{fees.TotalPaid}}</td>
                                            </tr>
                                            <tr class="allhide" :id="'modelshow_'+fees.student_enroll_id" >
                                                <td colspan="3" class="innerTable">
                                                    <table class="table table-bordered table-hover">
                                                        <thead class="headColor">
                                                            <tr>
                                                                <th style="width:20%;">Fee Type</th>
                                                                <th>Total Fee</th>
                                                                <th>Total Paid</th>
                                                                <th>Total Due</th>
                                                                <th style="width:20%;">Amount to Paid</th>
                                                                <th>GST Rate(%)</th>
                                                                <th>GST Amount</th>
                                                                <th>Fee Amount</th>
                                                                
                                                            </tr>                               
                                                        </thead>
                                                        <tbody>
                                                            <tr  v-for="(fee, index) in fees.children" :key="fee.id">
                                                                <td>{{fee.fees_type_name}}
                                                                    <div v-if="fee.coupon_amount && fee.coupon_amount > 0">
                                                                        <span style="font-size:12px;">(Full Amount - Coupon Discount)</span>
                                                                    </div>
                                                                    <div v-if="fee.credit_points && fee.credit_points > 0">
                                                                        <span style="font-size:12px;color:red">(Paid Using Credit)</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <i class="fa fa-rupee"></i>&nbsp; {{fee.total_fees}}<br/>
                                                                    <span style="font-size:12px;" v-if="fee.coupon_amount && fee.coupon_amount > 0">({{fee.main_amount}} - {{fee.coupon_amount}})</span>
                                                                </td>
                                                                <td><i class="fa fa-rupee"></i>&nbsp; {{fee.total_paid}} <br/><span style="font-size:12px;color:red" v-if="fee.credit_points && fee.credit_points > 0">({{fee.credit_points}})</span></td>
                                                                <td><i class="fa fa-rupee"></i>&nbsp; {{fee.total_due}}</td>
                                                                <td class="relative">
                                                                <i  class="fa fa-rupee"></i>&nbsp;
                                                                    <input 
                                                                        type="text"
                                                                        maxlength="10"
                                                                        class="form-control fieldInput"
                                                                        name="fee_paid"
                                                                        ref="fee_paid"
                                                                        oninput="this.value=this.value
                                                                                                    .replace(/[^\d.]/g, '')
                                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                                    .replace(/(\.[\d]{2})./g, '$1');"                  
                                                                        placeholder="Enter Amount to Paid"
                                                                        v-model="inputParams[fees.student_enroll_id+''+index]"
                                                                        @input="calculateAmount(fees.student_enroll_id, index, fee.gst_rate, idx)"
                                                                        >
                                                                </td>
                                                                <td>{{fee.gst_rate}}</td>
                                                                <td><i class="fa fa-rupee"></i>&nbsp; {{(gstParams[fees.student_enroll_id+''+index]) ? gstParams[fees.student_enroll_id+''+index]:'0'}}</td>
                                                                <td><i class="fa fa-rupee"></i>&nbsp; {{(amountParams[fees.student_enroll_id+''+index]) ? amountParams[fees.student_enroll_id+''+index]:'0'}}</td>
                                                                
                                                            </tr>
                                                            <tr class="total">
                                                                <td colspan="4" align="right"><b>Total</b></td>
                                                                <td><i class="fa fa-rupee"></i> {{(totalPayment[fees.student_enroll_id])? totalPayment[fees.student_enroll_id] : '0'}}</td>
                                                                <td></td>
                                                                <td><i class="fa fa-rupee"></i> {{(totalGST[fees.student_enroll_id]) ? totalGST[fees.student_enroll_id] : '0'}}</td>
                                                                <td><i class="fa fa-rupee"></i> {{(totalAmount[fees.student_enroll_id])?totalAmount[fees.student_enroll_id]:'0'}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                        </div>
                        <div v-else>
                            <table class="table table-bordered table-hover">
                                <thead class="headColor">
                                    <tr>
                                        <th>Course Name</th>
                                        <th>Total Fee</th>
                                        <th>Total Paid</th>
                                    </tr>                               
                                </thead>
                                <tbody>
                                    <tr><td colspan="3" class="text-align-center"><div>No course remain for Payment.</div></td></tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- ######### Fee List - End ############ --> 
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <span class="col-xs-12 error" v-show="errors.has('payment_amount_check')">
                                    {{ 
                                    $root.updateErrorMessage('payment_amount_check',this.errors)
                                    }}
                                </span> 
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
            <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
        </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['student_id', 'isAddReceipt', 'paymentShow','isOpen'],    
    data() {
        return {
            disable: [],
            inputParams:[],
            paymentInputParams:[],
            instrumentDateParams:[new Date()],
            instrumentNumberParams:[],
            bankNameParams:[],
            addPaymentModeParams:null,
            // paymentModeParams:[{value_id:1,name:'Cash'}],
            paymentModeParams:[],
            gstParams:[],
            amountParams:[],
            studentSeen:false,
            totalGST:{},
            totalAmount:[],
            totalPayment:{},
            sumTotalAmount:0,
            sumTotalPaid:0,
            sumTotalBalance:0,
            sumTotalDue:0,
            paymentModeAmount:0,
            angle:'fa fa-angle-right',
            courseTotalPaid:[],
        }
    },
    computed: {
         ...mapGetters('ReceiptsSingle', ['item', 'loading','studentAll','pendingFeeAll','paymentModeAll','paymentDetails']),
      
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
          vm.routerOldPath = from.path;
        })
    },          
    created() {
        const current = new Date();
        var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        this.updateReceiptDate(date);
        this.fetchPaymentModeAll();
        this.updateStudent();
        this.addPaymentModeParams = null;
    },
    destroyed() {
        this.resetState()
    },
     methods: {
        ...mapActions('ReceiptsSingle', ['fetchData', 'storeData', 'resetState', 'setStudent', 'setReceiptDate', 'setManualReceiptNo', 'setReceiptAmount','fetchStudentAll','fetchPendingFeeAll','setPaymentMode','setPaymentDetails','setPendingFeeAll', 'setNewPendingFeeAll','fetchPaymentModeAll','setTotalDue']),
        ...mapActions('StudentShowSingle', ['fetchStudentCount']),
        
        closeModel(){
            this.$emit('closeModel');
        },
        toogleModelSubTable(id ,child){
            var className = $('#modelshow_'+id).attr('class');
            //if(child.length != 0){
                if($('#modelshow_'+id).length > 0 && className == "allhide"){
                    $('#modelshow_'+id).removeClass('allhide').addClass('allshow');
                    $('#modelangle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');                    
                }else{
                    $('#modelshow_'+id).removeClass('allshow').addClass('allhide');
                    $('#modelangle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
                }
            //}            
        },
        test(){
            
        },
        updateStudent() {
            this.studentSeen = true;
            // Reset for element - Start
            this.inputParams = [];
            this.paymentInputParams = [];
            this.paymentInputParams[0] = 0;
            this.bankNameParams = [];
            this.instrumentNumberParams = [];
            this.instrumentDateParams = [];
            this.setManualReceiptNo(null);
            // this.paymentModeParams = [{value_id:1,name:'Cash'}];
            this.paymentModeParams = [{value_id:1,row_number:0,payment_mode_detail:{value_id:1,name:'Cash'}}];
            this.totalGST={};
            this.totalAmount=[];
            this.totalPayment={};
            this.sumTotalAmount='0';
            this.sumTotalPaid='0';
            this.sumTotalBalance='0';
            this.sumTotalDue='0';
            this.paymentModeAmount='0';
            this.gstParams=[]
            this.amountParams=[]
            // Reset for element - END
            this.setPaymentDetails(this.paymentModeParams);
            this.fetchPendingFeeAll(this.student_id)
            .then((res) => {
                this.getSumTotalValue()
                })
            this.setStudent(this.student_id)
        },
        getSumTotalValue(){
            var sumTotalAmount = 0;
            var sumTotalPaid = 0;
            var sumTotalBalance = 0;
            var sumTotalDue = 0;
            this.courseTotalPaid = [];
            var cobj = this;
            this.pendingFeeAll.forEach(function(fees){
                sumTotalAmount = sumTotalAmount + parseFloat(fees.totalFee);
                sumTotalPaid = sumTotalPaid + ((fees.totalPaid !=null) ? parseFloat(fees.totalPaid) : 0); 
                var TotalFinal = 0;
                fees.children.forEach(function(fee, inx){
                    fee.total_paid = (fee.total_paid) ? fee.total_paid : 0;
                    TotalFinal = (parseFloat(TotalFinal)+parseFloat(fee.total_paid)).toFixed(0);
                });
                cobj.courseTotalPaid[fees.student_enroll_id] = TotalFinal;
            })
            this.sumTotalAmount = parseFloat(sumTotalAmount).toFixed(0);
            this.sumTotalPaid = parseFloat(sumTotalPaid).toFixed(0);
            this.sumTotalBalance = parseFloat(sumTotalAmount - sumTotalPaid).toFixed(0);
        },
        doTotalPaymentDetail(){            
            var tot = 0;
            this.paymentInputParams.forEach(function(fee){
                if(!fee)
                {
                    fee = 0;
                }
                tot = parseFloat(tot) + parseFloat(fee);
            })
            this.totalPaymentDetail = parseFloat(tot).toFixed(0);
        },
        updateReceiptDate(value) {
            if(value){
                var formatedFromDate = value.split('/');
                var finalDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]  
                this.setReceiptDate(value)
            }
        },
        updateManualReceiptNo(e) {
            this.setManualReceiptNo(e.target.value)
        },
        updateReceiptAmount(e) {
            this.setReceiptAmount(e.target.value)
        },
        updatePaymentMode(index){
            // if(this.paymentModeParams[index] && this.paymentModeParams[index].value_id == 1){
            //     this.disable[index] = true;
            // } else if(this.paymentModeParams[index] && this.paymentModeParams[index].value_id == 2) {
            //     this.disable[index] = false;
            // }
        },        
        addPaymentRow(){
            this.$validator.errors.remove('payment_amount_check');
            if(this.addPaymentModeParams == null)
            {
                this.$validator.errors.add({
                  id: 'select_payment_mode',
                  field: 'select_payment_mode',
                  msg: 'Please Select payment mode.',
                  scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('select_payment_mode');
            }
            var newRow = {'payment_mode_detail':{'value_id':this.addPaymentModeParams.value_id,'name':this.addPaymentModeParams.name,'paymentModeValueId':this.addPaymentModeParams.value_id},'row_number':this.paymentDetails[this.paymentDetails.length - 1].row_number + 1}
            var paymentDetailsLocal = purify(this.paymentDetails);
            paymentDetailsLocal.push(newRow);
            if(this.addPaymentModeParams != null){
                    if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 1){
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = true;
                    } else// if(this.addPaymentModeParams && this.addPaymentModeParams.value_id == 2) 
                    {
                        this.disable[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = false;
                    }
                this.paymentModeParams[this.paymentDetails[this.paymentDetails.length - 1].row_number + 1] = newRow;
            }
            this.setPaymentDetails(paymentDetailsLocal);
            this.addPaymentModeParams = null;
        },

        removePaymentRow(index){
            // var paymentDetailsLocal = purify(this.paymentDetails);
            // paymentDetailsLocal.splice(index,1);
            // this.setPaymentDetails(paymentDetailsLocal);
            // this.paymentInputParams.splice(index,1);
            var paymentDetailsLocal = purify(this.paymentDetails);
            this.paymentDetails.forEach(function(selectedPaymentList,addedIndex){               
                if (selectedPaymentList && selectedPaymentList.row_number == index){
                    delete this.paymentModeParams[index];
                    delete this.paymentInputParams[index];
                    delete this.instrumentDateParams[index];
                    delete this.instrumentNumberParams[index];
                    delete this.bankNameParams[index];
                    paymentDetailsLocal.splice(addedIndex, 1);                       
                    return false;   
                }               
            }.bind(this))   
            this.setPaymentDetails(paymentDetailsLocal);    
        },
        totalDueAmount(totalFees,totalPaid){
            var dueAmount = parseFloat(parseFloat(totalFees)-parseFloat(totalPaid));
            if(dueAmount == 'NaN' || dueAmount == NaN){ dueAmount = 0; }
            return dueAmount.toFixed(0);
        },
        calculateAmount(student_enroll_id, index, gst_rate, idx)
        {
            var inputFees = [];
            var localInputParams = (this.inputParams[student_enroll_id+''+index]) ? this.inputParams[student_enroll_id+''+index] : 0;
            inputFees = this.inputParams;
            if(!(inputFees[student_enroll_id+''+index] > 0))
            {
                inputFees[student_enroll_id+''+index] = 0;   
            }
            this.amountParams[student_enroll_id+''+index] = 0;
            this.gstParams[student_enroll_id+''+index] = 0;
            this.inputParams[student_enroll_id+''+index] = parseInt(this.inputParams[student_enroll_id+''+index]);
            var balance = {};
            this.pendingFeeAll.forEach(function(fees,i){
                var balanceTotal = 0;  
                fees.children.forEach(function(fee,x){
                    if(fees.student_enroll_id+''+x == student_enroll_id+''+index){
                        if(fee.total_paid == null){ fee.total_paid = 0 }
                        balanceTotal = parseFloat(parseFloat(fee.total_fees) - parseFloat(fee.total_paid)).toFixed(0)
                    }
                })  
                 if(balanceTotal != 0){ balance[student_enroll_id+''+index] = balanceTotal; }
            }.bind(this))
            if(balance[student_enroll_id+''+index] == undefined){
                balance[student_enroll_id+''+index] = 0;
            }
            if(parseFloat(inputFees[student_enroll_id+''+index]) > balance[student_enroll_id+''+index])
            {
                inputFees[student_enroll_id+''+index] = balance[student_enroll_id+''+index];
                localInputParams = balance[student_enroll_id+''+index];
            }
            if(gst_rate > 0)
            {
                gst_rate = parseFloat(gst_rate);
                this.amountParams[student_enroll_id+''+index] = (parseFloat(inputFees[student_enroll_id+''+index])*100/(100+gst_rate)).toFixed(0);
                this.gstParams[student_enroll_id+''+index] = (parseFloat(inputFees[student_enroll_id+''+index])-parseFloat(this.amountParams[student_enroll_id+''+index])).toFixed(0);
            }
            else{
                this.amountParams[student_enroll_id+''+index] = parseFloat(inputFees[student_enroll_id+''+index]).toFixed(0);
                this.gstParams[student_enroll_id+''+index] = (0).toFixed(0);                
            }        
            this.totalPayment[student_enroll_id] = 0;  
            var totalPayment = {};  
            var totalGST = {};
            var totalAmt = {};  
            var gstDetails = this.gstParams;
            var amountParams = this.amountParams;
            this.pendingFeeAll.forEach(function(fees,inx){     
                var total = 0;  
                var tgst = 0;
                var totalAmount = 0;
                fees.children.forEach(function(fee,x){
                    if(inputFees[fees.student_enroll_id+''+x] != undefined){
                        total  = (parseFloat(total)+parseFloat(inputFees[fees.student_enroll_id+''+x])).toFixed(0);                        
                    }
                    if(gstDetails[fees.student_enroll_id+''+x] != undefined){
                        tgst = (parseFloat(tgst) + parseFloat(gstDetails[fees.student_enroll_id+''+x])).toFixed(0);
                    }  
                    if(amountParams[fees.student_enroll_id+''+x] != undefined){
                        totalAmount = (parseFloat(totalAmount) + parseFloat(amountParams[fees.student_enroll_id+''+x])).toFixed(0);
                    }                    
                });
                if(total != 0){ totalPayment[fees.student_enroll_id] = total }
                if(tgst != 0){ totalGST[fees.student_enroll_id] = tgst }
                if(totalAmount != 0){ totalAmt[fees.student_enroll_id] = totalAmount }
            });            
            if(totalPayment[student_enroll_id] == undefined){ totalPayment[student_enroll_id] = 0}
            this.totalPayment[student_enroll_id] = totalPayment[student_enroll_id]; 
            this.totalGST[student_enroll_id] = totalGST[student_enroll_id]
            this.totalAmount[student_enroll_id]= totalAmt[student_enroll_id]
            this.inputParams = inputFees; 
            this.inputParams[student_enroll_id+''+index] = localInputParams; 
        },
        preparePayment(){
            // Calculate all payment value for submit
            var newFeesLocal = this.inputParams;
            var ths = this;
            var pendingFeeAllLocal = {};                
            this.pendingFeeAll.forEach(function(fees, index){
                var submitFee = [];
                fees.children.forEach(function(fee, i){
                    var localIndex = fees.student_enroll_id+''+i;
                    if(newFeesLocal[localIndex] === undefined) {
                    } else {
                        var obj = {
                            'student_enroll_id': fees.student_enroll_id,
                            'student_course_id': fee.student_course_id,
                            'student_id': fee.student_id,
                            'course_id': fee.course_id,
                            'total_fees':fee.fees_amount,
                            'amount_to_paid': newFeesLocal[localIndex],
                            'gst_amount': ths.gstParams[localIndex],
                            'fees_type': fee.fees_type,
                        } 
                        submitFee.push(obj);
                    }
                });
                pendingFeeAllLocal[index] = submitFee;
                pendingFeeAllLocal[index]['total_amount_to_paid'] = ths.totalPayment;
                pendingFeeAllLocal[index]['course_id'] = fees.course_id;
                pendingFeeAllLocal[index]['student_id'] = fees.student_id;
            }); 
            return pendingFeeAllLocal;
            //this.setNewPendingFeeAll(pendingFeeAllLocal);            
        },
        preparePaymentMode(){
            // Calculate all payment mode for submit
            var paymentDetailsLocal =  purify(this.paymentDetails);
            var paymentMode = [];
            this.paymentModeAmount = 0;
            this.paymentInputParams.forEach(function(amt, index){
                paymentMode[index] = {};
                paymentMode[index].amount = parseFloat(amt);
                this.paymentModeAmount += parseFloat(amt);
                paymentMode[index].value_id = this.paymentModeParams[index].value_id;
                if(this.paymentModeParams != null && typeof this.paymentModeParams[index] == 'object')
                {
                    paymentMode[index].payment_mode = this.paymentModeParams[index].payment_mode_detail.value_id;    
                }
                else{
                    paymentMode[index].payment_mode = null;       
                }
                if(this.instrumentNumberParams != null && typeof this.instrumentNumberParams[index] === 'string')
                {
                    paymentMode[index].instrument_no = this.instrumentNumberParams[index];
                }
                else{
                    paymentMode[index].instrument_no = null;       
                }
                if(this.instrumentDateParams != null && typeof this.instrumentDateParams[index] === 'string')
                {
                    paymentMode[index].instrument_date = this.instrumentDateParams[index];
                }
                else{
                    paymentMode[index].instrument_date = null;       
                }
                if(this.bankNameParams != null && typeof this.bankNameParams[index] === 'string')
                {
                    paymentMode[index].bank_name = this.bankNameParams[index];
                }
                else{
                    paymentMode[index].bank_name = null;       
                }
            }.bind(this))            
            paymentDetailsLocal['paymentMode'] = paymentMode;
            this.setPaymentDetails(paymentDetailsLocal); 
        },
        handleSubmit() {
            this.setTotalDue(this.sumTotalBalance)
            var details = [];
            details['submitFee'] = this.preparePayment();
            details['student_id'] = this.student_id;
            this.preparePaymentMode();
            this.$validator.errors.remove('payment_amount_check');
            var finalAmount = Object.values(this.totalPayment).reduce(function(a, b) { return parseInt(a) + parseInt(b); }, 0);
            this.setReceiptAmount(this.paymentModeAmount);
            if(this.paymentDetails.length == 0){
                this.$validator.errors.add({
                id: 'payment_amount_check',
                field: 'payment_amount_check',
                msg: 'Please add payment detail.',
                scope: this.$options.scope,
                });
                return false;   
            }else if(this.paymentModeAmount != finalAmount || this.paymentModeAmount == 0 || this.paymentModeAmount == '')
            {
                this.$validator.errors.add({
                id: 'payment_amount_check',
                field: 'payment_amount_check',
                msg: 'Note: Amount to Paid and Payment Amount should be same.',
                scope: this.$options.scope,
                });
                return false;
            }
            else{
                this.$validator.errors.remove('payment_amount_check');
            }
            this.$validator.validate().then(result => {
            if (result) {
                this.storeData(details)
                    .then(() => {
                        this.$validator.errors.remove();
                        this.$eventHub.$emit('create-success')
                        this.$emit('closeModel');
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }             
            })
        },  
    }
}
</script>


<style scoped>
.innerTable{
    margin: 10px;
    padding-left: 20px;
}
.innerTable table{
    background-color:#888a8d1c;
}
.headColor{
    background:linear-gradient(#f4f5f8,#f1f3f6);
}
.fieldInput{
    display: inline-block;
    max-width: 90%;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
position: initial;
}
.border-0{
    border: 0px;
}
.table-arrow{
    width: 10px;
    background: #888a8d29;
    padding: 0px 12px 0px 6px;
    border-radius: 3px;
}
.table-arrow i{
  margin: -2px;
}
.allhide {
display: none;
}
.allShow {
display: block;
}
td.tabaleDatepicker .bootstrap-datetimepicker-widget{
    z-index: 999999;
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
 
 