<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Incomes</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Incomes List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can('income_create')"
                                        :to="{ name: 'income.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                                      
                            </div>
                        </div>
                        <div class="box-body" >
                          <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <label for="income_type">Income Type</label>
                                  <v-select
                                    name="income_type"
                                    label="name"                                           
                                    :value="item.income_type"
                                    :options="incomeTypeAll"        
                                    @input="updateIncomeType"
                                    />                                
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label for="payment_mode">Payment Mode</label>
                                  <v-select
                                    name="payment_mode"
                                    label="name"                                           
                                    :value="item.payment_mode"
                                    :options="paymentModeAll"        
                                    @input="updatePaymentMode"
                                    />                                
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <label for="from_date">From Date</label>
                                  <date-picker
                                      v-model= "filterData.from_date"
                                      :config="$root.dpconfigDate"
                                      name="from_date"
                                      placeholder="Select From Transaction Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <label for="to_date">To Date</label>
                                  <date-picker
                                      v-model= "filterData.to_date"
                                      :config="$root.dpconfigDate"
                                      name="to_date"
                                      placeholder="Select To Transaction Date"
                                      autocomplete="off"
                                      >
                                  </date-picker>                              
                            </div>
                            <div class="col-md-2 col-sm-12">
                                <button type="button" class="btn btn-success m-t-25" @click="searchData()">Search</button>
                            
                            </div>
                          </div>
                          
                        </div>
                      
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                              <datatable
                                      v-if="!loading"
                                      :columns="columns"
                                      :data="data"
                                      :total="total"
                                      :query="query"
                                      :xprops="xprops"
                                      />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import IncomeActions from '../../dtmodules/DatatableActions/IncomeActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import Amount from '../../dtmodules/Amount'

IncomeActions.attachStore('IncomeIndex');
export default {
    components,
    IncomeActions, 
    data() {
        return {
            columns: [
                { title: 'Income Type', field:'name',  sortable: true, thComp: 'FilterTh', tdClass: 'Income Type' },
                { title: 'Payment Mode', field: 'payment_mode', sortable: true, thComp: 'FilterTh', tdClass: 'Payment mode' },
                { title: 'Bank Name', field: 'bank_name', sortable: true, thComp: 'FilterTh', tdClass: 'Bank name' },
                { title: 'Cheque/Transaction Number', field: 'instrument_no', sortable: true, thComp: 'FilterTh', tdClass: 'Instrument Number' },

                { title: 'Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                // { title: 'Date', field: 'instrument_date', sortable: true, thComp: 'FilterTh', tdClass: 'Transaction Date' },
                
                { title: 'Amount', field: 'amount', sortable: true ,tdComp:Amount, thComp: 'FilterTh', tdClass: 'amount' },
//                { title: 'Remarks', field: 'remarks', sortable: true, thComp: 'FilterTh', tdClass: 'remarks' },
                { title: 'Actions', tdComp: IncomeActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'IncomeIndex',
                route: 'income',
                permission_prefix: 'income_'
            },
            filterData:{
              income_type: null,
              payment_mode: null,
              from_date:null,
              to_date:null,
            },
        }
    },

    created() {
        this.fetchData()
        this.fetchIncomeTypesAll()
        this.fetchPaymentModeAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('IncomeIndex', ['data', 'total', 'loading']),
        ...mapGetters('IncomeSingle', [ 'item', 'incomeTypeAll','paymentModeAll']),

    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        
    },
    methods: {
        ...mapActions('IncomeIndex', ['fetchData', 'setQuery', 'resetState']),
        ...mapActions('IncomeSingle', ['fetchIncomeTypesAll', 'fetchPaymentModeAll', 'setPaymentMode', 'setIncomeType']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['name','branch_name','payment_mode','bank_name','instrument_no','original_date','original_format_date','amount','remarks'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.updateIncomeType(null)
            this.updatePaymentMode(null)
            this.filterData.from_date = null;
            this.filterData.to_date = null;
            this.fetchData()
            
        },
        updateIncomeType(val){
          this.filterData.income_type = null
          this.setIncomeType(val)
          if(val){
            this.filterData.income_type = val.value_id
          }
        },
        updatePaymentMode(val){
          this.filterData.payment_mode = null
          this.setPaymentMode(val)
          if(val){
            this.filterData.payment_mode = val.value_id
          }
        },
        searchData(){
          if (this.filterData.from_date) {
            this.filterData.from_date = this.filterData.from_date.replace(/\//g, '-');    
          }
          if (this.filterData.to_date) {
            this.filterData.to_date = this.filterData.to_date.replace(/\//g, '-');
          }
          this.fetchData(this.filterData)
        },
    }
}
</script>


<style scoped>

</style>
