<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Branch Admission Fee</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/branch-admission-fee'">Branch Admission Fee</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Branch Admission Fee</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Edit Branch Admission Fee</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form @submit.prevent="submitForm" novalidate>
                                    <div class="box">
                                        <bootstrap-alert />
                                        <div class="box-body">
                                            <div class="col-xs-12 row">
                                                <div class="col-md-6">
                                                    <div ref="branch" class="form-group">
                                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                                        <v-select
                                                                name="branch"
                                                                label="branch_name"
                                                                v-validate="'required'"
                                                                @change="updateBranch"
                                                                :value="item.branch"
                                                                :options="branchMastersAll"
                                                                />
                                                            <span class="error" v-show="errors.has('branch')">
                                                                {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                                                </span>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-md-6">
                                                    <div ref="fee_id" class="form-group">
                                                        <label for="fee_id">Fee Type<p class="astrisk">*</p></label>
                                                        <v-select
                                                                name="fee_id"
                                                                label="name"
                                                                v-validate="'required'"
                                                                @change="updateFees_type"
                                                                :value="item.fee_id"
                                                                :options="feesTypeAll"
                                                                />
                                                        <span class="error" v-show="errors.has('fee_id')">
                                                            {{ $root.updateErrorMessage('fee_id',this.errors,'Fee Type')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 row">
                                                <div class="col-md-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="fee_amount">Fee Amount<p class="astrisk">*</p></label>
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            name="fee_amount"
                                                            ref="fee_amount"
                                                            maxlength="10"
                                                            oninput="this.value=this.value
                                                                                    .replace(/[^\d.]/g, '')
                                                                                    .replace(/(\..*)\./g, '$1')
                                                                                    .replace(/(\.[\d]{2})./g, '$1');"                                              
                                                            v-validate="'required|min_value:0.01'"
                                                            placeholder="Enter Fee Amount"
                                                            :value="item.amount"
                                                            @input="updateFees_amount"
                                                            >
                                                    <span class="error" v-show="errors.has('fee_amount')">
                                                        {{ $root.updateErrorMessage('fee_amount',this.errors, 'Fee Amount')}}
                                                    </span>
                                                </div>
                                                </div>    
                                            </div>                                                          
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save col-md-2 col-xs-2"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                        
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    computed: {
        ...mapGetters('BranchAdmissionFeeSingle', ['item', 'loading', 'feesTypeAll', 'branchName','branchMastersAll']),
      
    },
    created() {
        this.fetchData(this.$route.params.id)

    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
    },
    methods: {
        ...mapActions('BranchAdmissionFeeSingle', ['fetchData', 'updateData', 'resetState', 'fetchBranchName', 'fetchFeesTypeAll', 'setFees_type', 'setFees_amount' ,'setBranch']),
        updateFees_type(value) {
            if(value && value.other_value != null){
                        // this.totalCountGST = (value.other_value)?value.other_value:0;
                        // this.setGst_rate(this.totalCountGST);
                        this.setFees_type(value)
                    }
        },
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },   
        updateBranch(value) {
            this.setBranch(value)
        },         
        submitForm() {
        this.$validator.validate().then(result => {
        if (result) {
            this.updateData()
                .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                            id: 'fee_id',
                            field: 'fee_id',
                            msg: res.data.message,
                            scope: this.$options.scope,
                        });
                        return '';
                    }                    
                    this.$router.push({ name: 'branch_admission_fee.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }             
           })
        }    

    }
}
</script>


<style scoped>

</style>
 