<template>
    <!-- <div class="popup-form add-country"> -->
        <div>
    <b-modal id="studentRemarkModal"
             ref="modal"
             title="Change student course status"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
             <div class="col-md-12 col-xs-12 text-align-end">
                <button class="btn btn-primary" title="Download Adminssion Form" @click="openAdmissionForm"><i class="fa fa-download"></i></button>
            </div>
            <form @submit.stop.prevent="submitForm" >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12 text-align-end">
                                    <!-- <button class="btn btn-primary" @click="openAdmissionForm"><i class="fa fa-download"></i></button> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div ref="remark" class="form-group">
                                        <label for="remark">
                                            Remark
                                            <p class="astrisk">*</p>
                                        </label>
                                        <input
                                            autocomplete="off"
                                            type="text"
                                            class="form-control"
                                            name="remark"
                                            ref="remark"
                                            v-validate="'required'"
                                            maxlength="150"
                                            placeholder="Enter Remark"
                                            v-model="remark"
                                        >
                                        <span class="error" v-show="errors.has('remark')">
                                            {{ $root.updateErrorMessage('remark',this.errors,'Remark')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div ref="image_file" class="form-group">
                                        <label for="image_file">
                                            Admission Form With Signature
                                            <p class="astrisk">*</p>
                                        </label>
                                        <input
                                            type="file"
                                            class="form-control"
                                            name="image_file"
                                            ref="image_file"
                                            v-validate="'required|size:2048|ext:jpg,png,jpeg,pdf'"
                                            @change="updateDocument"
                                        >
                                        <span style="font-size: 13px;"><b>Note : </b> JPG, JPEG, PNG, PDF files are allowed. File size should be less than or equals to 2 MB.</span><br/>
                                        <span class="error" v-if="fileError != ''">
                                            {{fileError}}
                                        </span>
                                        <span class="error" v-show="errors.has('image_file')">
                                            {{ $root.updateErrorMessage('image_file',this.errors,'File')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="modal-footer modal-footer1">
                    <button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button>
                    <button type="submit" :disabled="loading" class="btn btn-success">Save</button>
                </footer>
            </form>
    </b-modal>
    <!-- </div>
    <div> -->
    <admissionForm v-show="false" v-if="is_download" @changeFlag="changeFlag" :show_sign="true" :enroll_id="id"></admissionForm>
    </div>
  <!-- </div> -->
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import admissionForm from '@/views/layout/AdmissionFormPDF.vue'
export default {
    props:['id'],
    components:{
        admissionForm
        // StudentEditCourse
    },
    data() {
           return {
                is_download:false,
                remark: '',
                document:{},
                fileError:'',
           }
    },
    computed: {
        ...mapGetters('StudentShowSingle', ['loading']),
    },
    methods: {
        ...mapActions('StudentShowSingle', ['changeStatus', 'setAdmissionImage']),
        openAdmissionForm() {
            this.is_download = true;
            this.$refs.admissionForm.generatePDF();
        },
        changeFlag() {
            this.is_download = false;
        },
        updateDocument(e) {
            this.fileError = '';
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                if(e.target.files[0].size <= 2000000 && e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'application/pdf'){
                    reader.onload = function (e) {
                        $('#blah')
                            .attr('src', e.target.result)
                            .width(150)
                            .height(200);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    this.setAdmissionImage(e.target.files[0]);
                    this.document = e.target.files[0];
                    this.$forceUpdate();
                }else{
                    var message = "";
                    if(e.target.files[0].size > 2000000 && (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' | e.target.files[0].type == 'application/pdf')){
                        message = 'File size should be less then 2MB.'
                    }
                    else {
                        this.fileError = 'Only JPG, JPEG, PNG, PDF files are allowed.'
                        message = 'Only JPG, JPEG, PNG, PDF files are allowed'
                    }
                     this.$swal({
                        title: message,
                        type: 'error',                      
                        focusCancel: true,                       
                    }).then(result => {
                        if (typeof result.dismiss === "undefined") {
                            $("#image_file").val('');
                            this.$validator.errors.remove('image_file');
                            this.setAdmissionImage('')
                            this.document = '';
                        }
                    })
                }
            }           
        },    
        handleCancel(evt){
            this.setAdmissionImage('')
        }, 
        closeModel(){
            this.setAdmissionImage('')
            this.remark='';
            this.fileError='';
            this.$refs.modal.hide();
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if (result) {
                    let data = {
                        'student_enroll_id':this.id,
                        'remark':this.remark,
                    }
                    this.changeStatus(data)
                    .then(response => {
                            this.closeModel();
                            this.$emit('loadItems');
                            this.$eventHub.$emit('enroll-status-update')
                    });
                } else {
                    return this.$root.handleValidationFocus(this.errors, this.$refs);
                }
            });
        },
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.errors.clear()
            this.is_download=false;
            this.fileError = '';
            // this.licenseName = '';
            // this.listOption = 1;
            // this.start_date = '';
            // this.end_date = '';
            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>


<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
.vue-html2pdf .pdf-preview[data-v-1fd3ad26] {
   width : 100% !important;
   height : 100% !important;
   top : 0px !important;
}
</style>
