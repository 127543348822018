<template>
<div v-if="row.profile != null">
	<img :src="row.profile" height="100px" width="100px" class="img-circle" :alt="row.employee_name" style="width:50px;height:50px">	
</div>
<div v-else>
	<img src="/assets/img/male.jpg" class="img-circle" :alt="row.employee_name" height="100px" width="100px"  style="width:50px;height:50px">
</div>
    <!-- <div v-html="'<img src="http://127.0.0.1:8000/public/settingImage/'+row.logo+'">'"></div> -->
</template>


<script>
    export default {
        props: ['row'],
    }
</script>
