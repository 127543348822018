<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Fee Adjustment</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Fee Adjustment</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Student Name</th>
                                            <td>
                                                <span v-if="item.student_name !== null">
                                                    {{ item.student_name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Fee Amount</th>
                                            <td> <i class="fa fa-rupee"></i> {{ item.fees_amount }}</td>
                                            </tr>
                                      
                                   <!--      <tr>
                                            <th>GST Rate(%)</th>
                                            <td>{{ item.gst_rate }}</td>
                                        </tr> -->
                                        
                                        </tbody>
                                    </table>
                                </div>   
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                      <!--   <tr>                                                                        
                                            <th>Total Fee</th>
                                            <td>{{ item.total_fees }}</td>
                                            </tr> -->
                                             <tr>
                                            <th>Fee Type</th>
                                            <td>
                                                <span v-if="item.fees_type !== null">
                                                    {{ item.fees_type.name }}
                                                </span>
                                            </td>
                                        </tr>
                                       <!--  <tr>
                                            <th>Fee Discount</th>
                                            <td>{{ item.discount }}</td>
                                        </tr> -->
                                       
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('FeeAdjustmentSingle', ['item'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('FeeAdjustmentSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
