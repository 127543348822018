<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Cancel Admission</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Edit Cancelled Admission</h3>
                        </div>
                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>                        
                        <div class="box-body">
                            SEND SMS
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {

        }
    },
    created() {

    },
    destroyed() {

    },
    computed: {
        ...mapGetters('cancelAdmissionSingle', ['item']),
    },
    methods: {
        ...mapActions('cancelAdmissionSingle', ['fetchData']),
    }
}
</script>


<style scoped>

</style>
 