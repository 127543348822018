<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Admission Digest Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Admission Digest Report</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>
                               <div class="col-md-6 col-xs-12">
                                     <date-r-picker @updateRange="updateRange"></date-r-picker>
                                </div> 
                                     
                                <div class="clearfix"></div>
                                                                                   
                            </div>              
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner 
                            class="std_information_save btn btn-block btn-success new_branch_save"
                            :isLoading="loading"
                            :disabled="loading">                            
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br>
                 
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Pending Inquiries</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetPendingQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data('Pending')"
                                    :total="total('Pending')"
                                    :query="PendingQuery"
                                    :xprops="setXprops('pending')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Enrolled Inquiries</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetEnrolledQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data('Enrolled')"
                                    :total="total('Enrolled')"
                                    :query="EnrolledQuery"
                                    :xprops="setXprops('Enrolled')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Followup Inquiries</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetFollowupQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!loading"
                                    :columns="pastInqColumns"
                                    :data="followupInquiryData"
                                    :total="followupInquiryTotal"
                                    :query="FollowupQuery"
                                    :xprops="setXprops('Followup')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Closed Inquiries</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetClosedQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data('Closed')"
                                    :total="total('Closed')"
                                    :query="ClosedQuery"
                                    :xprops="setXprops('Closed')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Past Followup Inquiries</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetPastQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!loading"
                                    :columns="pastInqColumns"
                                    :data="pastInquiryData"
                                    :total="pastInquiryTotal"
                                    :query="PastQuery"
                                    :xprops="setXprops('Past')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">New Admissions</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetAdmissionQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="admissionLoading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!admissionLoading"
                                    :columns="admissionColumns"
                                    :data="admissionData"
                                    :total="admissionTotal"
                                    :query="AdmissionQuery"
                                    :xprops="setXprops('Admission')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">New Course Enrolled</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetCourseEnrollQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="courseEnrollLoading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!courseEnrollLoading"
                                    :columns="courseEnrollColumns"
                                    :data="courseEnrollData"
                                    :total="courseEnrollTotal"
                                    :query="CourseEnrollQuery"
                                    :xprops="setXprops('Enrolled')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Student Course Completed</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetCourseCompleteQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="courseCompleteLoading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!courseCompleteLoading"
                                    :columns="courseCompleteColumns"
                                    :data="courseCompleteData"
                                    :total="courseCompleteTotal"
                                    :query="CourseCompleteQuery"
                                    :xprops="setXprops('Completed')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Fee Collection</h3>
                            <div class="box-tools pull-right">
                                <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                    <i class="fa fa-minus"></i>
                                </button> 
                            </div>
                        </div>                      
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="resetFeeCollectionQueryFilter">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="feeCollectionLoading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <datatable
                                    v-if="!feeCollectionLoading"
                                    :columns="feeCollectionColumns"
                                    :data="feeCollectionData"
                                    :total="feeCollectionTotal"
                                    :query="FeeCollectionQuery"
                                    :xprops="setXprops('Collection')"
                                    />                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>                               
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AdmissionDigestReportActions from '../../dtmodules/DatatableActions/AdmissionDigestReportActions'
import InquiryTypeAction from '../../dtmodules/InquiryTypeAction'

import components from '../../comps/'
import DateRangePicker from 'vue2-daterange-picker'
import '../../comps/vue-daterange-picker.min.css'

components['date-range-picker'] = DateRangePicker;

AdmissionDigestReportActions.attachStore('AdmissionDigestReportIndex');
import StudentName from '../../dtmodules/StudentName'
import BalanceReportAmount from '../../dtmodules/BalanceReportAmount'

export default {
    components,
    InquiryTypeAction,
    AdmissionDigestReportActions,
    
    data() {
        return {
            exitDateOptionFormate:{format:window.date_format_moment},           
            exitDateOptionFormateFirst:{format:window.date_format_moment},  
            dataSeen: false,
            columns: [
                { title: 'Inquiry Type', field: 'inquiry_type', tdComp: InquiryTypeAction, sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Type' },

                { title: 'Inquiry Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},
                // { title: 'Inquiry Date', field: 'inquiry_date', sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Date' },
                { title: 'Student Name', field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Contact Name', field: 'contact_name', sortable: true, thComp: 'FilterTh', tdClass: 'Father Name' },
                { title: 'Contact Mobile', field: 'contact_number', sortable: true, thComp: 'FilterTh', tdClass: 'Father Mobile' },
            ],
            pastInqColumns: [
                { title: 'Inquiry Type', field: 'inquiry_type', tdComp: InquiryTypeAction, sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Type' },

                { title: 'Inquiry Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                         
                // { title: 'Inquiry Date', field: 'inquiry_date', sortable: true, thComp: 'FilterTh',tdClass: 'Inquiry Date' },
                { title: 'Student Name', field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Next Followup', field: 'next_followup_date', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Contact Name', field: 'contact_name', sortable: true, thComp: 'FilterTh', tdClass: 'Father Name' },
                { title: 'Contact Mobile', field: 'contact_number', sortable: true, thComp: 'FilterTh', tdClass: 'Father Mobile' },
            ],            
            admissionColumns: [
                { title: 'Student Name', tdComp: StudentName, field: 'student_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Student Name' },

                { title: 'Admission Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                         
                // { title: 'Admission Date', field: 'enroll_date', sortable: true, thComp: 'FilterTh',tdClass: 'Admission Date' },
                { title: 'Gender', field: 'gender', sortable: true, thComp: 'FilterTh', tdClass: 'Gender' },
            ],
            courseEnrollColumns: [
                { title: 'Course Enrolled Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                          
                // { title: 'Course Enrolled Date', field: 'my_enroll_date',  sortable: true, thComp: 'FilterTh',tdClass: 'Course Enrolled Date' },
                { title: 'Course Name', field: 'display_course_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Course Name' },
                { title: 'Student Name', tdComp: StudentName, field: 'student_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Student Name' },
                { title: 'Gender', field: 'gender', sortable: true, thComp: 'FilterTh', tdClass: 'Gender' },
                { title: 'Total Fees', tdComp:BalanceReportAmount, field: 'TotalFees', sortable: true, thComp: 'FilterTh',tdClass: 'Total Fees' },
            ],
            courseCompleteColumns: [
                { title: 'Course Completed Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                      
                // { title: 'Course Completed Date', field: 'course_completed_date',  sortable: true, thComp: 'FilterTh',tdClass: 'Course Completed Date' },
                { title: 'Course Name', field: 'display_course_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Course Name' },
                { title: 'Student Name', tdComp: StudentName, field: 'student_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Student Name' },
                { title: 'Gender', field: 'gender', sortable: true, thComp: 'FilterTh', tdClass: 'Gender' },
            ],
            feeCollectionColumns: [
                { title: 'Receipt Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},                                  
                // { title: 'Receipt Date', field: 'receipt_date',  sortable: true, thComp: 'FilterTh',tdClass: 'Receipt Date' },
                { title: 'Student Name', tdComp: StudentName, field: 'student_name',  sortable: true, thComp: 'FilterTh',tdClass: 'Student Name' },
                { title: 'Manual Receipt Number', field: 'manual_receipt_no', sortable: true, thComp: 'FilterTh', tdClass: 'Manual Receipt Number' },
                { title: 'Receipt Type', field: 'receipt_type', sortable: true, thComp: 'FilterTh', tdClass: 'Receipt Type' },
                { title: 'Total Amount', field: 'receipt_amount', sortable: true, thComp: 'FilterTh', tdClass: 'Total Amount fa fa-rupee' },
            ],
                       
            EnrolledQuery: {  },
            PendingQuery: {  },
            FollowupQuery: {  },
            ClosedQuery: {  },
            PastQuery: {  },
            AdmissionQuery: {  },
            CourseEnrollQuery: {  },
            CourseCompleteQuery: {  },
            FeeCollectionQuery: {  },
            xprops: {
                module: 'AdmissionDigestReportIndex',
                route: 'admission_digest_report',
                permission_prefix: 'admission_digest_report_',
            },
            dateRange: { // used for v-model prop
                startDate: moment(),
                endDate: moment(),
            },
            inquiryFiltersField: ['inquiry_type','original_date','original_format_date','student_name','contact_name','contact_number'],
            pastInquiryFiltersField: ['inquiry_type','original_date','original_format_date','student_name','next_followup_date','contact_name','contact_number'],
        }
    },
    beforeCreate(){
//      window.Vue.component('date-range-picker', DateRangePicker)
    },
    created() {
        this.fetchBranchMasterAll().then(()=>{});
        this.updateRange(this.dateRange)
    },   
    watch: {
        EnrolledQuery: {
            handler(query) {
//                console.log(query)
                query.currentStatus = 'Enrolled'
                this.setQuery(query)
            },
            deep: true
        },
        PendingQuery: {
            handler(query) {
                query.currentStatus = 'Pending'
                this.setQuery(query)
            },
            deep: true
        },
        FollowupQuery: {
            handler(query) {
                this.setFollowupQuery(query)
            },
            deep: true
        },
        ClosedQuery: {
            handler(query) {
                query.currentStatus = 'Closed'
                this.setQuery(query)
            },
            deep: true
        },
        PastQuery: {
            handler(query) {
                this.setPastQuery(query)
            },
            deep: true
        },     
        AdmissionQuery: {
            handler(query) {
                this.setAdmissionQuery(query)
            },
            deep: true
        },
        CourseEnrollQuery: {
            handler(query) {
                this.setCourseEnrollQuery(query)
            },
            deep: true
        },
        CourseCompleteQuery: {
            handler(query) {
                this.setCourseCompleteQuery(query)
            },
            deep: true
        },
        FeeCollectionQuery: {
            handler(query) {
                this.setFeeCollectionQuery(query)
            },
            deep: true
        },
        data: function(val){},
        
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('AdmissionDigestReportIndex', ['item','data', 'total','admissionData','courseEnrollData','courseCompleteData','feeCollectionData','admissionTotal',
          ,'courseEnrollTotal','courseCompleteTotal','feeCollectionTotal','courseCompleteDataAll','feeCollectionDataAll','courseCompleteLoading','feeCollectionLoading'
          ,'admissionDataAll','courseEnrollDataAll','totalSummary', 'loading','admissionLoading','courseEnrollLoading','branchMastersAll'
          ,'reportsDataAll','categoriesAll','itemsAll','pastInquiryTotal','pastInquiryData','followupInquiryData','followupInquiryTotal']),
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                this.branchMastersAll.unshift({
                    'branch_name': 'All',
                    'id': '0'
                });
            }
            return this.branchMastersAll;
        },
         
    },
    methods: {
        ...mapActions('AdmissionDigestReportIndex', ['resetState','fetchBranchMasterAll','getReportsData','setBranch','setQuery',
            'setFromDate','setPastQuery','setExit_date','setReportType','fetchReportData','fetchPastInquiryData','fetchFollowupInqData','setAdmissionQuery','courseEnrollQuery','courseCompleteQuery','feeCollectionQuery','fetchAdmissionData'
                ,'setCourseEnrollQuery', 'setCourseCompleteQuery','setFeeCollectionQuery','fetchCourseEnrollData','fetchCourseCompleteData','fetchFeeCollectionData','setFollowupQuery']),
        setXprops(value){
          const props = Object.assign({}, this.xprops); 
          props.table_id = value
          return props
        },
        updateBranch(value) {
            this.setBranch(value)
            this.dataSeen = false;
        },
        updateRange(e) {
            this.dataSeen = false;
            this.updateFromDate(e.startDate)
            this.updateExit_date(e.endDate)
        },  
        updateFromDate(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setFromDate(date)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setExit_date(date)
            this.dataSeen = false;
        },             
        resetFilters(){
            this.resetEnrolledQueryFilter()
            this.resetPendingQueryFilter()
            this.resetFollowupQueryFilter()
            this.resetClosedQueryFilter()
            this.resetPastQueryFilter()
            this.resetAdmissionQueryFilter()
            this.resetCourseEnrollQueryFilter()
            this.resetCourseCompleteQueryFilter()
            this.resetFeeCollectionQueryFilter()
        },       
        resetEnrolledQueryFilter(){
          this.$root.resetSearchFilters(Vue.delete,this.EnrolledQuery,this.inquiryFiltersField,'Enrolled');
        },
        resetPendingQueryFilter(){
          this.$root.resetSearchFilters(Vue.delete,this.PendingQuery,this.inquiryFiltersField,'pending');
        },
        resetFollowupQueryFilter(){
          this.$root.resetSearchFilters(Vue.delete,this.FollowupQuery,this.pastInquiryFiltersField, 'Followup');
        },
        resetClosedQueryFilter(){
          this.$root.resetSearchFilters(Vue.delete,this.ClosedQuery,this.inquiryFiltersField, 'Closed');
        },
        resetPastQueryFilter(){
          this.$root.resetSearchFilters(Vue.delete,this.PastQuery,this.pastInquiryFiltersField, 'Past');
        },
        resetAdmissionQueryFilter(){
          let filtersField = ['student_name','enroll_date','gender']
          this.$root.resetSearchFilters(Vue.delete,this.AdmissionQuery,filtersField, 'Admission');
        },
        resetCourseEnrollQueryFilter(){
          let filtersField = ['original_date','display_course_name','student_name','gender','TotalFees']
          this.$root.resetSearchFilters(Vue.delete,this.CourseEnrollQuery,filtersField, 'Enrolled');
        },
        resetCourseCompleteQueryFilter(){
          let filtersField = ['original_date','display_course_name','student_name','gender','TotalFees']
          this.$root.resetSearchFilters(Vue.delete,this.CourseCompleteQuery,filtersField, 'Completed');
        },
        resetFeeCollectionQueryFilter(){
          let filtersField = ['original_date','student_name','manual_receipt_no','receipt_amount','receipt_type']
          this.$root.resetSearchFilters(Vue.delete,this.FeeCollectionQuery,filtersField ,'Collection');
        },
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.resetEnrolledQueryFilter()
                    this.resetPendingQueryFilter()
                    this.resetFollowupQueryFilter()
                    this.resetClosedQueryFilter()
                    this.resetPastQueryFilter()
                    this.resetAdmissionQueryFilter()
                    this.resetCourseEnrollQueryFilter()
                    this.resetCourseCompleteQueryFilter()
                    this.resetFeeCollectionQueryFilter()  
                    this.EnrolledQuery = {}
                    this.PendingQuery = {}
                    this.FollowupQuery = {}
                    this.ClosedQuery = {}
                    this.PastQuery = {}
                    this.AdmissionQuery = {}
                    this.CourseEnrollQuery = {}
                    this.CourseCompleteQuery = {}
                    this.FeeCollectionQuery = {}                    
                    this.fetchReportData()
                    .then(() => {
                        this.resetFilters()
                    })                     
                    .catch((error) => {
                        console.error(error)
                    })
                    this.fetchFollowupInqData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })                    
                    this.fetchPastInquiryData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })
                    this.fetchAdmissionData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })
                    this.fetchCourseEnrollData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })
                    this.fetchCourseCompleteData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })
                    this.fetchFeeCollectionData()
                    .then(() => {})                     
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
.mybox {
    border: 1px solid #eee;    
}

</style>
<style>
  .date-range-css .row{
    margin-left: 0px;
  }
  .date-range-css .reportrange-text{
    border-radius: 4px;
  }
</style>
 