<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventView"
                ref="modal"
                title="View Coupon Detail"
                @shown="openModel" 
                size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <table class="table table-bordered table-striped not-responsive-grid">
                                            <tbody>
                                            <tr>
                                                <th class="header-w">Coupon Code</th>
                                                <td>{{ item.coupon_code }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Coupon Amount/Discount</th>
                                                <td>{{ item.coupon_amount }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Expiry Date</th>
                                                <td>{{ item.new_expiry_date }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">First Admission</th>
                                                <td>{{ (item.first_admission > 0) ? 'Yes' : 'No' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Discount Type</th>
                                                <td>{{ (item.discount_type) ? item.discount_type.name : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Minimum Spends</th>
                                                <td>{{ (item.min_spends) ? item.min_spends : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Maximum Spends</th>
                                                <td>{{ (item.max_spends) ? item.max_spends : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Course Vertical</th>
                                                <td>{{ (item.course_vertical) ? item.course_vertical.name : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Course Category</th>
                                                <td>{{ (item.course_category) ? item.course_category.name : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Branch/es</th>
                                                <td class="dis-flex" v-if="item.branch && item.branch.length > 0">
                                                    <div v-for="(row, i) in item.branch" :key="row.id">
                                                        <span v-if="row.branch_name"><span v-if="i>0">,&nbsp;</span>{{row.branch_name}}</span>
                                                    </div>
                                                </td>
                                                <td v-else>-</td>
                                            </tr>
                                            <!-- <tr>
                                                <th class="header-w">Project/s</th>
                                                <td class="dis-flex" v-if="item.project && item.project.length > 0">
                                                    <div v-for="(row, i) in item.project" :key="row.id">
                                                        <span v-if="row.project_name"><span v-if="i>0">,&nbsp;</span>{{row.project_name}}</span>
                                                    </div>
                                                </td>
                                                <td v-else>-</td>
                                            </tr> -->
                                            <tr>
                                                <th class="header-w">Course/s</th>
                                                <td class="dis-flex" v-if="item.course && item.course.length > 0">
                                                    <div v-for="(row, i) in item.course" :key="row.id">
                                                        <span v-if="row.course_name"><span v-if="i>0">,&nbsp;</span>{{row.course_name}}</span>
                                                    </div>
                                                </td>
                                                <td v-else>-</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Parent/s</th>
                                                <td class="dis-flex" v-if="item.parent && item.parent.length > 0">
                                                    <div v-for="(row, i) in item.parent" :key="row.id">
                                                        <span v-if="row.mother_name"><span v-if="i>0">,&nbsp;</span>{{row.mother_name}}</span>
                                                    </div>
                                                </td>
                                                <td v-else>-</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Employee/s</th>
                                                <td class="dis-flex" v-if="item.employee && item.employee.length > 0">
                                                    <div v-for="(row, i) in item.employee" :key="row.id">
                                                        <span v-if="row.employee_name"><span v-if="i>0">,&nbsp;</span>{{row.employee_name}}</span>
                                                    </div>
                                                </td>
                                                <td v-else>-</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Usage limit per coupon</th>
                                                <td>{{ (item.coupon_limit) ? item.coupon_limit : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Usage limit per course</th>
                                                <td>{{ (item.course_limit) ? item.course_limit : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <th class="header-w">Usage limit per user</th>
                                                <td>{{ (item.user_limit) ? item.user_limit : '-' }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props : ['coupon_id'],
    data() {
        return {
            // Code...
        }
    },
    created() {
       // console.log(this.item);
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CouponsSingle', ['item', 'loading'])
    },
    watch: {
        //..
    },
    methods: {
        ...mapActions('CouponsSingle', ['fetchData', 'resetState', 'setItem']),
        openModel () {
            this.setItem([]);
            this.fetchData(this.coupon_id)
        },
    }
}
</script>


<style scoped>
.card {
    min-height: 185px !important;
}
.header-w{
    width: 20%;
}
.dis-flex{
    display: flex;
}
</style>
 