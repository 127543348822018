var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-form add-country"},[_c('b-modal',{ref:"batchHoldHistoryModel",attrs:{"id":"batchHoldHistoryModel","title":"Batch Hold History","okTitle":"Save","okVariant":"success","hide-footer":"","size":"xl","no-close-on-backdrop":true,"no-close-on-esc":true},on:{"shown":_vm.modalOpened}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"table-responsive setting-group"},[_c('vue-good-table',{ref:"table1",attrs:{"styleClass":"vgt-table table table-bordered striped thead-dark","mode":"remote","totalRows":_vm.totalRecords,"sort-options":{
                                        enabled: false,
                                    },"pagination-options":{
                                        enabled: true,
                                        perPage:10,
                                        perPageDropdown: [10, 20, 40, 80, 100],
                                        dropdownAllowAll: false,
                                    },"isLoading":_vm.isLoading,"select-options":{ 
                                        enabled: false,
                                    },"search-options":{
                                        enabled: false,
                                        placeholder: 'search',
                                    },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('HoldHistoryActions',{attrs:{"xprops":_vm.xprops,"row":props.row},on:{"loadItems":_vm.loadItems,"holdHistoryIdModel1":_vm.holdHistoryIdModel1,"modalClose":_vm.modalClose}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[(!_vm.isLoading)?_c('span',[_vm._v(_vm._s('No Records Found.'))]):_vm._e()]),_c('template',{slot:"loadingContent"},[_c('CustomLoader')],1)],2)],1),(_vm.pageName == 'history')?_c('HoldHistoryShow',{attrs:{"history_id":_vm.history_id},on:{"loadItems":_vm.loadItems}}):_vm._e()],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }