function initialState() {
    return {
        item: {
            id: null,
            employee_name: null,
            username: null,
            employee_address: null,
            branch_name: null,
            area: null,
            city: null,
            // country: null,
            country: {'id':99,'country_name':'India'},
            state: null,
            gender: '1',
            birth_date: null,
            mobile_no: null,
            email_id: null,
            highest_education: null,
            employee_type: null,
            // trainer_type: null,
            trainer_type: [],
            shift_start_time: null,
            shift_end_time: null,
            joining_date: null,
            exit_date: null,
            designation: null,
            role: null,
            user_status: 1,
            profile:null,
            course_vertical: [],
            competition_batch:0,

        },
        countrymastersAll: [],
        statemastersAll: [],
        citymastersAll: [],
        trainerTypeAll: [],
        employeeTypeAll: [],
        rolesAll: [],
        courseVerticalAll:[],
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    countrymastersAll: state => state.countrymastersAll,
    statemastersAll: state => state.statemastersAll,
    citymastersAll: state => state.citymastersAll,
    trainerTypeAll: state => state.trainerTypeAll,
    employeeTypeAll: state => state.employeeTypeAll,
    rolesAll: state => state.rolesAll,
    courseVerticalAll:state => state.courseVerticalAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.country)) {
                params.set('country_id', '')
            } else {
                params.set('country_id', state.item.country.id)
            }
            if (_.isEmpty(state.item.course_vertical)) {
                params.set('course_vertical', '')
            }
            if (_.isEmpty(state.item.state)) {
                params.set('state_id', '')
            } else {
                params.set('state_id', state.item.state.id)
            }
            if (_.isEmpty(state.item.city)) {
                params.set('city_id', '')
            } else {
                params.set('city_id', state.item.city.id)
            }
            if (_.isEmpty(state.item.employee_type)) {
                params.set('employee_type_id', '')
            } else {
                params.set('employee_type_id', state.item.employee_type.value_id)
            }
            // if (_.isEmpty(state.item.trainer_type)) {
            //     params.set('trainer_type_id', '')
            // } else {
            //     params.set('trainer_type_id', state.item.trainer_type.value_id)
            // }
            if (_.isEmpty(state.item.trainer_type)) {
                params.delete('trainer_type')
            } else {
                for (let index in state.item.trainer_type) {
                    params.set('trainer_type['+index+']', state.item.trainer_type[index].value_id)
                }
            }
            if (_.isEmpty(state.item.role)) {
                params.set('role_id', '')
            } else {
                params.set('role_id', state.item.role.id)
            }

            axios.post('/api/v1/employee-masters', params)
                .then(response => {
                    //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.country)) {
                params.set('country_id', '')
            } else {
                params.set('country_id', state.item.country.id)
            }
            if (_.isEmpty(state.item.state)) {
                params.set('state_id', '')
            } else {
                params.set('state_id', state.item.state.id)
            }
            if (_.isEmpty(state.item.city)) {
                params.set('city_id', '')
            } else {
                params.set('city_id', state.item.city.id)
            }
            if (_.isEmpty(state.item.employee_type)) {
                params.set('employee_type_id', '')
            } else {
                params.set('employee_type_id', state.item.employee_type.value_id)
            }
            if (_.isEmpty(state.item.trainer_type)) {
                params.delete('trainer_type')
            } else {
                for (let index in state.item.trainer_type) {
                    params.set('trainer_type['+index+']', state.item.trainer_type[index].value_id)
                }
            }
           
            if (_.isEmpty(state.item.role)) {
                params.set('role_id', '')
            } else {
                params.set('role_id', state.item.role.id)
            }

            axios.post('/api/v1/employee-masters/' + state.item.id, params)
                .then(response => {
                    //commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axios.get('/api/v1/employee-masters/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setLoading', false)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading', false)
                    }) 
        dispatch('fetchCountrymastersAll')
        // dispatch('fetchStatemastersAll')
        // dispatch('fetchCitymastersAll')
        dispatch('fetchTrainerTypeAll')
        dispatch('fetchEmployeeTypeAll')
        dispatch('fetchRolesAll')
        dispatch('fetchCourseVertical')
    },
    fetchCountrymastersAll({ commit }) {
        axios.get('/api/v1/countrys')
            .then(response => {
                commit('setCountrymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    fetchStatemastersAll({ commit }) {
        axios.get('/api/v1/states/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setStatemastersAll', response.data.data)
                }
                else{
                    commit('setStatemastersAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCitymastersAll({ commit }) {
        axios.get('/api/v1/cities/',{
            params: {
                isDropdown: 1
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setCitymastersAll', response.data.data)
                }
                else{
                    commit('setCitymastersAll', [])
                }
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchTrainerTypeAll({ commit }) {
        axios.get('/api/v1/trainerTypeList')
            .then(response => {
                commit('setTrainerTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchEmployeeTypeAll({ commit }) {
        axios.get('/api/v1/employeeTypeList')
            .then(response => {
                commit('setEmployeeTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchRolesAll({ commit }) {
        axios.get('/api/v1/employeeRoleList')
            .then(response => {
                commit('setRolesAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCountryStates({ commit }, country_id) {
        axios.get('/api/v1/countryStatesList/'+country_id)
            .then(response => {
                commit('setStatemastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchStatesCities({ commit }, state_id) {
        axios.get('/api/v1/stateCitiesList/'+state_id)
            .then(response => {
                commit('setCitymastersAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchCourseVertical({ commit }) {
        axios.get('/api/v1/getCommonMasterType/course vertical')
            .then(response => {
                //console.log(response.data.data);
                commit('setCourseVerticalAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })     
    },
    setEmployee_name({ commit }, value) {
        commit('setEmployee_name', value)
    },
    setUsername({ commit }, value) {
        commit('setUsername', value)
    },
    setEmployee_address({ commit }, value) {
        commit('setEmployee_address', value)
    },
    setArea({ commit }, value) {
        commit('setArea', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setState({ commit }, value) {
        commit('setState', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setGender({ commit }, value) {
        commit('setGender', value)
    },
    setBirth_date({ commit }, value) {
        commit('setBirth_date', value)
    },
    setMobile_no({ commit }, value) {
        commit('setMobile_no', value)
    },
    setEmail_id({ commit }, value) {
        commit('setEmail_id', value)
    },
    setHighest_education({ commit }, value) {
        commit('setHighest_education', value)
    },
    setEmployee_type({ commit }, value) {
        commit('setEmployee_type', value)
    },
    setTrainer_type({ commit }, value) {
        commit('setTrainer_type', value)
    },
    setShift_start_time({ commit }, value) {
        commit('setShift_start_time', value)
    },
    setShift_end_time({ commit }, value) {
        commit('setShift_end_time', value)
    },
    setJoining_date({ commit }, value) {
        commit('setJoining_date', value)
    },
    setExit_date({ commit }, value) {
        commit('setExit_date', value)
    },
    setDesignation({ commit }, value) {
        commit('setDesignation', value)
    },
    setRole({ commit }, value) {
        commit('setRole', value)
    },
    setUserStatus({ commit }, value) {
        commit('setUserStatus', value)
    },
    setProfile({ commit }, value) {
        commit('setProfile', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setStatemastersAll({ commit }, value) {
      commit('setStatemastersAll', value)
    },
    setCitymastersAll({ commit }, value) {
        commit('setCitymastersAll', value)
      },
    setCourseVertical({ commit }, value) {
        commit('setCourseVerticalVar', value)        
    },
    setCompetitionBatch({ commit }, value) {
        commit('setCompetitionBatchVar', value)        
    },
    
}

const mutations = {
    setCourseVerticalVar(state, item){
        state.item.course_vertical = item
    },
    setItem(state, item) {
        state.item = item
    },
    setEmployee_name(state, value) {
        state.item.employee_name = value
    },
    setUsername(state, value) {
        state.item.username = value
    },
    setEmployee_address(state, value) {
        state.item.employee_address = value
    },
    setArea(state, value) {
        state.item.area = value
    },
    setCity(state, value) {
        state.item.city = value
    },
    setCountry(state, value) {
        state.item.country = value
    },
    setState(state, value) {
        state.item.state = value
    },
    setGender(state, value) {
        state.item.gender = value
    },
    setBirth_date(state, value) {
        state.item.birth_date = value
    },
    setMobile_no(state, value) {
        state.item.mobile_no = value
    },
    setEmail_id(state, value) {
        state.item.email_id = value
    },
    setHighest_education(state, value) {
        state.item.highest_education = value
    },
    setEmployee_type(state, value) {
        state.item.employee_type = value
    },
    setTrainer_type(state, value) {
        state.item.trainer_type = value
    },
    setShift_start_time(state, value) {
        state.item.shift_start_time = value
    },
    setShift_end_time(state, value) {
        state.item.shift_end_time = value
    },
    setJoining_date(state, value) {
        state.item.joining_date = value
    },
    setExit_date(state, value) {
        state.item.exit_date = value
    },
    setDesignation(state, value) {
        state.item.designation = value
    },
    setRole(state, value) {
        state.item.role = value
    },
    setCountrymastersAll(state, value) {
        state.countrymastersAll = value
    },
    setStatemastersAll(state, value) {
        state.statemastersAll = value
    },
    setCitymastersAll(state, value) {
        state.citymastersAll = value
    },
    setTrainerTypeAll(state, value) {
        state.trainerTypeAll = value
    },
    setEmployeeTypeAll(state, value) {
        state.employeeTypeAll = value
    },
    setRolesAll(state, value) {
        state.rolesAll = value
    },
    setUserStatus(state, value) {
        state.item.user_status = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setProfile(state, value) {
        state.item.profile = value
    },
    setCourseVerticalAll(state, value) {
        state.courseVerticalAll = value
    },
    setCompetitionBatchVar(state, value) {
        state.item.competition_batch = value
    }, 
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
