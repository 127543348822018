<template>
  <div class="login-box mt-5">
   <div class="login-logo">
   </div>
   <div class="card">
      <div class="card-body login-card-body">
         <p class="login-box-msg">
               <a :to="'/'">
                  <img
                        src="/assets/images/logo.svg"
                        width="100%"
                        class="img-responsive"
                  >
               </a>
            </p>
         <p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
         
         <div class="alert alert-success" role="alert" v-if="successMsg">
            {{ this.successMsg }}
         </div>
         <div class="alert alert-danger" role="alert" v-if="errorMsg">
            {{ this.errorMsg }}
         </div>
        
         <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">   
            <div class="input-group">
               <input type="email"
                            class="form-control"
                            name="email"
                            id="email"
                            placeholder="Email"
                            v-model="user.email"
                            v-validate="'required|email'" >
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <span class="error" v-show="errors.has('email')">
               {{ $root.updateErrorMessage('email',this.errors, 'Email')}}
            </span>
            </div>
            <div class="row">
               <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block" :disabled="loading"><span class="alert text-center" v-if="loading">
                     <i class="fa fa-spinner fa-sync rotate"></i> Loading...</span><span v-else>Send Reset Password Link</span></button>
               </div>
            </div>
         </form>
         <p class="mt-3 mb-1">
            <router-link to="/">Login</router-link>
         </p>
      </div>
   </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            user: {
                email: '',
                redirect_url:window.location.origin+'/reset-password',
            },
            successMsg:'',
            errorMsg:'',
            loading:false
        }
    },
    methods:{
        ...mapActions('AuthIndex', ['storeUserData']),
        handleSubmit(e) {
         this.successMsg = ''
			this.errorMsg = ''
          // console.log("dfdf");
            this.$validator.validate().then(valid => {
                if (valid) {
                   this.loading = true;
                    axios.post('/api/v1/forgotpassword', this.user).then(response => {
                        var res = response.data;
                        if(res.result){
                           this.successMsg = res.message;
                           this.loading = false;
                        }
                        else{
                           //console.log(res.message)
                           this.errorMsg = res.message;
                           this.loading = false;
                        }
                    });
                   //location.href="/common-masters";
                }
            });
        }
    }
}
</script>