<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Leave Requests</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Leave Requests List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>
 
                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="col-md-3 col-sm-3">
                                <label for="from_date">From Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="from_date" placeholder="Select from date" v-model="date_filter.from_date" autocomplete="off"></date-picker>
                            </div>
                            <div class="col-md-3 col-sm-3">
                                <label for="to_date">To Date</label>
                                <date-picker :value="null" :config="$root.dpconfigDate" name="to_date" placeholder="Select to date" v-model="date_filter.to_date" autocomplete="off"></date-picker>
                            </div>
                             <div class="col-md-3 col-sm-3">
                                 <label for="from_date">Status</label>
                                 <v-select
                                    name="search_topic_name"
                                    label="name"
                                    :value="null"
                                    v-model="date_filter.search_topic_name"
                                    :options="topicAll"
                                    />    
                            </div>
                            <div class="col-md-3 col-sm-3">
                                <button type="button" class="btn btn-success m-t-25" @click="searchInquiriesData()">Search</button>
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableActions from '../../dtmodules/DatatableActions'
import DatatableStatus from '../../dtmodules/DatatableStatus'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'

export default {
    components,
    data() {
        return {
            columns: [
                { title: 'Leave Type', field: 'leaveType', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Type' },
                { title: 'Leave Option', field: 'leaveOption', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Option' },

                { title: 'Leave From  Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Holiday Date'},

                { title: 'Leave Upto Date', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Holiday Date'},

                // { title: 'Leave From Date', field: 'leave_start_date', sortable: true,thComp: 'FilterTh', tdClass: 'Leave From Date' },
                // { title: 'Leave Upto Date', field: 'leave_end_date', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Upto Date' },
                { title: 'Leave Days', field: 'days', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Days' },               
                { title: 'Start Time', field: 'start_time', sortable: true,thComp: 'FilterTh', tdClass: 'Start Time' },
                { title: 'End Time', field: 'end_time', sortable: true,thComp: 'FilterTh', tdClass: 'End Time' },
                { title: 'Leave Status', field: 'leaveStatus', sortable: true,thComp: 'FilterTh', tdClass: 'Leave Status' },
                { title: 'Actions', tdComp: DatatableActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'LeaveRequestsIndex',
                route: 'leave_requests',
                permission_prefix: 'leave_request_'
            },
            date_filter : {from_date : '', to_date: '',search_topic_name : null}
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
        this.fetchTopicAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('LeaveRequestsIndex', ['data', 'total', 'loading', 'relationships','topicAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('LeaveRequestsIndex', ['fetchData', 'setQuery', 'resetState','fetchTopicAll']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['original_date', 'original_format_date','original_date_a', 'original_format_date_a', 'start_time', 'end_time','days','leaveType','leaveOption','leaveStatus'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.query = {};
            this.date_filter.from_date = '';
            this.date_filter.to_date = '';
            this.date_filter.search_topic_name = null;
            this.fetchData()            
        },
        searchInquiriesData() {
            this.query = {};
            if (this.date_filter.from_date) {
                this.date_filter.from_date = this.date_filter.from_date.replace(/\//g, '-');    
            }
            if (this.date_filter.to_date) {
                this.date_filter.to_date = this.date_filter.to_date.replace(/\//g, '-');
            }
            if (this.date_filter.search_topic_name && this.date_filter.search_topic_name.name) {
                this.date_filter.search_topic_name = this.date_filter.search_topic_name.name;    
            }
            this.fetchData(this.date_filter);
        }
    }
}
</script>


<style scoped>

</style>
