function initialState() {
    return {
        item: {
            id: null,
            branch_id: null,
            user_id: null,
            leave_type: null,
            leave_start_date: null,
            leave_end_date: null,
            leave_option: null,
            start_time: null,
            end_time: null,
            leave_status: null,
            reason: null,
            approved_remarks: null,
            days: null,
        },
        leaveTypeAll: [],
        leaveOptionAll: [],
        usersAll: [],
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    leaveTypeAll: state => state.leaveTypeAll,
    leaveOptionAll: state => state.leaveOptionAll,
    usersAll: state => state.usersAll,
    
}

const actions = {
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/leave-approval/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        dispatch('fetchLeaveTypeAll')
        dispatch('fetchLeaveOptionAll')
        dispatch('fetchUsersAll')
    },
    fetchLeaveTypeAll({ commit }) {
        axios.get('/api/v1/leaveTypeList')
            .then(response => {
                commit('setLeaveTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchLeaveOptionAll({ commit }) {
        axios.get('/api/v1/leaveOptionList')
            .then(response => {
                commit('setLeaveOptionAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchUsersAll({ commit }) {
        // axios.get('/api/v1/users')
        //     .then(response => {
        //         commit('setUsersAll', response.data.data)
        //     })
        // .catch(function (error) {
        //         if (error.response) {
        //           // The request was made and the server responded with a status code
        //           // that falls out of the range of 2xx
        //           console.log(error.response.data.message);
        //           console.log(error.response.status);
        //           if(error.response.status == 401) {
        //             window.location.href = location.protocol + "//" + location.host + "/sessionExpired"
        //           }
        //         } else if (error.request) {
        //           // The request was made but no response was received
        //           // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //           // http.ClientRequest in node.js
        //           console.log(error.request);
        //         } else {
        //           // Something happened in setting up the request that triggered an Error
        //           console.log('Error', error.message);
        //           let message = error.response.data.message || error.message
        //           let errors  = error.response.data.errors
        //           dispatch(
        //               'Alert/setAlert',
        //               { message: message, errors: errors, color: 'danger' },
        //               { root: true })
        //           reject(error)                  
        //           }
        //           console.log(error.config);
        // })            
    },
    setBranch_id({ commit }, value) {
        commit('setBranch_id', value)
    },
    setUser_id({ commit }, value) {
        commit('setUser_id', value)
    },
    setLeave_type({ commit }, value) {
        commit('setLeave_type', value)
    },
    setLeave_start_date({ commit }, value) {
        commit('setLeave_start_date', value)
    },
    setLeave_end_date({ commit }, value) {
        commit('setLeave_end_date', value)
    },
    setLeave_option({ commit }, value) {
        commit('setLeave_option', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setLeave_status({ commit }, value) {
        commit('setLeave_status', value)
    },
    setReason({ commit }, value) {
        commit('setReason', value)
    },
    setApprovedRemarks({ commit }, value) {
        commit('setApprovedRemarks', value)
    },
    setDays({ commit }, value) {
        commit('setDays', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setBranch_id(state, value) {
        state.item.branch_id = value
    },
    setUser_id(state, value) {
        state.item.user_id = value
    },
    setLeave_type(state, value) {
        state.item.leave_type = value
    },
    setLeave_start_date(state, value) {
        state.item.leave_start_date = value
    },
    setLeave_end_date(state, value) {
        state.item.leave_end_date = value
    },
    setLeave_option(state, value) {
        state.item.leave_option = value
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setLeave_status(state, value) {
        state.item.leave_status = value
    },
    setReason(state, value) {
        state.item.reason = value
    },
    setApprovedRemarks(state, value) {
        state.item.approved_remarks = value
    },
    setDays(state, value) {
        state.item.days = value
    },
    setLeaveTypeAll(state, value) {
        state.leaveTypeAll = value
    },
    setLeaveOptionAll(state, value) {
        state.leaveOptionAll = value
    },
    setUsersAll(state, value) {
        state.usersAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
