<template>
     <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Parents</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>                                
                            </li>
                            <li class="breadcrumb-item active">Parents List</li>                            
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Parents List</h3>
                </div>
                <div class="card-body">
                   <div class="btn-group mb-3">
                        <b-btn v-b-modal.addParentModel v-if="$can(xprops.permission_prefix + 'create')" type="button" class="btn btn-success btn-sm">
                            <i class="fa fa-plus"></i> Add New
                        </b-btn> 

                        <!-- <router-link
                                v-if="$can(xprops.permission_prefix + 'create')"
                                :to="{ name: xprops.route + '.create' }"
                                class="btn btn-success btn-sm"
                                >
                            <i class="fa fa-plus"></i> Add new
                        </router-link>-->

                        <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                            <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                        </button>     
                        <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                            <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                        </button>                      
                    </div>
                      <!-- <div class="row" v-if="loading">
                        <div class="col-xs-4 col-xs-offset-4">
                            <div class="alert text-center">
                                <i class="fa fa-spin fa-refresh"></i> Loading
                            </div>
                        </div>
                    </div> -->
                    <AddParentPopup @loadItems="loadItems"></AddParentPopup>
                    <EditParentPopup :editId="editId" @loadItems="loadItems"></EditParentPopup>
                    <ViewParentPopup :showId="showId" @loadItems="loadItems"></ViewParentPopup>
                    <div class="col-md-12">
                             <bootstrap-alert />
                        </div>   

                        <!-- <div class="row" v-if="loading">
                            <div class="col-xs-4 col-xs-offset-4">
                                <div class="alert text-center">
                                    <i class="fa fa-spin fa-refresh"></i> Loading
                                </div>
                            </div>
                        </div> -->
                            <div class="table-responsive setting-group">
                                <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                            }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                            >
                                <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'actions'">
                                        <ParentActions :hideEdit="hideEdit" @editIdModel="editIdModel" @showIdModel="showIdModel" @loadItems="loadItems" :xprops="xprops" :row="props.row"></ParentActions>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                        </template>
                        <template slot="loadingContent">
                            <CustomLoader></CustomLoader>
                        </template>
                        </vue-good-table>
                        </div>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ParentActions from '../../dtmodules/DatatableActions/ParentActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'
import store from '../../../store' 
import CustomLoader from '@/components/CustomLoader'
import AddParentPopup from '@/components/cruds/Parents/AddParentPopup'
import EditParentPopup from '@/components/cruds/Parents/EditParentPopup'
import ViewParentPopup from '@/components/cruds/Parents/ViewParentPopup'

ParentActions.attachStore('ParentsIndex');
export default {
    components,
    components: {   
        ParentActions,
        CustomLoader,
        AddParentPopup,
        EditParentPopup,
        ViewParentPopup
    },
    data() {
        return {
            branch_id:(store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0,
            hideEdit:0,
            parentId:'',
            editId:'',
            showId:'',
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Mother Name",
                    field: "mother_name",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Mother Name', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Mother Mobile Number",
                    field: "other_mobile_no",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Mother Mobile Number', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Email",
                    field: "email",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Email', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Role",
                    field: "title",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Role', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Referral Code",
                    field: "referral_code",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Referral Code', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                    label: "Available Points",
                    field: "total_available_credits",
                    filterOptions: {
                        styleClass: 'parent_filter', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        placeholder: 'Available Points', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        // trigger: 'enter', //only trigger on enter not on keyup 
                    },
                },
                {
                label: "Actions",
                field: "actions",
                sortable: false
                }

            ],
            rows: [],
            student_id:'',
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'ParentsIndex',
                route: 'parents',
                permission_prefix: 'parent_'
            }
        }
    },
    // created() {
    //     this.getBranchesData()
    //     .then((res) => {
    //         store.state.AuthIndex.branches = (res.data.data) ? res.data.data : store.state.AuthIndex.branches;
    //         store.state.Rules.branches = (res.data.data) ? res.data.data : store.state.Rules.branches;
    //     })
    // },
    computed: {
        ...mapGetters('ParentsIndex', ['data', 'total', 'loading', 'relationships']),
    },  
    watch:{
        branch_id: function() { 
        return (store.state.AuthIndex.user) ? store.state.AuthIndex.user.api_session_branch_id : 0;
        }
    },
    created(){
        // if(this.branch_id == 1){
        //     this.$router.push({name:'admin_dashboard.show'});
        // }
        this.student_id = this.$route.params.id;
    },
    methods: {
        ...mapActions('ParentsIndex', ['fetchData', 'setQuery', 'resetState']),
        //...mapActions('Rules', ['getBranchesData']),
        editIdModel(id){
            console.log("dfdf",id);
            this.editId = id;
        },
        showIdModel(id){
            console.log("iud",id)
            this.showId = id;
        },
        branchIdModel(id){
            this.branchId = id;
        },
        updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
        },
        onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage, page: 1 });
        this.loadItems();
        },
        onSortChange(params) {
        this.updateParams({
            sort: [
            {
                type: params[0].type,
                field: params[0].field
            }
            ],
            page: 1
        });
        this.loadItems();
        },
        onColumnFilter(params) {
        //console.log("column",params);
        this.updateParams(params);
        this.updateParams({page:1});
        this.loadItems();
        },

        onSearch(params) {
        params.page = 1;
        this.updateParams(params);
        this.loadItems();
        },
        // load items is what brings back the rows from server
        loadItems(flag = 0) {
        
        var order = "";
        var field = "";
        if (
            this.serverParams.sort[0].type != "" &&
            this.serverParams.sort[0].type != "none"
        ) {
            order = this.serverParams.sort[0].type;
            field = this.serverParams.sort[0].field;
        }
        let search = this.serverParams.searchTerm
            ? this.serverParams.searchTerm
            : "";
            if(flag== 1){
                this.refershLoad = true;
            }
        axios
            .get("/api/v1/parents", {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                order: order,
                field: field,
                columnFilters:this.serverParams.columnFilters,
                student_id:this.student_id,
            }
            })
            .then(response => {
            //commit('setAll',  response.data.data)
            //console.log("rs",response.data);
            let res = response.data;
            if(res.result){
                this.rows = res.data.data;
                this.totalRecords = res.data.count;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
            else{
                this.rows = [];
                this.totalRecords = 0;
                if(flag== 1){
                    this.refershLoad = false;
                }
            }
        
            });
        }, 
        resetFilters(){
            this.serverParams.columnFilters = {};
            let columnFilters = {"branch_name":"","display_franchise_name":"","mobile_no":"","branch_email":"","branch_address":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }  
    }
}
</script>
<style scoped>

</style>
