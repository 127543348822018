<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Student Completed Course Report</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="submitForm" novalidate>
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Student Completed Course Report</h3>                             
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                v-validate="'required'"
                                                @input="updateBranch"
                                                :value="item.branch_id"
                                                :options="branchsAllComputed"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div ref="student" class="form-group">
                                        <label for="student">Student Name</label>
                                        <v-select
                                                name="student"
                                                label="student_name"
                                                @input="updateStudent"
                                                :value="item.student_id"
                                                :options="studentAll"
                                                />
                                        <span class="error" v-show="errors.has('student')">
                                            {{ $root.updateErrorMessage('student',this.errors,'Student Name')}}
                                        </span>
                                    </div>
                                </div>  
                                <div class="clearfix"></div>
                                <!-- <div class="col-md-6 col-xs-12">
                                     <date-r-picker @updateRange="updateRange"></date-r-picker>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12" style="text-align:center">
                        <vue-button-spinner class="std_information_save btn btn-block btn-success new_branch_save">
                            Go
                        </vue-button-spinner>
                    </div>  
                </form>
            </div>
            <br> 
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Student Completed Course Report</h3>
                        </div>                        
                        <div class="box-body">
                            <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm" @click="submitForm">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading Details...
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>                               
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import components from '../../comps/'
import StudentCompletedCoursePopup from '../../dtmodules/PopupActions/StudentCompletedCoursePopup'
import BalanceReportAmount from '../../dtmodules/BalanceReportAmount'
import BalanceReportTotalPaid from '../../dtmodules/BalanceReportTotalPaid'
import BalanceReportTotalDue from '../../dtmodules/BalanceReportTotalDue'
import DateRangePicker from 'vue2-daterange-picker'
import '../../comps/vue-daterange-picker.min.css'
components['date-range-picker'] = DateRangePicker;
export default {
    components,
    data() {
        return {
            dataSeen: false,
            columns: [
                // { title: '#', field: 'row_number', sortable: true, thComp: 'FilterTh', tdClass: 'Schedule Date' },
                { title: 'Student', tdComp:StudentCompletedCoursePopup, field: 'student_name', sortable: true, thComp: 'FilterTh' },
                { title: 'Regular', field: 'regular', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Camp', field: 'camp', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
                { title: 'Competition', field: 'competition', sortable: true, thComp: 'FilterTh', tdClass: 'Batch Name' },
            ],
            query: { sort: 'student_name' },
            xprops: {
                module: 'StudentCompletedCourseReportsIndex',
                route: 'fees_paid_and_balance_report',
                permission_prefix: 'fees_paid_and_balance_report_'
            },
            dateRange: { // used for v-model prop
                startDate: moment(),
                endDate: moment(),
            },            
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchBranchMasterAll()
        .then(() => {
            if(this.branchMastersAll.length == 1) {
                this.updateBranch(this.branchMastersAll[0])            
            }
        });
        this.updateRange(this.dateRange)
    },   
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){}
    },    
    destroyed() {
        this.resetState()
    },    
    computed: {
        ...mapGetters('StudentCompletedCourseReportsIndex', ['item','data', 'total', 'loading', 'relationships','branchMastersAll','reportsDataAll','studentAll']),
        branchsAllComputed() {
            if(this.branchMastersAll.length > 1) {
                this.branchMastersAll.unshift({
                    'branch_name': 'All',
                    'id': '0'
                });
            }
            return this.branchMastersAll;
        },        
        numberOfStudent() {
          var localvar = [];
          this.reportsDataAll.forEach(function(value,index){
            if(localvar.indexOf(value.student_id) == -1) {
              localvar.push(value.student_id);
            }
          }.bind(this))
          return localvar.length;
        },  
        totalBalanceComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.TotalBalance);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);            
        },
        totalPaidComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.TotalPaid);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);            
        },
        totalFeesComputed() {
          var localvar = 0;
          this.reportsDataAll.forEach(function(value,index){
              localvar = parseFloat(localvar) + parseFloat(value.TotalFees);
          }.bind(this))
          return parseFloat(localvar).toFixed(2);             
        },                
    },
    methods: {
        ...mapActions('StudentCompletedCourseReportsIndex', ['resetState','fetchBranchMasterAll','fetchStudentAll','getReportsData','setBranch','setStudent','setQuery','fetchFeesandPaidBalanceData','setStudentAll','feesPaidAndBalanceReportListStore','setFromDate','setExit_date']),
        updateRange(e) {
            this.dataSeen = false;
            this.updateFromDate(e.startDate)
            this.updateExit_date(e.endDate)
        },
        updateFromDate(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setFromDate(date)
            this.dataSeen = false;
        },
        updateExit_date(e) {
            let date = moment(e).format('YYYY-MM-DD')
            this.setExit_date(date)
            this.dataSeen = false;
        },                     
        feesPaidAndBalanceReportList() {
            var dataObj = {
                'branch_id': this.item.branch_id && this.item.branch_id.id ? this.item.branch_id.id : null,
                'student_id': this.item.student_id && this.item.student_id.id ? this.item.student_id.id : null
            }
            this.feesPaidAndBalanceReportListStore(dataObj)
        },  
        updateBranch(value) {
            this.dataSeen = false;
            this.setBranch(value)
            if(value && value.id) {
                this.fetchStudentAll(value.id)
            }
            if(!value) {
                this.setStudentAll([])
            }
            this.setStudent(null);
        },  
        updateStudent(value) {
            this.dataSeen = false;
            this.setStudent(value)
        },               
        resetFilters(){
            var filtersField= [];
            filtersField = ['student_name','regular','camp','competition'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
        },         
        submitForm() {
            this.$validator.validate().then(result => {
                if(result){
                    this.dataSeen = true
                    this.resetFilters()
                    this.query = {}
                    var obj = {
                        'branch_id': this.item.branch_id.id,
                        'student_id': this.item.student_id ? this.item.student_id.id : 0,
                    };
                    this.fetchFeesandPaidBalanceData(obj)
                    .then((res) => {})
                    .catch((error) => {
                        console.error(error)
                    })
                  }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
              });  
        },
    }
}
</script>


<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.mypanel {
    box-shadow: 1px 1px 1px 1px #919ca0;    
}
</style>
 