<template> 
    <form @submit.stop.prevent="submitForm" id="transfer_form">
        <div class="row transfer_create">
            <div class="col-md-12 col-xs-12">
                <div ref="branches" class="form-group branch_dropdown" id="branch_form">
                    <label for="branches">Branch<p class="astrisk">*</p></label>
                    <select v-validate="'required'" name="branch" label="branch" class="form-control branch_dropdown select2 branch" placeholder="Select Branch" v-model="branch_id" @change="updateBranch">
                        <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                            <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id">
                                {{branch.branch_name}}
                            </option>
                        </optgroup>
                    </select>
                    <span class="error" v-show="errors.has('branch')">
                        {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                    </span>
                </div>
            </div>
        </div>
        <footer class="modal-footer modal-footer1"><button type="button" @click="closeModel" class="btn btn-secondary">Cancel</button><button type="submit" :disabled="loading" class="btn btn-success">Save</button></footer>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props:['inquiryId'],
    data() {
        return {
            branchesDetails:[],
            inquiry_id: '',
            branch_id: '',
        }
    },
    components: {

    },
    computed: {
        ...mapGetters('InquiriesSingle', ['item', 'loading']),
    },
    created() {
           // console.log("dfdf",this.inquiryId);
         this.branchesDetails = this.$store.state.AuthIndex.branches;
    },
    mounted() {
        let _this = this;
        $(".branch").select2({val:'',dropdownParent: $('#branch_form'),placeholder: "Select Branch",}).on('change', function () {
           // console.log($(this).select2("val"));
           _this.updateBranch($(this).select2("val"));
        });
    },
    methods: {
        ...mapActions('InquiriesSingle', ['setBranch', 'storeTransferData']),
        closeModel(){
             this.$emit('closeModel');
        },
        submitForm () {
            this.$validator.validate().then(result => {

                if (result) {
                    let data = {
                        'inquiry_id': this.inquiryId,
                        'branch_id' : this.branch_id
                    };
                    this.storeTransferData(data)
                    .then((res) => {
                    if(!res.data.result)
                    {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // console.log("ke",key,value);
                            if( value !== null ) {
                                this.$validator.errors.add({
                                    id: key,
                                    field: key,
                                    msg: value[0],
                                    scope: this.$options.scope,
                                });
                            }
                        }
                        return '';    
                    } 
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                });
            
                }
                else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
                
            });

            
        },
        updateBranch(value) {
            console.log("value",value);
            this.branch_id = value;
            //this.setBranch(value)
        },
    }
}
</script>

<style scoped>
.transfer_create >>> .select2-container{
    width: 100% !important;
}

.transfer_create >>> .select2-container--open{
        z-index:9999999         
    }
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 75%;
}
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>