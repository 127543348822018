function initialState() {
    return {
        item: {
            id: null,
            title: null,
            selectedBranch: null,
            permission: [],
        },
        permissionsAll: [],
        menuMasterAll: [],
        
        loading: false,
        loading1:false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    loading1: state => state.loading1,
    permissionsAll: state => state.permissionsAll,
    menuMasterAll: state => state.menuMasterAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            if (_.isEmpty(state.item.permission)) {
                params.delete('permission')
            } else {
                for (let index in state.item.permission) {
                    params.set('permission['+index+']', state.item.permission[index])
                }
            }

            axios.post('/api/v1/roles', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            if (_.isEmpty(state.item.permission)) {
                params.delete('permission')
            } else {
                for (let index in state.item.permission) {
                    if(state.item.permission[index].id){
                        var a = params.set('permission['+index+']', state.item.permission[index].id);                        
                    }else{
                        var a = params.set('permission['+index+']', state.item.permission[index]);                        
                    }
                }
            }
            
            axios.post('/api/v1/roles/' + state.item.id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                commit('setLoading', false)
                })
            axios.post('/api/v1/auto-logout', {role_id:state.item.id})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(function (error) {
                        console.log('Error', error.message);
                    })
        })
    },
    fetchData({ commit, dispatch }, id) {
        commit('setLoading1', true)
        axios.get('/api/v1/roles/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setItem', response.data.data)
                    commit('setLoading1', false)
                }
                else{
                    commit('setItem', [])
                    commit('setLoading1', false)
                }
                
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading1', false)
                    }) 
        dispatch('fetchPermissionsAll');
        dispatch('fetchMenuMasterAll');
    },
    fetchPermissionsAll({ commit }) {
        axios.get('/api/v1/permissions')
            .then(response => {
                commit('setPermissionsAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchMenuMasterAll({ commit,state,dispatch },page_no=1) {
        commit('setLoading1', true)
        axios.get('/api/v1/menuList',{
            params: {
                page_no: page_no
            }
            })
            .then(response => {
                if(response.data.result){
                    commit('setMenuMasterAll', response.data.data.data)
                    if(response.data.data.isRecordsExists){
                        dispatch('fetchMenuMasterAll',page_no+1)
                    }
                    commit('setLoading1', false)
                }
                
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                          commit('setLoading1', false)
                    }) 
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setPermission({ commit }, value) {
        commit('setPermission', value)
    },
    setMenuMaster({ commit }, value) {
        commit('setMenuMaster', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setLoading1({ commit }, value) {
        commit('setLoading1', value)
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setPermission(state, value) {
        state.item.permission = value
    },
    setPermissionsAll(state, value) {
        state.permissionsAll = value
    },
    setMenuMasterAll(state, value) {
        // console.log("value mutation",state.menuMasterAll)
        //console.log(state.menuMasterAll);
        state.menuMasterAll = state.menuMasterAll.concat(value);
        // if(state.menuMasterAll.length == 0){
        //     state.menuMasterAll = [...state.menuMasterAll, ...value];
        // }else{
        //     state.menuMasterAll = value
        // }
        
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoading1(state, loading) {
        state.loading1 = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
