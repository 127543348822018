<template>
     <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Schools</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active">Schools List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Schools List</h3>
                    </div>
                    <div class="card-body">
                        <div class="btn-group mb-3">
                            <router-link
                                :to="{ name: 'school_masters.create' }"
                                class="btn btn-success btn-sm"
                            >
                                <i class="fa fa-plus"></i> Add New
                            </router-link>
                            
                            <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
                            </button>
                            <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
                            </button>
                        </div>
                        <div class="col-md-12">
                            <bootstrap-alert />
                        </div>
                        <div class="table-responsive setting-group">
                            <vue-good-table
                                ref="table1"
                                styleClass="vgt-table table table-bordered striped thead-dark"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="onSearch"
                                :totalRows="totalRecords"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:10,
                                    perPageDropdown: [10, 20, 40, 80, 100],
                                    dropdownAllowAll: false,
                                }"
                                :isLoading.sync="isLoading"
                                :select-options="{ 
                                    enabled: false,
                                }"
                                :search-options="{
                                    enabled: false,
                                    placeholder: 'search',
                                }"
                                :rows="rows"
                                :columns="columns"
                            >
                                <div slot="emptystate" class="text-center">
                                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                </div>
                                <template
                                    slot="table-row"
                                    slot-scope="props"
                                >
                                    <span v-if="props.column.field == 'actions'">
                                        <SchoolsActions :hideEdit="hideEdit" @loadItems="loadItems" :xprops="xprops" :row="props.row"></SchoolsActions>
                                    </span>
                                    <span v-else>
                                        {{props.formattedRow[props.column.field]}}
                                    </span>
                                </template>
                                <template slot="loadingContent">
                                    <CustomLoader></CustomLoader>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SchoolsActions from '../../dtmodules/DatatableActions/SchoolsActions'
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'

SchoolsActions.attachStore('SchoolMastersIndex');
export default {
    components,
    components: {
        SchoolsActions,
        CustomLoader,
    },
    data() {
        return {
            hideEdit:0,
            parentId:'',
            editId:'',
            showId:'',
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "School Name",
                    field: "school_name",
                    filterOptions: {
                        styleClass: 'school_master_filter',
                        enabled: true,
                        placeholder: 'School Name',
                        filterValue: '',
                        filterDropdownItems: [], 
                    },
                },
                {
                    label: "School Board",
                    field: "school_board_name",
                    filterOptions: {
                        styleClass: 'school_master_filter',
                        enabled: true,
                        placeholder: 'School Board',
                        filterValue: '',
                        filterDropdownItems: [], 
                    },
                },
                {
                    label: "Country",
                    field: "country_name",
                    filterOptions: {
                        styleClass: 'school_master_filter',
                        enabled: true,
                        placeholder: 'Country',
                        filterValue: '',
                        filterDropdownItems: [],
                    },
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }

            ],
            rows: [],
            student_id:'',
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'SchoolMastersIndex',
                route: 'school_masters',
                permission_prefix: 'school_master_'
            }
        }
    },
    computed: {
        ...mapGetters('SchoolMastersIndex', []),
    },  
    created(){
        this.student_id = this.$route.params.id;
    },
    methods: {
        ...mapActions('SchoolMastersIndex', []),

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.loadItems();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ],
                page: 1
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.updateParams({page:1});
            this.loadItems();
        },
        onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.loadItems();
        },
        loadItems(flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.refershLoad = true;
                }
            axios
            .get("/api/v1/schoolList", {
                params: {
                    max: this.serverParams.perPage,
                    page: this.serverParams.page,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:this.serverParams.columnFilters,
                }
            })
            .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.refershLoad = false;
                    }
                }
            });
        },
        resetFilters() {
            this.serverParams.columnFilters = {};
            let columnFilters = {"school_name":"","school_board":"","country":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }
    }
}
</script>
<style scoped>

</style>
