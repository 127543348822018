<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Modules</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/modules'">Modules</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Module</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Module</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                <div class="col-md-12">
                                    <bootstrap-alert />
                                </div>

                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="module_name">Module Name<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="module_name"
                                            ref="module_name"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter Module Name"
                                            @input="updateModuleName"
                                            :value="moduleName"
                                            >
                                    <span class="error" v-show="errors.has('module_name')">
                                        {{ $root.updateErrorMessage('module_name',this.errors,'Module Name')}}
                                    </span><br>
                                </div>  

                                <div class="form-group col-md-12 col-xs-12 mb-0">
                                    <label for="module_name">Project Selection<p class="astrisk">*</p></label>
                                </div>
                                 <div class="form-group col-md-12 col-xs-12 slectBoxIcon">
                                    <div class="subject-info-box-1">
                                    <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                    </div>
                                    <div class="subject-info-arrows text-center">
                                    <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                    <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                    <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                    <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                    <span class="top-margin" style="color: red;" id="movingError"></span>
                                    </div>
                                    <div class="subject-info-box-2">
                                    <select multiple="multiple" id='lstBox2' class="form-control"></select>
                                    </div>
                                </div>  
                           
                                <div class="form-group col-md-12 col-xs-12">
                                    <label for="module_hours">Module Hours<p class="astrisk">*</p></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="module_hours"
                                            ref="module_hours"
                                            maxlength="90"
                                            v-validate="'required'"
                                            placeholder="Enter Module Name"
                                            @input="updateModuleHours"
                                            :value="moduleHours"
                                            disabled="true"
                                            >
                                    <span class="error" v-show="errors.has('module_hours')">
                                        {{ $root.updateErrorMessage('module_hours',this.errors,'Module Hours')}}
                                    </span><br>
                                </div>  

                                                     
                                <div class="col-md-12 col-md-12 savebtn">
                                    <vue-button-spinner
                                            class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                            type="submit"
                                            :isLoading="loading"
                                            :disabled="loading"
                                            >
                                            Save
                                    </vue-button-spinner>
                                </div> 
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            moduleName: null,
            moduleHours:0,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ]            
        }
    },
    created() {
        this.fetchProjectAll()
            // .then((res) => {
            //     //console.log("res",res);
            //     var localStaffAll = this.projectAll;
            //     setTimeout(function() {
            //             for (var i = 0; i < localStaffAll.length; i++) {
            //                 $('#lstBox1')
            //                     .append($("<option></option>")
            //                         .attr("class","project")
            //                         .attr("value",localStaffAll[i].id)
            //                         .text(localStaffAll[i].employee_name));
            //             }
            //     },200)  
            // })
    },
    destroyed() {
      //  this.resetForm()
       // this.resetState()
    },
    computed: {
        ...mapGetters('ModulesIndex', ['all','loading','studentAll','projectAll']),
        ...mapGetters('AuthIndex', ['user']),
   },
    watch:{
        projectAll(value){
            for (var i = 0; i < value.length; i++) {
                //console.log("loop",value[i]);
                $('#lstBox1')
                    .append($("<option data-hours='"+value[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",value[i].id)
                        .text(value[i].project_name));
            }
        }
    },
    methods: {
        ...mapActions('ModulesIndex', ['resetState','fetchProjectAll','moduleStore']),
        updateModuleName(e) {
            this.moduleName = e.target.value
        },
        updateModuleHours(e){
            this.moduleHours = e.target.value
        },
        moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;             
            }
          
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + Number($(selectedOpts[i]).data('hours'));
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + Number($(selectedOpts[i]).data('hours'));
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            
            var selectedOpts = $('#lstBox2 option.project:selected');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move') 
                return false;              
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - Number($(selectedOpts[i]).data('hours'));
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
           
            var selectedOpts = $('#lstBox2 option.project');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - Number($(selectedOpts[i]).data('hours'));  
            }
            $(selectedAllOpts).remove();
        },
        submitForm() {
            var allProjects = [];
            $('#lstBox2 option.project').each(function() {
                allProjects.push({'name': $(this).text(), 'id': $(this).val()})
            });
           
            var params = {
                'module_name': this.moduleName,
                'allprojectNames': JSON.stringify(allProjects),
                'total_hours': this.moduleHours,
                'total_fee':0,
                'branch_id':this.user.api_session_branch_id
            }
            this.$validator.validate().then(result => {
                if($('#lstBox2 option').length == 0) {
                    this.$eventHub.$emit('common',2,'Please select atlease one project')
                    return false;
                }
                if(result && $('#lstBox2 option').length > 0) {
                    this.buttonLoading = true;                    
                    this.moduleStore(params)
                        .then((res)=>{
                            if(!res.data.result)
                            {
                                // this.$validator.errors.add({
                                //     id: 'module_name',
                                //     field: 'module_name',
                                //     msg: res.data.message,
                                //     scope: this.$options.scope,
                                // });
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    //console.log("ke",key,value);
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                return '';
                            }   
                            this.$eventHub.$emit('create-success')
                            this.$router.push({name: 'modules.index'})
                        })
                        .catch(()=>{
                           this.$eventHub.$emit('common',3,'Something went wrong.')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>