<template>
    <div class="popup-form add-country">
    <b-modal id="addStudentCourse"
             ref="addStudentCoursemodal"
             title="Add Student Course"
             @ok="handleOk"
             okTitle="Save"
             okVariant="success"  
             @cancel="handleCancel"
             hide-footer
             @hide="modelHide"
             @shown="modalOpened" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <StudentAddCourse @setBStudentId="setBStudentId" @closeModel="closeModel" :id="cid" :studentCourse="studentCourse"></StudentAddCourse>
    </b-modal>
  </div>
</template>
<script>
import StudentAddCourse from '@/components/cruds/Student/StudentAddCourse'
export default {
    props:['id','studId','studentCourse'],
    components:{
        StudentAddCourse
    },
    data(){
        return {
            cid:''
        }
    },
    watch:{
        'studId':function(value){
            if(this.studId != ''){
                this.id = this.studId;
                this.$refs.addStudentCoursemodal.show();
            }
        }
    },
    methods: {
        setBStudentId(id){
             this.$emit('setBStudentId',id);
        },
        modelHide(evt){
             this.$emit('setStudentId','');
             this.$emit('setStudentCourse','');
        },
         handleCancel(evt){
           
        }, 
        closeModel(){
            this.$emit('setStudentId','');
            this.$emit('setStudentCourse','');
            this.$refs.addStudentCoursemodal.hide();
        },   
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                    this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        modalOpened() {
            this.cid = this.id;
            if(this.studId != ''){
                this.cid = this.studId;
            }
            this.licenseName = '';
            this.listOption = 1;
            this.start_date = '';
            this.end_date = '';

            //clear fields   
            //this.getListing(this.item.project_id.id);   
        },
    },
}
</script>

