<template> 
    <div class="popup-form">
        <b-modal id="modalPreventTransferInquiries"
            ref="modalPreventTransferInquiries"
            title="Transfer Inquiry"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"
            hide-footer
            @cancel="handleCancel"
            @shown="openModel" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true"
            >
            <TransferCreate @closeModel="closeModel" :inquiryId="inquiryId" @loadItems="loadItems"/>
        </b-modal>
    </div>
</template>

<script>
import TransferCreate from './TransferCreate'

export default {
    props:['inquiryId'],
    components: {
        TransferCreate
    },
    data() {
        return {
            
        }
    },
    methods: {
        openModel () {
            
        },
        handleCancel(evt) {
            
        },
        handleOk (evt) {
            
        },
        loadItems() {
            this.$emit('loadItems');
        },
        closeModel() {
            this.$emit('setInquiryId','');
            this.$refs.modalPreventTransferInquiries.hide();
        },
    }
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    margin: 0 auto;
    left: 100%;
    top: 26px;
    bottom: 0;
}
.Color_Box_Div {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 4px;
    margin-left: 4px;
    float: left;
    border-radius: 3px;
}
#loadingDiv {
    background-color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10020;
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}
.ajax-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    display: block;
    z-index: 10020!important;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.project-card{
    margin: 10px;
}
.matchDiv{
    flex: 0 0 0.333333% !important;
    max-width: 0.333333% !important;
}
.matchDiv .divider{
    border-left: solid 1px black;
    height: 75%;
}
</style>