<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Groups</h1>
        </section>
        <section class="content">
            <div class="row">
                <form @submit.prevent="sentSmsOnDynamicTemplate">
                    <div class="col-xs-12">
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Create Group</h3>
                            </div>
                            <div class="box-body">
                                <back-buttton></back-buttton>
                            </div>
                            <div class="box-body text-center" v-if="loading">
                                <h4><i class="fa fa-spin fa-refresh"></i> 
                                    Loading...</h4> 
                            </div>
                            <div v-else class="box-body">
                                <div class="row">
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="group_name">Group Name<p class="astrisk">*</p></label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                name="group_name"
                                                ref="group_name"
                                                maxlength="90"
                                                v-validate="'required'"
                                                placeholder="Enter Group Name"
                                                @input="updateGroupName"
                                                :value="groupName"
                                                >
                                        <span class="error" v-show="errors.has('group_name')">
                                          {{ $root.updateErrorMessage('group_name',this.errors,'Group Name')}}
                                        </span><br>
                                    </div>                                    
                                    <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="gender">
                                                Select List Option
                                                <p class="astrisk">*</p>
                                            </label>
                                            <br>
                                            <div class="radio-inline">
                                                <label class="contain">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    v-validate="'required|included:1,2'"
                                                    value="1"
                                                    v-model="gender"
                                                    @change="updateGender(1)"
                                                    >
                                                <span class="checkmark"></span>
                                                Staff
                                                </label>
                                            </div>
                                            <div class="radio-inline" style="margin-left:15px;">
                                                <label class="contain">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    ref="gender"
                                                    value="2"
                                                    v-model="gender"
                                                    @change="updateGender(2)"
                                                    >
                                                <span class="checkmark"></span>
                                                Student
                                                </label>
                                            </div>
                                            <br>
                                            <span class="error" v-show="errors.has('gender')">
                                            {{ $root.updateErrorMessage('gender',this.errors)}}
                                            </span>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="subject-info-box-1">
                                  <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                </div>
                                <div class="subject-info-arrows text-center">
                                  <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                  <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                  <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                  <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                  <span class="top-margin" style="color: red;" id="movingError"></span>
                                </div>
                                <div class="subject-info-box-2">
                                  <select multiple="multiple" id='lstBox2' class="form-control"></select>
                                </div>
                                <div class="clearfix"></div>                                
                            </div>
                        </div>
                        <div v-if="!loading" class="col-md-12 col-xs-12 savebtn">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    :isLoading="loading || buttonLoading"
                                    :disabled="loading || buttonLoading"
                                    >
                                    Create Group
                            </vue-button-spinner>
                        </div>                    
                    </div>
                </form>
            </div>
        </section>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            gender: 1,
            groupName: null,
            buttonLoading: false,
            staffSeen: false,
            memberType: null,
            studentId: null,
            staffId: null,
            smsContent: null,
            membersAll: [
                {'member_type_name': 'Staff', 'member_type': 1},
                {'member_type_name': 'Student', 'member_type': 2}
            ]            
        }
    },
    created() {
        this.fetchStudentAll()
        this.fetchStaffAll()
            .then((res) => {
                var localStaffAll = this.staffAll;
                setTimeout(function() {
                        for (var i = 0; i < localStaffAll.length; i++) {
                            $('#lstBox1')
                                .append($("<option></option>")
                                    .attr("class","staff")
                                    .attr("value",localStaffAll[i].id)
                                    .text(localStaffAll[i].employee_name));
                        }
                },200)  
            })
    },
    destroyed() {
        this.resetForm()
        this.resetState()
    },
    computed: {
        ...mapGetters('SmsOnDynamicTemplateIndex', ['all','loading','studentAll','staffAll']),
    },
    methods: {
        ...mapActions('SmsOnDynamicTemplateIndex', ['resetState','fetchStudentAll','fetchStaffAll','sendSmsOnDynamicTemplateStore']),
        updateGroupName(e) {
            this.groupName = e.target.value
        },
        resetForm() {
            this.gender = 0
            this.groupName = null
            this.buttonLoading = false
            this.staffSeen = false
            this.memberType = null
            this.studentId = null
            this.staffId = null
            this.smsContent = null
            if($('#lstBox2 option').length > 0) {
                this.moveAllLeft()
            }
            $('#lstBox1').empty()
            for (var i = 0; i < this.staffAll.length; i++) {
                $('#lstBox1')
                    .append($("<option></option>")
                        .attr("class","staff")
                        .attr("value",this.staffAll[i].id)
                        .text(this.staffAll[i].employee_name));
            }                   
        },
        updateGender(value) {
            this.gender == parseInt(value)
            $('#lstBox1').empty()
            var allopt = [],
            options = $('#lstBox2 option'),
            values = $.map(options ,function(option) {
                allopt.push(option.value)
            }),
            allStaff = $('.staff'),
            allStudent = $('.student'),
            allSelectedStaffValue = [],
            allSelectedStudentValue = [];
            for (var i = 0; i < allStaff.length; i++) {
                allSelectedStaffValue.push(parseInt(allStaff[i].value))
            }
            for (var i = 0; i < allStudent.length; i++) {
                allSelectedStudentValue.push(parseInt(allStudent[i].value))
            }
            if(value == 1) {
                for (var i = 0; i < this.staffAll.length; i++) {
                    if(allSelectedStaffValue.indexOf(this.staffAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option></option>")
                                .attr("class","staff")
                                .attr("value",this.staffAll[i].id)
                                .text(this.staffAll[i].employee_name));
                    }
                }                
            } else if(value == 2) {
                for (var i = 0; i < this.studentAll.length; i++) {
                    if(allSelectedStudentValue.indexOf(this.studentAll[i].id) == -1) {
                        $('#lstBox1')
                            .append($("<option></option>")
                                .attr("class","student")
                                .attr("value",this.studentAll[i].id)
                                .text(this.studentAll[i].student_name));
                    }
                }                  
            }
        },
        moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')             
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
        },
        moveToTheLeft() {
            if(this.gender == 1) {
                var selectedOpts = $('#lstBox2 option.staff:selected');
            } else if(this.gender == 2) {
                var selectedOpts = $('#lstBox2 option.student:selected');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')               
            }
            $(selectedAllOpts).remove();
        },
        moveAllLeft() {
            if(this.gender == 1) {
                var selectedOpts = $('#lstBox2 option.staff');
            } else if(this.gender == 2) {
                var selectedOpts = $('#lstBox2 option.student');
            }
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
            }
            $(selectedAllOpts).remove();
        },
        updateMemberType(e) {
            if(e.member_type == 1) {
                this.staffSeen = true
                this.fetchStaffAll();
            } else if(e.member_type == 2) {
                this.staffSeen = false
                this.fetchStudentAll();
            }
        },
        sentSmsOnDynamicTemplate() {
            var allEmployeeMembers = [],
            allStudentMembers = [];
            $('#lstBox2 option.staff').each(function() {
                allEmployeeMembers.push({'name': $(this).text(), 'id': $(this).val()})
            });
            $('#lstBox2 option.student').each(function() {
                allStudentMembers.push({'name': $(this).text(), 'id': $(this).val()})
            });
            var params = {
                'group_name': this.groupName,
                'allEmployeeMembers': allEmployeeMembers,
                'allStudentMembers': allStudentMembers
            }
            this.$validator.validate().then(result => {
                if($('#lstBox2 option').length == 0) {
                    this.$eventHub.$emit('add-at-least-one-member')
                }
                if(result && $('#lstBox2 option').length > 0) {
                    this.buttonLoading = true;                    
                    this.sendSmsOnDynamicTemplateStore(params)
                        .then((res)=>{
                            if(res.data.error_message)
                            {
                                this.$validator.errors.add({
                                    id: 'group_name',
                                    field: 'group_name',
                                    msg: res.data.message,
                                    scope: this.$options.scope,
                                });
                                return '';
                            }                            
                            this.$eventHub.$emit('create-success')
                            this.resetForm()
                            this.$router.push({name: 'sms_on_dynamic_template.index'})
                        })
                        .catch(()=>{
                            this.$eventHub.$emit('sms-not-sent')
                        })
                        .finally(() => {
                            this.buttonLoading = false
                        })                        
                }
                else {
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }
            })
        },
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>