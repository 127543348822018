<template>
    <div class="popup-form add-country">
        <b-modal id="batchHoldHistoryModel"
                ref="batchHoldHistoryModel"
                title="Batch Hold History"
                okTitle="Save"
                okVariant="success"
                @shown="modalOpened"
                hide-footer
                size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
                <div class="container-fluid">  
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="table-responsive setting-group">
                                    <vue-good-table
                                        ref="table1"
                                        styleClass="vgt-table table table-bordered striped thead-dark"
                                        mode="remote"
                                        @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange"
                                        @on-column-filter="onColumnFilter"
                                        @on-per-page-change="onPerPageChange"
                                        @on-search="onSearch"
                                        :totalRows="totalRecords"
                                        :sort-options="{
                                            enabled: false,
                                        }"
                                        :pagination-options="{
                                            enabled: true,
                                            perPage:10,
                                            perPageDropdown: [10, 20, 40, 80, 100],
                                            dropdownAllowAll: false,
                                        }"
                                        :isLoading.sync="isLoading"
                                        :select-options="{ 
                                            enabled: false,
                                        }"
                                        :search-options="{
                                            enabled: false,
                                            placeholder: 'search',
                                        }"
                                        :rows="rows"
                                        :columns="columns"
                                        >
                                            <div slot="emptystate" class="text-center">
                                            <span v-if="!isLoading">{{'No Records Found.'}}</span>
                                        </div>
                                        <template
                                            slot="table-row"
                                            slot-scope="props"
                                        >
                                        <span v-if="props.column.field == 'actions'">
                                            <HoldHistoryActions
                                                @loadItems="loadItems"
                                                :xprops="xprops"
                                                :row="props.row"
                                                @holdHistoryIdModel1="holdHistoryIdModel1"
                                                @modalClose="modalClose"
                                                >
                                            </HoldHistoryActions>
                                        </span>
                                        <span v-else>
                                            {{props.formattedRow[props.column.field]}}
                                        </span>
                                        </template>
                                        <template slot="loadingContent">
                                            <CustomLoader></CustomLoader>
                                        </template>
                                    </vue-good-table>
                                </div>
                                <HoldHistoryShow 
                                    @loadItems="loadItems"
                                    v-if="pageName == 'history'" 
                                    :history_id="history_id"
                                    >
                                </HoldHistoryShow>
                            </div>
                        </div>
                    </div>
                </div>
        </b-modal>
    </div>
</template>

<script>
import components from '../../comps/'
import CustomLoader from '@/components/CustomLoader'
import HoldHistoryShow from './HoldHistoryShow'
import HoldHistoryActions from '../../dtmodules/DatatableActions/HoldHistoryActions'

HoldHistoryActions.attachStore('BatchSingle');

export default {
    components,
    components: {
        HoldHistoryShow,
        HoldHistoryActions,
        CustomLoader,
    },
    props: ['batchId', 'student_id', 'history_id'],
    data() {
        return {
            isLoading: true,
            batchLoad:false,
            modalShow:false,
            pageName:null,
            holdHistoryIdModel:null,
            columns: [
                {
                    label: "Course Name",
                    field: "course_name",
                },
                {
                    label: "Start Date",
                    field: "start_date",
                },
                {
                    label: "End Date",
                    field: "end_date",
                },
                {
                    label: "Hold Date",
                    field: "hold_date",
                },
                {
                    label: "Resume Date",
                    field: "resume_date",
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }
            ],
            rows: [],
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                domainName: window.location.origin,
                module: 'BatchSingle',
                route: 'student-batch',
                permission_prefix: 'student_batch_'
            },
        }
    },
    created() {
        this.holdHistoryData()
    },
    watch: {
        "$route.params.id": function() {
            this.holdHistoryData()
            this.$refs.batchHoldHistoryModel.show();
        },
        historyData:function(){

        },
        
    },
    computed: {
        
    },
    methods: {
        modalOpened() {
            this.holdHistoryData()
        },
        modalClose(){
            this.$refs.batchHoldHistoryModel.hide();
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.holdHistoryData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.holdHistoryData();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params[0].type,
                    field: params[0].field
                }],
                page: 1
            });
            this.holdHistoryData();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.updateParams({page:1});
            this.holdHistoryData();
        },
        onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.holdHistoryData();
        },
        holdHistoryData(flag=0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.batchLoad = true;
                }
            axiosLms.get("/api/v1/history/"+this.batchId, {
            params: {
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
            });
        },
        holdHistoryIdModel1(id, page) {
            this.holdHistoryIdModel = id;
            this.$emit('getId', id)
            this.history_id=id;
            this.pageName = page;
        },
        loadItems(p_id,flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.batchLoad = true;
                }
            axiosLms.get("/api/v1/history/show/" + p_id, {
            params: {
                history_id:this.$route.params.id,
                max: this.serverParams.perPage,
                page: this.serverParams.page,
                search: search,
                field: field,
                columnFilters:this.serverParams.columnFilters
            }
            })
            .then(response => {
                let res = response.data;
                if(res.result){
                    this.rows = res.data.data;
                    this.totalRecords = res.data.count;
                    if(flag == 1){
                        this.batchLoad = false;
                    }
                }
                else{
                    this.rows = [];
                    this.totalRecords = 0;
                    if(flag== 1){
                        this.batchLoad = false;
                    }
                }
            });
        },
    },
}
</script>