<template>
  <div class='topicModal'>
  <div class="popup-form ">
    <span style="cursor: pointer;" title="View Assessment Details" @click="getData(rows)" v-if="rows.from_schedule_listing && rows.from_schedule_listing == true" :class="rows.topic_class" :title="rows.topic_title">
        {{rows.topic_text}}    
    </span>
    <span v-else class="btn btn-sm btn-primary fa fa-eye" @click="getData(rows)"  title="View Assessment Details"></span>
    
    <b-modal id="modalPrevent"
             ref="viewAssessmentModal"
             title="View Assessment Details"
             modal-class=""
            ok-only
            @ok="handleOk"
            okTitle="Close"
            okVariant="danger"
            no-close-on-backdrop
            no-close-on-esc
            centered
            hide-header-close             
             >
      <!--@shown="clear"-->
      <div class="container-fluid topic-modal-class">  
        <div class="row">
          <div class="col-sm-12 col-md-12">
                <div class="col-xs-4 col-xs-offset-4" v-if="loading">
                    <div class="alert text-center">
                        <i class="fa fa-spin fa-refresh"></i> Loading Assessment Details...
                    </div>
                </div>
                <div v-if="!loading && assessmentDetails && assessmentDetails.detail && assessmentDetails.detail.studentTopicStatusIndex">
                    <div v-for="(outerValue, outerIndex) in assessmentDetails.detail.studentTopicStatusIndex">
                        <div v-if="assessmentDetails && assessmentDetails.detail && assessmentDetails.detail.isAssessmentRequired[outerIndex] == 1">
                            <div v-if="assessmentDetails && assessmentDetails.questioList && !isEmpty(assessmentDetails.questioList) && Object.keys(assessmentDetails.questioList[computedOuterValue(outerValue)]).length > 0">
                                <div class="question_ans">
                                    <div class="box box-solid">
                                        <div class="box-header with-border box-custom-header">
                                            <h3 class="box-title">
                                                Course Topic: 
                                                <strong>{{assessmentDetails.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                                            </h3>
                                        </div>
                                        <div class="box-body">
                                            <div class="left-30" v-for="(topicAssessmentDetail,keys,topicIndex) in assessmentDetails.questioList[computedOuterValue(outerValue)]">
                                                <h4>{{++topicIndex}}. {{keys}}</h4>
                                                <ul>
                                                    <li class="row" v-for="(assessment,sub_keys) in topicAssessmentDetail">
                                                        <span class="col-md-7 col-xs-12">Q{{sub_keys+1}}. {{assessment.my_question_name}}</span> 
                                                        <span class="col-md-5 col-xs-12">
                                                            <div class="res-icon">
                                                                <a :class="(assessment.my_answer_name == 1)? 'active':''"><img src="/assets/images/happy.png" alt="happy"></a>
                                                                <a :class="(assessment.my_answer_name == 2)? 'active':''" ><img src="/assets/images/Partial.png" alt="Partial"></a>
                                                                <a :class="(assessment.my_answer_name == 3)? 'active':''"><img src="/assets/images/sad.png" alt="sad"></a>
                                                            </div>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-else>
                                <div class="box">
                                    <div class="box-header with-border">
                                            <h3 class="box-title fa fa-plus">
                                                Course Topic: 
                                                <strong>{{assessmentDetails.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                                            </h3>
                                    </div>
                                    <div class="box-body">
                                        <h3>&#9785;</h3>
                                        <h4>This topic is marked as assessment required and no assessment questions are added!</h4>
                                        <h5>Please add assessment question for this topic first.</h5>
                                    </div>                                    
                                </div>                                    
                            </div>
                        </div>
                        <div class="box-header with-border" v-if="assessmentDetails.detail.my_topic_id[computedOuterValue(outerValue)] == null">
                            <h3 class="box-title">
                                Course Topic: 
                                <strong>{{assessmentDetails.detail.my_topic_name[computedOuterValue(outerValue)]}}</strong>
                            </h3>
                        </div>                           
                        <div class="col-md-10 col-md-offset-1">
                            <label for="assessment_remark">Status Remark:</label>
                            <textarea
                                rows="2"
                                class="form-control"
                                name="assessment_remark"
                                placeholder="No Status Remark Added"
                                disabled
                                :value="assessmentDetails.detail && assessmentDetails.detail.my_topic_status_remark[computedOuterValue(outerValue)]"
                                >
                            </textarea>                        
                        </div>                                                  
                    </div>
                </div>
                <div class="col-xs-4 col-xs-offset-4" v-if="!loading && assessmentDetails.length == 0">
                    <div class="alert text-center">
                        No data found
                    </div>
                </div>                
          </div>
        </div>
        
      </div>
      
    </b-modal>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
function dd(...args){
  for (let i in args){
    console.log(args[i]);
  }
}
export default {
  props:['rows'],
  data () {
    return {
      // row:null,
      assessmentDetails: [],
      loading: false,
    }
  },
   // created() {
   //    this.row = this.rows
   // },   
  methods: {
    computedOuterValue(outerValue) {
        return "'"+outerValue+"'";
    },
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    },    
    handleCancel(evt){
       this.$refs.modal.hide()
    },    
    handleOk (evt) {
      this.assessmentDetails = []
      this.$refs.viewAssessmentModal.hide()
    },
    getData(data){
      this.$refs.viewAssessmentModal.show()
      	this.loading = true;
       axios.post('/api/v1/viewAssessmentDetailsOfTopic', data)
	       .then(response => {
	           this.assessmentDetails = response.data.data
	       })     
	       .catch(function (error) {
	            console.log('Error', error.message);
	        }) 
	       .finally(() => {
	         	this.loading = false;
	       })        
    },
    
  }
}
</script>
<style scoped>
  .topicModal .popup-form{
      position: relative;
      top: unset;
      right: unset;
  }
  .topic-modal-class{
      height: 400px;
      overflow: auto;
  }
  .student-name {
      cursor: pointer;
      color: green;
  }
  .topicModal{
      float: left;
      width: 15px;
      padding: 0px 4px;
  }
</style>
