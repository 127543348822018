<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Course Fee</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Course Fee List</h3>
                        </div>

                        <div class="box-body">
                            <div class="btn-group">
                                <router-link
                                        v-if="$can(xprops.permission_prefix + 'create')"
                                        :to="{ name: xprops.route + '.create' }"
                                        class="btn btn-success btn-sm"
                                        >
                                    <i class="fa fa-plus"></i> Add New
                                </router-link>

                                <button type="button" class="btn btn-default btn-sm" @click="fetchData">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>       
                            </div>
                        </div>
                          <div class="box-body" >
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">Branch Name</label>
                                 <v-select
                                    name="search_branch_name"
                                    label="branch_name"
                                    :value="null"
                                    v-model="date_filter.search_branch_name"
                                    :options="branchMastersAll"
                                    />                                  
                            </div> 
                            <div class="col-md-4 col-sm-4">
                                <label for="from_date">Course Name</label>
                                  <v-select
                                    name="search_course_name"
                                    label="course_name"                                           
                                    :value="null"
                                    v-model="date_filter.search_course_name"
                                    :options="courseListWithDistinctAll"        
                                    />                                
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <button type="button" class="btn btn-success m-t-25" @click="searchCourseData()">Search</button>
                            
                            </div>
                        </div>
                        <bootstrap-alert />
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive setting-group">
                                <datatable
                                        v-if="!loading"
                                        :columns="columns"
                                        :data="data"
                                        :total="total"
                                        :query="query"
                                        :xprops="xprops"
                                        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import FeeActions from '../../dtmodules/DatatableActions/FeeActions'
import DatatableSingle from '../../dtmodules/DatatableSingle'
import DatatableList from '../../dtmodules/DatatableList'
import DatatableCheckbox from '../../dtmodules/DatatableCheckbox'
import components from '../../comps/'


FeeActions.attachStore('FeesMastersIndex');
export default {
    components,
    FeeActions,
    data() {
        return {
            columns: [
                { title: 'Branch Name', field: 'branch_name',sortable: true, thComp: 'FilterTh', tdClass: 'Branch Name'},
                { title: 'Course Name', field: 'course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name'},
                // { title: 'Fee Type', field: 'feesTypeName', sortable: true, thComp: 'FilterTh', tdClass: 'Fee Type'},
                { title: 'Course Type', field: 'course_type', sortable: true, thComp: 'FilterTh', tdClass: 'Fee Type'},

                { title: 'From Date', field: 'original_format_date', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },                
                { title: '', field: 'original_date', thClass: 'inqStats', tdClass: 'Fee Amount' },

                { title: 'Upto Date', field: 'original_format_date_a', sortable: true, thComp: 'FilterTh', tdClass: 'Holiday Date inqStats' },                
                { title: '', field: 'original_date_a', thClass: 'inqStats', tdClass: 'Fee Amount' },
                // { title: 'Fee Amount', field: 'course_fee_amount', sortable: true, thComp: 'FilterTh', tdClass: 'Fee Amount' },
                // { title: 'GST Rate(%)', field: 'gst_rate', sortable: true, thComp: 'FilterTh'},
                // { title: 'Total Fees', field: 'total_fees', sortable: true, thComp: 'FilterTh'},
                { title: 'Actions', tdComp: FeeActions, visible: true, thClass: 'text-right', tdClass: '', colStyle: '', tdStyle:'text-align: right' }
            ],

            query: { /*sort: 'id', order: 'desc'*/ },
            xprops: {
                module: 'FeesMastersIndex',
                route: 'fees_masters',
                permission_prefix: 'fees_master_'
            },
            date_filter : {search_branch_name : null, search_course_name : null}            
        }
    },
    created() {
        this.$root.relationships = this.relationships
        this.fetchData()
        this.fetchCourseListWithDistinctAll()
        this.fetchBranchMastersAll()
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('FeesMastersIndex', ['data', 'total', 'loading', 'relationships','courseListWithDistinctAll','branchMastersAll']),
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('FeesMastersIndex', ['fetchData', 'fetchCourseListWithDistinctAll', 'fetchBranchMastersAll', 'setQuery', 'resetState']),
        resetFilters(){
            var filtersField= [];
            filtersField = ['branch_name','course_name','course_type','original_date','original_date_a','original_format_date','original_format_date_a'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.query = {};
            this.date_filter.search_branch_name = null;
            this.date_filter.search_course_name = null;
            this.fetchData()
        },
        searchCourseData() {
            this.query = {};            
            // console.log(this.date_filter.search_branch_name)
            if (this.date_filter.search_branch_name && this.date_filter.search_branch_name.branch_name) {
                this.date_filter.search_branch_name = this.date_filter.search_branch_name.branch_name;    
            }
            if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
                this.date_filter.search_course_name = this.date_filter.search_course_name.course_name;    
            }
            this.fetchData(this.date_filter);
        }
    }
}
</script>


<style scoped>

</style>
