<template> 
    <div class="project-card">
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
            <div class="col-md-12">
                <bootstrap-alert />
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <label for="project_name">Project Name<p class="astrisk">*</p></label>
                <input
                        type="text"
                        class="form-control"
                        name="project_name"
                        ref="project_name"
                        maxlength="150"
                        v-validate="'required'"
                        placeholder="Enter Project Name"
                        v-model="form_data.project_name"
                        >
                <span class="error" v-show="errors.has('project_name')">
                    {{ $root.updateErrorMessage('project_name',this.errors,'Project Name')}}
                </span>                                            
            </div> 
            <div class="form-group col-md-6 col-xs-12">
                <label for="project_type">Project Type<p class="astrisk">*</p></label>
                    <v-select
                    name="project_type"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Type"
                    v-model="form_data.project_type"
                    :options="projectTypes"
                    />
                <span class="error" v-show="errors.has('project_type')">
                    {{ $root.updateErrorMessage('project_type',this.errors,'Project Type')}}
                </span>                                            
            </div>  

            <div class="form-group col-md-6 col-xs-12">
                <label for="project_brand">Project Brand<p class="astrisk">*</p></label>
                    <v-select
                    name="project_brand"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Brands"
                    v-model="form_data.project_brand"
                    :options="projectBrands"
                    multiple
                    />
                <span class="error" v-show="errors.has('project_brand')">
                    {{ $root.updateErrorMessage('project_brand',this.errors,'Project Brand')}}
                </span>                                            
            </div>    

            <div class="form-group col-md-6 col-xs-12">
                <label for="project_kit">Project Kit<p class="astrisk">*</p></label>
                    <v-select
                    name="project_kit"
                    label="name"
                    value="id"
                    v-validate="'required'"
                    placeholder="Select Project Brands"
                    v-model="form_data.project_kit"
                    :options="projectKits"
                    multiple
                    />
                <span class="error" v-show="errors.has('project_kit')">
                    {{ $root.updateErrorMessage('project_kit',this.errors,'Project Kit')}}
                </span>                                            
            </div>       
            <div class="form-group col-md-12 col-xs-12">
                <label for="project_hours">Project Hours<p class="astrisk">*</p></label>
                    <input
                        type="text"
                        class="form-control"
                        name="project_hours"
                        ref="project_hours"
                        maxlength="6"
                        v-validate="'required|min_value:1'"
                        placeholder="Enter Project Hours"
                        v-model="form_data.project_hours"
                        >
                <span class="error" v-show="errors.has('project_hours')">
                    {{ $root.updateErrorMessage('project_hours',this.errors,'Project Hours')}}
                </span>                                            
            </div>             
                <div class="form-group col-md-12 col-xs-12">
                <label for="description">Description<p class="astrisk">*</p></label>
                    <textarea
                        rows="3"
                        class="form-control"
                        name="description"
                        ref="description"
                        v-validate="'required'"
                        placeholder="Enter Description"
                        v-model="form_data.description"
                        ></textarea>
                <span class="error" v-show="errors.has('description')">
                    {{ $root.updateErrorMessage('description',this.errors,'Description')}}
                </span>                                            
            </div>                          
            <div class="col-md-12 col-md-12 savebtn">
                <vue-button-spinner
                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                        :isLoading="loading"
                        :disabled="loading"
                        >
                        Save
                </vue-button-spinner>
            </div> 
            </div>
        </form>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
            form_data:{
               id:'',
               project_name:'',
               project_type:[],
               project_brand:[],
               project_kit:[],
               description:''
           }      
        }
    },
    computed: {
        ...mapGetters('ProjectsSingle', ['projectDetail', 'loading','projectTypes','projectBrands','projectKits']),
    },
    created() {
        this.fetchTypes();
        this.fetchEditData(this.$route.params.id);
    },
    // destroyed() {
    //     this.resetState()
    // },
    watch: {
        "projectDetail":function(value){
           // console.log(value);
            this.form_data.id = value.project.id;
            this.form_data.project_name = value.project.project_name; 
            this.form_data.project_type = value.project_type;
            this.form_data.project_hours = value.project.project_hours; 
            this.form_data.description = value.project.description; 
            this.form_data.project_kit = value.project_kits;
            this.form_data.project_brand = value.project_brands;
        },
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
            this.getListing(this.$route.params.id);
        },
    },
    methods: {
        ...mapActions('ProjectsSingle', ['fetchEditData', 'updateData','fetchTypes']),
        submitForm() {
          this.$validator.validate().then(result => {
             if (result) {
            this.updateData(this.form_data)
                .then((res) => {
                    console.log(res.data.result);
                    if(!res.data.result)
                    {
                         for (const [key, value] of Object.entries(res.data.data)) {
                            console.log("ke",key,value);
                            this.$validator.errors.add({
                                id: key,
                                field: key,
                                msg: value[0],
                                scope: this.$options.scope,
                            });
                        }
                        this.buttonLoading = false
                        return '';
                    }
                                     
                    this.$router.push({ name: 'projects.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
             }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }
          });
        }
    }
}
</script>


<style scoped>

</style>
