<template>
    <div class="row">
        <bootstrap-alert />
        <div class="form-group col-md-12 col-xs-12">
            <b-btn v-b-modal.modalPrevent2 class="btn btn-success btn-sm float-right">+ Add Skill</b-btn>
            <AddSkill :header_id="header_id" @loadItems="loadItems"></AddSkill>
        </div>
        <div class="table-responsive setting-group">
            <vue-good-table
                ref="table1"
                styleClass="vgt-table table table-bordered striped thead-dark"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :pagination-options="{
                    enabled: true,
                    perPage:10,
                    perPageDropdown: [10, 20, 40, 80, 100],
                    dropdownAllowAll: false,
                }"
                :isLoading.sync="isLoading"
                :select-options="{ 
                    enabled: false,
                }"
                :search-options="{
                    enabled: false,
                    placeholder: 'search',
                }"
                :rows="rows"
                :columns="columns"
            >
                <div slot="emptystate" class="text-center">
                    <span v-if="!isLoading">{{'No Records Found.'}}</span>
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span v-if="props.column.field == 'actions'">
                        <SkillActions @loadItems="loadItems" :xprops="xprops" :row="props.row" @IdModel="IdModel"></SkillActions>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
                <template slot="loadingContent">
                    <CustomLoader></CustomLoader>
                </template>
            </vue-good-table>
        </div>
        <EditSkill :header_id="header_id" :Id="Id" @loadItems="loadItems"></EditSkill>
        <ShowSkill :header_id="header_id" :Id="Id"></ShowSkill>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SkillActions from '../../dtmodules/DatatableActions/SkillActions'
import AddSkill from '../../dtmodules/PopupActions/AddSkill'
import EditSkill from '../../dtmodules/PopupActions/EditSkill'
import ShowSkill from '../../dtmodules/PopupActions/ShowSkill'
import components from '../../comps'
import CustomLoader from '@/components/CustomLoader'

SkillActions.attachStore('SkillMasterIndex');
export default {
    components,
    components: {
        SkillActions,
        AddSkill,
        EditSkill,
        ShowSkill,
        CustomLoader,
    },
    data() {
        return {
            form_data: {
                id: '',
                header_name: ''
            },
            Id: 0,
            isLoading: true,
            refershLoad:false,
            columns: [
                {
                    label: "Skill Name",
                    field: "skill_name",
                    filterOptions: {
                        styleClass: 'skill_master_filter',
                        enabled: true,
                        placeholder: 'Skill Name',
                        filterValue: '',
                        filterDropdownItems: [], 
                    },
                },
                {
                    label: "Actions",
                    field: "actions",
                    sortable: false
                }

            ],
            rows: [],
            header_id:'',
            totalRecords: "",
            serverParams: {
                columnFilters: {},
                sort: [
                {
                    field: "",
                    type: ""
                }
                ],
                page: 1,
                perPage: 10
            },
            xprops: {
                module: 'SkillMasterIndex',
                route: 'skill-master',
                permission_prefix: 'skill_master_'
            }
        }
    },
    computed: {
        ...mapGetters('SkillMasterSingle', []),
    },  
    created(){
        this.header_id = this.$route.params.id
    },
    methods: {
        ...mapActions('SkillMasterSingle', []),

        IdModel(id, page){
            this.Id = id;
            this.pageName = page;
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage, page: 1 });
            this.loadItems();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params[0].type,
                        field: params[0].field
                    }
                ],
                page: 1
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.updateParams({page:1});
            this.loadItems();
        },
        onSearch(params) {
            params.page = 1;
            this.updateParams(params);
            this.loadItems();
        },
        loadItems(flag = 0) {
            var order = "";
            var field = "";
            if (
                this.serverParams.sort[0].type != "" &&
                this.serverParams.sort[0].type != "none"
            ) {
                order = this.serverParams.sort[0].type;
                field = this.serverParams.sort[0].field;
            }
            let search = this.serverParams.searchTerm
                ? this.serverParams.searchTerm
                : "";
                if(flag== 1){
                    this.refershLoad = true;
                }
            axiosLms
            .get('/api/v1/getSkillDetails/' + this.$route.params.id, {
                params: {
                    max: this.serverParams.perPage,
                    page: this.serverParams.page,
                    search: search,
                    order: order,
                    field: field,
                    columnFilters:this.serverParams.columnFilters,
                }
            })
            .then(response => {
                let res = response.data
                if(res.result) {
                    this.rows = res.data.data
                    this.totalRecords = res.data.count
                    if(flag== 1) {
                        this.refershLoad = false
                    }
                }
                else{
                    this.rows = []
                    this.totalRecords = 0
                    if(flag== 1) {
                        this.refershLoad = false
                    }
                }
            });
        },
        resetFilters() {
            this.serverParams.columnFilters = {};
            let columnFilters = {"header_name":""};
            this.updateParams(columnFilters);
            this.updateParams({page:1});
            this.$refs.table1.reset();
            this.loadItems(1);
        }
    }
}
</script>

<style scoped>

</style>
