function initialState() {
    return {
        all: [],
        relationships: {
            'country_list': 'country_name',
            'state_list': 'state_name',
            'city_list': 'city_name',
            'parent': 'name',
            'school': 'school_name',
            'standard': 'name',
            'student_status': 'name',
            'student_reason': 'name',
            'fees_type': 'name',
            'status': 'name',
            'reason': 'name',
            'tshirt_sizes': 'name'
        },
        query: {},
        excelFile: null,
        excelUpdateDetails: [],
        loading: false,
        excelLoader: false,
        universalFlag: true,
        studentInquiryId:''
    }
}

const getters = {
    studentInquiryId: state => state.studentInquiryId,
    loading: state => state.loading,
    excelLoader: state => state.excelLoader,
    excelUpdateDetails: state => state.excelUpdateDetails,
    universalFlag: state => state.universalFlag,
    relationships: state => state.relationships
}

const actions = {
    async exportReferenceDataListStore({ commit }) {
      window.location.href = location.protocol + "//" + location.host + '/exportReferenceDataList';
    },     
    fetchData({ commit, state, dispatch }, date_filter) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/student',{params:date_filter})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    async exportStudentListStore({ commit }) {
      window.location.href = location.protocol + "//" + location.host + '/exportStudentList';
    },    
    async importingExcelStore({ commit, state, dispatch }) {
        commit('setExcelLoader', true)
        let params = new FormData();
        params.set('excelFile', state.excelFile)
        return new Promise((resolve, reject) => { 
            axios.post('/api/v1/import', params)
                .then((response) => {
                  if(response.data.data[0] == false) {
                    commit('setUniversalFlag',response.data.data[0]);
                    resolve(response);
                  } else {
                    if(response && response.data && response.data.data) {
                        window.location.href = location.protocol + "//" + location.host + '/export/' + response.data.data[0];
                    }
                      resolve(response);  
                  }
                })            
                .catch(function (error) {
                              console.log('Error', error.message);
                              reject(error)
                        }) 
                .finally(() => {
                    commit('setExcelLoader', false)         
                })            
        })            
    },
    async fetchExcelUpdateDetails({ commit, state, dispatch }, id) {
        await axios.get('/api/v1/fetchExcelUpdateDetails/'+id)
            .then(response => {
                commit('setExcelUpdateDetails', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    })     
            .finally(() => {
              if(state.universalFlag) {
                 setTimeout(function () { dispatch('deleteTempExcelData'); }.bind(this), 240)
              }
            })              
    },      
    async deleteTempExcelData({ commit, state, dispatch }, uploadId) {
        commit('setLoading', true)
        await axios.delete('/api/v1/deleteTempExcelData')
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })            
    },    
    destroyData({ commit, state }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {        
            axios.delete('/api/v1/student/' + id)
                .then(response => {

                    // var rows = state.all
                    // rows = rows.filter((item) => {
                    //     return item.id != id
                    // })
                    // commit('setAll', rows);
                    
                    // var queryLocal = purify(state.query);
                    // if(rows.length == queryLocal.offset && rows.length != 0)
                    // {
                    //     queryLocal.offset = queryLocal.offset - queryLocal.limit;  
                    //     commit('setQuery', queryLocal)
                    // }
                    resolve(response);
                    })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                    .finally(() => {
                        commit('setError', false)
                        commit('setLoading', false)
                    })
        })
    },
    setStudentInquiryId({commit},value){
      commit('setStudentInquiryId', value)
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    setExcelFile({ commit }, value) {
        commit('setExcelFile', value)
    },
    setExcelUpdateDetails({ commit }, value) {
        commit('setExcelUpdateDetails', value)
    },         
    setUniversalFlag({ commit }, value) {
        commit('setUniversalFlag', value)
    }, 
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setStudentInquiryId(state,item){
      state.studentInquiryId = item 
    },
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setExcelLoader(state, loading) {
        state.excelLoader = loading
    },
    setExcelUpdateDetails(state, value) {
        state.excelUpdateDetails = value
    },    
    setUniversalFlag(state, value) {
        state.universalFlag = value
    },
    setQuery(state, query) {
        state.query = query
    },
    setExcelFile(state, value) {
        state.excelFile = value
    },
    setError(state, error) {
        state.error = error
    },     
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
