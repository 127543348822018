<template>
    <div class="popup-form add-country">
        <b-modal id="editCourseCaseStudyModel"
            ref="modal"
            title="Edit Course Case Study"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"
            @cancel="handleCancel"
            hide-footer
            no-enforce-focus
            @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true"
        >
            <EditCaseStudy @loadItems="loadItems" :editId="editId" v-if="editId!=''" @closeModel="closeModel"></EditCaseStudy>
        </b-modal>
    </div>
</template>

<script>
import EditCaseStudy from '@/components/cruds/Course/CaseStudy/EditCaseStudy'
export default {
    props:['editId'],
    components:{
        EditCaseStudy
    },
    data() {
        return {
        };
    },
    methods:{
        handleCancel(evt){
           
        },    
        handleOk (evt) {
            
        },
        modalOpened() {
            
        },
        closeModel(){
            this.$refs.modal.hide();
        },
        loadItems(){
            this.$emit('loadItems');
        }
    }
}
</script>