<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Batches</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/batch-masters'">Batches</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Batch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View Batch</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12 table-responsive">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                        <tr>
                                            <th>Batch Name</th>
                                            <td>{{ getShowBatchData.batch_name }}</td>
                                            </tr>
                                        <tr>
                                            <th>Branch Lab</th>
                                            <td>{{getShowBatchData.branch_lab.lab_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>Course Vertical</th>
                                            <td>{{getShowBatchData.course_vertical.name}}</td>
                                        </tr>
                                        <tr>
                                            <th>Allowed Course Category</th>
                                            <td><span v-for="course_category in getShowBatchData.course_category" :key="course_category.id">{{course_category.name}}<br></span></td>
                                            </tr>
                                        <tr>
                                            <th>Batch Start Time</th>
                                            <td>{{ getShowBatchData.formatted_start_time }}</td>
                                            </tr>
                                        <tr>
                                            <th>Batch End Time</th>
                                            <td>{{ getShowBatchData.formatted_end_time }}</td>
                                            </tr>
                                        <tr>
                                            <th>Batch Days</th>
                                            <td>{{ getDayName(getShowBatchData.working_days) }}</td>
                                            </tr>
                                        <tr>
                                            <th>Tutors</th>
                                            <td><span v-for="tutor in getShowBatchData.tutor" :key="tutor.id">{{tutor.employee_name}}<br></span></td>
                                            </tr>
                                        </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        // this.fetchData(this.$route.params.id)
        this.fetchShowBatchDetail(this.$route.params.id);
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('BatchMastersSingle', ['getShowBatchData','item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('BatchMastersSingle', ['fetchData', 'resetState','fetchShowBatchDetail']),
        getDayName(value){
            let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
            let str = '';
            value = value.split(',');
            value.forEach(function(item,index) {
                // console.log(item,index);
                str += days[item-1];
                if(value.length != index+1){
                    str += ', ';
                }
            });   
            //console.log(str);   
            return str;     
        }
    }
}
</script>


<style scoped>

</style>
