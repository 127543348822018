<template> 
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Batch Wise Student Listing</h1>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <form @submit.prevent="submitForm" novalidate>                    
                        <div class="box">
                            <div class="box-header with-border">
                                <h3 class="box-title">Batch Wise Student Listing</h3>
                            </div>
                            <div class="box-body">
                                <div class="col-md-6 col-xs-12">
                                    <div ref="date" class="form-group">
                                        <label for="date">Date<p class="astrisk">*</p></label>
                                        <date-picker
                                                :value="item.date"
                                                :config="$root.dpconfigDate"
                                                name="date"
                                                v-validate="'required'"
                                                placeholder="Select Date"
                                                @dp-change="updateDate"
                                                autocomplete="off"
                                                >
                                        </date-picker>
                                         <span class="error" v-show="errors.has('date')">
                                            {{ $root.updateErrorMessage('date',this.errors,'Date')}}
                                        </span>
                                    </div>
                                </div>                        
                                <div class="col-md-6 col-xs-12">
                                    <div ref="branch" class="form-group">
                                        <label for="branch">Branch Name<p class="astrisk">*</p></label>
                                        <v-select
                                                name="branch"
                                                label="branch_name"
                                                @input="updateBranch"
                                                v-validate="'required'"
                                                :value="item.branch_id"
                                                :options="branchMastersAll"
                                                />
                                        <span class="error" v-show="errors.has('branch')">
                                            {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                        </span>
                                    </div>
                                </div>                        
                               <div class="col-md-6 col-xs-12">
                                     <div ref="lab" class="form-group">
                                        <label for="lab">Lab Name</label>
                                        <v-select
                                              name="lab"
                                              label="lab_name"
                                              @input="updateLab"
                                              :value="item.lab_id"
                                              :options="labsAll" 
                                            />
                                        <span class="error" v-show="errors.has('lab')">
                                            {{ $root.updateErrorMessage('lab',this.errors,'Lab Name')}}
                                        </span>
                                    </div>
                                </div>                        
                                <div class="col-md-6 col-xs-12">
                                     <div ref="batch" class="form-group">
                                        <label for="batch">Batch Name</label>
                                        <v-select
                                            id="batch"
                                            name="batch"
                                            label="batch_name"
                                            :value="item.batch_id"
                                            @input="updateBatchId"
                                            :options="batchesAll"
                                            />
                                    </div>
                                </div>
                                <div class="form-group col-md-4 col-xs-12">
                                    <label for="gender">Other Options</label>
                                    <br>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                    type="checkbox"
                                                    name="workingDay"
                                                    :value="1"
                                                    :checked="item.onlyDueAttendance == true"
                                                    @change="updateOnlyAttendance"
                                                    >
                                            &nbsp;Attendance not done
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                    type="checkbox"
                                                    name="workingDay"
                                                    :value="1"
                                                    :checked="item.onlyDueAssessment == true"
                                                    @change="updateOnlyAssessmentDue"
                                                    >
                                            &nbsp;Assessment not done
                                        </label>
                                    </div>
                                </div>                                   
                                <!-- <div class="col-md-6 col-xs-12">
                                     <div ref="trainer" class="form-group">
                                        <label for="trainer">Trainer Name</label>
                                        <v-select
                                            id="trainer"
                                            name="trainer"
                                            label="employee_name"
                                            :value="item.trainer_id"
                                            @input="updateTrainerId"
                                            :options="trainerAll"
                                            />
                                    </div>
                                </div>  -->                       
                                </div>                                                            
                            </div>
                        <div class="col-md-12 col-xs-12" style="text-align:center">
                            <vue-button-spinner
                                    class="std_information_save btn btn-block btn-success new_branch_save"
                                    >
                                Go
                            </vue-button-spinner>
                        </div>    
                    </form>      
                    </div>
                </div>
            </div>
            <br>
            <div class="row"  v-if="dataSeen">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-body">
                            <!-- <div class="btn-group">

                                <button type="button" class="btn btn-default btn-sm" @click="getBatchWiseStudentDetails">
                                    <i class="fa fa-refresh" :class="{'fa-spin': loading}"></i> Refresh
                                </button>     
                                <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                                    <i class="fa fa-undo" :class="{'fa-spin': loading}"></i> Reset Filters
                                </button>                            
                            </div> -->
                        </div>
                        <div class="box-body">
                            <div class="row" v-if="loading">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <div class="alert text-center">
                                        <i class="fa fa-spin fa-refresh"></i> Loading...
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="!loading">
                                <div style="float: right;">
                                      <div class="Color_Box_Div" style="background-color: #fcf8e3; border: 1px solid #d4cda8;"></div>
                                      <span>Previous Assessment and attendance due</span>
                                </div><br>
                                <div style="float: right;">
                                      <div class="Color_Box_Div" style="background-color: #f2dede; border: 1px solid #0000002b;"></div>
                                      <span>Attendance due</span>
                                </div><br>                                
                                <div style="float: right;">
                                      <i class="fa fa-remove"></i>
                                      <span>&nbsp;Not Done</span>
                                </div><br>
                                <div style="float: right;">
                                      <i class="fa fa-check"></i>
                                      <span>&nbsp;Done</span>
                                </div>                                                                                                           
                                <div v-for="(key,value) in listingAll">
                                    <div style="font-weight: bold;
                                        float: left;
                                        font-size: 1.3em;
                                        border: 1px solid #d2d6de;
                                        padding: 10px;
                                        box-shadow: 1px 1px 1px 1px #5a574f91;">
                                       <span>Batch Name:</span> 
                                        <span 
                                         style="
                                            padding-top: 0.4em;
                                            padding-right: 0.6em;
                                            padding-bottom: 0.3em;
                                            padding-left: 0.6em;">
                                            {{value}}
                                        </span>
                                    </div><br><br><br>   
                                    <div class="table-responsive">                                   
                                        <table class="table table-hover">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Student Name</th>
                                                <th>Course Name</th>
                                                <th>Lab Name</th>
                                                <th>Attendance</th>
                                                <th>Topic</th>
                                                <th>Assessment</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr  v-for="(data, k) in key" v-bind:class="{danger: (data.attendance_done != 1), warning: (data.assessment_done != 1 && data.attendance_done != 1)}">
                                                <td class="#">{{k + 1}}</td>
                                                <td class="Student Name"> <StudentName :row="data"></StudentName>
                                                  <!--{{data.student_name}}-->
                                                </td>
                                                <td class="Course Name">{{data.course_name}}</td>
                                                <td class="Lab Name">{{data.lab_name}}</td>
                                                <td class="Attendance" v-if="data.attendance_done == 0"><i class="fa fa-remove"></i></td>
                                                <td class="Attendance" v-else-if="data.attendance_done == 1"><i class="fa fa-check"></i></td>
                                                <td v-else></td>
                                                <td class="Topic">
                                                    <div v-for="(topics, indexNames) in data.topicNamesArray">
                                                        <span v-if="data.topicStatusArray[indexNames] == 1" class="label label-primary">{{topics}}</span>
                                                        <span v-else-if="data.topicStatusArray[indexNames] == 2" class="label label-warning">{{topics}}</span>
                                                        <span v-else class="label label-success">{{topics}}</span>
                                                    </div>
                                                </td>
                                                <td class="Attendance" v-if="data.assessment_done == 0"><i class="fa fa-remove"></i></td>
                                                <td class="Attendance" v-else-if="data.assessment_done == 1"><i class="fa fa-check"></i></td>
                                                <td v-else></td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!loading && listingAll.length <= 0" class="text-center">
                                  <h3>&#9785;</h3>
                                  <h4>Looks like there are no data!</h4>
                            </div>
<!--                             <datatable
                                    v-if="!loading"
                                    :columns="columns"
                                    :data="data"
                                    :total="total"
                                    :query="query"
                                    :xprops="xprops"
                                    /> -->
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import DatatableActions from '../../dtmodules/DatatableActions'
import AttendanceStatus from '../../dtmodules/AttendanceStatus'
import TopicStatusListing from '../../dtmodules/TopicStatusListing'
import components from '../../comps/'
import StudentName from '../../dtmodules/StudentName'
import TopicStatusTags from '../../dtmodules/TopicStatusTags'

export default {
    components: {...components,StudentName},
    AttendanceStatus,
    TopicStatusListing,
    TopicStatusTags,     
    props: ['batchIdFromProps', 'dateFromProps', 'branchIdFromProps', 'labIdFromProps'],    
    data() {
        return {
            items:[],
            counter: 0,
            dataSeen: false,
            columns: [
                { title: 'Student Name', tdComp:StudentName, field: 'student_name', sortable: true, thComp: 'FilterTh', tdClass: 'Student Name' },
                { title: 'Course Name', field: 'course_name', sortable: true, thComp: 'FilterTh', tdClass: 'Course Name' },
                { title: 'Lab Name', field: 'lab_name', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' },
                // { title: 'Trainer Name', field: 'trainer_name', sortable: true, thComp: 'FilterTh', tdClass: 'lab_name' },
                { title: 'Attendance', /*tdComp:AttendanceStatus, */field: 'attendance', sortable: true, thComp: 'FilterTh', tdClass: 'Attendance'},
                // { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'Topic'},
                { title: 'Topic', field: 'topic_name', sortable: true, thComp: 'FilterTh', tdClass: 'inqStats'},
                { title: '', tdComp: TopicStatusTags, thClass: 'inqStats', tdClass: 'Inquiry Status'},

                { title: 'Status', field: 'display_topic_status', sortable: true, thComp: 'FilterTh', tdClass: 'Status'},
            ],
            query: { sort: 'id', order: 'desc' },
            xprops: {
                module: 'BatchWiseStudentListingIndex',
                route: 'batch_wise_student_listing',
                permission_prefix: 'batch_wise_student_listing_'
            }
        }
    },
    created() {
        this.$root.relationships = this.relationships
        // this.fetchCurrentEmployee();         
        if(!this.dateFromProps && !this.$route.query){  
            this.fetchBranchMasterAll().then(()=>{
                this.setBranch(this.branchMastersAll[0]);
            })
        }
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('BatchWiseStudentListingIndex', ['item'/*,'data', 'total'*/, 'loading', 'relationships','listingAll','batchesAll','trainerAll','labsAll','branchMastersAll']),
        // branchsAllComputed() {
        //     if(this.branchMastersAll.length > 1) {
        //         this.branchMastersAll.unshift({
        //             'branch_name': 'All',
        //             'id': '0'
        //         });
        //     }
        //     return this.branchMastersAll;
        // },        
    },
    mounted() {
        var localFlag = true;
        if(this.objectLength(this.$route.query) > 0) {
            localFlag = false;
            this.setDate(this.$route.query.date);
            this.fetchBranchMasterAll().then(()=>{
                    var branchIdLocal = this.$route.query.branch_id,
                    localStudentValue = '';
                    this.branchMastersAll.forEach(function(value, index){
                        if(value.id == branchIdLocal){
                            localStudentValue = value;
                        }
                    });
                    this.setBranch(localStudentValue);
                    var localBatchValue = '';
                    this.fetchBatches(this.$route.query.branch_id).then(()=>{
                        var batchIdLocal = this.$route.query.preferBatchId
                        this.batchesAll.forEach(function(value, index){
                            if(value.id == batchIdLocal){
                                localBatchValue = value;
                            }
                    })             
                this.setBatch_id(localBatchValue)   
                this.dataSeen = true
                setTimeout(function () { this.getBatchWiseStudentDetails(); }.bind(this), 240)
            })
            })
        }        
        if(this.dateFromProps){        
            localFlag = false;
            this.updateBatchId(this.batchIdFromProps);
            this.setDate(this.dateFromProps);
            this.fetchBranchMasterAll().then(()=>{
                    var branchIdLocal = this.branchIdFromProps,
                    localStudentValue = '';
                    this.branchMastersAll.forEach(function(value, index){
                        if(value.id == branchIdLocal){
                            localStudentValue = value;
                        }
                    });
                    this.updateBranch(localStudentValue);
                    setTimeout(function () { this.getBatchWiseStudentDetails(); }.bind(this), 240)
                    /*this.getBatchWiseStudentDetails()*/ 
            })     
            this.updateLab(this.labIdFromProps);
        }
        if(localFlag) {
            this.fetchBranchMasterAll()
        }
    },
    watch: {
        query: {
            handler(query) {
                this.setQuery(query)
            },
            deep: true
        },
        data: function(val){
           
        }
    },
    methods: {
        ...mapActions('BatchWiseStudentListingIndex', ['fetchBranchMasterAll','fetchLabsAll','fetchCurrentEmployee','fetchData','setQuery','resetState','getBatchWiseStudentDetails','fetchBatches','setDate','setBranch','setLab','setBatch_id','setTrainerId','setOnlyAttendance','setOnlyAssessmentDue']),
        objectLength(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        },
        updateBranch(value) {
            this.setBranch(value)
            this.updateLab(null);
            this.updateBatchId(null);
            if(value && value.id) {
                this.fetchLabsAll(value.id).then(()=>{});   
                this.fetchBatches(value.id).then(()=>{});   
            }
        },
        updateLab(value){
            this.setLab(value)
        },
        updateBatchId(value) {
            this.setBatch_id(value);
        },
        updateDate(e) {
            if(this.dateFromProps){
                this.dataSeen = true
                 // this.getBatchWiseStudentDetails()
            }
            this.setDate(e.target.value);
        },
        updateTrainerId(value) {
            this.setTrainerId(value);
        },        
        updateOnlyAttendance(e) {
            this.setOnlyAttendance(e.target.checked)
        },
        updateOnlyAssessmentDue(e) {
            this.setOnlyAssessmentDue(e.target.checked)
        },
        submitForm() {

        this.$validator.validate().then(result => {
            if(result){
                this.dataSeen = true
                 this.getBatchWiseStudentDetails()
                .then(() => {
                    // this.$eventHub.$emit('create-success')
                })
                .catch((error) => {
                    console.error(error)
                })
              }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                
          });  
        },        
        resetFilters(){
            var filtersField= [];
            filtersField = ['student_name','course_name','lab_name','attendance','topic_name','display_topic_status'];
            this.$root.resetSearchFilters(Vue.delete,this.query,filtersField);
            this.getBatchWiseStudentDetails()
            
        } 
    }
}
</script>


<style scoped>

</style>
 
