function initialState() {
    return {
        all: [],
        item: {
            search_course_name: null,
        },
        relationships: {
            'course_type_id': 'name',
            'search_course_name': 'course_name',
            'course_level_id': 'name',
            'standard': 'name',
        },
        query: {},
        filterQuery:[],
        courseListWithDistinctAll:[],
        loading: false,
        projectAll: [],
        moduleAll: [],
        courseAll:[],
        editDataAll: [],
        showData: [],
        selectedLicenseTab:'project'
    }
}

const getters = {
    item: state => state.item,
    courseListWithDistinctAll: state => state.courseListWithDistinctAll,
    loading:       state => state.loading,
    relationships: state => state.relationships,
    moduleAll: state => state.moduleAll,
    projectAll: state => state.projectAll,
    courseAll: state => state.courseAll,
    editDataAll: state => state.editDataAll,
    showData: state => state.showData,
    selectedLicenseTab:state => state.selectedLicenseTab
}

const actions = {
    async fetchShowData({ commit, state, dispatch }, id) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/license/show/' + id)
                .then(response => {
                    commit('setShowDataAll', response.data.data)
                    resolve(response)
                    commit('setLoading', false)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                    commit('setLoading', false)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },  
    async fetchEditData({ commit, state, dispatch }, id) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/license/edit/' + id)
                .then(response => {
                    commit('setEditDataAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async editLicense({ commit, state, dispatch }, form_data) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            axiosLms.post('/api/v1/license/' + form_data.id, params)
                .then(response => {
                        //commit('setEditDataAll', response.data.data)
                    resolve(response)
                    
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    }, 
    fetchData({ commit, state, dispatch }, date_filter) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        axios.get('/api/v1/courses',{params:date_filter})
            .then(response => {
                commit('setAll',  response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
            .finally(() => {
                commit('setLoading', false)
            })
    },
    licenseStore({ commit, state, dispatch }, form_data) {
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {

            let params = new FormData();

            for (let fieldName in form_data) {
               
                let fieldValue = form_data[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }


            axiosLms.post('/api/v1/license/store', params)
                .then(response => {
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    }, 
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/license/' + id)
                .then(response => {
                    resolve(response);
                    })
                .catch(function (error) {
                          console.log('Error', error.message);
                          // let message = error.response.data.message || error.message
                          // let errors  = error.response.data.errors
                          // dispatch(
                          //     'Alert/setAlert',
                          //     { message: message, errors: errors, color: 'danger' },
                          //     { root: true })
                          reject(error)
                    }) 
                    .finally(() => {
                        commit('setError', false)
                    })
        })
    },
     fetchCourseListWithDistinctAll({ commit }) {
        axios.get('/api/v1/getCourseListWithDistinct')
            .then(response => {
                commit('setCourseListWithDistinctAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })  
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value))
    },
    resetState({ commit }) {
        commit('resetState')
    },
    async fetchProjectAll({ commit, state, dispatch }) {
        commit('setProjectAll', [])
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllProjects')
                .then(response => {
                    if(response.data.result){
                        commit('setProjectAll', response.data.data)
                        resolve(response)
                    }
                    else{
                        commit('setProjectAll', [])
                        resolve([])
                    }
                    
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },       
    async fetchModuleAll({ commit, state, dispatch }) {
        commit('setModuleAll', [])
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllModules')
                .then(response => {
                    commit('setModuleAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    async fetchCourseAll({ commit, state, dispatch }) {
        commit('setCourseAll', [])
        // commit('setLoading', true)
        return new Promise((resolve, reject) => {
            axiosLms.get('/api/v1/getAllCourses')
                .then(response => {
                    commit('setCourseAll', response.data.data)
                    resolve(response)
                })
                .catch(function (error) {
                    console.log('Error', error);
                    reject(error)
                }) 
                .finally(() => {
                    // commit('setLoading', false)
                })
        })
    },  
    addShowDataAll({ commit, state, dispatch }) {
        commit('setShowDataAll', [])
    },
    setLicenseTabSelection({ commit }, value){
        commit('setLicenseTabSelection', value)
      }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setCourseListWithDistinctAll(state, value) {
        state.courseListWithDistinctAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },        
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setProjectAll(state, projectAll) {
        state.projectAll = projectAll
    },   
    setModuleAll(state, moduleAll) {
        state.moduleAll = moduleAll
    },
    setCourseAll(state, courseAll) {
        state.courseAll = courseAll
    },
    setEditDataAll(state, editDataAll) {
        state.editDataAll = editDataAll
    }, 
    setShowDataAll(state, all) {
        state.showData = all
    },
    setLicenseTabSelection(state,value){
        state.selectedLicenseTab = value
      },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
