function initialState() {
    return {
        item: {
            id: null,
            income_type: null,
            payment_mode: null,
            file: null,
        },
        incomeTypeAll: [],
        paymentModeAll: [],
        income_details: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    incomeTypeAll: state => state.incomeTypeAll,
    paymentModeAll: state => state.paymentModeAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.income_details) {
                let fieldValue = state.income_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/incomes', params)
                .then(response => {
                     //commit('resetState')
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.income_details) {
                let fieldValue = state.income_details[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            
            axios.post('/api/v1/incomes/' + state.item.id, params)
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                    // resolve(response.data.data)


                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/incomes/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchIncomeTypesAll({ commit }) {
        axios.get('/api/v1/income-types')
            .then(response => {
                commit('setIncomeTypeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    fetchPaymentModeAll({ commit }) {
        axios.get('/api/v1/paymentModeListAll')
            .then(response => {
                commit('setPaymentModeAll', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    })   
    },
    setIncomeType({ commit }, value) {
        commit('setIncomeType', value)
    },
    setPaymentMode({ commit }, value) {
        commit('setPaymentMode', value)
    },
    setIncomeDetails({ commit }, value) {
        commit('setIncomeDetails', value)
    },
    setInstrumentNo({ commit }, value) {
        commit('setInstrumentNo', value)
    },
    setInstrumentDate({ commit }, value) {
        commit('setInstrumentDate', value)
    },
    setBankName({ commit }, value) {
        commit('setBankName', value)
    },
    setAmount({ commit }, value) {
        commit('setAmount', value)
    },
    setFile({ commit }, value) {
        commit('setFile', value)
    },
    setRemarks({ commit }, value) {
        commit('setRemarks', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        item.income_type = {
            name:item.name,
            value_id:item.income_type
        };
        item.payment_mode = {
            name:item.payment_mode_text,
            value_id:item.payment_mode
        };
        state.item = item
    },
    setIncomeType(state, value) {
        state.item.income_type = value
    },
    setPaymentMode(state, value) {
        state.item.payment_mode = value
    },
    setIncomeTypeAll(state, value) {
        state.incomeTypeAll = value
    },
    setPaymentModeAll(state, value) {
        state.paymentModeAll = value
    },
    setIncomeDetails(state, value) {
        state.income_details = value
    },
    setInstrumentNo(state, value) {
        state.item.instrument_no = value
    },
    setInstrumentDate(state, value) {
        state.item.instrument_date = value
    },
    setBankName(state, value) {
        state.item.bank_name = value
    },
    setAmount(state, value) {
        state.item.amount = value
    },
    setFile(state, value) {
        state.item.file = value
    },
    setRemarks(state, value) {
        state.item.remarks = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
