<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Schools</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/school-masters'">Schools</router-link>
                            </li>
                            <li class="breadcrumb-item active">View School</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">View School</h3>
                    </div>
                    <div class="card-body" v-if="!loading">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped not-responsive-grid">
                                    <tbody>
                                    <tr>
                                        <th>School Name</th>
                                        <td><span>{{ item.school_name }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>School Board</th>
                                        <td><span>{{ item.school_board.name }}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td><span>{{ item.country.country_name }}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <CustomLoader></CustomLoader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    props : [],
    components:{
        CustomLoader
    },
    data() {
        return {

        }
    },
    created() {
       this.fetchSchoolData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('SchoolMastersSingle', ['item', 'loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchSchoolData(this.$route.params.id)
        }
    },
    methods: {
       ...mapActions('SchoolMastersSingle', ['fetchSchoolData', 'resetState']),
    }
}
</script>

<style scoped>
.card {
    min-height: 185px !important;
}
</style>
