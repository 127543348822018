<template>
    <section class="content-wrapper" style="min-height: 960px;">
        <section class="content-header">
            <h1>Cancel Admission</h1>
        </section>

        <section class="content">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">View Cancelled Admission</h3>
                        </div>

                        <div class="box-body">
                            <back-buttton></back-buttton>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <table class="table table-bordered table-striped not-responsive-grid">
                                        <tbody>
                                        <tr>
                                            <th>Student Name</th>
                                            <td>{{ showDataAll.student_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Cancelled Courses</th>
                                            <td>
                                                <span class="my-multipleCategory" v-for="courses in computedCoursesNames">
                                                    {{courses}}<br />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Cancelled On</th>
                                            <td>
                                                {{ showDataAll.cancel_date }}    
                                            </td>
                                        </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('CancelAdmissionSingle', ['showDataAll']),
        computedCoursesNames() {
            if(this.showDataAll && this.showDataAll.course_name) {
                return this.showDataAll.course_name.split(',')
            }
        }
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchShowData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('CancelAdmissionSingle', ['fetchShowData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
 