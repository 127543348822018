<template>
    <div class="btn-group btn-group-xs">
        <button
                v-if="$can('student_create')  && row.status == 1"
                @click="convertToStudent(row.id)"
                type="button"
                title="Convert To Student"
                class="btn btn-block btn-info edit_delete_btn">
            <i class="fa fa-exchange" aria-hidden="true"></i>
        </button>
        <button
                v-if="$can('inquiry_create')  && row.status == 1"
                @click="convertToInquiry(row.id)"
                type="button"
                title="Convert To Inquiry"
                class="btn btn-block btn-warning edit_delete_btn">
            <i class="fa fa-exchange" aria-hidden="true"></i>
        </button>
        <router-link
                v-if="$can(xprops.permission_prefix + 'view')"
                :to="{ name: xprops.route + '.show', params: { id: row.id } }"
                class="btn btn-success edit_delete_btn"
                title="View"
                >
            <i class="fa fa-fw fa-eye"></i>
        </router-link>


        <router-link
                v-if="$can(xprops.permission_prefix + 'edit') && row.status == 1"
                title="Edit"
                :to="{ name: xprops.route + '.edit', params: { id: row.id } }"
                class="btn btn-block btn-primary edit_delete_btn">
             <i class="fa fa-fw fa-pencil"></i>
        </router-link>
        <button
                v-if="$can(xprops.permission_prefix + 'delete')  && row.status == 1"
                @click="destroyDataaaa(row.id)"
                type="button"
                title="Delete"
                class="btn btn-block btn-danger edit_delete_btn">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
   </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
    props: ['row', 'xprops'],
    data() {
        return {
            namespace: this.xprops.module
        }
    },
    attachStore (namespace) {
        Object.assign(this.methods, mapActions(namespace, ['destroyData']))
      },
    methods: {
        destroyDataaaa(id) {
            this.$swal({
                title: 'Are you sure you want to delete this record?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.destroyData(id)
                    .then(() => {
                        console.log('then')
                        this.$eventHub.$emit('delete-success')
                    })
                    .catch(error => {
                        console.log('catch')
                        this.$eventHub.$emit('delete-error')
                    })

                }
            })
        },
        convertToStudent(id) {
            if(this.row.birth_date == null) {
                this.$swal({
                      title: 'Please fillup the birth date field.',
                      text: 'Birthday field is mandatory for conversion.',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#dd4b39',
                      focusConfirm: true,
                      reverseButtons: true
                  }).then(res => {
                    if(res && res.value && res.value == true){
                        this.$router.push("/workshop/" + this.row.id + "/edit");
                    }
                  });
            } else {                        
                this.$swal({
                    title: 'Are you sure you want to convert this record?',
                    text: 'You won\'t be able to modify this record after this!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Convert To Student',
                    confirmButtonColor: '#dd4b39',
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch(
                            this.xprops.module + '/convertToStudent',
                            id
                        ).then(response => {
                            this.$router.push({ name: 'student.edit', params: {id: response.data.data[0] }})
                            this.$eventHub.$emit('common',1,'Workshop converted to student successfully')
                        })
                        .catch(error => {
                            this.$eventHub.$emit('common',2,error.response.data.message)
                        })
                    }
                })
            }
        },
        convertToInquiry(id) {
            this.$swal({
                title: 'Are you sure you want to convert this record?',
                text: 'You won\'t be able to modify this record after this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Convert To Inquiry',
                confirmButtonColor: '#dd4b39',
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch(
                        this.xprops.module + '/convertToInquiry',
                        id
                    ).then(response => {
                        this.$router.push({ name: 'inquiries.edit', params: {id: response.data.data[0] }})
                        this.$eventHub.$emit('common',1,'Workshop converted to inquiry successfully')
                    })
                    .catch(error => {
                        this.$eventHub.$emit('common',2,error.response.data.message)
                    })
                }
            })
        },
    }
}
</script>


<style scoped>

</style>
