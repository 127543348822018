<template>
    <div class="popup-form add-country">
        <b-modal id="addCourseCaseStudyModel"
            ref="modal"
            title="Add Course Case Study"
            @ok="handleOk"
            okTitle="Save"
            okVariant="success"
            @cancel="handleCancel"
            hide-footer
            no-enforce-focus
            @shown="modalOpened" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true"
        >
            <AddCaseStudy @loadItems="loadItems" @closeModel="closeModel"></AddCaseStudy>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AddCaseStudy from '@/components/cruds/Course/CaseStudy/AddCaseStudy'
export default {
    components:{
        AddCaseStudy
    },
    data() {
        return {
        };
    },
    
    methods:{
        ...mapActions('CourseCaseStudyIndex', ['setCaseStudyImage','setBackgroundImage','setProfile']),
        
        handleCancel(evt){
        },    
        handleOk (evt) {
            
        },
        modalOpened() {
            this.setCaseStudyImage('');
            this.setBackgroundImage('');
            this.setProfile('');
        },
        closeModel(){
            this.$refs.modal.hide();
        },
        loadItems(){
            this.$emit('loadItems');
        }
    }
}
</script>