<template> 
    <div class="popup-form add-country">
        <b-modal id="modalPreventRefundView"
                ref="modal"
                title="View Refund"
                hide-footer  @shown="modalOpened" 
                size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
                <section class="content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-6 col-xs-12">
                                        <table class="table table-bordered table-striped not-responsive-grid">
                                            <tbody>
                                            <tr>
                                                <th>Refund Date</th>
                                                <td>{{ item.refund_date }}</td>
                                            </tr>
                                            <tr>
                                                <th>Refund Amount</th>
                                                <td><i  class="fa fa-rupee"></i> {{ item.refund_amount }}</td>
                                            </tr>
                                            <tr>
                                                <th>Due Adjustment</th>
                                                <td><i  class="fa fa-rupee"></i> {{ item.recover_amount }}</td>
                                            </tr>
                                            <tr>
                                                <th>Student Name</th>
                                                <td>{{ item.student_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Enrollment #</th>
                                                <td>{{ item.enroll_no }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Fee Details</h3>
                            </div>
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <table class="table table-bordered table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Course Name</th>
                                                    <th>Fee Type</th>
                                                    <th>Fee Refund Amount</th>
                                                    <th>GST Rate(%)</th>
                                                    <th>GST Amount</th>
                                                    <th>Fee Amount</th>
                                                    <th>Total Amount</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(fee, index) in item.refund_detail">
                                                    <td>{{fee.course.course_name}}</td>
                                                    <td>{{fee.fee_type_name}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amount}}</td>
                                                    <td>{{fee.gst_rate}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.gst_amount}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amt}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.total_fees}}</td>
                                                </tr>
                                                <tr  v-for="(fee, index) in item.receipt_detail">
                                                    <td>{{fee.course_name}}</td>
                                                    <td>{{fee.fee_type_name}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amount}}</td>
                                                    <td>{{fee.gst_rate}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.gst_amount}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.fees_amt}}</td>
                                                    <td><i class="fa fa-rupee"></i>  {{fee.total_fees}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Payment Details</h3>
                            </div>
                            <div class="card-body" >
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <table class="table table-bordered table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Payment Mode</th>
                                                <th>Amount</th>
                                                <th>Bank Name</th>
                                                <th>Cheque/Transaction Number</th>
                                                <th>Date</th>                            
                                            </tr>                               
                                        </thead>
                                        <tbody>
                                            <tr  v-for="(fee, index) in paymentDetails">
                                                <td>{{fee.payment_mode_detail.name}}</td>
                                                <td><i class="fa fa-rupee"></i> {{(fee.amount) ? fee.amount : 0.00}}</td>
                                                <td>{{(fee.bank_name) ? fee.bank_name : '-'}}</td>
                                                <td>{{(fee.instrument_no) ? fee.instrument_no: '-'}}</td>
                                                <td>{{(fee.instrument_date) ? fee.instrument_date : '-'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
        </b-modal>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['refundId', 'student_id'],
    data() {
        return {
                disable: [],
                inputParams:[],
                recoverParams:[],
                paymentInputParams:[],
                refundPlusRecover:[],
                instrumentDateParams:[new Date()],
                instrumentNumberParams:[],
                bankNameParams:[],
                addPaymentModeParams:null,
                // paymentModeParams:[{value_id:1,name:'Cash'}],
                paymentModeParams: [],
                gstParams:[],
                amountParams:[],
                studentSeen:false,
                totalGST:[],
                totalAmount:[],
                totalPayment:[],
                totalRecover:[],
                totalRefundRecover:[],                
                totalPaymentDetail:0,
                sumTotalAmount:0,
                sumTotalPaid:0,
                sumTotalBalance:0,
                sumTotalRefund:0,
                paymentModeAmount:0,
                angle:'fa fa-angle-right',
                // Code...
        }
    },
    created() {
        this.fetchData(this.refundId)
        this.fetchPaymentMode(this.refundId)
                .then((res) => {
                     var paymentModeParams = [],
                        row_number = [],
                        paymentInputParams = [],
                        instrumentDateParams = [],
                        instrumentNumberParams = [],
                        bankNameParams = [],
                        calculatingRefund = 0;
                        this.paymentDetails.forEach(function(payment, index){  
                            payment.row_number = payment.row_number -1;
                            calculatingRefund = calculatingRefund + parseFloat(payment.amount) || 0;   
                            paymentModeParams[payment.row_number]=({value_id:1,row_number:payment.row_number,payment_mode_detail:payment.payment_mode_detail});
                            row_number.push(payment.row_number);              
                            paymentInputParams[payment.row_number] = payment.amount;
                            instrumentDateParams[payment.row_number] = payment.instrument_date;
                            instrumentNumberParams[payment.row_number] = (payment.instrument_no != 'null') ? payment.instrument_no:'';
                            bankNameParams[payment.row_number] = (payment.bank_name != 'null') ? payment.bank_name : ''; 
                            
                        })
                        this.totalPaymentDetail = (parseFloat(calculatingRefund)).toFixed(2);
                        console.log(paymentInputParams);
                        if(paymentModeParams !== '')
                        {
                            this.paymentModeParams = [];
                            this.paymentInputParams = [];
                            this.instrumentDateParams = [];
                            this.instrumentNumberParams = [];
                            this.bankNameParams = [];
                            this.paymentModeParams = paymentModeParams;
                            this.paymentInputParams = paymentInputParams;
                            this.instrumentDateParams = instrumentDateParams;
                            this.instrumentNumberParams = instrumentNumberParams;
                            this.bankNameParams = bankNameParams;
                        } 
                    })
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('RefundsSingle', ['item', 'paymentDetails'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.refundId)
        },
        item:function(){
          //  console.log(this.item)
        }
    },
    methods: {
        ...mapActions('RefundsSingle', ['fetchData', 'resetReceipt', 'resetState', 'fetchPaymentMode']),
        handleCancel(evt){
            this.resetState()
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$refs.modal.hide();
        },
        clear () {
            this.resetState()
        },
        modalOpened() {
            console.log('ass', this.refundId);
            this.fetchData(this.refundId)            
        }
    }
}
</script>
<style scoped>

</style>
 