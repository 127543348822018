<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Project Fees</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/project-fees'">Project Fees</router-link>
                            </li>
                            <li class="breadcrumb-item active">Add Project Fees</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Add Project Fees</h3>
                </div>
                <div class="card-body">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12 row"> -->
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                    <div class="col-md-12">
                                        <bootstrap-alert />
                                    </div>
                                    <div class="row col-md-12 col-xs-12">    
                                        <div class="form-group col-md-12 col-xs-12">
                                            <div ref="branches" class="form-group">
                                                <label for="branches">Branches<p class="astrisk">*</p></label>
                                                <select v-validate="'required'" name="branch" label="branch" class="form-control select2 branches" placeholder="Select Branches" multiple @change="updateBranch">
                                                    <!-- <option value="-1" selected>Select Branches</option> -->
                                                    <optgroup v-for="(branches, index) in branchesDetails" :key="index" :label="(branches[0]['branch_franchise_id'] != 1) ? index : 'Main Franchises'">
                                                        <option v-for="(branch, i) in branches" :key="i" :value="branch.branch_id">
                                                            {{branch.branch_name}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                                <span class="error" v-show="errors.has('branch')">
                                                    {{ $root.updateErrorMessage('branch',this.errors,'Branch Name')}}
                                                </span>
                                            </div>
                                        </div>  
                                        
                                    </div> 
                                    <div class="form-group col-md-12 col-xs-12 slectBoxIcon">
                                        <label for="project_name" class="col-md-12 col-xs-12">Project Name<p class="astrisk">*</p></label>
                                        <div class="subject-info-box-1 col-md-12 col-xs-12" >
                                            <select multiple="multiple" id='lstBox1' class="form-control"></select>
                                            </div>
                                            <div class="subject-info-arrows text-center">
                                            <input type='button' title="Move all to the right" id='btnAllRight' @click="moveAllRight" value='>>' class="move-buttons btn btn-success" /><br />
                                            <input type='button' title="Move selected to the right" id='btnRight' @click="moveToTheRight" value='>' class="move-buttons btn btn-success top-margin" /><br />
                                            <input type='button' title="Move selected to the left" id='btnLeft' @click="moveToTheLeft" value='<' class="move-buttons btn btn-danger top-margin" /><br />
                                            <input type='button' title="Move all to the left" id='btnAllLeft' value='<<' @click="moveAllLeft" class="move-buttons btn btn-danger top-margin" /><br />
                                            <span class="top-margin" style="color: red;" id="movingError"></span>
                                            </div>
                                            <div class="subject-info-box-2">
                                            <select multiple="multiple" id='lstBox2' class="form-control"></select>
                                        </div>
                                    </div>  
                                    <div class="row" v-if="!myFlag && loadingFeesAvailable">
                                        <div class="col-md-12"><br>
                                            <div class="alert text-center" style="font-size:1.6em;">
                                                <i class="fa fa-spin fa-refresh"></i> <span>&nbsp;Loading...</span> 
                                            </div>
                                        </div>
                                    </div>    
                                    <div class="row col-md-12">
                                        <div class="col-md-12 col-xs-12" v-if="this.branch.length > 0 && this.isProject == true && myFlag && !loadingFeesAvailable">
                                            <div ref="start_date" class="form-group col-md-6 col-xs-6">
                                                <label for="start_date">New Start Date<p class="astrisk">*</p></label>
                                                <date-picker
                                                        :value="item.start_date"
                                                        :config="$root.dpconfigDate"
                                                        name="start_date"
                                                        v-validate="'required'"
                                                        placeholder="Select New Start Date"
                                                        @dp-change="updateStartDate"
                                                        autocomplete="off"
                                                        >
                                                </date-picker>
                                                <span class="error" v-show="errors.has('start_date')">
                                                {{ $root.updateErrorMessage('start_date',this.errors,'New Start Date')}}
                                                </span>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="col-md-12 col-md-offset-1">
                                                <div class="form-group">
                                                        <section v-if="feesTypeAll">
                                                            <table class="table table-bordered table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width: 150px;">Fee type</th>
                                                                            <th style="width: 280px;">New fee</th>
                                                                            
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-for="(feesType, i) in feesTypeAll" :key="i">
                                                                        <tr>
                                                                            <td>{{feesType.name}}</td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    name="percentage_from"
                                                                                    id="percentage_from"
                                                                                    maxlength="10"
                                                                                    oninput="this.value=this.value
                                                                                                            .replace(/[^\d.]/g, '')
                                                                                                            .replace(/(\..*)\./g, '$1')
                                                                                                            .replace(/(\.[\d]{2})./g, '$1');"
                                                                                    placeholder="Enter new fee"
                                                                                    v-validate="'required'"
                                                                                    :value="(newFees[feesType.id])?newFees[feesType.id]:0"
                                                                                    @input="updateNewFeeAmount($event, feesType.id)"  
                                                                                    >                                                            
                                                                            </td>         
                                                                        </tr>
                                                                    </tbody>
                                                            </table><br>                               
                                                        </section>
                                                    <span class="error" v-show="errors.has('percentage_from')">
                                                    Enter At least one new fee data.
                                                    </span><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-2 savebtn">
                                        <vue-button-spinner
                                                class="std_information_save btn btn-block btn-success new_branch_save"
                                                :isLoading="loading"
                                                :disabled="loading"
                                                >
                                                Save
                                        </vue-button-spinner>
                                    </div>                               
                                </div>
                            </form>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {        
        return {
            myFlag: true,
            isProject:false,
            newFees: [],
            totalCountFees:0,    
            totalCountGST:0,
            minValue:0,
            clLeave:'',
            lwpLeave:'',
            dpconfigDateFuture:this.$root.dpconfigDateFuture,
            form_data:{
               fee_type:[],
               project_id:[],
               branch_id:[],
           } 
        }
    },
    computed: {
        ...mapGetters('ProjectFeesSingle', ['branchesDetails','item', 'project','loading','loadingFeesAvailable', 'coursesAll', 'branchMastersAll', 'feesTypeAll','courseDetailedData','allCourseLevels','feesAvailable','nextFeeStartDate','mainProjectAll', 'branch']),
        computedOldDate() {
            if(this.courseDetailedData && this.courseDetailedData[0]) {
                var formatedFromDate = this.courseDetailedData[0].from_date.split('-');
                var finalOldDate = formatedFromDate[2]+'/'+formatedFromDate[1]+'/'+formatedFromDate[0]
                return finalOldDate;
            }
        }                
    },
    created() {
       // this.branchesDetails = this.$store.state.AuthIndex.branches;
        this.fetchBranchDetails();
        this.fetchFeesTypeAll()       
    },
    mounted() {
        let _this = this;
        $(".branches").select2({placeholder: "Select Branches",}).on('change', function () {
            $('#lstBox1').empty();
            $('#lstBox2').empty();
            _this.updateBranch($(this).select2("val"))
        });
    },
    destroyed() {
        this.resetState()
        this.setFees_type(null);
        this.setFees_amount('');
    },
    watch:{
        mainProjectAll(value){
            $('#lstBox1').empty();
            for (var i = 0; i < value.length; i++) {
                //console.log("loop",value[i]);
                $('#lstBox1')
                    .append($("<option data-hours='"+value[i].project_hours+"'></option>")
                        .attr("class","project")
                        .attr("value",value[i].id)
                        .text(value[i].project_name));
            }
        }
    },
    methods: {
        ...mapActions('ProjectFeesSingle', ['fetchBranchDetails','storeData', 'resetState', 'setProject', 'setFees_amount', 'setIs_gst_applicable', 'setGst_rate', 'setTotal_fees', 'setBranch', 'setFees_type', 'fetchFeesTypeAll', 'fetchBranchMasterAll','fetchCourseDetailedData','fetchCoursesLevel','setStartDate','updateBunchData','fetchIfFeesAvailable','setMainProject','fetchProjects']),
         moveToTheRight() {
            var selectedOpts = $('#lstBox1 option:selected');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;             
            }
          
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + $(selectedOpts[i]).data('hours');
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
            this.isProject = false
            if($('#lstBox2 option').length > 0) {
                this.isProject = true
                this.updateProject()
            }
        },
        moveAllRight() {
            var selectedOpts = $('#lstBox1 option');
            if (selectedOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours + $(selectedOpts[i]).data('hours');
            }
            $('#lstBox2').append($(selectedOpts).clone());
            $(selectedOpts).remove();
            this.isProject = false
            if($('#lstBox2 option').length > 0) {
                this.isProject = true
                this.updateProject()
            }
        },
        moveToTheLeft() {
            
            var selectedOpts = $('#lstBox2 option.project:selected');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option:selected');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move') 
                return false;              
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - $(selectedOpts[i]).data('hours');
            }
            $(selectedAllOpts).remove();
            this.isProject = false
            if($('#lstBox2 option').length > 0) {
                this.isProject = true
                this.updateProject()
            }
        },
        moveAllLeft() {
           
            var selectedOpts = $('#lstBox2 option.project');
           
            $('#lstBox1').append($(selectedOpts).clone());
            var selectedAllOpts = $('#lstBox2 option');
            if (selectedAllOpts.length == 0) {
                this.$eventHub.$emit('nothing-to-move')
                return false;
            }
            for (let i = 0; i < selectedOpts.length; i++) {
                this.moduleHours = this.moduleHours - $(selectedOpts[i]).data('hours');  
            }
            $(selectedAllOpts).remove();
            this.isProject = false
            if($('#lstBox2 option').length > 0) {
                this.isProject = true
                this.updateProject()
            }
        },
        countTotalFees(){
            var myVal = 0;
            if(this.totalCountFees != ''){
                myVal = parseFloat(this.totalCountFees) + (parseFloat(this.totalCountFees) * parseFloat(this.totalCountGST) / 100);
            }
            this.setTotal_fees(myVal.toFixed(2));            
            return myVal.toFixed(2);
        },
        computedOldFee(feesType) {
            var oldFee = 0;
            for (var i = 0; i < this.courseDetailedData.length; i++) {
                 if(feesType == this.courseDetailedData[i].fees_type) {
                    oldFee = this.courseDetailedData[i].fees_amount
                 }
             }
            return oldFee;
        },        
        updateProject() {
            var localFlag = true;
            if(!this.branch){                    
                this.$validator.errors.add({
                    id: 'branch',
                    field: 'branch',
                    msg: 'please selecte branch',
                    scope: this.$options.scope,
                });
                localFlag = false
                this.myFlag = false;
                return false;
            }
            this.$validator.errors.clear()
            this.myFlag = true                        
        },
        updateNewFeeAmount(e, index) {
            this.$set(this.newFees, parseInt(index),e.target.value)
            var myvalue = 0;
            /*this is use for amount total*/
            this.newFees.forEach(function(fee,index){
                 myvalue = parseInt(myvalue) + parseInt((fee)?fee:0);
            }.bind(this))
            this.minValue = myvalue;
        },        
        updateFees_amount(e) {
            this.setFees_amount(e.target.value)
        },
        updateStartDate(e) {
            this.setStartDate(e.target.value)
        },           
        updateIs_gst_applicable(e) {
            // this.setIs_gst_applicable(e.target.value)
            if(e.target.checked){
                this.setIs_gst_applicable(e.target.checked);
            }else{
                this.setIs_gst_applicable(e.target.checked);
            }
        },
        updateGst_rate(e) {
            if(e.target.value != ''){
                this.setGst_rate(e.target.value)
            }else{
                this.totalCountGST = 0;
                this.setGst_rate(0)
            }
        },
        updateTotal_fees(e) {
            this.setTotal_fees(e.target.value)
        },
        updateBranch(value) {
            this.setBranch(value)
            if(this.project !== null){
                this.fetchProjects(value);
            }
        },
        updateFees_type(value) {
            if(value && value.other_value != null){
                this.totalCountGST = (value.other_value)?value.other_value:0;
                this.setGst_rate(this.totalCountGST);
                this.setFees_type(value)
            }
        },       
        submitForm() {
            this.$validator.validate().then(result => {      
                if(this.minValue == 0){
                    this.$validator.errors.add({
                        id: 'percentage_from',
                        field: 'percentage_from',
                        msg: 'Enter At least one new fee data0.',
                        scope: this.$options.scope,
                    });
                    return false;
                }  
                 
                var form_details = []
                if (result && this.myFlag) {
                    if($('#lstBox2 option').length == 0) {
                        this.$eventHub.$emit('common',2,'Please select atlease one project')
                        return false;
                    }
                    var finalData = [],
                    detailedData = [],
                    newFeesLocal = this.newFees;
                    for (var j = 0; j < this.feesTypeAll.length; j++) {
                        var localIndex = this.feesTypeAll[j].id;
                        if(newFeesLocal[localIndex] === undefined) {
                        } else {
                            var formatedFromDate = this.item.start_date.split('/');
                            var finalOldDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]                            
                            var obj = {
                                'id': this.item.id,
                                'fees_type': this.feesTypeAll[j].id,
                                'fees_amount': newFeesLocal[localIndex],
                                'is_gst_applicable': this.feesTypeAll[j] && this.feesTypeAll[j].other_value != 0 ? 1 : 0,
                                'gst_rate': this.feesTypeAll[j].other_value,
                                'total_fees': 0,
                                'from_date': finalOldDate
                            }                            
                            finalData.push(obj);
                        }
                    }
                    this.form_data.fee_type = finalData;
                    let project_ids = [];
                    var allProjects = [];
                    $('#lstBox2 option.project').each(function() {
                        allProjects.push({'name': $(this).text(), 'id': $(this).val()})
                    });
                    allProjects.forEach((value, index) => {
                        project_ids.push(value.id);      
                    });
                    this.form_data.project_id = project_ids;
                    this.form_data.branch_id = this.branch;
                    this.updateBunchData(this.form_data)
                        .then((res) => {                  
                            if(!res.data.result){
                                for (const [key, value] of Object.entries(res.data.data)) {
                                    if(key == 'project_id'){ 
                                        this.$eventHub.$emit('common',3,value[0])
                                        return false;
                                    }
                                    this.$validator.errors.add({
                                        id: key,
                                        field: key,
                                        msg: value[0],
                                        scope: this.$options.scope,
                                    });
                                }
                                this.buttonLoading = false
                                return '';
                            }
                            this.$router.push({ name: 'project_fees.index' })
                            this.$eventHub.$emit('update-success')
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                    }
                  else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                }                
            });            
        }        
    }
}
</script>

<style scoped>
.move-buttons {
    width: 40px;
}
select[multiple] {
    height: 300px !important;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    select {
        height: 200px;
        padding: 0;
        option {
            padding: 4px 10px 4px 10px;
        }
        option:hover {
            background: #EEEEEE;
        }
    }
}
.subject-info-arrows {
    float: left;
    width: 10%;
    margin-top: 6%;
    input {
        width: 70%;
        margin-bottom: 5px;
    }
}
.top-margin {
    margin-top: 10px;
}
</style>