<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Modules</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/modules'">Modules</router-link>
                            </li>
                            <li class="breadcrumb-item active">View Module</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View Module</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Module Name</th>
                                    <td><span>{{ showData.module_name }}</span></td>
                                </tr>
                                <tr>
                                    <th>Projects</th>
                                    <td><span v-for="(project) in showData.module_project" :key="project.id">{{ project.project_name }}</br></span></td>
                                </tr>   
                                <tr>
                                    <th>Project Hours</th>
                                    <td><span>{{ totalHours() }}</span></td>
                                </tr>                           
                                </tbody>
                            </table>
                        </div>                
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchShowData(this.$route.params.id)
    },
    // destroyed() {
    //     this.resetState()
    // },
    computed: {
        ...mapGetters('ModulesIndex', ['showData','loading'])
    },
    updated(){
        console.log("load",this.loading)
    },
    watch: {
        "$route.params.id": function() {
            // this.resetState()
            this.fetchShowData(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions('ModulesIndex', ['fetchShowData', 'resetState']),
        totalHours(){
            let hours = 0;
           // console.log(this.showData.module_project);
            // for (const [key, value] of this.showData.module_project) {
            //     console.log("ke",key,value);
                
            // }
            const object = this.showData.module_project;
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    //console.log(element);
                    hours += Number(element.project_hours);
                }
            }
            return hours;
        }
    }
}
</script>


<style scoped>
.my-multipleCategory{
    margin: 0 6px 0 0;
}
</style>
