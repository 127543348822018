var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"btn-group mb-3"},[(_vm.$can(_vm.xprops.permission_prefix + 'create'))?_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.addCourseFaqModel",modifiers:{"addCourseFaqModel":true}}],staticClass:"btn btn-success btn-sm",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add New ")]):_vm._e(),_c('button',{staticClass:"btn btn-default btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.loadItems(1)}}},[_c('i',{staticClass:"fa fa-sync",class:{'rotate': _vm.refershLoad}}),_vm._v(" Refresh ")]),_c('button',{staticClass:"btn btn-default btn-sm",attrs:{"type":"button"},on:{"click":_vm.resetFilters}},[_c('i',{staticClass:"fa fa-undo",class:{'rotate': _vm.refershLoad}}),_vm._v(" Reset Filters ")])],1),(_vm.$can(_vm.xprops.permission_prefix + 'create'))?_c('AddFaqPopup',{on:{"loadItems":_vm.loadItems}}):_vm._e(),(_vm.$can(_vm.xprops.permission_prefix + 'edit'))?_c('EditFaqPopup',{attrs:{"editId":_vm.editId},on:{"loadItems":_vm.loadItems}}):_vm._e(),(_vm.$can(_vm.xprops.permission_prefix + 'view'))?_c('ShowFaqPopup',{attrs:{"showId":_vm.showId},on:{"loadItems":_vm.loadItems}}):_vm._e(),_c('bootstrap-alert'),_c('div',{staticClass:"table-responsive setting-group"},[_c('vue-good-table',{ref:"table1",attrs:{"styleClass":"vgt-table table table-bordered striped thead-dark","mode":"remote","totalRows":_vm.totalRecords,"pagination-options":{
                enabled: true,
                perPage:10,
                perPageDropdown: [10, 20, 40, 80, 100],
                dropdownAllowAll: false,
            },"isLoading":_vm.isLoading,"select-options":{ 
                enabled: false,
            },"search-options":{
                enabled: false,
                placeholder: 'search',
            },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('CourseFaqActions',{attrs:{"xprops":_vm.xprops,"row":props.row},on:{"editIdModel":_vm.editIdModel,"showIdModel":_vm.showIdModel,"loadItems":_vm.loadItems}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[(!_vm.isLoading)?_c('span',[_vm._v(_vm._s('No Records Found.'))]):_vm._e()]),_c('template',{slot:"loadingContent"},[_c('CustomLoader')],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }