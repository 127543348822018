<template> 
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Users</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/users'">Users</router-link>
                            </li>
                            <li class="breadcrumb-item active">View User</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">View User</h3>
                </div>
                <div class="card-body" v-if="!loading">
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>
                                <tr>
                                    <th>Owner Name</th>
                                    <td><span>{{ item.name }}</span></td>
                                </tr>
                                <!-- <tr>
                                    <th>Username</th>
                                    <td><span>{{ item.username }}</span></td>                                               
                                </tr> -->
                                <tr>
                                    <th>Email</th>
                                    <td><span>{{ item.email }}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="table table-bordered table-striped not-responsive-grid">
                                <tbody>                                                                        
                                <tr>
                                    <th>Franchise Name</th>
                                    <td><span>{{ item.franchise_name }}</span></td>                     
                                </tr>                                                 
                                <tr>
                                    <th>Branch Name</th>
                                    <td><span>{{ item.branch_name }}</span></td>          
                                </tr>
                                <tr>
                                    <th>Role Title</th>
                                    <td>
                                        <span v-for="role in item.role" :key="role.id">
                                            {{ role.title }}
                                        </span>
                                    </td>                         
                                </tr>    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components:{
        CustomLoader
    },
    data() {
        return {
            // Code...
        }
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    computed: {
        ...mapGetters('UsersSingle', ['item','loading'])
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        item(newValue, oldValue){
            //if(newVal)
            return newValue;
        }
    },
    methods: {
        ...mapActions('UsersSingle', ['fetchData', 'resetState'])
    }
}
</script>


<style scoped>

</style>
