function initialState() {
    return {
        relationships: {},
        item: {
            sending_method: null,
            sms_url: null,
            sms_url_params: null,
            enabled: null,
        },       
        loading: false,
        error: false,
        smsSettingAll: [],
        fetchedSmsDataAll: []
    }
} 

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    smsSettingAll: state => state.smsSettingAll,
    fetchedSmsDataAll: state => state.fetchedSmsDataAll,
    error: state => state.error
}

const actions = {
    saveSMSSetting({ commit, state, dispatch }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }
            if (_.isEmpty(state.item.sending_method)) {
                params.set('sending_method', '')
            } else {
                params.set('sending_method', state.item.sending_method.id)
            }
            axios.post('/api/v1/saveSmsSetting', params)
                .then(response => {
                    commit('setSmsSettingAll', response.data.data)
                    resolve()
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchSmsData({ commit }) {
        await axios.get('/api/v1/fetchSmsData')
            .then(response => {
                commit('setFetchedSmsData', response.data.data)
            })
            .catch(function (error) {
                          console.log('Error', error.message);
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },    
    setFetchedSmsData({ commit }, value) {
        commit('setFetchedSmsData', value)
    }, 
    setToggle({ commit }, value) {
        commit('setToggle', value)
    }, 
    setSMSURL({ commit }, value) {
        commit('setSMSURL', value)
    },
    setSMSURLParams({ commit }, value) {
        commit('setSMSURLParams', value)
    },     
    setSendingMethod({ commit }, value) {
        commit('setSendingMethod', value)
    },
    
}

const mutations = {    
    setLoading(state, loading) {
        state.loading = loading
    }, 
    setError(state, error) {
        state.error = error
    },
    setFetchedSmsData(state, value) {
        state.fetchedSmsDataAll = value
    },
    setToggle(state, value) {
        state.item.enabled = value
    },
    setSMSURL(state, value) {
        state.item.sms_url = value
    },
    setSMSURLParams(state, value) {
        state.item.sms_url_params = value
    },    
    setSendingMethod(state, value) {
        state.item.sending_method = value
    },
    setSmsSettingAll(state, value) {
        state.smsSettingAll = value
    }    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
