<template>
    <div class="popup-form add-country">
        <b-modal id="modalEditStudentBatch"
                ref="modal"
                title="Edit Student Batch"
                okTitle="Save"
                okVariant="success"
                hide-footer
                @shown="modalOpened"
                size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <batchEdit @closeModel="closeModel" @loadItems="loadItems" :batchId="batchId" :student_id="student_id" :isAddBatch="isAddBatch" :closeAddBatchTab="closeAddBatchTab" />
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import batchEdit from '@/components/cruds/Batch/batchEdit'
export default({
    props: ['student_id','batchId','isAddBatch', 'closeAddBatchTab'],
    components: {
        batchEdit,
    },
    data() {
        return {
            addCourseParams:null,
            checkedDays: [],
            workingCheckedDays: [],
            daysAll:{'1':'Monday','2':'Tuesday','3':'Wednesday','4':'Thursday','5':'Friday','6':'Saturday','7':'Sunday'},
            dayName:['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            start_date: null,
            end_date: null,
            batchSchedules:[],
        }
    },
    computed: {
        ...mapGetters('BatchSingle', ['item', 'coursesAll','batchAll','batchData']),
    }, 
    created() {
        // this.reset();
        // // if(this.isAddBatch == true) {
        // this.fetchCoursesAll(this.student_id);
        // this.getBatchData(this.batchId);
        // }
    },
    
    methods: {
        ...mapActions('BatchSingle', ['setBatchData','storeData','fetchBatchAll','fetchCoursesAll', 'fetchCourseWorkingDaysAll', 'setCoursesAll', 'setCourseId', 'setCourseName', 'setCourseMonth','getBatchData']),
        toogleModelSubTable(id ,child){
            var className = $('#modelshow_'+id).attr('class');
            if($('#modelshow_'+id).length > 0 && className == "allhide"){
                $('#modelshow_'+id).removeClass('allhide').addClass('allshow');
                $('#modelangle_'+id).removeClass('fa fa-angle-right').addClass('fa fa-angle-down');
            }else{
                $('#modelshow_'+id).removeClass('allshow').addClass('allhide');
                $('#modelangle_'+id).removeClass('fa fa-angle-down').addClass('fa fa-angle-right');
            }    
        },
        loadItems(){
             this.$emit('loadItems');
        },
        modalOpened(){
           
        },
        closeModel(){
             this.$refs.modal.hide();
        },
        submitForm() {
        this.$validator.validate().then(result => {
            if (result) {
            // if (this.courseListAll.length == 0) {
            //   this.$validator.errors.add({
            //     id: "nocourseAdded",
            //     field: "nocourseAdded",
            //     msg: "At least one course need to added.",
            //     scope: this.$options.scope
            //   });
            //   return false;
            // } else {
            //   this.$validator.errors.remove("nocourseAdded");
            // }
            //this.saveCourse()

            this.storeData({'student_id':this.student_id,'course_id':this.item.course_id,'start_date':this.start_date,'end_date':this.end_date,'batchSchedule':this.batchSchedules})
                .then(res => {
                    if (!res.data.result) {
                        this.$validator.errors.add({
                            id: "enroll_data",
                            field: "enroll_data",
                            msg: res.data.message,
                            scope: this.$options.scope
                        });
                        return this.$root.handleValidationFocus(
                            this.errors,
                            this.$refs
                        );
                    }
                    // this.$router.push({ name: "student.index" });
                    this.$emit('closeModel');
                    this.$emit('loadItems');
                    this.$eventHub.$emit("create-success");
                })
                .catch(error => {
                    console.error(error);
                });
            } else {
            return this.$root.handleValidationFocus(this.errors, this.$refs);
            }
        });
        },
        updateCourseName(value) {
            //console.log("upd",value);
            if(value != null) {
                this.setCourseId(value.course_id)
                this.setCourseName(value.course_name)
                this.setCourseMonth(value.course_months)
                this.fetchBatchAll(value.course_id);
            } else {
                this.reset();
            }
        },
        reset() {
            this.setCourseId(null);
            this.setCourseName(null);
            this.setCourseMonth(null);
            this.start_date = null;
            this.end_date = null;
        },
        update_date(e) {
            this.start_date = e
            var index = e.split('/')
            
            const dateFormat = index[1] + '/' + index[0] + '/' + index[2]
            this.end_date = moment(dateFormat).add(this.item.course_months, 'M').format('DD/MM/YYYY')
        },    
        updateWorkingDay1(e) {
            if(e.target.checked){

            }
        },
        handleCancel(evt){
            if(this.paymentShow == true){
                this.$emit('viewloadpayment');
            }else{
                this.$emit('paymentShowTab');  
            }
            this.addCourseParams = null;
        },    
        handleOk (evt) {
            evt.preventDefault();
            this.$validator.validate().then(result => {
                if (result) {
                        this.submitForm()
                    }
                    else{
                    return this.$root.handleValidationFocus(this.errors,this.$refs);
                    } 
                });
        },
        clear () {
            const current = new Date();
            var date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
            this.addCourseParams = null;
        },
    }
})
</script>

<style scoped>
.modal-footer1{
    padding: 12px 15px 0;
    margin: 0 -15px;
}
</style>
