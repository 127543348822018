<template>
  <div class='topicModal'>
    <div class="popup-form">
      <span class="student-name fa fa-history" @click="getData"  title="Inquiry History"></span>
      <b-modal id="modalPrevent"
        ref="modal"
        title="Inquiry History"
        @ok="handleOk"
        okTitle="Save"
        okVariant="success"
        @cancel="handleCancel"
        modal-class=""
        :hide-footer="true"
        size="lg"
      >
        <div class="container-fluid topic-modal-class">  
          <div class="row">
            <div class="btn-group mb-3">
              <button type="button" class="btn btn-default btn-sm" @click="loadItems(1)">
                <i class="fa fa-sync" :class="{'rotate': refershLoad}"></i> Refresh
              </button>
              <button type="button" class="btn btn-default btn-sm" @click="resetFilters">
                <i class="fa fa-undo" :class="{'rotate': refershLoad}"></i> Reset Filters
              </button>
            </div>
            <bootstrap-alert />
            <div class="table-responsive setting-group">
              <vue-good-table
                ref="table1"
                styleClass="vgt-table table table-bordered striped thead-dark"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :pagination-options="{
                  enabled: true,
                  perPage:10,
                  perPageDropdown: [10, 20, 40, 80, 100],
                  dropdownAllowAll: false,
                }"
                :isLoading.sync="isLoading"
                :select-options="{ 
                  enabled: false,
                }"
                :search-options="{
                  enabled: false,
                  placeholder: 'search',
                }"
                :rows="rows"
                :columns="columns"
              >
                <div slot="emptystate" class="text-center">
                  <span v-if="!isLoading">{{'No Records Found.'}}</span>
                </div>
                
                <template slot="loadingContent">
                  <CustomLoader></CustomLoader>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'

export default {
  props:['followup'],
  components: {
    CustomLoader
  },
  data () {
    return {
      row:null,
      refershLoad:false,
      totalRecords: "",
      isLoading: true,
      columns: [
        {
          label: "Date & Time",
          field: "dis_created_at",
          filterOptions: {
            styleClass: 'course_filter', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Date & Time', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: "Inquiry Status",
          field: "inquiry",
          filterOptions: {
            styleClass: 'course_filter', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Inquiry Status', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: "Followup Date & Time",
          field: "next_followup_date",
          filterOptions: {
            styleClass: 'Followup Date & Time', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Followup Date & Time', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: "Reason",
          field: "reason",
          filterOptions: {
            styleClass: 'course_filter', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Reason', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: "Status Remark",
          field: "status_remarks",
          filterOptions: {
            styleClass: 'course_filter', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Status Remark', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
      ],
      rows: [],
      totalRecords: "",
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: ""
          }
        ],
        page: 1,
        perPage: 10
      },
      date_filter : {search_course_name : null},
      xprops: {
        module: 'InquiriesIndex',
        route: 'courses',
        permission_prefix: 'course_'
      }
    }
  },
  computed: {
    ...mapGetters('InquiriesSingle', ['item', 'loading','inqHistoryAll']),
  },
  created() {
    this.row = this.followup
  },
  methods: {
    ...mapActions('InquiriesSingle', ['fetchInquiryHistoryAll']),
    
    handleCancel(evt){
      this.$refs.modal.hide()
    },    
    handleOk (evt) {
      
    },
    getData(){
      this.$refs.modal.show()
      // this.fetchInquiryHistoryAll(this.row.id)
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ],
        page: 1
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.updateParams({page:1})
      this.loadItems()
    },
    onSearch(params) {
      params.page = 1
      this.updateParams(params)
      this.loadItems()
    },
    loadItems(flag = 0) {
    
    var order = ""
    var field = ""
    if (
      this.serverParams.sort[0].type != "" &&
      this.serverParams.sort[0].type != "none"
    ) {
      order = this.serverParams.sort[0].type
      field = this.serverParams.sort[0].field
    }
    let search = this.serverParams.searchTerm
      ? this.serverParams.searchTerm
      : ""
    if(flag== 1){
      this.refershLoad = true
    }
    axios.get("/api/v1/inqHistoryList/"+this.row.id, {
      params: {
        max: this.serverParams.perPage,
        page: this.serverParams.page,
        search: search,
        order: order,
        field: field,
        columnFilters:this.serverParams.columnFilters,
        inquiry_id:this.inquiryId
      }
      })
      .then(response => {
        let res = response.data
        if (res.result) {
          this.rows = res.data.data
          this.totalRecords = res.data.count
          if (flag == 1) {
            this.refershLoad = false
          }
        }
        else{
          this.rows = []
          this.totalRecords = 0
          if (flag == 1) {
            this.refershLoad = false
          }
        }
      })
    },
    resetFilters(){
      this.serverParams.columnFilters = {}
      let columnFilters = {"dis_created_at": "", "inquiry": "", "next_followup_date": "", "reason": "", "status_remarks": ""}
      this.updateParams(columnFilters)
      this.updateParams({page:1})
      this.$refs.table1.reset()
      this.loadItems(1)
    },
    searchCourseData() {
      this.query = {}
      if (this.date_filter.search_course_name && this.date_filter.search_course_name.course_name) {
        this.date_filter.search_course_name = this.date_filter.search_course_name.course_name    
      }
      this.fetchData(this.date_filter)
    }
  }
}
</script>
