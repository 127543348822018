function initialState() {
    return {
        item: {
            project_id: '',
            topic_name:'',
            description:'',
            topic_video:'',
            topic_file:'',
            sort_order:0,
            project_name: null,
            is_access_in_mobile:0,
            have_quiz:0,
            is_task_for_tutor:0,
            topic_id:0,
            project_topics_file:'',
            project_topics_video:'',
        },
        projectList:[],
        loading: false,
        TopicListing:[],  
        loading1: false      
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    projectList: state => state.projectList,
    TopicListing:state => state.TopicListing,
    loading1: state => state.loading1
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading1', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise(async (resolve, reject) => {
        let params = new FormData();

        if (_.isEmpty(state.item.project_id)) {
            params.set('project_id', '')
        } else {
            params.set('project_id', state.item.project_id.id)
        }   
        if (_.isEmpty(state.item.topic_name)) {
            params.set('topic_name', '')
        } else {
            params.set('topic_name', state.item.topic_name)
        } 
        if (_.isEmpty(state.item.description)) {
            params.set('description', '')
        } else {
            params.set('description', state.item.description)
        } 
        var have_quiz = (state.item.have_quiz) ? 1 : 0;
        var is_task_for_tutor = (state.item.is_task_for_tutor) ? 1 : 0;
        var is_access_in_mobile = (state.item.is_access_in_mobile) ? 1 : 0;

        params.set('sort_order', state.item.sort_order)
        params.set('topic_file', state.item.topic_file)
        params.set('topic_video', state.item.topic_video)
        params.set('is_task_for_tutor', is_task_for_tutor)
        params.set('have_quiz', have_quiz)
        params.set('is_access_in_mobile', is_access_in_mobile)
        await axiosLms.post('/api/v1/projectTopics/store', params,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
                //commit('resetState')
                commit('setLoading1', false)
                resolve(response)
                
            })
            .catch(function (error) {
                commit('setLoading1', false)
                        console.log('Error', error.message);
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                
            })                    
            dispatch('getListing', state.item.project_id.id)
        })        
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading1', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise(async (resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')
            
            var have_quiz = (state.item.have_quiz) ? 1 : 0;
            var is_task_for_tutor = (state.item.is_task_for_tutor) ? 1 : 0;
            var is_access_in_mobile = (state.item.is_access_in_mobile) ? 1 : 0;

            params.set('project_id', state.item.project_id.id)
            params.set('topic_name', state.item.topic_name)
            params.set('description', state.item.description)
            params.set('sort_order', state.item.sort_order)
            params.set('topic_file', state.item.topic_file)
            params.set('topic_video', state.item.topic_video)
            params.set('is_task_for_tutor', is_task_for_tutor)
            params.set('have_quiz', have_quiz)
            params.set('is_access_in_mobile', is_access_in_mobile)
            await axiosLms.post('/api/v1/projectTopics/' + state.item.topic_id, params,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(response => {
                    // commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          console.log('Error', error.message);
                    }) 
                .finally(() => {
                    commit('setLoading1', false)
                })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/projectTopics/' + id)
                .then(response => {
                    resolve(response);
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    reject(error)
                }) 
                .finally(() => {
                    //commit('setError', false)
                })
            // dispatch('getListing', state.item.project_id.id)
        })
    },
    async getListing({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/getProjectsData/'+id)
            .then(response => {
                if(response.data.result){
                    let data = response.data.data;                    
                    commit('setTopicListing', data)
                    commit('setLoading', false)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                commit('setLoading', false)
            })                
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projects/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setProjectId', response.data.data)
                }
            })
            .catch(function (error) {
                    console.log('Error', error.message);
            }) 
                          
    },
    async fetchTopicData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/projectTopics/' + id)
            .then(response => {
                if(response.data.result){
                    commit('setTopicId', response.data.data.id)
                    commit('setTopicName', response.data.data.topic_name)
                    commit('setDescription', response.data.data.description)
                    commit('setHaveQuiz', response.data.data.have_quiz)
                    commit('setTaskForTutor', response.data.data.is_task_for_tutor)
                    commit('setAccessInMobile', response.data.data.is_access_in_mobile)
                    commit('setProjectTopicsFile', response.data.data.project_topics_file)
                    commit('setProjectTopicsVideo', response.data.data.project_topics_video)
                    dispatch('fetchData', response.data.data.project_id)    
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })  
                   
    }, 
    fetchProjectAll({ commit }) {
        axiosLms.get('/api/v1/getAllProjects')
            .then(response => {
                commit('setProjectAll', response.data.data)       
            })
            .catch(function (error) {
                    console.log('Error', error.message);
                    let message = error.response.data.message || error.message
                    let errors  = error.response.data.errors
                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })
                    reject(error)
            }) 
    },
    setTopicId({ commit }, value) {
        commit('setTopicId', value)
    },
    setTopicName({ commit }, value) {
        commit('setTopicName', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setProjectId({ commit }, value) {
        commit('setProjectId', value)
    },
    setTopicFile({ commit }, value) {
        commit('setTopicFile', value)
    },
    setProjectTopicsFile({ commit }, value) {
        commit('setProjectTopicsFile', value)
    },
    setProjectTopicsVideo({ commit }, value) {
        commit('setProjectTopicsVideo', value)
    },
    setTopicVideo({ commit }, value) {
        commit('setTopicVideo', value)
    },
    setHaveQuiz({ commit }, value) {
        commit('setHaveQuiz', value)
    },
    setTaskForTutor({ commit }, value) {
        commit('setTaskForTutor', value)
    },
    setAccessInMobile({ commit }, value) {
        commit('setAccessInMobile', value)
    },
    setLoading({ commit }, value) {
        commit('setLoading', value)
    },   
    resetState({ commit }) {
        commit('resetState')
    },
    async updateElements({ commit }, payload) {
        commit('setLoading', true)
        await axiosLms.post('/api/v1/updateSortOrder/'+payload.project_id, payload)
            .then(response => {
                if(response.data.result){
                    let data = response.data.data; 
                    console.log(data);                   
                    commit("updateElements", payload);
                    commit('setLoading', false)
                 }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            })   
    },    
}

const mutations = {
    updateElements: (state, payload) => {       
        state.TopicListing = payload;
    },
    setProjectAll(state,project_detail){
        state.projectList = project_detail;
        //state.item = project_detail;
    },
    setTopicId(state, value) {
        state.item.topic_id = value;
    },
    setTopicFile(state, value) {
        state.item.topic_file = value;
    },
    setProjectTopicsFile(state, value) {
        state.item.project_topics_file = value;
    },
    setProjectTopicsVideo(state, value) {
        state.item.project_topics_video = value;
    },
    setTopicName(state, value) {
        state.item.topic_name = value;
    },
    setDescription(state, value) {
        state.item.description = value;
    },
    setTopicVideo(state, value) {
        state.item.topic_video = value;
    },
    setProjectId(state, value) {
        state.item.project_id = value;
    },
    setProjectName(state,value){
        state.item.roject_name = value;
    },
    setHaveQuiz(state,value){
        state.item.have_quiz = value;
    },
    setTaskForTutor(state,value){
        state.item.is_task_for_tutor = value;
    },
    setAccessInMobile(state,value){
        state.item.is_access_in_mobile = value;
    },
    setTopicListing(state,value){
        state.TopicListing = value.project_topics1
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLoading1(state, loading) {
        state.loading1 = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
