<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Roles</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="'/home'">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="'/roles'">Roles</router-link>
                            </li>
                            <li class="breadcrumb-item active">Edit Role</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Edit Role</h3>
                </div>
                <div class="card-body">
                    
                   <div class="row">
                       <div class="col-md-12">
                        <form @submit.prevent="submitForm" novalidate>                    
                            <bootstrap-alert />                     
                            
                            <div class="form-group">
                                <label for="title">Role Title<p class="astrisk">*</p></label>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="title"
                                        ref="title"
                                        maxlength="150"
                                        placeholder="Enter Role Title"
                                        v-validate="{ required: true, regex:/^[ A-Za-z0-9_@./#&+-]*$/ }"
                                        :value="item.title"
                                        @input="updateTitle"
                                        >
                                    <span class="error" v-show="errors.has('title')">
                                        {{ $root.updateErrorMessage('title',this.errors,'Role Title') }}
                                    </span>                                              
                            </div>
                                <div class="form-group">
                                    <!-- <div class="row"> -->
                                    <div class="table-responsive my-roles">
                                        <table class="table no-resposive-grid">
                                                <tr class="text-center">
                                                        <th class="text-center" width="35%">Permission<p class="astrisk">*</p></th>
                                                        <th class="text-center" width="13%">All </th>
                                                        <th class="text-center" width="13%">Add</th>
                                                        <th class="text-center" width="13%">Edit</th>
                                                        <th class="text-center" width="13%">View</th>
                                                        <th class="text-center" width="13%">Delete</th>                                            
                                                    </tr>
                                                <tr class="text-center" v-for="menuVal in menuMasterAll" :id="'permissionRowId_'+menuVal.id" :key="menuVal.id" >
                                                <td class="text-left">{{ menuVal.menu_name }}</td>
                                                <td>
                                                <label class="containck">   
                                                    <input 
                                                    type="checkbox"
                                                    name="checkAll"
                                                    :checked="editCheckedAllField(menuVal.id)" 
                                                    :value="menuVal.id" 
                                                    v-model="checkedAllNames[menuVal.id]"
                                                    @change="checkAllPermission(menuVal.permission_menu_wise, menuVal.id)"
                                                    >
                                                    <span class="checkmarkck"></span>
                                                    </label>                                      
                                            </td>                                     
                                                <td v-for="(j,i) in 5" :key="i" v-show="i > 0">
                                                <label class="containck">   
                                                        <input type="checkbox" name="permission" :disabled="checkFields(menuVal.permission_menu_wise,i)" :checked="editCheckedField(getPermissionValue(menuVal.permission_menu_wise,i),item.permission)" 
                                                        :value="getPermissionValue(menuVal.permission_menu_wise,i)" 
                                                        v-model="checkedNames" 
                                                        v-validate="'required'"  @input="updatePermission"
                                                        v-on:click="select(menuVal.permission_menu_wise,i)"
                                                        >
                                                    <span class="checkmarkck"></span>
                                                </label>                  
                                            </td>
                                            </tr>                              
                                        </table>  
                                </div>
                                <!-- </div> -->
                                    <div class="col-md-12">
                                        <span class="error" v-show="errors.has('permission')">
                                            {{ $root.updateErrorMessage('permission',this.errors,'Select At least 1 Permission')}}
                                        </span>      
                                    </div>
                                </div>
                            
                            <div class="col-md-12 col-xs-12 savebtn">
                                <vue-button-spinner
                                        class="std_information_save btn btn-block btn-success new_branch_save col-md-2"
                                        :isLoading="loading"
                                        :disabled="loading"
                                        >
                                        Save
                                </vue-button-spinner>
                            </div>
                        </form>
                        </div>
                   </div>

                </div>
                <!-- <div class="card-body" v-else>
                    <CustomLoader></CustomLoader>
                </div> -->
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomLoader from '@/components/CustomLoader'
export default {
    components: {
        CustomLoader
    },
    data() {
        return {
            indexVal:'',
            checkedNames:[],
            permissionOrderArray:[],
            selectAll: false,
            i:0,
            checkedAllNames : []
        }
    },
    computed: {
        ...mapGetters('RolesSingle', ['item', 'loading','loading1', 'permissionsAll','menuMasterAll']),
    },
    created() {
        this.fetchData(this.$route.params.id)
    },
    destroyed() {
        this.resetState()
    },
    watch: {
        "$route.params.id": function() {
            this.resetState()
            this.fetchData(this.$route.params.id)
        },
        item(newValue, oldValue){
            //if(newVal)
            if(newValue.length == 0){
                this.$eventHub.$emit('common', 2, 'Something went wrong');
                this.$router.push('/roles');
            } 
        }
    },
    methods: {
        ...mapActions('RolesSingle', ['fetchData', 'updateData', 'resetState', 'setTitle', 'setPermission']),

        //this is use for  checkbox enabled or disabled as per database record
        checkFields(permissions,id)
        { 
            id++;
            var ret = permissions.filter(function(el){
                    return el.permission_order == id && el.permission_role;
            });
            if(ret.length>0)
            {

                return false;
            }
            else
            {
                if(permissions.length > 1 && id == 6)
                {
                    return false;
                }
                else
                {
                    return true;
                }
            }

        },
        //this is return enabled check box permission check or not 
        editCheckedField(permissions_id,editpermissions)
        {
            if(editpermissions){
            var ret = editpermissions.filter(function(el){
                    return el.id == permissions_id;                                
            });
            if(ret.length>0)
            {
                 if ($.inArray( ret[0].id, this.checkedNames) == -1){
                    this.checkedNames.push(ret[0].id);  
                return true;
                 }
            }
         }               
        },
        //this is return value for enabled checkbox
        getPermissionValue(el,id)
        {
            id++;
            var ret = el.filter(function(el){
                    return el.permission_order == id;
            });
            
            if(ret.length>0)
            {
                return ret[0].id;
            }
            else
            {
                if(el.length > 1 && id == 6)
                {
                    return 'permissionsAll'+el[0].menu_id;
                }
                else
                {
                    return true;
                }
            }

        },
        updateTitle(e) {
            this.setTitle(e.target.value)
        },        
         updatePermission(e) {
            var updatePermissionVal = parseInt(e.target.value);
            var indexVal = $.inArray( updatePermissionVal, this.checkedNames);
            if (indexVal == -1){
                    this.checkedNames.push(updatePermissionVal);  
                }else{                    
                    this.checkedNames.splice(indexVal,1);
                }
            var test = []; 
            this.checkedNames.forEach(function(val){
                test.push({ 'id': val });
            });
            this.setPermission(test)                   
            /*this.setPermission(this.checkedNames)*/
        },
        updatePermissionAll(value) {
            var indexVal = this.checkedNames.indexOf(value);
            if(indexVal == -1){
               this.checkedNames.push(value);          
            }else{
                this.checkedNames.splice(indexVal,1);          
            }
            var test = []; 
            this.checkedNames.forEach(function(val){
                test.push(val);
            });
            this.setPermission(test)            
            /*this.setPermission(this.checkedNames)*/
        },
        submitForm() {
             this.$validator.validate().then(result => {
             if (result) {
            this.updateData()
                 .then((res) => {
                    if(!res.data.result)
                    {
                        this.$validator.errors.add({
                          id: 'title',
                          field: 'title',
                          msg: res.data.message,
                          scope: this.$options.scope,
                        });
                        return '';    
                    } 
                    this.$router.push({ name: 'roles.index' })
                    this.$eventHub.$emit('update-success')
                })
                .catch((error) => {
                    console.error(error)
                })
                 }
              else{
                return this.$root.handleValidationFocus(this.errors,this.$refs);
            }                         
          });
        },
        select(permissions,id) {
            var isCheckAll = this.isCheckAllPermission(permissions[0].menu_id);
            this.checkedAllNames[permissions[0].menu_id] = (isCheckAll.length == permissions.length) ? true : false;
            var isChecked = this.isCheckedAnyFiled(permissions[0].menu_id);
            $("#permissionRowId_"+permissions[0].menu_id+" input[name='permission']:first").each(function() {
                if (isChecked && !$(this).prop("checked")) {
                    $(this).click();
                } else if (!isChecked && $(this).prop("checked")) {
                    $(this).click();
                }
            });
        },
        checkAllPermission(menuVal, menuId){
            for (var i = 0; i < 5; i++) {
                if(!this.checkFields(menuVal,i))
                {
                    var indexVal = this.checkedNames.indexOf(this.getPermissionValue(menuVal,i));
                    if (this.checkedAllNames[menuId]) {
                        if (indexVal == -1) {
                            this.updatePermissionAll(this.getPermissionValue(menuVal,i));
                        }
                    } else {
                        if (indexVal != -1) {
                            this.updatePermissionAll(this.getPermissionValue(menuVal,i));
                        }
                    }
                }
            }
        },
        isCheckAllPermission(menuId) {
            var checkFiled = []; 
            $("#permissionRowId_"+menuId+" input[name='permission']").each(function(i,v) {
                if ($(this).prop("checked")) {
                    checkFiled.push(i);
                }
            });

            return checkFiled;
        },
        isCheckedAnyFiled(menu_id) {
            var isChecked = false;
            $("#permissionRowId_"+menu_id+" input[name='permission']").each(function(i,v) {
                if (i > 0 && $(this).prop("checked")) {
                    isChecked = true;
                }
            });

            return isChecked;
        },
        editCheckedAllField(menu_id) {
            var permissionsLength = this.getActiveCheckbox(menu_id);
            var isCheckAll = this.isCheckAllPermission(menu_id);
            this.checkedAllNames[menu_id] = (isCheckAll.length == permissionsLength) ? true : false;
        },
        getActiveCheckbox(menu_id) {
            var count = 0;
            $("#permissionRowId_"+menu_id+" input[name='permission']").each(function(i,v) {
                if (!$(this).prop("disabled")) {
                    count = count +1; 
                }
            });

            return count;
        }
    }
}
</script>


<style scoped>
table, th, td {
   border: 1px solid #f4f4f4;
}
td {
    height: 33px;
}
.checkmarkck {
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
}
.containck{
    height: 33px;
    margin-bottom: 0px;
    font-size: unset;
}
</style>
