function initialState() {
    return {
        item: {
            id: null,
            student: null,
            receipt_date: /*moment().format('DD/MM/YYYY')*/null,
            manual_receipt_no: null,
            receipt_amount: null,
            paymentMode:[{value_id:1,name:'Cash'}],
            feeAll:[],
        },
        receiptId: null,
        total_due: 0,
        studentAll: [],
        paymentModeAll: [],
        pendingFeeAll: [],
        NewpendingFeeAll:[],
        loading: false,
        // paymentModeAll:[{id:1,name:'Cash'},{id:2,name:'Bank'}],
        paymentDetails : [],
        // paymentDetails : [{
        //     payment_mode : {id:1,name:'Cash'},
        //     mode_amount_paid : 0,
        // }],
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    receiptId:state => state.receiptId,
    studentAll: state => state.studentAll,
    pendingFeeAll: state => state.pendingFeeAll,
    paymentModeAll: state => state.paymentModeAll,
    paymentDetails: state => state.paymentDetails,
    NewpendingFeeAll: state => state.NewpendingFeeAll,
}

const actions = {
    storeData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            let params = new FormData();

            if(finalData){                
                params.set('submitFee',JSON.stringify(finalData['submitFee']));
                params.set('student_id',state.item.student);
            }
            if(state.item.receipt_date){
                var formatedFromDate = state.item.receipt_date.split('/');
                var finalDate = formatedFromDate[2]+'-'+formatedFromDate[1]+'-'+formatedFromDate[0]  
                params.set('receipt_date',finalDate);
            }
            if(state.item.receipt_amount){
                params.set('receipt_amount',state.item.receipt_amount);
            }
            if(state.paymentDetails){
                state.paymentDetails.paymentMode.forEach(function(paymentMode, index){
                    params.set('paymentMode['+index+'][payment_mode]',paymentMode.payment_mode);
                    if(paymentMode.payment_mode !=1)
                    {
                        params.set('paymentMode['+index+'][instrument_no]',paymentMode.instrument_no);
                        params.set('paymentMode['+index+'][instrument_date]',paymentMode.instrument_date);
                        params.set('paymentMode['+index+'][bank_name]',paymentMode.bank_name);
                    }
                    params.set('paymentMode['+index+'][amount]',paymentMode.amount);                    
                })
            }
            axiosLms.post('/api/v1/receipt/store', params)
                .then(response => {
                    commit('resetState')
                    resolve()
                })
            .catch(function (error) {
                        let message = error.response.data.message || error.message
                        let errors  = error.response.data.errors
                        dispatch(
                            'Alert/setAlert',
                            { message: message, errors: errors, color: 'danger' },
                            { root: true })
                        reject(error)
                }) 
            .finally(() => {
                commit('setLoading', false)
            })                
        }) 
    },
    updateData({ commit, state, dispatch }, finalData) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            if(finalData){                
                params.set('submitFee',JSON.stringify(finalData['submitFee']));
                params.set('student_id',state.item.student_id);
            }
            if(state.item.receipt_date){
                params.set('receipt_date',state.item.receipt_date);
            }
            if(state.item.receipt_amount){
                params.set('receipt_amount',state.item.receipt_amount);
            }

            state.paymentDetails.paymentMode.forEach(function(paymentMode, index){
                params.set('paymentMode['+index+'][payment_mode]',paymentMode.payment_mode);
                if(paymentMode.payment_mode !=1)
                {
                    params.set('paymentMode['+index+'][instrument_no]',paymentMode.instrument_no);
                    params.set('paymentMode['+index+'][instrument_date]',paymentMode.instrument_date);
                    params.set('paymentMode['+index+'][bank_name]',paymentMode.bank_name);
                }
                params.set('paymentMode['+index+'][amount]',paymentMode.amount);
                
            })
            axiosLms.post('/api/v1/receipt/update/' + state.receiptId, params)
                .then(response => {
                    //commit('setItem', response.data.data)
                    resolve(response)
                })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async fetchData({ commit, dispatch }, id) {
        await axiosLms.get('/api/v1/receipt/show/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                commit('setFeeAll', response.data.data.receipt_detail)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
        dispatch('fetchPaymentModeAll');
    },
    downloadInvoicePdf({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                params.set('receiptId', data.receiptId)
                params.set('student_id', data.student_id)

            axiosLms.post('/api/v1/receipt/downloadInvoicePdf', params,{ headers: {
                'Content-Type': 'multipart/form-data',
            },
                'responseType': 'blob' // responseType is a sibling of headers, not a child
            })
            .then(response => {
                // commit('resetState')
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    sendMailInvoicePdf({ commit, state, dispatch },data) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
                params.set('receiptId', data.receiptId)
                params.set('student_id', data.student_id)

            axiosLms.post('/api/v1/receipt/sendMailInvoicePdf', params)
            .then(response => {
                // commit('resetState')
                resolve(response)
            })
            .catch(function (error) {
                console.log('Error', error.message);
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    fetchPaymentModeAll({ commit }) {
        axiosLms.get('/api/v1/receipt/getPaymentModeList')
            .then(response => {                
                commit('setPaymentModeAll', response.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    // fetchPaymentModeTypeAll({ commit }) {
    //     axios.get('/api/v1/paymentModeTypeList')
    //         .then(response => {                
    //             commit('setPaymentModeTypeAll', response.data.data)
    //         })
    // },            
    async fetchStudentAll({ commit }) {
        await axios.get('/api/v1/students_list_excluding_cancelled')
            .then(response => {
                commit('setStudentAll', response.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchPendingFeeAll({ commit }, id) {
        await axiosLms.get('/api/v1/getStudentEnrollCoursesForAddPayment/'+id)
            .then(response => {
                commit('setPendingFeeAll', response.data.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchPendingEditFeeAll({ commit }, id) {
        await axiosLms.get('/api/v1/getStudentEnrollCoursesForEditPayment/'+id.student_id+'/'+id.rid)
            .then(response => {
                commit('setPendingFeeAll', response.data.data.data)
               // commit('setNewPendingFeeAll', response.data.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchPaymentFeeAll({ commit }, obj) {
        await axios.get('/api/v1/paymentFeeList/'+obj.id+'/'+obj.rid)
            .then(response => {
                commit('setPendingFeeAll', response.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    async fetchPaymentMode({ commit }, rid) {
        await axiosLms.get('/api/v1/paymentMode/'+rid)
            .then(response => {
                commit('setPaymentDetails', response.data.data)
            })
            .catch(function (error) {
                          let message = error.response.data.message || error.message
                          let errors  = error.response.data.errors
                          dispatch(
                              'Alert/setAlert',
                              { message: message, errors: errors, color: 'danger' },
                              { root: true })
                          reject(error)
                    }) 
    },
    setStudent({ commit }, value) {
        commit('setStudent', value)
    }, 
    setReceiptDate({ commit }, value) {
        commit('setReceiptDate', value)
    }, 
    setManualReceiptNo({ commit }, value) {
        commit('setManualReceiptNo', value)
    }, 
    setReceiptAmount({ commit }, value) {
        commit('setReceiptAmount', value)
    },    
    setPaymentMode({ commit }, value) {
        commit('setPaymentMode', value)
    },    
    setPaymentDetails({ commit }, value) {
        commit('setPaymentDetails', value)
    },
    setTotalDue({ commit }, value) {
        commit('setTotalDue', value)
    },    
    setPendingFeeAll({ commit }, value) {
        commit('setPendingFeeAll', value)
    }, 
    setFeeAll({ commit }, value) {
        commit('setFeeAll', value)
    },  
    setNewPendingFeeAll({ commit }, value) {
        commit('setNewPendingFeeAll', value)
    },  
    setReceiptId({ commit }, value) {
        commit('setReceiptId', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    // setPaymentModeTypeAll(state, value) {
    //     state.paymentModeTypeAll = value
    // },    
    setReceiptId(state, value) {
        state.receiptId = value
    },
    setStudent(state, value) {
        state.item.student = value
    },
    setPaymentMode(state, value) {
        state.item.paymentMode = value
    },
    setReceiptDate(state, value) {
        state.item.receipt_date = value
    },
    setManualReceiptNo(state, value) {
        state.item.manual_receipt_no = value
    },
    setReceiptAmount(state, value) {
        state.item.receipt_amount = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setStudentAll(state, value) {
        state.studentAll = value
    },
    setPendingFeeAll(state, value) {
        state.pendingFeeAll = value
    },
    setFeeAll(state, value) {
        state.item.FeeAll = value
    },
    setNewPendingFeeAll(state, value) {
        state.NewpendingFeeAll = value
    },
    setPaymentModeAll(state, value) {
        state.paymentModeAll = value
    },
    setTotalDue(state, value) {
        state.total_due = value
    },    
    setPaymentDetails(state, value) {
        state.paymentDetails = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
