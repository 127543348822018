function initialState() {
    return {
        item: {
            id: '',
            file: '',
            name: '',
            description: ''
        },
        editData:[],
        loading: false,
        certificatePlaceHolders:[]
    }
}
const getters = {
    item: state => state.item,
    getEditData: state => state.editData,
    loading: state => state.loading,
    certificatePlaceHolders: state => state.certificatePlaceHolders
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if (_.isEmpty(state.item.name)) {
                params.set('name', '')
            } else {
                params.set('name', state.item.name)
            }

            if (_.isEmpty(state.item.description)) {
                params.set('description', '')
            } else {
                params.set('description', state.item.description)
            }
            
            params.set('file', state.item.file)

            axiosLms.post('/api/v1/certificate', params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            }) 
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    async fetchEditData({ commit, dispatch }, id) {
        commit('setLoading', true)
        await axiosLms.get('/api/v1/certificate/' + id)
        .then(response => {
            commit('setItem', response.data.data)
            commit('setEditData', response.data.data)
            commit('setLoading', false)
        })
        .catch(function (error) {
            let message = error.response.data.message || error.message
            let errors  = error.response.data.errors
            dispatch(
                'Alert/setAlert',
                { message: message, errors: errors, color: 'danger' },
                { root: true })
            reject(error)
            commit('setLoading', false)
        })
    },
    async fetchCertificatePlaceHolder({ commit, dispatch }, id) {
        //commit('setLoading', true)
        await axiosLms.get('/api/v1/getCertificatePlaceholder')
        .then(response => {
          //  console.log(response)
           if(response.data.result){
                commit('setPlaceHolder', response.data.data)
           }
           else{
                commit('setPlaceHolder', [])
           }
        });
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            if (_.isEmpty(state.item.name)) {
                params.set('name', '')
            } else {
                params.set('name', state.item.name)
            }

            if (_.isEmpty(state.item.description)) {
                params.set('description', '')
            } else {
                params.set('description', state.item.description)
            }

            params.set('file', state.item.file)

            axiosLms.post('/api/v1/certificate/' + state.item.id, params)
            .then(response => {
                resolve(response)
            })
            .catch(function (error) {
                let message = error.response.data.message || error.message
                let errors  = error.response.data.errors
                dispatch(
                    'Alert/setAlert',
                    { message: message, errors: errors, color: 'danger' },
                    { root: true })
                reject(error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
        })
    },
    destroyData({ commit, state }, id) {
        return new Promise((resolve, reject) => {        
            axiosLms.delete('/api/v1/certificate/' + id)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
            }) 
            .finally(() => {
                commit('setError', false)
            })
        })
    },

    setEditData({ commit }, value) {
        commit('setEditData', value)
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setFile({ commit }, value) {
        commit('setFile', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setPlaceHolder(state,item){
        state.certificatePlaceHolders = item;
    },
    setItem(state, item) {
        state.item = item
    },
    setEditData(state, item) {
        state.editData = item
    },
    setName(state, value) {
        state.item.name = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setFile(state, value) {
        state.item.file = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}